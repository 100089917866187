import * as React from 'react';
import WalletInvests from '../../components/WalletInvests/WalletInvests'
import WalletPGraph from '../../components/WalletPieGraph/WalletPGraph'
import { IWallet, IWalletInvestments } from '../../redux/reducers/walletReducer';
import './WalletHeader.css'
import Resource from '../../components/Resources/Resource';
import { Box, createStyles, Divider, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';

// Create the containers interface
interface IProps {
  wallet: IWallet;
  walletInvestments: IWalletInvestments;
  cultureTag: string;
  culture: any;
  allowToSeeInvestment:boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      backgroundColor:theme.palette.primary.main,
      color:theme.palette.primary.main,
    }
  }),
);

function WalletHeader (props:IProps) {
  
  const { walletInvestments, wallet, cultureTag, culture, allowToSeeInvestment } = props;
  const classes = useStyles()

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="body1" className="WalletTextHeader">
            <Resource tag={"PageWallet::Points_AvailableToInvest"} args={[`${wallet?.totalAvailable.toLocaleString(cultureTag)} `]} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} lg={7}>
          <WalletPGraph allowToSeeInvestments={allowToSeeInvestment} culture={culture} totalCurrentValue={walletInvestments?.totalCurrentValue} wallet={wallet} cultureTag={cultureTag}/>
        </Grid>
        {
          allowToSeeInvestment &&
          <>
            <Hidden mdDown>
              <Grid item xs={1} >
                <Divider className={classes.divider} variant="fullWidth" orientation="vertical"></Divider>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={12} lg={4}>
              <WalletInvests walletInvestments={walletInvestments} cultureTag={cultureTag}/>
            </Grid>
          </>
        } 
      </Grid>
    </Box>
  );
}

export default WalletHeader;