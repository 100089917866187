import { Reducer } from "redux";
import {
  NewsDetailActionTypes,
  NewsDetailActions,
  NewsDetailActionStatus,
} from "../../actions/news/NewsDetailAction";

export interface IRelatedNews {
  newsId: string;
  image: string;
  publicationDate: string;
  topic: string;
  title: string;
  nrLikes: number;
  isFavorite: boolean;
}

export interface INewsDetailResult {
  authorName: string;
  authorPhoto: string;
  sharedCount: number;
  enableComment: boolean;
  commentsCount: number;
  comments: [
    {
      repliesCount: number;
      commentId: string;
      userId: string;
      fullName: string;
      photo: string;
      timeAgo: string;
      timeStampResource: string;
      message: string;
      isLikedByMe: boolean;
      likesCount: number;
    }
  ];
  relatedNews: IRelatedNews[];
  relatedNewsCount: number;
  newsId: string;
  image: string;
  publicationDate: string;
  topic: string;
  title: string;
  description: string;
  nrLikes: number;
  isFavorite: boolean;
}

export interface INewsReplies {
  commentId: string;
  userId: string;
  fullName: string;
  photo: string;
  timeAgo: string;
  timeStampResource: string;
  message: string;
  isLikedByMe: boolean;
  likesCount: number;
}

export interface INewsComments {
  replies: INewsReplies[];
  commentId: string;
  userId: string;
  fullName: string;
  photo: string;
  timeAgo: string;
  timeStampResource: string;
  message: string;
  isLikedByMe: boolean;
  likesCount: number;
}

export interface INewsDetailState {
  readonly error: any;
  readonly newsDetailStatus: string;
  readonly newsDetail: INewsDetailResult;
  readonly newsComments: INewsComments[];
  readonly newsCommentStatus: string;
  readonly metaComments: any;
  readonly likeStatus: string;
  readonly totalMessagePages: number;
  readonly messagesCount: number;
}

const initialIdeaManagersState = {
  newsDetailStatus: NewsDetailActionStatus.NEWS_DETAIL_LOADING,
  newsCommentStatus: "",
  likeStatus: NewsDetailActionStatus.LIKE_SUCCEEDED,
  error: null,
  newsDetail: {
    authorName: "",
    authorPhoto: "",
    sharedCount: 0,
    enableComment: false,
    commentsCount: 0,
    comments: [],
    relatedNews: [],
    relatedNewsCount: 0,
    newsId: "",
    image: "",
    publicationDate: "",
    topic: "",
    title: "",
    description: "",
    nrLikes: 0,
    isFavorite: false,
  },
  newsComments: [],
  metaComments: {},
};

export const newsDetailReducer: Reducer<any, NewsDetailActions> = (
  state = initialIdeaManagersState,
  action
) => {
  switch (action.type) {
    case NewsDetailActionTypes.NEWS_DETAIL_LOADING: {
      return {
        ...state,
        newsDetailStatus: action.newsDetailStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_DETAIL_SUCCEEDED: {
      return {
        ...state,
        newsDetail: action.newsDetail,
        newsDetailStatus: action.newsDetailStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_DETAIL_FAILED: {
      return {
        ...state,
        error: action.error,
        newsDetailStatus: action.newsDetailStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_COMMENTS_LOADING: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_COMMENTS_SUCCEEDED: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
        metaComments: action.meta,
        newsComments: action.newsComments,
        messagesCount: action.messagesCount,
        totalMessagePages: action.totalMessagePages,
      };
    }
    case NewsDetailActionTypes.NEWS_COMMENTS_FAILED: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
        error: action.error,
      };
    }
    case NewsDetailActionTypes.NEWS_POSTCOMMENT_LOAD: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_POSTCOMMENT_SUCCESS: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
        metaComments: action.meta,
        newsComments: action.newsComments,
      };
    }
    case NewsDetailActionTypes.NEWS_POSTCOMMENT_FAIL: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_PUT_COMMENT_LOAD: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_PUT_COMMENT_SUCCESS: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
        metaComments: action.meta,
        newsComments: action.newsComments,
      };
    }
    case NewsDetailActionTypes.NEWS_PUT_COMMENT_FAIL: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_DELETE_COMMENT_LOAD: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_DELETE_COMMENT_SUCCESS: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
        newsComments: action.newsComments,
      };
    }
    case NewsDetailActionTypes.NEWS_DELETE_COMMENT_FAIL: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_DELETE_COMMENT_REPLY_LOAD: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
      };
    }
    case NewsDetailActionTypes.NEWS_DELETE_COMMENT_REPLY_SUCCESS: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
        newsComments: action.newsComments,
      };
    }
    case NewsDetailActionTypes.NEWS_DELETE_COMMENT_REPLY_FAIL: {
      return {
        ...state,
        newsCommentStatus: action.newsCommentStatus,
      };
    }
    case NewsDetailActionTypes.HANDLE_LIKE_FAILED: {
      return {
        ...state,
        likeStatus: action.likeStatus,
        error: action.error,
      };
    }
    case NewsDetailActionTypes.HANDLE_LIKE_LOADING: {
      return {
        ...state,
        likeStatus: action.likeStatus,
      };
    }
    case NewsDetailActionTypes.HANDLE_LIKE_SUCCEEDED: {
      return {
        ...state,
        likeStatus: action.likeStatus,
      };
    }
    case NewsDetailActionTypes.HANDLE_DISLIKE_SUCCEEDED: {
      return {
        ...state,
        likeStatus: action.likeStatus,
      };
    }
    default:
      return state;
  }
};
