import Icon from "components/Icon/Icon";
import AlertList from "../../../containers/AlertList/AlertList";
import React, { useEffect, useCallback } from "react";
import { IAlert } from "../../../redux/reducers/alertReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { Badge, Box, createStyles, Fab, makeStyles } from "@material-ui/core";

//Exemplo de uso
//<AlertsButton alert={alert}/>

const useStyles = makeStyles(() =>
  createStyles({
    alertsContainer: {
      position: "relative",
    },
    arrowUpContainer: {
      "&:after": {
        width: 0,
        content: '""',
        height: 0,
        borderLeft: "14px solid transparent",
        borderRight: "14px solid transparent",
        position: "absolute",
        top: "45px",
        zIndex: 7,
        right: "10px",
        borderBottom: "16px solid #fff",
      },
    },
  })
);

interface IProps {
  id: string;
  alerts: IAlert;
  culture: ICultureState;
  readNotification: (
    alertId: string,
    type: string,
    object: string,
    objectId: string,
    objectIdHighlight: string
  ) => void;
}

function AlertsButton(props: IProps) {
  const classes = useStyles();
  const { alerts, culture, readNotification } = props;
  const [count, setCount] = React.useState(0);
  const [on, setOn] = React.useState(false);
  const alertContainer = React.createRef<HTMLDivElement>();

  const handleClickOutside = useCallback(
    (e: any) => {
      if (
        alertContainer.current &&
        !alertContainer.current.contains(e.target)
      ) {
        setOn(false);
      } else if (alertContainer.current === null) {
        setOn(false);
      }
    },
    [alertContainer]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const action = () => {
    setOn(!on);
  };

  function loadAlerts() {
    let meta = 0;
    let alerts = props.alerts?.result;

    for (let i = 0; i < alerts?.length; i++) {
      if (alerts[i]?.isVisualized === false) {
        meta++;
      }
    }
    setCount(meta);
  }

  useEffect(() => {
    loadAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    //@ts-ignore
    <Box className={classes.alertsContainer} ref={alertContainer}>
      <Badge
        invisible={count === 0}
        color="error"
        badgeContent={count}
        overlap="circle"
        data-count={count}
        onClick={action}
      >
        <Fab id={props.id} color="primary" size="medium">
          <Icon color="white" size={30} icon="notifications" />
        </Fab>
      </Badge>
      {on && <Box className={classes.arrowUpContainer} />}
      {on && (
        <AlertList
          culture={culture}
          readNotification={readNotification}
          alert={alerts}
        />
      )}
    </Box>
  );
}

export default AlertsButton;
