import React from 'react'
import './WalletInvests.css';
import MiniLine from '../MiniLine/MiniLine';
import ToolTip from '../ToolTip/ToolTip';
import Resource from '../Resources/Resource';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import { IWalletInvestments } from 'redux/reducers/walletReducer';

interface IProps {
   walletInvestments: IWalletInvestments;
   cultureTag: string;
}

function WalletInvests (props:IProps) {

        const { walletInvestments, cultureTag } = props
        const graph = walletInvestments?.result
        let initialValue = walletInvestments?.totalInvestedAmount
        let value = walletInvestments?.totalCurrentValue
        const data = graph?.map( graph => {
            return Object.values(graph?.graphIdeaRate)
        })

        return(
         <Box>
            <Hidden xsDown>
               <Grid container spacing={2} >
                  <Grid item xs={12} sm={12} md={12} lg={12} className="graph-div">
                     <Grid item xs={12} className="info-graph-2">
                        <Grid item xs={12}>
                           <Typography variant="body1" className="WalletCurrentInvestmentsTitle">
                              <Resource tag={"PageWallet::CurrentInvestmentsTitle"} />
                           </Typography>
                           <ToolTip title={<Resource tag={"PageWallet::ToolTipInvestmentsTitle"} args={['']}/>}>
                              <Resource tag={"PageWallet::ToolTipInvestmentsText"} args={['']}/>
                           </ToolTip>
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="h2" className="WalletTotalCurrentValue">
                              {walletInvestments ? walletInvestments?.totalCurrentValue?.toLocaleString(cultureTag) : 0}
                           </Typography>
                           <div className="substituir-div" style={{ top:"40px"}}>
                              <MiniLine points={walletInvestments ? value - initialValue : 0} data={walletInvestments ? data[0]: [0,0,0]} height={80} showPoints={true}/>
                           </div>                      
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="h4" style={{ textTransform: 'lowercase'}}>
                              <Resource tag={"PageWallet::Points_Invested"} args={['']}/>
                           </Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant="h4" className="bottom-header-wallet-text">
                              <Resource tag={"PageWallet::InitialInvestedAmount"} />
                           </Typography>
                           <Typography variant="body1" style={{ textTransform: 'lowercase', justifyContent: 'flex-end', paddingBottom: '8px'}}>
                              <Resource tag={"PageWallet::Points_Invested"} args={[walletInvestments ? walletInvestments?.totalInvestedAmount?.toLocaleString(cultureTag) : 0]} />
                           </Typography>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Hidden>
         </Box>
        )
}

export default WalletInvests;