import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "routes/history";
import { Box, CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";
import { api_general } from "config/api";
import { IActiveTenantAction, setActiveTenant } from "redux/actions/tenantAction";
import { ITenant } from "redux/reducers/tenantReducer";
import { ThunkDispatch } from "redux-thunk";
import { doLogout, IAuthLoginAction } from "redux/actions/AuthAction";
import { IAppState, store } from "redux/store/Store";

const basePath = "tenantconfigurations";

interface IProps {
  setActiveTenant: (activeTenant: ITenant) => Promise<IActiveTenantAction>;
}

interface IStateProps {
  activeTenant: ITenant;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerLoading : {
      margin: '0px auto',
      height: '100vh'
    }
  })
)

function Splash(props: IProps & IStateProps) {
  const classes = useStyles();
  const { setActiveTenant } = props
  const [urlLocation] = useState(window.location.host.toString());

  useEffect(() => {
    if ((urlLocation.indexOf('localhost') === -1) && urlLocation !== "dev.app.exagomarkets.com" && urlLocation !== "qa.exago.com" && urlLocation !== "app.exago.com" && urlLocation !== "dev.exago.app") {
      clearLocalStorage(store.dispatch, true)
      getTenantByEndpoint()
    }else {
      clearLocalStorage(store.dispatch, true)
      history.push('/log-in')
    }

    // eslint-disable-next-line
  }, [])

  function clearLocalStorage(dispatch: ThunkDispatch<any, any, IAuthLoginAction>, is_unauthorized?: boolean) {
		dispatch(doLogout(is_unauthorized));
	}

  function getTenantByEndpoint() {
    api_general.get(`${basePath}/endpoint?endpoint=${urlLocation}`)
      .then(response => {
        setActiveTenant(response.data)
        if (response.data.hasSSO) {
          window.location.href = response.data.urlSSO;
          return;
        }else {
          history.push('/log-in')
        }
      })
  }

  return (
    <Box height="100%" display="flex" className={classes.centerLoading}>
      <Box m='auto'>
        <CircularProgress size={60} className="icon" />
      </Box>
    </Box>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IAuthLoginAction>): IProps => {
  return {
    setActiveTenant: (activeTenant: ITenant) => dispatch(setActiveTenant(activeTenant)),
  };
};


const mapStateToProps = (store: IAppState, ownProps: any): IStateProps => {
  return {
    activeTenant: store.tenantState.activeTenant,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Splash);