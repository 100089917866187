import history from "routes/history";
import { connect } from "react-redux";
import { api_general } from "config/api";
import { ThunkDispatch } from "redux-thunk";
import { ArrowForward } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { IAppState, store } from "redux/store/Store";
import { ITenant } from "redux/reducers/tenantReducer";
import { IAuthUser } from "redux/reducers/authReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Resource, { resource } from "components/Resources/Resource";
import PasswordInputMui from "components/PasswordInput/PasswordInputMui";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import {
  doEnviroments,
  doLogin,
  doLoginSSO,
  IAuthLoginAction,
  IAuthLoginEnviromentsAction,
} from "redux/actions/AuthAction";
import {
  IActiveTenantAction,
  setActiveTenant,
} from "redux/actions/tenantAction";
import {
  getProfileData,
  IProfileGetDataAction,
} from "redux/actions/ProfileActions";
import {
  setCultureAndResources,
  getResourcesDefault,
  ICultureSetCulture,
} from "redux/actions/CultureActions";

interface IProps {
  getResourcesDefault: () => Promise<ICultureSetCulture>;
  doEnviroment: (
    email: string,
    password: string,
    captcha: string
  ) => Promise<IAuthLoginEnviromentsAction>;
  doLogin: (
    environmentType: number,
    tenantId: number,
    username: string,
    password: string,
    culture: object,
    captcha: string
  ) => Promise<IAuthLoginAction>;
  doLoginSSO: (response: IAuthUser) => Promise<IAuthLoginAction>;
  getProfileData: () => Promise<IProfileGetDataAction>;
  setActiveTenant: (activeTenant: ITenant) => Promise<IActiveTenantAction>;
  setCultureAndResources: (cultureTag: string) => Promise<any>;
}

interface IStateProps {
  cultureState: ICultureState;
  token: string;
  email: string;
}

interface IMatch {
  email: string;
  token: string;
}

interface ILocation {
  email: string;
}

interface IState {
  password: string;
  confirmPassword: string;
}

function NewUser(props: IStateProps & IState & IProps) {
  const { cultureState, token, email, doEnviroment, setActiveTenant } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();
  let captcha: string = "";
  const [tenant, setTenant] = useState<ITenant>({} as ITenant);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [, setPasswordError] = useState(false);
  const [, setCPasswordError] = useState(false);

  //The password must have numbers, letters, capital letters, special characters and at least 8 characters.
  const passwordRule = !(
    password.match(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[*!@#%&])(?=.{8,})"
    ) !== null
  );

  function setCulture() {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store.dispatch;
    dispatch(getResourcesDefault());
  }

  useEffect(() => {
    cultureState?.culture?.resources?.length === 0 && setCulture();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function passwordChange(e: any) {
    setPassword(e);
    setMsgError("");

    if (!e) {
      setPasswordError(false);
    }
  }

  function confirmPasswordChange(e: any) {
    setConfirmPassword(e);
    setMsgError("");

    if (!e) {
      setCPasswordError(false);
    }
  }

  useEffect(() => {
    if (passwordRule) {
      setMsgError(resource(cultureState, "PageLogin::PasswordRule"));
      setPasswordError(true);
      setLoadingSubmit(false);
      return;
    }
    if (password !== confirmPassword) {
      setMsgError(
        resource(cultureState, "PageLogin::WrongPassword_EnterTheSame")
      );
      setCPasswordError(true);
      setLoadingSubmit(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, confirmPassword]);

  async function submitPassword(e: any) {
    e.preventDefault();
    setLoadingSubmit(true);

    executeRecaptcha &&
      (await executeRecaptcha("Login").then((response) => {
        captcha = response;
      }));

    const atSignIndex = email.indexOf("@");
    const dotIndex = email.indexOf(".", atSignIndex);

    if (atSignIndex === -1 || dotIndex === -1) {
      setMsgError(resource(cultureState, "PageLogin::InvalidEmail"));
      setLoadingSubmit(false);
    }

    if (passwordRule) {
      setMsgError(resource(cultureState, "PageLogin::PasswordRule"));
      setPasswordError(true);
      setLoadingSubmit(false);
      return;
    }

    if (password !== confirmPassword) {
      setMsgError(
        resource(cultureState, "PageLogin::WrongPassword_EnterTheSame")
      );
      setCPasswordError(true);
      setLoadingSubmit(false);
      return;
    } else {
      let params = {
        email,
        password,
        tokenInviteId: token,
      };
      try {
        await api_general
          .post("accounts/password/define", params)
          .then((res) => {
            if (res.status !== 200) {
              setLoadingSubmit(false);
              return;
            } else {
              doEnviroment(email, password, captcha).then((res) => {
                setTenant(res.activeTenant[0]);
              });
              setLoadingSubmit(false);
            }
          });
      } catch (err) { 
        setMsgError(
          resource(
            cultureState,
            err?.error?.response?.data?.length > 0
              ? err?.error?.response?.data[0]?.value?.replace(".", "::")
              : "PageLogin::UnexpectedError"
          )
        );
        setLoadingSubmit(false);
      }
      setCPasswordError(false);
    }
  }

  useEffect(() => {
    const loginUser = async () => {
      if (!tenant.hasSSO) {
        executeRecaptcha &&
          (await executeRecaptcha("Login").then((response) => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            captcha = response;
          }));

        if (email && password) {
          props
            ?.doLogin(
              tenant?.environmentType,
              tenant?.tenantId,
              email,
              password,
              cultureState,
              captcha
            )
            .then(
              (response) => {
                //Check if it´s logged with success
                // if (!api_v2.defaults.headers.common["Authorization"]){
                //   setLoadingSubmit(false)
                // }else {
                props?.getProfileData().then(() => {
                  setLoadingSubmit(false);
                  if (
                    response?.auth?.allowCreateWorkspace &&
                    response?.auth?.hasWorkspaceConfigured === false
                  ) {
                    history.push("/setenvironment");
                  } else if (response?.auth?.isProfileSetted === false) {
                    history.push("/setprofile");
                  } else {
                    history.push("/");
                  }
                });
                // }
              },
              (err) => {
                setMsgError(
                  resource(
                    cultureState,
                    err?.error?.response?.data?.length > 0
                      ? err?.error?.response?.data[0]?.value?.replace(".", "::")
                      : "PageLogin::UnexpectedError"
                  )
                );
                setLoadingSubmit(false);
              }
            );
        }
      }
    };
    setActiveTenant(tenant);
    loginUser();
  }, [tenant]);

  return (
    <>
      {token ? (
        <form name="form" onSubmit={submitPassword}>
          {cultureState?.statusCultureDefault === "SUCCEEDED" ? (
            <>
              <Box pt={4}>
                <Box fontSize="1.8rem" fontWeight="bold" component="p">
                  <Resource tag="PageLogin::Welcome" />
                </Box>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  <Resource tag="PageLogin::DefineYourPassword" />
                </Typography>
              </Box>
              <Box mt={3} mb={3}>
                <PasswordInputMui
                  newUser
                  password={password}
                  showPassword={false}
                  handler={passwordChange}
                />
              </Box>
              <Box mb={9}>
                <PasswordInputMui
                  newUser
                  confirmP
                  password={confirmPassword}
                  showPassword={false}
                  handler={confirmPasswordChange}
                />
                {msgError && (
                  <Box marginTop={2}>
                    <Typography color="error">{msgError}</Typography>
                  </Box>
                )}
              </Box>
              <Box textAlign="end">
                <Button
                  type="submit"
                  variant={!loadingSubmit ? "contained" : "outlined"}
                  color="primary"
                  disabled={
                    password !== confirmPassword ||
                    !password ||
                    !confirmPassword ||
                    passwordRule
                  }
                  endIcon={
                    !loadingSubmit ? (
                      <ArrowForward className="icon icon-arrow" />
                    ) : (
                      <CircularProgress size={22} className="icon" />
                    )
                  }
                >
                  <Resource tag="PageLogin::Next" />
                </Button>
              </Box>
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="500px"
            >
              <CircularProgress size={64} />
            </Box>
          )}
        </form>
      ) : (
        <Redirect to="/log-in" />
      )}
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, IAuthLoginAction>
): IProps => {
  return {
    getResourcesDefault: () => dispatch(getResourcesDefault()),
    doEnviroment: (email: string, password: string, captcha: string) =>
      dispatch(doEnviroments(email, password, captcha)),
    doLogin: (
      environmentType: number,
      tenantId: number,
      email: string,
      password: string,
      culture: object,
      captcha: string
    ) =>
      dispatch(
        doLogin(environmentType, tenantId, email, password, culture, captcha)
      ),
    setActiveTenant: (activeTenant: ITenant) =>
      dispatch(setActiveTenant(activeTenant)),
    doLoginSSO: (response: IAuthUser) => dispatch(doLoginSSO(response)),
    getProfileData: () => dispatch(getProfileData()),
    setCultureAndResources: (cultureTag: string) =>
      dispatch(setCultureAndResources(cultureTag)),
  };
};

const mapStateToProps = (
  store: IAppState,
  ownProps: RouteComponentProps<IMatch, any, ILocation>
): IStateProps => {
  return {
    cultureState: store.cultureState,
    token: ownProps.match.params.token,
    email: ownProps?.location?.state?.email ?? ownProps?.match?.params?.email,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);
