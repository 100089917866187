import "./CreateIdea.css";
import IdeaForm from "./IdeaForm";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import IdeaFormV3 from "./IdeaFormV3";
import reactNewApp from "utils/reactNewApp";
import { ThunkDispatch } from "redux-thunk";
import IdeaFormSkeleton from "./IdeaFormSkeleton";
import React, { useEffect, useState } from "react";
import { IAppState } from "../../../redux/store/Store";
import Resource from "../../../components/Resources/Resource";
import { getAllUsers } from "../../../redux/actions/UsersAction";
import { IApplication } from "../../../redux/reducers/tenantReducer";
import { ICultureState } from "../../../redux/reducers/cultureReducer";
import { IUsers, IUsersState } from "../../../redux/reducers/usersReducers";
import { IChallengeState } from "redux/reducers/challenges/challengeReducer";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import {
  IIdeaDetails,
  IIdeasSimilares,
  IResultDrafts,
} from "redux/types/ideas";
import {
  IChallengeList,
  IChallengeListItem,
  IIdeaForm,
  IIdeaFormV3,
} from "redux/types/challenges";
import {
  getAllIdeaDetails,
  putDraftUpdate,
  IDraftPutAction,
  IIdeasDetailGetAllAction,
  IIdeasSimilaresGetAllSucceededAction,
  postIdeasSimilares,
} from "redux/actions/ideas/IdeaAction";
import {
  getIdeasForm,
  getIdeasFormV3,
  IChallengeGetIdeaFormAction,
} from "../../../redux/actions/ChallengeActions";

interface IDispatchProps {
  getAllUsers: (options: object) => Promise<IUsersState>;
  getIdeasForm: (options: object) => Promise<IChallengeGetIdeaFormAction>;
  getIdeasFormV3: (params: object) => Promise<IChallengeState>;
  putDraftUpdate: (params: object, culture: ICultureState) => Promise<IDraftPutAction>;
  getAllIdeaDetails: (params: object) => Promise<IIdeasDetailGetAllAction>;
  postIdeasSimilares: (
    options: object,
    culture: ICultureState
  ) => Promise<IIdeasSimilaresGetAllSucceededAction>;
}

interface IProps {
  workspaceId: string;
  ideaFormV3: IIdeaFormV3[];
  ideasForm: IIdeaForm;
  application: IApplication;
  culture: ICultureState;
  userList: IUsers[];
  challengeList: IChallengeList;
  ideaChallenge: IChallengeListItem;
  draftId: string;
  draft: IResultDrafts;
  ideaDetails: IIdeaDetails;
  applicationId: any;
}

function EditDraft(props: IProps & IDispatchProps) {
  const {
    workspaceId,
    getIdeasFormV3,
    getIdeasForm,
    draft,
    getAllUsers,
    ideaFormV3,
    ideasForm,
    getAllIdeaDetails,
    draftId,
    ideaDetails,
    postIdeasSimilares,
    applicationId,
    culture
  } = props;

  const [load, setLoad] = useState(true);
  const [challenge, setChallenge] = useState<IChallengeListItem>(
    props.ideaChallenge
  );
  const [isConfirmOpen, setIsConfirm] = useState(false);
  const [ideasSimilares, setIdeasSimilares] = useState<IIdeasSimilares>();

  useEffect(() => {
    if (reactNewApp && draft) {
      const [challenge] = props.challengeList?.result?.filter(
        (challenge) => challenge?.challengeId === draft?.challengeId
      );
      const options = {
        ideaId: draftId,
        challengeId: draft?.challengeId,
        culture: props?.culture,
        workspaceId: workspaceId,
      };
      setChallenge(challenge);
      getIdeasFormV3(options);
      getAllIdeaDetails({ ideaDetailId: draftId }).then(() => setLoad(false));
      // getIdeaDetail();
    } else if (!reactNewApp && draft) {
      const [challenge] = props.challengeList?.result?.filter(
        (challenge) => challenge?.challengeId === draft?.challengeId
      );
      const options = {
        ideaId: draftId,
        challengeId: draft?.challengeId,
        culture: props.culture,
      };
      setChallenge(challenge);
      getIdeasForm(options);
      getAllIdeaDetails({ ideaDetailId: draftId }).then(() => setLoad(false));
      // getIdeaDetail();
    }
    getAllUsers({ q: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCancel() {
    setIsConfirm(true);
  }

  function updateDraft(params: any) {
    const { putDraftUpdate } = props;

    params.append("ideaId", props.draftId);
    params.append("ChallengeId", draft?.challengeId);
    if (!reactNewApp) {
      params.append("InitialStateId", Object.keys(ideasForm.initialIdeas)[0]);
    }else{
      params.append("WorkspaceId", workspaceId);
    }

    putDraftUpdate(params, culture).then(() => {
      window.history.back();
    });
  }

  function getSimilarIdeas(e: any) {
    postIdeasSimilares({
      ...e,
      challengeId: draft && draft?.challengeId,
      workspaceId,
      applicationId,
      pageNumber: 1,
      pageSize: 6,
    }, culture).then((response) => {
      setIdeasSimilares(response.ideasSimilares);
    });
  }

  return (
    <>
      {!load ? (
        <>
          {reactNewApp ? (
            <IdeaFormV3
              challenge={challenge}
              setChallenge={setChallenge}
              culture={props.culture}
              getIdeasFormV3={getIdeasFormV3}
              handleCancel={handleCancel}
              handleSubmitAction={updateDraft}
              ideaFormV3={ideaFormV3}
              mode="draft"
              userList={props.userList}
              ideaDetail={ideaDetails}
              getIdeaSimilar={getSimilarIdeas}
              ideasSimilares={ideasSimilares}
            />
          ) : (
            <IdeaForm
              challenge={challenge}
              setChallenge={setChallenge}
              culture={props.culture}
              getIdeasForm={getIdeasForm}
              handleCancel={handleCancel}
              handleSubmitAction={updateDraft}
              ideaForm={ideasForm}
              mode="draft"
              userList={props.userList}
              ideaDetail={ideaDetails}
              getIdeaSimilar={getSimilarIdeas}
              ideasSimilares={ideasSimilares}
            />
          )}
        </>
      ) : (
        <IdeaFormSkeleton />
      )}
      <PopUpConfirmation
        closeButton={() => setIsConfirm(false)}
        sendButton={() => window.history.back()}
        message={<Resource tag="PageEditIdea::ExitWithoutSaving" />}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirm}
      />
    </>
  );
}

const mapStateToProps = (store: IAppState, ownProps: any) => {
  const ideaId = ownProps?.match?.params?.ideaId;
  return {
    workspaceId: store.authState.auth.workspaceId,
    ideaFormV3: store.challengeState.ideaFormV3,
    ideasForm: store.challengeState.ideasForm,
    application: store.tenantState.application,
    culture: store.cultureState,
    userList: store.usersState.users,
    challengeList: store.challengeState.challengeList,
    draftId: ideaId,
    draft: store.ideasState?.drafts?.result?.filter(
      (idea) => idea.ideaId === ownProps?.match?.params?.ideaId
    )[0],
    ideaDetails: store.ideasState.ideaDetails,
    applicationId: store.authState.auth.applicationId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllUsers: (options: object) => dispatch(getAllUsers(options)),
    getIdeasFormV3: (params: object) => dispatch(getIdeasFormV3(params)),
    getIdeasForm: (parameters: object) => dispatch(getIdeasForm(parameters)),
    putDraftUpdate: (params: object, culture: ICultureState) => dispatch(putDraftUpdate(params,culture)),
    getAllIdeaDetails: (params: object) => dispatch(getAllIdeaDetails(params)),
    postIdeasSimilares: (options, culture: ICultureState) => dispatch(postIdeasSimilares(options,culture)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDraft);
