import React from 'react';
import ChartComponent, { Chart } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import Resource from '../Resources/Resource'
import './MultiColorLine.css'

/* Example:
   <MultiColorLine title="Evaluation of my investiments" data={[0, 100, 50, -100, 200, 300, -250]}/>
 */

export default class MultiColorLine extends React.Component {

    constructor(props) {
        super(props);
        this.chartReference = React.createRef();

        //adding custom chart type
        Chart.defaults.multicolorLine = Chart.defaults.line;
        Chart.controllers.multicolorLine = Chart.controllers.line.extend({
            draw: function (ease) {
                var
                    startIndex = 0,
                    meta = this.getMeta(),
                    points = meta.data || [],
                    colors = this.getDataset().colors,
                    area = this.chart.chartArea,
                    originalDatasets = meta.dataset._children
                        .filter(function (data) {
                            return !isNaN(data._view.y);
                        });

                function setColor(newColor, meta) {
                    meta.dataset._view.borderColor = newColor;
                }

                if (!colors) {
                    Chart.controllers.line.prototype.draw.call(this, ease);
                    return;
                }

                for (var i = 2; i <= colors.length; i++) {
                    if (colors[i - 1] !== colors[i]) {
                        setColor(colors[i - 1], meta);
                        meta.dataset._children = originalDatasets.slice(startIndex, i);
                        meta.dataset.draw();
                        startIndex = i - 1;
                    }
                }

                meta.dataset._children = originalDatasets.slice(startIndex);
                meta.dataset.draw();
                meta.dataset._children = originalDatasets;

                points.forEach(function (point) {
                    point.draw(area);
                });
            }
        });
    }

    datasetKeyProvider = ()=>{ return Math.random(); }

    render() {
        const data = this.props.data != null ? this.props.data: [0];

        //Atual Points
        let atualPoints = data[data.length - 1];
        let classPoints = 'MultiColorLinePoints ';

        if (data[data.length - 1] >= 0) {
            atualPoints = '▲' + atualPoints;
            classPoints += ' MultiColorLinePositive';
        } else {
            atualPoints = '▼' + atualPoints;
            classPoints += ' MultiColorLineNegative';
        }

        //Labels
        const labels = new Array(data.length);
        labels.fill('');

        //Zero Axis
        const zeroData = new Array(data.length);
        const zeroColor = new Array(data.length);
        zeroData.fill(0);
        zeroColor.fill('lightgray');

        //Data Axis
        const dataColor = ['']; //first color is not important
        for (let i = 1; i <= data.length; i++) {
            if (data[i] < data[i - 1]) {
                dataColor.push('red');
            } else {
                dataColor.push('green');
            }
        }

        const dataChart = {
            type: 'multicolorLine',
            labels: labels,
            datasets: [
                {   //Data axis
                    borderColor: 'transparent',
                    data: data,
                    colors: dataColor,
                    backgroundColor: 'transparent'
                },
                {   //Zero axis
                    borderColor: 'transparent',
                    data: zeroData,
                    colors: zeroColor,
                    backgroundColor: 'transparent',
                    borderWidth: 1 
                },
            ]
        };

        // Configuration options go here
        const options = {
            layout: {
                padding: {
                    left: 50,
                    right: 50,
                    top: 50,
                    bottom: 50
                }
            },
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                        drawBorder: false,
                        drawOnChartArea: false
                    },
                    ticks: {
                        beginAtZero: false,
                        display: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display: false,
                        drawOnChartArea: false
                    },
                    ticks: {
                        beginAtZero: false,
                        display: false
                    }
                }]
            }
        }

        return (
            <Grid item xs={12} sm={12} md={12} lg={12} className="MultiColorLine">
                {this.props.data.length !== 0 ?
                    <>
                    <div className="MultiColorLineTitle">{this.props.title}</div>
                    <div className={classPoints}>
                        {atualPoints} <Resource tag={"PageProfile::PageProfile_Points"} />
                    </div>
                    <ChartComponent
                        {...this.props}
                        ref={this.chartReference}
                        datasetKeyProvider={this.datasetKeyProvider}
                        type='multicolorLine'
                        data={dataChart} options={options}
                    />
                    </>
                    :
                    <>
                    <div>
                        <Resource tag={"PageWallet::NoDataAvailable"} args={['']} />
                    </div>
                    </>
                }
            </Grid>
        );
    }
};
