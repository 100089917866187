import { toast } from 'react-toastify';
// Import redux types
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { resource } from '../../../components/Resources/Resource';
import { api_v2 } from "../../../config/api";

// Import Character Typing
import { IAddValueState } from '../../reducers/ideas/AddValueReducer';

const basePath = "ideas";

// Create Action Constants
export enum AddValueActionTypes {
  ADD_VALUE_LOADING = 'ADD_VALUE_LOADING',
  ADD_VALUE_SUCCEEDED = 'ADD_VALUE_SUCCEEDED',
  ADD_VALUE_FAILED = 'ADD_VALUE_FAILED',
}

//Create Action Statusses
export enum AddValueActionStatus {
  ADD_VALUE_LOADING = 'LOADING',
  ADD_VALUE_SUCCEEDED = 'SUCCEEDED',
  ADD_VALUE_FAILED = 'FAILED',
}

//------------*Get All Actions*---------------
export interface IAddValueLoadingAction {
  type: AddValueActionTypes.ADD_VALUE_LOADING;
  status: string;
}

export interface IAddValueSucceededAction {
  type: AddValueActionTypes.ADD_VALUE_SUCCEEDED;
  status: string;
}

export interface IAddValueFailedAction {
  type: AddValueActionTypes.ADD_VALUE_FAILED;
  status: string;
  error: null;
}

/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type AddValueActions =
  | IAddValueLoadingAction
  | IAddValueSucceededAction
  | IAddValueFailedAction

/* Get All idea
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postAddValue: ActionCreator<
  ThunkAction<Promise<any>, IAddValueState, null, IAddValueSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type:AddValueActionTypes.ADD_VALUE_LOADING,
      status:AddValueActionStatus.ADD_VALUE_LOADING
    })

    await api_v2.post(`${basePath}/${options?.ideaId}/comments/addvalue`, options?.params).then( () =>{
      toast.success(resource(options?.culture, 'PageDetailedIdea::InvestmentArea_MessageConfirmation',))
      dispatch({
        type: AddValueActionTypes.ADD_VALUE_SUCCEEDED,
        status: AddValueActionStatus.ADD_VALUE_SUCCEEDED,
      });
      
    }, error =>{
      dispatch({
        type: AddValueActionTypes.ADD_VALUE_FAILED,
        status: AddValueActionStatus.ADD_VALUE_FAILED,
        error: error,
      });
    })
      // treatError(options?.culture?.culture, 'Idea', err.toString());
  };
};

