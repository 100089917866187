import React from 'react'
import { Grid } from '@material-ui/core'

interface IDynamicSizeGridItem {
    children:any,
    size:number
  }

function DynamicGrid(props:IDynamicSizeGridItem){
    switch(props.size){
      case 4:
        return (
          <>
            <Grid item lg={4} xs={12}>
              {props.children}
            </Grid>
          </>
        )
      case 5:
        return (
          <>
            <Grid item lg={5} xs={12}>
              {props.children}
            </Grid>
          </>
        )
      case 6:
        return (
          <>
            <Grid item lg={6} xs={12}>
              {props.children}
            </Grid>
          </>
        )
      case 7:
        return (
          <>
            <Grid item lg={7} xs={12}>
              {props.children}
            </Grid>
          </>
        )
      case 8:
        return (
          <>
            <Grid item lg={8} xs={12}>
              {props.children}
            </Grid>
          </>
        )
      default:
        return (
          <>
          </>
        )
    }
  }

  export default DynamicGrid;