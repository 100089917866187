import { toast } from "react-toastify";
import sanitizeHtml from "sanitize-html";
import { ThunkAction } from "redux-thunk";
import reactNewApp from "utils/reactNewApp";
import { treatError } from "utils/treatError";
import { ActionCreator, Dispatch } from "redux";
import { api_v2, api_general } from "config/api";
import { resource } from "components/Resources/Resource";
import { securityTextEditor } from "utils/securityTextEditor";
import { FeedActionTypes } from "redux/actions/FeedAction";
import { IIdeasState } from "redux/reducers/ideas/ideaReducer";
import {
  IIdeas,
  IIdeasCollaborative,
  IIdeaDetails,
  IIdeasComments,
  IDrafts,
  IResultDrafts,
  IIdeasInProgress,
  IIdeasRejected,
  IIdeasImplemented,
  IIdeasSimilares,
} from "redux/types/ideas";

const basePath = "ideas";

export enum IdeasActionTypes {
  IDEAS = "IDEAS",
  IDEA_DETAILS = "IDEA_DETAILS",
  PORTFOLIO = "PORTFOLIO",
  COLLABORATIVE = "COLLABORATIVE",
  IDEAS_COMMENTS_LOADING = "IDEAS_COMMENTS_LOADING",
  IDEAS_COMMENTS_SUCCESS = "IDEAS_COMMENTS_SUCCESS",
  IDEAS_COMMENTS_FAILED = "IDEAS_COMMENTS_FAILED",

  IDEAS_PUT_COMMENTS_LOADING = "IDEAS_PUT_COMMENTS_LOADING",
  IDEAS_PUT_COMMENTS_SUCCESS = "IDEAS_PUT_COMMENTS_SUCCESS",
  IDEAS_PUT_COMMENTS_FAILED = "IDEAS_PUT_COMMENTS_FAILED",

  IDEAS_DELETE_COMMENTS_LOADING = "IDEAS_DELETE_COMMENTS_LOADING",
  IDEAS_DELETE_COMMENTS_SUCCESS = "IDEAS_DELETE_COMMENTS_SUCCESS",
  IDEAS_DELETE_COMMENTS_FAILED = "IDEAS_DELETE_COMMENTS_FAILED",

  IDEAS_DELETE_REPLY_COMMENTS_LOADING = "IDEAS_DELETE_REPLY_COMMENTS_LOADING",
  IDEAS_DELETE_REPLY_COMMENTS_SUCCESS = "IDEAS_DELETE_REPLY_COMMENTS_SUCCESS",
  IDEAS_DELETE_REPLY_COMMENTS_FAILED = "IDEAS_DELETE_REPLY_COMMENTS_FAILED",

  PUTIDEA = "PUTIDEA",
  GETDRAFTS = "GETDRAFTS",
  POSTDRAFT = "POSTDRAFT",
  PUTDRAFT = "PUTDRAFT",
  ALL_IDEAS_SUCCEEDED = "ALL_IDEAS_SUCCEEDED",
  ALL_IDEAS_FAILED = "ALL_IDEAS_FAILED",

  IDEAS_INPROGRESS_LOADING = "IDEAS_INPROGRESS_LOADING",
  IDEAS_INPROGRESS_SUCCESS = "IDEAS_INPROGRESS_SUCCESS",
  IDEAS_INPROGRESS_FAILED = "IDEAS_INPROGRESS_FAILED",

  IDEAS_REJECTED_LOADING = "IDEAS_REJECTED_LOADING",
  IDEAS_REJECTED_SUCCESS = "IDEAS_REJECTED_SUCCESS",
  IDEAS_REJECTED_FAILED = "IDEAS_REJECTED_FAILED",

  IDEAS_IMPLEMENTED_LOADING = "IDEAS_IMPLEMENTED_LOADING",
  IDEAS_IMPLEMENTED_SUCCESS = "IDEAS_IMPLEMENTED_SUCCESS",
  IDEAS_IMPLEMENTED_FAILED = "IDEAS_IMPLEMENTED_FAILED",

  IDEAS_SIMILARES_LOADING = "IDEAS_SIMILARES_LOADING",
  IDEAS_SIMILARES_SUCCESS = "IDEAS_SIMILARES_SUCCESS",
  IDEAS_SIMILARES_FAILED = "IDEAS_SIMILARES_FAILED",
}

export enum IdeasStatusTypes {
  IDEAS_LOADING = "LOADING",
  IDEAS_SUCCESS = "SUCCESS",
  IDEAS_FAILED = "FAILED",
}

// Interface for Get All on ideas
export interface IIdeasGetAllAction {
  type: IdeasActionTypes.IDEAS;
  ideas: IIdeas;
}

// GET ALL IDEAS IN PROGRESS
export interface IIdeasInProgressGetAllLoadingAction {
  type: IdeasActionTypes.IDEAS_INPROGRESS_LOADING;
  status: string;
}
export interface IIdeasInProgressGetAllSucceededAction {
  type: IdeasActionTypes.IDEAS_INPROGRESS_SUCCESS;
  ideasInProgress: IIdeasInProgress;
  status: string;
  isInfinite: boolean;
}
export interface IIdeasInProgressGetAllFailedAction {
  type: IdeasActionTypes.IDEAS_INPROGRESS_FAILED;
  status: string;
  error: null;
}

// GET ALL IDEAS REJECTED
export interface IIdeasRejectedGetAllLoadingAction {
  type: IdeasActionTypes.IDEAS_REJECTED_LOADING;
  status: string;
}
export interface IIdeasRejectedGetAllSucceededAction {
  type: IdeasActionTypes.IDEAS_REJECTED_SUCCESS;
  ideasRejected: IIdeasRejected;
  status: string;
  isInfinite: boolean;
}
export interface IIdeasRejectedGetAllFailedAction {
  type: IdeasActionTypes.IDEAS_REJECTED_FAILED;
  status: string;
  error: null;
}

// GET ALL IDEAS IMPLEMENTED
export interface IIdeasImplementedGetAllLoadingAction {
  type: IdeasActionTypes.IDEAS_IMPLEMENTED_LOADING;
  status: string;
}
export interface IIdeasImplementedGetAllSucceededAction {
  type: IdeasActionTypes.IDEAS_IMPLEMENTED_SUCCESS;
  ideasImplemented: IIdeasImplemented;
  status: string;
  isInfinite: boolean;
}
export interface IIdeasImplementedGetAllFailedAction {
  type: IdeasActionTypes.IDEAS_IMPLEMENTED_FAILED;
  status: string;
  error: null;
}

// Interface for Post on ideas
export interface IIdeasPostAction {
  type: IdeasActionTypes.IDEAS;
  ideas: IIdeas;
}

// Interface for Get All on ideas details
export interface IIdeasDetailGetAllAction {
  type: IdeasActionTypes.IDEA_DETAILS;
  ideaDetails: IIdeaDetails;
}

// Interface for Get All ideas in portfolio
export interface IIdeasGetAllPortfolioAction {
  type: IdeasActionTypes.PORTFOLIO;
  ideasPortfolio: IIdeas[];
}

// Interface for Get All on ideas collaborative
export interface IIdeasGetAllCollaborativeAction {
  type: IdeasActionTypes.COLLABORATIVE;
  ideasCollaborative: IIdeasCollaborative;
}

// Interface for Get All comments on ideas
export interface IIdeasCommentsLoadAction {
  type: IdeasActionTypes.IDEAS_COMMENTS_LOADING;
  statusComments: string;
}
export interface IIdeasCommentsSuccessAction {
  type: IdeasActionTypes.IDEAS_COMMENTS_SUCCESS;
  ideasComments: IIdeasComments[];
  statusComments: string;
}
export interface IIdeasCommentsFailAction {
  type: IdeasActionTypes.IDEAS_COMMENTS_FAILED;
  statusComments: string;
}

export interface IIdeasCommentsPostAction {
  type: IdeasActionTypes.IDEAS_COMMENTS_SUCCESS;
  ideasPostComments: [];
}

export interface IIdeasPutCommentsLoadingAction {
  type: IdeasActionTypes.IDEAS_PUT_COMMENTS_LOADING;
  statusComments: string;
}
export interface IIdeasPutCommentsSuccessAction {
  type: IdeasActionTypes.IDEAS_PUT_COMMENTS_SUCCESS;
  ideasPutComments: [];
  statusComments: string;
}
export interface IIdeasPutCommentsFailAction {
  type: IdeasActionTypes.IDEAS_PUT_COMMENTS_FAILED;
  statusComments: string;
  error: string;
}

export interface IIdeasDeleteCommentsLoadingAction {
  type: IdeasActionTypes.IDEAS_DELETE_COMMENTS_LOADING;
  statusComments: string;
}
export interface IIdeasDeleteCommentsSuccessAction {
  type: IdeasActionTypes.IDEAS_DELETE_COMMENTS_SUCCESS;
  statusComments: string;
}
export interface IIdeasDeleteCommentsFailAction {
  type: IdeasActionTypes.IDEAS_DELETE_COMMENTS_FAILED;
  statusComments: string;
  error: string;
}

export interface IIdeasDeleteReplyCommentsLoadingAction {
  type: IdeasActionTypes.IDEAS_DELETE_REPLY_COMMENTS_LOADING;
  statusComments: string;
}
export interface IIdeasDeleteReplyCommentsSuccessAction {
  type: IdeasActionTypes.IDEAS_DELETE_REPLY_COMMENTS_SUCCESS;
  statusComments: string;
}
export interface IIdeasDeleteReplyCommentsFailAction {
  type: IdeasActionTypes.IDEAS_DELETE_REPLY_COMMENTS_FAILED;
  statusComments: string;
  error: string;
}

// Interface for Post comments drafts ideas
export interface IDraftsGetAction {
  type: IdeasActionTypes.GETDRAFTS;
  drafts: IDrafts;
}

export interface IDraftPostAction {
  type: IdeasActionTypes.POSTDRAFT;
}

export interface IDraftPutAction {
  type: IdeasActionTypes.PUTDRAFT;
  data: IResultDrafts;
}

export interface IdeaPutAction {
  type: IdeasActionTypes.PUTIDEA;
}

// GET ALL IDEAS SIMILARES
export interface IIdeasSimilaresGetAllLoadingAction {
  type: IdeasActionTypes.IDEAS_SIMILARES_LOADING;
  status: string;
}
export interface IIdeasSimilaresGetAllSucceededAction {
  type: IdeasActionTypes.IDEAS_SIMILARES_SUCCESS;
  ideasSimilares: IIdeasSimilares;
  status: string;
  isInfinite: boolean;
}
export interface IIdeasSimilaresGetAllFailedAction {
  type: IdeasActionTypes.IDEAS_SIMILARES_FAILED;
  status: string;
  error: null;
}

export type IdeasActions =
  | IIdeasGetAllAction
  | IIdeasPostAction
  | IIdeasDetailGetAllAction
  | IIdeasGetAllPortfolioAction
  | IIdeasGetAllCollaborativeAction
  | IIdeasCommentsLoadAction
  | IIdeasCommentsSuccessAction
  | IIdeasCommentsFailAction
  | IDraftsGetAction
  | IDraftPostAction
  | IDraftPutAction
  | IdeaPutAction
  | IIdeasInProgressGetAllLoadingAction
  | IIdeasInProgressGetAllSucceededAction
  | IIdeasInProgressGetAllFailedAction
  | IIdeasRejectedGetAllLoadingAction
  | IIdeasRejectedGetAllSucceededAction
  | IIdeasRejectedGetAllFailedAction
  | IIdeasImplementedGetAllLoadingAction
  | IIdeasImplementedGetAllSucceededAction
  | IIdeasImplementedGetAllFailedAction
  | IIdeasSimilaresGetAllLoadingAction
  | IIdeasSimilaresGetAllSucceededAction
  | IIdeasSimilaresGetAllFailedAction
  | IIdeasPutCommentsLoadingAction
  | IIdeasPutCommentsSuccessAction
  | IIdeasPutCommentsFailAction
  | IIdeasDeleteCommentsLoadingAction
  | IIdeasDeleteCommentsSuccessAction
  | IIdeasDeleteCommentsFailAction
  | IIdeasDeleteReplyCommentsLoadingAction
  | IIdeasDeleteReplyCommentsSuccessAction
  | IIdeasDeleteReplyCommentsFailAction;

//get/ideas/implemented
export const getIdeasImplemented: ActionCreator<
  ThunkAction<
    Promise<any>,
    IIdeasState,
    null,
    IIdeasImplementedGetAllSucceededAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: IdeasActionTypes.IDEAS_IMPLEMENTED_LOADING,
        status: IdeasStatusTypes.IDEAS_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("challengeId")) {
        params.push(`challengeId=${options.challengeId}`);
      }
      if (options.hasOwnProperty("q")) {
        params.push(`q=${options.q}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
          .get(`${basePath}/implemented${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideasImplemented: response.data,
              type: IdeasActionTypes.IDEAS_IMPLEMENTED_SUCCESS,
              status: IdeasStatusTypes.IDEAS_SUCCESS,
              isInfinite: isInfinite,
            });
          })
        : await api_v2
          .get(`${basePath}/implemented${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideasImplemented: response.data,
              type: IdeasActionTypes.IDEAS_IMPLEMENTED_SUCCESS,
              status: IdeasStatusTypes.IDEAS_SUCCESS,
              isInfinite: isInfinite,
            });
          });
    } catch (err) {
      dispatch({
        error: err,
        type: IdeasActionTypes.IDEAS_IMPLEMENTED_FAILED,
        status: IdeasStatusTypes.IDEAS_FAILED,
      });

      treatError(options?.culture?.culture, "PageIdea::Header", err);
    }
  };
};

//get/ideas/rejected
export const getIdeasRejected: ActionCreator<
  ThunkAction<
    Promise<any>,
    IIdeasState,
    null,
    IIdeasRejectedGetAllSucceededAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: IdeasActionTypes.IDEAS_REJECTED_LOADING,
        status: IdeasStatusTypes.IDEAS_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("challengeId")) {
        params.push(`challengeId=${options.challengeId}`);
      }
      if (options.hasOwnProperty("q")) {
        params.push(`q=${options.q}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
          .get(`${basePath}/rejected${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideasRejected: response.data,
              type: IdeasActionTypes.IDEAS_REJECTED_SUCCESS,
              status: IdeasStatusTypes.IDEAS_SUCCESS,
              isInfinite: isInfinite,
            });
          })
        : await api_v2
          .get(`${basePath}/rejected${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideasRejected: response.data,
              type: IdeasActionTypes.IDEAS_REJECTED_SUCCESS,
              status: IdeasStatusTypes.IDEAS_SUCCESS,
              isInfinite: isInfinite,
            });
          });
    } catch (err) {
      dispatch({
        error: err,
        type: IdeasActionTypes.IDEAS_REJECTED_FAILED,
        status: IdeasStatusTypes.IDEAS_FAILED,
      });

      treatError(options?.culture?.culture, "PageIdea::Header", err);
    }
  };
};

//get/ideas/inprogress
export const getIdeasInProgress: ActionCreator<
  ThunkAction<
    Promise<any>,
    IIdeasState,
    null,
    IIdeasInProgressGetAllSucceededAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: IdeasActionTypes.IDEAS_INPROGRESS_LOADING,
        status: IdeasStatusTypes.IDEAS_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("challengeId")) {
        params.push(`challengeId=${options.challengeId}`);
      }
      if (options.hasOwnProperty("q")) {
        params.push(`q=${options.q}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
          .get(`${basePath}/inprogress${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideasInProgress: response.data,
              type: IdeasActionTypes.IDEAS_INPROGRESS_SUCCESS,
              status: IdeasStatusTypes.IDEAS_SUCCESS,
              isInfinite: isInfinite,
            });
          })
        : await api_v2
          .get(`${basePath}/inprogress${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideasInProgress: response.data,
              type: IdeasActionTypes.IDEAS_INPROGRESS_SUCCESS,
              status: IdeasStatusTypes.IDEAS_SUCCESS,
              isInfinite: isInfinite,
            });
          });
    } catch (err) {
      dispatch({
        error: err,
        type: IdeasActionTypes.IDEAS_INPROGRESS_FAILED,
        status: IdeasStatusTypes.IDEAS_FAILED,
      });

      treatError(options?.culture?.culture, "PageIdea::Header", err);
    }
  };
};

//get/ideas/incollaboration
export const getIdeasCollaborative: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasGetAllCollaborativeAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
          .get(`${basePath}/incollaboration${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideasCollaborative: response.data,
              type: IdeasActionTypes.COLLABORATIVE,
            });
          })
        : await api_v2
          .get(`${basePath}/incollaboration${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideasCollaborative: response.data,
              type: IdeasActionTypes.COLLABORATIVE,
            });
          });
    } catch (err) {
      treatError(options?.culture?.culture, "PageIdea::Header", err);
    }
  };
};

//post/ideas/similar
export const postIdeasSimilares: ActionCreator<
  ThunkAction<
    Promise<any>,
    IIdeasState,
    null,
    IIdeasSimilaresGetAllSucceededAction
  >
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch({
          type: IdeasActionTypes.IDEAS_SIMILARES_LOADING,
          status: IdeasStatusTypes.IDEAS_LOADING,
        });

        const {
          challengeId,
          workspaceId,
          title,
          description,
          pageNumber,
          pageSize,
          applicationId,
        } = options;

        const params = {
          challengeId,
          workspaceId,
          title,
          description,
          pageNumber,
          pageSize,
          applicationId,
        };

        if (reactNewApp) {
          api_general.post(`${basePath}/similar`, params).then((response) => {
            dispatch({
              type: IdeasActionTypes.IDEAS_SIMILARES_SUCCESS,
              status: IdeasStatusTypes.IDEAS_SUCCESS,
            });
            return resolve({
              response,
              ideasSimilares: response.data,
            });
          });
        } else {
          api_v2.post(`${basePath}/similar`, params).then((response) => {
            dispatch({
              type: IdeasActionTypes.IDEAS_SIMILARES_SUCCESS,
              status: IdeasStatusTypes.IDEAS_SUCCESS,
            });
            return resolve({
              response,
              ideasSimilares: response.data,
            });
          });
        }
      } catch (err) {
        dispatch({
          error: err,
          type: IdeasActionTypes.IDEAS_SIMILARES_FAILED,
          status: IdeasStatusTypes.IDEAS_FAILED,
        });

        treatError(culture, "PageIdea::Header", err);
        return reject(err);
      }
    });
  };
};

//get/ideas
export const getAllIdeas: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("challengeId")) {
        params.push(`challengeId=${options.challengeId}`);
      }
      if (options.hasOwnProperty("stateId")) {
        params.push(`state=${options.stateId}`);
      }
      if (options.hasOwnProperty("titleFilter")) {
        params.push(`titleFilter=${options.titleFilter}`);
      }
      if (options.hasOwnProperty("isHigherToLowerOrder")) {
        params.push(`isHigherToLowerOrder=${options.isHigherToLowerOrder}`);
      }
      if (options.hasOwnProperty("isFollowing")) {
        params.push(`isFollowing=${options.isFollowing}`);
      }
      if (options.hasOwnProperty("isParticipating")) {
        params.push(`isParticipating=${options.isParticipating}`);
      }
      if (options.hasOwnProperty("isTrending")) {
        params.push(`isTrending=${options.isTrending}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      if (reactNewApp) {
        await api_general
          .get(`${basePath}${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideas: response.data,
              type: IdeasActionTypes.IDEAS,
            });
          });
      } else {
        await api_v2.get(`${basePath}${params_qs}`, {}).then((response) => {
          dispatch({
            ideas: response.data,
            type: IdeasActionTypes.IDEAS,
          });
        });
      }
    } catch (err) {
      treatError(options?.culture?.culture, "PageIdea::Header", err);
    }
  };
};

//post/ideas
export const postIdeas: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, any>
> = (params, culture) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      reactNewApp
        ? api_general
          .post(`${basePath}`, params, {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          })
          .then(
            (response) => {
              toast.success(
                resource(
                  culture,
                  "PageIdeaDetail::Alert_TheIdeaWasSuccessfullyCreated"
                )
              );
              return resolve(response.data);
            },
            (error) => {
              treatError(culture, "PageIdea::Header", error);
              return reject(error);
            }
          )
        : api_v2
          .post(`${basePath}`, params, {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          })
          .then(
            (response) => {
              toast.success(
                resource(
                  culture,
                  "PageIdeaDetail::Alert_TheIdeaWasSuccessfullyCreated"
                )
              );
              return resolve(response.data);
            },
            (error) => {
              treatError(culture, "PageIdea::Header", error);
              return reject(error);
            }
          );
    });
  };
};

//get/ideas/{ideaId}
export const getAllIdeaDetails: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasDetailGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      reactNewApp
        ? await api_general
          .get(`${basePath}/${options.ideaDetailId}`, {})
          .then((response) => {
            dispatch({
              ideaDetails: response.data,
              type: IdeasActionTypes.IDEA_DETAILS,
            });
          })
        : await api_v2
          .get(`${basePath}/${options.ideaDetailId}`, {})
          .then((response) => {
            dispatch({
              ideaDetails: response.data,
              type: IdeasActionTypes.IDEA_DETAILS,
            });
          });
    } catch (err) {
      treatError(options?.culture, "PageIdea::Header", err);
    }
  };
};

//put/ideas/{ideaId}
export const putIdeaUpdate: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IdeaPutAction>
> = (params, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      const ideaId = params.get("ideaId");
      params.delete("ideaId");

      reactNewApp
        ? await api_general
          .put(`${basePath}/${ideaId}`, params, {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          })
          .then(async () => {
            dispatch({
              type: IdeasActionTypes.PUTIDEA,
            });
          })
        : await api_v2
          .put(`${basePath}/${ideaId}`, params, {
            headers: { "Content-Type": `multipart/form-data` },
          })
          .then(async () => {
            dispatch({ type: IdeasActionTypes.PUTIDEA });
          });
    } catch (err) {
      treatError(culture, "PageIdea::Header", err);
    }
  };
};

//get/ideas/drafts
export const getDrafts: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
          .get(`${basePath}/drafts${params_qs}`, {})
          .then((response) => {
            dispatch({
              drafts: response.data,
              type: IdeasActionTypes.GETDRAFTS,
            });
          })
        : await api_v2
          .get(`${basePath}/drafts${params_qs}`, {})
          .then((response) => {
            dispatch({
              drafts: response.data,
              type: IdeasActionTypes.GETDRAFTS,
            });
          });
    } catch (err) {
      treatError(options?.culture?.culture, "PageIdea::Header", err);
    }
  };
};

//post/ideas/draft
export const postDraft: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IDraftPostAction>
> = (params, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      let paramsIdeas: any[] = [];
      let params_qs = "";

      if (paramsIdeas.length) {
        params_qs = `?${paramsIdeas.join("&")}`;
      }

      if (reactNewApp) {
        await api_general.post(`${basePath}/draft${params_qs}`, params, {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        });
        dispatch({
          type: IdeasActionTypes.POSTDRAFT,
        });
        toast.success(resource(culture, "PageIdeaDraft::Saved"));
      } else {
        await api_v2.post(`${basePath}/draft${params_qs}`, params, {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        });
        dispatch({
          type: IdeasActionTypes.POSTDRAFT,
        });
        toast.success(resource(culture, "PageIdeaDraft::Saved"));
      }
    } catch (err) {
      treatError(culture, "PageIdea::Header", err);
    }
  };
};

//post/ideas/{ideaId}/evaluation/stars
export const postIdeasStars: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const params = {
        workspaceId: options.workspaceId,
        applicationId: options.applicationId,
        dimensions: options.dimensions,
        comment: sanitizeHtml(options.comment, securityTextEditor),
      };
      if (reactNewApp) {
        await api_general
          .post(`${basePath}/${options.ideaId}/evaluation/stars`, params)
          .then(async () => {
            toast.success(
              resource(
                options.culture,
                "PageIdeaDetail::ReviewRating_StarsEvaluation_Saved"
              )
            );
            const responseStars = await api_general.get(
              `${basePath}/${options.ideaId}`,
              {}
            );
            dispatch({
              ideaDetails: responseStars.data,
              type: IdeasActionTypes.IDEA_DETAILS,
            });
            options.isFeed &&
              dispatch({
                ideaDetails: responseStars.data,
                type: FeedActionTypes.POST_EVALUATION,
              });
          });
      } else {
        await api_v2
          .post(`${basePath}/${options.ideaId}/evaluation/stars`, params)
          .then(async () => {
            toast.success(
              resource(
                options.cultureTag,
                "PageIdeaDetail::ReviewRating_StarsEvaluation_Saved"
              )
            );
            const responseStars = await api_v2.get(
              `${basePath}/${options.ideaId}`,
              {}
            );
            dispatch({
              ideaDetails: responseStars.data,
              type: IdeasActionTypes.IDEA_DETAILS,
            });
            options.isFeed &&
              dispatch({
                ideaDetails: responseStars.data,
                type: FeedActionTypes.POST_EVALUATION,
              });
          });
      }
    } catch (err) {
      treatError(options.culture, "Post - Review Starts", err);
    }
  };
};

//put/ideas/{ideaId}/evaluation/stars
export const putIdeasStars: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      const params = {
        workspaceId: options.workspaceId,
        applicationId: options.applicationId,
        dimensions: options.dimensions,
        comment: sanitizeHtml(options.comment, securityTextEditor),
      };

      if (reactNewApp) {
        api_general
          .put(`${basePath}/${options.ideaId}/evaluation/stars`, params)
          .then(
            (response) => {
              toast.success(
                resource(
                  options.culture,
                  "PageIdeaDetail::ReviewRating_StarsEvaluation_Updated"
                )
              );

              api_general.get(`${basePath}/${options.ideaId}`, {}).then(
                (response) => {
                  dispatch({
                    ideaDetails: response.data,
                    type: IdeasActionTypes.IDEA_DETAILS,
                  });
                  options.isFeed &&
                    dispatch({
                      ideaDetails: response.data,
                      type: FeedActionTypes.POST_EVALUATION,
                    });
                  resolve(response.data);
                },
                (err) => {
                  treatError(options.culture, "Idea", err, err.error_errors);
                  reject(err);
                }
              );
            },
            (err) => {
              treatError(options.culture, "Idea", err);
              reject(err);
            }
          );
      } else {
        api_v2
          .put(`${basePath}/${options.ideaId}/evaluation/stars`, params)
          .then(
            (response) => {
              toast.success(
                resource(
                  options.culture,
                  "PageIdeaDetail::ReviewRating_StarsEvaluation_Updated"
                )
              );

              api_v2.get(`${basePath}/${options.ideaId}`, {}).then(
                (response) => {
                  dispatch({
                    ideaDetails: response.data,
                    type: IdeasActionTypes.IDEA_DETAILS,
                  });
                  options.isFeed &&
                    dispatch({
                      ideaDetails: response.data,
                      type: FeedActionTypes.POST_EVALUATION,
                    });
                  resolve(response.data);
                },
                (err) => {
                  treatError(options.culture, "Idea", err);
                  reject(err);
                }
              );
            },
            (err) => {
              treatError(options.culture, "Idea", err);
              reject(err);
            }
          );
      }
    });
  };
};

//post/ideas/{ideaId}/evaluation/investments
export const postIdeasInvestments: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {

    try {
      const params = {
        points: options.points,
        isIncrease: options.isIncrease,
        applicationId: options.applicationId,
        workspaceId: options.workspaceId,
      };

      reactNewApp
        ? await api_general
          .post(
            `${basePath}/${options.ideaId}/evaluation/investments`,
            params
          )
          .then(async () => {
            toast.success(
              resource(
                options.cultureTag,
                "PageManageInvestments::InvestedAmount",
                [options.points]
              )
            );

            const responseInvestments = await api_general.get(
              `${basePath}/${options.ideaId}`,
              {}
            );
            dispatch({
              ideas: responseInvestments.data,
              type: IdeasActionTypes.IDEAS,
            });
            options.isFeed &&
              dispatch({
                ideaDetails: responseInvestments.data,
                type: FeedActionTypes.POST_EVALUATION,
              });
          })
        : await api_v2
          .post(
            `${basePath}/${options.ideaId}/evaluation/investments`,
            params
          )
          .then(async () => {
            toast.success(
              resource(
                options.cultureTag,
                "PageManageInvestments::InvestedAmount",
                [options.points]
              )
            );

            const responseInvestments = await api_v2.get(
              `${basePath}/${options.ideaId}`,
              {}
            );
            dispatch({
              ideas: responseInvestments.data,
              type: IdeasActionTypes.IDEAS,
            });
            options.isFeed &&
              dispatch({
                ideaDetails: responseInvestments.data,
                type: FeedActionTypes.POST_EVALUATION,
              });
          });
    } catch (err) {
      treatError(options.cultureTag, "PageIdea::Header", err);
    }
  };
};

//post/ideas/{ideaId}/comments
export const postIdeasComments: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasCommentsPostAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        statusComments: IdeasStatusTypes.IDEAS_LOADING,
        type: IdeasActionTypes.IDEAS_COMMENTS_LOADING,
      });
      let params;

      // verify if it is a new comment or it is a reply comment
      if (options.hasOwnProperty("ideaCommentPostId")) {
        params = {
          ideaCommentPostId: options.ideaCommentPostId,
          message: sanitizeHtml(options.message, securityTextEditor),
          status: options.status,
          workspaceId: options.workspaceId,
          applicationId: options.applicationId,
          captcha: options.captcha,
        };
      } else {
        params = {
          message: sanitizeHtml(options.message, securityTextEditor),
          status: options.status,
          workspaceId: options.workspaceId,
          applicationId: options.applicationId,
          captcha: options.captcha,
        };
      }

      if (reactNewApp) {
        await api_general
          .post(`${basePath}/${options.ideaId}/comments`, params)
          .then(() => {
            toast.success(
              resource(options.culture, "CommentsArea::SuccessCreatedComment")
            );
            // async () => {
            // let paramsComments = [];
            // let params_qs = "";

            // paramsComments.push(`status=${options.status}`);
            // paramsComments.push(`pageNumber=${options.pageNumber}`);
            // paramsComments.push(`pageSize=${options.pageSize}`);

            // if (paramsComments.length) {
            //   params_qs = `?${paramsComments.join("&")}`;
            // }
            // const responseComments = await api_general.get(
            //   `${basePath}/${options.ideaId}/comments${params_qs}`,
            //   {}
            // );
            dispatch({
              // ideasComments: responseComments.data,
              statusComments: IdeasStatusTypes.IDEAS_SUCCESS,
              type: IdeasActionTypes.IDEAS_COMMENTS_SUCCESS,
            });
          });
      } else {
        await api_v2
          .post(`${basePath}/${options.ideaId}/comments`, params)
          .then(async () => {
            let paramsComments = [];
            let params_qs = "";

            paramsComments.push(`status=${options.status}`);
            paramsComments.push(`pageNumber=${options.pageNumber}`);
            paramsComments.push(`pageSize=${options.pageSize}`);

            if (paramsComments.length) {
              params_qs = `?${paramsComments.join("&")}`;
            }
            const responseComments = await api_v2.get(
              `${basePath}/${options.ideaId}/comments${params_qs}`,
              {}
            );
            dispatch({
              ideasComments: responseComments.data,
              statusComments: IdeasStatusTypes.IDEAS_SUCCESS,
              type: IdeasActionTypes.IDEAS_COMMENTS_SUCCESS,
            });
          });
      }
    } catch (err) {
      dispatch({
        statusComments: IdeasStatusTypes.IDEAS_SUCCESS,
        type: IdeasActionTypes.IDEAS_COMMENTS_FAILED,
      });
      treatError(options?.culture, "Idea", err);
    }
  };
};

//get/ideas/{ideaId}/comments
export const getAllIdeasComments: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasCommentsSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        statusComments: "LOADING",
        type: IdeasActionTypes.IDEAS_COMMENTS_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("status")) {
        params.push(`status=${options.status}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
          .get(`${basePath}/${options.ideaId}/comments${params_qs}`, {})
          .then((response) => {
            dispatch({
              statusComments: IdeasStatusTypes.IDEAS_SUCCESS,
              ideasComments: response.data,
              type: IdeasActionTypes.IDEAS_COMMENTS_SUCCESS,
            });
          })
        : await api_v2
          .get(`${basePath}/${options.ideaId}/comments${params_qs}`, {})
          .then((response) => {
            dispatch({
              statusComments: IdeasStatusTypes.IDEAS_SUCCESS,
              ideasComments: response.data,
              type: IdeasActionTypes.IDEAS_COMMENTS_SUCCESS,
            });
          });
    } catch (err) {
      treatError(options?.culture, "PageIdea::Header", err);
    }
  };
};

//post/ideas/{ideaId}/comments/like
export const postLikeComment: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasCommentsPostAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params;
      if (options.hasOwnProperty("ideaId")) {
        params = {
          workspaceId: options.workspaceId,
          applicationId: options.applicationId,
          commentId: options.commentId,
          ideaId: options.ideaId,
        };
      } else {
        params = {
          workspaceId: options.workspaceId,
          status: options.status,
          applicationId: options.applicationId,
        };
      }

      reactNewApp
        ? await api_general
          .post(`${basePath}/${options.ideaId}/comments/like`, params)
          .then(async () => { })
        : await api_v2
          .post(`${basePath}/${options.ideaId}/comments/like`, params)
          .then(async () => { });
    } catch (err) {
      treatError(options?.culture, "PageIdea::Header", err);
    }
  };
};

//post/ideas/{ideaId}/comments/dislike
export const postDislikeComment: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasCommentsPostAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params;

      if (options.hasOwnProperty("ideaId")) {
        params = {
          workspaceId: options.workspaceId,
          ideaId: options.ideaId,
          applicationId: options.applicationId,
          commentId: options.commentId,
        };
      } else {
        params = {
          workspaceId: options.workspaceId,
          status: options.status,
          applicationId: options.applicationId,
        };
      }

      reactNewApp
        ? await api_general
          .post(`${basePath}/${options.ideaId}/comments/dislike`, params)
          .then(async () => { })
        : await api_v2
          .post(`${basePath}/${options.ideaId}/comments/dislike`, params)
          .then(async () => { });
    } catch (err) {
      treatError(options?.culture, "PageIdea::Header", err);
    }
  };
};

//put/ideas/draft/{ideaId}
export const putDraftUpdate: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IDraftPutAction>
> = (params, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      if (reactNewApp) {
        await api_general
          .put(`${basePath}/draft/${params.get("ideaId")}`, params, {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          })
          .then(async (response) => {
            dispatch({
              type: IdeasActionTypes.PUTDRAFT,
            });
            toast.success(resource(culture, "PageIdeaDraft::Updated"));
          });
      } else {
        await api_v2
          .put(`${basePath}/draft/${params.get("ideaId")}`, params, {
            headers: {
              "Content-Type": `multipart/form-data`,
            },
          })
          .then(async (response) => {
            dispatch({
              type: IdeasActionTypes.PUTDRAFT,
            });
            toast.success(resource(culture, "PageIdeaDraft::Updated"));
          });
      }
    } catch (err) {
      treatError(culture, "PageIdea::Header", err);
    }
  };
};

//delete/ideas/draft/{ideaId}
export const deleteDraft: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IDraftPostAction>
> = (params, options) => {
  return async (dispatch: Dispatch) => {
    try {
      if (reactNewApp) {
        await api_general.delete(`${basePath}/draft/${params.ideaId}`, params);
        toast.success(resource(options?.culture, "PageIdeaDraft::Deleted"));
      } else {
        await api_v2.delete(`${basePath}/draft/${params.ideaId}`, params);
        toast.success(resource(options?.culture, "PageIdeaDraft::Deleted"));
      }
    } catch (err) {
      treatError(options?.culture?.culture, "PageIdea::Header", err);
    }
  };
};

//put/ideas/{commentsId}/comments
export const putIdeasComments: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasPutCommentsSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        statusComments: IdeasStatusTypes.IDEAS_LOADING,
        type: IdeasActionTypes.IDEAS_PUT_COMMENTS_LOADING,
      });

      let params;
      // verify if it is a new comment or it is a reply comment
      if (options.hasOwnProperty("parentCommentId")) {
        params = {
          parentCommentId: options.parentCommentId,
          ideaId: options.ideaId,
          workspaceId: options.workspaceId,
          delete: options.delete,
          edit: options.edit,
          message: sanitizeHtml(options.message, securityTextEditor),
          captcha: options.captcha,
        };
      } else {
        params = {
          ideaId: options.ideaId,
          workspaceId: options.workspaceId,
          delete: options.delete,
          edit: options.edit,
          message: sanitizeHtml(options.message, securityTextEditor),
          captcha: options.captcha,
        };
      }

      await api_general
        .put(`${basePath}/${options.commentId}/comments`, params)
        .then(() => {
          toast.success(
            resource(options.culture, "CommentsArea::SuccessEditedComment")
          );
          dispatch({
            statusComments: IdeasStatusTypes.IDEAS_SUCCESS,
            type: IdeasActionTypes.IDEAS_PUT_COMMENTS_SUCCESS,
          });
        });
    } catch (err) {
      dispatch({
        statusComments: IdeasStatusTypes.IDEAS_FAILED,
        type: IdeasActionTypes.IDEAS_PUT_COMMENTS_FAILED,
      });
      treatError(options?.culture, "Idea", err);
    }
  };
};

export const deleteIdeasComments: ActionCreator<
  ThunkAction<
    Promise<any>,
    IIdeasState,
    null,
    IIdeasDeleteCommentsSuccessAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        statusComments: IdeasStatusTypes.IDEAS_LOADING,
        type: IdeasActionTypes.IDEAS_DELETE_COMMENTS_LOADING,
      });

      let params = {
        ideaId: options.ideaId,
        workspaceId: options.workspaceId,
        commentId: options.commentId,
        //captcha: options.captcha,
      };

      await api_general
        .delete(`${basePath}/comments`, { data: params })
        .then(() => {
          toast.success(
            resource(options.culture, "CommentsArea::SuccessDeletedComment")
          );

          // async () => {
          // toast.success(
          //   resource(options.culture, "CommentsArea::SuccessDeletedComment")
          // );

          // let paramsComments = [];
          // let params_qs = "";

          // paramsComments.push(`status=${options.status}`);
          // paramsComments.push(`pageNumber=${options.pageNumber}`);
          // paramsComments.push(`pageSize=${options.pageSize}`);

          // if (paramsComments.length) {
          //   params_qs = `?${paramsComments.join("&")}`;
          // }
          // const responseComments = await api_general.get(
          //   `${basePath}/${options.ideaId}/comments${params_qs}`,
          //   {}
          // );
          dispatch({
            // ideasComments: responseComments.data,
            statusComments: IdeasStatusTypes.IDEAS_SUCCESS,
            type: IdeasActionTypes.IDEAS_DELETE_COMMENTS_SUCCESS,
          });
        });
    } catch (err) {
      dispatch({
        statusComments: IdeasStatusTypes.IDEAS_FAILED,
        type: IdeasActionTypes.IDEAS_DELETE_COMMENTS_FAILED,
      });
      treatError(options?.culture, "Idea", err);
    }
  };
};

export const deleteIdeasReplyComments: ActionCreator<
  ThunkAction<
    Promise<any>,
    IIdeasState,
    null,
    IIdeasDeleteReplyCommentsSuccessAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        statusComments: IdeasStatusTypes.IDEAS_LOADING,
        type: IdeasActionTypes.IDEAS_DELETE_REPLY_COMMENTS_LOADING,
      });

      let params;
      params = {
        parentCommentId: options.parentCommentId,
        ideaId: options.ideaId,
        workspaceId: options.workspaceId,
        commentId: options.commentId,
        //captcha: options.captcha,
      };

      await api_general
        .delete(`${basePath}/reply`, { data: params })
        .then(() => {
          toast.success(
            resource(options.culture, "CommentsArea::SuccessDeletedComment")
          );
          // async () => {
          // toast.success(
          //   resource(options.culture, "CommentsArea::SuccessDeletedComment")
          // );

          // let paramsComments = [];
          // let params_qs = "";

          // paramsComments.push(`status=${options.status}`);
          // paramsComments.push(`pageNumber=${options.pageNumber}`);
          // paramsComments.push(`pageSize=${options.pageSize}`);

          // if (paramsComments.length) {
          //   params_qs = `?${paramsComments.join("&")}`;
          // }
          // const responseComments = await api_general.get(
          //   `${basePath}/${options.ideaId}/comments${params_qs}`,
          //   {}
          // );
          dispatch({
            // ideasComments: responseComments.data,
            statusComments: IdeasStatusTypes.IDEAS_SUCCESS,
            type: IdeasActionTypes.IDEAS_DELETE_REPLY_COMMENTS_SUCCESS,
          });
        });
    } catch (err) {
      dispatch({
        statusComments: IdeasStatusTypes.IDEAS_FAILED,
        type: IdeasActionTypes.IDEAS_DELETE_REPLY_COMMENTS_FAILED,
      });
      treatError(options?.culture, "Idea", err);
    }
  };
};

// não vai ter na v3
export const postIdeasLikes: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    //ideaId, applicationId, status, pageNumber, pageSize, culture
    try {
      await api_v2
        .post(`${basePath}/${options.ideaId}/evaluation/like`, {
          applicationId: options.applicationId,
        })
        .then(async () => {
          await api_v2.get(`${basePath}/${options.ideaId}`, {}).then(
            (response) => {
              dispatch({
                ideaDetails: response.data,
                type: IdeasActionTypes.IDEA_DETAILS,
              });
              options.isFeed &&
                dispatch({
                  ideaDetails: response.data,
                  type: FeedActionTypes.POST_EVALUATION,
                });
            },
            (error) => { }
          );
        });
    } catch (err) {
      treatError(options.culture, "PageIdea::Header", err);
    }
  };
};

// não vai ter na v3
export const deleteIdeasLikes: ActionCreator<
  ThunkAction<Promise<any>, IIdeasState, null, IIdeasGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    //ideaId, applicationId, status, pageNumber, pageSize, culture
    try {
      await api_v2
        .delete(`${basePath}/${options.ideaId}/evaluation/like`, {
          data: { applicationId: options.applicationId },
        })
        .then(
          async () => {
            const response = await api_v2.get(
              `${basePath}/${options.ideaId}`,
              {}
            );
            dispatch({
              ideaDetails: response.data,
              type: IdeasActionTypes.IDEA_DETAILS,
            });
            options.isFeed &&
              dispatch({
                ideaDetails: response.data,
                isDislike: true,
                type: FeedActionTypes.POST_EVALUATION,
              });
          },
          (error) => { }
        );
    } catch (err) {
      treatError(options.culture, "PageIdea::Header", err);
    }
  };
};
