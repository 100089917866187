import React, { useEffect, useState } from "react";
import { createStyles, Grid, makeStyles, Theme, Badge, Box } from "@material-ui/core";
import Resource from "components/Resources/Resource";

/*
    Example:
    <KeyTasksFilterButtons props={{
        "totalAll": 8,
        "totalEvaluation": 4,
        "totalValidation": 2,
        "totalCorrections" 2,
        "totalTransition": 2
    }} />
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    KeyTasksFilterButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '50px',
      textTransform: 'uppercase',
    },

    Buttons: {
      textAlign: 'center',
      cursor: 'pointer',
      backgroundColor: 'white',
      color: theme.palette.primary.main,

      fontSize: '13px',
      fontWeight: 600,
      width: 'fit-content',
      height: '30px',        

      paddingTop: '4px',  
      paddingBottom: '4px', 
      paddingLeft: '25px',
      paddingRight: '25px',

      borderRadius:' 50px',
      boxShadow: '0px 2px 2px 2px rgba(136, 136, 136, 0.3)',         

      /* internal content alignment */
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',                
    },
    
    ButtonSelected: {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.light,
    },
    
    CounterSelected: {
      backgroundColor: theme.palette.primary.main,
    },

    zero: {
      display: 'none',
    },

    Counter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'red',
      color: 'white',
      float: 'right',
      borderRadius: '50px',
      marginLeft: '20px',
      width: '20px',
      height: '20px',
      fontSize: '12px',
    }
  })
);

interface IProps {
  getKeyTasks: (typeTask: string) => void;
  props: any;
  activeTab?: string;
}

function KeyTasksFilterButtons(props: IProps) {
  const classes = useStyles();
  const [active, setActive] = useState(props?.activeTab ? props?.activeTab : 'ALL');
  const { activeTab } = props
  useEffect(()=>{
    setActive(activeTab!)
  },[activeTab])
  const { getKeyTasks } = props;
  let classAll = classes.Buttons;
  let classEvaluations = classes.Buttons;
  let classValidations = classes.Buttons;
  let classCorrections = classes.Buttons;
  let classTransitions = classes.Buttons;


  switch (active) {
    case "ALL":
      classAll += ` ${classes.ButtonSelected}`;
      break;
    case "EVALUATIONS":
      classEvaluations += ` ${classes.ButtonSelected}`;
      break;
    case "VALIDATIONS":
      classValidations += ` ${classes.ButtonSelected}`;
      break;
    case "CORRECTIONS":
      classCorrections += ` ${classes.ButtonSelected}`;
      break;
    case "TRANSITIONS":
      classTransitions += ` ${classes.ButtonSelected}`;
      break;
  }

  return (
    <div className={classes.KeyTasksFilterButtons}>
      <Grid container spacing={2}>
        {/* TODO Translate */}
        <Grid item>
          <div
            className={classAll}
            onClick={() => {
              setActive("ALL");
              getKeyTasks("");
            }}
          >
             <Box paddingRight={props.props?.totalAll ? 3 : 0}><Resource tag={"Common::All"}/></Box>
             <Badge badgeContent={props.props?.totalAll} color="error" max={99}/>
          </div>
        </Grid>
        <Grid item>
          <div
            className={classValidations}
            onClick={() => {
              setActive("VALIDATIONS");
              getKeyTasks("2");
            }}
          >
            <Box paddingRight={props.props?.totalValidation ? 3 : 0}><Resource tag="Common::Validations"/></Box>
            <Badge badgeContent={props.props?.totalValidation} color="error" max={99}/>
          </div>
        </Grid>
        <Grid item>
          <div
            className={classEvaluations}
            onClick={() => {
              setActive("EVALUATIONS");
              getKeyTasks("1");
            }}
          >
             <Box paddingRight={props.props?.totalEvaluation ? 3 : 0}><Resource tag="Common::Evaluations"/></Box>
             <Badge badgeContent={props.props?.totalEvaluation} color="error" max={99}/>
          </div>
        </Grid>
        <Grid item>
          <div
            className={classCorrections}
            onClick={() => {
              setActive("CORRECTIONS");
              getKeyTasks("4");
            }}
          >
             <Box paddingRight={props.props?.totalCorrection ? 3 : 0}><Resource tag="Common::Corrections"/></Box>
             <Badge badgeContent={props.props?.totalCorrection} color="error" max={99}/>
          </div>
        </Grid>
        <Grid item>
          <div
            className={classTransitions}
            onClick={() => {
              setActive("TRANSITIONS");
              getKeyTasks("3");
            }}
          >
             <Box paddingRight={props.props?.totalTransition ? 3 : 0}><Resource tag="Common::Transitions"/></Box>
             <Badge badgeContent={props.props?.totalTransition} color="error" max={99}/>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default KeyTasksFilterButtons;
