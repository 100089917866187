import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import RemoveIcon from "@material-ui/icons/Remove";
import { ICultureState } from "redux/reducers/cultureReducer";
import Resource, { resource } from "components/Resources/Resource";
import {
  Box,
  Button,
  createStyles,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    starsContainer: {
      maxHeight: 220,
      overflowY: "auto",
    },
    numberPercentage: {
      width: "9%",
      marginLeft: 24,
      fontWeight: 500,

      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "-moz-appearance": "textfield",
    },
    removeButton: {
      padding: 0,
      border: `1px solid ${theme.palette.grey[300]}`,
      color: theme.palette.grey[300],
      marginLeft: 12,
    },
  })
);

interface IProps {
  culture: ICultureState;
  criterias: {
    cultures: [
      {
        title: string;
        cultureTag: string;
      }
    ];
    weight: string;
    order: number;
    criteriaId?: string;
  }[];
  setAnalysisState: (newState: any) => void;
  setCriteriaCompletion: (number:number) => void
}

function AnalysisWorkflow({
  culture,
  criterias,
  setAnalysisState,
  setCriteriaCompletion
}: IProps) {
  const classes = useStyles();
  const [stars, setCriterias] = useState(criterias);
  const { cultureTag } = culture.culture || "";

  useEffect(() => {
    setAnalysisState(stars);
  }, [stars, setAnalysisState]);

  const addStarsValue = () => {
    setCriterias([...stars, { cultures: [{ title: "", cultureTag }], weight: "", order: criterias.length + 1 }]);
  };

  const deleteStar = (valIndex: number) => {
    const totalWeight = criterias.length
      ? criterias
          .map((x, i) => (i !== valIndex ? Number(x.weight) : 0))
          .reduce((accumulator, currentValue) => accumulator + currentValue)
      : 0;
    setCriterias(stars.filter((star, index) => index !== valIndex));

    setCriteriaCompletion(totalWeight)
  };

  const setCriteriasValue = (newValue: any, index: number) => {
    const newArr = [...stars];
    newArr[index] = { ...newArr[index], ...newValue };
    setCriterias(newArr);
  };

  const handleCriteriaWeight = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const {value} = e.target;
    const totalWeight = criterias.length ? criterias
      .map((x, i) => (i !== index ? Number(x.weight) : 0))
      .reduce((accumulator, currentValue) => accumulator + currentValue)
    : 0;

    setCriteriaCompletion(totalWeight)

    totalWeight + Number(value) < 101
      ? setCriteriasValue({ weight: e.target.value }, index)
      : setCriteriasValue({ weight: 100 - totalWeight }, index);

      setCriteriaCompletion(totalWeight + Number(value))

  };

  return (
    <Box>      
      <Box mt={2}>
        <Box>
          <Typography variant="body2" color="textSecondary">
            <Resource tag="PageCreateChallenge::Workflow_Expert_ApplyingMethod" />
          </Typography>
          <Box mt={4}>
            <Typography variant="h4">
              <Resource tag="PageCreateChallenge::Workflow_RatingCriteria" />
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography variant="body2" color="textSecondary">
              <Resource tag="PageCreateChallenge::Workflow_DefineCriterias" />
            </Typography>
          </Box>
          <Box mt={2} className={classes.starsContainer}>
            {stars.map((star, index, self) => (
              <Box display="flex" alignItems="center" mb={2} key={index}>
                <Input
                  fullWidth
                  value={star.cultures[0].title}
                  onChange={(e) =>
                    setCriteriasValue({ cultures: [{title: e.target.value, cultureTag}] }, index)
                  }
                  placeholder={resource(
                    culture,
                    "PageCreateChallenge::Workflow_WriteCriteria"
                  )}
                  style={{ width: "80%" }}
                />
                <Input
                  value={star.weight}
                  type="number"
                  onChange={(e) =>
                    handleCriteriaWeight(e, index)
                  }
                  className={classes.numberPercentage}
                  endAdornment={
                    <InputAdornment position="end">
                      <strong>%</strong>
                    </InputAdornment>
                  }
                />
                {self.length > 1 && (
                  <IconButton
                    className={classes.removeButton}
                    onClick={() => deleteStar(index)}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
          <Box mt={1} textAlign="end">
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={addStarsValue}
              disabled={
                criterias.length
                  ? criterias
                      .map((x, i) => Number(x.weight))
                      .reduce((accumulator, currentValue) => accumulator + currentValue) >= 100
                  : false
              }
            >
              <Resource tag="PageCreateChallenge::Workflow_AddAnotherCriteria" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AnalysisWorkflow;
