import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAuthLoginAction, doLoginSSO } from "redux/actions/AuthAction";
import { getProfileData, IProfileGetDataAction } from "redux/actions/ProfileActions";
import { IAuthUser } from "redux/reducers/authReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IAuthState } from "redux/reducers/authReducer";
import { IProfileData } from "redux/reducers/profileReducer";
import { IAppState } from "redux/store/Store";
import history from "routes/history";
import { Box, CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";

interface IStateProps {
  cultureState: ICultureState,
  profileData: IProfileData,
  authState: IAuthState
}

interface IProps {
  doLoginSSO: (response: any) => Promise<any>,
  getProfileData: () => Promise<IProfileGetDataAction>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerLoadingSSO : {
      margin: '0px auto',
      height: '100vh'
    }
  })
)

function getCookie(cname: string) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
      c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
      }
  }
  return "";
}

function SSOResponse(props: IProps & IStateProps) {
  const classes = useStyles();
  let sso:any = getCookie('sso');
  sso= JSON.parse(sso);

  const { doLoginSSO, getProfileData } = props

  useEffect(() => {
    doLoginSSO(sso).then(response=>{
      getProfileData().then(data=>{
        if (sso?.allowCreateWorkspace && sso?.hasWorkspaceConfigured === false) {
          history.push('/setenvironment')
        } else if (sso?.isProfileSetted === true) {
          history.push('/setprofile')
        } else {
          history.push('/')
        }  
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doLoginSSO])

  return (
    <Box height="100%" display="flex" className={classes.centerLoadingSSO}>
      <Box m='auto'>
        <CircularProgress size={60} className="icon" />
      </Box>
    </Box>
  );
};


const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IAuthLoginAction>): IProps => {
  return {
    doLoginSSO: (response: IAuthUser) => dispatch(doLoginSSO(response)),
    getProfileData: () => dispatch(getProfileData()),
  };
};

const mapStateToProps = (store: IAppState, ownProps: any): IStateProps => {
  return {
   // activeTenant: store.tenantState.activeTenant,
    authState: store.authState,
    cultureState: store.cultureState,
    profileData: store.profileState.profileData
   // expiredLogin: ownProps?.location?.state?.expiredLogin 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SSOResponse);