import React from "react";
import XCard from "common/X/XCard";
import newApp from "utils/reactNewApp";
import { Link } from "react-router-dom";
import EMIcon from "components/Icon/Icon";
import Resource from "../../Resources/Resource";
import { IResultChallengesClosed } from "redux/types/challenges";
import { Grid, Typography, Chip, MenuItem, useTheme } from "@material-ui/core";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import history from "routes/history";
import { useSelector } from "react-redux";
import { IAppState } from "redux/store/Store";
import { api_general } from "config/api";

interface IProps {
  challengesClosed: IResultChallengesClosed;
}

function ClosedCard(props: IProps) {
  const { challengesClosed } = props;
  const theme = useTheme();
  const store = useSelector((store) => store) as IAppState;
  const permission = store.authState.auth.permission;
  
  const duplicateChallenge = async () => {
    const response = await api_general.post(`challenges/duplicate`, {
      challengeId: challengesClosed.challengeId,
      workspaceId: store.authState.auth.workspaceId,
    });

    if(response?.data?.data){
      history.push(`/createChallenge/${response?.data?.data}`);
    }
  };

  return (
    <XCard
      key={challengesClosed?.challengeId}
      link={`/challenges/details/${challengesClosed?.challengeId}`}
      color={"dark"}
      backgroundImage={challengesClosed?.image}
      elevation={1}
      height={200}
      topContentPadding={20}
      bottomContentPadding={20}
      topContent={
        <Grid container>
          <Grid container item xs={11}>
            <Typography variant="h4">{challengesClosed?.title}</Typography>
          </Grid>
          {newApp && (permission === "Admin" || permission === "ChallengeManager") && (
            <Grid
              container
              alignItems="baseline"
              justify="flex-end"
              item
              xs={1}
            >
              <MoreOptionsButton
                translate="-72px, 42px"
                iconColor={theme.palette.primary.contrastText}
              >
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    duplicateChallenge();
                  }}
                >
                  <Resource tag="PageChallenges::Duplicate" />
                </MenuItem>
              </MoreOptionsButton>
            </Grid>
          )}
        </Grid>
      }
      bottomContent={
        <Grid container>
          <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            justify="flex-end"
            direction="row"
          >
            <object>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: `idea`,
                  state: { challengeId: challengesClosed?.challengeId },
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <Chip
                  size="small"
                  icon={<EMIcon color="#000" icon="section-ideas" />}
                  style={{
                    marginRight: "-40px",
                    paddingRight: "24px",
                    cursor: "pointer",
                  }}
                  label={
                    <>
                      {challengesClosed.ideasImplementedCount === 1 ? (
                        <Resource
                          tag={"PageChallenges::Challenge_ImplementedIdea"}
                          args={[challengesClosed.ideasImplementedCount]}
                        />
                      ) : (
                        <Resource
                          tag={"PageChallenges::Challenge_ImplementedIdeas"}
                          args={[challengesClosed.ideasImplementedCount]}
                        />
                      )}
                    </>
                  }
                />
              </Link>
            </object>
          </Grid>
        </Grid>
      }
    />
  );
}

export default ClosedCard;
