import React from "react";
import { Link } from "react-router-dom";
import { Button, Theme, makeStyles, createStyles } from "@material-ui/core";

interface IProps {
  devMode?: boolean;
  link?: string;
  dark?: boolean;
  size?: any;
  variant?: any;
  title: any;
  textUppercase?: boolean;
  textLowercase?: boolean;
}

export default function XButton(props: IProps) {
  const styleProps = {
    devMode: props.devMode,
    dark: props.dark,
    size: props.size,
    title: props.title,
    variant: props.variant,
    textUppercase: props.textUppercase,
    textLowercase: props.textLowercase,
  };
  const classes = useStyles(styleProps);

  return (
    <main className="xbutton">
      <Button
        size={props.size ? props.size : "medium"}
        variant={props.variant ? props.variant : "contained"}
        className={classes.root}
        component={Link}
        to={{ pathname: props.link }}
      >
        {props.title}
      </Button>
    </main>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: IProps) => ({
      padding: props.size === "small" ? "0px 10px" : "auto",
      fontSize: props.size === "small" ? "12px" : "normal",
      textTransform: props.textUppercase
        ? "uppercase"
        : props.textLowercase
        ? "lowercase"
        : "none",
      color: props.dark
        ? theme.palette.common.white
        : theme.palette.common.black,
    }),
  })
);
