import React, { Component } from "react";
import EMIcon from "components/Icon/Icon";
import "./LikeIcon.css";

interface Iprops {
  active?: boolean;
}

class LikeIcon extends Component<Iprops> {
  state = {
    active: this.props?.active,
  };

  render() {
    const handleIconChange = () =>
      this.setState({ active: !this.state.active });
    return (
      <div className="LikeIcon-container" onClick={handleIconChange}>
        <EMIcon
          size={20}
          color={this.state?.active ? "#1B9DFC" : "#ccc"}
          icon={this.state?.active ? "like-b" : "like"}
        ></EMIcon>
      </div>
    );
  }
}

export default LikeIcon;
