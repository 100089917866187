import {IChallengesInProgress} from "redux/types/challenges";
import {Box, CircularProgress, FormGroup, Grid} from "@material-ui/core";
import InProgressCard from "../../ChallengesCards/InProgressCard/InProgressCard";
import React, {useEffect} from "react";
import {toast} from "react-toastify";
import {resource} from "../../Resources/Resource";
import {ICultureState} from "../../../redux/reducers/cultureReducer";

interface IProps {
  challengesInProgress: IChallengesInProgress;
  challengeStatus: string;
  culture: ICultureState;
}

export default function ChallengeInProgressList(props: IProps) {
  const {
    culture,
    challengesInProgress,
    challengeStatus,
  } = props;

  useEffect(() => {
    if (!challengesInProgress || challengesInProgress?.result?.length === 0) {
      toast.error(resource(culture, "PageSettings::NoResults"));
    }
  }, [challengesInProgress?.result?.length]);

  return (
      <FormGroup>
        {challengeStatus === "LOADING" ? (
            <Box display="flex" justifyContent="center" alignItems="center" p={5}>
              <CircularProgress />
            </Box>
        ) : (
            <Grid container>
              <Grid container spacing={2} direction="row">
                {challengesInProgress &&
                    challengesInProgress?.result?.map((chaIP, index) => {
                      return (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <InProgressCard
                                key={"inProgress_" + challengesInProgress.meta.currentPage + "_" + index}
                                challengesInProgress={chaIP}
                            />
                          </Grid>
                      );
                    })
                }
              </Grid>
            </Grid>
        )}
      </FormGroup>
  );
}