import "./PrizePage.css";
import React, {useMemo, useState} from "react";
import NextPrizes from "../NextPrizes/NextPrizes";
import imagemPrize from "../../assets/img/empty@3x.png";
import BuyNowPrizes from "../BuyNowPrizes/BuyNowPrizes";
import { resource } from "components/Resources/Resource";
import {
  IPrizePrevious,
  IResultPrizeActiveAuctionUser,
  IResultPrizeActiveBuyNow,
  IResultPrizeFutures
} from "../../redux/reducers/prizeReducer";
import { IProfileState } from "redux/reducers/profileReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import ActiveAuctionPrizes from "../ActiveAuctionPrizes/ActiveAuctionPrizes";
import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import Resource from "../Resources/Resource";
import PreviousPrizes from "../PreviousPrizes/PreviousPrizes";

const useStyles = makeStyles(() =>
  createStyles({
    noDataPrizes: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 2.0,
    },
  })
);
interface IProps {
  postBidPrize: (prizeId: string, value: number) => void;
  prizeActiveAuction: IResultPrizeActiveAuctionUser[];
  prizeActiveBuyNow: IResultPrizeActiveBuyNow[];
  prizeFutures: IResultPrizeFutures[];
  prizePrevious: IPrizePrevious;
  getPrizePrevious: (options: object) => Promise<any>;
  points: number;
  pageLink?: any;
  postBuyPrize: (prizeId: string) => void;
  culture?: ICultureState;
  profile: IProfileState;
  permission: string;
  workspaceId: string;
  requestManagePrize: (prizeId: string, type: number, isCanceling?: boolean) => void;
}

function PrizePage(props: IProps) {
  const {
    prizeActiveAuction,
    prizeActiveBuyNow,
    prizeFutures,
    prizePrevious,
    getPrizePrevious,
    points,
    permission,
    postBuyPrize,
    postBidPrize,
    requestManagePrize,
    pageLink,
    profile,
    culture,
    workspaceId,
  } = props;
  const classes = useStyles();

  const [prizeP, setPrizeP] = useState<IPrizePrevious>();

  const prize = useMemo(() => {
    let result = prizePrevious;
    if (prizeP) {
      result = prizeP;
      result!.meta = prizePrevious.meta;
      prizePrevious?.result.map((prize, index) => {
        result?.result.push(prize);
      })
    }
    setPrizeP(result);
    return result;
  }, [prizePrevious]);

  const textNoDataPrizes = resource(
    culture?.culture,
    "Errors::NoPrizesAvailable"
  )?.split("<br></br>");

  return (
    <>
      {prizeActiveAuction?.length === 0 &&
      prizeActiveBuyNow?.length === 0 &&
      prizeFutures?.length === 0 &&
      prizePrevious?.result.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={2}
          className="no-prizes"
        >
          <Typography variant="h4" className={classes.noDataPrizes}>
            {textNoDataPrizes[0]}
          </Typography>
          <Typography variant="h4" className={classes.noDataPrizes}>
            {textNoDataPrizes[1]}
          </Typography>
          <img src={imagemPrize} alt="" />
        </Box>
      ) : (
        <Box className="with-prize">
          {prizeActiveAuction &&
              prizeActiveAuction.length > 0 && (
            <ActiveAuctionPrizes
              permission={permission}
              culture={culture}
              pageLink={pageLink}
              postBidPrize={postBidPrize}
              requestManagePrize={requestManagePrize}
              activeAuctionPrizes={prizeActiveAuction}
              points={points}
              profile={profile?.profile[0]?.userId}
            ></ActiveAuctionPrizes>
          )}
          {prizeActiveBuyNow &&
              prizeActiveBuyNow.length > 0 && (
            <BuyNowPrizes
              permission={permission}
              cultureTag={culture?.culture.cultureTag!}
              pageLink={pageLink}
              postBuyPrize={postBuyPrize}
              requestManagePrize={requestManagePrize}
              buyNowPrizes={prizeActiveBuyNow}
            />
          )}
          {prizeFutures &&
              prizeFutures.length > 0 && (
            <NextPrizes
              permission={permission}
              nextPrizes={prizeFutures}
              cultureTag={culture?.culture.cultureTag!}
              requestManagePrize={requestManagePrize}
            ></NextPrizes>
          )}
          {prizeP &&
              prizeP.meta.total > 0 && (
              <Box width="100%" marginTop={4}>
                <Box mt={4} mb={2}>
                  <Typography variant="h3">
                    <Resource tag={"PagePrizes::Prize_PreviousPrizes"} />
                  </Typography>
                </Box>
                  <PreviousPrizes
                    permission={permission}
                    profile={profile}
                    cultureTag={culture?.culture.cultureTag!}
                    prizePrevious={prizePrevious}
                    prizeP={prize!}
                    workspaceId={workspaceId}
                    getPrizePrevious={getPrizePrevious}
                    requestManagePrize={requestManagePrize}
                  />
              </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default PrizePage;
