import React from "react";
import { Box, Grid } from "@material-ui/core";
import SkeletonGrid from "common/UI/Skeleton/SkeletonGrid";
import SkeletonTitle from "common/UI/Skeleton/SkeletonTitle";
import NewsCardSkeleton from "components/NewsCard/NewsCardSkeleton";

export default function NewsDetailSkeleton() {
  return (
    <Box>
      <SkeletonTitle />
      <SkeletonGrid col={1} row={1} rowHeight={350} />
      <SkeletonGrid col={1} row={1} rowHeight={250} />
      <SkeletonTitle />
      <Grid container spacing={2}>
        <Grid item md={4} sm={12} style={{ width: "100%" }}>
          <NewsCardSkeleton />
        </Grid>
        <Grid item md={4} sm={12} style={{ width: "100%" }}>
          <NewsCardSkeleton />
        </Grid>
        <Grid item md={4} sm={12} style={{ width: "100%" }}>
          <NewsCardSkeleton />
        </Grid>
      </Grid>
    </Box>
  );
}
