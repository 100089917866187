// eslint-disable array-callback-return
import React from "react";
import Resource from "components/Resources/Resource";
import { FEATURES } from "config/features";
import { Box, Fab, Grid, Typography } from "@material-ui/core";
import XCard from "common/X/XCard";
import XCarousel from "common/X/XCarousel";
import XButton from "common/X/XButton";
import AddIcon from "@material-ui/icons/Add";
import history from "../../routes/history";
import { Link } from "react-router-dom";

interface IProps {
  items: any;
}

export default function FeedCarouselChallenges(props: IProps) {
  const validChallenges = props?.items?.filter(
    (item: any) =>
      item?.statusResource === "PageChallenges.Challenge_Active" ||
      item?.statusResource === "PageChallenges.Challenge_Permanent"
  );

  return (
    <Box mt={5} mb={5}>
      <Grid container style={{ width: "calc(100% - 10px)" }}>
        <XCarousel itemsToShow={2} infinite={true} partialVisible={true}>
          {validChallenges?.length > 0 &&
            validChallenges?.map((item: any, index: number) => {              
              const statusResource = item?.statusResource?.replace(".", "::");
              return (
                <XCard
                  key={item?.challengeId}
                  color={"dark"}
                  elevation={1}
                  height={200}
                  middleContentPadding={20}
                  bottomContentPadding={20}
                  middleContent={
                    <Link
                      to={{
                        pathname: `/challenges/details/${item?.challengeId}`,
                      }}
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        color: "#fff",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Grid container>
                        <Box paddingY={0.5}>
                          <Typography variant="subtitle2">
                            <Resource tag={statusResource} />
                          </Typography>
                        </Box>
                        <Typography variant="h4">{item?.title}</Typography>
                      </Grid>
                    </Link>
                  }
                  bottomContent={
                    <>
                      <Grid container item xs={7} sm={8}>
                        {item?.timeValue1 > 0 || item?.timeValue2 > 0 ? (
                          <>
                            {item?.allowSubmitIdea ? (
                              <Typography variant={"subtitle2"}>
                                <Resource
                                  tag={
                                    "PageDetailChallenge::Challenge_TimeIdeaSubmit"
                                  }
                                />
                              </Typography>
                            ) : (
                              <Typography variant={"subtitle2"}>
                                <Resource
                                  tag={
                                    "PageChallenges::Challenge_TimeIdeaEvaluate"
                                  }
                                  args={[""]}
                                />
                              </Typography>
                            )}

                            <Typography variant={"h6"}>
                              <Box component="span" display="inline" mr={1}>
                                <Resource
                                  tag={`${item?.timeValue1Resource?.replace(
                                    ".",
                                    "::"
                                  )}`}
                                  args={[`${item?.timeValue1}`]}
                                />
                              </Box>
                              <Box component="span" display="inline" mr={1}>
                                <Resource
                                  tag={`${item?.timeValue2Resource?.replace(
                                    ".",
                                    "::"
                                  )}`}
                                  args={[`${item?.timeValue2}`]}
                                />
                              </Box>
                            </Typography>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid container item xs={5} sm={4} justify="flex-end">
                        {!FEATURES?.challenges?.follow ? (
                          ""
                        ) : (
                          <XButton
                            dark={true}
                            link={`/prizes`}
                            size={"small"}
                            variant={"outlined"}
                            title={
                              <Resource
                                tag={"PageDetailChallenge::Button_Follow"}
                              />
                            }
                            textUppercase={true}
                          />
                        )}
                        {item?.allowSubmitIdea ? (
                          <Fab
                            size="small"
                            color="secondary"
                            style={{
                              position: "absolute",
                              top: 0,
                              zIndex: 40,
                            }}
                            onClick={() => {
                              history?.push(`/createIdea/${item?.challengeId}`);
                            }}
                          >
                            <AddIcon style={{ color: "#FFF" }} />
                          </Fab>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </>
                  }
                  backgroundImage={item?.image}
                />
              );
            })}
        </XCarousel>
      </Grid>
    </Box>
  );
}
