import "./Profile.css";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import ukIcon from "assets/img/ukIcon.svg";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { PhotoCamera } from "@material-ui/icons";
import spainIcon from "assets/img/spainIcon.svg";
import brazilIcon from "assets/img/brazilIcon.svg";
import romeniaIcon from "assets/img/romeniaIcon.svg";
import Resource from "components/Resources/Resource";
import portugalIcon from "assets/img/portugalIcon.svg";
import ProfilePageSkeleton from "./ProfilePage.Skeleton";
import { IProfileData } from "redux/reducers/profileReducer";
import React, { Fragment, useEffect, useState } from "react";
import { ICultureState } from "redux/reducers/cultureReducer";
import GenericField from "components/GenericField/GenericField";
import { IApplication, ITenant } from "redux/reducers/tenantReducer";
import { setCultureAndResources } from "redux/actions/CultureActions";
import { IResourcesAction } from "../../redux/actions/ResourcesAction";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Fab,
  Paper,
  TextField,
  Typography,
  Avatar,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
  FormControl,
  Link,
} from "@material-ui/core";
import {
  ICountries,
  IDepartments,
  ITimeZones,
} from "redux/reducers/usersReducers";
import {
  getAllTimeZones,
  getAllDepartaments,
  getAllCountries,
  ITimeZonesSucceededGetAllAction,
  IDepartmentsSucceededGetAllAction,
  ICountriesSucceededGetAllAction,
} from "redux/actions/UsersAction";
import {
  IProfileGetAllAction,
  IProfileGetDataAction,
  IProfileUpdateAction,
  getProfile,
  getProfileData,
  updateProfile,
} from "redux/actions/ProfileActions";
import history from "routes/history";

interface IDispatchProps {
  getProfileData: () => Promise<IProfileGetDataAction>;
  updateProfile: (
    params: object,
    options: object
  ) => Promise<IProfileUpdateAction>;
  getProfile: (params: object) => Promise<IProfileGetAllAction>;
  getAllDepartaments: (
    params: object
  ) => Promise<IDepartmentsSucceededGetAllAction>;
  getAllCountries: (params: object) => Promise<ICountriesSucceededGetAllAction>;
  setCultureAndResources: (params: string) => Promise<IResourcesAction>;
  getAllTimeZones: (
    options: object
  ) => Promise<ITimeZonesSucceededGetAllAction>;
}

interface IProps {
  profileData: IProfileData;
  application: IApplication;
  cultureState: ICultureState;
  countries: ICountries[];
  departments: IDepartments[];
  activeTenant: ITenant;
  timeZones: ITimeZones[];
  permission: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      "& label": {
        color: theme.palette.text.secondary,
        textTransform: "uppercase",
        fontWeight: "bold",
      },
    },
    cardGrid: {
      margin: "0 auto",
    },
    card: {
      position: "relative",
      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
      borderRadius: 10,
    },
    cardHeader: {
      width: "160px",
      margin: "0 auto",
      padding: "46px 0",
    },
    avatar: {
      width: 160,
      height: 160,
    },
    cardHeaderButton: {
      position: "absolute",
      top: "35%",
      right: 16,
      width: 46,
      height: 46,
    },
    fabBtnPhoto: {
      width: 46,
      height: 46,
      backgroundColor: theme.palette.background.default,
    },
    photoCameraIcon: {
      color: theme.palette.primary.light,
    },
    userNameText: {
      marginRight: 2,
      color: theme.palette.text.secondary,
      padding: "0px",
      textTransform: "uppercase",
    },
    languageText: {
      color: theme.palette.text.secondary,
      fontWeight: 600,
      fontSize: 12,
      marginLeft: 23,
    },
    cardUserName: {
      padding: "0px",
    },
    selectLabel: {
      marginTop: 16,
      fontSize: "0.8rem",
    },
    selectInputs: {
      marginBottom: 8,
    },
    customFieldsBox: {
      marginTop: 72,
    },
  })
);

const Profile = (props: IProps & IDispatchProps) => {
  const classes = useStyles();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState<any>(props.profileData.photo);
  // const [passwordValue, setPasswordValue] = useState("");
  // const [cPasswordValue, setCPasswordValue] = useState("");
  const [cultureTag, setCultureTag] = useState("");
  const {
    profileData,
    departments,
    countries,
    activeTenant,
    timeZones,
    cultureState,
    permission
  } = props;
  const userName = profileData?.userName;
  const possibleCultures = profileData?.possibleCultures;
  const customFields = profileData?.customFields;

  useEffect(() => {
    let isMounted = true;
    const {
      getAllCountries,
      getAllDepartaments,
      getProfileData,
      getAllTimeZones,
    } = props;

    isMounted &&
      Promise.all([
        getAllTimeZones({ culture: cultureState }),
        getAllCountries({
          culture: cultureState,
          cultureTag: cultureState.culture.cultureTag,
        }),
        getAllDepartaments({
          culture: cultureState,
          cultureTag: cultureState.culture.cultureTag,
        }),
        getProfileData(),
      ]).finally(() => isMounted && setLoading(false));
    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.getProfileData().then(() => {
      setPhoto(props.profileData.photo);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData.photo]);

  const handlePhotoChange = async (e: any) => {
    const toBase64 = (file: any) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const file = e.target.files[0];
    toBase64(file).then((data) => setPhoto(data));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoadingSubmit(true);
    const form = e.target;
    const data = new FormData(form);
    const { customFields } = props.profileData;
    const { updateProfile, getProfileData, cultureState } = props;

    //The password must have numbers, letters, capital letters, special characters and at least 8 characters.
    // if(!(password.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})") != null)){
    //   setMsgError(lang.Login.Error.ForcePassword)
    //   return
    // }

    // if(password !== confirmPassword){
    //   setConfirmPasswordError(true)
    //   return
    // }else(
    //   setConfirmPasswordError(false)
    // )

    let customFieldsValues = {};
    customFields?.map((x) => {
      const newObjNode = {
        [x.customField.columnName]: sanitizeHtml(
          data.get(x.customField.columnName)?.toString()!
        ),
      };
      Object.assign(customFieldsValues, {
        ...customFieldsValues,
        ...newObjNode,
      });
      return "";
    });

    let newValues = {
      fullName: sanitizeHtml(data.get("fullName")?.toString()!),
      email: sanitizeHtml(
        data.get("email") !== null
          ? data.get("email")?.toString()!
          : profileData?.email
      ),
      phone: sanitizeHtml(data.get("phone")?.toString()!),
      department: data.get("department"),
      country: data.get("country"),
      timezone: data.get("timezone"),
      customFields,
    };

    if (photo !== props.profileData.photo && photo !== "") {
      newValues = { ...newValues, ...{ photo } };
    }

    if (cultureTag === "") {
      newValues = {
        ...newValues,
        ...{ cultureTag: cultureState.culture.cultureTag },
      };
    } else {
      newValues = { ...newValues, ...{ cultureTag } };
    }

    let params;

    params = {
      ...newValues,
      customFields: { ...customFieldsValues },
    };

    updateProfile(params, { cultureState }).then((x) => {
      const { getProfile, application, cultureState } = props;
      const options = {
        applicationId: String(application.applicationId),
        culture: cultureState,
      };

      getProfileData().then(() => {
        getProfile(options);
      });
    });
    setLoadingSubmit(false);
  };

  const getCultureFlag = (culture: any) => {
    switch (culture) {
      case "en-GB":
        return ukIcon;
      case "pt-PT":
        return portugalIcon;
      case "es-ES":
        return spainIcon;
      case "pt-BR":
        return brazilIcon;
      case "ro-RO":
        return romeniaIcon;
      default:
        return "";
    }
  };

  const returnRandomString = () => Math.random().toString(36);

  const [formKey, setFormKey] = useState<any>(returnRandomString());

  const getNewKey = () => {
    setFormKey(returnRandomString());
  };

  return (
    <>
      {cultureState?.statusCulture === "SUCCEEDED" ? (
        loading ? (
          <ProfilePageSkeleton />
        ) : (
          <form key={formKey} onReset={getNewKey} onSubmit={onSubmit}>
            <Container className="profile-page">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={5} className={classes.cardGrid}>
                  <Box className="profile-left-container">
                    <Card elevation={0} className={classes.card}>
                      <CardHeader
                        className={classes.cardHeader}
                        avatar={
                          <Avatar
                            alt={userName}
                            src={photo}
                            className={classes.avatar}
                            key={profileData?.userName}
                          />
                        }
                        action={
                          <Box className={classes.cardHeaderButton}>
                            <input
                              id="userThumbnail-fileInput"
                              onChange={handlePhotoChange}
                              type="file"
                            />
                            <label htmlFor="userThumbnail-fileInput">
                              <Fab
                                component="span"
                                className={classes.fabBtnPhoto}
                              >
                                <PhotoCamera
                                  className={classes.photoCameraIcon}
                                />
                              </Fab>
                            </label>
                          </Box>
                        }
                      />
                      <CardContent className={classes.cardUserName}>
                        <Typography component="p" align="center">
                          <Box
                            component="span"
                            display="inline"
                            className={classes.userNameText}
                            mr={1}
                          >
                            <Resource tag={"PageProfile::UserName"} />:
                          </Box>
                          <Box component="span" display="inline" mr={1}>
                            {userName}
                          </Box>
                        </Typography>
                      </CardContent>
                    </Card>
                    <Paper elevation={0} className="profile-possible-cultures">
                      <Typography
                        component="p"
                        className={classes.languageText}
                      >
                        <Resource tag={"PageProfile::Language"} />
                      </Typography>
                      {possibleCultures &&
                        possibleCultures?.map((x) => {
                          const activeCultureTag = cultureTag
                            ? cultureTag
                            : profileData?.cultureTag;
                          return (
                            <div
                              key={x?.cultureTag}
                              className={
                                "cultureBtnProfile" +
                                (x?.cultureTag === activeCultureTag
                                  ? " active"
                                  : "")
                              }
                              onClick={() => setCultureTag(x?.cultureTag)}
                            >
                              <img
                                alt={cultureTag}
                                src={getCultureFlag(x?.cultureTag)}
                              />
                              {x?.cultureTag?.toLocaleUpperCase()}
                            </div>
                          );
                        })}
                      <Box mt={2}>
                        {profileData?.urlPrivacyPolicy && (
                          <Box>
                            <Link
                              color="primary"
                              href={profileData?.urlPrivacyPolicy}
                              target="_blank"
                            >
                              <Resource tag="PageProfile::Policy" />
                            </Link>
                          </Box>
                        )}

                        {profileData?.urlTermsOfService && (
                          <Box mt={0.5}>
                            <Link
                              color="primary"
                              href={profileData?.urlTermsOfService}
                              target="_blank"
                            >
                              <Resource tag="PageProfile::Terms" />
                            </Link>
                          </Box>
                        )}
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                  <FormControl
                    style={{ width: "100%" }}
                    className={classes.form}
                  >
                    <TextField
                      id="fullName"
                      label={<Resource tag={"PageProfile::Input_Name"} />}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      required
                      defaultValue={
                        profileData?.fullName ? profileData?.fullName : ""
                      }
                      name="fullName"
                      disabled={activeTenant?.hasSSO}
                    />
                    <TextField
                      id="email"
                      label={<Resource tag={"PageProfile::Input_Email"} />}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      required
                      defaultValue={
                        profileData?.email ? profileData?.email : ""
                      }
                      className="profile-email-input"
                      name="email"
                      disabled={activeTenant?.hasSSO}
                    />
                    <TextField
                      id="phone"
                      label={
                        <>
                          {<Resource tag={"PageProfile::Input_Phone"} />}
                          &nbsp;
                          {<Resource tag={"PageNewIdea::Optional"} />}
                        </>
                      }
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={
                        profileData?.phone ? profileData?.phone : ""
                      }
                      className="profile-phone-input"
                      name="phone"
                      type="number"
                    />
                    <TextField
                      select
                      label={<Resource tag={"PageProfile::Timezone"} />}
                      name="timezone"
                      id="timezone"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      defaultValue={
                        profileData?.timezone ? profileData?.timezone : ""
                      }
                    >
                      {timeZones?.map((timeZone) => (
                        <MenuItem key={timeZone?.timeZoneId} value={timeZone?.timeZoneId}>
                          {timeZone?.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    {/*
                   {!activeTenant.hasSSO && 
                    <Box mt={9} mb={9}>
                      <PasswordInputMui
                        password={passwordValue}
                        showPassword={false}
                        handler={setPasswordValue}
                      />
                      <PasswordInputMui
                        confirmP={true}
                        password={cPasswordValue}
                        showPassword={false}
                        handler={setCPasswordValue}
                      />
                    </Box>
                  } 
                  */}

                    {countries?.length > 0 ? (
                      <>
                        <TextField
                          select
                          label={
                            <Resource tag={"PageProfile::Input_Country"} />
                          }
                          name="country"
                          id="country"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          className={classes.selectInputs}
                          defaultValue={
                            profileData?.country ? profileData?.country : ""
                          }
                        >
                          {countries &&
                            countries?.map((country) => (
                              <MenuItem
                                key={country?.countryId}
                                value={country?.countryId}
                              >
                                {country?.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </>
                    ) : (
                      <></>
                    )}

                    {departments?.length > 0 ? (
                      <>
                        <TextField
                          disabled={permission !== "Admin" && permission !== "ChallengeManager"}
                          select
                          label={
                            <Resource tag={"PageProfile::Input_Department"} />
                          }
                          name="department"
                          id="department"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          className={classes.selectInputs}
                          defaultValue={
                            profileData?.department
                              ? profileData?.department
                              : ""
                          }
                        >
                          {departments &&
                            departments?.map((department) => (
                              <MenuItem
                                key={department?.departmentId}
                                value={department?.departmentId}
                              >
                                {department?.title}
                              </MenuItem>
                            ))}
                        </TextField>
                      </>
                    ) : (
                      <></>
                    )}
                    {customFields &&
                      customFields?.map((x) => (
                        <Fragment key={x?.customField?.columnName}>
                          <GenericField
                            value={x?.value ? x?.value : ""}
                            input={x?.customField}
                            noFutureDate
                          />
                        </Fragment>
                      ))}

                    <Box
                      marginTop={5}
                      display="flex"
                      gridGap={15}
                      alignSelf="flex-end"
                    >
                      <Button
                        id="ProfilePageButtonReset"
                        type="reset"
                        color="primary"
                        onClick={() => history?.goBack()}
                      >
                        <Resource tag={"PageProfile::Cancel"} />
                      </Button>
                      <Button
                        id="ProfilePageButtonSave"
                        type="submit"
                        variant={!loadingSubmit ? "contained" : "outlined"}
                        color="primary"
                        endIcon={
                          loadingSubmit && (
                            <CircularProgress size={22} className="icon" />
                          )
                        }
                      >
                        <Resource tag={"PageProfile::SaveChanges"} />
                      </Button>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
          </form>
        )
      ) : (
        <ProfilePageSkeleton />
      )}
    </>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    application: store.tenantState.application,
    cultureState: store.cultureState,
    profileData: store.profileState.profileData,
    departments: store.usersState.departaments,
    countries: store.usersState.countries,
    activeTenant: store.tenantState.activeTenant,
    timeZones: store.usersState.timeZones,
    permission: store.authState.auth.permission,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getProfileData: () => dispatch(getProfileData()),
    updateProfile: (params: object, options: object) =>
      dispatch(updateProfile(params, options)),
    getProfile: (params: object) => dispatch(getProfile(params)),
    getAllDepartaments: (params: object) =>
      dispatch(getAllDepartaments(params)),
    getAllCountries: (params: object) => dispatch(getAllCountries(params)),
    setCultureAndResources: (params: string) =>
      dispatch(setCultureAndResources(params)),
    getAllTimeZones: (options: object) => dispatch(getAllTimeZones(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
