
// Import Reducer type
import { Reducer } from 'redux';
import {
    CultureActions,
  CultureActionTypes,
} from '../actions/CultureActions';

// Define the Character type
export interface ICulture {
  cultureTag:string,
  resources: Array<any>
}

// Define the Character State
export interface ICultureState {
  readonly culture: ICulture;
  readonly statusCulture: string;
  readonly statusCultureDefault: string;
}

// Define the initial state
const initialCharacterState: ICultureState = {
  culture: {
    cultureTag:'pt-PT', 
    resources:[]
  },
  statusCulture: '',
  statusCultureDefault: ''
};

export const cultureReducer: Reducer<ICultureState, CultureActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case CultureActionTypes.GET_CULTURE: {
      return {
        ...state,
        culture: action.culture,
        statusCulture: action.statusCulture,
        statusCultureDefault: action.statusCultureDefault
      };
    }
    default:
      return state;
  }
};