import { AnyAction } from "redux";
import { connect } from "react-redux";
import XDialog from "common/X/XDialog";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import Resource from "components/Resources/Resource";
import { IUsers } from "redux/reducers/usersReducers";
import { getAllUsers } from "redux/actions/UsersAction";
import {
  IDynamicWorkflow,
  PageState,
  ValidationPopupState,
} from "redux/types/createChallenge";
import { ICultureState } from "redux/reducers/cultureReducer";
import React, { useCallback, useEffect, useState } from "react";
import WorkflowValidator from "components/Workflow/WorkflowDialogsContent/Validator/Validator";
import BasicSettings from "components/Workflow/WorkflowDialogsContent/BasicSettings/BasicSetting";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  getChallengeDetailValidationAction,
  getWorkflowAction,
  putChallengeDetailValidationPhaseAction,
} from "redux/actions/Workflow/WorkflowActions";
import { v4 as uuidv4 } from 'uuid';
import { getCreateChallengeDetail } from "redux/actions/CreateChallenge/CreateChallengeActions";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => any;
  state: ValidationPopupState;
  users: IUsers[];
  culture: ICultureState;
  saveValue: (value: any) => void;
  pageState: PageState;
  phaseId: string;
  workspaceId: string;
  challengeId: string;
  order: number;
  dynamicWorkflow: IDynamicWorkflow;
}

interface IDispatchProps {
  getWorkflow: (options: object, culture: ICultureState) => Promise<any>;
  getUserList: (options: object, culture: ICultureState) => Promise<any>;
  getValidationDetails: (
    options: object,
    culture: ICultureState
  ) => Promise<any>;
  getChallengeDetail: (
    options: object,
    culture: ICultureState
  ) => Promise<any>;
  putValidationPhase: (options: object, culture: ICultureState) => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    item: {
      borderRadius: "50px 0px 0px 50px;",
      paddingTop: "5px",
      paddingBottom: "5px",
      marginBottom: "10px",
    },
    selectedItem: {
      borderRadius: "50px 0px 0px 50px;",
      marginBottom: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      background: theme.palette.primary.light,
      "& div": {
        "& span": {
          "& span": {
            fontWeight: 500,
          },
        },
      },
    },
  })
);

function PopupWorkflowValidation({
  open,
  setOpen,
  users,
  getUserList,
  culture,
  state,
  saveValue,
  pageState,
  getValidationDetails,
  getChallengeDetail,
  phaseId,
  workspaceId,
  putValidationPhase,
  challengeId,
  getWorkflow,
  order,
  dynamicWorkflow,
}: IProps & IDispatchProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getValidationDetails({ phaseId, workspaceId }, culture).then(() =>
      setLoading(false)
    );
    // eslint-disable-next-line
  }, []);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [currentScreen, setCurrentScreen] = useState(2);
  const [localState, setLocalState] = useState(state);
  const [localStateDynamic, setLocalStateDynamic] = useState(state);
  const [mapAddItem, setMapAddItem] = useState<string[]>([]);
  //@ts-ignore
  const [radioType, setRadioType] = useState<string>('');

  useEffect(() => {
    setLocalState(state);
  }, [state, setLocalState]);

  useEffect(() => {
    setLocalStateDynamic(state);
  }, [state, setLocalStateDynamic]);

  const action = (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={1}>
        <Button color="primary" onClick={() => setOpen(false)}>
          <Resource tag="PageCreateChallenge::Workflow_Cancel" />
        </Button>
      </Box>
      <Box>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            savePopupContent();
            setOpen(false);
          }}
        >
          <Resource tag="PageCreateChallenge::Workflow_SaveChanges" />
        </Button>
      </Box>
    </Box>
  );

  const savePopupContent = () => {
    const values = {
      ...localState.basicSettings,
      cultures: [
        {
          description: localState.basicSettings.description,
          title: localState.basicSettings.title,
          cultureTag: culture.culture.cultureTag,
        },
      ],
    };


    let newResponsibles: any = [];
    localState?.validators?.forEach((user) => {
      newResponsibles.push(user.userId)
    })

    const newValidators = dynamicWorkflow.optionSelect.map((opt) => {
      return {
        ideaCustomFieldId: opt.ideaCustomFieldId || '',
        value: opt.ideaCustomFieldIdValue || '',
        //@ts-ignore      
        users: opt?.users?.map(user => user.userId),
      };
    });

    const newIsValidatorDinamic = radioType === 'radio2' ? true : false;

    //@ts-ignore
    values.isValidatorDinamic = newIsValidatorDinamic;

    //@ts-ignore
    values.validatorsDinamic = newIsValidatorDinamic ? newValidators : [];

    //@ts-ignore
    values.responsibles = newIsValidatorDinamic ? [] : newResponsibles;

    putValidationPhase(
      {
        ...values,
        phaseId,
        workspaceId,
        challengeId,
        order,
      },
      culture
    ).then((status) => {
      if (status === "PUT_WF_PHASE_VALIDATION_SUCCEEDED") {
        setOpen(false);
        getWorkflow({ challengeId, workspaceId, culture }, culture);
        getDataValidationDynamic();
      }
    });
  };

  useEffect(() => {
    getUserList({ q: "" }, culture);
  }, [getUserList, culture]);

  const setLocalStateBasicSettings = useCallback(
    (newValue) => setLocalState((prev: any) => ({ ...prev, basicSettings: newValue })),
    []
  );

  const setLocalStateValidator = useCallback(
    (newValue) => setLocalState((prev: any) => ({ ...prev, validators: newValue })),
    []
  );



  const getDataValidationDynamic = () => {
    getValidationDetails({ phaseId, workspaceId }, culture).then((response) => {
      const dynamicValidators = response.validatorsDinamic;
      //@ts-ignore
      setRadioType(response.isValidatorDinamic ? 'radio2' : 'radio1');
      let mapItem: string[] = [];
      dynamicWorkflow.optionSelect = dynamicValidators.map((dyn: any) => {
        const uuid = uuidv4();
        //@ts-ignore
        mapItem.push(uuid);
        return {
          optionOrder: uuid,
          userId: '',
          ideaCustomFieldId: dyn.ideaCustomFieldId,
          ideaCustomFieldIdValue: dyn.value,
          users: dyn.users
        }
      });
      setMapAddItem(mapItem);

    });
  };

  useEffect(() => {
    getDataValidationDynamic();
    getChallengeDetail({ challengeId, workspaceId }, culture);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getCurrentScreen = () => {
    switch (currentScreen) {
      case 1:
        return (
          <BasicSettings
            fatherState={localState.basicSettings}
            setFatherState={setLocalStateBasicSettings}
            stepsState={pageState}
            order={order}
          />
        );
      case 2:
        return (
          <WorkflowValidator
            radioType={radioType}
            setRadioType={setRadioType}
            users={users}
            culture={culture}
            fatherState={localState.validators}
            setFatherState={setLocalStateValidator}
            mapAddItem={mapAddItem}
            setMapAddItem={setMapAddItem}
          />
        );
      default:
        return <Box></Box>;
    }
  };

  return (
    <XDialog
      title={<Resource tag="PageCreateChallenge::Workflow_EditPhase" />}
      maxWidth="md"
      open={open}
      setOpen={setOpen}
      height={"550px"}
      actions={action}
      py={1.5}
      px={1.5}
    >
      {!loading ? (
        <Grid container>
          <Grid item xs={3} sm={3} md={3} xl={3}>
            <List disablePadding>
              <ListItem
                button
                id="SideBarWorkflow"
                onClick={() => setCurrentScreen(1)}
                className={
                  currentScreen === 1 ? classes.selectedItem : classes.item
                }
              >
                <ListItemText className="text-button-sidebar">
                  <Resource tag="PageCreateChallenge::Workflow_BasicSettings" />
                </ListItemText>
              </ListItem>
              <ListItem
                button
                id="SideBarWorkflow"
                onClick={() => setCurrentScreen(2)}
                className={
                  currentScreen === 2 ? classes.selectedItem : classes.item
                }
              >
                <ListItemText className="text-button-sidebar">
                  <Resource tag="PageCreateChallenge::Workflow_Validator" />
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={1} sm={1} md={1} xl={1} style={{ maxWidth: "4%" }}>
            <Divider orientation={"vertical"} />
          </Grid>
          <Grid item xs={8} sm={8} md={8} xl={8}>
            <Box textAlign="right">
              <Typography color="textSecondary" variant="caption">
                <Resource tag="PageCreateChallenge::Workflow_TypeOfPhase" />
                <strong style={{ marginLeft: "8px", fontWeight: 500 }}>
                  <Resource tag="PageCreateChallenge::Workflow_Validation" />
                </strong>
              </Typography>
            </Box>
            {getCurrentScreen()}
          </Grid>
        </Grid>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      )}
    </XDialog>
  );
}

const mapStateToProps = (store: IAppState) => ({
  users: store.usersState.users,
  culture: store.cultureState,
  pageState: store.createChallengeState.pageState,
  state: store.createChallengeState.validationPopupState,
  workspaceId: store.authState.auth.workspaceId,
  dynamicWorkflow: store.createChallengeState.dynamicWorkFlow,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  getUserList: (options: object, culture: ICultureState) =>
    dispatch(getAllUsers(options, culture)),
  getValidationDetails: (options: object, culture: ICultureState) =>
    dispatch(getChallengeDetailValidationAction(options, culture)),
  putValidationPhase: (options: object, culture: ICultureState) =>
    dispatch(putChallengeDetailValidationPhaseAction(options, culture)),
  getWorkflow: (options: object, culture: ICultureState) =>
    dispatch(getWorkflowAction(options, culture)),
  getChallengeDetail: (newValue: any, culture: ICultureState) =>
    dispatch(getCreateChallengeDetail(newValue, culture))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupWorkflowValidation);
