import "./CommentItem.scss";
import newApp from "utils/reactNewApp";
import XDialog from "common/X/XDialog";
import TimeFrom from "../TimeFrom/TimeFrom";
import LikeIcon from "../LikeIcon/LikeIcon";
import Resource from "../Resources/Resource";
import Editor from "draft-js-plugins-editor";
import XBoxMentions from "common/X/XBoxMentions";
import "components/CommentList/StylesMentions.css";
import { IUsers } from "redux/reducers/usersReducers";
import { IProfile } from "redux/reducers/profileReducer";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import { PositionSuggestionsParams } from "@draft-js-plugins/mention/lib/utils/positionSuggestions";
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Button,
  Box,
  ClickAwayListener,
  TextField,
  MenuItem,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
  MentionPluginTheme,
} from "@draft-js-plugins/mention";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnReply: {
      background: 0,
      border: 0,
      color: "#777",
      fontWeight: 600,
      cursor: "pointer",
      fontSize: "14px",
      outline: 0,
    },
    btnReplyActive: {
      background: 0,
      border: 0,
      fontWeight: 600,
      cursor: "pointer",
      fontSize: "14px",
      outline: 0,
      color: theme.palette.primary.main,
    },
    commentAvatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    thumbnail: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    buttonAdded: {
      maxHeight: "20px",
      maxWidth: "100%",
      padding: "5px 5px",
      borderRadius: "4px",
      "&:last-of-type": {
        marginLeft: "auto",
        textTransform: "uppercase",
      },
    },
    boxAdded: {
      maxHeight: "20px",
      maxWidth: "100%",
      padding: "5px 5px",
      borderRadius: "4px",
      "&:last-of-type": {
        marginLeft: "auto",
      },
      backgroundColor: theme.palette.secondary.main,
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      color: "white",
    },
    menuItemReport: {
      minHeight: 30,
      borderRadius: 10,
    },
    BoxMention: {
      width: "20%",
      display: "flex",
      position: "absolute",
      zIndex: 2,
      backgroundColor: theme.palette.common.white,
      overflow: "scroll",
      maxHeight: 300,
      boxShadow: "0px 2px 10px -1px rgba(0, 0, 0, 0.2)",
      borderRadius: 10,
      padding: 8,
    },
    editor: {
      "& .DraftEditor-root": {
        display: "flex",
        position: "relative",
        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
      },
      "& .public-DraftEditorPlaceholder-root": {
        display: "flex",
        position: "absolute",
        /* top: 5px, */
        backgroundColor: "#FFF",
        color: "#838383",
      },
      "& .DraftEditor-editorContainer": {
        display: "flex",
        position: "relative",
        paddingLeft: 0.5,
        /* top: 5px, */
      },
      "& .public-DraftEditor-content": {
        maxWidth: 625,
      },
      "& .DraftEditor-editorContainer .m6zwb4v": {
        color: theme.palette.primary.main,
      },
    },
  })
);

export interface EntryComponentProps {
  className?: string;
  role: string;
  id: string;
  "aria-selected"?: boolean | "false" | "true";
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
  searchValue?: string;
}

const positionSuggestions = ({
  props,
}: PositionSuggestionsParams): React.CSSProperties => {
  let transform;
  let transition;

  if (props?.open && props?.suggestions?.length > 0) {
    transform = "scaleY(1)";
    transition = "all 0.25s cubic-bezier(.3,1.2,.2,1)";
  } else if (props?.open) {
    transform = "scaleY(0)";
    transition = "all 0.25s cubic-bezier(.3,1,.2,1)";
  }

  return {
    transform,
    transition,
  };
};

interface IProps {
  commentId: string;
  userId?: string;
  fullName?: string;
  photo?: string;
  timeAgo?: string;
  timeStamp?: string;
  message?: string;
  isLikedByMe: boolean;
  likesCount?: number;
  replies?: any;
  isAddedValue?: boolean;
  timeStampResource?: string;
  postLikeComment: (commentId: string, userLiked: boolean) => void;
  postAddedValue?: (commentId: string) => void;
  postReply?: (reply: string) => void;
  putComment?: (
    commentId: string,
    text: string,
    typeF: number,
    replyId?: string
  ) => void;
  replyHandler?: any;
  isAuthor?: boolean;
  highlight?: boolean;
  isAllowedComment?: boolean;
  profile?: IProfile;
  users?: IUsers[];
  canShowMenu?: boolean;
}

function CommentItem(props: IProps) {
  const classes = useStyles();
  const {
    // isAddedValue,
    commentId,
    fullName,
    photo,
    message,
    isLikedByMe,
    likesCount,
    timeStamp,
    replies,
    timeAgo,
    highlight,
    isAllowedComment,
    timeStampResource,
    profile,
    userId,
    postReply,
    putComment,
    replyHandler,
    postLikeComment,
    canShowMenu = true,
  } = props;
  // const [addValue, setAddValue] = useState(isAddedValue!)
  let keyTimer: any;
  let waitTimeAfterType = 1000;
  let timeStampResources = timeStampResource?.replace(".", "::");
  const [commentIdEdit, setCommentIdEdit] = useState("");
  const [commentValue, setCommentValue] = useState("");
  const [replyId, setReplyId] = useState("");
  const [typeF, setTypeF] = useState(0);
  const [likesCounter, setLikesCount] = useState(likesCount!);
  const [userLiked, setLiked] = useState(isLikedByMe!);
  const [reportSelected, setReportSelected] = useState(0);

  //for NO post when click on Enter button
  const handleEnter = (e: any) => {
    if (e?.keyCode === 13) {
      postReply!(commentValue);
    } else if (e?.keyCode === 27) {
      setEdit("");
    }
  };

  // open dialogs
  const [openDeleteCommentDialog, setOpenDeleteCommentDialog] = useState(false);
  const [openReportCommentDialog, seOpenReportCommentDialog] = useState(false);

  // change background class to active(color)
  const [replyOn, setReplyOn] = useState(false);
  const replySwitch = () => {
    setReplyOn(!replyOn);
  };

  // open input reply comment
  const [openReply, setOpenReply] = useState(false);
  const handleOpenInputReply = () => {
    setOpenReply((prev) => !prev);
    replyHandler(commentId);
  };

  //close input area of reply comment when click outside
  const handleClickAway = () => {
    setOpenReply(false);
    setReplyOn(false);
  };

  // post like
  const handleLike = () => {
    commentId && postLikeComment(commentId, userLiked);
    if (userLiked === true) {
      setLikesCount(likesCounter - 1);
      setLiked(!userLiked);
    } else {
      setLikesCount(likesCounter + 1);
      setLiked(!userLiked);
    }
  };

  // put input area
  const handleChange = (e: any) => {
    setCommentValue(e?.target?.value);
  };

  //functions to mentions
  const ref = useRef<Editor>(null);
  const [openMention, setOpenMention] = useState(false);
  const [, /*commentSend*/ setCommentSend] = useState({
    text: "",
    mentionRange: {},
    mentions: {},
  });
  let newUsers: MentionData[] = props?.users?.map((x) => ({
    userId: x?.userId,
    name: x?.fullName,
    avatar: x?.photo,
  }))!;
  const [suggestions, setSuggestions] = useState(newUsers);
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      //theme: mentionsStyles,
      positionSuggestions,
      mentionPrefix: "@",
      supportWhitespace: true,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);
  const [editorState, setEditorState] = useState(() =>
    EditorState?.createEmpty()
  );
  function Entry(props: EntryComponentProps): ReactElement {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
      isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...parentProps
    } = props;

    return (
      <div {...parentProps}>
        <Box>
          <Grid container style={{ maxWidth: "650px" }} key={mention?.id}>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Button fullWidth>
                <Grid container spacing={2}>
                  {mention?.avatar && (
                    <Grid item xs={2} sm={2} md={2} xl={2} className="avatar">
                      <Avatar src={mention?.avatar} alt={mention?.name} />
                    </Grid>
                  )}
                  <Grid item xs={10} sm={9} md={8} xl={8}>
                    <Box ml={2} className="options-name-subtitle">
                      <Typography>{mention?.name}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
  const onChange = useCallback((_editorState: EditorState) => {
    const contentState = _editorState?.getCurrentContent();
    const raw = convertToRaw(contentState);

    const createArrayMentions = Object?.values(raw?.entityMap);

    let newMentions = createArrayMentions?.map((x) => x?.data?.mention);

    setCommentSend({
      text: raw?.blocks[0]?.text,
      mentionRange: raw?.blocks[0]?.entityRanges,
      mentions: newMentions,
    });

    setEditorState(_editorState);
    setCommentValue(raw?.blocks[0]?.text);

    // eslint-disable-next-line
  }, []);
  const onOpenChange = useCallback((_open: boolean) => {
    setOpenMention(_open);
  }, []);
  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(defaultSuggestionsFilter(value, newUsers));

    // eslint-disable-next-line
  }, []);
  function clearStateEditor() {
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      setEditorState(() => EditorState?.createEmpty());
    }, waitTimeAfterType);
  }

  // select comment or reply type
  const [type, setType] = useState("");
  const [edit, setEdit] = useState("");
  function handleGetCommentEdit(
    commentId: string,
    message: string,
    edit: string
  ) {
    setCommentValue(message);
    setCommentIdEdit(commentId);
    setEditorState(
      EditorState?.createWithContent(ContentState?.createFromText(message))
    );
    setType("Edit");

    if (edit === "comment") {
      setEdit(edit);
    } else if (edit === "reply") {
      setEdit(edit);
    }

    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      setType("");
    }, waitTimeAfterType);
  }

  //buton save - put and post
  const actions = (
    <Button
      color="primary"
      onClick={() => {
        putComment && putComment(commentIdEdit, commentValue, typeF);
        clearStateEditor();
      }}
      disabled={!commentValue}
    >
      <Resource tag={"CommentsArea::Save"} />
    </Button>
  );

  //delete comment and reply
  function handleDeleteComment() {
    putComment && putComment(commentIdEdit, commentValue, typeF, replyId);
  }

  //ADD button after function is ok
  // const handleAddValue = () => {
  //   if (postAddedValue) {
  //     commentId && postAddedValue(commentId)
  //     if (addValue) {
  //       setAddValue(false)
  //     } else {
  //       setAddValue(true)
  //     }
  //   }
  // }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className={
            "CommentItem" +
            (replyOn ? " active" : "") +
            (highlight ? " highlight" : "")
          }
        >
          {edit === "comment" ? (
            <>
              <Avatar
                src={profile?.photo}
                alt={profile?.fullName}
                className={classes.thumbnail}
              />
              <Box
                className="comment-input"
                width="80%"
                style={{ marginLeft: "40px", marginTop: "-25px" }}
              >
                <XBoxMentions actions={actions}>
                  {newApp ? (
                    <>
                      <div
                        style={{ width: "100%" }}
                        //className={editorStyles.editor}
                        onClick={() => {
                          ref?.current!?.focus();
                        }}
                      >
                        <Box className={classes.editor}>
                          <Editor
                            editorKey={"editor"}
                            editorState={editorState}
                            onChange={onChange}
                            plugins={plugins}
                            ref={ref}
                          />
                        </Box>

                        <Box className={openMention ? classes.BoxMention : ""}>
                          <MentionSuggestions
                            open={openMention}
                            onOpenChange={onOpenChange}
                            suggestions={suggestions}
                            onSearchChange={onSearchChange}
                            onAddMention={() => {
                              // get the mention object selected
                            }}
                            entryComponent={Entry}
                          />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        style={{ width: "100%" }}
                        disabled={!isAllowedComment}
                        onChange={handleChange}
                        onKeyUp={handleEnter}
                        value={commentValue}
                        type="text"
                      />
                    </>
                  )}
                  <Box display="flex">
                    <Typography color="textSecondary">
                      <Resource tag="CommentsArea::EditCommentPressEsc" />
                    </Typography>

                    <Box ml={1} onClick={() => setEdit("")}>
                      <Typography color="primary" style={{ cursor: "pointer" }}>
                        <Resource tag="CommentsArea::EditCommentCancelEdition" />
                      </Typography>
                    </Box>
                  </Box>
                </XBoxMentions>
              </Box>
            </>
          ) : (
            <>
              <div className="comment-content">
                <Avatar
                  alt={fullName && fullName}
                  src={photo && photo}
                  className={classes.commentAvatar}
                />
                <div className="comment-message">
                  <p className="comment-userName">{fullName && fullName}</p>
                  {message && message}
                </div>
                {newApp && canShowMenu ? (
                  <>
                    {userId === profile?.userId ? (
                      <Box mt={0.6} mr={2}>
                        <MoreOptionsButton
                          translate="-72px, 42px"
                          iconColor={"#727C81"}
                          type={type}
                        >
                          <MenuItem
                            onClick={() => {
                              handleGetCommentEdit(
                                commentId,
                                message!,
                                "comment"
                              );
                              setTypeF(1);
                            }}
                          >
                            <Resource tag="Comment::Edit" />
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpenDeleteCommentDialog(true);
                              setCommentIdEdit(commentId);
                              setCommentValue(message!);
                              setTypeF(2);
                              setReplyId("");
                            }}
                          >
                            <Resource tag="Comment::Delete" />
                          </MenuItem>
                        </MoreOptionsButton>
                      </Box>
                    ) : (
                      <Box mt={0.6} mr={2}>
                        {/*TODO Next phase */}
                        {/* <MoreOptionsButton
                          translate="-72px, 42px"
                          iconColor={'#727C81'}
                        >
                          <MenuItem onClick={() => seOpenReportCommentDialog(true)}>
                            <Resource tag="Comment::Report" />
                          </MenuItem>
                        </MoreOptionsButton> */}
                      </Box>
                    )}
                  </>
                ) : (
                  ""
                )}
                <span onClick={handleLike}>
                  <LikeIcon active={userLiked && userLiked} />
                </span>
                <Box width="25%"></Box>
              </div>
              <div className="comment-stats">
                {timeStamp !== "" && timeStamp ? (
                  <TimeFrom time={timeStamp!}></TimeFrom>
                ) : timeAgo?.length! >= 5 ? (
                  <Box minWidth="40px">{timeAgo}</Box>
                ) : (
                  <Box minWidth="40px">
                    <Resource tag={timeStampResources!} args={[timeAgo]} />
                  </Box>
                )}
                {
                likesCounter > 0 && <p>
                  {likesCounter} {
                    likesCounter === 1 ? <Resource tag="CommentsArea::Like" /> :<Resource tag="CommentsArea::Likes" />
                  }
                </p>
                }
                {isAllowedComment && (
                  <Button
                    className={
                      replyOn ? classes.btnReplyActive : classes.btnReply
                    }
                    color="primary"
                    onClick={() => {
                      replySwitch();
                      handleOpenInputReply();
                    }}
                  >
                    <Resource tag="CommentsArea::Reply" />
                  </Button>
                )}

                {/* ADD button after function is ok */}
                {/* {isAuthor ?
                  <Button
                    className={classes.buttonAdded}
                    color='secondary'
                    variant='contained'
                    onClick={() => { !addValue && handleAddValue() }}
                  >
                    {!addValue ? <Resource tag={"PageDetailIdea::CommentsArea_AddButton"} /> : <Resource tag={"PageDetailedIdea::CommentsArea_AddedValue"} />}
                  </Button>
                  :
                  addValue ?
                    <Box className={classes.boxAdded} color='secondary' display="flex" alignItems="center">
                      <Resource tag={"PageDetailedIdea::CommentsArea_AddedValue"} />
                    </Box>
                    :
                    <></>
                } */}
              </div>
            </>
          )}
          {edit === "reply" ? (
            <>
              <Avatar
                src={profile?.photo}
                alt={profile?.fullName}
                className={classes.thumbnail}
              />
              <Box
                className="comment-input"
                width="80%"
                style={{ marginLeft: "40px", marginTop: "-25px" }}
              >
                <XBoxMentions actions={actions}>
                  {newApp ? (
                    <>
                      <div
                        style={{ width: "100%" }}
                        //className={editorStyles.editor}
                        onClick={() => {
                          ref?.current!?.focus();
                        }}
                      >
                        <Box className={classes.editor}>
                          <Editor
                            editorKey={"editor"}
                            editorState={editorState}
                            onChange={onChange}
                            plugins={plugins}
                            ref={ref}
                          />
                        </Box>

                        <Box className={openMention ? classes.BoxMention : ""}>
                          <MentionSuggestions
                            open={openMention}
                            onOpenChange={onOpenChange}
                            suggestions={suggestions}
                            onSearchChange={onSearchChange}
                            onAddMention={() => {
                              // get the mention object selected
                            }}
                            entryComponent={Entry}
                          />
                        </Box>
                      </div>
                    </>
                  ) : (
                    <>
                      <TextField
                        fullWidth
                        style={{ width: "100%" }}
                        disabled={!isAllowedComment}
                        onChange={handleChange}
                        onKeyUp={handleEnter}
                        value={commentValue}
                        type="text"
                      />
                    </>
                  )}
                  <Box display="flex">
                    <Typography color="textSecondary">
                      <Resource tag="CommentsArea::EditCommentPressEsc" />
                    </Typography>

                    <Box ml={1} onClick={() => setEdit("")}>
                      <Typography color="primary" style={{ cursor: "pointer" }}>
                        <Resource tag="CommentsArea::EditCommentCancelEdition" />
                      </Typography>
                    </Box>
                  </Box>
                </XBoxMentions>
              </Box>
            </>
          ) : (
            <div className="replies">
              {replies &&
                replies?.map((reply: any, index: number) => {
                  return (
                    <div className="reply-content" key={index}>
                      <Avatar
                        alt={reply?.userName}
                        src={reply?.photo}
                        className={classes.thumbnail}
                      />
                      <div className="comment-message">
                        <p className="comment-userName">
                          {reply?.fullName ? reply?.fullName : reply?.userName}
                        </p>
                        {reply?.message}
                      </div>
                      {newApp ? (
                        <>
                          {reply?.userId === profile?.userId ? (
                            <Box mt={0.6} mr={2}>
                              <MoreOptionsButton
                                translate="-72px, 42px"
                                iconColor={"#727C81"}
                              >
                                <MenuItem
                                  onClick={() => {
                                    replyHandler(commentId);
                                    handleGetCommentEdit(
                                      reply?.commentId,
                                      reply?.message!,
                                      "reply"
                                    );
                                    setTypeF(1);
                                  }}
                                >
                                  <Resource tag="Comment::Edit" />
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setOpenDeleteCommentDialog(true);
                                    setCommentIdEdit(commentId);
                                    setCommentValue(reply?.message!);
                                    setTypeF(3);
                                    setReplyId(reply?.commentId);
                                  }}
                                >
                                  <Resource tag="Comment::Delete" />
                                </MenuItem>
                              </MoreOptionsButton>
                            </Box>
                          ) : (
                            <Box mt={0.6} mr={2}>
                              {/*TODO Next phase */}
                              {/* <MoreOptionsButton
                                translate="-72px, 42px"
                                iconColor={'#727C81'}
                              >
                                <MenuItem onClick={() => seOpenReportCommentDialog(true)}>
                                  <Resource tag="Comment::Report" />
                                </MenuItem>
                              </MoreOptionsButton> */}
                            </Box>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              {isAllowedComment && openReply && (
                <Box width="90%" className="comment-bar">
                  <Avatar
                    src={profile?.photo}
                    alt={profile?.fullName}
                    className={classes.thumbnail}
                  />
                  <Box className="comment-input">
                    <TextField
                      disabled={!isAllowedComment}
                      onChange={handleChange}
                      onKeyUp={handleEnter}
                      value={commentValue}
                      type="text"
                      fullWidth
                    />
                    {/* TODO: add functionality to this button */}
                    {/* <button onClick={() => {}}>@</button> */}
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => postReply!(commentValue)}
                      disabled={!commentValue}
                    >
                      <Resource tag={"CommentsArea::Post"} />
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          )}
        </div>
      </ClickAwayListener>

      {/* delete comment dialog */}
      <XDialog
        width="800px"
        maxWidth="md"
        open={openDeleteCommentDialog}
        setOpen={setOpenDeleteCommentDialog}
        title={<Resource tag="Comment::TitleDialogDelete" />}
        actions={
          <Box textAlign="right">
            <Button
              color="primary"
              size="small"
              variant="outlined"
              style={{ marginRight: 8 }}
              onClick={() => {
                setOpenDeleteCommentDialog(false);
                setReplyId("");
                setTypeF(0);
              }}
            >
              <Resource tag="Comment::NoCancel" />
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => {
                setOpenDeleteCommentDialog(false);
                handleDeleteComment();
              }}
            >
              <Resource tag="Comment::YesDelete" />
            </Button>
          </Box>
        }
      >
        <Box textAlign="center">
          <Resource tag="Comment::ContentDialog" />
        </Box>
      </XDialog>

      {/* report comment dialog */}
      <XDialog
        width="800px"
        maxWidth="md"
        open={openReportCommentDialog}
        setOpen={seOpenReportCommentDialog}
        title={<Resource tag="Comment::TitleDialogReport" />}
        actions={
          <Box textAlign="right">
            <Button
              color="primary"
              size="small"
              variant="outlined"
              style={{ marginRight: 8 }}
              onClick={() => {
                seOpenReportCommentDialog(false);
                setReportSelected(0);
              }}
            >
              <Resource tag="Comment::NoCancelReport" />
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              disabled={reportSelected === 0}
              onClick={() => {
                seOpenReportCommentDialog(false);
                setReportSelected(0);
                alert("Awaintg backend");
              }}
            >
              <Resource tag="Comment::YesReport" />
            </Button>
          </Box>
        }
      >
        <Box textAlign="center">
          <Grid container>
            <Grid item xs={12}>
              <Box mb={2} textAlign="left">
                <MenuItem
                  className={classes.menuItemReport}
                  onClick={() => setReportSelected(1)}
                >
                  {reportSelected === 1 ? (
                    <Box display={"flex"}>
                      <CheckRoundedIcon />
                      <Box ml={2}>
                        <Typography variant="body1" style={{ fontWeight: 800 }}>
                          <Resource tag="Comment::ReportInappropriate" />
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="body1">
                      <Resource tag="Comment::ReportInappropriate" />
                    </Typography>
                  )}
                </MenuItem>
              </Box>
            </Grid>
            <Divider style={{ width: "100%" }} />
            <Grid item xs={12}>
              <Box mt={2} mb={2} textAlign="left">
                <MenuItem
                  className={classes.menuItemReport}
                  onClick={() => setReportSelected(2)}
                >
                  {reportSelected === 2 ? (
                    <Box display={"flex"}>
                      <CheckRoundedIcon />
                      <Box ml={2}>
                        <Typography variant="body1" style={{ fontWeight: 800 }}>
                          <Resource tag="Comment::ReportOffensive" />
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="body1">
                      <Resource tag="Comment::ReportOffensive" />
                    </Typography>
                  )}
                </MenuItem>
              </Box>
            </Grid>
            <Divider style={{ width: "100%" }} />
            <Grid item xs={12}>
              <Box mt={2} textAlign="left">
                <MenuItem
                  className={classes.menuItemReport}
                  onClick={() => setReportSelected(3)}
                >
                  {reportSelected === 3 ? (
                    <Box display={"flex"}>
                      <CheckRoundedIcon />
                      <Box ml={2}>
                        <Typography variant="body1" style={{ fontWeight: 800 }}>
                          <Resource tag="Comment::ReportOutOfContext" />
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="body1">
                      <Resource tag="Comment::ReportOutOfContext" />
                    </Typography>
                  )}
                </MenuItem>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </XDialog>
    </>
  );
}

export default CommentItem;
