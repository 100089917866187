import React from "react";
import "./RankingPodium.css";
import EMIcon from "components/Icon/Icon";
import {
  Avatar,
  Grid,
  Container,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Box,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "600px",
      minWidth: "fit-content",
      [theme.breakpoints.down("xs")]: {
        minWidth: "300px",
      },
    },
    gridItem: {
      textAlign: "center",
    },
    podiumPosition: {
      position: "relative",
      display: "inline-block",
      width: "100px",
      height: "100px",
      marginBottom: "20px",
    },
    sidePosition: {
      marginTop: "20px",
    },
    podiumPositionFirst: {
      width: "100px",
      height: "100px",
      marginBottom: "30px",
    },
    avatar: {
      width: "100%",
      height: "100%",
    },
    avatarSide: {
      width: "90%",
      height: "90%",
      margin: "auto",
    },
    badge: {
      position: "absolute",
      top: "65px",
      right: "0px",
      width: "40px",
      height: "40px",
    },
    number: {
      position: "absolute",
      fontSize: "20px",
      color: "white",
      top: "5px",
      left: "26px",
      fontWeight: "bold",
      textAlign: "center",
    },
    sideNumber: {
      position: "absolute",
      fontSize: "20px",
      color: "white",
      top: "7px",
      left: "18px",
      fontWeight: "bold",
      textAlign: "center",
    },
    svgBox: {
      position: "absolute",
      right: "80px",
    },
    pointsBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 6,
      "& > svg": {
        marginRight: 5,
      }
    },
  })
);

interface IPodium {
  avatar: string;
  name: any;
  points: number;
  avatarName: string;
}

interface IProps {
  first: IPodium;
  second: IPodium;
  third: IPodium;
  cultureTag: string;
}

export default function RankingPodium(props: IProps) {
  const classes = useStyles();
  const theme = useTheme();

  const firstPoints = new Intl.NumberFormat(props?.cultureTag).format(
    Number(props?.first?.points)
  );
  const secondPoints = new Intl.NumberFormat(props?.cultureTag).format(
    Number(props?.second?.points)
  );
  const thirdPoints = new Intl.NumberFormat(props?.cultureTag).format(
    Number(props?.third?.points)
  );

  return (
    <Container className={classes.root}>
      <Grid container>
        <Grid item xs={4} className={classes.gridItem}>
          {props?.second?.avatar !== undefined && props?.second.avatarName !== undefined &&
            props?.second?.name !== undefined && props?.second?.points !== undefined ?
            <>
              <Box className={`${classes.podiumPosition} ${classes.sidePosition}`}>
                <Avatar
                  alt={`${props?.second?.avatarName}`}
                  src={props?.second?.avatar}
                  className={classes.avatarSide}
                />
                <Box className={classes.badge}>
                  <EMIcon color={theme.palette.primary.main} icon="medal" size={50} />
                  <Box className={classes.sideNumber}>2</Box>
                </Box>
              </Box>
              <Typography>{props?.second?.name}</Typography>
              <Box className={classes.pointsBox}>
                <EMIcon color={theme.palette.text.secondary} icon="section-ranking" size={20} />
                <Typography color="textSecondary">{secondPoints}</Typography>
              </Box>
            </>
          :
            '' 
          }
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Box
            className={`${classes.podiumPosition} ${classes.podiumPositionFirst}`}
          >
            <Avatar
              alt={`${props?.first?.avatarName}`}
              src={props?.first?.avatar}
              className={classes.avatar}
            />
            <Box className={classes.badge}>
              <EMIcon color={theme.palette.primary.main} icon="trophies" size={65} />
              <Box className={classes.number}>1</Box>
            </Box>
          </Box>
          <Typography>{props?.first?.name}</Typography>
          <Box className={classes.pointsBox}>
            <EMIcon color={theme.palette.text.secondary} icon="section-ranking" size={20} />
            <Typography color="textSecondary">{firstPoints}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          {props?.third?.avatar !== undefined && props?.third?.avatarName !== undefined && 
            props?.third?.name !== undefined && props?.third?.points !== undefined ?
            <>
              <Box className={`${classes.podiumPosition} ${classes.sidePosition}`}>
                <Avatar
                  alt={`${props?.third?.avatarName}`}
                  src={props?.third?.avatar}
                  className={classes.avatarSide}
                />
                <Box className={classes.badge}>
                  <EMIcon color={theme.palette.primary.main} icon="medal" size={50} />
                  <Box className={classes.sideNumber}>3</Box>
                </Box>
              </Box>
              <Typography>{props?.third?.name}</Typography>
              <Box className={classes.pointsBox}>
                <EMIcon color={theme.palette.text.secondary} icon="section-ranking" size={20} />
                <Typography color="textSecondary" >{thirdPoints}</Typography>
              </Box>
            </>
          :
            ''
          }
        </Grid>
      </Grid>
    </Container>
  );
}
