import React, { useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  TableRow,
  TableCell,
  Box,
  Typography,
  Hidden,
} from "@material-ui/core";
import KeyTaskIdeaDetailPopUp from "components/KeyTaskIdeaDetailPopUp/KeyTasksIdeaDetailDialog";
import Resource from "components/Resources/Resource";
import Truncate from "react-truncate";

interface IProps {
  checkAll?: boolean;
  radioButtons?: boolean;
  type?: string;
  title?: string;
  time?: number;
  timeType?: string;
  ideaId?: string;
  status?: string;
  challenge?: string;
  limitDateResource?: string;
  stateEvaluationDate: any;
  stateEvaluationDateResource: any;
  stateEvaluation?: any;
  limitDate: any;
  handlerPopUp?: (value: string, check: boolean) => void;
  postIdeasStars?: (options: object) => Promise<any>;
  getEvaluationKeyTasks?: (options: object) => Promise<any>;
  refreshTabs?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      borderTop: "solid 1px #ccc",
    },
    tableCell: {
      padding: "24px 10px 24px 0px!important",
      maxWidth: "250px",
    },
    allListCell: {
      display: "flex",
      padding: "24px 10px 24px 0px!important",
      width: "100%",
      justifyContent: "space-between",
    },
    truncate: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      maxWidth: 600,
    },
    uppercase: {
      textTransform: "uppercase",
    },
  })
);

function KeyTaskListItemEvaluation(props: IProps) {
  const {
    title,
    time,
    timeType,
    ideaId,
    status,
    challenge,
    stateEvaluationDate,
    stateEvaluationDateResource,
    getEvaluationKeyTasks,
    postIdeasStars,
    refreshTabs,
  } = props;
  const [type] = useState(props.type);
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  const evaluationTime = stateEvaluationDateResource?.replace(".", "::");

  let timeResource = "";
  if (timeType) {
    timeResource = timeType?.replace(".", "::")!;
  }

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell
          onClick={() => setOpen(true)}
          style={{ cursor: "pointer" }}
          className={classes.tableCell}
        >
          <Typography
            color="textPrimary"
            variant="body2"
            className={classes.truncate}
          >
            <strong>{title}</strong>
          </Typography>
        </TableCell>
        <Hidden xsDown>
          <TableCell className={classes.tableCell}>
            <Typography color="textPrimary" variant="body2">
              {status}
            </Typography>
          </TableCell>
        </Hidden>
        <TableCell className={classes.tableCell}>
          <Truncate lines={1} width={320} ellipsis={<span>...</span>}>
            <Typography color="textPrimary" variant="body2">
              {challenge}
            </Typography>
          </Truncate>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography
            style={{ display: "grid" }}
            color={stateEvaluationDate < 0 ? "error" : "textPrimary"}
            variant="body2"
          >
            {stateEvaluationDate ? (
              stateEvaluationDate < 0 ? (
                <>
                  <Resource tag="PageKeyTasks::Evaluation_EvaluationOverdue" />
                  <strong>
                    <Resource
                      tag={evaluationTime!}
                      args={[-stateEvaluationDate]}
                    />
                  </strong>
                </>
              ) : (
                <>
                  <Resource tag="PageKeyTasks::Evaluation_ToBeEvaluated" />
                  <Box component="span">
                    <Box component="span" mr={0.3} fontWeight={600}>
                      <Resource
                        tag={evaluationTime!}
                        args={[stateEvaluationDate]}
                      />
                    </Box>
                    <Box component="span" fontWeight={600}>
                      <Resource tag={"PageIdeaDetail::IdeaDetail_Left"} />
                    </Box>
                  </Box>
                </>
              )
            ) : (
              <Resource tag="PageKeyTasks::Evaluation_NoTimeFrame" />
            )}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography color="textPrimary" variant="body2" noWrap>
            <Resource tag={timeResource} args={[time]} />
          </Typography>
        </TableCell>
      </TableRow>
      {isOpen && (
        <KeyTaskIdeaDetailPopUp
          getEvaluationKeyTasks={getEvaluationKeyTasks}
          refreshTabs={refreshTabs}
          postIdeasStars={postIdeasStars}
          type={type}
          isOpen={isOpen}
          setOpen={setOpen}
          ideaId={ideaId!}
          closeButton={() => setOpen(false)}
        />
      )}
    </>
  );
}

export default KeyTaskListItemEvaluation;
