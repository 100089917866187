import "./CommentList.scss";
import "./StylesMentions.css";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import newApp from "utils/reactNewApp";
import { ThunkDispatch } from "redux-thunk";
import Editor from "draft-js-plugins-editor";
import XBoxMentions from "common/X/XBoxMentions";
import { IAppState } from "../../redux/store/Store";
import CommentItem from "../CommentItem/CommentItem";
import { getAllUsers } from "redux/actions/UsersAction";
import Resource, { resource } from "../Resources/Resource";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { IProfile } from "../../redux/reducers/profileReducer";
import { IUsers, IUsersState } from "redux/reducers/usersReducers";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import { postAddValue } from "../../redux/actions/ideas/AddValueAction";
import { PositionSuggestionsParams } from "@draft-js-plugins/mention/lib/utils/positionSuggestions";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Typography,
  Grow,
  useTheme,
  useMediaQuery,
  Divider,
  TextField,
  Button,
  Grid,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
  MentionPluginTheme,
} from "@draft-js-plugins/mention";
import {
  postIdeasComments,
  putIdeasComments,
  deleteIdeasComments,
  deleteIdeasReplyComments,
  postLikeComment,
  postDislikeComment,
  IIdeasCommentsSuccessAction,
  getAllIdeasComments,
} from "redux/actions/ideas/IdeaAction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    BoxMention: {
      position: "absolute",
      backgroundColor: theme.palette.common.white,
      overflow: "scroll",
      maxHeight: 300,
      bottom: 35,
      boxShadow: "0px 2px 10px -1px rgba(0, 0, 0, 0.2)",
      borderRadius: 10,
      padding: 8,
    },
    editor: {
      "& .DraftEditor-root": {
        display: "flex",
        position: "relative",
        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
      },
      "& .public-DraftEditorPlaceholder-root": {
        display: "flex",
        position: "absolute",
        /* top: 5px, */
        backgroundColor: "#FFF",
        color: "#838383",
      },
      "& .DraftEditor-editorContainer": {
        display: "flex",
        position: "relative",
        paddingLeft: 0.5,
        /* top: 5px, */
      },
      "& .public-DraftEditor-content": {
        maxWidth: 625,
      },
      "& .DraftEditor-editorContainer .m6zwb4v": {
        color: theme.palette.primary.main,
      },
    },
  })
);

interface IProps {
  phaseTitle?: any;
  ideaId: string;
  comments: any;
  isAllowedComment: boolean;
  applicationId: any;
  culture: ICultureState;
  profile: IProfile[];
  isAuthor: boolean;
  initialPageSize?: number;
  statusComments: string;
  highlight?: string;
  isCard?: boolean;
  users?: IUsers[];
  workspaceId: string;
}

interface IDispatchProps {
  postIdeasComments: any;
  putIdeasComments: any;
  deleteIdeasComments: any;
  deleteIdeasReplyComments: any;
  postLikeComment: any;
  postDislikeComment: any;
  postAddValue: any;
  getAllIdeasComments: (
    options: object
  ) => Promise<IIdeasCommentsSuccessAction>;
  getAllUsers: (options: object) => Promise<IUsersState>;
}

export interface EntryComponentProps {
  className?: string;
  role: string;
  id: string;
  "aria-selected"?: boolean | "false" | "true";
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
  searchValue?: string;
}

const pagination = {
  current: 1,
  pageSize: 0,
  finished: false,
};

const status = {
  init: true,
  refreshControl: false,
  isActive: true,
};

const options = {
  ideaId: "",
  status: 1,
  isActive: status?.isActive,
  pageNumber: status?.init || !status?.isActive ? 1 : pagination?.current + 1,
  pageSize: pagination?.pageSize,
  culture: {},
};

const positionSuggestions = ({
  props,
}: PositionSuggestionsParams): React.CSSProperties => {
  let transform;
  let transition;

  if (props?.open && props?.suggestions?.length > 0) {
    transform = "scaleY(1)";
    transition = "all 0.25s cubic-bezier(.3,1.2,.2,1)";
  } else if (props?.open) {
    transform = "scaleY(0)";
    transition = "all 0.25s cubic-bezier(.3,1,.2,1)";
  }

  return {
    transform,
    transition,
  };
};

function CommentList(props: IProps & IDispatchProps) {
  const [commentValue, setCommentValue] = useState("");
  const [reply, setReply] = useState("");
  const [countPreviousClick, setClick] = useState(1);
  const {
    postIdeasComments,
    putIdeasComments,
    deleteIdeasComments,
    deleteIdeasReplyComments,
    postLikeComment,
    postDislikeComment,
    isAuthor,
    applicationId,
    postAddValue,
    getAllIdeasComments,
    initialPageSize,
    statusComments,
    isCard,
    comments,
    workspaceId,
    culture,
    getAllUsers,
  } = props;

  const isAllowedComment = props?.isAllowedComment;
  const profile = props?.profile?.[0];
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const ref = useRef<Editor>(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState?.createEmpty()
  );
  const [open, setOpen] = useState(false);
  const [, /*commentSend*/ setCommentSend] = useState({
    text: "",
    mentionRange: {},
    mentions: {},
  });

  let newUsers: MentionData[]  = props?.users ? props?.users?.map((x) => ({
    userId: x?.userId,
    name: x?.fullName,
    avatar: x?.photo,
  })) : []!

  const [suggestions, setSuggestions] = useState(newUsers);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      //theme: mentionsStyles,
      positionSuggestions,
      mentionPrefix: "@",
      supportWhitespace: true,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  let captcha: string = "";

  useEffect(() => {
    getAllUsers({ q: "" });

    // eslint-disable-next-line
  }, []);

  //to v6
  const allStates = comments?.result?.map((x: any) => {
    return x?.messages?.stateTitle;
  });
  const states = allStates?.filter((elem: any, index: any, self: any) => {
    return index === self?.indexOf(elem);
  });

  //to newApp
  const allPhases = comments?.result?.map((y: any) => {
    return y?.messages?.phaseTitle;
  });
  const phasesI = allPhases?.filter((elem: any, index: any, self: any) => {
    return index === self?.indexOf(elem);
  });

  function Entry(props: EntryComponentProps): ReactElement {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
      isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
      ...parentProps
    } = props;

    return (
      <div {...parentProps}>
        <Box>
          <Grid container style={{ maxWidth: "650px" }} key={mention?.id}>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Button fullWidth>
                <Grid container spacing={2}>
                  {mention?.avatar && (
                    <Grid item xs={2} sm={2} md={2} xl={2} className="avatar">
                      <Avatar src={mention?.avatar} alt={mention?.name} />
                    </Grid>
                  )}
                  <Grid item xs={10} sm={9} md={8} xl={8}>
                    <Box ml={2} className="options-name-subtitle">
                      <Typography>{mention?.name}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }

  const replyHandler = (value: any) => {
    if (reply === value) {
      setReply("");
    } else {
      setReply(value);
    }
  };

  const handleEnter = (e: any) => {
    if (e?.keyCode === 13) {
      postComment(e);
    }
  };

  const handleChange = (e: any) => {
    setCommentValue(e?.target?.value);
  };

  const onChange = useCallback((_editorState: EditorState) => {
    const contentState = _editorState?.getCurrentContent();
    const raw = convertToRaw(contentState);

    const createArrayMentions = Object?.values(raw?.entityMap);

    let newMentions = createArrayMentions?.map((x) => x?.data?.mention);

    setCommentSend({
      text: raw?.blocks[0]?.text,
      mentionRange: raw?.blocks[0]?.entityRanges,
      mentions: newMentions,
    });

    setEditorState(_editorState);
    setCommentValue(raw?.blocks[0]?.text);

    // eslint-disable-next-line
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(defaultSuggestionsFilter(value, newUsers));

    // eslint-disable-next-line
  }, []);

  const commentOptions = {
    ideaId: "",
    status: 1,
    pageNumber: 1,
    pageSize: options?.pageSize + initialPageSize!,
    culture: {},
  };
  commentOptions.culture = props?.culture;
  commentOptions.ideaId = props?.ideaId;

  useEffect(() => {
    let isMounted = true;

    isMounted && getAllIdeasComments(commentOptions);

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllIdeasComments]);

  const postAddedValue = (commentId: string) => {
    const options = {
      ideaId: props?.ideaId,
      culture: props?.culture,
      params: {
        applicationId,
        commentId,
      },
    };
    postAddValue(options);
  };
  const postLikeComments = (commentId: string, userLiked: boolean) => {
    if (userLiked === false) {
      postLikeComment({
        ...options,
        ideaId: props?.ideaId,
        commentId: commentId,
        applicationId: applicationId,
        workspaceId
      });
    } else {
      postDislikeComment({
        ...options,
        ideaId: props?.ideaId,
        commentId: commentId,
        applicationId: applicationId,
        workspaceId
      });
    }
  };

  async function postReply(replyMessage: string) {
    executeRecaptcha &&
      (await executeRecaptcha("createIdeaPage")?.then((response) => {
        captcha = response;
      }));

    options.pageSize = initialPageSize! + 1;
    postIdeasComments({
      ...options,
      ideaId: props?.ideaId,
      message: replyMessage,
      workspaceId: workspaceId,
      applicationId: applicationId,
      ideaCommentPostId: reply,
      captcha: captcha,
      culture
    }).then(() => {
      getAllIdeasComments(commentOptions);
    });
    setReply("");
  }

  async function postComment(e?: any) {
    executeRecaptcha &&
      (await executeRecaptcha("createIdeaPage")?.then((response) => {
        captcha = response;
      }));
    if (!reply) {
      options.pageSize = initialPageSize! + 1;
      postIdeasComments({
        ...options,
        ideaId: props?.ideaId,
        message: commentValue,
        applicationId: applicationId,
        workspaceId: workspaceId,
        captcha: captcha,
      })?.then(() => {
        getAllIdeasComments(commentOptions);
      });
    }
    setCommentValue("");
    setEditorState(
      EditorState?.push(editorState, ContentState?.createFromText(""), "undo")
    );
  }

  async function putEditComment(
    commentId: string,
    message: any,
    typeF: number,
    replyId?: string
  ) {
    executeRecaptcha &&
      (await executeRecaptcha("createIdeaPage")?.then((response) => {
        captcha = response;
      }));

    options.pageSize = initialPageSize! + 1;
    options.culture = props?.culture?.culture;

    if (typeF === 1) {
      if (reply) {
        putIdeasComments({
          ...options,
          parentCommentId: reply,
          ideaId: props?.ideaId,
          workspaceId: workspaceId,
          message: message,
          commentId: commentId,
          delete: false,
          edit: true,
          captcha: captcha,
        })?.then(() => {
          setReply("");
          getAllIdeasComments(commentOptions);
        });
      } else {
        putIdeasComments({
          ...options,
          ideaId: props?.ideaId,
          workspaceId: workspaceId,
          message: message,
          commentId: commentId,
          delete: false,
          edit: true,
          captcha: captcha,
        })?.then(() => {
          getAllIdeasComments(commentOptions);
        });
      }
    } else if (typeF === 2) {
      deleteIdeasComments({
        ...options,
        commentId: commentId,
        ideaId: props?.ideaId,
        workspaceId: workspaceId,
      })?.then(() => {
        getAllIdeasComments(commentOptions);
      });
    } else if (typeF === 3) {
      deleteIdeasReplyComments({
        ...options,
        parentCommentId: commentId,
        commentId: replyId,
        ideaId: props?.ideaId,
        workspaceId: workspaceId,
      })?.then(() => {
        getAllIdeasComments(commentOptions);
      });
    }
  }

  const handleMoreComments = () => {
    if (comments?.result?.length >= comments?.meta?.total) {
      return;
    } else {
      setClick(countPreviousClick + 1);
      commentOptions.pageSize =
        commentOptions.pageSize + initialPageSize! * countPreviousClick;
      getAllIdeasComments(commentOptions);
    }
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const actions = (
    <Button variant="contained" color="primary" onClick={postComment} disabled={!commentValue}>
      <Resource tag={"CommentsArea::Post"} />
    </Button>
  );

  return (
    <Box width="100%" className="comment-container">
      <Box width="100%" display="flex" justifyContent="space-between">
        {comments?.messagesCount > comments?.totalMessagePages ? (
          <Typography
            onClick={handleMoreComments}
            style={{ textTransform: "lowercase", cursor: "pointer" }}
            variant="caption"
            color="primary"
          >
            <Resource tag="Common::PreviousComments" />
          </Typography>
        ) : (
          <Typography></Typography>
        )}
        {comments?.messagesCount > 0 ? (
          <Typography variant="caption" color="textSecondary">
            {comments?.totalMessagePages} <Resource tag="Common::of" />{" "}
            {comments?.messagesCount}
          </Typography>
        ) : (
          <Typography></Typography>
        )}
      </Box>
      {statusComments !== "LOADING" ? (
        <Grow in>
          <Box
            width={isCard ? "100%" : matches ? "100%" : "80%"}
            className="comment-list"
            marginTop={2}
          >
            {!newApp && states ? (
              <>
                {states?.map((state: any, index: any) => {
                  return (
                    <Box key={index}>
                      <Divider
                        style={{ margin: "8px 0", width: "60%", height: "2px" }}
                      />
                      <Typography
                        style={{
                          textTransform: "uppercase",
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        <Resource
                          tag="PageDetailIdea::CommentsIn"
                          args={[state]}
                        />
                      </Typography>
                      {comments?.result?.map((comment: any) => {
                        let high = false;
                        if (comment?.messages?.commentId === props?.highlight) {
                          high = true;
                        }
                        if (state === comment?.messages?.stateTitle) {
                          return (
                            <CommentItem
                              key={comment?.messages?.commentId}
                              commentId={comment?.messages?.commentId}
                              userId={comment?.messages?.userId}
                              fullName={comment?.messages?.userName}
                              photo={comment?.messages?.photo}
                              timeAgo={comment?.messages?.timeAgo}
                              message={comment?.messages?.message}
                              isLikedByMe={comment?.messages?.userLiked}
                              likesCount={comment?.messages?.likesCount}
                              replies={comment?.messages?.replies}
                              isAddedValue={comment?.messages?.isAddedValue}
                              timeStampResource={
                                comment?.messages?.timeStampResource
                              }
                              postAddedValue={postAddedValue}
                              isAuthor={isAuthor}
                              isAllowedComment={isAllowedComment}
                              replyHandler={replyHandler}
                              postLikeComment={postLikeComments}
                              postReply={postReply}
                              putComment={putEditComment}
                              profile={profile}
                              highlight={high}
                              users={props?.users}
                            />
                          );
                        } else {
                          return "";
                        }
                      })}
                    </Box>
                  );
                })}
              </>
            ) : newApp && phasesI ? (
              <>
                {phasesI?.map((phase: any, index: any) => {
                  return (
                    <Box key={index}>
                      <Divider
                        style={{ margin: "8px 0", width: "60%", height: "2px" }}
                      />
                      <Typography
                        style={{
                          textTransform: "uppercase",
                        }}
                        variant="body2"
                        color="textSecondary"
                      >
                        <Resource
                          tag="PageDetailIdea::CommentsIn"
                          args={[phase]}
                        />
                      </Typography>
                      {comments?.result?.map((comment: any) => {
                        let high = false;
                        if (comment?.messages?.commentId === props?.highlight) {
                          high = true;
                        }
                        if (phase === comment?.messages?.phaseTitle) {
                          return (
                            <CommentItem
                              key={comment?.messages?.commentId}
                              commentId={comment?.messages?.commentId}
                              userId={comment?.messages?.userId}
                              fullName={comment?.messages?.userName}
                              photo={comment?.messages?.photo}
                              timeAgo={comment?.messages?.timeAgo}
                              message={comment?.messages?.message}
                              isLikedByMe={comment?.messages?.userLiked}
                              likesCount={comment?.messages?.likesCount}
                              replies={comment?.messages?.replies}
                              isAddedValue={comment?.messages?.isAddedValue}
                              timeStampResource={
                                comment?.messages?.timeStampResource
                              }
                              postAddedValue={postAddedValue}
                              isAuthor={isAuthor}
                              isAllowedComment={isAllowedComment}
                              replyHandler={replyHandler}
                              postLikeComment={postLikeComments}
                              postReply={postReply}
                              putComment={putEditComment}
                              profile={profile}
                              highlight={high}
                              users={props?.users}
                            />
                          );
                        } else {
                          return "";
                        }
                      })}
                    </Box>
                  );
                })}
              </>
            ) : (
              <>
                {comments &&
                  comments?.result?.map((comment: any) => {
                    let high = false;
                    if (comment?.messages?.commentId === props?.highlight) {
                      high = true;
                    }
                    return (
                      <CommentItem
                        key={comment?.messages?.commentId}
                        commentId={comment?.messages?.commentId}
                        userId={comment?.messages?.userId}
                        fullName={comment?.messages?.userName}
                        photo={comment?.messages?.photo}
                        timeAgo={comment?.messages?.timeAgo}
                        message={comment?.messages?.message}
                        isLikedByMe={comment?.messages?.userLiked}
                        likesCount={comment?.messages?.likesCount}
                        replies={comment?.messages?.replies}
                        isAddedValue={comment?.messages?.isAddedValue}
                        timeStampResource={comment?.messages?.timeStampResource}
                        postAddedValue={postAddedValue}
                        isAuthor={isAuthor}
                        isAllowedComment={isAllowedComment}
                        replyHandler={replyHandler}
                        postLikeComment={postLikeComments}
                        postReply={postReply}
                        putComment={putEditComment}
                        profile={profile}
                        highlight={high}
                        users={props?.users}
                      />
                    );
                  })}
              </>
            )}
          </Box>
        </Grow>
      ) : (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      )}
      {isAllowedComment && (
        <Box
          width={isCard ? "90%" : matches ? "100%" : "80%"}
          className="comment-bar"
        >
          <Avatar
            src={profile?.photo}
            alt={profile?.fullName}
            className={classes.thumbnail}
          />
          <Box className="comment-input" width="100%">
            <XBoxMentions actions={actions}>
              {newApp ? (
                <div
                  style={{ width: "100%" }}
                  //className={editorStyles.editor}
                  onClick={() => {
                    ref?.current!?.focus();
                  }}
                >
                  <Box className={classes.editor}>
                    <Editor
                      placeholder={`${resource(
                        props?.culture,
                        "CommentsArea::AddComment"
                      )}...`}
                      editorKey={"editor"}
                      editorState={editorState}
                      onChange={onChange}
                      plugins={plugins}
                      ref={ref}
                    />
                  </Box>

                  <Box className={open ? classes.BoxMention : ""}>
                    <MentionSuggestions
                      open={open}
                      onOpenChange={onOpenChange}
                      suggestions={suggestions}
                      onSearchChange={onSearchChange}
                      onAddMention={() => {
                        // get the mention object selected
                      }}
                      entryComponent={Entry}
                    />
                  </Box>
                </div>
              ) : (
                <TextField
                  fullWidth
                  placeholder={`${resource(
                    props?.culture,
                    "CommentsArea::AddComment"
                  )}...`}
                  style={{ width: "100%" }}
                  disabled={!isAllowedComment}
                  onChange={handleChange}
                  onKeyUp={handleEnter}
                  value={commentValue}
                  type="text"
                />
              )}
            </XBoxMentions>
          </Box>
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    addStatus: store?.addValueState?.status,
    comments: store?.ideasState?.ideasComments,
    culture: store?.cultureState,
    applicationId: store?.authState?.auth?.applicationId,
    profile: store?.profileState?.profile,
    statusComments: store?.ideasState?.statusComment,
    users: store?.usersState?.users,
    workspaceId: store?.authState?.auth?.workspaceId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    postIdeasComments: (options: object) =>
      dispatch(postIdeasComments(options)),
    putIdeasComments: (options: object) => dispatch(putIdeasComments(options)),
    deleteIdeasComments: (options: object) =>
      dispatch(deleteIdeasComments(options)),
    deleteIdeasReplyComments: (options: object) =>
      dispatch(deleteIdeasReplyComments(options)),
    postLikeComment: (options: object) => dispatch(postLikeComment(options)),
    postDislikeComment: (options: object) =>
      dispatch(postDislikeComment(options)),
    postAddValue: (options: object) => dispatch(postAddValue(options)),
    getAllIdeasComments: (options: object) =>
      dispatch(getAllIdeasComments(options)),
    getAllUsers: (options: object) => dispatch(getAllUsers(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentList);
