import XDialog from "common/X/XDialog";
import { Box, Button } from "@material-ui/core";
import TextCounter from "common/UI/Text/TextCounter";
import { ICultureState } from "redux/reducers/cultureReducer";
import React, { Dispatch, SetStateAction, useState } from "react";
import Resource, { resource } from "components/Resources/Resource";

interface IProps {
  culture: ICultureState;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>> | ((open: boolean) => void);
  saveTemplateAction: (title:string) => void
}

function PopupSaveTemplate({ open, setOpen, culture, saveTemplateAction }: IProps) {
  const [title, setTitle] = useState("");

  const actions = (
    <Box width="100%" textAlign="right">
      <Button
        onClick={() => setOpen(false)}
        color="primary"
        variant="outlined"
        size="small"
        style={{ marginRight: 8 }}
      >
        <Resource tag="PageCreateChallenge::Workflow_Cancel" />
      </Button>
      <Button
        onClick={() => {
          saveTemplateAction(title)
          setOpen(false);
        }}
        color="primary"
        variant="contained"
        size="small"
      >
        <Resource tag="PageCreateChallenge::Workflow_Save" />
      </Button>
    </Box>
  );

  return (
    <XDialog
      title={<Resource tag="PageCreateChallenge::Workflow_CreateTemplate" />}
      maxWidth="sm"
      open={open}
      setOpen={setOpen}
      actions={actions}
    >
      <Box mt={2}>
        <TextCounter
          maxLength={50}
          label={<Resource tag="PageCreateChallenge::Workflow_TemplateTitle" />}
          placeholder={resource(
            culture,
            "PageCreateChallenge::Workflow_GiveThisTemplate"
          )}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
    </XDialog>
  );
}

export default PopupSaveTemplate;
