import React, { useEffect, useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Resource from "components/Resources/Resource";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import {
  Box,
  CardMedia,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";

interface IProps {
  photo: any;
  setPhoto: any;
  prize?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputPhoto: {
      display: "none",
    },
    iconBtn: {
      padding: 0,
      "& .MuiIconButton-root": {
        padding: 0,
      },
      "&:hover": {
        padding: 0,
      },
    },
    containerImage: {
      cursor: "pointer",
      position: "relative",
      width: "100%",
      maxWidth: "348px",
    },
    containerPrizeImg: {
      cursor: "pointer",
      position: "relative",
      width: "250px",
      borderRadius: "8px",
      marginTop: "8px",
    },
    Img: {
      width: "100%",
      borderRadius: "8px",
    },
    closeButton: {
      position: "absolute",
      top: "9px",
      right: "-4px",
      color: theme.palette.text.primary,
      transform: "translate(-50%,-50%)",
      backgroundColor: theme.palette.common.white,
      padding: 0,
      "&:hover": {
        opacity: 1,
        backgroundColor: theme.palette.grey[300],
      },
    },
  })
);

function PhotoUpload(props: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const photoRef = useRef<any>(null);
  const { photo, setPhoto, prize } = props;

  useEffect(() => {
    photo && setPhoto && setPhoto(photo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePhotoChange = async (e: any) => {
    const toBase64 = (file: any) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const file = e.target.files[0];
    toBase64(file).then((data) => setPhoto(data));
  };
  const handleLogoClose = () => {
    setPhoto(null);
  };

  return (
    <Box>
      {!photo ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={prize ? "250px" : "348px"}
          width="100%"
          minHeight={prize ? "220px" : "153px"}
          borderRadius="8px"
          style={{
            backgroundColor: theme.palette.primary.light,
          }}
          onClick={() => photoRef.current}
        >
          <input
            ref={photoRef}
            accept="image/*"
            className={classes.inputPhoto}
            id="image-upload"
            name="image-upload"
            type="file"
            onChange={handlePhotoChange}
          />
          <label
            style={{
              width: "100%",
              textAlign: "center",
              cursor: "pointer",
              height: "120px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            htmlFor="image-upload"
          >
            <Box style={{ textAlignLast: "center" }}>
              <IconButton
                color="primary"
                component="span"
                className={classes.iconBtn}
              >
                <PhotoCameraIcon color="primary" fontSize="large" />
              </IconButton>
            </Box>
            <Typography
              color="primary"
              variant="caption"
              style={{ cursor: "pointer" }}
            >
              <Resource tag="PageCreateChallenge::InsertImage" />
            </Typography>
          </label>
        </Box>
      ) : (
        <CardMedia
          className={classes.Img}
          style={{
            backgroundImage: `url(${photo})`,
            maxWidth: prize ? "250px" : "348px",
            height: prize ? "220px" : "153px",
          }}
        >
          <Box className={classes.containerImage}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleLogoClose}
            >
              <CloseIcon style={{ fontSize: "0.8rem" }} />
            </IconButton>
          </Box>
        </CardMedia>
      )}
    </Box>
  );
}
export default PhotoUpload;
