import React from 'react'
import './HistoricInvestments.css';
import { IResultMyActivitiesInvestmentsHistory } from '../../redux/types/myActivities';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { TableRow, TableCell, Box, Typography, Hidden } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Resource from 'components/Resources/Resource';
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      borderBottom: 'solid 1px #ccc'
    },
    tableCell: {
      padding: '8px 10px 16px 0px!important',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      '& div': {
        '& svg': {
          marginTop: '5px'
        }
      }
    },
    linkItem: {
      'color' : '#000',
      'textDecoration': 'none',
      'display': '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      'overflow': "hidden",
      'text-overflow': "ellipsis",
    },
    noCell: {
      [theme.breakpoints.down('sm')]: {
        'width': '0',
      }
    },
    date: {
      textTransform: 'lowercase',
      '&:first-line': {
        textTransform: 'capitalize',
      },
    },
    new: {
      textTransform: 'lowercase',
      display:"inline-block",
      "&:first-letter": {
        textTransform: 'capitalize',
        
      },
    }
  }),
);

interface IProps {
  applicationId?: string;
  investmentsHistory?: IResultMyActivitiesInvestmentsHistory;
}

function HistoricInvestments(props: IProps) {

  const { investmentsHistory } = props;
  const ideaBackURL = "/idea/details";
  const classes = useStyles();
  const lastSaleDate = investmentsHistory?.lastSaleDate?.split(" ")??null
  const newLastSaleDate = `<span>
      ${
        lastSaleDate?lastSaleDate[0]:""}
      <span class=${classes.new}>${lastSaleDate?lastSaleDate[1]:""}</span>
      ${lastSaleDate?lastSaleDate[2]:""}
    </span>`
    
  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        <Box display="flex" flexDirection="column" marginTop={2}>
        <Link className={classes.linkItem} component={RouterLink} to={{
          pathname: `${ideaBackURL}/${investmentsHistory?.ideaId}`
        }}>
          <strong>{investmentsHistory?.ideaTitle}</strong>
        </Link>
        <Typography variant="caption" noWrap className={classes.date}>
          <Resource tag="PageMyActivity::Investments_InvestmentDate" args={[investmentsHistory?.lastInvestmentDate]}/>
        </Typography>
        <Hidden implementation="css" mdUp>
          <Typography variant="body2" noWrap>{investmentsHistory?.ideaState}</Typography>
        </Hidden>
        </Box>
      </TableCell>
      <TableCell className={classes.tableCell + ' ' + classes.noCell}>
        <Hidden implementation="css" smDown>
          <Typography variant="body2" noWrap>{investmentsHistory?.ideaState}</Typography>
        </Hidden>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Typography variant="caption" noWrap>
          <NumberFormat value={investmentsHistory?.investedAmount} displayType={'text'} thousandSeparator={true} />
        </Typography>
      </TableCell>

      {/* <TableCell className="InvestmentHistoric-senseInvestment">
            {investmentsHistory.sense} Doesn't have data to use here
          </TableCell> */}
      <TableCell className={classes.tableCell}>
        <NumberFormat value={investmentsHistory?.finalAmount && Math.round(investmentsHistory?.finalAmount)} displayType={'text'} thousandSeparator={true} />
        
      </TableCell>
      {investmentsHistory?.saleAmount === null ?
        <>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </>
        :
        <>
          <TableCell className={classes.tableCell}>
            <Hidden implementation="css" xsDown>
              <Box display="flex" flexDirection="column" marginTop={2}>
                <Typography variant="caption" noWrap>
                  <strong><NumberFormat value={investmentsHistory?.saleAmount && Math.round(investmentsHistory?.saleAmount)} displayType={'text'} thousandSeparator={true} /></strong> points
                </Typography>
                <Typography variant="caption" noWrap>
                  <Resource tag="PageMyActivity::Investments_SaleDate" args={[newLastSaleDate]}/>
                </Typography>
              </Box>
            </Hidden>
          </TableCell>
        </>
      }
    </TableRow>
  )
}
export default HistoricInvestments;