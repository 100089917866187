import { AnyAction } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import React, { useEffect, useState } from "react";
import PrizePage from "components/PrizePage/PrizePage";
import {
  IPrizePrevious,
  IPrizeState,
  IResultPrizeActiveAuctionUser,
  IResultPrizeActiveBuyNow,
  IResultPrizeFutures, IResultPrizePrevious
} from "redux/reducers/prizeReducer";
import { IApplication } from "redux/reducers/tenantReducer";
import { IProfileState } from "redux/reducers/profileReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import PrizePageSkeleton from "components/PrizePage/PrizePageSkeleton";
import { getProfile, IProfileGetAllAction } from "redux/actions/ProfileActions";
import {
  getAllPrizes,
  IPrizeGetAllAction,
  postBuyPrize,
  IPrizePostBuyPrize,
  postPrizeBid,
  IPrizeBidPostAction,
  duplicatePrize,
  IPrizeDuplicateSucceededAction,
  deletePrize,
  IPrizeDeleteSucceededAction,
  closeAuctionPrize,
  IPrizeCloseAuctionSucceededAction,
  getPrizesActiveAuction,
  getPrizesActiveBuyNow,
  getPrizesFutures,
  getAllPrizesPrevious
} from "redux/actions/PrizeActions";
import history from 'routes/history';

interface IProps {
  culture: ICultureState;
  prizes: IPrizeState;
  prizePrevious: IPrizePrevious;
  application: IApplication;
  applicationId: any;
  scrollDown?: boolean;
  prizeId?: string;
  profile: IProfileState;
  allowToSeePrizes: boolean;
  permission: string;
  workspaceId: string;
}
interface IDispatchProps {
  getPrizes: (options: object) => Promise<IPrizeGetAllAction>;
  getPrizePrevious: (options: object) => Promise<IPrizeState>;
  postBuyPrize: (options: object) => Promise<IPrizePostBuyPrize>;
  postBidPrize: (options: object) => Promise<IPrizeBidPostAction>;
  getProfile: (options: object) => Promise<IProfileGetAllAction>;
  duplicatePrize: (options: object) => Promise<IPrizeDuplicateSucceededAction>;
  deletePrize: (options: object) => Promise<IPrizeDeleteSucceededAction>;
  closeAuctionPrize: (options: object) => Promise<IPrizeCloseAuctionSucceededAction>;
}

function Prize(props: IProps & IDispatchProps) {
  const {
    prizes,
    prizePrevious,
    culture,
    applicationId,
    scrollDown,
    prizeId,
    profile,
    allowToSeePrizes,
    permission,
    workspaceId,
    getPrizePrevious,
    postBuyPrize,
    postBidPrize,
    getPrizes,
    getProfile,
    duplicatePrize,
    deletePrize,
    closeAuctionPrize
  } = props;

  const pagination = {
    current: 1,
    pageSize: 10,
    finished: false,
  };

  const status = {
    init: true,
    refreshControl: false,
  };

  const options = {
    applicationId: applicationId,
    pageNumber: status.init ? 1 : pagination.current,
    pageSize: pagination.pageSize,
    culture: culture,
  };

  const option = {
    workspaceId: workspaceId,
    pageSize: 9,
    NumberPage: 1,
  };

  useEffect(() => {
    getPrizes(options).then(() => {
      setLoading(false);
    });

    // eslint-disable-next-line
  }, [getPrizes]);

  const [loading, setLoading] = useState(true);
  const [prizeActiveAuction, setPrizeActiveAuction] = useState<IResultPrizeActiveAuctionUser[]>([]);
  const [prizeActiveBuyNow, setPrizeActiveBuyNow] = useState<IResultPrizeActiveBuyNow[]>([]);
  const [prizeFutures, setPrizeFutures] = useState<IResultPrizeFutures[]>([]);

  useEffect(() => {
    getPrizesActiveAuction(option).then((result) => {
      if (typeof result !== undefined) {
        setPrizeActiveAuction(result!);
      }
    });
    getPrizesActiveBuyNow(option).then((result) => {
      if (typeof result !== undefined) {
        setPrizeActiveBuyNow(result!);
      }
    });
    getPrizesFutures(option).then((result) => {
      if (typeof result !== undefined) {
        setPrizeFutures(result!);
      }
    });
  }, [])

  const pageLink = {
    scrollDown,
    prizeId,
  };

  const requestBuyPrize = (prizeId: string) => {
    const params = {
      prizeId: prizeId,
      applicationId: String(applicationId),
      culture: culture,
    };

    postBuyPrize(params).then(() => {
      getPrizes(options);
      getProfile(options);
    });
  };

  const requestBidPrize = (prizeId: string, value: number) => {
    const params = {
      prizeId: prizeId,
      value: value,
      applicationId: String(applicationId),
      culture: culture,
    };

    postBidPrize(params).then(() => {
      getPrizes(options);
      getProfile(options);
    });
  };

  const requestManagePrize = (prizeId: string, type: number, isCanceling?: boolean) => {
    setLoading(true);
    const params = {
      prizeId: prizeId,
      isCanceling: isCanceling,
      culture: culture,
    };
    
    if(type === 1) {
      history.push(`/editPrize/${prizeId}`);
    }
    else if(type === 2) {
      duplicatePrize(params).then(() => {
        getPrizes(options).then(() => {
          getProfile(options);
          setLoading(false);
        })
      });
    }
    else if(type === 3){
      deletePrize(params).then(() => {
        getPrizes(options).then(() => {
          getProfile(options);
          setLoading(false);
        })
      });
    }
    else if(type === 4){
      closeAuctionPrize(params).then(() => {
        getPrizes(options).then(() => {
          getProfile(options);
          setLoading(false);
        })
      })
    }
  };

  return (
    <>
      {allowToSeePrizes ? (
        <div className="prize-screen" style={{ marginBottom: 32 }}>
          {!loading ? (
            <PrizePage
              permission={permission}
              profile={profile}
              culture={culture}
              workspaceId={workspaceId}
              pageLink={pageLink}
              postBidPrize={requestBidPrize}
              postBuyPrize={requestBuyPrize}
              requestManagePrize={requestManagePrize}
              prizeActiveAuction={prizeActiveAuction}
              prizeActiveBuyNow={prizeActiveBuyNow}
              prizeFutures={prizeFutures}
              prizePrevious={prizePrevious}
              getPrizePrevious={getPrizePrevious}
              points={prizes.points}
            />
          ) : (
            <PrizePageSkeleton />
          )}
        </div>
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )}
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getPrizes: (options: object) => dispatch(getAllPrizes(options)),
    getPrizePrevious: (options: object) => dispatch(getAllPrizesPrevious(options)),
    postBuyPrize: (options: object) => dispatch(postBuyPrize(options)),
    postBidPrize: (options: object) => dispatch(postPrizeBid(options)),
    getProfile: (options: object) => dispatch(getProfile(options)),
    duplicatePrize: (options: object) => dispatch(duplicatePrize(options)),
    deletePrize: (options: object) => dispatch(deletePrize(options)),
    closeAuctionPrize: (options: object) => dispatch(closeAuctionPrize(options)),
  };
};

// Grab the characters from the store and make them available on props
const mapStateToProps = (store: IAppState, ownProps: any) => {
  return {
    culture: store.cultureState,
    prizes: store.prizeState,
    prizePrevious: store.prizeState.prizesPrevious,
    application: store.tenantState.application,
    applicationId: store.authState.auth.applicationId,
    scrollDown: ownProps?.location?.state?.scrollDown,
    prizeId: ownProps?.location?.state?.prizeId,
    workspaceId: store.authState.auth.workspaceId,
    profile: store.profileState,
    allowToSeePrizes: store.authState.auth.allowToSeePrizes,
    permission: store.authState.auth.permission,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Prize);
