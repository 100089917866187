import "./ProfileBottom.css";
import EMIcon from "components/Icon/Icon";
import { connect } from "react-redux";
import Truncate from "react-truncate";
import { Link as RouterLink } from "react-router-dom";
import history from "../../routes/history";
import { ThunkDispatch } from "redux-thunk";
import Resource from "../Resources/Resource";
import { IAppState } from "redux/store/Store";
import NumberFormat from "react-number-format";
import { PhotoCamera } from "@material-ui/icons";
import DraftsIcon from "@material-ui/icons/Drafts";
import React, { useEffect, useCallback } from "react";
import { deepOrange } from "@material-ui/core/colors";
import { IMessagesReceived } from "redux/types/messages";
import { IProfileData } from "redux/reducers/profileReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IProfile } from "../../redux/reducers/profileReducer";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import {
  IAuthLogoutAction,
  doLogout,
  IAuthLogoutSSOAction,
  doLogoutSSO,
} from "../../redux/actions/AuthAction";
import {
  updateProfile,
  IProfileUpdateAction,
  getProfile,
  IProfileGetAllAction,
  getProfileData,
  IProfileGetDataAction,
} from "../../redux/actions/ProfileActions";
import {
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
  Avatar,
  Fab,
  CardHeader,
  Badge,
  Box,
  Link,
} from "@material-ui/core";
import newApp from "utils/reactNewApp";

interface IProps {
  profile: IProfile[];
  toggleProfile: any;
  culture: ICultureState;
  tenantId: number;
  hasSSOLogout: boolean;
  receivedMessages: IMessagesReceived;
  profileData: IProfileData;
}

interface IDispatchProps {
  logOutConnect: () => Promise<IAuthLogoutAction>;
  updateProfile: (
    params: object,
    options: object
  ) => Promise<IProfileUpdateAction>;
  getProfile: (options: object) => Promise<IProfileGetAllAction>;
  logoutSSO: (tenantId: number) => Promise<IAuthLogoutSSOAction>;
  getProfileData: () => Promise<IProfileGetDataAction>;
}

const paperStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "340px",
      height: "fit-content",
      position: "absolute",
      right: 0,
      top: "75px",
      overflowY: "auto",
    },
    container: {
      padding: "24px",
    },
    card: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    cardHeader: {
      padding: 0,
    },
    avatar: {
      border: "solid whitesmoke",
      width: "128px",
      height: "128px",
    },
    fab: {
      top: "12%",
      right: "27%",
      width: "35px",
      height: "33px",
      position: "absolute",
      background: "#fff",
      minHeight: "32px",
      boxShadow:
        "0px 3px 2px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    },
    icon: {
      color: theme.palette.primary.main,
      width: "0.9em",
      height: "0.9em",
    },
    cardContent: {
      padding: 0,
      "&:last-child": {
        padding: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    typoName: {
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    link: {
      textDecoration: "none",
    },
    typoRanking: {
      gap: "6px",
      display: "flex",
      alignItems: "end",
      marginBottom: "1.35em",
      marginTop: "0.35em",
    },
    rankingTitle: {
      fontWeight: "bold",
      fontSize: "0.87rem",
    },
    buttons: {
      margin: "30px 0 10px 0",
      fontSize: "0.8125rem",
      padding: "12px 12px 12px 18px",
      justifyContent: "space-between",
      borderRadius: "30px",
    },
    btnMessage: {
      margin: "10px 0 10px 0",
      fontSize: "0.8125rem",
      padding: "12px 12px 12px 18px",
      justifyContent: "space-between",
      borderRadius: "30px",
      color: theme.palette.primary.main,
      background: theme.palette.primary.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    divIconKeyTask: {
      width: "200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "0.9rem",
    },
    iconKeyTask: {
      fontSize: "1rem",
    },
    badge: {
      justifyContent: "center",
      fontSize: "9px",
      minWidth: "20px",
      minHeight: "20px",
      borderRadius: "50px",
      color: "#FFF",
      backgroundColor: theme.palette.error.main,
      alignItems: "center",
    },
    capitalize: {
      textTransform: "capitalize",
    },
  })
);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      width: "90%",
      "&:last-child": {
        padding: 0,
      },
    },
    card: {
      paddingTop: "8px",
      height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: theme.palette.primary.light,
    },
    totalsFont: {
      lineHeight: "1.1em",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    buttonKeyTasks: {
      width: "100%",
    },
    icon: {
      minWidth: 30,
    },
    item: {
      color: theme.palette.text.primary,
      padding: 0,
    },
    overrides: {
      MuiButton: {
        textPrimary: theme.palette.primary,
      },
    },
    dialogContainer: {
      height: "26vh",
      width: "23vw",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    dialogTitle: {
      textAlign: "center",
      fontSize: "1rem",
      textTransform: "lowercase",
      "&:first-letter": {
        textTransform: "capitalize",
      },
    },
    dialogAction: {
      justifyContent: "center",
    },
    list: {
      margin: "0 9px",
      padding: "4px 0",
    },
  })
);

function ProfileBottom(props: IProps & IDispatchProps) {
  const classes = useStyles();
  const paperStyle = paperStyles();
  const {
    profile,
    toggleProfile,
    culture,
    updateProfile,
    getProfile,
    profileData,
  } = props;
  const [openLogout, setOpenLogout] = React.useState(false);
  const container = React.createRef<HTMLDivElement>();
  const fileInput = React.useRef<any>();

  const handleClickOutside = useCallback(
    (e: any) => {
      if (container.current && !container.current.contains(e.target)) {
        toggleProfile();
      } else if (container.current === null) {
        toggleProfile();
      }
    },
    [container, toggleProfile]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleLogoutClickOpen = () => {
    setOpenLogout(true);
  };

  const handleLogoutClose = () => {
    setOpenLogout(false);
  };

  const handleLogout = () => {
    toggleProfile();
    if (props?.hasSSOLogout) {
      props.logoutSSO(props?.tenantId);
    } else {
      props.logOutConnect();
    }
  };

  const handlePhotoClick = () => fileInput.current.click();

  const handlePhotoUpload = (e: any) => {
    const toBase64 = (file: any) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const file = e.target.files[0];
    toBase64(file).then((data) => {
      updateProfile(
        { photo: data, cultureTag: culture.culture.cultureTag, timezone: profileData.timezone},
        { culture }
      ).then(() => getProfile({ culture }));
    });
  };

  function formatNumber(number: Number) {
    if (number > 0 && number <= 999) {
      return "###";
    } else if (number > 0 && number <= 9999) {
      return "#,#K";
    } else if (number > 9999 && number <= 99999) {
      return "##,#K";
    } else if (number > 99999) {
      return "###,#K";
    } else if (number === 0 || number === undefined) {
      return "0";
    }
  }

  return (
    <Paper
      elevation={3}
      className={paperStyle.paper}
      ref={container}
      style={{ maxHeight: window.innerHeight - 125 }}
    >
      {profile &&
        profile?.map((profiles) => {
          return (
            <Container key={profiles.userId} className={paperStyle.container}>
              <Card elevation={0} className={paperStyle.card}>
                <CardHeader
                  className={paperStyle.cardHeader}
                  avatar={
                    <Avatar
                      src={profiles.photo}
                      className={paperStyle.avatar}
                    />
                  }
                  action={
                    <>
                      <input
                        ref={fileInput}
                        onChange={handlePhotoUpload}
                        type="file"
                        name="profile-photo-button"
                        id="profile-photo-button"
                        style={{ display: "none" }}
                      />
                      <Fab
                        onClick={handlePhotoClick}
                        className={paperStyle.fab}
                      >
                        <PhotoCamera className={paperStyle.icon} />
                      </Fab>
                    </>
                  }
                />
                <CardContent className={paperStyle.cardContent}>
                  <Typography color="primary" className={paperStyle.typoName}>
                    <Truncate
                      lines={1}
                      width={290}
                      trimWhitespace
                      ellipsis={<span>...</span>}
                    >
                      {profiles.fullName}
                    </Truncate>
                  </Typography>
                  <div className={paperStyle.typoRanking}>
                    <Typography>
                      <EMIcon
                        icon="section-ranking"
                        color="#747D81"
                        size={16}
                      />
                    </Typography>
                    <Link
                      component={RouterLink}
                      to={"/ranking"}
                      className={paperStyle.link}
                    >
                      <Typography
                        color="textSecondary"
                        className={paperStyle.rankingTitle}
                      >
                        <Resource tag="PageRanking::Header" />
                      </Typography>
                    </Link>
                    <Link
                      component={RouterLink}
                      to={"/ranking"}
                      className={paperStyle.link}
                    >
                      <Typography
                        color="primary"
                        className={paperStyle.rankingTitle}
                      >
                        {profiles.rankingPosition}
                      </Typography>
                    </Link>
                  </div>
                </CardContent>
              </Card>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: "/MyActivity",
                      state: {
                        pageDefault: "IDEAS",
                      },
                    }}
                    className={paperStyle.link}
                  >
                    <Card className={classes.card} elevation={0}>
                      <CardContent className={classes.root}>
                        <Typography
                          color="primary"
                          className={classes.totalsFont}
                        >
                          {profiles.countIdeas}
                        </Typography>
                        <Typography
                          color="primary"
                          variant="subtitle1"
                          noWrap
                          className={paperStyle.capitalize}
                        >
                          <Resource tag={"PageProfile::MyIdeas"} />
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <Link
                    component={RouterLink}
                    to={"/wallet"}
                    className={paperStyle.link}
                  >
                    <Card className={classes.card} elevation={0}>
                      <CardContent className={classes.root}>
                        <Typography
                          variant="h6"
                          color="primary"
                          className={classes.totalsFont}
                        >
                          <NumberFormat
                            value={profiles.countAvailablePoints}
                            thousandSeparator={true}
                            displayType={"text"}
                            format={formatNumber(profiles.countAvailablePoints)}
                          />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          noWrap
                          className={paperStyle.capitalize}
                        >
                          <Resource tag={"PageProfile::AvailablePoints"} />
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <Link
                    component={RouterLink}
                    to={"/wallet"}
                    className={paperStyle.link}
                  >
                    <Card className={classes.card} elevation={0}>
                      <CardContent className={classes.root}>
                        <Typography
                          variant="h6"
                          color="primary"
                          className={classes.totalsFont}
                        >
                          <NumberFormat
                            value={profiles.countInvestedPoints}
                            thousandSeparator={true}
                            displayType={"text"}
                            format={formatNumber(profiles.countInvestedPoints)}
                          />
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          noWrap
                          className={paperStyle.capitalize}
                        >
                          <Resource tag={"PageProfile::InvestedPoints"} />
                        </Typography>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              </Grid>
              <Link
                component={RouterLink}
                to="/keytasks"
                className="key-tasks-to-link"
              >
                <Button
                  id="ProfileBottomKeyTasks"
                  onClick={toggleProfile}
                  className={paperStyle.buttons}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  <FormatListBulletedIcon className={paperStyle.iconKeyTask} />
                  <div className={paperStyle.divIconKeyTask}>
                    <Resource tag={"PageProfile::MyArea_KeyTasks"} />
                  </div>
                  <Badge
                    color="error"
                    overlap="circle"
                    className={paperStyle.badge}
                  >
                    {profiles.countKeyTasks}
                  </Badge>
                </Button>
              </Link>
              {newApp && (
                <Link
                  component={RouterLink}
                  to="/messages"
                  style={{ textDecoration: "none", display:"none" }}
                >
                  <Button
                    onClick={toggleProfile}
                    className={paperStyle.btnMessage}
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="small"
                    disableElevation
                  >
                    <DraftsIcon
                      color="primary"
                      className={paperStyle.iconKeyTask}
                    />
                    <div className={paperStyle.divIconKeyTask}>Messages</div>
                    <Badge
                      color="error"
                      overlap="circle"
                      className={paperStyle.badge}
                    >
                      {props?.receivedMessages?.meta?.total || 0}
                    </Badge>
                  </Button>
                </Link>
              )}
              <hr />
              <List className={classes.list}>
                <ListItem
                  id="ProfileBottomItemWallet"
                  button
                  classes={{ root: classes.item }}
                  onClick={() => {
                    toggleProfile();
                    history.push("/wallet");
                  }}
                >
                  <ListItemIcon className={classes.icon}>
                    <EMIcon icon="wallet" />
                  </ListItemIcon>
                  <ListItemText className={classes.item}>
                    <Resource tag={"PageProfile::MyArea_Wallet"} />
                  </ListItemText>
                </ListItem>
              </List>
              {/* <List> //TODO:Ativar este item quando for implementar os badges
                <ListItem button classes={{root: classes.item}} onClick={()=>{toggleProfile(); history.push('/badges')}}>
                  <ListItemIcon className={classes.icon}>
                    <EMIcon icon="badges"/>
                  </ListItemIcon>
                  <ListItemText className={classes.item} primary="Badges" />
                </ListItem>
              </List> */}
              <List className={classes.list}>
                <ListItem
                  id="ProfileBottomItemDrafts"
                  button
                  classes={{ root: classes.item }}
                  onClick={() => {
                    toggleProfile();
                    history.push("/drafts");
                  }}
                >
                  <ListItemIcon className={classes.icon}>
                    <EMIcon icon="drafts" />
                  </ListItemIcon>
                  <ListItemText className={classes.item}>
                    <Resource tag={"PageProfile::MyArea_IdeaDrafts"} />
                  </ListItemText>
                </ListItem>
              </List>
              <List className={classes.list}>
                <ListItem
                  id="ProfileBottomItemMyActivity"
                  button
                  classes={{ root: classes.item }}
                  onClick={() => {
                    toggleProfile();
                    history.push("/MyActivity", { pageDefault: "IDEAS" });
                  }}
                >
                  <ListItemIcon className={classes.icon}>
                    <EMIcon icon="activity" />
                  </ListItemIcon>
                  <ListItemText className={classes.item}>
                    <Resource tag={"PageProfile::MyArea_MyActivity"} />
                  </ListItemText>
                </ListItem>
              </List>
              <hr />
              <List className={classes.list}>
                <ListItem
                  id="ProfileBottomItemConfig"
                  button
                  classes={{ root: classes.item }}
                  onClick={() => {
                    toggleProfile();
                    history.push("/profile");
                  }}
                >
                  <ListItemIcon className={classes.icon}>
                    <EMIcon icon="settings" />
                  </ListItemIcon>
                  {/* <ListItemText className={classes.item} primary="Configurations" /> */}
                  <ListItemText className={classes.item}>
                    <Resource tag={"PageProfile::MyArea_Configurations"} />
                  </ListItemText>
                </ListItem>
              </List>
              <List className={classes.list}>
                <ListItem
                  id="ProfileBottomItemLogout"
                  button
                  classes={{ root: classes.item }}
                  onClick={handleLogoutClickOpen}
                >
                  <ListItemIcon className={classes.icon}>
                    <EMIcon icon="logout" />
                  </ListItemIcon>
                  <ListItemText className={classes.item}>
                    <Resource tag={"PageProfile::Button_Logout"} />
                  </ListItemText>
                </ListItem>
              </List>

              {newApp && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt={2}
                  style={{ gap: 8 }}
                >
                  <Box fontSize="0.7rem">
                    <Link
                      color="textSecondary"
                      href={profileData?.urlPrivacyPolicy}
                      target="_blank"
                    >
                      <Resource tag="PageProfile::Policy" />
                    </Link>
                  </Box>
                  <FiberManualRecordIcon
                    style={{ fontSize: "0.25rem", color: "#828282" }}
                  />
                  <Box fontSize="0.7rem">
                    <Link
                      color="textSecondary"
                      href={profileData?.urlTermsOfService}
                      target="_blank"
                    >
                      <Resource tag="PageProfile::Terms" />
                    </Link>
                  </Box>
                  <FiberManualRecordIcon
                    style={{ fontSize: "0.25rem", color: "#828282" }}
                  />
                  <Box fontSize="0.7rem">
                    <Link
                      component={RouterLink}
                      to="/regulations"
                      color="textSecondary"
                      target="_blank"
                    >
                      <Resource tag="PageProfile::Regulation" />
                    </Link>
                  </Box>
                </Box>
              )}
              {/* <div onClick={toggleProfile} className="navProfile">
                <ProfileLink to="wallet" icon="wallet" color="#313b53" title="Wallet" />
                <ProfileLink to="badges" icon="badges" color="#313b53" title="Badges" />
                <ProfileLink to="drafts" icon="drafts" color="#313b53" title="Idea drafts" />
                <ProfileLink to="MyActivity" icon="activity" color="#313b53" title="My activity" />
              </div>
              <hr />
              <div onClick={toggleProfile} className="navProfile" >
                <ProfileLink to="profile" icon="settings" color="#313b53" title="Configurations" />
                <ProfileLink to="log-out" icon="logout" color="#313b53" title="Log-out" />
              </div> */}
            </Container>
          );
        })}
      <Dialog
        ref={container}
        open={openLogout}
        onClose={handleLogoutClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <Box className={classes.dialogContainer}>
          <DialogTitle
            disableTypography
            id="alert-dialog-title"
            className={classes.dialogTitle}
          >
            <Typography variant="h4">
              <Resource tag={"LogoutPopUp::WantLogOut"} />
            </Typography>
          </DialogTitle>
          <DialogActions className={classes.dialogAction}>
            <Button
              onClick={handleLogoutClose}
              variant="contained"
              color="primary"
            >
              <Resource tag={"LogoutPopUp::Cancel"} />
            </Button>
            <Button
              onClick={handleLogout}
              variant="outlined"
              color="primary"
              autoFocus
            >
              <Resource tag={"LogoutPopUp::Yes"} />
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Paper>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    culture: store.cultureState,
    tenantId: store.tenantState.activeTenant.tenantId,
    hasSSOLogout: store.tenantState.activeTenant.hasSSOLogout,
    profileData: store.profileState.profileData,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, IAuthLogoutAction>
): IDispatchProps => {
  return {
    logOutConnect: () => dispatch(doLogout()),
    updateProfile: (params: object, options: object) =>
      dispatch(updateProfile(params, options)),
    getProfile: (options: object) => dispatch(getProfile(options)),
    logoutSSO: (tenantId: number) => dispatch(doLogoutSSO(tenantId)),
    getProfileData: () => dispatch(getProfileData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBottom);
