import React from "react";
import ReplyIcon from "@material-ui/icons/Reply";
import Resource from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import TextEditorInput from "components/GenericField/TextEditorInput/TextEditorInput";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

interface IProps {
  culture: ICultureState;
  isOpen: boolean;
  setIsOpen: (on: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    widthCheckboxBtn: {
      alignItems: "center",
      flexFlow: "nowrap",
      "& .MuiSvgIcon-root": {
        width: "0.7em",
        height: "0.7em",
      },
    },
    labelCheckbox: {
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
        color: theme.palette.primary.main,
      },
    },
    paperForwardContainer: {
      borderRadius: 6,
      marginLeft: "16px",
      width: "100%",
    },
    forwardIcon: {
      color: theme.palette.text.secondary,
      transform: "rotate(180deg)",
      fontSize: "1rem",
      marginRight: "8px",
    },
  })
);

function MessageForwardDialog(props: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const matchXsSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const { culture, isOpen, setIsOpen } = props;

  //reply functions: rich text
  const msgDescriptionProps = {
    columnName: "msg-description",
    isMandatory: false,
    typeId: 9,
    title: "",
    isEditable: true,
  };
  const valueRichText =
    "Consectetur adipiscing elit, sed do e ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim, Sarah Philips";

  //reply functions: checkbox
  const [stateCheckbox, setStateCheckbox] = React.useState({
    checkedA: true,
    checkedB: false,
  });
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCheckbox({
      ...stateCheckbox,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      {isOpen && (
        <Box display="flex" className="forward-container">
          <Avatar
            className="forward-avatar"
            alt="avatar"
            src="https://cdn.iconscout.com/icon/free/png-512/ironman-marvel-super-hero-earth-saver-avenger-28699.png"
          />
          <Paper elevation={3} className={classes.paperForwardContainer}>
            <Box
              className="icon-and-email"
              display="flex"
              padding="12px 12px 8px"
            >
              <ReplyIcon className={classes.forwardIcon} />
              <Typography color="textSecondary" variant="subtitle1">
                To:
              </Typography>
            </Box>
            <Box className="forward-content" padding="0 24px 24px">
              <Box className="forwarded-message-container" mb={3}>
                <Box fontWeight="bold">
                  ---------- Forwarded message ---------
                </Box>
                <Box>From: Sarah Philips &lt;s.philips@exago.com&gt;</Box>
                <Box>Date: 7 july 2020 às 11:31 am</Box>
                <Box>
                  Subject: Convergence of telecommunications infrastructure
                </Box>
                <Box>To: &lt;a.smith@exago.com&gt;</Box>
              </Box>
              <Box className="forward-description">
                <TextEditorInput
                  value={valueRichText}
                  culture={culture}
                  propsI={msgDescriptionProps}
                  charCounterLabelShow={false}
                  maxCharCountShow={false}
                />
              </Box>
              <Box
                className="forward-actions-container"
                mt={matchXsSm ? 14 : 8}
                display={matchXsSm ? "grid" : "flex"}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box className="forward-checkbox">
                  <FormControl
                    component="fieldset"
                    className={classes.widthCheckboxBtn}
                    style={{
                      display: matchXsSm ? "grid" : "flex",
                      gap: matchXsSm ? "8px" : "20px",
                    }}
                  >
                    <FormLabel>
                      <Box fontSize="0.7rem" fontWeight="bold">
                        <Resource tag={"PageMessages::SendChannels"} />
                      </Box>
                    </FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        className={classes.labelCheckbox}
                        control={
                          <Checkbox
                            checked={stateCheckbox.checkedA}
                            onChange={handleChangeCheckbox}
                            name="checkedA"
                            color="primary"
                          />
                        }
                        label={
                          <Resource tag={"PageMessages::InternalMessage"} />
                        }
                      />
                      <FormControlLabel
                        className={classes.labelCheckbox}
                        control={
                          <Checkbox
                            checked={stateCheckbox.checkedB}
                            onChange={handleChangeCheckbox}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={<Resource tag={"PageMessages::EmailMessage"} />}
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
                <Box
                  className="forward-buttons-cancel-send"
                  display="flex"
                  mt={matchXsSm ? 2 : 0}
                >
                  <Box mr={2}>
                    <Button
                      color="primary"
                      size="small"
                      variant="text"
                      onClick={() => setIsOpen(false)}
                    >
                      <Resource tag={"PageMessages::Cancel"} />
                    </Button>
                  </Box>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    // onClick={() => setOpen(false)}
                  >
                    <Resource tag={"PageMessages::Send"} />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
}

export default MessageForwardDialog;
