import { AnyAction } from "redux";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { ArrowForward } from "@material-ui/icons";
import Resource from "components/Resources/Resource";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import ToolTip from "components/ToolTip/ToolTip";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  TextField,
  Button,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import {
  getAllWorkspaces,
  getSuggestedNamesWorkspaces,
  getWorkspace,
  postWorkspaces,
  putWorkspacesName,
} from "redux/actions/workspaceAction";
import {
  IWorkspaceReducer,
  ISuggestedNamesWorkspaces,
  IWorkspaceState,
} from "redux/reducers/workspaceReducer";

interface IDispatchProps {
  getAllWorkspace: (options: object) => Promise<IWorkspaceState>;
  putWorkspaceName: (options: object) => Promise<IWorkspaceState>;
  getSuggestedNamesWorkspace: (options: object) => Promise<IWorkspaceState>;
  postWorkspaces: (options: object) => Promise<any>;
  getWorkspace: (options: object) => Promise<IWorkspaceState>;
}

interface IProps {
  setCurrentScreen: Dispatch<SetStateAction<number>>;
}

interface IStateProps {
  workspaces: IWorkspaceReducer;
  suggestedNames: ISuggestedNamesWorkspaces;
  application: IApplication;
  culture: ICultureState;
  istab?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setWorkspaceTitle: {
      fontSize: "22px",
      textAlign: "left",
      color: "#313B53",
      fontWeight: 700,
      marginBottom: theme.spacing(7),
    },
    btnNames: {
      padding: "5px 8px",
      marginRight: "8px",
    },
  })
);

const options = {
  applicationId: 2,
  culture: {},
};

function StepWorkspaceName(props: IDispatchProps & IStateProps & IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const {
    setCurrentScreen,
    getAllWorkspace,
    getSuggestedNamesWorkspace,
    putWorkspaceName,
    postWorkspaces,
    getWorkspace,
    workspaces,
    suggestedNames,
    istab
  } = props;


  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceSuggestedName, setWorkspaceSuggestedName] = useState("");

  useEffect(() => {
    options.culture = props?.culture;
    let isMounted = true;

    if (!istab) {
      isMounted &&
        Promise.all([
          getAllWorkspace(options),
          getSuggestedNamesWorkspace(options),
        ]).finally(() => isMounted && setLoading(false));

      return () => {
        isMounted = false;
      };
    } else {
      isMounted &&
        Promise.all([
          getSuggestedNamesWorkspace(options),
        ]).finally(() => isMounted && setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workspaces?.name) {
      setWorkspaceName(workspaces?.name);
    }
  }, [workspaces]);

  const handleWorkspacenameChange = (e: any) => {
    setWorkspaceName(e.target.value);
    setWorkspaceSuggestedName("");
  };

  const handleSuggestedName = (name: any) => {
    setWorkspaceName(name);
    setWorkspaceSuggestedName(name);

    if (name === workspaceSuggestedName) setWorkspaceSuggestedName("");
  };

  function putNameWorkspace() {
    setLoadingSubmit(true);
    let params = {};

    if (workspaceName !== "" || workspaceSuggestedName !== "") {
      params = {
        culture: props?.culture,
        name:
          workspaceName !== ""
            ? sanitizeHtml(workspaceName)
            : workspaceSuggestedName,
        workspaceId: workspaces?.workspaceId,
      };

      const obj = {
        applicationId: props.application.applicationId,
        culture: props.culture,
        workspaceId: props.workspaces.workspaceId,
      };

      if (
        props?.workspaces?.workspaceId === "" ||
        props?.workspaces?.workspaceId === undefined
      ) {
        postWorkspaces({ category: 1, culture: props.culture }).then(
          (response) => {
            params = {
              culture: props.culture,
              name:
                workspaceName !== ""
                  ? sanitizeHtml(workspaceName)
                  : workspaceSuggestedName,
              workspaceId: response.data,
            };

            if(!istab){
              getAllWorkspace(options).then(() => {
                putWorkspaceName(params).then(() => {
                  getAllWorkspace(options).then(() => {
                    setCurrentScreen((prevState) => prevState + 1);
                  });
                  setLoadingSubmit(false);
                });
              });
            }else{
              getWorkspace(obj).then(() => {
                putWorkspaceName(params).then(() => {
                  getWorkspace(obj);
                  setLoadingSubmit(false);
                });
              });
            }
          }
        );
      } else {
        if(!istab){
          getAllWorkspace(options).then(() => {
            putWorkspaceName(params).then(() => {
              getAllWorkspace(options).then(() => {
                setCurrentScreen((prevState) => prevState + 1);
              });
              setLoadingSubmit(false);
            });
          });
        }else{
          getWorkspace(obj).then(() => {
            putWorkspaceName(params).then(() => {
              getWorkspace(obj);
              setLoadingSubmit(false);
            });
          });
        }
      }
    }
  }

  return (
    <Container>
      {loading ? (
        <Box display="flex" mt={26} ml={20}>
          <Box>
            <CircularProgress size={50} className="icon" />
          </Box>
        </Box>
      ) : (
        <>
          <Typography className={classes.setWorkspaceTitle}>
            <Resource tag="PageSetup::NameWorkspace_Question" />
          </Typography>
          <TextField
            id="workspaceName"
            label={
              <Box display="flex" alignItems="center" gridGap="5px">
                <Resource tag={"PageSetup::NameWorkspace_Name"} />
                <ToolTip children={<Resource tag="PageSetup::NameWorkspaceToltip" />} />
              </Box>
            }
            fullWidth
            value={workspaceName}
            onChange={handleWorkspacenameChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {suggestedNames && (
            <Box textAlign="left" mt={7} mb={4}>
              <Box mb={3}>
                <Resource tag="PageSetup::NameWorkspace_Suggested" />
              </Box>
              {suggestedNames.map((sName, index) => (
                <Button
                  key={index}
                  className={classes.btnNames}
                  color="primary"
                  variant={
                    sName === workspaceSuggestedName ? "outlined" : "text"
                  }
                  onClick={() => handleSuggestedName(sName)}
                >
                  {sName}
                </Button>
              ))}
            </Box>
          )}
          <Box textAlign="right" mt={7}>
            <Button
              disabled={!workspaceName && !workspaceSuggestedName}
              onClick={() => putNameWorkspace()}
              color="primary"
              variant="contained"
              endIcon={
                !loadingSubmit ? (
                  <ArrowForward className="icon icon-arrow" />
                ) : (
                  <CircularProgress
                    size={22}
                    style={{ color: `${theme.palette.common.white}` }}
                  />
                )
              }
            >
              {istab ?
                <Resource tag="PageSetup::Save" /> :
                <Resource tag="PageSetup::Continue" />
              }
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllWorkspace: (options: object) => dispatch(getAllWorkspaces(options)),
    getWorkspace: (options: object) => dispatch(getWorkspace(options)),
    getSuggestedNamesWorkspace: (options: object) =>
      dispatch(getSuggestedNamesWorkspaces(options)),
    putWorkspaceName: (params: object) => dispatch(putWorkspacesName(params)),
    postWorkspaces: (params: object) => dispatch(postWorkspaces(params)),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    application: store.tenantState.application,
    culture: store.cultureState,
    workspaces: store.workSpacesState.workspaces,
    suggestedNames: store.workSpacesState.suggestedNamesWorkspaces,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepWorkspaceName);
