import React from "react";
import "moment-timezone";
import "moment/locale/pt"; //Portuguese
import "moment/locale/es"; //Spanish
import Moment from "react-moment";
import { connect } from "react-redux";
import { IAppState } from "../../redux/store/Store";

/* Example
    <TimeFrom time="2020-07-16T12:59-0500"/>
*/

interface IProps {
  time: string;
}

interface IStateProps {
  locale: string;
}

function TimeFrom(props: IProps & IStateProps) {
  let dateWithTimeZone = props?.time + "+0100";
  return (
    <Moment locale={props?.locale} fromNow>
      {dateWithTimeZone}
    </Moment>
  );
}

const mapStateToProps = (store: IAppState): IStateProps => ({
  locale: store?.authState?.auth?.cultureTag,
});

export default connect(mapStateToProps)(TimeFrom);
