import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  makeStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: 320,
    display: "grid",
    gridGap: "24px",
  },
  card: {
    display: "grid",
    gridTemplateRows: "1fr auto",
    minHeight: 280,
  },
  media: {
    height: 175,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  body: {
    alignSelf: "baseline",
    textAlign: "start",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
});

interface IProps {
  isLoading?: boolean;
}

function NewsCardSkeleton(props: IProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea className={classes.body}>
          <Skeleton variant="rect" height={175} />
          <CardContent>
            <Skeleton width="60%" />
            <Skeleton />
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing className={classes.actions}>
          <Skeleton />
        </CardActions>
      </Card>
    </div>
  );
}

export default NewsCardSkeleton;
