import React, { useState, useEffect } from "react";
import {
  Checkbox,
  CircularProgress,
  createStyles,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Box, Button } from "@material-ui/core";
import UserListItem from "../../../../common/UI/List/User/UserListItem";
import TextToolTip from "components/ToolTip/TextTooltip";
import Resource, { resource } from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import DialogAssignMembersToGroup from "../Dialogs/DialogAssignMembersToGroup";
import ManagePoints from "../Dialogs/ManagePoints";
import { IManageUsers } from "redux/types/manageUsers";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import { IAllManageGroups } from "redux/types/manageGroups";
import { toast } from "react-toastify";

interface IProps {
  culture: ICultureState;
  users: IManageUsers;
  postUserDeactivate: any;
  activateStatus: string;
  userStatus: string;
  workspaceId: string;
  putAssignGroups: any;
  allGroups: IAllManageGroups[];
  handleUpdate:any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& label": {
        marginBottom: "15px",
        marginTop: "15px",
        marginRight: "8px",
        "& span:last-child": {
          width: "100%",
        },
      },
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  })
);
export default function ManageActiveUsersList(props: IProps) {
  const {
    culture,
    users,
    postUserDeactivate,
    activateStatus,
    workspaceId,
    userStatus,
    allGroups,
    putAssignGroups,
    handleUpdate
  } = props;
  const classes = useStyles();
  const [assignToGroup, setAssignToGroup] = useState(false);
  const [openDeactivateUser, setOpenDeactivateUser] = useState(false);
  const [state, setState] = useState<any>([]);
  const [openPointsDialog, setOpenPoints] = useState(false);

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!users || users?.result?.length === 0) {
      toast.error(resource(props.culture, "PageSettings::NoResults"));
    }
  }, [users?.result?.length]);
  
  return (
    <FormGroup className={classes.root}>
      {!users || users?.result?.length === 0 ? (
        <Box display="flex" justifyContent="flex-end" width="100%" mb={1}>
          <Typography
            style={{ paddingRight: "15px" }}
            variant="body2"
            color="textSecondary">
            0 <Resource tag="PageSettings::users" />
          </Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" width="100%" mb={1}>
          <FormControlLabel
            style={{
              marginBottom: "0px",
              marginTop: "0px",
            }}
            control={
              <Checkbox
                checked={state.length === users?.result?.length}
                onChange={() =>
                  state?.length !== users?.result?.length
                    ? setState(users?.result)
                    : setState([])
                }
                name="checkedAll"
                color="primary"
              />
            }
            label=""
          />
          <Grid
            container
            direction={matchSm ? "column-reverse" : "row"}
            spacing={2}
          >
            <Grid
              justify="flex-start"
              container
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              style={{ gap: "20px" }}
            >
              <Button
                onClick={(e: any) => {
                  setAssignToGroup(true);
                  e.stopPropagation();
                }}
                onFocus={(e: any) => e.stopPropagation()}
                // aria-label="Buttons"
                variant="text"
                color="primary"
                disabled={state.length === 0}
                style={{
                  textTransform: "uppercase",
                }}
              >
                <Resource tag="PageSettings::AssignToGroup" />
              </Button>
              <Button
                onClick={(e: any) => {
                  e.stopPropagation();
                  setOpenPoints(true);
                }}
                onFocus={(e: any) => e.stopPropagation()}
                // aria-label="Buttons"
                variant="text"
                color="primary"
                disabled={state.length === 0}
                style={{
                  textTransform: "uppercase",
                }}
              >
                <Resource tag="PageSettings::ManagePoints" />
              </Button>
              <ManagePoints
                usersId={state.map((data: any) => data.userId)}
                setOpen={setOpenPoints}
                open={openPointsDialog}
              />
              {activateStatus === "" ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setOpenDeactivateUser(true);
                  }}
                  onFocus={(e: any) => e.stopPropagation()}
                  // aria-label="Buttons"
                  variant="text"
                  color="primary"
                  disabled={state.length === 0}
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <Resource tag="PageSettings::Deactivate" />
                </Button>
              )}
              <PopUpConfirmation
                message={<Resource tag="PageManageUsers::DeactivateUser" />}
                setIsOpen={setOpenDeactivateUser}
                isOpen={openDeactivateUser}
                closeButton={() => setOpenDeactivateUser(false)}
                sendButton={() =>
                  postUserDeactivate({
                    usersId:
                      state?.length > 0
                        ? state?.map((data: any) => data.userId)
                        : [],
                    workspaceId,
                  }).then(()=>handleUpdate())
                }
              />
            </Grid>
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              wrap="nowrap"
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
            >
              <Typography
                style={{ paddingRight: "15px" }}
                variant="body2"
                color="textSecondary"
              >
                {users?.meta?.total} <Resource tag="PageSettings::users" />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {userStatus === "" ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={5}>
          <CircularProgress />
        </Box>
      ) : (
        users &&
        users?.result?.map((data, index) => {
          return (
            <FormControlLabel
              key={data.userId}
              control={
                <Checkbox
                  checked={state
                    .map((x: any) => x.userId)
                    .includes(data?.userId)}
                  onChange={() =>
                    state.map((x: any) => x.userId).includes(data?.userId)
                      ? setState(
                          state.filter((y: any) => y.userId !== data?.userId)
                        )
                      : setState([...state, data])
                  }
                  name="checkedA"
                  color="primary"
                />
              }
              label={
                <UserListItem
                  culture={culture}
                  userId={data?.userId}
                  checked={state
                    .map((x: any) => x?.userId)
                    .includes(data?.userId)}
                  principalText={data?.fullName}
                  subtitle={data?.email}
                  photo={data?.photo}
                  groups={
                    !matchSm ? (
                      <TextToolTip
                        text={
                          <Typography
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            variant="body1"
                            noWrap
                          >
                            {data?.groups?.length}{" "}
                            <Resource tag="PageSettings::groups" />
                          </Typography>
                        }
                        title={
                          <Resource tag="PageManageUsers::Groups_Assigned" />
                        }
                      >
                        {data?.groups?.map((group, index) => {
                          return (
                            <Box key={group?.groupId}>- {group?.title}</Box>
                          );
                        })}
                      </TextToolTip>
                    ) : (
                      <Typography variant="caption" noWrap>
                        {data?.groups?.length}{" "}
                        <Resource tag="PageSettings::groups" />
                      </Typography>
                    )
                  }
                  points={
                    <Typography variant="caption" noWrap>
                      <Resource
                        tag="PageManageUsers::Points"
                        args={[data?.availablePoints]}
                      />
                    </Typography>
                  }
                  typeUser={
                    data?.permission === 1 ? (
                      <Resource tag="ManageUsers::Admin" />
                    ) : data?.permission === 2 ? (
                      <Resource tag="ManageUsers::ChallengeManager" />
                    ) : (
                      <Resource tag="ManageUsers::Participant" />
                    )
                  }
                  // online="online 2h ago" //TODO: This data it's not available yet. awaiting backend
                />
              }
            />
          );
        })
      )}
      <DialogAssignMembersToGroup
        workspaceId={workspaceId}
        usersId={
          state?.length > 0 ? state?.map((data: any) => data.userId) : []
        }
        allGroups={allGroups}
        putAssignGroups={putAssignGroups}
        open={assignToGroup}
        setOpen={setAssignToGroup}
      />
    </FormGroup>
  );
}
