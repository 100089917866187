import React, { Component } from "react";
import { ICustomInputProps } from '../../redux/types/inputs'
import ToolTip from '../../components/ToolTip/ToolTip';
import EMIcon from "components/Icon/Icon";
import "./InputDate.css";
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/en-gb';
import 'moment/locale/pt';

/*
    Example
    
    <InputDate props={{
        columnName: "COLUMN",
        isMandatory: true,
        length: 30,
        minValue: "1",
        maxValue: "5",
        defaultValue: "3",
        typeId: 3,
        validationExpression: "[a-zA-Z0-9]+",
        title: "Titulo do Campo Inteiro",
        info: "string",
        help: "Help do Campo",
        error: "string",
        isEditable: true,
        cultureTag: "pt-PT"
    }} />  
*/


interface IState {
  dateBirth: string
  day: string,
  month: string,
  year: string,
  daysMonth: number,
  cultureTag?: string
}

export default class InputDate extends Component<ICustomInputProps, IState>{
  constructor(props: ICustomInputProps & IState) {
    super(props);

    const { value } = this.props

    let date = [] as Array<string>

    if(value !== null){
      date = value.split('/');
    }
   
    this.state = {
      dateBirth: value ? value : '',
      day: date[0] ? date[0] : '',
      month:date[1] ? date[1] : '',
      year: date[2] ? date[2] : '',
      daysMonth: 31,
      cultureTag: this.props.props.cultureTag
    }
  }

  componentDidMount(){
    const {value} = this.props

    let date = [] as Array<string>

    if(value !== null){
       date = value.split('/');
    }

    this.setState({
      dateBirth: value ? value : '',
      day: date[0] ? date[0] : '',
      month:date[1] ? date[1] : '',
      year: date[2] ? date[2] : '',
    })
  }

  public render() {
    let {props} = this.props;
    const { day, month, year} = this.state
    let years = []
    let days = []
    let culture = this.state.cultureTag?.toLocaleLowerCase();

    let monthId=month.toString()

    if(culture === "pt-pt") {
      moment.locale("pt")
    }else {
      moment.locale(`${culture}`)
    }
       
    let monthsAbbreviated = moment.monthsShort()
    let months = []
    
    for(let d = 1; d <= this.state.daysMonth; d++) {
      days.push(d);
    }

    for(let m = 1; m <= 12; m++) {
      monthsAbbreviated[m -1] = monthsAbbreviated[m-1].charAt(0).toLocaleUpperCase() + monthsAbbreviated[m-1].slice(1);
      months.push({idMonth: m <= 9 ? "0" + m : m, nameMonth: monthsAbbreviated[m -1]});
    }
    if(this.props.noFutureDate){
      for(let y = 0; y < 120; y++) {
        years.push(new Date().getFullYear() - y);
      }
    }else{
      for(let y = 0; y< 120; y++) {
        years.push((new Date().getFullYear()+ 10) - y)
      }
    }
    
    

    const handleChangeDay = (event: any) => {
      let newDay = event.target.value
      let newDateBirth = newDay + "/" + this.state.month + "/" + this.state.year
      
      this.setState({
        dateBirth: newDateBirth,
        day: newDay
      },()=>{
        if(this.state.dateBirth !== '' && this.props.handler !== undefined){
          this.props.handler()
        }
      })

    }

    const handleChangeMonth = (event: any) => {
      let newMonth = event.target.value 
      
      let yearMonth = this.state.year + "-" + newMonth

      let newDays = moment(yearMonth).daysInMonth();
      let newDateBirth = this.state.day + "/" + newMonth + "/" + this.state.year

      this.setState({
        dateBirth: newDateBirth,
        daysMonth: newDays,
        month: newMonth
      },()=>{
        if(this.state.dateBirth !== ''  && this.props.handler !== undefined){
          this.props.handler()
        }
      })

    }

    const handleChangeYear = (event: any) => {
      let newYear = event.target.value
      let yearMonth = newYear + "-" + this.state.month
      let newDays = moment(`${yearMonth}`).daysInMonth();
      let newDateBirth = this.state.day + "/" + this.state.month + "/" + newYear
      
      this.setState({ 
        dateBirth: newDateBirth,
        year: newYear,
        daysMonth: newDays
      },()=>{
        if(this.state.dateBirth !== ''  && this.props.handler !== undefined){
          this.props.handler()
        }
      })

    }
    
    return (
      <div className="inputSelect-container">
          <input type="hidden" onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()} value={this.state.dateBirth} name={props.columnName}></input>
          <span>
            <h4 className="inputSelect-title">{props.title}{props.isMandatory ? '*' : ''}</h4>
            {/* TODO Translate Tooltips's Title */}
            {props.help ? <ToolTip title="Help">{props.help}</ToolTip> : ''}
          </span>
          
          <div className="inputSelect-content">
          <div className="dropdown-container">
            <select id="day" onChange={handleChangeDay} defaultValue={day}
              //readOnly={!props.isEditable} 
              required={props.isMandatory} 
              //pattern={props.validationExpression}  
              {...(props.minValue!=null ? {min : props.minValue} : {})}   
              {...(props.maxValue!=null ? {max : props.maxValue} : {})}     
            > 
              <option value={''}>Select an option</option>
              {days.map((day) => {
                return(
                  <option key={day} value={day}>{day}</option>
                )
              })}
            </select>
            <div className="select-icon">
              <EMIcon color={'#1B9DFC'} size={18} icon={'arrow-down'} />
            </div>
          </div>

          <div className="dropdown-container">          
            <select id="month" onChange={handleChangeMonth} defaultValue={monthId}
                //readOnly={!props.isEditable} 
                required={props.isMandatory} 
                //pattern={props.validationExpression}  
                {...(props.minValue!=null ? {min : props.minValue} : {})}   
                {...(props.maxValue!=null ? {max : props.maxValue} : {})}   
            >
              <option value={''} >Select an option</option>
              {months.map(month => {
                return(
                  <option key={month.idMonth} value={month.idMonth}>{month.nameMonth}</option>
                )
              })}
            </select>
            <div className="select-icon">
              <EMIcon color={'#1B9DFC'} size={18} icon={'arrow-down'} />
            </div>
          </div>

          <div className="dropdown-container">
            <select id="year" onChange={handleChangeYear} defaultValue={year}
                //readOnly={!props.isEditable} 
                required={props.isMandatory} 
                //pattern={props.validationExpression}  
                {...(props.minValue!=null ? {min : props.minValue} : {})}   
                {...(props.maxValue!=null ? {max : props.maxValue} : {})}   
            >
              <option value={''} >Select an option</option>
              {years.map(year => {
                return(
                  <option key={year} value={year}>{year}</option>
                )
              })}
            </select>
            <div className="select-icon">
              <EMIcon color={'#1B9DFC'} size={18} icon={'arrow-down'} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}