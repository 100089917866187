import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { ArrowForward } from "@material-ui/icons";
import Resource from "components/Resources/Resource";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Button,
  CircularProgress,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  getAllWorkspaces,
  getSuggestedNamesWorkspaces,
  getWorkspace,
  postWorkspaces,
  postWorkspacesLanguage,
  putWorkspacesName,
} from "redux/actions/workspaceAction";
import {
  IWorkspaceReducer,
  ISuggestedNamesWorkspaces,
  IWorkspaceState,
  ICulturesWorkspaces,
} from "redux/reducers/workspaceReducer";
import { IAuthUser } from "redux/reducers/authReducer";
import ToolTip from "components/ToolTip/ToolTip";

interface IDispatchProps {
  getAllWorkspace: (options: object) => Promise<IWorkspaceState>;
  getWorkspace: (options: object) => Promise<IWorkspaceState>;
  putWorkspaceName: (options: object) => Promise<IWorkspaceState>;
  getSuggestedNamesWorkspace: (options: object) => Promise<IWorkspaceState>;
  postWorkspaces: (options: object) => Promise<any>;
  postLanguage: (options: object) => Promise<any>;
}

interface IProps {
  setCurrentScreen: Dispatch<SetStateAction<number>>;
}

interface IStateProps {
  authUser: IAuthUser;
  workspaces: IWorkspaceReducer;
  languages: ICulturesWorkspaces[];
  suggestedNames: ISuggestedNamesWorkspaces;
  application: IApplication;
  culture: ICultureState;
  tenantId: number;
  istab?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setWorkspaceTitle: {
      fontSize: "22px",
      textAlign: "left",
      color: "#313B53",
      fontWeight: 700,
      marginBottom: theme.spacing(7),
    },
    btnNames: {
      padding: "5px 8px",
      marginRight: "8px",
    },
  })
);


const options = {
  applicationId: 2,
  workspaceId: '',
  culture: {}
};

function StepWorkspaceLanguage(props: IDispatchProps & IStateProps & IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [language, setLanguage] = useState<String | unknown>();
  const {
    setCurrentScreen,
    postLanguage,
    getAllWorkspace,
    getWorkspace,
    languages,
    workspaces,
    istab
  } = props;


  function putLanguageWorkspace() {
    setLoadingSubmit(true);

    options.culture = props.culture;
    options.workspaceId = workspaces.workspaceId;

    postLanguage({
      ...options,
      tenantId: props.tenantId,
      DefaultCultureTag: language
    }).then(() => {
      if (!istab) {
        getAllWorkspace(options)
          .then(() => {
            setCurrentScreen((prevState) => prevState + 1);
          });
      } else {
        getWorkspace(options);
      }
      setLoadingSubmit(false);
    });

  }

  return (
    <Box>
      <Typography className={classes.setWorkspaceTitle}>
        <Resource tag="PageSetup::ChooseLanguage" />
      </Typography>

      <FormControl fullWidth>
        <InputLabel shrink id="language-select">
          <Box display="flex" alignItems="center" gridGap="5px">
            <Resource tag="PageSetup::Language" />
            <ToolTip children={<Resource tag="PageSetup::LanguageToltip" />} />
          </Box>
        </InputLabel>
        <Select
          labelId="language-select"
          id="id-language-select"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          {languages?.map((tag) => {
            return (
              <MenuItem
                key={tag.cultureTag}
                value={tag.cultureTag}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Box display="flex" mr={1}>
                    <img
                      width="18px"
                      height="18px"
                      alt={tag.cultureDescription}
                      src={`/flags/${tag.cultureTag}.svg`}
                    ></img>
                  </Box>
                  <Box>{tag.cultureTitle}</Box>
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Box textAlign="right" mt={7}>
        <Button
          disabled={!language}
          onClick={() => putLanguageWorkspace()}
          color="primary"
          variant="contained"
          endIcon={
            !loadingSubmit ? (
              <ArrowForward className="icon icon-arrow" />
            ) : (
              <CircularProgress
                size={22}
                style={{ color: `${theme.palette.common.white}` }}
              />
            )
          }
        >
          {istab ?
            <Resource tag="PageSetup::Save" /> :
            <Resource tag="PageSetup::Continue" />
          }
        </Button>
      </Box>
    </Box>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllWorkspace: (options: object) => dispatch(getAllWorkspaces(options)),
    getWorkspace: (option: object) => dispatch(getWorkspace(option)),
    getSuggestedNamesWorkspace: (options: object) =>
      dispatch(getSuggestedNamesWorkspaces(options)),
    putWorkspaceName: (params: object) => dispatch(putWorkspacesName(params)),
    postWorkspaces: (params: object) => dispatch(postWorkspaces(params)),
    postLanguage: (params: object) => dispatch(postWorkspacesLanguage(params))
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    authUser: store.authState.auth,
    application: store.tenantState.application,
    tenantId: store.tenantState.activeTenant.tenantId,
    culture: store.cultureState,
    workspaces: store.workSpacesState.workspaces,
    languages: store.workSpacesState.culturesWorkspaces,
    suggestedNames: store.workSpacesState.suggestedNamesWorkspaces,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepWorkspaceLanguage);
