import { AnyAction } from "redux";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IDrafts } from "redux/types/ideas";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import imageEmpty from "assets/img/empty@3x.png";
import { ChallengeDrafts, IChallengeList } from "redux/types/challenges";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import IdeasDraftPageSkeleton from "./IdeasDraftPage.Skeleton";
import IdeaDraftCard from "components/IdeaDraftCard/IdeaDraftCard";
import Resource, { resource } from "components/Resources/Resource";
import { getDrafts, deleteDraft } from "redux/actions/ideas/IdeaAction";
import { deleteChallengeDraft } from 'redux/actions/Drafts/DraftAction';
import { getChallengeDraftsAction } from 'redux/actions/ChallengeActions';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Typography,
} from "@material-ui/core";
import { IChallengeState } from "redux/reducers/challenges/challengeReducer";
import ChallengeDraftCard from "components/ChallengeDraftCard/ChallengeDraftCard";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import DeleteDraftDialog from "components/Dialogs/DeleteDraftDialog";
import { IDraftState } from "redux/reducers/drafts/DraftsReducer";
import { removeSelectDraft, selectDraft } from "redux/actions/Drafts/DraftAction";

const useStyles = makeStyles(() =>
  createStyles({
    noDataDraft: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 2.0,
    },
    noDataDraftImg: {
      marginTop: "30px",
      width: "300px",
      height: "300px",
    },
  })
);

const options = {
  workspaceId: "",
  applicationId: 2,
  pageNumber: 0,
  pageSize: 0,
  culture: {},
};

const draftOptions = {
  workspaceId: "",
  applicationId: 0,
  pageNumber: 0,
  pageSize: 0,
  cultureTag: {},
  // isInfinite: false
}


interface IDispatchProps {
  getDrafts: (options: object) => Promise<IDrafts>;
  deleteDraft: (options: object) => Promise<any>;
  getChallengesDraft: (options: object) => Promise<IChallengeState>;
  selectDraft: (options: object) => Promise<IDraftState>;
  removeSelectDraft: () => Promise<IDraftState>;
  deleteChallengeDraft:(options:object) => Promise<any>;
}

interface IStateProps {
  workspaceId: string;
  application: IApplication;
  culture: ICultureState;
  drafts: IDrafts;
  challengeList: IChallengeList;
  challengeDrafts: ChallengeDrafts;
  draftState: IDraftState
}

function IdeaDraft(props: IDispatchProps & IStateProps) {
  const classes = useStyles();
  const [tab, setTab] = useState('1');
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(8);
  const [pageSizeChallenge, setPageSizeChallenge] = useState(6);
  const [pageNumber] = useState(1);
  const [isLoading, setLoad] = useState(false);
  const {
    workspaceId,
    getDrafts,
    drafts,
    deleteDraft,
    challengeList,
    culture,
    getChallengesDraft,
    challengeDrafts,
    draftState,
    removeSelectDraft,
    selectDraft,
    deleteChallengeDraft
  } = props;

  const textNoDataIdeas = resource(
    culture?.culture,
    "Errors::NoDraftsAvailable"
  ).split("<br></br>");

  const handleChangeTab = (event: any, newValue: string) => {
    setTab(newValue);
  }

  useEffect(() => {
    setPageSize(pageSize + 8);
    setPageSizeChallenge(pageSizeChallenge + 3);
    options.workspaceId = workspaceId;
    options.culture = props.culture;
    options.pageSize = pageSize;
    options.pageNumber = pageNumber;
    options.applicationId = props.application.applicationId;

    draftOptions.applicationId = props.application.applicationId;
    draftOptions.workspaceId = workspaceId;
    draftOptions.cultureTag = props.culture;
    draftOptions.pageNumber = pageNumber;
    draftOptions.pageSize = pageSizeChallenge;

    getDrafts(options).then(() => {
      getChallengesDraft(draftOptions).then(() => {
        setLoading(false);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMore = () => {
    setLoad(true);
    setPageSize(pageSize + 8);
    options.pageSize = pageSize;
    getDrafts(options).then(() => setLoad(false));
  };

  const handleMoreChallenge = () => {
    setLoad(true);
    setPageSizeChallenge(pageSizeChallenge + 3);
    draftOptions.pageSize = pageSizeChallenge;
    getChallengesDraft(draftOptions).then(() => setLoad(false));
  };

  const deleteThisDraft = (id: string, type: 'Idea' | 'Challenge') => {
    const { culture } = props;
    
    if(type === "Idea"){
      deleteDraft({ ideaId:id, culture }).then(() => {
        getDrafts(options);
      })
      .then(()=>removeSelectDraft());
    }else{
      deleteChallengeDraft({id, culture}).then(()=>{
        getChallengesDraft(draftOptions)
      }).then(()=>removeSelectDraft())
    }
  };

  return (
    <>
      {loading ? (
        <IdeasDraftPageSkeleton />
      ) : (
        <Box paddingY={5}>
          <TabContext value={tab}>
            <AppBar position="static" elevation={0}>
              <TabList onChange={handleChangeTab}>
                <Tab label={<Resource tag={"PageChallenges::Header"} />} value="1" />
                <Tab label={<Resource tag={"PageIdea::Header"} />} value="2" />
              </TabList>
            </AppBar>
            <TabPanel value="1">
              {challengeDrafts ?
                (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        display="flex"
                        gridGap={20}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {challengeDrafts?.result?.map((draft, index) => {
                          if (index === 0 || index % 2 === 0) {
                            return (
                              <ChallengeDraftCard
                                challengeList={challengeList}
                                draft={draft}
                                selectDraft={(object:any) =>{
                                  selectDraft(object)
                                }}
                              />
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box
                        display="flex"
                        gridGap={20}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {challengeDrafts?.result?.map((draft, index) => {
                          if (index === 1 || index % 2 !== 0) {
                            return (
                              <ChallengeDraftCard
                                challengeList={challengeList}
                                draft={draft}
                                selectDraft={(object:any) =>{
                                  selectDraft(object)
                                }}
                              />
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Box>
                    </Grid>
                    {challengeDrafts.meta.total > challengeDrafts.result.length ? (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {isLoading ? (
                          <Box display="flex" justifyContent="center" p={2}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <Box display="flex" justifyContent="flex-end">
                            <Button color="primary" onClick={handleMoreChallenge}>
                              <Resource tag="Common::ViewAll" />
                            </Button>
                          </Box>
                        )}
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    textAlign="center"
                    alignItems="center"
                  >
                    <img src={imageEmpty} alt="" className={classes.noDataDraftImg} />
                  </Box>
                )
              }
            </TabPanel>
            <TabPanel value="2">
              {drafts ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box
                      display="flex"
                      gridGap={20}
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {drafts?.result?.map((draft, index) => {
                        if (index === 0 || index % 2 === 0) {
                          return (
                            <IdeaDraftCard
                              challengeList={challengeList}
                              selectDraft={(object:any) =>{
                                selectDraft(object)
                              }}
                              draft={draft}
                              key={draft.ideaId}
                            />
                          );
                        } else {
                          return "";
                        }
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box
                      display="flex"
                      gridGap={20}
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {drafts?.result?.map((draft, index) => {
                        if (index === 1 || index % 2 !== 0) {
                          return (
                            <IdeaDraftCard
                              challengeList={challengeList}
                              selectDraft={(object:any) =>{
                                selectDraft(object)
                              }}
                              draft={draft}
                              key={draft.ideaId}
                            />
                          );
                        } else {
                          return "";
                        }
                      })}
                    </Box>
                  </Grid>
                  {drafts.meta.total > drafts.result.length ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {isLoading ? (
                        <Box display="flex" justifyContent="center" p={2}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Box display="flex" justifyContent="flex-end">
                          <Button color="primary" onClick={handleMore}>
                            <Resource tag="Common::ViewAll" />
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  textAlign="center"
                  alignItems="center"
                >
                  <Typography variant="h4" className={classes.noDataDraft}>
                    {textNoDataIdeas[0]}
                  </Typography>
                  <Typography variant="h4" className={classes.noDataDraft}>
                    {textNoDataIdeas[1]}
                  </Typography>
                  <img src={imageEmpty} alt="" className={classes.noDataDraftImg} />
                </Box>
              )}
            </TabPanel>
          </TabContext>
          <DeleteDraftDialog
            draftTitle={draftState?.draft?.title}
            open={draftState.draft?true:false}
            setOpen={()=>{
              removeSelectDraft()
            }}
            yesButton={()=>{
              let t:"Idea"|"Challenge" = draftState.draft.ideaId?"Idea":"Challenge";
              deleteThisDraft((draftState.draft.ideaId??draftState.draft.challengeId), t);
            }}
            noCancelButton={() => {
              removeSelectDraft()
            }}
            culture={props.culture}
          />
        </Box>
      )}
    </>
  );
}

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    workspaceId: store.authState.auth.workspaceId,
    application: store.tenantState.application,
    culture: store.cultureState,
    drafts: store.ideasState.drafts,
    challengeList: store.challengeState.challengeList,
    challengeDrafts: store.challengeState.challengeDraft,
    draftState: store.draftState
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getDrafts: (parameters: object) => dispatch(getDrafts(parameters)),
    deleteDraft: (parameters: object) => dispatch(deleteDraft(parameters)),
    getChallengesDraft: (parameters: object) => dispatch(getChallengeDraftsAction(parameters)),
    selectDraft: (options: object) => dispatch(selectDraft(options)),
    removeSelectDraft: () => dispatch(removeSelectDraft()),
    deleteChallengeDraft: (options:object) => dispatch(deleteChallengeDraft(options))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeaDraft);
