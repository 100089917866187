import React from "react";
import "./PortfolioCarousel.css";
import XCarousel from "common/X/XCarousel";
import Resource from "../Resources/Resource";
import { Grid, Box, Typography } from "@material-ui/core";
import CardIdeaPortfolio from "../CardIdeaPortfolio/CardIdeaPortfolio";

interface IProps {
  ideas?: any;
  portfolioTag: string;
  getMorePortifolio?: any;
}

function PortfolioCarousel(props: IProps) {
  const ideasImplemented = props?.ideas;
  const lenghtI = ideasImplemented?.length;

  return (
    <div className="Portfolio-carousel-container">
      <Grid container>
        <Box mt={4} mb={2}>
          <Typography variant="h3">
            <Resource tag={props?.portfolioTag} />
          </Typography>
        </Box>
      </Grid>
      {ideasImplemented?.result?.length! > 3 &&
      ideasImplemented?.meta?.total! === ideasImplemented?.result?.length! ? (
        <Grid container spacing={2}>
          {ideasImplemented &&
            ideasImplemented?.result?.map((idea: any, index: number) => (
              <Grid item xs={12} sm={12} md={6} key={idea?.ideaId}>
                <CardIdeaPortfolio idea={idea} />
              </Grid>
            ))}
        </Grid>
      ) : (
        <Grid container>
          {lenghtI <= 2 ? (
            <>
              {ideasImplemented &&
                ideasImplemented?.result?.map((idea: any, index: number) => (
                  <Grid item xs={11} sm={8} md={5} key={idea?.ideaId}>
                    <CardIdeaPortfolio idea={idea} />
                  </Grid>
                ))}
            </>
          ) : (
            <XCarousel itemsToShow={2}>
              {ideasImplemented?.result?.length > 0 &&
                ideasImplemented?.result?.map((idea: any, index: number) => (
                  <Box key={idea?.ideaId} marginY={0.5}>
                    <CardIdeaPortfolio idea={idea} />
                  </Box>
                ))}
            </XCarousel>
          )}
        </Grid>
      )}
    </div>
  );
}

export default PortfolioCarousel;
