import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import XDialog from "common/X/XDialog";
import XSelect from "common/X/XSelect";
import Resource, { resource } from "components/Resources/Resource";
import ToolTip from "components/ToolTip/ToolTip";
import React, { useState } from "react";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IKeyTasksImpactTypes } from "redux/reducers/keyTasks/keyTasksReducer";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: theme.palette.grey[500],
      width: "75%",
      margin: "20px 0",
    },
    textarea: {
      // marginTop: 40,
      padding: "10px 0",
      border: 0,
      outline: 0,
      width: "-webkit-fill-available",
      fontSize: "14px",
      fontFamily: "Rubik",
      borderBottom: "1px solid" + theme.palette.grey[500],
      maxWidth: 800,
    },
    checkDivider: {
      width: "100%",
      color: theme.palette.grey[500],
      margin: "12px 0",
    },
    checkboxLabel: {
      color: theme.palette.primary.main,
      fontSize: 13,
    },
    boxSize:{
      height: 130
    },
  })
);

interface IProps {
  culture: ICultureState;
  phases: any;
  phaseId: string;
  impactTypes: IKeyTasksImpactTypes[];
  postChoseForImplementation: (options: object, culture: ICultureState) => Promise<any>;
  workspaceId: string;
  ideaId: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  setMoveForwardReload: (open: boolean) => void;
  transitKeytask: (options:object) => Promise<any>;
}

function MoveForwardPopup({
  culture,
  phases,
  impactTypes,
  postChoseForImplementation,
  workspaceId,
  ideaId,
  open,
  phaseId,
  setOpen,
  setMoveForwardReload,
  transitKeytask,
}: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const tooltip = resource(culture, "PageIdeaDetail::WhoCanSeeMyPrivateComments")?.split("\\n\\n");
  const [screen, setScreen] = useState(1);
  const [typeRadio, setTypeRadio] = useState("1");
  const [followingPhase, setFollowingPhase] = useState<any>("");
  const [comment, setComment] = useState("");
  const [privateComment, setCommentPrivate] = useState(false);
  const [selectedImpactType, setSelectedImpactType] = useState("");
  const [expectedReturn, setExpectedReturn] = useState("");

  const actions = (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Button onClick={() => setOpen(false)} color="primary" size="small" variant="outlined" style={{ marginRight: 12 }}>
        <Resource tag="PageIdeaDetail::Cancel" />
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        disabled={!typeRadio || !followingPhase || !comment}
        onClick={() => (typeRadio === "1" || screen === 2 ? saveChoseForImplementation() : setScreen(2))}
      >
        {typeRadio === "1" || screen === 2 ? (
          <Resource tag="PageIdeaDetail::Save" />
        ) : (
          <Resource tag="PageIdeaDetail::Move" />
        )}
      </Button>
    </Box>
  );

  const saveChoseForImplementation = () => {
    if (typeRadio === "1") {
      const phaseIdNext = phases.filter((x:any) => x.title === followingPhase)?.[0]?.phaseId
      transitKeytask({ workspaceId, ideaId:[ideaId], commentText:comment, phaseId, phaseIdNext, status: privateComment ? 2 : 1, culture}).then(() => {
        setMoveForwardReload(true)
      })
    } else {
      postChoseForImplementation(
        {
          phaseId,
          workspaceId,
          ideaId,
          impactTypeId: (!selectedImpactType && selectedImpactType !== "" ? selectedImpactType : "00000000-0000-0000-0000-000000000000"),
          comment,
          status: privateComment ? 2 : 1,
          expectedReturn: (!expectedReturn && expectedReturn !== "" ? expectedReturn:  0),
        },
        culture
      ).then(() => {
          setMoveForwardReload(true)
      })
    }
  };

  return (
    <XDialog
      actions={actions}
      open={open}
      setOpen={setOpen}
      title={<Resource tag="PageIdeaDetail::MoveForward" />}
      maxWidth="md"
    >
      {screen === 1 ? (
        <Box>
          <Typography variant="h4">
            <Resource tag="PageIdeaDetail::SelectTheMostAppropriateOption" />
          </Typography>
          <RadioGroup style={{ marginTop: 24 }} value={typeRadio} onChange={(e, v) => setTypeRadio(v)}>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                value={"1"}
                control={<Radio color="primary" />}
                label={<Resource tag="PageIdeaDetail::MoveTheIdeaToTheFollowingPhase" />}
              />
              <XSelect
                value={followingPhase}
                setValue={setFollowingPhase}
                options={phases}
                optionId={"phaseId"}
                optionName={"title"}
              />
            </Box>
            <Divider className={classes.divider} />
            <FormControlLabel
              value={"2"}
              control={<Radio color="primary" />}
              label={<Resource tag="PageIdeaDetail::MoveTheIdeaToTheFinalStatus" />}
            />
          </RadioGroup>
          <Box mt={6}>
            <Typography color="textPrimary" variant="h4">
              <Resource tag="PageIdeaDetail::WriteAComment" />
            </Typography>
            <TextareaAutosize
              placeholder={resource(culture, "PageIdeaDetail::WriteAComment")}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              className={classes.textarea}
              rowsMin={4}
            />
          </Box>
          <Box mt={0.5} className={classes.boxSize}>
            <FormControlLabel
              control={
                <Checkbox
                  name="iValidation-privite-comment"
                  onChange={(e, c) => setCommentPrivate(c)}
                  id="label-private-comment"
                  color="primary"
                  checked={privateComment}
                />
              }
              label={
                <Box className={classes.checkboxLabel}>
                  <Resource tag="PageIdeaDetail::MakeThisCommentPrivate" />
                  <Box component="span" ml={0.5}>
                    {tooltip && (
                      <ToolTip  title={<div dangerouslySetInnerHTML={{__html: tooltip[0]}}></div>} width={400}>
                        {<div dangerouslySetInnerHTML={{__html: tooltip[1]}}></div>}
                      </ToolTip>
                    )}
                  </Box>
                </Box>
              }
            />
          </Box>
        </Box>
      ) : (
        <Box position="relative">
          <IconButton
            onClick={() => setScreen(1)}
            size="small"
            color="primary"
            style={{ position: "absolute", left: -32, top: -4 }}
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="h4">
            <Resource tag="PageIdeaDetail::ExpectedImplementationResults" />
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <Resource tag="PageIdeaDetail::CompleteTheFollowingFields" />
          </Typography>

          <Box display="flex" width="40%" mt={4}>
            <TextField
              id="phone"
              label={
                <Box display="flex" alignItems="center">
                  <Box component="strong" style={{ textTransform: "uppercase" }}>
                    <Resource tag="PageIdeaDetail::ExpectedReturn" />
                  </Box>
                  <Box mx={0.5} style={{ textTransform: "lowercase", fontWeight: 400 }}>
                    (<Resource tag="PageIdeaDetail::Optional" />)
                  </Box>
                </Box>
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={expectedReturn}
              onChange={(e) => setExpectedReturn(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon htmlColor={theme.palette.text.secondary} />
                  </InputAdornment>
                ),
              }}
              type="number"
            />
          </Box>
          <Box mt={6}>
            <Box>
              <Box display="flex" alignItems="center" mb={1} fontSize="0.8rem">
                <Box component="strong" color={theme.palette.text.secondary} style={{ textTransform: "uppercase" }}>
                  <Resource tag="PageIdeaDetail::ImpactType" />
                </Box>
                <Box mx={0.5} color={theme.palette.text.secondary}>
                  (<Resource tag="PageIdeaDetail::Optional" />)
                </Box>
              </Box>
              <RadioGroup
                style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", maxHeight: 290, overflow: "auto" }}
                value={selectedImpactType}
                onChange={(e, v) => setSelectedImpactType(v)}
              >
                {impactTypes?.length > 0 &&
                  impactTypes.map((type) => (
                    <Box width="45%" key={type.impactTypeId}>
                      <FormControlLabel
                        style={{ margin: "6px 0" }}
                        value={type.impactTypeId}
                        control={<Radio />}
                        label={type.title}
                      />
                      <Divider />
                    </Box>
                  ))}
              </RadioGroup>
            </Box>
          </Box>
        </Box>
      )}
    </XDialog>
  );
}

export default MoveForwardPopup;
