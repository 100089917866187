import { AnyAction } from "redux";
import Truncate from "react-truncate";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import AddIcon from "@material-ui/icons/Add";
import { IAppState } from "redux/store/Store";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { ICultureState } from "redux/reducers/cultureReducer";
import DeleteGroupDialog from "components/Dialogs/DeleteGroupDialog";
import { PageStateParticipantsPrize } from "redux/types/createPrize";
import CreateGroupDialog from "components/Dialogs/CreateGroupDialog";
import GroupDetailsDialog from "components/Dialogs/GroupDetailsDialog";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
import { getAllCountries, getAllDepartaments } from "redux/actions/UsersAction";
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  IManageGroup,
  IManageGroups,
  IResultManageGroups,
} from "redux/types/manageGroups";
import {
  deleteManageGroups,
  getManageGroup,
  getManageGroups,
} from "redux/actions/manageGroups/manageGroupsAction";

interface IProps {
  culture: ICultureState;
  allGroups: IManageGroups;
  setSteps: any;
  steps: PageStateParticipantsPrize;
  workspaceId: string;
  allGroupsSelected?: boolean;
  setAllGroupsSelected?:Function
}

interface IStateProps {
  countries: ICountries[];
  departments: IDepartments[];
  group: IManageGroup;
}

interface IDispatchProps {
  getCountries: (options: object) => Promise<any>;
  getDepartments: (options: object) => Promise<any>;
  //getAllManageGroups: (options: object) => Promise<any>;
  getManageGroups: (options: object) => Promise<any>;
  getManageGroup: (options: object) => Promise<any>;
  //putRevoveUserGroupManageGroups: (options: object) => Promise<any>;
  deleteManageGroups: (options: object) => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelCheckbox: {
      display: "flex",
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
        width: "75%",
        [theme.breakpoints.only("xs")]: {
          width: "80%",
        },
      },
    },
    widthBtnCheckbox: {
      width: "100%",
      "& .MuiSvgIcon-root": {
        width: "0.8em",
        height: "0.8em",
      },
    },
    dividerCheckbox: {
      width: "90%",
    },
    iconBtn: {
      padding: 0,
      "&:hover": {
        padding: 0,
      },
    },
    labelMenu: {
      fontSize: "0.8rem",
    },
  })
);

function CreatePrizeStepParticipants(
  props: IProps & IDispatchProps & IStateProps
) {
  const classes = useStyles();
  const {
    workspaceId,
    culture,
    allGroups,
    setSteps,
    steps,
    countries,
    departments,
    group,
    allGroupsSelected,
    setAllGroupsSelected,
    getCountries,
    getDepartments,
    getManageGroups,
    getManageGroup,
    deleteManageGroups,
  } = props;

  //create, edit and delete group dialogs
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);
  const [, setLoading] = useState(true);
  const [groupSelected, setGroupSelected] = useState("");
  const [
    groupDetailSelected,
    setGroupDetailSelected,
  ] = useState<IResultManageGroups>();
  const [allWorkSpace, setAllWorkSpace] = useState(false);
  const { cultureTag } = culture?.culture ?? ""

  useEffect(() => {
    Promise.all([
      getCountries({ cultureTag }),
      getDepartments({ cultureTag }),
      getManageGroups({
        workspaceId,
        cultureTag,
        culture: culture.culture,
        pageSize: 100
      }),
    ]).finally(() => setLoading(false));

    // eslint-disable-next-line
  }, []);

  //Checkbox function
  // const [groupsState, setGroupsState] = useState<Array<string>>([]);
  const handleChangeCheckbox = (id: string) => {
    setAllGroupsSelected?.(false);
    steps?.groupsId?.map((x: any) => x).includes(id)
      ? setSteps({
          ...steps,
          groupsId: steps.groupsId.filter((y: any) => y !== id),
        })
      : setSteps({
          ...steps,
          groupsId: [...steps.groupsId, id],
        });
  };

  const handleChangeAllCheckbox = (event: React.ChangeEvent<any>) => {
    
    setAllGroupsSelected?.(!allGroupsSelected);

    setSteps({
      ...steps,
      groupsId: [],
    });
  };

  //Menu options functions
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //groupDetailsDialog fake data
  const [groupDetails, setGroupDetails] = useState(false);

  useEffect(() => {
    if (groupSelected !== "") {
      Promise.all([
        getManageGroup({ workspaceId, groupId: groupSelected }),
      ]).finally(() => {});
    }
    // eslint-disable-next-line
  }, [groupSelected]);

  function handleDeleteGroup() {
    deleteManageGroups({
      groupId: groupSelected,
      workspaceId,
      culture: culture,
    }).then(() => {
      getManageGroups({ workspaceId, cultureTag: culture.culture.cultureTag });
    });
  }

  return (
    <>
      <Box className="create-prize-participants-container">
        <Box mb={"35px"} className="particpants-groups-container">
          <Grid container alignItems="center">
            <Grid item xs={6} sm={6} md={8} xl={8}>
              <Typography variant="h4">
                <Resource tag="PageCreatePrize::ParticipantGroups" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              xl={4}
              style={{ textAlignLast: "end", paddingRight: "30px" }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon style={{ fontSize: "0.9rem" }} />}
                onClick={() => setCreateGroup(true)}
              >
                <Resource tag="PageCreatePrize::CreateGroup" />
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box className="checkbox-container">
          <FormControl
            component="fieldset"
            className={classes.widthBtnCheckbox}
          >
            <FormGroup>
              <Grid container>
                {allGroups?.result && allGroups?.result?.length !== 0 ? (
                  <Grid item xs={12} sm={6} md={4} xl={4}>
                    <Box className="checkboxAll">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name="checkedAll"
                            checked={
                              steps?.groupsId?.length === 0 && allGroupsSelected
                            }
                            onChange={handleChangeAllCheckbox}
                          />
                        }
                        className={classes.labelCheckbox}
                        label={
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={10} sm={10} md={10} xl={10}>
                              <Resource tag="PageCreatePrize::AllWorkspace" />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} xl={1}></Grid>
                            <Grid item xs={1} sm={1} md={1} xl={1}>
                              <IconButton
                                onClick={() => {
                                  setGroupDetails(true);
                                  setAllWorkSpace(true);
                                  setGroupDetailSelected(undefined);
                                }}
                                className={classes.iconBtn}
                              >
                                <Visibility style={{ color: "#DADADA" }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        }
                      />
                      <Divider className={classes.dividerCheckbox} />
                    </Box>
                  </Grid>
                ) : (
                  <Box>
                    <Resource
                      tag={"PageCreatePrize::ThereAreNoParticipantsGroups"}
                    />
                  </Box>
                )}
                {allGroups?.result?.map((list) => {
                  return (
                    <Grid key={list?.groupId} item xs={12} sm={6} md={4} xl={4}>
                      <FormControlLabel
                        key={list?.groupId}
                        control={
                          <Checkbox
                            key={list?.groupId}
                            name={list?.groupName}
                            color="primary"
                            checked={steps?.groupsId
                              ?.map((x: any) => x)
                              .includes(list?.groupId)}
                            onChange={() => handleChangeCheckbox(list?.groupId)}
                          />
                        }
                        className={classes.labelCheckbox}
                        label={
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={10} sm={10} md={10} xl={10}>
                              <Truncate width={197} ellipsis={<span>...</span>}>
                                {list?.groupName}
                              </Truncate>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} xl={1}>
                              <IconButton
                                className={classes.iconBtn}
                                onClick={(e) => {
                                  setGroupSelected(list.groupId);
                                  handleClickMenu(e);
                                  setAllWorkSpace(false);
                                }}
                              >
                                <MoreHorizIcon
                                  fontSize="small"
                                  style={{ color: "#DADADA" }}
                                />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                PaperProps={{ elevation: 0 }}
                              >
                                <MenuItem
                                  className={classes.labelMenu}
                                  onClick={() => {
                                    setEditGroup(true);
                                  }}
                                >
                                  <Resource tag="PageCreatePrize::EditGroup" />
                                </MenuItem>

                                <MenuItem
                                  className={classes.labelMenu}
                                  onClick={() => {
                                    setDeleteGroup(true);
                                  }}
                                >
                                  <Resource tag="PageCreatePrize::Delete" />
                                </MenuItem>
                              </Menu>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} xl={1}>
                              <IconButton
                                onClick={() => {
                                  setGroupDetails(true);
                                  setGroupDetailSelected(list);
                                }}
                                className={classes.iconBtn}
                              >
                                <Visibility style={{ color: "#DADADA" }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        }
                      />
                      <Divider className={classes.dividerCheckbox} />
                    </Grid>
                  );
                })}
              </Grid>
            </FormGroup>
          </FormControl>
          <CreateGroupDialog
            open={createGroup}
            setOpen={setCreateGroup}
            type="create"
            countries={countries}
            departments={departments}
          />
          <CreateGroupDialog
            open={editGroup}
            setOpen={setEditGroup}
            type="edit"
            countries={countries}
            departments={departments}
            groupData={group}
          />
          <DeleteGroupDialog
            open={deleteGroup}
            setOpen={setDeleteGroup}
            groupName={group?.cultures ? group?.cultures[0]?.title : ""}
            noCancelButton={() => setDeleteGroup(false)}
            yesButton={() => {
              handleDeleteGroup();
              setDeleteGroup(false);
            }}
          />
          <GroupDetailsDialog
            culture={culture}
            open={groupDetails}
            setOpen={setGroupDetails}
            groupDetail={groupDetailSelected}
            allWorkSpace={allWorkSpace}
          />
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (store: IAppState) => ({
  //culture: store.cultureState,
  countries: store.usersState.countries,
  departments: store.usersState.departaments,
  //allGroups: store.manageGroupsState.allGroups,
  //groups: store.manageGroupsState.groups,
  workspaceId: store.authState.auth.workspaceId,
  group: store.manageGroupsState.group,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  getCountries: (options: object) => dispatch(getAllCountries(options)),
  getDepartments: (options: object) => dispatch(getAllDepartaments(options)),
  //getAllManageGroups: (options: object) => dispatch(getAllManageGroups(options)),
  getManageGroups: (options: object) => dispatch(getManageGroups(options)),
  getManageGroup: (options: object) => dispatch(getManageGroup(options)),
  //putRevoveUserGroupManageGroups: (options: object) => dispatch(putRevoveUserGroupManageGroups(options)),
  deleteManageGroups: (options: object) =>
    dispatch(deleteManageGroups(options)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePrizeStepParticipants);
