import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Box, ClickAwayListener, IconButton, Menu } from "@material-ui/core";

interface IProps {
  children: any;
  iconColor?: string;
  translate?: string;
  type?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    menuButton: {
      padding: 0,
      marginTop: "-8px",
    },
    list: {
      "& li": {
        paddingRight: "24px",
      },
    },
  })
);

export default function IdeaDraftCard(props: IProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    props?.type === "Edit" && setAnchorEl(null);

    // eslint-disable-next-line
  }, [props?.type]);

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.stopPropagation();
        setAnchorEl(null);
      }}
    >
      <Box>
        <IconButton
          // aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={classes.menuButton}
          onFocus={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={(e) => {
            setAnchorEl(e.target);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <MoreHorizIcon style={{ color: props.iconColor }} fontSize="large" />
        </IconButton>
        <Menu
          id="long-menu"
          keepMounted
          open={open}
          classes={{ list: classes.list }}
          anchorEl={anchorEl}
          onClose={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(null);
          }}
          style={{
            transform: `translate(${
              props?.translate ? props?.translate : "-95px, 42px"
            })`,
          }}
        >
          {props?.children}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
}
