import reactNewApp from "utils/reactNewApp";
import { ThunkAction } from "redux-thunk";
import { ActionCreator, Dispatch } from "redux";
import { treatError } from "../../../utils/treatError";
import { api_general, api_v2 } from "../../../config/api";
import { IMyActivitiesState } from "redux/reducers/MyActivities/myActivitiesReducer";
import {
  IMyActivities,
  IMyActivitiesStarsCollaborative,
  IMyActivitiesStarsPrivate,
  IMyActivitiesInvestmentsActive,
  IMyActivitiesInvestmentsHistory,
  IMyActivitiesLikes,
  IPublicMyActivityComments,
  IPrivateMyActivityComments,
  IMyActivitiesPrizesActiveBid,
  IMyActivitiesPrizesWon,
  IMyActivitiesPrizesLost,
  IMyActivitiesIdeasImplemented,
  IMyActivitiesIdeasClosed,
  IMyActivitiesIdeasInProgress,
} from "redux/types/myActivities";

const basePath = "myactivities";

export enum MyActivitiesActionTypes {
  GET_ALL_MYACTIVITIES_LOADING = " GET_ALL_MYACTIVITIES_LOADING",
  GET_ALL_MYACTIVITIES_SUCCEDDED = "GET_ALL_MYACTIVITIES_SUCCEDDED",
  GET_ALL_MYACTIVITIES_FAILED = "GET_ALL_MYACTIVITIES_FAILED",

  GET_MYACTIVITIES_STARS_COLLABORATIVE_LOADING = "GET_MYACTIVITIES_STARS_COLLABORATIVE_LOADING",
  GET_MYACTIVITIES_STARS_COLLABORATIVE_SUCCEDDED = "GET_MYACTIVITIES_STARS_COLLABORATIVE_SUCCEDDED",
  GET_MYACTIVITIES_STARS_COLLABORATIVE_FAILED = "GET_MYACTIVITIES_STARS_COLLABORATIVE_FAILED",

  GET_MYACTIVITIES_STARS_PRIVATE_LOADING = "GET_MYACTIVITIES_STARS_PRIVATE_LOADING",
  GET_MYACTIVITIES_STARS_PRIVATE_SUCCEDDED = "GET_MYACTIVITIES_STARS_PRIVATE_SUCCEDDED",
  GET_MYACTIVITIES_STARS_PRIVATE_FAILED = "GET_MYACTIVITIES_STARS_PRIVATE_FAILED",

  INVESTMENTS_ACTIVE_LOADING = "INVESTMENTS_ACTIVE_LOADING",
  INVESTMENTS_ACTIVE_SUCCEDDED = "INVESTMENTS_ACTIVE_SUCCEDDED",
  INVESTMENTS_ACTIVE_FAILED = "INVESTMENTS_ACTIVE_FAILED",

  INVESTMENTS_HISTORY_LOADING = "INVESTMENTS_HISTORY_LOADING",
  INVESTMENTS_HISTORY_SUCCEDDED = "INVESTMENTS_HISTORY_SUCCEDDED",
  INVESTMENTS_HISTORY_FAILED = "INVESTMENTS_HISTORY_FAILED",

  PUBLIC_COMMENTS_LOADING = "PUBLIC_COMMENTS_LOADING",
  PUBLIC_COMMENTS_SUCCEDDED = "PUBLIC_COMMENTS_SUCCEDDED",
  PUBLIC_COMMENTS_FAILED = "PUBLIC_COMMENTS_FAILED",

  PRIVATE_COMMENTS_LOADING = "PRIVATE_COMMENTS_LOADING",
  PRIVATE_COMMENTS_SUCCEDDED = "PRIVATE_COMMENTS_SUCCEDDED",
  PRIVATE_COMMENTS_FAILED = "PRIVATE_COMMENTS_FAILED",

  LIKES_LOADING = "LIKES_LOADING",
  LIKES_SUCCEDDED = "LIKES_SUCCEDDED",
  LIKES_FAILED = "LIKES_FAILED",

  GET_MYACTIVITIES_PRIZES_ACTIVEBID_LOADING = "GET_MYACTIVITIES_PRIZES_ACTIVEBID_LOADING",
  GET_MYACTIVITIES_PRIZES_ACTIVEBID_SUCCEDDED = "GET_MYACTIVITIES_PRIZES_ACTIVEBID_SUCCEDDED",
  GET_MYACTIVITIES_PRIZES_ACTIVEBID_FAILED = "GET_MYACTIVITIES_PRIZES_ACTIVEBID_FAILED_FAILED",

  GET_MYACTIVITIES_PRIZES_WON_LOADING = "GET_MYACTIVITIES_PRIZES_WON_LOADING",
  GET_MYACTIVITIES_PRIZES_WON_SUCCEDDED = "GET_MYACTIVITIES_PRIZES_WON_SUCCEDDED",
  GET_MYACTIVITIES_PRIZES_WON_FAILED = "GET_MYACTIVITIES_PRIZES_WON_FAILED",

  GET_MYACTIVITIES_PRIZES_LOST_LOADING = "GET_MYACTIVITIES_PRIZES_LOST_LOADING",
  GET_MYACTIVITIES_PRIZES_LOST_SUCCEDDED = "GET_MYACTIVITIES_PRIZES_LOST_SUCCEDDED",
  GET_MYACTIVITIES_PRIZES_LOST_FAILED = "GET_MYACTIVITIES_PRIZES_LOST_FAILED",

  GET_MYACTIVITIES_IDEAS_IMPLEMENTED_LOADING = "GET_MYACTIVITIES_IDEAS_IMPLEMENTED_LOADING",
  GET_MYACTIVITIES_IDEAS_IMPLEMENTED_SUCCEDDED = "GET_MYACTIVITIES_IDEAS_IMPLEMENTED_SUCCEDDED",
  GET_MYACTIVITIES_IDEAS_IMPLEMENTED_FAILED = "GET_MYACTIVITIES_IDEAS_IMPLEMENTED_FAILED",

  GET_MYACTIVITIES_IDEAS_CLOSED_LOADING = "GET_MYACTIVITIES_IDEAS_CLOSED_LOADING",
  GET_MYACTIVITIES_IDEAS_CLOSED_SUCCEDDED = "GET_MYACTIVITIES_IDEAS_CLOSED_SUCCEDDED",
  GET_MYACTIVITIES_IDEAS_CLOSED_FAILED = "GET_MYACTIVITIES_IDEAS_CLOSED_FAILED",

  GET_MYACTIVITIES_IDEAS_INPROGRESS_LOADING = "GET_MYACTIVITIES_IDEAS_INPROGRESS_LOADING",
  GET_MYACTIVITIES_IDEAS_INPROGRESS_SUCCEDDED = "GET_MYACTIVITIES_IDEAS_INPROGRESS_SUCCEDDED",
  GET_MYACTIVITIES_IDEAS_INPROGRESS_FAILED = "GET_MYACTIVITIES_IDEAS_INPROGRESS_FAILED",

  MYACTIVITIES_IDEAS_INPROGRESS_MORE_LOADING = "MYACTIVITIES_IDEAS_INPROGRESS_MORE_LOADING",
  MYACTIVITIES_IDEAS_INPROGRESS_MORE_SUCCEEDED = "MYACTIVITIES_IDEAS_INPROGRESS_MORE_SUCCEEDED",
  MYACTIVITIES_IDEAS_INPROGRESS_MORE_FAILED = "MYACTIVITIES_IDEAS_INPROGRESS_MORE_FAILED",

  SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
}

export enum MyActivitiesActionStatus {
  MYACTIVITIES_LOADING = "LOADING",
  MYACTIVITIES_SUCCEDDED = "SUCCEEDED",
  MYACTIVITIES_FAILED = "FAILED",

  GET_MYACTIVITIES_IDEAS_INPROGRESS_LOADING = "GET_MYACTIVITIES_IDEAS_INPROGRESS_LOADING",
  GET_MYACTIVITIES_IDEAS_INPROGRESS_SUCCEDDED = "GET_MYACTIVITIES_IDEAS_INPROGRESS_SUCCEDDED",
  GET_MYACTIVITIES_IDEAS_INPROGRESS_FAILED = "GET_MYACTIVITIES_IDEAS_INPROGRESS_FAILED",

  MYACTIVITIES_IDEAS_INPROGRESS_MORE_LOADING = "MYACTIVITIES_IDEAS_INPROGRESS_MORE_LOADING",
  MYACTIVITIES_IDEAS_INPROGRESS_MORE_SUCCEEDED = "MYACTIVITIES_IDEAS_INPROGRESS_MORE_SUCCEEDED",
  MYACTIVITIES_IDEAS_INPROGRESS_MORE_FAILED = "MYACTIVITIES_IDEAS_INPROGRESS_MORE_FAILED",
}

// GET ALL MY ACTIVITIES
export interface IMyActivitiesGetAllLoadingAction {
  type: MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_LOADING;
  status: string;
}
export interface IMyActivitiesGetAllSucceededAction {
  type: MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_SUCCEDDED;
  myActivities: IMyActivities;
  status: string;
  isInfinite: boolean;
}
export interface IMyActivitiesGetAllFailedAction {
  type: MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_FAILED;
  status: string;
  error: null;
}

// GET ALL MY ACTIVITIES STAR COLLABORATIVE
export interface IMyActivitiesStarsCollaborativeGetAllLoadingAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_LOADING;
  status: string;
}
export interface IMyActivitiesStarsCollaborativeGetAllSucceddedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_SUCCEDDED;
  status: string;
  myActivitiesStarsCollaborative: IMyActivitiesStarsCollaborative;
  isInfinite: boolean;
}
export interface IMyActivitiesStarsCollaborativeGetAllFailedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES STAR PRIVATE
export interface IMyActivitiesStarsPrivateGetAllLoadingAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_LOADING;
  status: string;
}
export interface IMyActivitiesStarsPrivateGetAllSucceddedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_SUCCEDDED;
  status: string;
  myActivitiesStarsPrivate: IMyActivitiesStarsPrivate;
  isInfinite: boolean;
}
export interface IMyActivitiesStarsPrivateGetAllFailedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES INVESTMENTS ACTIVE
export interface IMyActivitiesInvestmentsActiveGetAllLoadingAction {
  type: MyActivitiesActionTypes.INVESTMENTS_ACTIVE_LOADING;
  status: string;
}
export interface IMyActivitiesInvestmentsActiveGetAllSucceddedAction {
  type: MyActivitiesActionTypes.INVESTMENTS_ACTIVE_SUCCEDDED;
  status: string;
  myActivitiesInvestmentsActive: IMyActivitiesInvestmentsActive;
  isInfinite: boolean;
}
export interface IMyActivitiesInvestmentsActiveGetAllFailedAction {
  type: MyActivitiesActionTypes.INVESTMENTS_ACTIVE_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES INVESTMENTS HISTORY
export interface IMyActivitiesInvestmentsHistoryGetAllLoadingAction {
  type: MyActivitiesActionTypes.INVESTMENTS_HISTORY_LOADING;
  status: string;
}
export interface IMyActivitiesInvestmentsHistoryGetAllSucceddedAction {
  status: string;
  type: MyActivitiesActionTypes.INVESTMENTS_HISTORY_SUCCEDDED;
  myActivitiesInvestmentsHistory: IMyActivitiesInvestmentsHistory;
  isInfinite: boolean;
}
export interface IMyActivitiesInvestmentsHistoryGetAllFailedAction {
  type: MyActivitiesActionTypes.INVESTMENTS_HISTORY_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES PUBLIC COMMENTS
export interface IMyActivitiesPublicCommentsGetLoadingAction {
  type: MyActivitiesActionTypes.PUBLIC_COMMENTS_LOADING;
  status: string;
}
export interface IMyActivitiesPublicCommentsGetSucceddedAction {
  status: string;
  type: MyActivitiesActionTypes.PUBLIC_COMMENTS_SUCCEDDED;
  publicComments: IPublicMyActivityComments;
  isInfinite: boolean;
}
export interface IMyActivitiesPublicCommentsGetFailedAction {
  type: MyActivitiesActionTypes.PUBLIC_COMMENTS_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES PRIVATE COMMENTS
export interface IMyActivitiesPrivateCommentsGetLoadingAction {
  type: MyActivitiesActionTypes.PRIVATE_COMMENTS_LOADING;
  status: string;
}
export interface IMyActivitiesPrivateCommentsGetSucceddedAction {
  status: string;
  type: MyActivitiesActionTypes.PRIVATE_COMMENTS_SUCCEDDED;
  privateComments: IPrivateMyActivityComments;
  isInfinite: boolean;
}
export interface IMyActivitiesPrivateCommentsGetFailedAction {
  type: MyActivitiesActionTypes.PRIVATE_COMMENTS_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES LIKES
export interface IMyActivitiesLikesGetAllLoadingAction {
  type: MyActivitiesActionTypes.LIKES_LOADING;
  status: string;
}
export interface IMyActivitiesLikesGetAllSucceddedAction {
  status: string;
  type: MyActivitiesActionTypes.LIKES_SUCCEDDED;
  myActivitiesLikes: IMyActivitiesLikes;
  isInfinite: boolean;
}
export interface IMyActivitiesLikesGetAllFailedAction {
  type: MyActivitiesActionTypes.LIKES_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES ACTIVE BID
export interface IMyActivitiesPrizesActiveBidGetAllLoadingAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_LOADING;
  status: string;
}
export interface IMyActivitiesPrizesActiveBidGetAllSucceddedAction {
  status: string;
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_SUCCEDDED;
  myActivitiesPrizesActiveBid: IMyActivitiesPrizesActiveBid;
  isInfinite: boolean;
}
export interface IMyActivitiesPrizesActiveBidGetAllFailedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES PRIZES WON
export interface IMyActivitiesPrizesWonGetAllLoadingAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_LOADING;
  status: string;
}
export interface IMyActivitiesPrizesWonGetAllSucceddedAction {
  status: string;
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_SUCCEDDED;
  myActivitiesPrizesWon: IMyActivitiesPrizesWon;
  isInfinite: boolean;
}
export interface IMyActivitiesPrizesWonGetAllFailedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES PRIZES LOST
export interface IMyActivitiesPrizesLostGetAllLoadingAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_LOADING;
  status: string;
}
export interface IMyActivitiesPrizesLostGetAllSucceddedAction {
  status: string;
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_SUCCEDDED;
  myActivitiesPrizesLost: IMyActivitiesPrizesLost;
  isInfinite: boolean;
}
export interface IMyActivitiesPrizesLostGetAllFailedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES IDEAS IMPLEMENTED
export interface IMyActivitiesIdeasImplementedGetAllLoadingAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_LOADING;
  status: string;
}
export interface IMyActivitiesIdeasImplementedGetAllSucceddedAction {
  status: string;
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_SUCCEDDED;
  myActivitiesIdeasImplemented: IMyActivitiesIdeasImplemented;
  isInfinite: boolean;
}
export interface IMyActivitiesIdeasImplementedGetAllFailedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_FAILED;
  status: string;
  error: any;
}

// GET ALL MY ACTIVITIES IDEAS CLOSED
export interface IMyActivitiesIdeasClosedGetAllLoadingAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_LOADING;
  status: string;
}
export interface IMyActivitiesIdeasClosedGetAllSucceddedAction {
  status: string;
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_SUCCEDDED;
  myActivitiesIdeasClosed: IMyActivitiesIdeasClosed;
  isInfinite: boolean;
}
export interface IMyActivitiesIdeasClosedGetAllFailedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_FAILED;
  status: string;
  error: any;
}

//GET MY ACTIVITIE IN PROGRESS
export interface IMyActivitiesGetIdeasInProgressLoadAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_LOADING;
  myActivitiesIdeasInProgressStatus: string;
}
export interface IMyActivitiesGetIdeasInProgressSucceedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_SUCCEDDED;
  myActivitiesIdeasInProgress: IMyActivitiesIdeasInProgress;
  myActivitiesIdeasInProgressStatus: string;
  isInfinite: boolean;
}
export interface IMyActivitiesGetIdeasInProgressFailedAction {
  type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_FAILED;
  myActivitiesIdeasInProgressStatus: string;
}

export interface IMyActivitiesSetActiveTab {
  type: MyActivitiesActionTypes.SET_ACTIVE_TAB;
  activePageMyActivities: string;
}

export type MyActivitiesActions =
  | IMyActivitiesGetAllLoadingAction
  | IMyActivitiesGetAllSucceededAction
  | IMyActivitiesGetAllFailedAction
  | IMyActivitiesStarsCollaborativeGetAllLoadingAction
  | IMyActivitiesStarsCollaborativeGetAllSucceddedAction
  | IMyActivitiesStarsCollaborativeGetAllFailedAction
  | IMyActivitiesStarsPrivateGetAllLoadingAction
  | IMyActivitiesStarsPrivateGetAllSucceddedAction
  | IMyActivitiesStarsPrivateGetAllFailedAction
  | IMyActivitiesInvestmentsActiveGetAllLoadingAction
  | IMyActivitiesInvestmentsActiveGetAllSucceddedAction
  | IMyActivitiesInvestmentsActiveGetAllFailedAction
  | IMyActivitiesInvestmentsHistoryGetAllLoadingAction
  | IMyActivitiesInvestmentsHistoryGetAllSucceddedAction
  | IMyActivitiesInvestmentsHistoryGetAllFailedAction
  | IMyActivitiesPublicCommentsGetLoadingAction
  | IMyActivitiesPublicCommentsGetSucceddedAction
  | IMyActivitiesPublicCommentsGetFailedAction
  | IMyActivitiesPrivateCommentsGetLoadingAction
  | IMyActivitiesPrivateCommentsGetSucceddedAction
  | IMyActivitiesPrivateCommentsGetFailedAction
  | IMyActivitiesLikesGetAllLoadingAction
  | IMyActivitiesLikesGetAllSucceddedAction
  | IMyActivitiesLikesGetAllFailedAction
  | IMyActivitiesPrizesActiveBidGetAllLoadingAction
  | IMyActivitiesPrizesActiveBidGetAllSucceddedAction
  | IMyActivitiesPrizesActiveBidGetAllFailedAction
  | IMyActivitiesPrizesWonGetAllLoadingAction
  | IMyActivitiesPrizesWonGetAllSucceddedAction
  | IMyActivitiesPrizesWonGetAllFailedAction
  | IMyActivitiesPrizesLostGetAllLoadingAction
  | IMyActivitiesPrizesLostGetAllSucceddedAction
  | IMyActivitiesPrizesLostGetAllFailedAction
  | IMyActivitiesIdeasImplementedGetAllLoadingAction
  | IMyActivitiesIdeasImplementedGetAllSucceddedAction
  | IMyActivitiesIdeasImplementedGetAllFailedAction
  | IMyActivitiesIdeasClosedGetAllLoadingAction
  | IMyActivitiesIdeasClosedGetAllSucceddedAction
  | IMyActivitiesIdeasClosedGetAllFailedAction
  | IMyActivitiesSetActiveTab
  | IMyActivitiesGetIdeasInProgressLoadAction
  | IMyActivitiesGetIdeasInProgressSucceedAction
  | IMyActivitiesGetIdeasInProgressFailedAction;

export const setActivePage: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesGetAllSucceededAction
>> = (page) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      activePageMyActivities: page,
      type: MyActivitiesActionTypes.SET_ACTIVE_TAB,
    });
  };
};

export const getAllMyActivities: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesGetAllSucceededAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (options.hasOwnProperty("applicationId ")) {
        params.push(`applicationId =${options.applicationId}`);
      }

      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      if (reactNewApp) {
        const response = await api_general.get(`${basePath}${params_qs}`, {});
        dispatch({
          myActivities: response.data,
          type: MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_SUCCEDDED,
          status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
        });
      } else {
        const response = await api_v2.get(`${basePath}${params_qs}`, {});
        dispatch({
          myActivities: response.data,
          type: MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_SUCCEDDED,
          status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
        });
      }
    } catch (err) {
      dispatch({
        error: err,
        type: MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_FAILED,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
      });

      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesStarCollaborative: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesStarsCollaborativeGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type:
          MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/stars/collaborative${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesStarsCollaborative: response.data,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_SUCCEDDED,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/stars/collaborative${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesStarsCollaborative: response.data,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_SUCCEDDED,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        type:
          MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_FAILED,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesStarPrivate: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesStarsPrivateGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/stars/private${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesStarsPrivate: response.data,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_SUCCEDDED,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/stars/private${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesStarsPrivate: response.data,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_SUCCEDDED,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_FAILED,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesInvestmentActive: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesInvestmentsActiveGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.INVESTMENTS_ACTIVE_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/investments/active${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesInvestmentsActive: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.INVESTMENTS_ACTIVE_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/investments/active${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesInvestmentsActive: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.INVESTMENTS_ACTIVE_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.INVESTMENTS_ACTIVE_SUCCEDDED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesInvestmentHistory: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesInvestmentsHistoryGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.INVESTMENTS_HISTORY_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/investments/history${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesInvestmentsHistory: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.INVESTMENTS_HISTORY_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/investments/history${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesInvestmentsHistory: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.INVESTMENTS_HISTORY_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.INVESTMENTS_HISTORY_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesLikes: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesLikesGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.LIKES_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/likes${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesLikes: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.LIKES_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/likes${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesLikes: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.LIKES_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.LIKES_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getPublicComments: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesPublicCommentsGetSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.PUBLIC_COMMENTS_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/comments/public${params_qs}`, {})
            .then((response) => {
              dispatch({
                publicComments: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.PUBLIC_COMMENTS_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/comments/public${params_qs}`, {})
            .then((response) => {
              dispatch({
                publicComments: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.PUBLIC_COMMENTS_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.PUBLIC_COMMENTS_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getPrivateComments: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesPrivateCommentsGetSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.PRIVATE_COMMENTS_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/comments/private${params_qs}`, {})
            .then((response) => {
              dispatch({
                privateComments: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.PRIVATE_COMMENTS_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/comments/private${params_qs}`, {})
            .then((response) => {
              dispatch({
                privateComments: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type: MyActivitiesActionTypes.PRIVATE_COMMENTS_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.PRIVATE_COMMENTS_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesPrizesActivieBid: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesPrizesActiveBidGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/prizes/activebid${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesPrizesActiveBid: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_SUCCEDDED,
              });
            })
        : await api_v2
            .get(`${basePath}/prizes/activebid${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesPrizesActiveBid: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_SUCCEDDED,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesPrizesWon: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesPrizesWonGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/prizes/won${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesPrizesWon: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_SUCCEDDED,
              });
            })
        : await api_v2
            .get(`${basePath}/prizes/won${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesPrizesWon: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_SUCCEDDED,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesPrizesLost: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesPrizesLostGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_LOADING,
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
      });

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/prizes/lost${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesPrizesLost: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_SUCCEDDED,
              });
            })
        : await api_v2
            .get(`${basePath}/prizes/lost${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesPrizesLost: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_SUCCEDDED,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesIdeasImplemented: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesIdeasImplementedGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
        type:
          MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_LOADING,
      });

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/ideas/implemented${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesIdeasImplemented: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_SUCCEDDED,
              });
            })
        : await api_v2
            .get(`${basePath}/ideas/implemented${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesIdeasImplemented: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_SUCCEDDED,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesIdeasClosed: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesIdeasClosedGetAllSucceddedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: MyActivitiesActionStatus.MYACTIVITIES_LOADING,
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_LOADING,
      });

      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/ideas/closed${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesIdeasClosed: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/ideas/closed${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesIdeasClosed: response.data,
                status: MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        error: err,
        status: MyActivitiesActionStatus.MYACTIVITIES_FAILED,
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};

export const getAllMyActivitiesIdeasInProgress: ActionCreator<ThunkAction<
  Promise<any>,
  IMyActivitiesState,
  null,
  IMyActivitiesGetIdeasInProgressSucceedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      dispatch({
        myActivitiesIdeasInProgressStatus:
          MyActivitiesActionStatus.MYACTIVITIES_LOADING,
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_LOADING,
      });

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/ideas/inprogress${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesIdeasInProgress: response.data,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_SUCCEDDED,
                myActivitiesIdeasInProgressStatus:
                  MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/ideas/inprogress${params_qs}`, {})
            .then((response) => {
              dispatch({
                myActivitiesIdeasInProgress: response.data,
                type:
                  MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_SUCCEDDED,
                myActivitiesIdeasInProgressStatus:
                  MyActivitiesActionStatus.MYACTIVITIES_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        type: MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_FAILED,
        myActivitiesIdeasInProgressStatus:
          MyActivitiesActionStatus.MYACTIVITIES_FAILED,
      });
      treatError(options?.culture, "PageMyActivity::Header", err);
    }
  };
};
