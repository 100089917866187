import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IAppState } from '../../../redux/store/Store';

import { Box, Grid, Container } from '@material-ui/core';
import { IProfileData } from 'redux/reducers/profileReducer';
import Validations from './Validations';
import StateRuler from 'components/StateRuler/StateRuler';
import StateRulerItem from 'components/StateRuler/StateRulerItem';
import Resource from 'components/Resources/Resource';
import { useState } from 'react';
import Overview from './Overview';
import Engagament from './Engagement';
import Implementation from './Implementation';

interface IDispatchProps {

}

interface IStateProps {
  profile: IProfileData;
  workspaceId: string;
}

const Reports = (store: IStateProps) => {

  const [pageActive, setPageActive] = useState({
    title: "PageAnalytics::Tab_Overview",
    component: <Overview workspaceId={store.workspaceId}  />
  })

  const [pages] = useState([
    {
      title: "PageAnalytics::Tab_Overview",
      component: <Overview workspaceId={store.workspaceId}  />
    },
    {
      title: "PageAnalytics::Tab_Engagement",
      component: <Engagament workspaceId={store.workspaceId} />
    },
    {
      title: "PageAnalytics::Tab_ValidationTransitions",
      component: <Validations workspaceId={store.workspaceId} />
    },
    {
      title: "PageAnalytics::Tab_ImplementationRoi",
      component: <Implementation workspaceId={store.workspaceId} />
    }
  ])

  return (
    <Box>
      <Grid container>
        <Container>
          <StateRuler>
            {
              pages.map((page) => {
                return (
                  <div key={page.title} onClick={()=>{
                    if(page.title !== pageActive.title){
                      setPageActive(page);
                    }
                  }}>
                    <StateRulerItem
                      title={<Resource tag={page.title} />}
                      isActive={page.title === pageActive.title}
                      hasCursorIcon={true}
                    />
                  </div>
                );
              })
            }
          </StateRuler>
        </Container>
        {
          pageActive.component
        }
      </Grid>
    </Box>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => {
  return {

  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    profile: store.profileState.profileData,
    workspaceId: store.authState.auth.workspaceId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);