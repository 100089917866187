import { DEFAULT_LANGUAGE_CODE, AVAILABLE_LANGUAGE_CODES } from 'config/general';

/**
 * Localiza idioma com prefixo similar, caso não encontre com o nome exato.
 * Example:
 * filterLanguage('es-AR')
 * Input: es-AR <search> Output: es-ES
 */
export function filterLanguage(code: string) {
    const codes = AVAILABLE_LANGUAGE_CODES;
    
    // Try found the exact match
    const codeExactFound = codes.find(element => element.includes(code));
    if (codeExactFound) return codeExactFound;

    // Try found the similar language
    const codeSplitted = code.split('-');
    const codePrefix = codeSplitted[0];
    const codeFound = codes.find(element => element.includes(codePrefix));

    if (codeFound) return codeFound;

    return DEFAULT_LANGUAGE_CODE;
}
