import React, { useState } from "react";
import "./InspirationCard.scss";
import Tags from "../Tags/Tags";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  Hidden,
  createStyles,
  makeStyles,
  Theme,
  Divider,
  Box,
  ClickAwayListener,
  Button,
  useTheme,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import Resource, { resource } from "../Resources/Resource";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CommentListInsights from "components/CommentList/CommentListInsights";
import EMIcon from "components/Icon/Icon";
import sanitizeHtml from "sanitize-html";
import { ICultureState } from "redux/reducers/cultureReducer";
import { securityTextEditor } from "utils/securityTextEditor";
import newApp from "utils/reactNewApp";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import history from 'routes/history';
import { deleteInsight, getAllInsights } from "redux/actions/insight/InsightsActions";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "redux/store/Store";
import { toast } from "react-toastify";
import { getAllFeed } from "redux/actions/FeedAction";
import Image from "./../Image/Image";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "mobileCard:lastChild": {
      paddingBottom: "16px !important",
    },
    divider: {
      width: "100%",
      marginTop: "12px 0",
    },
  })
);

interface IProps {
  insights: any;
  culture: ICultureState;
}

function InspirationCard(props: IProps) {
  const { insights: result, culture } = props;
  const theme = useTheme()
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const [openComments, setCommentsOpen] = useState(false);
  const dispatch = useDispatch()
  const store = useSelector(store => store) as IAppState
  const handleClickAway = () => {
    setCommentsOpen(false);
  };
  const [loading, setLoading] = useState(false)

  const handleClickComments = () => {
    setCommentsOpen((prev) => !prev);
  };
  const deleteCard = async () => {
    setLoading(true)
    await dispatch(deleteInsight({
      insightId: result?.insightId,
      challengeId: result?.challengeId,
      workspaceId: store.authState.auth.workspaceId
    }))

    await dispatch(getAllInsights({
      challengeId: result?.challengeId
    }))
    await dispatch(getAllFeed({
      workspaceId: store.authState.auth.workspaceId,
      cultureTag: culture.culture.cultureTag
    }))
    setLoading(false)
  }
  const showMoreOptions = () => {
    const permission = store.authState.auth.permission
    const currentUserId = store.profileState.profile[0].userId;

    return newApp ? permission === "Admin" || permission === "ChallengeManager" || currentUserId === result.authorId : false
  }
  const cardWeb = (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Card className="inspirationCard-container" key={result?.insightId}>
        {loading ? <Box display="flex" alignItems="center" justifyContent="center" height={250} textAlign="center" width="100%" ><CircularProgress></CircularProgress></Box> :
          <CardContent className="inspirationCard-content">
            <Grid container >
              <Grid item xs={6} sm={11}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    textTransform: "lowercase",
                  }}
                >
                  <Resource
                    tag="PageChallenges::Inspiration_PublishedOn"
                    args={[" "]}
                  />
                      &nbsp;
                      {result?.creationDate}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={1} justify="flex-start">
                {showMoreOptions() ? (
                  <Box>
                    <MoreOptionsButton>
                      <MenuItem
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          history.push({
                            pathname: `/editInspiration/${result.insightId}`
                          })
                        }}
                      >
                        <Resource tag="PageChallenges::EditInspiration" />
                      </MenuItem>
                      <MenuItem
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          deleteCard().then(() => {
                            toast.success(resource(culture, "PageChallenges::SuccessDeletedInspirations"))
                          })
                        }}
                      >
                        <Resource tag="PageChallenges::DeleteInspiration" />
                      </MenuItem>
                    </MoreOptionsButton>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>

            </Grid>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="stretch"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="inspirationCard-title"
              >
                <Typography>{result?.title}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="inspiration-description"

              >
                <Box dangerouslySetInnerHTML={{ __html: sanitizeHtml(result?.description, securityTextEditor) }} />
              </Grid>
              {
                result?.image
                  ?
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className="inspiration-image"
                  >
                    <Image
                        src={result.image ? result.image : ""}
                        alt={result.title}
                        style={{
                          maxHeight: "50%",
                          width: "100%",
                          borderRadius: "7px",
                          marginTop: "10px",
                          marginBottom: "10px"
                        }}
                        height={"400px"}
                    />
                  </Grid>
                  : ""
              }
            </Grid>
            <Grid
              container
              direction="column"
              justify="space-between"
              alignItems="stretch"
            >
              {result?.tags?.length > 0 && (
                <div className="inspiration-line"></div>
              )}
              {result?.tags?.length > 0 && (
                <div className="inspiration-tags">
                  <Tags list={result?.tags} />
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Box>
                  <Button
                    onClick={() => handleClickComments()}
                    color="primary"
                    size='small'
                    startIcon={
                      <EMIcon
                        icon="comments-b"
                        color={theme.palette.primary.main}
                        size={16}
                      />
                    }
                  >
                    <Resource tag={"PageFeed::Comment"} />
                  </Button>
                </Box>
                <Box>
                  {result ? (
                    <>
                      {result?.commentsCount > 1 ? (
                        <Box className="counters-texts">
                          {result?.commentsCount === 0 ? (
                            ""
                          ) : (
                            <Typography
                              style={{ textTransform: "lowercase" }}
                              variant="caption"
                            >
                              {result?.commentsCount}{" "}
                              <Resource tag={"PageFeed::Comments"} />
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        <Box className="counters-texts">
                          {result?.commentsCount === 0 ? (
                            ""
                          ) : (
                            <Typography
                              style={{ textTransform: "lowercase" }}
                              variant="caption"
                            >
                              {result?.commentsCount}{" "}
                              <Resource tag={"PageFeed::Comment"} />
                            </Typography>
                          )}
                        </Box>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {openComments ? (
                <Box width="100%" marginTop={2}>
                  <Divider className={classes.divider} />
                  <Box marginBottom={2}>
                    <CommentListInsights
                      isAllowedComment={true}
                      insightId={result?.insightId}
                      initialPageSize={3}
                      workspaceId={store.authState.auth.workspaceId}
                    />
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </CardContent>
        }
      </Card>
    </ClickAwayListener>
  );

  const cardMobile = (
    <Card className="inspirationCard-container mobile" key={result?.insightId}>
      <CardContent
        className={classes["mobileCard:lastChild"] + " inspirationCard-content"}
      >
        <Grid container>
          <Grid item xs={11} md={11} sm={11}>
            <Typography
              variant="subtitle1"
              className="inspirationCard-info mobile"
            >
              <Resource tag="PageChallenges::Inspiration_To" />
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} sm={1}>
            {isOpen === true ? (
              <ExpandLessIcon
                style={{ color: "#333" }}
                onClick={() => setOpen(false)}
              />
            ) : (
              <ExpandMoreIcon
                style={{ color: "#333" }}
                onClick={() => setOpen(true)}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className="inspirationCard-title mobile"
          >
            <Typography>{result?.title}</Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <Typography
              variant="subtitle1"
              className="inspirationCard-date mobile"
            >
              <span>{result?.creationDate}</span>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      {isOpen && (
        <>
          <Divider variant="fullWidth" className={classes.divider}></Divider>
          <CardContent className={classes["mobileCard:lastChild"]}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="inspiration-description"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(result?.description, securityTextEditor),
              }}
            />
          </CardContent>
        </>
      )}
    </Card>
  );

  return (
    <>
      <Hidden xsDown>{cardWeb}</Hidden>
      <Hidden smUp>{cardMobile}</Hidden>
    </>
  );
}

export default InspirationCard;
