import React from "react";
import ReviewLikes from "./ReviewLikes";
import ReviewInvestments from "./ReviewInvestments";
import ReviewStars from "./ReviewStars";
import { ICultureState } from "../../redux/reducers/cultureReducer";

interface IProps {
  title: string;
  props: any;
  points: number;
  postLike: (userLiked: boolean) => void;
  postInvestments: (value: any, isIncrease: boolean) => void;
  sellInvestments: (value: any) => void;
  getAllIdeaDetails: any;
  ideaId: string;
  culture: ICultureState;
  isCollaboration?: boolean;
  isChooseForImplementation?: boolean;
}

export default class ReviewRatingBox extends React.PureComponent<IProps> {
  public render() {
    switch (this?.props?.props?.evaluationType?.toUpperCase()) {
      case "LIKES":
        return (
          <ReviewLikes
            title={this.props?.title}
            props={this.props?.props}
            postLike={this.props?.postLike}
          />
        );
      case "STARS":
        return (
          <ReviewStars
            isCollaboration={this.props?.isCollaboration}
            ideaId={this.props?.ideaId}
            starsEvaluation={this.props?.props}
            getAllIdeaDetails={this.props?.getAllIdeaDetails}
          />
        );
      case "INVESTMENTS":
        return (
          <ReviewInvestments
            isChooseForImplementation={this.props?.isChooseForImplementation}
            points={this.props?.points}
            title={this.props?.title}
            investment={this.props?.props?.investment}
            sellInvestments={this.props?.sellInvestments}
            postInvestments={this.props?.postInvestments}
            culture={this.props?.culture}
          />
        );
      default:
        return <></>;
    }
  }
}
