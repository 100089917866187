import { AnyAction } from "redux";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import React, { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import logo from "../../../assets/img/logo.png";
import Resource from "components/Resources/Resource";
import { securityTextEditor } from "utils/securityTextEditor";
import { ICultureState } from "redux/reducers/cultureReducer";
import { getRegulationsPublish } from "redux/actions/regulations/RegulationsAction";
import {
  Box,
  CircularProgress,
  Container,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  IRegulationsPublish,
  IRegulationsState,
} from "redux/types/regulations";
import {
  getProfileData,
  IProfileGetDataAction,
} from "redux/actions/ProfileActions";


interface IProps {
  cultureState: ICultureState;
  workspaceId: string;
  regulationsPublish: IRegulationsPublish;
  regulationsStatus: string;
  image: string;
}

interface IDispatchProps {
  getRegulationsPublish: (params: object) => Promise<IRegulationsState>;
  getProfileData: () => Promise<IProfileGetDataAction>;
}

const useStyles = makeStyles(() =>
  createStyles({
    scroll: {
      paddingRight: 12,
      position: "relative",
      overflowY: "auto",
      marginBottom: "50px",
      "&::-webkit-scrollbar": {
        width: "0.3em",
        border: 0,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: 0,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c3c3c3",
        outline: 0,
        borderRadius: 12,
        border: 0,
      },
    },
  })
);

function RegulationsCurrentVersion(props: IProps & IDispatchProps) {
  const {
    cultureState,
    workspaceId,
    regulationsStatus,
    regulationsPublish,
    getRegulationsPublish,
    image,
  } = props;
  const classes = useStyles();
  useEffect(() => {
    getRegulationsPublish({
      culture: cultureState,
      workspaceId: workspaceId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {regulationsStatus === "LOADING" ? (
        <Box
          display="flex"
          height="60vh"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Container className="regulation-container">
          <Box mt={3}>
            <img
              style={{ maxHeight: "80px", maxWidth: "250px" }}
              src={image ? image : logo}
              alt="Exago"
            />
          </Box>
          <Box
            className="page-title"
            fontSize="1.5rem"
            fontWeight="bold"
            mt={3}
            mb={3}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <Resource tag="PageRegulation::Regulation" />
          </Box>
          {regulationsPublish?.description ? (
            <Box
              className={classes.scroll}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  `${regulationsPublish?.description}`,
                  securityTextEditor
                ),
              }}
            />
          ) : (
            ""
          )}
        </Container>
      )}
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  
  return {
    cultureState: store.cultureState,
    workspaceId: store.authState.auth.workspaceId,
    regulationsPublish: store.regulationsState.regulationsPublish,
    regulationsStatus: store.regulationsState.regulationsStatus,
    image: store.tenantState.activeTenant.image,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getRegulationsPublish: (params: object) =>
      dispatch(getRegulationsPublish(params)),
    getProfileData: () => dispatch(getProfileData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegulationsCurrentVersion);
