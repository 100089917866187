import React from "react";
import XCarousel from "common/X/XCarousel";
import Resource from "../Resources/Resource";
import {
  Grid,
  Box,
  Typography,
  Container,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import XValuableIdeaCard from "components/ValuableIdeaCard/XValuableIdeaCard";

interface IProps {
  ideasMyActivities?: any;
  valuableTag: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);

function ValuableCarousel(props: IProps) {
  const classes = useStyles();
  const ideasImplementedMyActivities = props?.ideasMyActivities?.result;
  const lenghtMy = ideasImplementedMyActivities?.length;

  return (
    <Container className={classes.container}>
      <Grid container>
        <Box mt={4} mb={2}>
          <Typography variant="h3">
            <Resource tag={props?.valuableTag} />
          </Typography>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        {lenghtMy <= 2 ? (
          <>
            {ideasImplementedMyActivities &&
              ideasImplementedMyActivities?.map((idea: any, index: number) => (
                <Grid item xs={11} sm={8} md={5} key={index}>
                  <XValuableIdeaCard key={index} idea={idea} />
                </Grid>
              ))}
          </>
        ) : (
          <XCarousel itemsToShow={2} partialVisible={true}>
            {ideasImplementedMyActivities?.length > 0 &&
              ideasImplementedMyActivities?.map((idea: any, index: number) => (
                <Grid item sm={12} md={12} key={index}>
                  <XValuableIdeaCard key={index} idea={idea} />
                </Grid>
              ))}
          </XCarousel>
        )}
      </Grid>
    </Container>
  );
}

export default ValuableCarousel;
