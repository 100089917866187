import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ITabState } from '../reducers/tabReducer';
import { toast } from 'react-toastify';


export enum TabActionsTypes {
    TAB_SELECT = 'TAB_SELECT',
}

export interface ITabAction {
    type: TabActionsTypes.TAB_SELECT;
    activeName: string;
    activeContent: React.ReactNode;
}

export type TabActions =
| ITabAction

export const tabAction: ActionCreator<ThunkAction<Promise<any>, ITabState, null, ITabAction>> = (name, content) => {
    return async (dispatch: Dispatch) => {
      try {
        //TODO: GET THE REAL DATA FROM THE API
        dispatch({
            activeContent: content,
            activeName: name,
            type: TabActionsTypes.TAB_SELECT,
        });            
      } catch (err) {
        toast.error(err.error_message)
      }
    };
  };