import Tags from "../Tags/Tags";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { ThunkDispatch } from "redux-thunk";
import Resource from "../Resources/Resource";
import { IIdeaDetails } from "redux/types/ideas";
import CloseIcon from "@material-ui/icons/Close";
import { IAppState } from "../../redux/store/Store";
import ShowCoAuthors from "../ShowCoAuthors/ShowCoAuthors";
import React, { Fragment, useEffect, useState } from "react";
import { securityTextEditor } from "utils/securityTextEditor";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import KeyTaskDetailButtons from "../KeyTaskDetailButtons/KeyTaskDetailButtons";
import IdeaDetailCustomFields from "../IdeaDetailCustomFields/IdeaDetailCustomFields";
import { getRelatedContent } from "redux/actions/relatedContent/RelatedContentAction";
import KeyTasksIdeaDetailTabs from "components/KeyTaksIdeaDetail/KeyTasksIdeaDetailTabs/KeyTasksIdeaDetailTabs";
import KeyTaskIdeaDetailEvaluationComments from "../KeyTaskIdeaDetailEvaluationComments/KeyTaskIdeaDetailEvaluationComments";
import {
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  IIdeasDetailGetAllAction,
  getAllIdeaDetails,
  getAllIdeasComments,
} from "redux/actions/ideas/IdeaAction";
import {
  IRelatedContentResult,
  IRelatedContentState,
} from "redux/reducers/relatedContent/RelatedContentReducer";
import { postChoseForImplementationAction, postKeyTasksTransitions } from "redux/actions/keyTasks/KeyTasksAction";
import { IKeyTasksImpactTypes } from "redux/reducers/keyTasks/keyTasksReducer";
import newApp from "utils/reactNewApp";

interface IDispatchProps {
  getAllIdeaDetails: (options: object) => Promise<IIdeasDetailGetAllAction>;
  getRelatedContent: (options: object) => Promise<IRelatedContentState>;
  getAllIdeasComments: (options: object) => Promise<any>;
  postChoseForImplementation: (options: object, culture: ICultureState) => Promise<any>;
  transitKeytask: (options:object) => Promise<any>;
}

interface IProps {
  type?: string;
  closeButton?: any;
  applicationId?: string | number;
  culture?: ICultureState;
  ideaDetails: IIdeaDetails;
  ideaId: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  postIdeasStars?: any;
  getEvaluationKeyTasks?: any;
  relatedContent: IRelatedContentResult[];
  relatedStatus: string;
  privateComments: any;
  refreshTabs?: any;
  workspaceId: string;
  impactTypes: IKeyTasksImpactTypes[];
  permission: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: "30px 60px 0",
      fontSize: 18,
    },
    pageTitle: {
      marginTop: 24,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      color: theme.palette.grey[500],
      marginTop: 8,
    },
    grid: {
      padding: " 12px 5% 3%",
    },
    ideaTitle: {
      fontSize: 20,
      marginBottom: 4,
    },
  })
);

const KeyTasksIdeaDetailDialog = (props: IDispatchProps & IProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    ideaDetails,
    isOpen,
    setOpen,
    postIdeasStars,
    ideaId,
    applicationId,
    getEvaluationKeyTasks,
    refreshTabs
  } = props;

  const [loading, setLoading] = useState({
    main: true,
    tabs: true,
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [starsData, setStarsData] = useState({});

  useEffect(() => {
    const {
      applicationId,
      getAllIdeaDetails,
      ideaId,
      getRelatedContent,
      getAllIdeasComments
    } = props;

    const options = {
      ideaDetailId: ideaId,
      applicationId: applicationId,
      culture: props?.culture,
    };

    getAllIdeasComments({
      ideaId,
      status: 2,
      pageNumber: 1,
      pageSize: 10,
    });

    getAllIdeaDetails(options).then(() => {
      setLoading((prev) => {
        return { ...prev, main: false };
      });
      const relatedContentOptions = {
        objectType: 1,
        objectId: ideaId,
        pageNumber: 1,
        pageSize: 8,
      };
      getRelatedContent(relatedContentOptions).then(() => {
        setLoading((prev) => {
          return { ...prev, tabs: false };
        });
      });
    });
    // eslint-disable-next-line
  }, []);

  const postStars = (params: any) => {
    postIdeasStars({ ideaId, applicationId, ...starsData }).then(async () => {
      getEvaluationKeyTasks &&
        (await refreshTabs({
          culture: props?.culture,
        }));
      setOpen(false);
    });
  };

  const otherFiles = ideaDetails?.files?.map((file, index) => {
    let fileType = file?.fileName.split(".")[1];
    if (
      fileType === "pdf" ||
      fileType === "jpg" ||
      fileType === "bmp" ||
      fileType === "png" ||
      fileType === "jpeg" ||
      fileType === "gif" ||
      fileType === "webp" ||
      fileType === "svg"
    ) {
      return "";
    } else {
      return file;
    }
  });

  return (
    <Dialog maxWidth={"lg"} fullScreen={fullScreen} fullWidth open={isOpen}>
      {!loading?.main ? (
        <>
          <DialogTitle disableTypography className={classes.title}>
            <Typography color="textPrimary" variant="h4">
              {props?.type === "evaluation" && (
                <Resource tag="ModalKeyTasks::EvaluateIdea" />
              )}
              {props?.type === "validation" && (
                <Resource tag="ModalKeyTasks::Validations" />
              )}
              {props?.type === "transition" && (
                <Resource tag="ModalKeyTasks::Transitions" />
              )}
            </Typography>
            <IconButton
              className={classes.closeButton}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <Divider className={classes.divider} />
          </DialogTitle>
          <DialogContent>
            <Grid container className={classes.grid}>
              <Grid item xs={12} sm={7}>
                <Box>
                  <Typography className={classes.ideaTitle} variant="h4">
                    {ideaDetails?.title}
                  </Typography>
                  <Typography color={"textSecondary"} variant="body2">
                    <Resource tag="PageEditIdea::Challenge" />
                    {ideaDetails?.challengeTitle}
                  </Typography>
                  <Typography
                    style={{ textTransform: "uppercase" }}
                    color={"textSecondary"}
                    display="inline"
                    variant="body2"
                  >
                    { newApp ? <Resource tag={"PageDetailIdea::StatusV3"} /> : <Resource tag={"PageDetailIdea::Status"} /> }:
                  </Typography>
                  <Box ml={0.5} component="span" fontWeight={600}>
                    {ideaDetails?.phaseTitle}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box>
                  <KeyTaskDetailButtons
                    setIdeaDetailOpen={setOpen}
                    dataObject={{
                      title: [ideaDetails?.title],
                      ideaIds: [ideaDetails?.ideaId],
                      ideaId: ideaDetails?.ideaId,
                      allowReject: ideaDetails?.allowReject,
                    }}
                    type={
                      props?.type === "transition" ? "idea-detail" : props?.type
                    }
                    submitDisabled={submitDisabled}
                    postIdeasStars={postStars}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box
                  mt={2}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                      ideaDetails?.description,
                      securityTextEditor
                    ),
                  }}
                ></Box>
                <Box mt={3}>
                  {ideaDetails?.tags?.length <= 0 ? (
                    ""
                  ) : (
                    <Tags list={ideaDetails?.tags} />
                  )}
                </Box>
                <Box mt={4} py={2}>
                  {ideaDetails?.files?.length !== 0 ? (
                    <>
                      <Typography
                        color="textSecondary"
                        style={{
                          textTransform: "uppercase",
                          marginBottom: "16px",
                        }}
                      >
                        <Resource tag={"PageDetailIdea::Media"} />
                      </Typography>
                      <Grid container spacing={2}>
                        {ideaDetails?.files?.map((file, index) => {
                          let fileType = file?.fileName.split(".")[1];
                          if (
                            fileType === "jpg" ||
                            fileType === "bmp" ||
                            fileType === "png" ||
                            fileType === "jpeg" ||
                            fileType === "gif" ||
                            fileType === "svg" ||
                            fileType === "webp"
                          ) {
                            return (
                              <Fragment key={file?.fileId || index}>
                                <Grid
                                  key={file?.fileId || index}
                                  item
                                  container
                                  xs={12}
                                  sm={12}
                                  md={index === 0 ? 8 : 4}
                                  lg={index === 0 ? 8 : 4}
                                >
                                  <a
                                    style={{ width: "100%" }}
                                    rel="noopener noreferrer"
                                    href={file.fileUrl}
                                    download={file?.fileName}
                                  >
                                    <div
                                      className="img-media"
                                      style={{
                                        backgroundImage: `url("${file.fileUrl}")`,
                                      }}
                                    ></div>
                                  </a>
                                </Grid>
                              </Fragment>
                            );
                          } else return "";
                        })}
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
                <Box mt={3}>
                  {(ideaDetails?.customFields?.length > 0 ||
                    otherFiles?.length > 0) && (
                    <IdeaDetailCustomFields
                      files={ideaDetails?.files}
                      customFields={ideaDetails?.customFields}
                    />
                  )}
                </Box>
                <Box mb={2}>
                  <ShowCoAuthors ideaDetails={ideaDetails} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                {ideaDetails?.evaluation?.starsEvaluation?.comments && (
                  <KeyTaskIdeaDetailEvaluationComments
                    evaluate={props?.type === "evaluation"}
                    evaluation={ideaDetails?.evaluation}
                    comments={
                      ideaDetails?.evaluation?.starsEvaluation?.comments
                    }
                    setSubmitDisabled={setSubmitDisabled}
                    setStarsData={setStarsData}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  {!loading?.tabs ? (
                    <KeyTasksIdeaDetailTabs
                      ideaDetails={ideaDetails}
                      culture={props?.culture}
                      relatedContent={props?.relatedContent}
                      relatedStatus={props?.relatedStatus}
                      getRelatedContent={props?.getRelatedContent}
                    />
                  ) : (
                    <Box display="flex" justifyContent="center" p={2}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      ) : (
        <Box width="100%" textAlign="center" padding="5%">
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

const mapStateToProps = (store: IAppState) => {
  return {
    applicationId: store.authState.auth.applicationId,
    culture: store.cultureState,
    ideaDetails: store.ideasState.ideaDetails,
    relatedContent: store.relatedContentState.relatedContent,
    relatedStatus: store.relatedContentState.relatedStatus,
    privateComments: store.ideasState.ideasComments,
    workspaceId: store.authState.auth.workspaceId,
    impactTypes: store?.keyTasksState?.impactTypes,
    permission: store.authState.auth.permission,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllIdeaDetails: (parameters: object) =>
      dispatch(getAllIdeaDetails(parameters)),
    getRelatedContent: (options: object) =>
      dispatch(getRelatedContent(options)),
    getAllIdeasComments: (options: object) =>
      dispatch(getAllIdeasComments(options)),
    postChoseForImplementation: (options: object, culture: ICultureState) =>
      dispatch(postChoseForImplementationAction(options, culture)),
    transitKeytask: (options: object) => dispatch(postKeyTasksTransitions(options))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyTasksIdeaDetailDialog);
