import React, { useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
  Hidden,
} from "@material-ui/core";
import KeyTaskIdeaDetailPopUp from "components/KeyTaskIdeaDetailPopUp/KeyTasksIdeaDetailDialog";
import Resource from "components/Resources/Resource";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Truncate from "react-truncate";

interface IProps {
  checkAll?: boolean;
  radioButtons?: boolean;
  type?: string;
  title?: string;
  time?: number;
  timeType?: string;
  ideaId?: string;
  status?: string;
  challenge?: string;
  numberCorrections?: number;
  handlerPopUp?: (value: string, check: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      borderTop: "solid 1px #ccc",
    },
    tableCell: {
      padding: "24px 10px 24px 0px!important",
      maxWidth: "250px",
    },
    allListCell: {
      display: "flex",
      padding: "24px 10px 24px 0px!important",
      width: "100%",
      justifyContent: "space-between",
    },
    truncate: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      maxWidth: 600,
    },
    uppercase: {
      textTransform: "uppercase",
    },
  })
);

function KeyTaskListItemValidation(props: IProps) {
  const {
    checkAll,
    title,
    time,
    timeType,
    ideaId,
    status,
    challenge,
    numberCorrections,
  } = props;
  const [type] = useState(props.type);
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  const data = [{ title: title! }, { ideaId: ideaId! }];

  const handleSelect = (event: any) => {
    const { handlerPopUp } = props;
    const value = event?.target?.value;
    const check = event?.target?.checked;

    if (handlerPopUp) {
      handlerPopUp(value, check);
    }
  };

  let timeResource = "";
  if (timeType) {
    timeResource = timeType?.replace(".", "::")!;
  }

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.tableCell}>
          <Checkbox
            value={`${data[1].ideaId},${data[0].title}`}
            checkedIcon={<RadioButtonCheckedIcon />}
            icon={
              checkAll ? (
                <RadioButtonCheckedIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )
            }
            inputProps={{ name: "idea-key-task" }}
            // inputProps={{ "aria-label": "Checkbox A", name: "idea-key-task" }}
            color="primary"
            disabled={checkAll}
            onChange={handleSelect}
          />
        </TableCell>
        <TableCell
          onClick={() => setOpen(true)}
          style={{ cursor: "pointer" }}
          className={classes.tableCell}
        >
          <Typography
            color="textPrimary"
            variant="body2"
            className={classes.truncate}
          >
            <strong>{title}</strong>
          </Typography>
        </TableCell>
        <Hidden xsDown>
          <TableCell className={classes.tableCell}>
            <Typography color="textPrimary" variant="body2">
              {status}
            </Typography>
          </TableCell>
        </Hidden>
        <TableCell className={classes.tableCell}>
          <Truncate lines={1} width={320} ellipsis={<span>...</span>}>
            <Typography color="textPrimary" variant="body2">
              {challenge}
            </Typography>
          </Truncate>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography color="textPrimary" variant="body2">
            <strong>{numberCorrections}</strong>
          </Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {timeResource !== null && (
            <Typography color="textPrimary" variant="body2" noWrap>
              <Resource tag={timeResource} args={[time]} />
            </Typography>
          )}
        </TableCell>
      </TableRow>
      {isOpen && (
        <KeyTaskIdeaDetailPopUp
          type={type}
          isOpen={isOpen}
          setOpen={setOpen}
          ideaId={ideaId!}
          closeButton={() => setOpen(false)}
        />
      )}
    </>
  );
}

export default KeyTaskListItemValidation;
