import React, { useEffect, useState } from "react";
import moment from "moment";
import "./HighligthedCard.css";
import "./HighligthedCard.css";
import XCard from "common/X/XCard";
import newApp from "utils/reactNewApp";
import sanitizeHtml from "sanitize-html";
import EMIcon from "components/Icon/Icon";
import history from "../../../routes/history";
import Resource from "../../Resources/Resource";
import { securityTextEditorWithoutStyles } from "utils/securityTextEditor";
import { IResultHighlighted } from "redux/types/challenges";
import { Grid, Typography, Box, Button, MenuItem } from "@material-ui/core";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllChallenges,
  patchHighlightChallengeAction,
  putEndSubmissionAction,
} from "redux/actions/ChallengeActions";
import { IAppState } from "redux/store/Store";
import { api_general } from "config/api";
//import ReactCountDownTimer from '../Timer/Timer';

interface IProps {
  challenge: IResultHighlighted;
}

const HighligthedCard = (props: IProps) => {
  const [state, setState] = useState<any>({
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
    countdown: undefined,
  });

  const { challenge } = props;
  const { days, hours, minutes, seconds, countdown } = state;

  const interval = setInterval(() => {
    const { challenge } = props;
    const time: any = moment(challenge?.endSubmitionDate).unix();
    const now: any = moment().unix();
    const countdown = time - now;
    const duration = moment.duration(countdown * 1000, "milliseconds");
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    setState({ days, hours, minutes, seconds, countdown });
  }, 1000);

  useEffect(() => {
    return clearInterval(interval);
  });

  const dispatch = useDispatch();
  const store = useSelector((store) => store) as IAppState;
  const permission = store.authState.auth.permission;

  const dispatchHighlight = async () => {
    await dispatch(
      patchHighlightChallengeAction({
        challengeId: challenge.challengeId,
        workspaceId: store.authState.auth.workspaceId,
        isHighlighted: false,
      })
    );
  };

  const dispatchSubmition = async () => {
    await dispatch(
      putEndSubmissionAction({
        challengeId: challenge.challengeId,
        workspaceId: store.authState.auth.workspaceId,
      })
    );
  };

  const setHighlight = async () => {
    await dispatchHighlight().then(() => {
      dispatch(
        getAllChallenges({
          workspaceId: store.authState.auth.workspaceId,
          challengeId: store.cultureState.culture.cultureTag,
        })
      );
    });
  };

  const setEndSubmition = async () => {
    await dispatchSubmition().then(() => {
      dispatch(
        getAllChallenges({
          workspaceId: store.authState.auth.workspaceId,
          challengeId: store.cultureState.culture.cultureTag,
        })
      );
    });
  };

  const duplicateChallenge = async () => {
    const response = await api_general.post(`challenges/duplicate`, {
      challengeId: challenge.challengeId,
      workspaceId: store.authState.auth.workspaceId,
    });

    if(response?.data?.data){
      history.push(`/createChallenge/${response?.data?.data}`);
    }
  };

  // TODO: handle data coming from the API (challenge?.timeValue1), (challenge?.timeValue1Resource), (challenge?.timeValue2), (challenge?.timeValue2Resource)
  return (
    <XCard
      color={"dark"}
      elevation={5}
      height={350}
      topContentPadding={20}
      middleContentPadding={20}
      bottomContentPadding={20}
      topContent={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            {days! === 0 ? (
              <Grid item xs={6} className="last24">
                <Resource tag={"PageChallenges::Challenge_Last24Hours"} />
              </Grid>
            ) : days! <= 2 ? (
              <Grid item xs={6} className="last24">
                <Resource tag={"PageChallenges::Challenge_Last48Hours"} />
              </Grid>
            ) : (
              <Grid item xs={12} className="more24"></Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" pr="45px">
              {days! < 1 && Math.sign(countdown!) === 1 && (
                <Box mr="25px" display="flex" alignItems="center">
                  <Box mr={1}>
                    {hours}:{minutes}:{seconds}
                  </Box>
                  <Resource tag="PageChallenges::Challenge_LeftTo" />
                </Box>
              )}
              {days! >= 0 && Math.sign(countdown!) === 1 && (
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    history.push("/createIdea/" + challenge.challengeId);
                  }}
                  startIcon={<EMIcon icon="plus" color="#fff" size={12} />}
                  size="small"
                >
                  <Box className="new-ideas-text">
                    <Resource tag={"PageChallenges::Challenge_ShareNewIdeas"} />
                  </Box>
                </Button>
              )}
              {newApp && (permission === "Admin" || permission === "ChallengeManager") && (
                <Box
                  style={{
                    position: "absolute",
                    top: "19px",
                    right: "16px",
                    padding: 6,
                  }}
                >
                  <MoreOptionsButton translate="-140px, 42px" iconColor="#fff">
                    <MenuItem
                      onClick={() => {
                        history.push(`createChallenge/${challenge.challengeId}`);
                      }}
                    >
                      <Resource tag="PageChallenges::EditChallenge" />
                    </MenuItem>
                    <MenuItem onClick={() => setHighlight()}>
                      <Resource tag="PageChallenges::RemoveHighlighted" />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        duplicateChallenge();
                      }}
                    >
                      <Resource tag="PageChallenges::Duplicate" />
                    </MenuItem>
                    <MenuItem onClick={() => setEndSubmition()}>
                      <Resource tag="PageChallenges::EndSubmissionPeriod" />
                    </MenuItem>
                  </MoreOptionsButton>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      }
      middleContent={
        <Box
          height="100%"
          onClick={() => {
            history.push(`/challenges/details/${challenge?.challengeId}`);
          }}
        >
          <Grid container spacing={2} justify="space-evenly">
            <Grid item xs={10}>
              <Box textAlign="left">
                <Typography variant="h1">
                  <strong>{challenge.title}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Box
                className="high-description-truncate"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(`${challenge.description}`, securityTextEditorWithoutStyles),
                }}
              />
            </Grid>
          </Grid>
        </Box>
      }
      bottomContent={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
      }
      backgroundImage={challenge.image}
    />
  );
};

export default HighligthedCard;
