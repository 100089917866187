import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from "@material-ui/core/styles";
import { DialogContent, DialogTitle, Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import ChangeCoAuthors from "../ChangeCoAuthor/ChangeCoAuthor";
import Resource from "../Resources/Resource";

export interface IProps {
  open: boolean;
  ideaId: string | undefined;
  onClose: () => void;
}

function KeyTasksChangeMPopup(props: IProps) {
  const theme = useTheme();
  const { onClose, open, ideaId } = props;

  const handleClose = () => {
    onClose();
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      onClose={handleClose}
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle disableTypography style={{ padding: "24px 4%" }}>
        <Typography variant="h4">
          <Resource tag="PageKeyTasks::ChangeManagers" />
        </Typography>
        {onClose ? (
          <IconButton
            // aria-label="close"
            style={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <Divider
          style={{
            width: "100%",
            color: theme.palette.grey[500],
            margin: "12px 0 3px",
          }}
        />
      </DialogTitle>
      <DialogContent>
        <ChangeCoAuthors onClose={onClose} ideaId={ideaId} />
      </DialogContent>
    </Dialog>
  );
}

export default KeyTasksChangeMPopup;
