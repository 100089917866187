// Import redux types
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api_v2, api_general } from "./../../config/api";
import { treatError } from "../../utils/treatError";
import newApp from 'utils/reactNewApp';

// Import Character Typing
import { IFeed, IFeedState } from '../reducers/feedReducer';
import { IIdeaDetails } from 'redux/types/ideas';

const basePath = "feed";

// Create Action Constants
export enum FeedActionTypes {
  GET_ALL_FEED = 'GET_ALL_FEED',

  POST_EVALUATION = 'POST_EVALUATION',
}

// Interface for Get All Feed Action Type
export interface IFeedGetAllAction {
  type: FeedActionTypes.GET_ALL_FEED;
  feed: IFeed;
}
export interface IFeedEvaluationAction {
  type: FeedActionTypes.POST_EVALUATION;
  isDislike?: boolean
  ideaDetails: IIdeaDetails;
}


/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type FeedActions = 
| IFeedGetAllAction
| IFeedEvaluationAction

/* Get All Feed
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllFeed: ActionCreator<
  ThunkAction<Promise<any>, IFeedState, null, IFeedGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }
      
      newApp ? 
        await api_general.get(`${basePath}${params_qs}`, {}).then(response => {
          dispatch({
            feed: response.data,
            type: FeedActionTypes.GET_ALL_FEED,
          });
        })
      :
        await api_v2.get(`${basePath}${params_qs}`, {}).then(response => {
          dispatch({
            feed: response.data,
            type: FeedActionTypes.GET_ALL_FEED,
          });
        })

      } catch (err) {
      treatError(options?.culture, 'Feed', err);
    }
  };
};