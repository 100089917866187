// Import redux types
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import sanitizeHtml from 'sanitize-html';
import newApp from 'utils/reactNewApp';
import { api_v2, api_general } from "../../../config/api";
import { treatError } from "../../../utils/treatError";

// Import Character Typing
import { IInsights, IInsightsState, Insight } from '../../reducers/insight/insightsReducer';

const basePath = "insights";

// Create Action Constants
export enum InsightsActionTypes {
  GET_INSIGHTS_SUCCESS = 'GET_INSIGHTS_SUCCESS',
  GET_INSIGHTS_LOAD = 'GET_INSIGHTS_LOAD',
  GET_INSIGHTS_FAIL = 'GET_INSIGHTS_FAIL',

  INSIGHTS_COMMENTS_LOAD = 'INSIGHTS_COMMENTS_LOAD',
  INSIGHTS_COMMENTS_SUCCESS = 'INSIGHTS_COMMENTS_SUCCESS',
  INSIGHTS_COMMENTS_FAIL = 'INSIGHTS_COMMENTS_FAIL',

  INSIGHTS_POSTCOMMENTS_LOAD = 'INSIGHTS_POSTCOMMENTS_LOAD',
  INSIGHTS_POSTCOMMENTS_SUCCESS = 'INSIGHTS_POSTCOMMENTS_SUCCESS',
  INSIGHTS_POSTCOMMENTS_FAIL = 'INSIGHTS_POSTCOMMENTS_FAIL',

  GET_MORE_INSIGHTS_SUCCESS = 'GET_MORE_INSIGHTS_SUCCESS',

  INSIGHTS_LIKE_COMMENT = 'INSIGHTS_LIKE_COMMENT',
  INSIGHTS_DISLIKE_COMMENT = 'INSIGHTS_DISLIKE_COMMENT',

  POST_CREATE_INSIGHTS = 'POST_CREATE_INSIGHTS',
  PUT_UPDATE_INSIGHTS = 'PUT_UPDATE_INSIGHTS',
  DELETE_INSIGHTS = 'DELETE_INSIGHTS',
  GET_SPECIFIC_INSIGHTS = "GET_SPECIFIC_INSIGHTS"
}
export enum InsightsStatusTypes {
  INSIGHTS_LOAD = 'LOADING',
  INSIGHTS_SUCCESS = 'SUCCESS',
  INSIGHTS_FAIL = 'FAILED'
}

// Interface for Get All Alert Action Type
export interface IInsightsLoadAction {
  type: InsightsActionTypes.GET_INSIGHTS_LOAD;
  insightStatus: string;
}
export interface IInsightsSuccessAction {
  type: InsightsActionTypes.GET_INSIGHTS_SUCCESS;
  insights: IInsights;
  insightStatus: string;
}
export interface IInsightsFailAction {
  type: InsightsActionTypes.GET_INSIGHTS_FAIL;
  insightStatus: string;
}

export interface IInsightsMoreSuccessAction {
  type: InsightsActionTypes.GET_MORE_INSIGHTS_SUCCESS;
  insights: IInsights
  insightStatus: string;
}

export interface IInsightsCommentsLoadAction {
  type: InsightsActionTypes.INSIGHTS_COMMENTS_LOAD;
  commentInsightStatus: string;
}
export interface IInsightsCommentsSuccessAction {
  type: InsightsActionTypes.INSIGHTS_COMMENTS_SUCCESS;
  insightsComments: any;
  commentInsightStatus: string;
}
export interface IInsightsCommentsFailAction {
  type: InsightsActionTypes.INSIGHTS_COMMENTS_FAIL;
  commentInsightStatus: string;
}

export interface IInsightsPostCommentsLoadAction {
  type: InsightsActionTypes.INSIGHTS_POSTCOMMENTS_LOAD;
  commentInsightStatus: string;
}
export interface IInsightsPostCommentsSuccessAction {
  type: InsightsActionTypes.INSIGHTS_POSTCOMMENTS_SUCCESS;
  insightsComments: any;
  commentInsightStatus: string;
}
export interface IInsightsPostCommentsFailAction {
  type: InsightsActionTypes.INSIGHTS_POSTCOMMENTS_FAIL;
  commentInsightStatus: string;
}
export interface IInsightsLikeCommentAction {
  type: InsightsActionTypes.INSIGHTS_LIKE_COMMENT;
}
export interface IInsightsDislikeCommentAction {
  type: InsightsActionTypes.INSIGHTS_DISLIKE_COMMENT;
}

export interface IInsightsPostCreateAction {
  type: InsightsActionTypes.POST_CREATE_INSIGHTS;
}

export interface IInsightsPutUpdateAction {
  type: InsightsActionTypes.PUT_UPDATE_INSIGHTS;
}
export interface IInsightsDeleteAction {
  type: InsightsActionTypes.DELETE_INSIGHTS;
}

export interface IInsightsGetSpecific {
  type: InsightsActionTypes.GET_SPECIFIC_INSIGHTS,
  specificInsight: Insight
}
/* 

Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type InsightsActions =
  | IInsightsLoadAction
  | IInsightsSuccessAction
  | IInsightsFailAction
  | IInsightsCommentsLoadAction
  | IInsightsCommentsSuccessAction
  | IInsightsCommentsFailAction
  | IInsightsPostCommentsLoadAction
  | IInsightsPostCommentsSuccessAction
  | IInsightsPostCommentsFailAction
  | IInsightsLikeCommentAction
  | IInsightsDislikeCommentAction
  | IInsightsMoreSuccessAction
  | IInsightsPostCreateAction
  | IInsightsPutUpdateAction
  | IInsightsDeleteAction
  | IInsightsGetSpecific

/* Get All Insights
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllInsights: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        insightStatus: InsightsStatusTypes.INSIGHTS_LOAD,
        type: InsightsActionTypes.GET_INSIGHTS_LOAD,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }


      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = !newApp
        ? await api_v2.get(`${basePath}/${options.challengeId}${params_qs}`, {})
        : await api_general.get(`${basePath}/${options.challengeId}${params_qs}`, {})

      dispatch({
        insights: response.data,
        insightStatus: InsightsStatusTypes.INSIGHTS_SUCCESS,
        type: InsightsActionTypes.GET_INSIGHTS_SUCCESS,
      });
    } catch (err) {
      dispatch({
        insightStatus: InsightsStatusTypes.INSIGHTS_FAIL,
        type: InsightsActionTypes.GET_INSIGHTS_FAIL,
      });
      treatError(options?.culture, 'PageInsights::Header', err);
    }
  };
};

export const getMoreInsights: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = !newApp
        ? await api_v2.get(`${basePath}/${options.challengeId}${params_qs}`, {})
        : await api_general.get(`${basePath}/${options.challengeId}${params_qs}`, {})

      dispatch({
        insights: response.data,
        insightStatus: InsightsStatusTypes.INSIGHTS_SUCCESS,
        type: InsightsActionTypes.GET_MORE_INSIGHTS_SUCCESS,
      });
    } catch (err) {
      treatError(options?.culture, 'PageInsights::Header', err);
    }
  };
};

export const getInsightsComments: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsCommentsSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        commentInsightStatus: InsightsStatusTypes.INSIGHTS_LOAD,
        type: InsightsActionTypes.INSIGHTS_COMMENTS_LOAD,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = !newApp
        ? await api_v2.get(`${basePath}/${options.insightId}/comments${params_qs}`, {})
        : await api_general.get(`${basePath}/${options.insightId}/comments${params_qs}`, {})

      dispatch({
        insightsComments: response.data,
        commentInsightStatus: InsightsStatusTypes.INSIGHTS_SUCCESS,
        type: InsightsActionTypes.INSIGHTS_COMMENTS_SUCCESS,
      });
    } catch (err) {
      dispatch({
        commentInsightStatus: InsightsStatusTypes.INSIGHTS_FAIL,
        type: InsightsActionTypes.INSIGHTS_COMMENTS_FAIL,
      });
      treatError(options?.culture, 'PageInsights::Header', err);
    }
  };
};

export const postInsightsComments: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsCommentsSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        commentInsightStatus: InsightsStatusTypes.INSIGHTS_LOAD,
        type: InsightsActionTypes.INSIGHTS_POSTCOMMENTS_LOAD,
      });
      let params
      if (!newApp) {
        if (options.hasOwnProperty('commentParentId')) {
          params = {
            commentParentId: options.commentParentId,
            message: sanitizeHtml(options.message),
            applicationId: options.applicationId,
            captcha: options.captcha
          }
        } else {
          params = {
            message: sanitizeHtml(options.message),
            applicationId: options.applicationId,
            captcha: options.captcha
          }
        }
        await api_v2.post(`${basePath}/${options.insightId}/comments`, params).then(async () => {

          let paramsComments = [];
          let params_qs = "";

          paramsComments.push(`pageNumber=${options.pageNumber}`);
          paramsComments.push(`pageSize=${options.pageSize}`);

          if (paramsComments.length) {
            params_qs = `?${paramsComments.join("&")}`;
          }
          const response = await api_v2.get(`${basePath}/${options.insightId}/comments${params_qs}`, {});
          dispatch({
            commentInsightStatus: InsightsStatusTypes.INSIGHTS_SUCCESS,
            insightsComments: response.data,
            type: InsightsActionTypes.INSIGHTS_POSTCOMMENTS_SUCCESS,
          });
        });

      } else {
        if (options.hasOwnProperty('commentParentId')) {
          params = {
            commentParentId: options.commentParentId,
            message: sanitizeHtml(options.message),
            workspaceId: options.workspaceId,
            captcha: options.captcha
          }
        } else {
          params = {
            message: sanitizeHtml(options.message),
            workspaceId: options.workspaceId,
            captcha: options.captcha
          }
        }
        await api_general.post(`${basePath}/${options.insightId}/comments`, params).then(async () => {

          let paramsComments = [];
          let params_qs = "";

          paramsComments.push(`pageNumber=${options.pageNumber}`);
          paramsComments.push(`pageSize=${options.pageSize}`);

          if (paramsComments.length) {
            params_qs = `?${paramsComments.join("&")}`;
          }
          const response = await api_general.get(`${basePath}/${options.insightId}/comments${params_qs}`, {});
          dispatch({
            commentInsightStatus: InsightsStatusTypes.INSIGHTS_SUCCESS,
            insightsComments: response.data,
            type: InsightsActionTypes.INSIGHTS_POSTCOMMENTS_SUCCESS,
          });
        });
      }
    } catch (err) {
      dispatch({
        commentInsightStatus: InsightsStatusTypes.INSIGHTS_FAIL,
        type: InsightsActionTypes.INSIGHTS_POSTCOMMENTS_FAIL,
      });
      treatError(options?.culture, 'PageInsights::Header', err);
    }
  };
};

export const postLikeComment: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsLikeCommentAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {

      !newApp
        ? await api_v2.post(`${basePath}/${options.insightId}/comments/like`, {
          applicationId: options.applicationId,
          commentId: options.commentId,
        })
        : await api_general.post(`${basePath}/${options.insightId}/comments/like`, {
          workspaceId: options.workspaceId,
          commentId: options.commentId,
        })
      dispatch({
        type: InsightsActionTypes.INSIGHTS_LIKE_COMMENT,
      });
    } catch (err) {
      treatError(options?.culture, 'PageInsights::Header', err);
    }
  };
};

/* Post Dislike  Like Comment
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postDislikeComment: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsDislikeCommentAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      !newApp
        ? await api_v2.post(`${basePath}/${options.insightId}/comments/dislike`, {
          applicationId: options.applicationId,
          commentId: options.commentId,
        })
        : await api_general.post(`${basePath}/${options.insightId}/comments/dislike`, {
          workspaceId: options.workspaceId,
          commentId: options.commentId,
        })
      dispatch({
        type: InsightsActionTypes.INSIGHTS_DISLIKE_COMMENT,
      });

    } catch (err) {
      treatError(options?.culture, 'PageInsights::Header', err);
    }
  };
};
export const postCreateInsight: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsPostCreateAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_general.post(`${basePath}`, options)
      dispatch({
        type: InsightsActionTypes.POST_CREATE_INSIGHTS,
      });
    } catch (err) {
      treatError(options?.culture, 'PageInsights::Header', err);
    }
  };
}
export const putUpdateInsight: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsPutUpdateAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_general.put(`${basePath}/${options.insightId}`, options)

      dispatch({
        type: InsightsActionTypes.PUT_UPDATE_INSIGHTS
      })
    } catch (error) {
      treatError(options?.culture, 'PageInsights::Header', error);
    }
  }
}
export const deleteInsight: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsDeleteAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [`challengeId=${options.challengeId}`, `workspaceId=${options.workspaceId}`];
      let params_qs = `?${params.join("&")}`;
      await api_general.delete(`${basePath}/${options.insightId}${params_qs}`)

      dispatch({
        type: InsightsActionTypes.DELETE_INSIGHTS
      })
    } catch (error) {
      treatError(options?.culture, 'PageInsights::Header', error);
    }
  }
}
export const getInsight: ActionCreator<
  ThunkAction<Promise<any>, IInsightsState, null, IInsightsDeleteAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await api_general.get(`${basePath}/${options.insightId}/specific`, options)

      dispatch({
        type: InsightsActionTypes.GET_SPECIFIC_INSIGHTS,
        specificInsight: res.data
      })
    } catch (error) {
      treatError(options?.culture, 'PageInsights::Header', error);
    }
  }
}