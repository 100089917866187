import React from "react";
import { Box } from "@material-ui/core";
import SkeletonGrid from "common/UI/Skeleton/SkeletonGrid";

export default function IdeasDraftPageSkeleton() {
  return (
    <>
      <Box mt={5} mb={5}>
        <SkeletonGrid col={2} row={10} rowHeight={200} />
      </Box>
    </>
  );
}
