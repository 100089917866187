import React from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EMIcon from "components/Icon/Icon";
interface IProps {
  children?: any;
  maxItemsToShow?: number;
  deviceType?: string;
  itemsToShow?: number;
  infinite?: boolean;
  partialVisible?: boolean;
  showDots?: boolean;
  autoPlay?: boolean;
}

export default function XCarousel(props: IProps) {
  const styleProps = {
    deviceType: props?.deviceType,
  };
  const classes = useStyles(styleProps);
  const CustomRightArrow = ({ onClick }: any) => {
    return (
      <button
        // aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        onClick={() => onClick()}
      >
        <EMIcon icon="arrow-right" color="#fff" />
      </button>
    );
  };
  const CustomLeftArrow = ({ onClick }: any) => {
    return (
      <button
        // aria-label="Go to previous slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        onClick={() => onClick()}
      >
        <EMIcon icon="arrow-left" color="#fff" />
      </button>
    );
  };
  return (
    <main className={classes.root}>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={props?.autoPlay}
        ssr
        autoPlaySpeed={3000}
        centerMode={false}
        className={classes.carousel}
        containerClass=""
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={props?.infinite}
        itemClass=""
        keyBoardControl
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        partialVisible={props?.partialVisible}
        responsive={{
          mobile: {
            breakpoint: {
              min: 0,
              max: 600,
            },
            items: 1,
            partialVisibilityGutter: 0,
          },
          tablet: {
            breakpoint: {
              min: 600,
              max: 900,
            },
            items: 1,
            partialVisibilityGutter: 110,
          },
          desktop: {
            breakpoint: {
              min: 900,
              max: 3000,
            },
            items: props?.itemsToShow ? props?.itemsToShow : 2,
            partialVisibilityGutter: props?.itemsToShow === 1 ? 0 : 120,
          },
        }}
        showDots={props?.showDots}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {props?.children}
      </Carousel>
    </main>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: IProps) => ({
      position: "relative",
      width: "calc(100% + 20px)",
      marginLeft: "-10px",
      marginRight: "-10px",
    }),
    carousel: (props: IProps) => ({
      position: "relative",
      width: "100%",
      "& .xcard": {
        padding: "0px 10px",
      },
      "&:after": {
        content: '""',
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "10px",
        height: "100%",
        backgroundColor: "#FFFFFF",
      },
      "&:before": {
        content: '""',
        position: "absolute",
        // zIndex: 1,
        top: "0px",
        right: "0px",
        width: "10px",
        height: "100%",
        backgroundColor: "#FFFFFF",
      },
      "& .react-multiple-carousel__arrow": {
        minWidth: 40,
        minHeight: 40,
      },
      "& .react-multiple-carousel__arrow--left": {
        left: 30,
        "&:before": {
          content: '""',
        },
      },
      "& .react-multiple-carousel__arrow--right": {
        right: 30,
        "&:before": {
          content: '""',
        },
      },
    }),
    customLeftArrow: {
      position: "absolute",
      left: 10,
      backgroundColor: "red",
      width: 50,
      height: 50,
    },
    customRightArrow: {
      position: "absolute",
      // zIndex: 10,
      right: 10,
      backgroundColor: "blue",
      width: 50,
      height: 50,
    },
  })
);
