import React from "react";
import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default function SkeletonRandomCards() {
  return (
    <>
      <Grid item container spacing={3}>
        <Grid item xs={12} sm={5}>
          <Skeleton variant="rect" height={225} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Skeleton variant="rect" height={225} />
        </Grid>
      </Grid>

      <Grid item container spacing={3}>
        <Grid item xs={12} sm={7}>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={225} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={225} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Skeleton variant="rect" height={475} />
        </Grid>
      </Grid>
    </>
  );
}
