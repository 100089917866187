import React from "react";
import Resource from "../Resources/Resource";
import logo from "../../assets/img/logo.png";
import { Box, Typography } from "@material-ui/core";
import { IAuthUser } from "redux/reducers/authReducer";
import ChooseLanguage from "components/ChooseLanguage/ChooseLanguage";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 120,
      [theme.breakpoints.only("xs")]: {
        width: 90,
        height: 20,
      },
    },
    texts: {
      fontSize: "0.6rem",
      [theme.breakpoints.only("xs")]: {
        fontSize: "0.5rem",
      },
    },
  })
);

interface IProps {
  userProps?: any;
  pathname?: string;
  authUser?: IAuthUser;
}

function LoggedOutFooter(props: IProps) {
  const classes = useStyles();
  const today = new Date();
  const year = today.getFullYear();

  let pathname = props?.userProps?.location?.pathname.split("/")[1];
  let pathnameCongrats = props?.pathname?.split("/")[1];

  return (
    <Grid container className="logged-out-footer-container">
      <Grid item xs={6} sm={6} md={6}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          paddingRight="35px"
        >
          {pathname !== "setprofile" &&
          pathnameCongrats !== "congrats" &&
          pathname !== "acceptterms" &&
          pathname !== "acceptRegulations" ? (
            <ChooseLanguage authUser={props?.authUser} />
          ) : (
            ""
          )}
        </Box>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Box
          className="footer"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          paddingRight="20px"
        >
          <Box className="poweredby-logo" display="flex" alignItems="center">
            <Box mr={2}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.texts}
              >
                <Resource tag={"PageLogin::PoweredBy"} />
              </Typography>
            </Box>
            <Box>
              <img src={logo} alt="Exago" className={classes.img} />
            </Box>
          </Box>
          <Box className="terms-version" display="flex" alignItems="center">
            {/* TODO IN THE NEW PRODUCT
            <Box mr={3} style={{ textAlign: "right" }}>
              <Link href="/terms" variant="subtitle2" color="textPrimary">
                <Resource tag={"PageLogin::TermsAndConditions"} />
              </Link>
            </Box> */}
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.texts}
              >
                <Resource tag={"PageLogin::Version"} />{" "}
                {process.env.REACT_APP_VERSION} | Exago© {year}.{" "}
                <Resource tag={"PageLogin::AllRightsReserved"} />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LoggedOutFooter;
