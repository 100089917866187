import React from "react";
import XDialog from "common/X/XDialog";
import { Box, Button } from "@material-ui/core";
import Resource from "components/Resources/Resource";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  confirmAction: () => void;
}

function PopupClearAllWorkflow({ open, setOpen, confirmAction }: IProps) {
  const action = (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <Box mr={1}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => setOpen(false)}
        >
          <Resource tag="PageCreateChallenge::Workflow_NoCancel" />
        </Button>
      </Box>
      <Box>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            confirmAction();
            setOpen(false);
          }}
        >
          <Resource tag="PageCreateChallenge::Workflow_YesDelete" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      title={<Resource tag="PageCreateChallenge::Workflow_ClearAll" />}
      maxWidth="sm"
      actions={action}
    >
      <Box textAlign="center" mt={3} mx={6}>
        <Resource tag="PageCreateChallenge::Workflow_ConfirmClear" />
      </Box>
    </XDialog>
  );
}

export default PopupClearAllWorkflow;
