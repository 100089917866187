import "./MyActivity.css";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import Title from "components/Title/Title";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import CommentsTab from "containers/CommentsTab/CommentsTab";
import { IProfileState } from "redux/reducers/profileReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import MyActivityPageSkeleton from "./MyActivityPage.Skeleton";
import { Grid, Paper, Typography, Fade } from "@material-ui/core";
import ValuableCarousel from "components/ValuableCarousel/ValuableCarousel";
import MyActivityPrizes from "components/MyActivityPrizes/MyActivityPrizes";
import MyActivityStarListTabs from "containers/MyActivityStarList/MyActivityStarListTabs";
import MyActivitiesLikesList from "containers/MyActivitiesLikesList/MyActivitiesLikesList";
import MyActivitiesInvestList from "containers/MyActivitiesInvestList/MyActivitiesInvestList";
import MyActivitiesInProgressList from "containers/MyActivitiesInProgressList/MyActivitiesInProgressList";
import MyActivitiesNotProgressList from "containers/MyActivitiesNotProgressList/MyActivitiesNotProgressList";
import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  Divider,
} from "@material-ui/core";
import {
  getAllMyActivities,
  getAllMyActivitiesLikes,
  getAllMyActivitiesStarCollaborative,
  getAllMyActivitiesStarPrivate,
  getAllMyActivitiesInvestmentActive,
  getAllMyActivitiesInvestmentHistory,
  getPublicComments,
  getPrivateComments,
  getAllMyActivitiesPrizesActivieBid,
  getAllMyActivitiesPrizesWon,
  getAllMyActivitiesPrizesLost,
  getAllMyActivitiesIdeasImplemented,
  getAllMyActivitiesIdeasClosed,
  getAllMyActivitiesIdeasInProgress,
  setActivePage,
} from "redux/actions/MyActivities/MyActivitiesActions";
import {
  IMyActivities,
  IMyActivitiesLikes,
  IMyActivitiesStarsCollaborative,
  IMyActivitiesStarsPrivate,
  IMyActivitiesInvestmentsActive,
  IMyActivitiesInvestmentsHistory,
  IPublicMyActivityComments,
  IPrivateMyActivityComments,
  IMyActivitiesPrizesActiveBid,
  IMyActivitiesPrizesWon,
  IMyActivitiesPrizesLost,
  IMyActivitiesIdeasImplemented,
  IMyActivitiesIdeasClosed,
  IMyActivitiesIdeasInProgress,
} from "redux/types/myActivities";
import DynamicInfiniteScroll from "../KeyTasks/DynamicInfiniteScroll";
import { getProfile, IProfileGetAllAction } from "redux/actions/ProfileActions";
import { closeAuctionPrize, deletePrize, duplicatePrize, IPrizeBidPostAction, IPrizeCloseAuctionSucceededAction, IPrizeDeleteSucceededAction, IPrizeDuplicateSucceededAction, postPrizeBid } from "redux/actions/PrizeActions";
import history from 'routes/history';
import newApp from "utils/reactNewApp";

const pagination = {
  current: 1,
  pageSize: 10,
  finished: false,
};

const status = {
  init: true,
  refreshControl: false,
};

const options = {
  ideaId: "",
  applicationId: 0,
  status: 1,
  pageNumber: status.init ? 1 : pagination.current,
  pageSize: pagination.pageSize,
  cultureTag: "",
  typeTask: "",
  culture: {},
  isInfinite: false,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "50px",
      width: "100%",
      [theme.breakpoints.between("xs", "sm")]: {
        marginTop: 0,
        width: "100%",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "20px",
        width: "100%",
      },
    },
    paper: {
      height: theme.spacing(10),
      [theme.breakpoints.between("xs", "sm")]: {
        height: theme.spacing(6),
      },
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      cursor: "pointer",
      "&:hover": {
        background: "#ddd",
      },
      "& h5": {
        fontWeight: "600",
      },
      [theme.breakpoints.down("xs")]: {
        "& h5": {
          fontSize: 18,
        },
        "& p": {
          fontSize: 12,
        },
      },
    },
    activePage: {
      background: "#ddd",
      "& h5": {
        fontWeight: "600",
      },
    },
    content: {
      marginTop: "24px",
    },
    divider: {
      width: 0,
      backgroundColor: "rgb(187, 231, 249)",
      [theme.breakpoints.between("md", "xl")]: {
        margin: "auto",
        height: "13vh",
        width: "2px",
        maxHeight: "98px",
      },
    },
  })
);

interface IProps {
  getAllMyActivities: (options: object) => Promise<IMyActivities>;
  getAllMyActivitiesIdeasInProgress: (
    options: object
  ) => Promise<IMyActivitiesIdeasInProgress>;
  getAllMyActivitiesLikes: (options: object) => Promise<IMyActivitiesLikes>;
  getAllMyActivitiesStarCollaborative: (
    options: object
  ) => Promise<IMyActivitiesStarsCollaborative>;
  getAllMyActivitiesStarPrivate: (
    options: object
  ) => Promise<IMyActivitiesStarsPrivate>;
  getAllMyActivitiesInvestmentActive: (
    options: object
  ) => Promise<IMyActivitiesInvestmentsActive>;
  getAllMyActivitiesInvestmentHistory: (
    options: object
  ) => Promise<IMyActivitiesInvestmentsHistory>;
  getPublicComments: (options: object) => Promise<IPublicMyActivityComments>;
  getPrivateComments: (options: object) => Promise<IPrivateMyActivityComments>;
  getAllMyActivitiesPrizesActivieBid: (
    options: object
  ) => Promise<IMyActivitiesPrizesActiveBid>;
  getAllMyActivitiesPrizesWon: (
    options: object
  ) => Promise<IMyActivitiesPrizesWon>;
  getAllMyActivitiesPrizesLost: (
    options: object
  ) => Promise<IMyActivitiesPrizesLost>;
  getAllMyActivitiesIdeasImplemented: (options: object) => Promise<any>;
  getAllMyActivitiesIdeasClosed: (options: object) => Promise<any>;
  setActivePage: (page: string) => Promise<any>;
  getProfile: (options: object) => Promise<IProfileGetAllAction>;
  duplicatePrize: (options: object) => Promise<IPrizeDuplicateSucceededAction>;
  deletePrize: (options: object) => Promise<IPrizeDeleteSucceededAction>;
  closeAuctionPrize: (options: object) => Promise<IPrizeCloseAuctionSucceededAction>;
  postBidPrize: (options: object) => Promise<IPrizeBidPostAction>;
}
interface IStateProps {
  profile: IProfileState;
  myActivities: IMyActivities;
  activitiesInProgress: IMyActivitiesIdeasInProgress;
  myActivitiesLikes: IMyActivitiesLikes;
  arrStarsCollaborative: IMyActivitiesStarsCollaborative;
  arrStarsPrivate: IMyActivitiesStarsPrivate;
  myActivitiesInvestmentsActive: IMyActivitiesInvestmentsActive;
  activeInvestmentsMeta: any;
  myActivitiesInvestmentsHistory: IMyActivitiesInvestmentsHistory;
  publicComments: IPublicMyActivityComments;
  privateComments: IPrivateMyActivityComments;
  myActivitiesPrizesActiveBid: IMyActivitiesPrizesActiveBid;
  myActivitiesPrizesWon: IMyActivitiesPrizesWon;
  myActivitiesPrizesLost: IMyActivitiesPrizesLost;
  myActivitiesIdeasImplemented: IMyActivitiesIdeasImplemented;
  myActivitiesIdeasClosed: IMyActivitiesIdeasClosed;
  myActivitiesIdeasInProgress: IMyActivitiesIdeasInProgress;
  applicationId: string | number;
  culture: ICultureState;
  activePage: string;
  location: any;
  //requestManagePrize: (prizeId: string, type: number) => void;
  points: number;
  permission: string;
}

function MyActivity(props: IProps & IStateProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const {
    activePage,
    myActivities,
    arrStarsCollaborative,
    arrStarsPrivate,
    myActivitiesLikes,
    publicComments,
    privateComments,
    myActivitiesInvestmentsActive,
    activeInvestmentsMeta,
    myActivitiesInvestmentsHistory,
    myActivitiesPrizesActiveBid,
    myActivitiesPrizesWon,
    myActivitiesPrizesLost,
    myActivitiesIdeasImplemented,
    myActivitiesIdeasInProgress,
    myActivitiesIdeasClosed,
    permission,
    applicationId,
    postBidPrize,
    getAllMyActivitiesLikes,
    getAllMyActivitiesStarCollaborative,
    getAllMyActivitiesStarPrivate,
    getPublicComments,
    getPrivateComments,
    getAllMyActivitiesInvestmentActive,
    getAllMyActivitiesInvestmentHistory,
    getAllMyActivitiesPrizesActivieBid,
    getAllMyActivitiesPrizesWon,
    getAllMyActivitiesPrizesLost,
    getAllMyActivitiesIdeasImplemented,
    getAllMyActivitiesIdeasClosed,
    getAllMyActivitiesIdeasInProgress,
    getAllMyActivities,
    setActivePage,
    duplicatePrize,
    deletePrize,
    closeAuctionPrize,
    culture,
    profile,
    points,
  } = props;

  useEffect(() => {
    options.culture = culture;
    options.cultureTag = culture.culture.cultureTag;

    // getAllMyActivities(options).then(() => {
    //   setLoading(false);
    // });

    Promise.all([
      getAllMyActivities(options),
      getAllMyActivitiesLikes({ ...options, pageSize: 6 }),
      getAllMyActivitiesStarCollaborative({ ...options, pageSize: 6 }),
      getAllMyActivitiesStarPrivate({ ...options, pageSize: 6 }),
      getPublicComments({ ...options, pageSize: 6 }),
      getPrivateComments({ ...options, pageSize: 6 }),
      getAllMyActivitiesInvestmentActive({ ...options, pageSize: 6 }),
      getAllMyActivitiesInvestmentHistory({ ...options, pageSize: 6 }),
      getAllMyActivitiesPrizesActivieBid({ ...options, pageSize: 6 }),
      getAllMyActivitiesPrizesWon({ ...options, pageSize: 6 }),
      getAllMyActivitiesPrizesLost({ ...options, pageSize: 6 }),
      getAllMyActivitiesIdeasImplemented(options),
      getAllMyActivitiesIdeasClosed({ ...options, pageSize: 6 }),
      getAllMyActivitiesIdeasInProgress({ ...options, pageSize: 6 }),
    ]).finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPage = (page: string) => {

    if(props.location.state) 
      props.location.state.pageDefault = "";
    setActivePage(page);
  };

  const inProgressTitle = (
    <Resource tag="PageMyActivity::Idea_TitleInProgress" />
  );
  const notProgressedTitle = (
    <Resource tag="PageMyActivity::Idea_TitleNotProgressed" />
  );

  async function showMore(
    typeTabs?: string,
    pageNumber?: number,
    pageSize?: number,
    isInfinite?: boolean
  ) {
    if (activePage === "COMMENTS") {
      if (typeTabs === "1") {
        getPublicComments({ ...options, pageNumber, pageSize, isInfinite });
      } else if (typeTabs === "2") {
        getPrivateComments({ ...options, pageNumber, pageSize, isInfinite });
      }
    } else if (activePage === "STARS") {
      if (typeTabs === "1") {
        getAllMyActivitiesStarCollaborative({
          ...options,
          pageNumber,
          pageSize,
          isInfinite,
        });
      } else if (typeTabs === "2") {
        getAllMyActivitiesStarPrivate({
          ...options,
          pageNumber,
          pageSize,
          isInfinite,
        });
      }
    } else if (activePage === "INVESTMENTS") {
      if (typeTabs === "1") {
        getAllMyActivitiesInvestmentActive({
          ...options,
          pageNumber,
          pageSize,
          isInfinite,
        });
      } else if (typeTabs === "2") {
        getAllMyActivitiesInvestmentHistory({
          ...options,
          pageNumber,
          pageSize,
          isInfinite,
        });
      }
    }
  }

  const requestManagePrize = (prizeId: string, type: number, isCanceling?: boolean) => {
    setLoading(true);
    const params = {
      prizeId: prizeId,
      isCanceling: isCanceling,
      culture: culture,
    };

    if (type === 1) {
      history.push(`/editPrize/${prizeId}`);
    }
    else if (type === 2) {
      duplicatePrize(params).then(() => {
        getProfile(options);
        setLoading(false);

      });
    }
    else if (type === 3) {
      deletePrize(params).then(() => {
        getProfile(options);
        setLoading(false);
      });
    }
    else if (type === 4) {
      closeAuctionPrize(params).then(() => {
        getProfile(options);
        setLoading(false);
      })
    }
  };

  const requestBidPrize = (prizeId: string, value: number) => {
    const params = {
      prizeId: prizeId,
      value: value,
      applicationId: applicationId,
      culture: culture,
    };

    postBidPrize(params).then(() => {
      getAllMyActivitiesPrizesActivieBid(options);
      getAllMyActivitiesPrizesWon(options);
      getAllMyActivitiesPrizesLost(options);
      getProfile(options);
    });
  };

  useEffect(() => {
    if(activePage === 'PRIZES') {
      getAllMyActivitiesPrizesActivieBid({ ...options, pageSize: myActivities.totalPrizes < 3 ? 6 : myActivities.totalPrizes});
      getAllMyActivitiesPrizesWon({ ...options, pageSize: myActivities.totalPrizes < 3 ? 6 : myActivities.totalPrizes });
      getAllMyActivitiesPrizesLost({ ...options, pageSize: myActivities.totalPrizes < 3 ? 6 : myActivities.totalPrizes });
    }

    // eslint-disable-next-line
  }, [activePage])

  return (
    <>
      {loading ? (
        <MyActivityPageSkeleton />
      ) : (
        <Box className={classes.root} marginBottom={5}>
          <Grid container spacing={3}>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Paper
                elevation={3}
                onClick={() => setPage("IDEAS")}
                className={`${classes.paper} + ${activePage === "IDEAS" ? classes.activePage : ""
                  }`}
              >
                <Typography
                  align="center"
                  variant="h4"
                  color={activePage === "IDEAS" ? "initial" : "primary"}
                >
                  {myActivities?.totalIdeas}
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                  color={activePage === "IDEAS" ? "initial" : "primary"}
                >
                  <Resource tag="PageIdea::Header" />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Paper
                elevation={3}
                onClick={() => setPage("COMMENTS")}
                className={`${classes.paper} + ${activePage === "COMMENTS" ? classes.activePage : ""
                  }`}
              >
                <Typography
                  align="center"
                  color={activePage === "COMMENTS" ? "initial" : "primary"}
                  variant="h4"
                >
                  {myActivities?.totalComments}
                </Typography>
                <Typography
                  align="center"
                  color={activePage === "COMMENTS" ? "initial" : "primary"}
                  variant="body1"
                >
                  <Resource tag="CommentsArea::Comments" />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Paper
                elevation={3}
                onClick={() => setPage("PRIZES")}
                className={`${classes.paper} + ${activePage === "PRIZES" ? classes.activePage : ""
                  }`}
              >
                <Typography
                  align="center"
                  color={activePage === "PRIZES" ? "initial" : "primary"}
                  variant="h4"
                >
                  {myActivities?.totalPrizes}
                </Typography>
                <Typography
                  align="center"
                  color={activePage === "PRIZES" ? "initial" : "primary"}
                  variant="body1"
                >
                  <Resource tag="PagePrizes::Header" />
                </Typography>
              </Paper>
            </Grid>
            {!newApp
              ?
              <>
                <Divider orientation="vertical" className={classes.divider} />
                <Grid item xs={4} sm={4} md={2} lg={2}>
                  <Paper
                    elevation={3}
                    onClick={() => setPage("LIKES")}
                    className={`${classes.paper} + ${activePage === "LIKES" ? classes.activePage : ""
                      }`}
                  >
                    <Typography
                      align="center"
                      color={activePage === "LIKES" ? "initial" : "primary"}
                      variant="h4"
                    >
                      {myActivities?.totalLikes}
                    </Typography>
                    <Typography
                      align="center"
                      color={activePage === "LIKES" ? "initial" : "primary"}
                      variant="body1"
                    >
                      <Resource
                        tag="PageDetailIdea::PrivateCommentsArea_LikesNumber"
                        args={[""]}
                      />
                    </Typography>
                  </Paper>
                </Grid>
              </>
              : ""
            }
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Paper
                elevation={3}
                onClick={() => setPage("STARS")}
                className={`${classes.paper} + ${activePage === "STARS" ? classes.activePage : ""
                  }`}
              >
                <Typography
                  align="center"
                  color={activePage === "STARS" ? "initial" : "primary"}
                  variant="h4"
                >
                  {myActivities?.totalStarRating}
                </Typography>
                <Typography
                  align="center"
                  color={activePage === "STARS" ? "initial" : "primary"}
                  variant="body1"
                >
                  <Resource
                    tag="PageMyActivity::StarRatingNumber"
                    args={[""]}
                  />
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <Paper
                elevation={3}
                onClick={() => setPage("INVESTMENTS")}
                className={`${classes.paper} + ${activePage === "INVESTMENTS" ? classes.activePage : "primary"
                  }`}
              >
                <Typography
                  align="center"
                  color={activePage === "INVESTMENTS" ? "initial" : "primary"}
                  variant="h4"
                >
                  {myActivities?.totalInvestments}
                </Typography>
                <Typography
                  align="center"
                  color={activePage === "INVESTMENTS" ? "initial" : "primary"}
                  variant="body1"
                >
                  <Resource tag="PageWallet::InvestmentTabTitle" />
                </Typography>
              </Paper>
            </Grid>
            {activePage === "IDEAS" && (
              <Fade in={activePage === "IDEAS"}>
                <Grid
                  className={classes.content}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {myActivitiesIdeasImplemented?.result ? (
                    <Box className="ideas-list-activity" mb={10}>
                      <ValuableCarousel
                        ideasMyActivities={myActivitiesIdeasImplemented}
                        valuableTag={"PageMyActivity::ValuableIdeas"}
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                  {myActivitiesIdeasInProgress?.result ? (
                    <Box className="ideas-list-activity" mb={10}>
                      <Title title={inProgressTitle} />
                      <DynamicInfiniteScroll
                        dataList={myActivitiesIdeasInProgress}
                        getAction={(pageNumber, pageSize) =>
                          getAllMyActivitiesIdeasInProgress({
                            pageNumber,
                            pageSize,
                            isInfinite: true,
                          })
                        }
                        pageSize={6}
                      //isAutomatic
                      >
                        <MyActivitiesInProgressList
                          myActivitiesInProgress={myActivitiesIdeasInProgress}
                        />
                      </DynamicInfiniteScroll>
                    </Box>
                  ) : (
                    ""
                  )}
                  {myActivitiesIdeasClosed?.result ? (
                    <Box className="ideas-list-activity">
                      <Title title={notProgressedTitle} />
                      <DynamicInfiniteScroll
                        dataList={myActivitiesIdeasClosed}
                        getAction={(pageNumber, pageSize) =>
                          getAllMyActivitiesIdeasClosed({
                            pageNumber,
                            pageSize,
                            isInfinite: true,
                          })
                        }
                        pageSize={6}
                      //isAutomatic
                      >
                        <MyActivitiesNotProgressList
                          myActivitiesClosed={myActivitiesIdeasClosed}
                        />
                      </DynamicInfiniteScroll>
                    </Box>
                  ) : (
                    ""
                  )}
                  {!myActivitiesIdeasClosed?.result &&
                    !myActivitiesIdeasInProgress?.result &&
                    !myActivitiesIdeasImplemented?.result && (
                      <div
                        className="MyActivity-error"
                        style={{ textAlign: "center" }}
                      >
                        <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
                      </div>
                    )}
                </Grid>
              </Fade>
            )}
            {activePage === "COMMENTS" && (
              <Fade in={activePage === "COMMENTS"}>
                <Grid
                  className={classes.content}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {publicComments?.result && privateComments?.result ? (
                    <CommentsTab
                      publicComments={publicComments}
                      privateComments={privateComments}
                      cultureTag={culture.culture.cultureTag}
                      showMore={showMore}
                    />
                  ) : (
                    <>
                      {publicComments?.result ? (
                        <CommentsTab
                          publicComments={publicComments}
                          privateComments={privateComments}
                          cultureTag={culture.culture.cultureTag}
                          showMore={showMore}
                        />
                      ) : (
                        <>
                          {privateComments?.result ? (
                            <CommentsTab
                              publicComments={publicComments}
                              privateComments={privateComments}
                              cultureTag={culture.culture.cultureTag}
                              showMore={showMore}
                            />
                          ) : (
                            <div
                              className="MyActivity-error"
                              style={{ textAlign: "center" }}
                            >
                              <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Fade>
            )}
            {activePage === "PRIZES" && (
              <Fade in={activePage === "PRIZES"}>
                <MyActivityPrizes
                  // classes={classes}
                  permission={permission}
                  myActivitiesPrizesActiveBid={myActivitiesPrizesActiveBid}
                  myActivitiesPrizesLost={myActivitiesPrizesLost}
                  myActivitiesPrizesWon={myActivitiesPrizesWon}
                  postBidPrize={requestBidPrize}
                  requestManagePrize={requestManagePrize}
                  profile={profile}
                  points={points}
                />
              </Fade>
            )}
            {activePage === "LIKES" && (
              <Fade in={activePage === "LIKES"}>
                <Grid
                  className={classes.content}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {myActivitiesLikes?.result ? (
                    <DynamicInfiniteScroll
                      dataList={myActivitiesLikes}
                      getAction={(pageNumber, pageSize) =>
                        getAllMyActivitiesLikes({
                          pageNumber,
                          pageSize,
                          isInfinite: true,
                        })
                      }
                      pageSize={6}
                    //isAutomatic
                    >
                      <MyActivitiesLikesList
                        myActivitiesLikes={myActivitiesLikes}
                      />
                    </DynamicInfiniteScroll>
                  ) : (
                    <div
                      className="MyActivity-error"
                      style={{ textAlign: "center" }}
                    >
                      <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
                    </div>
                  )}
                </Grid>
              </Fade>
            )}
            {activePage === "STARS" && (
              <Fade in={activePage === "STARS"}>
                <Grid
                  className={classes.content}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {arrStarsCollaborative?.result && arrStarsPrivate?.result ? (
                    <MyActivityStarListTabs
                      starCollaborative={arrStarsCollaborative}
                      starPrivate={arrStarsPrivate}
                      showMore={showMore}
                    />
                  ) : (
                    <>
                      {arrStarsCollaborative?.result ? (
                        <MyActivityStarListTabs
                          starCollaborative={arrStarsCollaborative}
                          starPrivate={arrStarsPrivate}
                          showMore={showMore}
                        />
                      ) : (
                        <>
                          {arrStarsPrivate?.result ? (
                            <MyActivityStarListTabs
                              starCollaborative={arrStarsCollaborative}
                              starPrivate={arrStarsPrivate}
                              showMore={showMore}
                            />
                          ) : (
                            <div
                              className="MyActivity-error"
                              style={{ textAlign: "center" }}
                            >
                              <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Fade>
            )}
            {activePage === "INVESTMENTS" && (
              <Fade in={activePage === "INVESTMENTS"}>
                <Grid
                  className={classes.content}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  {myActivitiesInvestmentsActive?.result &&
                    myActivitiesInvestmentsHistory?.result ? (
                    <MyActivitiesInvestList
                      investmentMeta={activeInvestmentsMeta}
                      activeInvestments={myActivitiesInvestmentsActive}
                      investmentsHistory={myActivitiesInvestmentsHistory}
                      showMore={showMore}
                    />
                  ) : (
                    <>
                      {myActivitiesInvestmentsActive?.result ? (
                        <MyActivitiesInvestList
                          investmentMeta={activeInvestmentsMeta}
                          activeInvestments={myActivitiesInvestmentsActive}
                          investmentsHistory={myActivitiesInvestmentsHistory}
                          showMore={showMore}
                        />
                      ) : (
                        <>
                          {myActivitiesInvestmentsHistory?.result ? (
                            <MyActivitiesInvestList
                              investmentMeta={activeInvestmentsMeta}
                              investmentsHistory={
                                myActivitiesInvestmentsHistory
                              }
                              showMore={showMore}
                            />
                          ) : (
                            <div
                              className="MyActivity-error"
                              style={{ textAlign: "center" }}
                            >
                              <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Fade>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
}
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IProps => {
  return {
    getAllMyActivitiesIdeasInProgress: (parameters: object) =>
      dispatch(getAllMyActivitiesIdeasInProgress(parameters)),
    getAllMyActivitiesLikes: (parameters: object) =>
      dispatch(getAllMyActivitiesLikes(parameters)),
    getAllMyActivitiesStarCollaborative: (parameters: object) =>
      dispatch(getAllMyActivitiesStarCollaborative(parameters)),
    getAllMyActivitiesStarPrivate: (parameters: object) =>
      dispatch(getAllMyActivitiesStarPrivate(parameters)),
    getAllMyActivitiesInvestmentActive: (parameters: object) =>
      dispatch(getAllMyActivitiesInvestmentActive(parameters)),
    getAllMyActivitiesInvestmentHistory: (parameters: object) =>
      dispatch(getAllMyActivitiesInvestmentHistory(parameters)),
    getPublicComments: (parameters: object) =>
      dispatch(getPublicComments(parameters)),
    getPrivateComments: (parameters: object) =>
      dispatch(getPrivateComments(parameters)),
    getAllMyActivitiesPrizesActivieBid: (parameters: object) =>
      dispatch(getAllMyActivitiesPrizesActivieBid(parameters)),
    getAllMyActivitiesPrizesWon: (parameters: object) =>
      dispatch(getAllMyActivitiesPrizesWon(parameters)),
    getAllMyActivitiesPrizesLost: (parameters: object) =>
      dispatch(getAllMyActivitiesPrizesLost(parameters)),
    getAllMyActivitiesIdeasImplemented: (parameters: object) =>
      dispatch(getAllMyActivitiesIdeasImplemented(parameters)),
    getAllMyActivitiesIdeasClosed: (parameters: object) =>
      dispatch(getAllMyActivitiesIdeasClosed(parameters)),
    getAllMyActivities: (parameters: object) =>
      dispatch(getAllMyActivities(parameters)),
    setActivePage: (page: string) => dispatch(setActivePage(page)),
    getProfile: (options: object) => dispatch(getProfile(options)),
    duplicatePrize: (options: object) => dispatch(duplicatePrize(options)),
    deletePrize: (options: object) => dispatch(deletePrize(options)),
    closeAuctionPrize: (options: object) => dispatch(closeAuctionPrize(options)),
    postBidPrize: (options: object) => dispatch(postPrizeBid(options)),
  };
};
const mapStateToProps = (store: IAppState) => {
  return {
    arrStarsCollaborative:
      store.myActivitiesState.myActivitiesStarsCollaborative,
    arrStarsPrivate: store.myActivitiesState.myActivitiesStarsPrivate,
    myActivitiesLikes: store.myActivitiesState.myActivitiesLikes,
    myActivitiesInvestmentsActive:
      store.myActivitiesState.myActivitiesInvestmentsActive,
    activeInvestmentsMeta:
      store.myActivitiesState.myActivitiesInvestmentsActive.meta,
    myActivitiesInvestmentsHistory:
      store.myActivitiesState.myActivitiesInvestmentsHistory,
    publicComments: store.myActivitiesState.publicComments,
    privateComments: store.myActivitiesState.privateComments,
    myActivitiesPrizesActiveBid:
      store.myActivitiesState.myActivitiesPrizesActiveBid,
    myActivitiesPrizesWon: store.myActivitiesState.myActivitiesPrizesWon,
    myActivitiesPrizesLost: store.myActivitiesState.myActivitiesPrizesLost,
    myActivitiesIdeasImplemented:
      store.myActivitiesState.myActivitiesIdeasImplemented,
    myActivitiesIdeasClosed: store.myActivitiesState.myActivitiesIdeasClosed,
    myActivitiesIdeasInProgress:
      store.myActivitiesState.myActivitiesIdeasInProgress,
    applicationId: store.authState.auth.applicationId,
    culture: store.cultureState,
    myActivities: store.myActivitiesState.myActivities,
    activePage: store.myActivitiesState.activePageMyActivities,
    permission: store.authState.auth.permission,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyActivity);
