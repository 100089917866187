import {
  Box,
  createStyles,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  NativeSelect,
  Paper,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import Resource, { resource } from "../Resources/Resource";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import { IFilters, IKeyTasksState } from "redux/reducers/keyTasks/keyTasksReducer";

const options = {
  pageNumber: 1,
  pageSize: 4,
  searchTerm: '',
  challengeId: '00000000-0000-0000-0000-000000000000',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperP: {
      padding: "20px 30px",
      background: "#F2F2F2",
    },
    paperSearch: {
      borderRadius: 22,
      padding: "3px 10px 2px 14px",
      display: 'flex'
    },
    iconButton: {
      padding: 0,
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    inputLabel: {
      width: "max-content",
      textTransform: "uppercase",
    },
  })
);

const SearchInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "0.7rem",
      padding: "0 0 0",
    },
  })
)(InputBase);

const SelectInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "& .MuiNativeSelect-icon": {
        color: theme.palette.primary.main,
      },
    },
    input: {
      borderRadius: 22,
      backgroundColor: theme.palette.background.paper,
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "0.8rem",
      padding: "7px 26px 7px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 22,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.1rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

interface IProps {
  culture?: ICultureState;
  filters: IFilters
  getValidationKeyTasks(options: object): Promise<IKeyTasksState>;
}

function ValidationFilter(props: IProps): JSX.Element {
  const classes = useStyles();
  const { filters, getValidationKeyTasks } = props

  const filterData = (e: any) => {
    e.preventDefault()
    switch (e.target.id) {
      case 'type-task-challenge-validation':
        options.challengeId = e.target.value
        break;
      case 'task-type-search-input-validation':
        e.preventDefault()
        options.searchTerm = e.target.value
        break;
      default:
        break;
    }
    getValidationKeyTasks(options)
  }
  let keyTimer: any
  let waitTimeAfterType = 500
  const handlerKeyUp = ((e: any) => {
    let text = e.value;
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      search(text)
    }, waitTimeAfterType);
  });
  const handlerKeydDown = (() => {
    clearTimeout(keyTimer);
  });

  const search = (text: string) => {
    if (text.length >= 3) {
      options.searchTerm = text
      getValidationKeyTasks(options)
    }
    else {
      options.searchTerm = ''
      getValidationKeyTasks(options)
    }
  }
  return (
    <Box marginY={2} width="100%">
      <Paper elevation={0} className={classes.paperP}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <FormControl fullWidth onSubmit={(e) => e.preventDefault()}>
              <InputLabel
                htmlFor="type-task-challenge-validation"
                shrink={true}
                className={classes.inputLabel}
              >
                <Resource tag={"Common::Challenge"} />
              </InputLabel>
              <NativeSelect id="type-task-challenge-validation" onChange={filterData} input={<SelectInput />}>
                {filters?.challenges && filters?.challenges?.map((data, index) => {
                  return (
                    <option key={index} value={data.challengeId}>{data.name === 'PageKeyTasks.TaskType_Default' ? resource(props?.culture, "Common::All") : data.name}</option>
                  )
                })}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={4}>
            <Paper
              component="form"
              elevation={0}
              onSubmit={(e) => e.preventDefault()}
              className={`${classes.paperSearch} + ${classes.withoutLabel}`}
            >
              <SearchInput
                placeholder={resource(
                  props.culture,
                  "SearchFilter::SearchByIdeaTitle"
                )}
                inputMode="search"
                id="task-type-search-input-validation"
                onKeyUp={(e) => { handlerKeyUp(e.target) }}
                onKeyDown={handlerKeydDown}
                fullWidth
              />
                <SearchIcon className={classes.iconButton} />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default ValidationFilter;
