import newApp from 'utils/reactNewApp';

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------
const CURRENT_URL = process.env["REACT_APP_API_URL"];
export const API_URL = CURRENT_URL + `api/v2/`;
export const API_GENERAL_URL = newApp ? process.env["REACT_APP_API_NEW_PRODUCT"] + "api/v3" : process.env["REACT_APP_API_GENERAL"] + "api/v2";
export const API_TENANT_CONFIG_URL = process.env["REACT_APP_API_GENERAL"] + "api/v2";
export const PUBLIC_URL = process.env["PUBLIC_URL"]

// -----------------------------------------------------------------------------
// Errors
// -----------------------------------------------------------------------------
export const API_ERRO_TYPE_VALIDATION   = "validation";
export const API_ERRO_TYPE_API          = "api";
export const API_ERRO_TYPE_SERVER       = "server";
export const API_ERRO_TYPE_CONNECTION   = "connection";
export const API_ERRO_TYPE_OTHER        = "other";
export const API_ERRO_TYPE_ACCESS_TOKEN = "access_token";
export const API_ERRO_TYPE_CANCEL       = "cancel";

// -----------------------------------------------------------------------------
// LANGUAGE
// -----------------------------------------------------------------------------
export const DEFAULT_LANGUAGE_CODE = 'en-GB';
export const AVAILABLE_LANGUAGE_CODES = ["es-ES", "en-GB", "pt-BR", "pt-PT", "ro-RO"];