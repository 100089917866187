import React, { useState } from "react";
//import Tags from '../Tags/Tags';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Hidden,
  createStyles,
  makeStyles,
  Theme,
  Divider,
  Box,
  useTheme,
  Button,
} from "@material-ui/core";
import Resource from "../Resources/Resource";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { IRelatedContentResult } from "redux/reducers/relatedContent/RelatedContentReducer";
import TimeFriendly from "components/TimeFriendly/TimeFriendly";
import CommentListRelatedContent from "components/CommentsListRelatedContent/CommentListRelatedContent";
import EMIcon from "components/Icon/Icon";
import sanitizeHtml from "sanitize-html";
import { securityTextEditor } from "utils/securityTextEditor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "mobileCard:lastChild": {
      paddingBottom: "16px !important",
    },
    divider: {
      width: "100%",
      marginTop: "12px 0",
    },
  })
);

interface IProps {
  result: IRelatedContentResult;
}

function RelatedContentItem(props: IProps) {
  const { result } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);
  const [openComments, setCommentsOpen] = useState(false);

  const handleClickComments = () => {
    setCommentsOpen((prev) => !prev);
  };
  const cardWeb = (
   
      <Card key={result?.objectId} style={{ width: "100%" }} elevation={3}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box mt={1} mr={0.5} mb={1} textAlign="right">
                <Typography variant="body2" color="textSecondary">
                  <Resource
                    tag="PageChallenges::Inspiration_PublishedOn"
                    args={[" "]}
                  />
                  <TimeFriendly time={result.publishedDate} type={2} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box ml={0.5}>
                <Typography variant="h4">{result.title}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Box
                mt={1.5}
                ml={0.5}
                mr={0.5}
                mb={2}
                style={{ overflow: "hidden" }}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(result?.description, securityTextEditor),
                }}
              />
            </Grid>
          </Grid>

          {/* <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
          >
            {result?.tags?.length > 0 && <div className="inspiration-line"></div>}
            {result?.tags?.length > 0 &&
              <div className="inspiration-tags">
                <Tags list={result?.tags} />
              </div>
            }
          </Grid> */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" marginTop={2}>
              <Box>
                <Button
                  onClick={() => handleClickComments()}
                  color="primary"
                  startIcon={
                    <EMIcon
                      color={theme.palette.primary.main}
                      icon={"comments-b"}
                      size={16}
                    />
                  }
                >
                  <Resource tag={"PageFeed::Comment"} />
                </Button>
              </Box>
              <Box>
                {result ? (
                  <>
                    {result?.commentsCount > 1 ? (
                      <Box className="counters-texts">
                        {result?.commentsCount === 0 ? (
                          ""
                        ) : (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ textTransform: "lowercase" }}
                          >
                            {result?.commentsCount}{" "}
                            <Resource tag={"PageFeed::Comments"} />
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      <Box className="counters-texts">
                        {result?.commentsCount === 0 ? (
                          ""
                        ) : (
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ textTransform: "lowercase" }}
                          >
                            {result?.commentsCount}{" "}
                            <Resource tag={"PageFeed::Comment"} />
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {openComments ? (
              <Box width="100%" marginTop={2}>
                <Divider className={classes.divider} />
                <Box marginBottom={2}>
                  <CommentListRelatedContent
                    newcomments={result.comments}
                    initialPageSize={4}
                    objectType={result.objectType}
                    ideaId={result.objectId}
                  />
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Grid>
        </CardContent>
      </Card>
  );

  const cardMobile = (
    <Card className="inspirationCard-container mobile" key={result?.objectId}>
      <CardContent
        className={classes["mobileCard:lastChild"] + " inspirationCard-content"}
      >
        <Grid container>
          <Grid item xs={11} md={11} sm={11}>
            <Typography
              variant="subtitle1"
              className="inspirationCard-info mobile"
            >
              <Resource tag="PageChallenges::Inspiration_To" />
            </Typography>
          </Grid>
          <Grid item xs={1} md={1} sm={1}>
            {isOpen === true ? (
              <ExpandLessIcon
                style={{ color: "#333" }}
                onClick={() => setOpen(false)}
              />
            ) : (
              <ExpandMoreIcon
                style={{ color: "#333" }}
                onClick={() => setOpen(true)}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className="inspirationCard-title mobile"
          >
            <Typography>{result?.title}</Typography>
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <Typography
              variant="subtitle1"
              className="inspirationCard-date mobile"
            >
              <span>{result?.publishedDate}</span>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      {isOpen && (
        <>
          <Divider variant="fullWidth" className={classes.divider}></Divider>
          <CardContent className={classes["mobileCard:lastChild"]}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="inspiration-description"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(result?.description, securityTextEditor),
              }}
            />
          </CardContent>
        </>
      )}
    </Card>
  );

  return (
    <>
      <Hidden xsDown>{cardWeb}</Hidden>
      <Hidden smUp>{cardMobile}</Hidden>
    </>
  );
}

export default RelatedContentItem;
