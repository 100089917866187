import React from "react";
import { Box, Fab, Grid, Typography, useTheme } from "@material-ui/core";
import XCard from "common/X/XCard";
import XCarousel from "common/X/XCarousel";
import Resource from "components/Resources/Resource";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import history from "routes/history";

interface IProps {
  items: any;
}

export default function FeedCarouselPrizes(props: IProps) {
  const theme = useTheme();
  return (
    <Box mt={5} mb={5}>
      <Grid container>
        <XCarousel itemsToShow={3}>
          {props?.items?.length > 0 &&
            props?.items?.map((item: any, index: number) => {
              const resource = item?.timeLeftResource?.replace(".", "::");
              return (
                <XCard
                  key={index}
                  color={"dark"}
                  link={`/prizes`}
                  elevation={1}
                  height={200}
                  bottomContentPadding={20}
                  bottomContent={
                    <Grid container>
                      <Typography variant="subtitle2">
                        <Resource tag="PageFeed::Prize_New" />
                      </Typography>
                      <Typography variant="h4">{item?.title}</Typography>
                      <Typography variant="subtitle2">
                        <Resource tag={resource} args={[item?.timeLeftValue]} />
                      </Typography>
                    </Grid>
                  }
                  backgroundImage={item?.image}
                />
              );
            })}
          {props?.items && (
            <Box
              margin="0 10px"
              onClick={() => history?.push("/prizes")}
              color="primary"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              height="100%"
              borderRadius="3%"
              width="50%"
              style={{
                cursor: "pointer",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  color: theme.palette.background.default,
                }}
              >
                <Resource tag="PageFeed::Prize_New" />
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: theme.palette.background.default,
                  marginBottom: theme.spacing(3),
                }}
              >
                <Resource tag="Common::ViewAll" />
              </Typography>
              <Fab
                onClick={() => history?.push("/prizes")}
                color="primary"
                style={{
                  backgroundColor: "rgb(0, 0, 0,50%)",
                }}
                // aria-label="edit"
              >
                <ArrowForwardIcon />
              </Fab>
            </Box>
          )}
        </XCarousel>
      </Grid>
    </Box>
  );
}
