import "./ReviewStars.scss";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { Star } from "@material-ui/icons";
import { ThunkDispatch } from "redux-thunk";
import Rating from "@material-ui/lab/Rating";
import React, { useEffect, useState } from "react";
import { IAppState } from "../../redux/store/Store";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IIdeasState } from "redux/reducers/ideas/ideaReducer";
import Resource, { resource } from "components/Resources/Resource";
import { IAllIdeasState } from "redux/reducers/ideas/AllIdeasReducer";
import { postIdeasStars, putIdeasStars } from "redux/actions/ideas/IdeaAction";
import KeyTaskIdeaDetailEvaluationComments from "components/KeyTaskIdeaDetailEvaluationComments/KeyTaskIdeaDetailEvaluationComments";
import {
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  CircularProgress,
  Theme,
  makeStyles,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconFilled: {
      color: theme.palette.primary.main,
    },
    iconHover: {
      color: theme.palette.primary.dark,
    },
  })
);

interface IProps {
  ideaId?: string;
  starsEvaluation?: any;
  getAllIdeaDetails?: any;
  setSubmitDisabled?: (on: boolean) => void;
  setStarsData?: (obj: any) => void;
  setEvaluated?: any;
  isFeed?: boolean;
  isKeyTasks?: boolean;
  isCollaboration?: boolean;
}

interface IDispatchProps {
  postIdeasStars?: (options: object) => Promise<IIdeasState>;
  putIdeasStars?: (options: object) => Promise<IIdeasState>;
  getAllIdeas?: (options: object) => Promise<IAllIdeasState>;
}

interface IStateProps {
  workspaceId: string;
  applicationId: string;
  culture: ICultureState;
}

function ReviewStars(props: IProps & IDispatchProps & IStateProps) {
  const {
    workspaceId,
    setStarsData,
    setSubmitDisabled,
    setEvaluated,
    isKeyTasks,
    isCollaboration,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalAverage, setTotalAverage] = useState(0);
  const [userAverage, setUserAverage] = useState(0);
  const [
    evaluationUserDimensions,
    setEvaluationUserDimensions,
  ] = useState<object>({});
  const [dimensionsTitle, setDimensionsTitle] = useState<object>({});
  const [comment, setComment] = useState("");
  const [pageNumber] = useState(1);
  const [pageSize] = useState(4);

  const classes = useStyles();
  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTotalAverage(props.starsEvaluation?.starsEvaluation?.average);
    setUserAverage(
      props.starsEvaluation?.starsEvaluation?.evaluationUser?.average
    );
    setEvaluationUserDimensions(
      props.starsEvaluation?.starsEvaluation?.evaluationUser?.dimensions
        ? props.starsEvaluation?.starsEvaluation?.evaluationUser?.dimensions
        : {}
    );
    setDimensionsTitle(props.starsEvaluation?.starsEvaluation?.dimensionsTitle);
    setComment(props.starsEvaluation?.starsEvaluation?.evaluationUser?.comment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.starsEvaluation]);

  const dimensionsTitleArray = Object.entries(dimensionsTitle || {});
  const evaluationUserDimensionsArray = Object.entries(
    evaluationUserDimensions || {}
  ).map(([key, value]) => value);

  const setDimension = (key: any, value: any) => {
    if (value == null) value = 0;
    setEvaluationUserDimensions((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (
      Object.entries(dimensionsTitle || {}).length ===
        evaluationUserDimensionsArray?.length &&
      comment
    ) {
      setSubmitDisabled && setSubmitDisabled(false);
      const ids = Object.values(dimensionsTitle);
      const evaluationUserDimensionsArray = Object.values(
        evaluationUserDimensions
      );
      const evaluationsDimensions = ids.map((value, index) => {
        return {
          id: value.dimensionId,
          value: evaluationUserDimensionsArray[index],
        };
      });

      const options: any = {
        dimensions: evaluationsDimensions,
        comment: comment ? comment : "",
      };
      setStarsData && setStarsData(options);
    } else {
      setSubmitDisabled && setSubmitDisabled(true);
    }
  }, [
    evaluationUserDimensions,
    comment,
    dimensionsTitle,
    evaluationUserDimensionsArray,
    setSubmitDisabled,
    setStarsData,
  ]);

  const confirmEvaluation = () => {
    setLoading(true);

    const ids = Object.values(dimensionsTitle);
    const evaluationUserDimensionsArray = Object.values(
      evaluationUserDimensions
    );
    const evaluationsDimensions = ids.map((value, index) => {
      return {
        id: value.dimensionId,
        value: evaluationUserDimensionsArray[index],
      };
    });
    const options: any = {
      ideaId: props.ideaId,
      dimensions: evaluationsDimensions,
      comment: comment ? comment : "",
      applicationId: props.applicationId,
      isFeed: props.isFeed && props.isFeed,
      culture: props.culture.culture,
      workspaceId: workspaceId,
    };

    const getNewRatingOptions = {
      ideaDetailId: props.ideaId,
    };
    if (props.starsEvaluation.starsEvaluation.evaluationUser) {
      props.putIdeasStars &&
        props.putIdeasStars(options).then(
          (response) => {
            setEvaluated && setEvaluated(true);
            props.getAllIdeaDetails &&
              props.getAllIdeaDetails(getNewRatingOptions);
            setTimeout(() => {
              setLoading(false);
              setEditMode(false);
            }, 1000);
            props.getAllIdeas && props.getAllIdeas({ pageNumber, pageSize });
          },
          (err) => {
            setTimeout(() => {
              setLoading(false);
              setEditMode(false);
            }, 1000);
          }
        );
    } else {
      props.postIdeasStars &&
        props.postIdeasStars(options).then(() => {
          setEvaluated && setEvaluated(true);
          props.getAllIdeaDetails &&
            props.getAllIdeaDetails(getNewRatingOptions);
          setTimeout(() => {
            setLoading(false);
            setEditMode(false);
          }, 1000);
          props.getAllIdeas && props.getAllIdeas({ pageNumber, pageSize });
        });
    }
  };

  const handleChangeComment = (e: any) => {
    const { value } = e.target;
    setComment(value);
  };
  return (
    <main className="component-review-stars">
      <Card variant="outlined">
        {loading ? (
          <CardContent className="loading-review-stars">
            <CircularProgress />
          </CardContent>
        ) : dimensionsTitle ? (
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs>
                <div className="title">
                  <Resource tag="PageIdeaDetail::ReviewRating_StarsEvaluation" />
                </div>
              </Grid>
              <Grid item xs>
                <div className="average-rating">
                  <Star />
                  <em>{totalAverage}</em>
                  <Resource tag="PageDetailIdea::ReviewRatingAreaEdit_AverageRating" />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              {!editMode && (
                <>
                  <Grid item xs className="stars">
                    <Rating
                      classes={{
                        iconFilled: classes.iconFilled,
                        iconHover: classes.iconHover,
                      }}
                      value={userAverage}
                      name="simple-controlled"
                      className="secondary"
                      precision={0.5}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs className="evaluate">
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      disabled={
                        !props?.starsEvaluation?.starsEvaluation
                          ?.isAllowedEvaluate
                      }
                      onClick={() => setEditMode(true)}
                    >
                      {evaluationUserDimensionsArray.length > 0 ? (
                        <Resource tag="PageDetailIdea::EvaluationArea_ButtonEdit" />
                      ) : (
                        <Resource tag="PageDetailIdea::EvaluationArea_ButtonEvaluate" />
                      )}
                    </Button>
                  </Grid>
                </>
              )}
              {editMode && (
                <>
                  <Grid container className="stars">
                    <div className="dimensions-title">
                      {dimensionsTitleArray.length > 1 ? (
                        <Resource
                          tag="PageDetailIdea::EvaluationArea_Dimensions"
                          args={[dimensionsTitleArray.length]}
                        />
                      ) : (
                        <Resource
                          tag="PageDetailIdea::EvaluationArea_Dimension"
                          args={[dimensionsTitleArray.length]}
                        />
                      )}
                    </div>
                    {Object.values(dimensionsTitle).map((dimension, index) => {
                      return (
                        <div key={index} className="dimension">
                          <div className="dimension-title">
                            {dimension.dimensionTile}
                          </div>
                          <Grid container>
                            <Grid item xs className="stars">
                              <Rating
                                classes={{
                                  iconFilled: classes.iconFilled,
                                  iconHover: classes.iconHover,
                                }}
                                onChange={(event, value) =>
                                  setDimension(dimension.dimensionId, value)
                                }
                                value={evaluationUserDimensionsArray[index]}
                                name={`dim-${index}`}
                              />
                            </Grid>
                            <Grid item xs className="stars-average">
                              {evaluationUserDimensionsArray[index]}
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </Grid>
                  {isCollaboration ? (
                    ""
                  ) : (
                    <Grid container className="comment">
                      <div className="comment-title">
                        <Resource tag="ModalKeyTasks::WriteAComment" />
                      </div>
                      <TextField
                        placeholder={resource(
                          props.culture,
                          "PageDetailIdea::ReviewRatingArea_PleaseElaborate"
                        )}
                        multiline
                        value={comment}
                        onChange={handleChangeComment}
                      />
                    </Grid>
                  )}
                  {!props.setSubmitDisabled && (
                    <Grid container className="evaluate">
                      <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={() => confirmEvaluation()}
                      >
                        <Resource tag="Common::Confirm" />
                      </Button>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </CardContent>
        ) : (
          <CardContent>
            <Resource tag="PageWallet::NoDataAvailable" />
          </CardContent>
        )}
      </Card>
      {props?.starsEvaluation?.starsEvaluation?.comments &&
        !isKeyTasks &&
        !isCollaboration && (
          <KeyTaskIdeaDetailEvaluationComments
            detailPage
            fullWidth
            evaluate={props?.starsEvaluation?.type === "Stars"}
            evaluation={props?.starsEvaluation}
            comments={props?.starsEvaluation?.starsEvaluation?.comments}
          />
        )}
    </main>
  );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    postIdeasStars: (options: object) => dispatch(postIdeasStars(options)),
    putIdeasStars: (options: object) => dispatch(putIdeasStars(options)),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    applicationId: store.authState.auth.applicationId,
    culture: store.cultureState,
    workspaceId: store.authState.auth.workspaceId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewStars);
