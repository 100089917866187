/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";
import { Box } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { ptBR, enGB, es, ro, pt } from "date-fns/locale";

interface IProps {
  date: any;
  cultureTag?: string;
}

// return: dd/mm/yyyy
export function ShortDateFormat({ date }: IProps): JSX.Element {
  return (
    <Box>
      {new Date(date).getDate() < 10 && "0"}
      {new Date(date).getDate()}/{new Date(date).getMonth() + 1 < 10 && "0"}
      {new Date(date).getMonth() + 1}/{new Date(date).getFullYear()}
    </Box>
  );
}

// return: dd month yyyy
export function LongDateFormat({ date, cultureTag }: IProps): JSX.Element {
  let locale = cultureTag !== undefined ? cultureTag : "";
  const newCulture = locale?.replace("-", "");
  let cult;

  if (newCulture === "ptBR") {
    cult = ptBR;
  } else if (newCulture === "enGB") {
    cult = enGB;
  } else if (newCulture === "esES") {
    cult = es;
  } else if (newCulture === "roRO") {
    cult = ro;
  } else if (newCulture === "ptPT") {
    cult = pt;
  }

  const dateISO = parseISO(date);
  const formatteDate = format(dateISO, `dd MMMM yyyy`, { locale: cult });
  return <Box>{formatteDate}</Box>;
}
