import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";

// Redux
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IApplication } from "redux/reducers/tenantReducer";
import { IChallengeList, IChallengeListItem } from "redux/types/challenges";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IAppState } from "redux/store/Store";
import {
  getNewChallengeList,
  getAllChallengesInspiration,
  IChallengeGetNewListAction,
} from "redux/actions/ChallengeActions";
import history from "../../routes/history";

// Style
import "./ModalSelectAChallenge.css";

import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Grid,
  Divider,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    gridItem: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "flex-end",
    },
    label: {
      margin: "5px 0",
      padding: "0.7rem 0.7rem",
    },
    bgLabel: {
      background: theme.palette.primary.light,
      padding: "0.7rem 0.7rem",
      borderRadius: "6px",
      margin: "5px 0",
      marginRight: "20px",
    },
    divider: {
      width: "90%",
      marginTop: "12px 0",
      marginLeft: "10px",
    },
    arrowForward: {
      color: "#fff",
      marginLeft: "20px",
    },
    arrowForwardDisabled: {
      color: "rgba(0, 0, 0, 0.26)",
      marginLeft: "20px",
    },
    gridContainer: {
      overflow: "scroll",
      maxHeight: "325px",
    },
    titleIdea: {
      color: "#313b53",
      fontSize: "15px",
    },
    link: {
      textDecoration: "none",
    },
  })
);

const pagination = {
  current: 1,
  pageSize: 10,
  finished: false,
};

const status = {
  init: true,
  refreshControl: false,
  isActive: true,
};

const options = {
  applicationId: 2,
  isActive: status.isActive,
  pageNumber: status.init || !status.isActive ? 1 : pagination.current + 1,
  pageSize: pagination.pageSize,
  culture: {},
};

interface IDispatchProps {
  getNewChallengeList: (options: object) => Promise<IChallengeGetNewListAction>;
  getAllChallengesInspiration: (options: object) => Promise<any>;
}

interface IProps {
  path: string;
  closeButton?: any;
  selectedChallenge?: string;
  challengeList: IChallengeList;
  application: IApplication;
  culture: ICultureState;
  setChallengeId?: (challenge: any) => void;
}

function ModalSelectAChallenge(props: IProps & IDispatchProps) {
  const { getNewChallengeList, getAllChallengesInspiration } = props;
  const { path, selectedChallenge, setChallengeId } = props;
  const { challengeList, closeButton } = props;
  const [value, setValue] = useState("");
  const [challenge, setChallenge] = useState<IChallengeListItem>();
  const classes = useStyles();
  const [isInspiration, setIsInspiration] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    options.applicationId = props.application.applicationId;
    options.culture = props.culture;
    let isMounted = true;

    if (path === "/createIdea") {
      if (selectedChallenge != null) {
        isMounted && setValue(selectedChallenge);
        const [challenge] = challengeList?.result?.filter(
          (x) => x.challengeId === selectedChallenge
        );
        isMounted && setChallenge(challenge);
      }
    } else if (path === "/createInspiration") {
      setIsInspiration(true);
      if (selectedChallenge != null) {
        isMounted && setValue(selectedChallenge);
        const [challenge] = challengeList?.result?.filter(
          (x) => x.challengeId === selectedChallenge
        );
        isMounted && setChallenge(challenge);
      }
    }
    path === "/createInspiration" ?
      isMounted && getAllChallengesInspiration(options).then((result) => {
        setLoading(false)
      }) :
      isMounted && getNewChallengeList(options).then((result) => {
        setLoading(false)
      })
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlevalueChange = (e: any) => {
    const { value } = e.target;

    const [challenge] = challengeList?.result?.filter(
      (x) => x.challengeId === value
    );
    setValue(value);
    setChallenge(challenge);
  };

  return (
    <Box>
      <MuiDialogContent className="ModalSelectAChallenge-body">
        {
          loading ? <Box display="flex" alignItems="center" justifyContent="center" textAlign="center"><CircularProgress /></Box>
            :
            <FormControl component="fieldset">
              <RadioGroup
                // aria-label="challenge"
                value={value}
                onChange={handlevalueChange}
              >
                <DynamicInfiniteScroll
                  dataList={challengeList} 
                  getAction={(pageNumber,pageSize) => isInspiration ? getAllChallengesInspiration({pageNumber, pageSize, isInfinite: true}) : getNewChallengeList({pageNumber, pageSize, isInfinite: true})}
                  pageSize={10}
                  isAutomatic
                >
                  <Grid container className={classes.gridContainer}>
                    {challengeList &&
                    challengeList?.result.map((x, index) => (
                      <Grid
                        item
                        xs={challengeList.meta.total > 1 ? 6 : 12}
                        className={classes.gridItem}
                        key={index}
                      >
                        <FormControlLabel
                          className={
                            x.challengeId === value
                              ? `${classes.bgLabel} ${"AutoTest_SelectAChallenge"}`
                              : `${classes.label} ${"AutoTest_SelectAChallenge"}`
                          }
                          id={x.challengeId}
                          value={x.challengeId}
                          control={<Radio color="primary" />}
                          checked={x.challengeId === value}
                          label={
                            <Typography
                              variant="body2"
                              className={classes.titleIdea}
                            >
                              {x.title}
                            </Typography>
                          }
                        />
                        <Divider variant="fullWidth" className={classes.divider} />
                      </Grid>
                    ))}
                  </Grid>
                </DynamicInfiniteScroll>
              </RadioGroup>
            </FormControl>
        }

      </MuiDialogContent>
      <MuiDialogActions>
        <Box p={2} textAlign="center">
          {setChallengeId ? (
            <Button
              onClick={() => setChallengeId(challenge)}
              disabled={!value}
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
            >
              <Box ml={6} mr={3}>
                <Resource tag={"PageNewIdea::Challenge_ButtonKeepCreating"} />
              </Box>
            </Button>
          ) : (
            <>
              {!isInspiration ? (
                <Button
                  id="SelectAChallenge_Button"
                  onClick={() => {
                    history.push(`/createIdea/${challenge?.challengeId}`);
                    closeButton();
                  }}
                  variant="contained"
                  color="primary"
                  disabled={!value}
                  endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
                >
                  <Box ml={6} mr={3}>
                    <Resource
                      tag={"PageNewIdea::Challenge_ButtonKeepCreating"}
                    />
                  </Box>
                </Button>
              ) : (
                <Button
                  id="SelectAChallenge_Button"
                  onClick={() => {
                    history.push({
                      pathname: `/createInspiration/${challenge?.challengeId}`,
                      state: challenge?.challengeId,
                    });
                    closeButton();
                  }}
                  variant="contained"
                  color="primary"
                  disabled={!value}
                  endIcon={<ArrowForwardIosRoundedIcon fontSize="small" />}
                >
                  <Box ml={6} mr={3}>
                    <Resource
                      tag={
                        "PageCreateInspiration::Inspiration_ButtonKeepCreating"
                      }
                    />
                  </Box>
                </Button>
              )}
            </>
          )}
        </Box>
      </MuiDialogActions>
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    challengeList: store.challengeState.challengeList,
    application: store.tenantState.application,
    culture: store.cultureState,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getNewChallengeList: (parameters: object) =>
      dispatch(getNewChallengeList(parameters)),

    getAllChallengesInspiration: (parameters: object) =>
      dispatch(getAllChallengesInspiration(parameters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSelectAChallenge);
