import Chart from "react-apexcharts";
import { Grid } from "@material-ui/core";
import PortfolioCarousel from "components/PortfolioCarousel/PortfolioCarousel";
import Resource, { resource } from "components/Resources/Resource";

export interface IProps {
    partic: number;
    visit: number;
    visitors: number;
    participants: number;
    culture: object;
  }

const ImageChart = (props: IProps) => {
    const options2 = {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                show: true,
                label: "total"
              }
            }
          }
        },
        labels: [resource(props.culture,"PageDashboardEngagement::Visitors"), resource(props.culture, "PageDashboardEngagement::Participants")]
      };
    return(
        <Grid container justify="center" alignContent="center">
            <Grid item xs={12}>
                <PortfolioCarousel
                    portfolioTag={"PageDashboardEngagement::Kpi"}
                />
            </Grid>
            <Grid item xs={4} style={{marginTop:"auto", textAlign:"center"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg><br />
                <strong><Resource tag={"PageDashboardEngagement::Visitors"} /></strong><br></br>
                <strong>{props.visit}</strong>
            </Grid>    
            <Grid item xs={4} style={{marginTop:"auto", textAlign:"center", borderLeft:"solid 1px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg><br />
                <strong><Resource  tag={"PageDashboardEngagement::Participants"} /></strong><br />
                <strong>{props.partic}</strong>
            </Grid>
            <Grid item xs={4} style={{textAlign:"center", borderLeft:"solid 1px"}}>
                <Chart style={{marginLeft:"40px"}}
                    options={options2}
                    series={[props.visitors, props.participants]}
                    type="radialBar"
                    width="80%"
                />
                <strong><Resource tag={"PageDashboardEngagement::Percentage"} /></strong>
                {console.log(<Resource tag={"PageDashboardEngagement::Visitors"} />)}
            </Grid>
        </Grid>
    )
}


export default ImageChart;