import { AnyAction } from "redux";
import { connect } from "react-redux";
import newApp from "utils/reactNewApp";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { useState, useEffect } from "react";
import NewsCard from "components/NewsCard/NewsCard";
import NewsList from "containers/NewsList/NewsList";
import Resource from "components/Resources/Resource";
import imagemNotChallenges from "assets/img/empty@3x.png";
import SearchNews from "components/SearchNews/SearchNews";
import { ICultureState } from "redux/reducers/cultureReducer";
import NewsCardSkeleton from "components/NewsCard/NewsCardSkeleton";
import { IRelatedNews } from "redux/reducers/news/newsDetailReducer";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import NewsHighlightedCard from "components/NewsHighlighted/NewsHighlightedCard";
import {
  Grid,
  makeStyles,
  createStyles,
  Box,
  Button,
  Typography,
  Theme,
} from "@material-ui/core";
import {
  INewsHighlightedGetAction,
  INewsPopularGetAction,
  INewsAction,
  INewsGetTopicsAction,
  INews,
  INewsHighlighted,
  INewsPopular,
  INewsState,
  IResultNews,
} from "redux/types/news";
import {
  getNews,
  getNewsTopics,
  getNewsHighlighted,
  getNewsPopular,
  deleteNew,
  highlightNew,
  getFutureNews,
  updateNews,
} from "redux/actions/news/NewsActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 25,
    },
    futureNewsContainer: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 11,
    },
  })
);

const options = {
  applicationId: 2,
  isOrderByPublicationDate: true,
  topic: "",
  isFavorite: false,
  q: "",
  pageNumber: 1,
  pageSize: 9,
  culture: {} as ICultureState,
};

interface IOptions {
  applicationId: number;
  isOrderByPublicationDate: boolean;
  topic: string;
  isFavorite: boolean;
  q: string;
  pageNumber: number;
  pageSize: number;
  culture: ICultureState;
  workspaceId?: string;
}

interface IProps {
  news: INews;
  topics: [];
  newsHighlighted: INewsHighlighted;
  newsPopular: INewsPopular;
  getAllNews: (options: IOptions) => Promise<INewsAction>;
  getNewsTopics: (options: IOptions) => Promise<INewsGetTopicsAction>;
  getNewsHighlighted: (options: IOptions) => Promise<INewsHighlightedGetAction>;
  getNewsPopular: (options: IOptions) => Promise<INewsPopularGetAction>;
  deleteNew: (options: object) => Promise<INewsState>;
  highlightNew: (options: object) => Promise<INewsState>;
  getFutureNews: (options: object) => Promise<INewsState>;
  updateNews: (options: object) => Promise<INewsState>;
  culture: ICultureState;
  workspaceId: string;
  permission: string;
  futureNews: Partial<IResultNews>[];
  futureNewsStatus: string;
}

function News(props: IProps & IOptions) {
  const classes = useStyles();
  const [applicationId] = useState(options.applicationId);
  const [isOrderByPublicationDate] = useState(options.isOrderByPublicationDate);
  const [topic, setTopic] = useState(options.topic);
  const [isFavorite] = useState(options.isFavorite);
  const [q, setQ] = useState(options.q);
  const [pageNumber] = useState(options.pageNumber);
  const [pageSize] = useState(options.pageSize);
  const [viewAll, setView] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const {
    getAllNews,
    getNewsTopics,
    getNewsHighlighted,
    getNewsPopular,
    deleteNew,
    highlightNew,
    getFutureNews,
    updateNews,
    topics,
    newsHighlighted,
    newsPopular,
    news,
    workspaceId,
    permission,
    futureNews,
    futureNewsStatus,
  } = props;

  const handleDelete = (newsId: string) => {
    deleteNew({
      newsId,
      workspaceId,
    }).then(() => {
      Promise.all([
        getFutureNews({
          ...options,
          workspaceId,
        }),
        getAllNews({
          ...options,
          workspaceId,
        }),
      ]);
    });
  };

  const handleHighlight = (newsId: string, isHighlighted: boolean) => {
    highlightNew({
      newsId,
      workspaceId,
      isHighlighted,
    });
  };

  const handleReschedule = (
    publicationDate: Date,
    data: Partial<IResultNews> | Partial<IRelatedNews>
  ) => {
    updateNews({
      ...data,
      publicationDate,
      workspaceId,
    }).then(() => {
      Promise.all([
        getFutureNews({
          ...options,
          workspaceId,
        }),
        getAllNews({
          ...options,
          workspaceId,
        }),
      ]);
    });
  };

  useEffect(() => {
    let isMounted = true;
    options.culture = props.culture;

    isMounted &&
      Promise.all([
        getAllNews({
          ...options,
          workspaceId,
        }),
        getNewsTopics({
          ...options,
          workspaceId,
        }),
        getNewsHighlighted({
          ...options,
          workspaceId,
        }),
        getNewsPopular({
          ...options,
          workspaceId,
        }),
      ]);

    isMounted &&
      (permission === "Admin" || permission === "ChallengeManager") &&
      Promise.all([
        getFutureNews({
          ...options,
          workspaceId,
        }),
      ]);

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSearchQueryChange(e: any) {
    const { value } = e.target;
    setQ(value);
    setSearchValue(value);
  }

  function handleSearchTopic(topic: string) {
    setTopic(topic);
  }

  function searchQuery() {
    setIsLoading(true);
    const { getAllNews } = props;
    getAllNews({
      applicationId: applicationId,
      culture: props.culture,
      isOrderByPublicationDate: isOrderByPublicationDate,
      topic: topic,
      isFavorite: isFavorite,
      q: q,
      pageNumber: pageNumber,
      pageSize: pageSize,
      workspaceId,
    }).then((response) => {
      setIsLoading(false);
    });
  }

  const search = (text: string) => {
    setQ(text);
    if (text && text.length > 2) {
      getAllNews({
        applicationId: applicationId,
        culture: props.culture,
        isOrderByPublicationDate: isOrderByPublicationDate,
        topic: topic,
        isFavorite: isFavorite,
        q: text,
        pageNumber: pageNumber,
        pageSize: pageSize,
        workspaceId,
      }).then((response) => {
        setIsLoading(false);
      });
    } else {
      getAllNews({
        applicationId: applicationId,
        culture: props.culture,
        isOrderByPublicationDate: isOrderByPublicationDate,
        topic: topic,
        isFavorite: isFavorite,
        q: "",
        pageNumber: pageNumber,
        pageSize: pageSize,
        workspaceId,
      }).then((response) => {
        setIsLoading(false);
      });
    }
  };

  let keyTimer: any;
  let waitTimeAfterType = 3000;

  const handlerKeyUp = (text: string) => {
    setIsLoading(true);
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      search(text);
    }, waitTimeAfterType);
  };

  const handlerKeydDown = () => {
    clearTimeout(keyTimer);
  };

  const viewMoreNews = () => {
    setIsLoading(true);
    setView((prev) => !prev);

    props?.news?.news?.result?.length === 9
      ? getAllNews({
        applicationId: applicationId,
        culture: props.culture,
        isOrderByPublicationDate: isOrderByPublicationDate,
        topic: topic,
        isFavorite: isFavorite,
        q: q,
        pageNumber: pageNumber,
        pageSize: props?.news?.news?.meta?.total,
        workspaceId,
      }).then((response) => {
        setIsLoading(false);
      })
      : getAllNews({
        applicationId: applicationId,
        culture: props.culture,
        isOrderByPublicationDate: isOrderByPublicationDate,
        topic: topic,
        isFavorite: isFavorite,
        q: q,
        pageNumber: pageNumber,
        pageSize: pageSize,
        workspaceId,
      }).then((response) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setIsLoading(true);
      search(searchValue);
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        {newApp && (
          <>
            {futureNews &&
              (permission === "Admin" || permission === "ChallengeManager") ? (
              <Box
                width="100%"
                className={classes.futureNewsContainer}
                px={4}
                pt={1}
                pb={2}
              >
                <Box my={2} display="flex" alignItems="center" gridGap={16}>
                  <VisibilityOutlinedIcon />
                  <Typography variant="body1">
                    <Resource tag="PageNews::ScheduleVisible" />
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  {futureNewsStatus === "LOADING" ? (
                    <>
                      <Grid item md={4} sm={12} style={{ width: "100%" }}>
                        <NewsCardSkeleton
                          isLoading={futureNewsStatus === "LOADING"}
                        />
                      </Grid>
                      <Grid item md={4} sm={12} style={{ width: "100%" }}>
                        <NewsCardSkeleton
                          isLoading={futureNewsStatus === "LOADING"}
                        />
                      </Grid>
                      <Grid item md={4} sm={12} style={{ width: "100%" }}>
                        <NewsCardSkeleton
                          isLoading={futureNewsStatus === "LOADING"}
                        />
                      </Grid>
                    </>
                  ) : (
                    futureNews &&
                    futureNews?.map((data, index) => (
                      <Grid
                        item
                        md={4}
                        sm={12}
                        style={{ width: "100%" }}
                        key={data?.newsId!}
                      >
                        <NewsCard
                          handleReschedule={handleReschedule}
                          handleDelete={handleDelete!}
                          id={data?.newsId!}
                          news={data}
                          key={data?.newsId}
                          isFutureNews={true}
                        />
                      </Grid>
                    ))
                  )}
                </Grid>
              </Box>
            ) : (
              <></>
            )}
          </>
        )}
        {isLoading ? (
          <Grid spacing={2} container style={{ marginTop: 16 }}>
            <Grid item md={4} sm={12} style={{ width: "100%" }}>
              <NewsCardSkeleton isLoading={isLoading} />
            </Grid>
            <Grid item md={4} sm={12} style={{ width: "100%" }}>
              <NewsCardSkeleton isLoading={isLoading} />
            </Grid>
            <Grid item md={4} sm={12} style={{ width: "100%" }}>
              <NewsCardSkeleton isLoading={isLoading} />
            </Grid>
          </Grid>
        ) : (
          <>
            {!(props?.news?.news?.result.length > 0) ? (
              <Box
                p={5}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
                alignItems="center"
                width="100%"
              >
                <Typography color="textSecondary">
                  <Resource tag="PageNews::Error_NoNewsAvailable" />
                </Typography>
                <img
                  src={imagemNotChallenges}
                  alt=""
                  style={{ marginTop: 16, maxWidth: 300, maxHeight: 300 }}
                />
                <Box mt="20px">
                  <Button onClick={() => setSearchValue("")}>
                    <Resource tag="NewsPage::BackButton" />
                  </Button>
                </Box>
              </Box>
            ) : (
              <>
                <SearchNews
                  key={1}
                  value={searchValue}
                  handlerInput={handleSearchQueryChange}
                  handlerSearch={searchQuery}
                  handlerTopicSearch={handleSearchTopic}
                  handlerKeyDown={handlerKeydDown}
                  searchInput={handlerKeyUp}
                  topics={topics}
                  activeTopic={topic}
                />
                <Grid item xs={12}>
                  {newsHighlighted?.meta?.total > 0 ||
                    newsPopular?.meta?.total > 0 ? (
                    <NewsHighlightedCard
                      isLoading={isLoading}
                      moreOptions={permission !== "Admin" ? false : true}
                      handleHighlight={handleHighlight}
                      handleDelete={handleDelete}
                      highlighted={news?.highlighted}
                      popular={news?.popular}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12}>
                  <NewsList
                    moreOptions={permission !== "Admin" ? false : true}
                    handleHighlight={handleHighlight}
                    handleDelete={handleDelete}
                    arrNews={props?.news?.news?.result}
                    isLoading={isLoading}
                  />
                </Grid>
                {props?.news?.news?.meta?.total > 9 && (
                  <Grid item xs={12}>
                    <Box textAlign="right">
                      <Button
                        onClick={viewMoreNews}
                        variant="text"
                        color="primary"
                      >
                        <Typography
                          variant="button"
                          style={{ textTransform: "uppercase" }}
                        >
                          {viewAll ? (
                            <Resource tag="Common::ViewAll" />
                          ) : (
                            <Resource tag="Common::ViewLess" />
                          )}
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    news: store.newsState.news,
    topics: store.newsState.topics,
    culture: store.cultureState,
    newsHighlighted: store.newsState.newsHighlighted,
    newsPopular: store.newsState.newsPopular,
    workspaceId: store.authState.auth.workspaceId,
    futureNews: store.newsState.futureNews,
    futureNewsStatus: store.newsState.futureNewStatus,
    permission: store.authState.auth.permission,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getAllNews: (options: IOptions) => dispatch(getNews(options)),
    getNewsTopics: (options: IOptions) => dispatch(getNewsTopics(options)),
    getNewsHighlighted: (options: IOptions) =>
      dispatch(getNewsHighlighted(options)),
    getNewsPopular: (options: IOptions) => dispatch(getNewsPopular(options)),
    deleteNew: (options: object) => dispatch(deleteNew(options)),
    highlightNew: (options: object) => dispatch(highlightNew(options)),
    getFutureNews: (options: object) => dispatch(getFutureNews(options)),
    updateNews: (options: object) => dispatch(updateNews(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
