import "./AddButton.css";
import React from "react";
import EMIcon from "components/Icon/Icon";
import AddIcon from "@material-ui/icons/Add";
import Resource from "../Resources/Resource";
import {
  Fab,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
/* Example
    <AddButton method={this.action}/>
    <AddButton size="big" method={this.action}/>
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    big: {
      color: theme.palette.common.white,
      width: "255px",
      height: "45px",
      justifyContent: "space-between",
      borderRadius: "40px",
      background: `linear-gradient(186deg, ${theme.palette.secondary.light} 15%, ${theme.palette.secondary.main} 100%)`,
    },
    endIcon: {
      right: "-4px",
      width: "32px",
      height: "32px",
      minHeight: "32px",
      color: "#fff",
      backgroundColor: theme.palette.secondary.main,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      borderRadius: "50%",
    },
  })
);

interface IProps {
  method?: () => void;
  open?: boolean;
  size?: string;
  id: string;
}

function AddButton(props: IProps) {
  const classes = useStyles();
  let endIcon = (
    <span className={classes.endIcon}>
      {" "}
      <AddIcon fontSize="small" />{" "}
    </span>
  );
  // function handleCreateIdea(e: any) {
  //   history.push("/create");
  // }

  const small = () => {
    return (
      <Fab
        id={props.id}
        className="add-button"
        color="secondary"
        size="medium"
        // aria-label="add"
        onClick={props.method}
      >
        <EMIcon color={"white"} size={16} icon={"plus"} />
      </Fab>
    );
  };

  const big = () => {
    return (
      <Button
        id={props.id}
        variant="contained"
        className={classes.big}
        onClick={props.method}
        color="secondary"
        endIcon={endIcon}
      >
        <Resource tag={"PageDetailChallenge::Idea_Creation"} />
      </Button>
    );
  };

  if (props.size === undefined || props.size === "small") {
    return small();
  } else {
    return big();
  }
}

export default AddButton;
