import React from "react";
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import NewsList from "containers/NewsList/NewsList";
import Resource from "components/Resources/Resource";
import { INewsHighlighted } from "redux/types/news";
import {
  Grid,
  makeStyles,
  createStyles,
  Box,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 25,
    },
  })
);

interface IProps {
  highlighted: INewsHighlighted;
}

function NewsHighlightedPage(props: IProps) {
  const classes = useStyles();
  const { highlighted } = props;

  return (
    <>
      <Grid container>
        <Box mt={4} mb={2}>
          <Typography variant="h3">
            <Resource tag={"Common::Highlighted"}></Resource>
          </Typography>
        </Box>
      </Grid>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <NewsList arrNews={highlighted?.result} />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    highlighted: store.newsState.newsHighlighted,
  };
};

export default connect(mapStateToProps)(NewsHighlightedPage);
