import { AnyAction } from "redux";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { IAppState, store } from "redux/store/Store";
import Resource from "components/Resources/Resource";
import { IAuthUser } from "redux/reducers/authReducer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import StepWorkspaceName from "./StepWorkspaceName/StepWorkspaceName";
//import StepWorkspaceType from "./StepWorkspaceType/StepWorkspaceType";
import StepWorkspaceTheme from "./StepWorkspaceTheme/StepWorkspaceTheme";
import StepWorkspaceImages from "./StepWorkspaceImages/StepWorkspaceImages";
import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  createStyles,
  IconButton,
  makeStyles,
  Tab,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  getAllWorkspaces,
  getCulturesWorkspaces,
  getWorkspace,
} from "redux/actions/workspaceAction";
import {
  ICultureSetCulture,
  setCultureAndResources,
} from "redux/actions/CultureActions";
import {
  ICulturesWorkspaces,
  IWorkspaceReducer,
  IWorkspaceState,
} from "redux/reducers/workspaceReducer";
import StepWorkspaceLanguage from "./StepWorkspaceLanguage/StepWorkspaceLanguage";
import StepWorkspaceCountries from "./StepWorkspaceCountries/StepWorkspaceCountries";
import StepWorkspaceDepartments from "./StepWorkspaceDepartments/StepWorkspaceDepartments";
import StepWorkspaceResume from "./StepWorkspaceResume/StepWorkspaceResume";
import { TabContext, TabList } from "@material-ui/lab";
import StepWorkspaceCurrency from "./StepWorkspaceCurrency/StepWorkspaceCurrency";

interface IDispatchProps {
  getAllWorkspaces: (options: object) => Promise<IWorkspaceState>;
  getCulturesWorkspaces: (options: object) => Promise<ICulturesWorkspaces>;
  getWorkspace: (options: object) => Promise<IWorkspaceState>;
}

interface IStateProps {
  workspaces: IWorkspaceReducer;
  application: IApplication;
  culture: ICultureState;
  culturesWorkspaces: ICulturesWorkspaces[];
  auth: IAuthUser;
  istab?:boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: "40px 0",
    },
    setupProgress: {
      position: "absolute",
      top: 40,
    },
    formProgressBar: {
      backgroundColor: theme.palette.primary.main,
      display: "inline-block",
      width: "32px",
      height: "5px !important",
      margin: "0 4px 0 0",
      opacity: "0.4",
      borderRadius: "5px",

      "&.active": {
        opacity: 1,
      },
    },
    centerLoading: {
      margin: "0px auto",
      height: "75vh",
    },
  })
);

const options = {
  applicationId: 2,
  culture: {},
  workspaceId: "",
};

function SetupEnvironmentScreen(props: IDispatchProps & IStateProps ) {
  
  const classes = useStyles();
  const {
    getAllWorkspaces,
    getCulturesWorkspaces,
    getWorkspace,
    culturesWorkspaces,
    workspaces,
    culture,
    istab
  } = props;

  const [currentScreen, setCurrentScreen] = useState(istab ? 2 : 1);
  const [loading, setLoading] = useState(true);

  function getResourcesHandler(cultureTag: string) {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store.dispatch;
    dispatch(setCultureAndResources(cultureTag));
    return store.getState().cultureState;
  }

  useEffect(() => {
    options.applicationId = props.application.applicationId;
    options.culture = props.culture;

    Promise.all([
      getAllWorkspaces(options),
      getCulturesWorkspaces(options),
    ]).finally(() => setLoading(false));

    if(istab){
      options.workspaceId = workspaces.workspaceId;
      Promise.all([
        getWorkspace(options),
      ])
    }

    // eslint-disable-next-line
  }, []);

  {/*
  //---------------------------------------------------------------------------
  //A função getResourcesHandler faz o menu todo escrever "undefined", 
  //porque lá é executado a função "setCultureAndResources"
  //Analisei e não vi a necessidade disso, por isso comentei o código abaixo
  //Task: 5100 ( Felipe Melo em 21/04/2022 )
  //---------------------------------------------------------------------------
  useEffect(() => {
    culturesWorkspaces.length === 0
      ? getResourcesHandler("en-GB")
      : getResourcesHandler(culturesWorkspaces[0]?.cultureTag);
  }, [culturesWorkspaces]);
  */}

  const setUpBar = () => {
    const bars = [];
    for (let index = 1; index < 8
      ; index++) {
      const barClass = index < currentScreen;
      bars.push(
        <span
          key={index}
          className={classes.formProgressBar + (barClass ? " active" : "")}
        ></span>
      );
    }
    return bars;
  };

  const handleChangeTab = (event: any, newValue: string) => {
    setCurrentScreen(parseInt(newValue));
  };

  const getCurrentScreen = () => {
    switch (currentScreen) {
      //TODO To implemented
      case 1:
        return <StepWorkspaceLanguage setCurrentScreen={setCurrentScreen} {...{ istab }} />;
      case 2:
        return <StepWorkspaceName setCurrentScreen={setCurrentScreen} {...{ istab }} />;
      case 3:
        return <StepWorkspaceTheme setCurrentScreen={setCurrentScreen} {...{ istab }} />;
      case 4:
        return <StepWorkspaceImages setCurrentScreen={setCurrentScreen} {...{ istab }} />;
      case 5:
        return <StepWorkspaceCountries setCurrentScreen={setCurrentScreen} {...{ istab }} />;
      case 6:
        return <StepWorkspaceCurrency setCurrentScreen={setCurrentScreen} {...{ istab }} />
      case 7:
        return <StepWorkspaceDepartments setCurrentScreen={setCurrentScreen} {...{ istab }} />
      case 8:
        return <StepWorkspaceResume setCurrentScreen={setCurrentScreen} {...{ istab }} />
      default:
        return <Box></Box>;
    }
  };

  return (
    <Container className={classes.container}>
      {culture.statusCulture === "SUCCEEDED" ? (
        <>
          {!loading ? (
            <>
              <Box className={classes.setupProgress}>
                <Typography variant="h2">
                  <Resource tag="PageSetup::Title" />
                </Typography>
                {istab &&
                  <Box pt="30px">
                    <TabContext value={currentScreen.toString()}>
                      <AppBar position="static" elevation={0}>
                        <TabList
                          onChange={handleChangeTab}
                          variant="standard"
                          scrollButtons="on"
                        >
                          <Tab label={<Resource tag="PageSetup::LabelName" />} value="2" />
                          <Tab label={<Resource tag="PageSetup::LabelColors" />} value="3" />
                          <Tab label={<Resource tag="PageSetup::LabelImages" />} value="4" />
                          <Tab label={<Resource tag="PageSetup::LabelCountries" />} value="5" />
                          <Tab label={<Resource tag="PageSetup::LabelCurrency" />} value="6" />
                          <Tab label={<Resource tag="PageSetup::LabelDepartments" />} value="7" />
                        </TabList>
                      </AppBar>
                    </TabContext>
                  </Box>
                }
                {!istab &&
                  <Box>
                    {setUpBar()}
                  </Box>
                }
              </Box>
              <Box textAlign="center">
                {currentScreen > 1 && !istab && (
                  <Box
                    style={{ marginTop: '-15px' }}
                    component="span"
                    display="flex"
                    onClick={() => setCurrentScreen(currentScreen - 1)}
                    flexDirection="row"
                  >
                    <IconButton color="primary">
                      <ArrowBackIcon />
                    </IconButton>
                  </Box>
                )}
                {getCurrentScreen()}
              </Box>
            </>
          ) : (
            <>
              <Box
                height="100%"
                display="flex"
                className={classes.centerLoading}
              >
                <Box m="auto">
                  <CircularProgress size={60} className="icon" />
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Box height="100%" display="flex" className={classes.centerLoading}>
            <Box m="auto">
              <CircularProgress size={60} className="icon" />
            </Box>
          </Box>
        </>
      )
      }
    </Container >
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllWorkspaces: (options: object) => dispatch(getAllWorkspaces(options)),
    getCulturesWorkspaces: (options: object) =>
      dispatch(getCulturesWorkspaces(options)),
    getWorkspace: (option: object) => dispatch(getWorkspace(option)),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    application: store.tenantState.application,
    culture: store.cultureState,
    workspaces: store.workSpacesState.workspaces,
    culturesWorkspaces: store.workSpacesState.culturesWorkspaces,
    auth: store.authState.auth,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupEnvironmentScreen);
