import React from "react";
import XDialog from "common/X/XDialog";
import { Box, Button, CircularProgress } from "@material-ui/core";
import Resource from "components/Resources/Resource";
import TextCounter from "common/UI/Text/TextCounter";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  content?: any;
  title: string;
  setTitle: any;
  noCancelButton?: any;
  yesButton?: any;
  loading: boolean;
  justSave: boolean;
}

function SaveOrPublishDialog({
  open,
  setOpen,
  content,
  title,
  setTitle,
  noCancelButton,
  yesButton,
  justSave,
  loading
}: IProps) {
  const actions = (
    <Box textAlign="right">
      <Button
        color="primary"
        size="small"
        variant="outlined"
        style={{ marginRight: 8 }}
        onClick={noCancelButton}
      >
        <Resource tag="PageRegulation::NoCancel" />
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        disabled={!title || loading}
        onClick={yesButton}
        endIcon={
          !loading ? (
            ''
          ) : (
            <CircularProgress size={22} className="icon" />
          )
        }
      >
        {justSave ? (
          <Resource tag="PageRegulation::Save" />
        ) : (
          <Resource tag="PageRegulation::YesPublish" />
        )}
      </Button>
    </Box>
  );

  return (
    <XDialog
      width="800px"
      maxWidth="md"
      open={open}
      setOpen={setOpen}
      title={
        justSave ? (
          <Resource tag="PageRegulation::SaveDraft" />
        ) : (
          <Resource tag="PageRegulation::SaveAndPublish" />
        )
      }
      actions={actions}
    >
      {justSave ? (
        <Box textAlign="center">{content}</Box>
      ) : (
        <Box textAlign="center">{content}</Box>
      )}
      <Box margin="40px 20px 0">
        <TextCounter
          fullWidth
          maxLength={30}
          label={<Resource tag="PageRegulation::Title" />}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Box>
    </XDialog>
  );
}

export default SaveOrPublishDialog;
