import { AnyAction } from "redux";
import history from "routes/history";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import CongratsMobile from "./CongratsMobile";
import logo from "../../../assets/img/logo.png";
import { ArrowForward } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import { IAuthUser } from "redux/reducers/authReducer";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import LoggedOutFooter from "components/LoggedOutFooter/LoggedOutFooter";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  Hidden,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  getAllWorkspaces,
  putWorkspacesCompleted,
  postInviteWorkspaces,
} from "redux/actions/workspaceAction";
import {
  IWorkspaceReducer,
  IWorkspaceState,
} from "redux/reducers/workspaceReducer";
import { doLogout, IAuthLogoutAction } from "redux/actions/AuthAction";

interface IDispatchProps {
  getAllWorkspace: (options: object) => Promise<IWorkspaceState>;
  putWorkspaceCompleted: (options: object) => Promise<any>;
  postInviteWorkspace: (options: object) => Promise<any>;
  logOutConnect: () => Promise<IAuthLogoutAction>;
}

interface IStateProps {
  workspaces: IWorkspaceReducer;
  application: IApplication;
  culture: ICultureState;
  pathname: string;
  auth: IAuthUser;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    retangle1: {
      background: theme.palette.primary.main,
      width: 283,
      height: 379,
      borderRadius: "5px",
      transform: "rotate(14.1deg)",
      position: "absolute",
      left: "-24.16px",
      top: "-78.76px",
    },
    retangle2: {
      background: theme.palette.secondary.main,
      width: 149,
      height: 456,
      borderRadius: "10px",
      transform: "rotate(-8.25deg)",
      position: "absolute",
      left: "62.63px",
      top: "218px",
    },
    retangle3: {
      background: theme.palette.primary.light,
      width: 133,
      height: 133,
      borderRadius: "15px",
      transform: "rotate(23.52deg)",
      position: "absolute",
      left: "182.6px",
      top: "470px",
    },
    form: {
      margin: "30px 0",
      width: "85%",
      "& label.Mui-focused": {
        color: theme.palette.primary.main,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.primary.main,
      },
      "& .MuiFormControl-root": {
        padding: "10px 0",
      },
      "& .MuiFormLabel-root": {
        fontSize: "0.8rem",
      },
      "& .MuiInputBase-input": {
        fontSize: "0.8rem",
      },
    },
    iconInvite: {
      color: "#fff",
    },
    resourceInvite: {
      color: "#fff",
      textTransform: "lowercase",
    },
    logo: {
      top: "30px",
      right: "160px",
      height: 67,
      position: "absolute",
    },
    circle1: {
      top: "114px",
      right: "113px",
      width: 130,
      height: 130,
      position: "absolute",
      background: theme.palette.secondary.main,
      borderRadius: "50%",
    },
    circle2: {
      top: "192px",
      right: "268px",
      width: 90,
      height: 90,
      position: "absolute",
      background: theme.palette.primary.light,
      borderRadius: "50%",
    },
    circle3: {
      top: "309px",
      right: "113px",
      width: 290,
      height: 290,
      position: "absolute",
      background: theme.palette.primary.main,
      borderRadius: "50%",
    },
    centerLoading: {
      margin: "0px auto",
      height: "100vh",
    },
  })
);

const options = {
  applicationId: 2,
  culture: {},
};

function Congrats(props: IDispatchProps & IStateProps) {
  const classes = useStyles();
  const [addressEmail1, setAddressEmail1] = useState("");
  const [addressEmail2, setAddressEmail2] = useState("");
  const [addressEmail3, setAddressEmail3] = useState("");
  const {
    getAllWorkspace,
    putWorkspaceCompleted,
    postInviteWorkspace,
    workspaces,
    culture,
    auth,
  } = props;
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      history.push("/splash");
    } else {
      getAllWorkspace(options).then(() => {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddressEmailChange1 = (e: any) => {
    setAddressEmail1(e.target.value);
  };

  const handleAddressEmailChange2 = (e: any) => {
    setAddressEmail2(e.target.value);
  };

  const handleAddressEmailChange3 = (e: any) => {
    setAddressEmail3(e.target.value);
  };

  function postWorkSpaceInvite() {
    setLoadingSubmit(true);
    let params = {};

    let emails = [
      addressEmail1 ?? "",
      addressEmail2 ?? "",
      addressEmail3 ?? "",
    ];

    params = {
      workspaceId: workspaces.workspaceId,
      emails: emails.filter((x) => x),
      culture: culture,
    };

    postInviteWorkspace(params).then((response) => {
      getAllWorkspace(options).then(() => {
        response.data.hasWorkspaceConfigured && history.push("/setprofile");
      });

      setLoadingSubmit(false);
    });
  }

  function putSkipStep() {
    setLoadingSubmit(true);

    let params = {};
    params = {
      workspaceId: workspaces.workspaceId,
      culture: culture,
    };

    putWorkspaceCompleted(params).then((response) => {
      getAllWorkspace(options).then(() => {
        response.data.hasWorkspaceConfigured && history.push("/setprofile");
      });

      setLoadingSubmit(false);
    });
  }

  return (
    <>
      {culture.statusCulture === "SUCCEEDED" ? (
        <>
          <Hidden smDown>
            {/* <Container className="congrats-page"> */}
            <Grid container className="congrats-page">
              <Grid item md={3} lg={2} xl={2}>
                <Box className={classes.retangle1} />
                <Box className={classes.retangle2} />
                <Box className={classes.retangle3} />
              </Grid>
              <Grid item md={6} lg={7} xl={7}>
                <Box padding="20vh 8vw" zIndex={9999999999999}>
                  <Typography
                    variant="h2"
                    color="textPrimary"
                    style={{ fontSize: "1.5rem", marginBottom: "10px" }}
                  >
                    <Resource tag={"PageSetup::ReadyWorkspace_Message"} />
                  </Typography>
                  <Typography color="textPrimary">
                    <Resource tag={"PageSetup::ReadyWorkspace_Question"} />
                  </Typography>
                  <FormControl className={classes.form}>
                    <TextField
                      id="ReadyWorkspace_Email"
                      label={
                        <Resource tag={"PageSetup::ReadyWorkspace_Email"} />
                      }
                      onChange={handleAddressEmailChange1}
                      type="search"
                    />
                    <TextField
                      id="ReadyWorkspace_Email"
                      onChange={handleAddressEmailChange2}
                      label={
                        <Resource tag={"PageSetup::ReadyWorkspace_Email"} />
                      }
                      type="search"
                    />
                    <TextField
                      id="ReadyWorkspace_Email"
                      onChange={handleAddressEmailChange3}
                      label={
                        <Resource tag={"PageSetup::ReadyWorkspace_Email"} />
                      }
                      type="search"
                    />
                    <Box mt={6} alignSelf="flex-end" display="flex">
                      <Box mr={1}>
                        <Button
                          color="primary"
                          variant="text"
                          onClick={() => putSkipStep()}
                        >
                          <Resource tag={"PageSetup::SkipStep"} />
                        </Button>
                      </Box>
                      <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={
                          !addressEmail1 && !addressEmail2 && !addressEmail3
                        }
                        onClick={() => postWorkSpaceInvite()}
                        endIcon={
                          !loadingSubmit ? (
                            <ArrowForward className={classes.iconInvite} />
                          ) : (
                            <CircularProgress
                              size={22}
                              className={classes.iconInvite}
                            />
                          )
                        }
                      >
                        <Box className={classes.resourceInvite}>
                          <Resource tag={"PageSetup::ReadyWorkspace_Invite"} />
                        </Box>
                      </Button>
                    </Box>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <Box className={classes.logo}>
                  <img
                    style={{ maxHeight: "80px", maxWidth: "250px" }}
                    src={workspaces?.photoLogo ? workspaces?.photoLogo : logo}
                    alt="Exago"
                  />
                </Box>
                <Box className={classes.circle1} />
                <Box className={classes.circle2} />
                <Box className={classes.circle3} />
              </Grid>
            </Grid>
            <Box className="congrats-footer">
              <LoggedOutFooter pathname={props.pathname} />
            </Box>
            {/* </Container> */}
          </Hidden>
          <Hidden mdUp>
            <CongratsMobile
              statusCulture={culture.statusCulture === "SUCCEEDED"}
              photoLogo={workspaces?.photoLogo}
              pathname={props.pathname}
              email1={handleAddressEmailChange1}
              email2={handleAddressEmailChange2}
              email3={handleAddressEmailChange3}
              putSkip={() => putSkipStep()}
              postInvite={() => postWorkSpaceInvite()}
              disableBtnInvite={
                !addressEmail1 && !addressEmail2 && !addressEmail3
              }
            />
          </Hidden>
        </>
      ) : (
        <>
          <Box height="100%" display="flex" className={classes.centerLoading}>
            <Box m="auto">
              <CircularProgress size={60} className="icon" />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllWorkspace: (options: object) => dispatch(getAllWorkspaces(options)),
    putWorkspaceCompleted: (params: object) =>
      dispatch(putWorkspacesCompleted(params)),
    postInviteWorkspace: (params: object) =>
      dispatch(postInviteWorkspaces(params)),
    logOutConnect: () => dispatch(doLogout()),
  };
};

const mapStateToProps = (store: IAppState, ownProps: any): IStateProps => {
  return {
    application: store.tenantState.application,
    culture: store.cultureState,
    workspaces: store.workSpacesState.workspaces,
    pathname: ownProps.location.pathname,
    auth: store.authState.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Congrats);
