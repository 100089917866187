
import "./PreviousPrizes.css";
import { UX } from "../../config/ux";
import React, {Component} from "react";
import { IProfileState } from "redux/reducers/profileReducer";
import {Collapse, Grid} from "@material-ui/core";
import {IPrizePrevious} from "../../redux/reducers/prizeReducer";
import PreviousPrizeCard from "../../components/PreviousPrizesCard/PreviousPrizesCard";
import PreviousPrizesCardSkeleton from "../PreviousPrizesCard/PreviousPrizesCardSkeleton";
import PreviousPrizeDescription from "../../components/PreviousPrizesCard/PreviousPrizesDescription";
import DynamicInfiniteScroll from "../../views/KeyTasks/DynamicInfiniteScroll";
interface IProps {
  cultureTag?: string;
  profile: IProfileState;
  permission: string;
  prizePrevious: IPrizePrevious;
  prizeP: IPrizePrevious;
  workspaceId: string;
  getPrizePrevious: (options: object) => Promise<any>;
  requestManagePrize: (prizeId: string, type: number, isCanceling?: boolean) => void;
}

interface IState {
  activePrize: any;
  activeIndex: number;
  loading: boolean;
  openGrid: boolean;
}

class PreviousPrizes extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      activePrize: null,
      activeIndex: 0,
      openGrid: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, UX.skeleton.minDelay);
  }
  render() {
    const { cultureTag, profile, permission, requestManagePrize, prizePrevious, workspaceId, getPrizePrevious, prizeP } = this.props;
    const profileName = profile?.profile[0]?.fullName;

    const topDesk = this.state.activeIndex % 3 != 0 ?
        "calc(280px + (275px * " +  (this.state.activeIndex - (this.state.activeIndex % 3)) / 3 + "))" :
        "calc(280px + (275px * " + (this.state.activeIndex - 3) / 3 + "))";
    const topMobile = "calc(280px + (275px * " +  (this.state.activeIndex - 1) + "))";
    const topStyle = window.innerWidth > 1023 ? topDesk : topMobile;


    const handleClick = (cardData: any, index: number) => {
      if (
        this.state.activePrize !== null &&
        cardData.prizeId === this.state.activePrize.prizeId
      ) {
        this.setState({ activePrize: null, activeIndex: 0, openGrid: false });
      } else {
        this.setState({
          activePrize: cardData,
          activeIndex: index,
          openGrid: true,
        });
      }
    };

    return (
        <DynamicInfiniteScroll
            getAction={(pn, ps) => getPrizePrevious({
              workspaceId: workspaceId,
              pageNumber: pn,
              pageSize: ps,
            })}
            dataList={prizePrevious}
            pageSize={9}
            isAutomatic={true}
        >
          <Grid container style={this.state.openGrid ? { position: "relative", paddingBottom: '140px' } : { position: "relative" }} spacing={3}>
              {!this.state.loading ? (
                <>
                  {prizeP &&
                      prizeP?.result?.map((cardData, index) => {
                      const iWon = cardData.winners.some(
                        (winner) => winner.name === profileName
                      );
                      return (
                        <Grid key={index} item xs={12} sm={12} md={4} lg={4}>
                          <PreviousPrizeCard
                            permission={permission}
                            openGrid={
                              index + 1 === this.state.activeIndex &&
                              this.state.openGrid
                            }
                            iWon={iWon}
                            cultureTag={cultureTag}
                            onClick={handleClick}
                            index={index + 1}
                            prize={cardData}
                            requestManagePrize={requestManagePrize}
                          />
                        </Grid>
                      );
                    })}
                </>
              ) : (
                  <PreviousPrizesCardSkeleton />
                )}
              {
                this.state.activePrize && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Collapse
                    style={{
                      width: "calc(100% - 24px)",
                      position: "absolute",
                      top: topStyle,
                    }}
                    in={this.state.activePrize !== null}
                  >
                    <PreviousPrizeDescription
                      handleClick={handleClick}
                      cultureTag={cultureTag}
                      activeIndex={this.state.activeIndex}
                      itemIndex={this.state.activeIndex}
                      result={this.state.activePrize}
                    />
                  </Collapse>
                </Grid>
              )}
          </Grid>
        </DynamicInfiniteScroll>
    );
  }
}

export default PreviousPrizes;
