import { useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Button,
  Grid,
  AppBar,
  Tab,
} from "@material-ui/core";
import Resource from "components/Resources/Resource";
import { TabPanel, TabList, TabContext } from "@material-ui/lab";
import ManageUsersTabs from "./UsersTab/ManageUsersTabs";
import DialogInviteToWorkspace from "./UsersTab/DialogInviteToWorkspace";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IAppState } from "redux/store/Store";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add"
import ManagePermissionsTabs from "./PermissionsTab/ManagePermissionsTabs";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { deleteInvite, getManageUsers, getUserPermissions, postImportUsers, postInviteUser, postSchedule, postToken, postUserActivate, postUserDeactivate, putPermission, putReinvite, putReschedule } from "redux/actions/manageUsers/ManageUsersAction";
import { IManageUsers, IManageUsersState } from "redux/types/manageUsers";
import { getAllManageGroups, putAssignGroups } from "redux/actions/manageGroups/manageGroupsAction";
import { IAllManageGroups, IManageGroupsState } from "redux/types/manageGroups";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
import { getAllCountries, getAllDepartaments } from "redux/actions/UsersAction";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    noSidePadding: {
      padding: "24px 0",
    },
  })
);
interface IProps {
  culture:ICultureState
  permissions: any;
  permissionStatus: string
  activateStatus: string
  users: IManageUsers
  workspaceId: string
  userStatus: string
  allGroups: IAllManageGroups[]
  departaments: IDepartments[];
  countries: ICountries[];
}
interface IDispatchProps {
  getCountries: (options: object) => Promise<any>;
  getDepartments: (options: object) => Promise<any>;
  getUserPermissions:(options: object) => Promise<IManageUsersState>
  postUserActivate: (options: object) => Promise<IManageUsersState>
  postUserDeactivate:(options: object) => Promise<IManageUsersState>
  getUsers: (options: object) => Promise<IManageUsersState>
  postImportUsers: (options:object) => Promise<IManageUsersState>
  getAllManageGroups: (options:object) => Promise<IManageUsersState>
  postInviteUser: (options: object) => Promise<IManageUsersState>
  postSchedule: (options: object) => Promise<IManageUsersState>
  putReinvite: (options: object) => Promise<IManageUsersState>
  deleteInvite: (options:object) => Promise<IManageUsersState>
  putReschedule:(options:object)=> Promise<IManageUsersState>
  putAssignGroups: (options: object) => Promise<IManageGroupsState>
  putPermission:(options: object) => Promise<IManageUsersState>
  postToken: (options: object) => Promise<IManageUsersState>
}

function ManageUsers(props: IProps & IDispatchProps) {
  const [inviteToWs, setInviteToWs] = useState(false)
  const classes = useStyles();
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [tab, setTab] = useState("1");
  const handleChangeTab = (event: any, newValue: string) => {
    setTab(newValue);
  };
  const { 
    culture,
    permissionStatus, 
    permissions, 
    activateStatus,
    getUserPermissions,
    getUsers,
    workspaceId,
    users,
    postImportUsers,
    postUserActivate, 
    postUserDeactivate,
    userStatus,
    getAllManageGroups,
    allGroups,
    postInviteUser,
    postSchedule,
    putReschedule,
    putAssignGroups,
    deleteInvite,
    putReinvite,
    putPermission,
    postToken,
    departaments,
    countries,
    getCountries,
    getDepartments
  } = props
  useEffect(() => {
    countries.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  })
  useEffect(() => {
    Promise.all([
      getUsers({
        cultureTag: culture.culture.cultureTag,
        workspaceId: workspaceId,
        userType: tab,
        pageNumber: 1,
        pageSize: 10
      }),
      getCountries({ cultureTag: culture.culture.cultureTag }),
      getDepartments({ cultureTag: culture.culture.cultureTag }),
    ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  function deleteInviteUser(date: any) {
    deleteInvite(date).then(() => {
      getUsers({
        cultureTag: culture.culture.cultureTag,
        workspaceId: workspaceId,
        userType: 2,
        pageNumber: 1,
        pageSize: 10
      })
    })
  }

  return (
    <>
      <Box className={classes.root}>
        <DialogInviteToWorkspace
          getAllManageGroups={getAllManageGroups}
          allGroups={allGroups}
          workspaceId={workspaceId}
          open={inviteToWs} 
          postImportUsers={postImportUsers} 
          postSchedule={postSchedule}
          postInviteUser={postInviteUser}
          setOpen={setInviteToWs} 
          culture={props.culture} 
          postToken={postToken}
        />
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h1">
              <strong>
                <Resource tag="PageSettings::ManageUsers" />
              </strong>
            </Typography>
          </Grid>
          <Grid
            container
            justify={matchSm ? "flex-start" : "flex-end"}
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setInviteToWs(true)}
            >
              <Resource tag="PageSettings::InviteNewUsers" />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.root} marginY={3}>
        <TabContext value={tab}>
          <AppBar position="static" elevation={0}>
            <TabList
              onChange={handleChangeTab}
              variant="standard"
              scrollButtons="on"
            >
              <Tab label={<Resource tag="PageSettings::Users" />} value="1" />
              <Tab
                label={<Resource tag="PageSettings::Permissions" />}
                value="2"
              />
            </TabList>
          </AppBar>
          <TabPanel value="1" className={classes.noSidePadding}>
            <ManageUsersTabs  
              departaments={departaments}
              countries={countries}
              putAssignGroups={putAssignGroups}
              workspaceId={workspaceId} 
              activateStatus={activateStatus}
              putReschedule={putReschedule}
              postUserActivate={postUserActivate}
              postUserDeactivate={postUserDeactivate}
              deleteInviteUser={deleteInviteUser}
              putReinvite={putReinvite}
              getUsers={getUsers} 
              users={users} 
              culture={culture} 
              userStatus={userStatus}
              allGroups={allGroups}
              />
          </TabPanel>
          <TabPanel value="2" className={classes.noSidePadding}>
            <ManagePermissionsTabs  
              putPermission={putPermission}
              workspaceId={workspaceId}
              permissions={permissions}
              permissionStatus={permissionStatus}
              getUserPermissions={getUserPermissions}
              culture={culture} 
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getCountries: (options: object) => dispatch(getAllCountries(options)),
    getDepartments: (options: object) => dispatch(getAllDepartaments(options)),
    getUsers: (options: object) => dispatch(getManageUsers(options)),
    getUserPermissions: (options: object) => dispatch(getUserPermissions(options)),
    putPermission: (options: object) => dispatch(putPermission(options)),
    postUserActivate: (options: object) => dispatch(postUserActivate(options)),
    postUserDeactivate: (options: object) => dispatch(postUserDeactivate(options)),
    postImportUsers: (options: object) => dispatch(postImportUsers(options)),
    getAllManageGroups: (options: object) => dispatch(getAllManageGroups(options)),
    postInviteUser: (options: object) => dispatch(postInviteUser(options)),
    postSchedule: (options: object) => dispatch(postSchedule(options)),
    putReinvite: (options: object) => dispatch(putReinvite(options)),
    deleteInvite: (options: object) => dispatch(deleteInvite(options)),
    putReschedule: (options: object) => dispatch(putReschedule(options)),
    putAssignGroups: (options: object) => dispatch(putAssignGroups(options)),
    postToken: (options: object) => dispatch(postToken(options))
  };
};

const mapStateToProps = (store:IAppState) => ({
  culture: store.cultureState,
  permissions: store.manageUsersState.permissions,
  permissionStatus: store.manageUsersState.permissionStatus,
  activateStatus: store.manageUsersState.activateStatus,
  users: store.manageUsersState.users,
  userStatus: store.manageUsersState.userStatus,
  workspaceId: store.authState.auth.workspaceId,
  allGroups: store.manageGroupsState.allGroups,
  departaments: store.usersState.departaments,
  countries: store.usersState.countries,
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
