import {
  Box,
  Tab,
  AppBar,
  Grid,
  Input,
  Button,
  TextField,
  Typography,
  useTheme,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  LinearProgress,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import XDialog from "common/X/XDialog";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import XSelect from "../../../../common/X/XSelect";
import Resource, { resource } from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TextCounter from "common/UI/Text/TextCounter";
import CloseIcon from "@material-ui/icons/Close";
import { random } from "utils/random";
import {IAllManageGroups, ICultureTag} from "redux/types/manageGroups";
import { toast } from "react-toastify";
import DialogManageUsersScheduleInvite from "../Dialogs/DialogManageUsersScheduleInvite";
import { guid } from "utils/guidGeneretor";
import {getCulturesTags} from "../../../../redux/actions/CultureActions";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  culture: ICultureState;
  postImportUsers: any;
  workspaceId: string;
  getAllManageGroups: any;
  postInviteUser: any;
  allGroups: IAllManageGroups[];
  postSchedule: any;
  postToken: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSummaryRoot: {
      padding: 0,
    },
    accordionSummaryContent: {
      alignItems: "center",
    },
    accordionDetails: {
      padding: 0,
    },
    tabPanel: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    customButton: {
      padding: 0,
      borderRadius: "12%",
      marginLeft: 16,
      backgroundColor: theme.palette.grey[200],
    },
    deleteConfimartion: {
      color: "#EB5757",
      fontWeight: 500,
      textDecoration: "underline",
      cursor: "pointer",
    },
  })
);

function DialogInviteToWorkspace(props: IProps) {
  const {
    culture,
    open,
    setOpen,
    postImportUsers,
    workspaceId,
    getAllManageGroups,
    allGroups,
    postInviteUser,
    postSchedule,
    postToken,
  } = props;
  const [tab, setTab] = useState("1");
  const theme = useTheme();
  const [message, setMessage] = useState("");
  const [expanded, setExpanded] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>();
  const [fileLength, setFileLength] = useState<number | null>();
  const [linearLoading, setLinearLoading] = useState(false);
  const [deleteCSV, setDeleteCSV] = useState(false);
  const [group, setGroup] = useState("");
  const [language, setLanguage] = useState("");
  const [permission, setPermission] = useState("");
  const [groupLink, setGroupLink] = useState("");
  const [permissionLink, setPermissionLink] = useState("");
  const [emails, setEmails] = useState("");
  const [openSchedule, setOpenSchedule] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(Date.now());
  const [token, setToken] = useState("");
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const permissions = [
    {
      id: 3,
      name: resource(
        props?.culture,
        "ManageUsers::Participant"
      ),
    },
    {
      id: 2,
      name: resource(
        props?.culture,
        "ManageUsers::ChallengeManager"
      ),
    },
    {
      id: 1,
      name: resource(props?.culture, "ManageUsers::Admin"),
    },
  ]
  useEffect(() => {
    getAllManageGroups({
      workspaceId,
      cultureTag: culture?.culture?.cultureTag,
      culture
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllManageGroups, workspaceId]);
  const handleChange = (event: any, newValue: string) => {
    newValue === "2" && setToken(guid());
    setTab(newValue);
  };

  const handleFileClick = () =>
    inputFileRef.current && inputFileRef.current.click();

  const returnRandomString = () => random().toString(36);
  const [inputKey, setInputKey] = useState(returnRandomString());

  const handleFileChange = (e: any) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function (evt) {
        if (evt.target?.result) {
          setFileLength(
            evt.target?.result?.toString().split(String.fromCharCode(10))
              .length - 1
          );
        }
      };
      setFile(file);
    }
  };

  useEffect(() => {
    setLinearLoading(true);
  }, [file, setLinearLoading]);
  useEffect(() => {
    setFile(null);
    setFileLength(null);
    setDeleteCSV(false);
    setMessage("");
    setExpanded(true);
    setGroup("");
    setPermission("");
    setPermissionLink("");
    setGroupLink("");
    setEmails("");
    setLanguage("");
    setOpenSchedule(false);
    setScheduledDate(Date.now());
    setToken("");
    setTab("1");
  }, [open]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLinearLoading(false);
    }, 1100);

    return () => clearTimeout(timer);
  }, [linearLoading, setLinearLoading]);

  useEffect(() => {
    permissionLink !== "Select" && permissionLink &&
      postToken({
        token,
        groups: allGroups && allGroups?.filter(x => x.title === groupLink).map(y => y.groupId),
        permissionType: permissions?.filter(x => x.name === permissionLink)[0].id,
        workspaceId,
        culture
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionLink, groupLink, token]);

  const handleDeleteFile = () => {
    setInputKey(returnRandomString());
    setFile(null);
    setFileLength(null);
    setDeleteCSV(false);
  };
  const baseLink = `${window.location.origin}/app/newUserLink/${token}`;

  const postInvite = () => {
    file && message && lang
      ? postImportUsers({
        privateMessage: message,
        file,
        workspaceId,
        culture,
        resourceId: lang
      }).then(() => setOpen(false))
      : emails && permission && group
        ? postInviteUser({
          workspaceId,
          resourceId: lang,
          emails: emails.split(",").map(element => element.trim()),
          permissionType:
            permission === resource(props?.culture, "ManageUsers::Participant")
              ? 3
              : permission ===
                resource(props?.culture, "ManageUsers::ChallengeManager")
                ? 2
                : 1,
          groups: allGroups ? allGroups?.filter((data) => data.title === group).map((group) => group.groupId) : [],
          culture
        }).then(() =>
          setOpen(false),
        )
        : toast.error(resource(props?.culture, "Errors::Error_MissingParameters").split(':')[0], { toastId: 'treat-error-toast' });
  };
  const postScheduleInvite = () => {
    const emailArr = emails?.split(";");
    postSchedule({
      workspaceId,
      resourceId: lang,
      scheduleDate: new Date(scheduledDate),
      emails: emailArr ? emailArr : "",
      culture,
      permissionType:
        permission === resource(props?.culture, "ManageUsers::Participant")
          ? 3
          : permission ===
            resource(props?.culture, "ManageUsers::ChallengeManager")
            ? 2
            : 1,
      groups: allGroups ? allGroups?.filter((data) => data.title === group).map((group) => group.groupId) : [],
    }).then(() => setOpen(false));
  };

  const [languages, setLanguages] = useState<ICultureTag[]>([]);
  const [lang, setLang] = useState("");

  useEffect(() => {
    getCulturesTags().then((result) => {
      if (result) {
        setLanguages(result);
      }
    });
  }, []);

  useEffect(() => {
    languages.map((langs, index) => {
      if (langs.cultureTag == language) {
        setLang(langs.resourceId);
      }
    });
  }, [language]);

  function clipboard() {
    let text = document.getElementById('inputLink') as HTMLInputElement;
    text?.select()
    document.execCommand('copy')
  }

  let disabled = true

  if (!permission === false && permission !== 'Select') {
    if (!lang === false) {
      if(!message === false){
        if (!emails === false) {
          disabled = false
        } else if(!file === false){
          disabled = false
        }
      }
    }
  } else if (!message === false && !file === false && !lang === false) {
    disabled = false
  }

  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      title={<Resource tag="PageManageUsers::InviteToWorkspace" />}
      actions={
        openSchedule ? (
          <Grid container>
            <Grid item xs={12}>
              <Box textAlign="right">
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={() => setOpenSchedule(false)}
                  style={{ marginRight: 8 }}
                >
                  <Resource tag="PageManageUsers::Cancel" />
                </Button>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => postScheduleInvite()}
                >
                  <Resource tag="PageManageUsers::SaveChanges" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : (
          ""
        )
      }
      maxWidth={"md"}
    >
      {openSchedule ? (
        <DialogManageUsersScheduleInvite
          culture={culture}
          setScheduledDate={setScheduledDate}
          scheduledDate={scheduledDate}
          workspaceId={workspaceId}
          setOpen={setOpenSchedule}
        />
      ) : (
        <Box>
          <TabContext value={tab}>
            <AppBar position="static" elevation={0}>
              <TabList onChange={handleChange}>
                <Tab
                  value="1"
                  label={resource(culture, "PageManageUsers::InviteWithEmail")}
                />
                <Tab
                  value="2"
                  label={resource(culture, "PageManageUsers::InviteWithLink")}
                />
              </TabList>
            </AppBar>

            <TabPanel value={"1"} className={classes.tabPanel}>
              <Box mt={3}>
                <Grid container spacing={2}>
                  <Grid item sm={3}>
                    <XSelect
                      inputLabel={resource(
                        culture,
                        "PageManageUsers::Permissions"
                      )}
                      value={permission}
                      setValue={setPermission}
                      options={permissions}
                      optionId="id"
                      optionName="name"
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <XSelect
                      inputLabel={resource(culture, "PageManageUsers::Groups")}
                      options={allGroups}
                      value={group}
                      setValue={setGroup}
                      optionId="groupId"
                      optionName="title"
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <XSelect
                      inputLabel={resource(culture, "PageManageUsers::Language")}
                      options={languages}
                      value={language}
                      setValue={setLanguage}
                      optionId="resourceId"
                      optionName="cultureTag"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mt={4}>
                      <TextField
                        label={resource(culture, "PageManageUsers::Email")}
                        fullWidth
                        placeholder={resource(
                          culture,
                          "PageManageUsers::InsertEmail"
                        )}
                        multiline
                        value={emails}
                        onChange={(e) => setEmails(e.target.value)}
                        rows={2}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Box display="flex" justifyContent="center">
                    <Box
                      width={25}
                      justifyContent="center"
                      padding={0.5}
                      textAlign="center"
                      fontWeight={500}
                      bgcolor="#ddd"
                      color="#777"
                    >
                      <Resource tag="PageManageUsers::Or" />
                    </Box>
                  </Box>

                  <Box mt={3}>
                    <Typography variant="h4" color="textPrimary">
                      <Resource tag="PageManageUsers::UploadCSV" />
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body2" color="textSecondary">
                        <Resource tag="PageManageUsers::UploadTemplate" />
                      </Typography>
                    </Box>

                    <Box mt={3} alignItems="center">
                      <Grid container>
                        <Grid item xs={12} sm={3}>
                          <Box display="flex" alignItems="center" height="100%">
                            <Button color="primary" onClick={() => {
                              let link = document.createElement("a");
                              link.download = "InviteUserExample.csv";
                              link.href = "https://exago-storage.s3-sa-east-1.amazonaws.com/templates/InviteUserExample.csv";
                              link.click();
                            }} startIcon="1.">
                              <Resource tag="PageManageUsers::DownloadTemplate" />
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={1}>
                          <Box
                            textAlign="center"
                            display="flex"
                            alignItems="center"
                            height="100%"
                            mb={mobile ? 2 : ""}
                          >
                            <ChevronRightIcon />
                          </Box>
                        </Grid>
                        <Grid container item xs={12} sm={8}>
                          <Grid item xs={12} sm={7} md={5}>
                            <Button
                              color="primary"
                              startIcon="2."
                              endIcon={<AttachFileIcon />}
                              variant="contained"
                              style={{
                                backgroundColor: !file
                                  ? theme.palette.primary.light
                                  : "",
                                color: !file ? theme.palette.primary.main : "",
                                boxShadow: "none",
                              }}
                              onClick={handleFileClick}
                              disabled={!!file}
                            >
                              <Resource tag="PageManageUsers::AddCSV" />
                            </Button>
                            <input
                              type="file"
                              accept=".csv"
                              ref={inputFileRef}
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              key={inputKey}
                            />
                          </Grid>
                          <Grid item xs={12} sm={5} md={7}>
                            {file && (
                              <Box display="flex" flexDirection="column">
                                <Box my={mobile ? 1 : ""}>
                                  <Typography variant="caption">
                                    {file.name}
                                  </Typography>
                                  {!linearLoading && !deleteCSV && (
                                    <IconButton
                                      className={classes.customButton}
                                      onClick={() => setDeleteCSV(true)}
                                    >
                                      <CloseIcon fontSize={"small"} />
                                    </IconButton>
                                  )}
                                </Box>
                                {!deleteCSV ? (
                                  <>
                                    {fileLength && !linearLoading && (
                                      <Typography
                                        variant="caption"
                                        style={{ color: "#27AE60" }}
                                      >
                                        <Resource
                                          tag="PageManageUsers::UsersImported"
                                          args={[fileLength]}
                                        />
                                      </Typography>
                                    )}

                                    {linearLoading && (
                                      <Box>
                                        <LinearProgress />
                                      </Box>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      variant="caption"
                                      style={{ color: "#EB5757" }}
                                    >
                                      <Resource tag="PageManageUsers::EliminateAllUsers" />{" "}
                                      <Box
                                        component="span"
                                        className={classes.deleteConfimartion}
                                        onClick={handleDeleteFile}
                                      >
                                        <Resource tag="Common::Yes" />
                                      </Box>{" "}
                                      <Box
                                        component="span"
                                        className={classes.deleteConfimartion}
                                        onClick={() => setDeleteCSV(false)}
                                      >
                                        <Resource tag="Common::No" />
                                      </Box>
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box mt={2}>
                      <Accordion
                        elevation={0}
                        expanded={expanded}
                        onChange={() => setExpanded(!expanded)}
                      >
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className={classes.accordionSummaryRoot}
                          classes={{
                            content: classes.accordionSummaryContent,
                          }}
                        >
                          <Typography
                            color="primary"
                            style={{ fontWeight: 500 }}
                          >
                            * <Resource tag="PageManageUsers::PreviewMessage" />
                          </Typography>

                          {!expanded ? (
                            <IconButton color="primary">
                              <ExpandMoreIcon color="primary" />
                            </IconButton>
                          ) : (
                            <IconButton color="primary">
                              <ExpandLessIcon color="primary" />
                            </IconButton>
                          )}
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <TextCounter
                            maxLength={450}
                            label={<><Resource tag="PageManageUsers::Message" /> *</>}
                            fullWidth
                            multiline
                            rows={2}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>
                </Box>
                <Box mt={2} textAlign="right">
                  <Box component="span" mr={1}>
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={() => setOpen(false)}
                    >
                      <Resource tag="PageManageUsers::Cancel" />
                    </Button>
                  </Box>
                  <Box component="span" mr={1}>
                    <Button
                      onClick={() => setOpenSchedule(true)}
                      size="small"
                      color="primary"
                      variant="contained"
                      disabled={disabled}
                    >
                      <Resource tag="PageManageUsers::Schedule" />
                    </Button>
                  </Box>
                  <Box component="span">
                    <Button
                      onClick={postInvite}
                      disabled={disabled}
                      size="small"
                      color="primary"
                      variant="contained"
                    >
                      <Resource tag="PageManageUsers::InviteNow" />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </TabPanel>

            <TabPanel value={"2"} className={classes.tabPanel}>
              <Box>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <XSelect
                        inputLabel={resource(
                          culture,
                          "PageManageUsers::Permissions"
                        )}
                        value={permissionLink}
                        setValue={setPermissionLink}
                        options={permissions}
                        optionId="id"
                        optionName="name"
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <XSelect
                        inputLabel={resource(
                          culture,
                          "PageManageUsers::Groups"
                        )}
                        options={allGroups}
                        value={groupLink}
                        setValue={setGroupLink}
                        optionId="groupId"
                        optionName="title"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={5} mb={4}>
                  <Grid container spacing={4}>
                    <Grid item sm={10}>
                      <Input
                        id='inputLink'
                        fullWidth
                        readOnly
                        value={permissionLink !== "Select" ? baseLink : ""}
                      />
                    </Grid>
                    <Grid item sm={2}>
                      <Box textAlign="right">
                        <Button
                          color="primary"
                          disabled={permissionLink === "Select"}
                          variant="contained"
                          onClick={() => clipboard()}
                        >
                          <Resource tag="PageManageUsers::CopyLink" />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </XDialog>
  );
}

export default DialogInviteToWorkspace;
