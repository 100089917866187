import { AnyAction } from "redux";
import Truncate from "react-truncate";
import { connect } from "react-redux";
import XDialog from "common/X/XDialog";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect, useState } from "react";
import SettingsSelect from "../../common/X/XSelect";
import { getAllUsers } from "redux/actions/UsersAction";
import UserListItem from "common/UI/List/User/UserListItem";
import { ICultureState } from "redux/reducers/cultureReducer";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import XAutocomplete from "common/UI/XAutocomplete/XAutocomplete";
import Resource, { resource } from "components/Resources/Resource";
import TextInputWCounter from "../../common/UI/Text/TextCounter";
import Steps from "components/Steps/Steps";
import {
  Typography,
  Box,
  Grid,
  Button,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  Avatar,
  useTheme,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import { ICountries, IDepartments, IUsers, IUsersState } from "redux/reducers/usersReducers";
import {
  getAllManageGroups,
  getManageGroups,
  getManageGroupsMembers,
  postManageGroups,
  putManageGroups,
} from "redux/actions/manageGroups/manageGroupsAction";
import { random } from "utils/random";

interface IProps {
  type: "create" | "edit";
  open: boolean;
  setOpen: (open: boolean) => void;
  groupData?: any;
  users: IUsers[];
  countries?: ICountries[];
  departments?: IDepartments[];
  culture: ICultureState;
  workspaceId?: string;
  groupUsers: IUsers[];
}

interface IDispatchProps {
  getAllUsers: (options: object) => Promise<IUsersState>;
  getManageGroups: (options: object) => Promise<any>;
  postManageGroups: (options: object) => Promise<any>;
  putManageGroups: (options: object) => Promise<any>;
  getAllManageGroups: (options: object) => Promise<any>;
  getGroupUsers: (options: object) => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    actualManagers: {
      marginRight: "auto",
      marginTop: theme.spacing(2),
      minWidth: "300px",
      width: "100%",
      gap: "24px",
      maxHeight: "150px",
      overflowY: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",

      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    listButton: {
      width: 25,
      height: 25,
      borderRadius: 5,
      boxShadow: "0 0 5px " + theme.palette.grey[400],
      marginTop: 4,
      marginBottom: 4,

      "&:hover": {
        borderRadius: 5,
      },
    },
  })
);

function CreateGroupDialog(props: IProps & IDispatchProps) {
  const {
    type,
    open,
    countries,
    departments,
    groupData,
    culture,
    workspaceId,
    users,
    groupUsers,
    setOpen,
    getAllUsers,
    getManageGroups,
    postManageGroups,
    putManageGroups,
    getAllManageGroups,
    getGroupUsers
  } = props;

  const {cultureTag} = culture?.culture ?? ""

  useEffect(() => {
    getAllUsers({ q: "" });   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(groupData?.groupId){
      getGroupUsers({workspaceId,groupId: groupData?.groupId, cultureTag, pageSize:100})
    }
  }, [getGroupUsers, workspaceId, groupData?.groupId, cultureTag ]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const [activeIndex, setActive] = useState(1);
  const [nameValue, setNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [autoCompleteValue, setAutoCompleteValue] = useState<any[]>([]);
  const [countriesActive, setCountriesActive] = useState<string[]>([]);
  const [departmentsActive, setDepartmentsActive] = useState<string[]>([]);

  useEffect(() => {
    setActive(1);
    setAutoCompleteValue([]);

    if (type === "create") {
      setNameValue("");
      setDescriptionValue("");
      setCountriesActive([]);
      setDepartmentsActive([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    setAutoCompleteValue(groupUsers);
  }, [groupUsers, setAutoCompleteValue]);

  const handleAutoComlpeteValue = (value: any) => {
    if (value?.length) {
      setAutoCompleteValue(value);
    }
  };

  const handleDeleteValue = async (value: any) => {
    setAutoCompleteValue(autoCompleteValue.filter((x) => x.userId !== value));
  };

  const handleChange = (value: any) => {
    setCountriesActive(value);
  };

  const handleDepChange = (value: any) => {
    setDepartmentsActive(value);
  };

  useEffect(() => {
    if (groupData) {
      setNameValue(groupData?.cultures ? groupData?.cultures[0]?.title : "");
      setDescriptionValue(groupData?.cultures ? groupData?.cultures[0]?.description : "");

      if(groupData?.groupId){
        setAutoCompleteValue([]);
      }

      if (groupData?.countries) {
        countries &&
          setCountriesActive(
            countries
              ?.filter(data => groupData?.countries.includes(data?.countryId))
              .map((country) => country.name)
          );
      }

      if (groupData?.departments) {
        departments &&
          setDepartmentsActive(
            departments
              ?.filter(data => groupData?.departments.includes(data?.departmentId))
              .map((department) => department.title)
          );
      }
    }
    // eslint-disable-next-line
  }, [groupData]);

  function createGroup() {
    let cultures = [
      {
        cultureTag: culture.culture.cultureTag,
        title: nameValue,
        description: descriptionValue,
      },
    ];

    const countrieSelected = countries
      ? countries?.filter((data, index) => data.name === countriesActive[index]).map((country) => country.countryId)
      : [];

    const departamentSelected = departments
      ? departments
          ?.filter((data, index) => data.title === departmentsActive[index])
          .map((department) => department.departmentId)
      : [];

    type === "edit"
      ? putManageGroups({
          groupId: groupData?.groupId,
          workspaceId,
          cultures,
          countrieSelected,
          departamentSelected,
          culture,
          userIds: autoCompleteValue?.map(x => x.userId) || []
        }).then(() => {
          getManageGroups({
            workspaceId,
            cultureTag: culture.culture.cultureTag,
          });
        })
      : postManageGroups({
          workspaceId,
          cultures,
          countrieSelected,
          departamentSelected,
          culture,
          userIds: autoCompleteValue?.map(x => x.userId)  || []
        }).then(() => {
          getManageGroups({
            workspaceId,
            cultureTag: culture.culture.cultureTag,
          });
          getAllManageGroups({
            workspaceId,
            cultureTag: culture.culture.cultureTag,
          });
        });

    setOpen(false);
  }

  const buttonArr = [
    {
      title: <Resource tag="PageManageGroups::Details" />,
      form: (
        <Box my={3}>
          <Box mb={3}>
            <TextInputWCounter
              maxLength={50}
              label={<Resource tag="ManageGroups::Name" />}
              placeholder={resource(culture, "ManageGroups::Name_Tip")}
              fullWidth
              onChange={(e) => setNameValue(e.target.value)}
              value={nameValue}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box>
            <TextInputWCounter
              label={
                <Box>
                  <Box component="span" mr={1}>
                    <Resource tag="ManageGroups::Description" />
                  </Box>
                  <Box component="span" fontWeight={400} style={{ textTransform: "lowercase" }}>
                    (<Resource tag="ManageGroups::Optional" />)
                  </Box>
                </Box>
              }
              placeholder={resource(culture, "ManageGroups::Description_Tip")}
              maxLength={500}
              fullWidth
              multiline
              rows={4}
              onChange={(e) => setDescriptionValue(e.target.value)}
              value={descriptionValue}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
      ),
    },
    {
      title:  <Resource tag="PageManageGroups::Members" />,
      form: (
        <Box>
          <Typography variant="h4">
            <Resource tag="ManageGroups::AssignToTheGroup" />
          </Typography>
          <Box mt={1}>
            <Typography variant="body2" color="textSecondary">
              <Resource tag="ManageGroups::AssignToTheGroup_Tip" />
            </Typography>
          </Box>

          <Box mt={3.5}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <SettingsSelect
                  inputLabel={<Resource tag="ManageGroups::Country" />}
                  multiple
                  value={countriesActive}
                  setValue={handleChange}
                  options={countries}
                  optionId={"countryId"}
                  optionName={"name"}
                  selectedResource={resource(culture, "XSelect::selected")}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <SettingsSelect
                  inputLabel={<Resource tag="ManageGroups::Departament" />}
                  multiple
                  value={departmentsActive}
                  setValue={handleDepChange}
                  options={departments}
                  optionId={"departmentId"}
                  optionName={"title"}
                  selectedResource={resource(culture, "XSelect::selected")}
                />
              </Grid>
            </Grid>
          </Box>
          <Box position="relative" marginY={3} display="flex" justifyContent="center">
            <Box position="absolute" width="100%" zIndex={0} top="13px" borderBottom="1px solid #313B53"></Box>
            <Box
              width={25}
              justifyContent="center"
              padding={0.5}
              textAlign="center"
              fontWeight={500}
              bgcolor="#ddd"
              color="#777"
              zIndex={1}
            >
              <Resource tag="PageManageUsers::Or" />
            </Box>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">
                <Resource tag="PageManageGroups::FindSpecificUsers" /> .....
              </Typography>
              <Box mt={0.5}>
                <XAutocomplete
                  culture={culture}
                  options={users}
                  optionName={"fullName"}
                  optionAvatar={"photo"}
                  optionSubtitle={"email"}
                  autoCompleteValue={autoCompleteValue || []}
                  onChange={(newValue) => handleAutoComlpeteValue(newValue)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box className={classes.actualManagers} mx={mobile ? "auto" : ""}>
                {autoCompleteValue?.map((value: any, index: number) => {
                  return (
                    <div className="manager-item-containerPopup" key={random()}>
                      <Avatar src={value?.photo} alt={value.fullName} className={classes.thumbnail} />
                      <div className="manager-item-label">
                        <Truncate width={120} ellipsis={<span>...</span>}>
                          {value.fullName}
                        </Truncate>
                      </div>
                      <div className="manage-item-cross">
                        <IconButton
                          className="bottom-manage-item-cross"
                          onClick={() => handleDeleteValue(value.userId)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      title:  <Resource tag="PageManageGroups::Confirmation" />,
      form: (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Typography variant="h4">
                {type === "create" ? <Resource tag="PageManageGroups::ConfirmCreate" /> : <Resource tag="PageManageGroups::ConfirmEdit" />}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <XAutocomplete
                culture={culture}
                options={users}
                optionName={"fullName"}
                optionAvatar={"photo"}
                optionSubtitle={"email"}
                autoCompleteValue={autoCompleteValue || []}
                onChange={(newValue) => handleAutoComlpeteValue(newValue)}
              />
            </Grid>
          </Grid>
          <Box marginY={2}>
            <Grid container alignItems="center" item wrap="nowrap" xs={12} sm={12} md={6} lg={6} zeroMinWidth>
              <Box maxWidth="100%" display="flex" alignItems="center" gridGap={10}>
                <Typography variant="body1" noWrap>
                  <strong>{nameValue}</strong>
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography variant="caption" color="textSecondary" noWrap>
                  {autoCompleteValue?.length} <Resource tag="PageSettings::members" />
                </Typography>
              </Box>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            {autoCompleteValue?.length > 0 && autoCompleteValue.map((data, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={5} lg={5}>
                  <UserListItem
                    userId={data?.userId}
                    culture={culture}
                    permissionTab
                    alignItems
                    principalText={data.fullName}
                    subtitle={data.email}
                    photo={data.photo}
                    finalContent={
                      <IconButton className="bottom-manage-item-cross" onClick={() => handleDeleteValue(data.userId)}>
                        <HighlightOffIcon color="disabled" />
                      </IconButton>
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ),
    },
  ];

  const actions = (
    <Grid container>
      <Grid item xs={12}>
        <Box textAlign="right">
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={() => setOpen(false)}
            style={{ marginRight: 8 }}
          >
            <Resource tag="PageCreateChallenge::Cancel" />
          </Button>

          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => (activeIndex < buttonArr.length ? setActive(activeIndex + 1) : createGroup())}
          >
            {activeIndex !== buttonArr.length ? (
              <Resource tag="PageCreateChallenge::Next" />
            ) : type === "create" ? (
              <Resource tag="ManageGroups::AddGroup" />
            ) : (
              <Resource tag="ManageGroups::SaveChanges" />
            )}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      height="400px"
      maxWidth="md"
      title={
        type === "create" ? <Resource tag="ManageGroups::CreateGroup" /> : <Resource tag="ManageGroups::EditGroup" />
      }
      actions={actions}
    >
      <Steps setActive={setActive} activeIndex={activeIndex} contentArr={buttonArr} />
      {buttonArr.map((data: any, index: any) => (
        <Box key={index} mt={2.5}>
          {index + 1 === activeIndex && <>{data.form}</>}
        </Box>
      ))}
    </XDialog>
  );
}

const mapStateToProps = (store: IAppState) => ({
  culture: store.cultureState,
  users: store.usersState.users,
  workspaceId: store.authState.auth.workspaceId,
  groupUsers: store.manageGroupsState.groupMembers.result
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => ({
  getAllUsers: (options: object) => dispatch(getAllUsers(options)),
  getManageGroups: (options: object) => dispatch(getManageGroups(options)),
  postManageGroups: (options: object) => dispatch(postManageGroups(options)),
  putManageGroups: (options: object) => dispatch(putManageGroups(options)),
  getAllManageGroups: (options: object) => dispatch(getAllManageGroups(options)),
  getGroupUsers: (options: object) => dispatch(getManageGroupsMembers(options))
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupDialog);
