import "./NewsList.css";
import { Grid } from "@material-ui/core";
import NewsCard from "components/NewsCard/NewsCard";
import { IRelatedNews } from "redux/reducers/news/newsDetailReducer";
import { IResultNews, IResultNewsHighlighted, IResultNewsPopular } from "redux/types/news";

interface IProps {
  arrNews: IResultNews[] | IRelatedNews[] | IResultNewsPopular[] | IResultNewsHighlighted[];
  isLoading?: boolean;
  moreOptions?: boolean;
  handleDelete?: (newsId: string) => void;
  handleHighlight?: (newsId: string, isHighlighted: boolean) => void;
}

function NewsList(props: IProps) {
  const { arrNews, handleDelete, handleHighlight, moreOptions } = props;
  const newsArr = arrNews as Array<IResultNews | IRelatedNews | IResultNewsPopular | IResultNewsHighlighted>;

  return (
    <Grid container spacing={2}>
      {arrNews &&
        newsArr.map((news: any, index: number) => (
          <Grid item md={4} sm={12} style={{ width: "100%" }} key={index}>
            <NewsCard
              moreOptions={moreOptions??true}
              handleHighlight={handleHighlight!}
              handleDelete={handleDelete!}
              id={news.newsId}
              news={news}
              key={news.newsId}
            />
          </Grid>
        ))}
    </Grid>
  );
}

export default NewsList;
