import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IRanking, IMyRanking, IRankingsState, IRankingUsers } from '../reducers/rankingsReducer';
import { api_general, api_v2 } from "../../config/api";
import { treatError } from "../../utils/treatError";
import reactNewApp from 'utils/reactNewApp';

const basePath = "rankings";

export enum RankingActionTypes {
    GET_RANKING = 'GET_RANKING',
    SEARCH_RANKING = 'SEARCH_RANKING'
}

export interface IRankingAction {
    type: RankingActionTypes.GET_RANKING;
    pagination: IRanking;
    myRanking: IMyRanking
}

export interface ISearchRankingAction {
    type: RankingActionTypes.SEARCH_RANKING;
    top3Users: IRankingUsers[],
    usersFounded:IRankingUsers[],
    missingPoints: number,
    fullName: string,
    photo: string,
    rankingPosition: number,
    rankingPoints: number,
    resourceYou: string
}



export type RankingsActions =
| IRankingAction
| ISearchRankingAction

export const getRanking: ActionCreator<ThunkAction<Promise<any>, IRankingsState, null, IRankingAction>> = (options) => {
    return async (dispatch: Dispatch) => {
        try {
            let params    = [];
            let params_qs = "";

            if( options.hasOwnProperty("pageNumber")) {
                params.push(`pageNumber=${options.pageNumber}`)
            }
            if( options.hasOwnProperty("pageSize")) {
                params.push(`pageSize=${options.pageSize}`)
            }
            params_qs = `?${params.join("&")}`;
            if(reactNewApp) {
                const response = await api_general.get(`${basePath}${params_qs}`,);
                dispatch({
                    pagination: response.data.pagination,
                    myRanking: response.data,
                    type: RankingActionTypes.GET_RANKING,
                });
            }else{
                const response = await api_v2.get(`${basePath}${params_qs}`,);
                dispatch({
                    pagination: response.data.pagination,
                    myRanking: response.data,
                    type: RankingActionTypes.GET_RANKING,
                });
            }
            
        } catch(err) {
            treatError(options?.culture, 'PageRanking::Header', err);
        }
    }
}

export const searchRanking: ActionCreator<ThunkAction<Promise<any>, IRankingsState, null, IRankingAction>> = (options) => {
    return async (dispatch: Dispatch) => {
        try {
            let params    = [];
            let params_qs = "";
            
            if( options.hasOwnProperty("searchTerm")) {
                params.push(`searchTerm=${options.searchTerm}`)
            }
            params_qs = `?${params.join("&")}`;
            if(reactNewApp) {
                const response = await api_general.get(`${basePath}/search-user${params_qs}`,);
                dispatch({
                    top3Users: response.data.top3Users,
                    usersFounded:response.data.usersFounded,
                    missingPoints: response.data.missingPoints,
                    fullName:  response.data.fullName,
                    photo:  response.data.photo,
                    rankingPosition:  response.data.rankingPosition,
                    rankingPoints:  response.data.rankingPoints,
                    resourceYou:  response.data.resourceYou,
                    type: RankingActionTypes.SEARCH_RANKING,
                });
            }else{
                const response = await api_v2.get(`${basePath}/search-user${params_qs}`,);
                dispatch({
                    top3Users: response.data.top3Users,
                    usersFounded:response.data.usersFounded,
                    missingPoints: response.data.missingPoints,
                    fullName:  response.data.fullName,
                    photo:  response.data.photo,
                    rankingPosition:  response.data.rankingPosition,
                    rankingPoints:  response.data.rankingPoints,
                    resourceYou:  response.data.resourceYou,
                    type: RankingActionTypes.SEARCH_RANKING,
                });
            }
            
        }catch(err) {
            treatError(options?.culture, 'PageRanking::Header', err);
        }
    }
}