import React from 'react'
import EMIcon from 'components/Icon/Icon';
import { TableRow, TableCell, Avatar, Typography, Hidden, Box, useTheme } from '@material-ui/core';
import useStyles from './RankingListItemStyle'

/* Example:
    <RankingListItem userid="1" avatar="http://i.stack.imgur.com/Dj7eP.jpg" name="Sebastian Vila" rank={4} points={12750} department="Sales" mySelf/>
*/
interface IProps {
    userid?: string;
    avatar: string;
    name: string;
    rank: number;
    points: number;
    department?: string;
    mySelf?: boolean;
    cultureTag:string;
}



export default function RankingListItem (props:IProps) {
    const { rank, avatar, name, department, points, mySelf, cultureTag } = props;
    const classes = useStyles();
    const theme = useTheme();

    return (
        <TableRow className={mySelf ? classes.rankingMySelf + " " + classes.rankingItem : classes.rankingItem}>
            <TableCell align="left" className={mySelf ? classes.rankingMySelfTd : ''}>
                <Box className={classes.rankingUser} component="span">
                    <Box className={classes.boxes}>
                        <Typography className={classes.rankingListItemRank} >{rank}</Typography>
                    </Box>
                    <Avatar src={avatar} alt={name} className={classes.rankingListItemAvatar}/>
                    <Box>
                        <Typography className={mySelf ? classes.rankingMySelf + " " + classes.rankingListItemName : classes.rankingListItemName} >
                            {name}
                        </Typography> 
                    </Box>
                </Box>
            </TableCell>      
            <TableCell align="center" className={mySelf ? classes.rankingMySelfTd : ''}>
                <Hidden smDown implementation="css">
                    <Typography className={classes.rankingListItemSector}>{department}</Typography>
                </Hidden>
            </TableCell>                                        
            <TableCell className={mySelf ? classes.rankingMySelfTd : ''}> 
                <Box className={classes.rankingListItemPoints}>
                    <Box className={classes.svgBox}>
                        <EMIcon color={theme.palette.text.secondary} icon="section-ranking" size={20} />
                    </Box>
                    <Typography className={classes.rankingListItemPointsP}>{points.toLocaleString(cultureTag)}</Typography>
                </Box>
            </TableCell>
        </TableRow> 
    )
}

