import React from "react";
import XCard from "common/X/XCard";
import history from "routes/history";
import newApp from "utils/reactNewApp";
import Resource from "components/Resources/Resource";
import { IResultChallengesInProgress } from "redux/types/challenges";
import { Grid, MenuItem, Typography, useTheme } from "@material-ui/core";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import { useSelector } from "react-redux";

import { IAppState } from "redux/store/Store";
import { api_general } from "config/api";

interface IProps {
  challengesInProgress: IResultChallengesInProgress;
}

function InProgressCard(props: IProps) {
  const { challengesInProgress } = props;
  const theme = useTheme();

  const store = useSelector(store => store) as IAppState;
  const permission = store.authState.auth.permission;



  const duplicateChallenge = async () => {
    const response = await api_general.post(`challenges/duplicate`, {
      challengeId: challengesInProgress.challengeId,
      workspaceId: store.authState.auth.workspaceId,
    });

    if (response?.data?.data) {
      history.push(`/createChallenge/${response?.data?.data}`);
    }
  };

  return (
    <XCard
      key={challengesInProgress?.challengeId}
      link={`/challenges/details/${challengesInProgress?.challengeId}`}
      color={"dark"}
      backgroundImage={challengesInProgress?.image}
      elevation={1}
      height={200}
      topContentPadding={20}
      topContent={
        <Grid container>
          <Grid container item xs={10}>
            <Typography variant="h4">{challengesInProgress?.title}</Typography>
          </Grid>
          {newApp && (permission === "Admin" || permission === "ChallengeManager") && (
            <Grid
              container
              alignItems="baseline"
              justify="flex-end"
              item
              xs={2}
            >
              <MoreOptionsButton
                translate="-107px, 42px"
                iconColor={theme.palette.primary.contrastText}
              >
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push(
                      `createChallenge/${challengesInProgress.challengeId}`
                    );
                  }}
                >
                  <Resource tag="PageChallenges::EditChallenge" />
                </MenuItem>
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    duplicateChallenge()
                  }}
                >
                  <Resource tag="PageChallenges::Duplicate" />
                </MenuItem>
              </MoreOptionsButton>
            </Grid>
          )}
        </Grid>
      }
    />
  );
}

export default InProgressCard;
