import React, { useState } from "react";
import Resource from "../Resources/Resource";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import {
  createStyles,
  Button,
  withStyles,
  InputBase,
  Box,
  Theme,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    btnBid: {
      padding: "6px 16px",
      right: "25px",
      minWidth: "75px",

      "&.Mui-disabled": {
        background: "#d5d5d7",
      },
    },
  })
);

const BidInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      fontSize: "0.8rem",
      padding: "4px 20px",
      backgroundColor: "#F2F3F5",
      color: theme.palette.text.secondary,
      borderRadius: "20px",
      border: "none",
      width: "150px",
      height: "27px",
      margin: "0 0 0 15px",
    },
  })
)(InputBase);

interface IProps {
  postBidPrize: (prizeId: string, value: number) => void;
  prizeId: string;
  cultureTag?: string;
  lastBidValue: any;
  userAllowToBid?: any;
}

function PrizeBid(props: IProps): JSX.Element {
  const classes = useStyles();
  const { cultureTag, lastBidValue, userAllowToBid } = props;
  const [newBidValue, setNewBidValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e: any) => {
    e.preventDefault();
    setNewBidValue(e.target.value);
  };

  const formatPoints = new Intl.NumberFormat(cultureTag).format(
    Number(lastBidValue)
  );

  const handleSubmit = () => {
    const { postBidPrize } = props;
    const params = {
      prizeId: props.prizeId,
      value: newBidValue,
    };
    postBidPrize(params.prizeId, Number(params.value));
  };

  //onKeyPress: to on keypress accept just numbers
  function onlynumber(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /^[0-9.]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  return (
    <Box display="flex" alignItems="center" minWidth="415px" className="prize-bid">
      <Box fontWeight={900}>
        <Resource tag="PagePrizes::BidForOneOfThen" />
      </Box>
      <BidInput
        placeholder={formatPoints}
        onChange={handleChange}
        inputMode="numeric"
        inputProps={{ maxLength: 13, min: formatPoints }}
        onKeyPress={onlynumber}
      />
      <Button
        className={classes.btnBid}
        color="primary"
        variant="contained"
        onClick={() => setIsOpen(true)}
        disabled={userAllowToBid === false}
      >
        <Resource tag={"PagePrizeDetail::Button_Bid"} args={[]} />
      </Button>
      <PopUpConfirmation
        closeButton={() => setIsOpen(false)}
        sendButton={handleSubmit}
        message={<Resource tag="Common::ConfirmAction" />}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Box>
  );
}

export default PrizeBid;
