import React, { useState } from "react";
import "./IdeaDetailCustomFields.scss";
import Resource from "../Resources/Resource";
import { Box, Button, Typography } from "@material-ui/core";
import sanitizeHtml from "sanitize-html";
import { securityTextEditor } from "utils/securityTextEditor";
import reactNewApp from "utils/reactNewApp";

interface IProps {
  customFields: any;
  alwaysOn?: boolean;
  expectedROI?: number;
  files: {
    fileId: string;
    fileUrl: string;
    fileName: string;
  }[];
}

function IdeaDetailCustomFields(props: IProps) {
  const [isShowOn, setShowOn] = useState(false);
  const { customFields, alwaysOn, expectedROI = 0, files } = props;

  const handleClick = () => {
    setShowOn(!isShowOn);
  };
  const formatedROI = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })?.format(expectedROI!);

  const handleListInputs = (value: any, customField: any) => {
    if (!value) return;

    if (customField?.typeId === 4) {
      const optionsKeys = Object.keys(customField?.options) as Array<string>;
      const optionsValues = Object.values(
        customField?.options
      ) as Array<string>;
      return optionsValues[optionsKeys?.indexOf(value)];
    } else if (customField?.typeId === 10) {
      const valueArray = value?.split(",");

      let optionsValues = [];

      for (const i in customField?.options) {
        if (valueArray?.indexOf(String(i)) !== -1) {
          optionsValues?.push(customField?.options[i]);
        }
      }
      return optionsValues?.map((x, index) => (
        <p key={index} style={{ margin: 0 }}>
          {x}
        </p>
      ));
    }
  };

  const handleListInputsV3 = (value: any, customField: any) => {
    if (!value) return;
    if (customField.type === 6 || customField.type === 7) {
      const teste = customField?.options?.filter(
        (x: any) => x?.ideaCustomFieldOptionId === value
      );
      return (
        <Typography variant="body2">
          {teste?.[0]?.cultures?.[0]?.title}
        </Typography>
      );
    } else if (customField?.type === 5) {
      const valueArray = value?.split(",");

      let optionsValues = [];

      for (const i in customField.options) {
        if (
          valueArray.includes(customField.options?.[i]?.ideaCustomFieldOptionId)
        ) {
          optionsValues.push(customField.options[i]);
        }
      }

      return optionsValues.map((data: any, index: any) => (
        <Typography key={index} variant="body2">
          - {data.cultures?.[0]?.title}
        </Typography>
      ));
    }
  };

  return (
    <Box className="ideaDetail-fields">
      {!alwaysOn && (
        <Button
          color="primary"
          style={{ marginLeft: "-7px" }}
          onClick={handleClick}
        >
          {isShowOn ? (
            <Resource tag={"PageDetailIdea::Link_LessDetail"} />
          ) : (
            <Resource tag={"PageDetailIdea::Link_MoreDetail"} />
          )}
        </Button>
      )}

      {(isShowOn || alwaysOn) && (
        <Box
          className={
            alwaysOn ? "keyTaks-genericFields" : "ideaDetail-genericFields"
          }
        >
          {customFields?.map((field: any, index: any) => {
            if (!reactNewApp) {
              const fieldValue =
                field?.customField?.typeId === 10 ||
                field?.customField?.typeId === 4
                  ? handleListInputs(field?.value, field?.customField)
                  : field?.value;
              return (
                <Box key={field?.customField?.customFieldId}>
                  {fieldValue ? (
                    <Box key={field?.customField?.customFieldId}>
                      <Typography variant="h4">
                        {field?.customField?.title && field?.customField?.title}
                      </Typography>
                      {field?.customField?.typeId === 9 ? (
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              String(fieldValue),
                              securityTextEditor
                            ),
                          }}
                        ></Box>
                      ) : (
                        <Box>
                          <Typography variant="body2">{fieldValue}</Typography>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              );
            } else {
              const fieldValue =
                field.customField.type === 5 ||
                field.customField.type === 6 ||
                field.customField.type === 7
                  ? handleListInputsV3(field.value, field.customField)
                  : field.value;
              return (
                <Box key={field?.customField?.ideaCustomFieldId}>
                  {fieldValue ? (
                    <Box key={field?.customField?.ideaCustomFieldId}>
                      <Typography variant="h4">
                        {field?.customField?.cultures[0]?.title &&
                          field?.customField?.cultures[0]?.title}
                      </Typography>
                      {field?.customField?.type === 9 ||
                      field?.customField?.type === 2 ? (
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              String(fieldValue),
                              securityTextEditor
                            ),
                          }}
                        ></Box>
                      ) : (
                        <Box>{fieldValue}</Box>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              );
            }
          })}
          <Box>
            <Typography variant="h4">
              <Resource tag="Stats::ExpectedROI" />{" "}
            </Typography>
            <Box>
              <Typography variant="body2">{formatedROI}</Typography>
            </Box>
          </Box>
          {isShowOn &&
            files?.length > 0 &&
            !files?.every(
              (x) =>
                x?.fileName?.split(".")[1] === "jpg" ||
                x?.fileName?.split(".")[1] === "bmp" ||
                x?.fileName?.split(".")[1] === "png" ||
                x?.fileName?.split(".")[1] === "jpeg" ||
                x?.fileName?.split(".")[1] === "gif" ||
                x?.fileName?.split(".")[1] === "webp" ||
                x?.fileName?.split(".")[1] === "svg"
            ) && (
              <Box>
                <Typography variant="h4">
                  <Resource tag="CustomFields::Attachment" />
                </Typography>
                {files &&
                  files?.map((file, index) => {
                    let fileType = file?.fileName?.split(".")[1];
                    if (
                      fileType === "jpg" ||
                      fileType === "bmp" ||
                      fileType === "png" ||
                      fileType === "jpeg" ||
                      fileType === "gif" ||
                      fileType === "webp" ||
                      fileType === "svg"
                    ) {
                      return "";
                    } else {
                      return (
                        <a
                          key={file?.fileId || index}
                          // target="_blank"
                          style={{ width: "100%", textDecoration: "none" }}
                          rel="noopener noreferrer"
                          href={file?.fileUrl}
                          download={file?.fileName}
                        >
                          <Typography variant="body2" color="primary">
                            {file?.fileName?.split("_")?.splice(1)?.concat()}
                          </Typography>
                        </a>
                      );
                    }
                  })}
              </Box>
            )}
        </Box>
      )}
    </Box>
  );
}

export default IdeaDetailCustomFields;
