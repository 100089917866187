import { useEffect, useState } from "react";
// Redux
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IApplication } from "redux/reducers/tenantReducer";
import { IChallengeAllList } from "redux/types/challenges";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IAppState } from "redux/store/Store";
import {
  getNewChallengeList,
  getAllChallengesInspiration,
  IChallengeGetNewListAction,
} from "redux/actions/ChallengeActions";

// Style
import "./ModalSelectAChallenge.css";

import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Grid,
  Divider,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Box,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { IAllManageGroups } from "redux/types/manageGroups";
import { api_v3 } from "config/api";
import Resource from "components/Resources/Resource";
import XDatepicker from "common/UI/XDatepicker/XDatepicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    gridItem: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "flex-end",
    },
    label: {
      margin: "10px 0",
      width: "100%",
      display: "block"
      // padding: "0.7rem 0.7rem",
    },
    bgLabel: {
      background: theme.palette.primary.light,
      padding: "0.7rem 0.7rem",
      borderRadius: "6px",
      margin: "5px 0",
      marginRight: "20px",
    },
    divider: {
      width: "90%",
      marginTop: "12px 0",
      marginLeft: "10px",
    },
    arrowForward: {
      color: "#fff",
      marginLeft: "20px",
    },
    arrowForwardDisabled: {
      color: "rgba(0, 0, 0, 0.26)",
      marginLeft: "20px",
    },
    gridContainer: {
      overflow: "scroll",
      maxHeight: "325px",
    },
    titleIdea: {
      color: "#313b53",
      fontSize: "15px",
    },
    link: {
      textDecoration: "none",
    },
  })
);

const pagination = {
  current: 1,
  pageSize: 10,
  finished: false,
};

const status = {
  init: true,
  refreshControl: false,
  isActive: true,
};

const options = {
  applicationId: 2,
  isActive: status.isActive,
  pageNumber: status.init || !status.isActive ? 1 : pagination.current + 1,
  pageSize: pagination.pageSize,
  culture: {},
};

interface IDispatchProps {
  getNewChallengeList: (options: object) => Promise<IChallengeGetNewListAction>;
  getAllChallengesInspiration: (options: object) => Promise<any>;
}

interface IProps {
  path: string;
  closeButton?: any;
  selectedChallenge?: string;
  challengeList: IChallengeAllList[];
  application: IApplication;
  culture: ICultureState;
  allGroups: IAllManageGroups[];
  workspaceId: string;
  setChallengeId?: (challenge: any) => void;
}

interface modelReportUser {
  "UserType": number,
  "TimePeriod": number,
  "Groups": string[],
  "WorkspaceId": string
}

interface modelReportPrize {
  "PrizeType": number,
  "AuctionState": number,
  "TimePeriodInitial": Date
  "TimePeriodFinal": Date,
  "WorkspaceId": string
}

interface modelReportComment {
  "TimePeriodInitial": Date,
  "TimePeriodFinal": Date,
  "Visibility": number,
  "Specific": object,
  "Objects": object,
  "WorkspaceId": string
}


interface modelReportUsersGroups {
  "WorkspaceId": string
}

interface modelReportEvaluations {
  "Challenge": string,
  "Phase": number,
  "WorkspaceId": string
}

interface modelReportIdeas {
  "Status": number,
  "ChallengeId": string,
  "WorkspaceId": string
}

interface sendRequest {
  url: string,
  body: modelReportUser | modelReportPrize | modelReportComment | modelReportUsersGroups | modelReportEvaluations | modelReportIdeas
}

const send = (request: sendRequest) => {
  return api_v3.post(request.url, request.body)
    .then(results => results)
    .catch(error => {
      throw Error(error);
    })
}


function ModalSelectFilterAnalytics(props: IProps & IDispatchProps) {
  const { getNewChallengeList } = props;
  const { path, allGroups, workspaceId } = props;
  const { challengeList, closeButton } = props;
  const [showMessage, setShowMessage] = useState(false);
  const [showMessageType, setShowMessageType] = useState(false)
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState({
    users: {
      timePeriod: 0,
      userType: 0,
      groups: Array<string>(),
      workspaceId: workspaceId
    },
    ideas: {
      status: -1,
      challenge: "",
      workspaceId: workspaceId
    },
    usergroups: {
      workspaceId: workspaceId
    },
    userAggregate: {
      workspaceId: workspaceId
    },
    evaluations: {
      challenge: "",
      phase: "",
      workspaceId: workspaceId
    },
    prizes: {
      type: 0,
      auctionState: 0,
      initial: new Date(),
      final: new Date(),
      workspaceId: workspaceId
    },
    comments: {
      initial: new Date(),
      final: new Date(),
      comment: 0,
      specific: {
        all: false,
        add: false,
        report: false
      },
      objects: {
        all: false,
        inspirations: false,
        ideas: false,
        news: false
      },
      workspaceId: workspaceId
    }
  })

  useEffect(() => {
    options.applicationId = props.application.applicationId;
    options.culture = props.culture;

    let isMounted = true;

    if (path === "usergroups") {
      //pre-fire show message
      setShowMessage(true);
      setShowMessageType(true);
      let usergroups: modelReportUsersGroups = {
        WorkspaceId: workspaceId
      }

      send({
        url: "reports/users-groups",
        body: usergroups
      }).then(r => {
        setShowMessage(true);
        setShowMessageType(true);
      })
        .catch(err => {
          setShowMessage(true);
          setShowMessageType(false)
        });

    } else if (path === "userAggregate") {
      setShowMessage(true);
      setShowMessageType(true);
      let userAggregate:any = {
        WorkspaceId: workspaceId
      }

      send({
        url: "reports/userAggregate",
        body: userAggregate
      }).then(r => {
        setShowMessage(true);
        setShowMessageType(true);
      })
        .catch(err => {
          setShowMessage(true);
          setShowMessageType(false)
        });
    } else if (path === "ideas") {
      isMounted && getNewChallengeList(options).then((result) => {
        setLoading(false)
      })
    }

    setLoading(false)
    return () => {
      isMounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const checkAllowExportAction = () => {
    switch (path) {
      case "userAggregate":
      case "userGroups":
        return true;
      case "users":
        return filters.users.groups.length === 0;
      case "prizes":
        return !filters.prizes.initial || filters.prizes.initial > new Date("2100-01-01") || filters.prizes.initial < new Date("1900-01-01")
          || !filters.prizes.final || filters.prizes.final > new Date("2100-01-01") || filters.prizes.final < new Date("1900-01-01");
      case "ideas":
        return !filters.ideas.challenge;
      case "evaluations":
        return !filters.evaluations.challenge || !filters.evaluations.phase;
      case "comments":
        return (!filters.comments.objects.all
          && !filters.comments.objects.ideas
          && !filters.comments.objects.inspirations
          && !filters.comments.objects.news) || (
            !filters.comments.specific.add
            && !filters.comments.specific.all
            && !filters.comments.specific.report
          ) || !filters.comments.initial || filters.comments.initial > new Date("2100-01-01") || filters.comments.initial < new Date("1900-01-01")
          || !filters.comments.final || filters.comments.final > new Date("2100-01-01") || filters.comments.final < new Date("1900-01-01");
    }
  }

  const exportAction = () => {
    switch (path) {
      case "users":
        let users: modelReportUser = {
          TimePeriod: filters.users.timePeriod,
          UserType: filters.users.userType,
          Groups: filters.users.groups,
          WorkspaceId: workspaceId
        }

        send({
          url: "reports/users",
          body: users
        }).then(r => {
          setShowMessage(true);
          setShowMessageType(true);
        })
          .catch(err => {
            setShowMessage(true);
            setShowMessageType(false)
          });

        break;
      case "prizes":
        let prizes: modelReportPrize = {
          AuctionState: filters.prizes.auctionState,
          PrizeType: filters.prizes.type,
          TimePeriodInitial: filters.prizes.initial,
          TimePeriodFinal: filters.prizes.final,
          WorkspaceId: workspaceId
        }

        send({
          url: "reports/prizes",
          body: prizes
        }).then(r => {
          setShowMessage(true);
          setShowMessageType(true);
        })
          .catch(err => {
            setShowMessage(true);
            setShowMessageType(false)
          });

        break;
      case "ideas":
        let ideas: modelReportIdeas = {
          ChallengeId: filters.ideas.challenge,
          Status: +filters.ideas.status,
          WorkspaceId: workspaceId
        }

        send({
          url: "reports/ideas",
          body: ideas
        }).then(r => {
          setShowMessage(true);
          setShowMessageType(true);
        })
          .catch(err => {
            setShowMessage(true);
            setShowMessageType(false)
          });

        break;
      case "usergroups":
        let usergroups: modelReportUsersGroups = {
          WorkspaceId: workspaceId
        }

        send({
          url: "reports/users-groups",
          body: usergroups
        }).then(r => {
          setShowMessage(true);
          setShowMessageType(true);
        })
          .catch(err => {
            setShowMessage(true);
            setShowMessageType(false)
          });

        break;
      case "evaluations":
        let evaluationsObject: modelReportEvaluations = {
          Challenge: filters.evaluations.challenge,
          Phase: +filters.evaluations.phase,
          WorkspaceId: workspaceId
        }

        send({
          url: "reports/evaluations",
          body: evaluationsObject
        }).then(r => {
          setShowMessage(true);
          setShowMessageType(true);
        })
          .catch(err => {
            setShowMessage(true);
            setShowMessageType(false)
          });

        break;
      case "comments":

        let commentsObject: modelReportComment = {
          TimePeriodInitial: filters.comments.initial,
          TimePeriodFinal: filters.comments.final,
          Objects: filters.comments.objects,
          Specific: filters.comments.specific,
          Visibility: filters.comments.comment,
          WorkspaceId: workspaceId
        }

        send({
          url: "reports/comment",
          body: commentsObject
        }).then(r => {
          setShowMessage(true);
          setShowMessageType(true);
        })
          .catch(err => {
            setShowMessage(true);
            setShowMessageType(false)
          });
        break;
      case "userAggregate":
        let userAggregateObject: any = {
          WorkspaceId: workspaceId
        }

        send({
          url: "reports/userAggregate",
          body: userAggregateObject
        }).then(r => {
          setShowMessage(true);
          setShowMessageType(true);
        })
          .catch(err => {
            setShowMessage(true);
            setShowMessageType(false)
          });
        break;
    }
  }
  return (
    <Box>
      <MuiDialogContent className="ModalSelectFilterAnalytics-body">
        {
          loading ? <Box display="flex" alignItems="center" justifyContent="center" textAlign="center"><CircularProgress /></Box> : null
        }
        {
          !loading && showMessage && <>
            <Typography variant="h4" align="center">
              <Resource
                tag={showMessageType ? "PageAnalytics::Email_sended" : "PageAnalytics::Email_error"}
              />
            </Typography>
          </>
        }
        {
          !loading && !showMessage && path === "users" && <>
            <p
            >
              <Resource
                tag={
                  "PageAnalytics::PleaseSelect"
                }
              />{'\n\n'}
            </p>
            <Grid container>
              <Grid item xs={4}>
                <FormLabel className={classes.label} component="legend">
                  <Resource tag={"PageAnalytics::TimePeriod"} />
                </FormLabel>
                <FormGroup>
                  <Select
                    defaultValue={"all"}
                    style={{ marginTop: "16px" }}
                    name="timePeriod-select"
                    labelId={""}
                    id="timePeriod-select"
                    value={filters.users.timePeriod}
                    fullWidth
                    onChange={(e: any) => {
                      setFilters({
                        ...filters,
                        users: {
                          ...filters.users,
                          timePeriod: +e.target.value
                        }
                      });
                    }}
                  >
                    <MenuItem selected value="0"><Resource tag="PageAnalytics::AllTime" /></MenuItem>
                    <MenuItem value="12"><Resource tag="PageAnalytics::LastYear" /></MenuItem>
                    <MenuItem value="30"><Resource tag="PageAnalytics::LastMonth" /></MenuItem>
                    <MenuItem value="7"><Resource tag="PageAnalytics::LastWeek" /></MenuItem>
                  </Select>
                </FormGroup>
              </Grid>
              <Grid item xs={8}>
                <FormLabel className={classes.label} component="legend">
                  <Resource tag="PageAnalytics::UserStatus" />
                </FormLabel>
                <FormGroup>
                  <RadioGroup row name="users" value={filters.users.userType} onChange={(e: any) => {
                    setFilters({
                      ...filters,
                      users: {
                        ...filters.users,
                        userType: +e.target.value
                      }
                    })
                  }}>
                    <FormControlLabel
                      color="primary"
                      key="status1"
                      value={0}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::All" />}
                    />
                    <FormControlLabel
                      color="primary"
                      key="status2"
                      value={1}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::Active" />}
                    />
                    <FormControlLabel
                      color="primary"
                      key="status3"
                      value={2}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::Imported" />}
                    />
                    <FormControlLabel
                      color="primary"
                      key="status4"
                      value={3}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::Desativacted" />}
                    />
                  </RadioGroup>
                </FormGroup>
              </Grid>
            </Grid>
            <Divider />
            <FormLabel className={classes.label} >
              <Resource tag="PageAnalytics::Groups" />
            </FormLabel>
            <FormGroup>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checked"
                        color="primary"
                      />
                    }
                    label={<Resource tag="PageAnalytics::Allworkspace" />}
                    className={classes.label}
                    onChange={(e) => {
                      const idx = filters.users.groups.indexOf("00000000-0000-0000-0000-000000000000");
                      if (idx > -1) {
                        filters.users.groups.splice(idx, 1);
                      } else {
                        filters.users.groups.push("00000000-0000-0000-0000-000000000000");
                      }
                      setFilters({
                        ...filters,
                        users: {
                          ...filters.users,
                        }
                      });
                    }}
                  />
                  <Divider />
                </Grid>
                {allGroups &&
                  allGroups?.map((list, index) => {
                    return (
                      <Grid item xs={12} sm={4} key={index}>
                        <FormControlLabel
                          key={list?.groupId}
                          control={
                            <Checkbox
                              key={list?.groupId}
                              name="checked"
                              color="primary"
                            />
                          }
                          label={list?.title}
                          className={classes.label}
                          onChange={(e) => {
                            const idx = filters.users.groups.indexOf(list.groupId);
                            if (idx > -1) {
                              filters.users.groups.splice(idx, 1);
                            } else {
                              filters.users.groups.push(list.groupId);
                            }
                            setFilters({
                              ...filters,
                              users: {
                                ...filters.users,
                              }
                            });
                          }}
                        />
                        <Divider />
                      </Grid>
                    );
                  })}
              </Grid>
            </FormGroup>
          </>
        }
        {
          !loading && !showMessage && path === "prizes" && <>
            <p>
              <Resource
                tag={
                  "PageAnalytics::PleaseSelect"
                }
              />{'\n\n'}</p>
            <Grid container>
              <Grid xs={4}>
                <FormLabel className={classes.label} component="legend">
                  <Resource tag={"PageAnalytics::Date_range"} />
                </FormLabel>
                <FormGroup>
                  <XDatepicker
                    value={filters.prizes.initial}
                    label={<Resource tag={"PageAnalytics::Date_initial"} />}
                    onChange={(e: any) => {
                      let obj = filters.prizes;
                      obj.initial = e;
                      setFilters({ ...filters, ...obj });
                    }}
                    fullWidth
                    disablePast={false}
                    keyboardDatePicker={true}
                    allowKeyboardControl={true}
                  />
                  <XDatepicker
                    label={<Resource tag={"PageAnalytics::Date_final"} />}
                    value={filters.prizes.final}
                    onChange={(e: any) => {
                      let obj = filters.prizes;
                      obj.final = e;
                      setFilters({ ...filters, ...obj });
                    }}
                    fullWidth
                    disablePast={false}
                    minDate={filters.prizes.initial}
                    keyboardDatePicker={true}
                    allowKeyboardControl={true}
                  />
                </FormGroup>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid xs={7}>
                <FormLabel className={classes.label} component="legend">
                  <Resource tag="PageAnalytics::PrizeType" />
                </FormLabel>
                <FormGroup>
                  <RadioGroup row name="prizes_type" value={filters.prizes.type} onChange={(e: any) => {
                    setFilters({
                      ...filters,
                      prizes: {
                        ...filters.prizes,
                        type: +e.target.value
                      }
                    });
                  }}>
                    <FormControlLabel
                      color="primary"
                      key="prizeType1"
                      value={0}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::All" />}
                    />
                    <FormControlLabel
                      color="primary"
                      key="prizeType2"
                      value={1}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::Auction" />}
                    />
                    <FormControlLabel
                      color="primary"
                      key="prizeType3"
                      value={2}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::BuyNow" />}
                    />
                  </RadioGroup>
                </FormGroup>
              </Grid>
            </Grid>

            <FormLabel className={classes.label} component="legend">
              <Resource tag="PageAnalytics::AuctionStatus" />
            </FormLabel>
            <FormGroup>
              <RadioGroup row name="prizes_status" value={filters.prizes.auctionState} onChange={(e) => {
                setFilters({
                  ...filters,
                  prizes: {
                    ...filters.prizes,
                    auctionState: +e.target.value
                  }
                });
              }}>
                <FormControlLabel
                  color="primary"
                  key="status1"
                  value={0}
                  control={<Radio color="primary" />}
                  label={<Resource tag="PageAnalytics::All" />}
                />
                <FormControlLabel
                  color="primary"
                  key="status2"
                  value={1}
                  control={<Radio color="primary" />}
                  label={<Resource tag="PageAnalytics::OnGoing" />}
                />
                <FormControlLabel
                  color="primary"
                  key="status3"
                  value={2}
                  control={<Radio color="primary" />}
                  label={<Resource tag="PageAnalytics::Closed" />}
                />
                <FormControlLabel
                  color="primary"
                  key="status4"
                  value={3}
                  control={<Radio color="primary" />}
                  label={<Resource tag="PageAnalytics::Canceled" />}
                />
              </RadioGroup>
            </FormGroup>
          </>
        }
        {
          !loading && !showMessage && path === "ideas" && <>
            <p>
              <Resource
                tag={
                  "PageAnalytics::PleaseSelect"
                }
              />{'\n\n'}</p>
            <FormLabel className={classes.label} component="legend">
              <Resource tag="PageAnalytics::Challenge" />
            </FormLabel>
            <FormControl fullWidth>
              <Select
                style={{ marginTop: "16px" }}
                name="challenge-select"
                id="challenge"
                fullWidth
                value={filters.ideas.challenge ?? ""}
                onChange={(e: any) => {
                  setFilters({
                    ...filters, ideas: {
                      ...filters.ideas,
                      challenge: e.target.value
                    }
                  });
                }}>
                {
                  challengeList?.map((list: any, index: number) => {
                    return (
                      <MenuItem value={list.challengeId} key={index}>
                        {list.title}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormLabel className={classes.label} component="legend">
              <Resource tag="PageAnalytics::IdeaStatus" />
            </FormLabel>
            <FormGroup>
              <RadioGroup row name="ideias" value={filters.ideas.status} onChange={(e) => {
                setFilters({
                  ...filters, ideas: {
                    ...filters.ideas,
                    status: +e.target.value
                  }
                });
              }}>
                <FormControlLabel
                  color="primary"
                  key="status1"
                  value={-1}
                  control={<Radio color="primary" />}
                  label={<Resource tag="PageAnalytics::All" />}
                />
                <FormControlLabel
                  color="primary"
                  key="status2"
                  value={3}
                  control={<Radio color="primary" />}
                  label={<Resource tag="PageAnalytics::OnGoing" />}
                />
                <FormControlLabel
                  color="primary"
                  key="status3"
                  value={2}
                  control={<Radio color="primary" />}
                  label={<Resource tag="PageAnalytics::Imported" />}
                />
                <FormControlLabel
                  color="primary"
                  key="status4"
                  value={4}
                  control={<Radio color="primary" />}
                  label={<Resource tag="PageAnalytics::Desactivate" />}
                />
              </RadioGroup>
            </FormGroup>
          </>
        }
        {
          !loading && !showMessage && path === "evaluations" && <>
            <p>
              <Resource
                tag={
                  "PageAnalytics::PleaseSelect"
                }
              />{'\n\n'}</p>
            <FormLabel className={classes.label} component="legend">
              <Resource tag="PageAnalytics::Challenge" />
            </FormLabel>
            <FormControl fullWidth>
              <Select
                defaultValue={"all time"}
                style={{ marginTop: "16px" }}
                name="timePeriod-select"
                labelId={""}
                id="timePeriod-select"
                fullWidth
                value={filters.evaluations.challenge}
                onChange={(e: any) => {
                  setFilters({
                    ...filters, evaluations: {
                      ...filters.evaluations,
                      challenge: e.target.value
                    }
                  });
                }}>
                {
                  challengeList?.map((list: any, index: number) => {
                    return (
                      <MenuItem value={list.challengeId} key={index}>
                        {list.title}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormLabel className={classes.label} component="legend">
              <Resource tag="PageAnalytics::Phase" />
            </FormLabel>
            <FormControl fullWidth>
              <Select
                defaultValue={"all time"}
                style={{ marginTop: "16px" }}
                name="phase-select"
                labelId={""}
                id="phase-select"
                fullWidth
                onChange={(e: any) => {
                  setFilters({
                    ...filters,
                    evaluations: {
                      ...filters.evaluations,
                      phase: e.target.value
                    }
                  });
                }}>
                <MenuItem selected value="2">
                  <Resource tag="PageAnalytics::Collaboration" />
                </MenuItem>
                <MenuItem value="3">
                  <Resource tag="PageAnalytics::ExpertReview" />
                </MenuItem>
                <MenuItem value="4">
                  <Resource tag="PageAnalytics::Development" />
                </MenuItem>
              </Select>
            </FormControl>
          </>
        }
        {
          !loading && !showMessage && path === "comments" && <>
            <p>
              <Resource
                tag={
                  "PageAnalytics::PleaseSelect"
                }
              />{'\n\n'}</p>
            <Grid container>
              <Grid xs={4}>
                <FormLabel className={classes.label} component="legend">
                  <Resource tag={"PageAnalytics::Date_range"} />
                </FormLabel>
                <FormGroup>
                  <XDatepicker
                    value={filters.comments.initial}
                    onChange={(e: any) => {
                      setFilters({
                        ...filters, comments: {
                          ...filters.comments,
                          initial: e
                        }
                      });
                    }}
                    label={<Resource tag={"PageAnalytics::Date_initial"} />}
                    disablePast={false}
                    allowKeyboardControl={true}
                    keyboardDatePicker={true}
                    fullWidth
                  />
                  <XDatepicker
                    label={<Resource tag={"PageAnalytics::Date_final"} />}
                    value={filters.comments.final}
                    onChange={(e: any) => {
                      setFilters({
                        ...filters, comments: {
                          ...filters.comments,
                          final: e
                        }
                      });
                    }}
                    fullWidth
                    disablePast={false}
                    minDate={filters.comments.initial}
                    allowKeyboardControl={true}
                    keyboardDatePicker={true}

                  />
                </FormGroup>
              </Grid>
              <Grid xs={1}>
              </Grid>
              <Grid xs={7}>
                <FormLabel className={classes.label} component="legend">
                  <Resource tag={"PageAnalytics::CommentVisibility"} />
                </FormLabel>
                <FormGroup>
                  <RadioGroup row name="status" value={filters.comments.comment} onChange={(e) => {
                    setFilters({
                      ...filters, comments: {
                        ...filters.comments,
                        comment: +e.target.value
                      }
                    });
                  }}>
                    <FormControlLabel
                      color="primary"
                      key="prizeType1"
                      value={0}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::All" />}
                    />
                    <FormControlLabel
                      color="primary"
                      key="prizeType2"
                      value={1}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::Public" />}
                    />
                    <FormControlLabel
                      color="primary"
                      key="prizeType3"
                      value={2}
                      control={<Radio color="primary" />}
                      label={<Resource tag="PageAnalytics::Private" />}
                    />
                  </RadioGroup>
                </FormGroup>
              </Grid>
            </Grid>
            <FormLabel className={classes.label} component="legend">
              <Resource tag="PageAnalytics::SpecificComments" />
            </FormLabel>
            <FormGroup>
              <Grid container>
                <Grid xs={3}>
                  <FormControlLabel
                    color="primary"
                    key="status1"
                    checked={filters.comments.specific.all}
                    value={1}
                    control={<Checkbox color="primary" />}
                    label={<Resource tag="PageAnalytics::All" />}
                    onChange={(e) => {
                      setFilters({
                        ...filters, comments: {
                          ...filters.comments,
                          specific: {
                            ...filters.comments.specific,
                            all: !filters.comments.specific.all
                          }
                        }
                      });
                    }}
                  />
                </Grid>
                {/* <Grid xs={3}>
                  <FormControlLabel
                    color="primary"
                    key="status2"
                    value=""
                    control={<Checkbox color="primary" />}
                    label={<Resource tag="PageAnalytics::AddedValue" />}
                    checked={filters.comments.specific.add}
                    onChange={(e) => {
                      setFilters({
                        ...filters, comments: {
                          ...filters.comments,
                          specific: {
                            ...filters.comments.specific,
                            add: !filters.comments.specific.add
                          }
                        }
                      });
                    }}
                  />
                </Grid> */}
                <Grid xs={3}>
                  <FormControlLabel
                    color="primary"
                    key="status3"
                    value={3}
                    control={<Checkbox color="primary" />}
                    label={<Resource tag="PageAnalytics::Reported" />}
                    checked={filters.comments.specific.report}
                    onChange={(e) => {
                      setFilters({
                        ...filters, comments: {
                          ...filters.comments,
                          specific: {
                            ...filters.comments.specific,
                            report: !filters.comments.specific.report
                          }
                        }
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </FormGroup>

            <FormLabel className={classes.label} component="legend">
              <Resource tag="PageAnalytics::Objects" />
            </FormLabel>
            <FormGroup>
              <Grid container>
                <Grid xs={3}>
                  <FormControlLabel
                    color="primary"
                    key="status1"
                    checked={filters.comments.objects.all}
                    value={""}
                    control={<Checkbox color="primary" />}
                    label={<Resource tag="PageAnalytics::All" />}
                    onChange={(e) => {
                      setFilters({
                        ...filters, comments: {
                          ...filters.comments,
                          objects: {
                            ...filters.comments.objects,
                            all: !filters.comments.objects.all
                          }
                        }
                      });
                    }}
                  />
                </Grid>
                <Grid xs={3}>
                  <FormControlLabel
                    color="primary"
                    key="status2"
                    value={"Inspirations"}
                    checked={filters.comments.objects.inspirations}
                    control={<Checkbox color="primary" />}
                    label={<Resource tag="PageAnalytics::Inspirations" />}
                    onChange={(e) => {
                      setFilters({
                        ...filters, comments: {
                          ...filters.comments,
                          objects: {
                            ...filters.comments.objects,
                            inspirations: !filters.comments.objects.inspirations
                          }
                        }
                      });
                    }}
                  />
                </Grid>
                <Grid xs={3}>
                  <FormControlLabel
                    color="primary"
                    key="status3"
                    value={"Ideas"}
                    checked={filters.comments.objects.ideas}
                    control={<Checkbox color="primary" />}
                    label={<Resource tag="PageAnalytics::Ideas" />}
                    onChange={(e) => {
                      setFilters({
                        ...filters, comments: {
                          ...filters.comments,
                          objects: {
                            ...filters.comments.objects,
                            ideas: !filters.comments.objects.ideas
                          }
                        }
                      });
                    }}
                  />
                </Grid>
                <Grid xs={3}>
                  <FormControlLabel
                    color="primary"
                    key="status3"
                    checked={filters.comments.objects.news}
                    value={"News"}
                    control={<Checkbox color="primary" />}
                    label={<Resource tag="PageAnalytics::News" />}
                    onChange={(e) => {
                      setFilters({
                        ...filters, comments: {
                          ...filters.comments,
                          objects: {
                            ...filters.comments.objects,
                            news: !filters.comments.objects.news
                          }
                        }
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </FormGroup>
          </>
        }
        {
          !loading && !showMessage && path === "userAggregate" && <>
            <p>
              <Resource
                tag={
                  "PageAnalytics::PleaseSelect"
                }
              />{'\n\n'}</p>


          </>
        }
      </MuiDialogContent>
      <MuiDialogActions>
        <Box p={2} textAlign="center">
          {
            <Button
              type="submit"
              variant="contained"
              color={showMessage ? "primary" : "default"}
              size="small"
              onClick={closeButton}
            >
              <Resource
                tag={
                  showMessage ? "PageAnalytics::BtnDone" : "PageAnalytics::BtnCancel"
                }
              />
            </Button>
          }
        </Box>
        {
          !loading && !showMessage && <>
            <Box p={2} textAlign="center">
              {
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={exportAction}
                  disabled={checkAllowExportAction()}
                  style={{ color: "#ffffff" }}
                >
                  <Resource
                    tag={"PageAnalytics::BtnExport"}
                  />
                </Button>
              }
            </Box>
          </>
        }
      </MuiDialogActions>
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    challengeList: store.challengeState.challengeAllList,
    application: store.tenantState.application,
    culture: store.cultureState,
    allGroups: store.manageGroupsState.allGroups,
    workspaceId: store.authState.auth.workspaceId
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getNewChallengeList: (parameters: object) =>
      dispatch(getNewChallengeList(parameters)),

    getAllChallengesInspiration: (parameters: object) =>
      dispatch(getAllChallengesInspiration(parameters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSelectFilterAnalytics);
