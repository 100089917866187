import { resource } from "../components/Resources/Resource";
import { toast } from "react-toastify";
import sanitizeHtml from "sanitize-html";

export function treatError(culture: any, owner: string, err: any,args?:any) {
    let msg, message;
   
    //Who is calling the error
    if(err?.error?.response?.status !== 401) {
            if (owner?.search("::")===-1){
                msg = '';
            } else{
                msg = resource(culture, owner)+' - ';
            }
            //Erro can be a string or object
            if (typeof err==='object'){
                if (Array.isArray(err?.error?.response?.data) && (err?.error?.response?.data?.length>0)){
                    message=err?.error?.response?.data[0]?.value; 
                }
                else if (err?.error?.response?.data?.length>0){
                    message=err?.error?.response?.data; 
                }
                else if (err?.response?.data?.length>0){
                    message=err?.response?.data[0]?.value; 
                }
                //Erro can be a string or object
                if (typeof err==='object'){
                    if (Array.isArray(err?.error?.response?.data) && (err?.error?.response?.data?.length>0)){
                        message=err?.error?.response?.data[0]?.value; 
                    }
                    else if (err?.error?.response?.data?.length>0){
                        message=err?.error?.response?.data; 
                    }
                    else if (err?.response?.data?.length>0){
                        message=err?.response?.data[0]?.value; 
                    }
                    else if (err?.error_errors?.length>0){
                        message=err?.error_errors[0]?.value; 
                    } else if(err?.error_errors?.errors?.stateId?.length > 0){
                        message = 'Missing required Fields' //TODO: FIELD err?.error_errors?.errors?.stateId[0] doesn't inform the error to the user
                    } else{
                        message=err?.toString(); 
                    }
                } else{
                    message=err;
                }
            }
            //Translate error
            if (message?.search("::")===-1 && message?.indexOf(".")===-1){
                msg += message
            } else{
                const msgFromResource = resource(culture, message?.replace(".", "::"),args);
                msg += msgFromResource ? msgFromResource : message;
            }
            //Show the erro in screen
            toast.error(sanitizeHtml(msg), {toastId: 'treat-error-toast'});   
    }
}