import React from "react";
import imageEmpty from "assets/img/empty@3x.png";
import Resource from "components/Resources/Resource";
import { Link as RouterLink } from "react-router-dom";
import { IMessagesArchived } from "redux/types/messages";
import { ICultureState } from "redux/reducers/cultureReducer";
// import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";
import MessageListUserItem from "common/UI/List/User/MessageListUserItem";
import {
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Box,
  Link,
} from "@material-ui/core";

interface IProps {
  culture: ICultureState;
  archivedMessages: IMessagesArchived;
  // getSentMessages: (
  //   pageSize: number,
  //   pageNumber: number,
  //   isInfinite: boolean
  // ) => Promise<any>;
  // postMessagesArchive: (options: object) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& label": {
        width: "100%",
        margin: "0 0 0 -11px",
        "& span:last-child": {
          width: "100%",
        },
      },
    },
    textUpper: {
      textTransform: "uppercase",
    },
    divider: {
      width: "99%",
      marginTop: 2,
      marginBottom: 2,
      background:
        "linear-gradient(90deg, rgba(242,242,242,0.3617822128851541) 0%, rgba(242,242,242,1) 29%, rgba(242,242,242,1) 66%, rgba(242,242,242,0.32816876750700286) 100%)",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    checkboxAll: {
      "& .MuiIconButton-label": {
        marginLeft: "-11px",
      },
    },
  })
);

function MessageArchivedContent({
  culture,
  archivedMessages,
}: // getSentMessages,
IProps) {
  const classes = useStyles();
  const backURL = "messages/details";

  return (
    <>
      {archivedMessages?.meta?.total === 0 ||
      archivedMessages?.meta?.total === undefined ? (
        <Box
          className="no-message-archived"
          mt={7}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Resource tag="PageMessages::NotArchivedMessage" />
          <Box className="emptyImg" mt={5}>
            <img src={imageEmpty} alt="" width="340px" height="350px" />
          </Box>
        </Box>
      ) : (
        <Box className="archived-message-container" ml="-11px">
          {archivedMessages?.result &&
            archivedMessages?.result?.map((message: any) => (
              <Link
                component={RouterLink}
                to={{ pathname: `${backURL}/${message?.messageId}` }}
                className={classes.link}
              >
                <MessageListUserItem
                  culture={culture}
                  isMarkedAsRead={true}
                  avatar={message?.author?.photo}
                  userName={message?.author?.fullName}
                  msgSubject={message?.subject}
                  msgContent={message?.message}
                  msgDate={message?.date}
                />
                <Divider className={classes.divider} />
              </Link>
            ))}
        </Box>
      )}
    </>
  );
}

export default MessageArchivedContent;
