import history from "./history";
import Image from "material-ui-image";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import logo from "../assets/img/logo.png";
import { RouteProps } from "react-router";
import { IAppState } from "../redux/store/Store";
import { Route, Redirect } from "react-router-dom";
import BGImage from "../assets/img/loginBackground.png";
import { IAuthUser } from "../redux/reducers/authReducer";
import { ITenantState } from "redux/reducers/tenantReducer";
import { IProfileData } from "redux/reducers/profileReducer";
import { ICultureState } from "../redux/reducers/cultureReducer";
import { Container, Box, Grid, Hidden } from "@material-ui/core";
import LoggedOutFooter from "components/LoggedOutFooter/LoggedOutFooter";
import { IWorkspaceReducer } from "redux/reducers/workspaceReducer";

interface IProps {
  authUser: IAuthUser;
  culture: ICultureState;
  children: RouteProps["children"];
  userProps: any;
  on: boolean;
  tenants: ITenantState;
  profileData: IProfileData;
  workspaces: IWorkspaceReducer;
}

const LoggedInSetupRoute: React.FC<IProps> = (props: IProps) => {
  useEffect(() => {
    if (props?.authUser?.isAuthenticated === false) {
      history.push("/splash");
    } else if (
      props?.authUser?.allowCreateWorkspace &&
      props?.authUser?.hasWorkspaceConfigured === false
    ) {
      history.push("/setenvironment");
    } else if (props?.authUser?.isProfileSetted === false) {
      history.push("/setprofile");
    } else if (props?.profileData?.agreedTermsAndPolicyDate === "") {
      history.push("/acceptterms");
    } else if (
      props?.profileData?.agreedRegulationVersion !== null &&
      props?.profileData?.agreedRegulations === ""
    ) {
      history?.push("/acceptRegulations");
    } else {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    // eslint-disable-next-line
  }, [props?.workspaces?.photoLogin])

  return (
    <Route
      render={() =>
        props?.authUser?.isAuthenticated ? (
          <>
            <Grid container>
              <Hidden smDown>
                <Grid item md={4} xs={6}>
                  <Box height="100vh" width="100%">
                    <Image
                      src={
                        props.tenants?.error?.error ||
                          !props.tenants.activeTenant?.photoLogin
                          ? props?.workspaces?.photoLogin ? props?.workspaces?.photoLogin : BGImage
                          : props.tenants?.activeTenant?.photoLogin
                      }
                      style={{
                        paddingTop: "0",
                        position: "none",
                        height: "100vh",
                      }}
                      imageStyle={{ objectFit: "cover", position: "none" }}
                      disableSpinner
                      animationDuration={5500}
                    />
                  </Box>
                </Grid>
              </Hidden>
              <Grid item md={8} xs={12} style={{ position: "relative" }}>
                <Box
                  height="6vh"
                  display="flex"
                  flexDirection="row-reverse"
                  p={1}
                  m={1}
                >
                  <Box p={1}>
                    <Image
                      className="tenant-logo"
                      alt="Exago"
                      src={
                        props.tenants?.error?.error ||
                          !props.tenants.activeTenant?.image
                          ? props?.workspaces?.photoLogo ? props?.workspaces?.photoLogo : logo
                          : props?.tenants?.activeTenant?.image
                      }
                      style={{
                        paddingTop: "0",
                        position: "none",
                        height: "50px",
                      }}
                      imageStyle={{
                        paddingTop: "0",
                        position: "none",
                        objectFit: "contain",
                        maxWidth: "150px",
                      }}
                      disableSpinner
                      animationDuration={5500}
                    />
                  </Box>
                </Box>
                <Box>
                  <Container style={{ margin: 0 }} maxWidth="sm" className="container-children">
                    <>{props.children}</>
                    <Box
                      className="login-footer"
                      width="100%"
                      mt="20px"
                    >
                      <LoggedOutFooter
                        userProps={props.userProps}
                        authUser={props.authUser}
                      />
                    </Box>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/log-in",
              state: {
                expiredLogin: true,
              },
            }}
          />
        )
      }
    />
  );
};
const mapStateToProps = (state: IAppState) => ({
  authUser: state.authState.auth,
  culture: state.cultureState,
  on: state.profileState.on,
  tenants: state.tenantState,
  profileData: state.profileState.profileData,
  workspaces: state.workSpacesState.workspaces
});
export default connect(mapStateToProps)(LoggedInSetupRoute);
