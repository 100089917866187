
import * as React from 'react';
import * as ReactDOM from 'react-dom';


/* Make the store available to all container 
components in the application without passing it explicitly */
import { Provider } from 'react-redux';

// Store type from Redux
import { Store } from 'redux';

// Import the store function and state
import configureStore, { IAppState } from './redux/store/Store';

import './index.css';
import App from './components/App/App';
/*import { init as initApm } from '@elastic/apm-rum'


initApm({

  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'Front-End_'+process.env.NODE_ENV,

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: process.env.REACT_APP_ELASTIC,

  // Set service version (required for source map feature)
  serviceVersion: '0.90'
})*/

interface IProps {
  store: Store<IAppState>;
}

/* 
Create a root component that receives the store via props
and wraps the App component with Provider, giving props to containers
*/
const Root: React.SFC<IProps> = props => {
  return (
    <Provider store={props.store}>
      <App />
    </Provider>
  );
};

const store = configureStore();

// Render the App
ReactDOM.render(<Root store={store} />, document.getElementById(
  'root'
) as HTMLElement);