import "./NewsDetail.css";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import EMIcon from "components/Icon/Icon";
import { ThunkDispatch } from "redux-thunk";
import reactNewApp from "utils/reactNewApp";
import { IAppState } from "redux/store/Store";
import { withRouter } from "react-router-dom";
import { IResultNews } from "redux/types/news";
import React, { useEffect, useState } from "react";
import { ShortDateFormat } from "utils/FormatDate";
import NewsList from "containers/NewsList/NewsList";
import NewsDetailSkeleton from "./NewsDetailSkeleton";
import { IProfile } from "redux/reducers/profileReducer";
import CommentItem from "components/CommentItem/CommentItem";
import { securityTextEditor } from "utils/securityTextEditor";
import { ICultureState } from "redux/reducers/cultureReducer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ShareButtons from "components/ShareButtons/ShareButtons";
import Resource, { resource } from "components/Resources/Resource";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  Divider,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  Grow,
  useTheme,
} from "@material-ui/core";
import {
  getNewsComments,
  getNewsDetail,
  handleLikeNews,
  handleDislikeNews,
  postComment,
  postLikeComment,
  postDislikeComment,
  putNewsComment,
  deleteNewsComment,
  deleteNewsCommentReply,
} from "redux/actions/news/NewsDetailAction";
import {
  INewsDetailState,
  INewsDetailResult,
  INewsComments,
} from "redux/reducers/news/newsDetailReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperT: {
      marginBottom: 30,
      width: "fit-content",
      padding: "1px 10px",
    },
    topic: {
      fontWeight: "bold",
      color: theme.palette.text.primary,
      textTransform: "capitalize",
    },
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    icon: {
      "& svg": {
        marginRight: "5px",
      },
    },
    inputText: {
      width: "100%",
    },
    divider: {
      width: "100%",
    },
    paper: {
      margin: "20px 0",
    },
  })
);

interface IDispatchProps {
  getNewsDetail: (options: object) => Promise<INewsDetailState>;
  getNewsComments: (options: object) => Promise<INewsDetailState>;
  handleLikeNews: (options: object) => Promise<INewsDetailState>;
  handleDislikeNews: (options: object) => Promise<INewsDetailState>;
  postComment: (options: object) => Promise<INewsDetailState>;
  putNewsComment: (options: object) => Promise<INewsDetailState>;
  deleteNewsComment: (options: object) => Promise<INewsDetailState>;
  deleteNewsCommentReply: (options: object) => Promise<INewsDetailState>;
  postDislikeComment: (options: object) => Promise<INewsDetailState>;
  postLikeComment: (options: object) => Promise<INewsDetailState>;
}

interface IProps {
  news: IResultNews;
  newsDetail: INewsDetailResult;
  newsId: string;
  newsDetailStatus: string;
  newsComments: INewsComments[];
  newsCommentStatus: string;
  profile: IProfile[];
  culture: ICultureState;
  likeStatus: string;
  metaComments: any;
  applicationId: any;
  messagesCount: number;
  totalMessagePages: number;
  workspaceId: string;
}

function NewsDetail(props: IProps & IDispatchProps) {
  const {
    getNewsComments,
    getNewsDetail,
    handleDislikeNews,
    handleLikeNews,
    postComment,
    postLikeComment,
    postDislikeComment,
    culture,
    newsDetail,
    newsComments,
    likeStatus,
    newsCommentStatus,
    metaComments,
    workspaceId,
    putNewsComment,
    deleteNewsComment,
    deleteNewsCommentReply,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [commentValue, setCommentValue] = useState("");
  const [pageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const profile = props?.profile?.[0];
  const [reply, setReply] = useState("");
  const [countPreviousClick, setClick] = useState(1);
  const { executeRecaptcha } = useGoogleReCaptcha();

  let captcha: string = "";

  const options = {
    newsId: props?.newsId,
    culture,
    pageSize,
    pageNumber,
    workspaceId,
  };

  useEffect(() => {
    setIsLoading(true);
    getNewsDetail({
      ...options,
    }).then(() => {
      setIsLoading(false);
    });
    getNewsComments({
      ...options,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.newsId]);

  //select the parentCommentId
  const replyHandler = (value: any) => {
    if (reply === value) {
      setReply("");
    } else {
      setReply(value);
    }
  };

  async function postReply(replyMessage: string) {
    executeRecaptcha &&
      (await executeRecaptcha("newsDetailPage").then((response) => {
        captcha = response;
      }));
    postComment({
      newsId: props?.newsId,
      message: replyMessage,
      workspaceId: workspaceId,
      applicationId: props?.applicationId,
      commentParentId: reply,
      captcha: captcha,
      pageNumber,
      pageSize,
      culture
    }).then(() => {
      getNewsComments({
        ...options,
      });
      getNewsDetail({
        ...options,
      }).then(() => {
        setIsLoading(false);
      });
    });
    setReply("");
  }

  async function postComments(e?: any) {
    executeRecaptcha &&
      (await executeRecaptcha("newsDetailPage").then((response) => {
        captcha = response;
      }));

    // if (reply) {
    //   postComment({
    //     newsId: props?.newsId,
    //     message: commentValue,
    //     applicationId: props?.applicationId,
    //     workspaceId,
    //     commentParentId: reply,
    //     captcha: captcha,
    //     pageNumber,
    //     pageSize,
    //   }).then(() => {
    //     getNewsComments({
    //       ...options,
    //     });
    //   });
    // } else {
    if (!reply) {
      postComment({
        newsId: props?.newsId,
        message: commentValue,
        applicationId: props?.applicationId,
        workspaceId,
        captcha: captcha,
        pageNumber,
        pageSize,
      }).then(() => {
        getNewsComments({
          ...options,
        });
        getNewsDetail({
          ...options,
        }).then(() => {
          setIsLoading(false);
        });
      });
    }
    setCommentValue("");
  }

  async function putEditComment(
    commentId: string,
    message: any,
    typeF: number,
    replyId?: string
  ) {
    executeRecaptcha &&
      (await executeRecaptcha("newsDetailPage")?.then((response) => {
        captcha = response;
      }));

    if (typeF === 1) {
      if (reply) {
        putNewsComment({
          ...options,
          commentParentId: reply,
          newsId: props?.newsId,
          workspaceId: workspaceId,
          message: message,
          commentId: commentId,
          delete: false,
          edit: true,
        }).then(() => {
          setReply("");
        });
      } else {
        putNewsComment({
          ...options,
          newsId: props?.newsId,
          workspaceId: workspaceId,
          message: message,
          commentId: commentId,
          delete: false,
          edit: true,
        });
      }
    } else if (typeF === 2) {
      deleteNewsComment({
        ...options,
        commentId: commentId,
        newsId: props?.newsId,
        workspaceId: workspaceId,
      }).then(() => {
        getNewsComments({
          ...options,
        });
        getNewsDetail({
          ...options,
        }).then(() => {
          setIsLoading(false);
        });
      });
    } else if (typeF === 3) {
      deleteNewsCommentReply({
        ...options,
        parentCommentId: commentId,
        commentId: replyId,
        newsId: props?.newsId,
        workspaceId: workspaceId,
      }).then(() => {
        getNewsComments({
          ...options,
        });
        getNewsDetail({
          ...options,
        }).then(() => {
          setIsLoading(false);
        });
      });
    }
  }

  const handleEnter = (e: any) => {
    if (e?.keyCode === 13) {
      postComments(e);
    }
  };

  const handleChange = (e: any) => {
    setCommentValue(e.target.value);
  };

  const postLikeComments = (commentId: string, userLiked: boolean) => {
    if (userLiked === false) {
      postLikeComment({
        newsId: props?.newsId,
        commentId: commentId,
        applicationId: props?.applicationId,
        workspaceId,
      });
    } else {
      postDislikeComment({
        newsId: props?.newsId,
        commentId: commentId,
        applicationId: props?.applicationId,
        workspaceId,
      });
    }
  };

  const postLikeNews = (newsId: string, userLiked: boolean) => {
    const options = {
      newsId,
    };
    if (userLiked === false) {
      handleLikeNews({
        ...options,
        workspaceId,
      });
    } else {
      handleDislikeNews({
        ...options,
        workspaceId,
      });
    }
  };

  const handleLike = () => {
    newsDetail.newsId &&
      postLikeNews(newsDetail.newsId, newsDetail?.isFavorite);
  };

  const handleMoreComments = () => {
    if (newsComments?.length >= metaComments?.total) {
      return;
    } else {
      setClick(countPreviousClick + 1);
      getNewsComments({
        pageSize: pageSize + pageSize * countPreviousClick,
        pageNumber,
        newsId: props?.newsId,
        culture,
      });
    }
  };

  const backgroundColor = newsDetail?.isFavorite
    ? theme.palette.primary.light
    : theme.palette.common.white;

  return (
    <>
      {!isLoading ? (
        <Container>
          <Box className="news-detail-topic">
            {newsDetail?.topic === "" ? (
              ""
            ) : (
              <Paper className={classes.paperT}>
                <Typography className={classes.topic}>
                  {newsDetail?.topic}
                </Typography>
              </Paper>
            )}
          </Box>
          <Box
            className="news-detail-title-and-btnlike"
            display="flex"
            flex-direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            mb="20px"
          >
            <Box
              className="news-detail-title"
              mr={3}
              display="flex"
              fontSize="23px"
              fontWeight="bold"
              textAlign="start"
              color="textPrimary"
            >
              {newsDetail?.title}
            </Box>
            {likeStatus === "LIKE_SUCCEEDED" ||
            likeStatus === "DISLIKE_SUCCEEDED" ? (
              <Box
                className="news-detail-btnlike"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {newsDetail?.nrLikes === 0 ? (
                  ""
                ) : (
                  <>
                    {newsDetail?.isFavorite && (
                      <Typography variant="caption" color="primary">
                        <Resource tag="PageNews::You" />
                        &nbsp;
                        {newsDetail?.nrLikes > 1 && "+"}
                      </Typography>
                    )}
                    <Typography variant="caption" color="primary">
                      {newsDetail?.nrLikes === 1 && newsDetail?.isFavorite
                        ? ""
                        : newsDetail?.isFavorite
                        ? newsDetail?.nrLikes - 1
                        : newsDetail?.nrLikes}
                    </Typography>
                  </>
                )}
                <Box
                  ml={1}
                  width={40}
                  height={40}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="100%"
                  style={{
                    cursor: "pointer",
                    backgroundColor: backgroundColor,
                    boxShadow:
                      backgroundColor === theme.palette.common.white
                        ? "rgba(0, 0, 0, 0.1) 0px 1px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px"
                        : "none",
                  }}
                >
                  <span onClick={handleLike}>
                    <EMIcon
                      size={20}
                      color={
                        newsDetail?.isFavorite
                          ? theme.palette.primary.main
                          : theme.palette.grey[500]
                      }
                      icon={newsDetail?.isFavorite ? "like-b" : "like"}
                    ></EMIcon>
                  </span>
                </Box>
              </Box>
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            )}
          </Box>
          <Box
            className="news-detail-publication-date"
            fontSize="9px"
            color={theme.palette.text.secondary}
            textAlign="start"
            mb="20px"
          >
            <ShortDateFormat date={newsDetail?.publicationDate} />
          </Box>
          {newsDetail?.image === null ? (
            ""
          ) : (
            <Box
              mt={2}
              className="news-detail-img"
              style={{ backgroundImage: `url("${newsDetail?.image}")` }}
            />
          )}
          <Box
            mt={2}
            display="flex"
            flexDirection="column"
            className="news-detail-description"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(newsDetail?.description, securityTextEditor),
            }}
          />
          <Paper className={classes.paper}>
            <Box
              paddingX={3}
              pb={3}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                alignItems="center"
                gridGap={10}
                paddingTop={5}
              >
                <Typography color="textSecondary" variant="caption">
                  <Resource tag="PageDetailedIdea::AuthorIdea" />:
                </Typography>
                <Avatar
                  alt={newsDetail?.authorName}
                  src={newsDetail?.authorPhoto}
                />
                <Typography component="p" variant="caption">
                  {newsDetail?.authorName}
                </Typography>
              </Box>
              <Box
                fontSize="12px"
                color={theme.palette.text.secondary}
                textAlign="start"
              >
                <ShareButtons />
              </Box>
            </Box>

            {reactNewApp && newsDetail?.enableComment && (
              <Box p="0 24px 24px 24px" className="comments-container">
                <Box display="flex" justifyContent="space-between" paddingY={1}>
                  <Typography
                    className={classes.icon}
                    variant="caption"
                    color="primary"
                  >
                    <EMIcon
                      icon="comments"
                      color={theme.palette.primary.main}
                    />
                    <Resource tag="CommentsArea::Comments" />
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {newsDetail?.commentsCount === 1 ? (
                      <Resource
                        tag="PageDetailChallenge::Idea_CommentNumber"
                        args={[newsDetail?.commentsCount]}
                      />
                    ) : newsDetail?.commentsCount === 0 ? (
                      ""
                    ) : (
                      <Resource
                        tag="PageDetailChallenge::Idea_CommentsNumber"
                        args={[newsDetail?.commentsCount]}
                      />
                    )}
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
                <Box display="flex" justifyContent="space-between" paddingY={1}>
                  {props?.messagesCount > props?.totalMessagePages ? (
                    <Typography
                      onClick={handleMoreComments}
                      style={{ textTransform: "lowercase", cursor: "pointer" }}
                      variant="caption"
                      color="primary"
                    >
                      <Resource tag="Common::PreviousComments" />
                    </Typography>
                  ) : (
                    <Typography
                      style={{ textTransform: "lowercase", cursor: "pointer" }}
                      variant="caption"
                      color="primary"
                    ></Typography>
                  )}
                  {props?.messagesCount > 0 ? (
                    <Typography variant="caption" color="textSecondary">
                      {props?.totalMessagePages} <Resource tag="Common::of" />{" "}
                      {props?.messagesCount}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
                {newsCommentStatus !== "LOADING" ? (
                  <Grow in>
                    <Box>
                      {newsComments &&
                        newsComments?.map((comment) => {
                          return (
                            <CommentItem
                              key={comment?.commentId}
                              commentId={comment?.commentId}
                              userId={comment?.userId}
                              fullName={comment.fullName}
                              photo={comment?.photo}
                              timeAgo={comment?.timeAgo}
                              message={comment.message}
                              isLikedByMe={comment?.isLikedByMe}
                              likesCount={comment?.likesCount}
                              replies={comment?.replies}
                              timeStampResource={comment?.timeStampResource}
                              postLikeComment={postLikeComments}
                              replyHandler={replyHandler}
                              postReply={postReply}
                              profile={profile}
                              isAllowedComment
                              putComment={putEditComment}
                            />
                          );
                        })}
                    </Box>
                  </Grow>
                ) : (
                  <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress />
                  </Box>
                )}
                <Box
                  gridGap="10px"
                  display="flex"
                  alignItems="center"
                  width="100%"
                  marginTop={1}
                >
                  <Avatar
                    src={profile?.photo}
                    alt={profile?.fullName}
                    className={classes.thumbnail}
                  />
                  <TextField
                    className={classes.inputText}
                    placeholder={`${resource(
                      props?.culture,
                      "CommentsArea::AddComment"
                    )}...`}
                    onKeyUp={handleEnter}
                    onChange={handleChange}
                    value={commentValue}
                    type="text"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={postComments}
                    disabled={false}
                  >
                    <Resource tag={"CommentsArea::Post"} />
                  </Button>
                </Box>
              </Box>
            )}
            {!reactNewApp && (
              <Box p="0 24px 24px 24px" className="comments-container">
                <Box display="flex" justifyContent="space-between" paddingY={1}>
                  <Typography
                    className={classes.icon}
                    variant="caption"
                    color="primary"
                  >
                    <EMIcon
                      icon="comments"
                      color={theme.palette.primary.main}
                    />
                    <Resource
                      tag="CommentsArea::Comments"
                      args={[newsDetail?.commentsCount]}
                    />
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {newsDetail?.commentsCount === 1 ? (
                      <Resource
                        tag="PageDetailChallenge::Idea_CommentNumber"
                        args={[newsDetail?.commentsCount]}
                      />
                    ) : newsDetail?.commentsCount === 0 ? (
                      ""
                    ) : (
                      <Resource
                        tag="PageDetailChallenge::Idea_CommentsNumber"
                        args={[newsDetail?.commentsCount]}
                      />
                    )}
                  </Typography>
                </Box>
                <Divider className={classes.divider} />
                <Box display="flex" justifyContent="space-between" paddingY={1}>
                  {props?.messagesCount > props?.totalMessagePages ? (
                    <Typography
                      onClick={handleMoreComments}
                      style={{ textTransform: "lowercase", cursor: "pointer" }}
                      variant="caption"
                      color="primary"
                    >
                      <Resource tag="Common::PreviousComments" />
                    </Typography>
                  ) : (
                    <Typography
                      style={{ textTransform: "lowercase", cursor: "pointer" }}
                      variant="caption"
                      color="primary"
                    ></Typography>
                  )}
                  {props?.messagesCount > 0 ? (
                    <Typography variant="caption" color="textSecondary">
                      {props?.totalMessagePages} <Resource tag="Common::of" />{" "}
                      {props?.messagesCount}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
                {newsCommentStatus !== "LOADING" ? (
                  <Grow in>
                    <Box>
                      {newsComments &&
                        newsComments?.map((comment) => {
                          return (
                            <CommentItem
                              key={comment?.commentId}
                              commentId={comment?.commentId}
                              userId={comment?.userId}
                              fullName={comment.fullName}
                              photo={comment?.photo}
                              message={comment.message}
                              isLikedByMe={comment?.isLikedByMe}
                              likesCount={comment?.likesCount}
                              timeAgo={comment?.timeAgo}
                              replies={comment?.replies}
                              timeStampResource={comment?.timeStampResource}
                              postLikeComment={postLikeComments}
                              replyHandler={replyHandler}
                              postReply={postReply}
                              profile={profile}
                              isAllowedComment
                            />
                          );
                        })}
                    </Box>
                  </Grow>
                ) : (
                  <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress />
                  </Box>
                )}
                <Box
                  gridGap="10px"
                  display="flex"
                  alignItems="center"
                  width="100%"
                  marginTop={1}
                >
                  <Avatar
                    src={profile?.photo}
                    alt={profile?.fullName}
                    className={classes.thumbnail}
                  />
                  <TextField
                    className={classes.inputText}
                    placeholder={`${resource(
                      props?.culture,
                      "CommentsArea::AddComment"
                    )}...`}
                    onKeyUp={handleEnter}
                    onChange={handleChange}
                    value={commentValue}
                    type="text"
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={postComments}
                    disabled={false}
                  >
                    <Resource tag={"CommentsArea::Post"} />
                  </Button>
                </Box>
              </Box>
            )}
          </Paper>
          <Box marginTop={5} display="flex" flexDirection="column" gridGap={16}>
            <Typography variant="h3">
              <Resource tag="PageNews::AboutThisTopic" />
            </Typography>
            <Grid item xs={12}>
              <NewsList
                arrNews={newsDetail?.relatedNews}
                isLoading={isLoading}
              />
            </Grid>
          </Box>
        </Container>
      ) : (
        <NewsDetailSkeleton />
      )}
    </>
  );
}

const mapStateToProps = (store: IAppState, ownProps: any) => {
  return {
    news: store?.newsState?.news?.news?.result?.filter(
      (news: any) => news.newsId === ownProps?.match?.params?.newsId
    )[0],
    newsId: ownProps?.match?.params?.newsId,
    newsDetail: store.newDetailState.newsDetail,
    newsDetailStatus: store.newDetailState.newsDetailStatus,
    newsComments: store.newDetailState.newsComments,
    newsCommentStatus: store.newDetailState.newsCommentStatus,
    profile: store.profileState.profile,
    culture: store.cultureState,
    likeStatus: store.newDetailState.likeStatus,
    metaComments: store.newDetailState.metaComments,
    applicationId: store.authState.auth.applicationId,
    messagesCount: store.newDetailState.messagesCount,
    totalMessagePages: store.newDetailState.totalMessagePages,
    workspaceId: store.authState.auth.workspaceId,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getNewsDetail: (options: object) => dispatch(getNewsDetail(options)),
    getNewsComments: (options: object) => dispatch(getNewsComments(options)),
    handleLikeNews: (options: object) => dispatch(handleLikeNews(options)),
    handleDislikeNews: (options: object) =>
      dispatch(handleDislikeNews(options)),
    postComment: (options: object) => dispatch(postComment(options)),
    putNewsComment: (options: object) => dispatch(putNewsComment(options)),
    deleteNewsComment: (options: object) =>
      dispatch(deleteNewsComment(options)),
    deleteNewsCommentReply: (options: object) =>
      dispatch(deleteNewsCommentReply(options)),
    postLikeComment: (options: object) => dispatch(postLikeComment(options)),
    postDislikeComment: (options: object) =>
      dispatch(postDislikeComment(options)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsDetail)
);
