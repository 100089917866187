import React, { useEffect, useState } from 'react';

// Redux
import { connect } from 'react-redux';
import { AnyAction } from 'redux'
import { IAppState } from 'redux/store/Store';
import { ThunkDispatch } from 'redux-thunk';
import { getRanking, IRankingAction, searchRanking, ISearchRankingAction } from 'redux/actions/RankingActions'
import { IRanking, IMyRanking, IRankingUsers } from 'redux/reducers/rankingsReducer'
import { IApplication } from 'redux/reducers/tenantReducer';
import { ICultureState } from 'redux/reducers/cultureReducer';

// Components
import RankingPodium from 'components/RankingPodium/RankingPodium';
import RankingList from 'components/RankingList/RankingList';

// Style
import { Box, Container, createStyles, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Resource, { resource } from 'components/Resources/Resource';
import imageEmpty from 'assets/img/empty@3x.png';
import './Ranking.css'
import RankingPageSkeleton from './RankingPage.Skeleton';

const options = {
  applicationId: localStorage.getItem("applicationId"),
  pageNumber: 1, //TODO: Change for real options
  pageSize: 10, //TODO: Change for real options
  culture: {}
};
const searchOptions = {
  applicationId: 0,
  searchTerm: ""
}

interface IProps {
  getRanking: (options: object) => Promise<IRankingAction>;
  searchRanking: (options: object) => Promise<ISearchRankingAction>;
}
interface IStateProps {
  ranking: IRanking;
  myRanking: IMyRanking;
  application: IApplication;
  culture: ICultureState
  top3Users: IRankingUsers[],
  usersFound: IRankingUsers[],
  missingPoints: number,
  fullName: string,
  photo: string,
  rankingPosition: number,
  rankingPoints: number,
  resourceYou: string
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  textPodium:{
    color: '#fff',
    textAlign: "left",
    width:"100%",
    padding:"8px 24px"
  },
  frameRankingImg:{ 
    minHeight: 620, 
    width: '100%',
  },
  whiteBackgroundArc:{
    right: 0,
    width: "calc(100% - 210px)",
    bottom: "28vh",
    height: "50vh",
    zIndex: -2,
    position: "absolute",
    backgroundColor: "white",
    borderTopLeftRadius: "50%",
    borderTopRightRadius: "50%",

    [theme.breakpoints.down("sm")]:{
      width:"100%"
    }
  },
  blueBackground:{
    right: 0,
    width: "calc(100% - 210px)",
    top: 0,
    height: "50vh",
    zIndex: -3,
    position: "absolute",
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down("sm")]:{
      width:"100%"
    }
  }

}))

function Rankings(props: IProps & IStateProps) {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true
    const { getRanking } = props
    options.applicationId = String(props.application.applicationId);
    options.culture = props.culture;

    isMounted && getRanking(options).then(() => {
      isMounted && setLoading(false);
    });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [showFilterRanking, setShowFilterRanking] = useState(false)

  let keyTimer: any
  let waitTimeAfterType = 500
  const classes = useStyles()

  const handlerKeyUp = ((e: any) => {
    let text = e.value;
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      search(text)
    }, waitTimeAfterType);
  });

  const handlerKeydDown = (() => {
    clearTimeout(keyTimer);
  });

  const search = (text: string) => {
    const { searchRanking, getRanking, application } = props
    if (text.length >= 3) {
      searchOptions.searchTerm = text
      searchOptions.applicationId = application.applicationId
      setShowFilterRanking(true)
      searchRanking(searchOptions)
    }
    else {
      setShowFilterRanking(false)
      getRanking(options)
      return
    }
  }

  const { ranking, myRanking, usersFound } = props
  const rankingData = ranking.result.map((data, index) => {
    let rankingList = []
    if (myRanking.fullName === data.fullName) {
      const list = {
        avatar: data.photo,
        name: data.fullName,
        rank: Number(data.rankingPosition),
        points: Number(data.rankingPoints),
        mySelf: true
      }
      rankingList[index] = list
    } else {
      const list = {
        avatar: data.photo,
        name: data.fullName,
        rank: Number(data.rankingPosition),
        points: Number(data.rankingPoints),
        mySelf: false
      }
      rankingList[index] = list
    }
    return rankingList[index]
  })

  const rankingSearchData = usersFound?.map((data, index) => {
    let rankingList = []
    if (myRanking.fullName === data.fullName) {
      const list = {
        avatar: data.photo,
        name: data.fullName,
        rank: Number(data.rankingPosition),
        points: Number(data.rankingPoints),
        mySelf: true
      }
      rankingList[index] = list
    } else {
      const list = {
        avatar: data.photo,
        name: data.fullName,
        rank: Number(data.rankingPosition),
        points: Number(data.rankingPoints),
        mySelf: true
      }
      rankingList[index] = list
    }
    return rankingList[index]
  })
  
  const first = {
    avatar: ranking?.result[0]?.photo,
    name: myRanking.fullName === ranking?.result[0]?.fullName ? resource(props?.culture, 'PageRanking::TextYou') : ranking?.result[0]?.fullName ,
    avatarName: ranking?.result[0]?.fullName,
    points: Number(ranking?.result[0]?.rankingPoints)
  }

  const second = {
    avatar: ranking?.result[1]?.photo,
    name: myRanking.fullName === ranking?.result[1]?.fullName ? resource(props?.culture, 'PageRanking::TextYou') :  ranking?.result[1]?.fullName,
    avatarName: ranking?.result[1]?.fullName,
    points: Number(ranking?.result[1]?.rankingPoints)
  }

  const third = {
    avatar: ranking?.result[2]?.photo,
    name: myRanking.fullName === ranking?.result[2]?.fullName ? resource(props?.culture, 'PageRanking::TextYou') : ranking?.result[2]?.fullName,
    avatarName: ranking?.result[2]?.fullName,
    points: Number(ranking?.result[2]?.rankingPoints)
  }

  return (
    <>
      {loading ?
        <RankingPageSkeleton />
      :
      <>
        { (ranking?.result.length === 0) ?
          <div className="no-ranking">
            <p>
              <Resource tag="Errors::NoRankingAvailable" />
            </p>
            <img src={imageEmpty} alt="" />
          </div>
        :
        <>
          <Box>
            <Box>
              <Typography className={classes.textPodium}>
                {Number(myRanking.rankingPosition) < 3 
                  ?
                    <Resource 
                      tag="PageRanking::YouAreInPodium" 
                      args={
                        [
                          `<strong>${myRanking.rankingPosition}</strong>`, 
                          `<strong>${ranking.meta.total}</strong>`
                        ]
                      }
                    />
                  : 
                    <Resource 
                      tag="PageRanking::MissingPodiumPoints" 
                      args={
                        [
                          `<strong>${myRanking.rankingPosition}</strong>`,
                          `<strong>${ranking.meta.total}</strong>`,
                          `<strong>${Number(third.points) - Number(myRanking.rankingPoints)}</strong>`
                        ]
                      }
                    />
                }
              </Typography>
            </Box>
            <Box className={classes.whiteBackgroundArc} />
            <Box className={classes.blueBackground} />
          </Box>
          <Container className="name-container" style={{ paddingTop: '10px' }}>
            <Grid container>
              <Grid item xs={12} style={{marginTop:20}}>
                <RankingPodium
                  first={first}
                  second={second}
                  third={third}
                  cultureTag={props.culture.culture.cultureTag}
                />
              </Grid>
              <Grid item xs={12}>
                {ranking.meta.total > 3 ?
                  <>
                    <Box className="search-input-container">
                      <TextField placeholder={resource(props.culture, 'PageRanking::PlaceholderSearch')} onKeyUp={(e) => { handlerKeyUp(e.target) }} onKeyDown={handlerKeydDown} className="search-input" id="ranking-search" />
                      <Box component='span' className="icon-container" >
                        <SearchIcon />
                      </Box>
                    </Box>
                    <Box marginBottom={5}>
                      {!showFilterRanking && <RankingList search={showFilterRanking} cultureTag={props.culture.culture.cultureTag} list={rankingData} />}
                      {showFilterRanking && <RankingList search={showFilterRanking} cultureTag={props.culture.culture.cultureTag} list={rankingSearchData} />}
                    </Box>
                  </>
                :
                  ''
                }
              </Grid>
            </Grid>
          </Container>
        </>
        }
      </>
      }
    </>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IProps => {
  return {
    getRanking: (parameters: object) => dispatch(getRanking(parameters)),
    searchRanking: (parameters: object) => dispatch(searchRanking(parameters))
  };
};
const mapStateToProps = (store: IAppState) => {
  return {
    ranking: store.rankingsState.rankings,
    myRanking: store.rankingsState.myRanking,
    application: store.tenantState.application,
    top3Users: store.rankingsState.top3Users,
    usersFound: store.rankingsState.usersFound,
    missingPoints: store.rankingsState.missingPoints,
    fullName: store.rankingsState.fullName,
    photo: store.rankingsState.photo,
    rankingPosition: store.rankingsState.rankingPosition,
    rankingPoints: store.rankingsState.rankingPoints,
    resourceYou: store.rankingsState.resourceYou,
    culture: store.cultureState
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rankings);