import { AnyAction } from "redux";
import { connect } from "react-redux";
import Steps from "components/Steps/Steps";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import Resource from "components/Resources/Resource";
import StickyComponent from "components/Sticky/Sticky";
import { IManageGroups } from "redux/types/manageGroups";
import { ICultureState } from "redux/reducers/cultureReducer";
import { useCallback, useEffect, useState } from "react";
import CreatePrizeStepDetails from "./Steps/CreatePrizeStepDetails";
import { Box, Button, CircularProgress, createStyles, makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import CreatePrizeStepParticipants from "./Steps/CreatePrizeStepParticipants";
import { PageStateDetailPrize, PageStateParticipantsPrize, PageStatePrize } from "redux/types/createPrize";
import CreatePrizesStepConfirmation from "./Steps/CreatePrizesStepConfirmation";
import { getManageGroups } from "redux/actions/manageGroups/manageGroupsAction";
import { putPrizeDetail, updateDetailsPrize, updateParticipantsPrize, getCreatePrizeDetail } from "redux/actions/CreatePrize/CreatePrizeActions";
import { RouteComponentProps } from "react-router-dom";
import history from 'routes/history';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerLoading: {
      margin: "0px auto",
      height: "80vh",
    },
  })
);

interface IDispatchProps {
  updateDetailsPrize: (newValue: any) => any;
  updateParticipantsPrize: (newValue: any) => any;
  getManageGroups: (options: object) => Promise<any>;
  putPrizeDetail: (options: object) => Promise<any>;
  getCreatePrizeDetail: (options: object) => Promise<any>;
}

interface IProps {
  culture: ICultureState;
  groups: IManageGroups;
  workspaceId: string;
  pageStatePrize: PageStatePrize;
  allGroupsSelected: boolean;
}

interface IMatch {
  prizeId: string
}

function EditPrizePage(props: IProps & IDispatchProps & IMatch) {
  const classes = useStyles();
  const {
    culture,
    workspaceId,
    pageStatePrize,
    groups,
    prizeId,
    getManageGroups,
    updateDetailsPrize,
    updateParticipantsPrize,
    putPrizeDetail,
    getCreatePrizeDetail
  } = props;

  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));
  const [loading, setLoading] = useState(true);
  const [allGroupsSelected, setAllGroupsSelected] = useState(false);
  const [activeIndex, setActive] = useState(1);

  useEffect(() => {
    Promise.all([
      getCreatePrizeDetail({
        prizeId,
        workspaceId,
        culture: culture.culture
      }),
      getManageGroups({
        workspaceId,
        cultureTag: culture.culture.cultureTag,
      }),

    ]).finally(() => setLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDetailsStatePrize = useCallback(
    (newState: PageStateDetailPrize) => {
      updateDetailsPrize(newState);
    },
    [updateDetailsPrize]
  );

  const setParticipantsStatePrize = useCallback(
    (newState: PageStateParticipantsPrize) => {
      updateParticipantsPrize(newState);
    },
    [updateParticipantsPrize]
  );

  const buttonArr = [
    {
      title: <Resource tag="PageCreatePrize::Details" />,
      form: (
        <CreatePrizeStepDetails
          culture={culture}
          setSteps={setDetailsStatePrize}
          steps={pageStatePrize?.stepDetail}
        />
      ),
    },
    {
      title: <Resource tag="PageCreatePrize::Participants" />,
      form: (
        <CreatePrizeStepParticipants
          culture={culture}
          allGroups={groups}
          setSteps={setParticipantsStatePrize}
          allGroupsSelected={allGroupsSelected}
          setAllGroupsSelected={setAllGroupsSelected}
          steps={pageStatePrize?.stepParticipants}
        />
      ),
    },
    {
      title: <Resource tag="PageCreatePrize::Confirmation" />,
      form: (
        <CreatePrizesStepConfirmation
          culture={culture}
          setActive={setActive}
          steps={pageStatePrize}
          groups={groups}
          allGroupsSelected={allGroupsSelected}
        />
      ),
    },
  ];

  function updatePrize() {
    let params = {
      workspaceId: workspaceId,
      prizeType: pageStatePrize.stepDetail.prizeType,
      activationDate: pageStatePrize.stepDetail.activationDate,
      endDate: pageStatePrize.stepDetail.endDate,
      unit: Number(pageStatePrize.stepDetail.unit),
      maxUnitPerUser: pageStatePrize.stepDetail.prizeType === 1 ? 0 : Number(pageStatePrize.stepDetail.maxUnitPerUser),
      value: Number(pageStatePrize.stepDetail.value),
      image: pageStatePrize.stepDetail.image,
      cultures: [
        {
          description: pageStatePrize.stepDetail.description,
          cultureTag: culture.culture.cultureTag,
          title: pageStatePrize.stepDetail.title
        }
      ],
      groupsId: pageStatePrize.stepParticipants.groupsId
    }

    putPrizeDetail({ culture: culture, params, prizeId });
  }

  return (
    <Box mt={4} className="prize-create-page">
      {loading ?
        <>
          <Box height="100%" display="flex" className={classes.centerLoading}>
            <Box m="auto">
              <CircularProgress size={60} className="icon" />
            </Box>
          </Box>
        </>
        :
        <>
          <StickyComponent offsetX={"0"}>
            <Box
              className="steps-and-buttons-container"
              display={matchXs ? "block" : "flex"}
              alignItems="center"
              justifyContent="space-between"
              style={{ backgroundColor: theme.palette.common.white }}
            >
              <Steps
                activeIndex={activeIndex}
                setActive={setActive}
                contentArr={buttonArr}
              />
              <Box
                className="btns-cancel-next"
                display="flex"
                justifyContent="flex-end"
                mt={matchXs ? "20px" : 0}
              >
                <Box mr={2}>
                  <Button variant="outlined" color="primary" size="small" onClick={() => history.push('/prizes')}>
                    <Resource tag="PageCreatePrize::Cancel" />
                  </Button>
                </Box>
                {activeIndex !== 3 ?
                  <Button
                    onClick={() => setActive(activeIndex + 1)}
                    disabled={activeIndex >= buttonArr?.length}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <Resource tag="PageCreatePrize::Next" />
                  </Button>
                  :
                  <Button
                    onClick={() => updatePrize()}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={
                      !pageStatePrize?.stepDetail?.activationDate || !pageStatePrize?.stepDetail?.endDate ||
                      !pageStatePrize?.stepDetail?.image || !pageStatePrize?.stepDetail?.title || !pageStatePrize?.stepDetail?.description ||
                      !pageStatePrize?.stepDetail?.value || (pageStatePrize?.stepParticipants?.groupsId?.length === 0 && !allGroupsSelected)
                    }
                  >
                    <Resource tag="PageCreatePrize::SaveAndPublish" />
                  </Button>
                }
              </Box>
            </Box>
          </StickyComponent>
          {buttonArr?.map((data: any, index: any) => {
            return (
              <Box mt={4} key={index} className="steps-content">
                {index + 1 === activeIndex && <>{data?.form}</>}
              </Box>
            );
          })}
        </>
      }
    </Box>
  );
}

const mapStateToProps = (store: IAppState, ownProps: RouteComponentProps<IMatch>) => ({
  culture: store.cultureState,
  groups: store.manageGroupsState.groups,
  workspaceId: store.authState.auth.workspaceId,
  pageStatePrize: store.createPrizeState.pageState,
  prizeId: ownProps.match.params.prizeId,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  updateDetailsPrize: (newValue: any) => dispatch(updateDetailsPrize(newValue)),
  updateParticipantsPrize: (newValue: any) => dispatch(updateParticipantsPrize(newValue)),
  getManageGroups: (options: object) => dispatch(getManageGroups(options)),
  putPrizeDetail: (options: object) => dispatch(putPrizeDetail(options)),
  getCreatePrizeDetail: (options: object) => dispatch(getCreatePrizeDetail(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPrizePage);
