import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import XDialog from "common/X/XDialog";
import Resource from "components/Resources/Resource";
import React, { useEffect, useState } from "react";
import Truncate from "react-truncate";
import ClearIcon from "@material-ui/icons/Clear";
import XAutocomplete from "common/UI/XAutocomplete/XAutocomplete";
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IUsers, IUsersState } from "redux/reducers/usersReducers";
import { getAllUsers } from "redux/actions/UsersAction";
import { getManageGroups, putAssignGroups } from "redux/actions/manageGroups/manageGroupsAction";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  culture: any;
  members?: any;
  previewManagers?: any[];
  users: IUsers[];
  groupId: string[];
  workspaceId: string;
}
interface IDispatchProps {
  getAllUsers: (options: object) => Promise<IUsersState>
  putAssignGroups: (options: object) => Promise<any>
  getManageGroups: (options: object) => Promise<any>
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    actualManagers: {
      marginLeft: "auto",
      marginTop: theme.spacing(2),
      minWidth: "300px",
      width: "20%",
      height: "300px",
      overflowY: "auto",

      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    listButton: {
      width: 25,
      height: 25,
      borderRadius: 5,
      boxShadow: "0 0 5px " + theme.palette.grey[400],
      marginTop: 4,
      marginBottom: 4,

      "&:hover": {
        borderRadius: 5,
      },

    }
  })
);

function DialogSettingsAssignMembersToGroup(props: IProps & IDispatchProps) {
  const classes = useStyles();
  const { culture, open, setOpen, getAllUsers, users, groupId, workspaceId, putAssignGroups, getManageGroups } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [autoCompleteValue, setAutoCompleteValue] = useState<any[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    getAllUsers({ q: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (value: any) => {
    if (value?.length) {
      setAutoCompleteValue(value);
    }
  };

  const handleDeleteValue = (value: any) => {
    setAutoCompleteValue(autoCompleteValue.filter((x) => x.fullName !== value));
  };

  function assignMembersToGroup() {
    let usersId = autoCompleteValue.map(x =>
      x.userId,
    )

    setLoadingSubmit(true);

    putAssignGroups({ groupsId: groupId, usersId, workspaceId, cultures: culture })
      .then(() => {
        getManageGroups({ workspaceId, cultureTag: culture.culture.cultureTag })
        setAutoCompleteValue([]);
        setLoadingSubmit(false);
        setOpen(false);
      });
  }

  const actions = (
    <Box textAlign="right">
      <Box component="span" mr={2} key={"button1"}>
        <Button
          size="small"
          onClick={() => setOpen(false)}
          color="primary"
          variant="outlined"
        >
          <Resource tag="PageSettings::Cancel" />
        </Button>
      </Box>
      <Box component="span" key={"button2"}>
        <Button
          size="small"
          color="primary"
          variant={!loadingSubmit ? "contained" : "outlined"}
          disabled={!autoCompleteValue.length}
          onClick={() => assignMembersToGroup()}
          endIcon={
            loadingSubmit && (
              <CircularProgress size={22} className="icon" />
            )
          }
        >
          <Resource tag="PageSettings::SaveChanges" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      title={<Resource tag="PageSettings::AssignMembersToGroup" />}
      actions={actions}
      maxWidth="md"
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">
            <Resource tag="PageSettings::Find" /> .....
          </Typography>
          <Box mt={0.5}>
            <XAutocomplete
              culture={culture}
              options={users}
              optionName={"fullName"}
              optionAvatar={"photo"}
              optionSubtitle={"email"}
              autoCompleteValue={autoCompleteValue}
              onChange={(newValue) => handleChange(newValue)}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box className={classes.actualManagers} mx={mobile ? "auto" : ""}>
            {autoCompleteValue?.map((value: any, index: number) => {
              return (
                <div className="manager-item-container" key={value.userId}>
                  <Avatar
                    src={value?.photo}
                    alt={value.fullName}
                    className={classes.thumbnail}
                  />
                  <div className="manager-item-label">
                    <Truncate width={120} ellipsis={<span>...</span>}>
                      {value.fullName}
                    </Truncate>
                  </div>
                  <div className="manage-item-cross">
                    <IconButton
                      className="bottom-manage-item-cross"
                      onClick={() => handleDeleteValue(value.fullName)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </XDialog>
  );
}
const mapStateToProps = (store: IAppState) => ({
  culture: store.cultureState,
  users: store.usersState.users
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => ({
  getAllUsers: (options: object) => dispatch(getAllUsers(options)),
  putAssignGroups: (options: object) => dispatch(putAssignGroups(options)),
  getManageGroups: (options: object) => dispatch(getManageGroups(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogSettingsAssignMembersToGroup);
