import Truncate from "react-truncate";
import ClearIcon from "@material-ui/icons/Clear";
import ToolTip from "components/ToolTip/ToolTip";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import { IUsers } from "redux/reducers/usersReducers";
import { ICultureState } from "redux/reducers/cultureReducer";
import XAutocomplete from "common/UI/XAutocomplete/XAutocomplete";
import {
  Avatar,
  Box,
  Container,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography  
} from "@material-ui/core";



//@ts-ignore
import WorkflowValidatorDynamicCard from "components/Workflow/WorkflowDialogsContent/ValidatorDynamicCard/ValidatorDynamicCard.tsx";

import "./ValidatorDynamic.scss"

interface IProps {
  users: IUsers[];
  culture: ICultureState;
  fatherState: any;
  setFatherState: (newValue: Array<any>) => void; 

  
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  })
);


function WorkflowValidatorDynamic(props: any) { 
  return (    
    <Box className="workflowValidatorDynamic">
      <Box display="flex" alignItems="center" mb={1}>
          <Typography
            variant="h4"
            component="span"
            style={{ width: "fit-content", marginRight: 8 }}
          >
            <Resource
              tag={"Workflow::ConditionToValidate"}
            />
          </Typography>           
        </Box>
        <Typography variant="body2" color="textSecondary">
          <Resource
            tag={"Workflow::SpecifyValidatorsText"}
          />
        </Typography>
       <WorkflowValidatorDynamicCard 
          fatherState={props?.fatherState}
          setFatherState={props?.setFatherState}
          mapAddItem={props?.mapAddItem}
          setMapAddItem={props?.setMapAddItem}
          radioType={props?.radioType}
       /> 
    </Box>
  );
}

export default WorkflowValidatorDynamic;
