import {IChallengesClosed, IChallengesPermanent} from "redux/types/challenges";
import {
    Box,
    CircularProgress,
    FormGroup,
    Grid,
} from "@material-ui/core";
import ClosedCard from "../../ChallengesCards/ClosedCard/ClosedCard";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {resource} from "../../Resources/Resource";
import {ICultureState} from "../../../redux/reducers/cultureReducer";
import PermanentCard from "../../ChallengesCards/PermanentCard/PermanentCard";

interface IProps {
    challengePermanent: IChallengesPermanent;
    challengeStatus: string;
    culture: ICultureState;
}

export default function ChallengePermanentList(props: IProps) {
    const {
        culture,
        challengePermanent,
        challengeStatus,
    } = props;

    useEffect(() => {
        if (!challengePermanent || challengePermanent?.result?.length === 0) {
            toast.error(resource(culture, "PageSettings::NoResults"));
        }
    }, [challengePermanent?.result?.length]);

    return (
        <FormGroup>
            {challengeStatus === "LOADING" ? (
                <Box display="flex" justifyContent="center" alignItems="center" p={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container>
                    <Grid container spacing={2} direction="row">
                        {challengePermanent &&
                            challengePermanent?.result?.map((chaP, index) => {
                                return (
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <PermanentCard
                                            key={"permanent_" + challengePermanent.meta.currentPage + "_" + index}
                                            challengesPermanent={chaP}
                                            culture={culture}
                                        />
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            )}
        </FormGroup>
    );
}