import React, { useEffect, useState } from 'react';
import { IFilters, IKeyTasksState, IResultValidationKeyTasks } from '../../redux/reducers/keyTasks/keyTasksReducer';
import KeyTaskDetailButtons from '../../components/KeyTaskDetailButtons/KeyTaskDetailButtons';
import { Table, TableHead, TableCell, TableRow, TableBody, Theme, createStyles, makeStyles, Hidden, Typography, Box, Checkbox } from '@material-ui/core';
import Resource from 'components/Resources/Resource';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ICultureState } from 'redux/reducers/cultureReducer';
import KeyTasksFilters from 'components/KeyTasksFilters/KeyTasksFilters';
import KeyTaskListItemValidation from 'components/KeyTaskListItem/KeyTaskListItemValidation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      table: {
        margin: '20px 0px',
      },
      tableTitles: {        
        'padding': '5px 10px 0px 0!important',
        'color': ' #767676',
        'font-weight': 500,
        'text-transform': 'uppercase',
      },
      tableRow: {
        borderBottom: 'solid 1px #ccc',
        [theme.breakpoints.down('sm')]: {
          borderBottom: 'none'
        }
      }
  })
);

interface IProps {
  validationList: IResultValidationKeyTasks[];
  radioButtons?: boolean;
  culture?: ICultureState
  filters?: IFilters
  getValidationKeyTasks?(options: object): Promise<IKeyTasksState>;
}

function KeyTaskValidationList(props: IProps) {
  const classes = useStyles()
  const [checkAll, setCheckAll] = useState(false)
  const [dataObject, setDataObject] = useState({
    ideaId: '',
    title: [''],
    ideaIds: [''],
  })

  const { validationList, radioButtons, culture, filters, getValidationKeyTasks } = props;

  const handlerPopUp = (value: string, check: boolean) => {
    const data = value.split(',').map(data => data)
    if(check){
      dataObject.ideaIds[0] === '' && dataObject.ideaIds.shift()
      dataObject.title[0] === '' && dataObject.title.shift()

      const dataObjectHandler = {
        title: dataObject.title.concat(data[1]),
        ideaId: data[0],
        ideaIds: dataObject.ideaIds.concat(data[0])
      }
      setDataObject(dataObjectHandler)
    }else{
      dataObject.title.length === 1 && dataObject.title.shift()
      const title = dataObject.title.splice(dataObject.title.indexOf(data[1]), 1);
      const ideaIds = dataObject.ideaIds.filter(e => e !== data[0])
      const unCheckData = {
        title: title,
        ideaId: '',
        ideaIds
      }
      setDataObject(unCheckData)
    }
  }
  const checkAllIdeaIdData = validationList?.map( data => data.ideaId)
  const checkAllTitleData = validationList?.map( data => data.ideaTitle)

  const handleCheckAll = (e:any) => {
    const check = e?.target?.checked
    setCheckAll(check)
    if(check) {
      const allCheckData = {
        title: checkAllTitleData,
        ideaId: '',
        ideaIds: checkAllIdeaIdData
      }
      setDataObject(allCheckData)
    }else{
      const allCheckData = {
        title: [],
        ideaId: '',
        ideaIds: []
      }
      setDataObject(allCheckData)
    }
  }

  useEffect(() => {
    setDataObject({
      ideaId: '',
      title: [''],
      ideaIds: ['']
    })
  }, [validationList])

  return (
    <>
      {validationList ?
      <>
        
        <KeyTasksFilters typeFilter="validation" culture={culture!} filters={filters!} getValidationKeyTasks={getValidationKeyTasks}/>
        <Box display="flex" justifyContent="flex-end" width='100%' mt={2} mb={1}>
          <KeyTaskDetailButtons dataObject={dataObject} type="validation" />
        </Box>
        <Table className={classes.table}>
          <TableHead >
            {radioButtons &&
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableTitles}>
                  <Checkbox
                    onChange={handleCheckAll}
                    checkedIcon={<RadioButtonCheckedIcon/>}
                    icon={<RadioButtonUncheckedIcon/>}
                    inputProps={{ 'aria-label': 'Checkbox A', name: "idea-key-task" }}
                    color="primary"
                    
                  />
                </TableCell >
                <TableCell className={classes.tableTitles}>
                  <Hidden xsDown>
                    <Typography><Resource tag="PageIdea::Idea_ColumnIdeia" /></Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Hidden xsDown>
                    <Typography>
                      <Resource tag="Common::Status"/>
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Hidden implementation="css" xsDown>
                    <Typography>
                      <Resource tag="Common::Challenge"/>
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>                
                  <Hidden xsDown>
                    <Typography>
                      <Resource tag="PageKeyTasks::NumberOfCorrections"/>
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>                
                  <Hidden xsDown>
                    <Typography>
                      <Resource tag="PageKeyTasks::TimeInThisStatus"/>
                    </Typography>
                  </Hidden>
                </TableCell>
              </TableRow>
            }
          </TableHead>
          <TableBody>
            {validationList?.map((list, index) =>
              <KeyTaskListItemValidation
                checkAll={checkAll}
                key={list?.ideaId}
                type="validation"
                handlerPopUp={handlerPopUp}
                radioButtons={radioButtons}
                title={list?.ideaTitle}
                time={list?.stateUpdated}
                timeType={list?.stateUpdatedAtResource}
                ideaId={list?.ideaId}
                status={list?.status}
                challenge={list?.challengeTitle}
                numberCorrections={list?.numCorrections}
              />
            )}
          </TableBody>
        </Table>
      </>
      :
        <>
          <KeyTasksFilters typeFilter="validation" culture={culture!} filters={filters!} getValidationKeyTasks={getValidationKeyTasks}/>
          <Box textAlign="center" width="100%" p={5}>
            <Typography color="textSecondary" variant="caption"><Resource tag="PageKeyTasks::Error_NoKeyTasksAvailable" /></Typography>
          </Box>    
        </>  
      }
    </>
  )
}

export default KeyTaskValidationList