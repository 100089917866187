import Funnel, {
  Title,
  Margin,
  Export,
  Tooltip,
  Item,
  Border,
  Label
} from 'devextreme-react/funnel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Grid, Input, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { FC, Fragment, useEffect, useState } from 'react';
import Resource, { resource } from 'components/Resources/Resource';
import PortfolioCarousel from 'components/PortfolioCarousel/PortfolioCarousel';
import { ThunkDispatch } from 'redux-thunk';
import { getAllChallengesInspiration, getNewChallengeList, IChallengeGetNewListAction } from 'redux/actions/ChallengeActions';
import { IChallengeAllList } from 'redux/types/challenges';
import { IApplication } from 'redux/reducers/tenantReducer';
import { ICultureState } from 'redux/reducers/cultureReducer';
import { ICountry } from 'redux/reducers/countryReducer';
import { IAllManageGroups } from 'redux/types/manageGroups';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IAppState } from 'redux/store/Store';
import { api_v3 } from 'config/api';
import { TrendingFlat } from '@material-ui/icons';
import {getAllChallenges, getAllCountries, getAllGroups} from "../../../redux/actions/DashboardActions";

export const dataSource = [
  { argument: 'Visited the Website', value: 9152 },
  { argument: 'Downloaded a Trial', value: 6879 },
  { argument: 'Contacted Support', value: 5121 },
  { argument: 'Subscribed', value: 2224 },
  { argument: 'Renewed', value: 1670 }
];

function formatLabel(arg: any) {
  return `<span class="label">${arg.percentText}</span><br/>${arg.item.argument}`;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: `${theme.palette.secondary.contrastText}`,
      borderRadius: 8,
      padding: 10,
      display: "flex",
      justifyContent: "space-between",
      marginTop: 60,
      marginBottom: 60,
      "& > div": {
        padding: 20,
      },
      "& > div:nth-last-child(-n+3)": {
        borderLeft: "solid 1px"
      },
      "& hr": {
        backgroundColor: `${theme.palette.primary.dark}`,
        maxWidth: "50%",
        height: 1,
        border: "none",
        marginTop: 30,
        marginBottom: 20
      }
    },
    card2: {
      backgroundColor: `${theme.palette.secondary.contrastText}`,
      borderRadius: 8,
      overflow: "hidden",
      "& .header": {
        backgroundColor: theme.palette.primary.main,
        padding: 10,
        display: "flex",
        justifyContent: "space-between",
        "& >:last-child": {
          textAlign: "right"
        }
      },
      "& svg": {
        transform: "rotate(90deg)",
        flex: 1
      },
      "& hr": {
        backgroundColor: `${theme.palette.primary.dark}`,
        height: 1,
        border: "none",
        flex: 4
      },
      "& .text": {
        padding: "5px 20px"
      }
    },
    downGrade: {
      display: "flex",
      alignItems: "center",
      "& > div": {
        flex: 1
      }
    },
    container: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 11,
      padding: 16
    },
    label: {
      textTransform: "uppercase",
      color: theme.palette.grey[600],
      fontSize: 13
    },
    labelChart: {
      display: "flex",
      alignItems: "center",
      "& ~ div": {
        display: "none"
      }
    },
    textColor: {
      color: `${theme.palette.primary.dark}`,
    },
    chart: {
      paddingRight: 30,
      "& #center-container": {
        flexDirection: "column!important",
        "& ul": {
          display: "flex",
          width: "fit-content",
          margin: "0 auto"
        }
      }
    },
    userBacklog: {
      display: "flex",
      alignItems: "center",
      "&:nth-child(even)": {
        marginTop: 15,
        marginBottom: 15
      }
    },
    nameBacklog: {
      "& span": {
        paddingRight: 5,
        marginRight: 5,
        borderRight: "solid 2px"
      }
    },
    photoBacklog: {
      width: 40,
      height: 40,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    numberBacklog: {
      padding: 10
    },
    blockBacklog: {
      borderRight: "solid 1px #c1c1c1"
    },
    headerBacklog: {
      marginBottom: 30
    }
  })
);

interface IDispatchProps {
  getNewChallengeList: (options: object) => Promise<IChallengeGetNewListAction>;
  getAllChallengesInspiration: (options: object) => Promise<any>;
}

interface IProps {
  closeButton?: any;
  selectedChallenge?: string;
  challengeList: IChallengeAllList[];
  application: IApplication;
  culture: ICultureState;
  allGroups: IAllManageGroups[];
  countries: ICountry[];
  setChallengeId?: (challenge: any) => void;
}

interface Parametros {
  workspaceId: string;
}

const guid = "00000000-0000-0000-0000-000000000000";

const Overview = (props: IProps & IDispatchProps & Parametros) => {

  const classes = useStyles();
  const [challengeList, setChallengeList] = useState<IChallengeAllList[]>([]);
  const [allGroups, setAllGroups] = useState<IAllManageGroups[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const { workspaceId } = props;
  const [options, setOptions] = useState<{
    challenge: string[],
    groups: string[],
    countries: string[]
  }>({
    challenge: [guid],
    groups: [guid],
    countries: [guid]
  });
  const [data, setData] = useState({
    challengesLaunched: 0,
    ideaPerTypePhaseLists: [],
    participants: 0,
    prizesAwarded: 0,
    top5MostIdeaReturnLists: [],
    top5MostParticipationReturnLists: [],
    top5VisitsReturnLists: [],
    visitors: 0
  });

  useEffect(() => {
    getAllChallenges({workspaceId: workspaceId, status: 0 }).then((res) => {
      if (res) {
        setChallengeList(res!);
      }
    });
    getAllGroups({workspaceId: workspaceId }).then((res) => {
      if (res) {
        setAllGroups(res!);
      }
    });
    getAllCountries({workspaceId: workspaceId }).then((res) => {
      if (res) {
        setCountries(res!);
      }
    });
  }, []);

  const handleSelect = (e: any, arr: any, property: string, stateOption: string[]) => {
    if (stateOption.includes(guid) && !e.target.value.includes(guid)) {
      setOptions({ ...options, [e.target.name]: [] })
    }
    else if (
      e.target.value.includes(guid) &&
      !stateOption.includes(guid)
    ) {
      let items: string[] = [guid];
      arr.forEach((element: any) => {
        items.push(element[property])
      });
      setOptions({ ...options, [e.target.name]: items })
    } else if (
      e.target.value.includes(guid) &&
      stateOption.includes(guid) &&
      e.target.value.length <= (arr.length + 1)
    ) {
      const idx = e.target.value.indexOf(guid);
      e.target.value.splice(idx, 1);
      setOptions({ ...options, [e.target.name]: e.target.value })
    } else {
      setOptions({ ...options, [e.target.name]: e.target.value })
    }
  }

  const send = (workspaceId: any) => {
    api_v3.post("dashboards/overview", {
      workspaceId: workspaceId,
      Challenges: options.challenge,
      Countries: options.countries,
      Groups: options.groups
    })
      .then(r => {
        setData(r.data);
      })
      .catch(err => { console.log(err) })
  }

  const apply = () => {
    send(workspaceId);
  }

  useEffect(() => {
    send(workspaceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);


  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.container}
        >
          <Grid
            container
            alignItems="flex-end"
            justify="space-between"
            spacing={3}
          >

            <Grid item xs={12} sm={3}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageAnalytics::Challenge"}
                />
              </Typography>
              <Select
                labelId="checkbox-challenges-label"
                id="checkbox-challenges"
                name="challenge"
                multiple
                fullWidth
                onChange={(e) => handleSelect(e, challengeList, "challengeId", options.challenge)}
                value={options.challenge}
                input={<Input />}
                renderValue={(selected) => {
                  let names: string[] = [];
                  if (options.challenge.indexOf(guid) > -1) {
                    names.push(resource(props.culture, "PageAnalytics::All"))
                  } else {
                    (selected as string[]).forEach(element => {
                      if (element === guid) {
                        names.unshift(resource(props.culture, "PageAnalytics::All"))
                      } else if (challengeList?.filter((val) => val.challengeId === element)[0]) {
                        names.push(challengeList?.filter((val) => val.challengeId === element)[0].title)
                      }
                    });
                  }
                  return (names as string[]).join(', ')
                }}
              >
                <MenuItem value={guid}>
                  <Checkbox checked={options.challenge.indexOf(guid) > -1} />
                  <Resource tag="PageAnalytics::All" />
                </MenuItem>
                {challengeList?.map((list: any, index: number) => (
                  <MenuItem key={index} value={list.challengeId}>
                    <Checkbox checked={options.challenge.indexOf(list.challengeId) > -1 || options.challenge.indexOf(guid) > -1} />
                    <ListItemText primary={list.title} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageSettings::group"}
                />
              </Typography>
              <Select
                labelId="checkbox-groups-label"
                id="checkbox-groups"
                name="groups"
                multiple
                fullWidth
                onChange={(e) => handleSelect(e, allGroups, "groupId", options.groups)}
                value={options.groups}
                input={<Input />}
                renderValue={(selected) => {
                  let names: string[] = [];
                  if (options.groups.indexOf(guid) > -1) {
                    names.push(resource(props.culture, "PageAnalytics::All"))
                  } else {
                    (selected as string[]).forEach(element => {
                      if (element === guid) {
                        names.unshift(resource(props.culture, "PageAnalytics::All"))
                      } else if (allGroups?.filter((val) => val.groupId === element)[0]) {
                        names.push(allGroups?.filter((val) => val.groupId === element)[0].title)
                      }
                    });
                  }
                  return (names as string[]).join(', ')
                }}
              >
                <MenuItem value={guid}>
                  <Checkbox checked={options.groups.indexOf(guid) > -1} />
                  <Resource tag="PageAnalytics::All" />
                </MenuItem>
                {allGroups?.map((list: any, index: number) => (
                  <MenuItem key={index} value={list.groupId}>
                    <Checkbox checked={options.groups.indexOf(list.groupId) > -1 || options.groups.indexOf(guid) > -1} />
                    <ListItemText primary={list.title} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageSettings::Country"}
                />
              </Typography>
              <Select
                labelId="checkbox-country-label"
                id="checkbox-countri"
                name="countries"
                multiple
                fullWidth
                onChange={(e) => handleSelect(e, countries, "countryId", options.countries)}
                value={options.countries}
                input={<Input />}
                renderValue={(selected) => {
                  let names: string[] = [];
                  if (options.countries.indexOf(guid) > -1) {
                    names.push(resource(props.culture, "PageAnalytics::All"))
                  } else {
                    (selected as string[]).forEach(element => {
                      if (element === guid) {
                        names.unshift(resource(props.culture, "PageAnalytics::All"))
                      } else if (countries?.filter((val) => val.countryId === element)[0]) {
                        names.push(countries?.filter((val) => val.countryId === element)[0].title)
                      }
                    });
                  }
                  return (names as string[]).join(', ')
                }}
              >
                <MenuItem value={guid}>
                  <Checkbox checked={options.countries.indexOf(guid) > -1} />
                  <Resource tag="PageAnalytics::All" />
                </MenuItem>

                {countries?.map((list: any, index: number) => (
                  <MenuItem key={index} value={list.countryId}>
                    <Checkbox checked={options.countries.indexOf(list.countryId) > -1 || options.countries.indexOf(guid) > -1} />
                    <ListItemText primary={list.title} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={3}>
              <Box textAlign="right">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={apply}
                  style={{ color: "#ffffff" }}
                >
                  <Resource
                    tag={"PageDashboard::Apply"}
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.card}>
            <div>
              <Typography variant="h2">
                {
                  data?.visitors ?? 0
                }
              </Typography>
              <Typography variant="body1">
                <Resource
                  tag={"PageDashboard::Visitors"}
                />
              </Typography>
            </div>
            <div>
              <span>
                <Typography variant="h2">
                  {data?.participants ?? 0}
                </Typography>
              </span>
              <Typography variant="body1">
                <Resource
                  tag={"PageDashboard::Participants"}
                />
              </Typography>
            </div>
            <div>
              <span>
                <Typography variant="h2">
                  {data?.challengesLaunched ?? 0}
                </Typography>
              </span>
              <Typography variant="body1">
                <Resource
                  tag={"PageDashboard::ChallengesLaunched"}
                />
              </Typography>
            </div>
            <div>
              <span>
                <Typography variant="h2">
                  {data?.prizesAwarded ?? 0}
                </Typography>
              </span>
              <Typography variant="body1">
                <Resource
                  tag={"PageDashboard::PrizesAwarded"}
                />
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <PortfolioCarousel
            portfolioTag={((options.challenge.indexOf(guid) > -1) || options.challenge.length === 0) ? "PageDashboard::Accumulated" : "PageDashboard::AccumulatedType"}
          />
        </Grid>
        <Grid item xs={12} sm={8} className={classes.chart}>
          <Funnel
            id="funnel"
            dataSource={data?.ideaPerTypePhaseLists ?? []}
            palette="Soft Pastel"
            argumentField="phase"
            valueField="quantidade"
          >
            <Title>
              <Margin bottom={30} />
            </Title>
            <Export enabled={false} />
            <Tooltip enabled={false} format="fixedPoint" />
            <Item>
              <Border visible={true} />
            </Item>
            <Label
              visible={false}
              position="inside"
              backgroundColor="none"
              customizeText={formatLabel}
            />
          </Funnel>

        </Grid>
        <Grid item xs={12} sm={4}>
          <div className={classes.card2}>
            <div className="header">
              <Typography variant="h4">
                <Resource tag={((options.challenge.indexOf(guid) > -1) || options.challenge.length === 0) ? "PageDashboard::TitleOfPhase" : "PageDashboard::PhaseType"} />
              </Typography>
              <Typography variant="h4">
                <Resource tag="PageDashboard::Conversion" />
              </Typography>
            </div>

            {
              data?.ideaPerTypePhaseLists?.map((phase: any, index: number) => (
                <Fragment key={index}>
                  <div className={"text"}>
                    <Typography variant="h2">
                      {phase.quantidade ?? 0}
                    </Typography>
                    <Typography variant="body1">
                      {phase.phase ?? ""}
                    </Typography>
                  </div>
                  {
                    (index + 1) < data.ideaPerTypePhaseLists.length &&
                    <DownGrade value={phase.conversion ?? 0} />
                  }
                </Fragment>
              ))
            }
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <PortfolioCarousel
            portfolioTag={"PageDashboard::Top5"}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" className={classes.headerBacklog}>
            <Resource tag={"PageDashboard::MostIdeas"} />
          </Typography>
          <div className={classes.blockBacklog}>
            {
              data?.top5MostIdeaReturnLists?.map((user: any, index: number) => {
                return <TransitionBacklog
                  key={index}
                  culture={props.culture}
                  urlPhoto={user.imagem}
                  days={user.quantidade}
                  name={user.username}
                  position={index + 1}
                  typeCount="idea"
                />
              })
            }
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" className={classes.headerBacklog}>
            <Resource tag={"PageDashboard::Visits"} />
          </Typography>
          <div className={classes.blockBacklog}>
            {
              data?.top5VisitsReturnLists?.map((user: any, index: number) => {
                return <TransitionBacklog
                  key={index}
                  culture={props.culture}
                  urlPhoto={user.imagem}
                  days={user.quantidade}
                  name={user.username}
                  position={index + 1}
                  typeCount="day"
                />
              })
            }
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h4" className={classes.headerBacklog}>
            <Resource tag={"PageDashboard::MostParticipation"} />
          </Typography>
          <div>
            {
              data?.top5MostParticipationReturnLists?.map((user: any, index: number) => {
                return <TransitionBacklog
                  key={index}
                  culture={props.culture}
                  urlPhoto={user.imagem}
                  days={user.quantidade}
                  name={user.username}
                  position={index + 1}
                  typeCount="participations"
                />
              })
            }
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
}

interface IDownGrade {
  value: number
}

const DownGrade: FC<IDownGrade> = (props: IDownGrade) => {

  const { downGrade } = useStyles();

  return (
    <div className={downGrade}>
      <hr />
      <TrendingFlat />
      <div>
        <Typography variant="body1" >
          {props.value} %
        </Typography>
      </div>
    </div>
  );
}

interface ITransitionBacklog {
  urlPhoto: string,
  position: number,
  name: string,
  days: number,
  culture: any,
  typeCount: 'idea' | 'day' | 'participations'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TransitionBacklog: FC<ITransitionBacklog> = (props: ITransitionBacklog) => {

  const {
    userBacklog,
    photoBacklog,
    nameBacklog,
    numberBacklog,
    textColor,
  } = useStyles();

  return (
    <div className={userBacklog}>
      <div>
        <div
          className={photoBacklog}
          style={{ backgroundImage: `url(${props.urlPhoto})` }}></div>
      </div>
      <div className={numberBacklog}>
        <Typography className={textColor} variant="h2">
          #{props.position}
        </Typography>
      </div>
      <div className={nameBacklog}>
        <Typography variant="button">
          {props.name}
        </Typography>
      </div>
      <div>
        <Typography variant="body1">
          {props.days + " "}
          {resource(props.culture, props.typeCount === "idea" ? "PageAnalytics::OverViewIdeas" : props.typeCount === "day" ? "PageAnalytics::OverViewDay" : "PageAnalytics::OverViewParticipations")}
        </Typography>
      </div>
    </div>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    challengeList: store.challengeState.challengeAllList,
    application: store.tenantState.application,
    culture: store.cultureState,
    allGroups: store.manageGroupsState.allGroups,
    countries: store.countryReducerState.countries
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getNewChallengeList: (parameters: object) =>
      dispatch(getNewChallengeList(parameters)),

    getAllChallengesInspiration: (parameters: object) =>
      dispatch(getAllChallengesInspiration(parameters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview);