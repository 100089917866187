// Import redux types
import { resource } from 'components/Resources/Resource';
import { api_general, api_v2 } from 'config/api';
import { toast } from 'react-toastify';
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IDraftState } from 'redux/reducers/drafts/DraftsReducer';
import { ChallengeDraftsResult } from 'redux/types/challenges';
import { treatError } from 'utils/treatError';
import reactNewApp from "utils/reactNewApp";

const basePath = "challenges";

export enum DraftActionTypes {
  ADD_SELECTED_DRAFT = 'ADD_SELECTED_DRAFT',
  REMOVE_SELECTED_DRAFT = 'REMOVE_SELECTED_DRAFT',
}

export interface IDraftSelectAction {
  type: DraftActionTypes.ADD_SELECTED_DRAFT,
  draft: ChallengeDraftsResult
}

export interface IDraftRemoveSelectAction {
  type: DraftActionTypes.REMOVE_SELECTED_DRAFT
}

export type DraftActions =
  IDraftSelectAction |
  IDraftRemoveSelectAction


export const selectDraft: ActionCreator<
  ThunkAction<Promise<any>, IDraftState, null, IDraftSelectAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DraftActionTypes.ADD_SELECTED_DRAFT,
      draft: options
    })
  };
};

export const removeSelectDraft: ActionCreator<ThunkAction<Promise<any>, IDraftState, null, IDraftRemoveSelectAction>
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DraftActionTypes.REMOVE_SELECTED_DRAFT
    })
  }
}

export const deleteChallengeDraft: ActionCreator<
  ThunkAction<Promise<any>, null, null, any>
> = (params, options) => {
  return async () => {
    try {
      if (reactNewApp) {
        await api_general.delete(`${basePath}/${params.id}`, params);
        toast.success(resource(options?.culture, "PageIdeaDraft::Deleted"));
      } else {
        await api_v2.delete(`${basePath}/${params.id}`, params);
        toast.success(resource(options?.culture, "PageIdeaDraft::Deleted"));
      }
    } catch (err) {
      treatError(options?.culture?.culture, "PageIdea::Header", err);
    }
  };
};
