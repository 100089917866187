import React from "react";
import { Box, Grid } from "@material-ui/core";
import SkeletonGrid from "common/UI/Skeleton/SkeletonGrid";

export default function ProfilePageSkeleton() {
  return (
    <>
      <Box mt={6} mb={5}>
        <Grid item container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SkeletonGrid col={1} row={1} rowHeight={200} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <SkeletonGrid col={1} row={10} rowHeight={50} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
