import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import React, { useEffect, useState } from "react";
import { ITenant } from "redux/reducers/tenantReducer";
import { IWorkspaceReducer } from "redux/reducers/workspaceReducer";
import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core";

interface IProps {
  children: React.ReactNode;
}

interface IStateProps {
  workspaces?: IWorkspaceReducer;
  activeTenant?: ITenant;
}

function ThemeComponent(props: IProps & IStateProps) {
  const workspace = props?.workspaces;
  const activeTenant = props?.activeTenant;

  const [colorWorkSpace, setColorWorkSpace] = useState({
    primaryColor: workspace?.theme?.primaryColor
      ? workspace?.theme?.primaryColor
      : activeTenant?.theme?.primaryColor,
    secondaryColor: workspace?.theme?.secondaryColor
      ? workspace?.theme?.secondaryColor
      : activeTenant?.theme?.secondaryColor,
    tertiaryColor: workspace?.theme?.tertiaryColor
      ? workspace?.theme?.tertiaryColor
      : activeTenant?.theme?.tertiaryColor,
  });

  useEffect(() => {
    activeTenant?.theme &&
      setColorWorkSpace({
        primaryColor: activeTenant?.theme?.primaryColor
          ? activeTenant?.theme?.primaryColor
          : workspace?.theme?.primaryColor,
        secondaryColor: activeTenant?.theme?.secondaryColor
          ? activeTenant?.theme?.secondaryColor
          : workspace?.theme?.secondaryColor,
        tertiaryColor: activeTenant?.theme?.tertiaryColor
          ? activeTenant?.theme?.tertiaryColor
          : workspace?.theme?.tertiaryColor,
      });

    // eslint-disable-next-line
  }, [activeTenant?.theme]);

  useEffect(() => {
    workspace?.theme &&
      setColorWorkSpace({
        primaryColor: workspace?.theme?.primaryColor
          ? workspace?.theme?.primaryColor
          : activeTenant?.theme?.primaryColor,
        secondaryColor: workspace?.theme?.secondaryColor
          ? workspace?.theme?.secondaryColor
          : activeTenant?.theme?.secondaryColor,
        tertiaryColor: workspace?.theme?.tertiaryColor
          ? workspace?.theme?.tertiaryColor
          : activeTenant?.theme?.tertiaryColor,
      });
    // eslint-disable-next-line
  }, [workspace?.theme]);

  const primaryDark = adjust(colorWorkSpace?.primaryColor!, -50);
  const settingsButton = adjust(colorWorkSpace?.secondaryColor!, 50);
  const tertiaryLight = adjust(colorWorkSpace?.tertiaryColor!, 50);
  const tertiaryDark = adjust(colorWorkSpace?.tertiaryColor!, -50);

  function adjust(color: string, amount: number) {
    if (color !== "" && amount !== undefined) {
      return (
        "#" +
        color
          ?.replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
                16
              )
            ).substr(-2)
          )
      );
    } else {
      return "";
    }
  }

  let theme = createMuiTheme({
    palette: {
      common: {
        black: "#000",
        white: "#fff",
      },
      background: {
        paper: "#fff",
        default: "#ffffff",
      },
      primary: {
        light: colorWorkSpace?.secondaryColor
          ? colorWorkSpace?.secondaryColor
          : "rgba(27, 157, 252, 0.3)",
        main: colorWorkSpace?.primaryColor
          ? colorWorkSpace?.primaryColor
          : "rgba(27, 157, 252, 1)",
        dark: primaryDark ? primaryDark : "rgba(18, 109, 176, 1)",
        contrastText: "#fff",
      },
      secondary: {
        light: tertiaryLight ? tertiaryLight : "rgba(255, 124, 51, 1)",
        main: colorWorkSpace?.tertiaryColor
          ? colorWorkSpace?.tertiaryColor
          : "rgba(255, 92, 0, 1)",
        dark: tertiaryDark ? tertiaryDark : "rgba(178, 64, 0, 1)",
        contrastText: settingsButton ? settingsButton : "#cfebfe",
      },
      error: {
        light: "#e57373",
        main: "rgba(241, 24, 6, 1)",
        dark: "rgba(128, 25, 25, 1)",
        contrastText: "#fff",
      },
      text: {
        primary: "#313B53",
        secondary: "rgb(136, 136, 136)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(255, 255, 255, 0.3)",
      },
    },
    overrides: {
      MuiTabs: {
        indicator: {
          backgroundColor: "black",
          height: 4,
        },
        root: {
          backgroundColor: "white",
          color: colorWorkSpace?.primaryColor
            ? colorWorkSpace?.primaryColor
            : "rgba(27, 157, 252, 1)",
          height: 40,
          minHeight: 40,
        },
      },
      MuiTab: {
        // general overrides for your material tab component here
        root: {
          height: 40,
          minHeight: 40,
          minWidth: 0,
          "@media (min-width: 0px)": {
            minWidth: 0,
          },
          marginRight: 15,
          padding: 0,
          backgroundColor: "white",
          borderBottom: `3px solid ${
            colorWorkSpace?.primaryColor
              ? colorWorkSpace?.primaryColor
              : "rgba(27, 157, 252, 1)"
          }`,
          "&$selected": {
            backgroundColor: "white",
            color: "#313B53",
            minWidth: 0,
            padding: 0,
            marginRight: 15,
          },
          "&:hover": {
            backgroundColor: "white",
            color: "#313B53",
          },
        },
      },
      MuiListItem: {
        root: {
          "&$selected": {
            backgroundColor: "white",
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: 20,
          textTransform: "none",
        },
        containedPrimary: {
          "&:disabled": {
            backgroundColor: colorWorkSpace?.secondaryColor
              ? colorWorkSpace?.secondaryColor
              : "rgba(27, 157, 252, 0.3)",
            color: "#FFF",
          },
          "&:hover": {
            border: "none",
            backgroundColor: primaryDark
              ? primaryDark
              : "rgba(18, 109, 176, 1)",
          },
        },
        outlined: {
          border: "2px solid",
        },
        outlinedPrimary: {
          border: "none",
          boxShadow: "0px 0px 6.7px rgba(27, 157, 252, 0.3)",
          "&:hover": {
            border: "none",
          },
        },
        outlinedSecondary: {
          border: "none",
          boxShadow: "0px 0px 6.7px rgba(255, 124, 51, 1)",
          "&:hover": {
            border: "none",
          },
        },
      },
      MuiInputLabel: {
        root: {
          fontWeight: 500,
          fontSize: "1.05rem",
          textTransform: "uppercase",
          color: "rgb(136, 136, 136)",

          "& + .MuiInput-formControl": {
            marginTop: 24,
          },
        },
      },
      MuiCssBaseline: {
        "@global": {
          "*::-webkit-scrollbar": {
            width: "0.5em",
            height: "0.5em",
          },
          "*::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
            border: "0",
            // '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#c3c3c3",
            outline: "0px",
            borderRadius: "12px",
            border: "0",
            // backgroundColor: 'rgba(0,0,0,.4)',
            // outline: '1px solid slategrey'
          },
        },
      },
    },
    typography: {
      fontFamily: "Rubik",
      fontSize: 14,
      h1: {
        fontSize: 22,
      },
      h2: {
        width: "100%",
        fontSize: 20,
        fontWeight: "bold",
      },
      h3: {
        position: "relative",
        width: "100%",
        marginBottom: 20,
        fontSize: 18,
        fontWeight: "bold",
        color: "#313B53",
        "&::before": {
          content: "''",
          position: "absolute",
          bottom: -10,
          left: 0,
          width: "60px",
          height: "2px",
          backgroundColor: "#313B53",
        },
      },
      h4: {
        width: "100%",
        fontSize: 16,
        lineHeight: 1.4,
        fontWeight: "bold",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
      },
      h5: {
        width: "100%",
        fontSize: 13,
      },
      h6: {
        width: "100%",
        fontSize: 10,
        fontWeight: "bold",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "1",
        "-webkit-box-orient": "vertical",
      },
      body1: {
        color: "#313B53",
      },
      body2: {
        color: "#313B53",
      },
      subtitle1: {
        fontSize: 10,
      },
      subtitle2: {
        width: "100%",
        fontSize: 10,
        fontWeight: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "1",
        "-webkit-box-orient": "vertical",
      },
    },
  });

  theme = responsiveFontSizes(theme);

  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
}

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    workspaces: store.workSpacesState.workspaces,
    activeTenant: store.tenantState.activeTenant,
  };
};

export default connect(mapStateToProps)(ThemeComponent);
