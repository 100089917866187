import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import EMIcon from "components/Icon/Icon";
import Resources from "../Resources/Resource";
import {
  Hidden,
  Drawer,
  makeStyles,
  Theme,
  createStyles,
  useTheme,
  ListItem,
  ListItemIcon,
  Box,
  Typography,
  Paper,
  Button,
  Avatar,
  Dialog,
} from "@material-ui/core";
import { ITenantState } from "redux/reducers/tenantReducer";
import TuneIcon from "@material-ui/icons/Tune";
import { IAppState } from "../../redux/store/Store";
import { connect } from "react-redux";
import { IProfileData } from "../../redux/reducers/profileReducer";
import {
  getProfileData,
  IProfileGetDataAction,
} from "../../redux/actions/ProfileActions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import newApp from "utils/reactNewApp";
import history from "../../routes/history";
import { IWorkspaceReducer } from "redux/reducers/workspaceReducer";
import { CountryActions, getCountries } from "redux/actions/CountryAction";

const drawerWidth = 210;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.MuiListItem-root": {
        paddingTop: "4px",
      },
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    item: {
      borderRadius: "50px 0px 0px 50px;",
      paddingTop: "5px",
      paddingBottom: "5px",
      marginBottom: "6px",
    },
    selectedItem: {
      borderRadius: "50px 0px 0px 50px;",
      background: "white",
      "& div": {
        "& span": {
          "& span": {
            fontWeight: 500,
          },
        },
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      borderRight: "0",
      [theme.breakpoints.up("xl")]: {
        marginLeft: 'calc(50% - 1440px/2)',
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    itemIcon: {
      minWidth: "40px",
    },
    EMIcon: {
      verticalAlign: "text-top",
    },
    workspace: {
      borderRadius: "10px",
      alignItems: "center",
      justifyContent: "space-around",
      backgroundColor: theme.palette.secondary.contrastText,
      width: "100%",
    },
    workspaceActive: {
      borderRadius: "10px",
      alignItems: "center",
      justifyContent: "space-around",
      backgroundColor: "#fff",
      width: "100%",
    },
    tenantImg: {
      width: "30px",
      height: "30px",
      "& img": {
        objectFit: "contain",
      },
    },
    scrollPaper: {
      flexDirection: "column-reverse",
      alignItems: "baseline",
      justifyContent: "flex-end",
    },
    paper: {
      maxWidth: "300px",
      width: "100%",
      margin: "48px 8px",
      [theme.breakpoints.up("xl")]: {
        marginLeft: 'calc(50% - 1440px/2)',
      },
    },
    workspaceTypography: {
      fontWeight: 500,
    },
  })
);

function encrypt(text: any) {
  var criptMail = `${text}|${Date.now()}`;
  var CryptoJS = require("crypto-js");
  var parsedkey = CryptoJS.enc.Utf8.parse(process.env["REACT_APP_CRYPTIV"]);
  var iv = CryptoJS.enc.Base64.parse(process.env["REACT_APP_CRYPT"]);
  var encoded = CryptoJS.enc.Utf8.parse(criptMail);
  var ciphertext = CryptoJS.TripleDES.encrypt(encoded, parsedkey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  return ciphertext.toString();
}

interface IProps {
  mobileOpen: boolean;
  handleDrawerToggle: any;
  tenants: ITenantState;
  isAllowedBackOffice?: boolean;
  backofficeUrl: string;
  allowToSeePrizes?: boolean;
  profileData?: IProfileData;
  WorkspaceUser: IWorkspaceReducer;
  permission: string;
}

interface IDispatchProps {
  getProfileData: () => Promise<IProfileGetDataAction>;
  getCountries:()=> Promise<CountryActions>;
}
export interface SimpleDialogProps {
  open: boolean;
  tenants: ITenantState;
  onClose: () => void;
  isAllowedBackOffice?: boolean;
  backofficeUrl: string;
  profileData?: IProfileData;
  WorkspaceUser?: IWorkspaceReducer;
  getProfileData: () => Promise<IProfileGetDataAction>;
  getCountries:()=>Promise<CountryActions>
}

function SimpleDialog(props: SimpleDialogProps) {
  const classes = useStyles();
  const { onClose, open, tenants, backofficeUrl, WorkspaceUser } = props;
  const [token, setToken] = useState("");
  const [host, setHost] = useState("");
  const theme = useTheme();
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    props.getProfileData().then(() => {
      setToken(encrypt(`${props.profileData?.email}`));
      setHost(`http://${window.location.host}`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setToken(encrypt(`${props.profileData?.email}`));
  }, [props.profileData]);

  return (
    <Dialog
      onClose={handleClose}
      classes={{ scrollPaper: classes.scrollPaper, paper: classes.paper }}
      open={open}
    >
      <Paper
        style={{
          position: "fixed",
          top: "16px",
          maxWidth: "200px",
          width: "100%",
        }}
      >
        <Button
          className={open ? classes.workspaceActive : classes.workspace}
          onClick={() => {
            handleClose();
          }}
        >
          <Avatar
            variant="square"
            alt={tenants?.activeTenant?.name}
            src={WorkspaceUser?.photoLogo !== undefined ? WorkspaceUser?.photoLogo : tenants?.activeTenant?.image}
            className={classes.tenantImg}
            sizes="small"
          />
          <Typography variant="h5" noWrap>
            <strong>{tenants?.activeTenant?.name}</strong>
          </Typography>
          <EMIcon
            icon={open ? "arrow-up" : "arrow-down"}
            color={theme.palette.primary.main}
            size={12}
          />
        </Button>
      </Paper>
      <Box paddingX={2} paddingTop={2}>
        <List>
          {newApp ? (
            <Button onClick={() => history.push("/settings/manage-users")}>
              <ListItem>
                <ListItemIcon className={classes.itemIcon}>
                  <TuneIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    className={classes.workspaceTypography}
                    color="textPrimary"
                    variant="body2"
                  >
                    <Resources tag="Common::Settings" />
                  </Typography>
                </ListItemText>
              </ListItem>
            </Button>
          ) : (
            <form action={backofficeUrl} method="post">
              <input type="hidden" name="accessToken" value={token} />
              <input type="hidden" name="requestUrl" value={host} />
              <Button type="submit">
                <ListItem>
                  <ListItemIcon className={classes.itemIcon}>
                    <TuneIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      className={classes.workspaceTypography}
                      color="textPrimary"
                      variant="body2"
                    >
                      <Resources tag="Common::Settings" />
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Button>
            </form>
          )}
          {/* {tenants?.tenants?.length >=2 &&
            <Box paddingX={2}><Typography>Change Workspace:</Typography></Box>
          }
          {tenants?.tenants?.length >= 2 && tenants?.tenants?.map((data, index) => {
            return (
              <ListItem key={index} button>
                <Avatar variant="square" style={{ marginRight: '15px' }} alt={data.name} src={data.image} className={classes.tenantImg} sizes="small" />
                <ListItemText><Typography noWrap>{data.name}</Typography></ListItemText>
              </ListItem>
            )
          })} */}
        </List>
      </Box>
    </Dialog>
  );
}

function Sidebar(props: IProps & IDispatchProps) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const {
    tenants,
    backofficeUrl,
    isAllowedBackOffice,
    allowToSeePrizes,
    WorkspaceUser,
    permission
  } = props;
  const { getProfileData, getCountries } = props;

  useEffect(()=>{
    getCountries();
  },[])

  const handleClose = () => {
    setOpen(false);
  };

  const drawer = (
    <div>
      {isAllowedBackOffice ? (
        <div>
          <Box marginTop={2} marginRight={1} position="relative">
            <Button
              id="Config"
              className={open ? classes.workspaceActive : classes.workspace}
              onClick={() => setOpen(!open)}
            >
              <Avatar
                variant="square"
                alt={tenants?.activeTenant?.name}
                src={WorkspaceUser?.photoLogo !== '' ? WorkspaceUser?.photoLogo : tenants?.activeTenant?.image}
                className={classes.tenantImg}
                sizes="small"
              />
              <Typography variant="h5" noWrap>
                <strong>{tenants?.activeTenant?.name}</strong>
              </Typography>
              <EMIcon
                icon={open ? "arrow-up" : "arrow-down"}
                color={theme.palette.primary.main}
                size={12}
              />
            </Button>
          </Box>
          <SimpleDialog
            backofficeUrl={backofficeUrl}
            tenants={tenants}
            open={open}
            onClose={handleClose}
            getProfileData={getProfileData}
            getCountries={getCountries}
            profileData={props.profileData}
          />
        </div>
      ) : (
        <Box marginTop={2} marginRight={1} position="relative">
          <Button
            disabled
            className={open ? classes.workspaceActive : classes.workspace}
          >
            <Avatar
              variant="square"
              alt={tenants?.activeTenant?.name}
              src={tenants?.activeTenant?.image}
              className={classes.tenantImg}
              sizes="small"
            />
            <Typography variant="h5" color="textPrimary" noWrap>
              <strong>{tenants?.activeTenant?.name}</strong>
            </Typography>
          </Button>
        </Box>
      )}
      <List>
        <ListItem
          button
          id="SideBarFeed"
          className={classes.item}
          component={NavLink}
          to="/"
          activeClassName={`Mui-selected ${classes.selectedItem}`}
          exact
        >
          <ListItemIcon className={classes.itemIcon}>
            <ListItemText>
              <EMIcon
                icon="section-feed"
                size={16}
                className={classes.EMIcon}
              />
            </ListItemText>
          </ListItemIcon>
          <ListItemText className="text-button-sidebar">
            <Resources tag={"PageFeed::Menu_Feed"} />
          </ListItemText>
        </ListItem>
        <ListItem
          button
          id="SideBarChallenges"
          className={classes.item}
          component={NavLink}
          to="/challenges"
          activeClassName={`Mui-selected ${classes.selectedItem}`}
          exact
        >
          <ListItemIcon className={classes.itemIcon}>
            <ListItemText>
              <EMIcon icon="challenges" size={16} className={classes.EMIcon} />
            </ListItemText>
          </ListItemIcon>
          <ListItemText>
            <Resources tag={"PageChallenges::Header"} />
          </ListItemText>
        </ListItem>
        <ListItem
          button
          id="SideBarIdea"
          className={classes.item}
          component={NavLink}
          to="/idea"
          activeClassName={`Mui-selected ${classes.selectedItem}`}
          exact
        >
          <ListItemIcon className={classes.itemIcon}>
            <ListItemText>
              <EMIcon
                icon="section-ideas"
                size={16}
                className={classes.EMIcon}
              />
            </ListItemText>
          </ListItemIcon>
          <ListItemText>
            <Resources tag={"PageIdea::Header"} />
          </ListItemText>
        </ListItem>
        {allowToSeePrizes ? (
          <ListItem
            button
            id="SideBarPrizes"
            className={classes.item}
            component={NavLink}
            to="/prizes"
            activeClassName={`Mui-selected ${classes.selectedItem}`}
            exact
          >
            <ListItemIcon className={classes.itemIcon}>
              <ListItemText>
                <EMIcon
                  icon="section-prizes"
                  size={16}
                  className={classes.EMIcon}
                />
              </ListItemText>
            </ListItemIcon>
            <ListItemText>
              <Resources tag={"PagePrizes::Header"} />
            </ListItemText>
          </ListItem>
        ) : (
          <></>
        )}
        <ListItem
          button
          id="SideBarNews"
          className={classes.item}
          component={NavLink}
          to="/news"
          activeClassName={`Mui-selected ${classes.selectedItem}`}
          exact
        >
          <ListItemIcon className={classes.itemIcon}>
            <ListItemText>
              <EMIcon
                icon="section-news"
                size={16}
                className={classes.EMIcon}
              />
            </ListItemText>
          </ListItemIcon>
          <ListItemText>
            <Resources tag={"PageNews::Header"} />
          </ListItemText>
        </ListItem>
        <ListItem
          button
          id="SideBarRanking"
          className={classes.item}
          component={NavLink}
          to="/ranking"
          activeClassName={`Mui-selected ${classes.selectedItem}`}
          exact
        >
          <ListItemIcon className={classes.itemIcon}>
            <ListItemText>
              <EMIcon
                icon="section-ranking"
                size={16}
                className={classes.EMIcon}
              />
            </ListItemText>
          </ListItemIcon>
          <ListItemText>
            <Resources tag={"PageRanking::Header"} />
          </ListItemText>
        </ListItem>
        {
        (permission === "Admin" || permission === "ChallengeManager") && (
        <ListItem
          button
          id="SideBarAnalytics"
          className={classes.item}
          component={NavLink}
          to="/analytics"
          activeClassName={`Mui-selected ${classes.selectedItem}`}
          exact
        >
          <ListItemIcon className={classes.itemIcon}>
            <ListItemText>
              <EMIcon
                icon="section-dashboard"
                size={16}
                className={classes.EMIcon}
              />
            </ListItemText>
          </ListItemIcon>
          <ListItemText>
            <Resources tag={"PageAnalytics::Header"} />
          </ListItemText>
        </ListItem>
       )}
      </List>
    </div>
  );

  return (
    <>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={props.mobileOpen}
          onClose={props.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Box
            style={{
              background: theme.palette.primary.light,
              height: "100%",
              paddingLeft: "1ch",
            }}
          >
            {drawer}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <Box
            style={{
              background: theme.palette.primary.light,
              height: "100%",
              paddingLeft: "1ch",
            }}
          >
            {drawer}
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    profileData: store.profileState.profileData,
    workspaces: store.workSpacesState.workspaces,
    permission: store.authState.auth.permission
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getProfileData: () => dispatch(getProfileData()),
    getCountries:()=>dispatch(getCountries())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
