import React from "react";
import Resource from "../Resources/Resource";
import NumberFormat from "react-number-format";
import { IProfile } from "../../redux/reducers/profileReducer";
import { Avatar, Box, Typography, useTheme } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(7.5),
      height: theme.spacing(7.5),
      border: "solid 2px #e8e7e8",
    },
    truncateName: {
      textAlign: "left",
      width: "144px",
      top: "10px",
      position: "absolute",
      fontSize: "0.8rem",
      textTransform: "capitalize",
      color: theme.palette.common.white,
      display: "-webkit-box",
     " -webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

interface IProps {
  profile: IProfile[];
  cultureTag: string;
}
function ProfileHeader(props: IProps) {
  const { profile } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {profile &&
        profile?.map((profiles) => {
          return (
            <Box key={profiles.userId} className="container">
              {(profiles.countKeyTasks > 0 || profiles.countMessages > 0) && (
                <div
                  className="notificationUP"
                  style={{ backgroundColor: theme.palette.error.main }}
                ></div>
              )}

              <Box className="imgContent">
                <Box className="imgProfile">
                  <Avatar
                    src={profiles.photo}
                    alt={profiles.fullName}
                    className={classes.thumbnail}
                  />
                </Box>
                <Box
                  className="expanDiv"
                  style={{
                    backgroundColor: theme.palette.primary.main,
                  }}
                  key={profiles.userId}
                >
                  <Box className="info-header-profile">
                    <Typography className={classes.truncateName}>
                      {profiles.fullName}
                    </Typography>
                    <Box mt={"12px"} display="flex" className="points-position">
                      <Box mr={1} fontWeight="bold">
                        <NumberFormat
                          value={profiles.countAvailablePoints}
                          thousandSeparator={true}
                          displayType={"text"}
                        />
                      </Box>
                      <Box mr={1} style={{ textTransform: "lowercase" }}>
                        {" "}
                        <Resource tag={"PageRanking::Points"} />{" "}
                      </Box>{" "}
                      |{" "}
                      <Box ml={1} fontWeight="bold">
                        {" "}
                        #{profiles.rankingPosition}{" "}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
    </>
  );
}
export default ProfileHeader;
