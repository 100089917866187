import React, { useState } from "react";
import "./KeyTaskDetailButtons.scss";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  getAllKeyTasksPositive,
  IKeyTasksPositiveGetAllAction,
  getAllKeyTasksNegative,
  IKeyTasksNegativeGetAllAction,
} from "../../redux/actions/keyTasks/KeyTasksAction";
import {
  IKeyTasksNegative,
  IKeyTasksPositive,
} from "../../redux/reducers/keyTasks/keyTasksReducer";
import { IAppState } from "../../redux/store/Store";
import { IApplication } from "../../redux/reducers/tenantReducer";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import KeyTaskIdeaValidationPopUp from "../KeyTaskIdeaValidationPopUp/KeyTaskIdeaValidationPopUp";
import KeyTaskIdeaTransitionPopUp from "../KeyTaskIdeaTransitionPopUp/KeyTaskIdeaTransitionPopUp";
import KeyTasksChangeMPopup from "../KeyTasksChangeMPopup/KeyTasksChangeMPopup";

import {
  Theme,
  makeStyles,
  createStyles,
  Button,
  Divider,
} from "@material-ui/core";
import Resource from "components/Resources/Resource";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    title: {
      color: "#333",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    divider: {
      margin: "0 2px",
    },
  })
);

interface IProps {
  type?: string;
  validationValue?: any;
  dataObject?: {
    title: string[];
    ideaId: string;
    ideaIds: string[];
    allowReject?: boolean;
  };
  allowReject?: boolean;
  setIdeaDetailOpen?: (on: boolean) => void;
  submitDisabled?: boolean;
  postIdeasStars?: any;
  isAllowedToChangeManager?: boolean;
  secondTab?: string;
}

interface IStateProps {
  application?: IApplication;
  culture?: ICultureState;
  keyTasksNegative?: IKeyTasksNegative[];
  keyTasksPositive?: IKeyTasksPositive[];
}

interface IDispatchProps {
  getAllKeyTasksPositive: (
    options: object
  ) => Promise<IKeyTasksPositiveGetAllAction>;
  getAllKeyTasksNegative: (
    options: object
  ) => Promise<IKeyTasksNegativeGetAllAction>;
}

function KeyTaskDetailButtons(props: IProps & IDispatchProps & IStateProps) {
  const {
    type,
    dataObject,
    getAllKeyTasksPositive,
    getAllKeyTasksNegative,
    keyTasksNegative,
    keyTasksPositive,
    application,
    culture,
    allowReject,
    submitDisabled,
    postIdeasStars,
    isAllowedToChangeManager,
    secondTab,
  } = props;
  const [isTransitionOpen, setTransitionOpen] = useState(false);
  const [isValidationOpen, setValidationOpen] = useState(false);
  const [isManagersOpen, setManagersOpen] = useState(false);
  const [isPositive, setIsPositive] = useState(false);
  const [isToAdjust, setIsToAdjust] = useState(false);
  const [stateType, setStateType] = useState("");
  const classes = useStyles();

  const options = {
    ideaId: dataObject?.ideaId,
    ideaIds: dataObject?.ideaIds,
    applicationId: application?.applicationId,
    culture: culture,
  };

  const handlerApiPositive = () => {
    if (!dataObject?.ideaIds?.length) {
      return;
    }
    getAllKeyTasksPositive(options);
    setIsPositive(true);
  };

  const handlerApiNegative = () => {
    if (!dataObject?.ideaIds?.length) {
      return;
    }
    getAllKeyTasksNegative(options);
    setIsPositive(false);
  };

  return (
    <>
      {type === "idea-detail" && (
        <div className="KeyTaskDetailButtons">
          {isAllowedToChangeManager ? (
            <>
              <Button
                onClick={() => setManagersOpen(true)}
                color="primary"
                variant="contained"
                disabled={dataObject?.ideaId === "" && true}
                size="small"
                style={{ whiteSpace: "nowrap" }}
              >
                <Resource tag="PageKeyTasks::ChangeManager" />
              </Button>
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
              <KeyTasksChangeMPopup
                open={isManagersOpen}
                ideaId={dataObject?.ideaId}
                onClose={() => setManagersOpen(false)}
              />
            </>
          ) : (
            <></>
          )}
          {props.dataObject?.allowReject && (
            <Button
              onClick={() => {
                setIsToAdjust(false);
                handlerApiNegative();
                setValidationOpen(true);
                setStateType("NotValidate");
              }}
              color="primary"
              variant="outlined"
              disabled={dataObject?.ideaId === "" && true}
              size="small"
            >
              <Resource tag="Common::Reject" />
            </Button>
          )}

          <Button
            onClick={() => {
              handlerApiPositive();
              setTransitionOpen(true);
              setStateType("Transit");
            }}
            color="primary"
            variant="contained"
            disabled={dataObject?.ideaId === "" && true}
            size="small"
          >
            <Resource tag="Common::Transit" />
          </Button>
        </div>
      )}
      {type === "transition" && (
        <div className="KeyTaskDetailButtons">
          {isAllowedToChangeManager ? (
            <>
              <Button
                onClick={() => setManagersOpen(true)}
                color="primary"
                variant="contained"
                disabled={
                  !dataObject?.ideaIds?.length ||
                  dataObject?.ideaIds[0] === "" ||
                  dataObject?.ideaIds?.length! > 1
                }
                size="small"
                style={{ whiteSpace: "nowrap" }}
              >
                <Resource tag="PageKeyTasks::ChangeManager" />
              </Button>
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
              <KeyTasksChangeMPopup
                open={isManagersOpen}
                ideaId={dataObject?.ideaIds[0]}
                onClose={() => setManagersOpen(false)}
              />
            </>
          ) : (
            <></>
          )}
          {allowReject && (
            <Button
              onClick={() => {
                /*handlerApiNegative();
                setTransitionOpen(true);
                setStateType("Reject");*/
                setIsToAdjust(false);
                handlerApiNegative();
                setValidationOpen(true);
                setStateType("NotValidate");
              }}
              color="primary"
              variant="outlined"
              disabled={
                !dataObject?.ideaIds?.length || dataObject?.ideaIds[0] === ""
              }
              size="small"
            >
              <Resource tag="Common::Reject" />
            </Button>
          )}

          <Button
            onClick={() => {
              handlerApiPositive();
              setTransitionOpen(true);
              setStateType("Transit");
            }}
            color="primary"
            variant="contained"
            disabled={
              !dataObject?.ideaIds?.length || dataObject?.ideaIds[0] === ""
            }
            size="small"
          >
            <Resource tag="Common::Transit" />
          </Button>
        </div>
      )}
      {type === "validation" && (
        <div className="KeyTaskDetailButtons">
          <Button
            onClick={() => {
              setIsToAdjust(false);
              handlerApiNegative();
              setValidationOpen(true);
              setStateType("NotValidate");
            }}
            color="primary"
            variant="outlined"
            disabled={
              !dataObject?.ideaIds?.length || dataObject?.ideaIds[0] === ""
            }
            size="small"
          >
            <Resource tag="Common::NotValidate" />
          </Button>

          <Button
            onClick={() => {
              setIsToAdjust(true);
              handlerApiPositive();
              setValidationOpen(true);
              setStateType("ToAdjust");
            }}
            color="primary"
            variant="outlined"
            disabled={
              !dataObject?.ideaIds?.length || dataObject?.ideaIds[0] === ""
            }
            size="small"
          >
            <Resource tag="Common::ToAdjust" />
          </Button>

          <Button
            onClick={() => {
              setIsToAdjust(false);
              handlerApiPositive();
              setValidationOpen(true);
              setStateType("Validate");
            }}
            color="primary"
            variant="contained"
            disabled={
              !dataObject?.ideaIds?.length || dataObject?.ideaIds[0] === ""
            }
            size="small"
          >
            <Resource tag="Common::Validate" />
          </Button>
        </div>
      )}
      {type === "evaluation" && (
        <div className="KeyTaskDetailButtons">
          <Button
            onClick={() =>
              props?.setIdeaDetailOpen && props?.setIdeaDetailOpen(false)
            }
            color="primary"
            variant="outlined"
            size="small"
          >
            <Resource tag="Common::Cancel" />
          </Button>
          <Button
            onClick={() => {
              postIdeasStars();
            }}
            color="primary"
            variant="contained"
            disabled={
              !dataObject?.ideaIds?.length ||
              dataObject?.ideaIds[0] === "" ||
              submitDisabled
            }
            size="small"
          >
            <Resource tag="ModalKeyTasks::SubmissionEvaluation" />
          </Button>
        </div>
      )}

      <KeyTaskIdeaTransitionPopUp
        ideaId={
          dataObject?.ideaIds?.length
            ? dataObject?.ideaIds!
            : [dataObject?.ideaId!]
        }
        secondTab={secondTab}
        keyTasksPositive={isPositive ? keyTasksPositive : undefined}
        keyTasksNegative={!isPositive ? keyTasksNegative : undefined}
        toAdjust={isToAdjust}
        title={dataObject ? dataObject?.title[0] : ""}
        closeButton={() => setTransitionOpen(false)}
        isTransitionOpen={isTransitionOpen}
        setTransitionOpen={setTransitionOpen}
      />

      <KeyTaskIdeaValidationPopUp
        ideaId={
          dataObject?.ideaIds?.length
            ? dataObject?.ideaIds!
            : [dataObject?.ideaId!]
        }
        keyTasksPositive={isPositive ? keyTasksPositive : undefined}
        keyTasksNegative={!isPositive ? keyTasksNegative : undefined}
        toAdjust={isToAdjust}
        title={dataObject ? dataObject?.title[0] : ""}
        closeButton={() => setValidationOpen(false)}
        isValidationOpen={isValidationOpen}
        stateType={stateType}
        setValidationOpen={setValidationOpen}
      />
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllKeyTasksPositive: (options: object) =>
      dispatch(getAllKeyTasksPositive(options)),
    getAllKeyTasksNegative: (options: object) =>
      dispatch(getAllKeyTasksNegative(options)),
  };
};
const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    keyTasksPositive: store.keyTasksState.keyTasksPositive,
    keyTasksNegative: store.keyTasksState.keyTasksNegative,
    application: store.tenantState.application,
    culture: store.cultureState,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyTaskDetailButtons);
