import { Box } from '@material-ui/core';
import React from 'react';

interface IProps {
  children: any;
  actions?: any;
}

function XBoxMentions(props: IProps) {
  const { children, actions } = props;

  return (
    <Box width='100%' display="flex">
      <Box width='100%'>{children}</Box>
      {actions && (
        <Box ml="10px">{actions}</Box>
      )}
    </Box>
  )
}

export default XBoxMentions;