import CloseIcon from "@material-ui/icons/Close";
import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Divider,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>> | ((open: boolean) => void);
  title: string | JSX.Element;
  children: any;
  actions?: any;
  width?: any;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  height?: string;
  py?: any;
  px?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    title: {
      padding: "24px 40px 0",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      color: theme.palette.grey[500],
      margin: "16px 0 0",
    },
  })
);

function XDialog(props: IProps) {
  const {
    title,
    open,
    children,
    actions,
    width,
    maxWidth,
    height,
    px,
    py,
    setOpen,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      style={{ width: `${width}`, margin: "0 auto" }}
      open={open}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullscreen}
      onClose={() => setOpen(false)}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h4">{title}</Typography>
        <IconButton
          // aria-label="close"
          className={classes.closeButton}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Divider className={classes.divider} />
      </DialogTitle>
      <DialogContent>
        <Box height={height} py={py || 1.5} px={px || 2.5}>
          {children}
        </Box>
      </DialogContent>
      {actions && (
        <DialogActions>
          <Box py={3} px={5} width="100%">
            {actions}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default XDialog;
