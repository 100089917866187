// Import Reducer type
import { Reducer } from 'redux';
import { RelatedContentActionTypes, RelatedContentActions, RelatedContentActionStatus } from '../../actions/relatedContent/RelatedContentAction';

export interface IRelatedContentResult {
    objectType: number,
    objectId: string,
    title: string,
    description: string,
    publishedDate: string,
    commentsCount: number,
    comments: [
        {
            commentId: string,
            userId: string,
            fullName: string,
            photo: string,
            timeAgo: string,
            timeStampResource: string,
            message: string,
            isLikedByMe: true,
            likesCount: number,
            repliesCount: number
          }
    ]
}

export interface IRelatedContentState {
    readonly error: any;
    readonly relatedStatus: string;
    readonly relatedContent: IRelatedContentResult[];
    readonly meta: any;
}

const initialIdeaManagersState = {
    relatedStatus:RelatedContentActionStatus.RELATED_CONTENT_LOADING,
    error: null,
    relatedContent: [],
    meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0
    }
}

export const relatedContentReducer: Reducer<any, RelatedContentActions> = (
  state = initialIdeaManagersState,
  action
) => {
  switch (action.type) {
    // ---------* ALL IDEAS  *---------
    case RelatedContentActionTypes.RELATED_CONTENT_LOADING: {
        return {
          ...state,
          relatedStatus: action.relatedStatus,
        };
      }
    case RelatedContentActionTypes.RELATED_CONTENT_SUCCEEDED: {
    return {
        ...state,
        relatedContent: action.relatedContent,
        meta: action.meta,
        relatedStatus: action.relatedStatus
      }
    }
    case RelatedContentActionTypes.RELATED_CONTENT_FAILED: {
      return {
          ...state,
          relatedStatus: action.relatedStatus,
          error: action.error
      };
    }
    default:
      return state;
  }
};