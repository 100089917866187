import React from 'react';
import { Card, makeStyles, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';

const useStyles = makeStyles({
  card: {
    width: "290px",
    height: "250px",
  },
});


function PreviousPrizesCardSkeleton() {
  const classes = useStyles();

  return (
    <Grid container spacing={2} style={{ marginTop: '40px' }}>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <Skeleton variant="rect" width="100%" height="90%" />
          <Skeleton variant="text" width="100%" />
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <Skeleton variant="rect" width="100%" height="90%" />
          <Skeleton variant="text" width="100%" />
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card className={classes.card}>
          <Skeleton variant="rect" width="100%" height="90%" />
          <Skeleton variant="text" width="100%" />
        </Card>
      </Grid>
    </Grid>
  );
}

export default PreviousPrizesCardSkeleton