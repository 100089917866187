import React from "react";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IFilters, IKeyTasksState } from "redux/reducers/keyTasks/keyTasksReducer";
import EvaluationFilter from "./EvaluationFilter";
import TransitionFilter from "./TransitionFilter";
import ValidationFilter from "./ValidationFilter";

// Ex: <KeyTasksFilters typeFilter="" />

interface IProps {
  typeFilter: string;
  culture: ICultureState;
  filters: IFilters;
  isEvaluation?: boolean;
  stateId?: string;
  getEvaluationKeyTasks?(options: object): Promise<IKeyTasksState>;
  getTransitionKeyTasks?(options: object): Promise<IKeyTasksState>;
  getValidationKeyTasks?(options: object): Promise<IKeyTasksState>;
}


function KeyTasksFilters(props: IProps): JSX.Element {
  const { filters, isEvaluation, getEvaluationKeyTasks, getValidationKeyTasks, getTransitionKeyTasks, stateId } = props
  const evaluation = () => {
    return <EvaluationFilter culture={props?.culture} filters={filters} getEvaluationKeyTasks={getEvaluationKeyTasks!}/>;
  };

  const validation = () => {
    return <ValidationFilter culture={props?.culture} filters={filters} getValidationKeyTasks={getValidationKeyTasks!}/>;
  };

  const transition = () => {
    return <TransitionFilter stateId={stateId!} culture={props?.culture} isEvaluation={isEvaluation} filters={filters} getTransitionKeyTasks={getTransitionKeyTasks!}/>;
  };

  if (props?.typeFilter === undefined || props?.typeFilter === "evaluation") {
    return evaluation();
  } else if (props?.typeFilter === "validation") {
    return validation();
  } else if (props?.typeFilter === "transition") {
    return transition();
  } else {
    return evaluation();
  }
}

export default KeyTasksFilters;
