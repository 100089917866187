import { AnyAction } from "redux";
import { connect } from "react-redux";
import history from "../../routes/history";
import { ThunkDispatch } from "redux-thunk";
import { withRouter } from "react-router-dom";
import { IAppState } from "redux/store/Store";
import React, { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import IdeaList from "containers/IdeaList/IdeaList";
import imagemNotIdeas from "assets/img/empty@3x.png";
import { getProfile } from "redux/actions/ProfileActions";
import { IChallengeAllList } from "redux/types/challenges";
import SkeletonTitle from "common/UI/Skeleton/SkeletonTitle";
import { ICultureState } from "redux/reducers/cultureReducer";
import { getAllIdeas } from "redux/actions/ideas/AllIdeasAction";
import Resource, { resource } from "components/Resources/Resource";
import { getAllChallengeList } from "redux/actions/ChallengeActions";
import { IAllIdeasState } from "redux/reducers/ideas/AllIdeasReducer";
import SkeletonRandomCards from "common/UI/Skeleton/SkeletonRandomCards";
import PortfolioCarousel from "components/PortfolioCarousel/PortfolioCarousel";
import CollaborativeIdeaList from "containers/CollaborativeIdeaList/CollaborativeIdeaList";
import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  IIdeasImplemented,
  IIdeasInProgress,
  IIdeasRejected,
} from "redux/types/ideas";
import {
  postIdeasInvestments,
  postIdeasLikes,
  deleteIdeasLikes,
  getIdeasInProgress,
  getIdeasRejected,
  getIdeasImplemented,
} from "redux/actions/ideas/IdeaAction";

interface IDispatchProps {
  getAllIdeas: (options: object) => Promise<IAllIdeasState>;
  postInvestments: (options: object) => Promise<any>;
  postLikes: (options: object) => Promise<any>;
  deleteLikes: (options: object) => Promise<any>;
  getAllChallengeList: (options: object) => Promise<any>;
  getProfile: () => Promise<any>;
  getIdeasRejected: (options: object) => Promise<IIdeasRejected>;
  getIdeasInProgress: (options: object) => Promise<IIdeasInProgress>;
  getIdeasImplemented: (options: object) => Promise<IIdeasImplemented>;
}

interface IProps {
  ideas: any;
  ideasInProgress: IIdeasInProgress;
  ideasRejected: IIdeasRejected;
  ideasImplemented: IIdeasImplemented;
  allIdeasStatus: string;
  noReloadComponents?: any;
  applicationId: string;
  culture: ICultureState;
  challengeAllList: IChallengeAllList[];
  urlChallengeId: string;
  workspaceId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxNoIdeasData: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    noDataIdeas: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 2.0,
    },
    noDataIdeasImg: {
      marginTop: "30px",
      width: "300px",
      height: "300px",
    },
  })
);

function Idea(props: IDispatchProps & IProps) {
  const classes = useStyles();
  const [challengeId, setChallengeId] = useState(
    props.urlChallengeId ? props.urlChallengeId : ""
  );
  const [loading, setLoading] = useState(true);
  const [q, setQ] = useState("");
  const [pageNumber] = useState(1);
  const [pageSize] = useState(24);
  const {
    ideas,
    ideasInProgress,
    ideasRejected,
    ideasImplemented,
    getAllIdeas,
    challengeAllList,
    postInvestments,
    applicationId,
    workspaceId,
    culture,
    getProfile,
    postLikes,
    deleteLikes,
    getAllChallengeList,
    getIdeasRejected,
    getIdeasInProgress,
    getIdeasImplemented,
  } = props;
  const inProgressTitle = <Resource tag="PageIdea::Idea_TitleInProgress" />;

  const notProgressedTitle = (
    <Resource tag="PageIdea::Idea_TitleNotProgressed" />
  );

  const postInvestmentsAction = async (options: object) => {
    options = {
      ...options,
      cultureTag: culture.culture.cultureTag,
      workspaceId
    }

    await postInvestments(options).then(() => {
      getProfile();
    });
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && getAllChallengeList({ pageNumber, pageSize });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   let isMounted = true
  //   if (!noReloadComponents && isMounted) getAllIdeas({ challengeId, q });
  //   getIdeasInProgress({
  //     q,
  //     challengeId,
  //     pageNumber,
  //     pageSize: 6
  //   })
  //   return () => {isMounted = false}
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    Promise.all([
      getAllIdeas({ challengeId, q }),
      getIdeasInProgress({ q, challengeId, pageNumber, pageSize: 6 }),
      getIdeasRejected({ q, challengeId, pageNumber, pageSize: 6 }),
      getIdeasImplemented({ q, challengeId, pageNumber, pageSize: 6 }),
    ]).finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPage = (type: string) => {
    type === "inprogress" &&
      history.push(`/idea/inprogress`, {
        challengeId,
        q,
      });

    type === "rejected" &&
      history.push(`/idea/rejected`, {
        challengeId,
        q,
      });

    type === "portfolio" &&
      history.push(`/idea/portfolio`, {
        challengeId,
        q,
      });
  };

  const handleSelect = (e: any) => {
    if (e?.target?.value === "All") {
      setChallengeId("");
      getAllIdeas({
        q,
      });
      getIdeasInProgress({
        q,
      });
      getIdeasRejected({
        q,
      });
      getIdeasImplemented({
        q,
      });
    } else {
      setChallengeId(e?.target?.value);
      getAllIdeas({
        challengeId: e?.target?.value,
        q,
      });
      getIdeasInProgress({
        challengeId: e?.target?.value,
        q,
      });
      getIdeasRejected({
        challengeId: e?.target?.value,
        q,
      });
      getIdeasImplemented({
        challengeId: e?.target?.value,
        q,
      });
    }
  };

  const handleSearch = (e: any) => {
    getAllIdeas({
      challengeId,
      q,
    });
    getIdeasInProgress({
      challengeId,
      q,
    });
    getIdeasRejected({
      challengeId,
      q,
    });
    getIdeasImplemented({
      challengeId,
      q,
    });
  };

  const search = (text: string) => {
    setQ(text);
    if (text && text.length > 2) {
      getAllIdeas({
        challengeId,
        q: text,
      });
      getIdeasInProgress({
        challengeId,
        q: text,
      });
      getIdeasRejected({
        challengeId,
        q: text,
      });
      getIdeasImplemented({
        challengeId,
        q: text,
      });
    } else {
      getAllIdeas({
        challengeId,
        q: "",
      });
      getIdeasInProgress({
        challengeId,
        q: "",
      });
      getIdeasRejected({
        challengeId,
        q: "",
      });
      getIdeasImplemented({
        challengeId,
        q: "",
      });
    }
  };

  let keyTimer: any;
  let waitTimeAfterType = 1000;

  const handlerKeyUp = (e: any) => {
    let text = e.value;
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      search(text);
    }, waitTimeAfterType);
  };

  const handlerKeydDown = () => {
    clearTimeout(keyTimer);
  };

  const textNoDataIdeas = resource(
    culture?.culture,
    "Errors::NoIdeasAvailable"
  ).split("<br></br>");
  return !loading ? (
    <Box mb={7} className="ideas-page-container">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Select
            defaultValue={props.urlChallengeId ? props.urlChallengeId : "All"}
            style={{ marginTop: "16px" }}
            name="challengeId-select"
            labelId={""}
            onChange={handleSelect}
            id="challengeId-select"
            fullWidth
          >
            <MenuItem selected value="All">
              <Resource tag="PageIdeas::AllChallenges" />
            </MenuItem>
            {challengeAllList &&
              challengeAllList?.map((data) => {
                return (
                  <MenuItem
                    key={data.challengeId}
                    value={data.challengeId || ""}
                  >
                    {data.title}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            id="search-idea-filter"
            label={""}
            fullWidth
            margin="normal"
            onKeyUp={(e) => handlerKeyUp(e.target)}
            onKeyDown={handlerKeydDown}
            InputLabelProps={{ shrink: true }}
            placeholder={resource(
              props?.culture,
              "PageIdeas::SearchForTitleOrDescription"
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                    <SearchIcon htmlColor="#333" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            name="search-idea-filter"
          />
        </Grid>
      </Grid>
      <div className="ideas" style={{ paddingTop: "10px" }}>
        <>
          {ideas?.ideasInCollaboration?.ideas?.meta?.total > 0 ? (
            <CollaborativeIdeaList
              challengeId={challengeId}
              q={q}
              ideasInCollaboration={ideas?.ideasInCollaboration?.ideas}
              aleatory={true}
              postInvestments={postInvestmentsAction}
              applicationId={applicationId}
              culture={culture}
              postLikes={postLikes}
              deleteLikes={deleteLikes}
              getAllIdeas={getAllIdeas}
            />
          ) : (
            <></>
          )}
          {ideasImplemented?.meta?.total > 0 ? (
            <Box>
              <PortfolioCarousel
                //getMorePortifolio={getMorePortifolio}
                ideas={ideasImplemented}
                portfolioTag={"PageIdea::Idea_TitlePortfolio"}
              />

              {ideasImplemented?.meta?.total > 6 && (
                <Box display="flex" justifyContent="flex-end" paddingY={1}>
                  <Button
                    color="primary"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => showPage("portfolio")}
                  >
                    <Resource tag={"Common::ViewMore"} />
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <></>
          )}
          {ideasInProgress?.meta?.total > 0 ? (
            <Box>
              <IdeaList
                type="inprogress"
                ideasInProgress={ideasInProgress}
                listHeader={inProgressTitle}
              />

              {ideasInProgress?.meta?.total > 6 && (
                <Box display="flex" justifyContent="flex-end" paddingY={1}>
                  <Button
                    color="primary"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => showPage("inprogress")}
                  >
                    <Resource tag={"Common::ViewMore"} />
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <></>
          )}
          {ideasRejected?.meta?.total > 0 ? (
            <Box>
              <IdeaList
                type="rejected"
                ideasRejected={ideasRejected}
                listHeader={notProgressedTitle}
              />
              {ideasRejected?.meta?.total > 6 && (
                <Box display="flex" justifyContent="flex-end" paddingY={1}>
                  <Button
                    color="primary"
                    style={{ textTransform: "uppercase" }}
                    onClick={() => showPage("rejected")}
                  >
                    <Resource tag={"Common::ViewMore"} />
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <></>
          )}
          {ideas?.ideasInCollaboration?.ideas?.meta?.total === 0 &&
          ideas?.ideasIdeaImplemented?.ideas?.meta?.total === 0 &&
          (ideasInProgress?.meta?.total === 0 ||
            ideasInProgress?.meta?.total === undefined) &&
          (ideasRejected?.meta?.total === 0 ||
            ideasRejected?.meta?.total === undefined) ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={2}
              className={classes.boxNoIdeasData}
            >
              <Typography variant="h4" className={classes.noDataIdeas}>
                {textNoDataIdeas[0]}
              </Typography>
              <Typography variant="h4" className={classes.noDataIdeas}>
                {textNoDataIdeas[1]}
              </Typography>
              <img
                src={imagemNotIdeas}
                alt=""
                className={classes.noDataIdeasImg}
              />
            </Box>
          ) : (
            <></>
          )}
        </>
      </div>
    </Box>
  ) : (
    <>
      <SkeletonTitle />
      <SkeletonRandomCards />
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllIdeas: (parameters: object) => dispatch(getAllIdeas(parameters)),
    postInvestments: (options: object) =>
      dispatch(postIdeasInvestments(options)),
    postLikes: (options: object) => dispatch(postIdeasLikes(options)),
    deleteLikes: (options: object) => dispatch(deleteIdeasLikes(options)),
    getAllChallengeList: (options: object) =>
      dispatch(getAllChallengeList(options)),
    getProfile: () => dispatch(getProfile()),
    getIdeasRejected: (options: object) => dispatch(getIdeasRejected(options)),
    getIdeasInProgress: (options: object) =>
      dispatch(getIdeasInProgress(options)),
    getIdeasImplemented: (options: object) =>
      dispatch(getIdeasImplemented(options)),
  };
};

const mapStateToProps = (store: IAppState, ownProps: any): IProps => {
  const queryParams = new URLSearchParams(ownProps?.location?.search);
  return {
    urlChallengeId: ownProps?.location?.state?.challengeId,
    ideas: store.allIdeasState.ideas,
    ideasInProgress: store.ideasState.ideasInProgress,
    ideasRejected: store.ideasState.ideasRejected,
    ideasImplemented: store.ideasState.ideasImplemented,
    allIdeasStatus: store.allIdeasState.status,
    noReloadComponents: queryParams.get("persistState"),
    applicationId: store.authState.auth.applicationId,
    culture: store.cultureState,
    challengeAllList: store.challengeState.challengeAllList,
    workspaceId: store.authState.auth.workspaceId
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Idea));
