import * as React from "react";
import {
  IFilters,
  IResultEvaluationKeyTasks,
} from "../../redux/reducers/keyTasks/keyTasksReducer";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Theme,
  createStyles,
  makeStyles,
  Hidden,
  Typography,
  Box,
} from "@material-ui/core";
import Resource from "components/Resources/Resource";
import KeyTasksFilters from "components/KeyTasksFilters/KeyTasksFilters";
import { ICultureState } from "redux/reducers/cultureReducer";
import KeyTaskListItemEvaluation from "components/KeyTaskListItem/KeyTaskListItemEvaluation";
import newApp from "utils/reactNewApp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: "20px 0px",
    },
    tableTitles: {
      padding: "5px 10px 0px 0!important",
      color: " #767676",
      "font-weight": 500,
      "text-transform": "uppercase",
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "none",
      },
    },
  })
);

interface IProps {
  evaluationList: IResultEvaluationKeyTasks[];
  radioButtons?: boolean;
  culture?: ICultureState;
  filters?: IFilters;
  getEvaluationKeyTasks?: (options: object) => Promise<any>;
  refreshTabs?: any;
  postIdeasStars?: (options: object) => Promise<any>;
}

function KeyTasksEvaluationList(props: IProps) {
  const {
    evaluationList,
    radioButtons,
    culture,
    filters,
    getEvaluationKeyTasks,
    postIdeasStars,
    refreshTabs,
  } = props;
  const classes = useStyles();
  return (
    <div>
      {evaluationList?.length ? (
        <>
          <KeyTasksFilters
            typeFilter="evaluation"
            culture={culture!}
            filters={filters!}
            getEvaluationKeyTasks={getEvaluationKeyTasks}
          />
          <Table
            // aria-label="simple table"
            className={classes.table}
          >
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableTitles}>
                  <Hidden xsDown>
                    <Typography>
                      <Resource tag="PageIdea::Idea_ColumnIdeia" />
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Hidden xsDown>
                    <Typography>
                      <Resource tag="Common::Status" />
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Hidden implementation="css" xsDown>
                    <Typography>
                      <Resource tag="Common::Challenge" />
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Hidden xsDown>
                    <Typography>
                    { newApp ? <Resource tag="PageKeyTasks::StateOfEvaluationV3" /> : <Resource tag="PageKeyTasks::StateOfEvaluation" /> }
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Hidden xsDown>
                    <Typography>
                      <Resource tag="PageKeyTasks::TimeInThisStatus" />
                    </Typography>
                  </Hidden>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {evaluationList?.map((evaluation, index) => (
                <KeyTaskListItemEvaluation
                  key={index}
                  title={evaluation?.ideaTitle}
                  type={"evaluation"}
                  time={evaluation?.stateUpdated}
                  timeType={evaluation?.stateUpdatedAtResource}
                  ideaId={evaluation?.ideaId}
                  stateEvaluationDate={evaluation?.limitDate}
                  stateEvaluationDateResource={evaluation?.limitDateResource}
                  challenge={evaluation?.challengeTitle}
                  status={evaluation?.status}
                  radioButtons={radioButtons}
                  limitDateResource={evaluation?.limitDateResource}
                  limitDate={evaluation?.limitDate}
                  postIdeasStars={postIdeasStars}
                  refreshTabs={refreshTabs}
                  getEvaluationKeyTasks={getEvaluationKeyTasks}
                />
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <>
          <KeyTasksFilters
            typeFilter="evaluation"
            culture={culture!}
            filters={filters!}
            getEvaluationKeyTasks={getEvaluationKeyTasks}
          />
          <Box textAlign="center" width="100%" p={5}>
            <Typography color="textSecondary" variant="caption">
              <Resource tag="PageKeyTasks::Error_NoKeyTasksAvailable" />
            </Typography>
          </Box>
        </>
      )}
    </div>
  );
}
export default KeyTasksEvaluationList;
