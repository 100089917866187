// Import Reducer type
import { Reducer } from 'redux';
import { AddValueActionTypes, AddValueActionStatus, AddValueActions } from '../../actions/ideas/AddValueAction';

export interface IAddValueState {
    readonly error: any;
    readonly status: string
}

const initialAllIdeasState = {
    status: AddValueActionStatus.ADD_VALUE_LOADING,
    error: null,
}

export const addValueReducer: Reducer<any, AddValueActions> = (
  state = initialAllIdeasState,
  action
) => {
  switch (action.type) {
    // ---------* ALL IDEAS  *---------
    case AddValueActionTypes.ADD_VALUE_LOADING: {
        return {
          ...state,
          status: action.status,
        };
      }
    case AddValueActionTypes.ADD_VALUE_SUCCEEDED: {
        return {
            ...state,
          status: action.status,
        }
    }
    case AddValueActionTypes.ADD_VALUE_FAILED: {
        return {
            ...state,
          status: action.status,
          error: action.error
        }
    }
    default:
      return state;
  }
};