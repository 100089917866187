import * as React from 'react';
import HistoricInvestments from '../../components/HistoricInvestments/HistoricInvestments'
import { IResultMyActivitiesInvestmentsHistory } from 'redux/types/myActivities';
import './HistoryInvestmentList.css'
import { Table, TableHead, TableRow, TableCell, Hidden, makeStyles, createStyles, Theme, TableBody } from '@material-ui/core';
import Resource from 'components/Resources/Resource';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: '20px 0px',
    },
    tableTitles: {
      'padding': '10px 5px 8px 0px!important',
      'color': ' #767676',
      'font-weight': 500,
      'font-size': '12px',
      'text-transform': 'uppercase',
    },
    tableRow: {
      borderBottom: 'solid 1px #ccc',
    }
  })

);
interface IProps {
  investmentsHistory?: IResultMyActivitiesInvestmentsHistory[];
}

function HistoryInvestmentList(props: IProps) {

  const { investmentsHistory } = props
  const classes = useStyles()
  return (
    <Table>
      <TableHead >
        <TableRow className={classes.tableRow}>
          <TableCell className={classes.tableTitles}>
            <Resource tag={"PageWallet::Investment_IdeaColumn"} />
          </TableCell>
          <TableCell className={classes.tableTitles}>
            <Hidden implementation="css" smDown>
              <Resource tag="PageMyActivity::CurrentStatus" />
            </Hidden>
          </TableCell>
          <TableCell className={classes.tableTitles}>
            <Resource tag="PageMyActivity::Investments_InvestedAmount"   /> 
          </TableCell>
          <TableCell className={classes.tableTitles}>
            <Resource tag="PageMyActivity::Investments_FinalValue"/> 
          </TableCell>
          <TableCell className={classes.tableTitles}>
            <Hidden implementation="css" xsDown>
              <Resource tag="PageMyActivity::Investments_Sold" />
            </Hidden>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {investmentsHistory && investmentsHistory.map(data => {
          return (
            <HistoricInvestments key={data.ideaId} investmentsHistory={data} />
          )
        })}
      </TableBody>
    </Table>
  );
}

export default HistoryInvestmentList;