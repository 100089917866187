import React from "react";
import { makeStyles, createStyles, Box } from "@material-ui/core";
import XCarousel from "common/X/XCarousel";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "10px",
      margin: "16px 0",
    },
  })
);

interface IProps {
  children: React.ReactNode;
}

export default function StateRuler(props: IProps) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <XCarousel itemsToShow={5}>{props?.children}</XCarousel>
    </Box>
  );
}
