import "./TitleCard.css";
import React from "react";
import XCard from "common/X/XCard";
import { Box } from "@material-ui/core";
import Resource from "../../Resources/Resource";
import { IChallenge } from "redux/types/challenges";
import { IChallengeDetail } from "../../../redux/reducers/challengeDetailReducer";

interface IProps {
  challenge: IChallenge | IChallengeDetail;
}

class TitleCard extends React.Component<IProps> {
  render() {
    const { challenge } = this.props;
    const resource1 = challenge?.timeValue1Resource?.replace(".", "::");
    const resource2 = challenge?.timeValue2Resource?.replace(".", "::");
    return (
      <>
        <XCard
          color={"dark"}
          elevation={5}
          height={210}
          middleContentPadding={40}
          bottomContentPadding={40}
          middleContent={
            <>
              <Box fontSize="1.2rem">{challenge?.title}</Box>
              <div className="title-line"></div>
              <br />
            </>
          }
          bottomContent={
            <>
              <div className="grid-time-btn">
                <div className="titleCard-timeLeft">
                  {challenge?.allowSubmitIdea ? (
                    challenge?.timeValue1 && challenge?.timeValue2 ? (
                      <p>
                        <Resource
                          tag={"PageChallenges::Challenge_TimeIdeaSubmit"}
                          args={[""]}
                        />
                      </p>
                    ) : (
                      ""
                    )
                  ) : challenge?.timeValue1 && challenge?.timeValue2 ? (
                    <p>
                      <Resource
                        tag={"PageChallenges::Challenge_TimeIdeaEvaluate"}
                        args={[""]}
                      />
                    </p>
                  ) : (
                    ""
                  )}
                  <strong>
                    {challenge?.timeValue1 ? (
                      <Resource
                        tag={resource1}
                        args={[challenge?.timeValue1]}
                      />
                    ) : (
                      ""
                    )}
                    {challenge?.timeValue2 ? (
                      <>
                        {" "}
                        <span></span>
                        <Resource
                          tag={resource2}
                          args={[challenge?.timeValue2]}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </strong>
                </div>
              </div>
            </>
          }
          backgroundImage={challenge?.image}
        />
      </>
    );
  }
}

export default TitleCard;
