import { Box, Button, Dialog, Divider, IconButton,  Slider, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Resource from 'components/Resources/Resource';
import React, { useState } from 'react'
import { IResultIdeasCollaborativeEvaluationInvestment } from 'redux/types/ideas';
import CloseIcon from '@material-ui/icons/Close';
import { useStylesInvestmentPopup } from './ReviewInvestments';

interface IProps {
    investment: IResultIdeasCollaborativeEvaluationInvestment;
    points: number;
    title: string;
    isSellOn: boolean;
    setSellOn: (is:boolean) => void;
    sellInvestments:(value:any) => void
}

function ReviewSellPopup(props:IProps){
    const [screen, setScreen] = useState('points');
    const { investment, points, isSellOn, setSellOn } = props;
    const [value,setValue] = useState<number | number[]>(investment.investmentStep > points ? 0 : investment.investmentStep);
    const classes = useStylesInvestmentPopup();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));   

    return( 
        <Dialog fullWidth maxWidth={'sm'} fullScreen={fullScreen} open={isSellOn}>
            <MuiDialogTitle disableTypography className={classes.popupTitle}>
                <Typography variant="h4">
                    <Resource tag={"PageSaleInvestment::Header"}/>
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={()=> setSellOn(false)}>
                    <CloseIcon />
                </IconButton>
                <Divider className={classes.titleDivider}></Divider>
            </MuiDialogTitle>
            <MuiDialogContent>
                <Box className="ReviewInvestmentsModal">
                    {(screen === 'points')
                        ? <>
                            <Box className="ReviewInvestmentsModalAmount">
                                <Box component='span' fontWeight='fontWeightMedium'>
                                    <Resource tag={"PageSaleInvestment::InvestedAmount"}/> 
                                </Box>
                                <Box component='span' marginLeft={4} className="ReviewInvestmentsModalSalePoints">  
                                    <Resource 
                                        tag={
                                            investment?.isIncreaseInvestment 
                                                ? "PageSaleInvestment::InvestedAmount_Approve" 
                                                : "PageSaleInvestment::InvestedAmount_Reject"
                                        }
                                        args={[`<strong>${investment.investmentValue}</strong>`]}
                                    />
                                </Box>
                                    <br/>
                                    <Resource tag="PageIdeaHistory::Date_On"/> {investment.investmentDate}
                                <Divider className={classes.amountDivider}/>
                                <Box component='span' fontWeight='fontWeightMedium'>
                                    <Resource tag={"PageSaleInvestment::CurrentValue"}/>:
                                </Box>
                                <Box component='span' marginLeft={'98px'}>
                                    <Resource tag="PageSaleInvestment::CurrentValue_Points" args={[`<strong>${investment.investmentVariation}</strong>`]} />
                                </Box> 
                            </Box>

                            <Box>
                                <Box className={classes.reviewInvestmentsModalText}><Resource tag="PageSaleInvestment::SelectValue" /></Box>
            
                                <Box className="ReviewInvestmentsModalSlider">
                                    <Box className="ReviewInvestmentsModalValue">{value}</Box>
                                    <Box className="ReviewInvestmentsModalPoints"><Resource tag="PageSaleInvestment::CurrentValue_Points" args={['']} /></Box>

                                    <Slider
                                        defaultValue={value}
                                        aria-labelledby="discrete-slider-small-steps"
                                        step={investment.investmentStep}
                                        marks
                                        min={points > investment.investmentStep ? investment.investmentStep : 0}
                                        max={investment.investmentVariation}
                                        valueLabelDisplay="off"
                                        onChange={(event, value)=> setValue(value)}
                                    />
                                </Box>
                            </Box>
                           
            
                            <Box className="ReviewInvestmentsModalButtons">
                                <Box>
                                    <Button 
                                        onClick={() => setSellOn(false)} 
                                        color='primary'
                                        variant="outlined"
                                    >
                                        <Resource tag="PageNewIdea::Button_Cancel" />
                                    </Button>
                                </Box>
                                <Box>
                                    <Button 
                                        onClick={() => { setScreen('confirm'); }}
                                        color='primary'
                                        variant="contained"
                                    >
                                        &nbsp;&nbsp;<Resource tag="PageDetailIdea::InvestmentArea_ButtonSell" />&nbsp;&nbsp;
                                    </Button>
                                </Box>
                            </Box>
                        </>
                        : 
                        <>
                            <Box className="ReviewInvestmentsModalText">
                                <Resource tag="PageSaleInvestment::SaleConfirmation" args={[`<strong>${value}</strong>`, `<strong>${props.title}</strong>`]} />
                            </Box>
            
                            <Box className="ReviewInvestmentsModalButtons">
                                <Box>
                                    <Button 
                                        onClick={() => { setScreen('points'); }}
                                        color='primary'
                                        variant="outlined"
                                    >
                                        <Resource tag="PageSaleInvestment::SaleConfirmation_ButtonNo" />
                                    </Button>
                                </Box>
                                <Box>
                                    <Button 
                                        onClick={() => { 
                                            setSellOn(false);
                                            setScreen('points');
                                            props.sellInvestments(value);
                                        }} 
                                        color='primary'
                                        variant="contained"
                                    >
                                        <Resource tag="PageSaleInvestment::SaleConfirmation_ButtonYes" />
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    }
                </Box>
            </MuiDialogContent>
        </Dialog>     
    )
}

export default ReviewSellPopup