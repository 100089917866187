import React, { useState } from "react";
import "./DropdownInput.css";
import reactNewApp from "utils/reactNewApp";
import ToolTip from "../../ToolTip/ToolTip";
import { ICultureState } from "redux/reducers/cultureReducer";
import { makeStyles, createStyles, TextField, Theme, MenuItem, Box, Typography } from "@material-ui/core";

interface IProps {
  value: any;
  handler: any;
  propsI: any;
  culture?: ICultureState;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontWeight: "bold",
      fontSize: "0.8rem",
      textTransform: "uppercase",
      color: theme.palette.text.secondary,
    },
    BoxTooltip: {
      display: "flex",
      alignItems: "center",
    },
  })
);

function DropdownInput(props: IProps) {
  const classes = useStyles();
  const { propsI, value, handler} = props;

  const [innerValue, setInnerValue] = useState(value ?? "");

  const optionsKeys = propsI?.options ? (Object.keys(propsI?.options) as Array<any>) : [];
  const optionsValues = propsI?.options ? (Object.values(propsI?.options) as Array<any>) : [];

  const labelT = (
    <Box className="textField-and-tooltip">
      <Typography variant="body1" className={classes.label}>
        {propsI?.title || propsI.cultures?.[0]?.title}
        {propsI?.isMandatory || propsI?.isRequired ? " *" : ""}
      </Typography>
      <Box className={classes.BoxTooltip} ml={1}>
        {propsI.help || propsI?.description ? <ToolTip title="Help">{propsI.help || propsI?.description}</ToolTip> : ""}
      </Box>
    </Box>
  );

  const setValue = async (e: React.ChangeEvent<any>) => {
    await setInnerValue(e.target.value);
    handler && handler(e.target.value);
  };

  return (
    <Box>
      {labelT}
      <TextField
        id={propsI?.title}
        name={propsI?.columnName || propsI?.ideaCustomFieldId}
        select
        fullWidth
        onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
        InputLabelProps={{
          shrink: true,
          className: classes.label,
        }}
        required={propsI?.isMandatory || propsI?.isRequired ? true : false}
        value={innerValue}
        onChange={setValue}
      >
        {reactNewApp
          ? propsI?.options &&
            propsI?.options?.map((data: any, index: number) => (
              <MenuItem key={data.ideaCustomFieldOptionId} value={data.ideaCustomFieldOptionId}>
                {data?.title || data?.cultures?.[0]?.title}
              </MenuItem>
            ))
          : propsI?.options &&
            optionsValues?.map((data, index) => (
              <MenuItem
                key={reactNewApp ? data.ideaCustomFieldOptionId : optionsKeys[index]}
                value={reactNewApp ? data.ideaCustomFieldOptionId : optionsKeys[index]}
              >
                {reactNewApp ? data?.title || data?.cultures?.[0]?.title : data}
              </MenuItem>
            ))}
      </TextField>
    </Box>
  );
}

export default DropdownInput;
