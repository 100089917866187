import {
  TableRow,
  TableCell,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Box,
  Hidden,
  useMediaQuery,
  Checkbox,
  Avatar,
} from "@material-ui/core";
import "moment/locale/pt";
import "moment/locale/es";
import "./KeyTaskListItem.scss";
import React, { useState } from "react";
import EMIcon from "components/Icon/Icon";
import Resource from "../Resources/Resource";
import { useTheme } from "@material-ui/core/styles";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import KeyTaskIdeaDetailPopUp from "../KeyTaskIdeaDetailPopUp/KeyTasksIdeaDetailDialog";
import Truncate from "react-truncate";
import TextToolTip from "../../components/ToolTip/TextTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: "#333",
    },
    pageTitle: {
      marginTop: 24,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    tableRow: {
      borderTop: "solid 1px #ccc",
    },
    tableCell: {
      padding: "24px 10px 24px 0px!important",
      maxWidth: "250px",
    },
    allListCell: {
      display: "flex",
      padding: "24px 10px 24px 0px!important",
      width: "100%",
      justifyContent: "space-between",
    },
    truncate: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      maxWidth: 600,
    },
    uppercase: {
      textTransform: "uppercase",
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
);

interface IProps {
  title?: string;
  type?: string;
  time?: number;
  timeType?: string;
  checkAll?: boolean;
  ideaId?: string;
  radioButtons?: boolean;
  challenge?: string;
  status?: string;
  limitDate?: number;
  limitDateResource?: string;
  allList?: any;
  numberCorrections?: number;
  totalUsersEvaluation?: number;
  totalUsersLeftToEvaluation?: number;
  stateEvaluationDate?: number;
  stateEvaluationDateResource?: string;
  usersLeft?: {
    userId: string;
    name: string;
    photo: string;
    email: string;
  }[];
  evaluationType?: string;
  evaluationValue?: number;
  isEvaluation?: boolean;
  handlerPopUp?: (value: string, check: boolean) => void;
  postIdeasStars?: any;
  getEvaluationKeyTasks?: any;
  filterKeyTasksEvaluations?: any;
  statusTransitionTask?: string;
}
/**
 * Example:
 *  <KeyTaskListItem ideaId="dah3-dak1k-abcd-1234" timeLeft="08-05-2020" title="Testando titulo"/>
 *  <KeyTaskListItem status="Validated" ideaId="dah3-dak1k-abcd-1234" timeLeft="08-05-2020" title="Testando titulo"/>
 */
function KeyTaskListItem(props: IProps) {
  const handleSelect = (event: any) => {
    const { handlerPopUp } = props;
    const value = event?.target?.value;
    const check = event?.target?.checked;

    if (handlerPopUp) {
      handlerPopUp(value, check);
    }
  };

  const {
    title,
    status,
    time,
    ideaId,
    timeType,
    radioButtons,
    challenge,
    limitDate,
    limitDateResource,
    allList,
    numberCorrections,
    evaluationType,
    evaluationValue,
    checkAll,
    isEvaluation,
    totalUsersLeftToEvaluation,
    totalUsersEvaluation,
    postIdeasStars,
    getEvaluationKeyTasks,
    filterKeyTasksEvaluations,
    stateEvaluationDate,
    stateEvaluationDateResource,
    statusTransitionTask,
    usersLeft,
  } = props;

  const [type, setType] = useState(props.type);
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("sm"));
  const data = [{ title: title! }, { ideaId: ideaId! }];
  const stateEvaluation = limitDateResource?.replace(".", "::")!;

  let timeResource = "";
  if (timeType) {
    timeResource = timeType?.replace(".", "::")!;
  }

  const evaluationTime = stateEvaluationDateResource?.replace(".", "::");
  let typeTaskResource = allList?.typeTaskResource?.replace(".", "::");
  let titleAllListResource = allList?.titleResource?.replace(".", "::");
  let timeLimitResource = allList?.limitDateResource?.replace(".", "::");
  const openModal = async (type: number) => {
    switch (type) {
      case 1:
        await setType("evaluation");
        break;
      case 2:
        await setType("validation");
        break;
      case 3:
        await setType("transition");
        break;
      default:
        break;
    }
  };
  const redirectTab = (type: number, status?: string) => {
    filterKeyTasksEvaluations(String(type), status);
  };

  return (
    <>
      <TableRow className={classes.tableRow}>
        {allList && (
          <TableCell className={classes.allListCell}>
            <Box display="flex" flexDirection="column" gridGap={3.5}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.truncate + " " + classes.uppercase}
              >
                <strong>
                  <Resource tag={typeTaskResource} />
                </strong>
              </Typography>
              <Box
                onClick={() =>
                  openModal(allList?.typeTask).then(() => setOpen(true))
                }
                style={{ cursor: "pointer" }}
              >
                <Typography
                  color="textPrimary"
                  variant="body2"
                  className={classes.truncate}
                >
                  <strong>{allList?.title}</strong>
                </Typography>
              </Box>
              {titleAllListResource && (
                <Box
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    redirectTab(allList?.typeTask, allList?.state);
                  }}
                >
                  <Typography
                    className={classes.truncate}
                    color="textPrimary"
                    variant="body2"
                  >
                    <strong>
                      {allList?.totalTasks}&nbsp;
                      <Resource
                        tag={titleAllListResource}
                        args={[allList?.state]}
                      />
                    </strong>
                  </Typography>
                </Box>
              )}
            </Box>
            <Box marginTop={3}>
              {timeLimitResource && (
                <Typography variant="body2" color="textSecondary">
                  <Resource
                    tag={
                      allList?.typeTask === 1
                        ? "PageKeyTasks::Transitions_TimeStatus"
                        : "PageKeyTasks::TimeLeft"
                    }
                    args={[""]}
                  />
                  &nbsp;
                  <Resource
                    tag={timeLimitResource}
                    args={[allList?.limitDate]}
                  />
                </Typography>
              )}
            </Box>
          </TableCell>
        )}
        {radioButtons && (
          <TableCell className={classes.tableCell}>
            <Checkbox
              value={`${data[1].ideaId},${data[0].title}`}
              checkedIcon={<RadioButtonCheckedIcon />}
              icon={
                checkAll ? (
                  <RadioButtonCheckedIcon />
                ) : (
                  <RadioButtonUncheckedIcon />
                )
              }
              inputProps={{ name: "idea-key-task" }}
              // inputProps={{ "aria-label": "Checkbox A", name: "idea-key-task" }}
              color="primary"
              disabled={checkAll}
              onChange={handleSelect}
            />
          </TableCell>
        )}
        {title && (
          <TableCell
            onClick={() => setOpen(true)}
            style={{ cursor: "pointer" }}
            className={classes.tableCell}
          >
            <Typography
              color="textPrimary"
              variant="body2"
              className={classes.truncate}
            >
              <strong>{title}</strong>
            </Typography>
            {status && (
              <Hidden implementation="css" smUp>
                <Typography color="textPrimary" variant="body2">
                  {status}
                </Typography>
              </Hidden>
            )}
          </TableCell>
        )}
        {status && match && (
          <TableCell className={classes.tableCell}>
            <Typography color="textPrimary" variant="body2">
              {status}
            </Typography>
          </TableCell>
        )}
        {challenge && (
          <TableCell className={classes.tableCell}>
            <Truncate lines={1} width={320} ellipsis={<span>...</span>}>
              <Typography color="textPrimary" variant="body2">
                {challenge}
              </Typography>
            </Truncate>
          </TableCell>
        )}
        {statusTransitionTask && match && (
          <TableCell className={classes.tableCell}>
            <Typography color="textPrimary" variant="body2">
              {statusTransitionTask}
            </Typography>
          </TableCell>
        )}
        {evaluationType && (
          <TableCell className={classes.tableCell}>
            <Box display="flex" alignItems="center" gridGap={5}>
              <span>
                {evaluationType === "Investments"
                  ? evaluationValue + "%"
                  : evaluationValue}
              </span>
              {evaluationType ? (
                <em>
                  {evaluationType === "Stars" || evaluationType === "Average" ? (
                    <EMIcon icon="star-b" size={13} color="#5f5f5f" />
                  ) : evaluationType === "Investments" ? (
                    <EMIcon icon="investment" size={13} color="#5f5f5f" />
                  ) : (
                    <EMIcon icon="like-b" size={13} color="#5f5f5f" />
                  )}
                </em>
              ) : (
                <em> - </em>
              )}
            </Box>
            {time && (
              <Hidden implementation="css" smUp>
                {timeResource !== null && (
                  <Typography color="textPrimary" variant="body2" noWrap>
                    <Resource tag={timeResource} args={[time]} />
                  </Typography>
                )}
              </Hidden>
            )}
          </TableCell>
        )}
        {limitDate && (
          <TableCell className={classes.tableCell}>
            <Typography color="textPrimary" variant="body2">
              <strong>
                <Resource tag={stateEvaluation} args={[limitDate]} />
              </strong>
            </Typography>
            {time && (
              <Hidden implementation="css" smUp>
                {timeResource !== null && (
                  <Typography color="textPrimary" variant="body2" noWrap>
                    <Resource tag={timeResource} args={[time]} />
                  </Typography>
                )}
              </Hidden>
            )}
          </TableCell>
        )}
        {stateEvaluationDate ? (
          <TableCell className={classes.tableCell}>
            <Typography
              style={{ display: "grid" }}
              color={stateEvaluationDate < 0 ? "error" : "textPrimary"}
              variant="body2"
            >
              {stateEvaluationDate ? (
                stateEvaluationDate < 0 ? (
                  <>
                    <Resource tag="PageKeyTasks::Evaluation_EvaluationOverdue" />
                    <strong>
                      <Resource
                        tag={evaluationTime!}
                        args={[-stateEvaluationDate]}
                      />
                    </strong>
                  </>
                ) : (
                  <>
                    <Resource tag="PageKeyTasks::Evaluation_ToBeEvaluated" />
                    <Box component="span" mr={0.3} fontWeight={600}>
                      <Resource
                        tag={evaluationTime!}
                        args={[stateEvaluationDate]}
                      />
                    </Box>
                    <Box component="span" fontWeight={600}>
                      <Resource tag={"PageKeyTasks::IdeaDetail_Left"} />
                    </Box>
                  </>
                )
              ) : (
                <Resource tag="PageKeyTasks::Evaluation_NoTimeFrame" />
              )}
            </Typography>
            {time && (
              <Hidden implementation="css" smUp>
                {timeResource !== null && (
                  <Typography color="textPrimary" variant="body2" noWrap>
                    <Resource tag={timeResource} args={[time]} />
                  </Typography>
                )}
              </Hidden>
            )}
          </TableCell>
        ) : (
          !evaluationType && (
            <TableCell className={classes.tableCell}>
              <Typography
                style={{ display: "grid" }}
                color={"textPrimary"}
                component="strong"
                variant="inherit"
              >
                -
              </Typography>
            </TableCell>
          )
        )}
        {(numberCorrections || numberCorrections === 0) && (
          <TableCell className={classes.tableCell}>
            <Typography color="textPrimary" variant="body2">
              <strong>{numberCorrections}</strong>
            </Typography>
          </TableCell>
        )}
        {isEvaluation && (
          <TableCell className={classes.tableCell}>
            {totalUsersLeftToEvaluation !== 0 ? (
              <TextToolTip
                text={
                  <Typography color="textPrimary" variant="body2">
                    <Resource tag="Common::Underway" />
                    <br />
                    <Resource
                      tag="PageKeyTasks::XofXevaluated"
                      args={[
                        totalUsersEvaluation,
                        totalUsersLeftToEvaluation! - totalUsersEvaluation!,
                      ]}
                    />
                  </Typography>
                }
                title={<Resource tag="PageKeyTasks::UsersLeftToEvaluate" />}
              >
                {usersLeft?.map((data, index) => {
                  return (
                    <Box key={index}>
                      <Box
                        paddingTop={1}
                        display="flex"
                        alignItems="center"
                        gridGap={5}
                      >
                        <Avatar
                          src={data.photo}
                          alt={data.name}
                          className={classes.small}
                        />
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          style={{ maxWidth: "100px" }}
                          noWrap
                        >
                          {data.name}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </TextToolTip>
            ) : (
              <Typography color="textPrimary" variant="body2">
                <Resource tag="PageKeyTasks::EvaluationCompleted" />
                <br />
                <Resource
                  tag="PageKeyTasks::XDone"
                  args={[totalUsersEvaluation]}
                />
              </Typography>
            )}
          </TableCell>
        )}
        {time && match && (
          <TableCell className={classes.tableCell}>
            {timeResource !== null && (
              <Typography color="textPrimary" variant="body2" noWrap>
                <Resource tag={timeResource} args={[time]} />
              </Typography>
            )}
          </TableCell>
        )}
      </TableRow>
      {isOpen && (
        <KeyTaskIdeaDetailPopUp
          getEvaluationKeyTasks={getEvaluationKeyTasks}
          postIdeasStars={postIdeasStars}
          type={type}
          isOpen={isOpen}
          setOpen={setOpen}
          ideaId={ideaId! ? ideaId : allList?.ideaId}
          closeButton={() => setOpen(false)}
        />
      )}
    </>
  );
}

export default KeyTaskListItem;
