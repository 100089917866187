import { AnyAction } from "redux";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import ReplyIcon from "@material-ui/icons/Reply";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import MessageReplyDialog from "./MessageReplyDialog";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import { IMessagesDetail } from "redux/types/messages";
import MessageForwardDialog from "./MessageForwardDialog";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import MessageDetailReduced from "./MessageDetailReduced";
import MessageDetailReplies from "./MessageDetailReplies";
import MessageReplyAllDialog from "./MessageReplyAllDialog";
import { IProfileData } from "redux/reducers/profileReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IMessagesState } from "redux/reducers/messagesReducer";
import {
  deleteMessage,
  getAllMessagesDetail,
  postMessageArchive,
  postMessageReply,
} from "redux/actions/MessagesActions";
import {
  Avatar,
  Box,
  Button,
  Container,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { securityTextEditor } from "utils/securityTextEditor";

interface IDispatchProps {
  getMessagesDetail: (options: object) => Promise<IMessagesDetail>;
  deleteMessages: (options: object) => Promise<IMessagesState>;
  postMessageArchive: (options: object) => Promise<IMessagesState>;
  postMessageReply: (options: object) => Promise<IMessagesState>;
}
interface IProps {
  culture: ICultureState;
  messageDetail: IMessagesDetail;
  messageId: string;
  profileData: IProfileData;
  applicationId: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textUpper: {
      textTransform: "uppercase",
    },
    divider: {
      marginBottom: "16px",
      width: "99%",
      background:
        "linear-gradient(90deg, rgba(242,242,242,0.3617822128851541) 0%, rgba(242,242,242,1) 29%, rgba(242,242,242,1) 66%, rgba(242,242,242,0.32816876750700286) 100%)",
    },
    boxGap: {
      gap: "10px",
    },
    greyColor: {
      color: theme.palette.text.secondary,
    },
    forwardIcon: {
      color: theme.palette.text.secondary,
      transform: "rotate(180deg)",
    },
  })
);

function MessageDetailPage(props: IProps & IDispatchProps) {
  const theme = useTheme();
  const classes = useStyles();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));
  const {
    culture,
    messageId,
    profileData,
    messageDetail,
    getMessagesDetail,
    deleteMessages,
    postMessageArchive,
    postMessageReply,
    applicationId,
  } = props;

  //State to active buttons
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openReply, setOpenReply] = useState(false);
  const [openReplyAll, setOpenReplyAll] = useState(false);
  const [openForward, setOpenForward] = useState(false);
  const [toggle, setToggle] = useState(true);

  //function to loading page
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const options = {
      messageId: messageId,
      pageNumber: 1,
      pageSize: 8,
      applicationId: applicationId,
      cultureTag: culture.culture.cultureTag,
    };
    getMessagesDetail(options).then(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageId]);

  //function to delete message
  const deleteThisMessage = (messageId: any) => {
    deleteMessages({ messageId }).then((x) => {
      getMessagesDetail(messageId);
    });
  };
  //function to archive message
  const handleArchive = () => postMessageArchive({ messageId });

  //close/open message reduced
  const handleToggle = () => setToggle(!toggle);

  //array for reply messages
  const hasReply = messageDetail?.replies?.length;

  return (
    <>
      {!isLoading && (
        <>
          <Container>
            <Button
              className={classes.textUpper}
              variant="text"
              color="primary"
              onClick={(e: any) => {
                setOpenDeleteDialog(true);
                e.stopPropagation();
              }}
              onFocus={(e: any) => e.stopPropagation()}
            >
              <Resource tag={"PageMessages::Delete"} />
            </Button>
            <PopUpConfirmation
              sendButton={deleteThisMessage}
              closeButton={() => setOpenDeleteDialog(false)}
              message={<Resource tag={"PageMessages::ConfirmDelete"} />}
              setIsOpen={setOpenDeleteDialog}
              isOpen={openDeleteDialog}
            />
            <Button
              className={classes.textUpper}
              variant="text"
              color="primary"
              onClick={(e: any) => {
                e.stopPropagation();
                setOpenArchiveDialog(true);
              }}
              onFocus={(e: any) => e.stopPropagation()}
            >
              <Resource tag={"PageMessages::Archive"} />
            </Button>
            <PopUpConfirmation
              sendButton={handleArchive}
              closeButton={() => setOpenArchiveDialog(false)}
              message={<Resource tag={"PageMessages::ConfirmArchive"} />}
              setIsOpen={setOpenArchiveDialog}
              isOpen={openArchiveDialog}
            />
            <Divider className={classes.divider} />
            <Box px={1} className="message-detail-content">
              <Box
                className="subject-and-date"
                mb={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box fontWeight="bold" fontSize="1.2rem">
                  {messageDetail?.subject}
                </Box>
                <Box textAlign="end" className="message-date">
                  <Typography color="textSecondary" variant="caption">
                    {messageDetail?.date}
                  </Typography>
                </Box>
              </Box>
              {toggle ? (
                <>
                  <Box
                    className="avatar-name-emailSender-container"
                    mb={5}
                    display="flex"
                    onClick={handleToggle}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar alt="avatar" src={messageDetail?.photo} />
                    <Box
                      className="name-emailSender-container"
                      display="flex"
                      flexDirection="column"
                      ml={2}
                      justifyContent="center"
                    >
                      <Box mb="4px" fontSize="0.8rem" fontWeight="700">
                        {messageDetail?.fullName}
                      </Box>
                      <Box
                        color={theme.palette.text.secondary}
                        fontSize="0.7rem"
                        fontWeight="400"
                      >
                        {messageDetail?.to?.fullName}
                        to me, carolyne.silva@exago.com,
                        texto-fixo-sem-retorno-API
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="message-description"
                    mb={5}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(
                        messageDetail?.message,
                        securityTextEditor
                      ),
                    }}
                  />
                </>
              ) : (
                <Box onClick={handleToggle} style={{ cursor: "pointer" }}>
                  <MessageDetailReduced
                    avatar={messageDetail?.photo}
                    fullName={messageDetail?.fullName}
                    message={messageDetail?.message}
                  />
                </Box>
              )}
              {!openReply && !openReplyAll && !openForward && !hasReply && (
                <Box
                  className={classes.boxGap + " actions-btn-to-msg"}
                  display={matchXs ? "grid" : "flex"}
                >
                  <Button
                    className={classes.greyColor}
                    variant="outlined"
                    startIcon={<ReplyIcon className={classes.greyColor} />}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setOpenReply(true);
                    }}
                  >
                    <Resource tag={"PageMessages::Reply"} />
                  </Button>
                  <Button
                    className={classes.greyColor}
                    variant="outlined"
                    startIcon={<ReplyAllIcon className={classes.greyColor} />}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setOpenReplyAll(true);
                    }}
                  >
                    <Resource tag={"PageMessages::ReplyToAll"} />
                  </Button>
                  <Button
                    className={classes.greyColor}
                    variant="outlined"
                    startIcon={<ReplyIcon className={classes.forwardIcon} />}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setOpenForward(true);
                    }}
                  >
                    <Resource tag={"PageMessages::Forward"} />
                  </Button>
                </Box>
              )}
              <MessageReplyDialog
                setIsOpen={setOpenReply}
                isOpen={openReply}
                culture={culture}
                applicationId={applicationId}
                profileData={profileData}
                getMessagesDetail={getMessagesDetail}
                postMessageReply={postMessageReply}
                authorId={messageDetail?.authorId}
                emailAuthor={messageDetail?.email}
                messageId={messageDetail?.messageId}
              />
              <MessageReplyAllDialog
                culture={culture}
                setIsOpen={setOpenReplyAll}
                isOpen={openReplyAll}
              />
              <MessageForwardDialog
                culture={culture}
                setIsOpen={setOpenForward}
                isOpen={openForward}
              />
            </Box>
          </Container>
          {hasReply ? (
            <MessageDetailReplies
              culture={culture}
              applicationId={applicationId}
              profileData={profileData}
              msgDetailReplies={messageDetail?.replies}
              msgDetail={messageDetail}
              getMessagesDetail={getMessagesDetail}
              postMessageReply={postMessageReply}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispach: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getMessagesDetail: (options: object) =>
      dispach(getAllMessagesDetail(options)),
    deleteMessages: (options: object) => dispach(deleteMessage(options)),
    postMessageArchive: (options: object) =>
      dispach(postMessageArchive(options)),
    postMessageReply: (options: object) => dispach(postMessageReply(options)),
  };
};

const mapStateToProps = (store: IAppState, ownProps: any) => {
  return {
    culture: store.cultureState,
    messageDetail: store?.messagesState?.messageDetail,
    messageId: ownProps?.match?.params?.messageId,
    profileData: store?.profileState?.profileData,
    applicationId: store.authState.auth.applicationId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageDetailPage);
