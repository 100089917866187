import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  DialogContent,
  TextField,
  Chip,
  DialogActions,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
  Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import TextEditorInput from "components/GenericField/TextEditorInput/TextEditorInput";
import Resource, { resource } from "components/Resources/Resource";
import { IUsers } from "redux/reducers/usersReducers";
import { toast } from "react-toastify";

interface IProps {
  options: IUsers[];
  culture: any;
  open: boolean;
  setOpen: any;
  postMessage: any;
  getSentMessages: any;
  applicationId: any;
}

function SendNewMessage(props: IProps) {
  const {
    options,
    culture,
    open,
    setOpen,
    postMessage,
    getSentMessages,
    applicationId,
  } = props;
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    setToValues([]);
    setSubjectValue("");
    setEditorValue("");
    setInternalMessage(true);
    setEmailMessage(false);
  }, [open]);
  const [toValues, setToValues] = useState([]);
  const [subjectValue, setSubjectValue] = useState("");
  const [editorValue, setEditorValue] = useState("");
  const [internalMessage, setInternalMessage] = useState(true);
  const [emailMessage, setEmailMessage] = useState(false);
  const handleToChange = (e: any) => {
    setToValues(e.map((data: any) => data.userId));
  };
  const handleChange = (e: any) => {
    setSubjectValue(e.target.value);
  };
  const onChangeEditor = (e: any) => {
    setEditorValue(e);
  };
  const handleSelect = (e: any) => {
    e.target.name === "internal-message-checkbox"
      ? setInternalMessage(!internalMessage)
      : setEmailMessage(!emailMessage);
  };
  const sendMessage = async () => {
    if (toValues.length === 0) {
      toast.error(resource(culture, "MessageError::UsersRequired"));
      return;
    }
    if (!internalMessage && !emailMessage) {
      toast.error(resource(culture, "MessageError::MessageOptionRequired"));
      return;
    } else {
      const params = {
        applicationId: applicationId,
        subject: subjectValue,
        message: editorValue, //TODO add sanitizeHtml after join R1.1
        to: toValues,
        isInternal: internalMessage,
        isEmail: emailMessage,
      };
      await postMessage(params).then(async (response: any) => {
        if (response?.error?.response?.status === 400) {
          return;
        } else {
          await getSentMessages({
            pageNumber: 1,
            pageSize: 10,
          });
          setOpen(false);
          toast.success(resource(culture, "PageMessages::SentMessage"));
        }
      });
    }
  };
  return (
    <Box position="relative" width="100%">
      {open ? (
        <Paper
          style={{
            width: matchSm ? "100%" : "50%",
            bottom: "0px",
            right: matchSm ? "0" : "200px",
            height: matchSm ? "100%" : "550px",
            position: "fixed",
            zIndex: 1200,
          }}
          elevation={1}
        >
          <DialogTitle disableTypography>
            <Box display="flex">
              <Typography variant="h4">
                <Resource tag="PageMessages::NewMessage" />
              </Typography>
              <IconButton
                // aria-label="close"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider
              style={{ width: "100%", marginTop: "3px" }}
              variant="fullWidth"
            />
          </DialogTitle>
          <DialogContent style={{ height: "76%", overflowX: "hidden" }}>
            <Box display="flex" alignItems="flex-end" gridGap={10}>
              <Typography>
                <Resource tag="PageMessages::To" />
              </Typography>
              <Autocomplete
                fullWidth
                multiple
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip
                      label={option.email}
                      {...getTagProps({ index })}
                      deleteIcon={<CloseIcon />}
                      variant="outlined"
                      size="small"
                    />
                  ));
                }}
                renderOption={(option) => (
                  <Grid container style={{ maxWidth: "350px" }}>
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                      <Grid container spacing={2}>
                        {option.photo && (
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            md={2}
                            xl={2}
                            className="avatar"
                          >
                            <Avatar src={option.photo} alt={option.fullName} />
                          </Grid>
                        )}
                        <Grid item xs={10} sm={9} md={8} xl={8}>
                          <Box className="options-name-subtitle">
                            <Typography>{option.fullName}</Typography>
                            {option.email && (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {option.email}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                onChange={(event, newValue) => handleToChange(newValue)}
                id="tags-standard"
                options={options}
                getOptionLabel={(option) => option.fullName && option.email}
                popupIcon={""}
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    variant="standard"
                  />
                )}
              />
            </Box>
            <Box pt={2} display="flex" alignItems="flex-end" gridGap={10}>
              <Typography>
                <Resource tag="PageMessages::Subject" />
              </Typography>
              <TextField
                fullWidth
                variant="standard"
                onChange={(e) => handleChange(e)}
                value={subjectValue}
              />
            </Box>
            <Box
              width="100%"
              pt={3}
              display="flex"
              alignItems="flex-end"
              gridGap={10}
            >
              <TextEditorInput
                culture={culture}
                height={matchSm ? 380 : 280}
                propsI={{
                  typeId: 9,
                  columnName: "send-message-text-input",
                  isMandatory: false,
                  defaultValue: "",
                  title: "",
                  isEditable: true,
                  placeholder: resource(culture, "PageMessages::WriteAMessage"),
                }}
                value={editorValue}
                onChange={onChangeEditor}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box px={2} width="100%">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gridGap={10}
                  >
                    <Typography variant="body2">
                      <strong>
                        <Resource tag="PageMessages::SendChannels" />
                      </strong>
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableTouchRipple
                          disableFocusRipple
                          name="internal-message-checkbox"
                          onChange={handleSelect}
                          defaultChecked
                          id="label-internal-message"
                          color="primary"
                        />
                      }
                      label={
                        <Box>
                          <Typography variant="body2">
                            <Resource tag="PageMessages::InternalMessage" />
                          </Typography>
                        </Box>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableTouchRipple
                          disableFocusRipple
                          name="email-message-checkbox"
                          onChange={handleSelect}
                          id="label-email-message"
                          color="primary"
                        />
                      }
                      label={
                        <Box>
                          <Typography variant="body2">
                            <Resource tag="PageMessages::EmailMessage" />
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{ alignSelf: "center" }}
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                >
                  <Box textAlign="right">
                    <Button
                      color="primary"
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        setOpen(false);
                      }}
                      style={{ marginRight: 8 }}
                    >
                      <Resource tag="PageMessages::Cancel" />
                    </Button>

                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => sendMessage()}
                    >
                      <Resource tag="PageMessages::Send" />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogActions>
        </Paper>
      ) : (
        ""
      )}
    </Box>
  );
}

export default SendNewMessage;
