import React from "react";
import sanitizeHtml from "sanitize-html";
import CloseIcon from "@material-ui/icons/Close";
import { securityTextEditor } from "utils/securityTextEditor";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  createStyles,
  makeStyles,
  Theme,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: any;
  content?: any;
}

export default function DialogVersion({
  open,
  setOpen,
  title,
  content,
}: IProps) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              // aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          margin="28px"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(`${content}`, securityTextEditor),
          }}
        />
      </Dialog>
    </Box>
  );
}
