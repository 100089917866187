import React from "react";
import "./ActiveCard.scss";
import history from "routes/history";
import newApp from "utils/reactNewApp";
import { Link } from "react-router-dom";
import { api_general } from "config/api";
import sanitizeHtml from "sanitize-html";
import EMIcon from "components/Icon/Icon";
import AddIcon from "@material-ui/icons/Add";
import { IAppState } from "redux/store/Store";
import Resource, { resource } from "../../Resources/Resource";
import { IResultActive } from "redux/types/challenges";
import { useDispatch, useSelector } from "react-redux";
import { securityTextEditorWithoutStyles } from "utils/securityTextEditor";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import {
  Card,
  Grid,
  CardMedia,
  Box,
  Button,
  useTheme,
  MenuItem,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import {
  getAllChallenges,
  patchHighlightChallengeAction,
  putEndSubmissionAction,
} from "redux/actions/ChallengeActions";
import { toast } from "react-toastify";
import { ICultureState } from "redux/reducers/cultureReducer";

const useStyles = makeStyles(() =>
  createStyles({
    truncate: {
      fontSize: "12px",
      textAlign: "left",
      padding: 0,
      margin: "26px 18px 10px 22px",
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

interface IProps {
  challenge: IResultActive;
  culture: ICultureState
}

function ActiveCard(props: IProps) {
  const { challenge, culture } = props;
  const classes = useStyles();
  const theme = useTheme();
  const ideasLength = challenge.ideasSubmitedCount;
  const backURL = "/challenges/details";

  const dispatch = useDispatch();
  const store = useSelector((store) => store) as IAppState;
  const permission = store.authState.auth.permission;
  
  const dispatchSetHighlight = async () => {
    await dispatch(
      patchHighlightChallengeAction({
        challengeId: challenge.challengeId,
        workspaceId: store.authState.auth.workspaceId,
        isHighlighted: true,
        culture
      })
    );
  };

  const dispatchEndSubmition = async () => {
    await dispatch(
      putEndSubmissionAction({
        challengeId: challenge.challengeId,
        workspaceId: store.authState.auth.workspaceId,
      })
    );
  };

  const setHighlight = async () => {
    dispatchSetHighlight().then(() => {
      toast.success(resource(culture, "PagePrizes::Highlighted"))
      dispatch(
        getAllChallenges({
          challengeId: challenge.challengeId,
          workspaceId: store.authState.auth.workspaceId,
          cultureTag: store.cultureState.culture.cultureTag,
        })
      );
    });
  };

  const endSubmition = async () => {
    dispatchEndSubmition().then(() => {
      dispatch(
        getAllChallenges({
          challengeId: challenge.challengeId,
          workspaceId: store.authState.auth.workspaceId,
          cultureTag: store.cultureState.culture.cultureTag,
        })
      );
    });
  };

  const duplicateChallenge = async () => {
    const response = await api_general.post(`challenges/duplicate`, {
      challengeId: challenge.challengeId,
      workspaceId: store.authState.auth.workspaceId,
    });

    if (response?.data?.data) {
      history.push(`/createChallenge/${response?.data?.data}`);
    }
  };

  return (
    <Card className="activeCard-container">
      <Grid container className="activeCard-content">
        <Grid item sm={12} md={6}>
          <Link
            id={challenge?.challengeId}
            style={{
              color: "white",
              cursor: "pointer",
              textDecoration: "none",
            }}
            to={{
              pathname: `${backURL}/${challenge.challengeId}`,
              state: { challenge: challenge },
            }}
          >
            <CardMedia
              className="activeCard-bgImage"
              style={{ backgroundImage: `url(${challenge?.image})` }}
            >
              <Box className="activeCard-info-l">
                <Box className="activeCard-title-and-numIdeas-grid">
                  <Box className="activeCard-title">
                    <h1>{challenge?.title}</h1>
                  </Box>
                  <Box className="activeCard-ideas-quantity">
                    <object>
                      <Link
                        id={`idea${challenge?.challengeId}`}
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        to={{
                          pathname: `idea`,
                          state: { challengeId: challenge?.challengeId },
                        }}
                      >
                        <p>
                          <EMIcon color="#000" icon="section-ideas" size={18} />
                          {ideasLength === 1 ? (
                            <Resource
                              tag="PageChallenges::Challenge_Idea"
                              args={[ideasLength ? ideasLength : 0]}
                            />
                          ) : (
                            <Resource
                              tag="PageChallenges::Challenge_Ideas"
                              args={[ideasLength ? ideasLength : 0]}
                            />
                          )}
                        </p>
                      </Link>
                    </object>
                  </Box>
                </Box>
                <Box className="activeCard-challenge-time">
                  {challenge?.allowSubmitIdea ? (
                    <p>
                      <Resource
                        tag={"PageChallenges::Challenge_TimeIdeaSubmit"}
                        args={[""]}
                      />
                    </p>
                  ) : (
                    <p>
                      <Resource
                        tag={"PageChallenges::Challenge_TimeIdeaEvaluate"}
                        args={[""]}
                      />
                    </p>
                  )}
                  <strong>
                      <Resource
                        tag={challenge?.timeValue1Resource.replace(".", "::")}
                        args={[challenge?.timeValue1]}
                      />
                    &nbsp;
                      <Resource
                        tag={challenge?.timeValue2Resource.replace(".", "::")}
                        args={[challenge?.timeValue2]}
                      />
                  </strong>
                </Box>
              </Box>
            </CardMedia>
          </Link>
        </Grid>
        <Grid item sm={12} md={6} style={{ position: "relative" }}>
          {newApp && (permission === "Admin" || permission === "ChallengeManager") && (
            <Box
              style={{
                position: "absolute",
                right: "3px",
                top: "1px",
                padding: 6,
              }}
            >
              <MoreOptionsButton translate="-170px, 42px">
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push(`createChallenge/${challenge.challengeId}`);
                  }}
                >
                  <Resource tag="PageChallenges::EditChallenge" />
                </MenuItem>
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setHighlight();
                  }}
                >
                  <Resource tag="PageChallenges::Highlight" />
                </MenuItem>
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    duplicateChallenge();
                  }}
                >
                  <Resource tag="PageChallenges::Duplicate" />
                </MenuItem>
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    endSubmition();
                  }}
                >
                  <Resource tag="PageChallenges::EndSubmissionPeriod" />
                </MenuItem>
              </MoreOptionsButton>
            </Box>
          )}
          <Box className="activeCard-info-r">
            {!newApp && (
              <Box
                className="activeCard-description"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    `${challenge.description}`,
                    securityTextEditorWithoutStyles
                  ),
                }}
              />
            )}
            {newApp && (
              <Box
                className={classes.truncate}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(
                    `${challenge.description}`,
                    securityTextEditorWithoutStyles
                  ),
                }}
              />
            )}
            <Box className="activeCard-link-and-buttons">
              <Box className="activeCard-read-more">
                <Link
                  style={{
                    cursor: "pointer",
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                  }}
                  to={{
                    pathname: `${backURL}/${challenge.challengeId}`,
                    state: { challenge: challenge },
                  }}
                >
                  <Button color="primary" style={{ fontSize: 12, left: -6 }}>
                    <Resource tag={"PageChallenges::Menu_More"} />
                  </Button>
                </Link>
              </Box>
              <Box className="activeCard-buttons">
                {challenge?.hasIdeasToEvaluate && (
                  <Box className="activeCard-btn-evaluate-ideas">
                    <Link
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      to={{
                        pathname: `idea`,
                        state: { challengeId: challenge.challengeId },
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        <Resource
                          tag={"PageChallenges::Challenge_EvaluateIdeas"}
                        />
                      </Button>
                    </Link>
                  </Box>
                )}
                {challenge?.allowSubmitIdea && (
                  <Box className="activeCard-btn-new-ideas">
                    <Link
                      style={{ cursor: "pointer", textDecoration: "none" }}
                      to={`/createIdea/${challenge.challengeId}`}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        startIcon={<AddIcon />}
                        size="small"
                        style={{ color: theme.palette.common.white }}
                      >
                        <Resource
                          tag={"PageChallenges::Challenge_ShareNewIdeas"}
                        />
                      </Button>
                    </Link>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ActiveCard;
