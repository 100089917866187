import XDialog from "common/X/XDialog";
import { Box, Button } from "@material-ui/core";
import Resource, { resource } from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  draftTitle: string;
  noCancelButton?: any;
  yesButton?: any;
  culture: ICultureState;
}

function DeleteDraftDialog({
  open,
  setOpen,
  draftTitle,
  noCancelButton,
  yesButton,
  culture
}: IProps) {
  const actions = (
    <Box textAlign="right">
      <Button
        color="primary"
        size="small"
        variant="outlined"
        style={{ marginRight: 8 }}
        onClick={noCancelButton}
      >
        <Resource tag="PageManageGroups::NoCancel" />
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={yesButton}
      >
        <Resource tag="PageManageGroups::Yes" />
      </Button>
    </Box>
  );
  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      title={`${resource(culture, "PageIdeaDraft::DeleteDraft")}`}
      maxWidth="xs"
      actions={actions}
    >
      <Box textAlign="center">
        <Resource
          tag="Common::ConfirmDeleteDraft"
          args={[`${draftTitle}`]}
        />
      </Box>
    </XDialog>
  );
}

export default DeleteDraftDialog;
