// import "./AcceptTerms.scss";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import history from "../../routes/history";
import { ThunkDispatch } from "redux-thunk";
import { treatError } from "utils/treatError";
import { ArrowForward } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Resource from "../../components/Resources/Resource";
import { IAppState, store } from "../../redux/store/Store";
import { IApplication } from "../../redux/reducers/tenantReducer";
import { IProfileData } from "../../redux/reducers/profileReducer";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Link,
  useTheme,
} from "@material-ui/core";
import {
  ICultureSetCulture,
  setCultureAndResources,
} from "redux/actions/CultureActions";
import {
  IProfileGetAllAction,
  IProfileGetDataAction,
  IProfileUpdateAction,
  getProfile,
  getProfileData,
  updateProfile,
} from "../../redux/actions/ProfileActions";

interface IDispatchProps {
  getProfileData: () => Promise<IProfileGetDataAction>;
  updateProfile: (
    params: object,
    options: object
  ) => Promise<IProfileUpdateAction>;
  getProfile: (params: object) => Promise<IProfileGetAllAction>;
}

interface IProps {
  profileData: IProfileData;
  application: IApplication;
  cultureState: ICultureState;
  loadingSubmit: Boolean;
  updateSuccess: Boolean;
  workspaceId: string;
  timezone: string;
}

function AcceptTerms(props: IProps & IDispatchProps) {
  const theme = useTheme();
  const { getProfileData, profileData, cultureState, workspaceId } = props;
  const [cultureTag] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [timezone, setTimezone] = useState("00000000-0000-0000-0000-000000000000");

  function getResourcesHandler(cultureTag: string) {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store?.dispatch;
    dispatch(setCultureAndResources(cultureTag));
    return store?.getState().cultureState;
  }

  useEffect(() => {
    profileData?.cultureTag === ""
      ? getResourcesHandler("en-GB")
      : getResourcesHandler(profileData?.cultureTag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;
    isMounted &&
      getProfileData().then((data:any) => {
        setTimezone(data.timezone);
        isMounted && setLoading(false);
      });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e: any) => {
    setLoadingSubmit(true);
    e.preventDefault();
    const { updateProfile, getProfileData, cultureState } = props;

    let customFieldsValues = {};

    let newValues = {
      agreedTermsPolicy: true,
      agreedRegulations: true,
      workspaceId: workspaceId,
      timezone: timezone
    };

    if (cultureTag === "") {
      newValues = {
        ...newValues,
        ...{ cultureTag: props?.cultureState?.culture?.cultureTag },
      };
    } else {
      newValues = { ...newValues, ...{ cultureTag } };
    }

    const params = {
      ...newValues,
      customFields: { ...customFieldsValues },
    };

    updateProfile(params, { cultureState }).then(
      (response) => {
        getProfileData().then(() => {
          // getProfile(options);
          setLoadingSubmit(false);

          if (response?.updateSuccess) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (
              props?.profileData?.agreedRegulationVersion !== null &&
              props?.profileData?.agreedRegulations === ""
            ) {
              history?.push("/acceptRegulations");
            } else {
              !profileData?.agreedTermsAndPolicyDate && history?.push("/");
            }
            
          }
        });
      },
      (err) => {
        setLoading(false);
        treatError(props?.cultureState?.culture, "Profile", err);
      }
    );
  };

  useEffect(() => {
    getProfileData().then(() => {
      setLoadingSubmit(false);

      if (profileData?.agreedTermsAndPolicyDate !== "") {
        history?.push("/");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData?.agreedTermsAndPolicyDate]);

  return (
    <>
      {cultureState?.statusCulture === "SUCCEEDED" ? (
        loading ? (
          <>
            <Box display="flex" margin="0px auto" height="80vh">
              <Box m="auto">
                <CircularProgress size={60} className="icon" />
              </Box>
            </Box>
          </>
        ) : (
          <form name="form" onSubmit={onSubmit}>
            <Box pl={1} ml={1} mt={5}>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                <Resource tag={"PageLogin::ReviewTheTerms"} />
              </Typography>
            </Box>
            <Box pl={1} ml={1} mt={6} display="flex" flexWrap="wrap">
              <Box component="p" mr={1} color={theme.palette.text.secondary}>
                <Resource tag="PageLogin::ClickToAgree" />
              </Box>
              <Box component="p" mr={1} style={{ whiteSpace: "nowrap" }}>
                <Link href={profileData?.urlTermsOfService} target="_blank">
                  <Resource tag="PageLogin::Terms" />{" "}
                  {profileData?.agreedRegulationVersion !== null && `,`}
                </Link>
              </Box>
              {profileData?.agreedRegulationVersion === null && (
                <Box
                  component="p"
                  mr={1}
                  color={theme.palette.text.secondary}
                  style={{ whiteSpace: "nowrap" }}
                >
                  <Resource tag="PageLogin::And" />
                </Box>
              )}
              <Box component="p" mr={1} style={{ whiteSpace: "nowrap" }}>
                <Link href={profileData?.urlPrivacyPolicy} target="_blank">
                  <Resource tag="PageLogin::PrivacyPolicy" />
                </Link>
              </Box>
              {profileData?.agreedRegulationVersion !== null && (
                <>
                  <Box
                    component="p"
                    mr={1}
                    color={theme.palette.text.secondary}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <Resource tag="PageLogin::And" />
                  </Box>
                  <Box component="p" mr={1} style={{ whiteSpace: "nowrap" }}>
                    <Link
                      component={RouterLink}
                      to="/regulations"
                      target="_blank"
                    >
                      <Resource tag="PageLogin::Regulation" />.
                    </Link>
                  </Box>
                </>
              )}
            </Box>
            <Box display="flex" flexDirection="row-reverse" mt={10}>
              <Box>
                <Button
                  type="submit"
                  variant={!loadingSubmit ? "contained" : "outlined"}
                  color="primary"
                  className="btn-login"
                  endIcon={
                    !loadingSubmit ? (
                      <ArrowForward className="icon icon-arrow" />
                    ) : (
                      <CircularProgress size={22} className="icon" />
                    )
                  }
                >
                  <Resource tag={"PageLogin::IAgree"} />
                </Button>
              </Box>
            </Box>
          </form>
        )
      ) : (
        <>
          <Box display="flex" margin="0px auto" height="80vh">
            <Box m="auto">
              <CircularProgress size={60} className="icon" />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    application: store.tenantState.application,
    cultureState: store.cultureState,
    profileData: store.profileState.profileData,
    countries: store.usersState.countries,
    updateSuccess: store.profileState.updateSuccess,
    workspaceId: store.authState.auth.workspaceId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getProfileData: () => dispatch(getProfileData()),
    updateProfile: (params: object, options: object) =>
      dispatch(updateProfile(params, options)),
    getProfile: (params: object) => dispatch(getProfile(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTerms);
