import { ResourcesActionTypes, IResourcesAction } from "../actions/ResourcesAction";
import { Reducer } from 'redux';

export interface IResourcesState {
  readonly resources: [];
}

// Define the initial state
const initialState: any = {
  resources: []
}

export const resourcesReducer: Reducer<any, IResourcesAction> =
  (state = initialState, action) => {
    switch (action.type) {
      case ResourcesActionTypes.RESOURCES: {
        return {
          ...state,
          resources: action.payload
        }
      }
      default:
        return state;
    }
  }