import React from "react";
import "./InvestmentItem.scss";
import Link from "@material-ui/core/Link";
import MiniLine from "../MiniLine/MiniLine";
import NumberFormat from "react-number-format";
import Resource from "components/Resources/Resource";
import { Link as RouterLink } from "react-router-dom";
import { IResultWalletInvestments } from "redux/reducers/walletReducer";
import { TableRow, TableCell, Typography, Box } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { IResultMyActivitiesInvestmentsActive } from "../../redux/types/myActivities";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      borderBottom: "solid 1px #ccc",
    },
    tableCell: {
      padding: "16px 10px 16px 0px!important",
    },
    linkItem: {
      textDecoration: "none",
      color: "#333",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      width: "100%",
    },
    truncate: {
      overflow: "hidden",
      "text-overflow": "ellipsis",
      "max-width": "300px",
      [theme.breakpoints.down("xs")]: {
        "max-width": "215px",
      },
    },
    NumberInvestedAmount: {
      display: "-webkit-box",
      "-webkit-box-orient": "vertical",
      width: "100%",
    },
    date: {
      textTransform: "lowercase",
      "&:first-line": {
        textTransform: "capitalize",
      },
    },
    title: {
      fontWeight: "bold",
    },
    miniLineZero: {
      color: "#609BBD",
    },
    miniLinePositive: {
      color: "#13C71E",
    },
    miniLineNegative: {
      color: "#db1f28",
    },
  })
);

interface IProps {
  investments?: {
    ideaId: string;
    ideaTitle: string;
    currentInvestmentValue: number;
    investmentDate: Date;
    invested: number;
  };
  currentValue?: any;
  investedValue?: any;
  points?: any;
  activeInvestments?: IResultMyActivitiesInvestmentsActive;
  data: number[];
  walletInvestment?: IResultWalletInvestments;
}

function InvestmentItems(props: IProps) {
  const { investments, data, activeInvestments, walletInvestment } = props;
  const ideaBackURL = "/idea/details";
  const classes = useStyles();

  return (
    <TableRow className={classes.tableRow}>
      {investments && (
        <TableCell>
          <Link
            className={classes.linkItem}
            component={RouterLink}
            to={{
              pathname: `${ideaBackURL}/${investments?.ideaId}`,
            }}
          >
            <Typography variant="body1" className={classes.title}>
              {investments?.ideaTitle}
            </Typography>
          </Link>
        </TableCell>
      )}
      {walletInvestment && (
        <TableCell>
          <Link
            className={classes.linkItem}
            component={RouterLink}
            to={{
              pathname: `${ideaBackURL}/${walletInvestment?.ideaId}`,
            }}
          >
            <Typography variant="body1" className={classes.title}>
              {walletInvestment?.ideaTitle}
            </Typography>
          </Link>
        </TableCell>
      )}
      {activeInvestments && (
        <TableCell className={classes.tableCell}>
          <Link
            className={classes.linkItem}
            component={RouterLink}
            to={{
              pathname: `${ideaBackURL}/${activeInvestments?.ideaId}`,
            }}
          >
            <strong>{activeInvestments?.ideaTitle}</strong>
          </Link>
          <Typography variant="caption" className={classes.date}>
            <Resource
              tag="PageMyActivity::Investments_InvestmentDate"
              args={[activeInvestments?.lastInvestmentDate]}
            />
          </Typography>
        </TableCell>
      )}
      {investments && (
        <TableCell>
          <Typography variant="body1">
            <NumberFormat
              value={investments?.invested}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
        </TableCell>
      )}
      {walletInvestment && (
        <TableCell>
          <Typography variant="body1">
            <NumberFormat
              value={walletInvestment?.invested}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
        </TableCell>
      )}
      {activeInvestments && (
        <TableCell className={classes.tableCell}>
          <Typography variant="body1">
            <NumberFormat
              className={classes.NumberInvestedAmount}
              value={activeInvestments?.investedAmount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
          <Typography variant="body1">{activeInvestments.ideaState}</Typography>
        </TableCell>
      )}
      {activeInvestments && (
        <TableCell className={classes.tableCell}>
          <NumberFormat
            value={activeInvestments?.investmentVariation}
            displayType={"text"}
            thousandSeparator={true}
          />
        </TableCell>
      )}

      {investments && (
        <TableCell>
          <b>
            <NumberFormat
              value={investments?.currentInvestmentValue}
              displayType={"text"}
              thousandSeparator={true}
            />
          </b>
        </TableCell>
      )}
      {walletInvestment && (
        <TableCell>
          <Box>
            <Box fontWeight="bold">
              <NumberFormat
                value={walletInvestment?.currentInvestmentValue}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Box>
            <Box
              className={
                props?.currentValue === props?.investedValue
                  ? classes.miniLineZero
                  : props?.currentValue > props?.investedValue
                  ? classes.miniLinePositive
                  : classes.miniLineNegative
              }
            >
              <NumberFormat
                value={props?.points && Math.round(props?.points)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={
                  props?.currentValue === props?.investedValue
                    ? ""
                    : props?.currentValue > props?.investedValue
                    ? "+"
                    : ""
                }
              />
            </Box>
          </Box>
        </TableCell>
      )}
      <TableCell align="center" className={classes.tableCell}>
        <MiniLine
          currentValue={props?.currentValue}
          investedValue={props?.investedValue}
          points={props?.points}
          data={data}
          height={80}
          showPoints={false}
        />
      </TableCell>
    </TableRow>
  );
}

export default InvestmentItems;
