import { useState , useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IAppState } from '../../redux/store/Store';

import { Box, makeStyles, createStyles, Theme,AppBar, Tab } from '@material-ui/core';
import Resource from 'components/Resources/Resource';
import { IProfileData } from 'redux/reducers/profileReducer';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Reports from './Reports/Reports';
import Dashboards from './Dashboards/Dashboards';

import {
  getAllManageGroups
} from "redux/actions/manageGroups/manageGroupsAction";
import { ICultureState } from 'redux/reducers/cultureReducer';
import { IChallengeState } from 'redux/reducers/challenges/challengeReducer';
import { getAllChallenges } from 'redux/actions/ChallengeActions';
import { IApplication } from 'redux/reducers/tenantReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      padding: "24px 0"
    },
  })
);

const pagination = {
  current: 1,
  pageSize: 10,
  finished: false
};

const status = {
  init: true,
  refreshControl: false,
  isActive: true
};

const options = {
  applicationId: 2,
  isActive: status.isActive,
  pageNumber: status.init || !status.isActive ? 1 : pagination.current + 1,
  pageSize: pagination.pageSize,
  culture: {}
};

interface IDispatchProps {
  getAllManageGroups: (options: object) => Promise<any>;
  getChallenges: (options: object) => Promise<IChallengeState>;
}

interface IStateProps {
  profile: IProfileData;
  culture: ICultureState;
  workspaceId: string;
  application: IApplication;
}

const Analytics = (store: IStateProps & IDispatchProps) => {

  const {
    getAllManageGroups,
    workspaceId,
    culture,
    getChallenges
  } = store;

  const classes = useStyles();

  const [tab, setTab] = useState('1');

  const handleChangeTab = (event: any, newValue: string) => {
    setTab(newValue);
  }
  
  useEffect(() => {
    options.applicationId = store.application.applicationId;
    options.culture = store.culture;

    getAllManageGroups({
      workspaceId,
      cultureTag: culture.culture.cultureTag,
    });
    getChallenges(options);

  }, [culture.culture.cultureTag, getAllManageGroups, getChallenges, store.application.applicationId, store.culture, workspaceId]);

  return (
    <Box>
      <TabContext value={tab}>
        <AppBar position="static" elevation={0}>
          <TabList onChange={handleChangeTab}>
            <Tab label={<Resource tag={"PageAnalytics::Tab_Dashboard"} />} value="1" />
            <Tab label={<Resource tag={"PageAnalytics::Tab_Reports"} />} value="2" />
          </TabList>
        </AppBar>
        <TabPanel className={classes.tabPanel} value="1">
          <Dashboards />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="2">
          <Reports />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => {
  return {
    getChallenges: (parameters: object) => dispatch(getAllChallenges(parameters)),
    getAllManageGroups: (options: object) =>
      dispatch(getAllManageGroups(options)),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    profile: store.profileState.profileData,
    culture: store.cultureState,
    workspaceId: store.authState.auth.workspaceId,
    application: store.tenantState.application,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);