export interface IRegulationsHistory {
  publishDate: string;
  version: string;
  cultureTag: string;
  title: string;
  description: string;
  isDraft: boolean;
}

export interface IRegulationsPublish {
  publishDate: string;
  version: string;
  cultureTag: string;
  title: string;
  description: string;
  isDraft: boolean;
}

export enum RegulationsActionTypes {
  GET_REGULATIONS_HISTORY_LOADING = "GET_REGULATIONS_HISTORY_LOADING",
  GET_REGULATIONS_HISTORY_SUCCESS = "GET_REGULATIONS_HISTORY_SUCCESS",
  GET_REGULATIONS_HISTORY_FAIL = "GET_REGULATIONS_HISTORY_HISTORY_FAIL",

  GET_REGULATIONS_PUBLISH_LOADING = "GET_REGULATIONS_PUBLISH_LOADING",
  GET_REGULATIONS_PUBLISH_SUCCESS = "GET_REGULATIONS_PUBLISH_SUCCESS",
  GET_REGULATIONS_PUBLISH_FAIL = "GET_REGULATIONS_PUBLISH_HISTORY_FAIL",

  POST_REGULATIONS_LOADING = "POST_REGULATIONS_LOADING",
  POST_REGULATIONS_SUCCESS = "POST_REGULATIONS_SUCCESS",
  POST_REGULATIONS_FAIL = "POST_REGULATIONS_FAIL",

  PUT_REGULATIONS_LOADING = "PUT_REGULATIONS_LOADING",
  PUT_REGULATIONS_SUCCESS = "PUT_REGULATIONS_SUCCESS",
  PUT_REGULATIONS_FAIL = "PUT_REGULATIONS_FAIL",
}

export enum RegulationsStatusTypes {
  REGULATIONS_LOADING = "LOADING",
  REGULATIONS_SUCCESS = "SUCCESS",
  REGULATIONS_FAIL = "FAIL",
}

export interface IRegulationsHistoryGetLoadingAction {
  type: RegulationsActionTypes.GET_REGULATIONS_HISTORY_LOADING;
  regulationsStatus: string;
}

export interface IRegulationsHistoryGetSuccessAction {
  type: RegulationsActionTypes.GET_REGULATIONS_HISTORY_SUCCESS;
  regulationsStatus: string;
  regulationsHistory: IRegulationsHistory[];
}

export interface IRegulationsHistoryGetFailAction {
  type: RegulationsActionTypes.GET_REGULATIONS_HISTORY_FAIL;
  regulationsStatus: string;
  error: any;
}

export interface IRegulationsPublishGetLoadingAction {
  type: RegulationsActionTypes.GET_REGULATIONS_PUBLISH_LOADING;
  regulationsStatus: string;
}

export interface IRegulationsPublishGetSuccessAction {
  type: RegulationsActionTypes.GET_REGULATIONS_PUBLISH_SUCCESS;
  regulationsStatus: string;
  regulationsPublish: IRegulationsPublish;
}

export interface IRegulationsPublishGetFailAction {
  type: RegulationsActionTypes.GET_REGULATIONS_PUBLISH_FAIL;
  regulationsStatus: string;
  error: any;
}

export interface IRegulationsPostLoadingAction {
  type: RegulationsActionTypes.POST_REGULATIONS_LOADING;
  regulationsPostStatus: string;
}

export interface IRegulationsPostSuccessAction {
  type: RegulationsActionTypes.POST_REGULATIONS_SUCCESS;
  regulationsPostStatus: string;
  regulationsHistory: IRegulationsHistory[];
  regulationsPublish: IRegulationsPublish;
}

export interface IRegulationsPostFailAction {
  type: RegulationsActionTypes.POST_REGULATIONS_FAIL;
  regulationsPostStatus: string;
  error: any;
}

export interface IRegulationsPutLoadingAction {
  type: RegulationsActionTypes.PUT_REGULATIONS_LOADING;
  regulationsPutStatus: string;
}

export interface IRegulationsPutSuccessAction {
  type: RegulationsActionTypes.PUT_REGULATIONS_SUCCESS;
  regulationsPutStatus: string;
  regulationsHistory: IRegulationsHistory[];
}

export interface IRegulationsPutFailAction {
  type: RegulationsActionTypes.PUT_REGULATIONS_FAIL;
  regulationsPutStatus: string;
  error: any;
}

export type RegulationsActions =
  | IRegulationsHistoryGetLoadingAction
  | IRegulationsHistoryGetSuccessAction
  | IRegulationsHistoryGetFailAction
  | IRegulationsPublishGetLoadingAction
  | IRegulationsPublishGetSuccessAction
  | IRegulationsPublishGetFailAction
  | IRegulationsPostLoadingAction
  | IRegulationsPostSuccessAction
  | IRegulationsPostFailAction
  | IRegulationsPutLoadingAction
  | IRegulationsPutSuccessAction
  | IRegulationsPutFailAction;

export interface IRegulationsState {
  readonly regulationsHistory: IRegulationsHistory[];
  readonly regulationsPublish: IRegulationsPublish;
  readonly regulationsStatus: string;
  readonly regulationsPostStatus: string;
  readonly regulationsPutStatus: string;
}
