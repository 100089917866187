import { AnyAction } from "redux";
import { connect } from "react-redux";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import CloseIcon from "@material-ui/icons/Close";
import { ArrowForward } from "@material-ui/icons";
import Resource from "components/Resources/Resource";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { useCallback, useRef, useState, Dispatch, SetStateAction, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Button,
  useTheme,
  IconButton,
  Dialog,
  DialogContent,
  Slider,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import {
  getAllWorkspaces,
  getWorkspace,
  getImagesWorkspaces,
  putWorkspacesImages,
} from "redux/actions/workspaceAction";
import {
  IWorkspaceReducer,
  IWorkspaceState,
} from "redux/reducers/workspaceReducer";
import ToolTip from "components/ToolTip/ToolTip";
interface IDispatchProps {
  getAllWorkspace: (options: object) => Promise<IWorkspaceState>;
  getWorkspace: (options: object) => Promise<IWorkspaceState>;
  getImagesWorkspace: (options: object) => Promise<IWorkspaceState>;
  putWorkspacesImage: (options: object) => Promise<IWorkspaceState>;
}

interface IProps {
  setCurrentScreen: Dispatch<SetStateAction<number>>
}

interface IStateProps {
  workspaces: IWorkspaceReducer;
  application: IApplication;
  culture: ICultureState;
  istab?: boolean
}

const options = {
  applicationId: 2,
  culture: {},
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setWorkspaceTitle: {
      fontSize: "22px",
      textAlign: "left",
      color: theme.palette.text.primary,
      fontWeight: 700,
      marginBottom: theme.spacing(3),
    },
    sendLogo: {
      display: "inline-flex",
      alignItems: "center",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      width: "fit-content",
      padding: theme.spacing(2),
      cursor: "pointer",
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      whiteSpace: "nowrap",
      "&.active": {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[500],
        cursor: "initial",
      },
    },
    logoImgContainer: {
      boxShadow: "0 0 3px 0 " + theme.palette.primary.main,
      borderRadius: 5,
      marginTop: theme.spacing(1),
      flexBasis: "auto",
      flexGrow: 1,
      display: "flex",
      paddingLeft: theme.spacing(3),
      position: "relative",
    },
    logoImg: {
      width: 90,
      height: 70,
    },
    closeButton: {
      position: "absolute",
      left: "100%",
      top: "50%",
      color: theme.palette.grey[700],
      transform: "translate(-50%,-50%)",
      border: `8px solid white`,
      borderLeft: 0,
      borderRight: 0,
      borderRadius: 0,
      backgroundColor: theme.palette.grey[200],
      padding: 0,
      "&:hover": {
        opacity: 1,
        backgroundColor: theme.palette.grey[300],
      },
    },
    closeButtonLoginImg: {
      position: "absolute",
      left: "100%",
      top: "0",
      color: theme.palette.grey[700],
      transform: "translate(-50%,-50%)",
      borderRadius: 3,
      backgroundColor: theme.palette.grey[200],
      padding: 0,

      "&:hover": {
        opacity: 1,
        backgroundColor: theme.palette.grey[300],
      },
    },
    neonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0 0 3px 0 " + theme.palette.primary.main,
      padding: 6,
      position: "relative",
      borderRadius: "5px",

      "& > div": {
        display: "inherit",
      },
    },
    neonImage: {
      width: 100,
      height: 115,
      borderRadius: "5px",
    },
    cropperContainer: {
      position: "relative",
      width: "100%",
      height: 400,
      background: "#fff",
      [theme.breakpoints.up("sm")]: {
        height: 500,
      },
    },
    sliderContainer: {
      display: "flex",
      flex: "1",
      alignItems: "center",
      justifyContent: "center",
    },
    sliderLabel: {
      [theme.breakpoints.down("xs")]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: "22px 0px",
      marginLeft: 16,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
        margin: "0 16px",
      },
    },
  })
);

function StepWorkspaceImages(props: IDispatchProps & IStateProps & IProps) {
  const {
    getAllWorkspace,
    getWorkspace,
    getImagesWorkspace,
    putWorkspacesImage,
    setCurrentScreen,
    workspaces,
    istab
  } = props;

  const returnRandomString = () => Math.random().toString(36);
  const classes = useStyles();
  const theme = useTheme();
  const logoRef = useRef<HTMLInputElement>(null);
  const loginImgRef = useRef<HTMLInputElement>(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<any>(1);
  const [open, setOpen] = useState(false);

  const [logo, setLogo] = useState<any>();
  const [loginImg, setLoginImg] = useState<any>();

  const [croppedImgLogo, setCroppedImgLogo] = useState();
  const [croppedImgLogin, setCroppedImgLogin] = useState();
  const [cropMode, setCropMode] = useState<"logo" | "login">();

  const [logoKey, setLogoKey] = useState(returnRandomString());
  const [loginImgKey, setLoginImgKey] = useState(returnRandomString());

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Object>({});
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    options.culture = props.culture;

    if (!istab) {
      getAllWorkspace(options).then(() => {
        setLoading(false);
      });

      getImagesWorkspace(options).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const img = cropMode === "logo" ? logo : loginImg;
      const croppedImage: any = await getCroppedImg(img, croppedAreaPixels);
      cropMode === "logo"
        ? setCroppedImgLogo(croppedImage)
        : setCroppedImgLogin(croppedImage);
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [croppedAreaPixels]);

  const handleLogoClick = () => logoRef.current && logoRef.current.click();
  const handleLoginImgClick = () =>
    loginImgRef.current && loginImgRef.current.click();

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleLogoChange = (e: any) => {
    const file = e.target.files[0];
    if (file)
      toBase64(file).then((data) => {
        setCropMode("logo");
        setLogo(data);
        setOpen(true);
      });
  };

  const handleLogoClose = () => {
    setLogoKey(returnRandomString());
    setLogo(null);
    setCroppedImgLogo(undefined);
    setOpen(false);
  };

  const handleLoginImgClose = () => {
    setLoginImgKey(returnRandomString());
    setLoginImg(null);
    setCroppedImgLogin(undefined);
    setOpen(false);
  };

  const handleLoginImgChange = (e: any) => {
    const file = e.target.files[0];
    if (file)
      toBase64(file).then((data) => {
        setCropMode("login");
        setLoginImg(data);
        setOpen(true);
      });
  };

  function putImagesWorkspace() {
    setLoadingSubmit(true);
    let params = {};

    params = {
      workspaceId: workspaces.workspaceId,
      imageLogo: croppedImgLogo,
      imageLogin: croppedImgLogin,
      culture: props.culture,
    };

    const obj = {
      applicationId: props.application.applicationId,
      culture: props.culture,
      workspaceId: props.workspaces.workspaceId,
    };

    putWorkspacesImage(params).then(() => {
      if (!istab) {
        getAllWorkspace(options).then(() => {
          // history.push("/congrats");
          setCurrentScreen((prevState) => prevState + 1);
        });
      } else {
        getWorkspace(obj);
      }
      setLoadingSubmit(false);
    });
  }

  return (
    <Container>
      {loading ? (
        <Box display="flex" mt={26} ml={20}>
          <Box>
            <CircularProgress size={50} className="icon" />
          </Box>
        </Box>
      ) : (
        <>
          <Typography className={classes.setWorkspaceTitle}>
            <Resource tag="PageSetup::LookWorkspace_Question" />
          </Typography>

          <Box textAlign="left">
            <Typography variant="body1" color="textSecondary">
              <Box display="flex" alignItems="center" gridGap="5px">
                <Resource tag="PageSetup::LookWorkspace_Logo" />
                <ToolTip children={<Resource tag="PageSetup::LookWorkspaceLogoToltip" />} />
              </Box>
              {/* LOGO */}
            </Typography>
            <Box display="flex" alignItems="center">
              <Box
                onClick={!croppedImgLogo ? handleLogoClick : () => { }}
                className={classes.sendLogo + (croppedImgLogo ? " active" : "")}
              >
                <CameraAltIcon fontSize={"large"} color="inherit" />
                <Box
                  component="span"
                  ml={1}
                  color={
                    croppedImgLogo
                      ? theme.palette.grey[500]
                      : theme.palette.primary.dark
                  }
                >
                  <Resource tag="PageSetup::LookWorkspace_InsertImage" />
                </Box>
                <input
                  key={logoKey}
                  onChange={handleLogoChange}
                  type="file"
                  id="logo"
                  name="logo"
                  accept="image/*"
                  ref={logoRef}
                  style={{ display: "none" }}
                />
              </Box>
              {croppedImgLogo && (
                <Box className={classes.logoImgContainer}>
                  <img
                    className={classes.logoImg}
                    alt={"logo"}
                    src={croppedImgLogo}
                  />
                  <IconButton
                    // aria-label="close"
                    className={classes.closeButton}
                    onClick={handleLogoClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>

          <Box textAlign="left" alignItems="center" mt={4}>
            <Typography variant="body1" color="textSecondary">
              <Box display="flex" alignItems="center" gridGap="5px">
                <Resource tag="PageSetup::LookWorkspace_LoginImage" />
                <ToolTip children={<Resource tag="PageSetup::LookWorkspaceImageToltip" />} />
              </Box>
              {/* LOGIN IMAGE */}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                onClick={!croppedImgLogin ? handleLoginImgClick : () => { }}
                className={
                  classes.sendLogo + (croppedImgLogin ? " active" : "")
                }
              >
                <CameraAltIcon fontSize={"large"} color="inherit" />
                <Box
                  component="span"
                  ml={1}
                  color={
                    croppedImgLogin
                      ? theme.palette.grey[500]
                      : theme.palette.primary.dark
                  }
                >
                  <Resource tag="PageSetup::LookWorkspace_InsertImage" />
                </Box>
                <input
                  key={loginImgKey}
                  onChange={handleLoginImgChange}
                  type="file"
                  id="loginImage"
                  name="loginImage"
                  accept="image/*"
                  ref={loginImgRef}
                  style={{ display: "none" }}
                />
              </Box>
              {croppedImgLogin && (
                <Box className={classes.neonContainer}>
                  <IconButton
                    // aria-label="close"
                    className={classes.closeButtonLoginImg}
                    onClick={handleLoginImgClose}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Box>
                    <img
                      className={classes.neonImage}
                      alt={"login"}
                      src={croppedImgLogin}
                    ></img>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box textAlign="right" mt={1}>
            <Button
              color="primary"
              variant="text"
              style={{ marginRight: 8 }}
              onClick={() => setCurrentScreen((prevState) => prevState + 1)}
            >
              <Resource tag="PageSetup::SkipStep" />
            </Button>
            <Button
              color="primary"
              variant="contained"
              endIcon={
                !loadingSubmit ? (
                  <ArrowForward className="icon icon-arrow" />
                ) : (
                  <CircularProgress
                    size={22}
                    style={{ color: `${theme.palette.common.white}` }}
                  />
                )
              }
              onClick={() => putImagesWorkspace()}
              disabled={!croppedImgLogo || !croppedImgLogin}
            >
              {istab ?
                <Resource tag="PageSetup::Save" /> :
                <Resource tag="PageSetup::Continue" />
              }
            </Button>
          </Box>

          <Dialog
            onClose={
              cropMode === "logo" ? handleLogoClose : handleLoginImgClose
            }
            maxWidth={"md"}
            fullWidth
            open={open}
          >
            <DialogContent>
              <Box className={classes.cropperContainer}>
                <Cropper
                  image={cropMode === "logo" ? logo : loginImg}
                  crop={crop}
                  zoom={zoom}
                  aspect={cropMode === "logo" ? 4 / 3 : 2 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Box className={classes.sliderContainer}>
                <Box ml={1} mr={5} width="50%">
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </Box>
                <Box textAlign="right">
                  <Button
                    onClick={showCroppedImage}
                    variant="contained"
                    color="primary"
                  >
                    <Resource tag="PageSetup::CutImage" />
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllWorkspace: (options: object) => dispatch(getAllWorkspaces(options)),
    getWorkspace: (option: object) => dispatch(getWorkspace(option)),
    getImagesWorkspace: (options: object) =>
      dispatch(getImagesWorkspaces(options)),
    putWorkspacesImage: (params: object) =>
      dispatch(putWorkspacesImages(params)),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    application: store.tenantState.application,
    culture: store.cultureState,
    workspaces: store.workSpacesState.workspaces,
    // imagesWorkspaces: store.workSpacesState.imagesWorkspaces
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepWorkspaceImages);
