function getAleatoryImage(width:number, height:number){
    return `https://picsum.photos/id/${imagesList()}/${width}/${height}`
}

function imagesList(){
    const possibleImagesId = [0, 1029, 1031, 1048, 1067, 1078, 1076, 1081, 160, 1, 1011];

    var aleatoryId = possibleImagesId[Math.floor(Math.random() * possibleImagesId.length)].toString();

    return aleatoryId;
}

export default getAleatoryImage;
