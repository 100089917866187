import React from "react";
import { useDrop } from "react-dnd";
import { Button, Box, CircularProgress } from "@material-ui/core";
import Resource from "components/Resources/Resource";
import { ItemTypes } from "../Draggable/ButtonIconTypes/cfTypes";

interface IProps {
  onDrop: any;
  loading: boolean;
}

function WorkflowDropableCard({ onDrop, loading }: IProps) {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.BUTTON,
    drop: (item) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return (
    <Box
      minWidth={250}
      height={448}
      borderRadius={11}
      bgcolor="#F2F2F2"
      border={"1px solid #BDBDBD"}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      marginRight={2}
      //ts-ignore to use ref in a mui box,
      //@ts-ignore
      ref={!loading ? drop : null}
    >
      {loading ?
        <Box textAlign="center">
          <CircularProgress />
        </Box>
        :
        <Button
        disabled
        disableRipple
        disableElevation
        disableFocusRipple
        disableTouchRipple
        style={{ border: `2px dashed #BDBDBD` }}
      >
        {isActive ? (
          <Resource tag="PageCreateChallenge::Workflow_ReleaseToDrop" />
        ) : (
          <Resource tag="PageCreateChallenge::Workflow_DragAndDrop" />
        )}
      </Button>
      }
    
    </Box>
  );
}

export default WorkflowDropableCard;
