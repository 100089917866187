import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAuthLoginAction, doLoginSSO } from "redux/actions/AuthAction";
import { getProfileData, IProfileGetDataAction } from "redux/actions/ProfileActions";
import { IAuthUser } from "redux/reducers/authReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IAuthState } from "redux/reducers/authReducer";
import { IProfileData } from "redux/reducers/profileReducer";
import { IAppState } from "redux/store/Store";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Chip, CircularProgress, FormControl, FormHelperText, Input, InputAdornment,  MenuItem, OutlinedInput, Select,  useTheme } from "@material-ui/core";
import axios from "axios";

interface IStateProps {
  cultureState: ICultureState,
  profileData: IProfileData,
  authState: IAuthState
}

interface IProps {
  doLoginSSO: (response: any) => Promise<any>,
  getProfileData: () => Promise<IProfileGetDataAction>;
}

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Exago
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
  }));

  const names = [
    'EN-GB',
    'PT-BR',
    'PT-PT',
    'ES-ES'
  ];

  const envs = [
    'PRD',
    'UAT'
  ];
  
  function getStyles(name: string, cultures: string | any[], theme: { typography: { fontWeightRegular: any; fontWeightMedium: any; }; }) {
    return {
      fontWeight:
        cultures.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
function NewTenant(props: IProps & IStateProps) {
    const classes = useStyles();
    const theme = useTheme();
    const [cultures, setcultures] = React.useState([]);
    const [company, setCompany] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [url, setURL] = React.useState("");
    const [isVisibleButtonHelp, setisVisibleButtonHelp] = React.useState(false);
    const [loading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [environment, setEnvironment] = React.useState("UAT");

    const handleChange = (event:any) => {
        setcultures(event.target.value);
      };

    const handleEnvChange = (event:any) => {
      setEnvironment(event.target.value);
    }; 
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    function activateLasers(){
        var newCultures:any = []
        cultures.forEach(element => {
            switch (element) {
                case "EN-GB":
                    newCultures.push({cultureTag:"en-GB", cultureDescription:"English"})
                    break;
                case "PT-BR":
                    newCultures.push({cultureTag:"pt-BR", cultureDescription:"Portugês"})
                    break;
                case "PT-PT":
                    newCultures.push({cultureTag:"pt-PT", cultureDescription:"Português"})
                    break;
                case "es-ES":
                    newCultures.push({cultureTag:"es-ES", cultureDescription:"Spanish"})
                    break;
                default:
                    break;
            }
        });

        var body = {}
        if(environment == "PRD"){
          body = {
            name:company, 
            endpointFront:url+".exago.com", 
            email:email, 
            endpoint:"https://api.exago.com", 
            cultures:newCultures,
            isVisibleButtonHelp:isVisibleButtonHelp
        }
        }else{
          body = {
              name:company, 
              endpointFront:url+".exago.app", 
              email:email, 
              endpoint:"https://api.exago.app", 
              cultures:newCultures,
              isVisibleButtonHelp:isVisibleButtonHelp
          }
        }

        setIsLoading(true)
        axios.post(process.env.REACT_APP_API_URL+'api/v3/tenantconfigurations', body)
          .then(function (response:any) {
            setError("Ambiente criado, verifique seu e-mail")
          })
          .catch(function (error:any) {
            console.log(error);
            setError("Erro ao criar ambiente")
          });
    }
    function handleCompany(e:any){
        setCompany(e.target.value)
    }
    function handleEmail(e:any){
        setEmail(e.target.value)
    }

    function handleURL(e:any){
        setURL(e.target.value)
    }

    function handleisVisibleButtonHelp(e:any){
        setisVisibleButtonHelp(!isVisibleButtonHelp)
    }



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="company"
                label="Company name"
                name="company"
                autoComplete="company"
                value={company}
                onChange={handleCompany}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={handleEmail}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.form} >
                    <OutlinedInput
                        id="outlined-adornment-weight"
                        endAdornment={<InputAdornment position="end">.exago.app</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                        labelWidth={0}
                        value={url}
                        onChange={handleURL}
                        disabled={loading}
                    />
                    <FormHelperText id="outlined-weight-helper-text">Application URL</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.form}>
                    <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    variant='outlined'
                    value={cultures}
                    onChange={handleChange}
                    input={<Input id="select-multiple-chip" />}
                    disabled={loading}
                    renderValue={(selected: any) => (
                        <div className={classes.chips}>
                        {selected.map((value: any) => (
                            <Chip key={value} label={value} className={classes.chip} />
                        ))}
                        </div>
                    )}
                    >
                    {names.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, cultures, theme)}>
                        {name}
                        </MenuItem>
                    ))}
                    </Select>
                    <FormHelperText id="outlined-weight-helper-text">Languages</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.form}>
                    <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    variant='outlined'
                    value={environment}
                    onChange={handleEnvChange}
                    input={<Input id="select-multiple-chip" />}
                    disabled={loading}
                    >
                    {envs.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, cultures, theme)}>
                        {name}
                        </MenuItem>
                    ))}
                    </Select>
                    <FormHelperText id="outlined-weight-helper-text">Environment</FormHelperText>
                </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="isVisibleButtonHelp" color="primary" disabled={loading} onChange={handleisVisibleButtonHelp}/>}
                label="Show button help?"
              />
            </Grid>
            {!loading &&
                <Button
                    onClick={activateLasers}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Sign Up
                </Button>
            }
            {loading &&
                <CircularProgress size={24} className={classes.buttonProgress} />
            }
            <p>{error}</p>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};


const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IAuthLoginAction>): IProps => {
  return {
    doLoginSSO: (response: IAuthUser) => dispatch(doLoginSSO(response)),
    getProfileData: () => dispatch(getProfileData()),
  };
};

const mapStateToProps = (store: IAppState, ownProps: any): IStateProps => {
  return {
   // activeTenant: store.tenantState.activeTenant,
    authState: store.authState,
    cultureState: store.cultureState,
    profileData: store.profileState.profileData
   // expiredLogin: ownProps?.location?.state?.expiredLogin 
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewTenant);