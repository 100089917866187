import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
  Button,
  DialogActions,
} from "@material-ui/core";
import React from "react";
import "./PopUpConfirmation.scss";
import CloseIcon from "@material-ui/icons/Close";
import Resource from "components/Resources/Resource";

interface IProps {
  closeButton?: any;
  message: any;
  sendButton?: (e: any) => void;
  isOpen: boolean;
  setIsOpen: (on: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialogActions: {
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

function PopUpConfirmation(props: IProps) {
  const { closeButton, sendButton, isOpen, setIsOpen, message } = props;
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={isOpen}
      onClose={(e: any) => {
        setIsOpen(false);
      }}
    >
      <DialogTitle disableTypography>
        <Typography
          color="textPrimary"
          style={{ fontWeight: "bold", padding: "0 16px" }}
        >
          {message}
        </Typography>
        <IconButton
          // aria-label="close"
          className={classes.closeButton}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            closeButton();
          }}
        >
          <Resource tag="Common::No" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            sendButton && sendButton(e);
          }}
          onClickCapture={closeButton}
        >
          <Resource tag="Common::Yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PopUpConfirmation;
