import PrizeBid from "./PrizeBid";
import Truncate from "react-truncate";
import React, { useEffect } from "react";
import Resource from "../Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IResultPrizeActiveAuctionUser } from "../../redux/reducers/prizeReducer";
import {
  Card,
  Paper,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  Box,
  Hidden,
  createStyles,
  Theme,
  Grid,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import newApp from "utils/reactNewApp";
import sanitizeHtml from "sanitize-html";
import { securityTextEditor } from "utils/securityTextEditor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridGap: "10px",
      cursor: 'pointer'
    },
    card: {
      display: "grid",
      position: "relative",
      background: "transparent",
    },
    media: {
      height: 220,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      borderRadius: 6,
      backgroundBlendMode: "color",
      display: "flex",
    },
    body: {
      alignSelf: "baseline",
      textAlign: "start",
    },
    typography: {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "1.5em",
      lineHeight: "normal",
      color: theme.palette.common.white,
      textDecoration: "none",
      fontWeight: 500,
    },
    auctionContent: {
      display: "flex",
      width: "100%",
    },
    colorTime: {
      color: theme.palette.common.white,
      fontSize: "0.9rem",
      marginTop: "8px",
    },
    bidText: {
      padding: "10px 10px 10px 16px",
      fontSize: "0.85rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    currentBid: {
      height: "40px",
    },
    winning: {
      cursor: "default",
      padding: "6px",
      color: theme.palette.primary.main,
      borderRadius: "5px",
      fontSize: "0.7rem",
      fontWeight: 800,
      width: "fit-content",
    },
    newResource: {
      fontWeight: "bold",
    },
  })
);

interface IProps {
  activeAuction: IResultPrizeActiveAuctionUser;
  postBidPrize: (prizeId: string, value: number) => void;
  requestManagePrize: (prizeId: string, type: number) => void;
  onClick?: any;
  index?: number;
  unableBid?: boolean;
  pageLink?: any;
  culture?: ICultureState;
  openGrid?: boolean;
  profile?: string;
  permission: string;
  setOpenCloseAuction: (open: boolean, prizeId: string) => void;
}

function ActiveAuctionPrizeCard(props: IProps) {
  const classes = useStyles();
  const {
    profile,
    activeAuction,
    onClick,
    index,
    unableBid,
    culture,
    openGrid,
    permission,
    postBidPrize,
    requestManagePrize,
    setOpenCloseAuction
  } = props;
  const theme = useTheme();
  const formatPoints = new Intl.NumberFormat(
    culture?.culture?.cultureTag
  ).format(Number(activeAuction?.lastBidValue));

  useEffect(() => {
    const { activeAuction, index, onClick, pageLink } = props;
    if (pageLink?.prizeId === activeAuction?.prizeId) {
      onClick(activeAuction, index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //separating resource and value to resource(args)
  const remaningTimeResource =
    activeAuction?.timeRemainingResource &&
    activeAuction?.timeRemainingResource?.replace(".", "::");
  const motiveDeniedBidResource = activeAuction?.motiveDeniedBid?.replace(".", "::");

  function handleManagePrize(prizeId: string, type: number) {
    requestManagePrize(prizeId, type)
  }

  return (
    <Box
      onClick={() => {
        onClick(activeAuction, index);
      }}
      className={classes.root}
    >
      <Card
        style={{ gridTemplateRows: openGrid ? "1fr 1.2fr" : "" }}
        className={classes.card}
      >
        <Box className={classes.body}>
          <CardMedia
            className={classes.media}
            style={{ backgroundImage: `url(${activeAuction?.image})` }}
          >
            <CardContent className={classes.auctionContent}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
              >
                <Grid container>
                  {activeAuction?.potentialWinners[0]?.userId === profile && (
                    <Grid item xs={11}>
                      <Paper elevation={0} className={classes.winning}>
                        <Resource tag="PagePrizes::Auction_StatusWinning" />
                      </Paper>
                    </Grid>
                  )}
                  {newApp ? (
                    <Grid
                      item
                      container
                      justify="flex-end"
                      xs={
                        activeAuction?.potentialWinners[0]?.userId === profile
                          ? 1
                          : 12
                      }
                    >
                      {permission === 'Admin' || permission === 'ChallengeManager' ? (
                        <>
                          <MoreOptionsButton
                            translate="-80px, 42px"
                            iconColor={theme.palette.primary.contrastText}
                          >
                            <MenuItem onClick={(e) => {e.stopPropagation();handleManagePrize(activeAuction?.prizeId, 1)}}>
                              <Resource tag="PagePrizes::EditPrize" />
                            </MenuItem>
                            <MenuItem onClick={(e) => {e.stopPropagation();handleManagePrize(activeAuction?.prizeId, 2)}}>
                              <Resource tag="PagePrizes::Duplicate" />
                            </MenuItem>
                            {activeAuction.currentQuantity ===
                            activeAuction.quantity ? (
                              <MenuItem onClick={(e) => {e.stopPropagation();handleManagePrize(activeAuction?.prizeId, 3)}}>
                                <Resource tag="PagePrizes::Delete" />
                              </MenuItem>
                            ) : (
                              <MenuItem onClick={(e) => {e.stopPropagation();setOpenCloseAuction(true, activeAuction?.prizeId)}}>
                                <Resource tag="PagePrizes::CloseSale" />
                              </MenuItem>
                            )}
                          </MoreOptionsButton>
                        </>
                      ) : '' }
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                <Box>
                  <Typography className={classes.typography}>
                    <Truncate
                      lines={3}
                      width={270}
                      trimWhitespace
                      ellipsis={<span>...</span>}
                    >
                      {activeAuction?.title}
                    </Truncate>
                  </Typography>
                  <Box
                    className={classes.colorTime}
                  >
                    <Resource tag={remaningTimeResource} args={[activeAuction?.timeRemaining]}/>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </CardMedia>

          {(unableBid === false ||
            unableBid === null ||
            unableBid === undefined) && (
            <Paper className={classes.currentBid}>
              <Typography className={classes.bidText} noWrap>
                <Resource tag={"PagePrizes::Auction_CurrentMinimumBid"} />:
                <Box ml={1} component="span" fontWeight="bold">
                  <Resource
                    tag={"PagePrizes::AvailableForPrizes_Points"}
                    args={[formatPoints]}
                  />
                </Box>
              </Typography>
            </Paper>
          )}
        </Box>
        <Hidden mdUp>
          <Box p={2} display={openGrid ? "flex" : "none"}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box
                className="prize-auctions-description"
                textAlign="left"
                fontSize="14px"
                margin="0 15px 0 15px"
                lineHeight="26px"
                fontWeight="400"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(activeAuction?.description, securityTextEditor),
                }}
              />
              <Box>
                <Box
                  display="flex"
                  textAlign="left"
                  fontSize="12px"
                  style={{ textTransform: "uppercase" }}
                >
                  <Resource tag="PagePrizes::UnitsAuction" />:
                  <Box ml={1} fontWeight="bold">
                    {activeAuction?.quantity}
                  </Box>
                </Box>
                <Box>
                  <PrizeBid
                    cultureTag={culture?.culture?.cultureTag}
                    postBidPrize={postBidPrize}
                    prizeId={activeAuction?.prizeId}
                    lastBidValue={activeAuction?.lastBidValue}
                    userAllowToBid={activeAuction?.userAllowToBid}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    className="prize-auctions-message"
                  >
                    {activeAuction?.motiveDeniedBid !== "" ? (
                      <Resource tag={motiveDeniedBidResource!} />
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Hidden>
      </Card>
    </Box>
  );
}

export default ActiveAuctionPrizeCard;
