import React, { Fragment, useEffect, useState } from "react";

import { IAppState } from "../../redux/store/Store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import ToolTip from "../ToolTip/ToolTip";
import {
  IKeyTasksPositive,
  IKeyTasksNegative,
  IKeyTasksState,
  IKeyTasksSummary,
} from "../../redux/reducers/keyTasks/keyTasksReducer";
import {
  getAllKeyTasks,
  getCorrectionKeyTasks,
  getEvaluationKeyTasks,
  getTransitionKeyTasks,
  getTransitionSummary,
  getValidationKeyTasks,
  postKeyTasksTransitions,
} from "../../redux/actions/keyTasks/KeyTasksAction";
import { IAuthUser } from "../../redux/reducers/authReducer";
import Resource, { resource } from "components/Resources/Resource";
import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import newApp from 'utils/reactNewApp';
import { api_v3 } from "config/api";
import { toast } from "react-toastify";

const options = {
  applicationId: "",
  ideaId: [""],
  stateId: "",
  commentText: "",
  status: 1,
  culture: {} as ICultureState | undefined,
  workspaceId: "",
  phaseId: "",
  phaseIdNext: ""
};

interface IDispatchProps {
  postKeyTasksTransitions: (options: object) => Promise<IKeyTasksState>;
  getTransitionKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getValidationKeyTasks(options: object): Promise<IKeyTasksState>;
  getKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getEvaluationKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getCorrectionKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getTransitionSummary: (options: object) => Promise<IKeyTasksState>;
}

interface IProps {
  closeButton?: any;
  ideaId: string[];
  application?: IAuthUser;
  culture?: ICultureState;
  keyTasksPositive?: IKeyTasksPositive[];
  toAdjust?: boolean;
  keyTasksNegative?: IKeyTasksNegative[];
  title: string;
  error: any;
  postTransitionStatus: string;
  isTransitionOpen: boolean;
  summary: IKeyTasksSummary[];
  secondTab?: string;
  setTransitionOpen: (isOpen: boolean) => void;
  workspaceId: string;
  ideaDetails: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "90%",
      margin: "0 auto",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      color: theme.palette.grey[500],
      margin: "12px 0 3px",
    },
    checkDivider: {
      width: "100%",
      color: theme.palette.grey[500],
      margin: "12px 0",
    },
    checkboxLabel: {
      color: theme.palette.primary.main,
      fontSize: 13,
    },
    textarea: {
      padding: "10px 0",
      border: 0,
      outline: 0,
      width: "-webkit-fill-available",
      fontSize: "14px",
      fontFamily: "Rubik",
      borderBottom: "1px solid" + theme.palette.grey[500],
      maxWidth: 800,
    },
  })
);

function KeyTaskIdeaTransitionPopUp(props: IDispatchProps & IProps) {
  const [commentValue, setcommentValue] = useState("");
  const [status, setstatus] = useState(false);
  const [stateId, setstateId] = useState("");
  const {
    summary,
    secondTab,
    closeButton,
    title,
    keyTasksNegative,
    keyTasksPositive,
    postKeyTasksTransitions,
    application,
    culture,
    ideaId,
    isTransitionOpen,
    ideaDetails,
    setTransitionOpen,
    getKeyTasks,
    getTransitionKeyTasks,
    getCorrectionKeyTasks,
    getEvaluationKeyTasks,
    getValidationKeyTasks,
    getTransitionSummary,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (e: any) => {
    setcommentValue(e.target.value);
  };

  const handleSelect = (event: any) => {
    setstatus(!status);
  };

  const handleSelectCheckBox = (e: any) => {
    setstateId(e.target.value);
  };

  const postTransition = () => {
    let nextPhase = "";

    if (stateId === "lastphase") {

      if (ideaId.length > 0) {
        ideaId.forEach((idea: string, index: number) => {
          api_v3.post(`keytasks/${idea}/choseforimplementation`, {
            Comment: commentValue,
            CommentStatus: status ? 2 : 1,
            WorkspaceId: props.workspaceId
          }).then(async () => {
            if ((index + 1) === ideaId.length) {
              await getKeyTasks({ ...options, pageSize: 6, pageNumber: 1 });

              await getTransitionSummary({ ...options });
              await getValidationKeyTasks({ ...options, pageNumber: 1, pageSize: 10 });
              await getCorrectionKeyTasks({ ...options, pageNumber: 1, pageSize: 10 });
              await getEvaluationKeyTasks({
                ...options,
                pageNumber: 1,
                pageSize: 10,
              }).then(() => {
                setcommentValue("");
                closeButton();
              });
            }
          }).catch(err=>{
            toast.error(err.error_message)
          })
        })
      } else {
        ideaId.forEach((idea: string, index: number) => {
          api_v3.post(`keytasks/${idea}/choseforimplementation`, {
            Comment: commentValue,
            CommentStatus: status ? 2 : 1,
            WorkspaceId: props.workspaceId
          }).then(async () => {
            await getKeyTasks({ ...options, pageSize: 6, pageNumber: 1 });

            await getTransitionSummary({ ...options });
            await getValidationKeyTasks({ ...options, pageNumber: 1, pageSize: 10 });
            await getCorrectionKeyTasks({ ...options, pageNumber: 1, pageSize: 10 });
            await getEvaluationKeyTasks({
              ...options,
              pageNumber: 1,
              pageSize: 10,
            }).then(() => {
              setcommentValue("");
              closeButton();
            });

          }).catch(err=>{
            toast.error(err.error_message)
          })
        })
      }

      return true;
    }

    for (let index = 0; index < ideaDetails?.phases?.length; index++) {
      let phase = ideaDetails?.phases[index];
      if (phase.isActual && ideaDetails?.phases?.length > (index + 1)) {
        nextPhase = ideaDetails?.phases[index + 1].phaseId;
        break;
      } else if (!ideaDetails.phaseId) {
        nextPhase = phase.phaseId;
        break;
      } else {
        nextPhase = "";
      }
    }

    options.commentText = commentValue;
    status ? (options.status = 2) : (options.status = 1);
    application && (options.applicationId = application?.applicationId);
    culture && (options.culture = culture);
    options.ideaId = ideaId;
    options.stateId = stateId;
    options.phaseId = ideaDetails?.phaseId ?? "";
    options.phaseIdNext = nextPhase;
    options.workspaceId = props.workspaceId;
    keyTasksPositive && (options.stateId = newApp ? keyTasksPositive[0]?.phaseId : keyTasksPositive[0]?.stateId);
    keyTasksNegative && (options.stateId = newApp ? keyTasksNegative[0]?.phaseId : keyTasksNegative[0]?.stateId);

    postKeyTasksTransitions(options).then(async () => {
      await getKeyTasks({ ...options, pageSize: 6, pageNumber: 1 });

      await getTransitionSummary({ ...options });
      await getValidationKeyTasks({ ...options, pageNumber: 1, pageSize: 10 });
      await getCorrectionKeyTasks({ ...options, pageNumber: 1, pageSize: 10 });
      await getEvaluationKeyTasks({
        ...options,
        pageNumber: 1,
        pageSize: 10,
      }).then(() => {
        setcommentValue("");
        closeButton();
      });
    });
  };

  const tooltip = resource(
    props?.culture,
    "Tooltip::MakeThisCommentPrivate"
  )?.split("\\n\\n");
  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      open={isTransitionOpen}
    >
      <DialogTitle disableTypography style={{ padding: "24px 4%" }}>
        <Typography variant="h4" color="textPrimary">
          <Resource tag="Common::Transitions" />{" "}
        </Typography>
        <IconButton
          // aria-label="close"
          className={classes.closeButton}
          onClick={() => setTransitionOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Divider className={classes.divider} />
      </DialogTitle>
      <DialogContent>
        <Box px={"7.5%"}>
          <Box maxWidth={650} textAlign="left">
            {keyTasksPositive && (
              <Box>
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: 600, marginBottom: 16 }}
                >
                  {ideaId.length === 1 ? (
                    <Resource
                      tag="PageKeyTasks::TransitIdeaXToStatus"
                      args={[title]}
                    />
                  ) : (
                    <Resource
                      tag="PageKeyTasks::TransitXIdeas"
                      args={[ideaId.length]}
                    />
                  )}
                </Typography>
                <FormControl component="fieldset" style={{ width: "100%" }}>
                  <RadioGroup
                    // aria-label="keytaskTransition"
                    name="keytaskTransition"
                    onChange={handleSelectCheckBox}
                  >
                    {keyTasksPositive &&
                      keyTasksPositive?.map((data, index) => (
                        <Fragment key={data?.stateId ?? data?.phaseId}>
                          {index > 0 && (
                            <Divider className={classes.checkDivider} />
                          )}
                          <FormControlLabel
                            value={data?.stateId ?? data?.phaseId}
                            control={<Radio color="primary" />}
                            label={<Typography>{data?.description}</Typography>}
                          />
                        </Fragment>
                      ))}
                    <Divider className={classes.checkDivider} />
                    <Fragment>
                      <FormControlLabel
                        value={"lastphase"}
                        control={<Radio color="primary" />}
                        label={<Typography><Resource tag="PageKeyTasks::ChooseLast" /></Typography>}
                      />
                    </Fragment>
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            {keyTasksNegative && (
              <Box>
                {ideaId.length === 1 ? (
                  <Typography>
                    <Resource
                      tag="PageKeyTasks::TransitIdeaXToStatus"
                      args={[title]}
                    />
                    <Box component="strong" fontWeight={600} ml={0.5}>
                      "{keyTasksNegative && keyTasksNegative[0]?.description}"
                    </Box>
                  </Typography>
                ) : (
                  <Typography>
                    <Resource
                      tag="PageKeyTasks::TransitXIdeas"
                      args={[ideaId.length]}
                    />
                    <Box component="strong" fontWeight={600} ml={0.5}>
                      "{keyTasksNegative && keyTasksNegative[0]?.description}"
                    </Box>
                  </Typography>
                )}
              </Box>
            )}
            {!keyTasksPositive &&
              <FormControl component="fieldset" style={{ width: "100%" }}>
                <RadioGroup
                  name="keytaskTransition"
                  onChange={handleSelectCheckBox}
                >
                  <Fragment>
                    <FormControlLabel
                      value={"lastphase"}
                      control={<Radio color="primary" />}
                      label={<Typography><Resource tag="PageKeyTasks::ChooseLast" /></Typography>}
                    />
                  </Fragment>
                </RadioGroup>
              </FormControl>
            }

            <Box mt={3}>
              <Typography variant="h4">
                <Resource tag="ModalKeyTasks::WriteAComment" />
              </Typography>
              <TextareaAutosize
                placeholder={resource(
                  props?.culture,
                  "PageKeyTasks::WriteComment"
                )}
                onChange={handleChange}
                value={commentValue}
                className={classes.textarea}
                rowsMin={4}
              />
            </Box>

            <Box mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="iValidation-privite-comment"
                    onChange={handleSelect}
                    id="label-private-comment"
                    color="primary"
                  />
                }
                label={
                  <Box className={classes.checkboxLabel}>
                    <Resource tag="ModalTransitions::MakeThisCommentPrivate" />
                    <Box component="span" ml={0.5}>
                      {tooltip && (
                        <ToolTip title={tooltip[0]} width={400}>
                          {tooltip[1]}
                        </ToolTip>
                      )}
                    </Box>
                  </Box>
                }
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end" my={2}>
            <Button
              onClick={() => {
                closeButton();
              }}
              color="primary"
              variant="outlined"
              size="small"
              style={{ marginRight: 8 }}
            >
              <Resource tag="LogoutPopUp::Cancel" />
            </Button>

            <Button
              onClick={() => {
                postTransition();
              }}
              color="primary"
              variant="contained"
              size="small"
              disabled={
                ((!keyTasksPositive && !ideaId) || !commentValue || !stateId) &&
                ((!keyTasksNegative && !ideaId) || !commentValue)
              }
            >
              <Resource tag="Common::Transit" />
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    application: store.authState.auth,
    culture: store.cultureState,
    ideaDetails: store.ideasState.ideaDetails,
    postTransitionStatus: store.keyTasksState.postTransitionStatus,
    error: store.keyTasksState.error,
    summary: store.keyTasksState.summary,
    workspaceId: store.authState.auth.workspaceId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    postKeyTasksTransitions: (options: object) =>
      dispatch(postKeyTasksTransitions(options)),
    getTransitionKeyTasks: (options: object) =>
      dispatch(getTransitionKeyTasks(options)),
    getKeyTasks: (options: object) => dispatch(getAllKeyTasks(options)),
    getValidationKeyTasks: (options: object) =>
      dispatch(getValidationKeyTasks(options)),
    getCorrectionKeyTasks: (options: object) =>
      dispatch(getCorrectionKeyTasks(options)),
    getEvaluationKeyTasks: (options: object) =>
      dispatch(getEvaluationKeyTasks(options)),
    getTransitionSummary: (options: object) =>
      dispatch(getTransitionSummary(options)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyTaskIdeaTransitionPopUp);
