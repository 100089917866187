import React from "react";
import { connect } from "react-redux";
import Resource from "components/Resources/Resource";
import { Link as RouterLink } from "react-router-dom";
import { IAppState } from "../../../redux/store/Store";
import MiniHighlightedCardTab from "./MiniHighlightedCardTab";
import { INewsHighlighted } from "redux/types/news";
import {
  makeStyles,
  Box,
  Link,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles({
  viewAll: {
    textTransform: "uppercase",
    fontSize: "0.75rem",
  },
  button: {
    padding: 0,
  },
});

interface IProps {
  highlighted: INewsHighlighted;
}

function HighlightedListTab(props: IProps) {
  const { highlighted } = props;
  const newsBackURL = "/news/highlighted";
  let maxCards = 4;
  const classes = useStyles();
  return (
    <>
      {highlighted?.result?.slice(0, maxCards).map((high, index) => (
        <Box key={index}>
          {index === 0 ? (
            <Box key={high?.newsId}>
              <MiniHighlightedCardTab resultHighlighted={high} />
            </Box>
          ) : (
            <Box key={high?.newsId}>
              <Divider />
            <MiniHighlightedCardTab resultHighlighted={high}/>
            </Box>
          )}
        </Box>
      ))}
      {highlighted?.meta?.total > maxCards ? (
        <Box mt={2} textAlign={"end"}>
          <Link component={RouterLink} to={{ pathname: `${newsBackURL}` }}>
            <Button variant="text" color="primary" className={classes.button}>
              <Typography variant="button" className={classes.viewAll}>
                <Resource tag="Common::ViewAll" />
              </Typography>
            </Button>
          </Link>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    news: store.newsState.news,
  };
};

export default connect(mapStateToProps)(HighlightedListTab);
