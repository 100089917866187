import React, { useEffect, useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import Resource from "components/Resources/Resource";
import AddButton from "components/AddButton/AddButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ListManagers from "components/ListManagers/ListManagers";
import { IChallengesTops } from "redux/types/challenges";
import {
  deleteIdeasLikes,
  getAllIdeas,
  postIdeasInvestments,
  postIdeasLikes,
} from "redux/actions/ideas/IdeaAction";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  makeStyles,
} from "@material-ui/core";
import ListIdeaChallenge from "components/ListIdeaChallenges/ListIdeaChallenges";
import {
  IChallengeTopGetAction,
  getAllChallengesTops,
} from "redux/actions/ChallengeActions";
import {
  getAllInsights,
  getMoreInsights,
  IInsightsSuccessAction,
} from "redux/actions/insight/InsightsActions";
import {
  getChallengeDetail,
  getDetailChallengeIdeas,
  getDetailChallengeResult,
  getMoreDetailChallengeIdeas,
} from "redux/actions/ChallengeDetailAction";
import {
  IDetailIdea,
  IChallengeDetailState,
  IChallengeDetail,
  IDetailChallengeResult,
} from "redux/reducers/challengeDetailReducer";
import StateRuler from "components/StateRuler/StateRuler";
import StateRulerItem from "components/StateRuler/StateRulerItem";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import Tags from "components/Tags/Tags";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IAllIdeasState } from "redux/reducers/ideas/AllIdeasReducer";
import sanitizeHtml from "sanitize-html";
import { securityTextEditor } from "utils/securityTextEditor";
import newApp from "utils/reactNewApp";
import TitleCard from "components/ChallengesCards/ChallengeDetailTitleCard/TitleCard";

const useStyles = makeStyles(() =>
  createStyles({
    btn: {
      color: "#fff",
      width: "200px",
      height: "45px",
      justifyContent: "space-between",
      borderRadius: "40px",
    },
    gItem: {
      padding: "12px 12px 12px 0!important",
    },
  })
);

interface IDispatchProps {
  getDetailChallengeIdeas: (options: object) => Promise<IChallengeDetailState>;
  getAllInsights: (options: object) => Promise<IInsightsSuccessAction>;
  getTopChallenges: (options: object) => Promise<IChallengeTopGetAction>;
  getChallengeDetail: (options: object) => Promise<IChallengeDetailState>;
  getMoreDetailChallengeIdeas: (options: object) => Promise<any>;
  getChallengeDetailResults: (
    options: object
  ) => Promise<IDetailChallengeResult>;
  postInvestments: (options: object) => Promise<any>;
  postLikes: (options: object) => Promise<any>;
  deleteLikes: (options: object) => Promise<any>;
  getAllIdeas: (options: object) => Promise<IAllIdeasState>;
  getMoreInsights: (options: object) => Promise<any>;
}

interface IStateProps {
  ideas: IDetailIdea;
  inspirations: any;
  applicationId: string | number;
  culture: ICultureState;
  challengeId: "";
  challengeTops: IChallengesTops;
  challengeDetailResults: IDetailChallengeResult;
  challengeDetail: IChallengeDetail;
  challengeDetailStatus: string;
  workspaceId: string;
}

function ChallengeDetail(props: IDispatchProps & IStateProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [loadingTab, setLoadingTab] = useState(true);

  const {
    getTopChallenges,
    getChallengeDetail,
    getDetailChallengeIdeas,
    getAllInsights,
    // getChallengeDetailResults,
    postInvestments,
    postLikes,
    deleteLikes,
    // getAllIdeas,
    getMoreInsights,
    getMoreDetailChallengeIdeas,
    inspirations,
    ideas,
    challengeTops,
    challengeDetail,
    culture,
    applicationId,
    workspaceId,
  } = props;

  const options = {
    challengeId: props?.challengeId,
    pageSize: 7,
    pageNumber: 1,
    workspaceId,
  };
  useEffect(() => {
    let isMounted = true;

    isMounted &&
      getChallengeDetail(options).then(() => isMounted && setLoading(false));

    isMounted &&
      Promise.all([
        getDetailChallengeIdeas(options),
        getAllInsights({ ...options, pageSize: 4 }),
        getTopChallenges(options),
        // getChallengeDetailResults(options),
      ]).finally(() => isMounted && setLoadingTab(false));
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIdeas = async (params: any) => {
    getMoreDetailChallengeIdeas({ ...options, ...params });
  };

  const getInsights = async (pageNumber: number, pageSize: number) => {
    getMoreInsights({ ...options, pageSize, pageNumber });
  };
  return !loading ? (
    <Container className="challenge">
      <TitleCard challenge={challengeDetail} />
      {newApp ? (
        <StateRuler>
          {challengeDetail?.workflows?.length > 0 &&
            challengeDetail?.workflows?.sort(function (a, b) {
              if (a.phase > b.phase) {
                return 1;
              }
              if (a.phase < b.phase) {
                return -1;
              }
              // a must be equal to b
              return 0;
            }).map((stage, index) => {
              return (
                <StateRulerItem
                  key={index}
                  title={stage.title}
                  isActive={stage.totalIdeas > 0}
                  endDate={stage.endDate?.toString()}
                  countIdeas={stage.totalIdeas}
                //countImplementedIdea={stage.totalIdeasImplemented}
                />
              );
            })}
        </StateRuler>
      ) : (
        <StateRuler>
          {challengeDetail?.stages?.length > 0 &&
            challengeDetail?.stages?.map((stage, index) => {
              return (
                <StateRulerItem
                  key={index}
                  title={stage?.stage}
                  isActive={stage?.totalIdeas > 0}
                  endDate={stage?.endDate}
                  countIdeas={stage?.totalIdeas}
                  countImplementedIdea={stage?.totalIdeasImplemented}
                />
              );
            })}
        </StateRuler>
      )}
      <Grid container spacing={2}>
        <Grid item md={9}>
          {newApp ? (
            <Box
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  challengeDetail?.description
                    ? challengeDetail?.description
                    : "",
                  securityTextEditor
                ),
              }}
            />
          ) : (
            <Box
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  challengeDetail?.descriptionHtml
                    ? challengeDetail?.descriptionHtml
                    : "",
                  securityTextEditor
                ),
              }}
            />
          )}
        </Grid>
        <Grid item md={3}>
          <Box>
            <Box className="detail-challenge-thumbnails">
              {challengeDetail?.managers?.length > 0 ? (
                <ListManagers list={challengeDetail?.managers} />
              ) : (
                ""
              )}
            </Box>
            <Box mt={2}>
              {/*} {tagArr?.map(tag => {
            return null
            // tagArr.push(tag.TagName) TODO: ADD TAGS PHASE 2
            })} */}
              {challengeDetail?.tags?.length > 0 ? (
                <Tags list={challengeDetail?.tags} />
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 16 }}>
        <Grid item className={classes.gItem}>
          {challengeDetail?.allowSubmitIdea ? (
            <Link
              style={{ textDecoration: "none", cursor: "auto" }}
              to={`/createIdea/${challengeDetail?.challengeId}`}
            >
              <AddButton id="ChallengeDetail_AddButton" size="big" />
            </Link>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs>
          {ideas ? (
            <Link
              style={{ textDecoration: "none", cursor: "auto" }}
              to={{
                pathname: `/idea`,
                state: { challengeId: challengeDetail?.challengeId },
              }}
            >
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIosIcon fontSize="small" />}
              >
                <Resource tag={"PageChallenges::Challenge_EvaluateIdeas"} />
              </Button>
            </Link>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {!loadingTab ? (
        <Box mt={3}>
          <ListIdeaChallenge
            inspirations={inspirations}
            ideas={ideas}
            tops={challengeTops}
            culture={culture}
            applicationId={String(applicationId)}
            postInvestments={postInvestments}
            postLikes={postLikes}
            deleteLikes={deleteLikes}
            getAllIdeas={getIdeas}
            getAllInsights={getInsights}
            // getMoreInsights={getMoreInsights}
            getChallengeDetail={getChallengeDetail}
            // getChallengeDetailResults={getChallengeDetailResults}
            getTopChallenges={getTopChallenges}
          />
        </Box>
      ) : (
        <Grid item xs={12}>
          <Box mt={4} alignItems="center" textAlign="center">
            <CircularProgress />
          </Box>
        </Grid>
      )}
    </Container>
  ) : (
    <Grid item xs={12}>
      <Box mt={4} alignItems="center" textAlign="center">
        <CircularProgress />
      </Box>
    </Grid>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getDetailChallengeIdeas: (options: object) =>
      dispatch(getDetailChallengeIdeas(options)),
    getAllInsights: (options: object) => dispatch(getAllInsights(options)),
    getTopChallenges: (options: object) =>
      dispatch(getAllChallengesTops(options)),
    getChallengeDetail: (options: object) =>
      dispatch(getChallengeDetail(options)),
    getChallengeDetailResults: (options: object) =>
      dispatch(getDetailChallengeResult(options)),
    postInvestments: (options: object) =>
      dispatch(postIdeasInvestments(options)),
    postLikes: (options: object) => dispatch(postIdeasLikes(options)),
    deleteLikes: (options: object) => dispatch(deleteIdeasLikes(options)),
    getAllIdeas: (parameters: object) => dispatch(getAllIdeas(parameters)),
    getMoreInsights: (parameters: object) =>
      dispatch(getMoreInsights(parameters)),
    getMoreDetailChallengeIdeas: (params: object) =>
      dispatch(getMoreDetailChallengeIdeas(params)),
  };
};

const mapStateToProps = (store: IAppState, ownProps: any): IStateProps => {
  return {
    applicationId: store.authState.auth.applicationId,
    culture: store.cultureState,
    inspirations: store.insightsState.insights,
    challengeId: ownProps?.match?.params?.challengeId,
    ideas: store.challengeDetailState.ideas,
    challengeTops: store.challengeState.challengesTop,
    challengeDetail: store.challengeDetailState.challengeDetail,
    challengeDetailStatus: store.challengeDetailState.challengeDetailStatus,
    challengeDetailResults: store.challengeDetailState.challengeDetailResults,
    workspaceId: store.authState.auth.workspaceId,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChallengeDetail);
