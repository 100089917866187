import { Typography, Box, Grid, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
import Resource, { resource } from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import XDialog from "common/X/XDialog";
import TextInputWCounter from "../../../../common/UI/Text/TextCounter";
import SettingsSelect from "../../../../common/X/XSelect";
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  getManageGroups,
  postManageGroups,
  putManageGroups,
  deleteManageGroups,
} from "redux/actions/manageGroups/manageGroupsAction";
import { IManageGroup } from "redux/types/manageGroups";

interface IProps {
  type: "create" | "edit";
  open: boolean;
  setOpen: (open: boolean) => void;
  groupData?: IManageGroup;
  countries?: ICountries[];
  departments?: IDepartments[];
  culture: ICultureState;
  userAssignGroup?: boolean;
}

interface IStateProps {
  workspaceId: string;
}

interface IDispatchProps {
  postManageGroups: (options: object) => Promise<any>;
  putManageGroups: (options: object) => Promise<any>;
  getManageGroups: (options: object) => Promise<any>;
  deleteManageGroups: (options: object) => Promise<any>;
}

function DialogSettingsManageGroup(
  props: IProps & IDispatchProps & IStateProps
) {
  const {
    type,
    open,
    countries,
    departments,
    groupData,
    culture,
    userAssignGroup,
    workspaceId,
    setOpen,
    postManageGroups,
    putManageGroups,
    getManageGroups,
    deleteManageGroups,
  } = props;

  const [nameValue, setNameValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [countriesActive, setCountriesActive] = useState<any>([]);
  const [departmentsActive, setDepartmentsActive] = useState<any>([]);

  const handleCountryChange = (value: any) => {
    setCountriesActive(value);
  };

  const handleDeptoChange = (value: any) => {
    setDepartmentsActive(value);
  };

  useEffect(() => {
    if (groupData) {
      groupData?.cultures?.map((cult) => {
        return (
          setNameValue(cult?.title), setDescriptionValue(cult?.description)
        );
      });

      //Tratamento para carregar itens ativos recebidos da API
      if (countries) {
        let countrieSelected = [];
        if (groupData?.countries) {
          for(let i=0; i< groupData?.countries.length; i++){
            countrieSelected.push(countries?.filter((data) => data?.countryId === groupData?.countries[i])?.map((country) => country?.name)[0]);
          };
        }
        setCountriesActive(countrieSelected);
      };

      //Tratamento para carregar itens ativos recebidos da API
      if (departments) {
        let departamentSelected = [];
        if (groupData?.departments) {
          for(let i=0; i< groupData?.departments.length; i++){
            departamentSelected.push(departments?.filter((data) => data?.departmentId === groupData?.departments[i])?.map((country) => country?.title)[0]);
          };
        }
        setDepartmentsActive(departamentSelected);
      };

    }
    // eslint-disable-next-line
  }, [groupData]);

  let cultures = [
    {
      cultureTag: culture.culture.cultureTag,
      title: nameValue,
      description: descriptionValue,
    },
  ];

  function createGroup() {
    //Tratamento para salvar IDs na variável que será enviada para API
    let countrieSelected = [];
    for(let i=0; i< countriesActive.length; i++){
      countrieSelected.push(countries?.filter((data) => data?.name === countriesActive[i])?.map((country) => country?.countryId)[0]);
    };

    //Tratamento para salvar IDs na variável que será enviada para API
    let departamentSelected = [];
    for(let i=0; i< departmentsActive.length; i++){
      departamentSelected.push(departments?.filter((data) => data?.title === departmentsActive[i])?.map((department) => department?.departmentId)[0]);
    };

    type !== "edit"
      ? postManageGroups({
          workspaceId,
          cultures,
          countrieSelected,
          departamentSelected,
          culture: culture.culture
        }).then(() => {
          getManageGroups({
            workspaceId,
            cultureTag: culture.culture.cultureTag,
          });
        })
      : putManageGroups({
          groupId: groupData?.groupId,
          workspaceId,
          cultures,
          countrieSelected,
          departamentSelected,
          culture: culture.culture
        }).then(() => {
          getManageGroups({
            workspaceId,
            cultureTag: culture.culture.cultureTag,
          });
        });
    
    setNameValue("");
    setDescriptionValue("");
    setCountriesActive([]);
    setDepartmentsActive([]);

    setOpen(false);
  }

  function deleteGroup() {
    deleteManageGroups({ groupId: groupData?.groupId, workspaceId }).then(
      () => {
        getManageGroups({
          workspaceId,
          cultureTag: culture.culture.cultureTag,
        });
      }
    );
  }

  const actions = (
    <Grid container>
      <Grid item xs={3}>
        {type === "edit" && !userAssignGroup && (
          <Button
            color="primary"
            onClick={() => {
              deleteGroup();
              setOpen(false);
            }}
          >
            <Resource tag="ManageGroups::DeleteGroup" />
          </Button>
        )}
      </Grid>
      <Grid item xs={9}>
        <Box textAlign="right">
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={() => setOpen(false)}
            style={{ marginRight: 8 }}
          >
            <Resource tag="ManageGroups::Cancel" />
          </Button>

          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={createGroup}
            disabled={!nameValue}
          >
            {type === "create" ? (
              <Resource tag="ManageGroups::AddGroup" />
            ) : (
              <Resource tag="ManageGroups::SaveChanges" />
            )}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      maxWidth="md"
      title={
        type === "create" ? (
          <Resource tag="ManageGroups::CreateGroup" />
        ) : (
          <Resource tag="ManageGroups::EditGroup" />
        )
      }
      actions={actions}
    >
      <Box my={3}>
        <Box mb={3}>
          <TextInputWCounter
            maxLength={50}
            label={<Resource tag="ManageGroups::Name" />}
            placeholder={resource(culture, "ManageGroups::Name_Tip")}
            fullWidth
            onChange={(e) => setNameValue(e.target.value)}
            value={nameValue}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box>
          <TextInputWCounter
            label={
              <Box>
                <Box component="span" mr={1}>
                  <Resource tag="ManageGroups::Description" />
                </Box>
                <Box
                  component="span"
                  fontWeight={400}
                  style={{ textTransform: "lowercase" }}
                >
                  (<Resource tag="ManageGroups::Optional" />)
                </Box>
              </Box>
            }
            placeholder={resource(culture, "ManageGroups::Description_Tip")}
            maxLength={500}
            fullWidth
            multiline
            rows={4}
            onChange={(e) => setDescriptionValue(e.target.value)}
            value={descriptionValue}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="h4">
          <Resource tag="ManageGroups::AssignToTheGroup" />
        </Typography>
        <Box mt={1}>
          <Typography variant="body2" color="textSecondary">
            <Resource tag="ManageGroups::AssignToTheGroup_Tip" />
          </Typography>
        </Box>

        <Box mt={3.5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <SettingsSelect
                inputLabel={<Resource tag="ManageGroups::Country" />}
                multiple
                value={countriesActive}
                setValue={handleCountryChange}
                options={countries}
                optionId={"countryId"}
                optionName={"name"}
                selectedResource={resource(culture, "XSelect::selected")}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <SettingsSelect
                inputLabel={<Resource tag="ManageGroups::Departament" />}
                multiple
                value={departmentsActive}
                setValue={handleDeptoChange}
                options={departments}
                optionId={"departmentId"}
                optionName={"title"}
                selectedResource={resource(culture, "XSelect::selected")}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </XDialog>
  );
}
const mapStateToProps = (store: IAppState) => ({
  culture: store.cultureState,
  workspaceId: store.authState.auth.workspaceId,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  postManageGroups: (options: object) => dispatch(postManageGroups(options)),
  putManageGroups: (options: object) => dispatch(putManageGroups(options)),
  getManageGroups: (options: object) => dispatch(getManageGroups(options)),
  deleteManageGroups: (options: object) =>
    dispatch(deleteManageGroups(options)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogSettingsManageGroup);
