import { connect } from "react-redux";
import { toast } from "react-toastify";
import sanitizeHtml from "sanitize-html";
import { Component } from "react";
import { securityTextEditor } from "utils/securityTextEditor";
import { IAppState } from "../../redux/store/Store";
import { ICultureState } from "../../redux/reducers/cultureReducer";

interface IProps {
  tag: string;
  cultureState?: ICultureState;
  args?: Array<any>;
  cssClass?: string;
}

// function getCultura() {
//   let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store.dispatch;
//   const tag = store.getState().cultureState.culture.cultureTag;
//   dispatch(setCultureAndResources(tag));
//   return store.getState().cultureState;
// }

export function resource(cultureState: any, tag: string, args?: any) {
  if (tag === undefined || tag.indexOf("<!DOCTYPE html>") !== -1) {
    tag = "MessageError::UnexpectedError";
  }

  if (
    cultureState === undefined ||
    cultureState === null ||
    Object.keys(cultureState).length === 0
  ) {
    console.log(`CULTURA está sendo passada vazia na TAG ${tag}`);
    // cultureState = getCultura();
  }

  const tagProperty = tag?.split("::");
  let resourceText: string;
  let culture = cultureState?.culture ?? cultureState;
  if (culture?.cultureTag === undefined || culture?.cultureTag === "") {
    resourceText = cultureState?.culture?.resources[tagProperty[1]];
    args = "resourceDefault"; // set to user's first access, resource default

    if (args === undefined) {
      if (!resourceText)
        console.log(
          `TAG ${tag} na cultura ${culture?.cultureTag} está vindo vazia nos resources`
        );
      return resourceText;
    } else {
      return resourceText;
    }
  } else {
    resourceText =
      culture.resources.length !== 0
        ? culture?.resources?.filter((resource: { [x: string]: any }) => {
            return resource["cultureTag"] === culture?.cultureTag;
          })?.[0]?.["resourceObject"]?.[tagProperty[0]]?.[tagProperty[1]]
        : "";

    if (!args) {
      if (!resourceText)
        console.log(
          `TAG ${tag} na cultura ${culture?.cultureTag} está vindo vazia nos resources`
        );
      return resourceText;
    } else {
      return resourceText?.replace(/{(\d+)}/g, function(match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
      });
    }
  }
}

class Resource extends Component<IProps> {
  render() {
    const { cultureState, tag, args, cssClass } = this.props;

    try {
      return (
        <span
          className={cssClass}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(
              resource(cultureState, tag, args),
              securityTextEditor
            ),
          }}
        />
      );
    } catch (err:any) {
      toast.error(err.error_message);
      return "";
    }
  }
}

function mapStateToProps(state: IAppState) {
  return {
    cultureState: state.cultureState,
  };
}

export default connect(mapStateToProps, null)(Resource);
