// Import redux types
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api_v2, api_general } from "../../../config/api";
import newApp from 'utils/reactNewApp';

// Import Character Typing
import { IAllIdeasState } from '../../reducers/ideas/AllIdeasReducer';

const basePath = "ideas";

// Create Action Constants
export enum IdeasActionTypes {
  ALL_IDEAS_LOADING = 'IDEAS_LOADING',
  ALL_IDEAS_SUCCEEDED = 'IDEAS_SUCCEEDED',
  ALL_IDEAS_FAILED = 'IDEAS_FAILED',

  COLLABORATION_IDEAS_LOADING = 'COLLABORATION_IDEAS_LOADING',
  COLLABORATION_IDEAS_SUCCEEDED = 'COLLABORATION_IDEAS_SUCCEEDED',
  COLLABORATION_IDEAS_FAILED = 'COLLABORATION_IDEAS_FAILED',
  COLLABORATION_IDEAS_MORE_LOADING = 'COLLABORATION_IDEAS_MORE_LOADING',
  COLLABORATION_IDEAS_MORE_SUCCEEDED = 'COLLABORATION_IDEAS_MORE_SUCCEEDED',
  COLLABORATION_IDEAS_MORE_FAILED = 'COLLABORATION_IDEAS_MORE_FAILED',

  
  IDEAS_REJECTED = 'GET_IDEAS_REJECTED',
  INPROGRESS = 'GET_INPROGRESS',
  IMPLEMENTED = 'GET_IMPLEMENTED',
}

//Create Action Statusses
export enum IdeasActionStatus {
  ALL_IDEAS_LOADING = 'LOADING',
  ALL_IDEAS_SUCCEEDED = 'SUCCEEDED',
  ALL_IDEAS_FAILED = 'FAILED',

  COLLABORATION_IDEAS_LOADING = 'LOADING',
  COLLABORATION_IDEAS_SUCCEEDED = 'SUCCEEDED',
  COLLABORATION_IDEAS_FAILED = 'FAILED',
  COLLABORATION_IDEAS_MORE_LOADING = 'FETCHING',
  COLLABORATION_IDEAS_MORE_SUCCEEDED = 'SUCCEEDED',
  COLLABORATION_IDEAS_MORE_FAILED = 'FAILED',
}

//------------*Get All Actions*---------------
export interface IIdeasGetAllLoadingAction {
  type: IdeasActionTypes.ALL_IDEAS_LOADING;
  status: string;
}

export interface IIdeasGetAllSucceededAction {
  type: IdeasActionTypes.ALL_IDEAS_SUCCEEDED;
  payload: any;
  status: string;
}

export interface IIdeasGetAllFailedAction {
  type: IdeasActionTypes.ALL_IDEAS_FAILED;
  status: string;
  error: null;
}


//------------*Collaboration Actons*---------------
export interface IIdeasGetCollaborationLoadingAction {
  type: IdeasActionTypes.COLLABORATION_IDEAS_LOADING;
  status: string;
}

export interface IIdeasGetCollaborationSucceededAction {
  type: IdeasActionTypes.COLLABORATION_IDEAS_SUCCEEDED;
  payload: any;
  status: string;
}

export interface IIdeasGetCollaborationFailedAction {
  type: IdeasActionTypes.COLLABORATION_IDEAS_FAILED;
  status: string;
  error: null;
}
export interface IIdeasGetCollaborationMoreLoadingAction {
  type: IdeasActionTypes.COLLABORATION_IDEAS_MORE_LOADING;
  status: string;
}

export interface IIdeasGetCollaborationMoreSucceededAction {
  type: IdeasActionTypes.COLLABORATION_IDEAS_MORE_SUCCEEDED;
  payload: any;
  status: string;
}

export interface IIdeasGetCollaborationMoreFailedAction {
  type: IdeasActionTypes.COLLABORATION_IDEAS_MORE_FAILED;
  status: string;
  error: null;
}

export interface IIdeasGetAllImplementedAction {
  type: IdeasActionTypes.IMPLEMENTED;
  ideasImplemented: any
}
/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type IdeasActions =
  | IIdeasGetAllLoadingAction
  | IIdeasGetAllSucceededAction
  | IIdeasGetAllFailedAction
  | IIdeasGetCollaborationLoadingAction
  | IIdeasGetCollaborationSucceededAction
  | IIdeasGetCollaborationFailedAction
  | IIdeasGetCollaborationMoreLoadingAction
  | IIdeasGetCollaborationMoreSucceededAction
  | IIdeasGetCollaborationMoreFailedAction
  | IIdeasGetAllImplementedAction

/* Get All idea
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllIdeas: ActionCreator<
  ThunkAction<Promise<any>, IAllIdeasState, null, IIdeasGetAllSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type:IdeasActionTypes.ALL_IDEAS_LOADING,
      status:IdeasActionStatus.ALL_IDEAS_LOADING
    })
    let params = [];
    let params_qs = "";


    if (options.hasOwnProperty("challengeId")) {
      params.push(`challengeId=${options.challengeId}`);
    }
    if (options.hasOwnProperty("q")) {
      params.push(`q=${options.q}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    newApp ?
      await api_general.get(`${basePath}${params_qs}`, {}).then(response =>{
        dispatch({
          type: IdeasActionTypes.ALL_IDEAS_SUCCEEDED,
          status: IdeasActionStatus.ALL_IDEAS_SUCCEEDED,
          payload: response.data,
        });
      }, error =>{
        dispatch({
          type: IdeasActionTypes.ALL_IDEAS_FAILED,
          status: IdeasActionStatus.ALL_IDEAS_FAILED,
          error: error,
        });
      })
    :
      await api_v2.get(`${basePath}${params_qs}`, {}).then(response =>{
        dispatch({
          type: IdeasActionTypes.ALL_IDEAS_SUCCEEDED,
          status: IdeasActionStatus.ALL_IDEAS_SUCCEEDED,
          payload: response.data,
        });
      }, error =>{
        dispatch({
          type: IdeasActionTypes.ALL_IDEAS_FAILED,
          status: IdeasActionStatus.ALL_IDEAS_FAILED,
          error: error,
        });
      })
      // treatError(options?.culture?.culture, 'Idea', err.toString());
  };
};



/* Get All ideas Collaborative
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getIdeasCollaborative: ActionCreator<
  ThunkAction<Promise<any>, IAllIdeasState, null, IIdeasGetCollaborationSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IdeasActionTypes.COLLABORATION_IDEAS_LOADING,
      status: IdeasActionStatus.COLLABORATION_IDEAS_LOADING
    });

    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("challengeId")) {
      params.push(`challengeId=${options.challengeId}`);
    }
    if (options.hasOwnProperty("q")) {
      params.push(`q=${options.q}`);
    }
    if (options.hasOwnProperty("pageNumber")) {
      params.push(`pageNumber=${options.pageNumber}`);
    }

    if (options.hasOwnProperty("pageSize")) {
      params.push(`pageSize=${options.pageSize}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    newApp ? 
      await api_general.get(`${basePath}/incollaboration${params_qs}`, {}).then(response =>{
        dispatch({
          payload: response.data,
          type: IdeasActionTypes.COLLABORATION_IDEAS_SUCCEEDED,
          status: IdeasActionStatus.COLLABORATION_IDEAS_SUCCEEDED
        });
      }, error =>{
        dispatch({
          error: error,
          type: IdeasActionTypes.COLLABORATION_IDEAS_FAILED,
          status: IdeasActionStatus.COLLABORATION_IDEAS_FAILED
        });
      })
    :
      await api_v2.get(`${basePath}/incollaboration${params_qs}`, {}).then(response =>{
        dispatch({
          payload: response.data,
          type: IdeasActionTypes.COLLABORATION_IDEAS_SUCCEEDED,
          status: IdeasActionStatus.COLLABORATION_IDEAS_SUCCEEDED
        });
      }, error =>{
        dispatch({
          error: error,
          type: IdeasActionTypes.COLLABORATION_IDEAS_FAILED,
          status: IdeasActionStatus.COLLABORATION_IDEAS_FAILED
        });
      });
    // treatError(options?.culture?.culture, 'Idea', err.toString());
  };
};

/* Get All ideas Collaborative
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getMoreIdeasCollaborative: ActionCreator<
  ThunkAction<Promise<any>, IAllIdeasState, null, IIdeasGetCollaborationMoreSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IdeasActionTypes.COLLABORATION_IDEAS_MORE_LOADING,
      status: IdeasActionStatus.COLLABORATION_IDEAS_MORE_LOADING
    });

    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("challengeId")) {
      params.push(`challengeId=${options.challengeId}`);
    }
    if (options.hasOwnProperty("q")) {
      params.push(`q=${options.q}`);
    }
    if (options.hasOwnProperty("pageNumber")) {
      params.push(`pageNumber=${options.pageNumber}`);
    }

    if (options.hasOwnProperty("pageSize")) {
      params.push(`pageSize=${options.pageSize}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    newApp ?
      await api_general.get(`${basePath}/incollaboration${params_qs}`, {}).then(response =>{
        dispatch({
          payload: response.data,
          type: IdeasActionTypes.COLLABORATION_IDEAS_MORE_SUCCEEDED,
          status: IdeasActionStatus.COLLABORATION_IDEAS_MORE_SUCCEEDED
        });
      }, error =>{
        dispatch({
          error: error,
          type: IdeasActionTypes.COLLABORATION_IDEAS_MORE_FAILED,
          status: IdeasActionStatus.COLLABORATION_IDEAS_MORE_FAILED
        });
      })
    :
      await api_v2.get(`${basePath}/incollaboration${params_qs}`, {}).then(response =>{
        dispatch({
          payload: response.data,
          type: IdeasActionTypes.COLLABORATION_IDEAS_MORE_SUCCEEDED,
          status: IdeasActionStatus.COLLABORATION_IDEAS_MORE_SUCCEEDED
        });
      }, error =>{
        dispatch({
          error: error,
          type: IdeasActionTypes.COLLABORATION_IDEAS_MORE_FAILED,
          status: IdeasActionStatus.COLLABORATION_IDEAS_MORE_FAILED
        });
      });
    // treatError(options?.culture?.culture, 'Idea', err.toString());
  };
};
export const getIdeasImplemented: ActionCreator<
  ThunkAction<Promise<any>, IAllIdeasState, null, IIdeasGetAllImplementedAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";


      if (options.hasOwnProperty("challengeId")) {
        params.push(`challengeId=${options.challengeId}`);
      }
      if (options.hasOwnProperty("q")) {
        params.push(`q=${options.q}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      newApp ?
        await api_general.get(`${basePath}/implemented${params_qs}`, {}).then(response => {
          dispatch({
            ideasImplemented: response.data,
            type: IdeasActionTypes.IMPLEMENTED,
          });
        })
      :
        await api_v2.get(`${basePath}/implemented${params_qs}`, {}).then(response => {
          dispatch({
            ideasImplemented: response.data,
            type: IdeasActionTypes.IMPLEMENTED,
          });
        })
      
    } catch (err) {
      // treatError(options?.culture?.culture, 'PageIdea::Header', err);
    }
  };
};