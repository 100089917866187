import React from 'react';
import { Card, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';

const useStyles = makeStyles({
  root: {
    height: "260px",
  },
});


function PrizesSkeleton() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Skeleton variant="rect" width="100%" height="90%" />
      <Skeleton variant="text" width="100%" />
    </Card>
  );
}

export default PrizesSkeleton