import React, { useState, useEffect } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Grid,
  AppBar,
  Tab,
  Input,
} from "@material-ui/core";
import Resource, { resource } from "components/Resources/Resource";
import { TabPanel, TabList, TabContext } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import ManageAdminsList from "./ManageAdminsList";
import ManageChallengeManagersList from "./ManageChallengeManagersList";
import ManageParticipantsList from "./ManageParticipantsList";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IPermissions } from "redux/types/manageUsers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    noSidePadding: {
      padding: "24px 0",
    },
    tabRoot: {
      color: `${theme.palette.primary.main}!important`,
      textTransform: "capitalize",
      padding: "0px 11px!important",
      height: "30px!important",
      minHeight: "30px!important",
      borderBottom: "none",
      borderRadius: "24px",
    },
    tabSelected: {
      backgroundColor: `${theme.palette.primary.light}!important`,
    },
    indicator: {
      display: "none",
    },
  })
);

interface IProps {
  culture: ICultureState;
  permissions: IPermissions;
  permissionStatus: string;
  getUserPermissions: any;
  putPermission: any;
  workspaceId: string;
}
export default function ManagePermissionsTabs(props: IProps) {
  const classes = useStyles();
  const [tab, setTab] = useState("1");
  const {
    permissions,
    permissionStatus,
    getUserPermissions,
    workspaceId,
    culture,
    putPermission,
  } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue("");
  }, [tab]);
  useEffect(() => {
    getUserPermissions({
      workspaceId,
      permission: Number(tab),
      pageNumber: 1,
      pageSize: 10,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTab = (event: any, newValue: string) => {
    getUserPermissions({
      workspaceId,
      permission: Number(newValue),
      pageNumber: 1,
      pageSize: 10,
    }).then(() => {
      setTab(newValue);
    });
  };

  let keyTimer: any;
  let waitTimeAfterType = 1000;

  /*
  //----------------------------------------------
  //Está redundante com a função handlerChange
  //----------------------------------------------
  const handlerKeyUp = (text: string) => {
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      handleSearch(text);
    }, waitTimeAfterType);
  };
  */

  const handlerKeydDown = () => {
    clearTimeout(keyTimer);
  };

  const handlerChange = (cFind: string) => {
    setValue(cFind);
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      handleSearch(cFind);
    }, waitTimeAfterType);
  }

  const handleSearch = (text: any) => {
    getUserPermissions({
      workspaceId,
      pageNumber: 1, /*permissions?.meta?.lastPage ? permissions?.meta?.lastPage : 1, //este tratamento estava causando problema no primeiro filtro*/
      permission: Number(tab),
      pageSize: 6,
      filterNameOrEmail: text,
    });
  };

  return (
    <>
      <Box className={classes.root} marginY={3}>
        <TabContext value={tab}>
          <Grid container spacing={2}>
            <Grid container item xs={12} sm={12} md={7} lg={7}>
              <AppBar position="static" elevation={0}>
                <TabList
                  classes={{ indicator: classes.indicator }}
                  onChange={handleChangeTab}
                  variant="standard"
                  scrollButtons="on"
                >
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    label={<Resource tag="PageManageUsers::Admins" />}
                    value="1"
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    label={
                      <Resource tag="PageManageUsers::Challenge_Managers" />
                    }
                    value="2"
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    label={<Resource tag="PageManageUsers::Participants" />}
                    value="3"
                  />
                </TabList>
              </AppBar>
            </Grid>
            <Grid container item xs={12} sm={12} md={5} lg={5}>
              <Input
                onKeyDown={handlerKeydDown}
                onChange={(e) => handlerChange(e.target.value)}
                value={value}
                fullWidth
                endAdornment={<SearchIcon />}
                placeholder={resource(culture, "PageSettings::SearchForUser")}
                //onKeyUp={(e) => handlerKeyUp(value)}
                //inputProps={{ "aria-label": "description" }}
              />
            </Grid>
          </Grid>
          <TabPanel value="1" className={classes.noSidePadding}>
            <ManageAdminsList
              workspaceId={workspaceId}
              tab={Number(tab)}
              getUserPermissions={getUserPermissions}
              permissions={permissions}
              permissionStatus={permissionStatus}
              culture={culture}
              putPermission={putPermission}
            />
          </TabPanel>
          <TabPanel value="2" className={classes.noSidePadding}>
            <ManageChallengeManagersList
              workspaceId={workspaceId}
              tab={Number(tab)}
              getUserPermissions={getUserPermissions}
              permissions={permissions}
              permissionStatus={permissionStatus}
              culture={culture}
              putPermission={putPermission}
            />
          </TabPanel>
          <TabPanel value="3" className={classes.noSidePadding}>
            <ManageParticipantsList
              workspaceId={workspaceId}
              tab={Number(tab)}
              getUserPermissions={getUserPermissions}
              permissions={permissions}
              permissionStatus={permissionStatus}
              culture={culture}
              putPermission={putPermission}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
