import FormTag from "components/FormTag/FormTag";
import ToolTip from "components/ToolTip/ToolTip";
import React, { useEffect, useRef, useState } from "react";
import PhotoUpload from "components/PhotoUpload/PhotoUpload";
import { PageStateDetail } from "redux/types/createChallenge";
import { ICultureState } from "redux/reducers/cultureReducer";
import Resource, { resource } from "components/Resources/Resource";
import TextInputWCounter from "../../../../common/UI/Text/TextCounter";
import TextEditorInput from "components/GenericField/TextEditorInput/TextEditorInput";
import {
  Box,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  RadioGroup,
  Theme,
  Typography,
  useTheme,
  Radio,
  Divider,
  withStyles,
  Switch,
  TextField,
} from "@material-ui/core";
import XDatepicker from "common/UI/XDatepicker/XDatepicker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formProgressBar: {
      backgroundColor: theme.palette.primary.main,
      display: "inline-block",
      width: "32px",
      height: "5px !important",
      margin: "0 4px 0 0",
      opacity: "0.4",
      borderRadius: "5px",
      "&.active": {
        opacity: 1,
      },
    },
    widthRadioBtn: {
      "& .MuiSvgIcon-root": {
        width: "0.8em",
        height: "0.8em",
      },
    },
    titleRadioGroup: {
      color: theme.palette.text.secondary,
      fontSize: "0.8rem",
      fontWeight: 700,
      paddingBottom: "10px",
      textTransform: "uppercase",
    },
    labelRadioBtn: {
      marginRight: "50px",
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
      },
    },
    inputPhoto: {
      display: "none",
    },
    iconBtn: {
      padding: 0,
      "& .MuiIconButton-root": {
        padding: 0,
      },
      "&:hover": {
        padding: 0,
      },
    },
    containerImage: {
      cursor: "pointer",
      position: "relative",
      width: "348px",
    },
    challengeImg: {
      width: "348px",
      height: "120px",
      borderRadius: "8px",
    },
    closeButton: {
      position: "absolute",
      top: "9px",
      right: "-4px",
      color: theme.palette.text.primary,
      transform: "translate(-50%,-50%)",
      backgroundColor: theme.palette.common.white,
      padding: 0,
      "&:hover": {
        opacity: 1,
        backgroundColor: theme.palette.grey[300],
      },
    },
  })
);

//Switch on/of style
const SwitchOnOff = withStyles((theme: Theme) =>
  createStyles({
    switchBase: {
      color: theme.palette.grey[100],
      "&$checked": {
        color: theme.palette.primary.main,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
    checked: {},
    track: {},
  })
)(Switch);

interface IProps {
  fieldsProgress?: number;
  completedFieldsProgress?: number;
  culture: ICultureState;
  state: PageStateDetail;
  setState: any;
}

function CreateChallengeStepDetails(props: IProps) {
  const { culture, state, setState } = props;
  const classes = useStyles();
  const theme = useTheme();

  //handleformdata to completedFieldsProgress
  const [fieldsProgress, setFieldsProgress] = useState(0);
  const [completedFieldsProgress, setCompletedFields] = useState(0);

  const formRef = useRef<HTMLFormElement | null>(null);

  //ProgressBar
  const setUpBar = () => {
    const bars = [];
    for (let index = 0; index < fieldsProgress; index++) {
      const barClass = index < completedFieldsProgress;
      bars.push(<span key={index} className={classes.formProgressBar + (barClass ? " active" : "")}></span>);
    }
    return bars;
  };

  //Porcentage completed
  const completedPorcentage = Math.floor((completedFieldsProgress / fieldsProgress) * 100);

  // Challenge type functions - radio group
  const handleChangeChallengeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      challengeType: Number((event.target as HTMLInputElement).value),
    });
  };

  //Challenge type functions - date and time
  const handleActivationDateChange = (date: any) => {
    setState({
      ...state,
      startDate: date,
    });
  };

  const handleSubmissionDateChange = (date: any) => {
    setState({
      ...state,
      endSubmitionDate: date,
    });
  };

  let hourActivation = new Date(state.startDate).getHours();
  let minuteActivation = new Date(state.startDate).getMinutes();
  let timeNowActivation = (hourActivation <= 9 ? `0${hourActivation}` : hourActivation) + ":" + (minuteActivation <= 9 ? `0${minuteActivation}` : minuteActivation);

  let hourEnd = new Date(state.endSubmitionDate).getHours();
  let minuteEnd = new Date(state.endSubmitionDate).getMinutes();
  let timeNowEnd = (hourEnd <= 9 ? `0${hourEnd}` : hourEnd) + ":" + (minuteEnd <= 9 ? `0${minuteEnd}` : minuteEnd);

  const changeSubmissionHour = (e: any) => {
    const [hour, minutes] = e.target.value.split(":");
    const time = new Date(state.endSubmitionDate);
    time.setHours(hour);
    time.setMinutes(minutes);

    setState({
      ...state,
      endSubmitionDate: time,
    });
  };

  const changeActivationHour = (e: any) => {
    const [hour, minutes] = e.target.value.split(":");
    const time = new Date(state.startDate);
    time.setHours(hour);
    time.setMinutes(minutes);

    setState({
      ...state,
      startDate: time,
    });
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      isIdeasVisible: event.target.checked,
    });
  };

  const challengeDescriptionProps = {
    columnName: "challenge-description",
    isMandatory: false,
    typeId: 9,
    title: resource(culture, "PageCreateChallenge::Description"),
    placeholder: resource(culture, "PageCreateChallenge::Description_Tip"),
    isEditable: true,
    maxLength: 3000,
  };

  const [photo, setPhoto] = useState<any>(state.image);
  const [description, setDescription] = useState(state.description);

  useEffect(() => {
    fCompletedFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, description]);

  useEffect(() => {
    handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo]);

  function fCompletedFields() {
    const form = formRef.current!;
    const formData = new FormData(form);
    const { completedFields } = handleFormData(formData);

    setCompletedFields(completedFields);
  }

  //functions to take all fields
  function handleFormData(data: FormData) {
    const elemArray = [
      data.get("challenge-title"),
      description === "<p><br></p>" ? "" : description,
      data.get("Tags"),
      photo !== undefined ? photo : "",
    ];

    setFieldsProgress(elemArray?.length);

    const challengeTagsArray = data.get("Tags") as string;
    const tags = challengeTagsArray ? challengeTagsArray.split(";") : [];

    let newValues = {
      title: data.get("challenge-title"),
      description: data.get("challenge-description"),
      tags: tags,
      img: photo,
    };

    const params = {
      ...newValues,
    };
    const completedFields = elemArray.filter((x) => x).length;

    return { params, completedFields };
  }

  const handleData = () => {
    const form = formRef.current!;
    const formData = new FormData(form);
    const { params } = handleFormData(formData);
    setState({
      ...state,
      title: params.title,
      description: params.description === "<p><br></p>" ? "" : params.description,
      image: params.img,
      tags: params.tags,
    });
  };

  useEffect(() => {
    setState({ ...state, description });
    // eslint-disable-next-line
  }, [description, setState]);

  return (
    <form ref={formRef} className="challenge-Form">
      <Box className="step-details-container">
        <Box className="header=form-progress" display="flex" flexDirection="column">
          <Box display="flex" alignSelf="self-start">
            <Box component="p" fontWeight="bold" mr={1}>
              <Resource tag="PageCreateChallenge::NewChallenge" />
            </Box>
            <Box component="p" color={theme.palette.text.secondary}>
              <Resource tag="PageCreateChallenge::Complete" args={[completedPorcentage]} />
            </Box>
          </Box>
          <Box mt={1} display="flex">
            {setUpBar()}
          </Box>
        </Box>
        <Box className="challenge-type" mt={4}>
          <Box className="radio-group-challenge-type">
            <FormControl component="fieldset" className={classes.widthRadioBtn}>
              <FormLabel component="legend" className={classes.titleRadioGroup}>
                <Resource tag="PageCreateChallenge::ChallengeType" />
              </FormLabel>
              <RadioGroup
                id="challenge-type"
                name="challenge-type"
                value={state.challengeType}
                onChange={handleChangeChallengeType}
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label={resource(culture, "PageCreateChallenge::Temporary")}
                  className={classes.labelRadioBtn}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label={resource(culture, "PageCreateChallenge::Permanent")}
                  className={classes.labelRadioBtn}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Divider style={{ width: "100%", margin: "24px 0" }} />
          <Box className="start" mb={4}>
            <Box mb={3}>
              <Box fontSize="0.9rem" fontWeight="bold">
                <Resource tag="PageCreateChallenge::ActivationDate" />
              </Box>
              <Typography variant="caption" color="textSecondary">
                <Resource tag="PageCreateChallenge::SelectStart" />
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <XDatepicker
                  keyboardDatePicker
                  label={
                    <Typography variant="body1" color="textSecondary">
                      <Resource tag="PageCreateChallenge::Date" />
                    </Typography>
                  }
                  variant="inline"
                  format="dd/MM/yyyy"
                  disableToolbar
                  autoOk
                  fullWidth
                  minDateMessage={
                    <Typography variant="caption" color="error">
                      <Resource tag="PageCreateChallenge::MinDateMessage" />
                    </Typography>
                  }
                  value={new Date(state.startDate)}
                  onChange={handleActivationDateChange}
                />

              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="time"
                  id="timeStart"
                  label={
                    <Typography variant="body2" color="textSecondary">
                      <Resource tag="PageCreateChallenge::Hour" />
                    </Typography>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={changeActivationHour}
                  defaultValue={timeNowActivation}
                />
              </Grid>
            </Grid>
          </Box>
          {state.challengeType === 1 && (
            <Box className="end" mb={5}>
              <Box mb={3}>
                <Box fontSize="0.9rem" fontWeight="bold">
                  <Resource tag="PageCreateChallenge::SubmissionEndDate" />
                </Box>
                <Typography variant="caption" color="textSecondary">
                  <Resource tag="PageCreateChallenge::SelectEnd" />
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <XDatepicker
                    keyboardDatePicker
                    label={
                      <Typography variant="body1" color="textSecondary">
                        <Resource tag="PageCreateChallenge::Date" />
                      </Typography>
                    }
                    variant="inline"
                    format="dd/MM/yyyy"
                    type="lo"
                    disableToolbar
                    autoOk
                    fullWidth
                    minDate={new Date(state.startDate)}
                    value={state.endSubmitionDate}
                    minDateMessage={
                      <Typography variant="caption" color="error">
                        <Resource tag="PageCreateChallenge::MinDateMessage" />
                      </Typography>
                    }
                    onChange={handleSubmissionDateChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    type="time"
                    id="timeEnd"
                    onChange={changeSubmissionHour}
                    label={
                      <Typography variant="body2" color="textSecondary">
                        <Resource tag="PageCreateChallenge::Hour" />
                      </Typography>
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={timeNowEnd}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Box mt={6} className="ideas-visibility-container">
          <Box className="text-and-tooltip" display="flex" alignItems="center" mb={2}>
            <Box mr={1} fontSize="0.9rem" fontWeight="bold">
              <Resource tag="PageCreateChallenge::IdeasVisibility" />
            </Box>
            <Box boxSizing={"content-box"}>
            <ToolTip>
              <Resource tag="PageCreateChallenge::IdeasVisibility_Tip" />
            </ToolTip>
            </Box>
          </Box>
          <Grid container alignItems="center">
            <Grid item xs={9} sm={9} md={9} xl={9}>
              <Typography variant="caption">
                <Resource tag="PageCreateChallenge::IdeasVisibility_Detail" />
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} xl={3}>
              <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant="caption" style={{ textTransform: "uppercase" }}>
                      <Resource tag={"PageCreateChallenge::Workflow_OFF"} />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SwitchOnOff checked={state.isIdeasVisible} onChange={handleSwitch} name="checkedOnOff" />
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" style={{ textTransform: "uppercase" }}>
                      <Resource tag={"PageCreateChallenge::Workflow_ON"} />
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={8} className="challenge-title">
          <TextInputWCounter
            id="challenge-title"
            name="challenge-title"
            maxLength={100}
            label={<Resource tag="PageCreateChallenge::Title" />}
            placeholder={resource(culture, "PageCreateChallenge::Title_Tip")}
            fullWidth
            onChange={(e) => {
              setState({
                ...state,
                title: e.target.value,
              });
            }}
            value={state.title}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box className="challenge-description-sun-editor" mt={6}>
          <TextEditorInput
            value={description}
            culture={culture}
            propsI={challengeDescriptionProps}
            onChange={setDescription}
            charCounterLabelShow={true}
            maxCharCountShow={true}
          />
        </Box>
        <Box className="challenge-tags" mt={10}>
          <FormTag
            tooltip={<Resource tag="PageCreateChallenge::TagsTip" />}
            culture={props.culture}
            tags={state.tags}
            handler={handleData}
            placeholder={resource(culture, "PageCreateChallenge::Tags_Tip")}
          />
        </Box>
        <Box className="challenge-image" mt={8}>
          <Box className="text-and-tooltip" display="flex" alignItems="center" mb={2}>
            <Box
              mr={1}
              color={theme.palette.text.secondary}
              fontSize="0.8rem"
              fontWeight="700"
              style={{
                textTransform: "uppercase",
                transformOrigin: "top left",
              }}
            >
              <Resource tag="PageCreateChallenge::Image" />
            </Box>
            <ToolTip>
              <Resource tag="PageCreateChallenge::Image_Tip" />
            </ToolTip>
          </Box>
          <PhotoUpload photo={photo} setPhoto={setPhoto} />
        </Box>
      </Box>
    </form>
  );
}

export default CreateChallengeStepDetails;
