import React, { useState } from "react";
import sanitizeHtml from "sanitize-html";
import ReplyIcon from "@material-ui/icons/Reply";
import Resource from "components/Resources/Resource";
import MessageReplyDialog from "./MessageReplyDialog";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import MessageForwardDialog from "./MessageForwardDialog";
import MessageReplyAllDialog from "./MessageReplyAllDialog";
import { IProfileData } from "redux/reducers/profileReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import {
  Avatar,
  Box,
  Button,
  Container,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { securityTextEditor } from "utils/securityTextEditor";

interface IProps {
  culture: ICultureState;
  profileData: IProfileData;
  applicationId: any;
  msgDetailReplies: any[];
  msgDetail: any;
  getMessagesDetail: any;
  postMessageReply: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginBottom: "16px",
      width: "99%",
      background:
        "linear-gradient(90deg, rgba(242,242,242,0.3617822128851541) 0%, rgba(242,242,242,1) 29%, rgba(242,242,242,1) 66%, rgba(242,242,242,0.32816876750700286) 100%)",
    },
    boxGap: {
      gap: "10px",
    },
    greyColor: {
      color: theme.palette.text.secondary,
    },
    forwardIcon: {
      color: theme.palette.text.secondary,
      transform: "rotate(180deg)",
    },
  })
);

function MessageDetailReplies(props: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));
  const {
    culture,
    profileData,
    applicationId,
    msgDetailReplies,
    msgDetail,
    getMessagesDetail,
    postMessageReply,
  } = props;

  const lastIndex = msgDetailReplies?.length - 1;

  //State to active buttons
  const [openReply, setOpenReply] = useState(false);
  const [openReplyAll, setOpenReplyAll] = useState(false);
  const [openForward, setOpenForward] = useState(false);

  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);

  return (
    <>
      {msgDetailReplies?.map((replies, index) => {
        return (
          <Container key={replies?.messageId}>
            <Divider className={classes.divider} />
            <Box px={1} className="message-detail-content">
              <Box
                className="avatar-name-emailSender-date-container"
                mb={5}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                onClick={handleToggle}
              >
                <Box display="flex" className="avatar-name-email-container">
                  <Avatar alt="avatar" src={replies?.photoReply} />
                  <Box
                    className="name-emailSender-container"
                    display="flex"
                    flexDirection="column"
                    ml={2}
                    justifyContent="center"
                  >
                    <Box mb="4px" fontSize="0.8rem" fontWeight="700">
                      {replies?.fullName}
                    </Box>
                    <Box
                      color={theme.palette.text.secondary}
                      fontSize="0.7rem"
                      fontWeight="400"
                    >
                      {replies?.to}
                      texto-fixo-sem-retorno-API
                    </Box>
                  </Box>
                </Box>
                <Box textAlign="end" className="message-date">
                  <Typography color="textSecondary" variant="caption">
                    {replies?.date}
                  </Typography>
                </Box>
              </Box>
              <Box
                className="message-description"
                mb={5}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(replies?.message, securityTextEditor),
                }}
              />
              {!openReply &&
                !openReplyAll &&
                !openForward &&
                lastIndex === index && (
                  <Box
                    className={classes.boxGap + " actions-btn-to-msg"}
                    display={matchXs ? "grid" : "flex"}
                  >
                    <Button
                      className={classes.greyColor}
                      variant="outlined"
                      startIcon={<ReplyIcon className={classes.greyColor} />}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpenReply(true);
                      }}
                    >
                      <Resource tag={"PageMessages::Reply"} />
                    </Button>
                    <Button
                      className={classes.greyColor}
                      variant="outlined"
                      startIcon={<ReplyAllIcon className={classes.greyColor} />}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpenReplyAll(true);
                      }}
                    >
                      <Resource tag={"PageMessages::ReplyToAll"} />
                    </Button>
                    <Button
                      className={classes.greyColor}
                      variant="outlined"
                      startIcon={<ReplyIcon className={classes.forwardIcon} />}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpenForward(true);
                      }}
                    >
                      <Resource tag={"PageMessages::Forward"} />
                    </Button>
                  </Box>
                )}
              <MessageReplyDialog
                setIsOpen={setOpenReply}
                isOpen={openReply}
                culture={culture}
                applicationId={applicationId}
                profileData={profileData}
                getMessagesDetail={getMessagesDetail}
                postMessageReply={postMessageReply}
                authorId={msgDetail?.authorId}
                emailAuthor={msgDetail?.email}
                messageId={msgDetail?.messageId}
              />
              <MessageReplyAllDialog
                culture={culture}
                setIsOpen={setOpenReplyAll}
                isOpen={openReplyAll}
              />
              <MessageForwardDialog
                culture={culture}
                setIsOpen={setOpenForward}
                isOpen={openForward}
              />
            </Box>
          </Container>
        );
      })}
    </>
  );
}

export default MessageDetailReplies;
