import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Grid, Input, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import { FC, Fragment, useEffect, useState } from 'react';
import Resource, { resource } from 'components/Resources/Resource';
import PortfolioCarousel from 'components/PortfolioCarousel/PortfolioCarousel';
import { ThunkDispatch } from 'redux-thunk';
import { getAllChallengesInspiration, getNewChallengeList, IChallengeGetNewListAction } from 'redux/actions/ChallengeActions';
import { IChallengeAllList } from 'redux/types/challenges';
import { IApplication } from 'redux/reducers/tenantReducer';
import { ICultureState } from 'redux/reducers/cultureReducer';
import { IAllManageGroups } from 'redux/types/manageGroups';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IAppState } from 'redux/store/Store';
import { api_v3 } from 'config/api';
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Chart, {
  Series,
  ValueAxis,
  Export,
  Legend
} from 'devextreme-react/chart';
import { ICountry } from 'redux/reducers/countryReducer';
import {getAllChallenges, getAllCountries, getAllGroups} from "../../../redux/actions/DashboardActions";


const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      borderBottom: "solid 2px !important"
    },
    body: {
      fontSize: 14,
      padding: "10px !important"
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.secondary.contrastText,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: `${theme.palette.secondary.contrastText}`,
      borderRadius: 8,
      padding: 30,
      "& hr": {
        backgroundColor: `${theme.palette.primary.dark}`,
        maxWidth: "50%",
        height: 1,
        border: "none",
        marginTop: 30,
        marginBottom: 20
      }
    },
    container: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 11,
      padding: 16
    },
    label: {
      textTransform: "uppercase",
      color: theme.palette.grey[600],
      fontSize: 13
    },
    labelChart: {
      display: "flex",
      alignItems: "center",
      "& ~ div": {
        display: "none"
      }
    },
    textColor: {
      color: `${theme.palette.primary.dark}`,
    },
    chart: {
      paddingRight: 30,
      "& #center-container": {
        flexDirection: "column!important",
        "& ul": {
          display: "flex",
          width: "fit-content",
          margin: "0 auto"
        }
      }
    },
    userBacklog: {
      display: "flex",
      alignItems: "center",
      "&:nth-child(even)": {
        marginTop: 15,
        marginBottom: 15
      }
    },
    nameBacklog: {
      "& span": {
        paddingRight: 5,
        marginRight: 5,
        borderRight: "solid 2px"
      }
    },
    photoBacklog: {
      width: 40,
      height: 40,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    numberBacklog: {
      padding: 10
    },
    blockBacklog: {
      borderRight: "solid 1px #c1c1c1"
    },
    headerBacklog: {
      marginBottom: 30
    }
  })
);

interface IDispatchProps {
  getNewChallengeList: (options: object) => Promise<IChallengeGetNewListAction>;
  getAllChallengesInspiration: (options: object) => Promise<any>;
}

interface IProps {
  // path: string;
  closeButton?: any;
  selectedChallenge?: string;
  challengeList: IChallengeAllList[];
  application: IApplication;
  culture: ICultureState;
  allGroups: IAllManageGroups[];
  countries: ICountry[];
  setChallengeId?: (challenge: any) => void;
}


const SpecialComponent = (props: any) => {
  const { component, culture } = props;

  return resource(culture, component.seriesName);
};

interface Parametros {
  workspaceId: string;
}

const guid = "00000000-0000-0000-0000-000000000000";

const Validations = (props: IProps & IDispatchProps & Parametros) => {

  const classes = useStyles();
  const { workspaceId, culture } = props;
  const [challengeList, setChallengeList] = useState<IChallengeAllList[]>([]);
  const [allGroups, setAllGroups] = useState<IAllManageGroups[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [options, setOptions] = useState<{
    challenge: string[],
    groups: string[],
    countries: string[]
  }>({
    challenge: [guid],
    groups: [guid],
    countries: [guid]
  });

  useEffect(() => {
    getAllChallenges({workspaceId: workspaceId, status: 0 }).then((res) => {
      if (res) {
        setChallengeList(res!);
      }
    });
    getAllGroups({workspaceId: workspaceId }).then((res) => {
      if (res) {
        setAllGroups(res!);
      }
    });
    getAllCountries({workspaceId: workspaceId }).then((res) => {
      if (res) {
        setCountries(res!);
      }
    });
  }, []);

  const [data, setData] = useState({
    ideadToBeValidated: 0,
    ideasInTransition: 0,
    top10IdeaLongestTransitionTimeReturnLists: [],
    top5UserMostIdeasTransitionReturnLists: [],
    top5UserWaitingLongestList: [],
    //transitionBackIdeasToTransitionList: [],
    //transitionBacklogWaitingLongestList: [],
    validatedAndInTransitionPhaseList: [],
    validatedAndInTransitionTitleList: []
  });
  const [firstChart, setFirstChart] = useState([]);

  const handleSelect = (e: any, arr: any, property: string, stateOption: string[]) => {
    if (stateOption.includes(guid) && !e.target.value.includes(guid)) {
      setOptions({ ...options, [e.target.name]: [] })
    }
    else if (
      e.target.value.includes(guid) &&
      !stateOption.includes(guid)
    ) {
      let items: string[] = [guid];
      arr.forEach((element: any) => {
        items.push(element[property])
      });
      setOptions({ ...options, [e.target.name]: items })
    } else if (
      e.target.value.includes(guid) &&
      stateOption.includes(guid) &&
      e.target.value.length <= (arr.length + 1)
    ) {
      const idx = e.target.value.indexOf(guid);
      e.target.value.splice(idx, 1);
      setOptions({ ...options, [e.target.name]: e.target.value })
    } else {
      setOptions({ ...options, [e.target.name]: e.target.value })
    }
  }

  const send = (workspaceId: any) => {
    api_v3.post("dashboards/validationtransition", {
      workspaceId: workspaceId,
      Challenges: options.challenge,
      Countries: options.countries,
      Groups: options.groups
    })
      .then(r => {

        let mapChart: any = [];

        r.data?.validatedAndInTransitionPhaseList?.forEach((element: any) => {
          mapChart.push({
            name: element.phaseName,
            countValidation: element.countPhase
          })
        });

        r.data?.validatedAndInTransitionTitleList?.forEach((element: any) => {
          mapChart.push({
            name: element.titleName,
            countTransition: element.countPhase
          })
        });
        setData(r.data);
        setFirstChart(mapChart)

      })
      .catch(err => { console.log(err) })
  }

  const apply = () => {
    send(workspaceId);
  }

  useEffect(() => {
    send(workspaceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.container}
        >
          <Grid
            container
            alignItems="flex-end"
            justify="space-between"
            spacing={3}
          >
            <Grid item xs={12} sm={3}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageAnalytics::Challenge"}
                />
              </Typography>
              <Select
                labelId="checkbox-challenges-label"
                id="checkbox-challenges"
                name="challenge"
                multiple
                fullWidth
                onChange={(e) => handleSelect(e, challengeList, "challengeId", options.challenge)}
                value={options.challenge}
                input={<Input />}
                renderValue={(selected) => {
                  let names: string[] = [];
                  if (options.challenge.indexOf(guid) > -1) {
                    names.push(resource(props.culture, "PageAnalytics::All"))
                  } else {
                    (selected as string[]).forEach(element => {
                      if (element === guid) {
                        names.unshift(resource(props.culture, "PageAnalytics::All"))
                      } else if (challengeList?.filter((val) => val.challengeId === element)[0]) {
                        names.push(challengeList?.filter((val) => val.challengeId === element)[0].title)
                      }
                    });
                  }
                  return (names as string[]).join(', ')
                }}
              >
                <MenuItem value={guid}>
                  <Checkbox checked={options.challenge.indexOf(guid) > -1} />
                  <Resource tag="PageAnalytics::All" />
                </MenuItem>
                {challengeList?.map((list: any, index: number) => (
                  <MenuItem key={index} value={list.challengeId}>
                    <Checkbox checked={options.challenge.indexOf(list.challengeId) > -1 || options.challenge.indexOf(guid) > -1} />
                    <ListItemText primary={list.title} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageSettings::group"}
                />
              </Typography>
              <Select
                labelId="checkbox-groups-label"
                id="checkbox-groups"
                name="groups"
                multiple
                fullWidth
                onChange={(e) => handleSelect(e, allGroups, "groupId", options.groups)}
                value={options.groups}
                input={<Input />}
                renderValue={(selected) => {
                  let names: string[] = [];
                  if (options.groups.indexOf(guid) > -1) {
                    names.push(resource(props.culture, "PageAnalytics::All"))
                  } else {
                    (selected as string[]).forEach(element => {
                      if (element === guid) {
                        names.unshift(resource(props.culture, "PageAnalytics::All"))
                      } else if (allGroups?.filter((val) => val.groupId === element)[0]) {
                        names.push(allGroups?.filter((val) => val.groupId === element)[0].title)
                      }
                    });
                  }
                  return (names as string[]).join(', ')
                }}
              >
                <MenuItem value={guid}>
                  <Checkbox checked={options.groups.indexOf(guid) > -1} />
                  <Resource tag="PageAnalytics::All" />
                </MenuItem>
                {allGroups?.map((list: any, index: number) => (
                  <MenuItem key={index} value={list.groupId}>
                    <Checkbox checked={options.groups.indexOf(list.groupId) > -1 || options.groups.indexOf(guid) > -1} />
                    <ListItemText primary={list.title} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageSettings::Country"}
                />
              </Typography>
              <Select
                labelId="checkbox-country-label"
                id="checkbox-countri"
                name="countries"
                multiple
                fullWidth
                onChange={(e) => handleSelect(e, countries, "countryId", options.countries)}
                value={options.countries}
                input={<Input />}
                renderValue={(selected) => {
                  let names: string[] = [];
                  if (options.countries.indexOf(guid) > -1) {
                    names.push(resource(props.culture, "PageAnalytics::All"))
                  } else {
                    (selected as string[]).forEach(element => {
                      if (element === guid) {
                        names.unshift(resource(props.culture, "PageAnalytics::All"))
                      } else if (countries?.filter((val) => val.countryId === element)[0]) {
                        names.push(countries?.filter((val) => val.countryId === element)[0].title)
                      }
                    });
                  }
                  return (names as string[]).join(', ')
                }}
              >
                <MenuItem value={guid}>
                  <Checkbox checked={options.countries.indexOf(guid) > -1} />
                  <Resource tag="PageAnalytics::All" />
                </MenuItem>

                {countries?.map((list: any, index: number) => (
                  <MenuItem key={index} value={list.countryId}>
                    <Checkbox checked={options.countries.indexOf(list.countryId) > -1 || options.countries.indexOf(guid) > -1} />
                    <ListItemText primary={list.title} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={3}>
              <Box textAlign="right">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={apply}
                  style={{ color: "#ffffff" }}
                >
                  <Resource
                    tag={"PageDashboard::Apply"}
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PortfolioCarousel
            portfolioTag={"PageDashboard::IdeasValidated"}
          />
        </Grid>
        <Grid item xs={12} sm={9} className={classes.chart}>
          <Chart
            id="chart"
            dataSource={firstChart}
            rotated={true}
          > 
          { 
            true ? (
            <Series 
              argumentField="name"
              valueField="countValidation"
              name="PageDashboard::Validations"
              type="bar"
              color="#c0c0c0"
            />
            )
            : (
            <Series
              argumentField="name"
              valueField="countTransition"
              name="PageDashboard::Transitions"
              type="bar"
              color="#ffd700"
            />
            )
          }
            <ValueAxis
              tickInterval={1}
              valueMarginsEnabled={false}
            />
            <Legend
              verticalAlignment="bottom"
              horizontalAlignment="center"
              customizeText={(e: any) => SpecialComponent({ component: e, culture: culture })}
            />
            <Export enabled={false} />
          </Chart>

        </Grid>
        <Grid item xs={12} sm={3}>
          <div className={classes.card}>
            <div>
              <Typography variant="h2">
                {
                  data?.ideadToBeValidated ?? 0
                }
              </Typography>
              <Typography variant="body1">
                <Resource
                  tag={"PageDashboard::IdeasToValidated"}
                />
              </Typography>
            </div>
            <hr />
            <div>
              <span>
                <Typography variant="h2">
                  {data?.ideasInTransition ?? 0}
                </Typography>
              </span>
              <Typography variant="body1">
                <Resource
                  tag={"PageDashboard::IdeasInTransition"}
                />
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <PortfolioCarousel
            portfolioTag={"PageDashboard::Top10Ideas"}
          />
        </Grid>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Resource tag={"PageDashboard::CollumnIdea"} />
                </StyledTableCell>
                <StyledTableCell>
                  <Resource tag={"PageDashboard::CollumnChallenge"} />
                </StyledTableCell>
                <StyledTableCell>
                  <Resource tag={"PageDashboard::CollumnPhase"} />
                </StyledTableCell>
                  <Tooltip title={<Resource tag="PageDashboard::NRDeAvalicaoCompleta" />} placement='top'>
                    <StyledTableCell>
                      <svg width="21" height="20" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2085 3.70308C10.1469 3.51313 9.98288 3.37477 9.78538 3.34609L6.88451 2.92396L5.58707 0.292607C5.49895 0.113479 5.3166 0 5.11712 0C4.91763 0 4.73527 0.113479 4.64697 0.292607L3.34972 2.92396L0.44868 3.34609C0.251361 3.37477 0.0871903 3.51331 0.0257421 3.70326C-0.0360665 3.89321 0.0154749 4.10191 0.158373 4.24117L2.25734 6.28971L1.76179 9.18264C1.7281 9.37945 1.80901 9.57949 1.97047 9.69693C2.13193 9.81436 2.34582 9.8324 2.52242 9.73932L5.11712 8.37881L7.71181 9.74925C7.7884 9.78965 7.8722 9.82429 7.95563 9.82429H7.95922C8.24863 9.82429 8.48325 9.57463 8.48325 9.28492C8.48325 9.23333 8.47586 9.16621 8.46217 9.11913L7.97671 6.28808L10.0759 4.24027C10.2188 4.10083 10.2701 3.89322 10.2085 3.70308Z" fill="#FECD31" />
                        <path d="M15.5891 11.9604L12.2932 14.3269C11.8611 13.8813 11.1807 13.8613 10.8894 13.8201C9.91275 13.6822 10.1021 13.7581 9.14251 13.3854C8.12289 12.9893 7.07382 13.0403 6.62052 13.1376C5.84553 13.3452 5.35668 13.4966 5.24218 12.9724C5.12656 12.4432 5.50495 12.0927 5.68803 11.9814C5.89547 11.8773 6.39027 11.6769 6.70994 11.7075C7.0296 11.7382 7.67508 11.57 7.95786 11.4822C8.1421 11.3946 8.5038 11.1634 8.47667 10.9384C8.44954 10.7135 8.01937 9.96795 7.80767 9.62331L6.06604 6.80554C5.80174 6.39634 5.24485 5.47067 5.13174 5.04154C5.01863 4.61241 5.22784 4.3907 5.34659 4.33349C6.20633 3.80894 7.09905 5.82435 7.70611 6.83724C7.87673 7.12191 8.25368 7.71488 8.33895 7.69204C8.40785 7.67357 8.40372 7.5326 8.39478 7.47588C8.34262 7.28123 8.33518 7.19415 8.34136 7.14522C8.46077 6.68348 8.80848 6.66677 8.83428 6.65801C9.00877 6.59876 9.3487 6.73388 9.3858 6.7673C9.4229 6.80073 9.51787 6.80067 9.55647 6.7872C9.58736 6.77643 9.61679 6.68066 9.62764 6.63413C9.63037 6.32804 9.7584 6.12181 9.82206 6.05696C9.87027 6.00198 10.0106 5.88399 10.1865 5.85186C10.4064 5.8117 10.9012 6.11044 10.9441 6.11301C10.9784 6.11506 11.034 6.0936 11.0575 6.08262C11.234 5.68801 11.4143 5.56861 11.5108 5.55251C12.8983 5.33309 14.7425 7.97923 15.1122 9.45538L15.2612 10.0113C15.3067 10.3936 15.3969 11.2013 15.3938 11.3728C15.3906 11.5443 15.5227 11.836 15.5891 11.9604Z" fill="#888888" />
                      </svg>
                    </StyledTableCell>
                  </Tooltip>
                  <Tooltip title={<Resource tag="PageDashboard::TimeInThisStatus"/>} placement='top'>
                    <StyledTableCell>
                       <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 8H7V2" stroke="#888888" />
                          <circle cx="7" cy="7" r="6.5" stroke="#888888" />
                        </svg>  
                    </StyledTableCell>
                  </Tooltip>
                  <Tooltip title={<Resource tag="PageDashboard::ResponsibleBringingTheIdea"/>} placement='top'>
                    <StyledTableCell>
                      <svg width="20" height="16" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.1506 6.3004C9.92437 6.31965 9.85378 6.51699 9.84375 6.61325L9.84977 6.99227C9.8618 7.13065 10.0182 7.23894 10.1145 7.25699L14.3078 7.26301C13.8767 7.69217 12.9987 8.57456 12.9361 8.67082C12.8736 8.76708 12.906 8.90546 12.9361 8.95359C12.9662 9.00172 13.1226 9.16416 13.1226 9.16416C13.1828 9.22432 13.2791 9.34465 13.4174 9.34465C13.5429 9.34465 13.6039 9.2905 13.6461 9.26644L15.9503 6.95016C16.0369 6.80577 15.9864 6.64935 15.9503 6.58918L13.5799 4.23681C13.4403 4.19349 13.3412 4.21876 13.3091 4.23681C13.2069 4.31101 12.9867 4.4931 12.9241 4.62787C12.8615 4.76263 12.9261 4.90863 12.9662 4.96478L14.3018 6.3004H10.1506Z" fill="#888888" />
                        <ellipse cx="5.23063" cy="2.77953" rx="2.85173" ry="2.77953" fill="#888888" />
                        <path fill-rule="evenodd" clipRule="evenodd" d="M0 11.6405V10.341C0.405098 9.03141 2.22723 6.69266 5.40865 6.7071C7.97501 6.71874 9.46953 8.35175 10.133 9.64407H4.06792L5.40354 8.30845C5.44365 8.2523 5.50822 8.10631 5.44565 7.97154C5.38309 7.83678 5.16289 7.65468 5.06061 7.58048C5.02852 7.56243 4.92946 7.53716 4.78988 7.58048L2.41946 9.93286C2.38336 9.99302 2.33282 10.1494 2.41946 10.2938L3.7591 11.6405H0ZM5.08634 11.6405H10.4262V10.6007L4.06191 10.6067C4.33154 10.8751 4.77592 11.3207 5.08634 11.6405Z" fill="#888888" />
                      </svg>
                    </StyledTableCell>
                  </Tooltip>
            </TableRow>
            </TableHead>
            <TableBody>
              {data?.top10IdeaLongestTransitionTimeReturnLists?.map((row: any) => (     
                <StyledTableRow key={row.idea}>
                  <StyledTableCell>
                    <Link
                      key={row.idea}
                      component={RouterLink}
                      to={{ pathname: `/idea/details/${row.ideaID}` }}
                      target={"_blank"}
                    >
                      <Box mt={1.5} display={"flex"} alignItems={"center"}>
                        <Box ml={2}>
                          <Typography variant={"body1"} color={"primary"}>
                            <strong>{row.idea}</strong>
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">
                      {row.challenge}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">
                        {row.phase}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">
                      {row.favoris}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">
                      {row.totalDays}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography className={classes.textColor} variant="body1">
                      <u>
                        {row.totalUsers}
                      </u>
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <PortfolioCarousel
            portfolioTag={"PageDashboard::TransitionBacklog"}
          />
        </Grid>
        {data?.top5UserWaitingLongestList?.length > 0 &&
          <Grid item xs={12} sm={5}>
            <Typography variant="h4" className={classes.headerBacklog}>
              <Resource tag={"PageDashboard::BacklogTitle1"} />
            </Typography>
            <div className={classes.blockBacklog}>
              {
                data?.top5UserWaitingLongestList?.map((user: any, index: number) => {

                  let imageUrl = user.photo ? user.photo : `https://as1.ftcdn.net/v2/jpg/03/53/11/00/1000_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg`
                  return <TransitionBacklog
                    key={user.usernameIdea}
                    urlPhoto={imageUrl}
                    days={user.countDays}
                    name={user.usernameIdea}
                    position={index + 1}
                    typeCount="day"
                    culture={props.culture}
                  />
                })
              }
            </div>
          </Grid>
        }

        {data?.top5UserMostIdeasTransitionReturnLists?.length > 0 &&
          <Grid item xs={12} sm={5}>
            <Typography variant="h4" className={classes.headerBacklog}>
              <Resource tag={"PageDashboard::BacklogTitle2"} />
            </Typography>
            <div>
              {
                data?.top5UserMostIdeasTransitionReturnLists?.map((user: any, index: number) => {

                  let imageUrl = user.image ? user.image : `https://as1.ftcdn.net/v2/jpg/03/53/11/00/1000_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg`
                  return <TransitionBacklog
                    key={user.username}
                    urlPhoto={imageUrl}
                    days={user.totalIdeas}
                    name={user.username}
                    position={index + 1}
                    typeCount="idea"
                    culture={props.culture}
                  />
                })
              }
            </div>
          </Grid>
        }
      </Grid>
    </Fragment>
  );
}

interface ITransitionBacklog {
  urlPhoto: string,
  position: number,
  name: string,
  days: number,
  culture: any,
  typeCount: 'idea' | 'day' | 'participations'
}

const TransitionBacklog: FC<ITransitionBacklog> = (props: ITransitionBacklog) => {

  const {
    userBacklog,
    photoBacklog,
    nameBacklog,
    numberBacklog,
    textColor,
  } = useStyles();

  return (
    <div className={userBacklog}>
      <div>
        <div
          className={photoBacklog}
          style={{ backgroundImage: `url(${props.urlPhoto})` }}>
        </div>
      </div>
      <div className={numberBacklog}>
        <Typography className={textColor} variant="h2">
          # {props.position}
        </Typography>
      </div>
      <div className={nameBacklog}>
        <Typography variant="button">
          {props.name} 
        </Typography>
      </div>
      <div>
        <Typography variant="body1">
          {resource(props.culture, props.typeCount === "idea" ? "PageAnalytics::OverViewIdeas" : props.typeCount === "day" ? "PageAnalytics::OverViewDay" : "PageAnalytics::OverViewParticipations")} {props.days > 0 ? props.days : 0}
        </Typography>
      </div>
    </div>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    challengeList: store.challengeState.challengeAllList,
    application: store.tenantState.application,
    culture: store.cultureState,
    allGroups: store.manageGroupsState.allGroups,
    countries: store.countryReducerState.countries
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getNewChallengeList: (parameters: object) =>
      dispatch(getNewChallengeList(parameters)),

    getAllChallengesInspiration: (parameters: object) =>
      dispatch(getAllChallengesInspiration(parameters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Validations);