// Import Reducer type
import { Reducer } from 'redux';
import { ManagerActionTypes, ManagersActions, ManagerActionStatus } from '../../actions/ideas/IdeaManagersAction';

export interface IIdeasManagers {
    userId: string,
    fullName: string,
    photo: string
}

export interface IIdeaManagersState {
    readonly error: any;
    readonly status: string
    readonly managers: IIdeasManagers[];
    readonly previewManagers: IIdeasManagers[];
}

const initialIdeaManagersState = {
    status:ManagerActionStatus.ACTUAL_MANAGERS_LOADING,
    error: null,
    managers: [],
    previewManagers: []
}

export const ideasManagersReducer: Reducer<any, ManagersActions> = (
  state = initialIdeaManagersState,
  action
) => {
  switch (action.type) {
    // ---------* ALL IDEAS  *---------
    case ManagerActionTypes.PREVIEW_MANAGERS_LOADING: {
        return {
          ...state,
          status: action.status,
        };
      }
    case ManagerActionTypes.PREVIEW_MANAGERS_SUCCEEDED: {
    return {
        ...state,
        previewManagers:action.previewManagers,
        status: action.status
      }
    }
    case ManagerActionTypes.PREVIEW_MANAGERS_FAILED: {
      return {
          ...state,
          status: action.status,
          error: action.error
      };
    }

    // ---------* IDEAS IN COLLABORATION  *---------
    case ManagerActionTypes.ACTUAL_MANAGERS_LOADING: {
      return {
          ...state,
          status: action.status,
      };
    }

    case ManagerActionTypes.ACTUAL_MANAGERS_SUCCEEDED: {
      return {
          ...state,
          status: action.status,
          managers: action.managers
      };
    }

    case ManagerActionTypes.ACTUAL_MANAGERS_FAILED: {
      return {
          ...state,
          status: action.status,
          error: action.error
      };
    }

    case ManagerActionTypes.CHANGE_MANAGER_LOADING: {
      return {
          ...state,
          status: action.status
      };
    }
    case ManagerActionTypes.CHANGE_MANAGER_SUCCEEDED: {
      return {
          ...state,
          status: action.status
      };
    }

    case ManagerActionTypes.CHANGE_MANAGER_FAILED: {
      return {
          ...state,
          status: action.status,
          error: action.error
      };
    }
    default:
      return state;
  }
};