import {
  makeStyles,
  createStyles,
  Box,
  Divider,
  Typography,
  useTheme,
} from "@material-ui/core";
import Resource from "components/Resources/Resource";
import { ShortDateFormat } from "utils/FormatDate";
import newApp from "utils/reactNewApp";

const useStyles = makeStyles(() =>
  createStyles({
    divider: {
      width: "100%",
    },
    title: {
      textTransform: "uppercase",
      width: "100%",
      position: "absolute",
      bottom: 8,
    },
    box: {
      position: "relative",
    },
  })
);

interface IProps {
  title: string | Object;
  isActive: boolean;
  hasCursorIcon?: boolean;
  countIdeas?: number;
  endDate?: Date | string;
  countImplementedIdea?: number;
}

export default function StateRulerItem(props: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { isActive, countIdeas, title, countImplementedIdea, endDate, hasCursorIcon } = props;
  let color = endDate
    ? theme.palette.common.black
    : isActive
    ? theme.palette.primary.main
    : theme.palette.text.secondary;
  let size = countIdeas || countIdeas === 0 ? "120px" : "";
  return (
    <Box
      paddingX={1}
      marginLeft={0.5}
      display="flex"
      alignItems="center"
      style={{ height: size, cursor: hasCursorIcon === true ? "pointer" : "" }}
    >
      <Box width="100%">
        <Box
          color={color}
          height={45}
          overflow="hidden"
          className={classes.box}
        >
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Divider
            className={classes.divider}
            style={{ background: color, height: "8px" }}
          ></Divider>
        </Box>
        {(countIdeas || countIdeas === 0) && !endDate ? (
          <Box paddingTop={1} height={countImplementedIdea ? 20 : 40}>
            <Typography
              variant="caption"
              color={countIdeas === 0 ? "textSecondary" : "textPrimary"}
            >
              {countIdeas} <Resource tag="PageChallenges::IdeasInProgress" />
            </Typography>
          </Box>
        ) : (
          ""
        )}
        {countImplementedIdea ? (
          <Box paddingTop={1} height={20}>
            <Typography variant="caption">
              <b>
                {countImplementedIdea}{" "}
                <Resource tag="PageChallenges::IdeasImplemented" />
              </b>
            </Typography>
          </Box>
        ) : (
          ""
        )}
        {endDate ? (
          <Box paddingTop={1} height={countImplementedIdea ? 20 : 40}>
            <Typography variant="caption" color="textPrimary">
              {countIdeas} <Resource tag="PageChallenges::IdeasInProgress" />
              {newApp ? <ShortDateFormat date={endDate} /> : <>{endDate}</>}
            </Typography>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}
