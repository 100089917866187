import * as React from "react";
import { IResultCorrectionKeyTasks } from "../../redux/reducers/keyTasks/keyTasksReducer";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Theme,
  createStyles,
  makeStyles,
  Hidden,
  Typography,
  Box,
} from "@material-ui/core";
import Resource from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import ToolTip from "components/ToolTip/ToolTip";
import KeyTaskListItemCorrection from "components/KeyTaskListItem/KeyTaskListItemCorrection";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: "20px 0px",
    },
    tableTitles: {
      padding: "5px 10px 0px 0!important",
      color: " #767676",
      "font-weight": 500,
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "none",
      },
    },
    tooltipTimeleft: {
      //textTransform: 'capitalize'
    },
  })
);

interface IProps {
  correctionList: IResultCorrectionKeyTasks[];
  culture?: ICultureState;
}

function KeyTasksCorrectionList(props: IProps) {
  const { correctionList } = props;
  const classes = useStyles();
  return (
    <>
      {correctionList?.length ? (
        <>
          <Table
            // aria-label="simple table"
            className={classes.table}
          >
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableTitles}>
                  <Hidden xsDown>
                    <Typography>
                      <Resource tag="PageIdea::Idea_ColumnIdeia" />
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Hidden xsDown>
                    <Typography style={{ textTransform: "uppercase" }}>
                      <Resource tag="Common::Status" />
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Hidden implementation="css" xsDown>
                    <Typography style={{ textTransform: "uppercase" }}>
                      <Resource tag="Common::Challenge" />
                    </Typography>
                  </Hidden>
                </TableCell>
                <TableCell className={classes.tableTitles}>
                  <Hidden xsDown>
                    <Box display="flex" alignItems="center" gridGap={10}>
                      <Typography style={{ textTransform: "uppercase" }}>
                        <Resource tag="PageKeyTasks::TimeLeftColumn" />
                      </Typography>
                      <Box className={classes.tooltipTimeleft}>
                        <ToolTip
                          title={
                            <Resource tag="PageKeyTasks::TimeLeftColumnTitleTooltip" />
                          }
                        >
                          <Resource tag="PageKeyTasks::TimeLeftColumnTooltip" />
                        </ToolTip>
                      </Box>
                    </Box>
                  </Hidden>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {correctionList?.map((correction, index) => (
                <KeyTaskListItemCorrection
                  key={index}
                  title={correction?.ideaTitle}
                  ideaId={correction?.ideaId}
                  limitDate={correction?.limitDate}
                  limitDateResource={correction?.limitDateResource}
                  challenge={correction?.challengeTitle}
                  challengeId={correction?.challengeId}
                  status={correction?.status}
                />
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box textAlign="center" width="100%" p={5}>
          <Typography color="textSecondary" variant="caption">
            <Resource tag="PageKeyTasks::Error_NoKeyTasksAvailable" />
          </Typography>
        </Box>
      )}
    </>
  );
}
export default KeyTasksCorrectionList;
