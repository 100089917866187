import "./CreateIdea.css";
import { AnyAction } from "redux";
import IdeaForm from "./IdeaForm";
import history from "routes/history";
import IdeaFormV3 from "./IdeaFormV3";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import reactNewApp from "utils/reactNewApp";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import IdeaFormSkeleton from "./IdeaFormSkeleton";
import React, { useState, useEffect } from "react";
import { IIdeasSimilares } from "redux/types/ideas";
import { getAllUsers } from "redux/actions/UsersAction";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Resource, { resource } from "components/Resources/Resource";
import { IUsers, IUsersState } from "redux/reducers/usersReducers";
import { IChallengeState } from "redux/reducers/challenges/challengeReducer";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import {
  IChallengeList,
  IChallengeListItem,
  IIdeaForm,
  IIdeaFormV3,
} from "redux/types/challenges";
import {
  getIdeasForm,
  getIdeasFormV3,
  getAllChallengeList,
  IChallengeGetIdeaFormAction,
  IChallengeGetNewListAction,
} from "redux/actions/ChallengeActions";
import {
  postDraft,
  IDraftPostAction,
  postIdeas,
  postIdeasSimilares,
  IIdeasSimilaresGetAllSucceededAction,
} from "redux/actions/ideas/IdeaAction";

interface IDispatchProps {
  getAllChallengeList: (options: object) => Promise<IChallengeGetNewListAction>;
  getAllUsers: (options: object) => Promise<IUsersState>;
  getIdeasForm: (options: object) => Promise<IChallengeGetIdeaFormAction>;
  getIdeasFormV3: (params: object) => Promise<IChallengeState>;
  postDraft: (params: object, culture: ICultureState) => Promise<IDraftPostAction>;
  postIdeas: (params: object, culture: ICultureState) => Promise<any>;
  postIdeasSimilares: (
    options: object,
    culture: ICultureState
  ) => Promise<IIdeasSimilaresGetAllSucceededAction>;
}

interface IProps {
  workspaceId: string;
  ideaFormV3: IIdeaFormV3[];
  ideasForm: IIdeaForm;
  application: IApplication;
  culture: ICultureState;
  userList: IUsers[];
  challengeList: IChallengeList;
  ideaChallenge: IChallengeListItem;
  ideaId: string;
  challengeId: string;
  applicationId: any;
}

function CreateIdea(props: IProps & IDispatchProps) {
  const {
    workspaceId,
    ideaFormV3,
    ideasForm,
    getIdeasFormV3,
    getIdeasForm,
    getAllUsers,
    postIdeasSimilares,
    getAllChallengeList,
    ideaChallenge,
    applicationId,
    culture
  } = props;

  const [load, setLoad] = useState(true);
  const [challenge, setChallenge] = useState<IChallengeListItem>(ideaChallenge);
  const [isConfirmOpen, setIsConfirm] = useState(false);
  const [ideasSimilares, setIdeasSimilares] = useState<IIdeasSimilares>();
  const { executeRecaptcha } = useGoogleReCaptcha();

  let captcha: string = "";

  useEffect(() => {
    const options = {
      applicationId: applicationId,
      culture: props.culture,
      workspaceId: workspaceId,
    };
    
    Promise.all([
      getAllChallengeList(options)
    ]).finally(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (reactNewApp) {
      const options = {
        ideaId: props.ideaId,
        challengeId: props.challengeId,
        culture: props.culture,
        workspaceId: workspaceId,
      };
      setChallenge(props.ideaChallenge);

      getIdeasFormV3(options).then(() => {
        setLoad(false);
      });
    } else if (!reactNewApp && ideaChallenge) {
      const options = {
        ideaId: props.ideaId,
        challengeId: ideaChallenge?.challengeId,
        culture: props.culture,
      };
      setChallenge(props.ideaChallenge);
      getIdeasForm(options).then(() => {
        setLoad(false);
      });
    }
    getAllUsers({ q: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideaChallenge]);

  function handleCancel() {
    setIsConfirm(true);
  }

  async function handleSaveDraft(data: any) {
    const { postDraft } = props;

    executeRecaptcha &&
      (await executeRecaptcha("createIdeaPage").then((response) => {
        captcha = response;
      }));

    data.append("ChallengeId", challenge.challengeId);
    data.append("Captcha", captcha);
    if (!reactNewApp) {
      data.append("InitialStateId", Object.keys(ideasForm.initialIdeas)[0]);
    }

    if (!data.get("Title")) {
      toast.error(resource(props.culture, "MessageError::TitleRequired"));
      return;
    } else {
      postDraft(data, culture).then(() => {
        history.push("/drafts");
      });
    }
  }

  async function handleSubmit(data: any) {
    const { postIdeas } = props;

    executeRecaptcha &&
      (await executeRecaptcha("createIdeaPage").then((response) => {
        captcha = response;
      }));

    data.append("Captcha", captcha);
    data.append("ChallengeId", props.challengeId);
    if (!reactNewApp) {
      data.append("InitialStateId", Object.keys(ideasForm.initialIdeas)[0]);
    }

    await postIdeas(data, culture).then(
      (response) => {
        setTimeout(() => {
          history.push(`/idea/details/${response.data.ideaId}`);
        }, 1000);
      },
      (error) => {
        setLoad(false)
      }
    );
  }

  function getSimilarIdeas(e: any) {
    postIdeasSimilares({
      ...e,
      challengeId: props.challengeId,
      workspaceId,
      applicationId,
      pageNumber: 1,
      pageSize: 6,
    }, culture).then((response) => {
      setIdeasSimilares(response.ideasSimilares);
    });
  }

  return (
    <>
      {!load ? (
        <>
          {reactNewApp ? (
            <IdeaFormV3
              key={challenge?.challengeId}
              challenge={challenge}
              setChallenge={setChallenge}
              culture={props.culture}
              getIdeasFormV3={getIdeasFormV3}
              handleCancel={handleCancel}
              handleSubmitAction={handleSubmit}
              ideaFormV3={ideaFormV3}
              mode="idea"
              userList={props.userList}
              handleSaveDraft={handleSaveDraft}
              getIdeaSimilar={getSimilarIdeas}
              ideasSimilares={ideasSimilares}
              captcha={captcha!}
            />
          ) : (
            <IdeaForm
              key={challenge?.challengeId}
              challenge={challenge}
              setChallenge={setChallenge}
              culture={props.culture}
              getIdeasForm={getIdeasForm}
              handleCancel={handleCancel}
              handleSubmitAction={handleSubmit}
              ideaForm={ideasForm}
              mode="idea"
              userList={props.userList}
              handleSaveDraft={handleSaveDraft}
              getIdeaSimilar={getSimilarIdeas}
              ideasSimilares={ideasSimilares}
              captcha={captcha!}
            />
          )}
        </>
      ) : (
        <IdeaFormSkeleton />
      )}
      <PopUpConfirmation
        closeButton={() => setIsConfirm(false)}
        sendButton={() => window.history.back()}
        message={<Resource tag="PageEditIdea::ExitWithoutSaving" />}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirm}
      />
    </>
  );
}

const mapStateToProps = (store: IAppState, ownProps: any) => {
  const ideaId = ownProps?.match?.params?.ideaId;
  return {
    workspaceId: store.authState.auth.workspaceId,
    ideaFormV3: store.challengeState.ideaFormV3,
    ideasForm: store.challengeState.ideasForm,
    application: store.tenantState.application,
    culture: store.cultureState,
    userList: store.usersState.users,
    challengeList: store.challengeState.challengeList,
    ideaId: ideaId,
    challengeId: ownProps?.match?.params?.challengeId,
    ideaChallenge: store.challengeState.challengeList.result?.filter(
      (challenge) =>
        challenge.challengeId === ownProps?.match?.params?.challengeId
    )[0],
    applicationId: store.authState.auth.applicationId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllUsers: (options) => dispatch(getAllUsers(options)),
    getIdeasFormV3: (params: object) => dispatch(getIdeasFormV3(params)),
    getIdeasForm: (parameters: object) => dispatch(getIdeasForm(parameters)),
    postDraft: (params: object, culture: ICultureState) => dispatch(postDraft(params, culture)),
    postIdeas: (params: object, culture: ICultureState) => dispatch(postIdeas(params, culture)),
    postIdeasSimilares: (options, culture: ICultureState) => dispatch(postIdeasSimilares(options, culture)),
    getAllChallengeList: (parameters: object) => dispatch(getAllChallengeList(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateIdea);
