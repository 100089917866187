import React from 'react';
import EMIcon from 'components/Icon/Icon';
import { IResultMyActivitiesLikes, } from '../../redux/types/myActivities';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { TableRow, TableCell, Box, Typography, Hidden } from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      borderBottom: 'solid 1px #ccc',
      padding: '12px 0'
    },
    firstTableCell: {
      padding: '16px 10px 16px 0px!important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '250px',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    tableCell: {
      padding: '16px 10px 16px 0px!important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '250px',
      [theme.breakpoints.down('sm')]: {
        width: '0'
      }
    },
    linkItem: {
      '& p': {
        '& a': {
          'textDecoration': 'none',
          'color': '#333',
          'font-weight': '600',
          '-webkit-line-clamp': 3,
          '-webkit-box-orient': 'vertical',
          'overflow': "hidden",
          'text-overflow': "ellipsis",
          'margin-left': "10px"
        }
      }
    },
    date: {
      textTransform: 'lowercase',
      '&:first-line': {
      textTransform: 'capitalize',
      },
    }
  }),
);

interface IProps {
  resultLike: IResultMyActivitiesLikes,
}

function MyActivitiesLikes(props: IProps) {
  const { resultLike } = props;
  const ideaBackURL = "/idea/details";
  const classes = useStyles()
  return (
    <TableRow className={classes.tableRow}>
      <TableCell align="left" className={classes.firstTableCell}>
        <Box>
          <Hidden implementation="css" smDown>
            <Typography variant="body2" color="textSecondary" className={classes.date}>{resultLike.date}</Typography>
          </Hidden>
          <Box display="inline-flex" alignItems="center" className={classes.linkItem}>
            <EMIcon size={20} color={"#ccc"} icon="like-b" />
            <Typography variant="body1" color="textPrimary">
              <Link component={RouterLink} to={{
                pathname: `${ideaBackURL}/${resultLike?.ideaId}`
              }}>
                {resultLike.ideaTitle}
              </Link>
            </Typography>
          </Box>
          <Hidden implementation="css" mdUp>
            <Typography variant="body2" color="textSecondary" className={classes.date}>{resultLike.date}</Typography>
          </Hidden>
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box>
          <Hidden implementation="css" smDown>
            <Typography variant="body1" color="textPrimary">{resultLike.challenge}</Typography>
          </Hidden>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default MyActivitiesLikes