import EMIcon from "components/Icon/Icon";
import CloseIcon from "@material-ui/icons/Close";
import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { IAppState, store } from "redux/store/Store";
import { IDynamicWorkflow } from "redux/types/createChallenge";
import RestoreIcon from "@material-ui/icons/Restore";
import Resource from "components/Resources/Resource";
import PopupWorkflowValidation from "../WorkflowDialogs/PopupWorkflowValidation";
import PopupDeleteWorkflowStage from "../WorkflowDialogs/PopupDeleteWorkflowStage";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import { IWorkflowCard } from "redux/types/createChallenge";
import { ShortDateFormat } from "utils/FormatDate";
import { getChallengeDetailValidationAction } from "redux/actions/Workflow/WorkflowActions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ICultureState } from "redux/reducers/cultureReducer";

interface IDispatchProps {
  getValidationDetails: (
    params: object,
    culture: ICultureState
  ) => Promise<any>;
}

interface IProps {
  index: number;
  card: IWorkflowCard;
  deleteCard?: (phaseId: string) => any;
  handleSaveValue?: (value: any, currentIndex: number) => void;
  challengeId: string;
  workspaceId: string;
  culture: ICultureState;
  notifications?: Array<any>; 
  dynamicWorkflow?: IDynamicWorkflow; 
}

function ValidationDraggableCard({
  index,
  card,
  deleteCard,
  handleSaveValue,
  challengeId,
  notifications,
  dynamicWorkflow,
  getValidationDetails,
  culture,
  workspaceId,
}: IProps & IDispatchProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isValidatorDinamic, setIsValidatorDinamic] = useState(false);

  useEffect(() => {
    getValidationDetails({ phaseId: card.phaseId, workspaceId }, culture).then(
      (response) => setIsValidatorDinamic(response.isValidatorDinamic)
    );
  }, []);

  const closeButton = (
    <IconButton
      color="primary"
      onClick={() => setDeleteOpen(true)}
      style={{
        padding: 2,
        border: `2px solid ${theme.palette.primary.light}`,
      }}
    >
      <CloseIcon color="primary" fontSize={"small"} />
    </IconButton>
  );

  const getTextValidator = () => {
    //@ts-ignore
    if (dynamicWorkflow.optionSelect.length > 0) {
      return "PageCreateChallenge::Workflow_ValidationDynamic"
    }
    else {
      return "PageCreateChallenge::Workflow_Validation"

    }

  }

  const saveValue = useCallback(
    (newValue) => {
      handleSaveValue && handleSaveValue(newValue, index);
    },
    [index, handleSaveValue]
  );

  const deletePhase = () => {
    deleteCard && deleteCard(card.phaseId);
  };

  const usersValidators = isValidatorDinamic
    ? card?.usersValidatorsDinamic
    : card?.responsibles;
  

  return (
    <Box
      display={"inline-block"}
      width={250}
      height={"100%"}
      bgcolor="white"
      borderRadius={11}
      border="1px solid rgba(136, 136, 136, 0.2)"
      p={1.5}
    >
      {handleSaveValue && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            color="primary"
            onClick={() => setOpen(true)}
            style={{ padding: 4, background: theme.palette.primary.light }}
          >
            <EMIcon
              color={theme.palette.primary.main}
              size={16}
              icon="filters"
            />
          </IconButton>
        </Box>
      )}
      <Box py={1} px={2} bgcolor="white" style={{ opacity: 1, marginTop: handleSaveValue ? 0 : 8 }}>
        <Typography
          variant="h4"
          color="textPrimary"
          style={{ display: "block", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {card.order}.{" "}
          {card?.title || card?.cultures?.[0].title || (
            <Resource tag="PageCreateChallenge::Workflow_Validation" />
          )}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          <Resource tag="PageCreateChallenge::Workflow_Type" />
          <Box component="span" style={{ textTransform: "uppercase" }}>
            {" "}
            <Resource tag="PageCreateChallenge::Workflow_Validation" />
          </Box>
        </Typography>
        <Box mt={1} mb={2}>
          <Divider style={{ width: "100%" }} />
        </Box>
        <Box height={320} display="flex" flexDirection="column" justifyContent="space-between">
          <Box style={{ overflow: "auto" }}>
            {notifications && (
              <Box>
                <Typography color="textSecondary" variant="body2">
                  <Resource tag={"PageCreateChallenge::Notifications"} />:
                </Typography>
                {notifications.map((noti, index) => (
                  <Box p={1} key={index}>
                    <Typography variant="body2" color="error">
                      <Resource tag={noti.message.replace(".", "::")} />
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Box>
              <Typography color="textSecondary" variant="body2">
                <Resource tag="PageCreateChallenge::Workflow_Validator" />:
              </Typography>

              {usersValidators.length > 0 &&
                usersValidators.map((val, index) => (
                  <Box
                    key={`validator${index}`}
                    display="flex"
                    alignItems="center"
                    mt={1}
                  >
                  <Avatar
                    style={{ width: 35, height: 35, marginRight: 8 }}
                      src={val.photo}
                      alt={val.fullName}
                  />
                  <Typography color="textPrimary" variant="body2">
                      {val.fullName}
                  </Typography>
                </Box>
                ))}
            </Box>
          </Box>
          <Box>
            <Typography color="textSecondary" variant="body2">
              <Resource tag="PageCreateChallenge::Workflow_TimePhase" />
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              position="relative"
              mt={1}
            >
              <Box display="flex" alignItems="center">
                <RestoreIcon color="disabled" />
                <Box ml={1}>
                  <Typography color="textPrimary" variant="body2">
                    {card?.endDate || card?.endDays ? (
                      <Box>
                        {(card?.endDate && (
                          <Box display="flex" alignItems="center">
                            <Resource tag="PageCreateChallenge::Workflow_Until" />{" "}
                            <Box ml={0.4}>
                              <ShortDateFormat date={card?.endDate} />
                            </Box>
                          </Box>
                        )) || (
                          <Box display="flex" alignItems="center">
                            {card?.endDays}{" "}
                            <Box ml={0.4}>
                              <Resource tag="PageCreateChallenge::Workflow_DaysPerIdea" />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      "-------"
                    )}
                  </Typography>
                </Box>
              </Box>
              {deleteCard && (
                <Box position="absolute" top={0} right={-16}>
                  {closeButton}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {open && (
        <PopupWorkflowValidation
          open={open}
          setOpen={(openVal) => setOpen(openVal)}
          saveValue={saveValue}
          phaseId={card.phaseId}
          challengeId={challengeId}
          order={card.order}
        />
      )}
      {deleteCard && deleteOpen && (
        <PopupDeleteWorkflowStage
          open={deleteOpen}
          setOpen={(openVal) => setDeleteOpen(openVal)}
          confirmAction={deletePhase}
        />
      )}
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => ({  
  dynamicWorkflow: store.createChallengeState.dynamicWorkFlow,
  culture: store.cultureState,
  workspaceId: store.authState.auth.workspaceId,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  getValidationDetails: (params: object, culture: ICultureState) =>
    dispatch(getChallengeDetailValidationAction(params, culture)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidationDraggableCard);
