import sanitizeHtml from "sanitize-html";
import ErrorIcon from "@material-ui/icons/Error";
import ToolTip from "components/ToolTip/ToolTip";
import Resource, { resource } from "components/Resources/Resource";
import { PageStatePrize } from "redux/types/createPrize";
import { IManageGroups } from "redux/types/manageGroups";
import { securityTextEditor } from "utils/securityTextEditor";
import { ICultureState } from "redux/reducers/cultureReducer";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Box, Typography, useTheme, Button, Grid, Divider, CardMedia, createStyles, makeStyles, Theme } from "@material-ui/core";

interface IProps {
  culture: ICultureState;
  setActive: any;
  steps: PageStatePrize;
  groups: IManageGroups;
  allGroupsSelected?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerImage: {
      cursor: "pointer",
      position: "relative",
      width: "100%",
      maxWidth: "348px",
    },
    containerPrizeImg: {
      cursor: "pointer",
      position: "relative",
      width: "250px",
      borderRadius: "8px",
      marginTop: "8px",
    },
    Img: {
      width: "100%",
      borderRadius: "8px",
    },
    closeButton: {
      position: "absolute",
      top: "9px",
      right: "-4px",
      color: theme.palette.text.primary,
      transform: "translate(-50%,-50%)",
      backgroundColor: theme.palette.common.white,
      padding: 0,
      "&:hover": {
        opacity: 1,
        backgroundColor: theme.palette.grey[300],
      },
    },
  })
);

function CreatePrizesStepConfirmation(props: IProps) {
  const theme = useTheme();
  const { steps, setActive, groups, culture, allGroupsSelected } = props;
  const classes = useStyles()

  const returnNameOfTypePrize = () => {
    switch (steps?.stepDetail?.prizeType) {
      case 2:
        return resource(culture, "PageCreatePrize::BuyNow")


      case 1:
        return resource(culture, "PageCreatePrize::Auction")

    }
  }
  let dataGroups = groups?.result
    ?.map((data) => {
      if (steps?.stepParticipants.groupsId.includes(data.groupId)) {
        return data;
      } else {
        return "";
      }
    })
    .filter((group) => group);

  const printDate = (date: any) => {
    date = new Date(date);
    return (
      <Box display="flex" alignItems="center" gridGap={10}>
        <Typography variant="body2">
          {`${date?.getDate() >= 10 ? date?.getDate().toString() : "0" + date?.getDate().toString()}/${date?.getMonth() >= 10 ? (date?.getMonth() + 1).toString() : "0" + (date?.getMonth() + 1)
            }/${date?.getFullYear()}`}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          <Resource tag="PageCreatePrize::At" />
        </Typography>
        <Typography variant="body2">
          {`${date?.getHours() >= 10 ? date?.getHours().toString() : "0" + date?.getHours().toString()}:${date?.getMinutes() >= 10 ? date?.getMinutes().toString() : "0" + date?.getMinutes().toString()
            }`}
        </Typography>
      </Box>
    );
  };
  return (
    <Box className="prize-confirmation-container">
      {steps?.stepDetail?.value &&
        steps?.stepDetail?.title &&
        steps?.stepDetail?.description &&
        steps?.stepDetail?.image ? (
        <></>
      ) : (
        <Box className="prize-confirmation-header">
          <Box fontWeight={700} fontSize="1rem" lineHeight={2} component="p">
            <Resource tag="PageCreatePrize::ThePrizeIsNotReady" />
          </Box>
          <Box fontSize="0.9rem" component="p">
            <Resource tag="PageCreatePrize::ReviewTheReportBelow" />
          </Box>
        </Box>
      )}
      <Box className="details-content-container">
        <Box
          className="paper-return-details-step"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="8px 16px"
          borderRadius={6}
          margin="40px 0 30px 0"
          style={{ background: "#F2F2F2" }}
        >
          <Box display="flex" alignItems="center" lineHeight={0}>
            <Box mr={1}>
              {steps?.stepDetail?.value &&
                steps?.stepDetail?.title &&
                steps?.stepDetail?.description &&
                steps?.stepDetail?.image ? (
                <CheckCircleOutlineIcon style={{ color: theme.palette.success.main }} fontSize="small" />
              ) : (
                <ErrorIcon color="error" fontSize="small" />
              )}
            </Box>
            <Box fontWeight={600} fontSize="1.1rem">
              <Resource tag="PageCreatePrize::Details" />
            </Box>
          </Box>
          <Button color="primary" variant="text" size="small" onClick={() => setActive(1)}>
            <Resource tag="PageCreatePrize::ReturnToThisStep" />
          </Button>
        </Box>
        <Box className="type-container" mb={3}>
          <Box className="type" display="flex" mb={1} style={{ gap: 8 }}>
            <Typography color="textSecondary" variant="caption">
              <Resource tag="PageCreatePrize::PrizeType" />:
            </Typography>
            <Typography color="textPrimary" variant="caption">
              {returnNameOfTypePrize()}
            </Typography>
          </Box>
          <Box className="prize-confirmation-start-date" display="flex" mb={1} style={{ gap: 8 }}>
            <Box component="span" fontSize="0.8rem" fontWeight="bold">
              <Resource tag="PageCreatePrize::ActivationDate" />:
            </Box>
            {printDate(steps?.stepDetail?.activationDate)}
          </Box>
          <Box className="prize-confirmation-end-date" display="flex" mb={1} style={{ gap: 8 }}>
            <Box component="span" fontSize="0.8rem" fontWeight="bold">
              {steps?.stepDetail?.prizeType === 2 ? (
                <>
                  <Resource tag="PageCreatePrize::SaleEndDate" />:
                </>
              ) : (
                <>
                  <Resource tag="PageCreatePrize::SaleEndDate" />:
                </>
              )}
            </Box>
            {printDate(steps?.stepDetail?.endDate)}
          </Box>
        </Box>
        <Box className="image-container" mb={3} display="flex" flexDirection="column">
          <Typography color="textSecondary" variant="caption">
            <Resource tag="PageCreatePrize::PrizeImage" />
          </Typography>
          {steps?.stepDetail?.image ? (
            <Box width="250px" borderRadius="8px" mt="8px">
              <CardMedia
                className={classes.Img}
                style={{
                  backgroundImage: `url(${steps?.stepDetail?.image})`,
                  maxWidth: "250px",
                  height: "220px",
                }}
              />

            </Box>
          ) : (
            <Typography color="error" variant="caption">
              <Resource tag="PageCreatePrize::MissingPrizeImage" />
            </Typography>
          )}
        </Box>
        <Box className="title-container" mb={3} display="flex" flexDirection="column">
          <Typography color="textSecondary" variant="caption">
            <Resource tag="PageCreatePrize::PrizeTitle" />
          </Typography>
          {steps?.stepDetail?.title ? (
            <Box fontWeight="bold" fontSize="0.8rem" mt={1}>
              {steps?.stepDetail?.title}
            </Box>
          ) : (
            <Typography color="error" variant="caption">
              <Resource tag="PageCreatePrize::MissingPrizeTitle" />
            </Typography>
          )}
        </Box>
        <Box className="description-container" mb={3} display="flex" flexDirection="column">
          <Typography color="textSecondary" variant="caption">
            <Resource tag="PageCreatePrize::PrizeDescription" />
          </Typography>
          {steps?.stepDetail?.description ? (
            <Box
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(steps?.stepDetail?.description, securityTextEditor),
              }}
            />
          ) : (
            <Typography color="error" variant="caption">
              <Resource tag="PageCreatePrize::MissingPrizeDescription" />
            </Typography>
          )}
        </Box>
        <Box className="units-container" mb={3} display="flex" style={{ gap: 50 }}>
          <Box>
            <Typography color="textSecondary" variant="caption">
              <Resource tag="PageCreatePrize::UnitsAvaiable" />
            </Typography>
            <Typography>{steps?.stepDetail?.unit ? steps?.stepDetail?.unit : 1}</Typography>
          </Box>
          {steps?.stepDetail?.prizeType === 2 && (
            <Box>
              <Box display="flex" alignItems="center" style={{ gap: 10 }}>
                <Typography color="textSecondary" variant="caption">
                  <Resource tag="PageCreatePrize::MaxUnitsPerUser" />
                </Typography>
                <ToolTip>
                  <Resource tag="PageCreatePrize::MaxUnitsPerUserTooltip" />
                </ToolTip>
              </Box>
              <Typography>{steps?.stepDetail?.maxUnitPerUser ? steps?.stepDetail?.maxUnitPerUser : 1}</Typography>
            </Box>
          )}
        </Box>
        <Box className="value-container" mb={3} display="flex" flexDirection="column">
          <Typography color="textSecondary" variant="caption">
            <Resource tag="PageCreatePrize::PrizeValue" />
          </Typography>
          {steps?.stepDetail?.value ? (
            <Box component="span" fontSize="0.8rem" mt={1}>
              <strong>{steps?.stepDetail?.value}</strong> <Resource tag="PageCreatePrize::Points" />
            </Box>
          ) : (
            <Typography color="error" variant="caption">
              <Resource tag="PageCreatePrize::MissingPrizeValue" />
            </Typography>
          )}
        </Box>
      </Box>
      <Box className="participants-content-container">
        <Box
          className="paper-return-participants-step"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="8px 16px"
          borderRadius={6}
          margin="40px 0 30px 0"
          style={{ background: "#F2F2F2" }}
        >
          <Box display="flex" alignItems="center" lineHeight={0}>
            <Box mr={1}>
              {steps?.stepParticipants?.groupsId?.length !== 0 || allGroupsSelected ? (
                <CheckCircleOutlineIcon style={{ color: theme.palette.success.main }} fontSize="small" />
              ) : (
                <ErrorIcon color="error" fontSize="small" />
              )}
            </Box>
            <Box fontWeight={600} fontSize="1.1rem">
              <Resource tag="PageCreatePrize::Participants" />
            </Box>
          </Box>
          <Button color="primary" variant="text" size="small" onClick={() => setActive(2)}>
            <Resource tag="PageCreatePrize::ReturnToThisStep" />
          </Button>
        </Box>
        <Grid container>
          <Grid item lg={2}>
            <Box component="span" fontSize="0.8rem" fontWeight="bold">
              <Resource tag="PageCreatePrize::ParticipantGroups" />:
            </Box>
          </Grid>
          <Grid item lg={10}>
            {steps?.stepParticipants?.groupsId?.length || allGroupsSelected ? (
              <>
                {allGroupsSelected &&
                  <Resource tag="PageCreatePrize::AllWorkspace" />
                }
                {dataGroups?.map((data: any, index: any) => {
                  return (
                    <Box
                      key={index}
                      className="participants-groups-item-container"
                      display="flex"
                      alignItems="center"
                      mb={1}
                      ml={2}
                      style={{ gap: 8 }}
                    >
                      <PersonOutlineIcon fontSize="small" style={{ color: theme.palette.grey[500] }} />
                      <Typography variant="subtitle1">{data?.groupName}</Typography>
                      <Divider orientation="vertical" style={{ height: 18 }} />
                      <Typography variant="subtitle1" color="textSecondary">
                        <Resource tag="PageCreatePrize::Members" args={[data?.numberOfMember]} />
                      </Typography>
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box ml={2}>
                <Typography color="error" variant="caption">
                  <Resource tag="PageCreatePrize::MissingParticipantGroups" />
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default CreatePrizesStepConfirmation;
