import React from 'react'
import './UserWinning.css';
import { Avatar } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Resource from '../Resources/Resource'
import getOrdinal from 'utils/toOrdinal';
//Example:
//<UserWinning userid="1" avatar="http://i.stack.imgur.com/Dj7eP.jpg" name="Sarah Philips" points={8020} rank={1}/>

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        thumbnail: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        }
    }),
);
interface IProps {
    userid: string;
    image: string;
    name: string;
    value: number ;
    position: number;
    mode: string;
    cultureTag?: string;
}

function UserWinning(props: IProps) {
    const classes = useStyles()
    const { mode, cultureTag } = props
    const position = getOrdinal(props.position, props?.cultureTag!)
    let UserWinningLabel;
    const bidValue = new Intl.NumberFormat(cultureTag).format(props.value);

    if (mode === 'bid') {
        UserWinningLabel = <Resource tag="PagePrizes::Prize_ActiveAuctions_UserIsWinningUnitForPoints" args={[props.name, position, bidValue]}/> 
    } else if (mode === 'buy-now') {
        UserWinningLabel = <> <strong>{props.name}</strong></>
    } else if (mode === 'previous') {
        UserWinningLabel = props.value ? <Resource tag="PagePrizes::Prize_ActiveAuctions_WinnerUserBid" args={[props.name, bidValue]}/> : <strong>{props.name}</strong>
    }

    return (
        <div className='UserWinning'>
            <Avatar src={props.image} alt={props.name} className={classes.thumbnail} />

            { /* TODO Translate */}
            <div className="UserWinningLabel">
                {UserWinningLabel}
            </div>

        </div>
    )
}

export default UserWinning;