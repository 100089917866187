import React from 'react';
import './NextPrizeCard.css';
import { IResultPrizeFutures } from '../../redux/reducers/prizeReducer';
import { Card, Paper, CardMedia, Typography, makeStyles, Box, createStyles, Theme, MenuItem, useTheme} from '@material-ui/core';
import Resource from '../Resources/Resource';
import MoreOptionsButton from 'components/MoreOptionsButton/MoreOptionsButton';
import newApp from 'utils/reactNewApp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  root: {
    display: "grid",
    gridGap: "10px",
    width: "100%",
    cursor: 'pointer'
  },
  bidText: {
    display: "flex",
    alignItems: "center",
    padding: "10px 16px",
  },
  currentBid: {
    height: "40px",
    width: "100%",
    textTransform: 'uppercase'
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  media: {
    width: "100%",
    height: "210px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    backgroundBlendMode: "color",
    borderRadius: "6px",
    color: theme.palette.common.white,
  },
})
);

interface IProps {
  prize?: IResultPrizeFutures,
  openGrid: any;
  index: any
  onClick: any;
  cultureTag: any;
  permission: string;
  requestManagePrize: (prizeId: string, type: number) => void;
}

function NextPrizeCard(props:IProps){
  // render
    const { prize, index, onClick, openGrid, cultureTag, permission, requestManagePrize } = props;
    const classes = useStyles();
    const formatPoints = new Intl.NumberFormat(cultureTag).format(
      Number(prize?.value)
    );
    const theme = useTheme()

    function handleManagePrize(prizeId: string, type: number) {
      requestManagePrize(prizeId, type)
    }

    return (
    <Box
      onClick={() => onClick(prize, index)}
      className={classes.root}
      style={{ height: openGrid ? "400px" : "250px" }}
    >
      <Card>
        <Box width="100%">
          <CardMedia className={classes.media} style={{ backgroundImage: `url(${prize?.image})`, }}>
            <Box
              padding="11px 11px 9px 10px"
              width="100%"
              height="205px"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              position="relative"
              textAlign="left"
              ml="4px"
            >
              {newApp ? (
                <Box
                  style={{
                    position: "absolute",
                    top: "16px",
                    right: '16px',
                    padding: 6,
                  }}
                >
                  {permission === 'Admin' || permission === 'ChallengeManager' ? (
                    <>
                      <MoreOptionsButton
                        translate="-72px, 42px"
                        iconColor={theme.palette.primary.contrastText}
                      >
                        <MenuItem onClick={(e) => {e.stopPropagation();handleManagePrize(prize?.prizeId!, 1)}}>
                          <Resource tag="PagePrizes::EditPrize" />
                        </MenuItem>
                        <MenuItem onClick={(e) => {e.stopPropagation();handleManagePrize(prize?.prizeId!, 2)}}>
                          <Resource tag="PagePrizes::Duplicate" />
                        </MenuItem>
                        <MenuItem onClick={(e) => {e.stopPropagation();handleManagePrize(prize?.prizeId!, 3)}}>
                          <Resource tag="PagePrizes::Delete" />
                        </MenuItem>
                      </MoreOptionsButton>
                    </>
                  ) : ''}
                </Box>
              ) : (
                ""
              )}
              <Typography variant="h4" className={classes.cardTitle}>
                {prize?.title}
              </Typography>
              <Box marginTop={0.5}>
              <Resource tag="PagePrizes::Auction_GoingLiveDate" args={[prize?.timeRemaining]} />
              </Box>
            </Box>
          </CardMedia>
        </Box>
        <Paper className={classes.currentBid}>
          <Box className={classes.bidText}>
            <Typography>
              <Resource tag={prize?.isBuyNow ? "PagePrizes::Auction_BuyValue" : "PagePrizes::Auction_InitialBid"} args={[formatPoints]} />
            </Typography>
          </Box>
        </Paper>
      </Card>
    </Box>
    );
}

export default NextPrizeCard
