import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Resource from "../../components/Resources/Resource";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    "&$.MuiIconButton-root": {
      padding: 4,
    },
    label: {
      fontSize: "1rem",
      textTransform: "uppercase",
    },
  })
);

interface State {
  password: string;
  showPassword: boolean;
}

interface IProps {
  confirmP?: boolean;
  handler: any;
  newUser?: boolean;
}

function InputAdornments(props: State & IProps) {
  const classes = useStyles();

  const [values, setValues] = React.useState<State>({
    password: "",
    showPassword: false,
  });
  const { confirmP, newUser } = props;
  useEffect(() => {
    props.handler(values.password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.password]);
  const handleChange = (prop: keyof State) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className={classes.root}>
      <FormControl fullWidth>
        <InputLabel className={classes.label}>
          {confirmP ? (
            <Resource
              tag={
                newUser
                  ? "PageLogin::ResetPassword_NewPasswordRepeat"
                  : "PageProfile::Confirm_Password"
              }
            />
          ) : (
            <Resource
              tag={
                newUser
                  ? "PageLogin::LoginOrganization_Password"
                  : "PageProfile::Password"
              }
            />
          )}
        </InputLabel>
        <Input
          mui-auto-fill-cancel="true"
          type={values.showPassword ? "text" : "password"}
          value={values.password}
          onChange={handleChange("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                className={classes["&$.MuiIconButton-root"]}
                // aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
}

export default InputAdornments;
