import "./ActiveAuctionPrizes.css";
import { UX } from "../../config/ux";
import Resource from "../Resources/Resource";
import React, { Component, createRef } from "react";
import PrizesSkeleton from "../PrizePage/PrizesSkeleton";
import { ICultureState } from "redux/reducers/cultureReducer";
import ActiveAuctionPrizeDescription from "../ActiveAuctionPrizeCard/ActiveAuctionPrizeDescription";
import { Collapse, Grid, Box, Typography, Hidden, Button } from "@material-ui/core";
import { IResultPrizeActiveAuctionUser } from "../../redux/reducers/prizeReducer";
import ActiveAuctionPrizeCard from "../ActiveAuctionPrizeCard/ActiveAuctionPrizeCard";
import XDialog from "common/X/XDialog";

interface IProps {
  activeAuctionPrizes: IResultPrizeActiveAuctionUser[];
  points: number;
  noText?: boolean;
  postBidPrize: (prizeId: string, value: number) => void;
  requestManagePrize: (prizeId: string, type: number, isCanceling?: boolean) => void;
  pageLink?: any;
  culture?: ICultureState;
  profile?: string;
  permission: string;
}

interface IState {
  activePrize: any;
  activePrizeIndex: number;
  loading: boolean;
  openGrid: boolean;
  openCloseAuction: boolean;
  prizeId: string;
}

class ActiveAuctionsPrizes extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      activePrize: null,
      activePrizeIndex: 1,
      openGrid: false,
      openCloseAuction: false,
      prizeId: ''
    };
  }
  private myRef = createRef<HTMLDivElement>();
  scrollTo(scroll: boolean) {
    if (scroll) {
      window.scrollTo({
        top: this.myRef.current?.offsetTop && this.myRef.current.offsetTop - 75,
        behavior: "smooth", // smooth scroll.
      });
    }
  }

  componentDidMount() {
    const interval = setTimeout(() => {
      this.setState({ loading: false });
    }, UX.skeleton.minDelay);
    
    return () => clearInterval(interval)
  }

  render() {
    const {
      activeAuctionPrizes,
      points,
      postBidPrize,
      requestManagePrize,
      pageLink,
      noText,
      culture,
      profile,
      permission
    } = this.props;
    const maxCards = 6;
    const formatPoints = new Intl.NumberFormat(
      culture?.culture?.cultureTag
    ).format(points || 0);

    const handleClick = (cardData: any, index: number) => {
      if (
        this.state?.activePrize !== null &&
        cardData.prizeId === this.state?.activePrize?.prizeId
      ) {
        this.setState({
          activePrize: null,
          activePrizeIndex: 0,
          openGrid: false,
        });
      } else {
        this.setState({
          activePrize: cardData,
          activePrizeIndex: index,
          openGrid: true,
        });
      }
    };

    const bidPrize = (prizeId: string, value: number) => {
      this.setState({ activePrize: null, activePrizeIndex: 0 });
      postBidPrize(prizeId, value);
    };

    const handleOpenCloseAuction = (open: boolean, prizeId: string) => {
      this.setState({openCloseAuction: open, prizeId: prizeId});
    };

    function handleManagePrize(prizeId: string, type: number, isCanceling: boolean) {
      requestManagePrize(prizeId, type, isCanceling)
    }

    return (
      <>
        {!noText && (
          <>
            <Grid container>
              <Box mt={4}>
                <Typography>
                  <Resource
                    tag={"PagePrizes::Prize_PointsAvaliabe"}
                    args={[formatPoints]}
                  />
                </Typography>
              </Box>
            </Grid>

            <Grid container>
              <Box mt={4} mb={2}>
                <Typography variant="h3">
                  <Resource
                    tag={"PagePrizes::Prize_ActiveAuctions"}
                    args={[formatPoints]}
                  />
                </Typography>
              </Box>
            </Grid>
          </>
        )}
        <Grid container style={{ position: "relative" }} spacing={3}>
          {activeAuctionPrizes &&
            activeAuctionPrizes?.slice(0, maxCards).map((cardData, index) => {
              if (cardData?.prizeId === pageLink?.prizeId) {
                this.scrollTo(pageLink?.scrollDown);
              }
              if (
                (index === 0 || index !== 2) &&
                activeAuctionPrizes.length !== index + 1
              ) {
                return (
                  <React.Fragment key={cardData?.prizeId}>
                    {!this.state.loading ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        style={{ width: "100%" }}
                      >
                        <div
                          ref={
                            cardData?.prizeId === pageLink?.prizeId
                              ? this.myRef
                              : ""
                          }
                        >
                          <ActiveAuctionPrizeCard
                            permission={permission}
                            profile={profile}
                            setOpenCloseAuction={handleOpenCloseAuction}
                            postBidPrize={postBidPrize}
                            requestManagePrize={requestManagePrize}
                            culture={culture}
                            pageLink={pageLink}
                            index={index + 1}
                            openGrid={
                              index + 1 === this.state.activePrizeIndex &&
                              this.state.openGrid
                            }
                            activeAuction={cardData}
                            onClick={handleClick}
                          />
                        </div>
                      </Grid>
                    ) : (
                      <Grid
                        key={cardData?.prizeId}
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        style={{ width: "100%" }}
                      >
                        <PrizesSkeleton />
                      </Grid>
                    )}
                  </React.Fragment>
                );
              } else
                return (
                  <React.Fragment key={cardData?.prizeId}>
                    {!this.state.loading ? (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        style={{ width: "100%" }}
                      >
                        <div
                          ref={
                            cardData?.prizeId === pageLink?.prizeId
                              ? this.myRef
                              : ""
                          }
                        >
                          <ActiveAuctionPrizeCard
                            profile={profile}
                            permission={permission}
                            setOpenCloseAuction={handleOpenCloseAuction}
                            postBidPrize={postBidPrize}
                            requestManagePrize={requestManagePrize}
                            culture={culture}
                            pageLink={pageLink}
                            openGrid={
                              index + 1 === this.state.activePrizeIndex &&
                              this.state.openGrid
                            }
                            index={index + 1}
                            activeAuction={cardData}
                            onClick={handleClick}
                          />
                        </div>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        style={{ width: "100%" }}
                      >
                        <PrizesSkeleton />
                      </Grid>
                    )}
                    <Hidden smDown>
                      <Collapse
                        style={{
                          position: "absolute",
                          top:
                            this.state.activePrizeIndex > 3
                              ? "calc(286px * 2)"
                              : "calc(276px)",
                          margin: "4px",
                          width: "100%",
                        }}
                        in={this.state.activePrize != null}
                      >
                        <ActiveAuctionPrizeDescription
                          cultureTag={culture?.culture?.cultureTag}
                          postBidPrize={bidPrize}
                          handleClick={handleClick}
                          result={this.state.activePrize}
                          itemIndex={this.state.activePrizeIndex}
                          index={index <= 2 ? 1 : 2}
                        />
                      </Collapse>
                    </Hidden>
                  </React.Fragment>
                );
            })}
        </Grid>
        {/* <div className="view-all">
          <Button >
            {activeAuctionPrizes?.length > 6 ? <Resource tag={"Common::ViewAll"}/> : ""}
          </Button>
        </div> */}

        <XDialog
          width="800px"
          maxWidth="md"
          open={this.state.openCloseAuction}
          setOpen={() => this.setState({openCloseAuction: false})}
          title={<Resource tag='PagePrizes::CloseAuction'/>}
          actions={
            <Box textAlign="right">
              <Button
                color="primary"
                size="small"
                variant="outlined"
                style={{ marginRight: 8 }}
                onClick={() => this.setState({openCloseAuction: false})}
              >
                <Resource tag="PagePrizes::Cancel" />
              </Button>
              <Button
                color="primary"
                size="small"
                variant="outlined"
                style={{ marginRight: 8 }}
                onClick={() => handleManagePrize(this.state.prizeId, 4, false)}
              >
                <Resource tag="PagePrizes::AwardThePrize" />
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => handleManagePrize(this.state.prizeId, 4, true)}
              >
                <Resource tag="PagePrizes::DeletePrize" />
              </Button>
            </Box>
          }
        >
          <Box textAlign="center">
            <Box fontWeight="bold">
              <Resource tag="PagePrizes::PrizeWantInactivate" />
            </Box>
            <Resource tag="PagePrizes::PrizeWantAward" />
          </Box>
        </XDialog>
      </>
    );
  }
}

export default ActiveAuctionsPrizes;
