import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import history from "./history";
import { IAppState, store } from "../redux/store/Store";
import { ICultureState } from "../redux/reducers/cultureReducer";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import { IAuthUser } from "../redux/reducers/authReducer";
import {
  makeStyles,
  Theme,
  createStyles,
  CssBaseline,
  AppBar,
  Container,
  useScrollTrigger,
  Slide,
  Zoom,
  Fab,
  Box,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { RouteProps } from "react-router";
import { ITenantState } from "redux/reducers/tenantReducer";
import { IProfileData } from "redux/reducers/profileReducer";
import { IWorkspaceReducer } from "redux/reducers/workspaceReducer";
import { ICultureSetCulture, setCultureAndResources } from "redux/actions/CultureActions";
import { ThunkDispatch } from "redux-thunk";

interface IProps {
  authUser: IAuthUser;
  culture: ICultureState;
  children: RouteProps["children"];
  userProps: any;
  on: boolean;
  tenants: ITenantState;
  profileData: IProfileData;
  workspaces: IWorkspaceReducer;
}

interface ComponentProps {
  children: React.ReactElement;
  window?: () => Window;
  isVisibleButtonHelp: boolean;
}

const drawerWidth = 210;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: "#ffffff",
      [theme.breakpoints.up("xl")]: {
        maxWidth: "1440px",
        margin: "auto",
        boxShadow: "0 4px 6px 0 rgb(85 85 85 / 8%), 0 1px 20px 0 rgb(0 0 0 / 7%), 0px 1px 11px 0px rgb(0 0 0 / 7%)",
      },
    },
    // drawer: {
    //   [theme.breakpoints.up('sm')]: {
    //     width: drawerWidth,
    //     flexShrink: 0,
    //   },
    // },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        paddingTop: 10,
        paddingBottom: 10
      },
      [theme.breakpoints.up("xl")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        paddingLeft: drawerWidth,
        paddingTop: 10,
        maxWidth: "1440px",
        margin: "auto",
        right: "auto",
      },
      [theme.breakpoints.down("sm")]: {
        width: `100%`,
        marginLeft: 0,
        paddingTop: 10,
      },
      backgroundColor: "#ffffff",
    },
    appBarRanking: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    // appBarRanking: {
    //   [theme.breakpoints.up("sm")]: {
    //     width: `calc(100% - ${drawerWidth}px)`,
    //     marginLeft: drawerWidth,
    //     paddingTop: 10,
    //     paddingBottom: 10,
    //     height: "100%",
    //   },
    //   [theme.breakpoints.down("sm")]: {
    //     width: `100%`,
    //     marginLeft: 0,
    //     paddingTop: 10,
    //   },
    //   backgroundColor: theme.palette.primary.main,
    //   color: "white",
    //   position: "relative",
    // },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
      color: theme.palette.primary.main,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        minHeight: "100vh"
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3),
        width: `100%`,
        marginLeft: 0,
      },
    },
    scrollTop: {
      position: "fixed",
      [theme.breakpoints.up("lg")]: {
        right: "calc(50% - 1104px/2)!important",
      },
      bottom: theme.spacing(4),
      right: ({ isVisibleButtonHelp }: any) =>
        isVisibleButtonHelp ? theme.spacing(15) : theme.spacing(6),
    },
    fab: {
      width: "38px",
      height: "38px",
    },
    centerLoading: {
      margin: "0px auto",
      height: "100vh",
    },
  })
);

function ScrollTop(props: ComponentProps) {
  const classes = useStyles({ isVisibleButtonHelp: props.isVisibleButtonHelp });
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.scrollTop}
      >
        {props.children}
      </div>
    </Zoom>
  );
}

function HideOnScroll(props: ComponentProps) {
  const { window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
}
const LoggedInRoute: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles({});
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { tenants, authUser, culture, workspaces } = props;

  function getResourcesHandler(cultureTag: string) {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store?.dispatch;
    dispatch(setCultureAndResources(cultureTag));
    return store?.getState().cultureState;
  }

  useEffect(() => {
    if(!culture?.culture?.cultureTag || props?.profileData?.cultureTag !== culture?.culture?.cultureTag)
    {
    props?.profileData?.cultureTag === ""
      ? getResourcesHandler("en-GB")
      : getResourcesHandler(props?.profileData?.cultureTag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props?.authUser?.isAuthenticated === false) {
      history.push("/splash");
    } else if (props?.profileData?.agreedTermsAndPolicyDate === "") {
      history.push("/acceptterms");
    } else if (
      props?.profileData?.agreedRegulationVersion !== null &&
      props?.profileData?.agreedRegulations === ""
    ) {
      history?.push("/acceptRegulations");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let pathname = props.userProps.location.pathname.split("/")[1];
  let pathComplement = props.userProps.location.pathname.split("/")[2];
  
  return (
    <Route
      render={() =>
        props?.authUser?.isAuthenticated ? (
          <>
            <div className={classes.root}>
              <CssBaseline />
              {culture.statusCulture === "SUCCEEDED" && culture.culture.cultureTag !== undefined ? (
                <>
                  {!props.on ? (
                    <HideOnScroll
                      isVisibleButtonHelp={props.authUser.isVisibleButtonHelp}
                    >
                      <AppBar
                        position="fixed"
                        className={
                          pathname === "ranking"
                            ? (classes.appBar + " " + classes.appBarRanking)
                            : (classes.appBar)
                        }
                        elevation={0}
                      >
                        <Header
                          complement={pathComplement}
                          culture={props?.culture!}
                          path={pathname === "" ? "/feed" : pathname}
                          handleDrawerToggle={handleDrawerToggle}
                        />
                      </AppBar>
                    </HideOnScroll>
                  ) : (
                    <AppBar
                      position="fixed"
                      className={
                        pathname === "ranking"
                          ? classes.appBarRanking
                          : classes.appBar
                      }
                      elevation={0}
                    >
                      <Header
                        complement={pathComplement}
                        culture={props?.culture!}
                        path={pathname === "" ? "/feed" : pathname}
                        handleDrawerToggle={handleDrawerToggle}
                      />
                    </AppBar>
                  )}
                  <nav
                  // aria-label="mailbox folders"
                  >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Sidebar
                      allowToSeePrizes={authUser?.allowToSeePrizes}
                      tenants={tenants}
                      isAllowedBackOffice={authUser.backOfficeAllowed}
                      backofficeUrl={
                        authUser.backOfficeAllowed ? authUser.backOfficeURL : ""
                      }
                      mobileOpen={mobileOpen}
                      handleDrawerToggle={handleDrawerToggle}
                      WorkspaceUser={workspaces}
                    />
                  </nav>
                  <main className={classes.content}>
                    <div className={classes.toolbar} id="back-to-top-anchor" />
                    <Container maxWidth="md">
                      <>{props.children}</>
                    </Container>
                  </main>
                  <ScrollTop
                    isVisibleButtonHelp={props.authUser.isVisibleButtonHelp}
                  >
                    <Fab
                      className={classes.fab}
                      color="primary"
                      // aria-label="scroll back to top"
                    >
                      <KeyboardArrowUpIcon />
                    </Fab>
                  </ScrollTop>
                </>
              ) : (
                <>
                  <Box
                    height="100%"
                    display="flex"
                    className={classes.centerLoading}
                  >
                    <Box m="auto">
                      <CircularProgress size={60} className="icon" />
                    </Box>
                  </Box>
                </>
              )}
            </div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/splash",
              state: {
                expiredLogin: true,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  authUser: state.authState.auth,
  culture: state.cultureState,
  on: state.profileState.on,
  tenants: state.tenantState,
  profileData: state.profileState.profileData,
  workspaces: state.workSpacesState.workspaces
});

export default connect(mapStateToProps)(LoggedInRoute);
