import React from 'react';
import './Title.css';

/* Example
    import Title from '../../components/Title/Title';

    <Title title="Active"> </Title>
*/

interface IProps {
    title: string | JSX.Element
}

export default class Title extends React.PureComponent<IProps> {
    public render() {
        return (

            <div className="title">
                <h4>{this.props.title}</h4>
            </div>

        )
    }
}