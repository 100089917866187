import React from 'react';
import Line from 'react-chartjs-2';
import './MiniLine.css'
import Resource from '../Resources/Resource';
import NumberFormat from "react-number-format";

/* Example:
   <MiniLine data={[0, 100, 50, 100, 200, 300, -250]} 
             height={130} showPoints={true}
             variation={30} />
 */

export default class MiniLine extends React.Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
    }

    datasetKeyProvider = () => { return Math.random(); }

    render() {
        const { data = [0, 0, 0, 0, 0, 0], points = 0 } = this.props;
        let bgColor;
        let borderColor;

        //Atual Points
        let atualPoints = points;
        let classPoints = 'MiniLinePoints ';
        if (data[data.length-1] - data[0] === 0) {
            classPoints += ' MiniLineZero';
            bgColor = '#AFDFF6';
            borderColor = '#609BBD';
        } else {
            if (data[data.length - 1] > data[0]) {
                atualPoints = '+' + atualPoints;
                classPoints += ' MiniLinePositive';
                bgColor = '#e3f9e7';
                borderColor = '#3cd343';
            } else {
                classPoints += ' MiniLineNegative';
                bgColor = '#fee6e7';
                borderColor = 'red';
            }
        }

        //Labels
        const labels = new Array(data.length);
        labels.fill('');

        const dataChart = {
            labels: labels,
            datasets: [
                {
                    backgroundColor: bgColor,
                    borderColor: borderColor,
                    borderWidth: 2,
                    data: data
                }
            ]
        };

        // Configuration options go here
        const options = {            
            elements: {
                point: {
                    radius: 0
                }
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            legend: {
                display: false,
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: false,
                        drawBorder: false,
                        drawOnChartArea: false
                    },
                    ticks: {
                        beginAtZero: false,
                        display: false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display: false,
                        drawOnChartArea: false
                    },
                    ticks: {
                        beginAtZero: false,
                        display: false
                    }
                }]
            }
        }

        return (
            <div className="MiniLine" style={{ width: '100%', margin: '0' }} >
                <Line
                    {...this.props}
                    ref={this.chartReference}
                    datasetKeyProvider={this.datasetKeyProvider}
                    type='line'
                    data={dataChart} options={options}
                />
                { this.props.showPoints === true
                    ? 
                    <div className={classPoints}>
                        <strong>
                            <NumberFormat value={atualPoints && Math.round(atualPoints)} displayType={'text'} thousandSeparator={true} />
                        </strong>  
                        <Resource tag={"Common::points"} />
                    </div>
                    : <></>
                }
            </div>
        );
    }
}