import React, { useState } from 'react';
import { IMyActivitiesStarsPrivate, IMyActivitiesStarsCollaborative } from 'redux/types/myActivities';
import MyActivityStarList from './MyActivityStarList';
import Resource from '../../components/Resources/Resource';
import { AppBar, Box, Tab } from '@material-ui/core';
import { TabPanel, TabList, TabContext } from '@material-ui/lab';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DynamicInfiniteScroll from 'views/KeyTasks/DynamicInfiniteScroll';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      padding: '12px 0'
    },
    titlesTab: {
      '& span': {
        textTransform: 'capitalize'
      }
    }
  }),
);

interface IProps {
  starCollaborative?: IMyActivitiesStarsPrivate;
  starPrivate?: IMyActivitiesStarsCollaborative;
  showMore: (typeTabs?: string, pageNumber?: number, pageSize?: number, isInfinite?: boolean) => Promise<any>
}

// TODO: API not realized by backend team
function MyActivityStarListTabs(props: IProps) {
  const { starCollaborative, starPrivate, showMore } = props
  const [starTab, setTab] = useState('1')
  const classes = useStyles()
  const handleChange = (event: any, newValue: string) => {
    setTab(newValue)
  };

  return (
    <section>
      <TabContext value={starTab}>
        <AppBar position="static" elevation={0}>
          <TabList onChange={handleChange}>
            <Tab value="1" label={
              <Box className={classes.titlesTab}>
                <Resource tag="PageMyActivity::StarRating_Collaborative" args={[starCollaborative?.result ? starCollaborative?.meta?.total : '0']} />
              </Box>      
            } />
            <Tab value="2" label={
              <Box className={classes.titlesTab}>
                <Resource tag="PageMyActivity::StarRating_Private" args={[starPrivate?.result ? starPrivate?.meta?.total : '0']} />
              </Box>
            } />
          </TabList>
        </AppBar>
        <TabPanel className={classes.tabPanel} value="1">
          {starCollaborative?.result ?
            <DynamicInfiniteScroll 
              dataList={starCollaborative} 
              getAction={(pageNumber,pageSize) => showMore(starTab, pageNumber, pageSize, true)}
              pageSize={6}
              //isAutomatic
            >
              <MyActivityStarList starCollaborative={starCollaborative} />
            </DynamicInfiniteScroll>
            :
            <div className="MyActivity-error" style={{ textAlign: 'center' }}>
              <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
            </div>
          }
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="2">
          {starPrivate?.result ?
            <DynamicInfiniteScroll 
              dataList={starPrivate} 
              getAction={(pageNumber,pageSize) => showMore(starTab, pageNumber, pageSize, true)}
              pageSize={6}
              //isAutomatic
            >
              <MyActivityStarList starPrivate={starPrivate} />
            </DynamicInfiniteScroll>
            :
            <div className="MyActivity-error" style={{ textAlign: 'center' }}>
              <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
            </div>
          }
        </TabPanel>
      </TabContext>
    </section>
  );
}
export default MyActivityStarListTabs;