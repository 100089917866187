
export const RegexPassword = (password: string) => {
  let reg:RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
  return reg.test(password)
}

export const RegexEight = (password: string) => {
  let reg:RegExp = /^.{8,}$/;
  return reg.test(password)
}

export const RegexUpper = (password: string) => {
  let reg:RegExp = /^.*?[A-Z]/;
  return reg.test(password)
}

export const RegexLower = (password: string) => {
  let reg:RegExp = /^.*?[a-z]/;
  return reg.test(password)
}

export const RegexNumber = (password: string) => {
  let reg:RegExp = /^.*?[0-9]/;
  return reg.test(password)
}

export const RegexCaracter = (password: string) => {
  let reg:RegExp = /^.*?[^\w\s]/;
  return reg.test(password)
}