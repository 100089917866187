import {
    TableRow,
    TableCell,
    makeStyles,
    Theme,
    createStyles,
    Typography,
    Hidden,
    useMediaQuery,
} from "@material-ui/core";
import "moment/locale/pt";
import "moment/locale/es";
import "./KeyTaskListItem.scss";
import React, { useState } from "react";
import Resource from "../Resources/Resource";
import { useTheme } from "@material-ui/core/styles";
import Truncate from "react-truncate";
import KeyTaskIdeaCorrectionPopUp from "components/KeyTaskIdeaDetailPopUp/KeyTaskIdeaCorrectionPopUp";
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      title: {
        color: "#333",
      },
      pageTitle: {
        marginTop: 24,
      },
      closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
      tableRow: {
        borderTop: "solid 1px #ccc",
      },
      tableCell: {
        padding: "24px 10px 24px 0px!important",
        maxWidth: "250px",
      },
      allListCell: {
        display: "flex",
        padding: "24px 10px 24px 0px!important",
        width: "100%",
        justifyContent: "space-between",
      },
      truncate: {
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        "text-overflow": "ellipsis",
        maxWidth: 600,
      },
      uppercase: {
        textTransform: "uppercase",
      },
      small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    })
  );
  
  interface IProps {
    title?: string;
    ideaId?: string;
    challenge?: string;
    challengeId?: string;
    status?: string;
    limitDate?: number;
    limitDateResource?: string;
  }

  function KeyTaskListItemCorrection(props: IProps) {
  
    const {
      title,
      status,
      ideaId,
      challenge,
      challengeId,
      limitDate,
      limitDateResource,
    } = props;

    const [isOpen, setOpen] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.up("sm"));
    const stateEvaluation = limitDateResource?.replace(".", "::")!;

    return (
      <>
        <TableRow className={classes.tableRow}>
          {title && (
            <TableCell
              onClick={() => setOpen(true)}
              style={{ cursor: "pointer" }}
              className={classes.tableCell}
            >
              <Typography
                color="textPrimary"
                variant="body2"
                className={classes.truncate}
              >
                <strong>{title}</strong>
              </Typography>
              {status && (
                <Hidden implementation="css" smUp>
                  <Typography color="textPrimary" variant="body2">
                    {status}
                  </Typography>
                </Hidden>
              )}
            </TableCell>
          )}
          {status && match && (
            <TableCell className={classes.tableCell}>
              <Typography color="textPrimary" variant="body2">
                {status}
              </Typography>
            </TableCell>
          )}
          {challenge && (
            <TableCell className={classes.tableCell}>
              <Truncate lines={1} width={320} ellipsis={<span>...</span>}>
                <Typography color="textPrimary" variant="body2">
                  {challenge}
                </Typography>
              </Truncate>
            </TableCell>
          )}
          {limitDate && (
            <TableCell className={classes.tableCell}>
              <Typography color="textPrimary" variant="body2">
                <strong>
                  <Resource tag={stateEvaluation} args={[limitDate]} />
                </strong>
              </Typography>
            </TableCell>
          )}
        </TableRow>
        {isOpen && (
          <KeyTaskIdeaCorrectionPopUp
            isOpen={isOpen}
            setOpen={setOpen}
            ideaId={ideaId!}
            ideaChallenge={challengeId}
            closeButton={() => setOpen(false)}
          />
        )}
      </>
    );
  }
  
  export default KeyTaskListItemCorrection;
  