import { Reducer } from 'redux';
import {
    InvestmentActions,
    InvestmentActionsTypes,
} from '../actions/InvestmentActions';

export interface IInvestment {
    totalInvestedAmount: number,
    totalCurrentValue: number,
    result: [{
        ideaId:string,
        ideaTitle: string,
        invested: number,
        currentInvestmentValue: number,
        investmentDate: Date,
        graphIdeaRate: {
            additionalProp1: number,
            additionalProp2: number,
            additionalProp3: number,
        }
    }],
    meta: {
        currentPage: number,
        from: number,
        to: number,
        lastPage: number,
        perPage: number,
        total: number
    },
}

export interface IInvestmentState {
  readonly investment: IInvestment;
  readonly showMoreInvestment: boolean;
}

const initialCharacterState: IInvestmentState = {
  investment: {
    totalInvestedAmount: 0,
    totalCurrentValue: 0,
    result: [{
        ideaId:"",
        ideaTitle: "",
        invested: 0,
        currentInvestmentValue: 0,
        investmentDate: new Date(),
        graphIdeaRate: {
            additionalProp1: 0,
            additionalProp2: 0,
            additionalProp3: 0,
        }
    }],
    meta: {
        currentPage: 0,
        from: 0,
        to: 0,
        lastPage: 0,
        perPage: 0,
        total: 0
    },
  },
  showMoreInvestment: false,
};

export const investmentReducer: Reducer<IInvestmentState, InvestmentActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case InvestmentActionsTypes.GET_INVESTMENTS: {
      return {
        ...state,
        investment: action.investment,
      };
    }
    case InvestmentActionsTypes.SHOW_MORE: {
      return {
        ...state,
        showMoreInvestment: !state.showMoreInvestment
      }
    }
    default:
      return state;
  }
};