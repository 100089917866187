import EMIcon from "components/Icon/Icon";
import CloseIcon from "@material-ui/icons/Close";
import React, { useCallback, useState } from "react";
import RestoreIcon from "@material-ui/icons/Restore";
import Resource from "components/Resources/Resource";
import PopupDeleteWorkflowStage from "../WorkflowDialogs/PopupDeleteWorkflowStage";
import { Avatar, Box, Divider, IconButton, Typography, useTheme } from "@material-ui/core";
import { IWorkflowCard } from "redux/types/createChallenge";
import { ShortDateFormat } from "utils/FormatDate";
import PopupWorkflowDevelopment from "../WorkflowDialogs/PopupWorkflowDevelopment";
import { AvatarGroup } from "@material-ui/lab";

interface IProps {
  challengeId: string;
  index: number;
  card: IWorkflowCard;
  handleSaveValue?: (value: any, currentIndex: number) => void;
  deleteCard?: (phaseId: string) => any;
  notifications?: Array<any>;
}

function DevelopmentDraggableCard({ index, card, challengeId, deleteCard, handleSaveValue, notifications }: IProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const closeButton = (
    <IconButton
      color="primary"
      onClick={() => setDeleteOpen(true)}
      style={{
        padding: 2,
        border: `2px solid ${theme.palette.primary.light}`,
      }}
    >
      <CloseIcon color="primary" fontSize={"small"} />
    </IconButton>
  );

  const saveValue = useCallback(
    (newValue) => {
      handleSaveValue && handleSaveValue(newValue, index);
    },
    [index, handleSaveValue]
  );

  const deletePhase = () => {
    deleteCard && deleteCard(card.phaseId);
  };

  return (
    <Box
      display={"inline-block"}
      width={250}
      height={448}
      bgcolor="white"
      borderRadius={11}
      border="1px solid rgba(136, 136, 136, 0.2)"
      p={1.5}
      style={{ opacity: 1 }}
    >
      {handleSaveValue && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            color="primary"
            onClick={() => setOpen(true)}
            style={{ padding: 4, background: theme.palette.primary.light }}
          >
            <EMIcon color={theme.palette.primary.main} size={16} icon="filters" />
          </IconButton>
        </Box>
      )}
      <Box py={1} px={2} bgcolor="white" style={{ opacity: 1, marginTop: handleSaveValue ? 0 : 8 }}>
        <Typography
          variant="h4"
          color="textPrimary"
          style={{ display: "block", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {card.order}.{" "}
          {card?.title || card?.cultures?.[0].title || <Resource tag="PageCreateChallenge::Workflow_Development" />}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          <Resource tag="PageCreateChallenge::Workflow_Type" />{" "}
          <Resource tag="PageCreateChallenge::Workflow_Development" />
        </Typography>
        <Box mt={1} mb={2}>
          <Divider style={{ width: "100%" }} />
        </Box>
        <Box overflow={notifications ? "auto" : "none"} height={320}>
          <Box
            minHeight={320}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gridGap={notifications ? 32 : 0}
          >
            {notifications && notifications?.length > 0 && (
              <Box>
                <Typography color="textSecondary" variant="body2">
                  <Resource tag={"PageCreateChallenge::Notifications"} />:
                </Typography>
                {notifications.map((noti, index) => (
                  <Box p={1} key={index}>
                    <Typography variant="body2" color="error">
                      <Resource tag={noti.message.replace(".", "::")} />
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Box>
              <Typography color="textSecondary" variant="body2">
                <Resource tag="PageCreateChallenge::Workflow_IdeaDevelopers" />:
              </Typography>
              <Box mt={1}>
                {/* @ts-ignore */}
                {card?.allowResponsiblesChooseEditors ? (
                  <Box>
                    <Box fontWeight={500}>
                      <Resource tag="PageCreateChallenge::Workflow_SelectionIdeaByIdea" />
                    </Box>
                    <Resource tag="PageCreateChallenge::Workflow_PreviousToThis" />
                  </Box>
                ) : (
                  card?.responsibles &&
                  card?.responsibles.map((responsible: any) => (
                    <Avatar
                      key={responsible.id}
                      style={{ width: 35, height: 35, marginRight: 8 }}
                      src={responsible.photo}
                      alt={responsible.name}
                    />
                  ))
                )}
              </Box>
            </Box>
            <Box>
              <Typography color="textSecondary" variant="body2">
                <Resource tag="PageCreateChallenge::Workflow_ToNextPhase" />
              </Typography>
              <Box display="flex" alignItems="center" mt={1}>
                {!card?.isAutomaticFlow ? (
                  card?.responsibles && (
                    <AvatarGroup max={3}>
                      {card?.responsibles.map((responsible, index) => (
                        <Avatar
                          key={index}
                          style={{ width: 35, height: 35, marginRight: 8 }}
                          src={responsible.photo}
                          alt={responsible.fullName}
                        />
                      ))}
                    </AvatarGroup>
                  )
                ) : (
                  <Box>
                    <Box fontWeight={500}>
                      <Resource tag="PageCreateChallenge::AutomaticTransitionRules" />
                    </Box>
                    <Box display="flex" alignItems="center" mt={0.5}></Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box>
              <Typography color="textSecondary" variant="body2">
                <Resource tag="PageCreateChallenge::Workflow_TimePhase" />
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between" position="relative" mt={1}>
                <Box display="flex" alignItems="center">
                  <RestoreIcon color="disabled" />
                  <Box ml={1}>
                    <Typography color="textPrimary" variant="body2">
                      {card?.endDate || card?.endDays ? (
                        <Box>
                          {(card?.endDate && (
                            <Box display="flex" alignItems="center">
                              <Resource tag="PageCreateChallenge::Workflow_Until" />{" "}
                              <Box ml={0.4}>
                                <ShortDateFormat date={card?.endDate} />
                              </Box>
                            </Box>
                          )) || (
                            <Box display="flex" alignItems="center">
                              {card?.endDays}{" "}
                              <Box ml={0.4}>
                                <Resource tag="PageCreateChallenge::Workflow_DaysPerIdea" />
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ) : (
                        "-------"
                      )}
                    </Typography>
                  </Box>
                </Box>
                {deleteCard && (
                  <Box position="absolute" top={0} right={-16}>
                    {closeButton}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {handleSaveValue && open && (
        <PopupWorkflowDevelopment
          open={open}
          setOpen={(openVal) => setOpen(openVal)}
          saveValue={saveValue}
          phaseId={card.phaseId}
          challengeId={challengeId}
          order={card.order}
        />
      )}

      {deleteCard && deleteOpen && (
        <PopupDeleteWorkflowStage
          open={deleteOpen}
          setOpen={(openVal) => setDeleteOpen(openVal)}
          confirmAction={deletePhase}
        />
      )}
    </Box>
  );
}

export default DevelopmentDraggableCard;
