import history from "routes/history";
import newApp from "utils/reactNewApp";
import React, { useState } from "react";
import EMIcon from "components/Icon/Icon";
import Resource from "../Resources/Resource";
import { ShortDateFormat } from "utils/FormatDate";
import { Link as RouterLink } from "react-router-dom";
import { IRelatedNews } from "redux/reducers/news/newsDetailReducer";
import DialogScheduleNew from "views/News/CreateNews/DialogScheduleNew";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  makeStyles,
  Link,
  Paper,
  Box,
  Button,
  Theme,
  createStyles,
  useTheme,
  MenuItem,
  Fade,
} from "@material-ui/core";
import {
  IResultNews,
  IResultNewsHighlighted,
  IResultNewsPopular,
} from "redux/types/news";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 320,
      display: "grid",
      gridGap: "24px",
      marginBottom: "20px",
    },
    card: {
      display: "grid",
      gridTemplateRows: "190px 1fr",
      minHeight: 280,
      position: "relative",
      background: "transparent",
    },
    media: {
      height: 165,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      borderRadius: 6,
      backgroundBlendMode: "color",
      cursor: "pointer",
    },
    body: {
      alignSelf: "baseline",
      textAlign: "start",
    },
    content: {
      padding: 8,
      paddingLeft: 0,
    },
    newsTitle: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "1.3em",
      lineHeight: "normal",
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      padding: 0,
    },
    overlayTopic: {
      position: "absolute",
      top: "20px",
      left: "20px",
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      padding: "1px 7px 1px 5px",
    },
    topic: {
      color: theme.palette.text.primary,
      fontWeight: "bold",
      fontSize: "0.6rem",
    },
    overlayLikes: {
      position: "absolute",
      top: "134px",
      right: "12px",
      padding: "2px 7px 1px 5px",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      minWidth: 35,
    },
    likeText: {
      color: theme.palette.common.white,
      fontSize: "0.79rem",
    },
    likeIcon: {
      color: theme.palette.common.white,
      marginLeft: 5,
    },
    readmore: {
      textTransform: "uppercase",
      fontSize: "0.75rem",
      marginTop: "0",
    },
  })
);

interface IProps {
  id: string;
  news: Partial<IRelatedNews> | Partial<IResultNews>;
  highlghted?: IResultNewsHighlighted;
  popular?: IResultNewsPopular;
  isFutureNews?: boolean;
  moreOptions?:boolean;
  handleDelete?: (newsId: string) => void;
  handleHighlight?: (newsId: string, isHighlighted: boolean) => void;
  handleReschedule?: (
    publicationDate: Date,
    data: Partial<IResultNews> | Partial<IRelatedNews>
  ) => void;
}

function NewsCard(props: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    news,
    handleDelete,
    handleHighlight,
    handleReschedule,
    moreOptions,
    isFutureNews,
  } = props;
  const newsBackURL = "/news/details";
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rescheduleOpen, setRescheduleOpen] = useState(false);

  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      <Box id={props?.id} className={`${classes.root} ${"AutoTest_News"}`}>
        <PopUpConfirmation
          closeButton={() => setDeleteOpen(false)}
          sendButton={() => handleDelete && handleDelete(news?.newsId ?? "")}
          message={<Resource tag="Common::ConfirmAction" />}
          isOpen={deleteOpen}
          setIsOpen={setDeleteOpen}
        />
        {handleReschedule && (
          <DialogScheduleNew
            open={rescheduleOpen}
            newDate={news?.publicationDate}
            setOpen={setRescheduleOpen}
            submitForm={(publicationDate) => {
              handleReschedule(publicationDate, news!);
              setRescheduleOpen(false);
            }}
          />
        )}
        <Card
          className={classes.card}
          elevation={0}
          onClick={() => {
            history.push({
              pathname: `${newsBackURL}/${news?.newsId}`,
            });
          }}
        >
          <CardMedia className={classes.media} image={`${news?.image}`}>
            {news?.topic && (
              <Paper className={classes.overlayTopic}>
                <Typography className={classes.topic}>{news?.topic}</Typography>
              </Paper>
            )}
            {newApp && (moreOptions??true)? (
              <Box
                style={{
                  position: "absolute",
                  right: "3px",
                  top: "1px",
                  padding: 6,
                }}
              >
                <MoreOptionsButton
                  iconColor={theme.palette.common.white}
                  translate="-83px, 42px"
                >
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/editNews/${news?.newsId}`);
                    }}
                  >
                    <Resource tag="PageNews::EditNews" />
                  </MenuItem>
                  {handleReschedule && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setRescheduleOpen(true);
                      }}
                    >
                      <Resource tag="PageNews::Reschedule" />
                    </MenuItem>
                  )}
                  {handleHighlight && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleHighlight(news?.newsId ?? "", true);
                      }}
                    >
                      <Resource tag="PageNews::Highlight" />
                    </MenuItem>
                  )}
                  {handleDelete && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteOpen(true);
                      }}
                    >
                      <Resource tag="PageNews::Delete" />
                    </MenuItem>
                  )}
                </MoreOptionsButton>
              </Box>
            ) : (
              ""
            )}
            {news?.nrLikes
              ? news?.nrLikes > 0 && (
                  <Paper className={classes.overlayLikes}>
                    <Box display="flex" flexDirection="row" paddingTop="1px">
                      <Box flexGrow={1}>
                        <Typography className={classes.likeText}>
                          {news?.nrLikes}
                        </Typography>
                      </Box>
                      <Box>
                        <EMIcon
                          size={13}
                          className={classes.likeIcon}
                          color={theme.palette.common.white}
                          icon="like"
                        />
                      </Box>
                    </Box>
                  </Paper>
                )
              : ""}
          </CardMedia>
          <CardContent className={classes.content}>
            <Link
              component={RouterLink}
              to={{ pathname: `${newsBackURL}/${news?.newsId}` }}
              style={{
                textDecoration: "none",
              }}
            >
              <Box
                fontSize="10px"
                color={theme.palette.text.secondary}
                display="flex"
              >
                {isFutureNews ? (
                  <Box display="flex">
                    <Box mr={0.5}>
                      <Resource tag="PageNews::ScheduledTo" />
                    </Box>
                    <ShortDateFormat date={news?.publicationDate} />
                  </Box>
                ) : (
                  <Box>
                    <ShortDateFormat date={news?.publicationDate!} />
                  </Box>
                )}
              </Box>
              <Typography className={classes.newsTitle} variant="subtitle2">
                {news?.title}
              </Typography>
            </Link>
          </CardContent>
          <CardActions disableSpacing className={classes.actions}>
            <Typography variant="caption" color="primary">
              <Link
                style={{
                  textDecoration: "none",
                }}
                component={RouterLink}
                to={{ pathname: `${newsBackURL}/${news?.newsId}` }}
              >
                <Button variant="text" color="primary">
                  <Typography variant="button" className={classes.readmore}>
                    <Resource tag={"Common::ReadMore"} />
                  </Typography>
                </Button>
              </Link>
            </Typography>
          </CardActions>
        </Card>
      </Box>
    </Fade>
  );
}

export default NewsCard;
