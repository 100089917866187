import React from "react";
import Resource from "components/Resources/Resource";
import { IResultMyActivitiesPrizesLost } from "redux/types/myActivities";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core";

interface IProps {
  prize: IResultMyActivitiesPrizesLost;
}

const useStyles = makeStyles({
  card: {
    display: "grid",
    position: "relative",
    background: "transparent",
    margin: "0 8px",
  },
  media: {
    height: 220,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: 6,
    backgroundBlendMode: "color",
    display: "flex",
  },
  typography: {
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "1.5em",
    lineHeight: "normal",
    color: "#fff",
    textDecoration: "none",
    fontWeight: 500,
  },
  prizeCardTitleAndTime: {
    height: "fit-content",
    alignSelf: "flex-start",
  },
  colorTime: {
    color: "#fff",
    fontSize: 12,
  },
  cardContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingBottom: "16px !important",
  },
});

function MyActivityPrizesLostCard(props: IProps) {
  const { prize } = props;
  const classes = useStyles();

  return (
    <Card key={prize.prizeId} className={classes.card}>
      <CardMedia
        className={classes.media}
        style={{ backgroundImage: `url(${prize.image})` }}
      >
        <CardContent className={classes.cardContent}>
          <Box className={classes.prizeCardTitleAndTime}>
            <Typography className={classes.typography}>
              {prize.title}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.colorTime}
              style={{ marginTop: 8 }}
            >
              <Resource
                tag={prize.bidResource.replace(".", "::")}
                args={[prize.value]}
              />
            </Typography>
            <Typography variant="subtitle2" className={classes.colorTime}>
              <Resource tag={"Common::on"} />
              &nbsp; {prize.date}
            </Typography>
          </Box>
        </CardContent>
      </CardMedia>
    </Card>
  );
}

export default MyActivityPrizesLostCard;
