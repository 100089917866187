import React, { Component } from 'react'
import { IResultPrizeFutures } from '../../redux/reducers/prizeReducer';
import NextPrizeCard from '../NextPrizeCard/NextPrizeCard'
import './NextPrizes.css'
import Resource from '../Resources/Resource';
import { UX } from '../../config/ux';
import NextPrizesSkeleton from './NextPrizesSkeleton';
import NextPrizeDescription from './NextPrizesDescription'
import { Box, Collapse, Grid, Typography } from '@material-ui/core';

interface IProps {
  nextPrizes: IResultPrizeFutures[];
  cultureTag?: string;
  permission: string;
  requestManagePrize: (prizeId: string, type: number, isCanceling?: boolean) => void;
}
interface IState {
  activePrize: any;
  activeIndex: number;
  loading: boolean;
  openGrid: boolean;
}

class  NextPrizes extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      activePrize: null,
      activeIndex: 0,
      openGrid: false,
    };
  }
  componentDidMount() {
    const interval = setTimeout(() => {
      this.setState({ loading: false });
    }, UX.skeleton.minDelay);
    
    return () => clearInterval(interval)
  }

  render() {
    const { nextPrizes, cultureTag, permission, requestManagePrize } = this.props;
    const maxCards = 3;

    const handleClick = (cardData: any, index: number) => {
      if (
        this.state.activePrize !== null &&
        cardData.prizeId === this.state.activePrize.prizeId
      ) {
        this.setState({ activePrize: null, activeIndex: 0, openGrid: false });
      } else {
        this.setState({
          activePrize: cardData,
          activeIndex: index,
          openGrid: true,
        });
      }
    };

    return (
      <Box marginTop={3} marginBottom={2} width="100%">
        <Box mt={4} mb={2}>
          <Typography variant="h3">
            <Resource tag={"PagePrizes::Prize_NextPrizes"} />
          </Typography>
        </Box>
        <Grid container style={{ position: "relative" }} spacing={3}>
          {!this.state.loading ?
            <>
              {nextPrizes && nextPrizes?.slice(0, maxCards).map((cardData, index) => {
                return (
                  <Grid key={index} item xs={12} sm={12} md={4} lg={4}>
                    <NextPrizeCard
                      permission={permission}
                      cultureTag={cultureTag}
                      prize={cardData}
                      openGrid={
                        index + 1 === this.state.activeIndex &&
                        this.state.openGrid
                      }
                      onClick={handleClick}
                      index={index + 1}
                      requestManagePrize={requestManagePrize}
                    />
                  </Grid>
                )
              })}
            </>
            :
            <NextPrizesSkeleton />
          }
          {this.state.activePrize && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Collapse
                style={{
                  width: "calc(100% - 24px)",
                  position: "absolute",
                  top: "280px",
                }}
                in={this.state.activePrize !== null}
              >
                <NextPrizeDescription
                  handleClick={handleClick}
                  cultureTag={cultureTag}
                  activeIndex={this.state.activeIndex}
                  itemIndex={this.state.activeIndex}
                  result={this.state.activePrize}
                />
              </Collapse>
            </Grid>
          )}
        </Grid>
        {/* <div className="view-all">
          <button >
            {nextPrizes?.length > 6 ? <Resource tag={"Common::ViewAll"}/> : ""}
          </button>
        </div> */}
      </Box>
    );
  }
}

export default NextPrizes