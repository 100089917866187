import { Reducer } from "redux";
import { FeedActions, FeedActionTypes } from "../actions/FeedAction";

// Define the Character type
export interface IFeed {
  showShareYourIdea: boolean;
  showShareInspiration: boolean;
  existAnyChallenges: boolean;  
  totalEvaluate: number;
  totalTasks: number;
  totalOverdueTasks: number;
  challenges: IFeedChallenge[];
  prizes: IFeedPrize[];
  ideas: IFeedIdea[];
  news: [
    {
      newsId: string;
      title: string;
      description: string;
      image: string;
      topic: string;
      publicationDate: string;
      nrLikes: number;
      isFavorite: boolean;
    }
  ];
  insights: IFeedInsights[];
}

export interface IFeedPrize {
  timeLeftValue: string;
  timeLeftResource?: string;
  prizeId: string;
  title: string;
  image: string;
  newPrizesResource: string;
  value: string;
}

export interface IFeedChallenge {
  challengeId: string;
  title: string;
  description: string;
  descriptionHtml: string;
  image: string;
  statusResource: string;
  dateResource: string;
  timeValue1Resource: string;
  timeValue1: number;
  timeValue2Resource: string;
  timeValue2: number;
  allowParticipate: boolean;
  allowSubmitIdea: boolean;
}

export interface IFeedIdea {
  ideaId: string;
  challengeId: string;
  phaseId: string /*v3*/;
  stateId: string /*v2*/;
  author: {
    userName: string;
    userId: string;
    fullName: string;
    photo: string;
  };
  coAuthors: [
    {
      userName: string;
      authorId: string;
      fullName: string;
      photo: string;
    }
  ];
  title: string;
  phase: {
    phaseId: string;
    tenantId: number;
    workspaceId: string;
    challengeId: string;
    type: number;
    order: number;
    isLastPhase: boolean;
    endDate: string;
    endDays: number;
    isAutomaticFlow: boolean;
    endTransitionDate: string;
    endTransitionDays: number;
    responsibles: [];
    cultures: [
      {
        description: string;
        cultureTag: string;
        title: string;
      }
    ];
  };
  state: string /*v2*/;
  countComments: number;
  isFollowing: boolean;
  isChosenForImplementation: boolean;
  isImplemented: boolean;
  expectedROI: number;
  achievedROI: number;
  isRated: boolean;
  evaluation: {
    isCollaboration: boolean;
    evaluationType: string;
    starsEvaluation: {
      average: number;
      countEvaluations: number;
      evaluationResource: string;
      isAllowedEvaluate: boolean;
      evaluationUser: {
        userId: string;
        lastUpdated: string;
      };
      dimensionsTitle: [
        {
          dimensionId: number;
          dimensionTile: string;
          average: number;
        }
      ];
      comments: [
        {
          comment: string;
          authorId: string;
          name: string;
          username: string;
          photo: string;
        }
      ];
    };
    likesEvaluation: {
      countEvaluations: number;
      evaluationResource: string;
      evaluationUser: {
        userId: string;
        lastUpdated: string;
      };
      isAllowedEvaluate: boolean;
    };
    investment: IFeedIdeaEvaluationInvestment;
  };
  createAt: string;
  resourcePublicComments: string;
  isAllowedComment: boolean;
  description: string;
  totalEvaluations: number;
  isAllowedViewOwnerOrCoOwner: boolean;
}

export interface IFeedIdeaEvaluationInvestment {
  investmentGraph: {
    additionalProp1: number;
    additionalProp2: number;
    additionalProp3: number;
  };
  investmentBeliefValue: number;
  investmentBeliefVariation: number;
  investmentValue: number;
  investmentVariation: number;
  investmentDate: string;
  investmentHour: string;
  investmentDateResource: string;
  isIncreaseInvestment: boolean;
  investmentStep: number;
  maximumInvestment: number;
  allowSell: boolean;
}

export interface IFeedInsights {
  commentsCount: number;
  challengeId: string;
  insightId: string;
  title: string;
  description: string;
  challengeTitle: string;
  creationDate: string;
  tags: [string];

}

// Define the Character State
export interface IFeedState {
  readonly feed: IFeed;
}

// Define the initial state
const initialCharacterState: IFeedState = {
  feed: {} as IFeed,
};

export const feedReducer: Reducer<IFeedState, FeedActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case FeedActionTypes.GET_ALL_FEED: {
      return {
        ...state,
        feed: action.feed,
      };
    }
    case FeedActionTypes.POST_EVALUATION: {
      const indexFeed = state?.feed?.ideas?.findIndex(
        (x) => x.ideaId === action.ideaDetails.ideaId
      );
      if (indexFeed !== -1) {
        const ideaIndexState = {
          ...state.feed.ideas[indexFeed],
        };
        ideaIndexState.evaluation = action.ideaDetails.evaluation;
        ideaIndexState.isRated = true;
        ideaIndexState.totalEvaluations = action.isDislike
          ? state.feed.ideas[indexFeed].totalEvaluations - 1
          : state.feed.ideas[indexFeed].totalEvaluations + 1;
        const newIdeaArray = state.feed.ideas.map((x, index) => {
          return indexFeed === index ? ideaIndexState : x;
        });
        return {
          ...state,
          feed: {
            ...state.feed,
            ideas: newIdeaArray,
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
};
