import { AnyAction } from "redux";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import history from "../../routes/history";
import { ThunkDispatch } from "redux-thunk";
import { treatError } from "utils/treatError";
import ukIcon from "../../assets/img/ukIcon.svg";
import { ArrowForward } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import spainIcon from "../../assets/img/spainIcon.svg";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import brazilIcon from "../../assets/img/brazilIcon.svg";
import { IAppState, store } from "../../redux/store/Store";
import romeniaIcon from "../../assets/img/romeniaIcon.svg";
import Resource from "../../components/Resources/Resource";
import portugalIcon from "../../assets/img/portugalIcon.svg";
import { IApplication } from "../../redux/reducers/tenantReducer";
import { IProfileData } from "../../redux/reducers/profileReducer";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import { IAuthLogoutAction, doLogout } from "../../redux/actions/AuthAction";
import { ICountries, IDepartments, ITimeZones } from "../../redux/reducers/usersReducers";
import {
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Fade,
} from "@material-ui/core";
import {
  ICultureSetCulture,
  setCultureAndResources,
} from "redux/actions/CultureActions";
import {
  getAllCountries,
  getAllDepartaments,
  getAllTimeZones,
  IDepartmentsSucceededGetAllAction,
  ITimeZonesSucceededGetAllAction,
} from "../../redux/actions/UsersAction";
import {
  IProfileGetAllAction,
  IProfileGetDataAction,
  IProfileUpdateAction,
  getProfile,
  getProfileData,
  updateProfile,
} from "../../redux/actions/ProfileActions";
import { IWorkspaceReducer } from "redux/reducers/workspaceReducer";
import { getWorkspace, IWorkspaceSucceededGetAllAction } from "redux/actions/workspaceAction";
import newApp from 'utils/reactNewApp';

interface IDispatchProps {
  getProfileData: () => Promise<IProfileGetDataAction>;
  getWorkspace:(options: object) => Promise<IWorkspaceSucceededGetAllAction>;
  updateProfile: (
    params: object,
    options: object
  ) => Promise<IProfileUpdateAction>;
  getProfile: (params: object) => Promise<IProfileGetAllAction>;
  getAllDepartaments: (
    params: object
  ) => Promise<IDepartmentsSucceededGetAllAction>;
  getAllCountries: (
    params: object
  ) => Promise<IDepartmentsSucceededGetAllAction>;
  getAllTimeZones: (
    options: object
  ) => Promise<ITimeZonesSucceededGetAllAction>;
  logOutConnect: () => Promise<IAuthLogoutAction>;
}

interface IProps {
  profileData: IProfileData;
  application: IApplication;
  cultureState: ICultureState;
  departments: IDepartments[];
  countries: ICountries[];
  timeZones: ITimeZones[];
  loadingSubmit: Boolean;
  updateSuccess: Boolean;
  workspaceId: string;
  workspaces: IWorkspaceReducer;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    languageText: {
      color: "#898989",
      fontSize: 12.8,
      textTransform: "uppercase",
    },
    inputName: {
      color: "#898989",
      fontSize: "13 px",
      textTransform: "uppercase",
    },
    selectLabel: {
      marginTop: 16,
      fontSize: "0.8rem",
    },
    selectInputs: {
      marginBottom: 8,
    },
    timezoneInput: {
      marginBottom: 0,
    },
    languageOp: {
      color: "rgba(0, 0, 0, 0.87)",
      width: "120px",
      cursor: "pointer",
      display: "flex",
      padding: "5px 8px",
      borderRadius: "20px",
      boxSizing: "border-box",
      justifyContent: "center",
      "&:active": {
        boxShadow: "0 0 3px #626262",
        fontWeight: "bold",
      },
      "&:hover": {
        boxShadow: "0px 0px 3.87097px rgba(27, 157, 252, 0.4)",
        fontWeight: "bold",
      },
      "&:selected": {
        boxShadow: "0px 0px 3.87097px rgba(27, 157, 252, 0.4)",
        fontWeight: "bold",
      },
    },
    lanSelected: {
      boxShadow: "0px 0px 3.87097px rgba(27, 157, 252, 0.4)",
      fontWeight: "bold",
    },
    language: {
      textTransform: "uppercase",
      color: theme.palette.text.primary,
    },
    centerLoading: {
      margin: "0px auto",
      height: "80vh",
    },
  })
);

function SetProfile(props: IProps & IDispatchProps) {
  const classes = useStyles();
  const {
    profileData,
    departments,
    countries,
    getProfileData,
    getAllDepartaments,
    getAllCountries,
    cultureState,
    timeZones,
    getAllTimeZones,
    getWorkspace,
    workspaceId,
  } = props;
  const [, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [cultureTag, setCultureTag] = useState("");
  const [fullname, setFullname] = useState("");
  const [timezone, setTimezone] = useState("");
  const [department, setDepartment] = useState("");
  const [country, setCountry] = useState("");
  
  const possibleCultures = profileData?.possibleCultures;

  function HandleBackLoginScreen() {
    props?.logOutConnect();
    history?.push("/log-in");
  }
  

  function getResourcesHandler(cultureTag: string) {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store?.dispatch;
    dispatch(setCultureAndResources(cultureTag));
    return store?.getState().cultureState;
  }

  useEffect(() => {
    let options = {
      workspaceId,
      cultureTag: cultureState?.culture?.cultureTag
    }
    
    console.log(cultureState);

    Promise.all([
      getAllDepartaments({
        culture: cultureState?.culture,
        cultureTag:
          cultureState?.culture?.cultureTag === undefined
            ? navigator?.language
            : cultureState?.culture?.cultureTag,
      }),
      getProfileData(),
      getAllTimeZones({ culture: cultureState?.culture }),
      getAllCountries({ culture: cultureState?.culture }),
    ]).finally(() => {
      //não chamar se for novo usuário
      newApp && profileData?.isFirstAccess === false && getWorkspace(options);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileData?.cultureTag === "") {
      profileData?.cultureTag === ""
        ? getResourcesHandler("en-GB")
        : getResourcesHandler(profileData?.cultureTag);
    } else {
      cultureTag === ""
        ? getResourcesHandler(
            profileData?.cultureTag ? profileData?.cultureTag : "en-GB"
          )
        : getResourcesHandler(cultureTag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData?.cultureTag, cultureTag]);

  const onSubmit = (e: any) => {
    setLoadingSubmit(true);
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const { updateProfile, getProfileData, cultureState } = props;

    let customFieldsValues = {};

    let newValues = {
      fullName: sanitizeHtml(data.get("fullName")?.toString()!),
      email: sanitizeHtml(
        data.get("email") ? data.get("email")?.toString()! : profileData?.email
      ),
      phone: sanitizeHtml(
        data?.get("phone")
          ? data?.get("phone")?.toString()!
          : profileData?.phone
          ? profileData?.phone
          : ""
      ),
      department: data?.get("department"),
      country: data?.get("country"),
      timezone: data?.get("timezone"),
      workspaceId: workspaceId,
    };

    if (cultureTag === "") {
      newValues = {
        ...newValues,
        ...{ cultureTag: props?.cultureState?.culture?.cultureTag },
      };
    } else {
      newValues = { ...newValues, ...{ cultureTag } };
    }

    const params = {
      ...newValues,
      customFields: { ...customFieldsValues },
    };

    updateProfile(params, { cultureState }).then(
      (response) => {
        const { getProfile, application, cultureState } = props;
        const options = {
          applicationId: String(application?.applicationId),
          culture: cultureState,
        };

        getProfileData().then(() => {
          getProfile(options);
          setLoadingSubmit(false);

          if (response?.updateSuccess) {
            if (profileData?.agreedTermsAndPolicyDate === "") {
              history?.push("/acceptterms");
            } else if (
              profileData?.agreedRegulationVersion !== null &&
              profileData?.agreedRegulations === ""
            ) {
              history?.push("/acceptRegulations");
            } else {
              history?.push("/");
            }
          }
        });
      },
      (err) => {
        setLoadingSubmit(false);
        treatError(props?.cultureState?.culture, "Profile", err);
      }
    );
  };

  const getCultureFlag = (culture: any) => {
    switch (culture) {
      case "en-GB":
        return ukIcon;
      case "pt-PT":
        return portugalIcon;
      case "es-ES":
        return spainIcon;
      case "pt-BR":
        return brazilIcon;
      case "ro-RO":
        return romeniaIcon;
      default:
        return "";
    }
  };

  return (
    <>
      <Fade in={true} mountOnEnter unmountOnExit>
        {cultureState?.statusCulture === "SUCCEEDED" ? (
          <form onSubmit={onSubmit}>
            <IconButton color="primary" onClick={HandleBackLoginScreen}>
              <ArrowBackIcon />
            </IconButton>
            <Box pl={1} ml={1} mt={3}>
              <Typography variant="h2" color="textPrimary" gutterBottom>
                <Resource tag={"PageLogin::SetProfile"} />
              </Typography>
            </Box>
            <Container style={{ paddingLeft: "16px" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={7}>
                  <Box style={{ width: "180%" }}>
                    <Box mt={0} mb={2}>
                      <TextField
                        id="fullName"
                        label={
                          <Typography
                            component="p"
                            className={classes.inputName}
                          >
                            <Resource tag={"PageProfile::Input_Name"} />
                          </Typography>
                        }
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={
                          fullname === "" ? profileData?.fullName : fullname
                        }
                        onChange={(e: any) => setFullname(e.target.value)}
                        name="fullName"
                      />
                    </Box>
                    <Box mb={2}>
                      <InputLabel
                        className={classes.selectLabel}
                        id="timezone-label"
                      >
                        <Typography
                          component="p"
                          className={classes.languageText}
                        >
                          <Resource tag={"PageProfile::Timezone"} />
                        </Typography>
                      </InputLabel>
                      <Select
                        name="timezone"
                        labelId={"timezone-label"}
                        id="timezone"
                        fullWidth
                        className={classes.timezoneInput}
                        defaultValue={
                          timezone??""
                        }
                      >
                        {timeZones?.map((timeZone) => (
                          <MenuItem 
                            onClick={() => setTimezone(timeZone?.timeZoneId)}
                            key={timeZone?.timeZoneId} 
                            value={timeZone?.timeZoneId}>
                            {timeZone?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>

                    <Box mb={4}>
                      <Box mb={1}>
                        <Typography
                          component="p"
                          className={classes.languageText}
                        >
                          <Resource tag={"PageProfile::Language"} />
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: "14px" }}
                      >
                        {possibleCultures?.map((x) => {
                          const activeCultureTag = cultureTag
                            ? cultureTag
                            : profileData?.cultureTag;
                          return (
                            <Paper
                              elevation={0}
                              className={
                                `${classes.languageOp}` +
                                (x?.cultureTag === activeCultureTag
                                  ? ` ${classes.lanSelected}`
                                  : "")
                              }
                              key={x?.cultureTag}
                              onClick={() => setCultureTag(x?.cultureTag)}
                            >
                              <Box display="flex" mr={1}>
                                <img
                                  width="18px"
                                  height="18px"
                                  alt={cultureTag}
                                  src={getCultureFlag(x?.cultureTag)}
                                />
                              </Box>
                              <Box className={classes.language}>
                                {x?.cultureTag?.toLocaleUpperCase()}
                              </Box>
                            </Paper>
                          );
                        })}
                      </Box>
                    </Box>

                    {departments?.length > 0 ? (
                      <>
                        <Box mb={4}>
                          <InputLabel
                            className={classes.selectLabel}
                            id="departments-label"
                          >
                            <Typography
                              component="p"
                              className={classes.languageText}
                            >
                              <Resource tag={"PageProfile::Input_Department"} />
                            </Typography>
                          </InputLabel>
                          <Select
                            name="department"
                            labelId={"departments-label"}
                            id="department"
                            fullWidth
                            className={classes.selectInputs}
                            value={
                              profileData?.department
                                ? profileData?.department
                                : department
                            }
                          >
                            {departments &&
                              departments?.map((depart, index) => (
                                <MenuItem
                                  key={index}
                                  value={
                                    depart?.departmentId
                                      ? depart?.departmentId
                                      : department
                                  }
                                  onClick={() =>
                                    setDepartment(
                                      depart?.departmentId.toString()
                                    )
                                  }
                                >
                                  {depart?.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                    {countries?.length > 0 ? (
                      <>
                        <Box mb={4}>
                          <InputLabel
                            className={classes.selectLabel}
                            id="countries-label"
                          >
                            <Typography
                              component="p"
                              className={classes.languageText}
                            >
                              <Resource tag={"PageProfile::Input_Country"} />
                            </Typography>
                          </InputLabel>
                          <Select
                            name="country"
                            labelId={"countries-label"}
                            id="country"
                            fullWidth
                            className={classes.selectInputs}
                            value={
                              profileData?.country
                                ? profileData?.country
                                : country
                            }
                          >
                            {countries &&
                              countries?.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  value={item.countryId}
                                  onClick={() =>
                                    setCountry(
                                      item.countryId.toString()
                                    )
                                  }
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Box display="flex" flexDirection="row-reverse" mt={2}>
              <Box>
                <Button
                  type="submit"
                  variant={!loadingSubmit ? "contained" : "outlined"}
                  color="primary"
                  className="btn-login"
                  disabled={fullname === '' || timezone === '' || department === '' || country === ''}
                  endIcon={
                    !loadingSubmit ? (
                      <ArrowForward className="icon icon-arrow" />
                    ) : (
                      <CircularProgress size={22} className="icon" />
                    )
                  }
                >
                  <Resource tag={"PageLogin::SaveProfile"} />
                </Button>
              </Box>
            </Box>
          </form>
        ) : (
          <>
            <Box height="100%" display="flex" className={classes.centerLoading}>
              <Box m="auto">
                <CircularProgress size={60} />
              </Box>
            </Box>
          </>
        )}
      </Fade>
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    application: store.tenantState.application,
    cultureState: store.cultureState,
    profileData: store.profileState.profileData,
    departments: store.usersState.departaments,
    countries: store.usersState.countries,
    timeZones: store.usersState.timeZones,
    updateSuccess: store.profileState.updateSuccess,
    workspaceId: store.authState.auth.workspaceId,
    workspaces: store.workSpacesState.workspaces
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getProfileData: () => dispatch(getProfileData()),
    getWorkspace: (options: object) => dispatch(getWorkspace(options)),
    updateProfile: (params: object, options: object) => dispatch(updateProfile(params, options)),
    getProfile: (params: object) => dispatch(getProfile(params)),
    getAllDepartaments: (params: object) => dispatch(getAllDepartaments(params)),
    getAllCountries: (params: object) => dispatch(getAllCountries(params)),
    getAllTimeZones: (options: object) => dispatch(getAllTimeZones(options)),
    logOutConnect: () => dispatch(doLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetProfile);
