import React from "react";
import sanitizeHtml from "sanitize-html";
import ListIcon from "@material-ui/icons/List";
import ErrorIcon from "@material-ui/icons/Error";
import CreateIcon from "@material-ui/icons/Create";
import SubjectIcon from "@material-ui/icons/Subject";
import Filter7Icon from "@material-ui/icons/Filter7";
import Resource from "components/Resources/Resource";
import { PageState, WorkflowNotifications } from "redux/types/createChallenge";
import { securityTextEditor } from "utils/securityTextEditor";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { ICultureState } from "redux/reducers/cultureReducer";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import ValidationDraggableCard from "../../../../components/Workflow/WorkflowCardsContent/ValidationDraggableCard";
import { Avatar, Box, Button, createStyles, Divider, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { random } from "utils/random";
import CollaborationDraggableCard from "components/Workflow/WorkflowCardsContent/CollaborationDraggableCard";
import DevelopmentDraggableCard from "components/Workflow/WorkflowCardsContent/DevelopmentDraggableCard";
import ExpertReviewDraggableCard from "components/Workflow/WorkflowCardsContent/ExpertReviewDraggableCard";
import { IManageGroups } from "redux/types/manageGroups";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grayBackground: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: "6px",
    },
    img: {
      width: "100%",
      height: "auto",
      maxWidth: "100%",
      maxHeight: "350px",
      borderRadius: "8px",
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    greenIcon: {
      color: theme.palette.success.main,
    },
    ideaForm: {
      /*height: 50,*/
      minHeight: "50px",
      borderRadius: "6px",
      border: "2px solid rgba(136, 136, 136, 0.2)",
      width: "100%",
    },
    whiteCard: {
      backgroundColor: "#fff",
      border: "1px solid rgba(136, 136, 136, 0.2)",
      boxSizing: "border-box",
      borderRadius: "11px",
      width: "200px",
    },
    textUpper: {
      textTransform: "uppercase",
    },
  })
);

interface IProps {
  culture: ICultureState;
  setActive: any;
  steps: PageState;
  notifications: WorkflowNotifications[];
  challengeId: string;
  groups: IManageGroups;
}

const typeButtons = [
  {
    type: "shortText",
    startIcon: <CreateIcon fontSize="small" />,
  },
  {
    type: "longText",
    startIcon: <SubjectIcon fontSize="small" />,
  },
  {
    type: "attachment",
    startIcon: <AttachFileIcon fontSize="small" />,
  },
  {
    type: "number",
    startIcon: <Filter7Icon fontSize="small" />,
  },
  {
    type: "checkmark",
    startIcon: <ListIcon fontSize="small" />,
  },
  {
    type: "radio",
    startIcon: <RadioButtonCheckedIcon fontSize="small" />,
  },
  {
    type: "dropdown",
    startIcon: <ArrowDropDownCircleIcon fontSize="small" />,
  },
];

function CreateChallengeStepConfirmation(props: IProps) {
  const classes = useStyles();
  const { steps, setActive, notifications, challengeId, groups } = props;

  let groupsIds = steps?.stepParticipants?.groups.map((group) => group?.groupId);

  let dataGroups = groups?.result
    ?.map((data) => {
      if (groupsIds.includes(data?.groupId)) {
        return data;
      } else {
        return "";
      }
    })
    .filter((group) => group);

  const printDate = (date: any) => {
    date = new Date(date);
    return (
      <Box display="flex" alignItems="center" gridGap={10}>
        <Typography variant="body2">
          {`${date?.getDate() >= 10 ? date?.getDate().toString() : "0" + date?.getDate().toString()}/${date?.getMonth() >= 10 ? date?.getMonth().toString() : "0" + date?.getMonth()
            }/${date?.getFullYear()}`}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          <Resource tag="PageCreateChallenge::At" />
        </Typography>
        <Typography variant="body2">
          {`${date?.getHours() >= 10 ? date?.getHours().toString() : "0" + date?.getHours().toString()}:${date?.getMinutes() >= 10 ? date?.getMinutes().toString() : "0" + date?.getMinutes().toString()
            }`}
        </Typography>
      </Box>
    );
  };

  const detailsNotifications = notifications.filter((notification) => notification.key === "Challenge_Details");
  const participantsNotifications = notifications.filter((notification) => notification.key === "Participants_Details");
  const ideaformNotifications = notifications.filter((notification) => notification.key === "IdeaForm_Details");
  const workflowNotifications = notifications.filter(
    (notification) => notification.key.indexOf("Workflow_Details") > -1
  );

  const titleNotifications = detailsNotifications.filter((x) => x.field === "title");
  const descriptionNotifications = detailsNotifications.filter((x) => x.field === "description");
  const tagsNotifications = detailsNotifications.filter((x) => x.field === "Tags");

  const managerNotifications = participantsNotifications.filter((x) => x.field === "Participants");
  const groupsNotifications = participantsNotifications.filter((x) => x.field === "Groups");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <Box my={2}>
          <Typography>
            <strong>
              {notifications.length > 0 ? (
                <Resource tag="PageCreateChallenge::TheChallengeIsNotReady" />
              ) : (
                <Resource tag="PageCreateChallenge::TheChallengeIsReady" />
              )}
            </strong>
          </Typography>
          <Typography variant="body2">
            <Resource tag="PageCreateChallenge::ReviewTheReportBelow" />
          </Typography>
        </Box>
        <Box
          className={classes.grayBackground}
          height="50px"
          display="flex"
          gridGap={10}
          px={1}
          alignItems="center"
          my={5}
        >
          {detailsNotifications.length === 0 ? (
            <CheckCircleOutlineIcon className={classes.greenIcon} />
          ) : (
            <ErrorIcon color="error" />
          )}

          <Typography>
            <strong>
              <Resource tag="PageCreateChallenge::Details" />
            </strong>
          </Typography>
          <Button onClick={() => setActive(1)} style={{ marginLeft: "auto" }} variant="text" color="primary">
            <Resource tag="PageCreateChallenge::ReturnToThisStep" />
          </Button>
        </Box>
        <Box display="flex" my={1} gridGap={10}>
          <Typography variant="body2" color="textSecondary" className={classes.textUpper}>
            <Resource tag="PageCreateChallenge::ChallengeType" />:
          </Typography>
          <Typography variant="body2">
            {steps.stepDetail.challengeType === 1 ? (
              <Resource tag="PageCreateChallenge::Temporary" />
            ) : (
              <Resource tag="PageCreateChallenge::Permanent" />
            )}
          </Typography>
        </Box>
        <Box display="flex" my={1} gridGap={10}>
          <Typography variant="body2">
            <strong>
              <Resource tag="PageCreateChallenge::ChallengeActivationDate" />
            </strong>
          </Typography>
          {steps.stepDetail.startDate && printDate(steps.stepDetail.startDate)}
        </Box>
        {steps.stepDetail.challengeType === 2 && (
          <Box display="flex" my={1} gridGap={10}>
            <Typography variant="body2">
              <strong>
                <Resource tag="PageCreateChallenge::SubmissionEndDate" />:
              </strong>
            </Typography>
            {steps.stepDetail.endSubmitionDate && printDate(steps.stepDetail.endSubmitionDate)}
          </Box>
        )}
        <Box my={4}>
          <Typography variant="body2" color="textSecondary" className={classes.textUpper}>
            <Resource tag="PageCreateChallenge::ChallengeImage" />
          </Typography>
          {steps.stepDetail.image ? (
            <Box>
              <img alt={steps.stepDetail.title} className={classes.img} src={steps.stepDetail.image} />
            </Box>
          ) : (
            <>
              <Typography variant="caption">
                <Resource tag="PageCreateChallenge::DefaultImage" />
              </Typography>
              <Box>
                <img alt="" className={classes.img} src="" />
              </Box>
            </>
          )}
        </Box>
        <Box my={4}>
          <Typography variant="body2" color="textSecondary" className={classes.textUpper}>
            <Resource tag="PageCreateChallenge::ChallengeTitle" />
          </Typography>
          {!titleNotifications.length ? (
            <Typography variant="body2">
              <strong>{steps.stepDetail.title}</strong>
            </Typography>
          ) : (
            <Box>
              {titleNotifications.map((noti) => (
                <Typography variant="body2" color="error" key={random()}>
                  <Resource tag={noti.message.replace(".", "::")} />
                </Typography>
              ))}
            </Box>
          )}
        </Box>
        <Box my={4}>
          <Typography variant="body2" color="textSecondary" className={classes.textUpper}>
            <Resource tag="PageCreateChallenge::ChallengeDescription" />
          </Typography>
          {steps.stepDetail.description ? (
            <Box
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(steps.stepDetail.description, securityTextEditor),
              }}
            ></Box>
          ) : (
            <Box>
              {descriptionNotifications.map((noti) => (
                <Typography variant="body2" color="error">
                  <Resource tag={noti.message.replace(".", "::")} />
                </Typography>
              ))}
            </Box>
          )}
        </Box>
        <Box my={4}>
          <Typography color="textSecondary" className={classes.textUpper}>
            <Resource tag="PageCreateChallenge::Tags" />
          </Typography>
          {!tagsNotifications.length ? (
            <Box display="flex">
              {steps.stepDetail.tags.map((tag: any) => (
                <Box
                  key={tag}
                  width="fit-content"
                  borderRadius={4}
                  padding={"2px 8px"}
                  mt={2}
                  mr={1}
                  display="flex"
                  alignItems="center"
                  bgcolor="rgb(241,241,243)"
                >
                  <Box>
                    <Typography component="p" color="textSecondary">
                      {tag}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              {tagsNotifications.map((noti, index) => (
                <Typography variant="body2" color="error" key={index}>
                  <Resource tag={noti.message.replace(".", "::")} />
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <Box
          className={classes.grayBackground}
          height="50px"
          display="flex"
          gridGap={10}
          px={1}
          alignItems="center"
          my={5}
        >
          {!participantsNotifications.length ? (
            <CheckCircleOutlineIcon className={classes.greenIcon} />
          ) : (
            <ErrorIcon color="error" />
          )}
          <Typography>
            <strong>
              <Resource tag="PageCreateChallenge::Participants" />
            </strong>
          </Typography>
          <Button onClick={() => setActive(2)} style={{ marginLeft: "auto" }} variant="text" color="primary">
            <Resource tag="PageCreateChallenge::ReturnToThisStep" />
          </Button>
        </Box>
        <Box display="flex" my={2} gridGap={steps.stepParticipants.managers.length !== 0 ? 32 : 10} flexWrap="wrap">
          <Typography variant="body2">
            <strong>
              <Resource tag="PageCreateChallenge::ChallengeManager" />
            </strong>
          </Typography>
          {steps.stepParticipants.managers.map((data: any, index: any) => (
            <Box key={index} display="flex" alignItems="center" gridGap={10}>
              <Avatar src={data.photo} alt={data.name} className={classes.avatar} />
              <Typography>{data.name}</Typography>
            </Box>
          ))}
          <Box>
            {managerNotifications.map((noti) => (
              <Typography variant="body2" color="error">
                <Resource tag={noti.message.replace(".", "::")} />
              </Typography>
            ))}
          </Box>
        </Box>
        <Box display="flex" my={2} gridGap={10} flexWrap="wrap">
          <Typography variant="body2">
            <strong>
              <Resource tag="PageCreateChallenge::ParticipantGroups" />
            </strong>
          </Typography>
          <Box display="flex" flexDirection="column" gridGap={10}>
            {dataGroups?.map((data: any, index: any) => (
              <Box key={data?.groupId || index} display="flex" gridGap={10} alignItems="center">
                <PersonOutlineIcon />
                <Typography variant="body2" noWrap>
                  {data?.groupName}
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography variant="body2" color="textSecondary" noWrap>
                  {
                    data?.numberOfMember > 1
                      ? <Resource tag="PageCreateChallenge::Members" args={[data?.numberOfMember]} />
                      : <Resource tag="PageCreateChallenge::Member" args={[data?.numberOfMember]} />
                  }
                </Typography>
              </Box>
            ))}
          </Box>
          {groupsNotifications.map((noti) => (
            <Typography variant="body2" color="error">
              <Resource tag={noti.message.replace(".", "::")} />
            </Typography>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <Box
          className={classes.grayBackground}
          height="50px"
          display="flex"
          gridGap={10}
          px={1}
          alignItems="center"
          my={5}
        >
          {!ideaformNotifications.length ? (
            <CheckCircleOutlineIcon className={classes.greenIcon} />
          ) : (
            <ErrorIcon color="error" />
          )}
          <Typography>
            <strong>
              <Resource tag="PageCreateChallenge::IdeaForm" />
            </strong>
          </Typography>
          <Button onClick={() => setActive(3)} style={{ marginLeft: "auto" }} variant="text" color="primary">
            <Resource tag="PageCreateChallenge::ReturnToThisStep" />
          </Button>
        </Box>
        <Box display="flex" my={2} gridGap={32} flexWrap="wrap">
          <Typography variant="body2">
            <strong>
              <Resource tag="PageCreateChallenge::RequiredFields" />
            </strong>
          </Typography>
          <Box width="70%" display="flex" flexDirection="column" gridGap={10}>
            {steps?.stepIdeaForm?.cards &&
              steps.stepIdeaForm.cards?.map(
                (data, index) =>
                  data.isRequired && (
                    <Box>
                      <Box
                        key={index}
                        className={classes.ideaForm}
                        display="flex"
                        gridGap={10}
                        px={1}
                        alignItems="center"
                      >
                        {typeButtons[data.type - 1].startIcon}
                        <Typography>{data.cultures?.[0]?.title}*</Typography>
                      </Box>
                      <Box ml={0.5} mt={1}>
                        {ideaformNotifications.map(
                          (noti) =>
                            noti.field.includes("" + index) && (
                              <Typography variant="body2" color="error">
                                <Resource tag={noti.message.replace(".", "::")} />
                              </Typography>
                            )
                        )}
                      </Box>
                    </Box>
                  )
              )}
          </Box>
        </Box>
        {steps.stepIdeaForm.cards.filter((x: any) => !x.isRequired).length !== 0 && (
          <Box display="flex" my={4} gridGap={32} flexWrap="wrap">
            <Typography variant="body2">
              <strong>
                <Resource tag="PageCreateChallenge::OptionalFields" />
              </strong>
            </Typography>
            <Box width="70%" display="flex" flexDirection="column" gridGap={10}>
              {steps?.stepIdeaForm?.cards &&
                steps.stepIdeaForm.cards.map(
                  (data: any, index: any) =>
                    !data.isRequired && (
                      <Box>
                        <Box
                          key={index}
                          className={classes.ideaForm}
                          display="flex"
                          gridGap={10}
                          px={1}
                          alignItems="center"
                        >
                          {typeButtons[data.type - 1].startIcon}
                          <Typography>{data.cultures?.[0]?.title}</Typography>
                        </Box>
                        <Box ml={0.5} mt={1}>
                          {ideaformNotifications.map(
                            (noti) =>
                              noti.field.includes("" + index) && (
                                <Typography variant="body2" color="error">
                                  <Resource tag={noti.message.replace(".", "::")} />
                                </Typography>
                              )
                          )}
                        </Box>
                      </Box>
                    )
                )}
            </Box>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <Box
          className={classes.grayBackground}
          height="50px"
          display="flex"
          gridGap={10}
          px={1}
          alignItems="center"
          my={2}
        >
          {!workflowNotifications.length ? (
            <CheckCircleOutlineIcon className={classes.greenIcon} />
          ) : (
            <ErrorIcon color="error" />
          )}
          <Typography>
            <strong>
              <Resource tag="PageCreateChallenge::Workflow" />
            </strong>
          </Typography>
          <Button onClick={() => setActive(4)} style={{ marginLeft: "auto" }} variant="text" color="primary">
            <Resource tag="PageCreateChallenge::ReturnToThisStep" />
          </Button>
        </Box>
        {workflowNotifications.filter((x) => !x.key.includes(`[`)).map((noti) => (
          <Box p={1}>
            <Typography variant="body2" color="error">
              <Resource tag={noti.message.replace(".", "::")} args={[noti.field]} />
            </Typography>
          </Box>
        ))}
        <Box
          pt={2}
          px={2}
          alignItems="stretch"
          className={classes.grayBackground}
          borderRadius="11px"
          minHeight="400px"
        >
          <Box width="100%" pb={2} display="flex" style={{ overflowX: "auto" }}>
            {steps.stepWorkflow.cards.map((card, index) => {
              const notification = workflowNotifications.filter((x) => x.key.includes(`[${index}]`))
              return (
                <Box mr={1}>
                  {card.workflowPhaseType === 1 && (
                    <ValidationDraggableCard notifications={notification} card={card} index={index} challengeId={challengeId} />
                  )}
                  {card.workflowPhaseType === 2 && (
                    <CollaborationDraggableCard notifications={notification} card={card} index={index} challengeId={challengeId} />
                  )}
                  {card.workflowPhaseType === 4 && (
                    <DevelopmentDraggableCard notifications={notification} card={card} index={index} challengeId={challengeId} />
                  )}
                  {card.workflowPhaseType === 3 && (
                    <ExpertReviewDraggableCard notifications={notification} card={card} index={index} challengeId={challengeId} />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default CreateChallengeStepConfirmation;
