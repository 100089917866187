import React from "react";
import {
  IMyActivitiesStarsPrivate,
  IMyActivitiesStarsCollaborative,
} from "redux/types/myActivities";
import MyActivityEvaluationItem from "../../components/MyActivityEvaluationItem/MyActivityEvaluationItem";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Hidden,
  makeStyles,
  createStyles,
  Theme,
  TableBody,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableTitles: {
      padding: "20px 20px 0px 0px!important",
      color: " #767676",
      "text-transform": "uppercase",
      [theme.breakpoints.down("sm")]: {
        padding: "0!important",
      },
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "none",
      },
    },
  })
);
interface IProps {
  starPrivate?: IMyActivitiesStarsPrivate;
  starCollaborative?: IMyActivitiesStarsCollaborative;
}

// TODO: API not realized by backend team
function MyActivityStarList(props: IProps) {
  const { starCollaborative, starPrivate } = props;
  const classes = useStyles();
  return (
    <Table
    // aria-label="simple table"
    >
      <TableHead>
        <TableRow className={classes.tableRow}>
          <TableCell
            className={classes.tableTitles}
            variant="head"
            align="left"
          >
            <Hidden smDown>MY EVALUATION</Hidden>
          </TableCell>
          <TableCell
            className={classes.tableTitles}
            variant="head"
            align="left"
          >
            <Hidden smDown>
              {starCollaborative ? "STAR RATED IDEA" : "COMMENT"}
            </Hidden>
          </TableCell>
          <TableCell
            className={classes.tableTitles}
            variant="head"
            align="left"
          >
            <Hidden smDown>
              {starCollaborative ? "CHALLENGE" : "STAR RATED IDEA"}
            </Hidden>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {starPrivate &&
          starPrivate.result &&
          starPrivate.result?.map((starData) => {
            return (
              <MyActivityEvaluationItem
                key={starData.ideaId}
                starResult={starData}
                type="private"
              />
            );
          })}
        {starCollaborative &&
          starCollaborative.result &&
          starCollaborative?.result?.map((starData) => {
            return (
              <MyActivityEvaluationItem
                key={starData.ideaId}
                starResult={starData}
                type="collaborative"
              />
            );
          })}
      </TableBody>
    </Table>
  );
}
export default MyActivityStarList;
