import React from "react";
import sanitizeHtml from "sanitize-html";
import Resource from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import {
  Avatar,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Typography,
} from "@material-ui/core";
import { securityTextEditor } from "utils/securityTextEditor";

interface IProps {
  isMarkedAsRead?: boolean;
  checked?: boolean;
  userName?: string;
  toSent?: string;
  avatar?: string;
  msgSubject?: any;
  msgContent?: any;
  msgDate?: any;
  culture: ICultureState;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    truncate: {
      fontSize: "0.8rem",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  })
);

export default function UserListItem(props: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    isMarkedAsRead,
    checked,
    userName,
    toSent,
    avatar,
    msgSubject,
    msgContent,
    msgDate,
  } = props;

  return (
    <Box
      py={toSent ? 3 : 2}
      style={{
        backgroundColor: checked
          ? theme.palette.primary.light
          : isMarkedAsRead
          ? "transparent"
          : theme.palette.grey[100],
        borderRadius: "6px",
      }}
    >
      <Grid container spacing={1}>
        {avatar && (
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            xs={1}
            sm={1}
            md={1}
            lg={1}
          >
            <Avatar src={avatar} className={classes.avatar} />
          </Grid>
        )}
        {userName && (
          <Grid
            item
            container
            justify="flex-start"
            alignItems="center"
            xs={2}
            sm={2}
            md={2}
            lg={2}
          >
            <Box
              className={classes.truncate}
              fontWeight={checked ? "bold" : "normal"}
            >
              {userName}
            </Box>
          </Grid>
        )}
        {toSent && (
          <Grid
            item
            container
            justify="flex-start"
            alignItems="center"
            xs={2}
            sm={2}
            md={2}
            lg={2}
          >
            <Box className={classes.truncate}>
              <Resource tag="PageMessages::To" />: {toSent}
            </Box>
          </Grid>
        )}
        {!matchXs && msgSubject && (
          <Grid
            item
            container
            justify="flex-start"
            alignItems="center"
            xs={4}
            sm={4}
            md={4}
            lg={4}
          >
            <Box
              fontWeight={checked ? "bold" : 500}
              className={classes.truncate}
            >
              {msgSubject}
            </Box>
          </Grid>
        )}
        {!matchSm && msgContent && (
          <Grid
            item
            container
            justify="flex-start"
            alignItems="center"
            xs={toSent ? 4 : 3}
            sm={toSent ? 4 : 3}
            md={toSent ? 4 : 3}
            lg={toSent ? 4 : 3}
          >
            <Box
              className={classes.truncate}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(msgContent, securityTextEditor),
              }}
            />
          </Grid>
        )}
        {!matchSm && msgDate && (
          <Grid
            item
            container
            justify="flex-end"
            alignItems="center"
            xs={2}
            sm={2}
            md={2}
            lg={2}
          >
            <Typography
              component="p"
              variant="caption"
              noWrap
              color="textSecondary"
              style={{
                fontWeight: checked ? "bold" : "normal",
                paddingRight: "15px",
              }}
            >
              {msgDate}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
