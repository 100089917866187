import { api_v3 } from "config/api";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { CreateChallengesState } from "redux/reducers/createChallenge/createChallengeReducer";
import { treatError } from "utils/treatError";

const basePath = "workflows";

export enum WorkflowActionTypes {
  GET_WORKFLOW = 'GET_WORKFLOW',
  GET_WORKFLOW_TEMPLATE = 'GET_WORKFLOW_TEMPLATE',

  GET_WF_PHASE_VALIDATION = 'GET_WF_PHASE_VALIDATION',
  GET_WF_PHASE_COLLABORATION = 'GET_WF_PHASE_COLLABORATION',
  GET_WF_PHASE_DEVELOPMENT = 'GET_WF_PHASE_DEVELOPMENT',
  GET_WF_PHASE_EXPERTREVIEW = 'GET_WF_PHASE_EXPERTREVIEW',

  POST_WF_PHASE_VALIDATION = "POST_WF_PHASE_VALIDATION", 
  PUT_WF_PHASE_VALIDATION = "PUT_WF_PHASE_VALIDATION",

  POST_WF_PHASE_COLLABORATION_FAILED = "POST_WF_PHASE_COLLABORATION_FAILED",
  POST_WF_PHASE_COLLABORATION_SUCCESS = "POST_WF_PHASE_COLLABORATION_SUCCESS",

  POST_WF_PHASE_EXPERTREVIEW_FAILED = "POST_WF_PHASE_EXPERTREVIEW_FAILED",
  POST_WF_PHASE_EXPERTREVIEW_SUCCESS = "POST_WF_PHASE_EXPERTREVIEW_SUCCESS",

  POST_WF_PHASE_DEVELOPMENT_FAILED = "POST_WF_PHASE_DEVELOPMENT_FAILED",
  POST_WF_PHASE_DEVELOPMENT_SUCCESS = "POST_WF_PHASE_DEVELOPMENT_SUCCESS",

  PUT_WF_PHASE_DEVELOPMENT_FAILED = "PUT_WF_PHASE_DEVELOPMENT_FAILED",
  PUT_WF_PHASE_DEVELOPMENT_SUCCEEDED = "PUT_WF_PHASE_DEVELOPMENT_SUCCEEDED",

  PUT_WF_PHASE_COLLABORATION_FAILED = "PUT_WF_PHASE_COLLABORATION_FAILED",
  PUT_WF_PHASE_COLLABORATION_SUCCEEDED = "PUT_WF_PHASE_COLLABORATION_SUCCEEDED",

  PUT_WF_PHASE_EXPERTREVIEW = "PUT_WF_PHASE_EXPERTREVIEW",

  POST_WF_TEMPLATE = "POST_WF_TEMPLATE",
  POST_WORKFLOW_TEMPLATE_BY_CHALLENGE = 'POST_WORKFLOW_TEMPLATE_BY_CHALLENGE',

  DELETE_WORKFLOW_PHASE = "DELETE_WORKFLOW_PHASE",
  DELETE_ALL_WORKFLOW_PHASE = "DELETE_ALL_WORKFLOW_PHASE",

  PUT_PHASE_ORDER = "PUT_PHASE_ORDER",

  SET_WORKFLOW = "SET_WORKFLOW"
}

export enum WorkflowActionStatus {

  GET_WORKFLOW_LOADING = 'GET_WORKFLOW_LOADING',
  GET_WORKFLOW_FAILED = 'GET_WORKFLOW_FAILED',
  GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS',

  GET_WORKFLOW_TEMPLATE_LOADING = 'GET_WORKFLOW_TEMPLATE_LOADING',
  GET_WORKFLOW_TEMPLATE_SUCCESS = 'GET_WORKFLOW_TEMPLATE_SUCCESS',
  GET_WORKFLOW_TEMPLATE_FAILED = 'GET_WORKFLOW_TEMPLATE_FAILED',

  POST_WF_PHASE_VALIDATION_FAILED = "VALIDATION_FAILED",
  POST_WF_PHASE_VALIDATION_SUCCEEDED = "VALIDATION_SUCCEEDED",

  PUT_WF_PHASE_VALIDATION_FAILED = 'PUT_WF_PHASE_VALIDATION_FAILED',
  PUT_WF_PHASE_VALIDATION_SUCCEEDED = 'PUT_WF_PHASE_VALIDATION_SUCCEEDED',

  GET_WF_PHASE_LOADING_STATUS = "GET_WF_PHASE_LOADING_STATUS",
  GET_WF_PHASE_SUCCEEDED_STATUS = "GET_WF_PHASE_SUCCEEDED_STATUS",
  GET_WF_PHASE_FAILED_STATUS = "GET_WF_PHASE_FAILED_STATUS",

  POST_WF_PHASE_COLLABORATION_FAILED_STATUS = "COLLABORATION_FAILED",
  POST_WF_PHASE_COLLABORATION_SUCCEEDED_STATUS = "COLLABORATION_SUCCESS",

  POST_WF_PHASE_EXPERTREVIEW_FAILED_STATUS = "EXPERTREVIEW_FAILED",
  POST_WF_PHASE_EXPERTREVIEW_SUCCESS_STATUS = "EXPERTREVIEW_SUCCESS",

  POST_WF_PHASE_DEVELOPMENT_FAILED_STATUS = "DEVELOPMENT_FAILED",
  POST_WF_PHASE_DEVELOPMENT_SUCCESS_STATUS = "DEVELOPMENT_SUCCESS",

  PUT_WF_PHASE_DEVELOPMENT_FAILED_STATUS = "PUT_DEVELOPMENT_FAILED",
  PUT_WF_PHASE_DEVELOPMENT_SUCCEEDED_STATUS = "PUT_DEVELOPMENT_SUCCEEDED",

  PUT_WF_PHASE_COLLABORATION_FAILED_STATUS = "PUT_COLLABORATION_FAILED",
  PUT_WF_PHASE_COLLABORATION_SUCCEEDED_STATUS = "PUT_COLLABORATION_SUCCEEDED",

  PUT_WF_PHASE_EXPERTREVIEW_FAILED_STATUS = "PUT_EXPERTREVIEW_FAILED",
  PUT_WF_PHASE_EXPERTREVIEW_SUCCEEDED_STATUS = "PUT_EXPERTREVIEW_SUCCEEDED",

  POST_WF_TEMPLATE_FAILED_STATUS = "POST_WF_TEMPLATE_FAILED_STATUS",
  POST_WF_TEMPLATE_SUCCEEDED_STATUS = "POST_WF_TEMPLATE_SUCCEEDED_STATUS",

  POST_WORKFLOW_TEMPLATE_BY_CHALLENGE_SUCCESS = 'POST_WORKFLOW_TEMPLATE_BY_CHALLENGE_SUCCESS',
  POST_WORKFLOW_TEMPLATE_BY_CHALLENGE_FAILED = 'POST_WORKFLOW_TEMPLATE_BY_CHALLENGE_FAILED',

  DELETE_WORKFLOW_PHASE = 'DELETE_WORKFLOW_PHASE',
  DELETE_WORKFLOW_PHASE_ALL = 'DELETE_WORKFLOW_PHASE_ALL',

  PUT_PHASE_ORDER_SUCCESS = "PUT_PHASE_ORDER_SUCCESS",
  PUT_PHASE_ORDER_FAILED = "PUT_PHASE_ORDER_FAILED",

  SET_WORKFLOW_DYNAMIC = 'SET_WORKFLOW_DYNAMIC',
  SET_WORKFLOW_DYNAMIC_SUCCESS = 'SET_WORKFLOW_DYNAMIC_SUCCESS',
  SET_WORKFLOW_DYNAMIC_FAILED = 'SET_WORKFLOW_DYNAMIC_FAILED'

}

interface GetWorkflowAction {
  type: WorkflowActionTypes.GET_WORKFLOW,
  status: string,
  payload: any
}

interface SetWorkflowAction {
  type: WorkflowActionTypes.SET_WORKFLOW,
  status: string,
  payload: any
}

interface GetWorkflowTemplateAction {
  type: WorkflowActionTypes.GET_WORKFLOW_TEMPLATE,
  payload: any,
  status: string
}

interface GetWfPhaseValidationAction {
  type: WorkflowActionTypes.GET_WF_PHASE_VALIDATION,
  status: string,
  payload?: any
}

interface GetWfPhaseCollaborationAction {
  type: WorkflowActionTypes.GET_WF_PHASE_COLLABORATION,
  status: string,
  payload?: any
}

interface GetWfPhaseDevelopmentAction {
  type: WorkflowActionTypes.GET_WF_PHASE_DEVELOPMENT,
  status: string,
  payload?: any
}
interface GetWfPhaseExpertReviewAction {
  type: WorkflowActionTypes.GET_WF_PHASE_EXPERTREVIEW,
  status: string,
  payload?: any
}
interface PostWfPhaseValidationAction {
  type: WorkflowActionTypes.POST_WF_PHASE_VALIDATION;
  status: string;
  payload?: any;
}

interface PutWfPhaseValidationAction {
  type: WorkflowActionTypes.PUT_WF_PHASE_VALIDATION;
  status: string;
  payload?: any;
}

interface PostWfPhaseCollaborationFailedAction {
  type: WorkflowActionTypes.POST_WF_PHASE_COLLABORATION_FAILED;
  status: string;
}

interface PostWfPhaseCollaborationSucceededAction {
  type: WorkflowActionTypes.POST_WF_PHASE_COLLABORATION_SUCCESS;
  status: string;
}

interface PostWfPhaseExpertReviewFailedAction {
  type: WorkflowActionTypes.POST_WF_PHASE_EXPERTREVIEW_FAILED,
  status: string;
}

interface PostWfPhaseExpertReviewSucceededAction {
  type: WorkflowActionTypes.POST_WF_PHASE_EXPERTREVIEW_SUCCESS,
  status: string;
}

interface PostWfPhaseDevelopmentFailedAction {
  type: WorkflowActionTypes.POST_WF_PHASE_DEVELOPMENT_FAILED,
  status: string;
}

interface PostWfPhaseDevelopmentSucceededAction {
  type: WorkflowActionTypes.POST_WF_PHASE_DEVELOPMENT_SUCCESS,
  status: string,
}

interface PutWfPhaseDevelopmentFailedAction {
  type: WorkflowActionTypes.PUT_WF_PHASE_DEVELOPMENT_FAILED,
  status: string
}

interface PutWfPhaseDevelopmentSucceededAction {
  type: WorkflowActionTypes.PUT_WF_PHASE_DEVELOPMENT_SUCCEEDED,
  status: string,
}

interface PutWfPhaseCollaborationFailedAction {
  type: WorkflowActionTypes.PUT_WF_PHASE_COLLABORATION_FAILED,
  status: string,
}

interface PutWfPhaseCollaborationSucceededAction {
  type: WorkflowActionTypes.PUT_WF_PHASE_COLLABORATION_SUCCEEDED,
  status: string,
}

interface PutWfPhaseExpertReviewAction {
  type: WorkflowActionTypes.PUT_WF_PHASE_EXPERTREVIEW,
  status: string,
}

interface PostWfTemplateAction {
  type: WorkflowActionTypes.POST_WF_TEMPLATE,
  status: string;
}

interface PostWorkflowTemplateByChallengeAction {
  type: WorkflowActionTypes.POST_WORKFLOW_TEMPLATE_BY_CHALLENGE,
  payload: any,
  status: string
}

interface DeleteWorkflowPhaseAction {
  type: WorkflowActionTypes.DELETE_WORKFLOW_PHASE,
  status: string
}

interface DeleteAllWorkflowPhaseAction {
  type: WorkflowActionTypes.DELETE_ALL_WORKFLOW_PHASE,
  status: string
}

interface PutPhaseOrderAction {
  type: WorkflowActionTypes.PUT_PHASE_ORDER,
  status: string
}

interface SetWorkflow {
  type: WorkflowActionTypes.SET_WORKFLOW,
  status: string,
  payload: any
}

export type WorkflowActions = 
  | GetWorkflowAction
  | GetWfPhaseValidationAction
  | GetWfPhaseCollaborationAction
  | GetWfPhaseDevelopmentAction
  | GetWfPhaseExpertReviewAction
  | PostWfPhaseCollaborationFailedAction
  | PostWfPhaseCollaborationSucceededAction
  | PostWfPhaseDevelopmentFailedAction
  | PostWfPhaseDevelopmentSucceededAction
  | PostWfPhaseExpertReviewFailedAction
  | PostWfPhaseExpertReviewSucceededAction
  | PutWfPhaseCollaborationFailedAction
  | PutWfPhaseCollaborationSucceededAction
  | PutWfPhaseDevelopmentFailedAction
  | PutWfPhaseDevelopmentSucceededAction
  | PostWfPhaseValidationAction
  | PutWfPhaseValidationAction
  | PutWfPhaseExpertReviewAction
  | PostWfTemplateAction
  | GetWorkflowTemplateAction
  | PostWorkflowTemplateByChallengeAction
  | DeleteWorkflowPhaseAction
  | DeleteAllWorkflowPhaseAction
  | PutPhaseOrderAction
  | SetWorkflow

  export const setWorkflowDinamic: ActionCreator<
  ThunkAction<Promise<any>, CreateChallengesState, null, SetWorkflowAction>
  > = (options, culture) => {    
    return async (dispatch: Dispatch) => {
      try {     
        dispatch({          
          status: WorkflowActionStatus.SET_WORKFLOW_DYNAMIC_SUCCESS,
          type: WorkflowActionTypes.SET_WORKFLOW,
          payload: options
        })        
      } catch (err) {
        dispatch({
          status: WorkflowActionStatus.SET_WORKFLOW_DYNAMIC_FAILED,
          type: WorkflowActionTypes.SET_WORKFLOW,
        })
        treatError(culture, "Workflow", err)
      }
    }
  }
  
export const getWorkflowAction: ActionCreator<
ThunkAction<Promise<any>, CreateChallengesState, null, GetWorkflowAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";

      dispatch({
        status: WorkflowActionStatus.GET_WORKFLOW_LOADING,
        type: WorkflowActionTypes.GET_WORKFLOW,
      })

      if( options.hasOwnProperty("workspaceId") )
      {
        params.push(`workspaceId=${options.workspaceId }`);
      }
      if( options.hasOwnProperty("challengeId") )
      {
        params.push(`challengeId=${options.challengeId }`);
      }
      if( options.hasOwnProperty("cultureTag") )
      {
        params.push(`cultureTag=${options.cultureTag }`);
      }
      

      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}${params_qs}`,{});

      dispatch({
        status: WorkflowActionStatus.GET_WORKFLOW_SUCCESS,
        type: WorkflowActionTypes.GET_WORKFLOW,
        payload: response.data
      })

    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.GET_WORKFLOW_FAILED,
        type: WorkflowActionTypes.GET_WORKFLOW,
      })
      treatError(culture, "Workflow", err)
    }
  }
}

export const getWorkflowTemplateAction: ActionCreator<
ThunkAction<Promise<any>, CreateChallengesState, null, GetWorkflowTemplateAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";

      dispatch({
        type:WorkflowActionTypes.GET_WORKFLOW_TEMPLATE,
        status: WorkflowActionStatus.GET_WORKFLOW_TEMPLATE_LOADING
      })

      if( options.hasOwnProperty("workspaceId") )
      {
        params.push(`workspaceId=${options.workspaceId }`);
      }
      if( options.hasOwnProperty("pageSize") )
      {
        params.push(`pageSize=${options.pageSize }`);
      } else {
        params.push(`pageSize=${1000}`);
      }
      if( options.hasOwnProperty("pageNumber") )
      {
        params.push(`pageNumber=${options.pageNumber }`);
      }

      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/template${params_qs}`,{});

      dispatch({
        status: WorkflowActionStatus.GET_WORKFLOW_TEMPLATE_SUCCESS,
        type: WorkflowActionTypes.GET_WORKFLOW_TEMPLATE,
        payload: response.data
      })

    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.GET_WORKFLOW_TEMPLATE_FAILED,
        type: WorkflowActionTypes.GET_WORKFLOW_TEMPLATE,
      })
      treatError(culture, "Workflow", err)
    }
  }
}

export const getChallengeDetailCollaborationAction: ActionCreator<
ThunkAction<Promise<any>, CreateChallengesState, null, GetWfPhaseCollaborationAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_LOADING_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_COLLABORATION,
      })

      let params: any[] = [];
      let params_qs = "";


      if( options.hasOwnProperty("workspaceId") )
      {
        params.push(`workspaceId=${options.workspaceId }`);
      }

      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/phase/${options.phaseId}/collaboration${params_qs}`,{});

      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_SUCCEEDED_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_COLLABORATION,
        payload: response.data
      })
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_FAILED_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_COLLABORATION,
      })
      treatError(culture, "Workflow", err)
    }
  }
}

export const getChallengeDetailValidationAction: ActionCreator<
ThunkAction<Promise<any>, CreateChallengesState, null, GetWfPhaseValidationAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_LOADING_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_VALIDATION,
      })

      let params: any[] = [];
      let params_qs = "";


      if( options.hasOwnProperty("workspaceId") )
      {
        params.push(`workspaceId=${options.workspaceId }`);
      }

      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/phase/${options.phaseId}/validation${params_qs}`,{});

      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_SUCCEEDED_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_VALIDATION,
        payload: response.data
      })
      return response.data
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_FAILED_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_VALIDATION,
      })
      treatError(culture, "Workflow", err)
    }
  }
}

export const getChallengeDetailDevelopmentAction: ActionCreator<
ThunkAction<Promise<any>, CreateChallengesState, null, GetWfPhaseDevelopmentAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_LOADING_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_DEVELOPMENT,
      })

      let params: any[] = [];
      let params_qs = "";


      if( options.hasOwnProperty("workspaceId") )
      {
        params.push(`workspaceId=${options.workspaceId }`);
      }

      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/phase/${options.phaseId}/development${params_qs}`,{});

      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_SUCCEEDED_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_DEVELOPMENT,
        payload: response.data
      })
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_FAILED_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_DEVELOPMENT,
      })
      treatError(culture, "Workflow", err)
    }
  }
}

export const getChallengeDetailExpertReviewAction: ActionCreator<
ThunkAction<Promise<any>, CreateChallengesState, null, GetWfPhaseExpertReviewAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_LOADING_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_EXPERTREVIEW,
      })

      let params: any[] = [];
      let params_qs = "";


      if( options.hasOwnProperty("workspaceId") )
      {
        params.push(`workspaceId=${options.workspaceId }`);
      }

      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/phase/${options.phaseId}/expertreview${params_qs}`,{});

      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_SUCCEEDED_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_EXPERTREVIEW,
        payload: response.data
      })
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.GET_WF_PHASE_FAILED_STATUS,
        type: WorkflowActionTypes.GET_WF_PHASE_EXPERTREVIEW,
      })
      treatError(culture, "Workflow", err)
    }
  }
}

export const postChallengeDetailValidationPhaseAction: ActionCreator<
ThunkAction<Promise<any>, CreateChallengesState, null, PostWfPhaseValidationAction>
> = (options,culture) => {
  return async (dispatch:Dispatch) => {
    try {
      const response = await api_v3.post(`${basePath}/phase/validation`, options);

      dispatch({
        status: WorkflowActionStatus.POST_WF_PHASE_VALIDATION_SUCCEEDED,
        type: WorkflowActionTypes.POST_WF_PHASE_VALIDATION,
        payload: response
      })

    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.POST_WF_PHASE_VALIDATION_FAILED,
        type: WorkflowActionTypes.POST_WF_PHASE_VALIDATION,
      })
      treatError(culture, "Workflow", err)
    }
  }
}

export const putChallengeDetailValidationPhaseAction: ActionCreator<
ThunkAction<Promise<any>, CreateChallengesState, null, PutWfPhaseValidationAction>
> = (options, culture) => {
  return async (dispatch:Dispatch) => {
    try {
      const response = await api_v3.put(`${basePath}/phase/${options.phaseId}/validation`, options);

      dispatch({
        status: WorkflowActionStatus.PUT_WF_PHASE_VALIDATION_SUCCEEDED,
        type: WorkflowActionTypes.PUT_WF_PHASE_VALIDATION,
        payload: response.data
      })
      return WorkflowActionStatus.PUT_WF_PHASE_VALIDATION_SUCCEEDED
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.PUT_WF_PHASE_VALIDATION_FAILED,
        type: WorkflowActionTypes.PUT_WF_PHASE_VALIDATION,
      })
      treatError(culture, "Workflow", err)
      return WorkflowActionStatus.PUT_WF_PHASE_VALIDATION_FAILED
    }
  }
}

export const postWfPhaseCollaboration: ActionCreator<
   ThunkAction<Promise<any>,CreateChallengesState, null, PostWfPhaseCollaborationSucceededAction>
  > = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.post(`${basePath}/phase/collaboration`, options);

      dispatch({
        status: WorkflowActionStatus.POST_WF_PHASE_COLLABORATION_SUCCEEDED_STATUS,
        type: WorkflowActionTypes.POST_WF_PHASE_COLLABORATION_SUCCESS,
      });
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.POST_WF_PHASE_COLLABORATION_FAILED_STATUS,
        type: WorkflowActionTypes.POST_WF_PHASE_COLLABORATION_FAILED,
      });
      treatError(culture, "Workflow", err);
    }
  };
};

export const postWfPhaseExpertReview: ActionCreator<
   ThunkAction<Promise<any>,CreateChallengesState, null, PostWfPhaseExpertReviewSucceededAction>
  > = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.post(`${basePath}/phase/expertreview`, options);

      dispatch({
        status: WorkflowActionStatus.POST_WF_PHASE_EXPERTREVIEW_SUCCESS_STATUS,
        type: WorkflowActionTypes.POST_WF_PHASE_EXPERTREVIEW_SUCCESS,
      });
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.POST_WF_PHASE_EXPERTREVIEW_FAILED_STATUS,
        type: WorkflowActionTypes.POST_WF_PHASE_EXPERTREVIEW_SUCCESS,
      });
      treatError(culture, "Workflow", err);
    }
  };
};

export const postWfPhaseDevelopment: ActionCreator<
   ThunkAction<Promise<any>,CreateChallengesState, null, PostWfPhaseDevelopmentSucceededAction>
  > = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.post(`${basePath}/phase/development`, options);

      dispatch({
        status: WorkflowActionStatus.PUT_WF_PHASE_EXPERTREVIEW_SUCCEEDED_STATUS,
        type: WorkflowActionTypes.PUT_WF_PHASE_EXPERTREVIEW,
      });
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.PUT_WF_PHASE_EXPERTREVIEW_FAILED_STATUS,
        type: WorkflowActionTypes.PUT_WF_PHASE_EXPERTREVIEW,
      });
      treatError(culture, "Workflow", err);
    }
  };
};

export const putWfPhaseDevelopment: ActionCreator<
   ThunkAction<Promise<any>,CreateChallengesState, null, PutWfPhaseDevelopmentSucceededAction>
  > = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.put(`${basePath}/phase/${options.phaseId}/development`, options);

      dispatch({
        status: WorkflowActionStatus.PUT_WF_PHASE_DEVELOPMENT_SUCCEEDED_STATUS,
        type: WorkflowActionTypes.PUT_WF_PHASE_DEVELOPMENT_SUCCEEDED,
      });
      return WorkflowActionStatus.PUT_WF_PHASE_DEVELOPMENT_SUCCEEDED_STATUS
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.PUT_WF_PHASE_DEVELOPMENT_FAILED_STATUS,
        type: WorkflowActionTypes.PUT_WF_PHASE_DEVELOPMENT_FAILED,
      });
      treatError(culture, "Workflow", err);
      return WorkflowActionStatus.PUT_WF_PHASE_DEVELOPMENT_FAILED_STATUS
    }
  };
};

export const putWfPhaseCollaboration: ActionCreator<
   ThunkAction<Promise<any>,CreateChallengesState, null, PutWfPhaseCollaborationSucceededAction>
  > = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.put(`${basePath}/phase/${options.phaseId}/collaboration`, options);

      dispatch({
        status: WorkflowActionStatus.PUT_WF_PHASE_COLLABORATION_SUCCEEDED_STATUS,
        type: WorkflowActionTypes.PUT_WF_PHASE_COLLABORATION_SUCCEEDED,
      });

      return WorkflowActionStatus.PUT_WF_PHASE_COLLABORATION_SUCCEEDED_STATUS
    } catch (err) {
      dispatch({
        status: WorkflowActionStatus.PUT_WF_PHASE_COLLABORATION_FAILED_STATUS,
        type: WorkflowActionTypes.PUT_WF_PHASE_COLLABORATION_FAILED,
      });
      treatError(culture, "Workflow", err);
      return WorkflowActionStatus.PUT_WF_PHASE_COLLABORATION_FAILED_STATUS
    }
  };
};

export const putWfPhaseExpertReview: ActionCreator<
ThunkAction<Promise<any>,CreateChallengesState, null, PutWfPhaseExpertReviewAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.put(`${basePath}/phase/${options.phaseId}/expertreview`, options);

      dispatch({
        type: WorkflowActionTypes.PUT_WF_PHASE_EXPERTREVIEW,
        status: WorkflowActionStatus.PUT_WF_PHASE_EXPERTREVIEW_SUCCEEDED_STATUS
      })
      return WorkflowActionStatus.PUT_WF_PHASE_EXPERTREVIEW_SUCCEEDED_STATUS
    } catch (err) {
      dispatch({
        type: WorkflowActionTypes.PUT_WF_PHASE_EXPERTREVIEW,
        status: WorkflowActionStatus.PUT_WF_PHASE_EXPERTREVIEW_FAILED_STATUS
      })
      treatError(culture, "Workflow", err);
      return WorkflowActionStatus.PUT_WF_PHASE_EXPERTREVIEW_FAILED_STATUS
    }
  }
}

export const postWfTemplateAction: ActionCreator<
  ThunkAction<Promise<any>,CreateChallengesState, null, PostWfTemplateAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {

      const params = {
        challengeId: options.challengeId,
        title: options.title,
        worskpaceId: options.workspaceId
      }

      await api_v3.post(`${basePath}/template`, params);

      dispatch({
        type: WorkflowActionTypes.POST_WF_TEMPLATE,
        status: WorkflowActionStatus.POST_WF_TEMPLATE_SUCCEEDED_STATUS
      })
    } catch (err) {
      treatError(culture, "Workflow", err);
      dispatch({
        type: WorkflowActionTypes.POST_WF_TEMPLATE,
        status: WorkflowActionStatus.POST_WF_TEMPLATE_FAILED_STATUS
      })
    }
  }
}

export const postWfTemplateByChallengeAction: ActionCreator<
  ThunkAction<Promise<any>,CreateChallengesState, null, PostWorkflowTemplateByChallengeAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.post(`${basePath}/challenge/template`, options);

      dispatch({
        type: WorkflowActionTypes.POST_WORKFLOW_TEMPLATE_BY_CHALLENGE,
        status: WorkflowActionStatus.POST_WORKFLOW_TEMPLATE_BY_CHALLENGE_SUCCESS
      })
    } catch (err) {
      treatError(culture, "Workflow", err);
      dispatch({
        type: WorkflowActionTypes.POST_WORKFLOW_TEMPLATE_BY_CHALLENGE,
        status: WorkflowActionStatus.POST_WORKFLOW_TEMPLATE_BY_CHALLENGE_FAILED
      })
    }
  }
}

export const deleteWorkflowPhaseAction: ActionCreator<
  ThunkAction<Promise<any>,CreateChallengesState, null, DeleteWorkflowPhaseAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {

      let params: any[] = [];
      let params_qs = "";


      if( options.hasOwnProperty("workspaceId") )
      {
        params.push(`workspaceId=${options.workspaceId }`);
      }
      if( options.hasOwnProperty("challengeId") )
      {
        params.push(`challengeId=${options.challengeId }`);
      }

      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      await api_v3.delete(`${basePath}/phase/${options.phaseId}${params_qs}`, options);

      dispatch({
        type: WorkflowActionTypes.DELETE_WORKFLOW_PHASE,
        status: WorkflowActionStatus.DELETE_WORKFLOW_PHASE
      })
    } catch (err) {
      treatError(culture, "Workflow", err);
      dispatch({
        type: WorkflowActionTypes.DELETE_WORKFLOW_PHASE,
        status: WorkflowActionStatus.DELETE_WORKFLOW_PHASE
      })
    }
  }
}

export const deleteWorkflowAllPhaseAction: ActionCreator<
  ThunkAction<Promise<any>,CreateChallengesState, null, DeleteAllWorkflowPhaseAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      
      let params: any[] = [];
      let params_qs = "";


      if( options.hasOwnProperty("workspaceId") )
      {
        params.push(`workspaceId=${options.workspaceId }`);
      }

      if( options.hasOwnProperty("challengeId") )
      {
        params.push(`challengeId=${options.challengeId }`);
      }

      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      await api_v3.delete(`${basePath}/phase/all${params_qs}`, {});

      dispatch({
        type: WorkflowActionTypes.DELETE_ALL_WORKFLOW_PHASE,
        status: WorkflowActionStatus.DELETE_WORKFLOW_PHASE_ALL
      })
    } catch (err) {
      treatError(culture, "Workflow", err);
      dispatch({
        type: WorkflowActionTypes.DELETE_ALL_WORKFLOW_PHASE,
        status: WorkflowActionStatus.DELETE_WORKFLOW_PHASE_ALL
      })
    }
  }
}

export const putPhaseOrderAction: ActionCreator<
ThunkAction<Promise<any>,CreateChallengesState, null, PutPhaseOrderAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.put(`${basePath}/phase/order`, options);

      dispatch({
        type: WorkflowActionTypes.PUT_PHASE_ORDER,
        status: WorkflowActionStatus.PUT_PHASE_ORDER_SUCCESS
      })
      return WorkflowActionStatus.PUT_PHASE_ORDER_SUCCESS
    } catch (err) {
      dispatch({
        type: WorkflowActionTypes.PUT_PHASE_ORDER,
        status: WorkflowActionStatus.PUT_PHASE_ORDER_FAILED
      })
      treatError(culture, "Workflow", err);
      return WorkflowActionStatus.PUT_PHASE_ORDER_FAILED
    }
  }
}