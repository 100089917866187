import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api_v2, api_general } from "./../../config/api";

import { IUsers, IDepartments, ICountries, IUsersState, ITimeZones, ICurrency } from '../reducers/usersReducers'
import { treatError } from "../../utils/treatError";
import reactNewApp from 'utils/reactNewApp';

const basePath = "users";

export enum UsersActionTypes {
  GET_USERS_LOADING = 'GET_USERS_LOADING',
  GET_USERS_SUCCEEDED = 'GET_USERS_SUCCEEDED',
  GET_USERS_FAILED = 'GET_USERS_FAILED',

  GET_DEPARTMENTS_LOADING = 'GET_DEPARTMENTS_LOADING',
  GET_DEPARTMENTS_SUCCEEDED = 'GET_DEPARTMENTS_SUCCEEDED',
  GET_DEPARTMENTS_FAILED = 'GET_DEPARTMENTS_FAILED',

  GET_COUNTRIES_LOADING = 'GET_COUNTRIES_LOADING',
  GET_COUNTRIES_SUCCEEDED = 'GET_COUNTRIES_SUCCEEDED',
  GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED',

  GET_CURRENCY_LOADING = 'GET_CURRENCY_LOADING',
  GET_CURRENCY_SUCCEEDED = 'GET_CURRENCY_SUCCEEDED',
  GET_CURRENCY_FAILED = 'GET_CURRENCY_FAILED',

  GET_TIMEZONES_LOADING = 'GET_TIMEZONES_LOADING',
  GET_TIMEZONES_SUCCEEDED = 'GET_TIMEZONES_SUCCEEDED',
  GET_TIMEZONES_FAILED = 'GET_TIMEZONES_FAILED'
}

export enum UsersStatus {
  GET_USERS_LOADING = 'LOADING_USERS',
  GET_USERS_SUCCEEDED = 'SUCCEEDED',
  GET_USERS_FAILED = 'FAILED',

  GET_DEPARTMENTS_LOADING = 'LOADING_DEPARTMENTS',
  GET_DEPARTMENTS_SUCCEEDED = 'SUCCEEDED',
  GET_DEPARTMENTS_FAILED = 'FAILED',

  GET_COUNTRIES_LOADING = 'LOADING_COUNTRIES',
  GET_COUNTRIES_SUCCEEDED = 'SUCCEEDED',
  GET_COUNTRIES_FAILED = 'FAILED',

  GET_CURRENCY_LOADING = 'LOADING_CURRENCY',
  GET_CURRENCY_SUCCEEDED = 'SUCCEEDED',
  GET_CURRENCY_FAILED = 'FAILED',

  GET_TIMEZONES_LOADING = 'LOADING_TIMEZONES',
  GET_TIMEZONES_SUCCEEDED = 'SUCCEEDED',
  GET_TIMEZONES_FAILED = 'FAILED'
}


// Interface for Get All Departaments Action Type
export interface IUsersLoadingGetAllAction {
  type: UsersActionTypes.GET_USERS_LOADING;
  status: string;
}

export interface IUsersSucceededGetAllAction {
  type: UsersActionTypes.GET_USERS_SUCCEEDED;
  users: IUsers[];
  status: string;
}

export interface IUsersFailedGetAllAction {
  type: UsersActionTypes.GET_USERS_FAILED;
  status: string;
  error: any;
}

// Interface for Get All Departaments Action Type
export interface IDepartmentsLoadingGetAllAction {
  type: UsersActionTypes.GET_DEPARTMENTS_LOADING;
  status: string;
}

export interface IDepartmentsSucceededGetAllAction {
  type: UsersActionTypes.GET_DEPARTMENTS_SUCCEEDED;
  departaments: IDepartments[];
  status: string;
}

export interface IDepartmentsFailedGetAllAction {
  type: UsersActionTypes.GET_DEPARTMENTS_FAILED;
  status: string;
  error: any;
}

// Interface for Get All Countries Action Type
export interface ICountriesLoadingGetAllAction {
  type: UsersActionTypes.GET_COUNTRIES_LOADING;
  status: string;
}

export interface ICountriesSucceededGetAllAction {
  type: UsersActionTypes.GET_COUNTRIES_SUCCEEDED;
  countries: ICountries[];
  status: string;
}

export interface ICountriesFailedGetAllAction {
  type: UsersActionTypes.GET_COUNTRIES_FAILED;
  status: string;
  error: any;
}

// Interface for get all currency

export interface ICurrencyLoadingGetAllAction {
  type: UsersActionTypes.GET_CURRENCY_LOADING;
  status: string;
}

export interface ICurrencySucceededGetAllAction {
  type: UsersActionTypes.GET_CURRENCY_SUCCEEDED;
  currency: ICurrency[];
  status: string;
}

export interface ICurrencyFailedGetAllAction {
  type: UsersActionTypes.GET_CURRENCY_FAILED;
  status: string;
  error: any;
}

// Interface for Get All Time Zones Action Type
export interface ITimeZonesLoadingGetAllAction {
  type: UsersActionTypes.GET_TIMEZONES_LOADING;
  status: string;
}

export interface ITimeZonesSucceededGetAllAction {
  type: UsersActionTypes.GET_TIMEZONES_SUCCEEDED;
  timeZones: ITimeZones[];
  status: string;
}

export interface ITimeZonesFailedGetAllAction {
  type: UsersActionTypes.GET_TIMEZONES_FAILED;
  status: string;
  error: any;
}

export type UsersActions =
  | IUsersLoadingGetAllAction
  | IUsersSucceededGetAllAction
  | IUsersFailedGetAllAction
  | IDepartmentsLoadingGetAllAction
  | IDepartmentsSucceededGetAllAction
  | IDepartmentsFailedGetAllAction
  | ICurrencyLoadingGetAllAction
  | ICurrencySucceededGetAllAction
  | ICurrencyFailedGetAllAction
  | ICountriesLoadingGetAllAction
  | ICountriesSucceededGetAllAction
  | ICountriesFailedGetAllAction
  | ITimeZonesLoadingGetAllAction
  | ITimeZonesSucceededGetAllAction
  | ITimeZonesFailedGetAllAction

//TODO: get data from API endpoint
// export const getAllUsers : ActionCreator<
//     ThunkAction<Promise<any>,IUsersState,null,IUsersSucceededGetAllAction>
// > = () => {
//     return async (dispatch: Dispatch) => {
//         dispatch({
//             users:response,
//             type:UsersActionTypes.GET_USERS_SUCCEEDED
//         })
//     }
// }

export const getAllUsers: ActionCreator<
  ThunkAction<Promise<any>, IUsersState, null, IUsersSucceededGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: UsersStatus.GET_USERS_LOADING,
        type: UsersActionTypes.GET_USERS_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options?.hasOwnProperty("q")) {
        params.push(`q=${options?.q}`);
      } else {
        params.push(`q=${""}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp ?
        await api_general.get(`${basePath}/autocomplete${params_qs}`, {}).then(response => {
          dispatch({
            users: response.data,
            status: UsersStatus.GET_USERS_SUCCEEDED,
            type: UsersActionTypes.GET_USERS_SUCCEEDED,
          });
        })
        :
        await api_v2.get(`${basePath}/autocomplete${params_qs}`, {}).then(response => {
          dispatch({
            users: response.data,
            status: UsersStatus.GET_USERS_SUCCEEDED,
            type: UsersActionTypes.GET_USERS_SUCCEEDED,
          });
        })

    } catch (err) {
      treatError(options?.culture, 'Users', err);
      dispatch({
        status: UsersStatus.GET_USERS_FAILED,
        type: UsersActionTypes.GET_USERS_FAILED,
      });
    }
  };
};


export const getAllDepartaments: ActionCreator<
  ThunkAction<Promise<any>, IUsersState, null, IDepartmentsSucceededGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: UsersStatus.GET_DEPARTMENTS_LOADING,
        type: UsersActionTypes.GET_DEPARTMENTS_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp ?
        await api_general.get(`${basePath}/departments${params_qs}`, {}).then(response => {
          dispatch({
            departaments: response.data,
            status: UsersStatus.GET_DEPARTMENTS_SUCCEEDED,
            type: UsersActionTypes.GET_DEPARTMENTS_SUCCEEDED,
          });
        })
        :
        await api_v2.get(`${basePath}/departments${params_qs}`, {}).then(response => {
          dispatch({
            departaments: response.data,
            status: UsersStatus.GET_DEPARTMENTS_SUCCEEDED,
            type: UsersActionTypes.GET_DEPARTMENTS_SUCCEEDED,
          });
        })

    } catch (err) {
      treatError(options.culture, 'Users', err);
    }
  };
};

export const getAllTimeZones: ActionCreator<
  ThunkAction<Promise<any>, IUsersState, null, IDepartmentsSucceededGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: UsersStatus.GET_TIMEZONES_LOADING,
        type: UsersActionTypes.GET_TIMEZONES_LOADING,
      });
      if (reactNewApp) {
        const response = await api_general.get(`${basePath}/timezones`, {});
        dispatch({
          timeZones: response.data,
          status: UsersStatus.GET_TIMEZONES_SUCCEEDED,
          type: UsersActionTypes.GET_TIMEZONES_SUCCEEDED,
        });
      } else {
        const response = await api_v2.get(`${basePath}/timezones`, {});
        dispatch({
          timeZones: response.data,
          status: UsersStatus.GET_TIMEZONES_SUCCEEDED,
          type: UsersActionTypes.GET_TIMEZONES_SUCCEEDED,
        });
      }

    } catch (err) {
      dispatch({
        error: err,
        status: UsersStatus.GET_TIMEZONES_FAILED,
        type: UsersActionTypes.GET_TIMEZONES_FAILED,
      });
      treatError(options.culture, 'Users', err);
    }
  };
};

export const getAllCountries: ActionCreator<
  ThunkAction<Promise<any>, IUsersState, null, ICountriesSucceededGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: UsersStatus.GET_COUNTRIES_LOADING,
        type: UsersActionTypes.GET_COUNTRIES_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp ?
        await api_general.get(`${basePath}/countries${params_qs}`, {}).then(response => {
          dispatch({
            countries: response.data,
            status: UsersStatus.GET_COUNTRIES_SUCCEEDED,
            type: UsersActionTypes.GET_COUNTRIES_SUCCEEDED,
          });
        })
        :
        await api_v2.get(`${basePath}/countries${params_qs}`, {}).then(response => {
          dispatch({
            countries: response.data,
            status: UsersStatus.GET_COUNTRIES_SUCCEEDED,
            type: UsersActionTypes.GET_COUNTRIES_SUCCEEDED,
          });
        })

    } catch (err) {
      treatError(options.culture, 'Users', err);
    }
  };
};

export const getAllCurrency: ActionCreator<
  ThunkAction<Promise<any>, IUsersState, null, ICurrencySucceededGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: UsersStatus.GET_CURRENCY_LOADING,
        type: UsersActionTypes.GET_CURRENCY_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      await api_general.get(`${basePath}/currencies${params_qs}`, {}).then(response => {
        dispatch({
          currency: response.data,
          status: UsersStatus.GET_CURRENCY_SUCCEEDED,
          type: UsersActionTypes.GET_CURRENCY_SUCCEEDED,
        });
      })

    } catch (err) {
      treatError(options.culture, 'Users', err);
    }
  };
};