import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
import Resource from "components/Resources/Resource";
import XDialog from "common/X/XDialog";
import ToolTip from "components/ToolTip/ToolTip";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IAppState } from "redux/store/Store";
import { connect } from "react-redux";
import { IManageUsersState } from "redux/types/manageUsers";
import { putWalletGivePoints, putWalletRemovePoints, putWalletResetPoints } from "redux/actions/manageUsers/ManageUsersAction";

interface IDispatchProps {
  putWalletGivePoints: (options: object) => Promise<IManageUsersState>
  putWalletResetPoints: (options: object) => Promise<IManageUsersState>
  putWalletRemovePoints: (options: object) => Promise<IManageUsersState>
}
interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  groupData?: any;
  countries?: ICountries[];
  departments?: IDepartments[];
  workspaceId: string;
  usersId: string[];
  givePointsStatus: string;
  resetPointsStatus: string;
  removePointsStatus: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& p": {
        textAlign: "center",
        padding: "10px 0",
      },
      "& div": {
        "& input": {
          textAlign: "center",
          maxWidth: "10ch",
          fontWeight: "bold",
          fontSize: "1.350em",
        },
      },
    },
  })
);
function ManagePoints(props: IProps & IDispatchProps) {
  const { 
    open, 
    setOpen,
    usersId,
    workspaceId,
    givePointsStatus,
    resetPointsStatus,
    removePointsStatus,
    putWalletGivePoints,
    putWalletRemovePoints,
    putWalletResetPoints
  } = props;
  const [points, setPoints] = useState(500);
  const [statePopUp, setState] = useState("points");
  const classes = useStyles();
  const handlePut = (state: string) => {
    switch(state){
      case 'remove':
        putWalletRemovePoints({
          workspaceId,
          usersId,
          points
        }).then(() => {
          setOpen(false)
        })
        return
      case 'reset':
        putWalletResetPoints({
          workspaceId,
          usersId
        }).then(() => {
          setOpen(false)
        })
        return
      case 'give':
        putWalletGivePoints({
          workspaceId,
          usersId,
          points
        }).then(() => {
          setOpen(false)
        })
        return
      default:
        return
    }
      
  }
  useEffect(() => {
    setState("points");
  }, [open]);

  const handleChange = (e: any) => {
    setPoints(e.target.value);
  };
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      maxWidth="sm"
      title={
        <>
          <Resource tag="PageManageUsers::DialogManagePoints_ManagePoints" />
        </>
      }
      actions={
        <>
          {statePopUp === "points" ? (
            <Box display="flex">
              <Grid container spacing={2} wrap={matchXs ? 'wrap' : "nowrap"}>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    onClick={() => setState("reset")}
                    variant="text"
                    size="small"
                  >
                    <Resource tag="PageManageUsers::DialogManagePoints_ResetPoints" />
                  </Button>
                  <ToolTip
                    top={-160}
                    title={
                      <Resource tag="PageManageUsers::DialogManagePoints_ResetPoints" />
                    }
                  >
                    <Resource tag="PageManageUsers::DialogManagePoints_Reset" />
                  </ToolTip>
                </Grid>
								{matchXs &&
									<Grid item xs={12}>
										<Button
											color="primary"
											onClick={() => setOpen(false)}
											variant="outlined"
											size="small"
										>
											<Resource tag="PageManageUsers::DialogManagePoints_Cancel" />
										</Button>
									</Grid>
								}
              </Grid>
              <Grid container justify="flex-end" spacing={2} wrap={matchXs ? 'wrap' : "nowrap"}>
                {!matchXs &&
									<Grid item xs={12}>
										<Button
											color="primary"
											onClick={() => setOpen(false)}
											variant="outlined"
											size="small"
										>
											<Resource tag="PageManageUsers::DialogManagePoints_Cancel" />
										</Button>
									</Grid>
								}		
                <Grid item container justify="flex-end" xs={12}>
                  <Button
                    color="primary"
                    onClick={() => setState("remove")}
                    variant="contained"
                    size="small"
                  >
                    <Resource tag="PageManageUsers::DialogManagePoints_Remove" />
                  </Button>
                </Grid>
                <Grid item container justify="flex-end" xs={12}>
									<Box>
										<Button
											color="primary"
											onClick={() => setState("give")}
											variant="contained"
											size="small"
										>
											<Resource tag="PageManageUsers::DialogManagePoints_Give" />
										</Button>
									</Box>
                  
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => setState("points")}
                  variant="outlined"
                  size="small"
                >
                  <Resource tag="PageManageUsers::DialogManagePoints_NoCancel" />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => handlePut(statePopUp)}
                  endIcon={
                    givePointsStatus === 'LOADING' || resetPointsStatus === 'LOADING' || removePointsStatus === 'LOADING' ? 
                    <CircularProgress size={14} color="inherit"/>
                    :
                    ''
                  }
                  variant="contained"
                  size="small"
                >
                  <Resource tag="PageManageUsers::DialogManagePoints_Yes" />
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      }
    >
      <>
        {statePopUp === "points" ? (
          <>
            <Typography color="textSecondary">
              <Resource tag="PageManageUsers::DialogManagePoints_DefineTheNumberOfPointsYouWantToGiveOrRemove" />
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100px"
              pt={2}
            >
              <TextField
                id="margin-none"
                type="numeric"
                // defaultValue={500}
                placeholder="500"
                onChange={handleChange}
                value={points}
                classes={{
                  root: classes.root,
                }}
                helperText={
                  <Resource tag="PageManageUsers::DialogManagePoints_points" />
                }
                // className={classes.textField}
              />
            </Box>
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100px"
            pt={2}
            paddingX={5}
            textAlign="center"
          >
            <Typography>
              {statePopUp === "reset" && (
                <Resource tag="PageManageUsers::DialogManagePoints_AreYouSureWantToResetThePointsToTheSelectedUsers" />
              )}
              {statePopUp === "remove" && (
                <Resource
                  tag="PageManageUsers::DialogManagePoints_AreYouSureWantToRemoveXPointsToTheSelectedUsers"
                  args={[points]}
                />
              )}
              {statePopUp === "give" && (
                <Resource
                  tag="PageManageUsers::DialogManagePoints_AreYouSureWantToGiveXPointsToTheSelectedUsers"
                  args={[points]}
                />
              )}
            </Typography>
          </Box>
        )}
      </>
    </XDialog>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    putWalletGivePoints: (options: object) => dispatch(putWalletGivePoints(options)),
    putWalletResetPoints: (options: object) => dispatch(putWalletResetPoints(options)),
    putWalletRemovePoints: (options: object) => dispatch(putWalletRemovePoints(options)),
  };
};

const mapStateToProps = (store:IAppState) => ({
  workspaceId: store.authState.auth.workspaceId,
  removePointsStatus: store.manageUsersState.givePointsStatus,
  resetPointsStatus: store.manageUsersState.resetPointsStatus,
  givePointsStatus: store.manageUsersState.removePointsStatus,
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagePoints);
