import React, { useEffect, useState } from "react";
import "./EvaluationBox.css";
import { Grid, Typography } from "@material-ui/core";
import MiniLine from "../../components/MiniLine/MiniLine";
import Resource from "../../components/Resources/Resource";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

interface IProps {
  type?: string;
  value: number;
  variation?: number;
  graph?: any[];
  scale: number;
  evaluated?: boolean
}

export default function EvaluationBox (props:IProps) {
  const [likes, setLikes] = useState(props.value)
  const { evaluated } = props

  useEffect(()=>{
    let isMounted = true
    evaluated && isMounted ? setLikes(prev => prev + 1) : setLikes(prev => prev - 1)
    return () => {isMounted = false}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluated])

  useEffect(() => {
    let isMounted = true
    isMounted && setLikes(props.value)
    return () => {isMounted = false}
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  const like = () => {
    return (
      <Grid container justify="flex-end" className="like-main-container">
        <Grid
          className="quantity-and-icon-container"
          container
          spacing={1}
          justify="flex-end"
        >
          <Grid item className="quant-like">
            <Typography
              variant="h2"
              color="textSecondary"
              style={{ paddingTop: 4 }}
            >
              {likes}
            </Typography>
          </Grid>
          <Grid item className="icon">
            <ThumbUpAltIcon style={{ fontSize: "1.6rem", color: "#727C81" }} />
          </Grid>
        </Grid>
        <Grid item className="likes">
          <Typography variant="caption" color="textSecondary">
            {likes === 1 ? (
              <Resource tag="Common::Liked" />
            ) : (
              <Resource tag="Common::Likes" />
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const star = () => {
    return (
      <Grid container justify="flex-end" className="stars-main-container">
        <Grid
          container
          spacing={1}
          justify="flex-end"
          className="quantity-and-icon-container"
        >
          <Grid item className="quant-stars">
            <Typography
              variant="h2"
              color="textSecondary"
              style={{ paddingTop: 4 }}
            >
              {props.value}
            </Typography>
          </Grid>
          <Grid item className="icon">
            <StarRoundedIcon style={{ fontSize: "1.6rem", color: "#727C81" }} />
          </Grid>
        </Grid>
        <Grid item className="average-rating">
          <Typography variant="caption" color="textSecondary">
            <Resource tag="PageDetailIdea::EvaluationArea_RatingAverage" />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const roi = () => {
    function intToString(value: number) {
      const suffixes = ["", "K", "M", "B", "T"];
      const suffixNum = Math.floor(("" + value).length / 3);
      let shortValue;
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(2)
      );
      if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(1);
      }
      return shortValue + suffixes[suffixNum];
    }

    return (
      <div className="EvaluationBoxRoi">
        <div className="EvaluationBoxRoiValue">
          $ {intToString(props.value)}
        </div>
        <div className="EvaluationBoxRoiTitle">
          <Resource tag="PageIdea::Idea_RoiAchieved" />
        </div>
      </div>
    );
  };

  const investment = () => {
    const miniHeight = 80 * props.scale;
    return (
      <>
        <Grid
          container
          justify="flex-end"
          className="investment-main-container"
        >
          {props.variation != null && props.variation >= 0 ? (
            <>
              <Grid
                className="quantity-and-icon-container"
                container
                spacing={1}
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item className="quant-investment">
                  <Typography
                    variant="h2"
                    color="textSecondary"
                    style={{ paddingTop: 4 }}
                  >
                    {props?.value?.toFixed(0)}%
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex" }}
                  className="icon-and-variation"
                >
                  {props?.variation !== 0 ? (
                    <ArrowUpwardIcon
                      style={{
                        fontSize: "1.2rem",
                        color: "#1FC723",
                        marginRight: "2px",
                        fontWeight: "bold",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <Typography
                    style={{
                      color:
                        props?.variation !== 0 ? "#1FC723" : "#609BBD",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                    }}
                  >
                    {props?.variation?.toFixed(0)}%
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ paddingTop: "4px" }}>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    className="crowdbelief-and-graph"
                  >
                    <Resource
                      tag={"PageDetailIdea::InvestmentArea_CrowdBelief"}
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <MiniLine
                    data={props.graph}
                    height={50}
                    width={100}
                    variation={props?.variation}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                className="quantity-and-icon-container"
                container
                spacing={1}
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item className="quant-investment">
                  <Typography
                    variant="h2"
                    color="textSecondary"
                    style={{ paddingTop: 4 }}
                  >
                    {props?.value?.toFixed(0)}%
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex" }}
                  className="icon-and-variation"
                >
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1.2rem",
                      color: "#e57373",
                      marginRight: "2px",
                      fontWeight: "bold",
                    }}
                  />
                  <Typography
                    style={{
                      color: "#e57373",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                    }}
                  >
                    {props?.variation?.toFixed(0)}%
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ paddingTop: "4px" }}>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    className="crowdbelief-and-graph"
                  >
                    <Resource
                      tag={"PageDetailIdea::InvestmentArea_CrowdBelief"}
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <MiniLine
                    data={props.graph}
                    height={miniHeight}
                    width={100}
                    variation={props?.variation}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  };

  const newInvestment = () => {
    const miniHeight = 80 * props.scale;
    return (
      <>
        <Grid
          container
          justify="flex-end"
          style={{ paddingTop: "16px", paddingRight: "16px" }}
          className="investment-main-container"
        >
          {props.variation != null && props.variation >= 0 ? (
            <>
              <Grid
                className="quantity-and-icon-container"
                container
                spacing={1}
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item className="quant-investment">
                  <Typography
                    variant="h2"
                    color="textSecondary"
                    style={{ paddingTop: 4 }}
                  >
                    {props?.value?.toFixed(0)}%
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex" }}
                  className="icon-and-variation"
                >
                  {props?.variation !== 0 ? (
                    <ArrowUpwardIcon
                      style={{
                        fontSize: "1.2rem",
                        color: "#1FC723",
                        marginRight: "2px",
                        fontWeight: "bold",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <Typography
                    style={{
                      color:
                        props?.variation !== 0 ? "#1FC723" : "#609BBD",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                    }}
                  >
                    {props?.variation?.toFixed(0)}%
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ paddingTop: "4px" }}>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    className="crowdbelief-and-graph"
                  >
                    <Resource
                      tag={"PageDetailIdea::InvestmentArea_CrowdBelief"}
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <MiniLine
                    data={props.graph}
                    height={50}
                    width={100}
                    variation={props?.variation}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                className="quantity-and-icon-container"
                container
                spacing={1}
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item className="quant-investment">
                  <Typography
                    variant="h2"
                    color="textSecondary"
                    style={{ paddingTop: 4 }}
                  >
                    {props?.value?.toFixed(0)}%
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex" }}
                  className="icon-and-variation"
                >
                  <ArrowDownwardIcon
                    style={{
                      fontSize: "1.2rem",
                      color: "#e57373",
                      marginRight: "2px",
                      fontWeight: "bold",
                    }}
                  />
                  <Typography
                    style={{
                      color: "#e57373",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                    }}
                  >
                    {props?.variation?.toFixed(0)}%
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ paddingTop: "4px" }}>
                <Grid item>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    className="crowdbelief-and-graph"
                  >
                    <Resource
                      tag={"PageDetailIdea::InvestmentArea_CrowdBelief"}
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <MiniLine
                    data={props.graph}
                    height={miniHeight}
                    width={100}
                    variation={props?.variation}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  };

  const newLike = () => {
    return (
      <>
        <Grid
          container
          justify="flex-end"
          style={{ paddingTop: "16px", paddingRight: "16px" }}
          className="like-main-container"
        >
          <Grid
            className="quantity-and-icon-container"
            container
            spacing={1}
            justify="flex-end"
          >
            <Grid item className="quant-like">
              <Typography
                variant="h2"
                color="textSecondary"
                style={{ paddingTop: 4 }}
              >
                {likes}
              </Typography>
            </Grid>
            <Grid item className="icon">
              <ThumbUpAltIcon
                style={{ fontSize: "1.6rem", color: "#727C81" }}
              />
            </Grid>
          </Grid>
          <Grid item className="likes">
            <Typography variant="caption" color="textSecondary">
              {likes === 1 ? (
                <Resource tag="Common::Liked" />
              ) : (
                <Resource tag="Common::Likes" />
              )}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };
  const newStars = () => {
    return (
      <>
        <Grid
          container
          justify="flex-end"
          style={{ paddingTop: "16px", paddingRight: "16px" }}
          className="stars-main-container"
        >
          <Grid
            container
            spacing={1}
            justify="flex-end"
            className="quantity-and-icon-container"
          >
            <Grid item className="quant-stars">
              <Typography
                variant="h2"
                color="textSecondary"
                style={{ paddingTop: 4 }}
              >
                {props.value}
              </Typography>
            </Grid>
            <Grid item className="icon">
              <StarRoundedIcon
                style={{ fontSize: "1.6rem", color: "#727C81" }}
              />
            </Grid>
          </Grid>
          <Grid item className="average-rating">
            <Typography variant="caption" color="textSecondary">
              <Resource tag="PageDetailIdea::EvaluationArea_RatingAverage" />
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

    switch (props.type?.toUpperCase()) {
      case "LIKES":
        return like();
      case "STARS":
        return star();
      case "ROI":
        return roi();
      case "INVESTMENTS":
        return investment();
      case "NEWINVESTMENTS":
        return newInvestment();
      case "NEWSTARS":
        return newStars();
      case "NEWLIKES":
        return newLike();
      default:
        return <div></div>;
    }
}
