import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { ArrowForward } from "@material-ui/icons";
import Resource from "components/Resources/Resource";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { getAllCurrency, getAllDepartaments } from "redux/actions/UsersAction";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import Input from '@material-ui/core/Input';

import {
  Container,
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Button,
  CircularProgress,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import {
  getAllWorkspaces,
  getWorkspace,
  getSuggestedNamesWorkspaces,
  postWorkspaces,
  putWorkspacesCurrencies
} from "redux/actions/workspaceAction";
import {
  IWorkspaceReducer,
  ISuggestedNamesWorkspaces,
  IWorkspaceState,
} from "redux/reducers/workspaceReducer";
import { IAuthUser } from "redux/reducers/authReducer";
import { ICurrency } from "redux/reducers/usersReducers";
import ToolTip from "components/ToolTip/ToolTip";

interface IDispatchProps {
  getAllWorkspace: (options: object) => Promise<IWorkspaceState>;
  getWorkspace: (options: object) => Promise<IWorkspaceState>;
  getSuggestedNamesWorkspace: (options: object) => Promise<IWorkspaceState>;
  postWorkspaces: (options: object) => Promise<any>;
  getCurrencies: (options: object, culture: ICultureState) => Promise<any>;
  putWorkspaceCurrency: (option: object) => Promise<IWorkspaceState>;
}

interface IProps {
  setCurrentScreen: Dispatch<SetStateAction<number>>;
  istab?: boolean
}

interface IStateProps {
  authUser: IAuthUser;
  workspaces: IWorkspaceReducer;
  suggestedNames: ISuggestedNamesWorkspaces;
  application: IApplication;
  culture: ICultureState;
  currencies: ICurrency[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setWorkspaceTitle: {
      fontSize: "22px",
      textAlign: "left",
      color: "#313B53",
      fontWeight: 700,
      marginBottom: theme.spacing(7),
    },
    btnNames: {
      padding: "5px 8px",
      marginRight: "8px",
    },
  })
);

const options = {
  applicationId: 2,
  culture: {},
};

function StepWorkspaceCurrency(props: IDispatchProps & IStateProps & IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const {
    setCurrentScreen,
    getCurrencies,
    getAllWorkspace,
    putWorkspaceCurrency,
    getWorkspace,
    culture,
    currencies,
    workspaces,
    istab
  } = props;

  const [currenciesValue, setCurrencies] = useState<string[]>(workspaces.currencies ?? []);

  const { cultureTag } = culture?.culture ?? "";

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrencies(event.target.value as string[]);
  };

  const handleDelete = (item: any) => {
    setCurrencies(currenciesValue.filter(currency => currency !== item))
  }

  const mapTip = (item: string) => {
    let cur = currencies?.filter(currency => currency.currencyId === item)[0];
    if (cur)
      return `${cur.description} ${cur.symbol}`
    return '';
  }

  useEffect(() => {
    getCurrencies({ cultureTag }, culture);
  }, [])

  function putCurrenciesWorkspace() {
    setLoadingSubmit(true);

    let params = {
      culture: props?.culture,
      currencies: currenciesValue,
      workspaceId: workspaces?.workspaceId,
    };

    options.culture = props?.culture;

    putWorkspaceCurrency(params).then(() => {
      if (!istab) {
        getAllWorkspace(options)
          .then(() => {
            setCurrentScreen((prevState) => prevState + 1);
          });
      } else {
        getWorkspace(params);
      }
      setLoadingSubmit(false);
    });

  }

  return (
    <Container>
      <Typography className={classes.setWorkspaceTitle}>
        <Resource tag="PageSetup::WorkspaceCurrency" />
      </Typography>
      <FormControl fullWidth>
        <InputLabel shrink id="multiple-label">
          <Box display="flex" alignItems="center" gridGap="5px">
            <Resource tag="PageSetup::WorkspaceCurrency" />
            <ToolTip children={<Resource tag="PageSetup::WorkspaceCurrencyToltip" />} />
          </Box>
        </InputLabel>
        <Select
          labelId="multiple-label"
          id="multiple"
          multiple
          fullWidth
          value={currenciesValue}
          onChange={handleChange}
          input={<Input />}
        >
          {currencies?.map((currency) => (
            <MenuItem key={currency.currencyId} value={currency.currencyId}>
              {currency.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        pt="5px"
        display="flex"
        gridGap="5px"
        flexWrap="wrap"
        justifyContent="center"
      >
        {currenciesValue?.map(item => {
          return (
            <Chip
              label={mapTip(item)}
              onDelete={() => handleDelete(item)}
              variant="outlined"
            />
          )
        })}
      </Box>
      <Box textAlign="right" mt={7}>
        <Button
          disabled={currenciesValue.length === 0}
          onClick={putCurrenciesWorkspace}
          color="primary"
          variant="contained"
          endIcon={
            !loadingSubmit ? (
              <ArrowForward className="icon icon-arrow" />
            ) : (
              <CircularProgress
                size={22}
                style={{ color: `${theme.palette.common.white}` }}
              />
            )
          }
        >
          {istab ?
            <Resource tag="PageSetup::Save" /> :
            <Resource tag="PageSetup::Continue" />
          }
        </Button>
      </Box>
    </Container>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllWorkspace: (options: object) => dispatch(getAllWorkspaces(options)),
    getWorkspace: (options: object) => dispatch(getWorkspace(options)),
    getSuggestedNamesWorkspace: (options: object) => dispatch(getSuggestedNamesWorkspaces(options)),
    postWorkspaces: (params: object) => dispatch(postWorkspaces(params)),
    getCurrencies: (options: object, culture: ICultureState) => dispatch(getAllCurrency(options, culture)),
    putWorkspaceCurrency: (options: object) => dispatch(putWorkspacesCurrencies(options))
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    authUser: store.authState.auth,
    currencies: store.usersState.currency,
    application: store.tenantState.application,
    culture: store.cultureState,
    workspaces: store.workSpacesState.workspaces,
    suggestedNames: store.workSpacesState.suggestedNamesWorkspaces,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepWorkspaceCurrency);