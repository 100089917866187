import "./FeedIdeaCollaborative.css";
import Truncate from "react-truncate";
import Image from "./../Image/Image";
import sanitizeHtml from "sanitize-html";
import EMIcon from "components/Icon/Icon";
import reactNewApp from "utils/reactNewApp";
import Resource from "../Resources/Resource";
import { AvatarGroup } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import EvaluationBox from "../EvaluationBox/EvaluationBox";
import { IFeedIdea } from "../../redux/reducers/feedReducer";
import CommentList from "components/CommentList/CommentList";
import { ICultureState } from "redux/reducers/cultureReducer";
import ReviewStars from "components/ReviewRatingBox/ReviewStars";
import { IAllIdeasState } from "redux/reducers/ideas/AllIdeasReducer";
import InvestmentPopUp from "components/ReviewRatingBox/ReviewInvestPopup";
import ReviewReinvestmentPopup from "components/ReviewRatingBox/ReviewReinvestPopup";
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  Theme,
  makeStyles,
  createStyles,
  ClickAwayListener,
  CardContent,
  Link,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  useMediaQuery,
  Avatar,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: "100%",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    titleDivider: {
      width: "96%",
      margin: "10px 0",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    popupTitle: {
      textTransform: "lowercase",
      "&:first-letter": {
        textTransform: "capitalize",
      },
      padding: "21px 36px 4px 36px",
    },
    gridExpectedROI: {
      height: "100%",
      textAlign: "end",
      justifyContent: "flex-end",
      color: "#FFF",
    },
    small: {
      width: "31px",
      height: "32px",
    },
    author: {
      fontSize: "0.6rem",
      textTransform: "uppercase",
      color: "#FFF",
    },
    authorName: {
      fontSize: "0.6rem",
      textTransform: "capitalize",
      fontWeight: 700,
    },
    avatarGroup: {
      "& .MuiAvatarGroup-avatar": {
        border: "none",
      },
    },
  })
);

interface IProps {
  workspaceId: string;
  size: string;
  result: IFeedIdea;
  postInvestments: (options: object) => void;
  applicationId: string | number;
  name?: string;
  culture: ICultureState;
  getAllIdeas: (options: object) => Promise<IAllIdeasState>;
  postLikes: (options: object) => Promise<any>;
  deleteLikes: (options: object) => Promise<any>;
}

export default function FeedCardIdeaCollaborative(props: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    result,
    applicationId,
    postInvestments,
    name,
    postLikes,
    deleteLikes,
  } = props;
  const ideaBackURL = "/idea/details";
  let value;
  let totalEvaluation;
  let graph;
  let variation;
  const investment = props?.result?.evaluation?.investment!;
  const [open, setOpen] = useState(false);
  const [isAuthor, setAuthor] = useState(false);
  const [isReviewOn, setIsReviewOn] = useState(false);
  const [isInvestmentOn, setInvestmentOn] = useState(false);
  const [isReinvestmentOn, setReinvestmenOn] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [evaluated, setEvaluated] = useState(props?.result?.isRated);

  useEffect(() => {
    result?.author?.fullName === name ? setAuthor(true) : setAuthor(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result?.author?.fullName, name]);

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClickComments = () => {
    setOpen((prev) => !prev);
  };

  const doInvestment = (value: any, isIncrease: boolean) => {
    const ideaId = props?.result?.ideaId;
    postInvestments({
      ideaId,
      points: value,
      isIncrease,
      applicationId,
      isFeed: true,
    });
  };

  totalEvaluation = result?.totalEvaluations;
  switch (result?.evaluation?.evaluationType) {
    case "Stars":
      value = result?.evaluation?.starsEvaluation.average;
      break;
    case "Investments":
      value = result?.evaluation?.investment.investmentBeliefValue;
      variation = result?.evaluation?.investment.investmentBeliefVariation;
      graph = Object.values(
        result?.evaluation?.investment.investmentGraph
      ) as Array<number>;
      break;
    case "Likes":
      value = result?.evaluation?.likesEvaluation.countEvaluations;
      break;
    default:
  }

  const likeOptions = {
    ideaId: props?.result?.ideaId,
    applicationId: props?.applicationId,
    isFeed: true,
  };

  var div: any = document.createElement("div");
  div.innerHTML = result.description;
  var firstImage = div.getElementsByTagName("img")[0];
  var rawImgSrc = firstImage ? firstImage.getAttribute("src") : undefined;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Card>
        <CardContent
          style={{
            backgroundColor: `${
              props?.result?.isChosenForImplementation ? "#1EC322" : "#FFF"
            }`,
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              style={{
                textTransform: "uppercase",
                color: `${
                  props?.result?.isChosenForImplementation
                    ? "#323054"
                    : "#888888"
                }`,
              }}
            >
              <Resource tag="PageFeed::WebIdea" />
              <Box component="span" ml={0.3} fontWeight="bold">
                { reactNewApp && !props?.result?.isChosenForImplementation && props?.result?.phase?.cultures?.find((data) => data?.title)?.title}
                { reactNewApp &&  props?.result?.isChosenForImplementation && <Resource tag="PageFeed::ChosenForImplem" /> }
                {!reactNewApp && props?.result?.state}
              </Box>
            </Typography>
            <Box mt={1} mb={1}>
              <Link
                component={RouterLink}
                to={{
                  pathname: `${ideaBackURL}/${result?.ideaId}`,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: `${
                      props?.result?.isChosenForImplementation ? "#FFF" : "#000"
                    }`,
                  }}
                >
                  <Truncate
                    lines={1}
                    width={570}
                    trimWhitespace
                    ellipsis={<span>...</span>}
                  >
                    {props?.result?.title}
                  </Truncate>
                </Typography>
              </Link>
            </Box>
          </Box>
          <Grid container>
            <Grid item xs sm md={9}>
              <Box
                mb={2}
                style={{
                  color: `${
                    props?.result?.isChosenForImplementation ? "#FFF" : "#000"
                  }`,
                }}
              >
                <Truncate
                  lines={4}
                  width={570}
                  trimWhitespace
                  ellipsis={
                    <span className="FeedCardIdeaReadMore">
                      <Link
                        component={RouterLink}
                        to={{
                          pathname: `${ideaBackURL}/${result?.ideaId}`,
                        }}
                      >
                        <Box
                          style={{
                            textDecoration: "underline white",
                            color: `${
                              props?.result?.isChosenForImplementation
                                ? "#FFF"
                                : "#000"
                            }`,
                          }}
                        >
                          ...
                          <Resource tag="PageFeed::Menu_More" />
                        </Box>
                      </Link>
                    </span>
                  }
                >
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(result?.description, {
                        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                          "figure",
                          "img",
                        ]),
                        allowedAttributes: {
                          span: ["style"],
                          img: ["src"],
                        },
                        selfClosing: [
                          "img",
                          "br",
                          "hr",
                          "area",
                          "base",
                          "basefont",
                          "input",
                          "link",
                          "meta",
                        ],
                        allowedSchemes: ["data"],
                      }),
                    }}
                  />
                </Truncate>
              </Box>
            </Grid>
            <Grid item xs sm md={3}>
              <Box display="flex" justifyContent="flex-end">
                {props?.result?.isChosenForImplementation ? (
                  <Grid item xs={4} sm={4} md={4}>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.gridExpectedROI}
                      >
                        <Typography variant="h4">
                          {props?.result?.isImplemented &&
                          props?.result?.expectedROI > 0 ? (
                            <> $ {props.result?.expectedROI} </>
                          ) : (
                            props?.result?.achievedROI > 0 && (
                              <> $ {props.result?.achievedROI} </>
                            )
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.gridExpectedROI}>
                      <Typography variant="subtitle2">
                        {props?.result?.isImplemented &&
                        props?.result?.achievedROI > 0 ? (
                          <Resource tag={"IdeaCard::AchievedROI"} />
                        ) : (
                          props?.result?.expectedROI > 0 && (
                            <Resource tag={"IdeaCard::ExpectedROI"} />
                          )
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <EvaluationBox
                    scale={0.8}
                    evaluated={evaluated}
                    type={props?.result?.evaluation?.evaluationType}
                    value={value === undefined ? 0 : value}
                    variation={variation}
                    graph={graph}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
          {props?.size.toUpperCase() === "BIG" ? (
            <>
              {rawImgSrc ? (
                  <Image
                    src={rawImgSrc ? rawImgSrc : ""}
                    style={{
                      maxHeight: "50%",
                      width: "100%",
                      borderRadius: "7px",
                      marginTop: "10px",
                      marginBottom: "10px"
                    }}
                    height={"400px"}
                    alt={""}
                  />
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
          {props?.result?.isChosenForImplementation &&
          props?.result?.isAllowedViewOwnerOrCoOwner ? (
            <Box mb={1} className="feed-isChosen-container-author-coauthor">
              <Grid item xs={12}>
                {props.result?.coAuthors?.length > 0 ? (
                  <Box className="author-e-coauthor" display="flex">
                    <Box className={classes.author}>
                      <React.Fragment>
                        <Box mr={1}>
                          <Resource tag={"PageDetailIdea::Idea_Author"} /> &{" "}
                        </Box>
                        <Resource tag={"PageDetailIdea::Idea_CoAuthor"} />:
                      </React.Fragment>
                    </Box>
                    <Box
                      ml={1}
                      display="flex"
                      style={{ gap: "5px" }}
                      className="avatar-group-author-more-coauthor"
                    >
                      <Avatar
                        className={classes.small}
                        alt={props.result?.author?.fullName}
                        src={props.result?.author?.photo}
                      />
                      <AvatarGroup max={4} className={classes.avatarGroup}>
                        {props.result?.coAuthors.map(
                          (coAuthor: any, index: any) => {
                            return (
                              <Avatar
                                className={classes.small}
                                alt={coAuthor?.fullName}
                                src={coAuthor?.photo}
                                key={index}
                              />
                            );
                          }
                        )}
                      </AvatarGroup>
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" className="author-avatar-and-fullname">
                    <Typography className={classes.author}>
                      <Resource tag={"PageDetailIdea::Idea_Author"} />:
                    </Typography>
                    <Box display="flex" alignItems="center" gridGap={5} ml={1}>
                      <Avatar
                        className={classes.small}
                        alt={props.result?.author?.fullName}
                        src={props.result?.author?.photo}
                      />
                      <Typography className={classes.authorName}>
                        {props.result?.author?.fullName}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Box>
          ) : (
            ""
          )}
          {props?.result?.isChosenForImplementation ? (
            <>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  className="btn-comment-ischosen"
                >
                  <Box className="btn-comment-ischosen">
                    {result?.isAllowedComment ? (
                      <Box className="btn-comment-white">
                        <Button
                          onClick={() => handleClickComments()}
                          size="small"
                          startIcon={
                            <EMIcon icon="comments-b" color="#FFF" size={16} />
                          }
                        >
                          <Box color="#FFF">
                            <Resource tag={"PageFeed::Comment"} />
                          </Box>
                        </Button>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  className="quant-comments-grid"
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    gridGap={20}
                    className="quant-comments-box"
                  >
                    {result?.countComments > 0 ? (
                      <Box
                        color="#FFF"
                        display="flex"
                        style={{ cursor: "pointer" }}
                        gridGap={5}
                        onClick={() => handleClickComments()}
                      >
                        <Typography variant="caption">
                          {result?.countComments}
                        </Typography>
                        <Typography className="lower-case" variant="caption">
                          {result?.countComments === 1 ? (
                            <Resource tag={"PageFeed::Comment"} />
                          ) : (
                            <Resource tag={"PageFeed::Comments"} />
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              container
              spacing={2}
              className={"buttons-qtdcomments-container"}
            >
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Box
                  display="flex"
                  className="container-btnType-and-btnComment"
                >
                  <Box mr={2} className="box-btn-type">
                    {props?.result?.evaluation?.evaluationType ===
                      "Investments" && (
                      <>
                        <Button
                          disabled={
                            !props?.result?.evaluation?.investment
                              ?.maximumInvestment
                          }
                          color="primary"
                          size="small"
                          onClick={() =>
                            props?.result?.evaluation?.investment
                              ?.investmentValue
                              ? setReinvestmenOn(true)
                              : setInvestmentOn(true)
                          }
                          startIcon={
                            <EMIcon
                              icon="investment"
                              color={
                                !props?.result?.evaluation?.investment
                                  ?.maximumInvestment
                                  ? theme.palette.grey[500]
                                  : theme.palette.primary.main
                              }
                              size={16}
                            />
                          }
                        >
                          {props?.result?.evaluation?.investment
                            ?.investmentValue === 0 ? (
                            <Resource tag={"PageFeed::Invest"} />
                          ) : (
                            <Resource tag={"PageIdea::ReinvestIdea"} />
                          )}
                        </Button>
                        <InvestmentPopUp
                          investment={investment}
                          points={
                            investment?.maximumInvestment
                              ? investment?.maximumInvestment
                              : 0
                          }
                          isInvestmentOn={isInvestmentOn}
                          setInvestmentOn={setInvestmentOn}
                          title={props?.result?.title}
                          postInvestments={doInvestment}
                          culture={props?.culture}
                        />
                        <ReviewReinvestmentPopup
                          investment={investment}
                          points={
                            investment?.maximumInvestment
                              ? investment?.maximumInvestment
                              : 0
                          }
                          isReinvestmentOn={isReinvestmentOn}
                          setReinvestmentOn={setReinvestmenOn}
                          title={props?.result?.title}
                          postInvestments={doInvestment}
                          culture={props?.culture}
                        />
                      </>
                    )}
                    {props?.result?.evaluation?.evaluationType === "Likes" && (
                      <Button
                        color="primary"
                        startIcon={
                          <EMIcon
                            icon="like"
                            color={
                              evaluated
                                ? "white"
                                : !props?.result?.evaluation?.likesEvaluation
                                    ?.isAllowedEvaluate
                                ? "#ccc"
                                : theme.palette.primary.main
                            }
                          />
                        }
                        size="small"
                        variant={evaluated ? "contained" : "text"}
                        onClick={() =>
                          !evaluated
                            ? postLikes(likeOptions).then((x) =>
                                setEvaluated(true)
                              )
                            : deleteLikes(likeOptions).then((x) =>
                                setEvaluated(false)
                              )
                        }
                        disabled={
                          !props?.result?.evaluation?.likesEvaluation
                            ?.isAllowedEvaluate
                        }
                      >
                        {evaluated ? (
                          <Resource tag="PageIdea::LikedIdea" />
                        ) : (
                          <Resource tag="PageIdea::LikeIdea" />
                        )}
                      </Button>
                    )}

                    {props?.result?.evaluation?.evaluationType === "Stars" || props?.result?.evaluation?.evaluationType === "Average" ? (
                      <>
                        <Button
                          variant={evaluated ? "contained" : "text"}
                          color="primary"
                          startIcon={
                            <EMIcon
                              icon="star"
                              color={
                                evaluated
                                  ? "white"
                                  : !props?.result?.evaluation?.starsEvaluation
                                      ?.isAllowedEvaluate
                                  ? "#ccc"
                                  : theme.palette.primary.main
                              }
                            />
                          }
                          size="small"
                          onClick={() => setIsReviewOn(true)}
                          disabled={
                            !props?.result?.evaluation?.starsEvaluation
                              ?.isAllowedEvaluate
                          }
                        >
                          <Resource tag={"Common::Rate"} />
                        </Button>
                        <Dialog
                          open={isReviewOn}
                          fullWidth
                          maxWidth={"xs"}
                          fullScreen={fullScreen}
                        >
                          <DialogTitle
                            disableTypography
                            className={classes.popupTitle}
                          >
                            <Typography variant="h4">
                              <Resource tag={"Common::Rate"} />
                            </Typography>
                            <IconButton
                              // aria-label="close"
                              className={classes.closeButton}
                              onClick={() => setIsReviewOn(false)}
                            >
                              <CloseIcon />
                            </IconButton>
                            <Divider className={classes.titleDivider}></Divider>
                          </DialogTitle>
                          <DialogContent style={{ padding: 0 }}>
                            <ReviewStars
                              setEvaluated={setEvaluated}
                              ideaId={props?.result?.ideaId}
                              getAllIdeas={props?.getAllIdeas}
                              starsEvaluation={props?.result?.evaluation}
                              isCollaboration={
                                props?.result?.evaluation?.isCollaboration
                              }
                              isFeed
                            />
                          </DialogContent>
                        </Dialog>
                      </>
                    ) : ''}
                  </Box>
                  {result?.isAllowedComment && (
                    <Button
                      onClick={() => handleClickComments()}
                      color="primary"
                      size="small"
                      startIcon={
                        <EMIcon
                          icon="comments-b"
                          color={theme.palette.primary.main}
                          size={16}
                        />
                      }
                    >
                      <Resource tag={"PageFeed::Comment"} />
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                className="comments-evaluations-grid"
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  gridGap={20}
                  className="comments-evaluations-box"
                >
                  {totalEvaluation > 0 ? (
                    <Box display="flex" gridGap={5}>
                      <Typography variant="caption">
                        {totalEvaluation}
                      </Typography>
                      <Typography className="lower-case" variant="caption">
                        {totalEvaluation === 1 ? (
                          <Resource tag={"PageFeed::Evaluation"} />
                        ) : (
                          <Resource tag={"PageFeed::Evaluations"} />
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                  {result?.countComments > 0 ? (
                    <Box display="flex" gridGap={5}>
                      <Typography variant="caption">
                        {result?.countComments}
                      </Typography>
                      <Typography className="lower-case" variant="caption">
                        {result?.countComments === 1 ? (
                          <Resource tag={"PageFeed::Comment"} />
                        ) : (
                          <Resource tag={"PageFeed::Comments"} />
                        )}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </CardContent>
        <>
          {open ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "8px" }}
            >
              <Box width="100%" padding="0 20px 20px">
                <Divider className={classes.divider} />
                <Box marginTop={1}>
                  <CommentList
                    isAuthor={isAuthor}
                    isAllowedComment={result?.isAllowedComment}
                    ideaId={result?.ideaId}
                    initialPageSize={3}
                    isCard
                  />
                </Box>
              </Box>
            </Grid>
          ) : (
            <Box />
          )}
        </>
      </Card>
    </ClickAwayListener>
  );
}
