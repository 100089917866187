import React, { useState } from "react";
import {
  Checkbox,
  CircularProgress,
  createStyles,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Box, Button } from "@material-ui/core";
import UserListItem from "../../../../common/UI/List/User/UserListItem";
import Resource from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IManageUsers } from "redux/types/manageUsers";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import imagemNotUsers from "assets/img/empty@3x.png";

interface IProps {
  culture: ICultureState;
  users: IManageUsers;
  postUserActivate: any;
  activateStatus: string;
  workspaceId: string;
  userStatus: string;
  handleUpdate:any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& label": {
        marginBottom: "15px",
        marginTop: "15px",
        marginRight: "8px",
        "& span:last-child": {
          width: "100%",
        },
      },
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    noDataPrizes: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 2.0,
    },
  })
);
export default function ManageDeactivatedUsersList(props: IProps) {
  const {
    culture,
    users,
    postUserActivate,
    activateStatus,
    workspaceId,
    userStatus,
    handleUpdate
  } = props;
  const classes = useStyles();
  const [state, setState] = React.useState<any>([]);
  const [openActivateUser, setOpenActivateUser] = useState(false);
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FormGroup className={classes.root}>
      {!users || users?.result?.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={2}
          className="no-prizes"
        >
          <Typography color="textSecondary" variant="caption"><Resource tag="ManageUsers::Error_NoUsersDisabled" /></Typography>
          <img src={imagemNotUsers} alt="" />
        </Box>
      ) : (
        <Box display="flex" alignItems="center" width="100%" mb={1}>
          <FormControlLabel
            style={{
              marginBottom: "0px",
              marginTop: "0px",
            }}
            control={
              <Checkbox
                checked={state?.length === users?.result?.length}
                onChange={() =>
                  state?.length !== users?.result?.length
                    ? setState(users?.result)
                    : setState([])
                }
                name="checkedAll"
                color="primary"
              />
            }
            label=""
          />
          <Grid
            container
            direction={matchSm ? "column-reverse" : "row"}
            spacing={2}
          >
            <Grid
              justify="flex-start"
              container
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              style={{ gap: "20px" }}
            >
              {activateStatus === "LOADING" ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setOpenActivateUser(true);
                  }}
                  onFocus={(e: any) => e.stopPropagation()}
                  // aria-label="Buttons"
                  variant="text"
                  color="primary"
                  disabled={state?.length === 0}
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  <Resource tag="PageManageUsers::Reactivate" />
                </Button>
              )}
              <PopUpConfirmation
                message={<Resource tag="PageManageUsers::ActivateUser" />}
                setIsOpen={setOpenActivateUser}
                isOpen={openActivateUser}
                closeButton={() => setOpenActivateUser(false)}
                sendButton={() =>
                  postUserActivate({
                    usersId:
                      state?.length > 0
                        ? state?.map((data: any) => data.userId)
                        : [],
                    workspaceId,
                  }).then(()=>handleUpdate())
                }
              />
              {/* <Button
                onClick={(e: any) => e.stopPropagation()}
                onFocus={(e: any) => e.stopPropagation()}
                // aria-label="Buttons"
                variant="text"
                color="primary"
                disabled={state?.length === 0}
                style={{
                  textTransform: "uppercase",
                }}
              >
                <Resource tag="PageManageUsers::Delete" />
              </Button> */}
            </Grid>
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              wrap="nowrap"
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
            >
              <Typography
                style={{ paddingRight: "15px" }}
                variant="body2"
                color="textSecondary"
              >
                {users?.meta?.total} <Resource tag="PageSettings::users" />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {userStatus === "LOADING" ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={5}>
          <CircularProgress />
        </Box>
      ) : (
        users &&
        users?.result?.map((data, index) => {
          return (
            <FormControlLabel
              key={data.userId}
              control={
                <Checkbox
                  checked={state
                    .map((x: any) => x.userId)
                    .includes(data?.userId)}
                  onChange={() =>
                    state.map((x: any) => x.userId).includes(data?.userId)
                      ? setState(
                          state.filter((y: any) => y.userId !== data?.userId)
                        )
                      : setState([...state, data])
                  }
                  name="checkedA"
                  color="primary"
                />
              }
              label={
                <UserListItem
                  userId={data?.userId}
                  culture={culture}
                  checked={state
                    .map((x: any) => x.userId)
                    .includes(data?.userId)}
                  alignItems
                  principalText={data?.fullName}
                  subtitle={data?.email}
                  photo={data?.photo}
                  finalContent={
                    <Typography variant="body2" noWrap>
                      <Resource
                        tag="PageManageUsers::DeactivatedOnX"
                        args={[data?.deactivateDate]}
                      />
                    </Typography>
                  }
                />
              }
            />
          );
        })
      )}
    </FormGroup>
  );
}
