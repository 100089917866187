import React from 'react';
import './CollaborativeIdeaList.css'
import Resource from '../../components/Resources/Resource';
import { Grid, Button, Box, Typography } from '@material-ui/core';
import AleatoryIdeaRows from '../../components/AleatoryIdeaRows/AleatoryIdeaRows';
import IdeaRows from '../../components/IdeaRows/IdeaRows';
import history from '../../routes/history'
import { ICultureState } from 'redux/reducers/cultureReducer';
import { IAllIdeasState } from 'redux/reducers/ideas/AllIdeasReducer';
import SkeletonRandomCards from 'common/UI/Skeleton/SkeletonRandomCards';

interface IProps {
  loading?: boolean,
  ideasInCollaboration: any;
  aleatory:boolean;
  postInvestments:(options:object)=> Promise<any>;
  applicationId:string;
  culture:ICultureState;
  postLikes:(options:object) => Promise<any>,
  deleteLikes:(options:object) => Promise<any>,
  getAllIdeas: (options: object) => Promise<IAllIdeasState>,
  challengeId?: string
  q?: string
}

function CollaborativeIdeaList (props:IProps) {
  const { 
    loading, 
    ideasInCollaboration, 
    aleatory, 
    postInvestments, 
    applicationId, 
    culture, 
    postLikes, 
    deleteLikes, 
    getAllIdeas,
    challengeId,
    q,
  } = props

  function handleViewMore(){
    history.push(`/idea/collaboration`, {
      challengeId,
      q
    })
  }
    return (
      <>
        <Grid container>
          <Box mt={4} mb={2}>
            <Typography variant="h3">
              <Resource tag={"PageIdea::Idea_TitleCollaborativeEvaluation"} />
            </Typography>
          </Box>
        </Grid>
        
        <Grid container spacing={3} className={"collaborativeIdeaList"} style={{width: "calc(100% + 50px)" }}>
          {aleatory && 
            <>
              {loading ?
              <SkeletonRandomCards />
              :
              <AleatoryIdeaRows 
                ideaList={ideasInCollaboration}
                postInvestments={postInvestments}
                applicationId={applicationId}
                culture={culture}
                postLikes={postLikes}
                deleteLikes={deleteLikes}
                getAllIdeas={getAllIdeas}
              /> 
              }

              {ideasInCollaboration?.meta?.total > ideasInCollaboration?.result?.length ?
                <Grid item xs={12} >
                  <Box flexDirection="row-reverse" style={{width:"97%"}} display="flex">
                    <Box marginTop={2}>
                      <Button variant="text" style={{textTransform: 'uppercase'}} color="primary" onClick={handleViewMore}>
                        <Resource tag={"Common::ViewAll"}/>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              :
                ''
              }
            </>    
          }
          {!aleatory && <IdeaRows getAllIdeas={getAllIdeas} culture={culture} postLikes={postLikes} deleteLikes={deleteLikes} applicationId={applicationId} postInvestments={postInvestments} ideaList={ideasInCollaboration}/> }
        </Grid>
      </>
    )
}


export default CollaborativeIdeaList;