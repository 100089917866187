import React from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../ButtonIconTypes/cfTypes";
import { makeStyles, Theme, createStyles, Button } from "@material-ui/core";

interface IProps {
  name: any;
  data: any;
  disabled?: boolean;
  startIcon?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    draggableButton: {
      marginTop: 4,
      marginBottom: 4,
      width: "100%",
      backgroundColor: `${theme.palette.primary.light}`,
      color: `${theme.palette.primary.main}`,
      border: `2px dashed ${theme.palette.primary.main}`,
      justifyContent: ({ startIcon }: any) =>
        startIcon ? "flex-start" : "center",
      padding: "6px 16px 6px 12px",

      "&:hover": {
        backgroundColor: `${theme.palette.primary.light}`,
        color: `${theme.palette.primary.main}`,
        border: `2px dashed ${theme.palette.primary.main}`,
      },
    },
  })
);

function DraggableButton({ name, data, startIcon, disabled }: IProps) {
  const classes = useStyles({ startIcon });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.BUTTON,
    item: { name, type: ItemTypes.BUTTON, data },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.6 : 1;

  return (
    <Button
      startIcon={startIcon}
      aria-readonly
      disableRipple
      disableElevation
      disableFocusRipple
      disableTouchRipple
      variant="contained"
      ref={disabled ? null : drag}
      disabled={disabled}
      style={{ opacity }}
      className={classes.draggableButton}
    >
      {name}
    </Button>
  );
}

export default DraggableButton;
