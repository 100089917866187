import { AnyAction } from "redux";
import history from "routes/history";
import { connect } from "react-redux";
import Steps from "components/Steps/Steps";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import Resource from "components/Resources/Resource";
import StickyComponent from "components/Sticky/Sticky";
import { IManageGroups } from "redux/types/manageGroups";
import { ICultureState } from "redux/reducers/cultureReducer";
import React, { useCallback, useEffect, useState } from "react";
import CreatePrizeStepDetails from "./Steps/CreatePrizeStepDetails";
import CreatePrizeStepParticipants from "./Steps/CreatePrizeStepParticipants";
import { getManageGroups } from "redux/actions/manageGroups/manageGroupsAction";
import CreatePrizesStepConfirmation from "./Steps/CreatePrizesStepConfirmation";
import {
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  PageStateDetailPrize,
  PageStateParticipantsPrize,
  PageStatePrize,
} from "redux/types/createPrize";
import {
  postPrizeDetail,
  resetCreatePrize,
  updateDetailsPrize,
  updateParticipantsPrize,
} from "redux/actions/CreatePrize/CreatePrizeActions";

interface IDispatchProps {
  updateDetailsPrize: (newValue: any) => any;
  updateParticipantsPrize: (newValue: any) => any;
  getManageGroups: (options: object) => Promise<any>;
  postPrizeDetail: (options: object) => Promise<any>;
  resetPagePrize: () => any;
}

interface IProps {
  culture: ICultureState;
  groups: IManageGroups;
  allGroupsSelected: boolean,
  workspaceId: string;
  pageStatePrize: PageStatePrize;
}

function CreatePrizePage(props: IProps & IDispatchProps) {
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));
  const {
    culture,
    workspaceId,
    pageStatePrize,
    groups,
    getManageGroups,
    updateDetailsPrize,
    updateParticipantsPrize,
    postPrizeDetail,
    resetPagePrize,
  } = props;

  const [activeIndex, setActive] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [allGroupsSelected, setAllGroupsSelected ] = useState(false);

  useEffect(() => {
    resetPagePrize();
    Promise.all([
      getManageGroups({
        workspaceId,
        cultureTag: culture?.culture?.cultureTag,
      }),
    ]).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDetailsStatePrize = useCallback(
    (newState: PageStateDetailPrize) => {
      updateDetailsPrize(newState);
    },
    [updateDetailsPrize]
  );

  const setParticipantsStatePrize = useCallback(
    (newState: PageStateParticipantsPrize) => {
      updateParticipantsPrize(newState || { groupsId: [] });
    },
    [updateParticipantsPrize]
  );

  const buttonArr = [
    {
      title: <Resource tag="PageCreatePrize::Details" />,
      form: (
        <CreatePrizeStepDetails
          culture={culture}
          setSteps={setDetailsStatePrize}
          steps={pageStatePrize?.stepDetail}
        />
      ),
    },
    {
      title: <Resource tag="PageCreatePrize::Participants" />,
      form: (
        <CreatePrizeStepParticipants
          culture={culture}
          allGroups={groups}
          allGroupsSelected={allGroupsSelected}
          setAllGroupsSelected={setAllGroupsSelected}
          setSteps={setParticipantsStatePrize}
          steps={pageStatePrize?.stepParticipants}
        />
      ),
    },
    {
      title: <Resource tag="PageCreatePrize::Confirmation" />,
      form: (
        <CreatePrizesStepConfirmation
          culture={culture}
          setActive={setActive}
          steps={pageStatePrize}
          groups={groups}
          allGroupsSelected={allGroupsSelected}
        />
      ),
    },
  ];

  function postPrizes() {
    setLoadingSubmit(true)
    let params = {
      workspaceId: workspaceId,
      prizeType: pageStatePrize.stepDetail.prizeType,
      activationDate: pageStatePrize.stepDetail.activationDate,
      endDate: pageStatePrize.stepDetail.endDate,
      unit: Number(pageStatePrize.stepDetail.unit),
      maxUnitPerUser:
        pageStatePrize.stepDetail.prizeType === 1
          ? 0
          : Number(pageStatePrize.stepDetail.maxUnitPerUser),
      value: Number(pageStatePrize.stepDetail.value),
      image: pageStatePrize.stepDetail.image,
      cultures: [
        {
          description: pageStatePrize.stepDetail.description,
          cultureTag: culture.culture.cultureTag,
          title: pageStatePrize.stepDetail.title,
        },
      ],
      groupsId: pageStatePrize.stepParticipants.groupsId,
    };

    postPrizeDetail({ culture: culture, params }).then(() => setLoadingSubmit(false))
  }

  return !loading ? (
    <Box mt={4} className="prize-create-page">
      <StickyComponent offsetX={"0"}>
        <Box
          className="steps-and-buttons-container"
          display={matchXs ? "block" : "flex"}
          alignItems="center"
          justifyContent="space-between"
          style={{ backgroundColor: theme.palette.common.white }}
        >
          <Steps
            activeIndex={activeIndex}
            setActive={setActive}
            contentArr={buttonArr}
          />
          <Box
            className="btns-cancel-next"
            display="flex"
            justifyContent="flex-end"
            mt={matchXs ? "20px" : 0}
          >
            <Box mr={2}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => history?.goBack()}
              >
                <Resource tag="PageCreatePrize::Cancel" />
              </Button>
            </Box>
            {activeIndex !== 3 ? (
              <Button
                onClick={() => setActive(activeIndex + 1)}
                disabled={activeIndex >= buttonArr?.length}
                variant="contained"
                color="primary"
                size="small"
              >
                <Resource tag="PageCreatePrize::Next" />
              </Button>
            ) : (
              <Button
                onClick={() => postPrizes()}
                variant="contained"
                color="primary"
                size="small"
                disabled={
                  !pageStatePrize?.stepDetail?.activationDate ||
                  !pageStatePrize?.stepDetail?.endDate ||
                  !pageStatePrize?.stepDetail?.image ||
                  !pageStatePrize?.stepDetail?.title ||
                  !pageStatePrize?.stepDetail?.description ||
                  !pageStatePrize?.stepDetail?.value ||
                  (pageStatePrize?.stepParticipants?.groupsId?.length === 0 && !allGroupsSelected) ||
                  loadingSubmit
                }
                endIcon={
                  !loadingSubmit ? (
                    ''
                  ) : (
                    <CircularProgress size={22} className="icon" />
                  )
                }
              >
                <Resource tag="PageCreatePrize::SaveAndPublish" />
              </Button>
            )}
          </Box>
        </Box>
      </StickyComponent>
      {buttonArr?.map((data: any, index: any) => {
        return (
          <Box mt={4} key={index} className="steps-content">
            {index + 1 === activeIndex && <>{data?.form}</>}
          </Box>
        );
      })}
    </Box>
  ) : (
    <Box width="100%" textAlign="center" mt={3}>
      <CircularProgress />
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => ({
  culture: store.cultureState,
  groups: store.manageGroupsState.groups,
  workspaceId: store.authState.auth.workspaceId,
  pageStatePrize: store.createPrizeState.pageState,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  updateDetailsPrize: (newValue: any) => dispatch(updateDetailsPrize(newValue)),
  updateParticipantsPrize: (newValue: any) =>
    dispatch(updateParticipantsPrize(newValue)),
  getManageGroups: (options: object) => dispatch(getManageGroups(options)),
  postPrizeDetail: (options: object) => dispatch(postPrizeDetail(options)),
  resetPagePrize: () => dispatch(resetCreatePrize()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePrizePage);
