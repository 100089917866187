import React from "react";
import EMIcon from "components/Icon/Icon";
import reactNewApp from "utils/reactNewApp";
import Resource, { resource } from "../../Resources/Resource";
import CardActionArea from "@material-ui/core/CardActionArea";
import { ICultureState } from "redux/reducers/cultureReducer";
import {
  Box,
  Typography,
  makeStyles,
  createStyles,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      float: "left",
      marginRight: "10px",
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    },
    actionArea: {
      width: "100%",
      padding: "8px 8px 8px 20px",
    },
    actionAreaActive: {
      backgroundColor: "#e2e2e2",
    },
    typographyTrunc: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": "3",
      "-webkit-box-orient": "vertical",
    },
  })
);

/*Example 

    <AlertListItem  alertId={alert.alertId} isNew={alert.isNew} isVisualized={alert.isVisualized}
        message={alert.message} timeStamp={alert.timeStamp} 
        timeStampSeparatorResource={alert.timeStampSeparatorResource} 
        timeStampResource={alert.timeStampResource} 
        type={alert.type} objectId={alert.objectId} objectIdHighlight={alert.objectIdHighlight} 
    />

*/

interface IProps {
  culture: ICultureState;
  alertId: string;
  isVisualized: boolean;
  message: string;
  timeStamp: string;
  timeStampSeparatorResource: string;
  timeStampResource: string;
  type: number;
  object: string;
  objectId: string;
  objectIdHighlight: string;
  isNew: boolean;
  readNotification: (
    alertId: string,
    type: string,
    object: string,
    objectId: string,
    objectIdHighlight: string
  ) => void;
}

//getAlertIcon returns icon of notification
function getAlertIcon(object: string) {
  switch (object.toUpperCase()) {
    case "COMMENTSIDEA":
      return "comments";

    case "KEYTASK":
      return "tasks";

    case "CHALLENGE":
      return "challenges";

    case "IDEA":
      return "section-ideas";

    case "PRIZES":
      return "section-prizes";

    default:
      return "section-ideas";
  }
}

function AlertListItem(props: IProps) {
  const theme = useTheme();
  const {
    culture,
    alertId,
    message,
    objectIdHighlight,
    isNew,
    timeStamp,
    timeStampResource,
    timeStampSeparatorResource,
    isVisualized,
    readNotification,
    object,
    objectId,
  } = props;
  const timeResource = timeStampResource?.replace(".", "::");

  let background;
  isVisualized
    ? (background = "")
    : (background = "rgba(221, 221, 221, 0.322)");
  const classes = useStyles();

  let newResource = timeStampSeparatorResource.replace(".", "::");
  let textResource =
    timeStampSeparatorResource !== "" &&
    resource(culture?.culture, newResource);
  let textDate = timeStamp.replace("{0}", `${textResource}`);

  if (alertId !== "") {
    return (
      <CardActionArea
        className={
          classes.actionArea + " " + (isNew ? classes.actionAreaActive : "")
        }
        style={{ backgroundColor: background }}
        onClick={() =>
          readNotification(alertId, "", object, objectId, objectIdHighlight)
        }
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.icon}
          style={{
            background: reactNewApp
              ? `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.dark})`
              : theme.palette.primary.main,
          }}
        >
          <EMIcon size={14} color="#fff" icon={getAlertIcon(object)}></EMIcon>
        </Box>
        <Box
          className="alert-text"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          textAlign="left"
        >
          <Box width="100%">
            <Typography
              variant="caption"
              color="textPrimary"
              className={classes.typographyTrunc}
            >
              {message}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" color="textSecondary">
              {timeStampResource !== "" ? (
                <Resource tag={timeResource} args={[timeStamp]} />
              ) : (
                textDate
              )}
            </Typography>{" "}
          </Box>
        </Box>
      </CardActionArea>
    );
  } else {
    return <></>;
  }
}

export default AlertListItem;
