import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { ICultureState } from "redux/reducers/cultureReducer";
import React, { useState, useLayoutEffect } from "react";
import { getIdeasImplemented } from 'redux/actions/ideas/IdeaAction';
import { IIdeasImplemented } from "redux/types/ideas";
import { Box, Grid, Typography } from "@material-ui/core";
import Resource from "components/Resources/Resource";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";
import CardIdeaPortfolio from "components/CardIdeaPortfolio/CardIdeaPortfolio";

interface IDispatchProps {
  getIdeasImplemented: (options: object) => Promise<IIdeasImplemented>
}

interface IStateProps {
  ideasImplemented: IIdeasImplemented;
  applicationId: string;
  culture: ICultureState;
  challengeId: string;
  q: string;
}

function IdeasPortfolio(props: IDispatchProps & IStateProps) {
  const {
    ideasImplemented,
    getIdeasImplemented,
    challengeId,
    q,
  } = props;
  const [pageNumber] = useState(1);
  const [pageSize] = useState(6);

  useLayoutEffect(() => {
    getIdeasImplemented({
      challengeId,
      q,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function showMore(typeTabs?: string, pageNumber?: number, pageSize?: number, isInfinite?: boolean) {
    if(typeTabs === 'portfolio') {
      getIdeasImplemented({
        challengeId,
        q,
        pageNumber,
        pageSize,
        isInfinite
      });
    }
  }

  const titlePortfolio = <Resource tag="PageIdea::Idea_TitlePortfolio" />;

  return (
    <Box mb={7} className="ideas-page-container">
      <div className="Portfolio-carousel-container">
        <Grid container>
          <Box mt={4} mb={2}>
            <Typography variant="h3">
              {titlePortfolio}
            </Typography>
          </Box>
        </Grid>
        <DynamicInfiniteScroll 
          dataList={ideasImplemented} 
          getAction={(pageNumber,pageSize) => showMore('portfolio', pageNumber, pageSize, true)}
          pageSize={6}
          isAutomatic
        >
          <Grid container spacing={2}>
            {ideasImplemented &&
              ideasImplemented?.result?.map((idea: any, index: number) => (
                <Grid item xs={12} sm={12} md={6} key={idea?.ideaId}>
                  <CardIdeaPortfolio idea={idea} />
                </Grid>
              ))}
          </Grid>
        </DynamicInfiniteScroll>
      </div>
    </Box>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getIdeasImplemented: (parameters: object) => dispatch(getIdeasImplemented(parameters))
  };
};

const mapStateToProps = (store: IAppState, ownProps: any) => {
  return {
    q: ownProps?.location?.state?.q,
    challengeId: ownProps?.location?.state?.challengeId,
    ideasImplemented: store.ideasState.ideasImplemented,
    culture: store.cultureState,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdeasPortfolio);
