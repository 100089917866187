import React from "react";
import "./NumberInput.css";
import reactNewApp from "utils/reactNewApp";
import ToolTip from "../../ToolTip/ToolTip";
import {
  makeStyles,
  createStyles,
  TextField,
  Theme,
  Box,
} from "@material-ui/core";

interface IProps {
  value: any;
  handler: any;
  propsI: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      padding: "15px 0 7px",
    },
    label: {
      fontWeight: "bold",
      fontSize: "1.05rem",
      textTransform: "uppercase",
      color: theme.palette.text.secondary,
    },
  })
);

function NumberInput(props: IProps) {
  const classes = useStyles();
  let { propsI, value, handler } = props;

  const labelT = (
    <Box className="textField-and-tooltip" display="flex" alignItems="center">
      <Box mr={1}>
        {propsI?.title || propsI.cultures?.[0]?.title}
        {propsI?.isMandatory || propsI?.isRequired ? " *" : ""}
      </Box>
      {propsI?.help || propsI?.description ? (
        <ToolTip title="Help">{propsI?.help || propsI?.description}</ToolTip>
      ) : (
        ""
      )}
    </Box>
  );

  const textValueChange = (e: any) => {    
    handler(e.target.value);
  };

  return (
    <TextField
      type="number"
      fullWidth
      label={labelT}
      placeholder={propsI?.placeholder}
      name={propsI?.columnName || propsI?.ideaCustomFieldId}
      InputProps={{ readOnly: !reactNewApp && !propsI?.isEditable }}
      onChange={textValueChange}
      onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
      defaultValue={
        value
          ? value
          : !reactNewApp && propsI?.defaultValue
          ? propsI?.defaultValue
          : ""
      }
      inputProps={{
        pattern: !reactNewApp ? propsI?.validationExpression : "",
        className: classes.input,
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.label,
      }}
      {...(!reactNewApp && propsI?.minValue != null
        ? { min: propsI?.minValue }
        : {})}
      {...(!reactNewApp && propsI?.maxValue != null
        ? { max: propsI?.maxValue }
        : {})}
    />
  );
}

export default NumberInput;
