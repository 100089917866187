import { AnyAction } from "redux";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { ShortDateFormat } from "utils/FormatDate";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import ErrorIcon from "@material-ui/icons/Error";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import { securityTextEditor } from "utils/securityTextEditor";
import ExistingDraft from "components/Dialogs/ExistingDraft";
import DialogVersion from "components/Dialogs/DialogVersion";
import { IProfileData } from "redux/reducers/profileReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import SaveOrPublishDialog from "components/Dialogs/SaveOrPublishDialog";
import TextEditorInput from "components/GenericField/TextEditorInput/TextEditorInput";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Link,
  makeStyles,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  IRegulationsHistory,
  IRegulationsPublish,
  IRegulationsState,
} from "redux/types/regulations";
import {
  getProfileData,
  IProfileGetDataAction,
} from "redux/actions/ProfileActions";
import {
  getRegulationsHistory,
  getRegulationsPublish,
  postRegulations,
  putRegulations,
} from "redux/actions/regulations/RegulationsAction";
import { resource } from "components/Resources/Resource";
import emptyImage from "../../../assets/img/empty@3x.png";


interface IProps {
  cultureState: ICultureState;
  profileData: IProfileData;
  workspaceId: string;
  regulationsPublish: IRegulationsPublish;
  regulationsHistory: IRegulationsHistory[];
  regulationsStatus: string;
  regulationsPostStatus: string;
  regulationsPutStatus: string;
}

interface IDispatchProps {
  getRegulationsPublish: (params: object) => Promise<IRegulationsState>;
  getRegulationsHistory: (params: object) => Promise<IRegulationsState>;
  postRegulations: (params: object) => Promise<IRegulationsState>;
  putRegulations: (params: object) => Promise<IRegulationsState>;
  getProfileData: () => Promise<IProfileGetDataAction>;
}

const useStylesTooltip = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    maxWidth: 300,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: "100%",
    },
    scroll: {
      paddingRight: 12,
      position: "relative",
      overflowY: "auto",
      height: 400,
      "&::-webkit-scrollbar": {
        width: "0.3em",
        border: 0,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: 0,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c3c3c3",
        outline: 0,
        borderRadius: 12,
        border: 0,
      },
    },
    publishedVsBox: {
      marginTop: "30px",
      position: "absolute",
    },
    versionItem: {
      display: "flex",
      alignItems: "center",
      marginBottom: "16px",
      gap: "14px",
    },
    defaultChangeRegulation: {
      cursor: "pointer",
      textDecoration: "underline",
      color: theme.palette.primary.main,
    },
    dividerPublished: {
      height: 18,
    },
    currentVersion: {
      background: theme.palette.common.black,
      color: theme.palette.common.white,
      width: "fit-content",
      padding: "6px 8px",
      fontSize: "0.7rem",
      borderRadius: 8,
      marginLeft: "16px",
    },
    boxFade: {
      zIndex: 1,
      bottom: 0,
      width: "100%",
      height: "90px",
      position: "absolute",
      background:
        "linear-gradient(180deg, rgba(255,255,255,0.6222864145658263) 69%, rgba(255,255,255,1) 84%)",
    },
    noData: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 2.0,
    },
  })
);

function Regulations(props: IProps & IDispatchProps) {
  const {
    cultureState,
    profileData,
    workspaceId,
    regulationsPublish,
    regulationsHistory,
    regulationsStatus,
    regulationsPostStatus,
    regulationsPutStatus,
    postRegulations,
    putRegulations,
    getRegulationsHistory,
    getRegulationsPublish,

  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matchXsSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [activeIndex, setActiveIndex] = useState(
    regulationsHistory?.length - 1
  );

  const textNoData = resource(
    cultureState?.culture,
    "PageRegulation::NoRegulationsAvailable"
  )?.split("<br></br>");

  const [title, setTitle] = useState(
    regulationsHistory &&
      regulationsHistory?.find((data) => data?.isDraft)?.isDraft
      ? regulationsHistory?.find((data) => data?.isDraft)?.title
      : regulationsPublish?.title
  );
  const [description, setDescription] = useState(
    regulationsHistory &&
      regulationsHistory?.find((data) => data?.isDraft)?.isDraft
      ? regulationsHistory?.find((data) => data?.isDraft)?.description
      : regulationsPublish?.description
  );

  const getDraftTitle = (index: number) => {
    setTitle(regulationsHistory[index]?.title ?? "");
  };
  const getDraftDescription = (index: number) => {
    setDescription(regulationsHistory[index]?.description ?? "");
  };

  const [existingDraft, setExistingDraft] = useState(false);
  const [saveDraft, setSaveDraft] = useState(false);
  const [publish, setPublish] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isPublishThisV, setIsPublishThisV] = useState(false);
  const [dialogVersion, setDialogVersion] = useState(false);
  const [loading, setLoading] = useState(false);

  // container published versions
  const publishedVersionsContainer = (
    index: number,
    title: string,
    version: string,
    publishDate: string,
    currentVersion: boolean,
    isDraft?: boolean
  ) => {
    return (
      <Box key={index} className={classes.versionItem}>
        {isDraft ? (
          <Box color={theme.palette.primary.main}>{title}</Box>
        ) : (
          <Box
            className={classes.defaultChangeRegulation}
            onClick={() => {
              setDialogVersion(true);
              getDraftTitle(index);
              getDraftDescription(index);
              setActiveIndex(index);
            }}
          >
            {title} {version}
          </Box>
        )}
        <Divider orientation="vertical" className={classes.dividerPublished} />
        {publishDate && (
          <Typography variant="caption" color="textSecondary">
            <Resource tag="PageRegulation::publishedOn" />
          </Typography>
        )}
        <Typography variant="caption" color="textSecondary">
          {publishDate && <ShortDateFormat date={publishDate} />}
          {isDraft && (
            <Box
              className={classes.defaultChangeRegulation}
              onClick={() => {
                setIsEdit(true);
                setIsPublishThisV(false);
                getDraftTitle(index);
                getDraftDescription(index);
                setActiveIndex(index);
              }}
            >
              <Resource tag="PageRegulation::ContinueDraft" />
            </Box>
          )}
        </Typography>
        {currentVersion && (
          <Box className={classes.currentVersion}>
            <Resource tag="PageRegulation::CurrentVersion" />
          </Box>
        )}
      </Box>
    );
  };

  //tooltip
  function TooltipStylezed(props: TooltipProps) {
    const classes = useStylesTooltip();
    return (
      <Tooltip
        arrow
        placement="right"
        interactive
        classes={classes}
        {...props}
      />
    );
  }

  //Rich text to description
  const regulationDescriptionProps = {
    columnName: "",
    isMandatory: false,
    typeId: 9,
    title: "",
    placeholder: "",
    isEditable: true,
  };

  useEffect(() => {
    getRegulationsHistory({
      culture: cultureState,
      workspaceId: workspaceId,
    });
    getRegulationsPublish({
      culture: cultureState,
      workspaceId: workspaceId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePostRegulations = async (publish: boolean) => {
    const options = {
      title: sanitizeHtml(title ?? "", securityTextEditor),
      description: sanitizeHtml(description ?? "", securityTextEditor),
      workspaceId: workspaceId,
      publish,
      culture: cultureState.culture
    };
    postRegulations(options).then(() => {
      setIsEdit(false);
      setPublish(false);
      setSaveDraft(false);
      setIsPublishThisV(false);
      setActiveIndex(activeIndex + 1);
      setLoading(false)
    });
  };

  const handlePutRegulations = () => {
    const options = {
      title: sanitizeHtml(title ?? "", securityTextEditor),
      description: sanitizeHtml(description ?? "", securityTextEditor),
      workspaceId: workspaceId,
      culture: cultureState.culture
    };
    putRegulations(options).then(() => {
      setIsEdit(false);
      setSaveDraft(false);
      setIsPublishThisV(true);
      setLoading(false);
    });
  };

  return (
    <>
      {regulationsStatus === "LOADING" ? (
        <Box
          display="flex"
          height="60vh"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={60} />
        </Box>
      ) : (
        <Box className="regulation-container" style={{ position: "relative" }}>
          <Box className="header-and-description-container">
            <Box
              className="regulation-header"
              mb={6}
              display={matchXsSm ? "grid" : "flex"}
              alignItems="center"
              justifyContent="space-between"
            >

              <Box fontSize="1.5rem" fontWeight="bold" className="page-title">
                <Resource tag="PageRegulation::Regulation" />
              </Box>
              <Box
                className="thisversion-edit"
                display="flex"
                alignItems="center"
                mt={matchXsSm ? 2 : 0}
              >

                {isPublishThisV && (

                  <Box mr={2}>
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={() => setPublish(true)}
                    >
                      <Resource tag="PageRegulation::PublishVersion" />
                    </Button>
                  </Box>
                )}
                {!isEdit || isPublishThisV ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      !isPublishThisV &&
                        regulationsHistory &&
                        regulationsHistory?.find((data) => data.isDraft)?.isDraft
                        ? setExistingDraft(true)
                        : setIsEdit(true);
                      setIsPublishThisV(false);
                    }}
                  >
                    <Resource tag="PageRegulation::Edit" />
                  </Button>
                ) : (
                  ""
                )}
                {isPublishThisV && (
                  <Box ml={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setIsEdit(false);
                        setIsPublishThisV(false);
                      }}
                    >
                      <Resource tag="PageRegulation::Regulation" />
                    </Button>
                  </Box>
                )}
              </Box>
              {isEdit && !isPublishThisV && (
                <Box
                  className="editRegulation-while-buttons"
                  display="flex"
                  alignItems="center"
                  mt={matchXsSm ? 2 : 0}
                >
                  <Box mr={2}>
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={() => setPublish(true)}
                      disabled={description === ""}
                    >
                      {regulationsPostStatus === "LOADING" ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Resource tag="PageRegulation::SaveAndPublish" />
                      )}
                    </Button>
                  </Box>
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={description === ""}
                      onClick={() => {
                        setIsPublishThisV(true);
                        // setIsEdit(false);
                        setSaveDraft(true);
                      }}
                    >
                      {regulationsPostStatus === "LOADING" ||
                        regulationsPutStatus === "LOADING" ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Resource tag="PageRegulation::Save" />
                      )}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setIsEdit(false);
                        setActiveIndex(regulationsHistory?.length - 1);
                      }}
                    >
                      <Resource tag="PageRegulation::Cancel" />
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <Box>
              {
                (regulationsHistory.length === 0 && !isEdit)
                  ?
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={2}
                    className="no-prizes"
                  >
                    <Typography variant="h4" className={classes.noData}>
                      {textNoData[0]}
                    </Typography>
                    <Typography variant="h4" className={classes.noData}>
                      {textNoData[1]}
                    </Typography>
                    <img src={emptyImage} alt="" />
                  </Box>
                  :
                  <Box className="regulation-description" mb={"50px"}>
                    {isEdit ? (
                      <>
                        <Box
                          className="regulation-text-tooltip"
                          display="flex"
                          alignItems="center"
                        >
                          <Box
                            className="text"
                            fontSize="0.75rem"
                            fontWeight="bold"
                            color={theme.palette.text.secondary}
                          >
                            <Resource tag="PageRegulation::RegulationText" />
                          </Box>
                          <Box ml={1} className="tooltip">
                            <TooltipStylezed
                              title={
                                <>
                                  <Box component="span" mr={1}>
                                    <Resource tag="PageRegulation::RegulationText_Tip" />
                                    <Link
                                      color="primary"
                                      href={profileData?.urlTermsOfService}
                                      target="_blank"
                                    >
                                      <Resource tag="PageRegulation::TermsOfService" />
                                    </Link>
                                  </Box>
                                </>
                              }
                            >
                              <ErrorIcon color="error" fontSize="small" />
                            </TooltipStylezed>
                          </Box>
                        </Box>
                        <Box mt={2}>
                          <TextEditorInput
                            culture={cultureState}
                            propsI={regulationDescriptionProps}
                            maxCharCountShow={false}
                            charCounterLabelShow={false}
                            height={400}
                            value={description}
                            onChange={(value: any) => setDescription(value)}
                          />
                        </Box>
                      </>
                    ) : !isEdit || isPublishThisV ? (
                      <Box
                        className={classes.scroll}
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            `${regulationsHistory && isPublishThisV
                              ? regulationsHistory?.find((data) => data.isDraft)
                                ?.isDraft
                                ? regulationsHistory?.find(
                                  (data) => data?.isDraft
                                )?.description
                                : ""
                              : regulationsPublish && !regulationsPublish?.isDraft
                                ? regulationsPublish?.description
                                : ""
                            }`,
                            securityTextEditor
                          ),
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
              }
              {!isEdit && <Divider className={classes.divider} />}

            </Box>
          </Box>
          {
            regulationsHistory.length !== 0
              ?

              <Box className={`published-versions-container ` + classes.publishedVsBox}
              >
                <Box fontWeight="bold" fontSize="1.2rem" mb={2}>
                  <Resource tag="PageRegulation::PublishedVersions" />
                </Box>

                {regulationsHistory &&
                  regulationsHistory?.map((history, index) => {
                    if (history?.isDraft) {
                      //return drafts
                      return publishedVersionsContainer(
                        index,
                        history?.title,
                        history?.version,
                        history?.publishDate,
                        false,
                        true
                      );
                    }
                    if (
                      regulationsHistory &&
                      regulationsHistory?.find(
                        (x) => x.version === regulationsPublish?.version
                      )?.version === history?.version
                    ) {
                      //return current version
                      return publishedVersionsContainer(
                        index,
                        history?.title,
                        history?.version,
                        history?.publishDate,
                        true,
                        false
                      );
                    } else {
                      //return all other versions
                      return publishedVersionsContainer(
                        index,
                        history?.title,
                        history?.version,
                        history?.publishDate,
                        false,
                        false
                      );
                    }
                  })}
              </Box>

              : ""
          }
        </Box>
      )}

      {/* publish dialog */}
      <SaveOrPublishDialog
        open={publish}
        setOpen={setPublish}
        justSave={false}
        content={<Resource tag="PageRegulation::Confirm" />}
        title={title ?? ""}
        setTitle={setTitle}
        loading={loading}
        noCancelButton={() => setPublish(false)}
        yesButton={() => {
          handlePostRegulations(true); setLoading(true)
        }}
      />
      {/* save draft dialog */}
      <SaveOrPublishDialog
        open={saveDraft}
        setOpen={setSaveDraft}
        justSave={true}
        content={<Resource tag="PageRegulation::SaveAsADraft" />}
        title={title ?? ""}
        setTitle={setTitle}
        loading={loading}
        noCancelButton={() => {
          setSaveDraft(false);
          setIsEdit(true);
          setIsPublishThisV(false);
        }}
        yesButton={() => {
          if (
            regulationsHistory?.length === 0 ||
            !regulationsHistory?.find((data) => data?.isDraft)?.isDraft
          ) {
            handlePostRegulations(false);
            setIsPublishThisV(true);
            setLoading(true);
          } else {
            handlePutRegulations();
            setLoading(true);
          }
        }}
      />

      <ExistingDraft
        open={existingDraft}
        setOpen={setExistingDraft}
        content={<Resource tag="PageRegulation::ReplaceDraft" />}
        noCancelButton={() => {
          setExistingDraft(false);
        }}
        yesButton={() => {
          setIsEdit(true);
          setExistingDraft(false);
          setTitle(regulationsPublish?.title);
          setDescription(regulationsPublish?.description);
        }}
      />
      <DialogVersion
        open={dialogVersion}
        setOpen={setDialogVersion}
        title={regulationsHistory?.[activeIndex]?.title}
        content={regulationsHistory?.[activeIndex]?.description}
      />
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    cultureState: store.cultureState,
    profileData: store.profileState.profileData,
    workspaceId: store.authState.auth.workspaceId,
    regulationsPublish: store.regulationsState.regulationsPublish,
    regulationsHistory: store.regulationsState.regulationsHistory,
    regulationsStatus: store.regulationsState.regulationsStatus,
    regulationsPostStatus: store.regulationsState.regulationsPostStatus,
    regulationsPutStatus: store.regulationsState.regulationsPutStatus,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getRegulationsPublish: (params: object) =>
      dispatch(getRegulationsPublish(params)),
    getRegulationsHistory: (params: object) =>
      dispatch(getRegulationsHistory(params)),
    postRegulations: (params: object) => dispatch(postRegulations(params)),
    putRegulations: (params: object) => dispatch(putRegulations(params)),
    getProfileData: () => dispatch(getProfileData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Regulations);
