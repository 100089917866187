import React from 'react';
import { Paper, Theme, makeStyles, createStyles, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface IProps {
  index?: number,
  stateIndex?:number,
  loading?: boolean,
  elevation?: number,
  height?: number,
  color1: string,
  color2: string,
  color3: string,
  selectedColor?: boolean,
  stateColor1?: string,
  stateColor2?: string,
  stateColor3?: string,
}

export default function XCardColorTheme(props: IProps) {

  const styleProps = {
    height: props.height,
    color1: (props.index === props.stateIndex ? props.stateColor1! : props.color1!),
    color2: (props.index === props.stateIndex ? props.stateColor2! : props.color2!),
    color3: (props.index === props.stateIndex ? props.stateColor3! : props.color3!),
  }
  const classes = useStyles(styleProps);

  return (
    <main className="xcardcolortheme">
      {props.loading ?
        <Skeleton variant="rect" height={props.height ? props.height : 250} style={{borderRadius: '20px'}}/>
        :

          <Paper elevation={props.elevation} className={classes.root}>
            <Box>
              {!props.color1 ? '' :
                <div className={classes.topContent}>
                </div>}

              {!props.color2 ? '' :
                <div className={classes.middleContent}>
                </div>}

              {!props.color3 ? '' :
                <div className={classes.bottomContent}>
                </div>}
            </Box>
          </Paper>
      }
    </main>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
   
    root: (props: IProps) => ({
      position: 'relative',
      //width: '25%',
      height: !props.height ? 'auto' : `${props.height}px`,
      borderRadius: '7px'
    }),
    topContent: (props: IProps) => ({
      overflow: 'hidden',
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '50%',
      borderRadius: '7px 0 0 7px',
      height: '100%',
      backgroundColor: props.color1
    }),
    middleContent: (props: IProps) => ({
      overflow: 'hidden',
      position: 'absolute',
      top: '0',
      right: '0',
      width: '50%',
      height: '50%',
      borderRadius: '0 7px 0 0',
      backgroundColor: props.color2
    }),
    bottomContent: (props: IProps) => ({
      overflow: 'hidden',
      position: 'absolute',
      bottom: '0px',
      right: '0px',
      width: '50%',
      borderRadius: '0 0 7px 0',
      height: '50%',
      backgroundColor: props.color3
    })
  }));