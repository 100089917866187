import { store } from "../store/Store";
import { toast } from "react-toastify";
import history from "../../routes/history";
import { ActionCreator, Dispatch } from "redux";
import { treatError } from "../../utils/treatError";
import { IAuthLoginParameters } from "../types/types";
import { ITenant } from "redux/reducers/tenantReducer";
import * as constants from "../constants/authConstants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { IAuthUser, IAuthState } from "../reducers/authReducer";
import {
  apiDeleteAuthorization,
  api_general,
  api_v2,
} from "./../../config/api";
import {
  ICultureSetCulture, setCultureAndResources,
} from "../actions/CultureActions";

const basePath = "accounts/login";
const basePathEnviroment = "accounts/login/enviroments";

export interface IAuthenticate {
  type: constants.AUTHENTICATE;
}
export function authenticate(): IAuthenticate {
  return {
    type: constants.AUTHENTICATE,
  };
}
export interface IUnauthenticate {
  type: constants.UNAUTHENTICATE;
}
export function unauthenticate(): IUnauthenticate {
  return {
    type: constants.UNAUTHENTICATE,
  };
}

export enum AuthActionTypes {
  ENVIROMENTS = "ENVIROMENTS",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  LOGOUTSSO = "LOGOUTSSO",
}

export interface IAuthLoginEnviromentsAction {
  type: AuthActionTypes.ENVIROMENTS;
  activeTenant: ITenant[];
}

// Interface for Login Action Type
export interface IAuthLoginAction {
  type: AuthActionTypes.LOGIN;
  auth: IAuthUser;
}

export interface IAuthLogoutAction {
  type: AuthActionTypes.LOGOUT;
  auth: IAuthUser;
}

export interface IAuthLogoutSSOAction {
  type: AuthActionTypes.LOGOUTSSO;
  auth: IAuthUser;
}

export type AuthActions =
  | IAuthLoginEnviromentsAction
  | IAuthLoginAction
  | IAuthLogoutAction;

export const doEnviroments: ActionCreator<ThunkAction<
  Promise<any>,
  null,
  null,
  IAuthLoginEnviromentsAction
>> = (email, password, captcha) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      const LoginParameters: any = {
        email: email,
        password: password,
        captcha: captcha,
      };
      api_general.post(`${basePathEnviroment}`, LoginParameters).then(
        (response) => {
          return resolve({
            activeTenant: response.data,
          });
        },
        (err) => {
          return reject(err);
        }
      );
    });
  };
};

export const doLogin: ActionCreator<ThunkAction<
  Promise<any>,
  IAuthState,
  null,
  IAuthLoginAction
>> = (environmentType, tenantId, email, password, culture, captcha) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      const LoginParameters: IAuthLoginParameters = {
        environmentType: environmentType,
        tenantId: tenantId,
        email: email,
        password: password,
        captcha: captcha
      }
      api_general.post(`${basePath}`, LoginParameters).then(response => {
        const resources = getResourcesHandler(response.data.cultureTag)
        dispatch({
          auth: {
            jwtToken: {
              accessToken: response.data.jwtToken.accessToken,
              expires: response.data.jwtToken.expires
            },
            isFirstAccess: response.data.isFirstAccess,
            isProfileSetted: response.data.isProfileSetted,
            isVisibleButtonHelp: response.data.isVisibleButtonHelp,
            backOfficeAllowed: response.data.backOfficeAllowed,
            backOfficeURL: response.data.backOfficeURL,
            cultureTag: response.data.cultureTag,
            applicationId: response.data.applicationId,
            workspaceId: response.data.workspaceId,
            allowToSeePrizes: response.data.allowToSeePrizes,
            permission: response.data.permission,
            allowCreateWorkspace: response.data.allowCreateWorkspace,
            hasWorkspaceConfigured: response.data.hasWorkspaceConfigured,
            notifyUserPasswordExpiration: response.data.notifyUserPasswordExpiration,
            isAuthenticated: true,
            resources
          },
          type: AuthActionTypes.LOGIN,
        });

          return resolve({
            auth: {
              jwtToken: {
                accessToken: response.data.jwtToken.accessToken,
                expires: response.data.jwtToken.expires,
              },
              isFirstAccess: response.data.isFirstAccess,
              isProfileSetted: response.data.isProfileSetted,
              isVisibleButtonHelp: response.data.isVisibleButtonHelp,
              backOfficeAllowed: response.data.backOfficeAllowed,
              backOfficeURL: response.data.backOfficeURL,
              cultureTag: response.data.cultureTag,
              applicationId: response.data.applicationId,
              workspaceId: response.data.workspaceId,
              allowToSeePrizes: response.data.allowToSeePrizes,
              permission: response.data.permission,
              allowCreateWorkspace: response.data.allowCreateWorkspace,
              hasWorkspaceConfigured: response.data.hasWorkspaceConfigured,
              notifyUserPasswordExpiration: response.data.notifyUserPasswordExpiration,
              isAuthenticated: true,
              resources
          }
        })

      }, err => {
        return reject(err)
      });
    })
  }
}

export const doLoginSSO: ActionCreator<ThunkAction<
  Promise<any>,
  any,
  null,
  IAuthLoginAction
>> = (response) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        /**** ATENÇÃO A ESTE PONTO:
         * O RESPONSE VEM VIA SOCKET E NÃO VIA API
         * PARA PEGAR O RESULTADO O OBJETO VEM DIRETO DO RESPONSE E **NÃO** DO RESPONSE.DATA
         *
         * */
        auth: {
          jwtToken: {
            accessToken: response.jwtToken.accessToken,
            expires: response.jwtToken.expires,
          },
          isFirstAccess: response.isFirstAccess,
          isProfileSetted: response.isProfileSetted,
          isVisibleButtonHelp: response.isVisibleButtonHelp,
          backOfficeAllowed: response.backOfficeAllowed,
          backOfficeURL: response.backOfficeURL,
          cultureTag: response.cultureTag,
          applicationId: response.applicationId,
          workspaceId: response.workspaceId,
          allowToSeePrizes: response.allowToSeePrizes,
          permission: response.permission,
          allowCreateWorkspace: response.allowCreateWorkspace,
          hasWorkspaceConfigured: response.hasWorkspaceConfigured,
          notifyUserPasswordExpiration: response.notifyUserPasswordExpiration,
          isAuthenticated: true,
        },
        type: AuthActionTypes.LOGIN,
      });
      getResourcesHandler(response.cultureTag);
    } catch (err) {
      treatError(response.culture, "Login", err);
    }
  };
};

export const doLogout: ActionCreator<ThunkAction<
  Promise<any>,
  IAuthState,
  null,
  IAuthLogoutAction
>> = (is_unauthorized?: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        auth: {
          permission: "",
          allowToSeePrizes: false,
          isAuthenticated: false,
          jwtToken: { accessToken: "", expires: "" },
          cultureTag: "",
          resources: [],
          backOfficeAllowed: false,
          backOfficeURL: "",
          isVisibleButtonHelp: false,
        },
        type: AuthActionTypes.LOGOUT,
      });
      apiDeleteAuthorization();
      history.push("/splash", {
        expiredLogin: is_unauthorized,
      });
    } catch (err) {
      toast.error(err?.error_message);
      history.push("/splash");
    }
  };
};

export const doLogoutSSO: ActionCreator<ThunkAction<
  Promise<any>,
  IAuthState,
  null,
  IAuthLogoutSSOAction
>> = (tenantId?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await api_v2.post(
        `accounts/logout/sso?tenantId=${tenantId}`,
        { tenantId }
      );
      dispatch({
        auth: {
          permission: "",
          allowToSeePrizes: false,
          isAuthenticated: false,
          jwtToken: { accessToken: "", expires: "" },
          cultureTag: "",
          resources: [],
          backOfficeAllowed: false,
          backOfficeURL: "",
          isVisibleButtonHelp: false,
        },
        type: AuthActionTypes.LOGOUT,
      });
      apiDeleteAuthorization();
      window.location.href = response.data.formAction;
    } catch (err) {
      toast.error(err?.error_message);
      history.push("/splash");
    }
  };
};

async function getResourcesHandler(cultureTag: string) {
  let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store.dispatch;
  await dispatch(setCultureAndResources(cultureTag));
  return store.getState().cultureState
}
