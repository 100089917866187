import React from "react";
import Resource from "../Resources/Resource";
import {
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";

/*Example:
    <Tags list={['Customer Satisfaction','Maketing','Design','Usability']}/>
*/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagWord: {
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "10px",
      paddingRight: "10px",
      marginRight: "10px",
      marginTop: "10px",
      float: "left",
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.secondary,
      borderRadius: "2px",
    },
  })
);
interface IProps {
  list: string[];
}

function Tags(props: IProps) {
  const classes = useStyles();

  return (
    <Box>
      <Box>
        <Typography color="textSecondary">
          <Resource tag={"PageDetailedIdea::TagsArea_Title"} />
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap">
        {props?.list?.map((tag, index) => {
          return (
            <Box className={classes.tagWord} key={index}>
              {tag}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default Tags;
