import axios from "axios";
import { store } from "./../redux/store/Store";
import {
	API_GENERAL_URL,
	API_TENANT_CONFIG_URL,
	API_ERRO_TYPE_VALIDATION,
	API_ERRO_TYPE_API,
	API_ERRO_TYPE_SERVER,
	API_ERRO_TYPE_CONNECTION,
	API_ERRO_TYPE_OTHER,
	API_ERRO_TYPE_ACCESS_TOKEN,
	API_ERRO_TYPE_CANCEL
} from "./general";
import { doLogout, IAuthLoginAction } from "../redux/actions/AuthAction";
import { ThunkDispatch } from "redux-thunk";
import { toast } from "react-toastify";
import { resource } from "components/Resources/Resource";

// -----------------------------------------------------------------------------
// General
// -----------------------------------------------------------------------------
export const API_HEADER_DEFAULT = {
	"Accept"      : "application/json",
	"ApiKey"	  : "7FF26A8B-B9A0-4681-A8B7-413D0B1EB175",
	"Content-Type": "application/json",
	"Language"    : "pt",
};


// -----------------------------------------------------------------------------
// Instance
// -----------------------------------------------------------------------------
export const api = axios.create({
	baseURL: '',//store.getState().tenantState.tenant.endpoint,
	timeout: 3 * 60 * 1000, // 3 minutes
	headers: API_HEADER_DEFAULT,
});

export const api_general = axios.create({
	baseURL: API_GENERAL_URL,
	timeout: 3 * 60 * 1000, // 3 minutes
	headers: API_HEADER_DEFAULT,
	
});
export const api_v2 = axios.create({
	baseURL: '',
	timeout: 3 * 60 * 1000, // 3 minutes
	headers: API_HEADER_DEFAULT,
});

export const api_v3 = axios.create({
	baseURL: API_GENERAL_URL,
	timeout: 3 * 60 * 1000, // 3 minutes
	headers: API_HEADER_DEFAULT,
});

export const api_tenant_config = axios.create({
	baseURL: API_TENANT_CONFIG_URL,
	timeout: 3 * 60 * 1000, // 3 minutes
	headers: API_HEADER_DEFAULT,
});

/**
 * Update baseUrl on default request
 *
 * @param url
 */
export const apiUpdateBaseUrl = (url = '') => {

	if( url === null )
	{
		url = store.getState().tenantState.activeTenant.endpoint;
	}

	api.defaults.baseURL = url + "/api/v2/";
	api_v2.defaults.baseURL = url + "/api/v2/";
	api_v3.defaults.baseURL = url + "/api/v3/";
};



/**
 * Update acess token on default request
 *
 * @param access_token
 */
export const apiUpdateAccessToken = (access_token: any) => {
	access_token = access_token ? access_token : store.getState().authState.auth.jwtToken.accessToken;

	// Set access_token to instance
	api.defaults.headers.common["Authorization"] = 'Bearer '+access_token;
	api_v2.defaults.headers.common["Authorization"] = 'Bearer '+access_token;
	api_v3.defaults.headers.common["Authorization"] = 'Bearer '+access_token;
	api_general.defaults.headers.common["Authorization"] = 'Bearer '+access_token;
};
export const apiDeleteAuthorization = () => {
	delete api.defaults.headers.common["Authorization"];
	delete api.defaults.headers.common["cultureTag"];
	delete api_v2.defaults.headers.common["Authorization"];
	delete api_v2.defaults.headers.common["cultureTag"];
	delete api_v3.defaults.headers.common["Authorization"];
	delete api_v3.defaults.headers.common["cultureTag"];
	delete api_general.defaults.headers.common["Authorization"];
	delete api_general.defaults.headers.common["cultureTag"];
	localStorage.clear();
}

/**
 * Update culture default request
 *
 * @param access_token
 */
export const languageCulture = (culture: any) => {
	//culture = culture// ? culture : store.getState().auth.cultureTag;

	// Set access_token to instance
	api.defaults.headers.common["cultureTag"] = culture;
	api_v2.defaults.headers.common["cultureTag"] = culture;
	api_v3.defaults.headers.common["cultureTag"] = culture;
	api_general.defaults.headers.common["cultureTag"] = culture;
};

// -----------------------------------------------------------------------------
// Errors, Refresh token
// -----------------------------------------------------------------------------
api.interceptors.request.use((config) => {
	const url = store.getState().tenantState.activeTenant.endpoint;

	if( url )
	{
		config.url = url + "/api/v2/" + config.url;

		// Update access_token on instance
		apiUpdateBaseUrl(url);
	}

	if( !config.headers.common["Authorization"] )
	{
		const access_token = store.getState().authState.auth.jwtToken.accessToken;

		if( access_token )
		{
			config.headers["Authorization"] = 'Bearer '+ access_token

			// Update access_token on instance
			apiUpdateAccessToken(access_token);
		}
	}

	if( !config.headers.common["cultureTag"] )
	{
		const culture = store.getState().authState.auth.cultureTag;

		if( culture )
		{
			config.headers["cultureTag"] = culture;

			// Update language culture on instance
			languageCulture(culture);
		}
	}

	const applicationId = store.getState().authState.auth.applicationId;

	if(config.hasOwnProperty("params")){
		config.params.push({applicationId:applicationId})
	}else{
		config.params = {applicationId:applicationId}
	}

	if(config.params.hasOwnProperty("params")){
		const culture = store.getState().authState.auth.cultureTag;

		if( culture )
		{
			config.params["cultureTag"] = culture;
		}
	}

	return config;
});

api_v2.interceptors.request.use((config) => {
	const url = store.getState().tenantState.activeTenant.endpoint;
	config.headers["timeStart"] = new Date().getTime()
	if( url )
	{
		config.url = url + "/api/v2/" + config.url;

		// Update access_token on instance
		apiUpdateBaseUrl(url);
	}

	if( !config.headers.common["Authorization"] )
	{
		const access_token = store.getState().authState.auth.jwtToken.accessToken;

		if( access_token )
		{
			config.headers["Authorization"] = 'Bearer '+ access_token;

			// Update access_token on instance
			apiUpdateAccessToken(access_token);
		}
	}

	if( !config.headers.common["cultureTag"] )
	{
		const culture = store.getState().authState.auth.cultureTag;

		if( culture )
		{
			config.headers["cultureTag"] = culture;

			// Update language culture on instance
			languageCulture(culture);
		}
	}


	if( !config.headers.common["applicationId"] )
	{
		const applicationId = store.getState().authState.auth.applicationId;

		if( applicationId )
		{
			config.headers["applicationId"] = applicationId;
		}
	}

	const applicationId = store.getState().authState.auth.applicationId;

	if(config.hasOwnProperty("params")){
		config.params.push({applicationId:applicationId})
	}else{
		config.params = {applicationId:applicationId}
	}

	if(config.params.hasOwnProperty("params")){
		const culture = store.getState().authState.auth.cultureTag;

		if( culture )
		{
			config.params["cultureTag"] = culture;
		}
	}

	return config;
});

api_v3.interceptors.request.use((config) => {
	const url = store.getState().tenantState.activeTenant.endpoint;
	const timezone = store.getState().profileState.profileData.timezone;

	if( url )
	{
		config.url = url + "/api/v3/" + config.url;

		// Update access_token on instance
		apiUpdateBaseUrl(url);
	}

	if(timezone){
		config.headers["TimeZone"] = timezone;
	}

	if( !config.headers.common["Authorization"] )
	{
		const access_token = store.getState().authState.auth.jwtToken.accessToken;

		if( access_token )
		{
			config.headers["Authorization"] = 'Bearer '+ access_token;

			// Update access_token on instance
			apiUpdateAccessToken(access_token);
		}
	}

	if( !config.headers.common["cultureTag"] )
	{
		const culture = store.getState().authState.auth.cultureTag;

		if( culture )
		{
			config.headers["cultureTag"] = culture;

			// Update language culture on instance
			languageCulture(culture);
		}
	}


	if( !config.headers.common["applicationId"] )
	{
		const applicationId = store.getState().authState.auth.applicationId;
		if( applicationId )
		{
			config.headers["applicationId"] = applicationId;
		}
	}

	const applicationId = store.getState().authState.auth.applicationId;

	if(config.hasOwnProperty("params")){
		config.params.push({applicationId:applicationId})
	}else{
		config.params = {applicationId:applicationId}
	}

	if(config.params.hasOwnProperty("params")){
		const culture = store.getState().authState.auth.cultureTag;

		if( culture )
		{
			config.params["cultureTag"] = culture;
		}
	}

	return config;
});

api_general.interceptors.request.use((config) => {
	const timezone = store.getState().profileState.profileData.timezone;
	
	if(timezone){
		config.headers["TimeZone"] = timezone;
	}

	if( !config.headers.common["Authorization"] )
	{
		const access_token = store.getState().authState.auth.jwtToken.accessToken;

		if( access_token )
		{
			config.headers["Authorization"] = 'Bearer '+ access_token;

			// Update access_token on instance
			apiUpdateAccessToken(access_token);
		}
	}

	if( !config.headers.common["cultureTag"] )
	{
		const culture = store.getState().authState.auth.cultureTag;

		if( culture )
		{
			config.headers["cultureTag"] = culture;

			// Update language culture on instance
			languageCulture(culture);
		}
	}


	if( !config.headers.common["applicationId"] )
	{
		const applicationId = store.getState().authState.auth.applicationId;
		if( applicationId )
		{
			config.headers["applicationId"] = applicationId;
		}
	}

	if( !config.headers.common["workspaceId"] )
	{
		const workspaceId = store.getState().authState.auth.workspaceId;
		if( workspaceId )
		{
			config.headers["workspaceId"] = workspaceId;
		}
	}

	const applicationId = store.getState().authState.auth.applicationId;
	const workspaceId = store.getState().authState.auth.workspaceId;

	if(config.hasOwnProperty("params")){
		config.params.push({applicationId:applicationId, workspaceId:workspaceId})
	}else{
		config.params = {applicationId:applicationId, workspaceId:workspaceId}
	}

	if(config.params.hasOwnProperty("params")){
		const culture = store.getState().authState.auth.cultureTag;

		if( culture )
		{
			config.params["cultureTag"] = culture;
		}
	}

	return config;
});

api_general.interceptors.response.use((response) => {

	return response;
}, (error) => {
	if( axios.isCancel(error) )
	{
		let errorReturn = {
			error        : error,
			error_type   : API_ERRO_TYPE_CANCEL,
			error_message: "Requisição cancelada.",
			error_errors : {},
		};

		errorReturn.toString = () => errorReturn.error_message;

		return Promise.reject(errorReturn);
	}

	// Has response from server
	if( error.response )
	{
		// Invalid Token
		if( error.response.status === 401)
		{
			error.response.data === "MessageError.TokenExpired" && silentLogout(store.dispatch, true);
			
			//const culture = error.response.config.headers["cultureTag"];
			
			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_ACCESS_TOKEN,
				error_message: "Você foi deslogado, por favor realize novamente o login.", //resource(culture, "MessageError::ForcedLoggout"),
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;
            toast.error(errorReturn.error_message, {toastId: 'treat-error-toast-api'});  
			window.location.replace('/app/splash');

			return Promise.reject(errorReturn);
		}
	}
	
	function silentLogout(dispatch: ThunkDispatch<any, any, IAuthLoginAction>, is_unauthorized?: boolean) {
		dispatch(doLogout(is_unauthorized));
	}

	let errorReturn = {
		error: error,
		...getError(error),
	};

	errorReturn.toString = () => errorReturn.error_message;

	return Promise.reject(errorReturn);
});

api.interceptors.response.use((response) => {
	return response;
}, (error) => {
	if( axios.isCancel(error) )
	{
		let errorReturn = {
			error        : error,
			error_type   : API_ERRO_TYPE_CANCEL,
			error_message: "Requisição cancelada.",
			error_errors : {},
		};

		errorReturn.toString = () => errorReturn.error_message;

		return Promise.reject(errorReturn);
	}

	const originalRequest = error.config;
	// TODO - Verificar se essas urls estão corretas, de login e logout
	let is_logout_request = originalRequest && originalRequest.url.endsWith("auth/logout");
	let is_login_request  = originalRequest && originalRequest.url.endsWith("auth/login");

	// Has response from server
	if( error.response )
	{
		//Bad Request
		if( error.response.status === 400 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "MessageError::UnexpectedError",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}

		// Invalid Token
		if( error.response.status === 401 && !is_logout_request && !is_login_request )
		{

			error.response.data !== "MessageError.UserBlocked" && silentLogout(store.dispatch, true);

			//const culture = store.getState().authState.auth.cultureTag;
			
			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_ACCESS_TOKEN,
				error_message: "Você foi deslogado, por favor realize novamente o login.", //resource(culture, "MessageError::ForcedLoggout")
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;
            toast.error(errorReturn.error_message, {toastId: 'treat-error-toast-api'});  
			window.location.replace('/app/splash');

			return Promise.reject(errorReturn);
		}

		//Forbidden
		if( error.response.status === 403 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_ACCESS_TOKEN,
				error_message: "Errors::Error_Forbidden",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}

		//Not found
		if( error.response.status === 404 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "Errors::Error_NotFound",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}	
		
		//Method not allowed
		if( error.response.status === 405 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_VALIDATION,
				error_message: "Errors::Error_MethodNotAllowed",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}
		
		//Missing Parameters
		if( error.response.status === 422 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_API,
				error_message: "Errors::Error_MissingParameters",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}	
			
		//Internal Server Error
		if( error.response.status === 500 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "Errors::Error_InternalServerError",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}		

		//Service Unavailable
		if( error.response.status === 503 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "Errors::Error_ServiceUnavailable",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}				

	}
	function silentLogout(dispatch: ThunkDispatch<any, any, IAuthLoginAction>, is_unauthorized?: boolean) {
		dispatch(doLogout(is_unauthorized));
	}
	let errorReturn = {
		error: error,
		...getError(error),
	};

	errorReturn.toString = () => errorReturn.error_message;

	return Promise.reject(errorReturn);
});
api_v2.interceptors.response.use((response) => {
	let now = new Date().getTime()
	let duration = now - response.config.headers.timeStart
	if(duration > 5000){
		toast.warning(
			resource(
				store.getState().cultureState, 
				'MessageWarning::InternetConnectionSlow'
			), 
			{ toastId: 'slow-connection-warning' }
		)
	}
	return response;
}, (error) => {
	if( axios.isCancel(error) )
	{
		let errorReturn = {
			error        : error,
			error_type   : API_ERRO_TYPE_CANCEL,
			error_message: "Requisição cancelada.",
			error_errors : {},
		};

		errorReturn.toString = () => errorReturn.error_message;

		return Promise.reject(errorReturn);
	}
	const originalRequest = error.config;
	// TODO - Verificar se essas urls estão corretas, de login e logout
	let is_logout_request = originalRequest && originalRequest.url.endsWith("auth/logout");
	let is_login_request  = originalRequest && originalRequest.url.endsWith("auth/login");

	// Has response from server
	if( error.response )
	{
		//Bad Request
		if( error.response.status === 400 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "MessageError::UnexpectedError",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}

		// Invalid Token
		if( error.response.status === 401 && !is_logout_request && !is_login_request )
		{
			error.response.data !== "MessageError.UserBlocked" && silentLogout(store.dispatch, true);
			//const culture = store.getState().authState.auth.cultureTag;
			
			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_ACCESS_TOKEN,
				error_message: "Você foi deslogado, por favor realize novamente o login.",//resource(culture, "MessageError::ForcedLoggout"),
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;
            toast.error(errorReturn.error_message, {toastId: 'treat-error-toast-api'});  
			window.location.replace('/app/splash');

			return Promise.reject(errorReturn);
		}

		//Forbidden
		if( error.response.status === 403 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_ACCESS_TOKEN,
				error_message: "Errors::Error_Forbidden",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}

		//Not found
		if( error.response.status === 404 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "Errors::Error_NotFound",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}	
		
		//Method not allowed
		if( error.response.status === 405 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_VALIDATION,
				error_message: "Errors::Error_MethodNotAllowed",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}
		
		//Missing Parameters
		if( error.response.status === 422 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_API,
				error_message: "Errors::Error_MissingParameters",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}	
			
		//Internal Server Error
		if( error.response.status === 500 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "Errors::Error_InternalServerError",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}		

		//Service Unavailable
		if( error.response.status === 503 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "Errors::Error_ServiceUnavailable",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}				

	}
	function silentLogout(dispatch: ThunkDispatch<any, any, IAuthLoginAction>, is_unauthorized?: boolean) {

		dispatch(doLogout(is_unauthorized));
	}

	let errorReturn = {
		error: error,
		...getError(error),
	};

	errorReturn.toString = () => errorReturn.error_message;

	return Promise.reject(errorReturn);
});

api_v3.interceptors.response.use((response) => {
	return response;
}, (error) => {
	if( axios.isCancel(error) )
	{
		let errorReturn = {
			error        : error,
			error_type   : API_ERRO_TYPE_CANCEL,
			error_message: "Requisição cancelada.",
			error_errors : {},
		};

		errorReturn.toString = () => errorReturn.error_message;

		return Promise.reject(errorReturn);
	}

	const originalRequest = error.config;
	// TODO - Verificar se essas urls estão corretas, de login e logout
	let is_logout_request = originalRequest && originalRequest.url.endsWith("auth/logout");
	let is_login_request  = originalRequest && originalRequest.url.endsWith("auth/login");

	// Has response from server
	if( error.response )
	{
		//Bad Request
		if( error.response.status === 400 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "MessageError::UnexpectedError",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}

		// Invalid Token
		if( error.response.status === 401 && !is_logout_request && !is_login_request )
		{
			error.response.data !== "MessageError.UserBlocked" && silentLogout(store.dispatch, true);
			
			//const culture = store.getState().authState.auth.cultureTag;
			
			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_ACCESS_TOKEN,
				error_message: "Você foi deslogado, por favor realize novamente o login.",//resource(culture, "MessageError::ForcedLoggout"),
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;
            toast.error(errorReturn.error_message, {toastId: 'treat-error-toast-api'});  
			window.location.replace('/app/splash');

			return Promise.reject(errorReturn);
		}

		//Forbidden
		if( error.response.status === 403 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_ACCESS_TOKEN,
				error_message: "Errors::Error_Forbidden",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}

		//Not found
		if( error.response.status === 404 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "Errors::Error_NotFound",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}	
		
		//Method not allowed
		if( error.response.status === 405 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_VALIDATION,
				error_message: "Errors::Error_MethodNotAllowed",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}
		
		//Missing Parameters
		if( error.response.status === 422 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_API,
				error_message: "Errors::Error_MissingParameters",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}	
			
		//Internal Server Error
		if( error.response.status === 500 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "Errors::Error_InternalServerError",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}		

		//Service Unavailable
		if( error.response.status === 503 ){

			let errorReturn = {
				error        : error,
				error_type   : API_ERRO_TYPE_SERVER,
				error_message: "Errors::Error_ServiceUnavailable",
				error_errors : error?.response?.data,
			};

			errorReturn.toString = () => errorReturn.error_message;

			return Promise.reject(errorReturn);
		}				

	}
	function silentLogout(dispatch: ThunkDispatch<any, any, IAuthLoginAction>, is_unauthorized?: boolean) {
		dispatch(doLogout(is_unauthorized));
	}
	let errorReturn = {
		error: error,
		...getError(error),
	};

	errorReturn.toString = () => errorReturn.error_message;

	return Promise.reject(errorReturn);
});

/**
 * Get erro to response
 *
 * @param error
 * @returns {{error_type: string, error_message: string, error_errors}}
 */
export function getError(error: { response: { status?: any; data?: any; }; }) {
	let error_type    = "";
	let error_message = "";
	let error_errors  = {};

	// Has response from server
	if( error.response )
	{
		const {data} = error.response;

		// Key message on data
		if( data.hasOwnProperty("message") )
		{
			error_message = data.message;
		}

		// 401 with message
		if( error.response.status === 401 )
		{
			error_type = API_ERRO_TYPE_OTHER;

			if( !error_message )
			{
				error_message = "Ocorreu um erro de autorização, por favor tente novamente.";
			}
		}
		// Form validation error
		else if( error.response.status === 422 )
		{
			error_type = API_ERRO_TYPE_VALIDATION;

			// Get first validation error
			if( data.hasOwnProperty("errors") )
			{
				let data_errors = data.errors;

				// First error
				for( let key in data_errors )
				{
					if( data_errors.hasOwnProperty(key) )
					{
						error_message = data_errors[key][0];

						break;
					}
				}

				for( let key in data_errors )
				{
					if( data_errors.hasOwnProperty(key) )
					{
						// error_errors[key] = data_errors[key][0];
					}
				}
			}
		}
		// Too Many Requests
		else if( error.response.status === 429 )
		{
			error_type = API_ERRO_TYPE_SERVER;

			if( !error_message )
			{
				error_message = "Foi atingido o limite de requisições ao servidor, por favor tente novamente mais tarde.";
			}
		}
		// Internal server error
		else if( error.response.status === 500 )
		{
			error_type = API_ERRO_TYPE_SERVER;

			if( !error_message )
			{
				error_message = "Ocorreu uma falha de comunicação com o servidor.";
			}
		}
		// Not found
		else if( error.response.status === 404 )
		{
			error_type = API_ERRO_TYPE_OTHER;

			if( !error_message )
			{
				error_message = "A url acessada não existe.";
			}
		}
		// 400-499 with message
		else if( error.response.status > 400 && error.response.status < 499 )
		{
			error_type = API_ERRO_TYPE_API;

			if( !error_message )
			{
				error_message = "Ocorreu um erro, por favor tente novamente.";
			}
		}
		else
		{
			error_type = API_ERRO_TYPE_OTHER;

			if( !error_message && error.response.data[0])
			{
				error_message = resource(store.getState().cultureState, ("" + error.response.data[0].value).replace(".", "::"));
			}
			else if( !error_message )
			{
				error_message = "Ocorreu um erro, por favor tente novamente.";
			}
		}
	}
	else
	{
		error_type    = API_ERRO_TYPE_CONNECTION;
		error_message = "Falha de comunicação com o servidor, verifique sua conexão com a internet e tente novamente.";
	}

	return {
		error_type   : error_type,
		error_message: error_message,
		error_errors : error_errors,
	};
}
