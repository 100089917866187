import "./FormProgress.css";
import React, { useState } from "react";
import Resource from "../Resources/Resource";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { IChallengeListItem } from "redux/types/challenges";
import ModalSelectAChallenge from "common/Modal/ModalSelectAChallenge";
import {
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  Divider,
  Box,
  Container,
  Grid,
  useTheme,
} from "@material-ui/core";

interface IProps {
  fields?: number;
  completedFields?: number;
  challenge?: IChallengeListItem;
  mode?: "edit" | "idea" | "draft";
  setChallengeId?: (challenge: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    pageTitle: {
      marginTop: 24,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialog: {
      margin: "auto",
    },
    titleHeader: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#313b53",
    },
    dividerHeader: {
      width: "90%",
      marginLeft: "30px",
    },
    dialogHeader: {
      padding: "22px 24px 10px 30px",
    },
    container: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);

const FormProgress = ({
  fields = 0,
  completedFields = 0,
  challenge,
  mode,
  setChallengeId,
}: IProps) => {
  const completedPorcentage = Math.floor((completedFields / fields) * 100);
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const bars = [];
  for (let index = 0; index < fields; index++) {
    const barClass = index < completedFields;
    bars.push(
      <span
        key={index}
        className={"FormProgress-bar" + (barClass ? " active" : "")}
        style={{
          backgroundColor: barClass
            ? theme.palette.primary.main
            : theme.palette.primary.light,
        }}
      ></span>
    );
  }

  return (
    <Container className={classes.container}>
      <Box className="FormProgress-Porcentage">
        <Typography variant="body2" className="completed-porcentage">
          <Resource
            tag="PageNewIdea::Header"
            args={[completedPorcentage]}
          ></Resource>
        </Typography>
      </Box>
      <Box className="FormProgressBar-container">{bars}</Box>
      {mode === "edit" ? (
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="body2" className="FormProgress-Challenge">
              <Resource tag="PageEditIdea::Challenge" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className="FormProgress-Challenge">
              {challenge?.title}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1} onClick={() => setOpen(true)}>
          <Grid item>
            <Typography variant="body2" className="FormProgress-ChallengeName">
              <Resource tag="PageEditIdea::Challenge" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className="FormProgress-ChallengeName">
              {challenge?.title}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Dialog open={isOpen} maxWidth="md" fullWidth className={classes.dialog}>
        <MuiDialogTitle className={classes.dialogHeader}>
          <Typography color="textPrimary" className={classes.titleHeader}>
            <Resource tag={"PageNewIdea::Challenge_Header"} />
          </Typography>
          <IconButton
            // aria-label="close"
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Divider className={classes.dividerHeader} />
        <ModalSelectAChallenge
          setChallengeId={setChallengeId}
          selectedChallenge={challenge ? challenge.challengeId : ""}
          closeButton={() => setOpen(false)}
          path={"/createIdea"}
        ></ModalSelectAChallenge>
      </Dialog>
    </Container>
  );
};

export default FormProgress;
