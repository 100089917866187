import * as React from "react";
import { connect } from "react-redux";
import ProfileBottom from "../ProfileBottom/ProfileBottom"
import { IAppState } from '../../redux/store/Store';
import { IProfile } from '../../redux/reducers/profileReducer';
import ProfileHeader from '../ProfileHeader/ProfileHeader'
import './Profile.css'
import { ICultureState } from "../../redux/reducers/cultureReducer";
import { Box, Button, useTheme } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from "react";
import { IMessagesReceived } from "redux/types/messages";


interface IProps {
  profile: IProfile[];
  icon: string;
  culture: ICultureState;
  receivedMessages: IMessagesReceived
}

function Profile(props: IProps) {


  const { profile, culture } = props;
  const [on, setOn] = useState(false)
  const theme = useTheme()
  const toggleProfile = () => {
    setOn(!on)
  }
  return (
    <Box>
      <Box position="relative" textAlign="center" className="profile-container" id="profile-container">
        <ProfileHeader cultureTag={culture.culture.cultureTag} profile={profile} />
        <Button id="ProfileButtonToggle" onClick={toggleProfile} style={{
          backgroundColor: theme.palette.text.hint,
        }} className="profileButton">
          {on ?
            <ExpandLessIcon style={{ color: theme.palette.common.white }} />
            : <ExpandMoreIcon style={{ color: theme.palette.common.white }} />
          }
        </Button>
        {on && <ProfileBottom receivedMessages={props.receivedMessages} toggleProfile={toggleProfile} profile={profile} />}
      </Box>
    </Box>
  )
}
const mapStateToProps = (store: IAppState) => {
  return {
    icon: store.profileState.icon,
    profile: store.profileState.profile,
    culture: store.cultureState
  };
};

export default connect(mapStateToProps)(Profile);