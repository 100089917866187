import React from "react";
import "./ListIdeaParticipants.css";
import ListUserItem from "../ListUserItem/ListUserItem";
import { IChallengesTops } from "redux/types/challenges";
import {
  Box,
  createStyles,
  Divider,
  Grid,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Title from "../Title/Title";
import Resource from "../Resources/Resource";
import StarIcon from "@material-ui/icons/Star";
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  tops?: IChallengesTops;
}

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      margin: "30px 0",
    },
    titleI: {
      fontSize: "1.25rem",
      color: "#333",
    },
    subTitle: {
      fontSize: "1rem",
      color: "#333",
    },
    li: {
      paddingLeft: 0,
      paddingRight: 0,
      color: "#333",
    },
    rank: {
      color: "rgba(27, 157, 252, 1)",
      padding: 0,
      width: "fit-content",
      fontWeight: "bold",
      fontSize: "0.9rem",
    },
    name: {
      paddingLeft: 6,
      paddingRight: 6,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: "bold",
    },
    link: {
      display: "flex",
      color: "#333",
    },
    counter: {
      fontWeight: "normal",
      paddingLeft: 6,
      paddingRight: 0,
      width: "fit-content",
      textTransform: 'lowercase',
    },
    rFlex: {
      flex: "none",
    },
  })
);

function ListIdeaParticipants(props: IProps) {
  //Cut long names
  const cutName = (name: string) => {
    const maxSize = 7;
    let newName: string = "";
    if (name?.length > maxSize) {
      newName = name.substr(0, maxSize).trim() + "...";
    } else {
      newName = name;
    }
    return newName;
  };

  const classes = useStyles();
  const { tops } = props;
  let eType;
  let iconType: React.ReactElement;
  const ideaBackURL = "/idea/details";
  return (
    <>

      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12} md={8}>
          <Box width="100%" p={1}>
            <Typography variant="h6" className={classes.titleI}>
              <Resource tag={"PageDetailChallenge::TopsTitle"} />
            </Typography>
            <List>
              {tops?.ideasHighestVotes?.length ?
                tops?.ideasHighestVotes.slice(0, 5).map((idea, index) => {
                  if (idea?.evaluationType === "Average" || idea?.evaluationType === "Stars") {
                    eType = parseFloat(idea?.average.toFixed(1));
                    iconType = <StarIcon />;
                  } else if (idea?.evaluationType === "Likes") {
                    eType = idea?.likes;
                    iconType = <Resource tag={"PageDetailChallenge::Likes"} />;
                  } else {
                    eType = parseFloat(idea?.average.toFixed(1)) + '% ';
                    iconType = <Resource tag={"PageDetailChallenge::Belief"} />;
                  }
                  return (
                    <ListItem key={idea?.ideaId} className={classes.li}>
                      <ListItemText className={classes.rFlex}>
                        <Typography className={classes.rank}>
                          {" "}
                          {`#${idea?.position}`}
                        </Typography>
                      </ListItemText>
                      <ListItemText style={{maxWidth: '75%'}} className={classes.rFlex}>
                        <Link className={classes.link}
                          component={RouterLink}
                          to={{
                            pathname: `${ideaBackURL}/${idea?.ideaId}`,
                          }}
                        >
                          <Typography className={classes.name}>
                            {" "}
                            {`"${idea?.title}"`}{" "}
                          </Typography>
                        </Link>
                      </ListItemText>
                      <ListItemText className={classes.rFlex}>
                        <Typography className={classes.counter}>
                          {`| `}
                          &nbsp;&nbsp;
                          {`${eType}`}
                        </Typography>
                      </ListItemText>
                      <ListItemIcon className={classes.rFlex}>
                        <Typography className={classes.counter}>
                          {iconType}
                        </Typography>
                      </ListItemIcon>
                    </ListItem>
                  );
                })
                :
                <ListItem style={{ textAlign: 'center' }}>
                  <Typography color="textSecondary" variant="subtitle1">
                    <Resource tag="PageWallet::NoDataAvailable" />
                  </Typography>
                </ListItem>
              }
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box width="100%" height="100%" display="flex">
            <Hidden smDown><Divider style={{ height: '80%', marginTop: '20%', marginRight: '8px' }} orientation="vertical" light /></Hidden>
            <Box p={1}>
              <Typography variant="h6" className={classes.subTitle}>
                <Resource tag={"PageDetailChallenge::TopsUserTitle"} />
              </Typography>
              <List>
                {tops?.usersMostIdeas?.length ?
                  tops?.usersMostIdeas.slice(0, 5).map((user, index) => {
                    return (
                      <ListUserItem
                        key={index}
                        avatar={user?.userImage}
                        rank={user?.position}
                        name={user?.userName}
                        counter={user?.totalIdeas}
                        title={
                          user?.totalIdeas === 1 ?  
                          <Resource tag={"PageDetailChallenge::Idea"} /> 
                          : 
                          <Resource tag={"PageDetailChallenge::Ideas"} />
                        }
                      />
                    );
                  })
                  :
                  <ListItem style={{ textAlign: 'center' }}>
                    <Typography color="textSecondary" variant="subtitle1">
                      <Resource tag="PageWallet::NoDataAvailable" />
                    </Typography>
                  </ListItem>
                }
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography variant="h3">
        <Title
          title={<Resource tag={"PageDetailChallenge::TopParticipations"} />}
        />
      </Typography>
      <Grid container className={classes.grid}>
        <Grid item xs={12} sm={12} md={4}>
          <Box width="100%" p={1}>
            <Typography variant="h6" className={classes.subTitle}>
              <Resource tag={"PageDetailChallenge::Users"} />
            </Typography>
            <List>
              {tops?.usersMostParticipation?.length ?
                tops?.usersMostParticipation.slice(0, 5).map((user, index) => {
                  return (
                    <ListUserItem
                      key={index}
                      avatar={user?.userImage}
                      rank={user?.position}
                      name={cutName(user?.userName)}
                      counter={user?.totalIdeas}
                      title={
                        user?.totalIdeas === 1 ?
                        <Resource tag={"PageDetailChallenge::Participation"} />
                        :
                        <Resource tag={"PageDetailChallenge::Participations"} />
                      }
                    />
                  );
                })
                :
                <ListItem style={{ textAlign: 'center' }}>
                  <Typography color="textSecondary" variant="subtitle1">
                    <Resource tag="PageWallet::NoDataAvailable" />
                  </Typography>
                </ListItem>
              }
            </List>
          </Box>
        </Grid>
        {tops?.departmentMostParticipation?.length ?
        <Grid item xs={12} sm={6} md={4}>
          <Box display="flex" height="100%">
            <Hidden smDown><Divider style={{ height: '80%', marginTop: '20%', marginRight: '8px' }} orientation="vertical" light /></Hidden>
            <Box width="100%" p={1}>
              <Typography variant="h6" className={classes.subTitle}>
                <Resource tag={"PageDetailChallenge::Departments"} />
              </Typography>
              <List>
                {tops?.departmentMostParticipation
                    .slice(0, 5)
                    .map((department, index) => {
                      return (
                        <ListUserItem
                          key={index}
                          rank={department?.position}
                          name={department?.title}
                          dontHaveAvatar
                          counter={department?.totalIdeas}
                          title={
                            department?.totalIdeas === 1 ?
                            <Resource tag={"PageDetailChallenge::Participation"} />
                            :
                            <Resource tag={"PageDetailChallenge::Participations"} />
                          }
                        />
                      );
                    })
                  }
              </List>
            </Box>
          </Box>
        </Grid>
        :
        ''
        }
        {tops?.countryMostParticipation?.length ?
        <Grid item xs={12} sm={6} md={4}>
          <Box display="flex" height="100%">
            <Hidden smDown><Divider style={{ height: '80%', marginTop: '20%', marginRight: '8px' }} orientation="vertical" light /></Hidden>
            <Box width="100%" p={1}>
              <Typography variant="h6" className={classes.subTitle}>
                <Resource tag={"PageDetailChallenge::Countries"} />
              </Typography>
              <List>
                {tops?.countryMostParticipation
                    .slice(0, 5)
                    .map((country, index) => {
                      return (
                        <ListUserItem
                          key={index}
                          dontHaveAvatar
                          rank={country?.position}
                          name={country?.title}
                          counter={country?.totalIdeas}
                          title={
                            country?.totalIdeas === 1 ?
                            <Resource tag={"PageDetailChallenge::Participation"} />
                            :
                            <Resource tag={"PageDetailChallenge::Participations"} />
                          }
                        />
                      );
                    })
                }
              </List>
            </Box>
          </Box>
        </Grid>
          :
          ''
        }
      </Grid>
    </>
  );
}

export default ListIdeaParticipants;