import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import React, { useState, useEffect } from "react";
import WalletPageSkeleton from "./WalletPage.Skeleton";
import PointsList from "containers/PointsList/PointsList";
import { ICultureState } from "redux/reducers/cultureReducer";
import WalletHeader from "containers/WalletHeader/WalletHeader";
import SelectFilter from "components/SelectFilter/SelectFilter";
import { getInvestments } from "redux/actions/InvestmentActions";
import { TabPanel, TabList, TabContext } from "@material-ui/lab";
import Resource, { resource } from "components/Resources/Resource";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import InvestmentList from "containers/InvestmentList/InvestmentList";
import MultiColorLine from "components/MultiColorLine/MultiColorLine";
import { AppBar, Tab, Box, Grid, Typography } from "@material-ui/core";
import {
  getWallet,
  IWalletGetAllAction,
  getWalletBalanceHistory,
  getWalletInvestments,
  IWalletBalanceHistoryGetAllAction,
  IWalletInvestmentsGetAllAction,
} from "redux/actions/WalletActions";
import {
  IWallet,
  IWalletBalanceHistory,
  IWalletInvestments,
} from "redux/reducers/walletReducer";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: "20px",
    },
    tabPanel: {
      padding: "12px 0",
    },
  })
);

const pagination = {
  current: 1,
  pageSize: 4, //TODO: Change for real options
  finished: false,
};

const status = {
  init: true,
  refreshControl: false, //TODO: Change for real options
  isActive: true,
};

const options = {
  applicationId: 2,
  dateFilter: 3,
  pageNumber: status.init || !status.isActive ? 1 : pagination.current + 1, //TODO: Change for real options
  pageSize: pagination.pageSize, //TODO: Change for real options
  culture: {},
};

interface IProps {
  getWallet: (options: object) => Promise<IWalletGetAllAction>;
  getWalletBalanceHistory: (
    options: object
  ) => Promise<IWalletBalanceHistoryGetAllAction>;
  getWalletInvestments: (
    options: object
  ) => Promise<IWalletInvestmentsGetAllAction>;
}
interface IStateProps {
  applicationId: any;
  wallet: IWallet;
  filterData: [];
  culture: ICultureState;
  balanceHistory: IWalletBalanceHistory;
  walletInvestments: IWalletInvestments;
}

function Wallet(props: IProps & IStateProps) {
  const [loading, setLoading] = useState(true);
  const [walletTab, setTab] = useState("1");

  const {
    wallet,
    balanceHistory,
    walletInvestments,
    getWallet,
    getWalletBalanceHistory,
    getWalletInvestments,
    culture,
  } = props;

  useEffect(() => {
    options.applicationId = props.applicationId;
    options.culture = props.culture;

    Promise.all(
      [
        getWallet(options),
        getInvestments(options),
        getWalletBalanceHistory(options),
        getWalletInvestments(options)
      ]
    ).finally(()=> {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=> {
    setTab(wallet.allowToSeeInvestment && walletInvestments.meta.total > 0 ? '1' : '2');

    // eslint-disable-next-line
  },[wallet.allowToSeeInvestment])

  const filterData = (value: string) => {
    const { getWalletBalanceHistory, getWalletInvestments } = props;
    options.dateFilter = Number(value);

    if (walletTab === "1") {
      getWalletInvestments(options);
    } else if (walletTab === "2") {
      getWalletBalanceHistory(options);
    }
  };
  
  const viewMoreActiveData = (pageSize: number) => {
    getWalletInvestments({
      ...options,
      pageSize,
    });
  };
  
  const viewMoreBalanceData = (pageSize: number) => {
    getWalletBalanceHistory({
      ...options,
      pageSize,
    });
  };

  const classes = useStyles();

  const handleChange = (event: any, newValue: string) => {
    setTab(newValue);
  };

  const balanceData = balanceHistory?.result?.map((results) => {
    return results.balance;
  });

  balanceData?.reverse();

  const investData = walletInvestments?.result?.map((walletInvestment) => {
    return walletInvestment.currentInvestmentValue;
  });

  investData?.reverse();

  const tabs = [
    <Tab
      key={498838}
      label={resource(props.culture, "PageWallet::InvestmentTabTitle")}
      value="1"
    />,
    <Tab
      key={498839}
      label={resource(props.culture, "PageWallet::HistoryTabTitle")}
      value="2"
    />,
  ];

  const tabsContent = [
    <TabPanel key={498840} className={classes.tabPanel} value={"1"}>
      {walletInvestments.meta.total > 0 ? (
        <>
          <InvestmentList
            viewMoreData={viewMoreActiveData}
            walletInvestments={walletInvestments}
          />
        </>
      ) : (
        <Box paddingTop={2}>
          <Typography>
            <Resource tag="PageWallet::NoDataAvailable" />
          </Typography>
        </Box>
      )}
    </TabPanel>
    ,
    <TabPanel key={498841} className={classes.tabPanel} value={"2"}>
      {balanceHistory.meta.total > 0 ? (
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SelectFilter
              cultureState={props.culture}
              filterAction={filterData}
            ></SelectFilter>
          </div>
          <PointsList
            balance={balanceHistory}
            viewMoreData={viewMoreBalanceData}
            cultureState={culture}
          />
          <Box marginTop={2}>
            <MultiColorLine
              key={15161}
              title={resource(props.culture, "PageWallet::Evolution_Available")}
              data={balanceData}
            />
          </Box>
        </>
      ) : (
        <Box paddingTop={2}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SelectFilter
              cultureState={props.culture}
              filterAction={filterData}
            ></SelectFilter>
          </div>
          <Typography>
            <Resource tag="PageWallet::NoDataAvailable" />
          </Typography>
        </Box>
      )}
    </TabPanel>,
  ];

  return (
    <>
      {loading ? (
        <WalletPageSkeleton />
      ) : (
        <Box className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <WalletHeader
                culture={culture}
                wallet={wallet}
                walletInvestments={walletInvestments}
                cultureTag={culture.culture.cultureTag}
                allowToSeeInvestment={wallet?.allowToSeeInvestment}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: 32 }}
            >
              <TabContext value={walletTab}>
                <AppBar position="static" elevation={0}>
                  <TabList onChange={handleChange}>
                    {
                      wallet?.allowToSeeInvestment && walletInvestments.meta.total > 0
                      ? tabs
                      : [tabs[1]]
                    }
                  </TabList>
                </AppBar>
                {
                  wallet?.allowToSeeInvestment && walletInvestments.meta.total > 0
                  ? tabsContent
                  : [tabsContent[1]]
                }
              </TabContext>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IProps => {
  return {
    getWallet: (parameters: object) => dispatch(getWallet(parameters)),
    getWalletBalanceHistory: (parameters: object) =>
      dispatch(getWalletBalanceHistory(parameters)),
    getWalletInvestments: (paramenters: object) =>
      dispatch(getWalletInvestments(paramenters)),
  };
};
const mapStateToProps = (store: IAppState) => {
  return {
    wallet: store.walletState.wallet,
    applicationId: store.authState.auth.applicationId,
    balanceHistory: store.walletState.balanceHistory,
    walletInvestments: store.walletState.walletInvestments,
    culture: store.cultureState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
