import { toast } from "react-toastify";
import { ThunkAction } from "redux-thunk";
import reactNewApp from "utils/reactNewApp";
import { ActionCreator, Dispatch } from "redux";
import { treatError } from "../../utils/treatError";
import { api_general, api_v2 } from "../../config/api";
import { resource } from "components/Resources/Resource";
import {
  IWallet,
  IWalletBalanceHistory,
  IWalletInvestments,
  IWalletState,
} from "../reducers/walletReducer";

const basePath = "wallets";

export enum WalletActionsTypes {
  GET_WALLET = "GET_WALLET",
  GET_WALLET_BALANCEHISTORY = "GET_WALLET_BALANCEHISTORY",
  GET_WALLET_INVESTMENTS = "GET_WALLET_INVESTMENTS",
  SHOW_MORE = "SHOW_MORE",
}

export interface IWalletGetAllAction {
  type: WalletActionsTypes.GET_WALLET;
  wallet: IWallet;
}

export interface IWalletBalanceHistoryGetAllAction {
  type: WalletActionsTypes.GET_WALLET_BALANCEHISTORY;
  balanceHistory: IWalletBalanceHistory;
  points: number;
}

export interface IWalletInvestmentsGetAllAction {
  type: WalletActionsTypes.GET_WALLET_INVESTMENTS;
  walletInvestments: IWalletInvestments;
  points: number;
}

export interface IBalanceShowMoreAction {
  type: WalletActionsTypes.SHOW_MORE;
}

export type WalletActions =
  | IWalletGetAllAction
  | IWalletBalanceHistoryGetAllAction
  | IWalletInvestmentsGetAllAction
  | IBalanceShowMoreAction;

export const showMoreBalance: ActionCreator<IBalanceShowMoreAction> = () => ({
  type: WalletActionsTypes.SHOW_MORE,
});

export const getWallet: ActionCreator<ThunkAction<
  Promise<any>,
  IWalletState,
  null,
  IWalletGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";

      params_qs = `?${params.join("&")}`;
      if (reactNewApp) {
        const response = await api_general.get(`${basePath}${params_qs}`, {});

        dispatch({
          wallet: {
            allowToSeeInvestment: response.data.allowToSeeInvestment,
            investments: response.data.investments,
            totalAvailable: response.data.totalAvailable,
            totalInvested: response.data.totalInvested,
            totalInvestedCurrentValue: response.data.totalInvestedCurrentValue,
          },
          type: WalletActionsTypes.GET_WALLET,
        });
      } else {
        const response = await api_v2.get(`${basePath}${params_qs}`, {});

        dispatch({
          wallet: {
            allowToSeeInvestment: response.data.allowToSeeInvestment,
            investments: response.data.investments,
            totalAvailable: response.data.totalAvailable,
            totalInvested: response.data.totalInvested,
            totalInvestedCurrentValue: response.data.totalInvestedCurrentValue,
          },
          type: WalletActionsTypes.GET_WALLET,
        });
      }
    } catch (err) {
      treatError(options.culture, "PageWallet::Header", err);
    }
  };
};

export const getWalletBalanceHistory: ActionCreator<ThunkAction<
  Promise<any>,
  IWalletState,
  null,
  IWalletBalanceHistoryGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("applicationId")) {
        params.push(`applicationId=${options.applicationId}`);
      }

      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      params_qs = `?${params.join("&")}`;
      if (reactNewApp) {
        const response = await api_general.get(
          `${basePath}/balancehistory${params_qs}`,
          {}
        );
        dispatch({
          balanceHistory: response.data.balance,
          points: response.data.points,
          type: WalletActionsTypes.GET_WALLET_BALANCEHISTORY,
        });
      } else {
        const response = await api_v2.get(
          `${basePath}/balancehistory${params_qs}`,
          {}
        );
        dispatch({
          balanceHistory: response.data.balance,
          points: response.data.points,
          type: WalletActionsTypes.GET_WALLET_BALANCEHISTORY,
        });
      }
    } catch (err) {
      treatError(options.culture, "PageWallet::Header", err);
    }
  };
};

export const getWalletInvestments: ActionCreator<ThunkAction<
  Promise<any>,
  IWalletState,
  null,
  IWalletInvestmentsGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("applicationId")) {
        params.push(`applicationId=${options.applicationId}`);
      }

      if (options.hasOwnProperty("dateFilter")) {
        params.push(`dateFilter=${options.dateFilter}`);
      }

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      params_qs = `?${params.join("&")}`;
      if (reactNewApp) {
        const response = await api_general.get(
          `${basePath}/investments${params_qs}`,
          {}
        );

        dispatch({
          walletInvestments: response.data,
          points: response.data.points,
          type: WalletActionsTypes.GET_WALLET_INVESTMENTS,
        });
      } else {
        const response = await api_v2.get(
          `${basePath}/investments${params_qs}`,
          {}
        );

        dispatch({
          walletInvestments: response.data,
          points: response.data.points,
          type: WalletActionsTypes.GET_WALLET_INVESTMENTS,
        });
      }
    } catch (err) {
      treatError(options.culture, "PageWallet::Header", err);
    }
  };
};

export const postSellInvestments: ActionCreator<ThunkAction<
  Promise<any>,
  IWalletState,
  null,
  IWalletGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const params = {
        ideaId: options.ideaId,
        steps: options.steps,
        workspaceId: options.workspaceId,
        applicationId: options.applicationId,
      };

      reactNewApp
        ? await api_general
            .post(`${basePath}/investment/sell`, params)
            .then(async () => {
              toast.success(
                resource(options.culture, "PageWallet::SuccessfullySold")
              );

              let paramsInvestments = [];
              let params_qs = "";

              paramsInvestments.push(`pageNumber=${options.pageNumber}`);
              paramsInvestments.push(`pageSize=${options.pageSize}`);

              if (paramsInvestments.length) {
                params_qs = `?${paramsInvestments.join("&")}`;
              }
              const responseInvestments = await api_general.get(
                `${basePath}${params_qs}`,
                {}
              );
              dispatch({
                wallet: {
                  totalAvailable: responseInvestments.data.totalAvailable,
                  totalInvested: responseInvestments.data.totalInvested,
                },
                type: WalletActionsTypes.GET_WALLET,
              });
            })
        : await api_v2
            .post(`${basePath}/investment/sell`, params)
            .then(async () => {
              toast.success(
                resource(options.culture, "PageWallet::SuccessfullySold")
              );

              let paramsInvestments = [];
              let params_qs = "";

              paramsInvestments.push(`pageNumber=${options.pageNumber}`);
              paramsInvestments.push(`pageSize=${options.pageSize}`);

              if (paramsInvestments.length) {
                params_qs = `?${paramsInvestments.join("&")}`;
              }
              const responseInvestments = await api_v2.get(
                `${basePath}${params_qs}`,
                {}
              );
              dispatch({
                wallet: {
                  totalAvailable: responseInvestments.data.totalAvailable,
                  totalInvested: responseInvestments.data.totalInvested,
                },
                type: WalletActionsTypes.GET_WALLET,
              });
            });
    } catch (err) {
      treatError(options.culture, "PageWallet::Header", err);
    }
  };
};
