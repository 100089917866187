import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  CircularProgress,
} from "@material-ui/core";
import FormTag from "../../../components/FormTag/FormTag";
import Resource, { resource } from "../../../components/Resources/Resource";
import CoAuthors from "../../../components/CoAuthors/CoAuthors";
import { IIdeaDetails, IResultDrafts } from "redux/types/ideas";
import { ICultureState } from "../../../redux/reducers/cultureReducer";
import TextEditorInput from "../../../components/GenericField/TextEditorInput/TextEditorInput";
import { IIdeaForm } from "redux/types/challenges";
import { IUsers } from "../../../redux/reducers/usersReducers";
import GenericField from "../../../components/GenericField/GenericField";
import sanitizeHtml from "sanitize-html";
import Truncate from "react-truncate";
import CloseIcon from "@material-ui/icons/Close";
import DescriptionIcon from "@material-ui/icons/Description";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperIdeaSimilar: {
      display: "flex",
      backgroundColor: theme.palette.primary.light,
      minHeight: 150,
      marginTop: "80px",
    },
    dropzone: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      margin: 0,
      padding: 24,
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 8,
      outline: 0,
      minHeight: 174,
      marginTop: 8,
    },
    thumbsContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 12,
    },
    thumb: {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 16,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: "border-box",
      position: "relative",
      backgroundColor: "white",
      cursor: "pointer",
    },
    thumbInner: {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
      width: "100%",
    },
    img: {
      display: "block",
      width: "auto",
      height: "100%",
    },
    closeButton: {
      position: "absolute",
      top: -12,
      right: -12,
      backgroundColor: "white",
      padding: 1,
      border: `1px solid ${theme.palette.grey[500]}`,
    },
  })
);

interface IProps {
  ideaDetail?: IIdeaDetails | IResultDrafts;
  culture: ICultureState;
  ideaForm: IIdeaForm;
  getIdeasForm: any;
  handleCancel: any;
  userList: IUsers[];
  mode: "idea" | "edit" | "draft";
  handleSaveDraft?: any;
  handleSubmitAction: any;
}

interface IStateProps {
  challenge: any;
  setChallenge: any;
}

function IdeaForm(props: IProps & IStateProps) {
  const {
    ideaDetail,
    ideaForm,
    challenge,
    handleCancel,
    mode,
    handleSaveDraft,
    handleSubmitAction,
  } = props;
  const formRef = useRef<HTMLFormElement | null>(null);
  const customFields = ideaForm?.customFields;
  const requiredForms = customFields?.filter((x) => x.isMandatory)!;
  const requiredFormLength = requiredForms ? requiredForms?.length : 2;
  const [completedFields, setCompletedFields] = useState(0);
  const [formReady, setFormReady] = useState(true);
  const [coAuthors, setCoauthors] = useState([]);
  const [titleLength, setTitleLength] = useState(0);
  const [files, setFiles] = useState<any>(ideaDetail?.files || []);
  const [postLoading, setPostLoading] = useState(false);
  const [postDraftLoading, setPostDraftLoading] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    fCompletedFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
/*
  useEffect(() => {
    console.log("completedFields", completedFields, "requiredFormLength", requiredFormLength)
    setFormReady(completedFields >= requiredFormLength);
  }, [completedFields, requiredFormLength]);
*/
  function fCompletedFields() {
    const form = formRef.current!;
    const formData = new FormData(form);

    const { completedFields } = handleFormData(formData);
    setCompletedFields(completedFields);
  }

  const ideaDescriptionProps = {
    columnName: "Description",
    isMandatory: true,
    typeId: 9,
    title: resource(props.culture, "PageEditIdea::IdeaDescription"),
    isEditable: true,
    maxLength: 3000,
  };

  function handleFormData(data: FormData) {
    const { customFields, initialIdeas } = ideaForm;

    let customFieldsValues = {};
    customFields.map((x) => {
      customFieldsValues = {
        ...customFieldsValues,
        [x.columnName]: sanitizeHtml(data.get(x.columnName)?.toString()!),
      };
      return x;
    });

    const customFieldsFd = customFields.map((x) => ({
      Key: x.columnName,
      Value: sanitizeHtml(data.get(x.columnName)?.toString()!),
    }));

    const requiredForms = customFields?.filter((x) => x.isMandatory);
    const requiredInputsNames = requiredForms?.map((x) =>
      data.get(x.columnName)
    )!;

    const elemArray = requiredInputsNames
      ? [
          data.get("Title"),
          data.get("Description") === "<p><br></p>"
            ? ""
            : data.get("Description"),
          ...requiredInputsNames,
        ]
      : [data.get("Title"), data.get("Description")];

    const ideaTagsArray = sanitizeHtml(
      data.get("tags-value")?.toString()!
    ) as string;
    const tags = ideaTagsArray ? ideaTagsArray.split(";") : [];

    let newValues = {
      title: sanitizeHtml(data.get("Title")?.toString()!),
      description: sanitizeHtml(data.get("Description")?.toString()!, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "figure",
          "img",
        ]),
        allowedAttributes: {
          span: ["style"],
          a: ["href"],
          img: ["src"],
        },
        selfClosing: [
          "img",
          "br",
          "hr",
          "area",
          "base",
          "basefont",
          "link",
          "meta",
        ],
        allowedSchemes: ["data", "https", "http"],
      }),
      tags: tags,
      coAuthors: coAuthors,
    };

    const params = {
      ...newValues,
      customFields: { ...customFieldsValues },
      files,
      challengeId: challenge?.challengeId,
      initialStateId: Object.keys(initialIdeas)[0],
    };

    const completedFields = elemArray.filter((x) => x).length;
    return { params, completedFields, customFieldsFd };
  }

  const saveDraft = () => {
    const formData = new FormData(formRef.current!);
    const { params } = handleFormData(formData);
    setPostDraftLoading(true);
    let j = 0;
    for (let i = 0; i < files.length; i++) {
      if (files?.[i]?.fileName) {
        formData.append(`FileIds[${j}]`, files[i].fileName);
        j++;
      } else {
        formData.append(`Files`, files[i]);
      }
    }

    // @ts-ignore
    const coAuthors = formData.get("CoAuthors").split(";");
    // @ts-ignore
    const tags = formData.get("Tags").split(";");

    formData.delete("CoAuthors");
    formData.delete("Tags");

    coAuthors.forEach((coAuthor: string, index: number) => {
      if (coAuthor) formData.append(`CoAuthors[${index}]`, coAuthor);
    });

    tags.forEach((tag: string, index: number) => {
      if (tag) formData.append(`Tags[${index}]`, tag);
    });

    const cfEntries = Object.entries(params.customFields);
    cfEntries.forEach((cf, index) => {
      formData.delete(cf[0]);
      if (cf[1]) {
        formData.append(`CustomFields[${index}].Key`, cf[0]);
        //@ts-ignore
        formData.append(`CustomFields[${index}].Value`, cf[1]);
      }
    });
    handleSaveDraft(formData);
  };

  const handleSubmit = (form: any) => {
    form.preventDefault();
    setPostLoading(true);
    const formData = new FormData(form.target);
    const { params } = handleFormData(formData);

    let j = 0;
    for (let i = 0; i < files.length; i++) {
      if (files?.[i]?.fileName) {
        formData.append(`FileIds[${j}]`, files[i].fileName);
        j++;
      } else {
        formData.append(`Files`, files[i]);
      }
    }

    // @ts-ignore
    const coAuthors = formData.get("CoAuthors")?.split(";");
    // @ts-ignore
    const tags = formData.get("Tags")?.split(";");

    formData.delete("CoAuthors");
    formData.delete("Tags");

    coAuthors?.forEach((coAuthor: string, index: number) => {
      if (coAuthor) formData.append(`CoAuthors[${index}]`, coAuthor);
    });

    tags?.forEach((tag: string, index: number) => {
      if (tag) formData.append(`Tags[${index}]`, tag);
    });

    const cfEntries = Object.entries(params.customFields);
    cfEntries?.forEach((cf, index) => {
      formData.delete(cf[0]);
      if (cf[1]) {
        formData.append(`CustomFields[${index}].Key`, cf[0]);
        //@ts-ignore
        formData.append(`CustomFields[${index}].Value`, cf[1]);
      }
    });
    handleSubmitAction(formData);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, video/*, audio/*, .pdf, .doc, .docx, .pptx, .xlsx, .csv",
    //@ts-ignore
    maxFiles: 5,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length + files.length <= 5) {
        setFiles(files.concat(acceptedFiles));
      } else {
        toast.error(resource(props.culture, "PageNewIdea::MaximumLimit"));
      }
    },
  });

  const deleteAttachment = (file: any) => {
    if (file?.fileName) {
      //@ts-ignore
      setFiles(files.filter((x: any) => x.fileName !== file.fileName));
    } else {
      setFiles(files.filter((x: any) => x.name !== file.name));
    }
  };

  const filesView = files.map((file: any) =>
    file?.fileName
      ? file
      : { name: file.name, fileUrl: URL.createObjectURL(file) }
  );

  return (
    <form
      key={ideaDetail?.ideaId}
      ref={formRef}
      className="CreateIdea-Form"
      onSubmit={handleSubmit}
    >
      <Box
        mt={2}
        mb={2}
        display="flex"
        justifyContent="flex-end"
        position={matches ? "unset" : "absolute"}
        top="0%"
        right="7%"
      >
        <Box mr={2}>
          <Button onClick={handleCancel} color="primary">
            {resource(props.culture, "PageNewIdea::Cancel")}
          </Button>
        </Box>
        <Box mr={1}>
          {mode === "idea" && (
            <Button
              id="IdeaForm_SaveDraft"
              color={"primary"}
              variant="outlined"
              type="button"
              onClick={saveDraft}
              disabled={postDraftLoading}
              endIcon={
                postDraftLoading && (
                  <Box display="flex" alignItems="center">
                    <CircularProgress size="14px" />
                  </Box>
                )
              }
              size="small"
            >
              <Resource tag="PageNewIdea::Button_SaveDraft" />
            </Button>
          )}
        </Box>
        <Box>
          {formReady && (
            <Button
              id="IdeaForm_Save"
              type="submit"
              variant="contained"
              color="primary"
              disabled={postLoading}
              endIcon={
                postLoading && (
                  <Box display="flex" alignItems="center">
                    <CircularProgress size="14px" />
                  </Box>
                )
              }
              size="small"
            >
              {resource(props.culture, "PageEditIdea::Save")}
            </Button>
          )}
        </Box>
      </Box>

      <Box>
        <TextField
          id="Title"
          label={resource(props.culture, "PageNewIdea::Idea_Title")}
          required
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
            style: {
              fontWeight: "bold",
              fontSize: "1.05rem",
              color: theme.palette.text.secondary,
            },
          }}
          onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
          inputProps={{ maxLength: 100, padding: "15px 0 7px" }}
          defaultValue={ideaDetail?.title}
          onChange={(e) => {
            setTitleLength(e.target.value.length);
            fCompletedFields();
          }}
          name="Title"
          helperText={
            <>
              <Resource tag="ManageGroups::AvailableCharacters" />:{" "}
              {100 - titleLength}{" "}
            </>
          }
          FormHelperTextProps={{
            style: { textAlign: "right" },
          }}
        />
      </Box>
      <Box mb={8} mt={8}>
        <TextEditorInput
          value={ideaDetail?.description}
          culture={props.culture}
          propsI={ideaDescriptionProps}
          handler={fCompletedFields}
        />
      </Box>
      <Box mb={8} mt={8}>
        <FormTag
          culture={props.culture}
          tags={ideaDetail && "tags" in ideaDetail ? ideaDetail?.tags : []}
          handler={fCompletedFields}
        />
      </Box>
      {ideaForm?.coAuthorNumber > 1 ? (
        <Box mb={8} mt={8}>
          <CoAuthors
            setCoauthors={setCoauthors}
            culture={props.culture}
            coAuthors={
              ideaDetail && "coAuthors" in ideaDetail
                ? ideaDetail?.coAuthors
                : []
            }
            userList={props?.userList}
            maxCoAuthors={ideaForm?.coAuthorNumber}
          />
        </Box>
      ) : (
        ""
      )}

      <div>
        <Typography
          color="textSecondary"
          variant="caption"
          style={{
            fontWeight: 600,
            marginBottom: 8,
            textTransform: "uppercase",
          }}
        >
          <Resource tag="PageNewIdea::Attachments" />
        </Typography>
        <div
          {...getRootProps({ className: "dropzone" })}
          className={classes.dropzone}
        >
          <input {...getInputProps()} />
          {!files.length && (
            <Box
              width={"100%"}
              textAlign="center"
              color={theme.palette.text.secondary}
            >
              <Resource tag="PageNewIdea::DragAndDrop" />
            </Box>
          )}

          <div className={classes.thumbsContainer}>
            {filesView.map((file: any, index: number) => {
              const fileType = file?.name
                ? file?.name.split(".")[1]
                : file?.fileName.split(".")[1];
              const isImg =
                fileType === "jpg" ||
                fileType === "bmp" ||
                fileType === "png" ||
                fileType === "jpeg" ||
                fileType === "gif" ||
                fileType === "webp" ||
                fileType === "svg";

              return (
                <div
                  className={classes.thumb}
                  key={file.fileName || index}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(file?.fileUrl, "_blank");
                  }}
                >
                  <IconButton
                    onFocus={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteAttachment(file);
                    }}
                    className={classes.closeButton}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  <div className={classes.thumbInner}>
                    {isImg ? (
                      <img
                        src={file?.fileUrl}
                        alt={file?.fileName}
                        className={classes.img}
                      />
                    ) : (
                      <Box
                        textAlign="center"
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box>
                          <DescriptionIcon fontSize="large" />
                        </Box>
                        <Typography style={{ width: "100%" }}>
                          <Truncate maxLength={10} ellipsis={"..."}>
                            {file?.name || file?.fileName}
                          </Truncate>
                        </Typography>
                      </Box>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {mode === "edit" && ideaDetail && "customFields" in ideaDetail
        ? ideaDetail.customFields &&
          ideaDetail.customFields.map((x) => (
            <Box mb={8} mt={8} key={x.customField.columnName}>
              <GenericField
                value={x.value}
                input={x.customField}
                handler={fCompletedFields}
              ></GenericField>
            </Box>
          ))
        : customFields &&
          customFields.map((x) => (
            <Box mb={8} mt={8} key={x.columnName}>
              <GenericField
                value=""
                input={x}
                handler={fCompletedFields}
              ></GenericField>
            </Box>
          ))}
    </form>
  );
}

export default IdeaForm;
