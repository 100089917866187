import React from "react";
import { Grid, Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

interface IProps {
  rowHeight: number;
  col: number;
  row: number;
}

export default function SkeletonGrid(props: IProps) {
  const RenderGrid = () => {
    let xs:
      | "boolean"
      | 1
      | 4
      | 12
      | "auto"
      | 2
      | 3
      | 5
      | 6
      | 7
      | 8
      | 9
      | 10
      | 11
      | "undefined";

    switch (props?.col) {
      case 2:
        xs = 6;
        break;

      case 3:
        xs = 4;
        break;

      case 4:
        xs = 3;
        break;

      case 6:
        xs = 2;
        break;

      default:
        xs = 12;
        break;
    }

    let gridChildren = [];

    for (let index = 0; index < props?.row; index++) {
      for (let index = 0; index < props?.col; index++) {
        gridChildren?.push(
          <Grid item xs={xs} key={Math?.random()}>
            <Skeleton variant="rect" height={props?.rowHeight} />
          </Grid>
        );
      }
    }

    return (
      <Box mb={2}>
        <Grid item container spacing={3}>
          {gridChildren}
        </Grid>
      </Box>
    );
  };

  return <>{RenderGrid()}</>;
}
