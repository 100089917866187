import { Box, createStyles, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import "./ToolTip.css";

/* Exemplo de uso
    <ToolTip title="Lorem Ipsum?">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit.
        Natus voluptatibus maiores aliquam accusantium neque, alias minus unde laboriosam.
        Iusto eum repudiandae dignissimos
        Lorem ipsum, dolor sit amet consectetur adipisicing elit.
        Natus voluptatibus maiores aliquam accusantium neque, alias minus unde laboriosam.
        Iusto eum repudiandae dignissimos
        Lorem ipsum, dolor sit amet consectetur adipisicing elit.
        Natus voluptatibus maiores aliquam accusantium neque, alias minus unde laboriosam.
        Iusto eum repudiandae dignissimos
    </ToolTip>
*/

interface IProps {
  title?: string | JSX.Element;
  width?: number
  children?: any;
  error?:boolean;
  isLeft?:boolean;
  top?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: (props: any) => ({
      
        display: "inline-block",
        lineHeight: 1.00,
        "& h5": {
          fontFamily: "monospace",
          fontSize: " x-small",
          color: "white",

          display: "flex",
          flex: 1,
          flexDirection: "column",
          margin: 0,

          width: "14px",
          height: "14px",
          borderRadius: "100%",
          backgroundColor: props.error ? "#FB0D1B" : theme.palette.primary.main,

          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "lowercase",
          whiteSpace: "normal",
          wordWrap: "break-word",
        },

        "&:hover ": {
          cursor: "pointer",
          "& span": {
            visibility: "visible",
            minWidth: props.width || "200px",
            width: "auto",
            maxWidth: "600px",
            height: "auto",
          },
        }
    }),

    tooltipText: {
      visibility: "hidden",
      backgroundColor: "white",
      marginLeft: "-3px",
      fontSize: "12px",
      color:  props => props.error ? "#FB0D1B" : theme.palette.text.primary,
      textAlign: "left",
      minWidth: 0,
      width: 0,
      height: 0,
      overflow: "hidden",
      padding: "15px 20px",

      borderRadius: "6px",
      left: props => props.isLeft ? "-450px" : "25px",
      top: props => props.isLeft ? "0px" : props.top ?? '-20px',
      boxShadow: "0px 2px 10px -1px rgba(0, 0, 0, 0.2)",
      position: "absolute",
      zIndex: 6,

      "&:after": {
        content: " ",
        position: "absolute",
        top: "10%",
        right: "100%",
        /* To the left of the tooltip */
        borderWidth: "7px",
        borderStyle: "solid",
        borderColor: "transparent white transparent transparent",
      },
    },
  })
);

function ToolTip(props: IProps) {
  const classes = useStyles(props);

  return (
    <Box className={classes.tooltip}>
      <h5>i</h5>
      <Box position="relative">
        <span className={classes.tooltipText}>
          {props.title && 
            <Typography>
              <strong>{props.title}</strong>
              <br />
            </Typography>
          }
          <p>{props.children}</p>
        </span>
      </Box>
    </Box>
  );
}

export default ToolTip;
