import "./LoginPage.scss";
import { useState } from "react";
import { connect } from "react-redux";
import newApp from "utils/reactNewApp";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { api_general } from "config/api";
import openSocket from "socket.io-client";
import Links from "@material-ui/core/Link";
import { ThunkDispatch } from "redux-thunk";
import history from "../../../routes/history";
import Button from "@material-ui/core/Button";
import { Fade, Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { IAppState } from "../../../redux/store/Store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IProfileData } from "redux/reducers/profileReducer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { IAuthUser } from "../../../redux/reducers/authReducer";
import { ITenant } from "../../../redux/reducers/tenantReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ICultureState } from "../../../redux/reducers/cultureReducer";
import Resource, { resource } from "../../../components/Resources/Resource";
import { ArrowForward, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Input,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  Box,
  Typography,
  Link,
} from "@material-ui/core";
import {
  getProfileData,
  IProfileGetDataAction,
} from "redux/actions/ProfileActions";
import {
  doLogin,
  IAuthLoginAction,
  IAuthLoginEnviromentsAction,
  doLoginSSO,
  doEnviroments,
} from "../../../redux/actions/AuthAction";
import {
  getTenantConfigurations,
  ITenantConfigurationAction,
  setActiveTenant,
  IActiveTenantAction,
} from "../../../redux/actions/tenantAction";
import {
  IPasswordUpdateAction,
  updatePassword,
  recoverPassword,
  IPasswordRecoverAction,
  validateRecoverCode,
  IPasswordVerifyAction,
} from "../../../redux/actions/PasswordActions";

const SOCKET_ENDPOINT = "https://socket.exago.com/";
const SSO_EVENT = "message";
const basePath = "tenantconfigurations";

interface IProps {
  doEnviroment: (
    email: string,
    password: string,
    captcha: string
  ) => Promise<IAuthLoginEnviromentsAction>;
  doLogin: (
    environmentType: number,
    tenantId: number,
    username: string,
    password: string,
    culture: object,
    captcha: string
  ) => Promise<IAuthLoginAction>;
  doLoginSSO: (response: IAuthUser) => Promise<IAuthLoginAction>;
  getTenantConfigurations: (
    email: string
  ) => Promise<ITenantConfigurationAction>;
  updatePassword: (options: object) => Promise<IPasswordUpdateAction>;
  recoverPassword: (options: object) => Promise<IPasswordRecoverAction>;
  validateRecoverCode: (options: object) => Promise<IPasswordVerifyAction>;
  setActiveTenant: (activeTenant: ITenant) => Promise<IActiveTenantAction>;
  getProfileData: () => Promise<IProfileGetDataAction>;
}

interface IStateProps {
  activeTenant: ITenant;
  cultureState: ICultureState;
  expiredLogin: boolean;
  profileData: IProfileData;
}

interface IState {
  email: string;
  password: string;
  tenantId: number;
  submitted: Boolean;
  tenantConfigurations: Boolean;
  emailVerifying: Boolean;
  loadingSubmit: Boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
    containerInputs: {
      position: "relative",
      minHeight: "320px",
    },
    textField: {
      width: "100%",
      "& label": {
        textTransform: "uppercase",
      },
    },
    boxBtnConnect: {
      display: "flex",
      position: "absolute",
      bottom: "0",
      right: "0",
      flexDirection: "row-reverse",
      [theme.breakpoints.only("sm")]: {
        marginTop: "5px",
      },
      [theme.breakpoints.only("md")]: {
        marginTop: "5px",
      },
    },
    btnConnect: {
      zIndex: 99999,
      [theme.breakpoints.only("sm")]: {
        padding: "0 15px",
      },
    },
    centerLoading: {
      margin: "0px auto",
      height: "80vh",
    },
  })
);

function LogIn(props: IProps & IStateProps & IState) {
  const classes = useStyles();
  const {
    activeTenant,
    setActiveTenant,
    getTenantConfigurations,
    expiredLogin,
    getProfileData,
  } = props;
  const { cultureState } = props;

  const [email, setEmail] = useState("");
  //const [emailVerifying, setEmailVerifying] = useState(false)
  const [emailError, setEmailError] = useState(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  //const [passwordVerifying, setPasswordVerifying] = useState(false)
  const [passwordError, setPasswordError] = useState(false);

  const [isTenantSelected, setIsTenantSelected] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isUserMultiTenant, setIsUserMultiTenant] = useState(false);
  const [tenants, setTenants] = useState<any>([]);
  const [showInputPassword, setShowInputPassword] = useState(true);
  const [showInputEnviroment, setShowInputEnviroment] = useState(false);
  const [showButtonSSO, setShowButtonSSO] = useState(false);
  const [showButtonValidEmail, setShowButtonValidEmail] = useState(true);
  const [, setActiveTenantUser] = useState(activeTenant);
  const [msgLogOut, setMsgLogOut] = useState("");
  const [msgError, setMsgError] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();
  let captcha: string = "";
  const [urlLocation] = useState(window.location.host.toString());

  useEffect(() => {
    getTenantByEndpoint();

    //Remove the FreshDesk button
    const FreskDesk = document.getElementById("freshworks-container");
    if (FreskDesk) {
      FreskDesk.remove();
    }

    // if ((urlLocation.indexOf('localhost') === -1) && urlLocation !== "dev.app.exagomarkets.com" && urlLocation !== "uat.app.exagomarkets.com" && urlLocation !== "app.exago.com") {
    //   getTenantByEndpoint()
    //   setIsCustomUrl(true)
    // }
    if (tenants?.length >= 1) {
      setIsUserMultiTenant(true);
      setActiveTenant(tenants?.[0]);
      setIsTenantSelected(true);
    } else {
      setIsUserMultiTenant(false);
      setActiveTenant(tenants?.[0]);
      //setActiveTenant(activeTenant)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);

  function getTenantByEndpoint() {
    api_general
      .get(`${basePath}/endpoint?endpoint=${urlLocation}`)
      .then((response) => {
        setActiveTenant(response.data);
        if (response.data.hasSSO) {
          window.location.href = response.data.urlSSO;
          return;
        } else {
          history.push("/log-in");
        }
      });
  }

  function handleEmailChange(e: any) {
    const { value } = e.target;
    setEmail(value);
    setShowButtonValidEmail(true);
    setShowInputPassword(true);
    setShowButtonSSO(false);
    setShowInputEnviroment(false);
    setPassword("");
    setMsgError("");
    if (!value) {
      getTenantConfigurations("");
      setEmailError(false);
      setTenants("");
      setShowButtonSSO(false);
      setShowInputEnviroment(false);
    }
  }

  function handlePasswordChange(e: any) {
    const { value } = e.target;
    setPassword(value);
    setMsgError("");
    setShowButtonValidEmail(true);

    if (!value) {
      getTenantConfigurations("");
      setPasswordError(false);
    }
  }

  function handleTenantUpdate(e: any, activeTenant: any) {
    if (activeTenant) {
      setIsTenantSelected(true);
      setActiveTenant(activeTenant);
    }
  }
  function submitAction(e: any) {
    if (e.key === 'Enter') {
      handleTenantSubmit(e);
    }
  }

  async function HandleLoginSubmit(e: any) {
    e.preventDefault();
    setLoadingSubmit(true);

    executeRecaptcha &&
      (await executeRecaptcha("Login").then((response) => {
        captcha = response;
      }));

    const atSignIndex = email.indexOf("@");
    const dotIndex = email.indexOf(".", atSignIndex);

    if (atSignIndex === -1 || dotIndex === -1) {
      //TODO: resource(,"Errors::Error_BadEmail")
      setMsgError(resource(cultureState, "Errors::EmailInvalid"));
    }
    if (email && password) {
      props
        ?.doLogin(
          props?.activeTenant?.environmentType,
          props?.activeTenant?.tenantId,
          email,
          password,
          cultureState,
          captcha
        )
        .then(
          async (response) => {
            //Check if it´s logged with success
            // if (!api_v2.defaults.headers.common["Authorization"]){
            //   setLoadingSubmit(false)
            // }else {
            await getProfileData().then(() => {
              setLoadingSubmit(false);
              if (
                response?.auth?.allowCreateWorkspace &&
                response?.auth?.hasWorkspaceConfigured === false
              ) {
                history.push("/setenvironment");
              } else if (response?.auth?.isProfileSetted === false) {
                history.push("/setprofile");
              } else {
                history.push("/");
              }
            });
            // }
          },
          (err) => {

            setMsgError(
              resource(
                cultureState,
                err?.error?.response?.data
                  ? err?.error?.response?.data.replace(".", "::")
                  : "PageLogin::UnexpectedError"
              )
            );
            setLoadingSubmit(false);
          }
        );
    }
  }

  async function handleTenantSubmit(e: any) {
    e.preventDefault();
    setLoadingSubmit(true);
    setShowButtonValidEmail(false);

    executeRecaptcha &&
      (await executeRecaptcha("Login").then((response) => {
        captcha = response;
      }));

    const atSignIndex = email.indexOf("@");
    const dotIndex = email.indexOf(".", atSignIndex);

    if (atSignIndex === -1 || dotIndex === -1) {
      setMsgError(resource(cultureState, "Errors::EmailInvalid"));
      setLoadingSubmit(false);
    }

    setEmailError(false);
    setPasswordError(false);

    props.doEnviroment(email, password, captcha).then(
      (response) => {
        setTenants(response.activeTenant);
        setActiveTenantUser(response.activeTenant[0]);
        setLoadingSubmit(false);
        setShowInputPassword(false);
        setShowInputEnviroment(true);
      },
      (err) => {
        setEmailError(true);
        setPasswordError(true);
        setLoadingSubmit(false);
        setShowInputEnviroment(false);
        setMsgError(
          resource(
            cultureState,
            err.error?.response?.data !== 0
              ? err.error?.response?.data[0]?.value?.replace(".", "::") || ((typeof err.error?.response?.data === 'string' && err.error?.response?.data?.replace(".", "::") ))
              : "PageLogin::UnexpectedError"
          )
        );
      }
    );
  }

  let openedWindow: any;

  const openWindow = (url: string) => {
    openedWindow = window.open(
      url,
      "",
      "width=600,height=600,menubar=no, top=300, left=300"
    );
  };

  const closeOpenedWindow = () => {
    openedWindow.close();
  };

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  const buttonSSOClickHandler = () => {
    let channel = email
      .replace("@", "")
      .replace(".", "")
      .replace(".", "")
      .replace(".", "");

    const socket = openSocket(SOCKET_ENDPOINT + channel);
    openWindow(activeTenant.urlSSO);
    socket.on(SSO_EVENT, async (data: any) => {
      if (data.status === 200) {
        props?.doLoginSSO(data.response).then(() => {
          getProfileData().then(() => {
            setLoadingSubmit(false);
            if (
              data?.response?.allowCreateWorkspace &&
              data?.response?.hasWorkspaceConfigured === false
            ) {
              history.push("/setenvironment");
            } else if (data?.response?.isProfileSetted === true) {
              history.push("/setprofile");
            } else {
              history.push("/");
            }
          });
        });
        closeOpenedWindow();
      } else if (data.status === 401) {
        closeOpenedWindow();
        setEmailError(true);
        toast.error(
          resource(cultureState, "MessageError::UserRegistrationError")
        );
      } else {
        toast.error(`${data.status}: ${data.response}`);
      }
    });
  };

  useEffect(() => {
    if (showButtonValidEmail === false && activeTenant?.hasSSO === true) {
      setShowButtonSSO(true);
      setShowInputPassword(false);
    } else if (
      showButtonValidEmail === false &&
      activeTenant?.hasSSO === false
    ) {
      setShowButtonSSO(false);
    } else if (
      showButtonValidEmail === false &&
      activeTenant?.hasSSO === undefined
    ) {
      setShowButtonSSO(false);
      setShowInputPassword(false);
      setShowButtonValidEmail(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTenant]);

  useEffect(() => {
    expiredLogin &&
      setMsgLogOut(resource(cultureState, "PageLogin::YourSessionHasExpired"));
    const interval = setTimeout(() => setMsgLogOut(""), 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  // const onChangeTokenReCaptcha = useCallback((token) => {
  //   setCaptcha(token)

  //   // eslint-disable-next-line
  // }, [captcha]);

  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      {cultureState.statusCultureDefault === "SUCCEEDED" ||
      cultureState.statusCulture === "SUCCEEDED" ? (
        <form
          name="form"
          onSubmit={
            !showButtonValidEmail ? HandleLoginSubmit : handleTenantSubmit
          }
        >
          <input type="hidden" value="something" />
          <Box pl={1} ml={1} pt={5}>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              <Resource tag={"PageLogin::Login_Header"} />
            </Typography>
          </Box>
          <Box pl={1} ml={1}>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              <Resource tag={"PageLogin::Login_WebHelpMessage"} />
            </Typography>
          </Box>
          <Box ml={2} className={classes.containerInputs}>
            <Box mt={3}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor="LoginFieldEmail">
                  {" "}
                  <Resource tag={"PageLogin::ChangeEmail_Email"} />
                </InputLabel>
                <Input
                  id="LoginFieldEmail"
                  type={"email"}
                  value={email}
                  autoComplete="new-password" // workaround to disable autocomplete on chrome
                  error={emailError}
                  onChange={handleEmailChange}
                  onKeyPress={submitAction}
                  // onBlurCapture={handleTenantSubmit}
                />
                {/* {!emailVerifying ? '' :
                  <LinearProgress />
                } */}
              </FormControl>
            </Box>
            {showInputPassword && (
              <>
                <Box mt={3}>
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="LoginFieldPassword">
                      <Resource tag={"PageLogin::LoginOrganization_Password"} />
                    </InputLabel>
                    <Input
                      id="LoginFieldPassword"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      autoComplete="new-password" // workaround to disable autocomplete on chrome
                      onChange={handlePasswordChange}
                      onKeyPress={submitAction}
                      error={passwordError}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {/* {!passwordVerifying ? '' :
                      <LinearProgress />
                    } */}
                  </FormControl>
                </Box>
                <Box mt={1}>
                  <Link
                    component={RouterLink}
                    to="/recover"
                    color="textSecondary"
                    variant="subtitle2"
                    className="forgot-password"
                  >
                    <Resource
                      tag={"PageLogin::LoginOrganization_LinkForgotPassword"}
                    />
                  </Link>
                </Box>
              </>
            )}

            {showInputEnviroment ? (
              <>
                {isUserMultiTenant && (
                  <Box mt={3}>
                    <Autocomplete
                      id="LoginFieldEnvironment"
                      autoSelect
                      value={activeTenant}
                      options={tenants}
                      className={classes.textField}
                      getOptionLabel={(option) => option.name}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<Resource tag={"PageLogin::Environment"} />}
                          variant="standard"
                          error={isUserMultiTenant && !isTenantSelected}
                        />
                      )}
                      onChange={handleTenantUpdate}
                    />
                  </Box>
                )}
              </>
            ) : (
              <></>
            )}

            <Box mt={2} mb={2}>
              <Typography variant="body2" color="textSecondary">
                <Resource tag={"PageLogin::Captcha_Protected"} />
                <Links
                  href={"https://policies.google.com/privacy"}
                  target="blank"
                >
                  {" "}
                  <Resource tag={"PageLogin::Captcha_Privacy"} />{" "}
                </Links>{" "}
                <Resource tag={"PageLogin::Captcha_And"} />
                <Links
                  href={"https://policies.google.com/terms"}
                  target="blank"
                >
                  {" "}
                  <Resource tag={"PageLogin::Captcha_Terms"} />{" "}
                </Links>{" "}
                <Resource tag={"PageLogin::Captcha_Apply"} />
              </Typography>
            </Box>

            {showButtonValidEmail ? (
              newApp ? (
                <Box>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        id="LoginButtonConnect"
                        className={classes.btnConnect}
                        variant={!loadingSubmit ? "contained" : "outlined"}
                        color="primary"
                        disabled={!email || !password} //|| isUserMultiTenant ? isTenantSelected: false}//&& emailError ? false : true}
                        onClick={handleTenantSubmit}
                        endIcon={
                          !loadingSubmit ? (
                            <ArrowForward className="icon icon-arrow" />
                          ) : (
                            <CircularProgress size={22} className="icon" />
                          )
                        }
                      >
                        <Resource tag={"PageLogin::Connect"} />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box className={classes.boxBtnConnect}>
                  <Button
                    id="LoginButtonConnect"
                    className={classes.btnConnect}
                    variant={!loadingSubmit ? "contained" : "outlined"}
                    type="button"
                    color="primary"
                    disabled={!email || !password} //|| isUserMultiTenant ? isTenantSelected: false}//&& emailError ? false : true}
                    onClick={handleTenantSubmit}
                    endIcon={
                      !loadingSubmit ? (
                        <ArrowForward className="icon icon-arrow" />
                      ) : (
                        <CircularProgress size={22} className="icon" />
                      )
                    }
                  >
                    <Resource tag={"PageLogin::Connect"} />
                  </Button>
                </Box>
              )
            ) : (
              <>
                {showButtonSSO ? (
                  <Box className={classes.boxBtnConnect}>
                    <Button
                      onClick={buttonSSOClickHandler}
                      type="button"
                      color="primary"
                      variant="contained"
                      className={classes.btnConnect}
                      disableElevation
                      disabled={
                        !email.length ||
                        emailError ||
                        (isUserMultiTenant ? !isTenantSelected : false)
                      }
                    >
                      Log-In with SSO - {activeTenant?.name}
                    </Button>
                  </Box>
                ) : (
                  <Box className={classes.boxBtnConnect}>
                    <Button
                      id="LoginButtonSubmit"
                      className={classes.btnConnect}
                      type="submit"
                      variant={!loadingSubmit ? "contained" : "outlined"}
                      color="primary"
                      disabled={
                        !email.length ||
                        !password.length ||
                        emailError ||
                        (isUserMultiTenant ? !isTenantSelected : false)
                      } //|| isUserMultiTenant ? isTenantSelected: false}//&& emailError ? false : true}
                      endIcon={
                        !loadingSubmit ? (
                          <ArrowForward className="icon icon-arrow" />
                        ) : (
                          <CircularProgress size={22} className="icon" />
                        )
                      }
                    >
                      <Resource tag={"PageLogin::Connect"} />
                    </Button>
                  </Box>
                )}
              </>
            )}
            {expiredLogin && (
              <Box marginTop={2}>
                <Typography color="error">{msgLogOut}</Typography>
              </Box>
            )}
            {msgError && (
              <Box marginTop={2}>
                <Typography color="error">{msgError}</Typography>
              </Box>
            )}
          </Box>
        </form>
      ) : (
        <>
          <Box height="100%" display="flex" className={classes.centerLoading}>
            <Box m="auto">
              <CircularProgress size={60} className="icon" />
            </Box>
          </Box>
        </>
      )}
    </Fade>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, IAuthLoginAction>
): IProps => {
  return {
    doEnviroment: (email: string, password: string, captcha: string) =>
      dispatch(doEnviroments(email, password, captcha)),
    doLogin: (
      environmentType: number,
      tenantId: number,
      email: string,
      password: string,
      culture: object,
      captcha: string
    ) =>
      dispatch(
        doLogin(environmentType, tenantId, email, password, culture, captcha)
      ),
    doLoginSSO: (response: IAuthUser) => dispatch(doLoginSSO(response)),
    getTenantConfigurations: (email: string) =>
      dispatch(getTenantConfigurations(email)),
    updatePassword: (options: object) => dispatch(updatePassword(options)),
    recoverPassword: (options: object) => dispatch(recoverPassword(options)),
    validateRecoverCode: (options: object) =>
      dispatch(validateRecoverCode(options)),
    setActiveTenant: (activeTenant: ITenant) =>
      dispatch(setActiveTenant(activeTenant)),
    getProfileData: () => dispatch(getProfileData()),
  };
};

const mapStateToProps = (store: IAppState, ownProps: any): IStateProps => {
  return {
    activeTenant: store.tenantState.activeTenant,
    cultureState: store.cultureState,
    expiredLogin: ownProps?.location?.state?.expiredLogin,
    profileData: store.profileState.profileData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
