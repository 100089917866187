export interface IResultNews {
  newsId: string,
  image: string,
  publicationDate: string,
  topic: string,
  title: string,
  description: string,
  nrLikes: number,
  isFavorite: true
}

export interface IResultNewsHighlighted {
  newsId: string,
  image: string,
  publicationDate: string,
  topic: string,
  title: string,
  description: string,
  nrLikes: number,
  isFavorite: boolean
}

export interface IResultNewsPopular {
  newsId: string,
  image: string,
  publicationDate: string,
  topic: string,
  title: string,
  description: string,
  nrLikes: number,
  isFavorite: boolean
}

export interface INews {
  highlighted: INewsHighlighted,
  popular: INewsPopular,
  news: {
    meta: {
      currentPage: number,
      from: number,
      lastPage: number,
      perPage: number,
      to: number,
      total: number
    },
    result: IResultNews[]
  }
}

export interface INewsHighlighted {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultNewsHighlighted[]
}

export interface INewsPopular {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultNewsPopular[]
}

export interface INewsState {
  readonly news: INews;
  readonly topics: [];
  readonly newsHighlighted: INewsHighlighted;
  readonly newsPopular: INewsPopular;
  readonly futureNews: Partial<IResultNews>[];
  readonly createNewStatus: string;
  readonly deleteNewStatus: string;
  readonly futureNewStatus: string;
}

export enum NewsActionTypes {
  GET_NEWS = 'GET_NEWS',
  GET_TOPICS = 'GET_TOPICS',
  GET_NEWS_HIGHLIGHTED = 'GET_NEWS_HIGHLIGHTED',
  GET_NEWS_POPULAR = 'GET_NEWS_POPULAR',

  CREATE_NEW_LOAD = 'CREATE_NEW_LOAD',
  CREATE_NEW_SUCCESS = 'CREATE_NEW_SUCCESS',
  CREATE_NEW_FAILED = 'CREATE_NEW_FAILED',

  UPDATE_NEW_LOAD = 'UPDATE_NEW_LOAD',
  UPDATE_NEW_SUCCESS = 'UPDATE_NEW_SUCCESS',
  UPDATE_NEW_FAILED = 'UPDATE_NEW_FAILED',

  DELETE_NEW_LOAD = 'DELETE_NEW_LOAD',
  DELETE_NEW_SUCCESS = 'DELETE_NEW_SUCCESS',
  DELETE_NEW_FAILED = 'DELETE_NEW_FAILED',

  HIGHLIGHT_NEW_LOAD = 'HIGHLIGHT_NEW_LOAD',
  HIGHLIGHT_NEW_SUCCESS = 'HIGHLIGHT_NEW_SUCCESS',
  HIGHLIGHT_NEW_FAILED = 'HIGHLIGHT_NEW_FAILED',

  FUTURE_NEWS_LOAD = 'FUTURE_NEWS_LOAD',
  FUTURE_NEWS_SUCCESS = 'FUTURE_NEWS_SUCCESS',
  FUTURE_NEWS_FAILED = 'FUTURE_NEWS_FAILED'
}
export enum NewsStatusTypes {
  LOAD = "LOADING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export interface INewsAction {
  type: NewsActionTypes.GET_NEWS;
  news: INews;
}

export interface INewsGetTopicsAction {
  type: NewsActionTypes.GET_TOPICS;
  topics: [];
}

export interface INewsHighlightedGetAction {
  type: NewsActionTypes.GET_NEWS_HIGHLIGHTED;
  newsHighlighted: INewsHighlighted;
}

export interface INewsPopularGetAction {
  type: NewsActionTypes.GET_NEWS_POPULAR;
  newsPopular: INewsPopular;
}

export interface ICreateNewsLoadAction {
  type: NewsActionTypes.CREATE_NEW_LOAD;
  status: string
}
export interface ICreateNewsSuccessAction {
  type: NewsActionTypes.CREATE_NEW_SUCCESS
  status: string
}
export interface ICreateNewsFailAction {
  type: NewsActionTypes.CREATE_NEW_FAILED;
  status: string
}

export interface IUpdateNewsLoadAction {
  type: NewsActionTypes.UPDATE_NEW_LOAD;
  status: string
}
export interface IUpdateNewsSuccessAction {
  type: NewsActionTypes.UPDATE_NEW_SUCCESS
  status: string
}
export interface IUpdateNewsFailAction {
  type: NewsActionTypes.UPDATE_NEW_FAILED;
  status: string
}

export interface IDeleteNewsLoadAction {
  type: NewsActionTypes.DELETE_NEW_LOAD;
  status: string
}
export interface IDeleteNewsSuccessAction {
  type: NewsActionTypes.DELETE_NEW_SUCCESS
  status: string
  newsId: string;
}
export interface IDeleteNewsFailAction {
  type: NewsActionTypes.DELETE_NEW_FAILED;
  status: string
}

export interface IHighlightNewsLoadAction {
  type: NewsActionTypes.HIGHLIGHT_NEW_LOAD;
}
export interface IHighlightNewsSuccessAction {
  type: NewsActionTypes.HIGHLIGHT_NEW_SUCCESS
  newsId: string;
  isHighlighted: boolean;
}
export interface IHighlightNewsFailAction {
  type: NewsActionTypes.HIGHLIGHT_NEW_FAILED;
}

export interface IFutureNewsLoadAction {
  type: NewsActionTypes.FUTURE_NEWS_LOAD;
  status: string
}
export interface IFutureNewsSuccessAction {
  type: NewsActionTypes.FUTURE_NEWS_SUCCESS
  futureNews: Partial<IResultNews>[]
  status: string
}
export interface IFutureNewsFailAction {
  type: NewsActionTypes.FUTURE_NEWS_FAILED;
  status: string
}



export type NewsActions =
  | INewsAction
  | INewsGetTopicsAction
  | INewsHighlightedGetAction
  | INewsPopularGetAction
  | ICreateNewsLoadAction
  | ICreateNewsSuccessAction
  | ICreateNewsFailAction
  | IUpdateNewsLoadAction
  | IUpdateNewsSuccessAction
  | IUpdateNewsFailAction
  | IDeleteNewsLoadAction
  | IDeleteNewsSuccessAction
  | IDeleteNewsFailAction
  | IHighlightNewsLoadAction
  | IHighlightNewsSuccessAction
  | IHighlightNewsFailAction
  | IFutureNewsLoadAction
  | IFutureNewsSuccessAction
  | IFutureNewsFailAction