import history from "./history";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IAppState } from "redux/store/Store";
import CloseIcon from "@material-ui/icons/Close";
import Resource from "components/Resources/Resource";
import { IAuthUser } from "redux/reducers/authReducer";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { ITenantState } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { Redirect, Route, RouteProps } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

interface IProps {
  authUser: IAuthUser;
  culture: ICultureState;
  children: RouteProps["children"];
  userProps: any;
  tenants: ITenantState;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      outline: 0,
      padding: 0,
    },
    menuItemActive: {
      borderRadius: 25,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      fontWeight: 500,
      margin: "4px 0",
      cursor: "pointer",
    },
    menuItem: {
      borderRadius: 25,
      color: theme.palette.primary.main,
      fontWeight: 500,
      margin: "4px 0",
      cursor: "pointer",
    },
  })
);

const LoggedInSettings = (props: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchXs = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  useEffect(() => {
    if (props?.authUser?.isAuthenticated === false) {
      history.push("/log-in");
    } else if (props?.authUser?.backOfficeAllowed === false) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      render={() =>
        props?.authUser?.backOfficeAllowed &&
          props?.authUser?.isAuthenticated ? (
          <Container>
            <Box
              className="settings-header"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Box my={3}>
                <Button
                  startIcon={<ChevronLeft />}
                  size="large"
                  onClick={() => history.push("/")}
                >
                  <Typography variant="h2">
                    <Resource tag="PageSettings::Settings" />
                  </Typography>
                </Button>
              </Box>
              <Box textAlign="right" my={3}>
                <IconButton onClick={() => history.push("/")}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>

            <Box className="settings-content">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={4} lg={3} xl={3}>
                  <Box ml={matchXs ? 0 : 3}>
                    <List className={classes.menu}>
                      {
                        props?.authUser.permission === "Admin" &&
                        <ListItem
                          className={classes.menuItem}
                          component={NavLink}
                          to="/settings/setup"
                          activeClassName={classes.menuItemActive}
                          exact
                        >
                          <Resource tag="PageSetup::General" />
                        </ListItem>
                      }
                      <ListItem
                        className={classes.menuItem}
                        component={NavLink}
                        to="/settings/manage-users"
                        activeClassName={classes.menuItemActive}
                        exact
                      >
                        <Resource tag={"PageSettings::ManageUsers"} />
                      </ListItem>
                      <ListItem
                        className={classes.menuItem}
                        component={NavLink}
                        to="/settings/manage-groups"
                        activeClassName={classes.menuItemActive}
                        exact
                      >
                        <Resource tag={"PageSettings::ManageGroups"} />
                      </ListItem>
                      <ListItem
                        className={classes.menuItem}
                        component={NavLink}
                        to="/settings/regulations"
                        activeClassName={classes.menuItemActive}
                        exact
                      >
                        <Resource tag={"PageSettings::Regulation"} />
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={7} md={8} lg={9} xl={9}>
                  <Box className="settings-children" pl={matchXs ? 1 : 12}>
                    {props.children}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        ) : (
          <Redirect
            to={{
              pathname: "/log-in",
              state: {
                expiredLogin: true,
              },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  authUser: state.authState.auth,
  culture: state.cultureState,
  tenants: state.tenantState,
});

export default connect(mapStateToProps)(LoggedInSettings);
