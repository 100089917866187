import Truncate from "react-truncate";
import ClearIcon from "@material-ui/icons/Clear";
import ToolTip from "components/ToolTip/ToolTip";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import { IUsers } from "redux/reducers/usersReducers";
import { ICultureState } from "redux/reducers/cultureReducer";
import XAutocomplete from "common/UI/XAutocomplete/XAutocomplete";
import {
  Avatar,
  Box,
  Container,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

interface IProps {
  users: IUsers[];
  culture: ICultureState;
  reviewers: {
    userId?: string,
    fullName: string,
    photo: string
  }[];
  setFatherState: (newValue: Array<any>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    actualManagers: {
      marginRight: "auto",
      marginTop: theme.spacing(2),
      minWidth: "300px",
      width: "100%",
      gap: "24px",
      maxHeight: "350px",
      overflowY: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",

      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    listButton: {
      width: 25,
      height: 25,
      borderRadius: 5,
      boxShadow: "0 0 5px " + theme.palette.grey[400],
      marginTop: 4,
      marginBottom: 4,

      "&:hover": {
        borderRadius: 5,
      },
    },
  })
);

function ReviewersWorkflow({
  users,
  culture,
  reviewers,
  setFatherState,
}: IProps) {
  const classes = useStyles();
  const [autoCompleteValue, setACVAlue] = useState(reviewers || []);
  const handleDeleteValue = (value: any) => {
    setACVAlue(autoCompleteValue.filter(e => e.fullName !== value));
  };

  useEffect(() => {
    setFatherState(autoCompleteValue);
  }, [autoCompleteValue, setFatherState]);

  return (
    <Container>
      <Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Typography
            variant="h4"
            component="span"
            style={{ width: "fit-content", marginRight: 8 }}
          >
            <Resource
              tag={"PageCreateChallenge::Workflow_ResponsibleForReview"}
            />
          </Typography>
          <ToolTip title={""}>
            <Resource
              tag={"PageCreateChallenge::Workflow_ResponsibleReviewTip"}
            />
          </ToolTip>
        </Box>
        <Typography variant="body2" color="textSecondary">
          <Resource
            tag={"PageCreateChallenge::Workflow_DefineTheUsersReview"}
          />
        </Typography>
      </Box>
      <Box mt={2} width="75%">
        <XAutocomplete
          options={users}
          optionName={"fullName"}
          optionAvatar={"photo"}
          optionSubtitle={"email"}
          autoCompleteValue={autoCompleteValue}
          onChange={(newValue) =>{ if(newValue.length !==0) setACVAlue(newValue)}}
          culture={culture}
        />
      </Box>
      <Box mt={3}>
        <Box className={classes.actualManagers}>
          {autoCompleteValue?.map((value: any, index: number) => {
            return (
              <div
                className="manager-item-containerPopup"
                key={value.userId ?? index}
              >
                <Avatar
                  src={value?.photo}
                  alt={value.fullName}
                  className={classes.thumbnail}
                />
                <div className="manager-item-label">
                  <Truncate width={120} ellipsis={<span>...</span>}>
                    {value.fullName}
                  </Truncate>
                </div>
                <div className="manage-item-cross">
                  <IconButton
                    className="bottom-manage-item-cross"
                    onClick={() => handleDeleteValue(value.fullName)}
                  >
                    <ClearIcon />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </Box>
      </Box>
    </Container>
  );
}

export default ReviewersWorkflow;
