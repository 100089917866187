import * as React from "react";
import './SelectFilter.css'
import EMIcon from 'components/Icon/Icon'
import {resource} from "../Resources/Resource";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import { MenuItem, Select } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { useState } from "react";

interface IProps {
    filterAction: (value:string) => void
    children?: React.ReactNode
    filterData?: [],
    cultureState: ICultureState
}

function SelectFilter (props: IProps) {

    const theme = useTheme();
    const mainColor = theme.palette.primary.main;

    const handleChange = (event:any) => {
        const value = event.target.value;
        const { filterAction } = props
        filterAction(value)
    }

    const { cultureState } = props;
    const [open, setOpen] = useState(false);


    return(
        <div style={{paddingTop: "20px"}}>
            <div className="select-filter-container" >
                <Select 
                    className="select-filter" 
                    onChange={handleChange}
                    style={
                        {
                            color:mainColor,
                            fontWeight:600, 
                            textTransform:"capitalize"
                        }
                    }
                    fullWidth
                    open={open}
                    onClose={()=> setOpen(false)}
                    onOpen={()=> setOpen(true)}
                    IconComponent={
                        props => !open 
                        ? <EMIcon color={mainColor} icon="arrow-down" size={14}/> 
                        : <EMIcon color={mainColor} icon="arrow-up" size={14}/>
                    }
                    defaultValue={"3"}
                >
                    <MenuItem style={{textTransform:"capitalize"}} value="3" >{resource(cultureState, "PageWallet::WithoutInvestment_PeriodAnalysisWeek")}</MenuItem>
                    <MenuItem style={{textTransform:"capitalize"}} value="2" >{resource(cultureState, "PageWallet::WithoutInvestment_PeriodAnalysisMonth")} </MenuItem>
                    <MenuItem style={{textTransform:"capitalize"}} value="1" >{resource(cultureState, "PageWallet::WithoutInvestment_PeriodAnalysisYear")}</MenuItem>
                </Select>
            </div>
            {props.children}
        </div>
    )   
}
export default SelectFilter;