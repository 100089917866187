import React, { useState, useEffect } from "react";
import Resource, { resource } from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IProfileData } from "../../redux/reducers/profileReducer";

// @ts-ignore
import { Steps } from "intro.js-react";
import CloseIcon from '@material-ui/icons/Close';
import "intro.js/introjs.css";
import {
    makeStyles,
    Theme,
    createStyles,
    Button,
    Box,
} from "@material-ui/core";

import "./Onboard.scss";
import people from "assets/img/happy.svg";
import icoHi from "assets/img/hi.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonPrimary: ({ path }: IProps) => ({
            color: path !== "ranking" ? "white" : theme.palette.primary.main,
            backgroundColor:
                path === "ranking" ? "white" : theme.palette.primary.main,
        }),
        buttonSecondary: ({ path }: IProps) => ({
            color: path === "ranking" ? "white" : theme.palette.primary.main,
            backgroundColor:
                path !== "ranking" ? "white" : theme.palette.primary.main,
        })
    })
);


interface IProps {
    path?: string,
    offBoard?: boolean,
    existAnyChallenges?: boolean,
    culture?: ICultureState,
    profileData?: IProfileData,
    permission: string
}

function Onboard(props: IProps) {
    const classes = useStyles(props);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep] = useState(0);
    const [modalStart, setModalStart] = useState(false);
    const [onOffOnboard] = useState(props.offBoard);
    const [existAnyChallenges] = useState(props.existAnyChallenges);
    const [menssageClose] = useState(false);
    const classAux = "introjs-tooltipReferenceLayer";
    const classAuxBtPrev = "introjs-prevbutton";
    const classAuxBtNext = "introjs-nextbutton";
    const classAuxDone = "introjs-donebutton";

    const [steps] = useState([
        {
            element: "#Config",
            intro: resource(props.culture, "OnBoarding::NoteLogo")
        },
        {
            element: "#profile-container",
            intro: resource(props.culture, "OnBoarding::NoteProfile")
        },
        {
            element: "#Header_AlertsButton",
            intro: resource(props.culture, "OnBoarding::NoteAlertProfile")
        },
        {
            element: "#Header_AddButton",
            intro: resource(props.culture, "OnBoarding::NoteNewChallenges")
        },
        {
            element: "#SideBarFeed",
            intro: resource(props.culture, "OnBoarding::NoteFeed")
        },
        {
            element: "#SideBarChallenges",
            intro: resource(props.culture, "OnBoarding::NoteChallenges")
        },
        {
            element: "#SideBarIdea",
            intro: resource(props.culture, "OnBoarding::NoteIdeas")
        },
        {
            element: "#SideBarPrizes",
            intro: resource(props.culture, "OnBoarding::NotePrizes")
        },
        {
            element: "#SideBarNews",
            intro: resource(props.culture, "OnBoarding::NoteNews")
        },
        {
            element: "#SideBarRanking",
            intro: resource(props.culture, "OnBoarding::NoteRanking")
        },
        {
            element: "#mensageFinal",
            intro: <div><p>{resource(props.culture, "OnBoarding::NoteFinalTile")}</p><p>{resource(props.culture, "OnBoarding::NoteFinalContent1")}</p><p>{resource(props.culture, "OnBoarding::NoteFinalContent2")}</p><p>{resource(props.culture, "OnBoarding::NoteFinalContent3")}</p></div>
        }
    ]);

    useEffect(() => {
        //@ts-ignore
        if (props.profileData?.isFirstAccess) {
            setModalStart(true)
        }

    }, [props]);


    const onLater = () => {
        localStorage.setItem('seeYouLater', "true");
        setModalStart(false);
    }

    const toggleSteps = () => {
        // @ts-ignore
        setStepsEnabled(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
        setModalStart(false);
        setStepsEnabled(true);
    };

    const toggleModal = () => {
        // @ts-ignore
        setModalStart(prevState => ({ setModalStart: !prevState.setModalStart }));
    }

    const closeModal = () => {
        setModalStart(false);
    }

    const onExit = () => {
        setStepsEnabled(false);
    }

    const onStart = () => {
        let buttNext = document.getElementsByClassName(classAuxBtNext);
        let buttPrev = document.getElementsByClassName(classAuxBtPrev);

        if (buttNext.length >= 0) {
            buttNext[0].className = buttNext[0].className + " " + classes.buttonPrimary + " obd-buntton-1";
        }

        if (buttPrev.length >= 0) {
            buttPrev[0].className = buttPrev[0].className + " " + classes.buttonSecondary + " obd-buntton-2";
        }
    }


    // @ts-ignore
    const onChange = (nextStepIndex, nextElement) => {
        let divLocal = document.getElementsByClassName(classAux);
        if (nextStepIndex === steps.length - 1) {
            if (divLocal.length >= 0) {
                divLocal[0].className = classAux + " elementFinal"
            }
        }
        else {
            if (divLocal.length >= 0) {
                divLocal[0].className = classAux
            }
        }
    }

    // @ts-ignore
    const onAfterChange = (newStepIndex, newElement) => {
        let buttNext = document.getElementsByClassName(classAuxBtNext);
        let buttPrev = document.getElementsByClassName(classAuxBtPrev);

        if (buttNext.length >= 0) {
            buttNext[0].className = buttNext[0].className + " " + classes.buttonPrimary + " obd-buntton-1";
        }

        if (buttPrev.length >= 0) {
            buttPrev[0].className = buttPrev[0].className + " " + classes.buttonSecondary + " obd-buntton-2";
        }

        if (newStepIndex === steps.length - 1) {
            let buttDone = document.getElementsByClassName(classAuxDone);

            if (buttDone.length >= 0) {
                buttDone[0].className = buttDone[0].className + " " + classes.buttonPrimary + " obd-buntton-1";
            }
        }
    }

    return (
        <div>
            <Steps
                // @ts-ignore
                enabled={stepsEnabled}
                steps={steps}
                initialStep={initialStep}
                onChange={onChange}
                onExit={onExit}
                onStart={onStart}
                onAfterChange={onAfterChange}
                options={{
                    nextLabel: resource(props.culture, "OnBoarding::BtnNext"),
                    prevLabel: resource(props.culture, "OnBoarding::BtnPrev"),
                    skipLabel: '',
                    doneLabel: resource(props.culture, "OnBoarding::BtnFinish"),
                    tooltipClass: 'stepsTip'
                }}
            />
            {
                modalStart &&
                <div className="modalStart">
                    <div className="modalStartWrapper">
                        <div className="modalStart-container">
                            <div className="obd-close">
                                <i onClick={closeModal}><CloseIcon /></i>
                            </div>
                            <div>
                                <p>
                                    <strong>
                                        <Resource tag="OnBoarding::ModalStartTitle" />
                                    </strong>
                                    <span>
                                        <img src={icoHi} alt="Hi" />
                                    </span>
                                </p>
                                <p>
                                    <Resource tag="OnBoarding::ModalStartContext1" />
                                </p>
                                <p>
                                    <Resource tag="OnBoarding::ModalStartContext2" />
                                </p>
                            </div>
                            <div className="obdButtons">
                                <button className={classes.buttonSecondary + " obd-buntton-2"} onClick={onLater}>
                                    <Resource tag="OnBoarding::ModalStartNotNow" />
                                </button>
                                <button className={classes.buttonPrimary + " obd-buntton-1"} onClick={toggleSteps} >
                                    <Resource tag="OnBoarding::ModalStartLetsGo" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                onOffOnboard && !existAnyChallenges &&
                <div className="obd-container">
                    <div className="obd-feed">
                        <div className="item">
                            <h3>
                                <Resource tag="OnBoarding::DescriptionTitle" />
                            </h3>
                            <img src={people} alt="People Happy" />
                            <h4>
                                <Resource tag="OnBoarding::DescriptionContent1" /> <br />

                                <Resource tag="OnBoarding::DescriptionContent2" />
                                <button style={{ fontWeight: "bold" }} className={classes.buttonSecondary} onClick={toggleModal}>
                                    <Resource tag="OnBoarding::DescriptionFirstPassButton" />
                                </button>
                                <Resource tag="OnBoarding::DescriptionContent3" />
                            </h4>
                            <p><Resource tag="OnBoarding::DescriptionContent4" /> </p>
                            {
                            (props.permission === "Admin" ||
                                props.permission === "ChallengeManager") &&
                                <Box mt={3}>
                                    <Link to="/createChallenge">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color={"secondary"}
                                            size="small"
                                            style={{ color: "white" }}
                                        >
                                            <Resource tag={"OnBoarding::ThrowChallenge"} />
                                        </Button>
                                    </Link>
                                </Box>
                            }
                        </div>
                    </div>
                </div>
            }

            {
                menssageClose &&
                <div id="mensageFinal">
                </div>
            }

        </div>
    );
}

export default Onboard;