import newApp from "utils/reactNewApp";
import sanitizeHtml from "sanitize-html";
import { ThunkAction } from "redux-thunk";
import { ActionCreator, Dispatch } from "redux";
import { api_general, api_v2, api_v3 } from "../../../config/api";
import { treatError } from "../../../utils/treatError";
import { securityTextEditor } from "utils/securityTextEditor";
import { INewsDetailState } from "../../reducers/news/newsDetailReducer";
import { toast } from "react-toastify";
import { resource } from "components/Resources/Resource";

const basePath = "news";

// Create Action Constants
export enum NewsDetailActionTypes {
  NEWS_DETAIL_LOADING = "NEWS_DETAIL_LOADING",
  NEWS_DETAIL_SUCCEEDED = "NEWS_DETAIL_SUCCEEDED",
  NEWS_DETAIL_FAILED = "NEWS_DETAIL_FAILED",
  SHOW_MORE = "SHOW_MORE",
  NEWS_COMMENTS_LOADING = "NEWS_COMMENTS_LOADING",
  NEWS_COMMENTS_SUCCEEDED = "NEWS_COMMENTS_SUCCEEDED",
  NEWS_COMMENTS_FAILED = "NEWS_COMMENTS_FAILED",

  HANDLE_LIKE_LOADING = "HANDLE_LIKE_LOADING",
  HANDLE_DISLIKE_SUCCEEDED = "HANDLE_DISLIKE_SUCCEEDED",
  HANDLE_LIKE_SUCCEEDED = "HANDLE_LIKE_SUCCEEDED",
  HANDLE_LIKE_FAILED = "HANDLE_LIKE_FAILED",

  NEWS_POSTCOMMENT_LOAD = "NEWS_POSTCOMMENT_LOAD",
  NEWS_POSTCOMMENT_SUCCESS = "NEWS_POSTCOMMENT_SUCCESS",
  NEWS_POSTCOMMENT_FAIL = "NEWS_POSTCOMMENT_FAIL",

  NEWS_PUT_COMMENT_LOAD = "NEWS_PUT_COMMENT_LOAD",
  NEWS_PUT_COMMENT_SUCCESS = "NEWS_PUT_COMMENT_SUCCESS",
  NEWS_PUT_COMMENT_FAIL = "NEWS_PUT_COMMENT_FAIL",

  NEWS_DELETE_COMMENT_LOAD = "NEWS_DELETE_COMMENT_LOAD",
  NEWS_DELETE_COMMENT_SUCCESS = "NEWS_DELETE_COMMENT_SUCCESS",
  NEWS_DELETE_COMMENT_FAIL = "NEWS_DELETE_COMMENT_FAIL",

  NEWS_DELETE_COMMENT_REPLY_LOAD = "NEWS_DELETE_COMMENT_REPLY_LOAD",
  NEWS_DELETE_COMMENT_REPLY_SUCCESS = "NEWS_DELETE_COMMENT_REPLY_SUCCESS",
  NEWS_DELETE_COMMENT_REPLY_FAIL = "NEWS_DELETE_COMMENT_REPLY_FAIL",

  NEWS_LIKE_COMMENT = "NEWS_LIKE_COMMENT",
  NEWS_DISLIKE_COMMENT = "NEWS_DISLIKE_COMMENT",
}

//Create Action Statusses
export enum NewsDetailActionStatus {
  NEWS_DETAIL_LOADING = "LOADING",
  NEWS_DETAIL_SUCCEEDED = "SUCCEEDED",
  NEWS_DETAIL_FAILED = "FAILED",

  NEWS_LOADING = "LOADING",
  NEWS_SUCCEEDED = "SUCCEEDED",
  NEWS_FAILED = "FAILED",

  LIKE_LOADING = "LIKE_LOADING",
  LIKE_SUCCEEDED = "LIKE_SUCCEEDED",
  DISLIKE_SUCCEEDED = "DISLIKE_SUCCEEDED",
  LIKE_FAILED = "LIKE_FAILED",
}

//------------*------------------------*---------------
export interface INewsDetailGetPreviewLoadingAction {
  type: NewsDetailActionTypes.NEWS_DETAIL_LOADING;
  newsDetailStatus: string;
}
export interface INewsDetailGetPreviewSucceededAction {
  type: NewsDetailActionTypes.NEWS_DETAIL_SUCCEEDED;
  newsDetail: any;
  newsDetailStatus: string;
}
export interface INewsDetailGetPreviewFailedAction {
  type: NewsDetailActionTypes.NEWS_DETAIL_FAILED;
  newsDetailStatus: string;
  error: null;
}
export interface IRelatedNewsShowMoreAction {
  type: NewsDetailActionTypes.SHOW_MORE;
}

export interface INewsDetailCommentsLoadAction {
  type: NewsDetailActionTypes.NEWS_COMMENTS_LOADING;
  newsCommentStatus: string;
}
export interface INewsDetailCommentsSucceededAction {
  type: NewsDetailActionTypes.NEWS_COMMENTS_SUCCEEDED;
  newsComments: any;
  meta: any;
  newsCommentStatus: string;
  messagesCount: number;
  totalMessagePages: number;
}
export interface INewsDetailCommentsFailedAction {
  type: NewsDetailActionTypes.NEWS_COMMENTS_FAILED;
  newsCommentStatus: string;
  error: null;
}

export interface INewsHandleLikeLoadAction {
  type: NewsDetailActionTypes.HANDLE_LIKE_LOADING;
  likeStatus: string;
}
export interface INewsHandleLikeSuccessAction {
  type: NewsDetailActionTypes.HANDLE_LIKE_SUCCEEDED;
  likeStatus: string;
}

export interface INewsHandleDislikeSuccessAction {
  type: NewsDetailActionTypes.HANDLE_DISLIKE_SUCCEEDED;
  likeStatus: string;
}
export interface INewsHandleLikeFailAction {
  type: NewsDetailActionTypes.HANDLE_LIKE_FAILED;
  likeStatus: string;
  error: any;
}

export interface INewsDetailPostCommentLoadAction {
  type: NewsDetailActionTypes.NEWS_POSTCOMMENT_LOAD;
  newsCommentStatus: string;
}
export interface INewsDetailPostCommentSuccessAction {
  type: NewsDetailActionTypes.NEWS_POSTCOMMENT_SUCCESS;
  newsComments: any;
  meta: any;
  newsCommentStatus: string;
}
export interface INewsDetailPostCommentFailAction {
  type: NewsDetailActionTypes.NEWS_POSTCOMMENT_FAIL;
  newsCommentStatus: string;
  error: null;
}

export interface INewsDetailPutCommentLoadAction {
  type: NewsDetailActionTypes.NEWS_PUT_COMMENT_LOAD;
  newsCommentStatus: string;
}
export interface INewsDetailPutCommentSuccessAction {
  type: NewsDetailActionTypes.NEWS_PUT_COMMENT_SUCCESS;
  newsComments: any;
  meta: any;
  newsCommentStatus: string;
}
export interface INewsDetailPutCommentFailAction {
  type: NewsDetailActionTypes.NEWS_PUT_COMMENT_FAIL;
  newsCommentStatus: string;
  error: null;
}

export interface INewsDetailDeleteCommentLoadAction {
  type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_LOAD;
  newsCommentStatus: string;
}
export interface INewsDetailDeleteCommentSuccessAction {
  type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_SUCCESS;
  newsCommentStatus: string;
  newsComments: any;
}
export interface INewsDetailDeleteCommentFailAction {
  type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_FAIL;
  newsCommentStatus: string;
  error: null;
}

export interface INewsDetailDeleteCommentReplyLoadAction {
  type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_REPLY_LOAD;
  newsCommentStatus: string;
}
export interface INewsDetailDeleteCommentReplySuccessAction {
  type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_REPLY_SUCCESS;
  newsCommentStatus: string;
  newsComments: any;
}
export interface INewsDetailDeleteCommentReplyFailAction {
  type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_REPLY_FAIL;
  newsCommentStatus: string;
  error: null;
}

export interface INewsDetailCommentLikeAction {
  type: NewsDetailActionTypes.NEWS_LIKE_COMMENT;
}
export interface INewsDetailCommentDislikeAction {
  type: NewsDetailActionTypes.NEWS_DISLIKE_COMMENT;
}

/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type NewsDetailActions =
  | INewsDetailGetPreviewLoadingAction
  | INewsDetailGetPreviewSucceededAction
  | INewsDetailGetPreviewFailedAction
  | IRelatedNewsShowMoreAction
  | INewsDetailCommentsLoadAction
  | INewsDetailCommentsSucceededAction
  | INewsDetailCommentsFailedAction
  | INewsHandleLikeLoadAction
  | INewsHandleLikeSuccessAction
  | INewsHandleDislikeSuccessAction
  | INewsHandleLikeFailAction
  | INewsDetailPostCommentLoadAction
  | INewsDetailPostCommentSuccessAction
  | INewsDetailPostCommentFailAction
  | INewsDetailPutCommentLoadAction
  | INewsDetailPutCommentSuccessAction
  | INewsDetailPutCommentFailAction
  | INewsDetailDeleteCommentLoadAction
  | INewsDetailDeleteCommentSuccessAction
  | INewsDetailDeleteCommentFailAction
  | INewsDetailDeleteCommentReplyLoadAction
  | INewsDetailDeleteCommentReplySuccessAction
  | INewsDetailDeleteCommentReplyFailAction
  | INewsDetailCommentLikeAction
  | INewsDetailCommentDislikeAction;

export const handleLikeNews: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsHandleLikeSuccessAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: NewsDetailActionTypes.HANDLE_LIKE_LOADING,
        likeStatus: NewsDetailActionStatus.LIKE_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }

      params_qs = `?${params.join("&")}`;
      if (newApp) {
        await api_v3
          .post(`${basePath}/${options.newsId}/like${params_qs}`)
          .then(async (response) => {
            const res = await api_v3.get(
              `${basePath}/${options.newsId}${params_qs}`,
              {}
            );
            dispatch({
              type: NewsDetailActionTypes.NEWS_DETAIL_SUCCEEDED,
              newsDetailStatus: NewsDetailActionStatus.NEWS_DETAIL_SUCCEEDED,
              newsDetail: res.data,
            });
            dispatch({
              type: NewsDetailActionTypes.HANDLE_LIKE_SUCCEEDED,
              likeStatus: NewsDetailActionStatus.LIKE_SUCCEEDED,
            });
          });
      } else {
        await api_v2
          .post(`${basePath}/${options.newsId}/like`)
          .then(async (response) => {
            const res = await api_v2.get(`${basePath}/${options.newsId}`, {});
            dispatch({
              type: NewsDetailActionTypes.NEWS_DETAIL_SUCCEEDED,
              newsDetailStatus: NewsDetailActionStatus.NEWS_DETAIL_SUCCEEDED,
              newsDetail: res.data,
            });
            dispatch({
              type: NewsDetailActionTypes.HANDLE_LIKE_SUCCEEDED,
              likeStatus: NewsDetailActionStatus.LIKE_SUCCEEDED,
            });
          });
      }
    } catch (err) {
      dispatch({
        type: NewsDetailActionTypes.HANDLE_LIKE_FAILED,
        likeStatus: NewsDetailActionStatus.LIKE_FAILED,
        error: err,
      });
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};
export const handleDislikeNews: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsHandleLikeSuccessAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: NewsDetailActionTypes.HANDLE_LIKE_LOADING,
        likeStatus: NewsDetailActionStatus.LIKE_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }

      params_qs = `?${params.join("&")}`;
      if (newApp) {
        await api_v3
          .post(`${basePath}/${options.newsId}/dislike${params_qs}`)
          .then(async (response) => {
            const res = await api_v3.get(
              `${basePath}/${options.newsId}${params_qs}`,
              {}
            );
            dispatch({
              type: NewsDetailActionTypes.NEWS_DETAIL_SUCCEEDED,
              newsDetailStatus: NewsDetailActionStatus.NEWS_DETAIL_SUCCEEDED,
              newsDetail: res.data,
            });
            dispatch({
              type: NewsDetailActionTypes.HANDLE_DISLIKE_SUCCEEDED,
              likeStatus: NewsDetailActionStatus.DISLIKE_SUCCEEDED,
            });
          });
      } else {
        await api_v2
          .post(`${basePath}/${options.newsId}/dislike`)
          .then(async (response) => {
            const res = await api_v2.get(`${basePath}/${options.newsId}`, {});
            dispatch({
              type: NewsDetailActionTypes.NEWS_DETAIL_SUCCEEDED,
              newsDetailStatus: NewsDetailActionStatus.NEWS_DETAIL_SUCCEEDED,
              newsDetail: res.data,
            });
            dispatch({
              type: NewsDetailActionTypes.HANDLE_DISLIKE_SUCCEEDED,
              likeStatus: NewsDetailActionStatus.DISLIKE_SUCCEEDED,
            });
          });
      }
    } catch (err) {
      dispatch({
        type: NewsDetailActionTypes.HANDLE_LIKE_FAILED,
        likeStatus: NewsDetailActionStatus.LIKE_FAILED,
        error: err,
      });
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

/* Get All idea
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getNewsDetail: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsDetailGetPreviewSucceededAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NewsDetailActionTypes.NEWS_COMMENTS_LOADING,
      newsDetailStatus: NewsDetailActionStatus.NEWS_LOADING,
    });
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }

    params_qs = `?${params.join("&")}`;
    if (newApp) {
      await api_v3.get(`${basePath}/${options.newsId}${params_qs}`, {}).then(
        (response) => {
          dispatch({
            type: NewsDetailActionTypes.NEWS_DETAIL_SUCCEEDED,
            newsDetailStatus: NewsDetailActionStatus.NEWS_DETAIL_SUCCEEDED,
            newsDetail: response.data,
          });
        },
        (error) => {
          treatError(options?.culture, "PageNews::Header", error);
          dispatch({
            type: NewsDetailActionTypes.NEWS_DETAIL_FAILED,
            newsDetailStatus: NewsDetailActionStatus.NEWS_DETAIL_FAILED,
            error: error,
          });
        }
      );
    } else {
      await api_v2.get(`${basePath}/${options.newsId}`, {}).then(
        (response) => {
          dispatch({
            type: NewsDetailActionTypes.NEWS_DETAIL_SUCCEEDED,
            newsDetailStatus: NewsDetailActionStatus.NEWS_DETAIL_SUCCEEDED,
            newsDetail: response.data,
          });
        },
        (error) => {
          treatError(options?.culture, "PageNews::Header", error);
          dispatch({
            type: NewsDetailActionTypes.NEWS_DETAIL_FAILED,
            newsDetailStatus: NewsDetailActionStatus.NEWS_DETAIL_FAILED,
            error: error,
          });
        }
      );
    }
  };
};

export const getNewsComments: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsDetailCommentsSucceededAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NewsDetailActionTypes.NEWS_COMMENTS_LOADING,
      newsCommentStatus: NewsDetailActionStatus.NEWS_LOADING,
    });
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("pageNumber")) {
      params.push(`pageNumber=${options.pageNumber}`);
    }

    if (options.hasOwnProperty("pageSize")) {
      params.push(`pageSize=${options.pageSize}`);
    }

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }
    if (newApp) {
      await api_v3
        .get(`${basePath}/${options.newsId}/comments${params_qs}`, {})
        .then(
          (response) => {
            dispatch({
              type: NewsDetailActionTypes.NEWS_COMMENTS_SUCCEEDED,
              newsCommentStatus: NewsDetailActionStatus.NEWS_SUCCEEDED,
              meta: response.data.meta,
              newsComments: response.data.result,
              messagesCount: response.data.messagesCount,
              totalMessagePages: response.data.totalMessagePages,
            });
          },
          (error) => {
            treatError(options?.culture, "PageNews::Header", error);
            dispatch({
              type: NewsDetailActionTypes.NEWS_COMMENTS_FAILED,
              newsCommentStatus: NewsDetailActionStatus.NEWS_FAILED,
              error: error,
            });
          }
        );
    } else {
      await api_v2
        .get(`${basePath}/${options.newsId}/comments${params_qs}`, {})
        .then(
          (response) => {
            dispatch({
              type: NewsDetailActionTypes.NEWS_COMMENTS_SUCCEEDED,
              newsCommentStatus: NewsDetailActionStatus.NEWS_SUCCEEDED,
              meta: response.data.meta,
              newsComments: response.data.result,
              messagesCount: response.data.messagesCount,
              totalMessagePages: response.data.totalMessagePages,
            });
          },
          (error) => {
            treatError(options?.culture, "PageNews::Header", error);
            dispatch({
              type: NewsDetailActionTypes.NEWS_COMMENTS_FAILED,
              newsCommentStatus: NewsDetailActionStatus.NEWS_FAILED,
              error: error,
            });
          }
        );
    }
  };
};

export const postComment: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsDetailPostCommentSuccessAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NewsDetailActionTypes.NEWS_POSTCOMMENT_LOAD,
      newsCommentStatus: NewsDetailActionStatus.NEWS_LOADING,
    });
    let params;
    if (options.hasOwnProperty("commentParentId")) {
      params = {
        commentParentId: options.commentParentId,
        message: sanitizeHtml(options.message, securityTextEditor),
        captcha: options.captcha,
      };
    } else {
      params = {
        message: sanitizeHtml(options.message, securityTextEditor),
        captcha: options.captcha,
      };
    }
    if (newApp) {
      params = {
        ...params,
        workspaceId: options.workspaceId,
      };
      await api_general
        .post(`${basePath}/${options.newsId}/comments`, params)
        .then(
          () => {
            toast.success(
              resource(options.culture, "CommentsArea::SuccessCreatedComment")
            );
            dispatch({
              type: NewsDetailActionTypes.NEWS_POSTCOMMENT_SUCCESS,
              newsCommentStatus: NewsDetailActionStatus.NEWS_SUCCEEDED,
            });
          },
          (error) => {
            treatError(options?.culture, "PageNews::Header", error);
            dispatch({
              type: NewsDetailActionTypes.NEWS_POSTCOMMENT_FAIL,
              newsCommentStatus: NewsDetailActionStatus.NEWS_FAILED,
            });
          }
        );
    } else {
      params = {
        ...params,
        applicationId: options.applicationId,
      };
      await api_v2.post(`${basePath}/${options.newsId}/comments`, params).then(
        async () => {
          let paramsComments = [];
          let params_qs = "";

          paramsComments.push(`pageNumber=${options.pageNumber}`);
          paramsComments.push(`pageSize=${options.pageSize}`);

          if (paramsComments.length) {
            params_qs = `?${paramsComments.join("&")}`;
          }
          const responseComments = await api_v2.get(
            `${basePath}/${options.newsId}/comments${params_qs}`,
            {}
          );
          dispatch({
            type: NewsDetailActionTypes.NEWS_POSTCOMMENT_SUCCESS,
            newsCommentStatus: NewsDetailActionStatus.NEWS_SUCCEEDED,
            newsComments: responseComments.data.result,
          });
        },
        (error) => {
          treatError(options?.culture, "PageNews::Header", error);
          dispatch({
            type: NewsDetailActionTypes.NEWS_POSTCOMMENT_FAIL,
            newsCommentStatus: NewsDetailActionStatus.NEWS_FAILED,
          });
        }
      );
    }
  };
};

export const putNewsComment: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsDetailPutCommentSuccessAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NewsDetailActionTypes.NEWS_PUT_COMMENT_LOAD,
      newsCommentStatus: NewsDetailActionStatus.NEWS_LOADING,
    });
    let params;
    if (options.hasOwnProperty("commentParentId")) {
      params = {
        commentParentId: options.commentParentId,
        newsId: options.newsId,
        workspaceId: options.workspaceId,
        message: sanitizeHtml(options.message, securityTextEditor),
      };
    } else {
      params = {
        newsId: options.newsId,
        workspaceId: options.workspaceId,
        message: sanitizeHtml(options.message, securityTextEditor),
      };
    }

    await api_general
      .put(`${basePath}/${options.commentId}/comments`, params)
      .then(
        async () => {
          toast.success(
            resource(options.culture, "CommentsArea::SuccessEditedComment")
          );
          let paramsComments = [];
          let params_qs = "";

          paramsComments.push(`pageNumber=${options.pageNumber}`);
          paramsComments.push(`pageSize=${options.pageSize}`);

          if (paramsComments.length) {
            params_qs = `?${paramsComments.join("&")}`;
          }
          const responseComments = await api_general.get(
            `${basePath}/${options.newsId}/comments${params_qs}`,
            {}
          );
          dispatch({
            type: NewsDetailActionTypes.NEWS_PUT_COMMENT_SUCCESS,
            newsCommentStatus: NewsDetailActionStatus.NEWS_SUCCEEDED,
            newsComments: responseComments.data.result,
          });
        },
        (error) => {
          treatError(options?.culture, "PageNews::Header", error);
          dispatch({
            type: NewsDetailActionTypes.NEWS_PUT_COMMENT_FAIL,
            newsCommentStatus: NewsDetailActionStatus.NEWS_FAILED,
          });
        }
      );
  };
};

export const deleteNewsComment: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsDetailDeleteCommentSuccessAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_LOAD,
      newsCommentStatus: NewsDetailActionStatus.NEWS_LOADING,
    });
    let params;
    params = {
      commentId: options.commentId,
      newsId: options.newsId,
      workspaceId: options.workspaceId,
    };

    await api_general.delete(`${basePath}/comments`, { data: params }).then(
      () => {
        toast.success(
          resource(options.culture, "CommentsArea::SuccessDeletedComment")
        );
        dispatch({
          type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_SUCCESS,
          newsCommentStatus: NewsDetailActionStatus.NEWS_SUCCEEDED,
        });
      },
      (error) => {
        treatError(options?.culture, "PageNews::Header", error);
        dispatch({
          type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_FAIL,
          newsCommentStatus: NewsDetailActionStatus.NEWS_FAILED,
        });
      }
    );
  };
};

export const deleteNewsCommentReply: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsDetailDeleteCommentReplySuccessAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_REPLY_LOAD,
      newsCommentStatus: NewsDetailActionStatus.NEWS_LOADING,
    });
    let params;
    params = {
      parentCommentId: options.parentCommentId,
      commentId: options.commentId,
      newsId: options.newsId,
      workspaceId: options.workspaceId,
    };

    await api_general.delete(`${basePath}/reply`, { data: params }).then(
      () => {
        toast.success(
          resource(options.culture, "CommentsArea::SuccessDeletedComment")
        );
        dispatch({
          type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_REPLY_SUCCESS,
          newsCommentStatus: NewsDetailActionStatus.NEWS_SUCCEEDED,
        });
      },
      (error) => {
        treatError(options?.culture, "PageNews::Header", error);
        dispatch({
          type: NewsDetailActionTypes.NEWS_DELETE_COMMENT_REPLY_FAIL,
          newsCommentStatus: NewsDetailActionStatus.NEWS_FAILED,
        });
      }
    );
  };
};

export const postLikeComment: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsDetailCommentLikeAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params;
      if (newApp) {
        params = {
          workspaceId: options.workspaceId,
          commentId: options.commentId,
        };

        await api_v3.post(
          `${basePath}/${options.newsId}/comments/like`,
          params
        );
      } else {
        params = {
          applicationId: options.applicationId,
          commentId: options.commentId,
        };
        await api_v2.post(
          `${basePath}/${options.newsId}/comments/like`,
          params
        );
      }
    } catch (err) {
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

/* Post Dislike  Like Comment
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postDislikeComment: ActionCreator<
  ThunkAction<
    Promise<any>,
    INewsDetailState,
    null,
    INewsDetailCommentDislikeAction
  >
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params;
      if (newApp) {
        params = {
          workspaceId: options.workspaceId,
          commentId: options.commentId,
        };

        await api_v3.post(
          `${basePath}/${options.newsId}/comments/dislike`,
          params
        );
      } else {
        params = {
          applicationId: options.applicationId,
          commentId: options.commentId,
        };
        await api_v2.post(
          `${basePath}/${options.newsId}/comments/dislike`,
          params
        );
      }
    } catch (err) {
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};
