import React from "react";
import "./MyActivitiesInProgressList.css";
import { IMyActivitiesIdeasInProgress } from "redux/types/myActivities";
import MyActivitiesInProgress from "../../components/MyActivitiesInProgress/MyActivitiesInProgress";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Hidden,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import Resource from "../../components/Resources/Resource";
import newApp from "utils/reactNewApp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: "20px 0px",
    },
    tableTitles: {
      padding: "20px 20px 0px 0px!important",
      color: " #767676",
      "text-transform": "uppercase",
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
      [theme.breakpoints.down("xs")]: {
        borderBottom: "none",
      },
    },
  })
);

interface IProps {
  myActivitiesInProgress: IMyActivitiesIdeasInProgress;
}

function MyActivitiesInProgressList(props: IProps) {
  const { myActivitiesInProgress } = props;
  const classes = useStyles();

  return (
    <>
      <Table
        // aria-label="simple table"
        className={classes.table}
      >
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell
              className={classes.tableTitles}
              variant="head"
              align="left"
            >
              <Hidden smDown>
                <Resource tag="PageIdea::Idea_ColumnIdeia" />
              </Hidden>
            </TableCell>
            <TableCell
              className={classes.tableTitles}
              variant="head"
              align="left"
            >
              <Hidden smDown>
                <Resource tag="PageIdea::Idea_ColumnSubmissionIdea" />
              </Hidden>
            </TableCell>
            <TableCell
              className={classes.tableTitles}
              variant="head"
              align="left"
            >
              <Hidden smDown>
                <Resource tag="PageIdea::Idea_ColumnLastEvaluation" />
              </Hidden>
            </TableCell>
            <TableCell
              className={classes.tableTitles}
              variant="head"
              align="left"
            >
              <Hidden smDown>
              { newApp ? <Resource tag="PageIdea::Idea_ColumnCurrentStatusV3" /> : <Resource tag="PageIdea::Idea_ColumnCurrentStatus" /> }
              </Hidden>
            </TableCell>
            <TableCell
              className={classes.tableTitles}
              variant="head"
              align="left"
            >
              <Hidden smDown>
                <Resource tag="PageIdea::Idea_ColumnManager" />
              </Hidden>
            </TableCell>
          </TableRow>
        </TableHead>
        {myActivitiesInProgress?.result?.map((activitiesInProgress, index) => (
          <MyActivitiesInProgress
            key={index}
            ideasInProgress={activitiesInProgress}
          />
        ))}
      </Table>
    </>
  );
}

export default MyActivitiesInProgressList;
