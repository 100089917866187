// Import redux types
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {api_v2, api_general, api_v3} from "./../../config/api";
import { toast } from "react-toastify";
import { resource } from '../../components/Resources/Resource';
import { treatError } from "../../utils/treatError";

// Import Character Typing
import {
  IPrize,
  IPrizeActiveAuction,
  IPrizeActiveAuctionUser,
  IPrizePrevious,
  IPrizePreviousUser,
  IPrizeActiveBuyNow,
  IPrizeActiveBuyNowUser,
  IPrizeFutures,
  IPrizeState,
  IResultPrizeActiveAuctionUser,
  IResultPrizeActiveBuyNow,
  IResultPrizeFutures
} from '../reducers/prizeReducer';

import newApp from 'utils/reactNewApp';

const basePath = "prizes";

// Create Action Constants
export enum PrizeActionTypes {
  GET_ALL_PRIZES = 'GET_ALL_PRIZES',
  GET_PRIZEACTIVEAUCTION = 'GET_PRIZEACTIVEAUCTION',
  GET_PRIZEACTIVEAUCTIONUSER = 'GET_PRIZEACTIVEAUCTIONUSER',
  GET_PRIZEPREVIOUS = 'GET_PRIZEPREVIOUS',
  GET_PRIZEPREVIOUSUSER = 'GET_PRIZEPREVIOUSUSER',
  GET_PRIZEACTIVEBUYNOW = 'GET_PRIZEACTIVEBUYNOW',
  GET_PRIZEACTIVEBUYNOWUSER = 'GET_PRIZEACTIVEBUYNOWUSER',
  GET_PRIZEFUTURES = 'GET_PRIZEFUTURES',
  POST_BUYPRIZE = 'POST_BUYPRIZE',
  POST_PRIZEBID = 'POST_PRIZEBID',
  
  DELETE_PRIZE_LOADING = 'DELETE_PRIZE_LOADING',
  DELETE_PRIZE_SUCCEEDED = 'DELETE_PRIZE_SUCCEEDED',
  DELETE_PRIZE_FAILED = 'DELETE_PRIZE_FAILED',

  DUPLICATE_PRIZE_LOADING = 'DUPLICATE_PRIZE_LOADING',
  DUPLICATE_PRIZE_SUCCEEDED = 'DUPLICATE_PRIZE_SUCCEEDED',
  DUPLICATE_PRIZE_FAILED = 'DUPLICATE_PRIZE_FAILED',

  CLOSE_AUCTION_PRIZE_LOADING = 'CLOSE_AUCTION_PRIZE_LOADING',
  CLOSE_AUCTION_PRIZE_SUCCEEDED = 'CLOSE_AUCTION_PRIZE_SUCCEEDED',
  CLOSE_AUCTION_PRIZE_FAILED = 'CLOSE_AUCTION_PRIZE_FAILED',
}

export enum PrizeActionStatus {
  PRIZE_LOADING = 'LOADING',
  PRIZE_SUCCEEDED = 'SUCCEEDED',
  PRIZE_FAILED = 'FAILED',
}

// Interface for Get All Action Type
export interface IPrizeGetAllAction {
  type: PrizeActionTypes.GET_ALL_PRIZES;
  points: number;
  prizes: IPrize;
}

// Interface for Get All Prize Active Auction Action Type
export interface IPrizeActiveAuctionGetAllAction {
  type: PrizeActionTypes.GET_PRIZEACTIVEAUCTION;
  prizesActiveAuction: IPrizeActiveAuction;
}

// Interface for Get All Prize Active Auction User Action Type
export interface IPrizeActiveAuctionUserGetAllAction {
  type: PrizeActionTypes.GET_PRIZEACTIVEAUCTIONUSER;
  prizesActiveAuctionUser: IPrizeActiveAuctionUser;
}

// Interface for Get All Prize Previous Action Type
export interface IPrizePreviousGetAllAction {
  type: PrizeActionTypes.GET_PRIZEPREVIOUS;
  prizesPrevious: IPrizePrevious;
}

// Interface for Get All Prize Previous User Action Type
export interface IPrizePreviousUserGetAllAction {
  type: PrizeActionTypes.GET_PRIZEPREVIOUSUSER;
  prizesPreviousUser: IPrizePreviousUser;
}

// Interface for Get All Prize Active Buy Now Action Type
export interface IPrizeActiveBuyNowGetAllAction {
  type: PrizeActionTypes.GET_PRIZEACTIVEBUYNOW;
  prizesActiveBuyNow: IPrizeActiveBuyNow;
}

// Interface for Get All Prize Active Buy Now User Action Type
export interface IPrizeActiveBuyNowUserGetAllAction {
  type: PrizeActionTypes.GET_PRIZEACTIVEBUYNOWUSER;
  prizesActiveBuyNowUser: IPrizeActiveBuyNowUser;
}

// Interface for Get All Prize Future Action Type
export interface IPrizeFutureGetAllAction {
  type: PrizeActionTypes.GET_PRIZEFUTURES;
  prizesFutures: IPrizeFutures;
}

export interface IPrizePostBuyPrize {
  type: PrizeActionTypes.POST_BUYPRIZE
// Interface for Post Prize Buy Now Action Type
}

// Interface for Post Prize Bid Action Type
export interface IPrizeBidPostAction {
  type: PrizeActionTypes.POST_PRIZEBID;
}

// Interface for Delete Prize Action Type
export interface IPrizeDeleteLoadingAction {
  type: PrizeActionTypes.DELETE_PRIZE_LOADING;
  status: string;
}
export interface IPrizeDeleteSucceededAction {
  type: PrizeActionTypes.DELETE_PRIZE_SUCCEEDED;
  status: string;
}
export interface IPrizeDeleteFailedAction {
  type: PrizeActionTypes.DELETE_PRIZE_FAILED;
  status: string;
  error: any;
}

// Interface for Duplicate Prize Action Type
export interface IPrizeDuplicateLoadingAction {
  type: PrizeActionTypes.DUPLICATE_PRIZE_LOADING;
  status: string;
}
export interface IPrizeDuplicateSucceededAction {
  type: PrizeActionTypes.DUPLICATE_PRIZE_SUCCEEDED;
  status: string;
}
export interface IPrizeDuplicateFailedAction {
  type: PrizeActionTypes.DUPLICATE_PRIZE_FAILED;
  status: string;
  error: any;
}

// Interface for Close Auction Prize Action Type
export interface IPrizeCloseAuctionLoadingAction {
  type: PrizeActionTypes.CLOSE_AUCTION_PRIZE_LOADING;
  status: string;
}
export interface IPrizeCloseAuctionSucceededAction {
  type: PrizeActionTypes.CLOSE_AUCTION_PRIZE_SUCCEEDED;
  status: string;
}
export interface IPrizeCloseAuctionFailedAction {
  type: PrizeActionTypes.CLOSE_AUCTION_PRIZE_FAILED;
  status: string;
  error: any;
}

/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type PrizeActions = 
  | IPrizeGetAllAction
  | IPrizeActiveAuctionGetAllAction 
  | IPrizeActiveAuctionUserGetAllAction 
  | IPrizePreviousGetAllAction
  | IPrizePreviousUserGetAllAction
  | IPrizeActiveBuyNowGetAllAction
  | IPrizeActiveBuyNowUserGetAllAction
  | IPrizeFutureGetAllAction
  | IPrizePostBuyPrize
  | IPrizeDeleteLoadingAction
  | IPrizeDeleteSucceededAction
  | IPrizeDeleteFailedAction
  | IPrizeDuplicateLoadingAction
  | IPrizeDuplicateSucceededAction
  | IPrizeDuplicateFailedAction
  | IPrizeCloseAuctionLoadingAction
  | IPrizeCloseAuctionSucceededAction
  | IPrizeCloseAuctionFailedAction

/* Get All Prizes
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllPrizes: ActionCreator<
  ThunkAction<Promise<any>, IPrizeState, null, IPrizeGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params    = [];
      let params_qs = "";
      
      if( options.hasOwnProperty("culture") )
      {
        params.push(`cultureTag=${options.culture.culture.cultureTag}`);
      }
    
      if( options.hasOwnProperty("pageNumber") )
      {
        params.push(`pageNumber=${options.pageNumber}`);
      }
    
      if( options.hasOwnProperty("pageSize") )
      {
        params.push(`pageSize=${options.pageSize}`);
      }
    
      if( params.length )
      {
        params_qs = `?${params.join("&")}`;
      }

      newApp ?
        await api_general.get(`${basePath}${params_qs}`, {}).then(response => {
          //Get bids users
          let activeAuctions= response.data.activeAuctions.result;
          for (let i=0; i<activeAuctions.length; i++){
            api_general.get(`${basePath}/active-auction/${activeAuctions[i].prizeId}/users${params_qs}`, {}).then(response => {
              activeAuctions[i].userBids= response.data.result;
            })
          }

          let previousPrizes= response.data.previousPrizes.result;
          for (let i=0; i<previousPrizes.length; i++){
            api_general.get(`${basePath}/previous/${previousPrizes[i].prizeId}/users${params_qs}`, {}).then(response => {
              previousPrizes[i].userBids= response.data.result;
            }) 
          }      

          dispatch({
            points: response.data.points,
            prizes: response.data,
            type: PrizeActionTypes.GET_ALL_PRIZES,
          });
        })
      :
        await api_v2.get(`${basePath}${params_qs}`, {}).then(response => {
          //Get bids users
          let activeAuctions= response.data.activeAuctions.result;
          for (let i=0; i<activeAuctions.length; i++){
            api_v2.get(`${basePath}/active-auction/${activeAuctions[i].prizeId}/users${params_qs}`, {}).then(response => {
              activeAuctions[i].userBids= response.data.result;
            })
          }

          let previousPrizes= response.data.previousPrizes.result;
          for (let i=0; i<previousPrizes.length; i++){
            api_v2.get(`${basePath}/previous/${previousPrizes[i].prizeId}/users${params_qs}`, {}).then(response => {
              previousPrizes[i].userBids= response.data.result;
            }) 
          }      

          dispatch({
            points: response.data.points,
            prizes: response.data,
            type: PrizeActionTypes.GET_ALL_PRIZES,
          });
        })
    } catch (err) {
      treatError(options?.culture.culture, 'PagePrizes::Header', err);
    }
  };
};

/* Get All Prizes Active Auctions 
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllPrizesActiveAuctions: ActionCreator<
  ThunkAction<Promise<any>, IPrizeState, null, IPrizeActiveAuctionGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params    = [];
      let params_qs = "";
      
      if( options.hasOwnProperty("pageNumber") )
      {
        params.push(`pageNumber=${options.pageNumber}`);
      }
    
      if( options.hasOwnProperty("pageSize") )
      {
        params.push(`pageSize=${options.pageSize}`);
      }
    
      if( params.length )
      {
        params_qs = `?${params.join("&")}`;
      }

      newApp ?
        await api_general.get(`${basePath}/active-auction${params_qs}`, {}).then(response => {
          dispatch({
            prizesActiveAuction: response.data.prizesActiveAuction,
            type: PrizeActionTypes.GET_PRIZEACTIVEAUCTIONUSER,
          });
        })
      :
        await api_v2.get(`${basePath}/active-auction${params_qs}`, {}).then(response => {
          dispatch({
            prizesActiveAuction: response.data.prizesActiveAuction,
            type: PrizeActionTypes.GET_PRIZEACTIVEAUCTIONUSER,
          });
        })
    
    } catch (err) {
      treatError(options?.culture, 'PagePrizes::Header', err);
    }
  };
};

/* Get All Prizes Active Auctions Users
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllPrizesActiveAuctionsUsers: ActionCreator<
  ThunkAction<Promise<any>, IPrizeState, null, IPrizeActiveAuctionUserGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params    = [];
      let params_qs = "";
      
    
      if( options.hasOwnProperty("pageNumber") )
      {
        params.push(`pageNumber=${options.pageNumber}`);
      }
    
      if( options.hasOwnProperty("pageSize") )
      {
        params.push(`pageSize=${options.pageSize}`);
      }
    
      if( params.length )
      {
        params_qs = `?${params.join("&")}`;
      }

      newApp ?
        await api_general.get(`${basePath}/active-auction/${options.prizeId}/users${params_qs}`, {}).then(response => {
          dispatch({
            prizesActiveAuctionUser: response.data.prizesActiveAuctionUser,
            type: PrizeActionTypes.GET_PRIZEACTIVEAUCTIONUSER,
          });
        })
      :
        await api_v2.get(`${basePath}/active-auction/${options.prizeId}/users${params_qs}`, {}).then(response => {
          dispatch({
            prizesActiveAuctionUser: response.data.prizesActiveAuctionUser,
            type: PrizeActionTypes.GET_PRIZEACTIVEAUCTIONUSER,
          });
        })
        
    } catch (err) {
      treatError(options?.culture, 'PagePrizes::Header', err);
    }
  };
};

/* Get All Prizes Previous
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllPrizesPrevious: ActionCreator<
  ThunkAction<Promise<any>, IPrizeState, null, IPrizePreviousGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params    = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
    
      if( params.length )
      {
        params_qs = `?${params.join("&")}`;
      }

      newApp ?
        await api_general.get(`${basePath}/previous${params_qs}`, {}).then(response => {
          dispatch({
            prizesPrevious: response.data,
            type: PrizeActionTypes.GET_PRIZEPREVIOUS,
          });
        })
      :
        await api_v2.get(`${basePath}/previous${params_qs}`, {}).then(response => {
          dispatch({
            prizesPrevious: response.data,
            type: PrizeActionTypes.GET_PRIZEPREVIOUS,
          });
        })
  
    } catch (err) {
      treatError(options?.culture, 'PagePrizes::Header', err);
    }
  };
};

/* Get All Prizes Previous User
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllPrizesPreviousUser: ActionCreator<
  ThunkAction<Promise<any>, IPrizeState, null, IPrizePreviousUserGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params    = [];
      let params_qs = "";
      
    
      if( options.hasOwnProperty("pageNumber") )
      {
        params.push(`pageNumber=${options.pageNumber}`);
      }
    
      if( options.hasOwnProperty("pageSize") )
      {
        params.push(`pageSize=${options.pageSize}`);
      }
    
      if( params.length )
      {
        params_qs = `?${params.join("&")}`;
      }

      newApp ?
        await api_general.get(`${basePath}/previous/${options.prizeId}/users${params_qs}`, {}).then(response => {
          dispatch({
            prizesPreviousUser: response.data.prizesPreviousUser,
            type: PrizeActionTypes.GET_PRIZEPREVIOUSUSER,
          });
        })
      :
        await api_v2.get(`${basePath}/previous/${options.prizeId}/users${params_qs}`, {}).then(response => {
          dispatch({
            prizesPreviousUser: response.data.prizesPreviousUser,
            type: PrizeActionTypes.GET_PRIZEPREVIOUSUSER,
          });
        })
        
    } catch (err) {
      treatError(options?.culture, 'PagePrizes::Header', err);
    }
  };
};

/* Get All Prizes Active Buy Now
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllPrizesActiveBuyNow: ActionCreator<
  ThunkAction<Promise<any>, IPrizeState, null, IPrizeActiveBuyNowGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params    = [];
      let params_qs = "";
      
      if( options.hasOwnProperty("cultureTag") )
      {
        params.push(`cultureTag=${options.cultureTag}`);
      }
    
      if( options.hasOwnProperty("pageNumber") )
      {
        params.push(`pageNumber=${options.pageNumber}`);
      }
    
      if( options.hasOwnProperty("pageSize") )
      {
        params.push(`pageSize=${options.pageSize}`);
      }
    
      if( params.length )
      {
        params_qs = `?${params.join("&")}`;
      }

      newApp ?
        await api_general.get(`${basePath}/active-buynow${params_qs}`, {}).then(response => {
          dispatch({
            prizesActiveBuyNow: response.data.prizes,
            type: PrizeActionTypes.GET_PRIZEACTIVEBUYNOW,
          });
        })
      :
        await api_v2.get(`${basePath}/active-buynow${params_qs}`, {}).then(response => {
          dispatch({
            prizesActiveBuyNow: response.data.prizes,
            type: PrizeActionTypes.GET_PRIZEACTIVEBUYNOW,
          });
        })

    } catch (err) {
      treatError(options?.culture, 'PagePrizes::Header', err);
    }
  };
};

/* Get All Prizes Active Buy Now User
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllPrizesActiveBuyNowUser: ActionCreator<
  ThunkAction<Promise<any>, IPrizeState, null, IPrizeActiveBuyNowUserGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params    = [];
      let params_qs = "";
      
      if( options.hasOwnProperty("cultureTag") )
      {
        params.push(`cultureTag=${options.cultureTag}`);
      }
    
      if( options.hasOwnProperty("pageNumber") )
      {
        params.push(`pageNumber=${options.pageNumber}`);
      }
    
      if( options.hasOwnProperty("pageSize") )
      {
        params.push(`pageSize=${options.pageSize}`);
      }
    
      if( params.length )
      {
        params_qs = `?${params.join("&")}`;
      }

      newApp ?
        await api_general.get(`${basePath}/active-buynow/${options.prizeId}${params_qs}`, {}).then(response => {
          dispatch({
            prizesActiveBuyNowUser: response.data.prizesActiveBuyNowUser,
            type: PrizeActionTypes.GET_PRIZEACTIVEBUYNOWUSER,
          });
        })
      :
        await api_v2.get(`${basePath}/active-buynow/${options.prizeId}${params_qs}`, {}).then(response => {
          dispatch({
            prizesActiveBuyNowUser: response.data.prizesActiveBuyNowUser,
            type: PrizeActionTypes.GET_PRIZEACTIVEBUYNOWUSER,
          });
        })
        
    } catch (err) {
      treatError(options?.culture, 'PagePrizes::Header', err);
    }
  };
};

/* Get All Prizes Futures
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllPrizesFuture: ActionCreator<
  ThunkAction<Promise<any>, IPrizeState, null, IPrizeFutureGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params    = [];
      let params_qs = "";
      

      if( options.hasOwnProperty("cultureTag") )
      {
        params.push(`cultureTag=${options.cultureTag}`);
      }
    
      if( options.hasOwnProperty("pageNumber") )
      {
        params.push(`pageNumber=${options.pageNumber}`);
      }
    
      if( options.hasOwnProperty("pageSize") )
      {
        params.push(`pageSize=${options.pageSize}`);
      }
    
      if( params.length )
      {
        params_qs = `?${params.join("&")}`;
      }

      newApp ?
        await api_general.get(`${basePath}/futures${params_qs}`, {}).then(response => {
          dispatch({
            prizesFutures: response.data.prizesFutures,
            type: PrizeActionTypes.GET_PRIZEFUTURES,
          });
        })
      :
        await api_v2.get(`${basePath}/futures${params_qs}`, {}).then(response => {
          dispatch({
            prizesFutures: response.data.prizesFutures,
            type: PrizeActionTypes.GET_PRIZEFUTURES,
          });
        })
       
    } catch (err) {
      treatError(options?.culture, 'PagePrizes::Header', err);
    }
  };
};

export const postBuyPrize: ActionCreator<
  ThunkAction<Promise<any>, IPrizeState, null, IPrizeFutureGetAllAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const {prizeId, applicationId} = options

      const params = {
        applicationId
      }
      if((applicationId !== null || undefined) && (prizeId !== null || undefined)) {
        newApp ?
          await api_general.post(`${basePath}/${prizeId}/buynow`,params).then(response => {
            dispatch({
              type: PrizeActionTypes.POST_BUYPRIZE,
            });
    
            if(response?.status === 200){
              toast.success(resource(options.culture, "PagePrizes::BuySuccessful_PopUp"))
            }
          })
        :
          await api_v2.post(`${basePath}/${prizeId}/buynow`,params).then(response => {
            dispatch({
              type: PrizeActionTypes.POST_BUYPRIZE,
            });
    
            if(response?.status === 200){
              toast.success(resource(options.culture, "PagePrizes::BuySuccessful_PopUp"))
            }
          })
      }else{
        toast.error(resource(options.culture, "Errors::Error_MissingParameters", ['Query and Path issues']))
      }
    } catch (err) {
      treatError(options?.culture, 'PagePrizes::Header', err);
    }
  };
};

/* Post Prize bid
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postPrizeBid: ActionCreator<
ThunkAction<Promise<any>, IPrizeState, null, IPrizeBidPostAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const {prizeId,value, applicationId} = options

      const requestParams ={
        applicationId,
        value
      }

      newApp ?
        await api_general.post(`${basePath}/${prizeId}/bid`, requestParams).then(response => {
          dispatch({
            type: PrizeActionTypes.POST_PRIZEBID,
          });

          if(response?.status === 200){
            toast.success(resource(options.culture, "PagePrizes::BidSuccessful_PopUp"))
          }
        })
      :
        await api_v2.post(`${basePath}/${prizeId}/bid`, requestParams).then(response => {
          dispatch({
            type: PrizeActionTypes.POST_PRIZEBID,
          });
    
          if(response?.status === 200){
            toast.success(resource(options.culture, "PagePrizes::BidSuccessful_PopUp"))
          }
        })

    } catch (err) {
        treatError(options?.culture, 'PagePrizes::Header', err, [err.error.response.data[0].arguments[0]]); 
    }
  };
};

/* Duplicate Prize 
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const duplicatePrize: ActionCreator<
ThunkAction<Promise<any>, IPrizeState, null, IPrizeDuplicateSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: PrizeActionStatus.PRIZE_LOADING,
        type: PrizeActionTypes.DUPLICATE_PRIZE_LOADING,
      });

      const { prizeId } = options

      await api_general.put(`${basePath}/${prizeId}/duplicate`, {}).then(response => {
        dispatch({
          status: PrizeActionStatus.PRIZE_SUCCEEDED,
          type: PrizeActionTypes.DUPLICATE_PRIZE_SUCCEEDED,
        });
  
        if(response?.status === 200){
          toast.success(resource(options.culture, "PagePrizes::DuplicatedPrizeSuccess"))
        }
      })
      
    } catch (err) {
        dispatch({
          status: PrizeActionStatus.PRIZE_FAILED,
          type: PrizeActionTypes.DUPLICATE_PRIZE_FAILED,
          error: err
        });

        treatError(options?.culture, 'PagePrizes::Header', err); 
    }
  };
};

/* Delete Prize 
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const deletePrize: ActionCreator<
ThunkAction<Promise<any>, IPrizeState, null, IPrizeDeleteSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: PrizeActionStatus.PRIZE_LOADING,
        type: PrizeActionTypes.DELETE_PRIZE_LOADING,
      });

      const { prizeId } = options

      await api_general.delete(`${basePath}/${prizeId}`, {}).then(response => {
        dispatch({
          status: PrizeActionStatus.PRIZE_SUCCEEDED,
          type: PrizeActionTypes.DELETE_PRIZE_SUCCEEDED,
        });
  
        if(response?.status === 200){
          toast.success(resource(options.culture, "PagePrizes::DeletedPrizeSuccess"))
        }
      })
      
    } catch (err) {
        dispatch({
          status: PrizeActionStatus.PRIZE_FAILED,
          type: PrizeActionTypes.DELETE_PRIZE_FAILED,
          error: err
        });

        treatError(options?.culture, 'PagePrizes::Header', err);
        
    }
  };
};

/* Close Auction Prize 
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const closeAuctionPrize: ActionCreator<
ThunkAction<Promise<any>, IPrizeState, null, IPrizeCloseAuctionSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: PrizeActionStatus.PRIZE_LOADING,
        type: PrizeActionTypes.CLOSE_AUCTION_PRIZE_LOADING,
      });

      const { prizeId, isCanceling } = options

      let params    = [];
      let params_qs = "";
      
    
      if( options.hasOwnProperty("isCanceling") )
      {
        params.push(`isCanceling=${isCanceling}`);
      }
    
    
      if( params.length )
      {
        params_qs = `?${params.join("&")}`;
      }

      await api_general.put(`${basePath}/${prizeId}/closeauction${params_qs}`, {}).then(response => {
        dispatch({
          status: PrizeActionStatus.PRIZE_SUCCEEDED,
          type: PrizeActionTypes.CLOSE_AUCTION_PRIZE_SUCCEEDED,
        });
  
        if(response?.status === 200){
          toast.success(resource(options.culture, "PagePrizes::ClosedAuctionPrizeSuccess"))
        }
      })
      
    } catch (err) {
        dispatch({
          status: PrizeActionStatus.PRIZE_FAILED,
          type: PrizeActionTypes.CLOSE_AUCTION_PRIZE_FAILED,
          error: err
        });

        treatError(options?.culture, 'PagePrizes::Header', err); 
    }
  };
};

export const getPrizesActiveAuction = async(options :any): Promise<IResultPrizeActiveAuctionUser[] | undefined> => {
  try {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/active-auction${params_qs}`, {});
    return data.prizes.result;
  } catch (err) {
    return undefined;
  }
};

export const getPrizesActiveBuyNow = async(options :any): Promise<IResultPrizeActiveBuyNow[] | undefined> => {
  try {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/active-buynow${params_qs}`, {});
    return data.prizes.result;
  } catch (err) {
    return undefined;
  }
};

export const getPrizesFutures = async(options :any): Promise<IResultPrizeFutures[] | undefined> => {
  try {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/futures${params_qs}`, {});
    return data.result;
  } catch (err) {
    return undefined;
  }
};

export const getPrizesPrevious = async(options :any): Promise<IPrizePrevious | undefined> => {
  try {
    let params = [];
    let params_qs = "";


    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }
    if (options.hasOwnProperty("pageSize")) {
      params.push(`pageSize=${options.pageSize}`);
    }
    if (options.hasOwnProperty("pageNumber")) {
      params.push(`pageNumber=${options.pageNumber}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/previous${params_qs}`, {});
    return data;
  } catch (err) {
    return undefined;
  }
};