import React, { useState, useEffect, useCallback } from "react";
import EMIcon from "components/Icon/Icon";
import ToolTip from "../../components/ToolTip/ToolTip";
import { toast } from "react-toastify";
import { IUsers } from "../../redux/reducers/usersReducers";
import "./CoAuthors.css";
import Resource, { resource } from "../Resources/Resource";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import { Avatar, Box, Input, Typography, useTheme } from "@material-ui/core";
import Truncate from "react-truncate";
import { useSelector } from "react-redux";
import { IAppState } from "redux/store/Store";

interface IProps {
  culture?: ICultureState;
  userList?: IUsers[];
  maxCoAuthors?: number;
  coAuthors?: IUserList[];
  setCoauthors?: any;
}

interface IUserList {
  fullName: string;
  authorId: string;
  photo: string;
  email: string;
  name?: string;
}

function CoAuthors(props: IProps) {
  const theme = useTheme();
  const store = useSelector(store => store) as IAppState
  const currentUserId = store.profileState.profile[0].userId; 
  const initialCoAuthorList: any[] = props?.coAuthors
    ? props?.coAuthors.map((data) => {
        return {
          userId: data.authorId,
          fullName: data.name,
          email: data.email,
          photo: data.photo,
        };
      })
    : [];

  const initialCoAuthorIds = initialCoAuthorList
    .map((x) => x.authorId)
    .join(";");
  const [inputValue, setInputValue] = useState("");
  const [view, setView] = useState(false);
  const [coAuthorList, setCoAuthorList] = useState(initialCoAuthorList);
  const [usersList, setUserList] = useState(props.userList);
  const [coAuthorsIds, setCoAuthorsIds] = useState(initialCoAuthorIds);

  const container = React.createRef<HTMLDivElement>();
  const handleClickOutside = useCallback(
    (e: any) => {
      if (container.current && !container.current.contains(e.target)) {
        setView(false);
        setInputValue("");
      }
    },
    [container, setView]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const { coAuthors } = props;
  useEffect(() => {
    props?.setCoauthors(coAuthors ? coAuthors?.map((data) => data?.authorId) : []);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const search = (e: any) => {
    const userList = props?.userList;
    const value = e.target.value.toLowerCase();

    if (value !== "") {
      const filteredUsers = userList?.filter(
        (user) => user.fullName.toLowerCase().indexOf(value) !== -1
      );

      setView(true);
      setUserList(filteredUsers);
    } else {
      setView(false);
    }
  };

  const addCoAuthor = (e: any) => {
    const userList = props?.userList!;
    const maxCoAuthors = props?.maxCoAuthors!;
    const newUsersList = userList.filter((userList) => userList.userId !== e);
    userList.map((user) => {
      if (user.userId === e) {
        if (coAuthorList.length < maxCoAuthors - 1) {
          const newCoAuthorList = [
            ...coAuthorList,
            {
              userId: user.userId,
              fullName: user.fullName,
              email: user.email,
              photo: user.photo,
            },
          ];

          const coAuthorsNames = newCoAuthorList.map(
            (coAuthor) => coAuthor.userId || coAuthor.authorId
          );

          setInputValue("");
          setCoAuthorList(newCoAuthorList);
          setUserList(newUsersList);
          setCoAuthorsIds(coAuthorsNames.join(";"));
          props.setCoauthors(coAuthorsNames.concat());
        } else {
          toast.error(resource(props.culture, "PageNewIdea::LimitExceeded"));
        }
      }
      return null;
    });
    setView(false);
  };

  const removeCoAuthor = (e: any) => {
    const coAuthor = coAuthorList.filter(
      (coAuthorList) =>
        coAuthorList.userId !== e && coAuthorList.userId !== ""
    );
    props.setCoauthors(coAuthor.map((data) => data.userId));
    setCoAuthorList(coAuthor);
  };

  return (
    <div className="coAuthor-container">
      <input type="hidden" name="CoAuthors" value={coAuthorsIds} />
      <span>
        <span className="coAuthor-title">
          <Box fontWeight="bold" style={{ fontSize: "0.8rem",color: theme.palette.text.secondary }}>
            <Resource tag={"PageNewIdea::Idea_CoAuthorsTitle"} />
            <span className="coAuthorOptional">
              <Resource tag={"PageNewIdea::Optional"} />
            </span>
          </Box>
          <ToolTip title={<Resource tag={"PageNewIdea::Help"} />}>
            <Resource tag={"PageNewIdea::Idea_CoAuthorsHelpText"} />
          </ToolTip>
        </span>
      </span>
      <div className="coAuthor-input-container">
        <Input
          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
          placeholder={resource(
            props.culture,
            "PageNewIdea::Idea_CoAuthorsMessage"
          )}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyUp={search}
          className="coAuthor-input"
        />
        <EMIcon size={20} color="#777" icon="search"></EMIcon>
      </div>

      {view ? (
        <div className="coAuthor" ref={container}>
          <div className="ShareCoAuthor">
            {usersList?.length === 0 ? (
              <Typography variant="subtitle2" color="textSecondary">
                <Resource tag="PageNewIdea::NoColleaguesFound" />
              </Typography>
            ) : (
              usersList?.filter(a=> a.userId !== currentUserId).map((user) => {
                if (coAuthorList.some((x) => x.fullName === user.fullName)) {
                  return "";
                } else {
                  return (
                    <div key={Math.random()} className="ShareCoAuthorContent">
                      <Avatar src={user.photo} alt={user.fullName} />
                      <Box className="ShareCoAuthorLabel">
                        <Truncate width={200} ellipsis={<span>...</span>}>
                          {user.fullName}
                        </Truncate>
                      </Box>

                      <div className="ShareCoAuthorAdd">
                        <button
                          className="BottomShareCoAuthorAdd"
                          onClick={() => addCoAuthor(user.userId)}
                        >
                          <EMIcon size={10} color="#333" icon="plus"></EMIcon>
                        </button>
                      </div>
                    </div>
                  );
                }
              })
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="coAuthorSelected">
        {coAuthorList?.map((coAuthor) => {
          return (
            <div key={Math.random()}>
              {coAuthor.authorId !== "" ? (
                <div className="coAuthorSelected-container">
                  <div className="coAuthorSelectedContent">
                    <Avatar
                      src={coAuthor.photo}
                      alt={
                        coAuthor.fullName ? coAuthor.fullName : coAuthor.name
                      }
                    />
                    <Box className="coAuthorSelectedLabel">
                      <Truncate width={140} ellipsis={<span>...</span>}>
                        {coAuthor.fullName ? coAuthor.fullName : coAuthor.name}
                      </Truncate>
                    </Box>

                    <div className="coAuthorSelectedRemove">
                      <button
                        className="BottomCoAuthorSelectedRemove"
                        onClick={() =>
                          removeCoAuthor(
                            coAuthor.authorId
                              ? coAuthor.authorId
                              : coAuthor.userId
                          )
                        }
                      >
                        <EMIcon
                          size={10}
                          color="#777"
                          icon="input-clear"
                        ></EMIcon>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CoAuthors;
