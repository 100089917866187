import React from "react";
import { Box } from "@material-ui/core";
import SkeletonGrid from "common/UI/Skeleton/SkeletonGrid";
import SkeletonTitle from "common/UI/Skeleton/SkeletonTitle";

export default function KeyTasksPageSkeleton() {
  return (
    <>
      <Box mt={6} mb={5}>
        <SkeletonGrid col={5} row={1} rowHeight={30} />

        <SkeletonTitle />
        <SkeletonGrid col={1} row={5} rowHeight={10} />

        <SkeletonTitle />
        <SkeletonGrid col={1} row={5} rowHeight={10} />
      </Box>
    </>
  );
}
