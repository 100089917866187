import React, { useState } from "react";
import "./IdeaList.css";
import EMIcon from "components/Icon/Icon";
import { IIdeas, IIdeasInProgress, IIdeasRejected } from "redux/types/ideas";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AvatarGroup } from "@material-ui/lab";
import {
  Avatar,
  Typography,
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import history from "../../routes/history";
import Resource from "components/Resources/Resource";
import CloseIcon from "@material-ui/icons/Close";
import newApp from "utils/reactNewApp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxHeight: 440,
    },
    tableTitle: {
      color: "#adadad",
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
    },
    tableText: {
      fontWeight: 600,
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
    },
    tableCell: {
      padding: "16px 10px 16px 0px!important",
    },
    thumbnail: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      margin: "0 5px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    titlePopup: {
      padding: "20px 40px 16px",
    },
    bodyPopup: {
      padding: "0px 40px 32px",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface IProps {
  ideas?: IIdeas;
  ideasInProgress?: IIdeasInProgress;
  ideasRejected?: IIdeasRejected;
  listHeader: any; //TODO: ADD THOSE PROPS TO IDEAS REDUCER
  getMoreListIdea?: any;
  type: string;
}

function IdeaList(props: IProps) {
  const classes = useStyles();
  const {
    listHeader,
    ideasInProgress,
    ideasRejected,
    // getMoreListIdea,
    // type,
  } = props;
  const [on, setOn] = useState(true);
  const [icon, setIcon] = useState("arrow-up");
  const [viewAll, setViewAll] = useState<number | null>(null);
  function toggleList() {
    setOn(!on);
    setIcon(on ? "arrow-down" : "arrow-up");
  }

  function handleClickOnCard(ideaId: string) {
    history.push(`/idea/details/${ideaId}`);
  }
  // const handleMore = () => {
  //   type === "inprogress"
  //     ? getMoreListIdea(
  //         type,
  //         ideasInProgress?.meta?.total! > ideasInProgress?.result?.length!
  //           ? ideasInProgress?.meta?.total!
  //           : 6
  //       )
  //     : getMoreListIdea(
  //         type,
  //         ideasRejected?.meta?.total! > ideasRejected?.result?.length!
  //           ? ideasRejected?.meta?.total!
  //           : 6
  //       );
  // };
  return (
    <Box mt={5}>
      <div className="idea-header-drop" onClick={toggleList}>
        <Typography variant="h3">{listHeader}</Typography>
        <EMIcon icon={icon} color="#333" size={24} />
      </div>
      <Collapse in={on}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableTitle}>
                <Resource tag="PageIdea::Idea_ColumnIdeia" />
              </TableCell>
              {ideasInProgress && (
                <TableCell className={classes.tableTitle}>
                  <Resource tag="PageIdea::Idea_ColumnManager" />
                </TableCell>
              )}
              <TableCell className={classes.tableTitle}>
                { newApp ? <Resource tag="PageIdea::Idea_ColumnCurrentStatusV3" /> : <Resource tag="PageIdea::Idea_ColumnCurrentStatus" /> }
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ideasInProgress?.meta?.total !== 0
              ? ideasInProgress?.result?.map((ideaInProgress, index) => {
                  return (
                    <TableRow
                      style={{ cursor: "pointer" }}
                      hover
                      className={classes.tableRow}
                      key={index}
                    >
                      <TableCell
                        scope="row"
                        onClick={() => {
                          handleClickOnCard(ideaInProgress?.ideaId);
                        }}
                        className={classes.tableCell}
                      >
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.tableText}
                        >
                          {ideaInProgress?.title}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {ideaInProgress?.managers?.length > 1 ? (
                          <>
                            <AvatarGroup
                              onClick={() => setViewAll(index)}
                              max={3}
                            >
                              {ideaInProgress?.managers.map(
                                (manager, managerIndex) => {
                                  return (
                                    <Avatar
                                      alt={manager?.fullName}
                                      src={manager?.photo}
                                      key={managerIndex}
                                    />
                                  );
                                }
                              )}
                            </AvatarGroup>
                            <Dialog
                              fullWidth
                              maxWidth={"sm"}
                              open={index === viewAll}
                            >
                              <DialogTitle
                                disableTypography
                                className={classes.titlePopup}
                              >
                                <Typography variant="h4">
                                  <Resource tag="PageIdea::Idea_ColumnManager" />
                                </Typography>
                                <IconButton
                                  // aria-label="close"
                                  className={classes.closeButton}
                                  onClick={() => setViewAll(null)}
                                >
                                  <CloseIcon />
                                </IconButton>
                                <Divider className={classes.divider}></Divider>
                              </DialogTitle>
                              <DialogContent className={classes.bodyPopup}>
                                {ideaInProgress?.managers?.map(
                                  (manager, index) => {
                                    return (
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        mb={1}
                                        key={manager.userId}
                                      >
                                        <Avatar
                                          className={classes.thumbnail}
                                          src={manager?.photo}
                                          alt={manager?.fullName}
                                        />
                                        <Box ml={1} fontSize="0.8rem">
                                          {manager?.fullName}
                                        </Box>
                                      </Box>
                                    );
                                  }
                                )}
                              </DialogContent>
                            </Dialog>
                          </>
                        ) : (
                          <Box display="flex" flexDirection="row">
                            <Box p={1}>
                              <Avatar
                                alt={ideaInProgress.managers[0]?.fullName}
                                src={ideaInProgress.managers[0]?.photo}
                                key={index}
                              />
                            </Box>
                            <Box m="auto" ml={1}>
                              {ideaInProgress?.managers[0]?.fullName}
                            </Box>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                      {ideaInProgress?.status ? ideaInProgress?.status : ideaInProgress?.phase}
                      </TableCell>
                    </TableRow>
                    // <IdeaItems key={ideaInProgress?.ideaId} resultIdeasInProgress={ideaInProgress} listHeader={listHeader}/>
                  );
                })
              : ""}
            {ideasRejected?.meta?.total !== 0 ? (
              <>
                {ideasRejected?.result?.map((ideaRejected, index) => {
                  return (
                    <TableRow
                      style={{ cursor: "pointer" }}
                      className={classes.tableRow}
                      hover
                      key={index}
                      onClick={() => {
                        handleClickOnCard(ideaRejected?.ideaId);
                      }}
                    >
                      <TableCell scope="row" className={classes.tableCell}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.tableText}
                        >
                          {ideaRejected?.title}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {ideaRejected?.status ? ideaRejected?.status : ideaRejected?.phase}
                      </TableCell>
                    </TableRow>
                    // <IdeaItems key={ideaRejected?.ideaId} resultIdeasRejected={ideaRejected} listHeader={listHeader}/>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      </Collapse>
    </Box>
  );
}
export default IdeaList;
