import React from "react";
import { ICulture } from "../../redux/reducers/cultureReducer";
import Resource from "../Resources/Resource";
import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import history from "../../routes/history";

interface IProps {
  path: string;
  culture?: ICulture;
  isDetail: boolean;
}

interface INameToValueMap {
  [key: string]: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    boxTitle: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
  })
);

function PageTitle(props: IProps) {
  const { path, isDetail } = props;
  const classes = useStyles();
  const formatPath = (path: string): string => {
    path = path.replace("/", "");
    path = path[0].toUpperCase() + path.slice(1);

    if (path === "Keytasks") {
      path = path[3].toUpperCase() + path.slice(4);
      path = "Key" + path;
    }

    return `Page${path}`;
  };

  const getTag = () => {
    const pageName = formatPath(path);
    switch (pageName) {
      case "PageIdea":
        return "PageDetailIdea::ViewAllIdeas";
      case "PageChallenges":
        return "PageChallenges::ViewAll";
      case "PageNews":
        return "PageNews::ViewAll";
      case "PageMessages":
        return "PageMessages::ViewAllMessages";
      default:
        return "";
    }
  };
  const backButton = (
    <Box onClick={history.goBack} className={classes.boxTitle}>
      <ArrowBackIosIcon />
      <Typography variant="h2">
        {" "}
        <Resource tag={getTag()} />
      </Typography>
    </Box>
  );

  const getPageTitle = (path: string) => {
    try {
      const pageName = formatPath(path);
      if (props?.culture?.cultureTag) {
        const cultureTag = props.culture?.cultureTag;
        const resources = props.culture?.resources;
        const rightCulture: INameToValueMap = resources?.filter(
          (resource) => resource["cultureTag"] === cultureTag
        )!;

        switch (pageName) {
          case "PageFeed":
            return <Resource tag="PageFeed::Menu_Feed" />;
          case "PageCreateIdea":
            return <Resource tag="PageNewIdea::CreateIdea" />;
          case "PageDrafts":
            return <Resource tag="Common::Drafts" />;
          case "PageIdea":
            return isDetail
              ? backButton
              : rightCulture?.[0]?.["resourceObject"]?.[pageName]?.["Header"];
          case "PageChallenges":
            return isDetail
              ? backButton
              : rightCulture?.[0]?.["resourceObject"]?.[pageName]?.["Header"];
          case "PageNews":
            return isDetail
              ? backButton
              : rightCulture?.[0]?.["resourceObject"]?.[pageName]?.["Header"];
          case "PageMessages":
            return isDetail
              ? backButton
              : rightCulture?.[0]?.["resourceObject"]?.[pageName]?.["Header"];
          default:
            return rightCulture?.[0]?.["resourceObject"]?.[pageName]?.[
              "Header"
            ];
        }
      }
    } catch (err) {
      return "";
    }
  };

  return <>{path && path === "/" ? "" : getPageTitle(path)}</>;
}

export default PageTitle;
