import { toast } from "react-toastify";
import sanitizeHtml from "sanitize-html";
import ReplyIcon from "@material-ui/icons/Reply";
import React, { useEffect, useState } from "react";
import { IProfileData } from "redux/reducers/profileReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import Resource, { resource } from "components/Resources/Resource";
import TextEditorInput from "components/GenericField/TextEditorInput/TextEditorInput";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

interface IProps {
  profileData: IProfileData;
  culture: ICultureState;
  isOpen: boolean;
  setIsOpen: (on: boolean) => void;
  postMessageReply: any;
  applicationId: any;
  getMessagesDetail: any;
  authorId: string;
  emailAuthor: string;
  messageId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    widthCheckboxBtn: {
      alignItems: "center",
      flexFlow: "nowrap",
      "& .MuiSvgIcon-root": {
        width: "0.7em",
        height: "0.7em",
      },
    },
    labelCheckbox: {
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
        color: theme.palette.primary.main,
      },
    },
    paperReplyContainer: {
      borderRadius: 6,
      marginLeft: "16px",
      width: "100%",
    },
    replyIcon: {
      color: theme.palette.text.secondary,
      fontSize: "1rem",
      marginRight: "8px",
    },
  })
);

function MessageReplyDialog(props: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const matchXsSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const {
    culture,
    isOpen,
    setIsOpen,
    profileData,
    postMessageReply,
    applicationId,
    getMessagesDetail,
    authorId,
    emailAuthor,
    messageId,
  } = props;

  // props to rich text
  const msgDescriptionProps = {
    columnName: "msg-description",
    isMandatory: false,
    typeId: 9,
    title: "",
    isEditable: true,
  };

  //set state values
  useEffect(() => {
    setRichTextValue("");
    setInternalMessage(true);
    setEmailMessage(false);
  }, [isOpen]);

  //reply functions: checkbox
  const [internalMessage, setInternalMessage] = useState(true);
  const [emailMessage, setEmailMessage] = useState(false);
  const handleCheckbox = (e: any) => {
    e.target.name === "internal-message-reply"
      ? setInternalMessage(!internalMessage)
      : setEmailMessage(!emailMessage);
  };

  // functions to post messageReply
  const [richTextValue, setRichTextValue] = useState("");
  const onChangeRichText = (e: any) => {
    setRichTextValue(e);
  };
  const handleMessageReply = async () => {
    if (!internalMessage && !emailMessage) {
      toast.error(resource(culture, "MessageError::MessageOptionRequired"));
      return;
    } else {
      const params = {
        applicationId: applicationId,
        to: authorId,
        message: sanitizeHtml(richTextValue, {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat([
            "figure",
            "img",
          ]),
          selfClosing: [
            "img",
            "br",
            "hr",
            "area",
            "base",
            "basefont",
            "link",
            "meta",
          ],
          allowedAttributes: {
            span: ["style"],

            img: sanitizeHtml.defaults.allowedAttributes.img.concat([
              "data-*",
              "origin-size",
              "style",
            ]),
            figure: ["style"],
            div: ["class", "se-*", "contenteditable"],
          },
          allowedSchemes: ["data"],
        }),
        isInternal: internalMessage,
        isEmail: emailMessage,
        messageId: messageId,
      };
      await postMessageReply(params).then(async (response: any) => {
        if (response?.error?.response?.status === 400) {
          return;
        } else {
          await getMessagesDetail({
            messageId: messageId,
            pageNumber: 1,
            pageSize: 10,
          });
          setIsOpen(false);
          toast.success(resource(culture, "PageMessages::SentMessage"));
        }
      });
    }
  };

  return (
    <>
      {isOpen && (
        <Box display="flex" className="reply-container">
          <Avatar
            className="reply-avatar"
            alt="avatar"
            src={profileData?.photo}
          />
          <Paper elevation={3} className={classes.paperReplyContainer}>
            <Box
              className="icon-and-email"
              display="flex"
              padding="12px 12px 8px"
            >
              <ReplyIcon className={classes.replyIcon} />
              <Typography color="textSecondary" variant="subtitle1">
                {emailAuthor}
              </Typography>
            </Box>
            <Box className="reply-content" padding="0 24px 24px">
              <Box className="reply-description">
                <TextEditorInput
                  culture={culture}
                  propsI={msgDescriptionProps}
                  charCounterLabelShow={false}
                  maxCharCountShow={false}
                  height={160}
                  value={richTextValue}
                  onChange={onChangeRichText}
                />
              </Box>
              <Box
                className="reply-actions-container"
                // mt={matchXsSm ? 14 : 8}
                display={matchXsSm ? "grid" : "flex"}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box className="reply-checkbox">
                  <FormControl
                    component="fieldset"
                    className={classes.widthCheckboxBtn}
                    style={{
                      display: matchXsSm ? "grid" : "flex",
                      gap: matchXsSm ? "8px" : "20px",
                    }}
                  >
                    <FormLabel>
                      <Box fontSize="0.7rem" fontWeight="bold">
                        <Resource tag={"PageMessages::SendChannels"} />
                      </Box>
                    </FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        className={classes.labelCheckbox}
                        control={
                          <Checkbox
                            onChange={handleCheckbox}
                            name="internal-message-reply"
                            color="primary"
                            defaultChecked
                          />
                        }
                        label={
                          <Resource tag={"PageMessages::InternalMessage"} />
                        }
                      />
                      <FormControlLabel
                        className={classes.labelCheckbox}
                        control={
                          <Checkbox
                            onChange={handleCheckbox}
                            name="email-message-reply"
                            color="primary"
                          />
                        }
                        label={<Resource tag={"PageMessages::EmailMessage"} />}
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
                <Box
                  className="reply-buttons-cancel-send"
                  display="flex"
                  mt={matchXsSm ? 2 : 0}
                >
                  <Box mr={2}>
                    <Button
                      color="primary"
                      size="small"
                      variant="text"
                      onClick={() => setIsOpen(false)}
                    >
                      <Resource tag={"PageMessages::Cancel"} />
                    </Button>
                  </Box>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => handleMessageReply()}
                  >
                    <Resource tag={"PageMessages::Send"} />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
}

export default MessageReplyDialog;
