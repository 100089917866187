import { Reducer } from "redux";
import {
  IRegulationsHistory,
  IRegulationsPublish,
  IRegulationsState,
  RegulationsActions,
  RegulationsActionTypes,
} from "../../types/regulations";

// Define the initial state
const initialCharacterState: IRegulationsState = {
  regulationsHistory: [] as IRegulationsHistory[],
  regulationsPublish: {} as IRegulationsPublish,
  regulationsStatus: "SUCCESS",
  regulationsPostStatus: "SUCCESS",
  regulationsPutStatus: "SUCCESS",
};

export const regulationReducer: Reducer<
  IRegulationsState,
  RegulationsActions
> = (state = initialCharacterState, action) => {
  switch (action.type) {
    case RegulationsActionTypes.GET_REGULATIONS_HISTORY_LOADING: {
      return {
        ...state,
        regulationsStatus: action.regulationsStatus,
      };
    }
    case RegulationsActionTypes.GET_REGULATIONS_HISTORY_SUCCESS: {
      return {
        ...state,
        regulationsStatus: action.regulationsStatus,
        regulationsHistory: action.regulationsHistory,
      };
    }
    case RegulationsActionTypes.GET_REGULATIONS_HISTORY_FAIL: {
      return {
        ...state,
        regulationsStatus: action.regulationsStatus,
      };
    }
    case RegulationsActionTypes.GET_REGULATIONS_PUBLISH_LOADING: {
      return {
        ...state,
        regulationsStatus: action.regulationsStatus,
      };
    }
    case RegulationsActionTypes.GET_REGULATIONS_PUBLISH_SUCCESS: {
      return {
        ...state,
        regulationsStatus: action.regulationsStatus,
        regulationsPublish: action.regulationsPublish,
      };
    }
    case RegulationsActionTypes.GET_REGULATIONS_PUBLISH_FAIL: {
      return {
        ...state,
        regulationsStatus: action.regulationsStatus,
      };
    }
    case RegulationsActionTypes.POST_REGULATIONS_LOADING: {
      return {
        ...state,
        regulationsPostStatus: action.regulationsPostStatus,
      };
    }
    case RegulationsActionTypes.POST_REGULATIONS_SUCCESS: {
      return {
        ...state,
        regulationsPostStatus: action.regulationsPostStatus,
        regulationsHistory: action.regulationsHistory,
        regulationsPublish: action.regulationsPublish,
      };
    }
    case RegulationsActionTypes.POST_REGULATIONS_FAIL: {
      return {
        ...state,
        regulationsPostStatus: action.regulationsPostStatus,
      };
    }
    case RegulationsActionTypes.PUT_REGULATIONS_LOADING: {
      return {
        ...state,
        regulationsPutStatus: action.regulationsPutStatus,
      };
    }
    case RegulationsActionTypes.PUT_REGULATIONS_SUCCESS: {
      return {
        ...state,
        regulationsPutStatus: action.regulationsPutStatus,
        regulationsHistory: action.regulationsHistory,
      };
    }
    case RegulationsActionTypes.PUT_REGULATIONS_FAIL: {
      return {
        ...state,
        regulationsPutStatus: action.regulationsPutStatus,
      };
    }
    default:
      return state;
  }
};
