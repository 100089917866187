import React from "react";
import XDialog from "common/X/XDialog";
import { Box, Button } from "@material-ui/core";
import Resource from "components/Resources/Resource";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  content?: any;
  noCancelButton?: any;
  yesButton?: any;
}

function ExistingDraft({
  open,
  setOpen,
  content,
  noCancelButton,
  yesButton,
}: IProps) {
  const actions = (
    <Box textAlign="right">
      <Button
        color="primary"
        size="small"
        variant="outlined"
        style={{ marginRight: 8 }}
        onClick={noCancelButton}
      >
        <Resource tag="PageRegulation::NoCancel" />
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={yesButton}
      >
        <Resource tag="PageRegulation::YesReplace" />
      </Button>
    </Box>
  );

  return (
    <XDialog
      width="800px"
      maxWidth="md"
      open={open}
      setOpen={setOpen}
      title={"Existing draft"}
      actions={actions}
    >
      <Box textAlign="center">{content}</Box>
    </XDialog>
  );
}

export default ExistingDraft;
