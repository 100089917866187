import { Box, Button } from "@material-ui/core";
import XDialog from "common/X/XDialog";
import Resource, { resource } from "components/Resources/Resource";
import React from "react";
import { ICultureState } from "redux/reducers/cultureReducer";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  type: 1 | 2 | 3;
  culture?: ICultureState;
  action: () => any
}

function DialogPromotion({ type, open, culture, setOpen, action }: IProps) {
  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      title={<Resource tag="PageManageUsers::Promotion" />}
      actions={
        <Box textAlign="right">
          <Button onClick={() => setOpen(false)} size="small" color="primary" variant="outlined" style={{ marginRight: 12 }}>
            <Resource tag="PageManageUsers::NoCancel" />
          </Button>
          <Button 
            onClick={async () => {
              await action()
              setOpen(false)
            }} 
            size="small"
            color="primary" 
            variant="contained"
          >
            <Resource tag="PageManageUsers::Yes" />
          </Button>
        </Box>
      }
      maxWidth="sm"
    >
      <Box textAlign="center" mt={3}>
        {type === 1 && (
          <Resource
            tag="PageManageUsers::Promotion_Tip"
            args={[
              resource(culture, "PageManageUsers::Challenge_Manager"),
              resource(culture, "PageManageUsers::Admin"),
            ]}
          />
        )}
        {type === 2 && (
          <Resource
            tag="PageManageUsers::Promotion_Tip"
            args={[
              resource(culture, "PageManageUsers::Participant"),
              resource(culture, "PageManageUsers::Admin"),
            ]}
          />
        )}
        {type === 3 && (
          <Resource
            tag="PageManageUsers::Promotion_Tip"
            args={[
              resource(culture, "PageManageUsers::Participant"),
              resource(culture, "PageManageUsers::Challenge_Manager"),
            ]}
          />
        )}
      </Box>
    </XDialog>
  );
}

export default DialogPromotion;
