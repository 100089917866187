import React from "react";
import "./DecimalInput.css";
import ToolTip from "../../components/ToolTip/ToolTip";
import { ICustomInputProps, IInput } from "../../redux/types/inputs";
import { makeStyles, createStyles, TextField, Theme } from "@material-ui/core";

interface IProps {
  value: ICustomInputProps;
  handler: ICustomInputProps;
  propsI: IInput;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      padding: "15px 0 7px",
    },
    label: {
      fontWeight: "bold",
      fontSize: "1.05rem",
      textTransform: "uppercase",
      color: theme.palette.text.secondary,
    },
  })
);

function DecimalInput(props: IProps) {
  const classes = useStyles();
  let { propsI, value, handler } = props;

  const labelT = (
    <div className="textField-and-tooltip">
      {propsI.title}
      {propsI?.isMandatory ? " *" : ""}
      {propsI.help ? <ToolTip title="Help">{propsI.help}</ToolTip> : ""}
    </div>
  );

  return (
    <TextField
      type="number"
      fullWidth
      label={labelT}
      name={propsI.columnName}
      InputProps={{ readOnly: !propsI.isEditable }}
      required={propsI.isMandatory ? true : false}
      onChange={handler?.handler}
      defaultValue={
        value ? value : propsI.defaultValue ? propsI.defaultValue : ""
      }
      onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
      inputProps={{
        step: 0.01,
        pattern: propsI.validationExpression,
        className: classes.input,
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.label,
      }}
      {...(propsI.minValue != null ? { min: propsI.minValue } : {})}
      {...(propsI.maxValue != null ? { max: propsI.maxValue } : {})}
    />
  );
}

export default DecimalInput;
