import Truncate from "react-truncate";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useState } from "react";
import { IUsers } from "redux/reducers/usersReducers";
import { ICultureState } from "redux/reducers/cultureReducer";
import XAutocomplete from "common/UI/XAutocomplete/XAutocomplete";
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import { ValidationPopupState } from "redux/types/createChallenge";

import {
  Avatar,
  Box,
  Container,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";



interface IProps {
  users: IUsers[];
  culture: ICultureState;
  state: ValidationPopupState;
  dynamicWorkflow?: any;
  selectedItem?: any;
  fatherState: any;
  setFatherState: (newValue: Array<any>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    actualManagers: {
      marginRight: "auto",
      marginTop: theme.spacing(2),
      minWidth: "300px",
      width: "100%",
      gap: "24px",
      maxHeight: "150px",
      overflowY: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",

      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    listButton: {
      width: 25,
      height: 25,
      borderRadius: 5,
      boxShadow: "0 0 5px " + theme.palette.grey[400],
      marginTop: 4,
      marginBottom: 4,

      "&:hover": {
        borderRadius: 5,
      },
    },
    formProgressBar: {
      backgroundColor: theme.palette.primary.main,
      display: "inline-block",
      width: "32px",
      height: "5px !important",
      margin: "0 4px 0 0",
      opacity: "0.4",
      borderRadius: "5px",
      "&.active": {
        opacity: 1,
      },
    },
    widthRadioBtn: {
      "& .MuiSvgIcon-root": {
        width: "0.8em",
        height: "0.8em",
      },
    },
    titleRadioGroup: {
      color: theme.palette.text.secondary,
      fontSize: "0.8rem",
      fontWeight: 700,
      paddingBottom: "10px",
      textTransform: "uppercase",
    },
    labelRadioBtn: {
      marginRight: "50px",
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
      },
    },
    labelRadio: {      
      "&:before": {
        border: "solid 2px" + theme.palette.primary.main,
      },
      "&:after": {
        backgroundColor: theme.palette.primary.main,
      },
    },    
  })
);


function ValidatorSearch(props: IProps) {
  const classes = useStyles();
  const [autoCompleteValue, setACVAlue] = useState(props?.dynamicWorkflow?.optionSelect[props.selectedItem]?.users);
  const handleDeleteValue = (value: any) => {
    setACVAlue(autoCompleteValue.filter((acv: any) => acv.userId !== value));
  };

  const handleValue = (newValue:string[]) => {
    setACVAlue(newValue); 
    if(props?.dynamicWorkflow?.optionSelect[props.selectedItem]){
      props.dynamicWorkflow.optionSelect[props.selectedItem].users = newValue;
    }
  }
  return (
    <>
      <Container >
        <Box mt={2} width="75%">
          <XAutocomplete
            options={props?.users}
            optionName={"fullName"}
            optionAvatar={"photo"}
            optionSubtitle={"email"}
            autoCompleteValue={autoCompleteValue}
            onChange={(newValue) => {handleValue(newValue)}}
            culture={props?.culture}
          />
        </Box>
        
        <Box mt={1}>
          <Box className={classes.actualManagers}>
            {autoCompleteValue?.map((value: any, index: number) => {
              return (
                <div className="manager-item-containerPopup" key={value.userId}>
                  <Avatar
                    src={value?.photo}
                    alt={value.fullName}
                    className={classes.thumbnail}
                  />
                  <div className="manager-item-label">
                    <Truncate width={120} ellipsis={<span>...</span>}>
                      {value.fullName}
                    </Truncate>
                  </div>
                  <div className="manage-item-cross">
                    <IconButton
                      className="bottom-manage-item-cross"
                      onClick={() => handleDeleteValue(value.userId)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })}
          </Box>
        </Box>     
      </Container>
    </>  
  );
}

const mapStateToProps = (store: IAppState, ownProps: IProps) => ({
    state: store.createChallengeState.validationPopupState,
    fatherState: ownProps.fatherState,
    dynamicWorkflow: ownProps.dynamicWorkflow,
    selectedItem: ownProps.selectedItem,
});

export default connect(mapStateToProps)(ValidatorSearch);
