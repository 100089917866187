import history from "routes/history";
import Truncate from "react-truncate";
import { connect } from "react-redux";
import newApp from "utils/reactNewApp";
import React, { useState } from "react";
import sanitizeHtml from "sanitize-html";
import EMIcon from "components/Icon/Icon";
import { Skeleton } from "@material-ui/lab";
import { ShortDateFormat } from "utils/FormatDate";
import { IAppState } from "../../redux/store/Store";
import Resource from "components/Resources/Resource";
import { Link as RouterLink } from "react-router-dom";
import NewsHighlightedTabs from "./NewsHighlightedTabs";
import { securityTextEditor } from "utils/securityTextEditor";
import { INewsHighlighted, INewsPopular } from "redux/types/news";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import {
  makeStyles,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Link,
  Grid,
  Button,
  Box,
  Paper,
  createStyles,
  Theme,
  useTheme,
  MenuItem,
  Fade,
  CardActionArea,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridGap: "24px",
      [theme.breakpoints.between("xs", "sm")]: {
        height: "fit-content",
      },
    },
    media: {
      height: "193px",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      borderRadius: 5,
    },
    body: {
      alignSelf: "baseline",
      textAlign: "start",
      position: "relative",
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 0",
    },
    readmore: {
      textTransform: "uppercase",
      fontSize: "0.75rem",
    },
    overlayTopic: {
      position: "absolute",
      top: "20px",
      left: "20px",
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      padding: "1px 7px 1px 5px",
    },
    topic: {
      color: theme.palette.text.primary,
      fontWeight: "bold",
      fontSize: "0.6rem",
    },
    overlayLikes: {
      position: "absolute",
      top: "165px",
      right: "12px",
      padding: "2px 7px 1px 5px",
      backgroundColor: "rgba(0,0,0,0.4)",
      minWidth: 35,
    },
    likeText: {
      color: theme.palette.common.white,
      fontSize: "0.79rem",
    },
    likeIcon: {
      color: theme.palette.common.white,
      marginLeft: 5,
    },
    lineClamp: {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    title: {
      WebkitLineClamp: 3,
      fontSize: "1.3em",
      lineHeight: "normal",
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },
    description: {
      fontSize: "0.8rem",
    },
    content: {
      padding: 0,
    },
  })
);

interface IProps {
  popular: INewsPopular;
  highlighted: INewsHighlighted;
  handleDelete: (newsId: string) => void;
  handleHighlight: (newsId: string, isHighlighted: boolean) => void;
  isLoading: boolean;
  moreOptions?:boolean;
}

function NewsHighlightedCard(props: IProps) {
  const {
    highlighted,
    popular,
    handleDelete,
    handleHighlight,
    isLoading,
    moreOptions
  } = props;
  const newsBackURL = "/news/details";
  const classes = useStyles();
  const theme = useTheme();
  const [deleteOpen, setDeleteOpen] = useState(false);

  return (
    <>
      <Box mt={2} mb={3} className={classes.root}>
        <Card elevation={0}>
          <Grid container>
            {!isLoading ? (
              <>
                {highlighted?.result?.map((highPrincipal, index) =>
                  index === 0 ? (
                    <Grid item md={8} key={highPrincipal?.newsId}>
                      <PopUpConfirmation
                        closeButton={() => setDeleteOpen(false)}
                        sendButton={() =>
                          handleDelete &&
                          handleDelete(highPrincipal?.newsId ?? "")
                        }
                        message={<Resource tag="Common::ConfirmAction" />}
                        isOpen={deleteOpen}
                        setIsOpen={setDeleteOpen}
                      />
                      <Fade timeout={1000} in={true} mountOnEnter unmountOnExit>
                        <Box mt={1}>
                          <Box className={classes.body}>
                            <CardMedia
                              className={classes.media}
                              image={`${highPrincipal?.image}`}
                              onClick={() => {
                                history.push({
                                  pathname: `${newsBackURL}/${highPrincipal?.newsId}`,
                                });
                              }}
                            >
                              {highPrincipal?.topic && (
                                <Paper className={classes.overlayTopic}>
                                  <Typography className={classes.topic}>
                                    {highPrincipal?.topic}
                                  </Typography>
                                </Paper>
                              )}
                              {newApp && (moreOptions?? true) ? (
                                <Box
                                  style={{
                                    position: "absolute",
                                    right: "3px",
                                    top: "1px",
                                    padding: 6,
                                  }}
                                >
                                  <MoreOptionsButton
                                    iconColor={theme.palette.common.white}
                                    translate="-83px, 42px"
                                  >
                                    <MenuItem
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(
                                          `/editNews/${highPrincipal?.newsId}`
                                        );
                                      }}
                                    >
                                      <Resource tag="PageNews::EditNews" />
                                    </MenuItem>
                                    <MenuItem
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleHighlight(
                                          highPrincipal.newsId,
                                          false
                                        );
                                      }}
                                    >
                                      <Resource tag="PageNews::RemoveHighlight" />
                                    </MenuItem>
                                    <MenuItem
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteOpen(true);
                                      }}
                                    >
                                      <Resource tag="PageNews::Delete" />
                                    </MenuItem>
                                  </MoreOptionsButton>
                                </Box>
                              ) : (
                                ""
                              )}
                              {highPrincipal?.nrLikes > 0 && (
                                <Paper className={classes.overlayLikes}>
                                  <Box
                                    width="fit-content"
                                    display="flex"
                                    flexDirection="row"
                                    paddingTop="1px"
                                  >
                                    <Box>
                                      <Typography className={classes.likeText}>
                                        {highPrincipal?.nrLikes}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <EMIcon
                                        size={13}
                                        className={classes.likeIcon}
                                        color={theme.palette.common.white}
                                        icon="like"
                                      />
                                    </Box>
                                  </Box>
                                </Paper>
                              )}
                            </CardMedia>
                            <CardContent className={classes.content}>
                              <Box mt={1}>
                                <Typography
                                  gutterBottom
                                  variant="subtitle2"
                                  color="textSecondary"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  <ShortDateFormat
                                    date={highPrincipal?.publicationDate}
                                  />
                                </Typography>
                              </Box>
                              <Box mt={1}>
                                <Link
                                  component={RouterLink}
                                  style={{
                                    textDecoration: "none",
                                  }}
                                  to={{
                                    pathname: `${newsBackURL}/${highPrincipal?.newsId}`,
                                  }}
                                >
                                  <Typography
                                    className={`${classes.title} + ${classes.lineClamp}`}
                                    variant="subtitle2"
                                  >
                                    {highPrincipal?.title}
                                  </Typography>
                                </Link>
                              </Box>
                              <Box mt={2}>
                                <Truncate
                                  lines={4}
                                  width={557}
                                  trimWhitespace
                                  ellipsis={<span>(...)</span>}
                                >
                                  <Typography
                                    className={classes.description}
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizeHtml(
                                        highPrincipal?.description,
                                        securityTextEditor
                                      ),
                                    }}
                                  />
                                </Truncate>
                              </Box>
                            </CardContent>
                          </Box>
                          <CardActions
                            disableSpacing
                            className={classes.actions}
                          >
                            <Typography variant="caption" color="primary">
                              <Link
                                component={RouterLink}
                                style={{
                                  textDecoration: "none",
                                }}
                                to={{
                                  pathname: `${newsBackURL}/${highPrincipal?.newsId}`,
                                }}
                              >
                                <Button variant="text" color="primary">
                                  <Typography
                                    variant="button"
                                    className={classes.readmore}
                                  >
                                    <Resource tag={"Common::ReadMore"} />
                                  </Typography>
                                </Button>
                              </Link>
                            </Typography>
                          </CardActions>
                        </Box>
                      </Fade>
                    </Grid>
                  ) : (
                    ""
                  )
                )}
              </>
            ) : (
              <>
                <Box style={{ width: "66%", padding: "16px 0" }}>
                  <Card>
                    <CardActionArea>
                      <Skeleton variant="rect" height={230} />
                      <CardContent>
                        <Skeleton width="60%" />
                        <Skeleton />
                      </CardContent>
                    </CardActionArea>
                    <CardActions disableSpacing>
                      <Skeleton />
                    </CardActions>
                  </Card>
                </Box>
              </>
            )}
            <Grid item md={4}>
              {!isLoading ? (
                <Box ml={2}>
                  <NewsHighlightedTabs
                    highlighted={highlighted}
                    popular={popular}
                  />
                </Box>
              ) : (
                <Box style={{ padding: "12px 8px" }}>
                  <Skeleton width="60%" />
                  <Skeleton />
                </Box>
              )}
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    news: store.newsState.news,
  };
};

export default connect(mapStateToProps)(NewsHighlightedCard);
