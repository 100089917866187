import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { ICultureState } from "redux/reducers/cultureReducer";
import { useEffect } from "react";
import { IAllIdeasState } from "redux/reducers/ideas/AllIdeasReducer";
import SkeletonRandomCards from "common/UI/Skeleton/SkeletonRandomCards";
import { getProfile } from "redux/actions/ProfileActions";
import CollaborativeIdeaList from "containers/CollaborativeIdeaList/CollaborativeIdeaList";
import {
  deleteIdeasLikes,
  postIdeasInvestments,
  postIdeasLikes,
} from "redux/actions/ideas/IdeaAction";
import {
  getAllIdeas,
  getIdeasCollaborative,
  getMoreIdeasCollaborative,
} from "redux/actions/ideas/AllIdeasAction";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";

interface IDispatchProps {
  getIdeasCollaborative: (options: object) => Promise<IAllIdeasState>;
  getMoreIdeasCollaborative: (options: object) => Promise<IAllIdeasState>;
  postInvestments: (options: object) => Promise<any>;
  postLikes: (options: object) => Promise<any>;
  deleteLikes: (options: object) => Promise<any>;
  getAllIdeas: (options: object) => Promise<IAllIdeasState>;
  getProfile: () => Promise<any>;
}

interface IStateProps {
  ideasCollaborative: any;
  collaborationIdeasStatus: string;
  applicationId: string;
  culture: ICultureState;
  challengeId: string;
  workspaceId: string;
  q: string;
}

function CollaborativeEvaluation(props: IDispatchProps & IStateProps) {
  const {
    ideasCollaborative,
    getIdeasCollaborative,
    getMoreIdeasCollaborative,
    collaborationIdeasStatus,
    postInvestments,
    applicationId,
    culture,
    workspaceId,
    deleteLikes,
    postLikes,
    getAllIdeas,
    getProfile,
    challengeId,
    q,
  } = props;

  const postInvestmentsAction = async (options: object) => {
    options = {
      ...options,
      cultureTag: culture.culture.cultureTag,
      workspaceId
    }

    await postInvestments(options).then(() => {
      getProfile();
    });
  };

  useEffect(() => {
    getIdeasCollaborative({
      challengeId,
      q,
      pageNumber: 1,
      pageSize: 3,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ideas" style={{ paddingTop: "10px" }}>
      {collaborationIdeasStatus !== "LOADING" ? (
        <DynamicInfiniteScroll
          pageSize={3}
          dataList={ideasCollaborative}
          getAction={(pageNumber: number, pageSize: number) =>
            getMoreIdeasCollaborative({
              challengeId,
              q,
              pageNumber,
              pageSize,
            })}
          isAutomatic
        >
          <CollaborativeIdeaList
            ideasInCollaboration={ideasCollaborative}
            aleatory={false}
            postInvestments={postInvestmentsAction}
            applicationId={applicationId}
            culture={culture}
            postLikes={postLikes}
            deleteLikes={deleteLikes}
            getAllIdeas={getAllIdeas}
          />
        </DynamicInfiniteScroll>
      ) : (
        <SkeletonRandomCards />
      )}
    </div>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getIdeasCollaborative: (parameters: object) =>
      dispatch(getIdeasCollaborative(parameters)),
    getMoreIdeasCollaborative: (parameters: object) =>
      dispatch(getMoreIdeasCollaborative(parameters)),
    postInvestments: (options: object) =>
      dispatch(postIdeasInvestments(options)),
    getProfile: () => dispatch(getProfile()),
    postLikes: (options: object) => dispatch(postIdeasLikes(options)),
    deleteLikes: (options: object) => dispatch(deleteIdeasLikes(options)),
    getAllIdeas: (parameters: object) => dispatch(getAllIdeas(parameters)),
  };
};

const mapStateToProps = (store: IAppState, ownProps: any) => {
  return {
    q: ownProps?.location?.state?.q,
    challengeId: ownProps?.location?.state?.challengeId,
    ideasCollaborative: store.allIdeasState.ideas.ideasInCollaboration.ideas,
    collaborationIdeasStatus:
      store.allIdeasState.ideas.ideasInCollaboration.status,
    culture: store.cultureState,
    applicationId: store.authState.auth.applicationId,
    workspaceId: store.authState.auth.workspaceId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollaborativeEvaluation);
