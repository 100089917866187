import "./ListPrizeUserWinning.css";
import React, { useState } from "react";
import Resource from "../Resources/Resource";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import UserWinning from "../UserWinning/UserWinning";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import PopUpListWinning from "../../components/PopUpListWinning/PopUpListWinning";
import {
  Typography,
  makeStyles,
  IconButton,
  createStyles,
  Theme,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    title: {
      padding: "20px 40px 16px",
    },
    body: {
      padding: "0px 40px 32px",
    },
  })
);

interface IProps {
  winners: [];
  bids: [];
  mode: string;
  cultureTag?: string;
  total: number;
}

function ListPrizeUserWinning(props: IProps) {
  const [viewAll, setViewAll] = useState(false);
  const classes = useStyles();

  let buttonView;
  let show;
  const { winners, mode, cultureTag, total } = props;

  if (viewAll) {
    show = 5;
  } else {
    show = 5;
    buttonView = (
      <div
        className="ListPrizeUserWinningFooter"
        onClick={() => setViewAll(true)}
      >
        <Resource tag={"PagePrizes::TopBidders"} args={[winners?.length]} />
      </div>
    );
  }

  let winnersQuantity;
  if (mode === "bid") {
    winnersQuantity = (
      <>
        <strong>{total}</strong>{" "}
        <Resource tag={"PagePrizes::BidsAlreadyMade"} />
      </>
    );
  } else if (mode === "buy-now") {
    winnersQuantity = (
      <strong>
        <Resource tag={"PagePrizes::Winners"} />:
      </strong>
    );
  } else if (mode === "previous") {
    winnersQuantity = (
      <strong>
        <Resource tag={"PagePrizes::Winners"} />:
      </strong>
    );
  }

  return (
    <div className="ListPrizeUserWinning">
      <div className="ListPrizeUserWinningTitle">
        {winners?.length > 0 ? winnersQuantity : ""}
      </div>
      <div>
        {winners.slice(0, show).map((winn: any) => {
          return (
            <UserWinning
              cultureTag={cultureTag}
              mode={mode}
              key={Math.random()}
              userid={winn.userId}
              image={winn.image}
              name={winn.name}
              value={winn.value || winn.bidValue}
              position={winn.position}
            />
          );
        })}
      </div>
      {total > show && buttonView}

      <Dialog fullWidth maxWidth={"sm"} open={viewAll}>
        <MuiDialogTitle className={classes.title}>
          <Typography variant="h4">
            <Resource tag="PagePrizes::WinningOfX" args={[""]} />
          </Typography>
          <IconButton
            // aria-label="close"
            className={classes.closeButton}
            onClick={() => setViewAll(false)}
          >
            <CloseIcon />
          </IconButton>
          <Divider className={classes.divider}></Divider>
        </MuiDialogTitle>
        <MuiDialogContent className={classes.body}>
          <PopUpListWinning mode={mode} winners={winners} />
        </MuiDialogContent>
      </Dialog>
    </div>
  );
}

export default ListPrizeUserWinning;
