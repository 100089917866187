
// Import redux types
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api_general } from "./../../config/api";
import { toast } from "react-toastify";
 

// Import Character Typing
import { IPasswordState } from '../reducers/passwordReducer';
import { resource } from 'components/Resources/Resource';


const basePath = "accounts/password";

export enum PasswordActionTypes {
    RECOVER_PASSWORD = 'RECOVER_PASSWORD',
    VERIFY_CODE = 'VERIFY_CODE',
    UPDATE_PASSWORD = 'UPDATE_PASSWORD'
}

export interface IPasswordRecoverAction {
    type: PasswordActionTypes.RECOVER_PASSWORD;
    emailValidation: boolean;
}

export interface IPasswordVerifyAction {
    type: PasswordActionTypes.VERIFY_CODE;
    codeValidation: boolean;
}

export interface IPasswordUpdateAction {
    type: PasswordActionTypes.UPDATE_PASSWORD;
    updateValidation: boolean;
}

export type PasswordActions =
 |  IPasswordRecoverAction
 |  IPasswordVerifyAction
 |  IPasswordUpdateAction

export const recoverPassword: ActionCreator<
  ThunkAction<Promise<any>, IPasswordState, null, IPasswordRecoverAction>
> = (options) => {
    return async (dispatch: Dispatch) => {
        return new Promise((resolve, reject) => {
            let params    = [];
            let params_qs = "";
            
            if( options.hasOwnProperty("email") ){
                params.push(`email=${options.email}`);
            }
            if( options.hasOwnProperty("captcha") ){
                params.push(`captcha=${options.captcha}`);
            }
            if( params.length ){
                params_qs = `/recover?${params.join("&")}`;
            }

            api_general.post(`${basePath}${params_qs}`, {}).then(response=>{
                dispatch({
                    emailValidation: response.status,
                    type: PasswordActionTypes.RECOVER_PASSWORD,
                })
                toast.success(resource(options.culture, 'PageLogin::ResetPassword_CodeSended'))
                return resolve(response)
            }, error=>{              
                //treatError(options.culture, 'Password', error);
                return reject(error)
            });
        })
    };
};

export const updatePassword: ActionCreator<
  ThunkAction<Promise<any>, IPasswordState, null, IPasswordUpdateAction>
> = (options) => {
    return async (dispatch: Dispatch) => {
        return new Promise((resolve, reject) => {
            let params    = [];
            let params_qs = "";
            if( options.hasOwnProperty("email") ){
                params.push(`email=${options.email}`);
            }
            if( options.hasOwnProperty("code") ){
                params.push(`code=${options.code}`);
            }
            if( options.hasOwnProperty("password") ){
                params.push(`password=${options.password}`);
            }
            if( params.length ){
                params_qs = `/redefine?${params.join("&")}`;
            }
            api_general.post(`${basePath}${params_qs}`, {}).then(response => {
                dispatch({
                    emailValidation: response.status,
                    type: PasswordActionTypes.UPDATE_PASSWORD,
                })
                toast.success(resource(options.culture, 'PageLogin::ResetPassword_Success'))
                return resolve(response);
            }, error =>{ 
                return reject(error);
            });
        })
    };
};

export const validateRecoverCode: ActionCreator<
ThunkAction<Promise<any>, IPasswordState, null, IPasswordVerifyAction>
> = (options) => {
    return async (dispatch: Dispatch) => {
        return new Promise((resolve, reject) => {
            let params    = [];
            let params_qs = "";
            if( options.hasOwnProperty("email") ){
                params.push(`email=${options.email}`);
            }
            if( options.hasOwnProperty("code") ){
                params.push(`code=${options.code}`);
            }
            if( params.length ){
                params_qs = `/validatecode?${params.join("&")}`;
            }
            
            api_general.get(`${basePath}${params_qs}`, {}).then(response =>{
                dispatch({
                    validateRecoverCode: response.data,
                    type: PasswordActionTypes.VERIFY_CODE,
                })
                return resolve(response);
            }, error => {
                return reject(error);
            });
        })
    };

};