import sanitizeHtml from "sanitize-html";

export const securityTextEditor = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat([
    "figure",
    "img",
    "iframe",
    "video",
  ]),
  selfClosing: ["img", "br", "hr", "area", "base", "basefont", "link", "meta"],
  allowedAttributes: {
    img: sanitizeHtml.defaults.allowedAttributes.img.concat([
      "data-*",
      "origin-size",
      "style",
    ]),
    figure: ["style"],
    div: ["class", "se-*", "contenteditable", "style"],
    iframe: ["frameborder", "src", "width", "height", "class"],
    video: ["class", "controls", "width", "height", "src"],
    span: ["style"],
    a: ["href", "style"],
    p: ["style"],
    h1: ["style"],
    h2: ["style"],
    h3: ["style"],
    h4: ["style"],
    h5: ["style"],
    h6: ["style"],
    em: ["style"],
    u: ["style"],
    strong: ["style"],
  },
  allowedSchemes: ["data", "https", "http"],
};

export const securityTextEditorWithoutStyles = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat([
    "figure",
    "img",
    "iframe",
    "video",
  ]),
  selfClosing: ["img", "br", "hr", "area", "base", "basefont", "link", "meta"],
  allowedAttributes: {
    img: sanitizeHtml.defaults.allowedAttributes.img.concat([
      "data-*",
      "origin-size",
      "style",
    ]),
    figure: ["style"],
    div: ["class", "se-*", "contenteditable"],
    iframe: ["frameborder", "src", "width", "height", "class"],
    video: ["class", "controls", "width", "height", "src"],
    a: ["href"],
  },
  allowedSchemes: ["data", "https", "http"],
};
