import { ThunkAction } from "redux-thunk";
import reactNewApp from "utils/reactNewApp";
import { ActionCreator, Dispatch } from "redux";
import { treatError } from "../../utils/treatError";
import { api_v2, api_general } from "./../../config/api";
import { IMessagesState } from "redux/reducers/messagesReducer";
import {
  IMessagesArchived,
  IMessagesDetail,
  IMessagesForwardHeader,
  IMessagesReceived,
  IMessagesSent,
} from "redux/types/messages";

// Import Character Typing

const basePath = "messages";

// Create Action Constants
export enum MessagesActionTypes {
  GET_ALL_MESSAGES_RECEIVED_LOADING = "GET_ALL_MESSAGES_RECEIVED_LOADING",
  GET_ALL_MESSAGES_RECEIVED_SUCCEEEDED = "GET_ALL_MESSAGES_RECEIVED_SUCCEEEDED",
  GET_ALL_MESSAGES_RECEIVED_FAILED = "GET_ALL_MESSAGES_RECEIVED_FAILED",

  GET_ALL_MESSAGES_SEND_LOADING = "GET_ALL_MESSAGES_SEND_LOADING",
  GET_ALL_MESSAGES_SEND_SUCCEEEDED = "GET_ALL_MESSAGES_SEND_SUCCEEEDED",
  GET_ALL_MESSAGES_SEND_FAILED = "GET_ALL_MESSAGES_SEND_FAILED",

  GET_ALL_MESSAGES_ARCHIVED_LOADING = "GET_ALL_MESSAGES_ARCHIVED_LOADING",
  GET_ALL_MESSAGES_ARCHIVED_SUCCEEEDED = "GET_ALL_MESSAGES_ARCHIVED_SUCCEEEDED",
  GET_ALL_MESSAGES_ARCHIVED_FAILED = "GET_ALL_MESSAGES_ARCHIVED_FAILED",

  GET_ALL_MESSAGES_DETAILS_LOADING = "GET_ALL_MESSAGES_DETAILS_LOADING",
  GET_ALL_MESSAGES_DETAILS_SUCCEEEDED = "GET_ALL_MESSAGES_DETAILS_SUCCEEEDED",
  GET_ALL_MESSAGES_DETAILS_FAILED = "GET_ALL_MESSAGES_DETAILS_FAILED",

  GET_ALL_MESSAGES_FORWARDHEADER_LOADING = "GET_ALL_MESSAGES_FORWARDHEADER_LOADING",
  GET_ALL_MESSAGES_FORWARDHEADER_SUCCEEEDED = "GET_ALL_MESSAGES_FORWARDHEADER_SUCCEEEDED",
  GET_ALL_MESSAGES_FORWARDHEADER_FAILED = "GET_ALL_MESSAGES_FORWARDHEADER_FAILED",

  POST_MESSAGES_SUCCEEDED = "POST_MESSAGES_SUCCEEDED",
  POST_MESSAGES_FAILED = "POST_MESSAGES_FAILED",

  POST_MESSAGES_ARCHIVE_SUCCEEDED = "POST_MESSAGES_ARCHIVE_SUCCEEDED",
  POST_MESSAGES_ARCHIVE_FAILED = "POST_MESSAGES_ARCHIVE_FAILED",

  POST_MESSAGES_REPLY_SUCCEEDED = "POST_MESSAGES_REPLY_SUCCEEDED",
  POST_MESSAGES_REPLY_FAILED = "POST_MESSAGES_REPLY_FAILED",

  POST_MESSAGES_REPLYALL_SUCCEEDED = "POST_MESSAGES_REPLYALL_SUCCEEDED",
  POST_MESSAGES_REPLYALL_FAILED = "POST_MESSAGES_REPLY_FAILED",

  DELETE_MESSAGES_SUCCEEDED = "DELETE_MESSAGES_SUCCEEDED",
  DELETE_MESSAGES_FAILED = "DELETE_MESSAGES_FAILED",
}

export enum MessagesActionStatus {
  GET_ALL_MESSAGES_LOADING = "LOADING",
  GET_ALL_MESSAGES_SUCCEEDED = "SUCCEEDED",
  GET_ALL_MESSAGES_FAILED = "FAILED",

  GET_ALL_MESSAGES_ARCHIVE_LOADING = "LOADING",
  GET_ALL_MESSAGES_ARCHIVE_SUCCEEDED = "SUCCEEDED",
  GET_ALL_MESSAGES_ARCHIVE_FAILED = "FAILED",

  GET_ALL_MESSAGES_SENT_LOADING = "LOADING",
  GET_ALL_MESSAGES_SENT_SUCCEEDED = "SUCCEEDED",
  GET_ALL_MESSAGES_SENT_FAILED = "FAILED",

  GET_ALL_MESSAGES_DETAIL_LOADING = "LOADING",
  GET_ALL_MESSAGES_DETAIL_SUCCEEDED = "SUCCEEDED",
  GET_ALL_MESSAGES_DETAIL_FAILED = "FAILED",

  GET_ALL_MESSAGES_FORWARDHEADER_LOADING = "LOADING",
  GET_ALL_MESSAGES_FORWARDHEADER_SUCCEEDED = "SUCCEEDED",
  GET_ALL_MESSAGES_FORWARDHEADER_FAILED = "FAILED",

  POST_MESSAGES_LOADING = "LOADING",
  POST_MESSAGES_SUCCEEDED = "SUCCEEDED",
  POST_MESSAGES_FAILED = "FAILED",

  POST_MESSAGES_SENT_SUCCEEDED = "SUCCEEDED",
  POST_MESSAGES_SENT_FAILED = "FAILED",

  POST_MESSAGES_ARCHIVE_SUCCEEDED = "SUCCEEDED",
  POST_MESSAGES_ARCHIVE_FAILED = "FAILED",

  POST_MESSAGES_REPLY_SUCCEEDED = "SUCCEEDED",
  POST_MESSAGES_REPLY_FAILED = "FAILED",

  POST_MESSAGES_REPLYALL_SUCCEEDED = "SUCCEEDED",
  POST_MESSAGES_REPLYALL_FAILED = "FAILED",

  DELETE_MESSAGES_SUCCEEDED = "SUCCEEDED",
  DELETE_MESSAGES_FAILED = "FAILED",
}

export interface IMessageLoadingGetReceivedAction {
  type: MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_LOADING;
  status: string;
}

export interface IMessageSucceededGetReceivedAction {
  type: MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_SUCCEEEDED;
  payload: IMessagesReceived;
  status: string;
}

export interface IMessageFailedGetReceivedAction {
  type: MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_FAILED;
  status: string;
}

export interface IMessageLoadingGetSent {
  type: MessagesActionTypes.GET_ALL_MESSAGES_SEND_LOADING;
  status: string;
}

export interface IMessageSucceededGetSent {
  type: MessagesActionTypes.GET_ALL_MESSAGES_SEND_SUCCEEEDED;
  payload: IMessagesSent;
  status: string;
}

export interface IMessageFailedGetSent {
  type: MessagesActionTypes.GET_ALL_MESSAGES_SEND_FAILED;
  status: string;
}

export interface IMessageLoadingGetArchived {
  type: MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_LOADING;
  status: string;
}

export interface IMessageSucceededGetArchived {
  type: MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_SUCCEEEDED;
  payload: IMessagesArchived;
  status: string;
}

export interface IMessageFailedGetArchived {
  type: MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_FAILED;
  status: string;
}

export interface IMessagesLoadingGetDetail {
  type: MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_LOADING;
  status: string;
}

export interface IMessagesSucceededGetDetail {
  type: MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_SUCCEEEDED;
  payload: IMessagesDetail;
  status: string;
}

export interface IMessagesFailedGetDetail {
  type: MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_FAILED;
  status: string;
}

export interface IMessagesLoadingForwardHeader {
  type: MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_LOADING;
  status: string;
}

export interface IMessagesSucceededForwardHeader {
  type: MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_SUCCEEEDED;
  payload: IMessagesForwardHeader;
  status: string;
}

export interface IMessagesFailedForwardHeader {
  type: MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_FAILED;
  status: string;
}

export interface IMessagesPostSucceeded {
  type: MessagesActionTypes.POST_MESSAGES_SUCCEEDED;
  status: string;
}

export interface IMessagesPostFailed {
  type: MessagesActionTypes.POST_MESSAGES_FAILED;
  status: string;
}

export interface IMessagesSucceededPostArchive {
  type: MessagesActionTypes.POST_MESSAGES_ARCHIVE_SUCCEEDED;
  status: string;
}

export interface IMessageFailedPostArchive {
  type: MessagesActionTypes.POST_MESSAGES_ARCHIVE_FAILED;
  status: string;
}

export interface IMessageSucceededPostReply {
  type: MessagesActionTypes.POST_MESSAGES_REPLY_SUCCEEDED;
  status: string;
}

export interface IMessageFailedPostReply {
  type: MessagesActionTypes.POST_MESSAGES_REPLY_FAILED;
  status: string;
}

export interface IMessageSucceededPostReplyAll {
  type: MessagesActionTypes.POST_MESSAGES_REPLYALL_SUCCEEDED;
  status: string;
}

export interface IMessageFailedReplyAll {
  type: MessagesActionTypes.POST_MESSAGES_REPLYALL_FAILED;
  status: string;
}

export interface IMessageSucceededDeleteMessage {
  type: MessagesActionTypes.DELETE_MESSAGES_SUCCEEDED;
  status: string;
}

export interface IMessageFailedDeleteMessage {
  type: MessagesActionTypes.DELETE_MESSAGES_FAILED;
  status: string;
}

export type MessageActions =
  | IMessageLoadingGetReceivedAction
  | IMessageSucceededGetReceivedAction
  | IMessageFailedGetReceivedAction
  | IMessageLoadingGetSent
  | IMessageSucceededGetSent
  | IMessageFailedGetSent
  | IMessageLoadingGetArchived
  | IMessageSucceededGetArchived
  | IMessageFailedGetArchived
  | IMessagesLoadingGetDetail
  | IMessagesSucceededGetDetail
  | IMessagesFailedGetDetail
  | IMessagesLoadingForwardHeader
  | IMessagesSucceededForwardHeader
  | IMessagesFailedForwardHeader
  | IMessagesPostSucceeded
  | IMessagesPostFailed
  | IMessagesSucceededPostArchive
  | IMessageFailedPostArchive
  | IMessageSucceededPostReply
  | IMessageFailedPostReply
  | IMessageSucceededPostReplyAll
  | IMessageFailedReplyAll
  | IMessageSucceededDeleteMessage
  | IMessageFailedDeleteMessage;

export const getAllMessagesReceived: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessageSucceededGetReceivedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_LOADING,
        type: MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_LOADING,
      });

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options?.pageNumber || 1}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options?.pageSize || 8}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/received${params_qs}`, {})
            .then((response) => {
              dispatch({
                payload: response.data,
                status: MessagesActionStatus.GET_ALL_MESSAGES_SUCCEEDED,
                type: MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_SUCCEEEDED,
              });
            })
        : await api_v2
            .get(`${basePath}/received${params_qs}`, {})
            .then((response) => {
              dispatch({
                payload: response.data,
                status: MessagesActionStatus.GET_ALL_MESSAGES_SUCCEEDED,
                type: MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_SUCCEEEDED,
              });
            });
    } catch (err) {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_FAILED,
        type: MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_FAILED,
      });
      treatError(options.culture, "PageMessages::Header", err);
    }
  };
};

export const getAllMessagesSent: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessageSucceededGetSent
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_SENT_LOADING,
        type: MessagesActionTypes.GET_ALL_MESSAGES_SEND_LOADING,
      });

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options?.pageNumber || 1}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options?.pageSize || 8}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      if (reactNewApp) {
        const response = await api_general.get(
          `${basePath}/sent${params_qs}`,
          {}
        );
        dispatch({
          payload: response.data,
          status: MessagesActionStatus.GET_ALL_MESSAGES_SENT_SUCCEEDED,
          type: MessagesActionTypes.GET_ALL_MESSAGES_SEND_SUCCEEEDED,
        });
      } else {
        const response = await api_v2.get(`${basePath}/sent${params_qs}`, {});
        dispatch({
          payload: response.data,
          status: MessagesActionStatus.GET_ALL_MESSAGES_SENT_SUCCEEDED,
          type: MessagesActionTypes.GET_ALL_MESSAGES_SEND_SUCCEEEDED,
        });
      }
    } catch (err) {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_SENT_FAILED,
        type: MessagesActionTypes.GET_ALL_MESSAGES_SEND_FAILED,
      });
      treatError(options.culture, "PageMessages::Header", err);
    }
  };
};

export const getAllMessagesArchived: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessageSucceededGetArchived
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_ARCHIVE_LOADING,
        type: MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_LOADING,
      });

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options?.pageNumber || 1}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options?.pageSize || 8}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      if (reactNewApp) {
        const response = await api_general.get(
          `${basePath}/archived${params_qs}`,
          {}
        );
        dispatch({
          payload: response.data,
          status: MessagesActionStatus.GET_ALL_MESSAGES_ARCHIVE_SUCCEEDED,
          type: MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_SUCCEEEDED,
        });
      } else {
        const response = await api_v2.get(
          `${basePath}/archived${params_qs}`,
          {}
        );
        dispatch({
          payload: response.data,
          status: MessagesActionStatus.GET_ALL_MESSAGES_ARCHIVE_SUCCEEDED,
          type: MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_SUCCEEEDED,
        });
      }
    } catch (err) {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_ARCHIVE_FAILED,
        type: MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_FAILED,
      });
      treatError(options.culture, "PageMessages::Header", err);
    }
  };
};

export const getAllMessagesDetail: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessagesSucceededGetDetail
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_DETAIL_LOADING,
        type: MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_LOADING,
      });

      if (reactNewApp) {
        const response = await api_general.get(
          `${basePath}/${options.messageId}`,
          {}
        );
        dispatch({
          payload: response.data,
          status: MessagesActionStatus.GET_ALL_MESSAGES_DETAIL_SUCCEEDED,
          type: MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_SUCCEEEDED,
        });
      } else {
        const response = await api_v2.get(
          `${basePath}/${options.messageId}`,
          {}
        );
        dispatch({
          payload: response.data,
          status: MessagesActionStatus.GET_ALL_MESSAGES_DETAIL_SUCCEEDED,
          type: MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_SUCCEEEDED,
        });
      }
    } catch (err) {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_DETAIL_FAILED,
        type: MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_FAILED,
      });
      treatError(options.culture, "PageMessages::Header", err);
    }
  };
};

export const getAllMessagesForwardHeader: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessagesSucceededForwardHeader
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_FORWARDHEADER_LOADING,
        type: MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_LOADING,
      });

      if (reactNewApp) {
        const response = await api_general.get(
          `${basePath}/${options.messageId}/forward-header`,
          {}
        );
        dispatch({
          payload: response.data,
          status: MessagesActionStatus.GET_ALL_MESSAGES_FORWARDHEADER_SUCCEEDED,
          type: MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_SUCCEEEDED,
        });
      } else {
        const response = await api_v2.get(
          `${basePath}/${options.messageId}/forward-header`,
          {}
        );
        dispatch({
          payload: response.data,
          status: MessagesActionStatus.GET_ALL_MESSAGES_FORWARDHEADER_SUCCEEDED,
          type: MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_SUCCEEEDED,
        });
      }
    } catch (err) {
      dispatch({
        status: MessagesActionStatus.GET_ALL_MESSAGES_FORWARDHEADER_FAILED,
        type: MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_FAILED,
      });
      treatError(options.culture, "PageMessages::Header", err);
    }
  };
};

export const postMessage: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessagesPostSucceeded
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const params = {
        applicationId: options?.applicationId,
        subject: options?.subject,
        message: options?.message,
        to: options?.to,
        isInternal: options?.isInternal,
        isEmail: options?.isEmail,
      };

      if (reactNewApp) {
        await api_general.post(`${basePath}`, params).then((response) => {
          dispatch({
            status: MessagesActionStatus.POST_MESSAGES_SUCCEEDED,
            type: MessagesActionTypes.POST_MESSAGES_SUCCEEDED,
          });
          return response;
        });
      } else {
        await api_v2.post(`${basePath}`, params).then((response) => {
          dispatch({
            status: MessagesActionStatus.POST_MESSAGES_SUCCEEDED,
            type: MessagesActionTypes.POST_MESSAGES_SUCCEEDED,
          });
          return response;
        });
      }
    } catch (err) {
      dispatch({
        status: MessagesActionStatus.POST_MESSAGES_FAILED,
        type: MessagesActionTypes.POST_MESSAGES_FAILED,
      });
      treatError(options.culture, "PageMessages::Header", err);
      return err;
    }
  };
};

export const postMessageArchive: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessagesSucceededPostArchive
>> = (options) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      try {
        const params = {
          applicationId: options?.applicationId,
          messageIds: options?.messageIds,
        };

        reactNewApp
          ? api_general.post(`${basePath}`, params).then((response) => {
              dispatch({
                status: MessagesActionStatus.POST_MESSAGES_ARCHIVE_SUCCEEDED,
                type: MessagesActionTypes.POST_MESSAGES_ARCHIVE_SUCCEEDED,
              });
              return resolve({ response, data: response.data });
            })
          : api_v2.post(`${basePath}`, params).then((response) => {
              dispatch({
                status: MessagesActionStatus.POST_MESSAGES_ARCHIVE_SUCCEEDED,
                type: MessagesActionTypes.POST_MESSAGES_ARCHIVE_SUCCEEDED,
              });
              return resolve({ response, data: response.data });
            });
      } catch (err) {
        dispatch({
          status: MessagesActionStatus.POST_MESSAGES_ARCHIVE_FAILED,
          type: MessagesActionTypes.POST_MESSAGES_ARCHIVE_FAILED,
        });
        treatError(options.culture, "PageMessages::Header", err);
        return reject(err);
      }
    });
  };
};

export const postMessageReply: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessageSucceededPostReply
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const params = {
        applicationId: options?.applicationId,
        to: options?.to,
        message: options?.message,
        isInternal: options?.isInternal,
        isEmail: options?.isEmail,
      };

      reactNewApp
        ? await api_general
            .post(`${basePath}/${options?.messageId}/reply`, params)
            .then((response) => {
              dispatch({
                status: MessagesActionStatus.POST_MESSAGES_REPLY_SUCCEEDED,
                type: MessagesActionTypes.POST_MESSAGES_REPLY_SUCCEEDED,
              });
              return response;
            })
        : await api_v2
            .post(`${basePath}/${options?.messageId}/reply`, params)
            .then((response) => {
              dispatch({
                status: MessagesActionStatus.POST_MESSAGES_REPLY_SUCCEEDED,
                type: MessagesActionTypes.POST_MESSAGES_REPLY_SUCCEEDED,
              });
              return response;
            });
    } catch (err) {
      dispatch({
        status: MessagesActionStatus.POST_MESSAGES_REPLY_FAILED,
        type: MessagesActionTypes.POST_MESSAGES_REPLY_FAILED,
      });
      treatError(options.culture, "PageMessages::Header", err);
    }
  };
};

export const postMessageReplyAll: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessageSucceededPostReplyAll
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const params = {
        applicationId: options?.applicationId,
        to: options?.to,
        message: options?.message,
        isInternal: options?.isInternal,
        isEmail: options?.isEmail,
      };
      reactNewApp
        ? await api_general
            .post(`${basePath}/${options?.messageId}/replyAll`, params)
            .then((response) => {
              dispatch({
                status: MessagesActionStatus.POST_MESSAGES_REPLYALL_SUCCEEDED,
                type: MessagesActionTypes.POST_MESSAGES_REPLYALL_SUCCEEDED,
              });
              return response;
            })
        : await api_v2
            .post(`${basePath}/${options?.messageId}/replyAll`, params)
            .then((response) => {
              dispatch({
                status: MessagesActionStatus.POST_MESSAGES_REPLYALL_SUCCEEDED,
                type: MessagesActionTypes.POST_MESSAGES_REPLYALL_SUCCEEDED,
              });
              return response;
            });
    } catch (err) {
      dispatch({
        status: MessagesActionStatus.POST_MESSAGES_REPLYALL_FAILED,
        type: MessagesActionTypes.POST_MESSAGES_REPLYALL_FAILED,
      });
      treatError(options.culture, "PageMessages::Header", err);
    }
  };
};

export const deleteMessage: ActionCreator<ThunkAction<
  Promise<any>,
  IMessagesState,
  null,
  IMessageSucceededDeleteMessage
>> = (options) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      try {
        const params = {
          data: [...options?.messageIds],
        };

        reactNewApp
          ? api_general.delete(`${basePath}`, params).then((response) => {
              dispatch({
                status: MessagesActionStatus.DELETE_MESSAGES_SUCCEEDED,
                type: MessagesActionTypes.DELETE_MESSAGES_SUCCEEDED,
              });
              return resolve({ response, data: response.data });
            })
          : api_v2.delete(`${basePath}`, params).then((response) => {
              dispatch({
                status: MessagesActionStatus.DELETE_MESSAGES_SUCCEEDED,
                type: MessagesActionTypes.DELETE_MESSAGES_SUCCEEDED,
              });
              return resolve({ response, data: response.data });
            });
      } catch (err) {
        dispatch({
          status: MessagesActionStatus.DELETE_MESSAGES_FAILED,
          type: MessagesActionTypes.DELETE_MESSAGES_FAILED,
        });
        treatError(options.culture, "PageMessages::Header", err);
        return reject(err);
      }
    });
  };
};
