import Truncate from "react-truncate";
import XDialog from "common/X/XDialog";
import SearchIcon from "@material-ui/icons/Search";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  createStyles,
  Divider,
  Grid,
  Input,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Resource, { resource } from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IResultManageGroups } from "redux/types/manageGroups";
import { getAllUsers } from "redux/actions/UsersAction";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "redux/store/Store";

interface IProps {
  culture: ICultureState;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  groupName?: string;
  memberList?: any;
  groupDetail?: IResultManageGroups;
  allWorkSpace?: boolean;

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnailSuggested: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    suggestedItemContainer: {
      padding: "8px",
      display: "flex",
      alignItems: "center",
      margin: "4px",
      borderRadius: "4px",
    },
  })
);

function GroupDetailsDialog({
  groupName,
  memberList,
  open,
  setOpen,
  groupDetail,
  allWorkSpace,
  culture,
}: IProps) {
  const theme = useTheme();
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [newUserList, setNewUserList] = useState(memberList);

  const store = useSelector(store => store) as IAppState;
  const dispatch = useDispatch();

  const allUsers = store.usersState.users

  let usersAllWorspace: any = [];
  let nameAllWorkspace = resource(
    culture.culture,
    "PageCreatePrize::AllWorkSpace"
  );

  useEffect(() => {
    dispatch(getAllUsers())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const newArr =
      memberList &&
      memberList.filter(
        (user: any) =>
          user.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    setNewUserList(newArr);
  }, [memberList, search, setNewUserList]);

  return (
    <XDialog
      width="800px"
      maxWidth="md"
      open={open}
      setOpen={setOpen}
      title={<Resource tag="PageManageGroups::GroupDetails" />}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex">
          <Typography variant="h4" style={{ width: "fit-content" }}>
            {groupName ? (
              groupName
            ) : (
              <>{groupDetail ? groupDetail?.groupName : nameAllWorkspace}</>
            )}
          </Typography>
          <Divider
            orientation="vertical"
            style={{ height: 24, marginLeft: 16, marginRight: 16 }}
            color={theme.palette.grey[500]}
          />
          <Box fontSize="0.9rem" color={theme.palette.text.secondary}>
            {memberList ? (
              <>
                {memberList?.length > 1 ? (
                  <>
                    <Resource
                      tag="PageCreatePrize::Members"
                      args={[memberList?.length]}
                    />
                  </>
                ) : (
                  <>
                    <Resource
                      tag="PageCreatePrize::Member"
                      args={[memberList?.length]}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {groupDetail ? (
                  <>
                    {groupDetail?.users?.meta?.total! > 1 ? (
                      <>
                        <Resource
                          tag="PageCreatePrize::Members"
                          args={[groupDetail?.users?.meta?.total]}
                        />
                      </>
                    ) : (
                      <>
                        <Resource
                          tag="PageCreatePrize::Member"
                          args={[groupDetail?.users?.meta?.total]}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {allUsers?.length! > 1 ? (
                      <>
                        <Resource
                          tag="PageCreatePrize::Members"
                          args={[allUsers?.length ?? 0]}
                        />
                      </>
                    ) : (
                      <>
                        <Resource
                          tag="PageCreatePrize::Member"
                          args={[allUsers?.length ?? 0]}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-end" width="40%">
          <Input
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={resource(culture, "PageManageGroups::SearchForUser")}
          />
          <Box ml={1} display="flex" alignItems="flex-end">
            <SearchIcon />
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        <Box
          fontSize="0.9rem"
          color={theme.palette.text.secondary}
          mb={1}
          style={{ textTransform: "uppercase" }}
        >
          <Resource tag="PageManageGroups::Description" />
        </Box>
        <Box fontSize="0.9rem">
          {groupName ? (
            <Resource tag="PageManageGroups::Composed" args={[groupName]} />
          ) : (
            <>
              {groupDetail ? (
                <>
                  <Resource
                    tag="PageManageGroups::Composed"
                    args={[groupDetail?.groupName]}
                  />
                </>
              ) : (
                <>
                  <Resource
                    tag="PageManageGroups::Composed"
                    args={[nameAllWorkspace]}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box ml="-11px" mt={3} height={220} style={{ overflowY: "auto" }}>
        <Grid container>
          {newUserList ? (
            <>
              {newUserList?.map((value: any, index: number) => {
                return (
                  <Grid item xs={12} sm={6} key={value?.id}>
                    <Box className={classes.suggestedItemContainer}>
                      <Box className="avatar" width="15%">
                        <Avatar
                          src={value?.avatar}
                          alt={value?.name}
                          className={classes.thumbnailSuggested}
                        />
                      </Box>
                      <Box
                        className="name"
                        width="65%"
                        fontSize="0.9rem"
                        margin="0 9px"
                      >
                        <Box mr="3px">
                          <Truncate width={125} ellipsis={<span>...</span>}>
                            {value?.name}
                          </Truncate>
                        </Box>
                        <Typography variant="caption" color="textSecondary">
                          <Truncate width={130} ellipsis={<span>...</span>}>
                            {value?.email}
                          </Truncate>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </>
          ) : (
            <>
              {groupDetail ? (
                <>
                  {groupDetail?.users?.result.map(
                    (value: any, index: number) => {
                      return (
                        <Grid item xs={12} sm={6} key={value?.id}>
                          <Box className={classes.suggestedItemContainer}>
                            <Box className="avatar" width="15%">
                              <Avatar
                                src={value?.avatar}
                                alt={value?.name}
                                className={classes.thumbnailSuggested}
                              />
                            </Box>
                            <Box
                              className="name"
                              width="65%"
                              fontSize="0.9rem"
                              margin="0 9px"
                            >
                              <Box mr="3px">
                                <Truncate
                                  width={125}
                                  ellipsis={<span>...</span>}
                                >
                                  {value?.name}
                                </Truncate>
                              </Box>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                <Truncate
                                  width={130}
                                  ellipsis={<span>...</span>}
                                >
                                  {value?.email}
                                </Truncate>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  {((allWorkSpace
                    ? (allUsers?.length > 0 && allUsers)
                    : usersAllWorspace?.length > 0 && usersAllWorspace) || []).map((value: any, index: number) => {
                      return (
                        <Grid item xs={12} sm={6} key={value?.id}>
                          <Box className={classes.suggestedItemContainer}>
                            <Box className="avatar" width="15%">
                              <Avatar
                                src={value?.avatar}
                                alt={value?.name}
                                className={classes.thumbnailSuggested}
                              />
                            </Box>
                            <Box
                              className="name"
                              width="65%"
                              fontSize="0.9rem"
                              margin="0 9px"
                            >
                              <Box mr="3px">
                                <Truncate width={125} ellipsis={<span>...</span>}>
                                  {value?.name}
                                </Truncate>
                              </Box>
                              <Typography variant="caption" color="textSecondary">
                                <Truncate width={130} ellipsis={<span>...</span>}>
                                  {value?.email}
                                </Truncate>
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                </>
              )}
            </>
          )}
        </Grid>
      </Box>
    </XDialog>
  );
}

export default GroupDetailsDialog;
