import * as React from 'react';
import { Route, Router } from "react-router-dom";
import history from "../../routes/history";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react"
import Pages from "../../routes/Pages";
import './App.css';
import { store, persistor } from '../../redux/store/Store';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

  const App = () => {
    const app = (
    <Router history={history}>
      <Route component={Pages} />
    </Router>
  )

  return (
    <GoogleReCaptchaProvider 
      reCaptchaKey={process.env['REACT_APP_RECAPTCHA_KEY']} 
      language={navigator.language}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <DndProvider backend={HTML5Backend}>
              {/* <ThemeComponent> */}
                {app}
              {/* </ThemeComponent> */}
            </DndProvider>
            <ToastContainer className="App-Toast" autoClose={4000} position={"bottom-left"}/>
          </div>
        </PersistGate>
      </Provider>
    </GoogleReCaptchaProvider>
  );
};

export default App