// Import redux types
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { api_general, api_v3 } from "config/api";
import { treatError } from "utils/treatError";
import { 
  IManageGroupsState, 
  IManageGroupsGetSuccessAction, 
  IManageGroupsPostSuccessAction, 
  IManageGroupsPutAssignMembersGroupSuccessAction, 
  IManageGroupsPutSuccessAction, 
  ManageGroupsActionTypes,
  ManageGroupsStatusTypes, 
  IManageGroupsGetAllSuccessAction, 
  IManageGroupGetSuccessAction,
  IManageGroupsPutRemoveUserGroupSuccessAction, 
  IManageGroupsTotalMembers
} from "redux/types/manageGroups";
import { toast } from "react-toastify";
import { resource } from "components/Resources/Resource";
import { ManageUsersActionTypes, ManageUsersStatusTypes } from "redux/types/manageUsers";

const basePath = "users";

export const getAllManageGroups: ActionCreator<ThunkAction<
  Promise<any>,
  IManageGroupsState,
  null,
  IManageGroupsGetAllSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.GET_ALL_GROUPS_LOADING,
      });
      let params = [];
      let params_qs = "";

      if(options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if(options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/groupsall${params_qs}`, {});

      dispatch({
        allGroups: response.data,
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
        type: ManageGroupsActionTypes.GET_ALL_GROUPS_SUCCESS,
      });
    } catch (err) {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.GET_ALL_GROUPS_FAIL,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};

export const getManageGroup: ActionCreator<ThunkAction<
  Promise<any>,
  IManageGroupsState,
  null,
  IManageGroupGetSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.GET_ALL_GROUPS_LOADING,
      });
      let params = [];
      let params_qs = "";

      if(options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/groups/${options.groupId}${params_qs}`, {});
      dispatch({
        group: response.data,
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
        type: ManageGroupsActionTypes.GET_GROUP_SUCCESS,
      });
    } catch (err) {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.GET_GROUP_FAIL,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};

/* Get All Action
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getManageGroups: ActionCreator<ThunkAction<
  Promise<any>,
  IManageGroupsState,
  null,
  IManageGroupsGetSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.GET_USERS_GROUPS_LOADING,
      });
      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false

      if(options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if(options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if(options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if(options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/groups${params_qs}`, {});
      dispatch({
        groups: response.data,
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
        type: ManageGroupsActionTypes.GET_USERS_GROUPS_SUCCESS,
        isInfinite: isInfinite
      });
    } catch (err) {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.GET_USERS_GROUPS_FAIL,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};

export const postManageGroups: ActionCreator<ThunkAction<Promise<any>, IManageGroupsState, null, IManageGroupsPostSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.POST_USERS_GROUPS_LOADING,
      });

      let params;
      let querys = [];
      let params_qs = "";

      if(options.hasOwnProperty("workspaceId")) {
        querys.push(`workspaceId=${options.workspaceId}`);
        params = {
          cultures: options.cultures,
          departaments: options.departamentSelected,
          countries: options.countrieSelected
        }
      }

      if(querys.length) {
        params_qs = `?${querys.join("&")}`;
      }

      await api_v3.post(`${basePath}/groups${params_qs}`, params).then(async () => {

        toast.success(resource('', 'PageManageGroups::CreateNewGroupSuccess'))

        let paramsMG = []
        let params_qs = "";

        paramsMG.push(`workspaceId=${options.workspaceId}`);      

        if(paramsMG.length) {
          params_qs = `?${paramsMG.join("&")}`;
        }
        const response = await api_v3.get(`${basePath}/groups${params_qs}`, {});
        dispatch({
          groups: response.data,
          maageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
          type: ManageGroupsActionTypes.POST_USERS_GROUPS_SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.POST_USERS_GROUPS_FAIL,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};

export const putManageGroups: ActionCreator<ThunkAction<Promise<any>, IManageGroupsState, null, IManageGroupsPutSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.PUT_USERS_GROUPS_LOADING,
      });
      let params
      if(options.hasOwnProperty("workspaceId")) {
        params = {
          workspaceId: options.workspaceId,
          cultures: options.cultures,
          departaments: options.departamentSelected,
          countries: options.countrieSelected,
          userIds: options.userIds
        }
      }

      await api_v3.put(`${basePath}/groups/${options.groupId}`, params).then(async () => {

        toast.success(resource(options.culture, 'PageManageGroups::EditGroupSuccess'))

        let paramsMG = []
        let params_qs = "";

        paramsMG.push(`workspaceId=${options.workspaceId}`);

        if(paramsMG.length) {
          params_qs = `?${paramsMG.join("&")}`;
        }
        const response = await api_v3.get(`${basePath}/groups${params_qs}`, {});
        dispatch({
          groups: response.data,
          maageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
          type: ManageGroupsActionTypes.PUT_USERS_GROUPS_SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.POST_USERS_GROUPS_FAIL,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};

export const putAssignGroups: ActionCreator<ThunkAction<Promise<any>, IManageGroupsState, null, IManageGroupsPutAssignMembersGroupSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.PUT_USERS_ASSIGN_MEMBERS_GROUP_LOAD,
      });
      let params;
      
      params = {
        groupsId: options.groupsId,
        usersId: options.usersId,
        workspaceId: options.workspaceId,
      }

      await api_v3.put(`${basePath}/assigngroups`, params).then(async () => {
        let params = [];
        let params_qs = "";
        if (options.hasOwnProperty("workspaceId")) {
          params.push(`workspaceId=${options.workspaceId}`);
        }
        params.push(`userType=1`);
        if (params.length) {
          params_qs = `?${params.join("&")}`;
        }
        const res = await api_v3.get(`${basePath}${params_qs}`, {});
        dispatch({
          type: ManageUsersActionTypes.GET_USERS_SUCCESS,
          users: res.data,
          status: ManageUsersStatusTypes.SUCCESS
        });
        toast.success(resource(options?.cultures, 'PageManageGroups::AssignMemberGroupSuccess'))
        
        dispatch({
          mageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
          type: ManageGroupsActionTypes.PUT_USERS_ASSIGN_MEMBERS_GROUP_SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.PUT_USERS_ASSIGN_MEMBERS_GROUP_FAILED,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};

export const putRevoveUserGroupManageGroups: ActionCreator<ThunkAction<Promise<any>, IManageGroupsState, null, IManageGroupsPutRemoveUserGroupSuccessAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.PUT_REMOVE_USER_GROUP_LOADING,
      });

      let params = [];
      let params_qs = "";
      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      params.push(`userType=1`);
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      await api_v3.put(`${basePath}/group/${options.groupId}/removeuser${params_qs}`, options.usersId).then(async () => {

        toast.success(resource(options?.cultures, 'PageManageGroups::EditGroupSuccess'))

        dispatch({
          manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
          type: ManageGroupsActionTypes.PUT_REMOVE_USER_GROUP_SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.PUT_REMOVE_USER_GROUP_FAIL,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};

export const getManageGroupsMembers: ActionCreator<ThunkAction<
  Promise<any>,
  IManageGroupsState,
  null,
  IManageGroupsGetSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.GET_ALL_GROUPS_MEMBERS_LOADING,
      });
      let params = [];
      let params_qs = "";

      if(options.hasOwnProperty("groupId")) {
        params.push(`groupId=${options.groupId}`);
      }
      if(options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if(options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if(options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/groups/${options.groupId}/members${params_qs}`, {});
      dispatch({
        groupMembers: response.data,
        status: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
        type: ManageGroupsActionTypes.GET_ALL_GROUPS_MEMBERS_SUCCESS,
      });
    } catch (err) {
      dispatch({
        status: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.GET_ALL_GROUPS_MEMBERS_FAIL,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};

/* Delete Group Action
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const deleteManageGroups: ActionCreator<ThunkAction<
  Promise<any>,
  IManageGroupsState,
  null,
  IManageGroupsGetSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.DELETE_GROUPS_MANAGE_LOADING,
      });
      let params = [];
      let params_qs = "";

      if(options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if(options.hasOwnProperty("groupId")) {
        params.push(`groupId=${options.groupId}`);
      }
      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      await api_v3.delete(`${basePath}/group${params_qs}`, {});

      toast.success(resource('', 'PageManageGroups::DeleteGroupSuccess'))

      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
        type: ManageGroupsActionTypes.DELETE_GROUPS_MANAGE_SUCCESS,
      });
    } catch (err) {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.DELETE_GROUPS_MANAGE_FAIL,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};

export const getGroupTotalUsersAction: ActionCreator<ThunkAction<
  Promise<any>,
  IManageGroupsState,
  null,
  IManageGroupsTotalMembers
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageGroupsStatusTypes.MANAGE_GROUPS_LOADING,
        type: ManageGroupsActionTypes.GET_GROUPS_TOTAL_USERS,
      });
      const params:any = [];
      let params_qs = "";

      if(options.hasOwnProperty("groupIds")) {
        options.groupIds.forEach((id:string) => {
          params.push(`groupIds=${id}`)
        });
      }
      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_general.get(`${basePath}/groups/totalusers${params_qs}`, {});
      dispatch({
        payload: response.data,
        status: ManageGroupsStatusTypes.MANAGE_GROUPS_SUCCESS,
        type: ManageGroupsActionTypes.GET_GROUPS_TOTAL_USERS,
      });
    } catch (err) {
      dispatch({
        manageGroupsStatus: ManageGroupsStatusTypes.MANAGE_GROUPS_FAIL,
        type: ManageGroupsActionTypes.GET_GROUPS_TOTAL_USERS,
      });
      treatError(options?.culture, "PageManageGroups::Header", err);
    }
  };
};