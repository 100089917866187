import { Reducer } from 'redux';
import { INews, INewsHighlighted, INewsPopular, INewsState, IResultNews, NewsActions, NewsActionTypes } from 'redux/types/news';

const initialCharacterState: INewsState = {
  news: {} as INews,
  topics: [],
  newsHighlighted: {} as INewsHighlighted,
  newsPopular: {} as INewsPopular,
  futureNews: [] as Partial<IResultNews>[],
  createNewStatus: 'SUCCESS',
  deleteNewStatus: 'SUCCESS',
  futureNewStatus: 'SUCCESS'
}

export const newsReducer: Reducer<INewsState, NewsActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case NewsActionTypes.GET_NEWS: {
      return {
        ...state,
        news: action.news,
      };
    }
    case NewsActionTypes.GET_TOPICS: {
      return {
        ...state,
        topics: action.topics
      }
    }
    case NewsActionTypes.GET_NEWS_HIGHLIGHTED: {
      return {
        ...state,
        newsHighlighted: action.newsHighlighted
      }
    }
    case NewsActionTypes.GET_NEWS_POPULAR: {
      return {
        ...state,
        newsPopular: action.newsPopular
      }
    }
    case NewsActionTypes.CREATE_NEW_LOAD: {
      return {
        ...state,
        createNewStatus: action.status
      }
    }
    case NewsActionTypes.CREATE_NEW_SUCCESS: {
      return {
        ...state,
        createNewStatus: action.status
      }
    }
    case NewsActionTypes.CREATE_NEW_FAILED: {
      return {
        ...state,
        createNewStatus: action.status
      }
    }
    case NewsActionTypes.UPDATE_NEW_LOAD: {
      return {
        ...state,
        createNewStatus: action.status
      }
    }
    case NewsActionTypes.UPDATE_NEW_SUCCESS: {
      return {
        ...state,
        createNewStatus: action.status
      }
    }
    case NewsActionTypes.UPDATE_NEW_FAILED: {
      return {
        ...state,
        createNewStatus: action.status
      }
    }
    case NewsActionTypes.DELETE_NEW_LOAD: {
      return {
        ...state,
        deleteNewStatus: action.status
      }
    }
    case NewsActionTypes.DELETE_NEW_SUCCESS: {
      const { newsId } = action
      const filteredHighlighted = state.news.highlighted.result.filter( data => data.newsId !== newsId)
      const filteredPopular = state.news.popular.result.filter( data => data.newsId !== newsId)
      const filteredNews = state.news.news.result.filter( data => data.newsId !== newsId)
      return {
        ...state,
        newsHighlighted:{
          ...state.newsHighlighted,
          result: filteredHighlighted
        },
        newsPopular: {
          ...state.newsPopular,
          result: filteredPopular
        },
        news: {
          highlighted: {
            ...state.news.highlighted,
            result:filteredHighlighted,
          },
          popular:{
            ...state.news.popular,
            result:filteredPopular
          },
          news: {
            ...state.news.news,
            result: filteredNews
          }
        },
        deleteNewStatus: action.status
      }
    }
    case NewsActionTypes.DELETE_NEW_FAILED: {
      return {
        ...state,
        deleteNewStatus: action.status
      }
    }
    case NewsActionTypes.HIGHLIGHT_NEW_LOAD: {
      return {
        ...state,
      }
    }
    case NewsActionTypes.HIGHLIGHT_NEW_SUCCESS: {
      const { newsId, isHighlighted } = action
      const highlighted = state.newsHighlighted
      if(isHighlighted){
        const filteredNews = state.news.news.result.filter( data => data.newsId === newsId)
        const removeNew = state.news.news.result.filter( data => data.newsId !== newsId)
        return {
          ...state,
          newsHighlighted:{
            ...state.newsHighlighted,
            meta: {
              ...state.news.highlighted.meta,
              total: state.news.highlighted.meta.total + 1
            },
            result: highlighted ? highlighted.result.concat(filteredNews) : filteredNews
          },
          news: {
            highlighted: {
              ...state.news.highlighted,
              meta:{
                ...state.news.highlighted.meta,
                total: state.news.highlighted.meta.total + 1
              },
              result: highlighted ? highlighted.result.concat(filteredNews) : filteredNews,
            },
            popular:{
              ...state.news.popular,
            },
            news: {
              ...state.news.news,
              meta:{
                ...state.news.news.meta,
                total: state.news.news.meta.total - 1
              },
              result: removeNew
            }
          },
        }
      }else{
        const filteredNews = state.newsHighlighted.result.filter( data => data.newsId === newsId)
        const findNew = state.news.news.result.find(data => data.newsId === filteredNews[0].newsId)
        const removeHighlightedNew = state.newsHighlighted.result.filter( data => data.newsId !== newsId)
        return {
          ...state,
          newsHighlighted:{
            ...state.newsHighlighted,
            meta:{
              ...state.newsHighlighted.meta,
              total: state.newsHighlighted.meta.total - 1
            },
            result: removeHighlightedNew
          },
          news: {
            highlighted: {
              ...state.news.highlighted,
              meta:{
                ...state.news.highlighted.meta,
                total: state.news.highlighted.meta.total - 1
              },
              result: removeHighlightedNew,
            },
            popular:{
              ...state.news.popular,
            },
            news: {
              ...state.news.news,
              meta:{
                ...state.news.news.meta,
                total: findNew ? state.news.news.meta.total : state.news.news.meta.total + 1
              },
              result: findNew ? state.news.news.result : state.news.news.result.concat(filteredNews as IResultNews[])
            }
          },
        }
      }
      
    }
    case NewsActionTypes.HIGHLIGHT_NEW_FAILED: {
      return {
        ...state,
      }
    }
    case NewsActionTypes.FUTURE_NEWS_LOAD: {
      return {
        ...state,
        futureNewStatus: action.status
      }
    }
    case NewsActionTypes.FUTURE_NEWS_SUCCESS: {
      return {
        ...state,
        futureNews: action.futureNews,
        futureNewStatus: action.status
      }
    }
    case NewsActionTypes.FUTURE_NEWS_FAILED: {
      return {
        ...state,
        futureNewStatus: action.status
      }
    }
    default:
      return state;
  }
};