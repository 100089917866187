import React from "react";
import XDialog from "common/X/XDialog";
import { Box, Button } from "@material-ui/core";
import Resource from "components/Resources/Resource";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  confirmAction: () => void;
}

function PopupDeleteWorkflowStage({ open, setOpen, confirmAction }: IProps) {
  const action = (
    <Box width="100%" display="flex" justifyContent="flex-end">
      <Button size="small" color="primary" onClick={() => setOpen(false)}>
        <Resource tag="PageCreateChallenge::Workflow_NoCancel" />
      </Button>
      <Button
        size="small"
        color="primary"
        variant="contained"
        style={{ marginLeft: 8 }}
        onClick={() => {
          setOpen(false);
          confirmAction();
        }}
      >
        <Resource tag="PageCreateChallenge::Workflow_Yes" />
      </Button>
    </Box>
  );

  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      title={<Resource tag="PageCreateChallenge::Workflow_RemovePhase" />}
      maxWidth="sm"
      actions={action}
    >
      <Box textAlign="center" mt={3}>
        <Resource tag="PageCreateChallenge::Workflow_ConfirmRemove" />
      </Box>
    </XDialog>
  );
}

export default PopupDeleteWorkflowStage;
