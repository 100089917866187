import { Reducer } from "redux";
import {
  CreatePrizesActions,
  CreatePrizesActionTypes,
} from "redux/actions/CreatePrize/CreatePrizeActions";
import { PageStatePrize } from "redux/types/createPrize";

export interface CreatePrizesState {
  pageState: PageStatePrize;
  status: string;
}

const initialState: CreatePrizesState = {
  pageState: {
    stepDetail: {
      prizeType: 2,
      activationDate: new Date(),
      endDate: new Date(),
      unit: "",
      maxUnitPerUser: "",
      value: "",
      image: "",
      title: "",
      description: "",
      cultureTag: "",
    },
    stepParticipants: {
      groupsId: [],
    },
  },
  status: "",
};

export const createPrizeReducer: Reducer<
  CreatePrizesState,
  CreatePrizesActions
> = (state = initialState, action) => {
  switch (action.type) {
    case CreatePrizesActionTypes.GET_CREATE_PRIZE_DETAIL:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          stepDetail: action.payload
            ? {
                prizeType: action.payload?.prizeType || 1,
                activationDate: action.payload?.activationDate || new Date(),
                endDate: action.payload?.endDate || new Date(),
                unit: action.payload?.unit || 0,
                maxUnitPerUser:
                  action.payload?.maxUnitPerUser ||
                  action.payload?.maxPrizePerUser ||
                  0,
                value: action.payload?.value || 0,
                image: action.payload?.image || "",
                title: action.payload?.cultures?.[0]?.title || "",
                description: action.payload?.cultures?.[0]?.description || "",
                cultureTag: action.payload?.cultures?.[0]?.cultureTag || "",
              }
            : initialState.pageState.stepDetail,
          stepParticipants: action.payload
            ? {
                ...state.pageState.stepParticipants,
                groupsId:
                  action.payload?.groupsId ||
                  action.payload?.groups.map((x: any) => x.groupId) ||
                  [],
              }
            : initialState.pageState.stepParticipants,
        },
      };
    case CreatePrizesActionTypes.UPDATE_DETAILS:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          stepDetail: action.payload,
        },
      };
    case CreatePrizesActionTypes.UPDATE_PARTICIPANTS:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          stepParticipants: action.payload,
        },
      };
    case CreatePrizesActionTypes.POST_PRIZE_DETAIL_FAILED:
    case CreatePrizesActionTypes.POST_PRIZE_DETAIL_SUCCEEDED:
    case CreatePrizesActionTypes.PUT_PRIZE_DETAIL_FAILED:
    case CreatePrizesActionTypes.PUT_PRIZE_DETAIL_SUCCEEDED:
    case CreatePrizesActionTypes.PUT_PRIZE_DETAIL_PARTICIPANTS_FAILED:
    case CreatePrizesActionTypes.PUT_PRIZE_DETAIL_PARTICIPANTS_SUCCEESS:
      return {
        ...state,
        status: action.status,
      };
    case CreatePrizesActionTypes.RESET_CREATE_PRIZE:
      return {
        ...state,
        pageState: { ...initialState.pageState },
      };
    default:
      return state;
  }
};
