import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ActionCreator, Dispatch } from 'redux';
import { api } from "./../../config/api";
import { setCultureAndResources, ICultureSetCulture } from "./CultureActions";
import { store } from "../store/Store";

const basePath = "resources";

export enum ResourcesActionTypes {
  RESOURCES = 'RESOURCES',
}

export interface IResourcesAction {
  type: ResourcesActionTypes.RESOURCES;
  payload: any;
}

export const getResources: ActionCreator<ThunkAction<Promise<any>, any, null, IResourcesAction>> =
  (cultureTag) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({
          type: ResourcesActionTypes.RESOURCES
        });

        await api.get(`${basePath}`).then(response => {
          dispatch({
            payload: response.data,
            type: ResourcesActionTypes.RESOURCES
          });
          setCulture(cultureTag, response.data);
        });
      } catch (err) {
      }
    };
  };

  function setCulture(cultureTag: string, resources: Array<any>) {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store.dispatch;
    dispatch(setCultureAndResources(cultureTag, resources));
  }