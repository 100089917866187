import { Reducer } from 'redux';
import {
    TabActions,
    TabActionsTypes,
} from '../actions/TabActions';


export interface ITabState {
    readonly activeName: string;
    readonly activeContent: React.ReactNode;
}

const initialCharacterState: ITabState = {
    activeName: "",
    activeContent: null
}
export const tabReducer: Reducer<ITabState, TabActions> = (
    state = initialCharacterState,
    action
  ) => {
    switch (action.type) {
      case TabActionsTypes.TAB_SELECT: {
        return {
          ...state,
          activeName: action.activeName,
          activeContent: action.activeContent
        };
      }
      default:
        return state;
    }
  };
