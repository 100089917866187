import React, { useState } from "react";
import sanitizeHtml from "sanitize-html";
import { Avatar, Box, createStyles, makeStyles } from "@material-ui/core";
import { securityTextEditor } from "utils/securityTextEditor";

interface IProps {
  avatar: string;
  fullName: string;
  message: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    descriptionReduced: {
      marginBottom: 1,
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
  })
);

function MessageDetailReduced(props: IProps) {
  const classes = useStyles();
  const { avatar, fullName, message } = props;
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);

  return (
    <Box className="message-detail-reduced-content">
      <Box
        mb={5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleToggle}
      >
        <Box display="flex">
          <Avatar alt="avatar" src={avatar} />
          <Box
            display="flex"
            flexDirection="column"
            ml={2}
            justifyContent="center"
          >
            <Box mb="4px" fontSize="0.8rem" fontWeight="700">
              {fullName}
            </Box>
            <Box
              className={classes.descriptionReduced}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(message, securityTextEditor),
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MessageDetailReduced;
