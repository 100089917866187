import { Reducer } from 'redux';
import {
  PrizeActions,
  PrizeActionTypes,
} from '../actions/PrizeActions';

// Define the Character type
export interface IResultPrizeActiveAuctionUser {
  timeRemaining: string,
  timeRemainingResource: string,
  lastBidValue: number,
  maxPrizePerUser: number,
  pointsResource: string,
  currentQuantity: number,
  quantityResource: string,
  statusResource: string,
  potentialWinners: [
    {
      position: number,
      value: number,
      userId: string,
      name: string,
      image: string
    }
  ],
  totalBidAlreadyMade?: number,
  userAllowToBid?: boolean,
  motiveDeniedBid?: string,
  prizeId: string,
  image: string,
  title: string,
  quantity: number,
  value: number,
  description: string,
  date?: string
  bidHigherResource?: string,
}

// Define the Character type
export interface IResultPrizePrevious {
  maximumValue: number,
  isBuyNow: boolean,
  dateClosed?:string,
  quantityWinners: number,
  winners: [
    {
      quantity: number,
      bidValue: number,
      bidValueResources: string,
      userId: string,
      name: string,
      image: string
    }
  ],
  currentQuantity: number,
  pointsResource: string,
  statusResource: string,
  statusQuantity: number,
  quantityResource: string,
  prizeId: string,
  image: string,
  title: string,
  description: string,
  quantity: number,
  value: number
}

// Define the Character type
export interface IResultPrizePreviousUser {
  position: number,
  value: number,
  userId: string,
  name: string,
  image: string
}

// Define the Character type
export interface IResultPrizeActiveBuyNow {
  timeRemaining: string,
  timeRemainingResource: string,
  currentQuantity: number,
  quantityResource: string,
  pointsResource: string,
  statusResource: string,
  statusQuantity: number,
  buyers: [
    {
      position: number,
      value: number,
      userId: string,
      name: string,
      image: string
    }
  ],
  isAllowedToBuy: boolean,
  motiveDeniedBuy: string,
  prizeId: string,
  image: string,
  title: string,
  quantity: number,
  value: number
  description: string,
}

// Define the Character type
export interface IResultPrizeActiveBuyNowUser {
  timeRemaining: string,
  timeRemainingResource: string,
  currentQuantity: number,
  quantityResource: string,
  pointsResource: string,
  statusResource: string,
  statusQuantity: number,
  buyers: [
    {
      position: number,
      value: number,
      userId: string,
      name: string,
      image: string
    }
  ],
  prizeId: string,
  image: string,
  title: string,
  description: string,
  quantity: number,
  value: number
}

// Define the Character type
export interface IResultPrizeFutures {
  isBuyNow: boolean,
  timeRemaining: string,
  timeRemainingResource: string,
  prizeId: string,
  image: string,
  title: string,
  description: string,
  quantity: number,
  value: number
}

// Define the Character type
export interface IPrize {
  activeAuctions: {
    meta: {
      currentPage: number,
      from: number,
      lastPage: number,
      perPage: number,
      to: number,
      total: number
    },
    result: IResultPrizeActiveAuctionUser[]
  },
  activeBuyNow: {
    meta: {
      currentPage: number,
      from: number,
      lastPage: number,
      perPage: number,
      to: number,
      total: number
    },
    result: IResultPrizeActiveBuyNow[]
  },
  previousPrizes: {
    meta: {
      currentPage: number,
      from: number,
      lastPage: number,
      perPage: number,
      to: number,
      total: number
    },
    result: IResultPrizePrevious[]
  },
  futurePrizes: {
    meta: {
      currentPage: number,
      from: number,
      lastPage: number,
      perPage: number,
      to: number,
      total: number
    },
    result: IResultPrizeFutures[]
  }
}

export interface IPrizeActiveAuction {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultPrizeActiveAuctionUser[]
};

export interface IPrizeActiveAuctionUser {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultPrizeActiveAuctionUser[]
};

export interface IPrizePrevious {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultPrizePrevious[]
};

export interface IPrizePreviousUser {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultPrizePreviousUser[]
};

export interface IPrizeActiveBuyNow {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultPrizeActiveBuyNow[]
};

export interface IPrizeActiveBuyNowUser {
  points: number,
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultPrizeActiveBuyNowUser[]
};

export interface IPrizeFutures {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultPrizeFutures[]
};

// Define the Character State
export interface IPrizeState {
  readonly points: number;
  readonly prizes: IPrize;
  readonly prizesActiveAuction: IPrizeActiveAuction;
  readonly prizesActiveAuctionUser: IPrizeActiveAuctionUser;
  readonly prizesPrevious: IPrizePrevious;
  readonly prizesPreviousUser: IPrizePreviousUser;
  readonly prizesActiveBuyNow: IPrizeActiveBuyNow;
  readonly prizesActiveBuyNowUser: IPrizeActiveBuyNowUser;
  readonly prizesFutures: IPrizeFutures;
}

// Define the initial state
const initialCharacterState: IPrizeState = {
  points: 0,
  prizes: {
    activeAuctions: {
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0
      },
      result: [
        {
          timeRemaining: "",
          timeRemainingResource: "",
          lastBidValue: 0,
          maxPrizePerUser: 0,
          pointsResource: "",
          currentQuantity: 0,
          quantityResource: "",
          statusResource: "",
          potentialWinners: [
            {
              position: 0,
              value: 0,
              userId: "",
              name: "",
              image: ""
            }
          ],
          totalBidAlreadyMade: 0,
          prizeId: "",
          image: "",
          title: "",
          quantity: 0,
          value: 0,
          description: "",
          userAllowToBid: true,
          motiveDeniedBid: "",
        }
      ]
    },
    activeBuyNow: {
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0
      },
      result: [
        {
          timeRemaining: "",
          timeRemainingResource: "",
          currentQuantity: 0,
          quantityResource: "",
          pointsResource: "",
          statusResource: "",
          statusQuantity: 0,
          buyers: [
            {
              position: 0,
              value: 0,
              userId: "",
              name: "",
              image: ""
            }
          ],
          isAllowedToBuy: true,
          motiveDeniedBuy: "",
          prizeId: "",
          image: "",
          title: "",
          quantity: 0,
          value: 0,
          description: "",
        }
      ]
    },
    previousPrizes: {
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0
      },
      result: [
        {
          maximumValue: 0,
          isBuyNow: true,
          quantityWinners: 0,
          winners: [
            {
              quantity: 0,
              bidValue: 0,
              bidValueResources: "",
              userId: "",
              name: "",
              image: ""
            }
          ],
          currentQuantity: 0,
          pointsResource: "",
          statusResource: "",
          statusQuantity: 0,
          quantityResource: "",
          prizeId: "",
          image: "",
          title: "",
          description: "",
          quantity: 0,
          value: 0
        }
      ]
    },
    futurePrizes: {
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0
      },
      result: [
        {
          isBuyNow: true,
          timeRemaining: "",
          timeRemainingResource: "",
          prizeId: "",
          image: "",
          title: "",
          description: "",
          quantity: 0,
          value: 0
        }
      ]
    }
  },

  prizesActiveAuction: {
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: [
      {
        timeRemaining: "",
        timeRemainingResource: "",
        lastBidValue: 0,
        maxPrizePerUser: 0,
        pointsResource: "",
        currentQuantity: 0,
        quantityResource: "",
        statusResource: "",
        potentialWinners: [
          {
            position: 0,
            value: 0,
            userId: "",
            name: "",
            image: ""
          }
        ],
        totalBidAlreadyMade: 0,
        prizeId: "",
        image: "",
        title: "",
        quantity: 0,
        value: 0,
        description: "",
        userAllowToBid: true,
        motiveDeniedBid: "",
      }
    ]
  },

  prizesActiveAuctionUser: {
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: [
      {
        timeRemaining: "",
        timeRemainingResource: "",
        lastBidValue: 0,
        maxPrizePerUser: 0,
        pointsResource: "",
        currentQuantity: 0,
        quantityResource: "",
        statusResource: "",
        potentialWinners: [
          {
            position: 0,
            value: 0,
            userId: "",
            name: "",
            image: ""
          }
        ],
        totalBidAlreadyMade: 0,
        prizeId: "",
        image: "",
        title: "",
        quantity: 0,
        value: 0,
        description: "",
        userAllowToBid: true,
        motiveDeniedBid: "",
      }
    ]
  },

  prizesPrevious: {
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: [
      {
        maximumValue: 0,
        isBuyNow: false,
        quantityWinners: 0,
        winners: [
          {
            quantity: 0,
            bidValue: 0,
            bidValueResources: "",
            userId: "",
            name: "",
            image: ""
          }
        ],
        currentQuantity: 0,
        pointsResource: "",
        statusResource: "",
        statusQuantity: 0,
        quantityResource: "",
        prizeId: "",
        image: "",
        title: "",
        description: "",
        quantity: 0,
        value: 0
      }
    ]
  },

  prizesPreviousUser: {
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: [
      {
        position: 0,
        value: 0,
        userId: "",
        name: "",
        image: ""
      }
    ]
  },

  prizesActiveBuyNow: {
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: [
      {
        timeRemaining: "",
        timeRemainingResource: "",
        currentQuantity: 0,
        quantityResource: "",
        pointsResource: "",
        statusResource: "",
        statusQuantity: 0,
        buyers: [
          {
            position: 0,
            value: 0,
            userId: "",
            name: "",
            image: ""
          }
        ],
        isAllowedToBuy: true,
        motiveDeniedBuy: "",
        prizeId: "",
        image: "",
        title: "",
        quantity: 0,
        value: 0,
        description: "",
      }
    ]
  },

  prizesActiveBuyNowUser: {
    points: 0,
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: [
      {
        timeRemaining: "",
        timeRemainingResource: "",
        currentQuantity: 0,
        quantityResource: "",
        pointsResource: "",
        statusResource: "",
        statusQuantity: 0,
        buyers: [
          {
            position: 0,
            value: 0,
            userId: "",
            name: "",
            image: ""
          }
        ],
        prizeId: "",
        image: "",
        title: "",
        description: "",
        quantity: 0,
        value: 0
      }
    ]
  },

  prizesFutures: {
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: [
      {
        isBuyNow: true,
        timeRemaining: "",
        timeRemainingResource: "",
        prizeId: "",
        image: "",
        title: "",
        description: "",
        quantity: 0,
        value: 0
      }
    ]
  },

};

export const prizeReducer: Reducer<IPrizeState, PrizeActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case PrizeActionTypes.GET_ALL_PRIZES: {
      return {
        ...state,
        points: action.points,
        prizes: action.prizes,
        prizesPrevious: action.prizes.previousPrizes,
      };
    }
    case PrizeActionTypes.GET_PRIZEACTIVEAUCTION: {
      return {
        ...state,
        prizesActiveAuction: action.prizesActiveAuction,
      };
    }
    case PrizeActionTypes.GET_PRIZEACTIVEAUCTIONUSER: {
      return {
        ...state,
        prizesActiveAuctionUser: action.prizesActiveAuctionUser,
      };
    }
    case PrizeActionTypes.GET_PRIZEPREVIOUS: {
      return {
        ...state,
        prizesPrevious: action.prizesPrevious,
      };
    }
    case PrizeActionTypes.GET_PRIZEPREVIOUSUSER: {
      return {
        ...state,
        prizesPreviousUser: action.prizesPreviousUser,
      };
    }
    case PrizeActionTypes.GET_PRIZEACTIVEBUYNOW: {
      return {
        ...state,
        prizesActiveBuyNow: action.prizesActiveBuyNow,
      };
    }
    case PrizeActionTypes.GET_PRIZEACTIVEBUYNOWUSER: {
      return {
        ...state,
        prizesActiveBuyNowUser: action.prizesActiveBuyNowUser,
      };
    }
    case PrizeActionTypes.POST_BUYPRIZE:{
      return {
        ...state
      }
    }
    default:
      return state;
  }
};