import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { Button, Box } from "@material-ui/core";
import Resource from "components/Resources/Resource";
import { ItemTypes } from "../Draggable/ButtonIconTypes/cfTypes";
import ValidationDraggableCard from "./WorkflowCardsContent/ValidationDraggableCard";
import { IWorkflowCard } from "redux/types/createChallenge";
import CollaborationDraggableCard from "./WorkflowCardsContent/CollaborationDraggableCard";
import DevelopmentDraggableCard from "./WorkflowCardsContent/DevelopmentDraggableCard";
import ExpertReviewDraggableCard from "./WorkflowCardsContent/ExpertReviewDraggableCard";

interface IProps {
  card: IWorkflowCard;
  index: number;
  onMove: (order: number, phaseId: string) => void;
  challengeId: string;
  handleSaveValue: (value: any, currentIndex: number) => void;
  deleteCard?: (phaseId: string) => any;
}

function WorkflowCard({
  card,
  index,
  onMove,
  handleSaveValue,
  challengeId,
  deleteCard,
}: IProps) {
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item) => {
      //@ts-ignore
      onMove(card.order, item.phaseId);
    },
  });

  const [, drop2] = useDrop({
    accept: ItemTypes.CARD,
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: {
      type: ItemTypes.CARD,
      order: card.order,
      phaseId: card.phaseId
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return !isDragging ? (
    <Box
      minWidth={250}
      height={448}
      marginRight={2}
      //ts-ignore to use ref in a mui box,
      //@ts-ignore
      ref={drop}
    >
      <div ref={drag}>
        {/* @ts-ignore */}
        {(card.workflowPhaseType === 1 || card.type === 1) && (
          <ValidationDraggableCard
            handleSaveValue={handleSaveValue}
            card={card}
            index={index}
            challengeId={challengeId}
            deleteCard={deleteCard}
          />
        )}
        {/* @ts-ignore */}
        {(card.workflowPhaseType === 2 || card.type === 2) && (
          <CollaborationDraggableCard
            handleSaveValue={handleSaveValue}
            card={card}
            index={index}
            challengeId={challengeId}
            deleteCard={deleteCard}
          />
        )}
        {/* @ts-ignore */}
        {(card.workflowPhaseType === 4 || card.type === 4) && (
          <DevelopmentDraggableCard
            handleSaveValue={handleSaveValue}
            card={card}
            index={index}
            challengeId={challengeId}
            deleteCard={deleteCard}
          />
        )}
        {/* @ts-ignore */}
        {(card.workflowPhaseType === 3 || card.type === 3) && (
          <ExpertReviewDraggableCard
            handleSaveValue={handleSaveValue}
            card={card}
            index={index}
            challengeId={challengeId}
            deleteCard={deleteCard}
          />
        )}
      </div>
    </Box>
  ) : (
    <Box
      minWidth={250}
      height={448}
      borderRadius={11}
      bgcolor="#F2F2F2"
      border={"1px solid #BDBDBD"}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      marginRight={2}
      //@ts-ignore
      ref={drop2}
    >
      <Button
        disabled
        disableRipple
        disableElevation
        disableFocusRipple
        disableTouchRipple
        style={{ border: `2px dashed #BDBDBD` }}
      >
        <Resource tag="PageCreateChallenge::Workflow_ReleaseToDrop" />
      </Button>      
    </Box>
  );
}

export default WorkflowCard;
