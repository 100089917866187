// Import redux types
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api_general, api_v2 } from "../../../config/api";

// Import Character Typing
import { IIdeaManagersState } from '../../reducers/ideas/IdeaManagersReducer';
import { toast } from 'react-toastify';
import { resource } from '../../../components/Resources/Resource';
import { treatError } from 'utils/treatError';
import newApp from 'utils/reactNewApp';

const basePath = "ideas";

// Create Action Constants
export enum ManagerActionTypes {
  PREVIEW_MANAGERS_LOADING = 'PREVIEW_MANAGERS_LOADING',
  PREVIEW_MANAGERS_SUCCEEDED = 'PREVIEW_MANAGERS_SUCCEEDED',
  PREVIEW_MANAGERS_FAILED = 'PREVIEW_MANAGERS_FAILED',

  ACTUAL_MANAGERS_LOADING = 'ACTUAL_MANAGERS_LOADING',
  ACTUAL_MANAGERS_SUCCEEDED = 'ACTUAL_MANAGERS_SUCCEEDED',
  ACTUAL_MANAGERS_FAILED = 'ACTUAL_MANAGERS_FAILED',

  CHANGE_MANAGER_LOADING = 'CHANGE_MANAGER_LOADING',
  CHANGE_MANAGER_SUCCEEDED = 'CHANGE_MANAGER_SUCCEEDED',
  CHANGE_MANAGER_FAILED = 'CHANGE_MANAGER_FAILED',
}

//Create Action Statusses
export enum ManagerActionStatus {
    PREVIEW_MANAGERS_LOADING = 'LOADING_PREVIEW',
    PREVIEW_MANAGERS_SUCCEEDED = 'SUCCEEDED',
    PREVIEW_MANAGERS_FAILED = 'FAILED',

    ACTUAL_MANAGERS_LOADING = 'LOADING_MANAGERS',
    ACTUAL_MANAGERS_SUCCEEDED = 'SUCCEEDED',
    ACTUAL_MANAGERS_FAILED = 'FAILED',

    CHANGE_MANAGER_LOADING = 'CHANGING_MANAGERS',
    CHANGE_MANAGER_SUCCEEDED = 'SUCCEEDED',
    CHANGE_MANAGER_FAILED = 'FAILED',
}

//------------*Get Preview Manager Actions*---------------
export interface IIdeasGetPreviewLoadingAction {
  type: ManagerActionTypes.PREVIEW_MANAGERS_LOADING;
  status: string;
}

export interface IIdeasGetPreviewSucceededAction {
  type: ManagerActionTypes.PREVIEW_MANAGERS_SUCCEEDED;
  previewManagers: any;
  status: string;
}

export interface IIdeasGetPreviewFailedAction {
  type: ManagerActionTypes.PREVIEW_MANAGERS_FAILED;
  status: string;
  error: null;
}


//------------*Get Actual Managers Actons*---------------
export interface IIdeasActualManagerLoadingAction {
  type: ManagerActionTypes.ACTUAL_MANAGERS_LOADING;
  status: string;
}

export interface IIdeasActualManagerSucceededAction {
  type: ManagerActionTypes.ACTUAL_MANAGERS_SUCCEEDED;
  managers: any;
  status: string;
}

export interface IIdeasActualManagerFailedAction {
  type: ManagerActionTypes.ACTUAL_MANAGERS_FAILED;
  status: string;
  error: null;
}

//------------------*Post change managers*-----------------
export interface IIdeasChangeManagerLoadingAction {
  type: ManagerActionTypes.CHANGE_MANAGER_LOADING;
  status: string;
}

export interface IIdeasChangeManagerSucceededAction {
  type: ManagerActionTypes.CHANGE_MANAGER_SUCCEEDED;
  status: string;
}

export interface IIdeasChangeManagerFailedAction {
  type: ManagerActionTypes.CHANGE_MANAGER_FAILED;
  status: string;
  error: null;
}

/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type ManagersActions =
  | IIdeasGetPreviewLoadingAction
  | IIdeasGetPreviewSucceededAction
  | IIdeasGetPreviewFailedAction
  | IIdeasActualManagerLoadingAction
  | IIdeasActualManagerSucceededAction
  | IIdeasActualManagerFailedAction
  | IIdeasChangeManagerLoadingAction
  | IIdeasChangeManagerSucceededAction
  | IIdeasChangeManagerFailedAction

/* Get All idea
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getPreviewManagers: ActionCreator<
  ThunkAction<Promise<any>, IIdeaManagersState, null, IIdeasGetPreviewSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type:ManagerActionTypes.PREVIEW_MANAGERS_LOADING,
      status:ManagerActionStatus.PREVIEW_MANAGERS_LOADING
    })
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("q")) {
      params.push(`q=${options.q}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

   if(newApp){
    await api_general.get(`users/autocomplete${params_qs}`, {}).then(response => {
      dispatch({
        type: ManagerActionTypes.PREVIEW_MANAGERS_SUCCEEDED,
        status: ManagerActionStatus.PREVIEW_MANAGERS_SUCCEEDED,
        previewManagers: response.data,
      });
    }, error =>{
      dispatch({
        type: ManagerActionTypes.PREVIEW_MANAGERS_FAILED,
        status: ManagerActionStatus.PREVIEW_MANAGERS_FAILED,
        error: error,
      });
    })
   } else {
    await api_v2.get(`users/autocomplete${params_qs}`, {}).then(response => {
      dispatch({
        type: ManagerActionTypes.PREVIEW_MANAGERS_SUCCEEDED,
        status: ManagerActionStatus.PREVIEW_MANAGERS_SUCCEEDED,
        previewManagers: response.data,
      });
    }, error =>{
      dispatch({
        type: ManagerActionTypes.PREVIEW_MANAGERS_FAILED,
        status: ManagerActionStatus.PREVIEW_MANAGERS_FAILED,
        error: error,
      });
    })
   }

    
      // treatError(options?.culture?.culture, 'Idea', err.toString());
  };
};



/* Get All ideas Collaborative
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getActualManagers: ActionCreator<
  ThunkAction<Promise<any>, IIdeaManagersState, null, IIdeasActualManagerSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ManagerActionTypes.ACTUAL_MANAGERS_LOADING,
      status: ManagerActionStatus.ACTUAL_MANAGERS_LOADING
    });

    if(newApp){
      await api_general.get(`${basePath}/${options.ideaId}/managers`, {}).then(response =>{
        if(response.status === 204){
          dispatch({
            managers: [],
            type: ManagerActionTypes.ACTUAL_MANAGERS_SUCCEEDED,
            status: ManagerActionStatus.ACTUAL_MANAGERS_SUCCEEDED
          })
        }else {
          dispatch({
            managers: response.data,
            type: ManagerActionTypes.ACTUAL_MANAGERS_SUCCEEDED,
            status: ManagerActionStatus.ACTUAL_MANAGERS_SUCCEEDED
          });
        }
      }, error =>{
        dispatch({
          error: error,
          type: ManagerActionTypes.ACTUAL_MANAGERS_FAILED,
          status: ManagerActionStatus.ACTUAL_MANAGERS_FAILED
        });
      });
    }else{
      await api_v2.get(`${basePath}/${options.ideaId}/managers`, {}).then(response =>{
        if(response.status === 204){
          dispatch({
            managers: [],
            type: ManagerActionTypes.ACTUAL_MANAGERS_SUCCEEDED,
            status: ManagerActionStatus.ACTUAL_MANAGERS_SUCCEEDED
          })
        }else {
          dispatch({
            managers: response.data,
            type: ManagerActionTypes.ACTUAL_MANAGERS_SUCCEEDED,
            status: ManagerActionStatus.ACTUAL_MANAGERS_SUCCEEDED
          });
        }
      }, error =>{
        dispatch({
          error: error,
          type: ManagerActionTypes.ACTUAL_MANAGERS_FAILED,
          status: ManagerActionStatus.ACTUAL_MANAGERS_FAILED
        });
      });
    }

   
    // treatError(options?.culture?.culture, 'Idea', err.toString());
  };
};

/* Get All ideas Collaborative
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postChangeManager: ActionCreator<
  ThunkAction<Promise<any>, IIdeaManagersState, null, IIdeasChangeManagerSucceededAction>
> = (options) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ManagerActionTypes.CHANGE_MANAGER_LOADING,
      status: ManagerActionStatus.CHANGE_MANAGER_LOADING
    });
    let params;
    params = {
        managersIds: options.managersIds,
        applicationId:options?.applicationId,
        workspaceId: options?.workspaceId
    }

    if(newApp){
      await api_general.post(`${basePath}/${options.ideaId}/managers`, params)
      .then(async () => {
        const responseManagers = await api_general.get(`${basePath}/${options.ideaId}/managers`, {});
        dispatch({
          managers: responseManagers.data,
          type: ManagerActionTypes.ACTUAL_MANAGERS_SUCCEEDED,
          status: ManagerActionStatus.ACTUAL_MANAGERS_SUCCEEDED
        });
        await toast.success(resource(options.culture, 'PageKeyTasks::Manager_SuccessfulyChanged'))
    }, error =>{
      dispatch({
        error: error,
        type: ManagerActionTypes.CHANGE_MANAGER_FAILED,
        status: ManagerActionStatus.CHANGE_MANAGER_FAILED
      });
      treatError(options?.culture?.culture, 'Idea', error.error_error);
    });

    }else {
      await api_v2.post(`${basePath}/${options.ideaId}/managers`, params)
      .then(async () => {
        const responseManagers = await api_v2.get(`${basePath}/${options.ideaId}/managers`, {});
        dispatch({
          managers: responseManagers.data,
          type: ManagerActionTypes.ACTUAL_MANAGERS_SUCCEEDED,
          status: ManagerActionStatus.ACTUAL_MANAGERS_SUCCEEDED
        });
        await toast.success(resource(options.culture, 'PageKeyTasks::Manager_SuccessfulyChanged'))
    }, error =>{
      dispatch({
        error: error,
        type: ManagerActionTypes.CHANGE_MANAGER_FAILED,
        status: ManagerActionStatus.CHANGE_MANAGER_FAILED
      });
      treatError(options?.culture?.culture, 'Idea', error.error_error);
    });
    }
  };
};
