import { TenantActionTypes, TenantActions } from "../actions/tenantAction";
import { IErrorHandling } from "../types/types"
import { Reducer } from 'redux';

// Define the Character type
export interface ITenant {
  tenantId: number,
  environmentType: number,
  endpoint: string,
  image: string,
  name: string,
  hasSSO: boolean,
  hasSSOLogout: boolean,
  urlSSO: string,
  tokenGuest: {},
  photoLogin: string,
  theme: {
    primaryColor: string,
    secondaryColor: string,
    tertiaryColor: string,
  }
}

export interface IApplication{
  applicationId:number
}

// Define the Character State
export interface ITenantState {
  readonly tenants: ITenant[];
  readonly activeTenant: ITenant;
  readonly error: IErrorHandling;
  readonly application: IApplication;
}

// Define the initial state
const initialCharacterState: ITenantState = {
  tenants: [{tenantId:0, endpoint: "", image:"", name:"", hasSSO: false, hasSSOLogout: false, urlSSO: "", environmentType: 0, tokenGuest: {}, photoLogin: '', theme: { primaryColor: '', secondaryColor: '', tertiaryColor: ''}}],
  activeTenant : {tenantId:0, endpoint: "", image:"", name:"", hasSSO: false, hasSSOLogout: false, urlSSO: "", environmentType: 0, tokenGuest: {}, photoLogin: '', theme: { primaryColor: '', secondaryColor: '', tertiaryColor: ''}},
  error: {error:false, message:""},
  application : {applicationId:0}
};

export const tenantReducer: Reducer<ITenantState, TenantActions> = (
  state = initialCharacterState,
  action) => {
  switch (action.type) {
    case TenantActionTypes.GET_CONFIGURATIONS:
      return {
        ...state,
        tenants: action.tenants,
        error: action.error,
        application: action.application
      };
    case TenantActionTypes.SET_ACTIVE:
      return {
        ...state,
        activeTenant: action.activeTenant
      };
    default:
      return state;
  } 
}