import React, { useEffect } from "react";
import NewForm from "./NewForm";
import { AnyAction } from "redux";
import history from "routes/history";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { INewsState } from "redux/types/news";
import { createNews, getNewsTopics } from "redux/actions/news/NewsActions";
import { ICultureState } from "redux/reducers/cultureReducer";

interface IDispatchProps {
  createNews: (options: object) => Promise<INewsState>;
  getTopics: (options: object) => Promise<any>;
}

interface IProps {
  culture: ICultureState;
  createNewStatus: string;
  workspaceId: string;
  topics: [];
}

interface submitProps {
  title: string;
  description: string;
  topic: string;
  image: string;
  enableComments: boolean;
  workspaceId: string;
  isHighlighted: boolean;
  publicationDate: Date;
}

function CreateNews(props: IProps & IDispatchProps) {
  const { culture, createNews, workspaceId, createNewStatus, topics, getTopics } = props;

  useEffect(() => {
    getTopics({
      workspaceId
    })
    // eslint-disable-next-line 
  },[])

  const submitForm = ({
    title,
    description,
    topic,
    image,
    enableComments,
    workspaceId,
    publicationDate,
    isHighlighted,
  }: submitProps) => {
    
    createNews({
      title,
      description,
      topic,
      image,
      enableComments,
      workspaceId,
      publicationDate,
      isHighlighted,
      culture: culture?.culture
    }).then(() => {
      history.push("/news");
    });
  };

  return (
    <Box marginY={4}>
      <NewForm
        topics={topics}
        culture={culture}
        workspaceId={workspaceId}
        createNewStatus={createNewStatus}
        submitForm={submitForm}
      />
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => ({
  culture: store.cultureState,
  workspaceId: store.authState.auth.workspaceId,
  createNewStatus: store.newsState.createNewStatus,
  topics: store.newsState.topics,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  createNews: (options: object) => dispatch(createNews(options)),
  getTopics: (options: object) => dispatch(getNewsTopics(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNews);
