import {
  Dialog,
  DialogTitle,
  DialogContent,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Checkbox,
  Grid,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Resource from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IAllManageGroups } from "redux/types/manageGroups";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  groupData?: any;
  culture?: ICultureState;
  putAssignGroups: any;
  allGroups: IAllManageGroups[];
  usersId: string[];
  workspaceId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: "30px 60px 0",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      color: theme.palette.grey[500],
      margin: "12px 0 3px",
    },
    inputHelper: {
      textAlign: "right",
    },
    boxSubtitle: {
      marginTop: "10px",
    },
    dividerContent: {
      width: "85%",
    },
    gridContent: {
      maxHeight: "152px",
      overflowY: "auto",

      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c3c3c3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
  })
);

function DialogAssignMembersToGroup(props: IProps) {
  const {
    open,
    setOpen,
    putAssignGroups,
    allGroups,
    usersId,
    workspaceId,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [values, setValues] = useState<string[]>([]);
  const handlePutAssignGroups = () => {
    putAssignGroups({
      usersId,
      workspaceId,
      groupsId: values,
    }).then(() => setOpen(false));
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h4" color="textPrimary">
          <Resource tag="PageManageUsers::AssignMembersToGroup" />
        </Typography>
        <IconButton
          // aria-label="close"
          className={classes.closeButton}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Divider className={classes.divider} />
      </DialogTitle>
      <DialogContent>
        <Box px={4.5}>
          <Box my={3} className={classes.boxSubtitle} mt={10}>
            <Box mb={2}>
              <Typography variant="h4" color="textPrimary">
                <Resource tag="PageManageUsers::ChooseTheGroupsToAssign" />
              </Typography>
              <Typography variant="body2" color="textPrimary">
                <Resource tag="PageManageUsers::SelectTheGroupsInWhichTheUserShouldParticipate" />
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box my={4}>
              <Grid container spacing={2} className={classes.gridContent}>
                {allGroups &&
                  allGroups?.map((list, index) => {
                    return (
                      <Grid item xs={6} sm={4} key={list?.groupId}>
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              key={list?.groupId}
                              checked={values?.indexOf(list?.groupId) > -1}
                              onChange={() =>
                                values.indexOf(list?.groupId) > -1
                                  ? setValues(
                                      values?.filter((y) => y !== list?.groupId)
                                    )
                                  : setValues([...values, list?.groupId])
                              }
                              name="checked"
                              color="primary"
                            />
                          }
                          label={
                            <Box>
                              <Typography variant="body2" color="textSecondary">
                                {list?.title}
                              </Typography>
                            </Box>
                          }
                        />
                        <Divider
                          variant="fullWidth"
                          className={classes.dividerContent}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>

            <Box mb={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box textAlign="right">
                    <Button
                      color="primary"
                      size="small"
                      variant="outlined"
                      onClick={() => setOpen(false)}
                      style={{ marginRight: 8 }}
                    >
                      <Resource tag="PageManageUsers::Cancel" />
                    </Button>

                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => handlePutAssignGroups()}
                    >
                      <Resource tag="PageManageUsers::SaveChanges" />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DialogAssignMembersToGroup;
