import React from "react";
import Resource from "components/Resources/Resource";
import MyActivityPrizesWon from "./MyActivityPrizesWon";
import MyActivityPrizesLost from "./MyActivityPrizesLost";
import { IProfileState } from "redux/reducers/profileReducer";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ActiveAuctionsPrizes from "components/ActiveAuctionPrizes/ActiveAuctionPrizes";
import {
  IMyActivitiesPrizesWon,
  IMyActivitiesPrizesActiveBid,
  IMyActivitiesPrizesLost,
} from "redux/types/myActivities";

const useStyles = makeStyles({
  content: {
    marginTop: "24px",
  },
});

interface IProps {
  myActivitiesPrizesWon: IMyActivitiesPrizesWon;
  myActivitiesPrizesActiveBid: IMyActivitiesPrizesActiveBid;
  myActivitiesPrizesLost: IMyActivitiesPrizesLost;
  postBidPrize: (prizeId: string, value: number) => void;
  requestManagePrize: (prizeId: string, type: number) => void;
  profile: IProfileState;
  points: number
  permission: string;
}

function MyActivityPrizes(props: IProps) {
  const {
    myActivitiesPrizesWon,
    myActivitiesPrizesActiveBid,
    myActivitiesPrizesLost,
    postBidPrize,
    requestManagePrize,
    profile,
    points,
    permission
  } = props;

  const classes = useStyles();

  return (
    <Grid className={classes.content} item xs={12} sm={12} md={12} lg={12}>
      {!myActivitiesPrizesWon?.meta?.total &&
      !myActivitiesPrizesActiveBid?.meta?.total &&
      !myActivitiesPrizesLost?.meta?.total ? (
        <div className="MyActivity-error" style={{ textAlign: "center" }}>
          <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
        </div>
      ) : (
        <div className="with-prize" style={{ marginLeft: "10px" }}>
          {myActivitiesPrizesWon !== null &&
          myActivitiesPrizesWon?.meta?.total > 0 &&
          typeof myActivitiesPrizesWon === "object" ? (
            <>
              <Typography variant="h3" style={{ marginBottom: 28 }}>
                <Resource
                  tag="PageMyActivity::Prizes_Own"
                  args={[myActivitiesPrizesWon?.meta?.total]}
                />
              </Typography>
              <MyActivityPrizesWon
                classes={classes}
                myActivitiesPrizesWon={myActivitiesPrizesWon}
              />
            </>
          ) : (
            ""
          )}

          {myActivitiesPrizesActiveBid !== null &&
          myActivitiesPrizesActiveBid?.meta?.total > 0 &&
          typeof myActivitiesPrizesActiveBid === "object" ? (
            <>
              <Typography variant="h3">
                <Resource
                  tag="PageMyActivity::Prizes_Active"
                  args={[myActivitiesPrizesActiveBid?.meta?.total]}
                />
              </Typography>
              <ActiveAuctionsPrizes
                permission={permission}
                points={points}
                postBidPrize={postBidPrize}
                requestManagePrize={requestManagePrize}
                activeAuctionPrizes={myActivitiesPrizesActiveBid?.result}
                noText={true}
                profile={profile?.profile[0]?.userId}
              />
            </>
          ) : (
            ""
          )}

          {myActivitiesPrizesLost !== null &&
          myActivitiesPrizesLost?.meta?.total &&
          typeof myActivitiesPrizesLost === "object" ? (
            <>
              <Typography
                variant="h3"
                style={{ marginTop: 28, marginBottom: 28 }}
              >
                <Resource
                  tag="PageMyActivity::Prizes_Lost"
                  args={[myActivitiesPrizesLost?.meta?.total]}
                />
              </Typography>
              <MyActivityPrizesLost
                classes={classes}
                myActivitiesPrizesLost={myActivitiesPrizesLost}
              />
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </Grid>
  );
}

export default MyActivityPrizes;
