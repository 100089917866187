import React from "react";
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import { ShortDateFormat } from "utils/FormatDate";
import { Link as RouterLink } from "react-router-dom";
import { IResultNewsHighlighted } from "redux/types/news";
import {
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Link,
  Box,
  Theme,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewAll: {
      textTransform: "uppercase",
      fontSize: "0.75rem",
    },
    lineClamp: {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    title: {
      WebkitLineClamp: 2,
      fontSize: "13px",
      color: theme.palette.text.primary,
    },
    content: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
  })
);

interface IProps {
  resultHighlighted: IResultNewsHighlighted;
}

function MiniHighlightedCardTab(props: IProps) {
  const { resultHighlighted } = props;
  const newsBackURL = "/news/details";
  const classes = useStyles();

  return (
    <>
      <Box mt={2} key={resultHighlighted?.newsId}>
        <Card elevation={0}>
          <CardActionArea>
            <Link
              component={RouterLink}
              to={{ pathname: `${newsBackURL}/${resultHighlighted?.newsId}` }}
            >
              <CardContent className={classes.content}>
                <Box mt={1}>
                  <Typography
                    className={`${classes.title} + ${classes.lineClamp}`}
                    variant="subtitle2"
                  >
                    {resultHighlighted?.title}
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Typography
                    gutterBottom
                    variant="subtitle2"
                    color="textSecondary"
                    style={{ textTransform: "uppercase" }}
                  >
                    <ShortDateFormat
                      date={resultHighlighted?.publicationDate}
                    />
                  </Typography>
                </Box>
              </CardContent>
            </Link>
          </CardActionArea>
        </Card>
      </Box>
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    news: store.newsState.news,
  };
};

export default connect(mapStateToProps)(MiniHighlightedCardTab);
