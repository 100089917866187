import * as React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import LoggedInRoute from "routes/LoggedInRoute";
import LoggedOutRoute from "routes/LoggedOutRoute";
import LoggedInSetupRoute from "routes/LoggedInSetupRoute";
//@ts-ignore
import { ApmRoute } from "@elastic/apm-rum-react";

/**
 * Redux
 */
import { IAuthUser } from "redux/reducers/authReducer";
import { IAppState } from "redux/store/Store";
import { connect } from "react-redux";
import history from "./history";

/**
 * Views
 */
// import X from 'views/X/XPage';
import Feed from "views/Feed/FeedPage";
import Challenge from "views/Challenges/ChallengePage/ChallengesPage";
import ChallengeDetail from "views/Challenges/ChallengeDetail/ChallengeDetailPage";
import Ideas from "views/Ideas/IdeasPage";
import CollaborativeEvaluation from "views/Ideas/CollaborativeEvaluation/CollaborativeEvaluationPage";
import IdeasInProgress from "views/Ideas/IdeasInProgress/IdeasInProgressPage";
import IdeasRejected from "views/Ideas/IdeasRejected/IdeasRejectedPage";
import IdeasPortfolio from "views/Ideas/IdeasPortfolio/IdeasPortfolioPage";
import IdeaDetail from "views/Ideas/IdeaDetail/IdeaDetailPage";
import CreateIdea from "views/Ideas/CreateIdea/CreateIdeaPage";
import EditIdea from "views/Ideas/CreateIdea/EditIdeaPage";
import EditDraft from "views/Ideas/CreateIdea/EditDraft";
import IdeaDraft from "views/Ideas/IdeaDrafts/IdeaDraftsPage";
import News from "views/News/NewsPage";
import NewsHighlightedPage from "views/News/NewsHighlighted/NewsHighlightedPage";
import NewsPopularPage from "views/News/NewsPopular/NewsPopularPage";
import NewsDetail from "views/News/NewsDetail/NewsDetailPage";
import KeyTasks from "views/KeyTasks/KeyTasksPage";
import MyActivity from "views/MyActivity/MyActivityPage";
import Prizes from "views/Prizes/PrizesPage";
import Profile from "views/Profile/ProfilePage";
import Ranking from "views/Ranking/RankingPage";
import Wallet from "views/Wallet/WalletPage";
import NotFound from "views/NotFound/NotFound";

import LogIn from "../views/Login/LoginPage/LoginPage";
import Recover from "views/Login/Recover/Recover";
import Terms from "components/Terms/Terms";
import SetProfile from "views/SetProfile/SetProfile";
import AcceptTerms from "components/AcceptTerms/AcceptTerms";
import NewUser from "views/Login/NewUser/NewUser";
import NewUserLink from "views/Login/NewUser/NewUserLink";

import Help from "screens/Help/Help";
import Discover from "screens/Discover/Discover";
import Analytics from "screens/Analytics/Analytics";
import SetupEnvironmentScreen from "views/SetupEnvironment/SetupEnvironment";
import Congrats from "views/SetupEnvironment/Congrats/Congrats";
import SSOResponse from "components/SSOResponse/SSOResponse";
import NewTenant from "components/TenantConfigurations/NewTenant";
import Splash from "routes/Splash";

import LoggedInSettings from "./LoggedInSettings";
import ManageGroup from "views/Settings/ManageGroups/ManageGroups";
import ManageUsers from "views/Settings/ManageUsers/ManageUsers";
import Regulations from "views/Settings/Regulations/Regulations";
import RegulationsCurrentVersion from "views/Settings/Regulations/RegulationsCurrentVersion";

import CreateChallenge from "views/Challenges/CreateChallenge/CreateChallegePage";
import MessagesPage from "views/Messages/MessagesPage";
import CreatePrizePage from "views/CreatePrizes/CreatePrizePage";
import EditPrizePage from "views/CreatePrizes/EditPrizePage";
import MessageDetailPage from "views/Messages/MessagesDetail/MessageDetailPage";
import CreateInspirationsPage from "views/CreateInspirations/CreateInpirationsPage";
import CreateNews from "views/News/CreateNews/CreateNews";
import EditNews from "views/News/CreateNews/EditNews";
import RequestAccess from "views/Login/RequestAccess/RequestAccess";

import newApp from "utils/reactNewApp";
import ThemeComponent from "theme/ThemeComponent";
import AcceptRegulations from "views/Settings/Regulations/AcceptRegulations";
import EditInspirationsPage from "views/CreateInspirations/EditInspirationsPage";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import enLocale from "date-fns/locale/en-GB";
import brLocale from "date-fns/locale/pt-BR";
import ptLocale from "date-fns/locale/pt";
import esLocale from "date-fns/locale/es";
import roLocale from "date-fns/locale/ro";
import ManageSetup from "views/Settings/ManageSetup/ManageSetup";
import {createStyles, makeStyles, Theme} from "@material-ui/core";

const currentLocale: any = {
  "en-GB": enLocale,
  "pt-BR": brLocale,
  "pt-PT": ptLocale,
  "es-ES": esLocale,
  "ro-RO": roLocale
}

interface IProps {
  AuthUser?: IAuthUser;
  cultureTag: string;
}

history.listen((location, action) => {
  window.scrollTo(0, 0);
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        back: {
            backgroundColor: "#eee",
        },
    })
);


const Pages = ({ AuthUser, cultureTag, ...props }: IProps) => {

  const classes = useStyles();

  return (
    <ThemeComponent>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={currentLocale[cultureTag]}>
        <Switch>
          <ApmRoute
            path="/regulations"
            exact={true}
            component={RegulationsCurrentVersion}
          />
          <ApmRoute path="/terms" exact={true} component={Terms} />
          <ApmRoute path="/congrats" exact={true} component={Congrats} />
          <ApmRoute path="/splash" exact={true} component={Splash} />
          <ApmRoute path="/ssoresponse" exact={true} component={SSOResponse} />
          <ApmRoute path="/newtenant" exact={true} component={NewTenant} />
          <Route
            path={[
              "/log-in",
              "/recover",
              "/terms",
              "/newtenant",
              "/requestAccess",
              "/newUserLink",
              "/newUser",
            ]}
            render={(userProps: any) => (
              <LoggedOutRoute userProps={userProps}>
                <Switch>
                  <ApmRoute path="/log-in" exact={true} component={LogIn} />
                  <ApmRoute path="/recover" exact={true} component={Recover} />
                  <ApmRoute
                    path="/acceptterms"
                    exact={true}
                    component={AcceptTerms}
                  />
                  <ApmRoute
                    path="/acceptRegulations"
                    exact={true}
                    component={AcceptRegulations}
                  />
                  <ApmRoute
                    path="/ssoresponse"
                    exact={true}
                    component={SSOResponse}
                  />
                  {newApp && (
                    <ApmRoute
                      path="/requestAccess"
                      exact={true}
                      component={RequestAccess}
                    />
                  )}
                  {newApp && (
                    <ApmRoute
                      path="/newUserLink/:token"
                      exact={true}
                      component={NewUserLink}
                    />
                  )}
                  {newApp && (
                    <ApmRoute
                      path="/newUser/email=:email&token=:token"
                      exact={true}
                      component={NewUser}
                    />
                  )}
                </Switch>
              </LoggedOutRoute>
            )}
          ></Route>
          <Route
            path={[
              "/setprofile",
              "/setenvironment",
              "/acceptterms",
              "/acceptRegulations",
            ]}
            render={(userProps: any) => (
              <LoggedInSetupRoute userProps={userProps}>
                <Switch>
                  <ApmRoute
                    path="/setprofile"
                    exact={true}
                    component={SetProfile}
                  />
                  <ApmRoute
                    path="/setenvironment"
                    exact={true}
                    component={SetupEnvironmentScreen}
                  />
                  <ApmRoute
                    path="/acceptterms"
                    exact={true}
                    component={AcceptTerms}
                  />
                  <ApmRoute
                    path="/acceptRegulations"
                    exact={true}
                    component={AcceptRegulations}
                  />
                </Switch>
              </LoggedInSetupRoute>
            )}
          ></Route>
          {newApp && (
            <Route
              path={["/settings"]}
              render={(userProps: any) => (
                <LoggedInSettings userProps={userProps}>
                  <Switch>
                    <ApmRoute
                      path="/settings/manage-users"
                      exact={true}
                      component={ManageUsers}
                    />
                    {AuthUser?.permission === "Admin" &&
                      <ApmRoute
                        path="/settings/setup"
                        exact={true}
                        component={ManageSetup}
                      />
                    }
                    <ApmRoute
                      path="/settings/manage-groups"
                      exact={true}
                      component={ManageGroup}
                    />
                    <ApmRoute
                      path="/settings/regulations"
                      exact={true}
                      component={Regulations}
                    />
                  </Switch>
                </LoggedInSettings>
              )}
            ></Route>
          )}
          <Route
            path={[
              "/",
              "/challenges",
              "/help",
              "/discover",
              "/analytics",
              "/profile",
              "/ranking",
              "/news",
              "/prizes",
              "/idea",
              "/wallet",
              "/MyActivity",
              "/drafts",
              "/challenges",
              "/createIdea",
              "/createNews",
              "/keytasks",
              "/createChallenge",
              "/createPrize",
            ]}
            render={(userProps: any) => (
              <div className={classes.back}>
                <LoggedInRoute userProps={userProps}>
                  <Switch>
                    <ApmRoute path="/" exact={true} component={Feed} />
                    <ApmRoute
                      path="/challenges"
                      exact={true}
                      component={Challenge}
                    />
                    {newApp && (
                      <ApmRoute
                        path="/createChallenge"
                        exact
                        component={CreateChallenge}
                      />
                    )}
                    {newApp && (
                      <ApmRoute
                        path="/createChallenge/:challengeId"
                        exact
                        component={CreateChallenge}
                      />
                    )}
                    <ApmRoute
                      path="/challenges/details/:challengeId"
                      exact={true}
                      component={ChallengeDetail}
                    />
                    <ApmRoute path="/help" exact={true} component={Help} />
                    <ApmRoute path="/discover" exact={true} component={Discover} />
                    <ApmRoute
                      path="/analytics"
                      exact={true}
                      component={Analytics}
                    />
                    <ApmRoute path="/profile" exact={true} component={Profile} />
                    <ApmRoute path="/ranking" exact={true} component={Ranking} />
                    <ApmRoute path="/news" exact={true} component={News} />
                    <ApmRoute
                      path="/news/highlighted"
                      exact={true}
                      component={NewsHighlightedPage}
                    />
                    <ApmRoute
                      path="/news/popular"
                      exact={true}
                      component={NewsPopularPage}
                    />
                    <ApmRoute
                      path="/news/details/:newsId"
                      exact={true}
                      component={NewsDetail}
                    />
                    {newApp && (
                      <ApmRoute path="/createNews" exact component={CreateNews} />
                    )}
                    {newApp && (
                      <ApmRoute path="/editNews/:newsId" exact component={EditNews} />
                    )}
                    <ApmRoute path="/prizes" exact={true} component={Prizes} />
                    <ApmRoute path="/idea" exact={true} component={Ideas} />
                    <ApmRoute
                      path="/idea/collaboration"
                      exact={true}
                      component={CollaborativeEvaluation}
                    />
                    <ApmRoute
                      path="/idea/inprogress"
                      exact={true}
                      component={IdeasInProgress}
                    />
                    <ApmRoute
                      path="/idea/rejected"
                      exact={true}
                      component={IdeasRejected}
                    />
                    <ApmRoute
                      path="/idea/portfolio"
                      exact={true}
                      component={IdeasPortfolio}
                    />
                    <ApmRoute
                      path="/idea/details/:ideaId"
                      exact={true}
                      component={IdeaDetail}
                    />
                    <ApmRoute
                      path="/createIdea/:challengeId"
                      exact={true}
                      component={CreateIdea}
                    />
                    <ApmRoute
                      path="/createIdea/draft/:ideaId"
                      exact={true}
                      component={EditDraft}
                    />
                    <ApmRoute
                      path="/editIdea/:ideaId"
                      exact={true}
                      component={EditIdea}
                    />
                    <ApmRoute path="/drafts" exact={true} component={IdeaDraft} />
                    <ApmRoute path="/wallet" exact={true} component={Wallet} />
                    <ApmRoute
                      path="/MyActivity"
                      exact={true}
                      component={MyActivity}
                    />
                    <ApmRoute path="/keytasks" exact={true} component={KeyTasks} />
                    {newApp && (
                      <ApmRoute
                        path="/createPrize"
                        exact={true}
                        component={CreatePrizePage}
                      />
                    )}
                    {newApp && (
                      <ApmRoute
                        path="/editPrize/:prizeId"
                        exact={true}
                        component={EditPrizePage}
                      />
                    )}
                    {newApp && (
                      <ApmRoute
                        path="/messages"
                        exact={true}
                        component={MessagesPage}
                      />
                    )}
                    {newApp && (
                      <ApmRoute
                        path="/messages/details/:messageId"
                        exact={true}
                        component={MessageDetailPage}
                      />
                    )}
                    {newApp && (
                      <ApmRoute
                        path="/createInspiration/:challengeId"
                        exact={true}
                        component={CreateInspirationsPage}
                      />
                    )}
                    {newApp && (
                      <ApmRoute
                        path="/editInspiration/:insightId"
                        exact={true}
                        component={EditInspirationsPage}
                      />
                    )}
                    <ApmRoute path="*" exact={true} component={NotFound} />
                    {/* <ApmRoute path="/x" exact={true} component={X} /> */}
                  </Switch>
                </LoggedInRoute>
              </div>
            )}
          ></Route>
        </Switch>
      </MuiPickersUtilsProvider>
    </ThemeComponent>
  );
};

const mapStateToProps = (state: IAppState) => ({
  AuthUser: state.authState.auth,
  cultureTag: state?.cultureState?.culture?.cultureTag
});
export default withRouter(connect(mapStateToProps)(Pages));
