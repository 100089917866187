import React, { useState } from "react";
import {
  Checkbox,
  CircularProgress,
  createStyles,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Box, Button } from "@material-ui/core";
import UserListItem from "../../../common/UI/List/User/UserListItem";
import Resource, { resource } from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IResultUsersManageGroups } from "redux/types/manageGroups";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import { IManageUsers } from "redux/types/manageUsers";
import { getManageUsers } from "redux/actions/manageUsers/ManageUsersAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";

interface IProps {
  culture: ICultureState;
  members: IResultUsersManageGroups;
  groupsIds?: any;
  workspaceId: string;
  users: IManageUsers;
  putRemoveUser?: (usersId: []) => void;
}

interface IStateProps {
  users: IManageUsers;
}

interface IDispatchProps {
  getUsers: (options: object) => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& label": {
        marginBottom: "15px",
        marginTop: "15px",
        marginRight: "8px",
        "& span:last-child": {
          width: "100%",
        },
      },
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    centerLoading: {
      margin: "0px auto",
      height: "80vh",
    },
  })
);

function ManageUsersGroup(props: IProps & IDispatchProps & IStateProps) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    culture,
    users,
    members,
    groupsIds,
    workspaceId,
    getUsers,
    putRemoveUser,
  } = props;
  const [state, setState] = useState<any>([]);
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [openPopUpRemoveUser, setOpenPopUpRemoveUser] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  async function handlePutRemoveUser(state?: any) {
    let usersId = state?.map((x: any) => x.userId);

    putRemoveUser && await putRemoveUser(usersId)
    setState([])
  }

  let keyTimer: any;
  let waitTimeAfterType = 1000;

  const handlerKeyUp = (text: string) => {
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      handleSearch(text);
    }, waitTimeAfterType);
  };

  const handlerKeydDown = () => {
    clearTimeout(keyTimer);
  };

  const handleSearch = (text: any) => {
    setLoading(true);
    text ? setSearch(true) : setSearch(false);
    Promise.all([
      getUsers({
        workspaceId,
        cultureTag: culture.culture.cultureTag,
        groupIds: groupsIds,
        pageNumber: users?.meta?.lastPage ? users?.meta?.lastPage : 1,
        pageSize: 6,
        searchTerm: text,
      }),
    ]).finally(() => setLoading(false));
  };
  return (
    <FormGroup className={classes.root}>
      <Box display="flex" alignItems="center" width="100%" mb={1}>
        {
          members.result.length > 0 ?
            <>
              <FormControlLabel
                style={{
                  marginBottom: "0px",
                  marginTop: "0px",
                }}
                control={
                  <Checkbox
                    checked={state.length === members?.result?.length}
                    onChange={() =>
                      state?.length !== members?.result?.length
                        ? setState(members?.result)
                        : setState([])
                    }
                    name="checkedAll"
                    color="primary"
                  />
                }
                label=""
              />

              <Grid
                container
                direction={matchSm ? "column-reverse" : "row"}
                spacing={2}
              >

                <Grid
                  justify="flex-start"
                  container
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                >

                  <Button
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setOpenPopUpRemoveUser(true);
                    }}
                    onFocus={(e: any) => e.stopPropagation()}
                    // aria-label="Buttons"
                    variant="text"
                    color="primary"
                    disabled={state.length === 0}
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <Resource tag="PageSettings::RemoveFromGroup" />
                  </Button>


                </Grid>

                <Grid
                  container
                  justify="flex-end"
                  alignItems="center"
                  wrap="nowrap"
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                >
                  <Input
                    fullWidth
                    onKeyDown={handlerKeydDown}
                    onKeyUp={(e) => handlerKeyUp(value)}
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    endAdornment={<SearchIcon />}
                    placeholder={resource(
                      props.culture,
                      "PageSettings::SearchForUser"
                    )}
                  // inputProps={{ "aria-label": "description" }}
                  />
                </Grid>
              </Grid>
            </>
            : ""}
      </Box>
      {!loading ? (
        <>
          {search ? (
            <>
              {users?.result?.map((user) => (
                <FormControlLabel
                  key={user.userId}
                  control={
                    <Checkbox
                      checked={state
                        .map((x: any) => x.userId)
                        .includes(user?.userId)}
                      onChange={() =>
                        state.map((x: any) => x.userId).includes(user?.userId)
                          ? setState(
                            state.filter(
                              (y: any) => y.userId !== user?.userId
                            )
                          )
                          : setState([...state, user])
                      }
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label={
                    <UserListItem
                      userId={user.userId}
                      culture={culture}
                      checked={state.checkedA}
                      principalText={user?.fullName}
                      subtitle={user?.email}
                      photo={user.photo}
                    />
                  }
                />
              ))}
            </>
          ) : (
            <>
              {members?.result?.map((member) => (
                <FormControlLabel
                  key={member.userId}
                  control={
                    <Checkbox
                      checked={state
                        .map((x: any) => x.userId)
                        .includes(member?.userId)}
                      onChange={() =>
                        state.map((x: any) => x.userId).includes(member?.userId)
                          ? setState(
                            state.filter(
                              (y: any) => y.userId !== member?.userId
                            )
                          )
                          : setState([...state, member])
                      }
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label={
                    <UserListItem
                      userId={member.userId}
                      culture={culture}
                      checked={state.checkedA}
                      principalText={member?.fullName}
                      subtitle={member?.email}
                      photo={member.photo}
                    />
                  }
                />
              ))}
            </>
          )}
        </>
      ) : (
        <>
          <Box height="100%" display="flex" className={classes.centerLoading}>
            <Box m="auto">
              <CircularProgress size={60} className="icon" />
            </Box>
          </Box>
        </>
      )}
      {/* TODO function to remove the user from group */}
      <PopUpConfirmation
        message={
          state.length > 0 && state.length === 1 ? (
            <Resource
              tag={"PageManageGroups::RemoveFromGroupUser"}
              args={[
                state?.[0].fullName === null
                  ? state?.[0].email
                  : state?.[0].fullName,
              ]}
            />
          ) : (
            <Resource tag={"PageManageGroups::RemoveFromGroupUsers"} />
          )
        }
        setIsOpen={setOpenPopUpRemoveUser}
        isOpen={openPopUpRemoveUser}
        closeButton={() => {
          setOpenPopUpRemoveUser(false);
        }}
        sendButton={() => handlePutRemoveUser(state)}
      />
    </FormGroup>
  );
}

const mapStateToProps = (store: IAppState) => ({
  users: store.manageUsersState.users,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  getUsers: (options: object) => dispatch(getManageUsers(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsersGroup);
