import React from "react";
import "./IdeaDraftCard.css";
import { Link } from "react-router-dom";
import Resource from "../Resources/Resource";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { IResultDrafts } from "redux/types/ideas";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Box,
  Divider,
  MenuItem,
} from "@material-ui/core";
import history from "../../routes/history";
import { IChallengeList } from "redux/types/challenges";
import Truncate from "react-truncate";
import sanitizeHtml from "sanitize-html";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import { securityTextEditor } from "utils/securityTextEditor";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      padding: "20px",
      border: "solid 1px #bdbdbd",
      borderRadius: "15px",
    },
    title: {
      color: "#313B53",
    },
    challengeTitle: {
      "& strong": {
        fontSize: "12px",
      },
    },
    description: {
      "& div ": {
        "& img": {
          width: "100%!important",
        },
      },
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    date: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    dateFormat: {
      textTransform: "lowercase",
      "&:first-line": {
        textTransform: "capitalize",
      },
    },
    button: {
      display: "flex",
      justifyContent: "flex-end",
      "& a": {
        textDecoration: "none",
      },
    },
    hr: {
      width: "100%",
    },
    menuButton: {
      padding: 0,
      marginTop: "-8px",
    },
    deleteAction: {
      color: "#FB0D1B"
    }
  })
);
interface IProps {
  draft: IResultDrafts;
  challengeList: IChallengeList;
  selectDraft: Function;
}

const endIcon = <ArrowForwardIcon />;

export default function IdeaDraftCard(props: IProps) {
  const { draft, challengeList, selectDraft } = props;
  const classes = useStyles();
  const descriptionLength = draft?.description?.length;
  const isActiveChallenge = challengeList?.result?.filter(
    (x) => x?.challengeId === draft?.challengeId
  );

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={11} sm={11} md={11} lg={11}>
          <Typography variant="h4" className={classes.title}>
            {draft?.title}
          </Typography>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1}>
          <MoreOptionsButton>
            {isActiveChallenge?.length > 0 && (
              <MenuItem
                onClick={() => {
                  history.push(`/idea/details/${draft?.ideaId}`, {
                    isDraft: true,
                  });
                }}
              >
                <Resource tag="Common::ViewDetails" />
              </MenuItem>
            )}
            <MenuItem
              className={classes.deleteAction}
              onClick={() => {
                selectDraft(draft)
              }}
            >
              <Resource tag="PageIdeaDraft::DeleteDraft" />
            </MenuItem>
          </MoreOptionsButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.challengeTitle}
        >
          <Typography variant="caption" color="textSecondary">
            <Resource tag="PageEditIdea::Challenge" />
            &nbsp;<strong>{draft?.challenge}</strong>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.challengeTitle}
        >
          <Divider className={classes.hr} variant="fullWidth" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.description}
        >
          {descriptionLength > 0 ? (
            <Truncate ellipsis={<span>...</span>} width={3000}>
              <Box
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(draft?.description, securityTextEditor),
                }}
              ></Box>
            </Truncate>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.date}>
          <Typography variant="caption" color="textSecondary">
            <Resource tag="PageNewIdea::LastEditionOn" /> &nbsp;
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.dateFormat}
          >
            {draft?.date}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.button}>
          {isActiveChallenge.length > 0 ? (
            <Link to={{ pathname: `/createIdea/draft/${draft?.ideaId}` }}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                endIcon={endIcon}
              >
                <Typography variant="caption">
                  <Resource tag="PageNewIdea::PopUpDraft_ButtonComplete" />
                </Typography>
              </Button>
            </Link>
          ) : (
            <Button
              color="primary"
              variant="contained"
              size="small"
              endIcon={endIcon}
              disabled
            >
              <Typography variant="caption">
                <Resource tag="PageNewIdea::PopUpDraft_ButtonComplete" />
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
