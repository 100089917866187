import React from "react";
import "./ListUserItem.css";
import { Avatar, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

interface IProps {
  userid?: string;
  avatar?: string;
  name?: string;
  rank?: number;
  counter?: number;
  title?: string | JSX.Element
  evaluationType?: string;
  dontHaveAvatar?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    li: {
      paddingLeft: 0,
      paddingRight: 0,
      color: "#333",
      flexWrap: 'wrap'
    },
    rank: {
      color: "rgba(27, 157, 252, 1)",
      padding: "0 4px 0 4px",
      width: "fit-content",
      fontWeight: "bold",
      fontSize: "0.85rem",
    },
    name: {
      paddingLeft: 6,
      paddingRight: 0,
      width: "fit-content",
      fontWeight: "bold",
      fontSize: "0.85rem",
    },
    counter: {
      fontWeight: "normal",
      paddingLeft: 6,
      paddingRight: 0,
      width: "fit-content",
      fontSize: "0.85rem",
      textTransform: 'lowercase',
    },
    rFlex: {
      flex: "none",
    },
  })
);

function ListUserItem(props: IProps) {
  const classes = useStyles();
  let avatar;
  const { dontHaveAvatar } = props
  avatar = (
    <Avatar
      className={classes.thumbnail}
      src={props.avatar}
      alt={props.name}
    />
  );

  return (
    <ListItem className={classes.li}>
      {dontHaveAvatar ? '' : avatar}
      <ListItemText className={classes.rFlex}>
        <Typography className={classes.rank}> {`#${props.rank}`} </Typography>
      </ListItemText>
      <ListItemText className={classes.rFlex}>
        <Typography className={classes.name}> {`${props.name} `} </Typography>
      </ListItemText>
      <ListItemText className={classes.rFlex}>
        <Typography className={classes.counter}>
          {`| ${""}${props.counter}`}
        </Typography>
      </ListItemText>
      <ListItemText className={classes.rFlex}>
        <Typography className={classes.counter}>
          {props.title}{" "}
        </Typography>
      </ListItemText>
    </ListItem>
  );
}

export default ListUserItem;
