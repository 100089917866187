import React, {useCallback ,useEffect, useState} from "react"
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import Resource from "components/Resources/Resource";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { ICultureState } from "redux/reducers/cultureReducer";
import DropdownInput from "components/GenericField/Dropdown/DropdownInput";
import ValidatorSearch from "components/Workflow/WorkflowDialogsContent/ValidatorSearch/ValidatorSearch";
import { IUsers } from "redux/reducers/usersReducers";
import {
  setWorkflowDinamic
} from "redux/actions/Workflow/WorkflowActions";

import { IDynamicWorkflow, ValidationPopupState } from "redux/types/createChallenge";

import { v4 as uuidv4 } from 'uuid';

import { 
  createStyles, 
  makeStyles,
  Theme, 
  MenuItem,  
  FormControl,
  Select,  
  Button
} from "@material-ui/core";

import {  
  IIdeaForm  
} from "redux/types/challenges";


import "./ValidatorDynamicCard.scss"

import { IIdeaDetails, IResultDrafts } from "redux/types/ideas";
import {Box} from "@material-ui/core";

interface IProps {
  ideasForm?: IIdeaForm;
  ideaDetail?: IIdeaDetails | IResultDrafts;
  culture: ICultureState;
  fatherState: any;
  setFatherState: (newValue: Array<any>) => void;
  users: IUsers[];
  state: ValidationPopupState;   
  dynamicWorkflow: IDynamicWorkflow;
  mapAddItem: string[];
  setMapAddItem?: any;
  radioType?:boolean;
}

interface IDispatchProps {  
  setWorkflow: (options: object, culture: ICultureState) => Promise<any>;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({    
    btnCard: { 
        border: "dashed  2px" + theme.palette.primary.main,
        color: theme.palette.primary.main,     
    },   
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      }, 
  })
);


function WorkflowValidatorDynamicCard(props: IProps & IDispatchProps) {  
  const {mapAddItem, setMapAddItem, radioType} = props;

  const classes = useStyles();
  const [autoCompleteValue, setACVAlue] = useState(props?.fatherState);
  const handleDeleteValue = (value: any) => {
    setACVAlue([]);
  };

  const [localState, setLocalState] = useState(props?.state);

  const setLocalStateValidator = useCallback(
    (newValue) => setLocalState((prev: any) => ({ ...prev, validators: newValue })),
    []
  );

  const getValueStoreString = (item: any) => {
    const selectedOption = props?.dynamicWorkflow?.optionSelect.filter(opt => opt.optionOrder === item);
    if (props?.dynamicWorkflow.optionSelect.length > 0 && selectedOption.length > 0) {
        return selectedOption[0].ideaCustomFieldIdValue;
    }
    else {
      return ""
    }
  } 

  interface IInputOptions{
    options:any[],
    item?:string,
  }
  //@ts-ignore
  const [inputChange, setinputChange] = useState([]);
  const [inputChange7, setinputChange7] = useState('');
  const [inputHandlerShort, setInputHandlerShort] = useState('');
  const [inputChangeRadio, setinputChangeRadio] = useState('');
  const [inputHandlerCheckmark, setInputHandlerCheckmark] = useState('');
  const [inputHandlerLong, setInputHandlerLong] = useState('');
  const [inputHandlerNumber, setInputHandlerNumber] = useState('');
  const [inputHandlerDrop, setInputHandlerDrop] = useState('');
  const [updateComponentes, setUpdateComponents] = useState(false);
  const [inputFormsIdea, setInputFormsIdea] = useState(false);
  const [inputFITitle, setinputFITitle] = useState([]);
  const [valueOption, setValueOption] = useState([]);
  const [valueType, setValueType] = useState("");
  const [inputOptions, setinputOptions] = useState<IInputOptions[]>([]);
  //const [customDiv, setCustomDiv] = useState(['div1']);
  const [typeCase1, settypeCase1] = useState([]); //shortext
  const [typeCase2, settypeCase2] = useState([]); //long text
  const [typeCase3, settypeCase3] = useState([]); // Attachment
  const [typeCase4, settypeCase4] = useState([]); // Number
  const [typeCase5, settypeCase5] = useState([]); // Checkmark
  const [typeCase6, settypeCase6] = useState([]); // Radiobutton
  const [typeCase7, settypeCase7] = useState([]); // Dropdown
  const [valueHDRadio, setValueHDRadio] = useState(false)
  
  //@ts-ignore
  const handleChange = (event, index, item) => {
    const typed = event.target.value;

    let optionsSelect = props?.dynamicWorkflow.optionSelect;
    if (optionsSelect[index]) {
      optionsSelect[index].ideaCustomFieldId = typed;
      optionsSelect[index].ideaCustomFieldIdValue = '';
    }
    else {
      const optionObj = {
        optionOrder: item,
        //@ts-ignore
        ideaCustomFieldId: typed,
        //@ts-ignore
        userId: '',
        //@ts-ignore
        users: [],
        //@ts-ignore
        ideaCustomFieldIdValue: ''
      };
      optionsSelect.push(optionObj);
    }
    props?.setWorkflow(optionsSelect, props?.culture);
  };

  // //@ts-ignore
  // const handleChange7 = (event) => {
  //   setinputChange7(event.target.value);
  // };

  //@ts-ignore
  const handlerGeneric = async (pCustomFieldValue, index) => {
    //setInputHandlerShort("true");  
    let optionsSelect = props?.dynamicWorkflow.optionSelect;
    if (optionsSelect[index]) {
      optionsSelect[index].ideaCustomFieldIdValue = pCustomFieldValue;
    }
    props?.setWorkflow(optionsSelect, props?.culture);
  };
 
  const addItens = () =>{  
    setInputFormsIdea(false)
    let mapItem = mapAddItem.length ? [...mapAddItem] : [];
    //@ts-ignore
    mapItem.push(uuidv4());
    setMapAddItem(mapItem);

     let mapinputFITitle = [...inputFITitle];
    //@ts-ignore
     mapinputFITitle.push(props?.ideasForm.cards);

     setinputFITitle(mapinputFITitle) 
    //@ts-ignore   
    //setinputFITitle(props?.ideasForm.cards);
    setInputFormsIdea(true)
  };



  useEffect(() => {
    let mapinputFITitle = inputFITitle.length ? [...inputFITitle] : [];
    let localInputOptions = inputOptions.length ? [...inputOptions] : [];
    let localValueOption = valueOption.length ? [...valueOption] : [];

    
    
    setInputFormsIdea(false)
    mapAddItem.forEach((item, index) => {
      //@ts-ignore
      mapinputFITitle.push(props?.ideasForm.cards);
      //@ts-ignore
      const findOptionSelected = props.dynamicWorkflow.optionSelect.find(option => option.optionOrder === item)?.ideaCustomFieldId;
      //@ts-ignore
      const cardIdea = props?.ideasForm.cards.find(c => c.ideaCustomFieldId === findOptionSelected);
      //@ts-ignore
      let opt = {options: cardIdea?.options, item};
      //@ts-ignore
      localInputOptions.push(opt);
      //@ts-ignore
      setValueType(renderSwitch(cardIdea?.type, index))
      if (localValueOption[index]) {
        //@ts-ignore
        localValueOption[index] = true
      }
      else {
        //@ts-ignore
        localValueOption.push(true);
      }
    })
    let optionsSelect = props?.dynamicWorkflow.optionSelect;
    props?.setWorkflow(optionsSelect, props?.culture);
    setinputOptions(localInputOptions);
    setValueOption(localValueOption);
    setinputFITitle(mapinputFITitle) 
    setInputFormsIdea(true)
    //@ts-ignore
  }, [radioType])



  const getValueTitulo = (cardIdea: any, index: any, item: any) =>{ 
    //@ts-ignore
    let opt = {options: cardIdea.options, item};
    let localInputOptions = [...inputOptions];

    if (!localInputOptions.find(local => local.item === item)) {
      //@ts-ignore
      localInputOptions.push(opt);
      setinputOptions(localInputOptions);
    } else {
      const newLocal = localInputOptions.filter((local) => local.item !== item);
      newLocal.push(opt);
      setinputOptions(newLocal);
    }
    //@ts-ignore
    //@ts-ignore
    setValueType(renderSwitch(cardIdea.type, index))

    let localValueOption = [...valueOption];
    if (localValueOption[index]) {
      //@ts-ignore
      localValueOption[index] = true
    }
    else {
      //@ts-ignore
      localValueOption.push(true);
    }
    setValueOption(localValueOption);
  };

  const localSetStateType = (localTypeCase: any, index: any) => {
    if (localTypeCase[index]) {
      //@ts-ignore
      localTypeCase[index] = false;
    }
    else {
      //@ts-ignore
      localTypeCase.push(false);
    }
  }

  const setFalseTypeCase = (index: any) => {
    let localTypeCase;

    localTypeCase = [...typeCase1]
    localSetStateType(localTypeCase, index);
    settypeCase1(localTypeCase);

    localTypeCase = [...typeCase2]
    localSetStateType(localTypeCase, index);
    settypeCase2(localTypeCase);

    localTypeCase = [...typeCase3]
    localSetStateType(localTypeCase, index);
    settypeCase3(localTypeCase);

    localTypeCase = [...typeCase4]
    localSetStateType(localTypeCase, index);
    settypeCase4(localTypeCase);

    localTypeCase = [...typeCase5]
    localSetStateType(localTypeCase, index);
    settypeCase5(localTypeCase);

    localTypeCase = [...typeCase6]
    localSetStateType(localTypeCase, index);
    settypeCase6(localTypeCase);

    localTypeCase = [...typeCase7]
    localSetStateType(localTypeCase, index);
    settypeCase7(localTypeCase);
  }

  //@ts-ignore
  const renderSwitch = (paramType: Int, index: Int) => {
    let localTypeCase;
    setFalseTypeCase(index);

    switch (paramType) {
      case 1:
        localTypeCase = [...typeCase1]
        return (          
          (
            //@ts-ignore
            localTypeCase[index] = true,
            settypeCase1(localTypeCase)
          )          
        );
      case 2:
        localTypeCase = [...typeCase2]
        return (
          (            
            //@ts-ignore
            localTypeCase[index] = true,
            settypeCase2(localTypeCase)
          )
        );
      case 3:
        localTypeCase = [...typeCase3]
        return (
          (            
            //@ts-ignore
            localTypeCase[index] = true,
            settypeCase3(localTypeCase)
          )
        );
      case 4:
        localTypeCase = [...typeCase4]
        return (
          (   
            //@ts-ignore
            localTypeCase[index] = true,
            settypeCase4(localTypeCase)
          )
        );
      case 5:
        localTypeCase = [...typeCase5]
        return (
          (           
            //@ts-ignore
            localTypeCase[index] = true,
            settypeCase5(localTypeCase)
          )
        );
      case 6:
        localTypeCase = [...typeCase6] 
        return (     
          (            
            //@ts-ignore
            localTypeCase[index] = true,
            settypeCase6(localTypeCase)
          )
        );  
      case 7:   
        localTypeCase = [...typeCase7]  
        return (     
          (
            //@ts-ignore
            localTypeCase[index] = true,
            settypeCase7(localTypeCase)
          )
        );      
      default:
        return "";
    }
  } 



  const removeCard = (uuid:string) => {
    const newMapItem = mapAddItem.filter((item) => item !== uuid)
    const newOptionSelect = props.dynamicWorkflow.optionSelect.filter((opt) => opt.optionOrder !== uuid);
    const newInputOptions = inputOptions.filter((input) => input.item !== uuid);
    setinputOptions(newInputOptions);
    setMapAddItem(newMapItem);
    props?.setWorkflow(newOptionSelect, props?.culture);
  }

  function renderContent (item: string, index: number, localInputFITitle: any )  {
    const selectedOption = props?.dynamicWorkflow?.optionSelect.filter(opt => opt.optionOrder === item);
    const selectedInputOptions = inputOptions.find(local => local.item === item);
    return(
      <>
        <FormControl className={classes.formControl}>
          <label id="0">
            <Resource tag={"Workflow::TitleWorkflow"}/>
          </label>
          <Select                    
            id=""   
            //@ts-ignore                
            value={selectedOption[0] ? selectedOption[0].ideaCustomFieldId : selectedOption.ideaCustomFieldId} 
            onChange={event => handleChange(event, index, item)}
            >                              
            {//@ts-ignore 
            localInputFITitle?.map(inpTitle => 
              inpTitle.type === 7 && (<MenuItem key={inpTitle.ideaCustomFieldId} value={inpTitle.ideaCustomFieldId} onClick={() => getValueTitulo(inpTitle, index, item)}></MenuItem>)
            )}
          </Select>
        </FormControl>
        {
          selectedInputOptions && (
          <FormControl className={classes.formControl + " itemCardDynamicForms"}>                      
            <label id="">Opções</label>
            <DropdownInput 
            value={getValueStoreString(item)} 
            propsI={{options:selectedInputOptions?.options?.filter(
              opt => 
              !props.dynamicWorkflow?.optionSelect?.some(sel => 
                sel.ideaCustomFieldIdValue === opt.ideaCustomFieldOptionId && sel.ideaCustomFieldIdValue !== getValueStoreString(item)
                )
              )}} 
            handler={(valueParam: any) => handlerGeneric(valueParam, index)}
            />     
            <br />
            <ValidatorSearch
                users={props?.users}
                culture={props?.culture}
                state={props?.state}
                fatherState={props?.fatherState}
                setFatherState={props?.setFatherState}
                dynamicWorkflow={props?.dynamicWorkflow}
                selectedItem={index}
            />                         
          </FormControl>
        )}
      </>
    )
  }

  return (    
   
    <div className="WorkflowValidatorDynamicCard"> 
        {
            inputFormsIdea &&
            <>
              {
                mapAddItem.map((item, index) => (
                <Box className="item itemCardDynamic" key={`item-${item}`}>
                  {renderContent(item, index, inputFITitle[index] )}
                  <Button 
                    onClick={() => removeCard(item)} 
                    style={{fontSize:12, marginTop:12, fontWeight:'bold', backgroundColor:'#189DFC', color:'#ffffff'}}
                    size="small"
                  >
                    <Resource tag={"Workflow::RemoveValidation"} />
                  </Button>
                </Box>        
            ))}
            
            </>          
        } 

        <div className="item">
          <div>
              <button className={classes.btnCard + " btnDynamicCardAdd"} type="button" onClick={addItens}>
                  <i>+ </i>
                  <Resource
                      tag={"Workflow::AddConditionButton"}
                  />                    
              </button>
          </div>            
        </div>       
       
    </div>        
  );
}

const mapStateToProps = (store: IAppState, ownProps: any) => {
  return {
    users: store.usersState.users,
    ideasForm: store.createChallengeState.pageState.stepIdeaForm,
    state: store.createChallengeState.validationPopupState,
    fatherState: ownProps.fatherState,
    dynamicWorkflow: store.createChallengeState.dynamicWorkFlow,
    setFatherState: ownProps.setFatherState
  };
};


const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => ({
  setWorkflow: (options: object, culture: ICultureState) => dispatch(setWorkflowDinamic(options, culture))
});


//@ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(WorkflowValidatorDynamicCard);