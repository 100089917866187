import { toast } from "react-toastify";
import newApp from "utils/reactNewApp";
import sanitizeHtml from "sanitize-html";
import { ThunkAction } from "redux-thunk";
import reactNewApp from "utils/reactNewApp";
import { ActionCreator, Dispatch } from "redux";
import { treatError } from "../../../utils/treatError";
import { resource } from "components/Resources/Resource";
import { securityTextEditor } from "utils/securityTextEditor";
import { api_v2, api_general } from "../../../config/api";
import {
  IKeyTasks,
  ITransition,
  IValidation,
  IEvaluation,
  ICorrection,
  IFilters,
  IKeyTasksPositive,
  IKeyTasksNegative,
  IKeyTasksTransitions,
  IKeyTasksState,
} from "../../reducers/keyTasks/keyTasksReducer";

const basePath = "keytasks";

// Create Action Constants
export enum KeyTasksActionTypes {
  GET_KEYTASKS_LOADING = "GET_KEYTASKS_LOADING",
  GET_KEYTASKS_SUCCEDDED = "GET_KEYTASKS_SUCCEDDED",
  GET_KEYTASKS_FAILED = "GET_KEYTASKS_FAILED",

  GET_EVALUATION_LOADING = "GET_EVALUATION_LOADING",
  GET_EVALUATION_SUCCEDDED = "GET_EVALUATION_SUCCEDDED",
  GET_EVALUATION_FAILED = "GET_EVALUATION_FAILED",

  GET_VALIDATION_LOADING = "GET_VALIDATION_LOADING",
  GET_VALIDATION_SUCCEDDED = "GET_VALIDATION_SUCCEDDED",
  GET_VALIDATION_FAILED = "GET_VALIDATION_FAILED",

  GET_TRANSITIONS_LOADING = "GET_TRANSITIONS_LOADING",
  GET_TRANSITIONS_SUCCEDDED = "GET_TRANSITIONS_SUCCEDDED",
  GET_TRANSITIONS_FAILED = "GET_TRANSITIONS_FAILED",

  GET_TRANSITIONS_SUMMARY = "GET_TRANSITIONS_SUMMARY",

  GET_CORRECTIONS_LOADING = "GET_CORRECTIONS_LOADING",
  GET_CORRECTIONS_SUCCEDDED = "GET_CORRECTIONS_SUCCEDDED",
  GET_CORRECTIONS_FAILED = "GET_CORRECTIONS_FAILED",

  GET_FILTERS_LOADING = "GET_FILTERS_LOADING",
  GET_FILTERS_SUCCEDDED = "GET_FILTERS_SUCCEDDED",
  GET_FILTERS_FAILED = "GET_FILTERS_FAILED",

  GET_ALL_KEYTASKS_POSITIVE = "GET_ALL_KEYTASKS_POSITIVE",
  GET_ALL_KEYTASKS_NEGATIVE = "GET_ALL_KEYTASKS_NEGATIVE",
  GET_ALL_KEYTASKS_TRANSITIONS = "GET_ALL_KEYTASKS_TRANSITIONS",

  POST_TANSITIONS_LOADING = "POST_TANSITIONS_LOADING",
  POST_TANSITIONS_SUCCESS = "POST_TANSITIONS_SUCCESS",
  POST_TANSITIONS_FAILED = "POST_TANSITIONS_FAILED",

  POST_VALIDATION_LOADING = "POST_VALIDATION_LOADING",
  POST_VALIDATION_SUCCESS = "POST_VALIDATION_SUCCESS",
  POST_VALIDATION_FAILED = "POST_VALIDATION_FAILED",

  PUT_REJECTED_LOADING = "PUT_REJECTED_LOADING",
  PUT_REJECTED_SUCCESS = "PUT_REJECTED_SUCCESS",
  PUT_REJECTED_FAILED = "PUT_REJECTED_FAILED",

  PUT_CORRECT_LOADING = "PUT_CORRECTLOADING",
  PUT_CORRECT_SUCCESS = "PUT_CORRECT_SUCCESS",
  PUT_CORRECT_FAILED = "PUT_CORRECT_FAILED",

  GET_IMPACT_TYPES = "GET_IMPACT_TYPES",
  POST_CHOSE_FOR_IMPLEMENTATION = 'POST_CHOSE_FOR_IMPLEMENTATION'
}

export enum KeyTasksActionStatus {
  KEYTASKS_LOADING = "LOADING",
  KEYTASKS_SUCCEDDED = "SUCCEEDED",
  KEYTASKS_FAILED = "FAILED",
}

//GET KEY TASKS ALL
export interface IKeyTasksGetLoadAction {
  type: KeyTasksActionTypes.GET_KEYTASKS_LOADING;
  keyTaskStatus: string;
}
export interface IKeyTasksGetSucceedAction {
  type: KeyTasksActionTypes.GET_KEYTASKS_SUCCEDDED;
  keyTasks: IKeyTasks;
  keyTaskStatus: string;
  isInfinite: boolean;
}
export interface IKeyTasksGetFailAction {
  type: KeyTasksActionTypes.GET_KEYTASKS_FAILED;
  keyTaskStatus: string;
}

// GET EVALUATION KEY TASKS
export interface IKeyTasksGetEvaluationLoadAction {
  type: KeyTasksActionTypes.GET_EVALUATION_LOADING;
  evaluationKeyTasksStatus: string;
}
export interface IKeyTasksGetEvaluationSucceedAction {
  type: KeyTasksActionTypes.GET_EVALUATION_SUCCEDDED;
  evaluationTasks: IEvaluation;
  evaluationKeyTasksStatus: string;
  isInfinite: boolean;
}
export interface IKeyTasksGetEvaluationFailAction {
  type: KeyTasksActionTypes.GET_EVALUATION_FAILED;
  evaluationKeyTasksStatus: string;
}

//GET VALIDATION KEY TASKS
export interface IKeyTasksGetValidationLoadAction {
  type: KeyTasksActionTypes.GET_VALIDATION_LOADING;
  validationKeyTasksStatus: string;
}
export interface IKeyTasksGetValidationSucceedAction {
  type: KeyTasksActionTypes.GET_VALIDATION_SUCCEDDED;
  validationTasks: IValidation;
  validationKeyTasksStatus: string;
  isInfinite: boolean;
  challengeId?:string;
  searchTerm?:string;
}
export interface IKeyTasksGetValidationFailAction {
  type: KeyTasksActionTypes.GET_VALIDATION_FAILED;
  validationKeyTasksStatus: string;
}
//GET transition KEY TASKS
export interface IKeyTasksGetTransitionsLoadAction {
  type: KeyTasksActionTypes.GET_TRANSITIONS_LOADING;
  transitionKeyTasksStatus: string;
}
export interface IKeyTasksGetTransitionsSucceedAction {
  type: KeyTasksActionTypes.GET_TRANSITIONS_SUCCEDDED;
  transitionTasks: ITransition;
  transitionKeyTasksStatus: string;
  isInfinite: boolean;
}
export interface IKeyTasksGetTransitionsFailAction {
  type: KeyTasksActionTypes.GET_TRANSITIONS_FAILED;
  transitionKeyTasksStatus: string;
}

//GET SUMMARY TRANSITIONS TASKS
export interface IKeyTasksGetTransitionsSummaryAction {
  type: KeyTasksActionTypes.GET_TRANSITIONS_SUMMARY;
  summary: any;
}
//GET CORRECTION KEY TASKS
export interface IKeyTasksGetCorrectionsLoadAction {
  type: KeyTasksActionTypes.GET_CORRECTIONS_LOADING;
  correctionKeyTasksStatus: string;
}
export interface IKeyTasksGetCorrectionsSucceedAction {
  type: KeyTasksActionTypes.GET_CORRECTIONS_SUCCEDDED;
  correctionTasks: ICorrection;
  correctionKeyTasksStatus: string;
  isInfinite: boolean;
}

export interface IKeyTasksGetCorrectionsFailAction {
  type: KeyTasksActionTypes.GET_CORRECTIONS_FAILED;
  correctionKeyTasksStatus: string;
}

//GET filters KEY TASKS
export interface IKeyTasksGetFiltersLoadAction {
  type: KeyTasksActionTypes.GET_FILTERS_LOADING;
  filtersKeyTasksStatus: string;
}
export interface IKeyTasksGetFiltersSucceedAction {
  type: KeyTasksActionTypes.GET_FILTERS_SUCCEDDED;
  filters: IFilters;
  filtersKeyTasksStatus: string;
}
export interface IKeyTasksGetFiltersFailAction {
  type: KeyTasksActionTypes.GET_FILTERS_FAILED;
  filtersKeyTasksStatus: string;
}

export interface IKeyTasksPositiveGetAllAction {
  type: KeyTasksActionTypes.GET_ALL_KEYTASKS_POSITIVE;
  keyTasksPositive: IKeyTasksPositive[];
}

export interface IKeyTasksNegativeGetAllAction {
  type: KeyTasksActionTypes.GET_ALL_KEYTASKS_NEGATIVE;
  keyTasksNegative: IKeyTasksNegative[];
}

export interface IKeyTasksTransitionsGetAllAction {
  type: KeyTasksActionTypes.GET_ALL_KEYTASKS_TRANSITIONS;
  keyTasksTransitions: IKeyTasksTransitions[];
}

export interface ITransitionsPostLoadAction {
  type: KeyTasksActionTypes.POST_TANSITIONS_LOADING;
  postTransitionStatus: string;
}
export interface ITransitionsPostSuccessAction {
  type: KeyTasksActionTypes.POST_TANSITIONS_SUCCESS;
  postTransitionStatus: string;
}

export interface ITransitionsPostFailAction {
  type: KeyTasksActionTypes.POST_TANSITIONS_FAILED;
  postTransitionStatus: string;
  error: any;
}

export interface IValidationPostLoadAction {
  type: KeyTasksActionTypes.POST_VALIDATION_LOADING;
  postValidationStatus: string;
}
export interface IValidationPostSuccessAction {
  type: KeyTasksActionTypes.POST_VALIDATION_SUCCESS;
  postValidationStatus: string;
}

export interface IValidationPostFailAction {
  type: KeyTasksActionTypes.POST_VALIDATION_FAILED;
  postValidationStatus: string;
  error: any;
}

export interface IRejectedPutLoadAction {
  type: KeyTasksActionTypes.PUT_REJECTED_LOADING;
  putRejectedStatus: string;
}
export interface IRejectedPutSuccessAction {
  type: KeyTasksActionTypes.PUT_REJECTED_SUCCESS;
  putRejectedStatus: string;
}

export interface IRejectedPutFailAction {
  type: KeyTasksActionTypes.PUT_REJECTED_FAILED;
  putRejectedStatus: string;
  error: any;
}

export interface ICorrectPutLoadAction {
  type: KeyTasksActionTypes.PUT_CORRECT_LOADING;
  putCorrectStatus: string;
}

export interface ICorrectPutSuccessAction {
  type: KeyTasksActionTypes.PUT_CORRECT_SUCCESS;
  putCorrectStatus: string;
}

export interface ICorrectPutFailAction {
  type: KeyTasksActionTypes.PUT_CORRECT_FAILED;
  putCorrectStatus: string;
  error: any;
}

export interface IGetImpactTypesAction {
  type: KeyTasksActionTypes.GET_IMPACT_TYPES,
  status: string,
  payload: any
}

export interface IPostChoseForImplementation {
  type: KeyTasksActionTypes.POST_CHOSE_FOR_IMPLEMENTATION,
  status: string,
}

export type KeyTasksActions =
  | IKeyTasksGetLoadAction
  | IKeyTasksGetSucceedAction
  | IKeyTasksGetFailAction
  | IKeyTasksGetEvaluationLoadAction
  | IKeyTasksGetEvaluationSucceedAction
  | IKeyTasksGetEvaluationFailAction
  | IKeyTasksGetValidationLoadAction
  | IKeyTasksGetValidationSucceedAction
  | IKeyTasksGetValidationFailAction
  | IKeyTasksGetTransitionsLoadAction
  | IKeyTasksGetTransitionsSucceedAction
  | IKeyTasksGetTransitionsFailAction
  | IKeyTasksGetCorrectionsLoadAction
  | IKeyTasksGetCorrectionsSucceedAction
  | IKeyTasksGetCorrectionsFailAction
  | IKeyTasksGetFiltersLoadAction
  | IKeyTasksGetFiltersSucceedAction
  | IKeyTasksGetFiltersFailAction
  | IKeyTasksPositiveGetAllAction
  | IKeyTasksNegativeGetAllAction
  | IKeyTasksTransitionsGetAllAction
  | ITransitionsPostLoadAction
  | ITransitionsPostSuccessAction
  | ITransitionsPostFailAction
  | IValidationPostLoadAction
  | IValidationPostSuccessAction
  | IValidationPostFailAction
  | IKeyTasksGetTransitionsSummaryAction
  | IGetImpactTypesAction
  | IPostChoseForImplementation;

/* Get All Key Tasks
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllKeyTasks: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IKeyTasksGetSucceedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      dispatch({
        keyTasksStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.GET_KEYTASKS_LOADING,
      });

      if (options?.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options?.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      newApp
        ? await api_general
            .get(`${basePath}${params_qs}`, {})
            .then((response) => {
              dispatch({
                keyTasks: response.data,
                type: KeyTasksActionTypes.GET_KEYTASKS_SUCCEDDED,
                keyTasksStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2.get(`${basePath}${params_qs}`, {}).then((response) => {
            dispatch({
              keyTasks: response.data,
              type: KeyTasksActionTypes.GET_KEYTASKS_SUCCEDDED,
              keyTasksStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
              isInfinite: isInfinite,
            });
          });
    } catch (err) {
      dispatch({
        type: KeyTasksActionTypes.GET_KEYTASKS_FAILED,
        keyTasksStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
      });
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

export const getEvaluationKeyTasks: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IKeyTasksGetEvaluationSucceedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      const { isInfinite = false } = options ?? false;

      dispatch({
        evaluationKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.GET_EVALUATION_LOADING,
      });

      if (options?.hasOwnProperty("statusId")) {
        params.push(`statusId=${options?.statusId}`);
      }
      if (options?.hasOwnProperty("challengeId")) {
        params.push(`challengeId=${options?.challengeId}`);
      }
      if (options?.hasOwnProperty("stateId")) {
        params.push(`stateId=${options?.stateId}`);
      }
      if (options?.hasOwnProperty("searchTerm")) {
        params.push(`searchTerm=${options?.searchTerm}`);
      }
      if (options?.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options?.pageNumber}`);
      }

      if (options?.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options?.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      newApp
        ? await api_general
            .get(`${basePath}/evaluations${params_qs}`, {})
            .then((response) => {
              dispatch({
                evaluationTasks: response.status === 204 ? {} : response.data,
                type: KeyTasksActionTypes.GET_EVALUATION_SUCCEDDED,
                evaluationKeyTasksStatus:
                  KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
                isInfinite: isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/evaluations${params_qs}`, {})
            .then((response) => {
              dispatch({
                evaluationTasks: response.status === 204 ? {} : response.data,
                type: KeyTasksActionTypes.GET_EVALUATION_SUCCEDDED,
                evaluationKeyTasksStatus:
                  KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
                isInfinite: isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        type: KeyTasksActionTypes.GET_EVALUATION_FAILED,
        evaluationKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
      });
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

export const getValidationKeyTasks: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IKeyTasksGetValidationSucceedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      dispatch({
        evaluationKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.GET_VALIDATION_LOADING,
      });

      if (options?.hasOwnProperty("challengeId") && options?.challengeId) {
        params.push(`challengeId=${options?.challengeId}`);
      }
      if (options?.hasOwnProperty("searchTerm")) {
        params.push(`searchTerm=${options?.searchTerm}`);
      }
      if (options?.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options?.pageNumber}`);
      }
      if (options?.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options?.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}/validations${params_qs}`, {})
            .then((response) => {
              dispatch({
                validationTasks: response.data,
                type: KeyTasksActionTypes.GET_VALIDATION_SUCCEDDED,
                validationKeyTasksStatus:
                  KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
                challengeId:options?.challengeId??null,
                searchTerm:options?.searchTerm??null,
                isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/validations${params_qs}`, {})
            .then((response) => {
              dispatch({
                validationTasks: response.data,
                type: KeyTasksActionTypes.GET_VALIDATION_SUCCEDDED,
                challengeId:options?.challengeId??null,
                searchTerm:options?.searchTerm??null,
                validationKeyTasksStatus:
                  KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
                isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        type: KeyTasksActionTypes.GET_VALIDATION_FAILED,
        evaluationKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
      });
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

export const getTransitionSummary: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IKeyTasksGetTransitionsSucceedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      newApp
        ? await api_general
            .get(`${basePath}/transitions/summary`, {})
            .then((response) => {
              dispatch({
                summary: response.data,
                type: KeyTasksActionTypes.GET_TRANSITIONS_SUMMARY,
              });
            })
        : await api_v2
            .get(`${basePath}/transitions/summary`, {})
            .then((response) => {
              dispatch({
                summary: response.data,
                type: KeyTasksActionTypes.GET_TRANSITIONS_SUMMARY,
              });
            });
    } catch (err) {
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

export const getTransitionKeyTasks: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IKeyTasksGetTransitionsSucceedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      dispatch({
        transitionKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.GET_TRANSITIONS_LOADING,
      });
      
      if (options?.hasOwnProperty("stateId")) {
        newApp ?
          params.push(`phaseId=${options?.stateId}`)
        :
          params.push(`stateId=${options?.stateId}`);
      }
      if (options?.hasOwnProperty("hasEvaluation")) {
        if (options?.hasEvaluation === "1") {
          params.push(`hasEvaluation=${true}`);
        }
        if (options?.hasEvaluation === "2") {
          params.push(`hasEvaluation=${false}`);
        }
      }
      if (options?.hasOwnProperty("challengeId")) {
        params.push(`challengeId=${options?.challengeId}`);
      }
      if (options?.hasOwnProperty("searchTerm")) {
        params.push(`searchTerm=${options?.searchTerm}`);
      }
      if (options?.hasOwnProperty("fromAverage")) {
        if (options?.fromAverage > 0 && options?.toAverage > 0) {
          params.push(`fromAverage=${options?.fromAverage}`);
        }
      }
      if (options?.hasOwnProperty("toAverage")) {
        if (options?.fromAverage > 0 && options?.toAverage > 0) {
          params.push(`toAverage=${options?.toAverage}`);
        }
      }
      if (options?.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options?.pageNumber}`);
      }

      if (options?.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options?.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      newApp
        ? await api_general
            .get(`${basePath}/transitions${params_qs}`, {})
            .then((response) => {
              dispatch({
                transitionTasks: response.data,
                type: KeyTasksActionTypes.GET_TRANSITIONS_SUCCEDDED,
                transitionKeyTasksStatus:
                  KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
                isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/transitions${params_qs}`, {})
            .then((response) => {
              dispatch({
                transitionTasks: response.data,
                type: KeyTasksActionTypes.GET_TRANSITIONS_SUCCEDDED,
                transitionKeyTasksStatus:
                  KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
                isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        type: KeyTasksActionTypes.GET_TRANSITIONS_FAILED,
        transitionKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
      });
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

export const getCorrectionKeyTasks: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IKeyTasksGetCorrectionsSucceedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      dispatch({
        correctionKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.GET_CORRECTIONS_LOADING,
      });

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      newApp
        ? await api_general
            .get(`${basePath}/corrections${params_qs}`, {})
            .then((response) => {
              dispatch({
                correctionTasks: response.data,
                type: KeyTasksActionTypes.GET_CORRECTIONS_SUCCEDDED,
                correctionKeyTasksStatus:
                  KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
                isInfinite,
              });
            })
        : await api_v2
            .get(`${basePath}/corrections${params_qs}`, {})
            .then((response) => {
              dispatch({
                correctionTasks: response.data,
                type: KeyTasksActionTypes.GET_CORRECTIONS_SUCCEDDED,
                correctionKeyTasksStatus:
                  KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
                isInfinite,
              });
            });
    } catch (err) {
      dispatch({
        type: KeyTasksActionTypes.GET_CORRECTIONS_FAILED,
        correctionKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
      });
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

export const getFilters: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IKeyTasksGetFiltersSucceedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        transitionKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.GET_FILTERS_LOADING,
      });

      newApp
        ? await api_general.get(`${basePath}/filters`, {}).then((response) => {
            dispatch({
              filters: response.data,
              type: KeyTasksActionTypes.GET_FILTERS_SUCCEDDED,
              transitionKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
            });
          })
        : await api_v2.get(`${basePath}/filters`, {}).then((response) => {
            dispatch({
              filters: response.data,
              type: KeyTasksActionTypes.GET_FILTERS_SUCCEDDED,
              transitionKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
            });
          });
    } catch (err) {
      dispatch({
        type: KeyTasksActionTypes.GET_FILTERS_FAILED,
        transitionKeyTasksStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
      });
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};
/*get/api/v3/keytasks/{ideaId}/transitions/positive*/
export const getAllKeyTasksPositive: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IKeyTasksPositiveGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }
      reactNewApp
        ? await api_general
            .get(
              `${basePath}/${
                options.ideaId ? options.ideaId : options.ideaIds[0]
              }/transitions/positive${params_qs}`,
              {}
            )
            .then((response) => {
              dispatch({
                keyTasksPositive: response.data,
                type: KeyTasksActionTypes.GET_ALL_KEYTASKS_POSITIVE,
              });
            })
        : await api_v2
            .get(
              `${basePath}/${
                options.ideaId ? options.ideaId : options.ideaIds[0]
              }/transitions/positive${params_qs}`,
              {}
            )
            .then((response) => {
              dispatch({
                keyTasksPositive: response.data,
                type: KeyTasksActionTypes.GET_ALL_KEYTASKS_POSITIVE,
              });
            });
    } catch (err) {
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

/*get/api/v3/keytasks/{ideaId}/transitions/negative*/
export const getAllKeyTasksNegative: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IKeyTasksNegativeGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(
              `${basePath}/${
                options.ideaId ? options.ideaId : options.ideaIds[0]
              }/transitions/negative${params_qs}`,
              {}
            )
            .then((response) => {
              dispatch({
                keyTasksNegative: response.data,
                type: KeyTasksActionTypes.GET_ALL_KEYTASKS_NEGATIVE,
              });
            })
        : await api_v2
            .get(
              `${basePath}/${
                options.ideaId ? options.ideaId : options.ideaIds[0]
              }/transitions/negative${params_qs}`,
              {}
            )
            .then((response) => {
              dispatch({
                keyTasksNegative: response.data,
                type: KeyTasksActionTypes.GET_ALL_KEYTASKS_NEGATIVE,
              });
            });
    } catch (err) {
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

/* Post Key Tasks Transitions
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postKeyTasksTransitions: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  ITransitionsPostSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {

    try {
      dispatch({
        postTransitionStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.POST_TANSITIONS_LOADING,
      });
      
      if(newApp) {
        let params_qs = {
          workspaceId: options?.workspaceId,
          ideaIds: options.ideaId,
          phaseIdActual: options.phaseId,
          phaseIdNext: options.phaseIdNext,
          commentText: sanitizeHtml(options.commentText, securityTextEditor),
          status: options.status
        };

        await api_general.post(`${basePath}/transit`, params_qs).then(async () => {
          toast.success(
            resource(options.culture, "PageKeyTasks::Transition_Done")
          );
          dispatch({
            postTransitionStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
            type: KeyTasksActionTypes.POST_TANSITIONS_SUCCESS,
          });
        });
      }else {
        let params_qs = {
          applicationId: options.applicationId,
          ideaIds: options.ideaId,
          stateId: options.stateId,
          commentText: sanitizeHtml(options.commentText, securityTextEditor),
          status: options.status,
        };
  
        await api_v2.post(`${basePath}/transit`, params_qs).then(async () => {
          toast.success(
            resource(options.culture, "PageKeyTasks::Transition_Done")
          );
          dispatch({
            postTransitionStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
            type: KeyTasksActionTypes.POST_TANSITIONS_SUCCESS,
          });
        });
      }

    } catch (err) {
      dispatch({
        postTransitionStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
        type: KeyTasksActionTypes.POST_TANSITIONS_FAILED,
      });
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

/* Post Key Tasks Validation
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postKeyTasksValidation: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IValidationPostSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        postValidationStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.POST_VALIDATION_LOADING,
      });
      
      let params_qs = {
        ideaIds: options.ideaId,
        workspaceId: options.workspaceId,
        commentText: sanitizeHtml(options.commentText, securityTextEditor),
        status: options.status,
      };

      await api_general.post(`${basePath}/validation`, params_qs).then(async () => {
        toast.success(
          resource(options.culture, "PageKeyTasks::Transition_Done")
        );
        dispatch({
          postValidationStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
          type: KeyTasksActionTypes.POST_VALIDATION_SUCCESS,
        });
      });

    } catch (err) {
      dispatch({
        postValidationStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
        type: KeyTasksActionTypes.POST_VALIDATION_FAILED,
      });
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

export const putKeyTasksRejected: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IRejectedPutSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        putRejectedStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.PUT_REJECTED_LOADING,
      });

      let params = []
      let params_qs = ""
      let param = {
        ideaId: options.ideaId,
      }

      if (options.hasOwnProperty("commentText")) {
        params.push(`comment=${options.commentText}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      await api_general
        .put(`${basePath}/rejected${params_qs}`, param)
        .then(async () => {
          toast.success(
            resource(options.culture, "PageKeyTasks::Rejected_Done")
          );
          dispatch({
            putRejectedStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
            type: KeyTasksActionTypes.PUT_REJECTED_SUCCESS,
          });
        });
    } catch (err) {
      dispatch({
        putRejectedStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
        type: KeyTasksActionTypes.PUT_REJECTED_FAILED,
      });
      treatError(options?.culture, "PageKeyTasks::Header", err);
    }
  };
};

export const putKeyTasksCorrect: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  ICorrectPutSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        putCorrectStatus: KeyTasksActionStatus.KEYTASKS_LOADING,
        type: KeyTasksActionTypes.PUT_CORRECT_LOADING,
      });

      let params = []
      let params_qs = ""
      let param = {
        ideaId: options.ideaId,
      }

      if (options.hasOwnProperty("commentText")) {
        params.push(`comment=${options.commentText}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      await api_general
        .put(`${basePath}/correct${params_qs}`, param)
        .then(async () => {
          toast.success(
            resource(options?.culture?.culture, "PageKeyTasks::Correct_Done")
          );
          dispatch({
            putCorrectStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
            type: KeyTasksActionTypes.PUT_CORRECT_SUCCESS,
          });
        });
    } catch (err) {
      dispatch({
        putCorrectStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
        type: KeyTasksActionTypes.PUT_CORRECT_FAILED,
      });
      treatError(options?.culture?.culture, "PageKeyTasks::Header", err);
    }
  };
};

export const getImpactTypes: ActionCreator<ThunkAction<
Promise<any>,
IKeyTasksState,
null,
IGetImpactTypesAction
>> = (options) => {
return async (dispatch: Dispatch) => {
  try {
    dispatch({
      status: KeyTasksActionStatus.KEYTASKS_LOADING,
      type: KeyTasksActionTypes.GET_IMPACT_TYPES,
    });

    await api_general
      .get(`${basePath}/impacttypes`)
      .then(async (data) => {
        dispatch({
          status: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
          type: KeyTasksActionTypes.GET_IMPACT_TYPES,
          payload: data.data
        });
      });
  } catch (err) {
    dispatch({
      status: KeyTasksActionStatus.KEYTASKS_FAILED,
      type: KeyTasksActionTypes.GET_IMPACT_TYPES,
    });
    treatError(options?.culture, "PageKeyTasks::Header", err);
  }
};
};

export const postChoseForImplementationAction: ActionCreator<ThunkAction<
  Promise<any>,
  IKeyTasksState,
  null,
  IPostChoseForImplementation
>> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_general.post(`${basePath}/${options.ideaId}/choseforimplementation`, options).then(async () => {
        toast.success(resource(culture, "PageKeyTasks::Transition_Done"));
        dispatch({
          postTransitionStatus: KeyTasksActionStatus.KEYTASKS_SUCCEDDED,
          type: KeyTasksActionTypes.POST_CHOSE_FOR_IMPLEMENTATION,
        });
      });

    } catch (err) {
      dispatch({
        postTransitionStatus: KeyTasksActionStatus.KEYTASKS_FAILED,
        type: KeyTasksActionTypes.POST_CHOSE_FOR_IMPLEMENTATION,
      });
      treatError(culture, "PageKeyTasks::Header", err);
    }
  };
};