import React  from 'react';
import Moment from 'react-moment';

import 'moment/locale/pt'; //Portuguese
import 'moment/locale/es'; //Spanish

import { format } from 'date-fns';
import { ptBR, enGB } from 'date-fns/locale';
import { IAppState } from 'redux/store/Store';
import { connect } from 'react-redux';
import { ICulture } from 'redux/reducers/cultureReducer';

/* Example
    <TimeFriendly time="2020-07-16T12:59-0500"m type={1} cultureTag='pt-BR'/>
    type(1): 01 Janeiro 2020
    type(2): 01/01/2020
*/

interface IProps {
    time: string;
    type?: number;
    cultureTag?: string;
}

interface IState {
    culture: ICulture
}

function TimeFriendly(props: IProps & IState) {
    const { time, type, cultureTag, culture } = props;
    let locale= cultureTag !== undefined ? cultureTag : culture.cultureTag; //TODO Get from cultureTag
    const newCulture = locale?.replace('-', '')
    let cult;

    if(newCulture === 'ptBR') {
        cult = ptBR
    }else if(newCulture === 'enGB') {
        cult = enGB
    }

    if(type === 1) {
        const date = format(new Date(time), 'dd LLLL yyyy', { locale: cult })
        return (
            <>
               {date}
            </>
        );
    }else if(type === 2) {
        const date = format(new Date(time), 'dd/MM/yyyy', {locale: cult})
        return (
            <>
                {date}
            </>
        );
    }

    return (
        <Moment locale={locale} format="DD MMMM YYYY">{time}</Moment>
    )
}

const mapStateToProps = (store: IAppState) => {
    return {
      culture: store.cultureState.culture
    };
  };
  
  export default connect(mapStateToProps)(TimeFriendly);

