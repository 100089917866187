import * as React from 'react';
import PointItems from '../../components/PointItems/PointItems'
import './PointsList.css'
import { IWalletBalanceHistory } from '../../redux/reducers/walletReducer';
import { Table, TableRow, TableHead, TableCell, Button, useTheme } from '@material-ui/core';
import Resource, { resource } from '../../components/Resources/Resource';
import { ICultureState } from 'redux/reducers/cultureReducer';

interface IProps {
  balance: IWalletBalanceHistory;
  cultureState: ICultureState;
  viewMoreData: any;
}

function PointsList(props: IProps) {
  const theme = useTheme();
  const { balance, cultureState, viewMoreData } = props;
  const pointsData = balance
  let maxLines = 4;

  return (
    <section>
      <div className="graph-div">
        <Table className="points-list-container">
          <TableHead>
            <TableRow className="list-points-header">
              <TableCell><Resource tag={"PageWallet::History_TransactionColumn"} /></TableCell>
            </TableRow>
            <TableRow className="list-points-header">
              <TableCell><Resource tag={"PageWallet::History_MovementColumn"} /></TableCell>
            </TableRow>
            <TableRow className="list-points-header">
              <TableCell><Resource tag={"PageWallet::History_TotalColumn"} /></TableCell>
            </TableRow>
          </TableHead>
          {pointsData &&
            pointsData?.result?.map((pointsData, index) => {
              return (
                <PointItems key={index} result={pointsData} cultureTag={cultureState.culture.cultureTag} />
              )
            })
          }
        </Table>
      </div>
      {pointsData?.meta.total > maxLines && (
        <div className="view-all-points">
          {pointsData?.meta?.total > pointsData?.result?.length &&
            <Button onClick={() => viewMoreData(pointsData?.meta.total)} style={{ color: theme.palette.primary.main }}>
              {resource(cultureState, "PageWallet::ViewAllTransactions", [pointsData.meta.total])}
            </Button>
          }
          {pointsData?.result?.length > maxLines &&
            <Button onClick={() => viewMoreData(4)}>
              {resource(cultureState, "Common::ViewLess")}
            </Button>
          }
        </div>
      )}
    </section>
  );
}
export default PointsList;