import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import EMIcon from "components/Icon/Icon";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Resource from "../../components/Resources/Resource";
import { IManagers } from "../../redux/reducers/challengeDetailReducer";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    title: {
      padding: "20px 40px 16px",
    },
    body: {
      padding: "0px 40px 32px",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
    managersTitle: {
      float: "left",
      color: theme.palette.text.secondary,
      textTransform: "uppercase",
    },
    userManager: {
      paddingLeft: "15px",
      color: theme.palette.text.primary,
      marginTop: "10px",
    },
    listManagerButton: {
      borderRadius: "50%",
      width: theme.spacing(4),
      height: theme.spacing(4),
      background: theme.palette.primary.light,
      cursor: "pointer",
      color: theme.palette.primary.main,
      fontSize: "1.3rem",
      float: "left",
      padding: "0px",
      marginLeft: "5px",
    },
  })
);

interface IProps {
  list: IManagers[];
}

function ListManagers(props: IProps) {
  const [viewAll, setViewAll] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  let showSlice = 3;
  let showButton;

  if (props?.list?.length > 3) {
    let showCount = props?.list?.length - 2;
    showSlice = 2;
    showButton = (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.listManagerButton}
        onClick={() => setViewAll(true)}
      >
        <EMIcon color={theme.palette.primary.main} size={10} icon={"plus"} />
        <span>{showCount}</span>
      </Box>
    );
  }

  return (
    <Box>
      <Box className={classes.managersTitle}>
        {props?.list?.length > 1 && (
          <Resource tag={"PageDetailChallenge::Challenge_Managers"} />
        )}
        {props?.list?.length <= 1 && (
          <Resource tag={"PageDetailChallenge::Challenge_Manager"} />
        )}
      </Box>
      <br />
      <Box paddingY={1} display="flex" gridGap={5}>
        {props?.list?.slice(0, showSlice).map((manager, index) => {
          return (
            <Box display="flex" key={manager.userId}>
              <Avatar
                className={classes.thumbnail}
                src={manager?.photo}
                alt={manager?.userName}
              />
              {props?.list?.length === 1 ? (
                <Box className={classes.userManager}>
                  {props?.list[0].fullName}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          );
        })}
        {showButton}
        <Dialog fullWidth maxWidth={"sm"} open={viewAll}>
          <DialogTitle className={classes.title}>
            <Typography variant="h4">
              <Resource tag={"PageDetailChallenge::Challenge_Managers"} />
            </Typography>
            <IconButton
              // aria-label="close"
              className={classes.closeButton}
              onClick={() => setViewAll(false)}
            >
              <CloseIcon />
            </IconButton>
            <Divider className={classes.divider}></Divider>
          </DialogTitle>
          <DialogContent className={classes.body}>
            {props?.list?.map((manager, index) => {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb={1}
                  key={manager.userId}
                >
                  <Avatar
                    className={classes.thumbnail}
                    src={manager?.photo}
                    alt={manager?.userName}
                  />
                  <Box ml={1} fontSize="0.8rem">
                    {manager?.fullName}
                  </Box>
                </Box>
              );
            })}
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

export default ListManagers;
