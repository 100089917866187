import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { ArrowForward } from "@material-ui/icons";
import Resource from "components/Resources/Resource";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Input from '@material-ui/core/Input';

import {
  Container,
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Button,
  CircularProgress,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@material-ui/core";
import {
  getAllWorkspaces,
  getWorkspace,
  getSuggestedNamesWorkspaces,
  postWorkspaces,
  putWorkspacesCountries
} from "redux/actions/workspaceAction";
import {
  IWorkspaceReducer,
  ISuggestedNamesWorkspaces,
  IWorkspaceState,
} from "redux/reducers/workspaceReducer";
import { IAuthUser } from "redux/reducers/authReducer";
import { getAllCountries } from "redux/actions/UsersAction";
import { ICountries } from "redux/reducers/usersReducers";
import ToolTip from "components/ToolTip/ToolTip";

interface IDispatchProps {
  getAllWorkspace: (options: object) => Promise<IWorkspaceState>;
  getWorkspace: (options: object) => Promise<IWorkspaceState>;
  getAllCountries: (options: object) => Promise<ICountries>;
  putWorkspaceCountries: (options: object) => Promise<IWorkspaceState>;
  getSuggestedNamesWorkspace: (options: object) => Promise<IWorkspaceState>;
  postWorkspaces: (options: object) => Promise<any>;
}

interface IProps {
  setCurrentScreen: Dispatch<SetStateAction<number>>;
}

interface IStateProps {
  authUser: IAuthUser;
  workspaces: IWorkspaceReducer;
  suggestedNames: ISuggestedNamesWorkspaces;
  application: IApplication;
  culture: ICultureState;
  countries: ICountries[];
  istab?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setWorkspaceTitle: {
      fontSize: "22px",
      textAlign: "left",
      color: "#313B53",
      fontWeight: 700,
      marginBottom: theme.spacing(7),
    },
    btnNames: {
      padding: "5px 8px",
      marginRight: "8px",
    },
  })
);

const options = {
  applicationId: 2,
  culture: {},
};

function StepWorkspaceCountries(props: IDispatchProps & IStateProps & IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const {
    setCurrentScreen,
    getAllCountries,
    putWorkspaceCountries,
    getAllWorkspace,
    getWorkspace,
    countries,
    workspaces,
    istab
  } = props;
  const [countriesValues, setCountries] = useState<string[]>(workspaces.countries ?? []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCountries(event.target.value as string[]);
  };

  const handleDelete = (item: any) => {
    setCountries(countriesValues.filter(country => country !== item))
  }

  useEffect(() => {
    getAllCountries({});
  }, [getAllCountries])

  async function putCountriesWorkspace() {
    setLoadingSubmit(true);

    let params = {
      culture: props?.culture,
      countries: countriesValues,
      workspaceId: workspaces?.workspaceId,
    };

    options.culture = props?.culture;

    try {
      await putWorkspaceCountries(params);

      if (!istab) {
        await getAllWorkspace(options);
        setCurrentScreen((prevState) => prevState + 1);
      } else {
        await getWorkspace(params);
      }
      setLoadingSubmit(false);
    } catch (e) {
      setLoadingSubmit(false);
    }
  }

  return (
    <Container>
      <Typography className={classes.setWorkspaceTitle}>
        <Resource tag="PageSetup::WorkspaceCountries" />
      </Typography>
      <FormControl fullWidth>
        <InputLabel shrink id="multiple-label">
          <Box display="flex" alignItems="center" gridGap="5px">
            <Resource tag="PageSetup::Countries" />
            <ToolTip children={<Resource tag="PageSetup::CountriesToltip" />} />
          </Box>
        </InputLabel>
        <Select
          labelId="multiple-label"
          id="multiple"
          multiple
          fullWidth
          value={countriesValues}
          onChange={handleChange}
          input={<Input />}
        >
          {countries?.map((country) => (
            <MenuItem key={country.name} value={country.countryId}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box
        pt="5px"
        display="flex"
        gridGap="5px"
        flexWrap="wrap"
        justifyContent="center"
      >
        {countriesValues?.map(item => {
          return (
            <Chip
              label={countries.filter(country => country.countryId === item)[0]?.name}
              onDelete={() => handleDelete(item)}
              variant="outlined"
            />
          )
        })}
      </Box>

      <Box textAlign="right" mt={7}>
        <Button
          disabled={countriesValues.length === 0}
          onClick={putCountriesWorkspace}
          color="primary"
          variant="contained"
          endIcon={
            !loadingSubmit ? (
              <ArrowForward className="icon icon-arrow" />
            ) : (
              <CircularProgress
                size={22}
                style={{ color: `${theme.palette.common.white}` }}
              />
            )
          }
        >
          {istab ?
            <Resource tag="PageSetup::Save" /> :
            <Resource tag="PageSetup::Continue" />
          }
        </Button>
      </Box>
    </Container>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllWorkspace: (options: object) => dispatch(getAllWorkspaces(options)),
    getWorkspace: (options: object) => dispatch(getWorkspace(options)),
    getSuggestedNamesWorkspace: (options: object) =>
      dispatch(getSuggestedNamesWorkspaces(options)),
    putWorkspaceCountries: (params: object) => dispatch(putWorkspacesCountries(params)),
    postWorkspaces: (params: object) => dispatch(postWorkspaces(params)),
    getAllCountries: (params: object) => dispatch(getAllCountries(params))
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    authUser: store.authState.auth,
    countries: store.usersState.countries,
    application: store.tenantState.application,
    culture: store.cultureState,
    workspaces: store.workSpacesState.workspaces,
    suggestedNames: store.workSpacesState.suggestedNamesWorkspaces,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepWorkspaceCountries);
