import { toast } from "react-toastify";
import { store } from "redux/store/Store";
import reactNewApp from "utils/reactNewApp";
import { ActionCreator, Dispatch } from "redux";
import { treatError } from "../../utils/treatError";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { resource } from "../../components/Resources/Resource";
import { api_v2, api_general, languageCulture } from "../../config/api";
import { ICultureSetCulture, setCultureAndResources } from "./CultureActions";
import {
  IProfile,
  IProfileData,
  IProfileState,
} from "../reducers/profileReducer";

const basePath = "users";

export enum ProfileActionTypes {
  GET_DATA = "GET_DATA",
  TOGGLE_PROFILE = "TOGGLE_PROFILE",
  SHOW_NOTIFICATION = "SHOW_NOTIFICATION",
  GET_PROFILE_DATA = "GET_PROFILE_DATA",
  UPDATE_PROFILE = "UPDATE_PROFILE",
}

export interface IProfileGetAllAction {
  type: ProfileActionTypes.GET_DATA;
  profiles: IProfile[];
}

export interface IProfileGetDataAction {
  type: ProfileActionTypes.GET_PROFILE_DATA;
  profileData: IProfileData;
}

export interface IProfileUpdateAction {
  type: ProfileActionTypes.UPDATE_PROFILE;
  updateSuccess: boolean;
}

export interface IProfileToggleAction {
  type: ProfileActionTypes.TOGGLE_PROFILE;
}

export type ProfileActions =
  | IProfileGetAllAction
  | IProfileToggleAction
  | IProfileGetDataAction
  | IProfileUpdateAction;

export const getProfile: ActionCreator<ThunkAction<
  Promise<any>,
  IProfileState,
  null,
  IProfileGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";

      params_qs = `?${params.join("&")}`;

      reactNewApp
        ? await api_general
            .get(`${basePath}/totals${params_qs}`, {})
            .then((response) => {
              dispatch({
                profiles: [response.data],
                type: ProfileActionTypes.GET_DATA,
              });
            })
        : await api_v2
            .get(`${basePath}/totals${params_qs}`, {})
            .then((response) => {
              dispatch({
                profiles: [response.data],
                type: ProfileActionTypes.GET_DATA,
              });
            });
    } catch (err) {
      treatError(options?.culture, "Profile", err);
    }
  };
};

export const getProfileData: ActionCreator<ThunkAction<
  Promise<any>,
  IProfileState,
  null,
  IProfileGetDataAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      if (reactNewApp) {
        const response = await api_general.get(`${basePath}/profile`, {});
        dispatch({
          profileData: response.data,
          type: ProfileActionTypes.GET_PROFILE_DATA,
        });
        return response.data;
      } else {
        const response = await api_v2.get(`${basePath}/profile`, {});
        dispatch({
          profileData: response.data,
          type: ProfileActionTypes.GET_PROFILE_DATA,
        });
        return response.data;
      }
    } catch (err) {
      treatError(options?.culture, "Profile", err);
    }
  };
};

export const updateProfile: ActionCreator<ThunkAction<
  Promise<any>,
  IProfileState,
  null,
  IProfileUpdateAction
>> = (params, options) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      reactNewApp
        ? api_general.post(`${basePath}/profile`, params).then(
            (response) => {
              console.log("updateProfile response", response);
              dispatch({
                updateSuccess: response.data.success,
                type: ProfileActionTypes.UPDATE_PROFILE,
              });
              let status = response.status;

              getResourcesHandler(params.cultureTag).then(
                (response) => {
                  if (status === 200) {
                    toast.success(
                      resource(response, "PageProfile::SaveMessage")
                    );
                  }
                }
              );
              languageCulture(params.cultureTag);

              return resolve({
                updateSuccess: response.data.success,
              });
            }).catch((err) => {
              if (err.response && (err.response.status === 408 || err.response.status === 504 || err.response.status === 502)) {
                dispatch({
                  updateSuccess: true,
                  type: ProfileActionTypes.UPDATE_PROFILE,
                });
                getResourcesHandler(params.cultureTag).then(
                  (response) => {
                    toast.success(
                      resource(response, "PageProfile::SaveMessage")
                    );
                  }
                );
                languageCulture(params.cultureTag);
  
                return resolve({
                  updateSuccess: true,
                });
              }else{
                toast.error(err.error_message);
                return reject(err);
              }
            }
          )
        : api_v2.patch(`${basePath}/profile`, params).then(
            (response) => {
              console.log("updateProfile v2 response", response);
              dispatch({
                updateSuccess: response.data.success,
                type: ProfileActionTypes.UPDATE_PROFILE,
              });

              let status = response.status;

              getResourcesHandler(params.cultureTag).then((response) => {
                if (status === 200) {
                  toast.success(resource(response, "PageProfile::SaveMessage"));
                }
              });
              languageCulture(params.cultureTag);

              return resolve({
                updateSuccess: response.data.success,
              });
            },
            (err) => {
              toast.error(err.error_message);
              return reject(err);
            }
          );
    });
  };
};

export const toggleProfile: ActionCreator<IProfileToggleAction> = () => ({
  type: ProfileActionTypes.TOGGLE_PROFILE,
});

async function getResourcesHandler(cultureTag: string) {
  let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store?.dispatch;
  await dispatch(setCultureAndResources(cultureTag));
  return store?.getState().cultureState;
}
