import Truncate from "react-truncate";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import { IUsers } from "redux/reducers/usersReducers";
import XDatepicker from "common/UI/XDatepicker/XDatepicker";
import { ICultureState } from "redux/reducers/cultureReducer";
import XAutocomplete from "common/UI/XAutocomplete/XAutocomplete";
import {
  Avatar,
  Box,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  makeStyles,
  Radio,
  RadioGroup,
  Slider,
  Switch,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { IWFFlow, PageState } from "redux/types/createChallenge";

interface IProps {
  users: IUsers[];
  culture: ICultureState;
  flowState: IWFFlow;
  flowSetState: (newValue: IWFFlow) => void;
  evaluationType?: number;
  totalUsers?: number;
  order: number;
  stepsState: PageState;
  development?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    actualManagers: {
      marginRight: "auto",
      marginTop: theme.spacing(2),
      minWidth: "300px",
      width: "100%",
      gap: "24px",
      maxHeight: "150px",
      overflowY: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",

      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    numberPercentage: {
      width: "60px",
      fontWeight: "bold",
      fontSize: "1.5rem",
      height: "28px",
      padding: 0,
      "text-align-last": "center",
      margin: "0 10px",
      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "-moz-appearance": "textfield",
    },
  })
);

function Flow({
  users,
  culture,
  flowState,
  evaluationType,
  flowSetState,
  totalUsers,
  order,
  stepsState,
  development,
}: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const [autoComplete, setAutoComplete] = useState<Array<IUsers>>(flowState?.responsibles ?? []);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const minDate = order - 2 >= 0 ? stepsState?.stepWorkflow.cards?.[order - 2]?.endDate : tomorrow;

  const handleDeleteValue = (value: any) => {
    setAutoComplete(autoComplete.filter((x) => x.fullName !== value));
  };

  useEffect(() => {
    flowSetState({ ...flowState, responsibles: autoComplete });
    // eslint-disable-next-line
  }, [autoComplete, flowSetState]);

  const [flowRuleParticipants, setFlowRuleParticipants] = useState<string | number>(
    flowState?.flowRuleParticipants ?? ""
  );
  const [flowRuleAverage, setFlowRuleAverage] = useState<string | number>(flowState?.flowRuleAverage ?? "");

  useEffect(() => {
    flowSetState({
      ...flowState,
      flowRuleParticipants,
    });
    // eslint-disable-next-line
  }, [flowRuleParticipants]);

  useEffect(() => {
    flowSetState({
      ...flowState,
      flowRuleAverage,
    });
    // eslint-disable-next-line
  }, [flowRuleAverage]);

  useEffect(() => {
    flowSetState({
      ...flowState,
    });
    // eslint-disable-next-line
  }, [evaluationType]);

  //slider value
  const [valueSlider, setValueSlider] = React.useState<number | string | Array<number | string>>(
    flowState?.flowRuleAverage ?? ""
  );

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setValueSlider(newValue);
  };

  const handleInputChangeSlider = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueSlider(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlurSlider = () => {
    if (valueSlider < 0) {
      setValueSlider(0);
    } else if (valueSlider > 100) {
      setValueSlider(100);
    }
  };
  useEffect(() => {
    flowSetState({
      ...flowState,
      flowRuleAverage: Number(valueSlider),
    });
    // eslint-disable-next-line
  }, [valueSlider]);

  const handleDatetypeChange = (value: number) => {
    if (value === 1 && stepsState?.stepDetail?.challengeType === 1) {
      flowSetState({
        ...flowState,
        dateType: value,
        endTransitionDays: 0,
        endTransitionDate: new Date(minDate),
      });
    } else {
      flowSetState({
        ...flowState,
        dateType: value,
        endTransitionDate: null,
        endTransitionDays: 1,
      });
    }
  };

  return (
    <Box pt={1}>
      <Grid container item direction="column" spacing={3}>
        <Grid container item alignItems="center" spacing={3}>
          <Grid item>
            <Typography variant="h4">
              <Resource tag={"PageCreateChallenge::Workflow_Automations"} />
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} xl={3}>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="caption" style={{ textTransform: "uppercase" }}>
                    <Resource tag={"PageCreateChallenge::Workflow_OFF"} />
                  </Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={flowState.isAutomaticFlow}
                    onChange={(e) =>
                      flowSetState({
                        ...flowState,
                        isAutomaticFlow: e.target.checked,
                      })
                    }
                    color="primary"
                    name="haveComments"
                  // inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="caption" style={{ textTransform: "uppercase" }}>
                    <Resource tag={"PageCreateChallenge::Workflow_ON"} />
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item xs={9} sm={9} md={9} xl={9}>
              <Typography variant="body2">
                <Resource tag={"PageCreateChallenge::Workflow_AutomaticallyTransit"} />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Divider style={{ width: "100%" }} />
        </Grid>
        <Grid item>
          {flowState.isAutomaticFlow ? (
            <Box>
              <Box>
                <Typography variant="h4">
                  <Resource tag={"PageCreateChallenge::Workflow_RulesAutomaticTransition"} />
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant="body2">
                  <Resource tag={"PageCreateChallenge::Workflow_AtTheEndOfTheTimeForEvaluation"} />
                </Typography>
                {development ? (
                  <Box mt={6} display='flex' alignItems="center">
                    <Box height={"1px"} width={15} mr={2} border={`1px solid ${theme.palette.text.primary}`} />
                    <Resource tag="PageCreateChallenge::DevelopmentFlowFilledRequiredFields" />
                  </Box>
                ) : (
                  <Box>
                    {evaluationType === 2 ? (
                      <>
                        <Box className="container-slider" display="flex" alignItems="center" mt={4}>
                          <Box height="1px" width={15} mr={2} border={`1px solid ${theme.palette.text.primary}`} />
                          <Box>
                            <Resource tag={"PageCreateChallenge::Workflow_IfTheIdeaBeliefReaches"} />
                          </Box>
                          <Box width={200} ml={3} mr={2}>
                            <Slider
                              defaultValue={65}
                              valueLabelDisplay="auto"
                              step={5}
                              marks
                              min={50}
                              max={95}
                              value={typeof valueSlider === "number" ? valueSlider : 0}
                              onChange={handleSliderChange}
                            />
                          </Box>
                          <Input
                            inputProps={{
                              step: 5,
                              min: 0,
                              max: 100,
                              type: "number",
                            }}
                            value={valueSlider}
                            margin="dense"
                            className={classes.numberPercentage}
                            onChange={handleInputChangeSlider}
                            onBlur={handleBlurSlider}
                          />
                          <Box fontWeight={500} fontSize="1.2rem">
                            %
                          </Box>
                        </Box>
                        <Box mt={3}>
                          <Resource
                            tag={"PageCreateChallenge::Workflow_ThisBeliefIsAchieved"}
                            args={[totalUsers && Math.round(totalUsers * (Number(valueSlider) / 100)), 200]}
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box display="flex" alignItems="center" mt={4} height={12}>
                          <Box height={"1px"} width={15} mr={2} border={`1px solid ${theme.palette.text.primary}`} />
                          <Resource tag={"PageCreateChallenge::Workflow_IfItHasBeenAssessed"} />
                          <Input
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            type="number"
                            value={flowRuleParticipants}
                            onChange={(e) =>
                              Number(e.target.value) < 101
                                ? setFlowRuleParticipants(e.target.value)
                                : setFlowRuleParticipants(100)
                            }
                            className={classes.numberPercentage}
                          />
                          <Resource tag={"PageCreateChallenge::Workflow_OfTheParticipants"} />
                          {/* <Divider orientation="vertical" style={{ marginLeft: 8, marginRight: 8 }} />
                          <Typography color="textSecondary" variant="body2"> 
                            {totalUsers} <Resource tag={"PageCreateChallenge::Workflow_Users"} />
                          </Typography> */}
                        </Box>
                        <Box display="flex" alignItems="center" mt={4} height={12}>
                          <Box height={"1px"} width={15} mr={2} border={`1px solid ${theme.palette.text.primary}`} />
                          <Box mr={0.4}>
                            <Resource tag={"PageCreateChallenge::Workflow_AndIfItReaches"} />
                          </Box>
                          <Input
                            inputProps={{
                              style: { textAlign: "center" },
                            }}
                            type="number"
                            value={flowRuleAverage}
                            onChange={(e) =>
                              Number(e.target.value) < 6 ? setFlowRuleAverage(e.target.value) : setFlowRuleAverage(5)
                            }
                            className={classes.numberPercentage}
                          />
                          <Resource tag={"PageCreateChallenge::Workflow_OrMoreStars"} />
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </Box>

              <Box mt={development ? 7 : 11}>
                <Typography color="textSecondary" variant="body1">
                  <Resource tag={"PageCreateChallenge::Workflow_TheIdeaIsRejected"} />
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box>
                <Typography variant="h4">
                  <Resource tag={"PageCreateChallenge::Workflow_ResponsibleForManualTransition"} />
                </Typography>
                <Box mt={2}>
                  <Typography color="textSecondary" variant="body2">
                    <Resource tag={"PageCreateChallenge::Workflow_DefineTheUsers"} />
                  </Typography>
                </Box>
                <Box mt={2}>
                  <XAutocomplete
                    options={users}
                    optionName={"fullName"}
                    optionAvatar={"photo"}
                    optionSubtitle={"email"}
                    autoCompleteValue={autoComplete}
                    onChange={(newValue) => setAutoComplete(newValue)}
                    culture={culture}
                  />
                  <Box className={classes.actualManagers} mt={1}>
                    {autoComplete?.map((value: any, index: number) => {
                      return (
                        <div className="manager-item-containerPopup" key={value.userId ?? index}>
                          <Avatar src={value?.photo} alt={value.fullName} className={classes.thumbnail} />
                          <div className="manager-item-label">
                            <Truncate width={120} ellipsis={<span>...</span>}>
                              {value.fullName}
                            </Truncate>
                          </div>
                          <div className="manage-item-cross">
                            <IconButton
                              className="bottom-manage-item-cross"
                              onClick={() => handleDeleteValue(value.fullName)}
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                  </Box>
                </Box>
                <Box mt={13}>
                  <Typography variant="h4">
                    <Resource tag={"PageCreateChallenge::Workflow_TimingsforIdea"} />
                  </Typography>
                  {stepsState?.stepDetail?.challengeType === 1 && (
                    <Box mt={1}>
                      <Typography variant="body2" color="textSecondary">
                        <Resource tag={"PageCreateChallenge::Workflow_SelectADeadline"} />
                      </Typography>
                    </Box>
                  )}
                  <Box mt={2}>
                    {stepsState?.stepDetail?.challengeType === 1 && (
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          name="dateType"
                          value={flowState.dateType}
                          onChange={(e, v) => handleDatetypeChange(Number(v))}
                        >
                          <FormControlLabel
                            color="primary"
                            value={1}
                            control={<Radio color="primary" />}
                            label={<Resource tag={"PageCreateChallenge::Workflow_FinalDate"} />}
                          />
                          <FormControlLabel
                            color="primary"
                            value={2}
                            control={<Radio color="primary" />}
                            label={<Resource tag={"PageCreateChallenge::Workflow_MaximumNumOfDaysToTransit"} />}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}

                    <Divider style={{ width: "100%", marginTop: 8 }} />
                    <Box mt={3} width={"50%"}>
                      {stepsState?.stepDetail?.challengeType === 1 && flowState.dateType === 1 ? (
                        <XDatepicker
                          minDate={minDate}
                          value={flowState.endTransitionDate}
                          onChange={(date) =>
                            flowSetState({
                              ...flowState,
                              endTransitionDate: date,
                            })
                          }
                          minDateMessage={
                            <Typography variant="caption" color="error">
                              <Resource tag="PageCreateChallenge::MinDateMessage" />
                            </Typography>
                          }
                          fullWidth
                        />
                      ) : (
                        <Box mt={1.5}>
                          <Typography variant="body2" color="textSecondary" style={{ textTransform: "uppercase" }}>
                            {stepsState?.stepDetail?.challengeType === 1 ? (
                              <Resource tag={"PageCreateChallenge::Workflow_NumOfDays"} />
                            ) : (
                              <Resource tag={"PageCreateChallenge::Workflow_NumOfDaysInThisPhase"} />
                            )}
                          </Typography>
                          <TextField
                            value={flowState.endTransitionDays}
                            onChange={(e) =>
                              flowSetState({
                                ...flowState,
                                endTransitionDays: e.target.value,
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Flow;
