import React from 'react';
import { Box, Grid } from '@material-ui/core';
import SkeletonGrid from '../../../common/UI/Skeleton/SkeletonGrid';
import { Skeleton } from '@material-ui/lab';

export default function IdeaFormSkeleton() {

  return (
    <>
      <Box mx={2}>

        <Grid item container spacing={2}>
          <Grid item xs={12} >
            <Skeleton variant="rect" width={"100%"} height={100}/>
          </Grid>
          <Grid item xs={12} style={{margin:"48px 0"}}>
            <Skeleton variant="rect" width={"100%"} height={50}/>
          </Grid>
          <Grid item xs={12} style={{marginBottom:"48px"}}>
            <Skeleton variant="rect" width={"100%"} height={300}/>
          </Grid>
          <Grid item xs={12} >
            <SkeletonGrid col={1} row={10} rowHeight={50} />
          </Grid>
        </Grid>

      </Box>
    </>
  )
}