import React from "react";
import XCard from "common/X/XCard";
import Resource from "../Resources/Resource";
import { AvatarGroup } from "@material-ui/lab";
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Box,
  Typography,
  Avatar,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridExpectedROI: {
      height: "100%",
      textAlign: "end",
      justifyContent: "flex-end",
      paddingRight: 5,
    },
    small: {
      width: "21px",
      height: "20px",
    },
    author: {
      fontSize: "0.6rem",
      textTransform: "uppercase",
    },
    authorName: {
      fontSize: "0.6rem",
      textTransform: "capitalize",
      fontWeight: 500,
    },
    avatarGroup: {
      "& .MuiAvatarGroup-avatar": {
        border: "none",
      },
    },
    button: {
      fontSize: "0.6rem",
      fontWeight: 500,
      textTransform: "uppercase",
      padding: "0 10px",
    },
    space: {
      marginRight: theme.spacing(6),
    },
    truncate: {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
  })
);

interface IProps {
  idea: any;
}

function CardIdeaPortfolio(props: IProps) {
  const classes = useStyles();
  return (
    <XCard
      key={props?.idea?.ideaId}
      link={`/idea/details/${props?.idea?.ideaId}`}
      color={props?.idea?.isChosenForImplementation ? "success" : "info"}
      elevation={2}
      height={155}
      topContentPadding={10}
      middleContentPadding={10}
      bottomContentPadding={10}
      topContent={
        <Box ml={0.5} mr={0.5}>
          <Typography
            variant="subtitle1"
            style={{ textTransform: "uppercase" }}
          >
            {props?.idea?.isImplemented === true ? (
              <Resource tag={"IdeaCard::Implemented"} />
            ) : (
              <Resource tag={"IdeaCard::ChosenForImplementation"} />
            )}
          </Typography>
        </Box>
      }
      middleContent={
        <Box ml={0.5} mr={0.5}>
          <Grid container style={{ height: "59px" }}>
            <Grid item xs={8} sm={8} md={9}>
              <Typography variant="h4" className={classes.truncate}>
                {props?.idea?.title}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={3}>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.gridExpectedROI}
                >
                  <Typography variant="h4">
                    {props?.idea?.isImplemented === true &&
                    props?.idea?.achievedROI > 0 ? (
                      <>$ {props?.idea?.achievedROI}</>
                    ) : (
                      props?.idea?.expectedROI > 0 && (
                        <>$ {props?.idea?.expectedROI}</>
                      )
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.gridExpectedROI}>
                <Typography variant="subtitle2">
                  {props?.idea?.isImplemented === true ? (
                    <>
                      {props?.idea?.achievedROI > 0 ? (
                        <Resource tag={"IdeaCard::AchievedROI"} />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {props?.idea?.expectedROI > 0 ? (
                        <Resource tag={"IdeaCard::ExpectedROI"} />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {props?.idea?.coAuthors?.length > 0 &&
            props?.idea?.isAllowedViewOwnerOrCoOwner ? (
              <Box className="author-e-coauthor">
                <Box
                  style={{
                    color: `${
                      props?.idea?.isChosenForImplementation
                        ? "#FFF"
                        : "#313B53"
                    }`,
                  }}
                  className={classes.author}
                >
                  <React.Fragment>
                    <Box display="flex">
                      <Resource tag={"PageDetailIdea::Idea_Author"} />
                      <Box ml={0.5} mr={0.5}>
                        {" "}
                        &{" "}
                      </Box>
                      <Box>
                        {props?.idea?.coAuthors?.length === 1 ? (
                          <>
                            <Resource tag={"PageDetailIdea::Idea_CoAuthor"} />
                            <>:</>
                          </>
                        ) : (
                          <>
                            <Resource tag={"PageDetailIdea::Idea_CoAuthors"} />
                            <>:</>
                          </>
                        )}
                      </Box>
                    </Box>
                  </React.Fragment>
                </Box>
                <Box
                  display="flex"
                  style={{ gap: "5px" }}
                  className="avatar-group-author-more-coauthor"
                >
                  <Box mr={3}>
                    <Avatar
                      className={classes.small}
                      alt={props?.idea?.author?.fullName}
                      src={props?.idea?.author?.photo}
                    />
                  </Box>
                  <AvatarGroup max={4} className={classes.avatarGroup}>
                    {props?.idea?.coAuthors.map((coAuthor: any, index: any) => {
                      return (
                        <Avatar
                          className={classes.small}
                          alt={coAuthor?.fullName}
                          src={coAuthor?.photo}
                          key={index}
                        />
                      );
                    })}
                  </AvatarGroup>
                </Box>
              </Box>
            ) : (
              props?.idea?.isAllowedViewOwnerOrCoOwner && (
                <Box className="author-avatar-and-fullname">
                  <Typography
                    className={classes.author}
                    style={{
                      color: `${
                        props?.idea?.isChosenForImplementation
                          ? "#FFF"
                          : "#313B53"
                      }`,
                    }}
                  >
                    <Resource tag={"PageDetailIdea::Idea_Author"} />:
                  </Typography>
                  <Box display="flex" alignItems="center" gridGap={5}>
                    <Avatar
                      className={classes.small}
                      alt={props?.idea?.author?.fullName}
                      src={props?.idea?.author?.photo}
                    />
                    <Typography className={classes.authorName}>
                      {props?.idea?.author?.fullName}
                    </Typography>
                  </Box>
                </Box>
              )
            )}
          </Grid>
        </Box>
      }
      bottomContent={
        <>
          {/*
         TODO: implemented the action for buttons
         <Box mt={1} ml={0.5} mr={0.5}>
           <Grid container spacing={2} alignItems="center">
             <Grid item>
               <EMIcon
                size={14}
                icon="comments-b"
                color={isChosenForImplementation ? "fff" : theme.palette.primary.main}
              />
            </Grid>
            <Grid item className={classes.space}>
              <EMIcon
                size={14}
                icon="send"
                color={isChosenForImplementation ? "fff" : theme.palette.primary.main}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color={isChosenForImplementation ? "default" : "primary"}
                size="small"
                className={classes.button}
              >
                <Resource tag={"PageIdea::Button_Congratulate"} />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color={isChosenForImplementation ? "inherit" : "primary"}
                size="small"
                className={classes.button}
              >
                {props?.idea?.isFollowing === true ? (
                  <Resource tag={"PageIdea::Button_Following"} />
                ) : (
                  <Resource tag={"PageIdea::Button_Follow"} />
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
                */}
        </>
      }
    />
  );
}

export default CardIdeaPortfolio;
