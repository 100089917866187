import { toast } from "react-toastify";
import { ThunkAction } from "redux-thunk";
import { api_v3 } from "./../../config/api";
import { ActionCreator, Dispatch } from "redux";
import { treatError } from "../../utils/treatError";
import {
  IWorkspaceReducer,
  IWorkspaceState,
  ITheme,
  ISuggestedNamesWorkspaces,
  IImagesWorkspaces,
  ICulturesWorkspaces,
} from "redux/reducers/workspaceReducer";
import { resource } from "components/Resources/Resource";
import { IAuthUser } from "redux/reducers/authReducer";

const basePath = "workspaces";

// Create Action Constants
export enum WorkspaceActionTypes {
  GET_ALL_WORKSPACES_LOADING = "GET_ALL_WORKSPACES_LOADING",
  GET_ALL_WORKSPACES_SUCCEEDED = "GET_ALL_WORKSPACES_SUCCEEDED",
  GET_ALL_WORKSPACES_FAILED = "GET_ALL_WORKSPACES_FAILED",

  GET_SUGGESTEDNAMES_WORKSPACES_LOADING = "GET_SUGGESTEDNAMES_WORKSPACES_LOADING",
  GET_SUGGESTEDNAMES_WORKSPACES_SUCCEEDED = "GET_SUGGESTEDNAMES_WORKSPACES_SUCCEEDED",
  GET_SUGGESTEDNAMES_WORKSPACES_FAILED = "GET_SUGGESTEDNAMES_WORKSPACES_FAILED",

  GET_IMAGES_WORKSPACES_LOADING = "GET_IMAGES_WORKSPACES_LOADING",
  GET_IMAGES_WORKSPACES_SUCCEEDED = "GET_IMAGES_WORKSPACES_SUCCEEDED",
  GET_IMAGES_WORKSPACES_FAILED = "GET_IMAGES_WORKSPACES_FAILED",

  GET_CULTURES_WORKSPACES_LOADING = "GET_CULTURES_WORKSPACES_LOADING",
  GET_CULTURES_WORKSPACES_SUCCEEDED = "GET_CULTURES_WORKSPACES_SUCCEEDED",
  GET_CULTURES_WORKSPACES_FAILED = "GET_CULTURES_WORKSPACES_FAILED",

  GET_THEMES_WORKSPACES_LOADING = "GET_THEMES_WORKSPACES_LOADING",
  GET_THEMES_WORKSPACES_SUCCEEDED = "GET_THEMES_WORKSPACES_SUCCEEDED",
  GET_THEMES_WORKSPACES_FAILED = "GET_THEMES_WORKSPACES_FAILED",

  POST_WORKSPACE_CATEGORY_LOADING = "POST_CATEGORY_LOADING",
  POST_WORKSPACE_CATEGORY_SUCCEEDED = "POST_CATEGORY_SUCCEEDED",
  POST_WORKSPACE_CATEGORY_FAILED = "POST_CATEGORY_FAILED",

  POST_WORKSPACE_INVITE_LOADING = "POST_INVITE_LOADING",
  POST_WORKSPACE_INVITE_SUCCEEDED = "POST_INVITE_SUCCEEDED",
  POST_WORKSPACE_INVITE_FAILED = "POST_INVITE_FAILED",

  POST_WORKSPACE_LANGUAGE_LOADING = "POST_WORKSPACE_LANGUAGE_LOADING",
  POST_WORKSPACE_LANGUAGE_SUCCEEDED = "POST_WORKSPACE_LANGUAGE_SUCCEEDED",
  POST_WORKSPACE_LANGUAGE_FAILED = "POST_WORKSPACE_LANGUAGE_FAILED",

  PUT_WORKSPACE_CATEGORY_LOADING = "PUT_WORKSPACE_CATEGORY_LOADING",
  PUT_WORKSPACE_CATEGORY_SUCCEEDED = "PUT_WORKSPACE_CATEGORY_SUCCEEDED",
  PUT_WORKSPACE_CATEGORY_FAILED = "PUT_WORKSPACE_CATEGORY_FAILED",

  PUT_WORKSPACE_NAME_LOADING = "PUT_WORKSPACE_NAME_LOADING",
  PUT_WORKSPACE_NAME_SUCCEEDED = "PUT_WORKSPACE_NAME_SUCCEEDED",
  PUT_WORKSPACE_NAME_FAILED = "PUT_WORKSPACE_NAME_FAILED",

  PUT_WORKSPACE_DEPARTMENTS_LOADING = "PUT_WORKSPACE_DEPARTMENTS_LOADING",
  PUT_WORKSPACE_DEPARTMENTS_SUCCEEDED = "PUT_WORKSPACE_DEPARTMENTS_SUCCEEDED",
  PUT_WORKSPACE_DEPARTMENTS_FAILED = "PUT_WORKSPACE_NAME_FAILED",

  PUT_WORKSPACE_COUNTRIES_LOADING = "PUT_WORKSPACE_COUNTRIES_LOADING",
  PUT_WORKSPACE_COUNTRIES_SUCCEEDED = "PUT_WORKSPACE_COUNTRIES_SUCCEEDED",
  PUT_WORKSPACE_COUNTRIES_FAILED = "PUT_WORKSPACE_COUNTRIES_FAILED",

  PUT_WORKSPACE_CURRENCY_LOADING = "PUT_WORKSPACE_CURRENCY_LOADING",
  PUT_WORKSPACE_CURRENCY_SUCCEEDED = "PUT_WORKSPACE_CURRENCY_SUCCEEDED",
  PUT_WORKSPACE_CURRENCY_FAILED = "PUT_WORKSPACE_CURRENCY_FAILED",

  PUT_WORKSPACE_THEMES_LOADING = "PUT_WORKSPACE_THEMES_LOADING",
  PUT_WORKSPACE_THEMES_SUCCEEDED = "PUT_WORKSPACE_THEMES_SUCCEEDED",
  PUT_WORKSPACE_THEMES_FAILED = "PUT_WORKSPACE_THEMES_FAILED",

  PUT_WORKSPACE_IMAGES_LOADING = "PUT_WORKSPACE_IMAGES_LOADING",
  PUT_WORKSPACE_IMAGES_SUCCEEDED = "PUT_WORKSPACE_IMAGES_SUCCEEDED",
  PUT_WORKSPACE_IMAGES_FAILED = "PUT_WORKSPACE_IMAGES_FAILED",

  PUT_WORKSPACE_COMPLETED_LOADING = "PUT_WORKSPACE_COMPLETED_LOADING",
  PUT_WORKSPACE_COMPLETED_SUCCEEDED = "PUT_WORKSPACE_COMPLETED_SUCCEEDED",
  PUT_WORKSPACE_COMPLETED_FAILED = "PUT_WORKSPACE_COMPLETED_FAILED",
}
export enum WorkspaceActionStatus {
  GET_ALL_WORKSPACES_LOADING = "LOADING_PREVIEW",
  GET_ALL_WORKSPACES_SUCCEEDED = "SUCCEEDED_ALL_WORKSPACES",
  GET_ALL_WORKSPACES_FAILED = "FAILED_ALL_WORKSPACES",

  GET_SUGGESTEDNAMES_WORKSPACES_LOADING = "LOADING_SUGGESTEDNAMES",
  GET_SUGGESTEDNAMES_WORKSPACES_SUCCEEDED = "SUCCEEDED_SUGGESTEDNAMES_WORKSPACES",
  GET_SUGGESTEDNAMES_WORKSPACES_FAILED = "FAILED",

  GET_IMAGES_WORKSPACES_LOADING = "LOADING_IMAGES",
  GET_IMAGES_WORKSPACES_SUCCEEDED = "SUCCEEDED_IMAGES_WORKSPACES",
  GET_IMAGES_WORKSPACES_FAILED = "FAILED_IMAGES_WORKSPACES",

  GET_CULTURES_WORKSPACES_LOADING = "LOADING_CULTURES",
  GET_CULTURES_WORKSPACES_SUCCEEDED = "SUCCEEDED_CULTURES_WORKSPACES",
  GET_CULTURES_WORKSPACES_FAILED = "FAILED_CULTURES_WORKSPACES",

  GET_THEMES_WORKSPACES_LOADING = "LOADING_THEMES",
  GET_THEMES_WORKSPACES_SUCCEEDED = "SUCCEEDED_THEMES_WORKSPACES",
  GET_THEMES_WORKSPACES_FAILED = "FAILED_THEMES_WORKSPACES",

  POST_WORKSPACE_CATEGORY_LOADING = "LOADING_CATEGORY",
  POST_WORKSPACE_CATEGORY_SUCCEEDED = "SUCCEEDED_WORKSPACE_CATEGORY",
  POST_WORKSPACE_CATEGORY_FAILED = "FAILED_WORKSPACE_CATEGORY",

  POST_WORKSPACE_LANGUAGE_LOADING = "POST_WORKSPACE_LANGUAGE_LOADING",
  POST_WORKSPACE_LANGUAGE_SUCCEEDED = "POST_WORKSPACE_LANGUAGE_SUCCEEDED",
  POST_WORKSPACE_LANGUAGE_FAILED = "POST_WORKSPACE_LANGUAGE_FAILED",

  POST_WORKSPACE_INVITE_LOADING = "LOADING_INVITE_WORKSPACE_INVITE",
  POST_WORKSPACE_INVITE_SUCCEEDED = "SUCCEEDED_WORKSPACE_INVITE",
  POST_WORKSPACE_INVITE_FAILED = "FAILED_WORKSPACE_INVITE",

  PUT_WORKSPACE_CATEGORY_LOADING = "LOADING_CATEGORY",
  PUT_WORKSPACE_CATEGORY_SUCCEEDED = "SUCCEEDED_WORKSPACE_CATEGORY",
  PUT_WORKSPACE_CATEGORY_FAILED = "FAILED_WORKSPACE_CATEGORY",

  PUT_WORKSPACE_NAME_LOADING = "LOADING_NAME",
  PUT_WORKSPACE_NAME_SUCCEEDED = "PUT_WORKSPACE_NAME_SUCCEEDED",
  PUT_WORKSPACE_NAME_FAILED = "PUT_WORKSPACE_NAME_FAILED",

  PUT_WORKSPACE_DEPARTMENTS_LOADING = "PUT_WORKSPACE_DEPARTMENTS_LOADING",
  PUT_WORKSPACE_DEPARTMENTS_SUCCEEDED = "PUT_WORKSPACE_DEPARTMENTS_SUCCEEDED",
  PUT_WORKSPACE_DEPARTMENTS_FAILED = "PUT_WORKSPACE_NAME_FAILED",

  PUT_WORKSPACE_COUNTRIES_LOADING = "PUT_WORKSPACE_COUNTRIES_LOADING",
  PUT_WORKSPACE_COUNTRIES_SUCCEEDED = "PUT_WORKSPACE_COUNTRIES_SUCCEEDED",
  PUT_WORKSPACE_COUNTRIES_FAILED = "PUT_WORKSPACE_COUNTRIES_FAILED",

  PUT_WORKSPACE_CURRENCY_LOADING = "PUT_WORKSPACE_CURRENCY_LOADING",
  PUT_WORKSPACE_CURRENCY_SUCCEEDED = "PUT_WORKSPACE_CURRENCY_SUCCEEDED",
  PUT_WORKSPACE_CURRENCY_FAILED = "PUT_WORKSPACE_CURRENCY_FAILED",
  
  PUT_WORKSPACE_THEMES_LOADING = "LOADING_THEMES",
  PUT_WORKSPACE_THEMES_SUCCEEDED = "SUCCEEDED_WORKSPACE_THEMES",
  PUT_WORKSPACE_THEMES_FAILED = "FAILED_WORKSPACE_THEMES",

  PUT_WORKSPACE_IMAGES_LOADING = "LOADING_IMAGES",
  PUT_WORKSPACE_IMAGES_SUCCEEDED = "SUCCEEDED_WORKSPACE_IMAGES",
  PUT_WORKSPACE_IMAGES_FAILED = "FAILED_WORKSPACE_IMAGES",

  PUT_WORKSPACE_COMPLETED_LOADING = "LOADING_WORKSPACE_COMPLETED",
  PUT_WORKSPACE_COMPLETED_SUCCEEDED = "SUCCEEDED_WORKSPACE_COMPLETED",
  PUT_WORKSPACE_COMPLETED_FAILED = "FAILED_WORKSPACE_COMPLETED",
}

// Interface for Get All Workspace Action Type
export interface IWorkspaceLoadingGetAllAction {
  type: WorkspaceActionTypes.GET_ALL_WORKSPACES_LOADING;
  status: string;
}
export interface IWorkspaceSucceededGetAllAction {
  type: WorkspaceActionTypes.GET_ALL_WORKSPACES_SUCCEEDED;
  workspaces: IWorkspaceReducer;
  status: string;
}
export interface IWorkspaceFailedGetAllAction {
  type: WorkspaceActionTypes.GET_ALL_WORKSPACES_FAILED;
  status: string;
  error: any;
}

// Interface for Get Suggested Names Workspace Action Type
export interface IWorkspaceLoadingGetSuggestedNamesAction {
  type: WorkspaceActionTypes.GET_SUGGESTEDNAMES_WORKSPACES_LOADING;
  status: string;
}
export interface IWorkspaceSucceededGetSuggestedNamesAction {
  type: WorkspaceActionTypes.GET_SUGGESTEDNAMES_WORKSPACES_SUCCEEDED;
  suggestedNamesWorkspaces: ISuggestedNamesWorkspaces;
  status: string;
}
export interface IWorkspaceFailedGetSuggestedNamesAction {
  type: WorkspaceActionTypes.GET_SUGGESTEDNAMES_WORKSPACES_FAILED;
  status: string;
  error: any;
}

// Interface for Get Images Workspace Action Type
export interface IWorkspaceLoadingGetImagesAction {
  type: WorkspaceActionTypes.GET_IMAGES_WORKSPACES_LOADING;
  status: string;
}
export interface IWorkspaceSucceededGetImagesAction {
  type: WorkspaceActionTypes.GET_IMAGES_WORKSPACES_SUCCEEDED;
  imagesWorkspaces: IImagesWorkspaces;
  status: string;
}
export interface IWorkspaceFailedGetImagesAction {
  type: WorkspaceActionTypes.GET_IMAGES_WORKSPACES_FAILED;
  status: string;
  error: any;
}

// Interface for Get Cultures Workspace Action Type
export interface IWorkspaceLoadingGetCulturesAction {
  type: WorkspaceActionTypes.GET_CULTURES_WORKSPACES_LOADING;
  status: string;
}
export interface IWorkspaceSucceededGetCulturesAction {
  type: WorkspaceActionTypes.GET_CULTURES_WORKSPACES_SUCCEEDED;
  culturesWorkspaces: ICulturesWorkspaces[];
  status: string;
}
export interface IWorkspaceFailedGetCulturesAction {
  type: WorkspaceActionTypes.GET_CULTURES_WORKSPACES_FAILED;
  status: string;
  error: any;
}

// Interface for Get Themes Workspace Action Type
export interface IWorkspaceLoadingGetThemesAction {
  type: WorkspaceActionTypes.GET_THEMES_WORKSPACES_LOADING;
  status: string;
}
export interface IWorkspaceSucceededGetThemesAction {
  type: WorkspaceActionTypes.GET_THEMES_WORKSPACES_SUCCEEDED;
  themesWorkspaces: ITheme;
  status: string;
}
export interface IWorkspaceFailedGetThemesAction {
  type: WorkspaceActionTypes.GET_THEMES_WORKSPACES_FAILED;
  status: string;
  error: any;
}

// Interface for Post Workspace Action Type
export interface IWorkspaceLoadingPostAction {
  type: WorkspaceActionTypes.POST_WORKSPACE_CATEGORY_LOADING;
  status: string;
}
export interface IWorkspaceSucceededPostAction {
  type: WorkspaceActionTypes.POST_WORKSPACE_CATEGORY_SUCCEEDED;
  workspaces: IWorkspaceReducer;
  status: string;
}
export interface IWorkspaceFailedPostAction {
  type: WorkspaceActionTypes.POST_WORKSPACE_CATEGORY_FAILED;
  status: string;
  error: any;
}

// Interface for Post Workspace Invite Action Type
export interface IWorkspaceInviteLoadingPostAction {
  type: WorkspaceActionTypes.POST_WORKSPACE_INVITE_LOADING;
  status: string;
}
export interface IWorkspaceInviteSucceededPostAction {
  type: WorkspaceActionTypes.POST_WORKSPACE_INVITE_SUCCEEDED;
  workspaces: IWorkspaceReducer;
  auth: IAuthUser;
  status: string;
}
export interface IWorkspaceInviteFailedPostAction {
  type: WorkspaceActionTypes.POST_WORKSPACE_INVITE_FAILED;
  status: string;
  error: any;
}

// Interface for Post Workspace Invite Action Type
export interface IWorkspaceCompletedLoadingPostAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_COMPLETED_LOADING;
  status: string;
}
export interface IWorkspaceCompletedSucceededPostAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_COMPLETED_SUCCEEDED;
  workspaces: IWorkspaceReducer;
  auth: IAuthUser;
  status: string;
}
export interface IWorkspaceCompletedFailedPostAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_COMPLETED_FAILED;
  status: string;
  error: any;
}

// Interface for Put Workspace category Action Type
export interface IWorkspaceCategoryLoadingPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_CATEGORY_LOADING;
  status: string;
}
export interface IWorkspaceCategorySucceededPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_CATEGORY_SUCCEEDED;
  workspaces: IWorkspaceReducer;
  status: string;
}
export interface IWorkspaceCategoryFailedPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_CATEGORY_FAILED;
  status: string;
  error: any;
}

// Interface for Put Workspace Name Action Type
export interface IWorkspaceNameLoadingPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_NAME_LOADING;
  status: string;
}
export interface IWorkspaceNameSucceededPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_NAME_SUCCEEDED;
  workspaces: IWorkspaceReducer;
  status: string;
}
export interface IWorkspaceNameFailedPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_NAME_FAILED;
  status: string;
  error: any;
}

// Interface for Put Workspace Themes Action Type
export interface IWorkspaceThemesLoadingPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_THEMES_LOADING;
  status: string;
}
export interface IWorkspaceThemesSucceededPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_THEMES_SUCCEEDED;
  workspaces: IWorkspaceReducer;
  status: string;
}
export interface IWorkspaceThemesFailedPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_THEMES_FAILED;
  status: string;
  error: any;
}

// Interface for Put Workspace Images Action Type
export interface IWorkspaceImagesLoadingPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_IMAGES_LOADING;
  status: string;
}
export interface IWorkspaceImagesSucceededPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_IMAGES_SUCCEEDED;
  workspaces: IWorkspaceReducer;
  status: string;
}
export interface IWorkspaceImagesFailedPutAction {
  type: WorkspaceActionTypes.PUT_WORKSPACE_IMAGES_FAILED;
  status: string;
  error: any;
}

/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type WorkspaceActions =
  | IWorkspaceLoadingGetAllAction
  | IWorkspaceSucceededGetAllAction
  | IWorkspaceFailedGetAllAction
  | IWorkspaceLoadingGetSuggestedNamesAction
  | IWorkspaceSucceededGetSuggestedNamesAction
  | IWorkspaceFailedGetSuggestedNamesAction
  | IWorkspaceLoadingGetImagesAction
  | IWorkspaceSucceededGetImagesAction
  | IWorkspaceFailedGetImagesAction
  | IWorkspaceLoadingGetCulturesAction
  | IWorkspaceSucceededGetCulturesAction
  | IWorkspaceFailedGetCulturesAction
  | IWorkspaceLoadingGetThemesAction
  | IWorkspaceSucceededGetThemesAction
  | IWorkspaceFailedGetThemesAction
  | IWorkspaceLoadingPostAction
  | IWorkspaceSucceededPostAction
  | IWorkspaceFailedPostAction
  | IWorkspaceInviteLoadingPostAction
  | IWorkspaceInviteSucceededPostAction
  | IWorkspaceInviteFailedPostAction
  | IWorkspaceCompletedLoadingPostAction
  | IWorkspaceCompletedSucceededPostAction
  | IWorkspaceCompletedFailedPostAction
  | IWorkspaceCategoryLoadingPutAction
  | IWorkspaceCategorySucceededPutAction
  | IWorkspaceCategoryFailedPutAction
  | IWorkspaceNameLoadingPutAction
  | IWorkspaceNameSucceededPutAction
  | IWorkspaceNameFailedPutAction
  | IWorkspaceThemesLoadingPutAction
  | IWorkspaceThemesSucceededPutAction
  | IWorkspaceThemesFailedPutAction
  | IWorkspaceImagesLoadingPutAction
  | IWorkspaceImagesSucceededPutAction
  | IWorkspaceImagesFailedPutAction;

/* Get All Workspaces
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllWorkspaces: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.GET_ALL_WORKSPACES_LOADING,
        type: WorkspaceActionTypes.GET_ALL_WORKSPACES_LOADING,
      });
      
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}${params_qs}`, {});
      dispatch({
        workspaces: response.data,
        status: WorkspaceActionStatus.GET_ALL_WORKSPACES_SUCCEEDED,
        type: WorkspaceActionTypes.GET_ALL_WORKSPACES_SUCCEEDED,
      });
    } catch (err) {
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Get All Workspaces
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getWorkspace: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.GET_ALL_WORKSPACES_LOADING,
        type: WorkspaceActionTypes.GET_ALL_WORKSPACES_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(
        `${basePath}/${options.workspaceId}${params_qs}`,
        {}
      );
      dispatch({
        workspaces: response.data,
        status: WorkspaceActionStatus.GET_ALL_WORKSPACES_SUCCEEDED,
        type: WorkspaceActionTypes.GET_ALL_WORKSPACES_SUCCEEDED,
      });
    } catch (err) {
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Get Suggested Names Workspaces
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getSuggestedNamesWorkspaces: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededGetSuggestedNamesAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.GET_SUGGESTEDNAMES_WORKSPACES_LOADING,
        type: WorkspaceActionTypes.GET_SUGGESTEDNAMES_WORKSPACES_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(
        `${basePath}/suggestednames${params_qs}`,
        {}
      );
      dispatch({
        suggestedNamesWorkspaces: response.data,
        status: WorkspaceActionStatus.GET_SUGGESTEDNAMES_WORKSPACES_SUCCEEDED,
        type: WorkspaceActionTypes.GET_SUGGESTEDNAMES_WORKSPACES_SUCCEEDED,
      });
    } catch (err) {
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Get Images Workspaces
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getImagesWorkspaces: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededGetImagesAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.GET_IMAGES_WORKSPACES_LOADING,
        type: WorkspaceActionTypes.GET_IMAGES_WORKSPACES_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/images${params_qs}`, {});
      dispatch({
        imagesWorkspaces: response.data,
        status: WorkspaceActionStatus.GET_IMAGES_WORKSPACES_SUCCEEDED,
        type: WorkspaceActionTypes.GET_IMAGES_WORKSPACES_SUCCEEDED,
      });
    } catch (err) {
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Get Cultures Workspaces
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getCulturesWorkspaces: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededGetCulturesAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.GET_CULTURES_WORKSPACES_LOADING,
        type: WorkspaceActionTypes.GET_CULTURES_WORKSPACES_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/cultures${params_qs}`, {});
      dispatch({
        culturesWorkspaces: response.data,
        status: WorkspaceActionStatus.GET_CULTURES_WORKSPACES_SUCCEEDED,
        type: WorkspaceActionTypes.GET_CULTURES_WORKSPACES_SUCCEEDED,
      });
    } catch (err) {
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Get Themes Workspaces
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getThemesWorkspaces: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededGetThemesAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.GET_THEMES_WORKSPACES_LOADING,
        type: WorkspaceActionTypes.GET_THEMES_WORKSPACES_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/themes${params_qs}`, {});
      dispatch({
        themesWorkspaces: response.data,
        status: WorkspaceActionStatus.GET_THEMES_WORKSPACES_SUCCEEDED,
        type: WorkspaceActionTypes.GET_THEMES_WORKSPACES_SUCCEEDED,
      });
    } catch (err) {
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Post Workspace
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postWorkspaces: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        status: WorkspaceActionStatus.POST_WORKSPACE_CATEGORY_LOADING,
        type: WorkspaceActionTypes.POST_WORKSPACE_CATEGORY_LOADING,
      });

      const params = {
        category: options.category,
      };

      api_v3.post(`${basePath}`, params).then(
        (response) => {
          dispatch({
            type: WorkspaceActionTypes.POST_WORKSPACE_CATEGORY_SUCCEEDED,
          });

          toast.success(
            resource(options?.culture, "PageSetup::Workspace_Category_Saved")
          );

          return resolve(response.data);
        },
        (err) => {
          treatError(options?.culture, "PageSetup::Title", err);
          return reject(err);
        }
      );
    });
  };
};

/* Post Workspace
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postInviteWorkspaces: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceInviteSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        status: WorkspaceActionStatus.POST_WORKSPACE_INVITE_LOADING,
        type: WorkspaceActionTypes.POST_WORKSPACE_INVITE_LOADING,
      });

      api_v3.post(`${basePath}/${options.workspaceId}/invite`, options).then(
        (response) => {
          dispatch({
            auth: {
              jwtToken: {
                accessToken: response.data.data.jwtToken.accessToken,
                expires: response.data.data.jwtToken.expires,
              },
              isFirstAccess: response.data.data.isFirstAccess,
              isProfileSetted: response.data.data.isProfileSetted,
              isVisibleButtonHelp: response.data.data.isVisibleButtonHelp,
              backOfficeAllowed: response.data.data.backOfficeAllowed,
              backOfficeURL: response.data.data.backOfficeURL,
              cultureTag: response.data.data.cultureTag,
              applicationId: response.data.data.applicationId,
              workspaceId: response.data.data.workspaceId,
              allowToSeePrizes: response.data.data.allowToSeePrizes,
              permission: response.data.data.permission,
              allowCreateWorkspace: response.data.data.allowCreateWorkspace,
              hasWorkspaceConfigured: response.data.data.hasWorkspaceConfigured,
              notifyUserPasswordExpiration:
                response.data.data.notifyUserPasswordExpiration,
              isAuthenticated: true,
            },
            status: WorkspaceActionTypes.POST_WORKSPACE_INVITE_SUCCEEDED,
            type: WorkspaceActionTypes.POST_WORKSPACE_INVITE_SUCCEEDED,
          });

          toast.success(
            resource(options?.culture, "PageSetup::Workspace_Saved")
          );
          return resolve(response.data);
        },
        (err) => {
          treatError(options?.culture, "PageSetup::Title", err);
          return reject(err);
        }
      );
    });
  };
};

/* Put Workspace Category
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const putWorkspacesCategory: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceCategorySucceededPutAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_CATEGORY_LOADING,
        type: WorkspaceActionTypes.PUT_WORKSPACE_CATEGORY_LOADING,
      });

      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("category")) {
        params.push(`category=${options.category}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      if (options.category !== undefined || options.category !== null) {
        const response = await api_v3.put(
          `${basePath}/${options.idWorkspace}/category${params_qs}`,
          {}
        );
        dispatch({
          type: WorkspaceActionTypes.PUT_WORKSPACE_CATEGORY_SUCCEEDED,
        });

        if (response?.status === 200) {
          toast.success(
            resource(options?.culture, "PageSetup::Workspace_Category_Updated")
          );
        }
      } else {
        toast.error(
          resource(options?.culture, "PageSetup::Workspace_Category_Error")
        );
      }
    } catch (err) {
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Put Workspace Name
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const putWorkspacesName: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_NAME_LOADING,
        type: WorkspaceActionTypes.PUT_WORKSPACE_NAME_LOADING,
      });

      let params_qs = {
        name: options.name,
        workspaceId: options.workspaceId,
      };

      const response = await api_v3.put(
        `${basePath}/${options.workspaceId}/name`,
        params_qs
      );
      dispatch({
        Workspace: response.data,
        status: WorkspaceActionStatus.PUT_WORKSPACE_NAME_SUCCEEDED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_NAME_SUCCEEDED,
      });

      toast.success(
        resource(options?.culture, "PageSetup::WorkspaceNameUpdated")
      );
    } catch (err) {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_NAME_FAILED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_NAME_FAILED,
      });
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Put Workspace Themes
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const putWorkspacesThemes: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_THEMES_LOADING,
        type: WorkspaceActionTypes.PUT_WORKSPACE_THEMES_LOADING,
      });

      await api_v3.put(
        `${basePath}/${options.idWorkspace}/themes`,
        options.themes
      );
      dispatch({
        status: WorkspaceActionTypes.PUT_WORKSPACE_THEMES_SUCCEEDED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_THEMES_SUCCEEDED,
      });

      toast.success(
        resource(options?.culture, "PageSetup::Workspace_Themes_Saved")
      );
    } catch (err) {
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Put Workspace Images
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const putWorkspacesImages: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_IMAGES_LOADING,
        type: WorkspaceActionTypes.PUT_WORKSPACE_IMAGES_LOADING,
      });

      let params = {
        imageLogin: options.imageLogin,
        imageLogo: options.imageLogo,
      };

      await api_v3.put(`${basePath}/${options.workspaceId}/images`, params);
      dispatch({
        status: WorkspaceActionTypes.PUT_WORKSPACE_IMAGES_SUCCEEDED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_IMAGES_SUCCEEDED,
      });

      toast.success(
        resource(options?.culture, "PageSetup::Workspace_Images_Saved")
      );
    } catch (err) {
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Put Workspace Completed
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const putWorkspacesCompleted: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceCompletedSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_COMPLETED_LOADING,
        type: WorkspaceActionTypes.PUT_WORKSPACE_COMPLETED_LOADING,
      });

      api_v3.put(`${basePath}/${options.workspaceId}/completed`, options).then(
        (response) => {
          dispatch({
            auth: {
              jwtToken: {
                accessToken: response.data.data.jwtToken.accessToken,
                expires: response.data.data.jwtToken.expires,
              },
              isFirstAccess: response.data.data.isFirstAccess,
              isProfileSetted: response.data.data.isProfileSetted,
              isVisibleButtonHelp: response.data.data.isVisibleButtonHelp,
              backOfficeAllowed: response.data.data.backOfficeAllowed,
              backOfficeURL: response.data.data.backOfficeURL,
              cultureTag: response.data.data.cultureTag,
              applicationId: response.data.data.applicationId,
              workspaceId: response.data.data.workspaceId,
              allowToSeePrizes: response.data.data.allowToSeePrizes,
              permission: response.data.data.permission,
              allowCreateWorkspace: response.data.data.allowCreateWorkspace,
              hasWorkspaceConfigured: response.data.data.hasWorkspaceConfigured,
              notifyUserPasswordExpiration:
                response.data.data.notifyUserPasswordExpiration,
              isAuthenticated: true,
            },
            status: WorkspaceActionTypes.PUT_WORKSPACE_COMPLETED_SUCCEEDED,
            type: WorkspaceActionTypes.PUT_WORKSPACE_COMPLETED_SUCCEEDED,
          });

          toast.success(
            resource(options?.culture, "PageSetup::Workspace_Saved")
          );
          return resolve(response.data);
        },
        (err) => {
          treatError(options?.culture, "PageSetup::Title", err);
          return reject(err);
        }
      );
    });
  };
};

/* Put Workspace Derpartments
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const putWorkspacesDepartments: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_DEPARTMENTS_LOADING,
        type: WorkspaceActionTypes.PUT_WORKSPACE_DEPARTMENTS_LOADING,
      });

      let params_qs = {
        departments: options.departments,
        workspaceId: options.workspaceId,
      };

      const response = await api_v3.put(
        `${basePath}/${options.workspaceId}/departments`,
        params_qs
      );

      dispatch({
        Workspace: response.data,
        status: WorkspaceActionStatus.PUT_WORKSPACE_DEPARTMENTS_SUCCEEDED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_DEPARTMENTS_SUCCEEDED,
      });

      toast.success(
        resource(options?.culture, "PageSetup::Workspace_Saved")
      );

    } catch (err) {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_DEPARTMENTS_FAILED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_DEPARTMENTS_FAILED,
      });
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Put Workspace Derpartments
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const putWorkspacesCountries: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_DEPARTMENTS_LOADING,
        type: WorkspaceActionTypes.PUT_WORKSPACE_DEPARTMENTS_LOADING,
      });

      let params_qs = {
        countries: options.countries,
        workspaceId: options.workspaceId,
      };

      const response = await api_v3.put(
        `${basePath}/${options.workspaceId}/countries`,
        params_qs
      );

      dispatch({
        Workspace: response.data,
        status: WorkspaceActionStatus.PUT_WORKSPACE_COUNTRIES_SUCCEEDED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_COUNTRIES_SUCCEEDED,
      });

      toast.success(
        resource(options?.culture, "PageSetup::Workspace_Saved")
      );

    } catch (err:any) {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_COUNTRIES_FAILED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_COUNTRIES_FAILED,
      });
      treatError(options?.culture, "PageSetup::Title", err);
      throw Error(err.message);
    }
  };
};

/* Put Workspace Currencies
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const putWorkspacesCurrencies: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_DEPARTMENTS_LOADING,
        type: WorkspaceActionTypes.PUT_WORKSPACE_DEPARTMENTS_LOADING,
      });

      let params_qs = {
        currencies: options.currencies,
        workspaceId: options.workspaceId,
      };

      const response = await api_v3.put(
        `${basePath}/${options.workspaceId}/currencies`,
        params_qs
      );

      dispatch({
        Workspace: response.data,
        status: WorkspaceActionStatus.PUT_WORKSPACE_DEPARTMENTS_SUCCEEDED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_DEPARTMENTS_SUCCEEDED,
      });

      toast.success(
        resource(options?.culture, "PageSetup::Workspace_Saved")
      );

    } catch (err) {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_DEPARTMENTS_FAILED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_DEPARTMENTS_FAILED,
      });
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};

/* Post Workspace language
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postWorkspacesLanguage: ActionCreator<ThunkAction<
  Promise<any>,
  IWorkspaceState,
  null,
  IWorkspaceSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: WorkspaceActionStatus.POST_WORKSPACE_LANGUAGE_LOADING,
        type: WorkspaceActionTypes.POST_WORKSPACE_LANGUAGE_LOADING,
      });
      console.log(options);
      await api_v3.post( 
        `tenantConfigurations/${options.tenantId}/culture`, 
        {DefaultCultureTag:options.DefaultCultureTag}
      );

      dispatch({
        status: WorkspaceActionStatus.POST_WORKSPACE_LANGUAGE_SUCCEEDED,
        type: WorkspaceActionTypes.POST_WORKSPACE_LANGUAGE_SUCCEEDED,
      });

      toast.success(
        resource(options?.culture, "PageSetup::Workspace_Saved")
      );

    } catch (err) {
      dispatch({
        status: WorkspaceActionStatus.PUT_WORKSPACE_DEPARTMENTS_FAILED,
        type: WorkspaceActionTypes.PUT_WORKSPACE_DEPARTMENTS_FAILED,
      });
      treatError(options?.culture, "PageSetup::Title", err);
    }
  };
};