import {
  Avatar,
  Box,
  Container,
  Divider,
  Typography,
  useTheme,
} from "@material-ui/core";
import EMIcon from "components/Icon/Icon";
import Resource from "components/Resources/Resource";
import ReviewStars from "components/ReviewRatingBox/ReviewStars";
import React from "react";

interface IProps {
  evaluation: any;
  comments: any;
  evaluate: boolean;
  detailPage?: boolean;
  fullWidth?: boolean;
  setSubmitDisabled?: (on: boolean) => void;
  setStarsData?: (obj: any) => void;
}

function KeyTaskIdeaDetailEvaluationComments(props: IProps) {
  const { evaluation, comments, setSubmitDisabled, setStarsData, fullWidth, detailPage } =
    props || null;
  const theme = useTheme();

  const star = () => {
    const { starsEvaluation } = evaluation || null;
    return (
      <>
        <Box
          mb={2}
          color={theme.palette.grey[500]}
          display="flex"
          alignItems="center"
        >
          <Box component="span" mr={1}>
            <Resource
              tag="PageKeyTasks::Transitions_WeightedAverage"
              args={[starsEvaluation?.average]}
            />
          </Box>
          <Box component="span">
            <EMIcon color={"#778085"} size={25} icon={"star-b"} />
          </Box>
        </Box>
        {starsEvaluation?.dimensionsTitle && (
          <>
            <Divider style={{ width: "100%" }} />
            <Box mt={2}>
              <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                <Resource tag="PageKeyTasks::AverageEvaluations" />
              </Typography>
              <Box mt={0.5}>
                {starsEvaluation?.dimensionsTitle.map(
                  (star: any, index: any) => (
                    <Box key={index}>
                      <Box component="span" mr={0.5}>
                        {star.dimensionTile}:
                      </Box>
                      <Box component="span" fontWeight={500}>
                        {star.average}
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </>
        )}
      </>
    );
  };

  const like = () => {
    const { likesEvaluation } = evaluation || null;

    return (
      <Box alignItems="center" display="flex" fontSize={16}>
        <Box component="span" color={"#778085"} mr={0.5}>
          Likes:
        </Box>
        <Box component="span" fontSize={20} color={"#778085"} mr={0.5}>
          {likesEvaluation?.countEvaluations}
        </Box>
        <Box component="span">
          <EMIcon color={"#778085"} size={20} icon={"like-b"} />
        </Box>
      </Box>
    );
  };

  const Investment = () => {
    const { investment } = evaluation || null;

    return (
      <div className="EvaluationBoxInvestment">
        <div className="EvaluationBoxInvestmentValue" style={{ fontSize: 20 }}>
          {investment?.investmentBeliefValue}%
          {investment?.investmentBeliefVariation != null &&
          investment?.investmentBeliefVariation >= 0 ? (
            <span
              style={{ fontSize: 20 }}
              className="EvaluationBoxInvestmentVarPositive"
            >
              <span>&#129145;</span>
              {investment?.investmentBeliefVariation?.toFixed(2)}%
            </span>
          ) : (
            <span
              style={{ fontSize: 20 }}
              className="EvaluationBoxInvestmentVarNegative"
            >
              <span>&#129147;</span>
              {investment?.investmentBeliefVariation?.toFixed(2)}%
            </span>
          )}
        </div>

        <div className="EvaluationBoxInvestmentTitle">
          <Resource tag={"PageDetailIdea::InvestmentArea_CrowdBelief"} />
        </div>
      </div>
    );
  };
  return (
    <Container 
      style={{ 
        width: fullWidth ? '100%':"80%", 
        marginLeft: "auto", 
        marginRight: 0, 
        paddingLeft: detailPage ? '0':'24px', 
        paddingRight: detailPage? '0' : '24px'
      }}
    >
      {detailPage ? 
      ''
      :
      <>
        {props.evaluate ? (
          <Box mb={2}>
            <ReviewStars
              isKeyTasks
              isCollaboration={evaluation?.isCollaboration}
              setStarsData={setStarsData}
              setSubmitDisabled={setSubmitDisabled}
              starsEvaluation={evaluation}
            />
          </Box>
        ) : (
          <Box mb={2}>
            {evaluation && evaluation.evaluationType === "Stars" && evaluation?.evaluationType === "Average" && star()}
            {evaluation && evaluation.evaluationType === "Likes" && like()}
            {evaluation &&
              evaluation.evaluationType === "Investments" &&
              Investment()}
          </Box>
        )}
      </>
      }
      {comments?.length > 0 && (
        <>
          {detailPage ? '' : <Divider style={{ width: "100%" }} />}
          <Box mt={2} maxHeight={detailPage ? "200px" : ""} overflow="scroll">
            {comments.length === 1 && comments[0].comment ? 
            <Box fontWeight={500}>
              <Resource tag={"PageKeyTasks::EvaluationComments"} />
            </Box>
            :
            ''
            }
            {comments &&
              comments.map((x: any, index: any) => (
                <Box key={index} mt={1} display="flex" alignItems="flex-start">
                  {x.comment ? 
                  <>
                    <Box mr={1} component="span">
                      <Avatar
                        style={{ width: 30, height: 30 }}
                        alt={x.username}
                        src={x.photo}
                      ></Avatar>
                    </Box>
                    <Box component="span" fontSize={12}>
                      <b style={{ color: theme.palette.primary.main }}>
                        {x.name}
                      </b>{" "}
                      {x.comment}
                    </Box>
                  </>
                  :
                  ''
                  }                  
                </Box>
              ))}
          </Box>
        </>
      )}
    </Container>
  );
}

export default KeyTaskIdeaDetailEvaluationComments;
