import React, { useEffect, useState } from 'react'
import './PointItems.css'
import EMIcon from 'components/Icon/Icon';
import { IResultWalletBalanceHistory } from '../../redux/reducers/walletReducer';
import { TableBody, TableRow, TableCell, Typography, Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Resource from 'components/Resources/Resource';

interface IProps {
  result: IResultWalletBalanceHistory;
  cultureTag: string;
}

function PointItems (props:IProps) {

    const { result, cultureTag } = props
    const [pageName, setPageName] = useState('')
    const pageNameChanger = ( ) =>{
      switch(result?.itemType){
        case 1:
          setPageName(`idea/details/${result?.itemId}`)
          return
        case 2:
          setPageName("") // TODO add link to insight detail when we have it something for it
          return
        case 3:
          setPageName("prizes") // TODO change the link if someday the project have the detail prize page
          return
        default:
          return
      }
    }
    useEffect(()=>{
      pageNameChanger()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
      <TableBody>
        <TableRow className="list-points-body">
          <TableCell>
            {result?.itemType === 1 ? 
            <Link component={RouterLink} to={{
              pathname: `/${pageName}`,
              state: {
                scrollDown: pageName === "prizes" ? true : false,
                prizeId: result?.itemId
              }
            }} >
              <Resource tag={result?.resource.replace('.', '::')} args={[`"${result?.resourceParameter}"`]}/>
            </Link>
            :
            <Resource tag={result?.resource.replace('.', '::')} args={[result?.resourceParameter]}/>
          }
          </TableCell>
          <TableCell id="WalletHistoryDateHour">
            <Box>
              <Typography variant="body1">
                {result?.dateDay}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                <Resource tag={"PageAlerts::Time_Separator"} />
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                {result?.dateHour}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
        <TableRow className="list-points-body">
          <TableCell className="WalletMovementIcons">
            {result.movement > 0
              ? <EMIcon color="#2bcd35" icon="plus" size={10}/>
              : result.movement === 0 ?
              ''
              :
              <EMIcon color="#fb171f" icon="minus" size={10}/>
            }
          </TableCell>
          <TableCell>
            {result.movement > 0 ? 
              <div className="WalletMovementUp">
                {Math.round(result?.movement)}
              </div>
            :
              <div className={result.movement === 0 ? '' : "WalletMovementDown"}>
                {Math.round(result?.movement*-1)}
              </div>
            }
          </TableCell>
        </TableRow>
        <TableRow className="list-points-body">
          <TableCell>
            <b>{Math.round(result?.balance).toLocaleString(cultureTag)}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    )
}

export default PointItems