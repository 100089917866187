import React from "react";
import Link from "@material-ui/core/Link";
import { Skeleton } from "@material-ui/lab";
import { Link as RouterLink } from "react-router-dom";
import { Paper, Theme, makeStyles, createStyles } from "@material-ui/core";

interface IProps {
  loading?: boolean;
  devMode?: boolean;
  link?: any;
  elevation?: number;
  height?: number;
  topContentPadding?: number;
  middleContentPadding?: number;
  bottomContentPadding?: number;
  topContent?: any;
  middleContent?: any;
  bottomContent?: any;
  backgroundImage?: string;
  color?: "default" | "dark" | "success" | "info";
}

export default function XCard(props: IProps) {
  const styleProps = {
    devMode: props.devMode,
    height: props.height,
    topContentPadding: props.topContentPadding,
    middleContentPadding: props.middleContentPadding,
    bottomContentPadding: props.bottomContentPadding,
    topContent: props.topContent,
    middleContent: props.middleContent,
    bottomContent: props.bottomContent,
    backgroundImage: props.backgroundImage,
    color: props.color,
  };
  const classes = useStyles(styleProps);

  return (
    <main className="xcard">
      {props.loading ? (
        <Skeleton variant="rect" height={props.height ? props.height : 250} />
      ) : props.link ? (
        <Link
          component={RouterLink}
          to={{ pathname: props.link }}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
          onFocus={(e: any) => {
            e.stopPropagation();
          }}
        >
          <Paper elevation={props.elevation} className={classes.root}>
            {!props.topContent ? (
              ""
            ) : (
              <div className={classes.topContent}>{props.topContent}</div>
            )}

            {!props.middleContent ? (
              ""
            ) : (
              <div className={classes.middleContent}>{props.middleContent}</div>
            )}

            {!props.bottomContent ? (
              ""
            ) : (
              <div className={classes.bottomContent}>{props.bottomContent}</div>
            )}
          </Paper>
        </Link>
      ) : (
        <Paper elevation={props.elevation} className={classes.root}>
          {!props.topContent ? (
            ""
          ) : (
            <div className={classes.topContent}>{props.topContent}</div>
          )}

          {!props.middleContent ? (
            ""
          ) : (
            <div className={classes.middleContent}>{props.middleContent}</div>
          )}

          {!props.bottomContent ? (
            ""
          ) : (
            <div className={classes.bottomContent}>{props.bottomContent}</div>
          )}
        </Paper>
      )}
    </main>
  );
}

const xcardBackgroundColor = (props: IProps) => {
  switch (props.color) {
    case "success":
      return "#1EC322";

    case "info":
      return "#F2F2F2";

    case "dark":
      return "#333333";

    case "default":
      return "#FFFFFF";

    default:
      return "#FFFFFF";
  }
};

const xcardTextColor = (props: IProps) => {
  switch (props.color) {
    case "success":
      return "#FFFFFF";

    case "info":
      return "#313B53";

    case "dark":
      return "#FFFFFF";

    case "default":
      return "#323232";

    default:
      return "#323232";
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: IProps) => ({
      position: "relative",
      width: "100%",
      height: !props.height ? "auto" : `${props.height}px`,
      color: xcardTextColor(props),
      backgroundColor: xcardBackgroundColor(props),
      backgroundImage: !props.backgroundImage
        ? "none"
        : `url(${props.backgroundImage})`,
      backgroundSize: `cover`,
      backgroundPosition: "center",
      borderRadius: "5px",
      "&::before": {
        display: "block",
        width: "100%",
        height: "100%",
        content: '""',
        backgroundColor: !props.backgroundImage ? "none" : "rgba(0, 0, 0, 0.4)",
        borderRadius: "5px",
      },
    }),
    topContent: (props: IProps) => ({
      overflow: "hidden",
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: props.middleContent
        ? "25%"
        : !props.bottomContent
        ? "100%"
        : "50%",
      padding: props.topContentPadding ? props.topContentPadding : 0,
      textAlign: "left",
      backgroundColor: !props.devMode ? "" : "#a09c93",
    }),
    middleContent: (props: IProps) => ({
      overflow: "hidden",
      position: "absolute",
      top: !props.topContent
        ? "0px"
        : props.middleContent && !props.topContent && !props.bottomContent
        ? "0px"
        : "25%",
      left: "0px",
      width: props.middleContent ? "100%" : "50%",
      height:
        props.middleContent && !props.topContent && !props.bottomContent
          ? "100%"
          : props.middleContent && (props.topContent || props.bottomContent)
          ? "75%"
          : "25%",
      padding: props.middleContentPadding ? props.middleContentPadding : 0,
      backgroundColor: !props.devMode ? "" : "#848077",
    }),
    bottomContent: (props: IProps) => ({
      overflow: "hidden",
      position: "absolute",
      display: "flex",
      alignItems: "flex-end",
      bottom: "0px",
      left: "0px",
      width: "100%",
      height: props.middleContent ? "25%" : !props.topContent ? "100%" : "50%",
      padding: props.bottomContentPadding ? props.bottomContentPadding : 0,
      textAlign: "left",
      backgroundColor: !props.devMode ? "" : "#6b6861",
    }),
  })
);
