import {
  createStyles,
  makeStyles,
  Theme,
  Box
} from "@material-ui/core";
import SetupEnvironmentScreen from "views/SetupEnvironment/SetupEnvironment";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IAppState } from "redux/store/Store";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { deleteInvite, getManageUsers, getUserPermissions, postImportUsers, postInviteUser, postSchedule, postToken, postUserActivate, postUserDeactivate, putPermission, putReinvite, putReschedule } from "redux/actions/manageUsers/ManageUsersAction";
import { IManageUsers, IManageUsersState } from "redux/types/manageUsers";
import { getAllManageGroups, putAssignGroups } from "redux/actions/manageGroups/manageGroupsAction";
import { IAllManageGroups, IManageGroupsState } from "redux/types/manageGroups";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
import { getAllCountries, getAllDepartaments } from "redux/actions/UsersAction";
import { useEffect } from "react";
import {
  getAllWorkspaces,
  getWorkspace
} from "redux/actions/workspaceAction";
import { IWorkspaceState } from "redux/reducers/workspaceReducer";
import { IApplication } from "redux/reducers/tenantReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    noSidePadding: {
      padding: "24px 0",
    },
  })
);

interface IProps {
  culture:ICultureState
  permissions: any;
  permissionStatus: string
  activateStatus: string
  users: IManageUsers
  workspaceId: string
  userStatus: string
  allGroups: IAllManageGroups[]
  departaments: IDepartments[];
  application: IApplication;
  countries: ICountries[];
}

interface IDispatchProps {
  getAllWorkspaces: (options: object) => Promise<IWorkspaceState>;
  getCountries: (options: object) => Promise<any>;
  getDepartments: (options: object) => Promise<any>;
  getUserPermissions:(options: object) => Promise<IManageUsersState>
  postUserActivate: (options: object) => Promise<IManageUsersState>
  postUserDeactivate:(options: object) => Promise<IManageUsersState>
  getUsers: (options: object) => Promise<IManageUsersState>
  postImportUsers: (options:object) => Promise<IManageUsersState>
  getAllManageGroups: (options:object) => Promise<IManageUsersState>
  postInviteUser: (options: object) => Promise<IManageUsersState>
  postSchedule: (options: object) => Promise<IManageUsersState>
  putReinvite: (options: object) => Promise<IManageUsersState>
  deleteInvite: (options:object) => Promise<IManageUsersState>
  putReschedule:(options:object)=> Promise<IManageUsersState>
  putAssignGroups: (options: object) => Promise<IManageGroupsState>
  putPermission:(options: object) => Promise<IManageUsersState>
  postToken: (options: object) => Promise<IManageUsersState>
}

const options = {
  applicationId: 2,
  culture: {},
  workspaceId: "",
};

function ManageUsers(props: IProps & IDispatchProps) {
  const classes = useStyles();

  useEffect(()=>{
    options.applicationId = props.application.applicationId;
    options.culture = props.culture;
    options.workspaceId = props.workspaceId;
    props.getAllWorkspaces(options)
  },[])
  
  return (
    <>
      <Box className={classes.root}>
        <SetupEnvironmentScreen {...{istab:true}} />
      </Box>
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getCountries: (options: object) => dispatch(getAllCountries(options)),
    getDepartments: (options: object) => dispatch(getAllDepartaments(options)),
    getUsers: (options: object) => dispatch(getManageUsers(options)),
    getUserPermissions: (options: object) => dispatch(getUserPermissions(options)),
    putPermission: (options: object) => dispatch(putPermission(options)),
    postUserActivate: (options: object) => dispatch(postUserActivate(options)),
    postUserDeactivate: (options: object) => dispatch(postUserDeactivate(options)),
    postImportUsers: (options: object) => dispatch(postImportUsers(options)),
    getAllManageGroups: (options: object) => dispatch(getAllManageGroups(options)),
    postInviteUser: (options: object) => dispatch(postInviteUser(options)),
    postSchedule: (options: object) => dispatch(postSchedule(options)),
    putReinvite: (options: object) => dispatch(putReinvite(options)),
    deleteInvite: (options: object) => dispatch(deleteInvite(options)),
    putReschedule: (options: object) => dispatch(putReschedule(options)),
    putAssignGroups: (options: object) => dispatch(putAssignGroups(options)),
    postToken: (options: object) => dispatch(postToken(options)),
    getAllWorkspaces: (options: object) => dispatch(getWorkspace(options))
  };
};

const mapStateToProps = (store:IAppState) => ({
  culture: store.cultureState,
  permissions: store.manageUsersState.permissions,
  permissionStatus: store.manageUsersState.permissionStatus,
  activateStatus: store.manageUsersState.activateStatus,
  users: store.manageUsersState.users,
  userStatus: store.manageUsersState.userStatus,
  workspaceId: store.authState.auth.workspaceId,
  allGroups: store.manageGroupsState.allGroups,
  departaments: store.usersState.departaments,
  countries: store.usersState.countries,
  application: store.tenantState.application,
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers)
