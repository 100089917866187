import React, { useState } from "react";
import "./ListIdeaChallenges.css";
import InspirationCard from "../InspirationCard/InspirationCard";
import {
  IDetailIdea,
} from "../../redux/reducers/challengeDetailReducer";
import {
  AppBar,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Typography,
} from "@material-ui/core";
import { TabPanel, TabList, TabContext } from "@material-ui/lab";
import Resource from "../../components/Resources/Resource";
import ListIdeaParticipants from "../ListIdeaParticipants/ListIdeaParticipants";
import { IChallengesTops } from "redux/types/challenges";
import { ICultureState } from "redux/reducers/cultureReducer";
import CollaborativeDetailList from "containers/CollaborativeDetailList/CollaborativeDetailList";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";

const useStyles = makeStyles(() =>
  createStyles({
    noSidePadding: {
      padding: "24px 0",
    },
  })
);
/* Example:
    <ListIdeaChallenges 
          inspirations={[
            { ideaId: "1", rank: 1, title: "More Digital, More Personal: change our Strategic position", rate: 136, type: "likes" }
          ]}
          ideas={[
            { ideaId: "1", rank: 1, title: "More Digital, More Personal: change our Strategic position", rate: 4.9, type: "star" }
          ]}
          tops={[
            { ideaId: "1", rank: 1, title: "More Digital, More Personal: change our Strategic position", rate: 136, type: "likes" }
          ]}
          results={[
            { ideaId: "1", rank: 1, title: "More Digital, More Personal: change our Strategic position", rate: 136, type: "likes" }
          ]}
    />   
*/
interface IProps {
  inspirations?: any;
  ideas?: IDetailIdea;
  tops?: IChallengesTops;
  // results?: IDetailChallengeResult;
  culture?: ICultureState;
  applicationId?: string;
  postInvestments: (options: object) => Promise<any>;
  postLikes: (options: object) => Promise<any>;
  deleteLikes: (options: object) => Promise<any>;
  getAllIdeas: (options: object) => Promise<any>;
  getAllInsights: (pageNumber: number, pageSize: number) => Promise<any>;
  // getMoreInsights: (pageNumber: number, pageSize: number) => Promise<any>;
  getTopChallenges: (options: object) => Promise<any>;
  getChallengeDetail: (options: object) => Promise<any>;
  getChallengeDetailResults?: (options: object) => Promise<any>;
}
function ListIdeaChallenges(props: IProps) {
  const [tab, setTab] = useState("1");
  //Change Tabs
  const handleChange = (event: any, newValue: string) => {
    setTab(newValue);
  };
  const classes = useStyles();
  const {
    inspirations,
    ideas,
    tops,
    postInvestments,
    culture,
    applicationId,
    postLikes,
    deleteLikes,
    getAllIdeas,
    getAllInsights,
    // getMoreInsights
  } = props;

  return (
    <TabContext value={tab}>
      <AppBar position="static" elevation={0}>
        <TabList onChange={handleChange} variant="standard" scrollButtons="on">
          <Tab
            label={<Resource tag="PageChallenges::Menu_Ideas" />}
            value="1"
          />
          <Tab
            label={<Resource tag="PageChallenges::Menu_Inspirations" />}
            value="2"
          />
          <Tab label="TOPS" value="3" />
          {/* <Tab label="RESULTS" value="4" /> */}
        </TabList>
      </AppBar>
      <TabPanel value="1" className={classes.noSidePadding}>
        {ideas ? (
          <DynamicInfiniteScroll
            getAction={(pageNumber, pageSize) =>
              getAllIdeas({ pageNumber, pageSize })
            }
            dataList={ideas}
            pageSize={7}
            isAutomatic
          >
            <CollaborativeDetailList
              getAllIdeas={getAllIdeas}
              postLikes={postLikes}
              deleteLikes={deleteLikes}
              ideasCollaborative={ideas}
              applicationId={applicationId!}
              culture={culture!}
              postInvestments={postInvestments}
            />
          </DynamicInfiniteScroll>
        ) : (
          <>
            <Typography variant="body2">
              <Resource tag="Errors::NoIdeasAvailable" />
            </Typography>
          </>
        )}
      </TabPanel>
      <TabPanel value="2" className={classes.noSidePadding}>
        {inspirations?.result ? (
          <DynamicInfiniteScroll
            getAction={(pageNumber, pageSize) =>
              getAllInsights(pageNumber, pageSize)
            }
            dataList={inspirations}
            pageSize={4}
            isAutomatic
          >
            <Grid container spacing={2}>
              {inspirations?.result?.map((data: any, index: any) => (

                <Grid item xs={12} sm={6} key={index}>
                  <InspirationCard key={data?.insightId} insights={data} culture={culture!} />
                </Grid>
              ))}
            </Grid>
          </DynamicInfiniteScroll>
        ) : (
          <Typography variant="body2">
            <Resource tag="PageDetailChallenge::NoInspirations" />
          </Typography>
        )}
      </TabPanel>
      <TabPanel value="3" className={classes.noSidePadding}>
        {tops ? (
          <>
            <ListIdeaParticipants tops={tops} />
          </>
        ) : (
          ""
        )}
      </TabPanel>
      {/* <TabPanel value="4" className={classes.noSidePadding}>
        {results !== undefined ? (
          <>
            <ChallengeDetailResults results={results} />
          </>
        ) : (
            "" TODO: NOT IMPLEMENTED YET
          )}
      </TabPanel> */}
    </TabContext>
  );
}
export default ListIdeaChallenges;
