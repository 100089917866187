import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import AddIcon from "@material-ui/icons/Add";
import { IAppState } from "redux/store/Store";
import React, { useEffect, useState } from "react";
import { getAllUsers } from "redux/actions/UsersAction";
import MessagesSentContent from "./MessagesSentContent";
import SendNewMessage from "./SendNewMessage/SendNewMessage";
import { ICultureState } from "redux/reducers/cultureReducer";
import MessageArchivedContent from "./MessageArchivedContent";
import MessagesReceivedContent from "./MessagesReceivedContent";
import { IMessagesState } from "redux/reducers/messagesReducer";
import { IUsers, IUsersState } from "redux/reducers/usersReducers";
import { Button, Grid, AppBar, Tabs, Tab, Box } from "@material-ui/core";
import {
  IMessagesArchived,
  IMessagesReceived,
  // IMessagesReply,
  IMessagesSent,
} from "redux/types/messages";
import {
  deleteMessage,
  getAllMessagesArchived,
  getAllMessagesReceived,
  getAllMessagesSent,
  postMessageArchive,
  postMessage,
} from "redux/actions/MessagesActions";

//props and functions to tabs
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IProps {
  culture: ICultureState;
  messagesReceived: IMessagesReceived;
  messagesSent: IMessagesSent;
  messagesArchived: IMessagesArchived;
  users?: IUsers[];
  applicationId: any;
}

interface IDispatchProps {
  getReceivedMessages: (options: object) => Promise<IMessagesState>;
  getSentMessages: (options: object) => Promise<IMessagesState>;
  getArchivedMessages: (options: object) => Promise<IMessagesState>;
  deleteMessages: (options: object) => Promise<IMessagesState>;
  postMessagesArchive: (options: object) => Promise<IMessagesState>;
  postMessage: (options: object) => Promise<IMessagesState>;
  getAllUsers: (options: object) => Promise<IUsersState>;
}

function MessagesPage(props: IProps & IDispatchProps) {
  const {
    culture,
    messagesArchived,
    messagesReceived,
    messagesSent,
    getReceivedMessages,
    getSentMessages,
    getArchivedMessages,
    deleteMessages,
    postMessagesArchive,
    postMessage,
    getAllUsers,
    users,
    applicationId,
  } = props;
  const [value, setValue] = useState(0);
  const [openSendMessage, setOpenSendMessage] = useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    const option = {
      pageNumber: 1,
      pageSize: 8,
      cultureTag: culture.culture.cultureTag,
    };
    getReceivedMessages(option);
    getSentMessages(option);
    getArchivedMessages(option);
    getAllUsers({ q: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReceivedMessagesAgain = async (
    pageSize: number,
    pageNumber: number,
    isInfinite: boolean
  ) => {
    const option = {
      pageSize,
      pageNumber,
      isInfinite,
      cultureTag: culture.culture.cultureTag,
    };

    await getReceivedMessages(option);
    getSentMessages({ cultureTag: culture.culture.cultureTag });
    getArchivedMessages({ cultureTag: culture.culture.cultureTag });
  };

  // const getArchivedMessagesAgain = async (pageSize:number, pageNumber:number, isInfinite:number) => {
  //   const option = {
  //     pageNumber,
  //     pageSize,
  //     isInfinite,
  //     cultureTag: culture.culture.cultureTag,
  //   }

  //   await getArchivedMessages(option);
  //   getSentMessages({cultureTag:culture.culture.cultureTag});
  //   getReceivedMessages({cultureTag:culture.culture.cultureTag});
  // }

  // const getSentMessagesAgain = async (
  //   pageSize: number,
  //   pageNumber: number,
  //   isInfinite: number
  // ) => {
  //   const option = {
  //     pageSize,
  //     pageNumber,
  //     isInfinite,
  //     cultureTag: culture.culture.cultureTag,
  //   };

  //   await getSentMessages(option);
  //   getReceivedMessages({ cultureTag: culture.culture.cultureTag });
  //   getArchivedMessages({ cultureTag: culture.culture.cultureTag });
  // };

  function handleDeleteMessages(options: object) {
    deleteMessages(options).then((response) => {});
  }

  function handleArchiveMessages(options: object) {
    postMessagesArchive(options).then((response) => {});
  }

  return (
    <Box mt={5}>
      <SendNewMessage
        applicationId={applicationId}
        postMessage={postMessage}
        getSentMessages={getSentMessages}
        open={openSendMessage}
        setOpen={setOpenSendMessage}
        culture={culture}
        options={users!}
      />
      <Grid container className="tabs-and-btn-send-message">
        <Grid item md={9}>
          <AppBar position="static" elevation={0}>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label={`Received (${messagesReceived?.meta?.total ?? 0})`}
                {...a11yProps(0)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label={`Sent (${messagesSent?.meta?.total ?? 0})`}
                {...a11yProps(1)}
                style={{ textTransform: "capitalize" }}
              />
              <Tab
                label={`Archived (${messagesArchived?.meta?.total ?? 0})`}
                {...a11yProps(2)}
                style={{ textTransform: "capitalize" }}
              />
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item md={3}>
          <Box textAlign="end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenSendMessage(true)}
              startIcon={<AddIcon fontSize="small" />}
            >
              Send new message
            </Button>
          </Box>
        </Grid>
      </Grid>

      <TabPanel value={value} index={0}>
        <MessagesReceivedContent
          culture={culture}
          postMessagesArchive={handleArchiveMessages}
          deleteMessages={handleDeleteMessages}
          getReceivedMessages={getReceivedMessagesAgain}
          receivedMessages={messagesReceived}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MessagesSentContent
          culture={culture}
          // getSentMessages={getSentMessagesAgain}
          sentMessages={messagesSent}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MessageArchivedContent
          culture={culture}
          // postMessagesArchive={handleArchiveMessages}
          // getArchivedMessages={getArchivedMessagesAgain}
          archivedMessages={messagesArchived}
        />
      </TabPanel>
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => ({
  culture: store.cultureState,
  messagesReceived: store.messagesState.received,
  messagesSent: store.messagesState.sent,
  messagesArquived: store.messagesState.archived,
  users: store.usersState.users,
  applicationId: store.authState.auth.applicationId,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  getReceivedMessages: (options: object) =>
    dispatch(getAllMessagesReceived(options)),
  getSentMessages: (options: object) => 
    dispatch(getAllMessagesSent(options)),
  getArchivedMessages: (options: object) =>
    dispatch(getAllMessagesArchived(options)),
  deleteMessages: (options: object) => dispatch(deleteMessage(options)),
  postMessagesArchive: (options: object) =>
    dispatch(postMessageArchive(options)),
  postMessage: (options: object) => dispatch(postMessage(options)),
  getAllUsers: (options: object) => dispatch(getAllUsers(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPage);
