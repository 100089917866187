import { AnyAction } from "redux";
import React, { useRef } from "react";
import { connect } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { useEffect, useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import CloseIcon from "@material-ui/icons/Close";
import FormTag from "components/FormTag/FormTag";
import StickyComponent from "components/Sticky/Sticky";
import { IChallengeList } from "redux/types/challenges";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import TextInputWCounter from "common/UI/Text/TextCounter";
import PhotoUpload from "components/PhotoUpload/PhotoUpload";
import { ICultureState } from "redux/reducers/cultureReducer";
import Resource, { resource } from "components/Resources/Resource";
import ModalSelectAChallenge from "common/Modal/ModalSelectAChallenge";
import TextEditorInput from "components/GenericField/TextEditorInput/TextEditorInput";
import history from "routes/history";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { postCreateInsight } from "redux/actions/insight/InsightsActions";
import { toast } from "react-toastify";

interface IDispatchProps {
  postInsight: (params: object) => Promise<any>;
}

interface IProps {
  culture: ICultureState;
}

interface IStateProps {
  challengeList: IChallengeList;
  ownProps: any;
  workspaceId: string;
  challengeId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formProgressBar: {
      backgroundColor: theme.palette.primary.main,
      display: "inline-block",
      width: "32px",
      height: "5px !important",
      margin: "0 4px 0 0",
      opacity: "0.4",
      borderRadius: "5px",
      "&.active": {
        opacity: 1,
      },
    },
    inputPhoto: {
      display: "none",
    },
    pageTitle: {
      marginTop: 24,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialog: {
      margin: "auto",
    },
    titleHeader: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#313b53",
    },
    dividerHeader: {
      width: "90%",
      marginLeft: "30px",
    },
    dialogHeader: {
      padding: "22px 24px 10px 30px",
    },
    createInspirationProgressButtons: {
      backgroundColor: theme.palette.common.white,
    },
  })
);

function CreateInpirationsPage(props: IProps & IDispatchProps & IStateProps) {
  const {
    culture,
    ownProps,
    challengeList,
    postInsight,
    challengeId,
    workspaceId,
  } = props;
  const inspirationDescriptionProps = {
    columnName: "inspiration-description",
    isMandatory: false,
    typeId: 9,
    title: resource(culture, "PageCreateInspiration::Description"),
    placeholder: resource(culture, "PageCreateInspiration::Description_Tip"),
    isEditable: true,
    maxLength: 3000,
  };
  const theme = useTheme();
  const classes = useStyles();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [titleValue, setTitleValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [photo, setPhoto] = useState<any>();
  const [fieldsProgress, setFieldsProgress] = useState(0);
  const [completedFieldsProgress, setCompletedFields] = useState(0);
  const [tagsValue, setTagsValue] = useState<any>([]);
  const [isOpen, setOpen] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  let challenge = challengeList?.result?.filter(
    (challenge) => challenge.challengeId === ownProps?.location?.state
  )[0];

  const setUpBar = () => {
    const bars = [];
    for (let index = 0; index < fieldsProgress; index++) {
      const barClass = index < completedFieldsProgress;
      bars.push(
        <span
          key={index}
          className={classes.formProgressBar + (barClass ? " active" : "")}
        ></span>
      );
    }
    return bars;
  };
  const completedPorcentage = Math.floor(
    (completedFieldsProgress / fieldsProgress) * 100
  );
  useEffect(() => {
    fCompletedFields();
    // eslint-disable-next-line
  });
  function fCompletedFields() {
    const form = formRef.current!;
    const formData = new FormData(form);
    const { completedFields } = handleFormData(formData);
    setCompletedFields(completedFields);
  }
  function handleFormData(data: FormData) {
    const elemArray = [
      data.get("inspiration-title"),
      data.get("inspiration-description") === "<p><br></p>"
        ? ""
        : data.get("inspiration-description"),
      data.get("Tags"),
      photo ? photo : "",
    ];

    setFieldsProgress(elemArray?.length);

    const inspirationTagsArray = data.get("Tags") as string;
    const tags = inspirationTagsArray ? inspirationTagsArray.split(";") : [];

    let newValues = {
      title: sanitizeHtml(data.get("inspiration-title")?.toString()!),
      description: sanitizeHtml(data.get("description-title")?.toString()!, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "figure",
          "img",
        ]),
        allowedAttributes: {
          span: ["style"],

          img: ["src"],
        },
        selfClosing: [
          "img",
          "br",
          "hr",
          "area",
          "base",
          "basefont",
          "link",
          "meta",
        ],
        allowedSchemes: ["data"],
      }),
      tags: tags,
      img: photo,
    };

    const params = {
      ...newValues,
    };

    const completedFields = elemArray.filter((x) => x).length;
    return { params, completedFields };
  }

  const TextTooltip = resource(culture, "PageCreateInspiration::Tags_Tooltip");

  const createInsights = () => {
    setLoadingSubmit(true)
    if (!titleValue) {
      toast.error(
          resource(culture?.culture, "MessageError::TitleRequired")
      );
      setLoadingSubmit(false)
    } else if (!descriptionValue) {
      toast.error(
          resource(culture?.culture, "MessageError::DescriptionRequired")
      );
      setLoadingSubmit(false)
    } else {
      const postParams = {
        title: titleValue,
        description: descriptionValue,
        workspaceId,
        challengeId,
        image: photo,
        tags: tagsValue,
      };
      postInsight(postParams).then(() => {
        toast.success(
            resource(culture?.culture, "PageCreateInspiration::SuccessCreatedInspiration")
        );
        setLoadingSubmit(false)
        history.push(`/challenges/details/${challengeId}`);
      }, err => {
        setLoadingSubmit(false)
      });
    }
  };

  return (
    <Box marginY={4}>
      <form
        ref={formRef}
        className="CreateInspiration-Form"
        onKeyDown={(e) =>
          e.key === "Enter" && !e.shiftKey && e.preventDefault()
        }
      // onSubmit={handleSubmit}
      >
        <StickyComponent offsetX={"0"}>
          <Grid
            container
            className={classes.createInspirationProgressButtons}
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={9}>
              <Box display="flex" alignSelf="self-start">
                <Box component="p" fontWeight="bold" mr={1}>
                  <Resource tag="PageCreateInspiration::NewInspiration" />
                </Box>
                <Box component="p" color={theme.palette.text.secondary}>
                  <Resource
                    tag="PageCreateInspiration::Complete"
                    args={[completedPorcentage]}
                  />
                </Box>
              </Box>
              <Box mt={1} display="flex">
                {setUpBar()}
              </Box>
              <Box
                mt={2}
                display="flex"
                onClick={() => setOpen(true)}
                style={{ cursor: "pointer" }}
              >
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    <Resource tag="PageEditIdea::Challenge" />
                  </Typography>
                </Box>
                <Box ml={1}>
                  <Typography variant="body2" color="textSecondary">
                    {challenge?.title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </StickyComponent>
        <Box mt={8} className="inspiration-title">
          <TextInputWCounter
            id="inspiration-title"
            name="inspiration-title"
            maxLength={100}
            label={<Resource tag="PageCreateInspiration::Title" />}
            placeholder={resource(culture, "PageCreateInspiration::Title_Tip")}
            fullWidth
            onChange={(e) => setTitleValue(e.target.value)}
            value={titleValue}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box className="inspiration-description-sun-editor" mt={6} mb={10}>
          <TextEditorInput
            onChange={(e: any) => setDescriptionValue(e)}
            value={descriptionValue}
            culture={culture}
            height={200}
            propsI={inspirationDescriptionProps}
            handler={fCompletedFields}
            charCounterLabelShow={true}
            maxCharCountShow={true}
          />
        </Box>
        <FormTag
          tooltip={TextTooltip}
          culture={props.culture}
          tags={tagsValue}
          handler={(e: any) => setTagsValue(e)}
          placeholder={resource(culture, "PageCreateInspiration::Tags_Tip")}
        />
        <Box className="" mt={8}>
          <Box
            mb={2}
            color={theme.palette.text.secondary}
            display="flex"
            gridGap={15}
            style={{
              transformOrigin: "top left",
            }}
          >
            <Typography
              style={{
                fontSize: "0.8rem",
                fontWeight: 700,
                textTransform: "uppercase",
              }}
              color="textSecondary"
            >
              <Resource tag="PageCreateInspiration::ImageAndVideo" />
            </Typography>
            <Typography
              style={{ fontSize: "0.8rem", fontWeight: 300 }}
              color="textSecondary"
            >
              ( <Resource tag="PageCreateInspiration::Optional" /> )
            </Typography>
          </Box>
          <PhotoUpload photo={photo} setPhoto={setPhoto} />
        </Box>
        <Box mt={"40px"}>
          <Box display="flex">
            <Box mr={2}>
              <Button
                onClick={() => {
                  history.push(`/`);
                }}
                color="primary"
                variant="outlined"
              >
                <Resource tag={"PageCreateInspiration::Cancel"} />
              </Button>
            </Box>
            <Box mr={1}>
              <Button
                id="IdeaForm_Share"
                color={"primary"}
                variant="contained"
                type="button"
                disabled={loadingSubmit}
                endIcon={
                  loadingSubmit ? (
                    <CircularProgress size={16} color="inherit" />
                  ) : (
                    ""
                  )
                }
                onClick={createInsights}
              >
                <Resource tag="PageCreateInspiration::CreateInspiration" />
              </Button>
            </Box>
          </Box>
        </Box>
      </form>

      <Dialog open={isOpen} maxWidth="md" fullWidth className={classes.dialog}>
        <MuiDialogTitle className={classes.dialogHeader}>
          <Typography color="textPrimary" className={classes.titleHeader}>
            <Resource tag={"PageNewIdea::Challenge_Header"} />
          </Typography>
          <IconButton
            // aria-label="close"
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Divider className={classes.dividerHeader} />
        <ModalSelectAChallenge
          selectedChallenge={challenge ? challenge.challengeId : ""}
          closeButton={() => setOpen(false)}
          path={"/createInspiration"}
        ></ModalSelectAChallenge>
      </Dialog>
    </Box>
  );
}
const mapStateToProps = (store: IAppState, ownProps: any) => ({
  culture: store.cultureState,
  ownProps: ownProps,
  challengeList: store.challengeState.challengeList,
  workspaceId: store.authState.auth.workspaceId,
  challengeId: ownProps.match.params.challengeId,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  postInsight: (params: object) => dispatch(postCreateInsight(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateInpirationsPage);
