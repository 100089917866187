import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { ArrowForward } from "@material-ui/icons";
import Resource from "components/Resources/Resource";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import React, { Dispatch, SetStateAction } from "react";
import {
  Container,
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Card,
  Grid,
  Chip,
} from "@material-ui/core";
import {
  getAllWorkspaces,
  getSuggestedNamesWorkspaces,
  postWorkspaces,
  putWorkspacesName,
} from "redux/actions/workspaceAction";
import {
  IWorkspaceReducer,
  ISuggestedNamesWorkspaces,
  IWorkspaceState,
} from "redux/reducers/workspaceReducer";
import { IAuthUser } from "redux/reducers/authReducer";
import { ICountries, ICurrency, IDepartments } from "redux/reducers/usersReducers";
import { useHistory } from "react-router";

interface IDispatchProps {
  getAllWorkspace: (options: object) => Promise<IWorkspaceState>;
  putWorkspaceName: (options: object) => Promise<IWorkspaceState>;
  getSuggestedNamesWorkspace: (options: object) => Promise<IWorkspaceState>;
  postWorkspaces: (options: object) => Promise<any>;
}

interface IProps {
  setCurrentScreen: Dispatch<SetStateAction<number>>;
}

interface IStateProps {
  authUser: IAuthUser;
  workspaces: IWorkspaceReducer;
  suggestedNames: ISuggestedNamesWorkspaces;
  application: IApplication;
  culture: ICultureState;
  countries: ICountries[];
  departments: IDepartments[];
  currencies: ICurrency[];
}

const Palletes = React.memo(function (props: any) {
  const { colorPrimary, colorSecondary, colorTertiary } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Box mt={3} textAlign="left">
          <Typography
            variant="subtitle2"
          >
            <Resource tag={"PageSetup::LookWorkspace_PrimaryColor"} />
          </Typography>
        </Box>
        <Box className="colorPicker" display={"flex"} gridGap="6px">
          <Box
            bgcolor={colorPrimary}
            width={24}
            height={24}
            boxShadow="0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)"
            borderRadius="4px"
          />
          <Box>{colorPrimary}</Box>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box mt={3} textAlign="left">
          <Typography
            variant="subtitle2"
          >
            <Resource tag={"PageSetup::LookWorkspace_AccentColor"} />
          </Typography>
        </Box>
        <Box className="colorPicker" display={"flex"} gridGap="6px">
          <Box
            bgcolor={colorTertiary}
            width={24}
            height={24}
            boxShadow="0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)"
            borderRadius="4px"
          />
          <Box>{colorTertiary}</Box>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box mt={3} textAlign="left">
          <Typography
            variant="subtitle2"
          >
            <Resource tag={"PageSetup::LookWorkspace_SecondaryColor"} />
          </Typography>
        </Box>
        <Box className="colorPicker" display={"flex"} gridGap="6px">
          <Box
            bgcolor={colorSecondary}
            width={24}
            height={24}
            boxShadow="0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)"
            borderRadius="4px"
          />
          <Box>{colorSecondary}</Box>
        </Box>
      </Grid>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setWorkspaceTitle: {
      fontSize: "22px",
      textAlign: "left",
      color: "#313B53",
      fontWeight: 700,
      marginBottom: theme.spacing(7),
    },
    btnNames: {
      padding: "5px 8px",
      marginRight: "8px",
    },
  })
);


function StepWorkspaceResume(props: IDispatchProps & IStateProps & IProps) {
  const classes = useStyles();
  const history = useHistory();

  const {
    workspaces,
    departments,
    countries,
    currencies,
  } = props;

  const mapTip = (item:string) =>{
    let cur = currencies?.filter(currency => currency.currencyId === item)[0];
    if(cur)
      return `${cur.description} ${cur.symbol}`
    return '';
  }

  return (
    <Container>
      <Typography className={classes.setWorkspaceTitle}>
        <Resource tag="PageSetup::Resume" />
      </Typography>
      <Box display="flex" mb="20px" alignItems="center" gridGap="8px">
        <Card>
          <Box
            width="100px"
            height="100px"
            display="flex"
            alignContent="center"
            alignItems="center"
          >
            <img style={{ maxWidth: "100px" }} src={workspaces.photoLogo} alt="logo" />
          </Box>
        </Card>
        <Box>{workspaces.name}</Box>
      </Box>
      <Card>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          p="10px"
          gridGap="15px"
        >
          <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start">
            <Resource tag="PageSetup::Colors" />
            <Palletes
              colorPrimary={workspaces.theme.primaryColor}
              colorSecondary={workspaces.theme.secondaryColor}
              colorTertiary={workspaces.theme.tertiaryColor}
            />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Resource tag="PageSetup::Countries" />
            <Box
              pt="5px"
              display="flex"
              gridGap="5px"
              flexWrap="wrap"
            >
              {workspaces.countries.map(country => {
                return <Chip
                  label={countries.filter(item => item.countryId === country)[0].name}
                  variant="outlined"
                />
              })}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Resource tag="PageSetup::TitleCurrencies" />
            <Box
              pt="5px"
              display="flex"
              gridGap="5px"
              flexWrap="wrap"
            >
              {workspaces.currencies.map(currency => {
                return <Chip
                  label={mapTip(currency)}
                  variant="outlined"
                />
              })}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Resource tag="PageSetup::TitleDepartments" />
            <Box
              pt="5px"
              display="flex"
              gridGap="5px"
              flexWrap="wrap"
            >
              {workspaces.departments.map(department => {
                return <Chip
                  label={departments.filter(item => item.departmentId === department)[0].title}
                  variant="outlined"
                />
              })}
            </Box>
          </Box>
        </Box>
      </Card>
      <Box textAlign="right" mt={7}>
        <Button
          onClick={() => history.push("/congrats")}
          color="primary"
          variant="contained"
          endIcon={<ArrowForward className="icon icon-arrow" />}
        >
          <Resource tag="PageSetup::FinishSettings" />
        </Button>
      </Box>
    </Container>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllWorkspace: (options: object) => dispatch(getAllWorkspaces(options)),
    getSuggestedNamesWorkspace: (options: object) =>
      dispatch(getSuggestedNamesWorkspaces(options)),
    putWorkspaceName: (params: object) => dispatch(putWorkspacesName(params)),
    postWorkspaces: (params: object) => dispatch(postWorkspaces(params)),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    authUser: store.authState.auth,
    departments: store.usersState.departaments,
    currencies: store.usersState.currency,
    countries: store.usersState.countries,
    application: store.tenantState.application,
    culture: store.cultureState,
    workspaces: store.workSpacesState.workspaces,
    suggestedNames: store.workSpacesState.suggestedNamesWorkspaces,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepWorkspaceResume);
