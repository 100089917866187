import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ICulture, ICultureState } from '../reducers/cultureReducer';
import { toast } from 'react-toastify';
import {api, api_general, api_v3} from "./../../config/api";
import { filterLanguage } from '../../utils/language';
import newApp from 'utils/reactNewApp';
import {ICultureTag} from "../types/manageGroups";

const basePath = "resources";

export enum CultureActionTypes {
  GET_CULTURE = 'GET_CULTURE',
}

export enum CultureActionStatus {
  CULTURE_LOADING = 'LOADING',
  CULTURE_SUCCEDDED = 'SUCCEEDED',
  CULTURE_FAILED = 'FAILED',
}

export interface ICultureSetCulture {
  type: CultureActionTypes.GET_CULTURE;
  statusCulture: string,
  statusCultureDefault: string,
  culture: ICulture;
}

export type CultureActions = ICultureSetCulture;

export const setCultureAndResources: ActionCreator<
  ThunkAction<Promise<any>, ICultureState, null, ICultureSetCulture>
> = (cultureTag) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        statusCulture: CultureActionStatus.CULTURE_LOADING,
        type: CultureActionTypes.GET_CULTURE,
      });

      newApp ?
        await api_general.get(`${basePath}`).then(response => {
          dispatch({
            culture: { cultureTag: cultureTag??"pt-PT", resources: response.data },
            statusCulture: CultureActionStatus.CULTURE_SUCCEDDED,
            type: CultureActionTypes.GET_CULTURE,
          });
        })
        :
        await api.get(`${basePath}`).then(response => {
          dispatch({
            culture: { cultureTag: cultureTag??"pt-PT", resources: response.data },
            statusCulture: CultureActionStatus.CULTURE_SUCCEDDED,
            type: CultureActionTypes.GET_CULTURE,
          });
        })
    } catch (err:any) {
      dispatch({
        err: err,
        statusCulture: CultureActionStatus.CULTURE_FAILED,
        type: CultureActionTypes.GET_CULTURE,
      });
      toast.error(err.error_message)
    }
  };
};

export const getResourcesDefault: ActionCreator<
  ThunkAction<Promise<any>, ICultureState, null, ICultureSetCulture>
> = (cultureTag) => {
  return async (dispatch: Dispatch) => {
    try {

      dispatch({
        statusCultureDefault: CultureActionStatus.CULTURE_LOADING,
        type: CultureActionTypes.GET_CULTURE,
      });

      let culture = filterLanguage(cultureTag === undefined ? navigator.language : cultureTag)
      const response = await api_general.get("tenantconfigurations/resources", {
        headers: {
          cultureTag: culture??'pt-PT',
        }
      });

      dispatch({
        culture: response.data,
        statusCultureDefault: CultureActionStatus.CULTURE_SUCCEDDED,
        type: CultureActionTypes.GET_CULTURE,
      });
    } catch (err) {
      dispatch({
        err: err,
        statusCultureDefault: CultureActionStatus.CULTURE_FAILED,
        type: CultureActionTypes.GET_CULTURE,
      });

      toast.error("Culture not found")
    }
  };
};

export const getCulturesTags = async(): Promise<ICultureTag[] | undefined> => {
  try {
    const { data } = await api_v3.get(`${basePath}/cultures`, {});
    return data;
  } catch (err) {
    return undefined;
  }
};
