import React from "react";
import { Grid, Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export default function SkeletonTitle() {
  return (
    <Box mb={2}>
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <Skeleton variant="rect" height={40} />
        </Grid>
      </Grid>
    </Box>
  );
}
