// Import Reducer type
import { Reducer } from "redux";
import {
  ChallengeDrafts,
  IChallengeActive,
  IChallengeAllList,
  IChallengeHighlighted,
  IChallengeList,
  IChallengesClosed,
  IChallengesInProgress,
  IChallengesPermanent,
  IChallengesTops,
  IIdeaForm,
  IIdeaFormV3,
} from "redux/types/challenges";
import {
  ChallengeActions,
  ChallengeActionTypes,
  ChallengeStatusTypes,
} from "redux/actions/ChallengeActions";

// Define the Character State
export interface IChallengeState {
  readonly showMore: boolean;
  readonly ideasForm: IIdeaForm;
  readonly ideaFormV3: IIdeaFormV3[];
  readonly ideaFormV3Status: string;
  readonly challengesTop: IChallengesTops;
  readonly challengesPermanent: IChallengesPermanent;
  readonly challengesInProgress: IChallengesInProgress;
  readonly challengesClosed: IChallengesClosed;
  readonly activeChallenges: IChallengeActive;
  readonly highlightedChallenges: IChallengeHighlighted;
  readonly challengeList: IChallengeList;
  readonly challengeAllList: IChallengeAllList[];
  readonly challengeDraft: ChallengeDrafts;
  readonly status: string;
  readonly draftStatus: string;
}

// Define the initial state
const initialCharacterState: IChallengeState = {
  showMore: false,
  ideasForm: {
    customFields: [],
    initialIdeas: {},
    coAuthorNumber: 0,
    mediaGalleryMaxItems: 0,
    mediaGalleryMaxSizeKbPerItem: 1024,
  },
  ideaFormV3: [] as IIdeaFormV3[],
  ideaFormV3Status: "SUCCESS",
  challengesTop: {} as IChallengesTops,
  challengesPermanent: {} as IChallengesPermanent,
  challengesInProgress: {} as IChallengesInProgress,
  challengesClosed: {} as IChallengesClosed,
  activeChallenges: {} as IChallengeActive,
  highlightedChallenges: {} as IChallengeHighlighted,
  challengeList: {} as IChallengeList,
  challengeAllList: [] as IChallengeAllList[],
  challengeDraft: {} as ChallengeDrafts,
  status: '',
  draftStatus: ''
};

export const challengeReducer: Reducer<IChallengeState, ChallengeActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case ChallengeActionTypes.GET_ALL_CHALLENGE: {
      switch (action.status) {
        case ChallengeStatusTypes.CHALLENGE_FAIL:
        case ChallengeStatusTypes.CHALLENGE_LOADING:
          return {
            ...state,
            status: action.status,
          }
        case ChallengeStatusTypes.CHALLENGE_SUCCESS:
          return {
            ...state,
            activeChallenges: action.activeChallenges,
            highlightedChallenges: action.highlightedChallenges,
            challengesClosed: action.challengesClosed,
            challengesPermanent: action.challengesPermanent,
            challengesInProgress: action.challengesInProgress,
            challengeAllList: action.challengeAllList,
            status: action.status
          };
        default:
          return {
            ...state
          }
      }
    }
    case ChallengeActionTypes.GET_IDEA_FORM_V3_LOADING: {
      return {
        ...state,
        ideaFormV3Status: action.ideaFormV3Status,
      };
    }
    case ChallengeActionTypes.GET_IDEA_FORM_V3_SUCCESS: {
      return {
        ...state,
        ideaFormV3: action.ideasFormV3 || [],
        ideaFormV3Status: action.ideaFormV3Status,
      };
    }
    case ChallengeActionTypes.GET_IDEA_FORM_V3_FAIL: {
      return {
        ...state,
        ideaFormV3Status: action.ideaFormV3Status,
      };
    }
    case ChallengeActionTypes.GET_IDEA_FORM: {
      return {
        ...state,
        ideasForm: action.ideasForm,
      };
    }
    case ChallengeActionTypes.SHOW_MORE: {
      return {
        ...state,
        showMore: !state.showMore,
      };
    }
    case ChallengeActionTypes.GET_CHALLENGE_TOPS: {
      return {
        ...state,
        challengesTop: action.challengesTop,
      };
    }
    case ChallengeActionTypes.GET_CHALLENGE_PERMANET: {
      return {
        ...state,
        challengesPermanent: action.challengesPermanent,
      };
    }
    case ChallengeActionTypes.GET_CHALLENGE_INPROGRESS: {
      return {
        ...state,
        challengesInProgress: action.challengesInProgress,
      };
    }
    case ChallengeActionTypes.GET_CHALLENGE_CLOSED: {
      return {
        ...state,
        challengesClosed: action.challengesClosed,
      };
    }
    case ChallengeActionTypes.GET_ACTIVE_CHALLENGES: {
      return {
        ...state,
        activeChallenges: action.activeChallenges,
      };
    }
    case ChallengeActionTypes.GET_HIGHLIGHTED_CHALLENGES: {
      return {
        ...state,
        highlightedChallenges: action.highlightedChallenges,
      };
    }
    case ChallengeActionTypes.GET_ALL_CHALLENGE_INSPIRATION:
    case ChallengeActionTypes.GET_CHALLENGE_LIST: {
      return action?.isInfinite
        ? {
            ...state,
            challengeList: {
              meta: action.challengeList.meta,
              result: [
                ...state.challengeList.result,
                ...action.challengeList.result,
              ],
            },
          }
        : {
            ...state,
            challengeList: action.challengeList,
          };
    }
    case ChallengeActionTypes.GET_ALL_CHALLENGE_LIST: {
      return {
        ...state,
        challengeAllList: action.challengeAllList,
      };
    }
    case ChallengeActionTypes.GET_CHALLENGE_DRAFTS: {
      switch (action.status) {
        case ChallengeStatusTypes.CHALLENGE_LOADING:
        case ChallengeStatusTypes.CHALLENGE_FAIL:
          return {
            ...state,
            draftStatus: action.status
          }
        case ChallengeStatusTypes.CHALLENGE_SUCCESS:
          return action?.isInfinite ? {
            ...state,
            draftStatus: action.status,
            challengeDraft: {
              meta: action.payload?.meta,
              result: [
                ...state.challengeDraft.result,
                ...action.payload?.result
              ]
            }
          } : {
            ...state,
            draftStatus: action.status,
            challengeDraft: action.payload
          }
        default:
          return {
            ...state
          }
      }
    }
    default:
      return state;
  }
};
