import React, { useCallback } from "react";
import ListIcon from "@material-ui/icons/List";
import CreateIcon from "@material-ui/icons/Create";
import SubjectIcon from "@material-ui/icons/Subject";
import Filter7Icon from "@material-ui/icons/Filter7";
import Resource from "components/Resources/Resource";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { ICultureState } from "redux/reducers/cultureReducer";
import { DraggableCard, PageStateIdeaForm } from "redux/types/createChallenge";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import DropableButton from "../../../../components/Draggable/DropableButton/DropableButton";
import DraggableButton from "../../../../components/Draggable/DraggableButton/DraggableButton";
import CustomFieldCollapsible from "../../../../components/Draggable/CustomFieldCollapsible/CustomFieldCollapsible";
import { Grid, Box, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import { random } from "utils/random";

export interface IWFCollapsibleCard {
  type: number;
  required: boolean;
  movable: boolean;
  firstOpen: boolean;
  cultures: {
    cultureTag: string;
    title: string;
    placeholder: string;
    helpText: string;
  }[];
  options?: {
    cultures: {
      cultureTag: string;
      title: string;
    }[];
  }[];
}

interface IProps {
  culture: ICultureState;
  setState: any;
  state: PageStateIdeaForm;
}

export const typeButtons = [
  {
    name: <Resource tag="CustomFields::ShortText" />,
    type: 1,
    startIcon: <CreateIcon fontSize="small" />,
  },
  {
    name: <Resource tag="CustomFields::LongText" />,
    type: 2,
    startIcon: <SubjectIcon fontSize="small" />,
  },
  {
    name: <Resource tag="CustomFields::Attachment" />,
    type: 3,
    startIcon: <AttachFileIcon fontSize="small" />,
  },
  {
    name: <Resource tag="CustomFields::Number" />,
    type: 4,
    startIcon: <Filter7Icon fontSize="small" />,
  },
  {
    name: <Resource tag="CustomFields::Checkmark" />,
    type: 5,
    startIcon: <ListIcon fontSize="small" />,
  },
  {
    name: <Resource tag="CustomFields::RadioButton" />,
    type: 6,
    startIcon: <RadioButtonCheckedIcon fontSize="small" />,
  },
  {
    name: <Resource tag="CustomFields::Dropdown" />,
    type: 7,
    startIcon: <ArrowDropDownCircleIcon fontSize="small" />,
  },
];

function CreateChallengeStepIdeaForm(props: IProps) {
  const theme = useTheme();
  const mobileSm = useMediaQuery(theme.breakpoints.down("md"));
  const { state, setState, culture } = props;
  const { cultureTag } = culture?.culture || "";

  const handleAddCard = useCallback(
    (card) => {
      const newCard: DraggableCard = {
        type: card.type,
        isRequired: false,
        firstOpen: true,
        order: state.cards.length,
        cultures: [
          {
            title: "",
            placeholder: "",
            helpText: "",
            description: "",
            cultureTag,
          },
        ],
        options: [{
          cultures: [{
            cultureTag,
            title: "",
          }],
        }],
      };

      const newArr = [...state.cards, newCard];
      setState({
        ...state,
        cards: newArr,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.cards]
  );

  const handleDeleteCard = useCallback(
    (card, index) => {
      const newArr = [...state.cards];
      setState({
        ...state,
        cards: [...newArr].filter((x, i) => i !== index),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.cards]
  );

  const handleCardChange = useCallback(
    (index, value) => {
      const newArr = [...state.cards];
      newArr[index].firstOpen = false;

      if (value) {
        newArr[index].cultures = value.fields;
        newArr[index].isRequired = value.isRequired;
        newArr[index].options = value.options;
      }
      setState({
        ...state,
        cards: newArr,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.cards]
  );

  const handleMove = useCallback(
    (index, futureIndex) => {
      const newArr = [...state.cards];
      const currentCard = { ...newArr[index] };
      const futureCard = { ...newArr[futureIndex] };
      currentCard.order = futureIndex;
      futureCard.order = index;
      newArr[index] = futureCard;
      newArr[futureIndex] = currentCard;

      setState({
        ...state,
        cards: newArr,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.cards]
  );

  const hasAttachment = state.cards.some((card) => card.type === 3)

  return (
    <Box className="step-idea-form-container">
      <Box fontWeight="bold" fontSize="1.3rem" mb={3}>
        <Resource tag="PageCreateChallenge::IdeaForm_CreateIdeaForm" />
      </Box>
      <Grid container spacing={1}>
        <Grid item sm={12} md={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="body1">
              <Resource tag="CustomFields::ChooseType" />
            </Typography>
            <Box mt={2} display="flex" flexDirection="column" width={mobileSm ? "100%" : "80%"}>
              {typeButtons.map((item, index) => (
                <DraggableButton name={item.name} data={item} startIcon={item?.startIcon} key={index} disabled={index === 2 && hasAttachment} />
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} md={9}>
          <Typography variant="body1">
            <Resource tag="CustomFields::EditField" />
          </Typography>
          <Box mt={2} bgcolor="#F2F2F2" borderRadius={7} p={1}>
            {state.cards.map((card, index) => (
              <CustomFieldCollapsible
                culture={culture}
                key={random()}
                handleCardChange={handleCardChange}
                card={card}
                index={index}
                handleDeleteCard={handleDeleteCard}
                handleMove={handleMove}
              />
            ))}
            <DropableButton handleAdd={handleAddCard} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreateChallengeStepIdeaForm;
