import EMIcon from "components/Icon/Icon";
import Truncate from "react-truncate";
import "./MyActivitiesInProgress.scss";
import React, { useState } from "react";
import Link from "@material-ui/core/Link";
import NumberFormat from "react-number-format";
import CloseIcon from "@material-ui/icons/Close";
import Resource from "components/Resources/Resource";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { TableBody, TableRow, TableCell, useTheme } from "@material-ui/core";
import { IResultMyActivitiesIdeasInProgress } from "../../redux/types/myActivities";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      margin: "0 5px",
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
    },
    firstTableCell: {
      padding: "16px 10px 16px 0px!important",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: "250px",
      maxWidth: "250px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "80px",
        maxWidth: "80px",
      },
    },
    tableCell: {
      padding: "16px 10px 16px 0px!important",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "150px",
    },
    icon: {
      margin: "0 5px",
    },
    linkItem: {
      fontSize: "14px",
      color: "#333",
      fontWeight: "bold",
      "& a": {
        textDecoration: "none",
        color: "#333",
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        "text-overflow": "ellipsis",
      },
    },
    ideaDate: {
      fontSize: 10,
      color: "#adadad",
      fontWeight: "bold",
      textTransform: "lowercase",
      "&:first-line": {
        textTransform: "capitalize",
      },
    },
    ideaEvaluation: {
      fontSize: 14,
      fontWeight: "bold",
      color: "#5f5f5f",
    },
    ideaStatus: {
      fontSize: 12,
      fontWeight: "bold",
      color: "#333",
    },
    ideaManager: {
      fontSize: 12,
      color: "#333",
    },
    listManagerButton: {
      borderRadius: "50%",
      width: theme.spacing(4),
      height: theme.spacing(4),
      background: theme.palette.primary.light,
      cursor: "pointer",
      color: theme.palette.primary.main,
      fontSize: "1rem",
      float: "left",
      padding: "0px",
      marginLeft: "5px",
    },
    userManager: {
      paddingLeft: "15px",
      color: theme.palette.text.primary,
      marginTop: "10px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(0.5),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    titlePopup: {
      padding: "20px 40px 16px",
    },
    bodyPopup: {
      padding: "0px 40px 32px",
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
  })
);

interface IProps {
  ideasInProgress: IResultMyActivitiesIdeasInProgress;
}

function MyActivitiesInProgress(props: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { ideasInProgress } = props;
  const ideaBackURL = "/idea/details";

  const [viewAll, setViewAll] = useState(false);
  let showSlice = 3;
  let showButton;

  if (props?.ideasInProgress?.managers?.length > 2) {
    let showCount = props?.ideasInProgress?.managers?.length - 2;
    showSlice = 2;
    showButton = (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.listManagerButton}
        onClick={() => setViewAll(true)}
      >
        <EMIcon color={theme.palette.primary.main} size={8} icon={"plus"} />
        <span>{showCount}</span>
      </Box>
    );
  }

  return (
    <TableBody>
      <TableRow className={classes.tableRow}>
        <TableCell
          align="left"
          className={classes.firstTableCell + " " + classes.linkItem}
        >
          <Link
            component={RouterLink}
            to={{
              pathname: `${ideaBackURL}/${ideasInProgress?.ideaId}`,
            }}
          >
            {ideasInProgress?.ideaTitle}
          </Link>
          <Hidden implementation="css" smUp>
            <Box className={classes.ideaStatus}>{ideasInProgress?.status}</Box>
          </Hidden>
          <Hidden implementation="css" smUp>
            <Box className={classes.ideaDate}>{ideasInProgress?.date}</Box>
          </Hidden>
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCell + " " + classes.ideaDate}
        >
          <Hidden xsDown>{ideasInProgress?.date}</Hidden>
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCell + " " + classes.ideaEvaluation}
        >
          <span>
            {ideasInProgress?.evaluation?.value ? (
              <>
                {ideasInProgress?.evaluation?.type === "Likes" ? (
                  ideasInProgress?.evaluation?.value
                ) : (
                  <>
                    {ideasInProgress?.evaluation?.type === "Investments" ? (
                      <>
                        <NumberFormat
                          value={Number(
                            ideasInProgress?.evaluation?.value
                          ).toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                        %
                      </>
                    ) : (
                      <NumberFormat
                        value={Number(
                          ideasInProgress?.evaluation?.value
                        ).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              ""
            )}
            {ideasInProgress?.evaluation?.value ? (
              <em className={classes.icon}>
                {ideasInProgress?.evaluation?.type === "Stars" ? (
                  <EMIcon icon="star-b" size={13} color="#5f5f5f" />
                ) : (
                  <>
                    {ideasInProgress?.evaluation?.type === "Likes" ? (
                      <EMIcon icon="like-b" size={13} color="#5f5f5f" />
                    ) : (
                      <EMIcon icon="investment" size={13} color="#5f5f5f" />
                    )}
                  </>
                )}
              </em>
            ) : (
              <em className={classes.icon}> - </em>
            )}
          </span>
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCell + " " + classes.ideaStatus}
        >
          <Hidden xsDown>{ideasInProgress?.status}</Hidden>
        </TableCell>

        <TableCell
          align="left"
          className={classes.tableCell + " " + classes.ideaManager}
        >
          <Box paddingY={1} display="flex" gridGap={5}>
            {props?.ideasInProgress?.managers
              ?.slice(0, showSlice)
              .map((manager, index) => {
                return (
                  <Box display="flex" key={manager.userId}>
                    <Avatar
                      className={classes.thumbnail}
                      src={manager?.image}
                      alt={manager?.fullName}
                    />
                    {props?.ideasInProgress?.managers?.length === 1 ? (
                      <Box className={classes.userManager}>
                        <Truncate
                          lines={1}
                          width={70}
                          trimWhitespace
                          ellipsis={<span>...</span>}
                        >
                          {props?.ideasInProgress?.managers?.[0].fullName}
                        </Truncate>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                );
              })}
            {showButton}
            <Dialog fullWidth maxWidth={"sm"} open={viewAll}>
              <DialogTitle className={classes.titlePopup} disableTypography>
                <Typography variant="h4">
                  <Resource tag="PageIdea::Idea_ColumnManager" />
                </Typography>
                <IconButton
                  // aria-label="close"
                  className={classes.closeButton}
                  onClick={() => setViewAll(false)}
                >
                  <CloseIcon />
                </IconButton>
                <Divider className={classes.divider}></Divider>
              </DialogTitle>
              <DialogContent className={classes.bodyPopup}>
                {props?.ideasInProgress?.managers?.map((manager, index) => {
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mb={1}
                      key={manager.userId}
                    >
                      <Avatar
                        className={classes.thumbnail}
                        src={manager?.image}
                        alt={manager?.fullName}
                      />
                      <Box ml={1} fontSize="0.8rem">
                        {manager?.fullName}
                      </Box>
                    </Box>
                  );
                })}
              </DialogContent>
            </Dialog>
          </Box>

          {/* <Box display="flex" justifyContent="flex-start" alignItems="center">
            {ideasInProgress?.managers.length >= 1 &&
              ideasInProgress?.managers.map((author, index) => {
                if (index < 2) {
                  return (
                    <Box
                      key={Math.random()}
                      display={
                        ideasInProgress?.managers.length < 2 ? "flex" : "block"
                      }
                      alignItems="center"
                    >
                      <Avatar
                        alt={author.fullName}
                        src={author.image}
                        className={classes.thumbnail}
                      />
                      {ideasInProgress?.managers.length < 2 &&
                        ideasInProgress?.managers[0].fullName}
                    </Box>
                  );
                }
              })}
            {ideasInProgress?.managers.length > 2 && (
              <div
                className="more-thumbnail"
                style={{
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                +{ideasInProgress?.managers.length - 2}
              </div>
            )}
          </Box>
              */}
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default MyActivitiesInProgress;
