import * as React from 'react';
import { IInvestment } from '../../redux/reducers/investmentReducer';
import InvestmentItems from '../../components/InvestmentItem/InvestmentItem'
import './InvestmentList.scss'
import { IResultMyActivitiesInvestmentsActive } from 'redux/types/myActivities';
import { Table, TableHead, TableRow, TableCell, makeStyles, createStyles, Theme, TableBody, Box, Grid, Button, Typography } from '@material-ui/core';
import Resource from '../../components/Resources/Resource';
import { IWalletInvestments } from 'redux/reducers/walletReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: '20px 0px',
    },
    tableTitles: {
      'color': ' #767676',
      'font-weight': 500,
      'font-size': '12px',
      'text-transform': 'uppercase',
    },
    tableRow: {
      borderBottom: 'solid 1px #ccc!important',
      [theme.breakpoints.down('sm')]: {
        borderBottom: 'none'
      }
    }
  })

);

// Create the containers interface
interface IProps {
  investments?: IInvestment;
  activeInvestments?: IResultMyActivitiesInvestmentsActive[];
  viewMoreData?: any
  showMore?: boolean;
  walletInvestments?: IWalletInvestments;
  activeInvestmentMeta?: any
}

function InvestmentList(props: IProps) {
  const { activeInvestments, investments, walletInvestments, viewMoreData, activeInvestmentMeta } = props;
  let maxLines = 4;
  const classes = useStyles()

  return (
    <Box>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableTitles} variant='head' align="left"><Resource tag={"PageWallet::Investment_IdeaColumn"} /></TableCell>
              <TableCell className={classes.tableTitles} variant='head' align="left"><Resource tag={"PageWallet::Investment_AmountColumn"} /></TableCell>
              <TableCell className={classes.tableTitles} variant='head' align="left"><Resource tag={"PageWallet::Investment_CurrentValueColumn"} /></TableCell>
              <TableCell className={classes.tableTitles} variant='head' align="center"><Resource tag={"PageWallet::Investment_GraphColumn"} /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {walletInvestments &&
              walletInvestments?.result.map((walletInvestment, index) => {
                const graph = walletInvestment.graphIdeaRate
                const data = Object.values(graph)
                return (
                  <InvestmentItems 
                  key={walletInvestment.ideaId} 
                  data={data} 
                  walletInvestment={walletInvestment} 
                  currentValue={walletInvestment?.currentInvestmentValue!}
                  investedValue={walletInvestment?.invested!}
                  points={walletInvestment?.currentInvestmentValue! - walletInvestment?.invested!} 
                  />
                )
              })}
            {activeInvestments &&
              activeInvestments?.map((activeInvestment, index) => {
                const graph = activeInvestment?.investmentGraph
                let data = [0];
                if (graph !== undefined) {
                  data = Object.values(graph)
                }
                  return (
                    <InvestmentItems 
                    key={activeInvestment.ideaId} 
                    data={data} 
                    activeInvestments={activeInvestment}
                    currentValue={activeInvestment?.investmentVariation!}
                    investedValue={activeInvestment?.investedAmount}
                    points={activeInvestment?.investmentVariation! - activeInvestment?.investedAmount!}  
                    />
                  )
              })}
          </TableBody>
        </Table>
      </Grid>
      {activeInvestmentMeta?.total > maxLines && (
        <Box marginTop={1} className="view-all-investments">
          {activeInvestmentMeta?.total > activeInvestments?.length! &&
            <Button style={{textTransform: 'uppercase'}} onClick={() => viewMoreData(activeInvestments?.length! + 4)}>
              <Typography variant="body2" color="primary">
                <Resource tag="Common::ViewAll" /> {activeInvestmentMeta?.total}
              </Typography>
            </Button>
          }
          {activeInvestments?.length! > maxLines && 
            <Button style={{textTransform: 'uppercase'}} onClick={() => viewMoreData(4)}>
              <Typography variant="body2" color="primary">
                <Resource tag="Common::ViewLess" />
              </Typography>
            </Button>
          }
        </Box>
      )}
      {investments && investments?.meta?.total > maxLines && (
        <Box marginTop={1} className="view-all-investments">
          {investments?.meta?.total > investments?.result?.length &&
            <Button style={{textTransform: 'uppercase'}} onClick={() => viewMoreData(investments?.result?.length + 4)}>
              <Typography variant="body2" color="primary">
                <Resource tag="Common::ViewAll" /> {investments?.meta.total}
              </Typography>
            </Button>
          }
          {investments?.result?.length > maxLines && 
            <Button style={{textTransform: 'uppercase'}} onClick={() => viewMoreData(4)}>
              <Typography variant="body2" color="primary">
                <Resource tag="Common::ViewLess" />
              </Typography>
            </Button>
          }
        </Box>
      )}
      {walletInvestments && walletInvestments?.meta?.total > maxLines && (
        <Box marginTop={1} className="view-all-investments">
          {walletInvestments?.meta?.total > walletInvestments?.result?.length &&
            <Button size="small" style={{ textTransform: 'uppercase' }} onClick={() => viewMoreData(walletInvestments?.result?.length + 4)}>
              <Typography variant="body2" color="primary">
                <Resource tag="Common::ViewAll" /> {walletInvestments?.meta?.total}
              </Typography>
            </Button>
          }
          {walletInvestments?.result?.length > maxLines &&
            <Button size="small" style={{ textTransform: 'uppercase' }} onClick={() => viewMoreData(4)}>
              <Typography variant="body2" color="primary">
                <Resource tag="Common::ViewLess" />
              </Typography>
            </Button>
          }
        </Box>
      )}
    </Box>
  );
}

export default InvestmentList;