import React from "react"
import PrizesSkeleton from './PrizesSkeleton';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';

export default function PrizePageSkeleton(){
  return(
    <>
      <Grid container spacing={2} style={{marginTop:45, padding:0}}>
        <Skeleton variant="rect" width="100%" height={40} style={{marginBottom:70}}/>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <PrizesSkeleton/>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <PrizesSkeleton/>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <PrizesSkeleton/>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{marginTop:115, padding:0}}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <PrizesSkeleton/>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <PrizesSkeleton/>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <PrizesSkeleton/>
        </Grid>
      </Grid>
    </>
  )
}