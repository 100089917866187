import React, { useState } from "react";
import { IAppState } from "../../redux/store/Store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import ToolTip from "../ToolTip/ToolTip";
import {
  IKeyTasksPositive,
  IKeyTasksNegative,
  IKeyTasksState,
} from "../../redux/reducers/keyTasks/keyTasksReducer";
import {
  getAllKeyTasks,
  getCorrectionKeyTasks,
  getEvaluationKeyTasks,
  getValidationKeyTasks,
  postKeyTasksTransitions,
  postKeyTasksValidation,
  putKeyTasksCorrect,
  putKeyTasksRejected
} from "../../redux/actions/keyTasks/KeyTasksAction";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  makeStyles,
  TextareaAutosize,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Resource, { resource } from "components/Resources/Resource";
import CloseIcon from "@material-ui/icons/Close";
import { IProfileGetDataAction, getProfile } from "redux/actions/ProfileActions";
import newApp from 'utils/reactNewApp';

/* How to use: 
    <Popup trigger={<td className={className}> {title}</td>} modal closeOnDocumentClick>
      {close => (<KeyTaskIdeaValidationPopUp ideaId={ideaId} closeButton={close}></KeyTaskIdeaValidationPopUp>)}
    </Popup>
*/

const options = {
  applicationId: 0,
  ideaId: [""],
  stateId: "",
  commentText: "",
  status: 1,
  culture: {} as ICultureState | undefined,
  pageSize: 4,
  workspaceId: ''
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      color: theme.palette.grey[500],
      margin: "12px 0 3px",
    },
    checkboxLabel: {
      color: theme.palette.primary.main,
      fontSize: 13,
    },
    textarea: {
      padding: "10px 0",
      border: 0,
      outline: 0,
      width: "-webkit-fill-available",
      fontSize: "14px",
      fontFamily: "Rubik",
      borderBottom: "1px solid" + theme.palette.grey[500],
      maxWidth: 800,
      minWidth: 325,
      maxHeight: 425,
      minHeight: 45,
    },
  })
);

interface IDispatchProps {
  postKeyTasksTransitions: (options: object) => Promise<IKeyTasksState>;
  getValidationKeyTasks(options: object): Promise<IKeyTasksState>;
  getKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getEvaluationKeyTasks: (options: object) => Promise<IKeyTasksState>
  getCorrectionKeyTasks: (options: object) => Promise<IKeyTasksState>
  postKeyTasksValidation: (options: object) => Promise<IKeyTasksState>
  putKeyTasksCorrect: (options: object) => Promise<IKeyTasksState>
  putKeyTasksRejected: (options: object) => Promise<IKeyTasksState>
  getProfile: () => Promise<IProfileGetDataAction>;
}

interface IProps {
  closeButton?: any;
  ideaId: string[];
  application?: any;
  workspaceId?: string;
  culture?: ICultureState;
  keyTasksPositive?: IKeyTasksPositive[];
  toAdjust?: boolean;
  keyTasksNegative?: IKeyTasksNegative[];
  title: string;
  postTransitionStatus: string;
  error: any;
  isValidationOpen: boolean;
  stateType?: string;
  setValidationOpen: (isOpen: boolean) => void;
}

function KeyTaskIdeaValidationPopUp(props: IDispatchProps & IProps) {
  const {
    closeButton,
    title,
    keyTasksNegative,
    keyTasksPositive,
    toAdjust,
    postKeyTasksTransitions,
    application,
    workspaceId,
    culture,
    ideaId,
    isValidationOpen,
    stateType,
    setValidationOpen,
    getValidationKeyTasks,
    getCorrectionKeyTasks,
    getEvaluationKeyTasks,
    getKeyTasks,
    getProfile,
    postKeyTasksValidation,
    putKeyTasksCorrect,
    putKeyTasksRejected
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [commentValue, setcommentValue] = useState("");
  const [status, setstatus] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const tooltip = resource(
    props?.culture,
    "Tooltip::MakeThisCommentPrivate"
  )?.split("\\n\\n");

  const handleChange = (e: any) => {
    setcommentValue(e.target.value);
  };

  const handleSelect = (event: any) => {
    setstatus(!status);
  };

  const postTransition = () => {
    options.commentText = commentValue;
    status ? (options.status = 2) : (options.status = 1);

    culture && (options.culture = culture);
    options.ideaId = ideaId;

    setLoadingSubmit(true);
    
    if(newApp) {
      options.workspaceId = workspaceId!;

      if(stateType === "NotValidate") {
        putKeyTasksRejected(options).then( async () => {
          await getProfile()
          await getKeyTasks({...options,pageNumber:1, pageSize:6})
          await getValidationKeyTasks({...options,pageNumber:1, pageSize:10})
          await getCorrectionKeyTasks({...options,pageNumber:1, pageSize:10})
          await getEvaluationKeyTasks({...options,pageNumber:1, pageSize:10})
          setcommentValue('');
          setLoadingSubmit(false);
          closeButton();
        });
      } else if(stateType === "ToAdjust") {
          putKeyTasksCorrect(options).then( async () => {
            await getProfile()
            await getKeyTasks({...options,pageNumber:1, pageSize:6})
            await getValidationKeyTasks({...options,pageNumber:1, pageSize:10})
            await getCorrectionKeyTasks({...options,pageNumber:1, pageSize:10})
            await getEvaluationKeyTasks({...options,pageNumber:1, pageSize:10})
            setcommentValue('');
            setLoadingSubmit(false);
            closeButton();
          });
      } else if(stateType === "Validate") {
          postKeyTasksValidation(options).then( async () => {
            await getProfile()
            await getKeyTasks({...options,pageNumber:1, pageSize:6})
            await getValidationKeyTasks({...options,pageNumber:1, pageSize:10})
            await getCorrectionKeyTasks({...options,pageNumber:1, pageSize:10})
            await getEvaluationKeyTasks({...options,pageNumber:1, pageSize:10})
            setcommentValue('');
            setLoadingSubmit(false);
            closeButton();
          });
      }

    } else {
        keyTasksPositive &&
        toAdjust &&
        (options.stateId = keyTasksPositive.filter(x => x.stateType === 8)[0]?.stateId);
        keyTasksPositive &&
          !toAdjust &&
          (options.stateId = keyTasksPositive.filter(x => x.stateType === 2)[0]?.stateId);
        keyTasksNegative && (options.stateId = keyTasksNegative.filter(x => x.stateType === 9)[0]?.stateId);
        application && (options.applicationId = application);


        postKeyTasksTransitions(options).then( async () => {
          await getProfile()
          await getKeyTasks({...options,pageNumber:1, pageSize:6})
          await getValidationKeyTasks({...options,pageNumber:1, pageSize:10})
          await getCorrectionKeyTasks({...options,pageNumber:1, pageSize:10})
          await getEvaluationKeyTasks({...options,pageNumber:1, pageSize:10})
          setcommentValue('');
          setLoadingSubmit(false);
          closeButton();
        });
    }

  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      open={isValidationOpen}
    >
      <DialogTitle disableTypography style={{ padding: "24px 4%" }}>
        <Typography variant="h4" color="textPrimary">
          <Resource tag="Common::Validations" />
        </Typography>
        <IconButton
          // aria-label="close"
          className={classes.closeButton}
          onClick={() => setValidationOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Divider className={classes.divider} />
      </DialogTitle>
      <DialogContent>
        <Box px="7.5%">
          <Box width="100%">
            <Box maxWidth={650}>
              {keyTasksPositive &&
                keyTasksPositive?.map((data, index) => {
                  if (toAdjust) {
                    if (data?.stateType === 8) {
                      return (
                        <Typography key={index}>
                          <Resource
                            tag="ModalKeyTasks::MovingIdeaXToStatusX"
                            args={[
                              title,
                              `<strong>"${data.description}"</strong>`,
                            ]}
                          />
                        </Typography>
                      );
                    } else return "";
                  } else {
                    if (data?.stateType === 2) {
                      return (
                        <Typography key={data?.description}>
                          <Resource
                            tag="ModalKeyTasks::MovingIdeaXToStatusX"
                            args={[
                              title,
                              `<strong>"${data?.description}"</strong>`,
                            ]}
                          />
                        </Typography>
                      );
                    } else return "";
                  }
                })}
              {keyTasksNegative && (
                <Typography>
                  <Resource
                    tag="ModalKeyTasks::MovingIdeaXToStatusX"
                    args={[
                      title,
                      `<strong>"${keyTasksNegative[0]?.description}"</strong>`,
                    ]}
                  />
                </Typography>
              )}

              <Box mt={3}>
                <Typography variant="h4" color="textPrimary">
                  <Resource tag="ModalKeyTasks::WriteAComment" />
                </Typography>

                <TextareaAutosize
                  placeholder={resource(
                    props?.culture,
                    "PageKeyTasks::WriteComment"
                  )}
                  onChange={handleChange}
                  value={commentValue}
                  className={classes.textarea}
                  rowsMin={4}
                />
              </Box>
              <Box mt={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="iValidation-privite-comment"
                      onChange={handleSelect}
                      id="label-private-comment"
                      color="primary"
                    />
                  }
                  label={
                    <Box className={classes.checkboxLabel}>
                      <Resource tag="ModalTransitions::MakeThisCommentPrivate" />
                      <Box component="span" ml={0.5}>
                        {tooltip && (
                          <ToolTip title={tooltip[0]} width={400}>
                            {" "}
                            {tooltip[1]}{" "}
                          </ToolTip>
                        )}
                      </Box>
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" my={2}>
              <Button
                onClick={() => {
                  closeButton();
                }}
                color="primary"
                variant="outlined"
                size="small"
                style={{ marginRight: 8 }}
              >
                <Resource tag="Common::Cancel" />
              </Button>
              <Button
                onClick={() => {
                  postTransition();
                }}
                color="primary"
                variant={!loadingSubmit ? "contained" : "outlined"}
                disabled={!ideaId || !commentValue || loadingSubmit}
                endIcon={
                  loadingSubmit && (
                    <CircularProgress size={22} className="icon" />
                  )
                }
                size="small"
              >
                <Resource tag="Common::Confirm" />
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    application: store.authState.auth.applicationId,
    culture: store.cultureState,
    ideaDetails: store.ideasState.ideaDetails,
    postTransitionStatus: store.keyTasksState.postTransitionStatus,
    error: store.keyTasksState.error,
    workspaceId: store.authState.auth.workspaceId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    postKeyTasksTransitions: (options: object) => dispatch(postKeyTasksTransitions(options)),
    getValidationKeyTasks: (options: object) => dispatch(getValidationKeyTasks(options)),
    getKeyTasks: (options:object) => dispatch(getAllKeyTasks(options)),
    getCorrectionKeyTasks: (options: object) => dispatch(getCorrectionKeyTasks(options)),
    getEvaluationKeyTasks: (options: object) => dispatch(getEvaluationKeyTasks(options)),
    postKeyTasksValidation: (options: object) => dispatch(postKeyTasksValidation(options)),
    putKeyTasksCorrect: (options: object) => dispatch(putKeyTasksCorrect(options)),
    putKeyTasksRejected: (options: object) => dispatch(putKeyTasksRejected(options)),
    getProfile: () => dispatch(getProfile())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyTaskIdeaValidationPopUp);
