import history from "routes/history";
import React, { useRef } from "react";
import sanitizeHtml from "sanitize-html";
import { useEffect, useState } from "react";
import ToolTip from "components/ToolTip/ToolTip";
import DialogScheduleNew from "./DialogScheduleNew";
import { securityTextEditor } from "utils/securityTextEditor";
import TextInputWCounter from "common/UI/Text/TextCounter";
import PhotoUpload from "components/PhotoUpload/PhotoUpload";
import { ICultureState } from "redux/reducers/cultureReducer";
import StickyComponent from "../../../components/Sticky/Sticky";
import Resource, { resource } from "components/Resources/Resource";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { IResultNews, IResultNewsHighlighted } from "redux/types/news";
import TextEditorInput from "components/GenericField/TextEditorInput/TextEditorInput";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formProgressBar: {
      backgroundColor: theme.palette.primary.main,
      display: "inline-block",
      width: "32px",
      height: "5px !important",
      margin: "0 4px 0 0",
      opacity: "0.4",
      borderRadius: "5px",
      "&.active": {
        opacity: 1,
      },
    },
    inputPhoto: {
      display: "none",
    },
  })
);

interface IProps {
  culture: ICultureState;
  createNewStatus: string;
  workspaceId: string;
  submitForm: any;
  newData?: Partial<IResultNews> | IResultNewsHighlighted;
  topics: [];
}

function NewForm(props: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    culture,
    workspaceId,
    createNewStatus,
    submitForm,
    newData,
    topics,
  } = props;
  const [open, setOpen] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [photo, setPhoto] = useState<any>(newData?.image ?? "");
  const [fieldsProgress, setFieldsProgress] = useState(0);
  const [completedFieldsProgress, setCompletedFields] = useState(0);

  const [newState, setNewState] = useState({
    title: newData?.title ?? "",
    description: newData?.description ?? "",
    topic: newData?.topic ?? "",
    image: newData?.image ?? "",
    enableComments: true,
  });
  const newsDescriptionProps = {
    columnName: "news-description",
    isMandatory: false,
    typeId: 9,
    title: resource(culture, "PageCreateNews::NewsDescription"),
    placeholder: resource(
      culture,
      "PageCreateNews::NewsDescriptionPlaceholder"
    ),

    isEditable: true,
    maxLength: 3000,
  };

  useEffect(() => {
    setNewState((prev) => ({
      ...prev,
      image: photo,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo]);

  useEffect(() => {
    fCompletedFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo, newState.topic, newState.title]);

  const handleHaveComments = (e: any) => {
    setNewState((prev) => ({
      ...prev,
      enableComments: e?.target?.checked,
    }));
  };

  const handleChangeAutocomplete = (value: any) => {
    fCompletedFields();
    setNewState((prev) => ({
      ...prev,
      topic: value ?? "",
    }));
  };

  const setUpBar = () => {
    const bars = [];
    for (let index = 0; index < fieldsProgress; index++) {
      const barClass = index < completedFieldsProgress;
      bars.push(
        <span
          key={index}
          className={classes.formProgressBar + (barClass ? " active" : "")}
        ></span>
      );
    }
    return bars;
  };

  const completedPorcentage = Math.floor(
    (completedFieldsProgress / fieldsProgress) * 100
  );

  useEffect(() => {
    fCompletedFields();
    // eslint-disable-next-line
  }, []);

  function fCompletedFields() {
    const form = formRef.current!;
    const formData = new FormData(form);
    const { completedFields, params } = handleFormData(formData);
    setCompletedFields(completedFields);
    setNewState((prev) => ({
      ...prev,
      description: params.description,
    }));
  }

  function handleFormData(data: FormData) {
    const elemArray = [
      data.get("news-title"),
      data.get("news-description") === "<p><br></p>"
        ? ""
        : data.get("news-description"),
      data.get("topic-input"),
      !data.get("image-upload"),
    ];
    setFieldsProgress(elemArray?.length);

    let newValues = {
      title: sanitizeHtml(
        data.get("news-title")?.toString()!,
        securityTextEditor
      ),
      description: sanitizeHtml(
        data.get("news-description")?.toString()!,
        securityTextEditor
      ),
      topic: sanitizeHtml(newState.topic),
      img: photo,
    };

    const params = {
      ...newValues,
    };
    const completedFields = elemArray.filter((x) => x).length;
    return { params, completedFields };
  }

  return (
    <>
      <form ref={formRef} className="CreateIdea-Form">
        <StickyComponent offsetX={"0"}>
          <Grid container className="createIdea-progress-buttons" spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box display="flex" alignSelf="self-start">
                <Box component="p" fontWeight="bold" mr={1}>
                  <Resource tag="PageCreateNews::NewNews" />
                </Box>
                <Box component="p" color={theme.palette.text.secondary}>
                  <Resource
                    tag="PageCreateNews::Completed"
                    args={[completedPorcentage]}
                  />
                </Box>
              </Box>
              <Box mt={1} display="flex">
                {setUpBar()}
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex">
                <Box mr={2}>
                  <Button
                    onClick={() => {
                      history.goBack();
                    }}
                    color="primary"
                  >
                    <Resource tag={"PageCreateNews::Cancel"} />
                  </Button>
                </Box>
                <Box mr={1}>
                  <Button
                    id="IdeaForm_SaveDraft"
                    color={"primary"}
                    variant="contained"
                    type="button"
                    disabled={(completedFieldsProgress / fieldsProgress !== 1) || (createNewStatus === "LOADING")}
                    onClick={() => setOpen(true)}
                  >
                    <Resource tag="PageCreateNews::Schedule" />
                  </Button>
                </Box>
                <Box mr={1}>
                  <Button
                    id="IdeaForm_PublishNews"
                    color="primary"
                    variant="contained"
                    type="button"
                    endIcon={
                      createNewStatus === "LOADING" ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : (
                        ""
                      )
                    }
                    disabled={(completedFieldsProgress / fieldsProgress !== 1) || createNewStatus === "LOADING"}
                    onClick={() => {
                      submitForm({
                        ...newState,
                        workspaceId,
                        publicationDate: new Date(),
                        isHighlighted: false,
                      });
                    }}
                  >
                    <Resource tag="PageCreateNews::Publish" />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </StickyComponent>
        <Box mt={8} className="news-title">
          <TextInputWCounter
            id="news-title"
            onKeyDown={fCompletedFields}
            name="news-title"
            maxLength={100}
            label={<Resource tag="PageCreateNews::NewsTitle" />}
            placeholder={resource(
              culture,
              "PageCreateNews::NewsTitlePlaceholder"
            )}
            fullWidth
            onChange={(e) => {
              e.persist();
              setNewState((prev) => ({
                ...prev,
                title: e?.target?.value,
              }));
            }}
            value={newState.title}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box
          className="news-description-sun-editor"
          onKeyDown={fCompletedFields}
          mt={6}
          mb={10}
        >
          <TextEditorInput
            value={newData?.description ?? ""}
            culture={culture}
            height={200}
            propsI={newsDescriptionProps}
            handler={fCompletedFields}
            charCounterLabelShow={true}
            maxCharCountShow={true}
          />
        </Box>
        <Box mt={14}>
          <Autocomplete
            id="combo-box-demo"
            onKeyDown={fCompletedFields}
            options={topics || []}
            fullWidth
            freeSolo
            value={newData?.topic}
            onChange={(event, newValue) => handleChangeAutocomplete(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="topic-input"
                onChange={(e) => handleChangeAutocomplete(e.target.value)}
                label={
                  <Typography
                    style={{ fontSize: "1.05rem", fontWeight: 700 }}
                    color="textSecondary"
                  >
                    <Resource tag="PageCreateNews::Topic" />
                  </Typography>
                }
                placeholder={resource(culture, "PageCreateNews::InsertTopic")}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                }}
              />
            )}
          />
        </Box>
        <Box className="" mt={8}>
          <Box
            mb={2}
            color={theme.palette.text.secondary}
            display="flex"
            gridGap={15}
            style={{
              transformOrigin: "top left",
            }}
          >
            <Typography
              style={{ fontSize: "0.8rem", fontWeight: 700 }}
              color="textSecondary"
            >
              <Resource tag="PageCreateNews::NewsImage" />
            </Typography>
            <ToolTip>
              <Resource tag="PageCreateNews::ImageHelp" />
            </ToolTip>
          </Box>
          <PhotoUpload photo={photo} setPhoto={setPhoto} />
        </Box>
        <Box className="" mt={8} mb={9}>
          <Typography variant="body1">
            <strong>
              <Resource tag="PageCreateNews::Comments" />
            </strong>
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body1">
              <Resource tag="PageCreateNews::AllowComments" />
            </Typography>
            <Box marginLeft="auto" style={{ textTransform: "uppercase" }}>
              <Resource tag="PageCreateNews::Off" />
            </Box>
            <Switch
              checked={newState.enableComments}
              onChange={handleHaveComments}
              color="primary"
              name="haveComments"
              // inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <Box style={{ textTransform: "uppercase" }}>
              <Resource tag="PageCreateNews::On" />
            </Box>
          </Box>
        </Box>
      </form>
      <DialogScheduleNew
        open={open}
        newDate={newData?.publicationDate}
        setOpen={setOpen}
        createNewStatus={createNewStatus}
        submitForm={(publicationDate) => {
          submitForm({
            ...newState,
            workspaceId,
            publicationDate,
            isHighlighted: false,
          });
          setOpen(false);
        }}
      />
    </>
  );
}

export default NewForm;
