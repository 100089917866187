import "./Checkmark.css";
import React, { useEffect, useState } from "react";
import reactNewApp from "utils/reactNewApp";
import ToolTip from "../../ToolTip/ToolTip";
import Resource from "components/Resources/Resource";
import { Typography, Input, Checkbox, FormLabel, Box } from "@material-ui/core";

interface IProps {
  value: any;
  propsI: any;
  handler: any;
}

function Checkmark(props: IProps) {
  const { propsI, handler, value: ogValue } = props;

  const [value, setValue] = useState((reactNewApp ? ogValue : propsI?.value) ?? "");
  const valueArray = value?.split(",");

  const optionsKeys = propsI?.options ? (Object.keys(propsI?.options) as Array<any>) : [];
  const optionsValues = propsI?.options ? (Object.values(propsI?.options) as Array<any>) : [];

  const verifyChecked = (e: any) => valueArray?.indexOf(e) !== -1;

  useEffect(() => {
    handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleCheckboxClick = async (e: any) => {
    const { value } = e.target;

    if (!e.target.checked) {
      valueArray?.splice(valueArray?.indexOf(value), 1);
    } else {
      valueArray?.push(value);
    }

    let finalValue = valueArray.join(",");

    if (finalValue?.charAt(0) === ",") {
      finalValue = finalValue?.slice(1, Infinity);
    }

    await setValue(finalValue !== "" ? finalValue : "");
    handler();
  };

  return (
    <Box className="checkbox-input-container">
      <Box className="title-and-tooltip">
        <Typography className="checkbox-input-title" style={{ fontSize: "0.8rem" }} color={"textSecondary"}>
          {propsI?.title || propsI.cultures?.[0]?.title}
          {propsI?.isMandatory || propsI?.isRequired ? "*" : ""}
        </Typography>
        <Box className="BoxTooltipCheckBox" ml={1}>
          {propsI?.help || propsI?.description ? (
            <ToolTip title={<Resource tag="Common::Help" />}>{propsI?.help || propsI?.description}</ToolTip>
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Box className="checkbox-data">
        <Input
          onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
          type="hidden"
          value={value}
          name={propsI?.columnName || propsI?.ideaCustomFieldId}
        />
        {reactNewApp
          ? propsI?.options &&
            propsI?.options?.map((data: any, index: number) => {
              const isChecked = verifyChecked(data.ideaCustomFieldOptionId);

              return (
                <FormLabel className="check-container" key={index}>
                  <Checkbox
                    color="primary"
                    value={data.ideaCustomFieldOptionId}
                    onChange={handleCheckboxClick}
                    checked={isChecked}
                  />
                  {data?.title || data?.cultures?.[0]?.title}
                </FormLabel>
              );
            })
          : propsI?.options &&
            optionsValues?.map((data, index) => {
              const isChecked = verifyChecked(reactNewApp ? data.ideaCustomFieldOptionId : optionsKeys[index]);

              return (
                <FormLabel className="check-container" key={index}>
                  <Checkbox
                    color="primary"
                    value={reactNewApp ? data.ideaCustomFieldOptionId : optionsKeys[index]}
                    onChange={handleCheckboxClick}
                    checked={isChecked}
                  />
                  {reactNewApp ? data?.title || data?.cultures?.[0]?.title : data}
                </FormLabel>
              );
            })}
      </Box>
    </Box>
  );
}

export default Checkmark;
