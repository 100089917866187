import React from "react";
import "./MyActivitiesLikesList.css";
import MyActivitiesLikes from "../../components/MyActivitiesLikes/MyActivitiesLikes";
import { IMyActivitiesLikes } from "redux/types/myActivities";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Hidden,
  makeStyles,
  createStyles,
  Theme,
  TableBody,
  Typography,
} from "@material-ui/core";
import Resource from "../../components/Resources/Resource";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: "20px 0px",
      [theme.breakpoints.down("sm")]: {
        margin: "0px",
      },
    },
    tableTitles: {
      "margin-top": "5px",
      "margin-bottom": "5px",
      "margin-left": "20px",
      padding: "20px 20px 0px 0px",
      color: " #767676",
      "font-weight": 500,
      "font-size": "12px",
      "text-transform": "uppercase",
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "none",
      },
    },
  })
);
interface IProps {
  myActivitiesLikes: IMyActivitiesLikes;
}

function MyActivitiesLikesList(props: IProps) {
  const classes = useStyles();
  const { myActivitiesLikes } = props;

  return (
    <>
      <Grid container>
        {!myActivitiesLikes?.meta?.total ? (
          <>
            <div className="MyActivity-error" style={{ textAlign: "center" }}>
              <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
            </div>
          </>
        ) : (
          <>
            <Table
              // aria-label="simple table"
              className={classes.table}
            >
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.tableTitles}
                    variant="head"
                    align="left"
                  >
                    <Hidden implementation="css" smDown>
                      <Typography>
                        <Resource tag="PageMyActivity::IdeaLiked" />
                      </Typography>
                    </Hidden>
                  </TableCell>
                  <TableCell
                    className={classes.tableTitles}
                    variant="head"
                    align="left"
                  >
                    <Hidden implementation="css" smDown>
                      <Typography>
                        <Resource tag="PageMyActivity::Menu_Challenges" />
                      </Typography>
                    </Hidden>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myActivitiesLikes?.result?.map((activitiesLikes, index) => (
                  <MyActivitiesLikes key={index} resultLike={activitiesLikes} />
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </Grid>
    </>
  );
}

export default MyActivitiesLikesList;
