import React from 'react'
import {
  Chart,
  PieSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { Palette, Animation } from '@devexpress/dx-react-chart';
import './WalletPGraph.css';
import { IWallet } from '../../redux/reducers/walletReducer';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import Resource, { resource } from '../Resources/Resource';
import ToolTip from '../ToolTip/ToolTip';


interface IProps {
  wallet: IWallet
  cultureTag: string
  culture: any
  totalCurrentValue?: number
  allowToSeeInvestments:boolean
}

function WalletPGraph(props: IProps) {

  let data
  const { wallet, cultureTag, totalCurrentValue, allowToSeeInvestments } = props
  const walletTotal = wallet?.totalAvailable + totalCurrentValue!
  data = [
    { argument: 1, total: wallet?.totalAvailable },
    { argument: 2, total: totalCurrentValue! },
  ];
  const theme = useTheme()
  const tooltipGraph = resource(props.culture, "PageWallet::ToolTipPointsText").split('\\n')
  return (
    <Box>
      <Grid container spacing={2}>

        <Grid item xs={12} sm={12} md={12} lg={12} className="pizza-graph">
          {
            allowToSeeInvestments &&
            <Box mr={8} >
              <Grid item className="center-text">
                <Typography variant="body2" align="center">
                  {walletTotal ? walletTotal.toLocaleString(cultureTag) : 0}
                </Typography>
                <Typography variant="h6" align="center">
                  <Resource tag={"PageWallet::Points_Total"} args={['']} />
                </Typography>
              </Grid>
              <Chart
                data={wallet ? data : [
                  { argument: 1, total: 0 },
                  { argument: 2, total: 0 },
                ]}
                width={250}
                height={250}
              >
                <Palette scheme={[theme.palette.primary.main, theme.palette.text.primary]} /> 
                <PieSeries
                  valueField="total"
                  argumentField="argument"
                  innerRadius={0.7}
                />
                <Animation duration={1000} />
              </Chart>
            </Box>
          }
          <Grid item xs={4} className="info-graph" style={{marginTop:!allowToSeeInvestments ? 32 : 0}}>
            <Grid item xs={12} style={{ display: 'flex', position: 'relative', paddingBottom: '12px', marginTop: "-16px" }}>
              <Typography variant="body1" style={{ display: 'flex', position: 'relative', fontWeight: 'bold' }}>
                <Resource tag={"PageWallet::Points_AvailableLabel"} />
              </Typography>
              <Grid item className="tooltip-AvailableLabel">
                <ToolTip title={<Resource tag={"PageWallet::ToolTipPointsTitle"} args={['']} />}>
                  <span>{tooltipGraph[0]}<br></br>{tooltipGraph[1]}</span>
                </ToolTip>
              </Grid>
            </Grid>
            <Typography style={{color: theme.palette.primary.main}} variant="h2" display="inline">
              {wallet ? wallet?.totalAvailable.toLocaleString(cultureTag) : 0}
            </Typography>
            <Typography variant="body1" display="inline" style={{ fontWeight: 500, textTransform: 'lowercase' }}>
              <Resource tag={"PageWallet::Points_Invested"} args={['']} />
            </Typography>
          </Grid>

        </Grid>

      </Grid>
    </Box>
  )
}

export default WalletPGraph;