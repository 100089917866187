import React from 'react'
import { Grid } from '@material-ui/core';
import DynamicGrid from '../DynamicGrid/DynamicGrid';
import CardIdeaCollaborative from '../CardIdeaCollaborative/CardIdeaCollaborative';
import { ICultureState } from 'redux/reducers/cultureReducer';
import { IAllIdeasState } from 'redux/reducers/ideas/AllIdeasReducer';
interface IProps {
  ideaList:any;
  postInvestments:(options:object)=> Promise<any>;
  applicationId:string;
  culture:ICultureState;
  postLikes:(options:object) => Promise<any>,
  deleteLikes:(options:object) => Promise<any>,
  getAllIdeas: (options: object) => Promise<IAllIdeasState>,
}

const rowsTypeMapping = [
    {
        cardComponent:RowType1,
        cardsQuantity:2,
        imageCardQuantity:0
    },
    {
        cardComponent:RowType2,
        cardsQuantity:3,
        imageCardQuantity:1
    }
]

function RowType1(props:IProps){
    const possibleWidth=[5,6,7]
    const gridWIdth = possibleWidth[Math.floor(Math.random() * possibleWidth.length)];
    const { postInvestments, applicationId, culture, postLikes, deleteLikes, getAllIdeas } = props;

    return(
      <>
      <Grid item xs={12} container spacing={3}>
          <Grid item xs container spacing={3} style={{maxWidth:'105%'}}>
            <DynamicGrid size={gridWIdth}>
              <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} idea={props.ideaList[0]} width={gridWIdth}/>
            </DynamicGrid>
            <DynamicGrid size={12 - gridWIdth}>
              <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} idea={props.ideaList[1]} width={gridWIdth}/>
            </DynamicGrid>
          </Grid>
        </Grid>
      </>
    )
}

  function RowType2(props:IProps){
    const possibleWidth=[5,7]
    const gridWIdth = possibleWidth[Math.floor(Math.random() * possibleWidth.length)];
    const {  postInvestments, applicationId, culture, postLikes, deleteLikes, getAllIdeas } = props;

    return(
      <Grid item xs={12} container spacing={3}>
          <Grid item xs container spacing={3} style={{maxWidth:'105%'}}>
            <DynamicGrid size={gridWIdth} >
              {gridWIdth === 5 &&
                <Grid item xs container direction="column" spacing={1} style={{margin:0}}>
                  <Grid item xs={12} style={{padding:0, paddingBottom:'24px'}}>
                    <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} idea={props.ideaList[0]} width={gridWIdth}/>
                  </Grid>
                  <Grid item xs={12} style={{padding:0}}>
                    <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments}  idea={props.ideaList[1]} width={gridWIdth}/>
                  </Grid>
                </Grid>
              }
              {gridWIdth === 7 &&
                <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments}  idea={props.ideaList[0]} width={gridWIdth} hasImage={true}/>
              }
            </DynamicGrid>
            <DynamicGrid size={12-gridWIdth}>
              {12-gridWIdth === 5 &&
                <Grid item xs container direction="column" spacing={1} style={{margin:0}}>
                  <Grid item xs={12} style={{padding:0, paddingBottom:'24px'}}>
                    <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} idea={props.ideaList[1]} width={12-gridWIdth}/>
                  </Grid>
                  <Grid item xs={12} style={{padding:0}}>
                    <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} idea={props.ideaList[2]} width={12-gridWIdth}/>
                  </Grid>
                </Grid>
              }
              {12-gridWIdth === 7 &&
                <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} idea={props.ideaList[2]} width={gridWIdth} hasImage={true}/>
              }
            </DynamicGrid>
          </Grid>
        </Grid>
    )
}
function RowType3(props:IProps){
  const possibleWidth=[5,6,7]
  const gridWIdth = possibleWidth[Math.floor(Math.random() * possibleWidth.length)];
  const { postInvestments, applicationId, culture, postLikes, deleteLikes, getAllIdeas } = props;

  return(
    <>
    <Grid item xs={12} container spacing={3}>
        <Grid item xs container spacing={3} >
          <DynamicGrid size={gridWIdth}>
            <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} idea={props.ideaList[0]} width={gridWIdth}/>
          </DynamicGrid>
        </Grid>
      </Grid>
    </>
  )
}

function returnAleatoryRow(array:any) {
    var auxArray = array.slice()
    var currentIndex = auxArray.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = auxArray[currentIndex];
        auxArray[currentIndex] = auxArray[randomIndex];
        auxArray[randomIndex] = temporaryValue;
    }

    return auxArray;
}

function MapIdeasToRows(props:any){
    let auxIdeasList = props?.ideas?.slice();
    let mapIdeasRows = []
    const {postInvestments, applicationId , culture, postLikes, deleteLikes, getAllIdeas} = props;

    for (var i = 0; i < (props.ideas?.length); i++) {
        if(auxIdeasList.length === 0){
            break;
        }
        else if(auxIdeasList.length === 1){
          let ideaList = []
          ideaList.push(auxIdeasList[0])
          auxIdeasList.splice(0,1)
          mapIdeasRows.push({
              component:RowType3,
              ideaList:ideaList,
          })
        }else if(auxIdeasList.length === 2){
            let ideaList = []
            for (var j = 0; j < 2; j++) {
                ideaList.push(auxIdeasList[0])
                auxIdeasList.splice(0,1)
            }
            mapIdeasRows.push({
                component:rowsTypeMapping[0].cardComponent,
                ideaList:ideaList,
            })
        }
        else if(auxIdeasList.length === 3){
            let ideaList = []
            for (j = 0; j < 3; j++) {
                ideaList.push(auxIdeasList[0])
                auxIdeasList.splice(0,1)
            }
            mapIdeasRows.push({
                component:rowsTypeMapping[1].cardComponent,
                ideaList:ideaList,
            })
        }else if(auxIdeasList.length === 4){
            let ideaList = []
            for(var k = 0; k < 2; k++){
                for (j = 0; j < 2; j++) {
                    ideaList.push(auxIdeasList[0])
                    auxIdeasList.splice(0,1)
                }
                mapIdeasRows.push({
                    component:rowsTypeMapping[0].cardComponent,
                    ideaList:ideaList,
                })
                ideaList = []
            }
        }else{
            let randomRow = returnAleatoryRow(rowsTypeMapping)[0]
            if(auxIdeasList.length >= randomRow?.cardsQuantity){
                let ideaList = []
                for (j = 0; j < randomRow?.cardsQuantity; j++) {
                    ideaList.push(auxIdeasList[0])
                    auxIdeasList.splice(0,1)
                }
                mapIdeasRows.push({
                    component:randomRow?.cardComponent,
                    ideaList:ideaList,
                })
            }
        }
    }

    // mapIdeasRows = returnAleatoryRow(mapIdeasRows)
    return(
        <>
        {mapIdeasRows.map((row:any, index:any)=>{
            const RowMounted = row.component;
            return(
              <RowMounted getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} ideaList={row.ideaList} key={index} />
            )
        })}
        </>
    )
}

function AleatoryIdeaRows(props:IProps){
    const totalIdeasQuantity = props?.ideaList?.meta?.total;
    const pageIdeasQuantity = props?.ideaList?.meta?.perPage;
    const cardQuantity = totalIdeasQuantity > pageIdeasQuantity ? pageIdeasQuantity : totalIdeasQuantity;
    const {postInvestments, applicationId, culture, postLikes, deleteLikes, getAllIdeas} = props;

    return(
        <>
            <MapIdeasToRows getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} ideas={props?.ideaList?.result} cardQuantity={cardQuantity}/>
        </>
    )      
}

export default AleatoryIdeaRows