// Import Reducer type
import { Reducer } from "redux";
import { IManageUsers, IPermissions, IUserDetail, ManageUsersActions, ManageUsersActionTypes } from "redux/types/manageUsers";
import { IManageUsersState } from "redux/types/manageUsers";

// Define the Character State


// Define the initial state
const initialCharacterState: IManageUsersState = {
  users: {} as IManageUsers,
  userDetails: {} as IUserDetail,
  permissions: {} as IPermissions,
  permissionStatus: 'SUCCESS',
  userDetailStatus: 'SUCCESS',
  activateStatus: 'SUCCESS',
  userStatus: 'SUCCESS',
  inviteStatus: 'SUCCESS',
  deleteInviteStatus: 'SUCCESS',
  scheduleStatus: 'SUCCESS',
  rescheduleStatus: 'SUCCESS',
  reinviteStatus: 'SUCCESS',
  putPermissionStatus: 'SUCCESS',
  importUserStatus: 'SUCCESS',
  givePointsStatus: 'SUCCESS',
  removePointsStatus: 'SUCCESS',
  resetPointsStatus: 'SUCCESS',
  tokenStatus: 'SUCCESS'
};

export const manageUsersReducer: Reducer<
  IManageUsersState,
  ManageUsersActions
> = (state = initialCharacterState, action) => {
  switch (action.type) {
    case ManageUsersActionTypes.GET_USERS_LOAD: {
      return {
        ...state,
        userStatus: action.status
      }
    }
    case ManageUsersActionTypes.GET_USERS_SUCCESS: {
      return action?.isInfinite
      ? {
          ...state,
          userStatus: action.status,
          users: {
            meta: action.users.meta,
            result: [
              ...state.users.result,
              ...action.users.result,
            ]
          },
        }
      : {
          ...state,
          userStatus: action.status,
          users: action.users
        }
    }
    case ManageUsersActionTypes.GET_USERS_FAILED: {
      return {
        ...state,
        userStatus: action.status
      }
    }
    case ManageUsersActionTypes.GET_USERSDETAIL_LOAD: {
      return {
        ...state,
        userDetailStatus: action.status
      }
    }
    case ManageUsersActionTypes.GET_USERSDETAIL_SUCCESS: {
      return {
        ...state,
        userDetails: action.userDetails,
        userDetailStatus: action.status
      }
    }
    case ManageUsersActionTypes.GET_USERSDETAIL_FAILED: {
      return {
        ...state,
        userDetailStatus: action.status
      }
    }
    case ManageUsersActionTypes.GET_PERMISSIONS_LOAD: {
      return {
        ...state,
        permissionStatus: action.status
      }
    }
    case ManageUsersActionTypes.GET_PERMISSIONS_SUCCESS: {
      return action?.isInfinite
      ? {
          ...state,
          permissionStatus: action.status,
          permissions: {
            meta: action.permissions.meta,
            result: [
              ...state.permissions.result,
              ...action.permissions.result,
            ]
          },
        }
      : {
          ...state,
          permissionStatus: action.status,
          permissions: action.permissions
        }
    }
    case ManageUsersActionTypes.GET_PERMISSIONS_FAILED: {
      return {
        ...state,
        permissionStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_ACTIVATE_LOAD: {
      return {
        ...state,
        activateStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_ACTIVATE_SUCCESS: {
      return {
        ...state,
        activateStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_ACTIVATE_FAILED: {
      return {
        ...state,
        activateStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_DEACTIVATE_LOAD: {
      return {
        ...state,
        activateStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_DEACTIVATE_SUCCESS: {
      return {
        ...state,
        activateStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_DEACTIVATE_FAILED: {
      return {
        ...state,
        activateStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_INVITE_LOAD: {
      return {
        ...state,
        inviteStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_INVITE_SUCCESS: {
      return {
        ...state,
        inviteStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_INVITE_FAILED: {
      return {
        ...state,
        inviteStatus: action.status
      }
    }
    case ManageUsersActionTypes.DELETE_INVITE_LOAD: {
      return {
        ...state,
        deleteInviteStatus: action.status
      }
    }
    case ManageUsersActionTypes.DELETE_INVITE_SUCCESS: {
      return {
        ...state,
        deleteInviteStatus: action.status
      }
    }
    case ManageUsersActionTypes.DELETE_INVITE_FAILED: {
      return {
        ...state,
        deleteInviteStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_SCHEDULE_LOAD: {
      return {
        ...state,
        scheduleStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_SCHEDULE_SUCCESS: {
      return {
        ...state,
        scheduleStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_SCHEDULE_FAILED: {
      return {
        ...state,
        scheduleStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_RESCHEDULE_LOAD: {
      return {
        ...state,
        rescheduleStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_RESCHEDULE_SUCCESS: {
      return {
        ...state,
        rescheduleStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_RESCHEDULE_FAILED: {
      return {
        ...state,
        rescheduleStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_REINVITE_LOAD: {
      return {
        ...state,
        reinviteStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_REINVITE_SUCCESS: {
      return {
        ...state,
        reinviteStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_REINVITE_FAILED: {
      return {
        ...state,
        reinviteStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_PERMISSION_LOAD: {
      return {
        ...state,
        putPermissionStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_PERMISSION_SUCCESS: {
      return {
        ...state,
        putPermissionStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_PERMISSION_FAILED: {
      return {
        ...state,
        putPermissionStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_IMPORT_USERS_LOAD: {
      return {
        ...state,
        importUserStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_IMPORT_USERS_SUCCESS: {
      return {
        ...state,
        importUserStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_IMPORT_USERS_FAILED: {
      return {
        ...state,
        importUserStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_WALLET_POINTS_GIVE_LOAD: {
      return {
        ...state,
        givePointsStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_WALLET_POINTS_GIVE_SUCCESS: {
      const resultArr = state.users.result
      return {
        ...state,
        givePointsStatus: action.status,
        users: {
          ...state.users,
          result: 
            resultArr.map(data => {
              if(action.usersId.includes(data.userId)){
                return {
                  ...data,
                  availablePoints: data.availablePoints + action.points
                }
              }
              else {
                return data
              }
            })
        }
      }
    }
    case ManageUsersActionTypes.PUT_WALLET_POINTS_GIVE_FAILED: {

      return {
        ...state,
        givePointsStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_WALLET_POINTS_RESET_LOAD: {
      return {
        ...state,
        resetPointsStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_WALLET_POINTS_RESET_SUCCESS: {
      const resultArr = state.users.result
      return {
        ...state,
        users: {
          ...state.users,
          result: 
            resultArr.map(data => {
              if(action.usersId.includes(data.userId)){
                return {
                  ...data,
                  availablePoints: 5000
                }
              }
              else {
                return data
              }
            })
        },
        resetPointsStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_WALLET_POINTS_RESET_FAILED: {
      return {
        ...state,
        resetPointsStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_WALLET_POINTS_REMOVE_LOAD: {
      return {
        ...state,
        removePointsStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_WALLET_POINTS_REMOVE_SUCCESS: {
      const resultArr = state.users.result
      return {
        ...state,
        users: {
          ...state.users,
          result: 
            resultArr.map(data => {
              if(action.usersId.includes(data.userId)){
                return {
                  ...data,
                  availablePoints: data.availablePoints - action.points
                }
              }
              else {
                return data
              }
            })
        },
        removePointsStatus: action.status
      }
    }
    case ManageUsersActionTypes.PUT_WALLET_POINTS_REMOVE_FAILED: {
      return {
        ...state,
        removePointsStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_TOKEN_LOAD: {
      return {
        ...state,
        tokenStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_TOKEN_SUCCESS: {
      return {
        ...state,
        tokenStatus: action.status
      }
    }
    case ManageUsersActionTypes.POST_TOKEN_FAILED: {
      return {
        ...state,
        tokenStatus: action.status
      }
    }
    default:
      return state;
  }
};
