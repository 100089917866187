import React, { useEffect, useState } from 'react';

// Redux
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import ChallengeList from 'containers/ChallengeList/ChallengeList';
import { ThunkDispatch } from 'redux-thunk';
import {
  getAllChallenges,
  getAllChallengesPermanent,
  getAllChallengesInProgress,
  getAllChallengesClosed,
  getActiveChallenges,
  getHighlightedChallenges,
  getChallengeDraftsAction,
  ChallengeStatusTypes,
} from 'redux/actions/ChallengeActions';
import { IAppState } from 'redux/store/Store';
import { IApplication } from 'redux/reducers/tenantReducer';
import { 
  IChallengesPermanent, 
  IChallengesInProgress, 
  IChallengesClosed, 
  IChallengeActive, 
  IChallengeHighlighted, 
  ChallengeDrafts
} from "redux/types/challenges"
import { IChallengeState } from 'redux/reducers/challenges/challengeReducer';
import { IProfile } from 'redux/reducers/profileReducer';
import { IUsers } from 'redux/reducers/usersReducers';
import { ICultureState } from 'redux/reducers/cultureReducer';

// Style
import { Box } from '@material-ui/core';
import ChallengesPageSkeleton from './ChallengesPage.Skeleton';
import newApp from 'utils/reactNewApp';

const pagination = {
  current: 1,
  pageSize: 10,
  finished: false
};

const status = {
  init: true,
  refreshControl: false,
  isActive: true
};

const options = {
  applicationId: 2,
  isActive: status.isActive,
  pageNumber: status.init || !status.isActive ? 1 : pagination.current + 1,
  pageSize: pagination.pageSize,
  culture: {}
};
interface IDispatchProps {
  getChallenges: (options: object) => Promise<IChallengeState>;
  getAllChallengesPermanent: (options: object) => Promise<IChallengeState>;
  getAllChallengesInProgress: (options: object) => Promise<IChallengeState>;
  getAllChallengesClosed: (options: object) => Promise<IChallengeState>;
  getActiveChallenges: (options: object) => Promise<IChallengeState>;
  getHighlightedChallenges: (options: object) => Promise<IChallengeState>;
  getChallengesDraft: (options: object) => Promise<IChallengeState>;
}

interface IStateProps {
  challengesPermanent: IChallengesPermanent
  challengesInProgress: IChallengesInProgress
  challengesClosed: IChallengesClosed
  activeChallenges: IChallengeActive;
  highlightedChallenges: IChallengeHighlighted
  application: IApplication
  profile: IProfile[]
  users: IUsers[]
  culture: ICultureState
  challengeDrafts: ChallengeDrafts;
  permission: string;
  workspaceId: string;
  status: string;
  draftStatus: string;
}

function Challenge(props: IDispatchProps & IStateProps) {

  const [loading, setLoading] = useState(true);
  const {  
    getChallenges,
    getActiveChallenges,
    getAllChallengesInProgress,
    getAllChallengesPermanent,
    getAllChallengesClosed,
    challengesPermanent, 
    challengesInProgress, 
    challengesClosed,
    activeChallenges,
    highlightedChallenges,
    getChallengesDraft,
    challengeDrafts,
    permission,
    culture,
    workspaceId,
    draftStatus
  } = props;
  const { cultureTag } = culture?.culture ?? ""

  useEffect(() => {
    let isMounted = true
    options.applicationId = props.application.applicationId;
    options.culture = props.culture;

    const draftOptions ={
      pageSize:6,
      pageNumber:1,
      workspaceId,
      cultureTag,
      isInfinite: false
    }

    newApp ?
      isMounted && Promise.all([
        getChallenges(options),
        getChallengesDraft(draftOptions)
      ]).then(() => {
        isMounted && setLoading(false);
      })
    :
      isMounted && Promise.all([
        getChallenges(options),
      ]).then(() => {
        isMounted && setLoading(false);
      })

    return () => {isMounted = false}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDrafts = (pageNumber:number, pageSize:number) => {
    const draftOptions ={
      pageSize,
      pageNumber,
      workspaceId,
      cultureTag,
      isInfinite: true
    }
    getChallengesDraft(draftOptions)
  }

  return (
    <Box className="challenge" marginBottom={6} style={{ textAlign: 'center' }} >
      {loading || props.status === ChallengeStatusTypes.CHALLENGE_LOADING ?
      <ChallengesPageSkeleton />
      :
        <ChallengeList
          culture={culture}
          permission={permission}
          activeChallenges={activeChallenges}
          getChallengeClosed={getAllChallengesClosed}
          getChallengePermanent={getAllChallengesPermanent}
          getChallengeInProgress={getAllChallengesInProgress}
          getChallengeActive={getActiveChallenges}
          highlightedChallenges={highlightedChallenges}
          ChallengePermanent={challengesPermanent}
          ChallengeInProgress={challengesInProgress}
          challengeDrafts={challengeDrafts}
          draftStatus={draftStatus}
          workspaceId={workspaceId}
          challengeStatus={props.status}
          ChallengeClosed={challengesClosed}
          ChallengeActive={activeChallenges}
        />
      }
    </Box>
  );
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => {
  return {
    getChallenges: (parameters: object) => dispatch(getAllChallenges(parameters)),
    getAllChallengesPermanent: (parameters: object) => dispatch(getAllChallengesPermanent(parameters)),
    getAllChallengesInProgress: (parameters: object) => dispatch(getAllChallengesInProgress(parameters)),
    getAllChallengesClosed: (parameters: object) => dispatch(getAllChallengesClosed(parameters)),
    getActiveChallenges: (parameters: object) => dispatch(getActiveChallenges(parameters)),
    getHighlightedChallenges: (parameters: object) => dispatch(getHighlightedChallenges(parameters)),
    getChallengesDraft: (parameters: object) => dispatch(getChallengeDraftsAction(parameters)),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    application: store.tenantState.application,
    profile: store.profileState.profile,
    users: store.usersState.users,
    culture: store.cultureState,
    challengesPermanent: store.challengeState.challengesPermanent,
    challengesInProgress: store.challengeState.challengesInProgress,
    challengesClosed: store.challengeState.challengesClosed,
    activeChallenges: store.challengeState.activeChallenges,
    highlightedChallenges: store.challengeState.highlightedChallenges,
    permission: store.authState.auth.permission,
    challengeDrafts: store.challengeState.challengeDraft,
    workspaceId: store.authState.auth.workspaceId,
    status: store.challengeState.status,
    draftStatus: store.challengeState.draftStatus
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Challenge);