import React from 'react';
import { IResultMyActivitiesStars } from '../../redux/types/myActivities';
import EMIcon from 'components/Icon/Icon';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Box, Hidden, TableCell, TableRow, Typography } from '@material-ui/core';
import Resource from '../../components/Resources/Resource';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      borderBottom: 'solid 1px #ccc',
    },
    tableCell: {
      padding: '8px 10px 24px 0px!important',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100px',
      }
    },
    lastCell: {
      padding: '48px 10px 32px 0px!important',
      maxWidth: '250px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '50px',
      }
    },
    collaborativeCell: {
      padding: '16px 10px 8px 0px!important',
    },
    link: {
      'textDecoration': 'none',
      'color': '#333',
      'display': '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      'overflow': "hidden",
      'text-overflow': "ellipsis",
    },
    truncate: {
      'display': '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      'overflow': "hidden",
      'text-overflow': "ellipsis",
    },
    date: {
      marginBottom: '8px',
      textTransform: 'lowercase',
      '&:first-line': {
        textTransform: 'capitalize',
      },
    }
  }),
);
/* Example:
  <MyActivityEvaluationItem  /> 
*/

interface IProps {
  starResult: IResultMyActivitiesStars;
  type: string;
}

function MyActivityEvaluationItem(props: IProps) {
  const { starResult, type } = props
  const ideaBackURL = "/idea/details";
  const classes = useStyles()
  
  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        <Hidden implementation="css" smDown>
          <Typography className={classes.date} variant="body2">{starResult?.date}</Typography>
        </Hidden>
        <Box display="flex" gridGap={10}>
          <Hidden implementation="css" mdUp>
            <EMIcon icon="comments-c" size={18} color="#adadad" />
          </Hidden>
          <Box display="flex" flexDirection="column" gridGap={20}>
            <Hidden implementation="css" mdUp>
              <Link className={classes.link} component={RouterLink} to={{
                pathname: `${ideaBackURL}/${starResult?.ideaId}`
              }}>
                {starResult?.ideaTitle}
              </Link>
              <Typography className={classes.truncate} variant="body1">{starResult?.ideaComment}</Typography>
              <Hidden implementation="css" mdUp><Typography className={classes.date} variant="body2">{starResult?.date}</Typography></Hidden>
            </Hidden>
            <Hidden implementation="css" smDown>
              <Box display="flex" alignItems="center" gridGap={10}>
                <Typography variant="body1">
                  {starResult?.average.toFixed(1)}
                </Typography>
                <EMIcon icon="star-b" color="#686868" size={16} />
              </Box>
            </Hidden>
          </Box>
        </Box>
      </TableCell>
      <TableCell className={type === 'private' ? classes.tableCell : classes.collaborativeCell}>
        {type === 'private' ?
          <Hidden implementation="css" smDown>
            <Box display="flex" alignItems="center" gridGap={10} marginTop={3}>
              {starResult?.ideaComment === null ? '' : <EMIcon icon="comments-c" size={18} color="#adadad" /> }
              <Typography variant="body1">{starResult?.ideaComment}</Typography>
            </Box>
          </Hidden>
          :
          <Hidden implementation="css" smDown>
            <Link className={classes.link} component={RouterLink} to={{
              pathname: `${ideaBackURL}/${starResult?.ideaId}`
            }}>
              {starResult?.ideaTitle}
            </Link>
          </Hidden>
        }
      </TableCell>
      <TableCell className={type === 'private' ? classes.lastCell : classes.collaborativeCell}>
        {type === 'private' ?
          <>
            <Hidden implementation="css" smDown>
              <Box>
                <Link className={classes.link} component={RouterLink} to={{
                  pathname: `${ideaBackURL}/${starResult?.ideaId}`
                }}>
                  {starResult?.ideaTitle}
                </Link>
                <Typography className={classes.truncate} color="textSecondary" noWrap variant="caption">
                  <Resource tag="PageEditIdea::Challenge" />&nbsp;{starResult?.challenge}
                </Typography>
              </Box>
            </Hidden>
          </>
          :
          <Hidden implementation="css" smDown>
            <Typography className={classes.truncate} color="textSecondary" noWrap variant="caption">
              {starResult?.challenge}
            </Typography>
          </Hidden>
        }
          <Hidden implementation="css" mdUp>
            <Box display="flex" alignItems="center" justifyContent="flex-end" marginBottom={3} gridGap={10}>
              <Typography variant="body1">
                {starResult?.average.toFixed(1)}
              </Typography>
              <EMIcon icon="star-b" color="#686868" size={16} />
            </Box>
          </Hidden>
      </TableCell>
    </TableRow>
  )
}

export default MyActivityEvaluationItem