import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { resource } from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import {
  Avatar,
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";

interface IProps {
  options: any[];
  optionName: string;
  optionSubtitle?: string;
  optionAvatar?: string;
  autoCompleteValue: any;
  onChange: (newValue: any) => any;
  culture: ICultureState;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      [theme.breakpoints.between("xs", "sm")]: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    },
    optionName: {
      fontWeight: "bold",
      lineHeight: "0.8rem",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      fontSize: "0.8rem",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "0.7rem",
      },
    },
    optionSubtitle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "0.7rem",
      },
    },
    addBtn: {
      width: 25,
      height: 25,
      [theme.breakpoints.only("xs")]: {
        width: 0,
        height: 0,
        padding: 10,
      },
      [theme.breakpoints.only("sm")]: {
        width: 0,
        height: 0,
        padding: 10,
      },
      borderRadius: 5,
      boxShadow: "0 0 5px " + theme.palette.grey[400],
      marginTop: 4,
      marginBottom: 4,
      "&:hover": {
        borderRadius: 5,
      },
    },
    optionStyle: {
      padding: 8,
      margin: 0,
      [theme.breakpoints.between("xs", "sm")]: {
        padding: 10,
        margin: 0,
      },
    },
    listbox: {
      "&::-webkit-scrollbar": {
        width: "0.4em",
        height: "0.4em",
        borderRadius: 25,
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
        borderRadius: 25,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: 25,
        border: "0",
      },
    },
  })
);

function XAutocomplete({
  options,
  optionName,
  autoCompleteValue,
  optionAvatar,
  optionSubtitle,
  culture,
  onChange,
  disabled
}: IProps) {
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        listbox: classes.listbox,
        option: classes.optionStyle,
      }}
      options={options}
      value={autoCompleteValue}
      clearOnBlur
      multiple
      disabled={disabled}
      getOptionSelected={(option, value) =>
        option[optionName] === value[optionName]
      }
      noOptionsText={resource(culture, "PageManageGroups::NoOptions")}
      renderTags={() => <></>}
      onChange={(event, newValue) => onChange(newValue)}
      getOptionLabel={(option) => option[optionName]}
      filterOptions={(options, state) =>
        options?.filter(
          (x) =>
            autoCompleteValue?.map((y: any) => y[optionName])?.indexOf(x[optionName]) === -1 &&
            x[optionName]
              .toLowerCase()
              .indexOf(state.inputValue.toLocaleLowerCase()) > -1
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={resource(culture, "PageSettings::SearchForUser")}
          fullWidth
          SelectProps={{
            IconComponent: () => <SearchIcon />,
          }}
        />
      )}
      renderOption={(option) => (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Grid container spacing={2}>
              {optionAvatar && option[optionAvatar] && (
                <Grid item xs={1} sm={2} md={2} xl={2} className="avatar">
                  <Avatar
                    className={classes.thumbnail}
                    src={option[optionAvatar]}
                    alt={option[optionName]}
                  />
                </Grid>
              )}
              <Grid item xs={10} sm={9} md={8} xl={8}>
                <Box className="options-name-subtitle">
                  <Typography className={classes.optionName}>
                    {option[optionName]}
                  </Typography>
                  {optionSubtitle && option[optionSubtitle] && (
                    <Typography
                      variant="caption"
                      className={classes.optionSubtitle}
                      color="textSecondary"
                    >
                      {option[optionSubtitle]}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={1} sm={1} md={2} xl={2} className="btn-add">
                <IconButton disableRipple className={classes.addBtn}>
                  <AddIcon style={{ fontSize: "0.9rem" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    />
  );
}

export default XAutocomplete;
