// Import Reducer type
import { Reducer } from "redux";
import { IAllManageGroups, IManageGroup, IManageGroups, IManageGroupsMembers, IManageGroupsState, ManageGroupsActions, ManageGroupsActionTypes } from "redux/types/manageGroups";

// Define the Character State


// Define the initial state
const initialCharacterState: IManageGroupsState = {
  groups: {} as IManageGroups,
  group: {} as IManageGroup,
  allGroups: [] as IAllManageGroups[],
  groupMembers: {} as IManageGroupsMembers,
  totalMembers: 0,
  manageGroupsStatus: 'SUCCESS',
  allGroupsStatus: 'SUCCESS'
};

export const manageGroupsReducer: Reducer<
  IManageGroupsState,
  ManageGroupsActions
> = (state = initialCharacterState, action) => {

  switch (action.type) {
    case ManageGroupsActionTypes.GET_USERS_GROUPS_LOADING: {
      return {
        ...state,
        manageGroupsStatus: action.manageGroupsStatus,
      };
    }
    case ManageGroupsActionTypes.GET_USERS_GROUPS_SUCCESS: {
      return action?.isInfinite
        ? {
          ...state,
          groups: {
            ...action.groups,
            meta: action.groups.meta,
            result: [...state.groups.result, ...action.groups.result],
          },
          manageGroupsStatus: action.manageGroupsStatus,
        }
        : {
          ...state,
          groups: { ...action.groups },
          manageGroupsStatus: action.manageGroupsStatus,
        };
    }
    case ManageGroupsActionTypes.GET_GROUP_LOADING: {
      return {
        ...state,
        manageGroupsStatus: action.manageGroupsStatus,
      };
    }
    case ManageGroupsActionTypes.GET_GROUP_SUCCESS: {
      return {
        ...state,
        manageGroupsStatus: action.manageGroupsStatus,
        group: action.group
      };
    }
    case ManageGroupsActionTypes.GET_GROUP_FAIL: {
      return {
        ...state,
        manageGroupsStatus: action.manageGroupsStatus,
      };
    }
    case ManageGroupsActionTypes.GET_USERS_GROUPS_FAIL: {
      return {
        ...state,
        manageGroupsStatus: action.manageGroupsStatus,
      };
    }
    case ManageGroupsActionTypes.POST_USERS_GROUPS_LOADING: {
      return {
        ...state,
        manageGroupsStatus: action.manageGroupsStatus,
      };
    }
    case ManageGroupsActionTypes.POST_USERS_GROUPS_SUCCESS: {
      return {
        ...state,
        manageGroupsStatus: action.manageGroupsStatus,
      };
    }
    case ManageGroupsActionTypes.POST_USERS_GROUPS_FAIL: {
      return {
        ...state,
        manageGroupsStatus: action.manageGroupsStatus,
      };
    }
    case ManageGroupsActionTypes.GET_ALL_GROUPS_LOADING: {
      return {
        ...state,
        allGroupsStatus: action.status,
      };
    }
    case ManageGroupsActionTypes.GET_ALL_GROUPS_SUCCESS: {
      return {
        ...state,
        allGroupsStatus: action.status,
        allGroups: action.allGroups
      };
    }
    case ManageGroupsActionTypes.GET_ALL_GROUPS_FAIL: {
      return {
        ...state,
        allGroupsStatus: action.status,
      };
    }
    case ManageGroupsActionTypes.GET_ALL_GROUPS_MEMBERS_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case ManageGroupsActionTypes.GET_ALL_GROUPS_MEMBERS_SUCCESS: {
      return {
        ...state,
        status: action.status,
        groupMembers: action.groupMembers
      };
    }
    case ManageGroupsActionTypes.GET_ALL_GROUPS_MEMBERS_FAIL: {
      return {
        ...state,
        status: action.status,
      };
    }
    case ManageGroupsActionTypes.GET_GROUPS_TOTAL_USERS: {
      return {
        ...state,
        totalMembers: action.payload?.users ?? 0
      }
    }
    default:
      return state;
  }
};
