import React from "react";
import PropTypes from "prop-types";
import IcomoonReact from "icomoon-react";
import iconSet from "../../assets/fonts/icons/selection.json";

type IconProps = {
  color: string;
  icon: string;
  size: number;
  className?: string;
};

const Icon = ({ color, icon, size, className }: IconProps) => {
  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      style={{ verticalAlign: "text-top" }}
    />
  );
};

Icon.propTypes = {
  color: PropTypes?.string,
  icon: PropTypes?.string?.isRequired,
  size: PropTypes?.oneOfType([PropTypes?.string, PropTypes?.number]),
};

Icon.defaultProps = {
  color: "#333",
  size: 16,
};

export default Icon;
