import React from "react";
import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import imagemNotFound from "../../assets/img/empty@3x.png";
import Resource from "components/Resources/Resource";

const useStyles = makeStyles(() =>
  createStyles({
    notFound: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 2.0,
    },
  })
);

function NotFound() {
  const classes = useStyles();

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={2}
        className="no-prizes"
      >
        <Typography variant="h4" className={classes.notFound}>
          <Resource tag={'MessageError::PageNotFound'} />
        </Typography>
        <img src={imagemNotFound} alt="" />
      </Box>
    </>
  );
}

export default NotFound
