import React from "react";
import "./MyActivitiesNotProgressList.css";
import { IMyActivitiesIdeasClosed } from "redux/types/myActivities";
import MyActivitiesNotProgress from "../../components/MyActivitiesNotProgress/MyActivitiesNotProgress";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Hidden,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import Resource from "../../components/Resources/Resource";
import newApp from "utils/reactNewApp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: "20px 0px",
    },
    tableTitles: {
      "margin-top": "5px",
      "margin-bottom": "5px",
      "margin-left": "20px",
      padding: "20px 20px 0px 0px",
      color: theme.palette.text.secondary,
      "font-weight": 500,
      "font-size": "12px",
      "text-transform": "uppercase",
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "none",
      },
    },
  })
);
interface IProps {
  myActivitiesClosed?: IMyActivitiesIdeasClosed;
}

function MyActivitiesNotProgressList(props: IProps) {
  const { myActivitiesClosed } = props;
  const classes = useStyles();

  return (
    <>
      <Table
        // aria-label="simple table"
        className={classes.table}
      >
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell
              className={classes.tableTitles}
              variant="head"
              align="left"
            >
              <Hidden smDown>
                <Resource tag="PageIdea::Idea_ColumnIdeia" />
              </Hidden>
            </TableCell>
            <TableCell
              className={classes.tableTitles}
              variant="head"
              align="center"
            >
              <Hidden smDown>
                <Resource tag="PageIdea::Idea_ColumnSubmissionIdea" />
              </Hidden>
            </TableCell>
            <TableCell
              className={classes.tableTitles}
              variant="head"
              align="center"
            >
              <Hidden smDown>
                <Resource tag="PageIdea::Idea_ColumnLastEvaluation" />
              </Hidden>
            </TableCell>
            <TableCell
              className={classes.tableTitles}
              variant="head"
              align="center"
            >
              <Hidden smDown>
              { newApp ? <Resource tag="PageIdea::Idea_ColumnCurrentStatusV3" /> : <Resource tag="PageIdea::Idea_ColumnCurrentStatus" /> }
              </Hidden>
            </TableCell>
          </TableRow>
        </TableHead>
        {myActivitiesClosed?.result?.map((activitiesClosed, index) => (
          <MyActivitiesNotProgress key={index} ideasClosed={activitiesClosed} />
        ))}
      </Table>
    </>
  );
}

export default MyActivitiesNotProgressList;
