import React, { useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Grid,
  AppBar,
  Tab,
  Input,
} from "@material-ui/core";
import Resource, { resource } from "components/Resources/Resource";
import { TabPanel, TabList, TabContext } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { ManageUsersFilter, ManageUsersInput } from "./ManageUsersFilter";
import ManageUsersList from "./ManageActiveUsersList";
import ManageImportedUsersList from "./ManageImportedUsersList";
import ManageDeactivatedUsersList from "./ManageDeactivatedUsersList";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IManageUsers } from "redux/types/manageUsers";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";
import { IAllManageGroups } from "redux/types/manageGroups";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
//import generateArray from "utils/generateArray";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    noSidePadding: {
      padding: "24px 0",
    },
    tabRoot: {
      color: `${theme.palette.primary.main}!important`,
      textTransform: "capitalize",
      padding: "0px 11px!important",
      height: "30px!important",
      minHeight: "30px!important",
      borderBottom: "none",
      borderRadius: "24px",
    },
    tabSelected: {
      backgroundColor: `${theme.palette.primary.light}!important`,
    },
    indicator: {
      display: "none",
    },
  })
);

interface IProps {
  users: IManageUsers;
  culture: ICultureState;
  getUsers: any;
  workspaceId: string;
  activateStatus: string;
  postUserDeactivate: any;
  postUserActivate: any;
  putReschedule: any;
  putReinvite: any;
  deleteInviteUser: any;
  userStatus: string;
  putAssignGroups: any;
  allGroups: IAllManageGroups[];
  departaments: IDepartments[];
  countries: ICountries[];
}
export default function ManageUsersTabs(props: IProps) {
  const {
    culture,
    users,
    getUsers,
    workspaceId,
    postUserDeactivate,
    postUserActivate,
    activateStatus,
    userStatus,
    putReschedule,
    putAssignGroups,
    allGroups,
    putReinvite,
    deleteInviteUser,
    departaments,
    countries,
  } = props;
  const classes = useStyles();
  const [tab, setTab] = useState("1");
  const [value, setValue] = useState("");
  const [lastSearchTerm, setLastSearchTerm] = useState("");
  const [state, setState] = useState({
    groupIds: [""],
    departmentsIds: [""],
    countriesIds: [""],
    ages: [""],
    userStatusInvitation: 0,
  });
  React.useEffect(() => {
    setValue("");
  }, [tab]);
  
  const handleUpdate = () => {
    getUsers({
      workspaceId,
      cultureTag: culture.culture.cultureTag,
      userType: tab,
    });
  };
  const handleChangeTab = (event: any, newValue: string) => {
    getUsers({
      cultureTag: culture.culture.cultureTag,
      workspaceId: workspaceId,
      userType: newValue,
      pageNumber: 1,
      pageSize: 10
    }).then(() => setTab(newValue));
  };
  const filterData = (e: any, type: string) => {
    switch (type) {
      case "group":
        setState((prev) => {
          return {
            ...prev,
            groupIds: [e.target.value],
          };
        });
        return;
      case "departament":
        setState((prev) => {
          return {
            ...prev,
            departmentsIds: [e.target.value],
          };
        });
        return;
      case "country":
        setState((prev) => {
          return {
            ...prev,
            countriesIds: [e.target.value],
          };
        });
        return;
      case "age":
        setState((prev) => {
          return {
            ...prev,
            ages: [e.target.value],
          };
        });
        return;
      case "state":
        setState((prev) => {
          return {
            ...prev,
            userStatusInvitation: Number(e.target.value),
          };
        });
        return;
      default:
        return;
    }
  };

  let keyTimer: any;
  let waitTimeAfterType = 1000;

  const handlerKeyUp = () => {
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      handleSearch();
    }, waitTimeAfterType);
  };

  const handlerKeydDown = () => {
    clearTimeout(keyTimer);
  };

  const handleSearch = () => {
    getUsers({
      cultureTag: culture.culture.cultureTag,
      workspaceId,
      userType: tab,
      pageNumber: lastSearchTerm !== value? 1 : users?.meta?.lastPage ? users?.meta?.lastPage : 1,
      pageSize: 6,
      searchTerm: value,
    });
    setLastSearchTerm(value);
  };

  return (
    <>
      <Box className={classes.root} marginY={3}>
        <TabContext value={tab}>
          <Grid container spacing={2}>
            <Grid container item xs={12} sm={12} md={7} lg={7}>
              <AppBar position="static" elevation={0}>
                <TabList
                  classes={{ indicator: classes.indicator }}
                  onChange={handleChangeTab}
                  variant="standard"
                  scrollButtons="on"
                >
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    label={<Resource tag="PageSettings::Active" />}
                    value="1"
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    label={<Resource tag="PageSettings::Imported" />}
                    value="2"
                  />
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected,
                    }}
                    label={<Resource tag="PageSettings::Deactivated" />}
                    value="3"
                  />
                </TabList>
              </AppBar>
            </Grid>
            <Grid container item xs={12} sm={12} md={5} lg={5}>
              <Input
                fullWidth
                onKeyDown={handlerKeydDown}
                onKeyUp={(e) => handlerKeyUp()}
                onChange={(e) => setValue(e.target.value)}
                value={value}
                endAdornment={<SearchIcon />}
                placeholder={resource(
                  props.culture,
                  "PageSettings::SearchForUser"
                )}
              />
            </Grid>
          </Grid>
          <TabPanel value="1" className={classes.noSidePadding}>
            <ManageUsersFilter
              filter={() => {
                getUsers({
                  groupIds: state.groupIds ?? [""],
                  departmentsIds: state.departmentsIds ?? [""],
                  countriesIds: state.countriesIds ?? [""],
                  ages: state.ages.map((x) => Number(x)),
                  workspaceId: workspaceId,
                  userType: tab,
                  pageNumber: 1,
                  pageSize: 10,
                });
              }}
            >
              <>
                <ManageUsersInput
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  title={resource(props?.culture, "PageSettings::Group")}
                  onChange={(e) => filterData(e, "group")}
                >
                  <>
                    <option value="">
                      {resource(props?.culture, "PageSettings::All")}
                    </option>
                    {allGroups &&
                      allGroups?.map((group, index) => {
                        return (
                          <option
                            key={group.groupId || index}
                            value={group?.groupId}
                          >
                            {group.title}
                          </option>
                        );
                      })}
                  </>
                </ManageUsersInput>
                <ManageUsersInput
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  title={resource(props?.culture, "PageSettings::Departament")}
                  onChange={(e) => filterData(e, "departament")}
                >
                  <>
                    <option value="">
                      {resource(props?.culture, "PageSettings::All")}
                    </option>
                    {departaments &&
                      departaments?.map((departament, index) => {
                        return (
                          <option
                            key={departament.departmentId || index}
                            value={departament.departmentId}
                          >
                            {departament.title}
                          </option>
                        );
                      })}
                  </>
                </ManageUsersInput>
                <ManageUsersInput
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  title={resource(props?.culture, "PageSettings::Country")}
                  onChange={(e) => filterData(e, "country")}
                >
                  <>
                    <option value="">
                      {resource(props?.culture, "PageSettings::All")}
                    </option>
                    {countries &&
                      countries?.map((country, index) => {
                        return (
                          <option
                            key={country.countryId || index}
                            value={country.countryId}
                          >
                            {country.name}
                          </option>
                        );
                      })}
                  </>
                </ManageUsersInput>
              </>
            </ManageUsersFilter>
            <DynamicInfiniteScroll
              dataList={users}
              getAction={(pageNumber, pageSize) =>
                getUsers({
                  pageNumber,
                  pageSize,
                  userType: tab,
                  workspaceId,
                  isInfinite: true,
                })
              }
              pageSize={10}
              isAutomatic
            >
              <ManageUsersList
                allGroups={allGroups}
                putAssignGroups={putAssignGroups}
                userStatus={userStatus}
                postUserDeactivate={postUserDeactivate}
                handleUpdate={handleUpdate}
                workspaceId={workspaceId}
                activateStatus={activateStatus}
                users={users}
                culture={culture}
              />
            </DynamicInfiniteScroll>
          </TabPanel>
          <TabPanel value="2" className={classes.noSidePadding}>
            <ManageUsersFilter
              filter={() => {
                getUsers({
                  workspaceId: workspaceId,
                  userType: tab,
                  pageNumber: 1,
                  pageSize: 10,
                  userStatusInvitation: state.userStatusInvitation,
                });
              }}
            >
              <>
                <ManageUsersInput
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  title={resource(props?.culture, "PageSettings::Status")}
                  onChange={(e) => filterData(e, "state")}
                >
                  <>
                    <option value="">
                      {resource(props?.culture, "PageSettings::All")}
                    </option>
                    <option value="1">
                      {resource(props?.culture, "PageSettings::Scheduled")}
                    </option>
                    <option value="2">
                      {resource(props?.culture, "PageSettings::Invited")}
                    </option>
                  </>
                </ManageUsersInput>
              </>
            </ManageUsersFilter>
            <DynamicInfiniteScroll
              dataList={users}
              getAction={(pageNumber, pageSize) =>
                getUsers({
                  pageNumber,
                  pageSize,
                  userType: tab,
                  workspaceId,
                  isInfinite: true,
                })
              }
              pageSize={10}
              isAutomatic
            >
              <ManageImportedUsersList
                workspaceId={workspaceId}
                putReschedule={putReschedule}
                userStatus={userStatus}
                deleteInviteUser={deleteInviteUser}
                putReinvite={putReinvite}
                users={users}
                culture={culture}
              />
            </DynamicInfiniteScroll>
          </TabPanel>
          <TabPanel value="3" className={classes.noSidePadding}>
            <DynamicInfiniteScroll
              dataList={users}
              getAction={(pageNumber, pageSize) =>
                getUsers({
                  pageNumber,
                  pageSize,
                  userType: tab,
                  workspaceId,
                  isInfinite: true,
                })
              }
              pageSize={10}
              isAutomatic
            >
              <ManageDeactivatedUsersList
                userStatus={userStatus}
                workspaceId={workspaceId}
                postUserActivate={postUserActivate}
                activateStatus={activateStatus}
                users={users}
                culture={culture}
                handleUpdate={handleUpdate}
              />
            </DynamicInfiniteScroll>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
