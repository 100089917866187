import React from "react";
import "./PreviousPrizesDescription.css";
import Resource from "../Resources/Resource";
import { Box, useTheme } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ListWinning from "../ListPrizeUserWinning/ListPrizeUserWinning";
import sanitizeHtml from "sanitize-html";
import { securityTextEditor } from "utils/securityTextEditor";

type Props = {
  result: any;
  activeIndex: number;
  itemIndex: number;
  cultureTag?: string;
  handleClick: any;
};

const PreviousPrizesDescription = (props: Props) => {
  const theme = useTheme();
  const { result, itemIndex, cultureTag, handleClick } = props;
  const value = new Intl.NumberFormat(cultureTag).format(result.value);
  const maximumValue = new Intl.NumberFormat(cultureTag).format(
    result.maximumValue
  );
  let activeIndex = 1;
  if (itemIndex <= 3) {
    activeIndex = itemIndex;
  } else if (itemIndex % 3 != 0) {
    activeIndex = itemIndex % 3;
  } else {
    activeIndex = 3;
  }
  if (window.innerWidth < 1023) {
    activeIndex = 2;
  }

  if (result == null) return <></>;
  else
    return (
      <Box mb={9}>
        <Box
          className={`previous-description previous-description-type-${activeIndex}`}
        >
          <Box
            onClick={() => handleClick(result, itemIndex)}
            className="arrow-icon"
          >
            <KeyboardArrowUpIcon
              style={{
                cursor: "pointer",
              }}
              color="primary"
              fontSize="large"
            />
          </Box>
        </Box>
        <Box
          flex-direction="row"
          style={{ background: theme.palette.common.white }}
          display="grid"
          gridTemplateColumns="65% 35%"
          padding="15px"
          boxShadow="0 2px 2px 0px rgba(0, 0, 0, 0.2)"
          borderRadius="0 0 6px 6px"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box
              textAlign="left"
              fontSize="14px"
              lineHeight="26px"
              fontWeight={400}
              style={{overflowY: 'auto', wordBreak: 'break-word', maxWidth: '100%', maxHeight: '160px'}}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(result?.description, securityTextEditor),
              }}
            />
            <Box mt={8}>
              <Box>
                <Box component="span" style={{ textTransform: "uppercase" }}>
                  <Resource tag={"Common::InitialBid"} />:
                </Box>
                <Box component="span" fontWeight="bold" ml={1}>
                  {value} <Resource tag={"Common::points"} />
                </Box>
              </Box>
              <Box>
                <Box component="span" style={{ textTransform: "uppercase" }}>
                  <Resource tag={"Common::MaximumBid"} />:
                </Box>
                <Box component="span" fontWeight="bold" ml={1}>
                  {maximumValue} <Resource tag={"Common::points"} />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* right side */}
          <Box>
            <ListWinning
              cultureTag={cultureTag}
              winners={result?.winners}
              bids={result?.userBids}
              mode="previous"
              total={result?.winners?.length}
            />
          </Box>
          {/* ./right side */}
        </Box>
      </Box>
    );
};

export default PreviousPrizesDescription;
