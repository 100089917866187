import Truncate from "react-truncate";
import ClearIcon from "@material-ui/icons/Clear";
import ToolTip from "components/ToolTip/ToolTip";
import { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import { IUsers } from "redux/reducers/usersReducers";
import { ICultureState } from "redux/reducers/cultureReducer";
import XAutocomplete from "common/UI/XAutocomplete/XAutocomplete";
import WorkflowValidatorDynamic from "components/Workflow/WorkflowDialogsContent/ValidatorDynamic/ValidatorDynamic"

import {
  Avatar,
  Box,
  Container,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";

import "./Validator.scss"
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import { ValidationPopupState } from "redux/types/createChallenge";

interface IProps {
  users: IUsers[];
  culture: ICultureState;
  fatherState: any;
  state?: ValidationPopupState;
  setFatherState: (newValue: Array<any>) => void;
  mapAddItem?: string[];
  setMapAddItem?: any;
  setRadioType?: any;
  radioType?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    actualManagers: {
      marginRight: "auto",
      marginTop: theme.spacing(2),
      minWidth: "300px",
      width: "100%",
      gap: "24px",
      maxHeight: "150px",
      overflowY: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",

      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    listButton: {
      width: 25,
      height: 25,
      borderRadius: 5,
      boxShadow: "0 0 5px " + theme.palette.grey[400],
      marginTop: 4,
      marginBottom: 4,

      "&:hover": {
        borderRadius: 5,
      },
    },
    formProgressBar: {
      backgroundColor: theme.palette.primary.main,
      display: "inline-block",
      width: "32px",
      height: "5px !important",
      margin: "0 4px 0 0",
      opacity: "0.4",
      borderRadius: "5px",
      "&.active": {
        opacity: 1,
      },
    },
    widthRadioBtn: {
      "& .MuiSvgIcon-root": {
        width: "0.8em",
        height: "0.8em",
      },
    },
    titleRadioGroup: {
      color: theme.palette.text.secondary,
      fontSize: "0.8rem",
      fontWeight: 700,
      paddingBottom: "10px",
      textTransform: "uppercase",
    },
    labelRadioBtn: {
      marginRight: "50px",
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
      },
    },
    labelRadio: {
      "&:before": {
        border: "solid 2px" + theme.palette.primary.main,
      },
      "&:after": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
);


function WorkflowValidator(props: IProps) {
  const classes = useStyles();
  const [autoCompleteValue, setACVAlue] = useState(props?.fatherState);
  const handleDeleteValue = (value: any) => {
    let newArr = autoCompleteValue.filter(( a:any )=>{
      return a.fullName !== value
    });
    setACVAlue(newArr);
  };

  const { radioType, setRadioType } = props;

  //@ts-ignore
  const [checkRadio, setCheckRadio] = useState('');

  useEffect(() => {
    //@ts-ignore
    setCheckRadio(props.radioType);
  }, [props.radioType, setCheckRadio])

  useEffect(() => {
    props?.setFatherState(autoCompleteValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoCompleteValue, props?.setFatherState]);

  const handleCheck = (radio: string): any => {
    setRadioType(radio);
    setCheckRadio(radio);
  }

  return (
    <div>

      <Container>
        <Box>
          <Box display="flex" alignItems="center" mb={1}>
            <Typography
              variant="h4"
              component="span"
              style={{ width: "fit-content", marginRight: 8 }}
            >
              <Resource
                tag={"PageCreateChallenge::Workflow_ResponsibleForValidation"}
              />
            </Typography>
            <ToolTip title={""}>
              <Resource
                tag={"PageCreateChallenge::Workflow_ResponsibleValidationTip"}
              />
            </ToolTip>
          </Box>
          <Typography variant="body2" color="textSecondary">
            <Resource
              tag={"PageCreateChallenge::Workflow_DefineTheUsersValidation"}
            />
          </Typography>

          <div className="radioButton-Validador">
            <div className="item">
              <input
                type="radio"
                id="radio1"
                name="radioValidator"
                value="radio1"
                checked={checkRadio === 'radio1'}
                onChange={() => handleCheck('radio1')}
              />
              <label htmlFor="radio1" className={classes.labelRadio}>
                <Resource
                  tag={"Workflow::StaticValidator"}
                />
              </label>
              <ToolTip title={""}>
                <Resource
                  tag={"Workflow::StaticValidatorTooltip"}
                />
              </ToolTip>

            </div>
            <div className="item">
              <input
                type="radio"
                id="radio2"
                name="radioValidator"
                value="radio2"
                checked={checkRadio === 'radio2'}
                onChange={() => handleCheck('radio2')}
              />
              <label htmlFor="radio2" className={classes.labelRadio}>
                <Resource
                  tag={"Workflow::DynamicValidator"}
                />
              </label>
              <ToolTip title={""}>
                <Resource
                  tag={"Workflow::DynamicValidatorTooltip"}
                />
              </ToolTip>
            </div>
          </div>
        </Box>

        {
          (checkRadio === 'radio1') &&
          <>
            <Box mt={2} width="75%">
              <XAutocomplete
                options={props?.users}
                optionName={"fullName"}
                optionAvatar={"photo"}
                optionSubtitle={"email"}
                autoCompleteValue={autoCompleteValue}
                onChange={(newValue) => setACVAlue(newValue)}
                culture={props?.culture}
              // disabled={!!autoCompleteValue?.length}
              />
            </Box>

            <Box mt={1}>
              <Box className={classes.actualManagers}>
                {autoCompleteValue?.map((value: any, index: number) => {
                  return (
                    <div className="manager-item-containerPopup" key={value.userId}>
                      <Avatar
                        src={value?.photo}
                        alt={value.fullName}
                        className={classes.thumbnail}
                      />
                      <div className="manager-item-label">
                        <Truncate width={120} ellipsis={<span>...</span>}>
                          {value.fullName}
                        </Truncate>
                      </div>
                      <div className="manage-item-cross">
                        <IconButton
                          className="bottom-manage-item-cross"
                          onClick={() => handleDeleteValue(value.fullName)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </Box>
            </Box>

          </>
        }
        {
          (checkRadio === 'radio2' && (
            <>
              <WorkflowValidatorDynamic
                radioType={radioType}
                fatherState={props?.fatherState}
                setFatherState={props?.setFatherState}
                mapAddItem={props?.mapAddItem}
                setMapAddItem={props?.setMapAddItem}
              />
            </>)
          )
        }

      </Container>

    </div>
  );
}


const mapStateToProps = (store: IAppState) => ({
  state: store.createChallengeState.validationPopupState,
});

export default connect(
  mapStateToProps,
  null
)(WorkflowValidator);
