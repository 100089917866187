const maxIdeaCardDescriptionWidth = [
    {
      width:1,
      maxChar:90
    },
    {
      width:2,
      maxChar:90
    },
    {
      width:3,
      maxChar:90
    },
    {
      width:4,
      maxChar:80
    },
    {
      width:5,
      maxChar:60
    },
    {
      width:6,
      maxChar:110
    },
    {
      width:7,
      maxChar:150
    },
    {
      width:8,
      maxChar:90
    },
    {
      width:9,
      maxChar:90
    },
    {
      width:10,
      maxChar:90
    },
    {
      width:11,
      maxChar:90
    },
    {
      width:12,
      maxChar:90
    },
  ]

  function getMaxChar(width:number){
    return maxIdeaCardDescriptionWidth[width-1].maxChar
  };
  export default getMaxChar;