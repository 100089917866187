// Import Reducer type
import { Reducer } from 'redux';
import {
  IdeasActions,
  IdeasActionTypes,
} from 'redux/actions/ideas/IdeaAction';
import { IDrafts, IIdeaDetails, IIdeas, IIdeasComments, IIdeasImplemented, IIdeasInProgress, 
  IIdeasRejected, IIdeasSimilares } from 'redux/types/ideas';

// Define the Character State
export interface IIdeasState {
  readonly ideas: IIdeas;
  readonly ideaDetails: IIdeaDetails;
  readonly ideasPortfolio: IIdeas[];
  readonly ideasImplemented: IIdeasImplemented;
  readonly ideasCollaborative: any;
  readonly ideasInProgress: IIdeasInProgress;
  readonly ideasRejected: IIdeasRejected;
  readonly ideasComments: IIdeasComments[];
  readonly ideasSimilares: IIdeasSimilares;
  readonly drafts: IDrafts;
  readonly statusComment: string;
}

// Define the initial state
const initialCharacterState: IIdeasState = {
  ideas: {} as IIdeas,
  ideaDetails: {} as IIdeaDetails,
  ideasPortfolio: [],
  ideasImplemented: {} as IIdeasImplemented,
  ideasCollaborative: {} as any,
  ideasInProgress: {} as IIdeasInProgress,
  ideasRejected: {} as IIdeasRejected,
  ideasComments: [],
  ideasSimilares: {} as IIdeasSimilares,
  drafts: {} as IDrafts,
  statusComment: ''
}

export const ideasReducer: Reducer<IIdeasState, IdeasActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case IdeasActionTypes.IDEAS: {
      return {
        ...state,
        ideas: action.ideas,
      };
    }
    case IdeasActionTypes.IDEA_DETAILS: {
      return {
        ...state,
        ideaDetails: action.ideaDetails,
      };
    }
    case IdeasActionTypes.PORTFOLIO: {
      return {
        ...state,
        ideasPortfolio: action.ideasPortfolio,
      };
    }
    case IdeasActionTypes.COLLABORATIVE: {
      return {
        ...state,
        ideasCollaborative: action.ideasCollaborative
      };
    }
    case IdeasActionTypes.IDEAS_COMMENTS_LOADING: {
      return {
        ...state,
        statusComment: action.statusComments,
      };
    }
    case IdeasActionTypes.IDEAS_COMMENTS_SUCCESS: {
      return {
        ...state,
        statusComment: action.statusComments,
        ideasComments: action.ideasComments,
      };
    }
    case IdeasActionTypes.IDEAS_COMMENTS_FAILED: {
      return {
        ...state,
        statusComment: action.statusComments,
      };
    }
    case IdeasActionTypes.GETDRAFTS: {
      return {
        ...state,
        drafts: action.drafts,
      };
    }
    case IdeasActionTypes.POSTDRAFT: {
      return {
        ...state
      }
    }
    
    // ALL IDEAS SIMILARES
    case IdeasActionTypes.IDEAS_SIMILARES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case IdeasActionTypes.IDEAS_SIMILARES_SUCCESS: {
      return {
        ...state,
        status: action.status,
        ideasSimilares: action.ideasSimilares,
      };
    }
    case IdeasActionTypes.IDEAS_SIMILARES_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }

    // ALL IDEAS IN PROGRESS
    case IdeasActionTypes.IDEAS_INPROGRESS_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case IdeasActionTypes.IDEAS_INPROGRESS_SUCCESS: {
      return action.isInfinite ? {
        ...state,
        ideasInProgress: {
          ...action.ideasInProgress,
          meta:action.ideasInProgress.meta,
          result: [
            ...state.ideasInProgress.result,
            ...action.ideasInProgress.result
          ]
        },
        status: action.status
      } : {
        ...state,
        ideasInProgress: {...action.ideasInProgress},
        status: action.status
      }
    }
    case IdeasActionTypes.IDEAS_INPROGRESS_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL IDEAS REJECTED
    case IdeasActionTypes.IDEAS_REJECTED_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case IdeasActionTypes.IDEAS_REJECTED_SUCCESS: {
      return action.isInfinite ? {
        ...state,
        ideasRejected: {
          ...action.ideasRejected,
          meta:action.ideasRejected.meta,
          result: [
            ...state.ideasRejected.result,
            ...action.ideasRejected.result
          ]
        },
        status: action.status
      } : {
        ...state,
        ideasRejected: {...action.ideasRejected},
        status: action.status
      }
    }
    case IdeasActionTypes.IDEAS_REJECTED_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL IDEAS IMPLEMENTED
    case IdeasActionTypes.IDEAS_IMPLEMENTED_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case IdeasActionTypes.IDEAS_IMPLEMENTED_SUCCESS: {
      return action.isInfinite ? {
        ...state,
        ideasImplemented: {
          ...action.ideasImplemented,
          meta:action.ideasImplemented.meta,
          result: [
            ...state.ideasImplemented.result,
            ...action.ideasImplemented.result
          ]
        },
        status: action.status
      } : {
        ...state,
        ideasImplemented: {...action.ideasImplemented},
        status: action.status
      }
    }
    case IdeasActionTypes.IDEAS_IMPLEMENTED_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    default:
      return state;
  }
};