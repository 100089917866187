// Import redux types
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { api_general, api_v2 } from "./../../config/api";
import { treatError } from "../../utils/treatError";
import newApp from "utils/reactNewApp";

// Import Character Typing
import { IAlert, IAlertState } from "../reducers/alertReducer";

const basePath = "alerts";

// Create Action Constants
export enum AlertActionTypes {
  GET_ALL_ALERTS_LOADING = "GET_ALL_ALERTS_LOADING",
  GET_ALL_ALERTS_SUCCEEDED = "GET_ALL_ALERTS_SUCCEEDED",
  GET_ALL_ALERTS_FAILED = "POST_ALL_ALERT_FAILED",

  POST_ALERT_LOADING = "POST_ALERT_LOADING",
  POST_ALERT_SUCCEEDED = "POST_ALERT_SUCCEEDED",
  POST_ALERT_FAILED = "POST_ALL_ALERT_FAILED",

  POST_ALL_ALERT_LOADING = "POST_ALL_ALERT_LOADING",
  POST_ALL_ALERT_SUCCEEDED = "POST_ALL_ALERT_SUCCEEDED",
  POST_ALL_ALERT_FAILED = "POST_ALL_ALERT_FAILED",
}

export enum AlertActionStatus {
  GET_ALL_ALERTS_LOADING = "LOADING_PREVIEW",
  GET_ALL_ALERTS_SUCCEEDED = "SUCCEEDED",
  GET_ALL_ALERTS_FAILED = "FAILED",

  POST_ALERT_LOADING = "LOADING_MANAGERS",
  POST_ALERT_SUCCEEDED = "SUCCEEDED",
  POST_ALERT_FAILED = "FAILED",

  POST_ALL_ALERT_LOADING = "CHANGING_MANAGERS",
  POST_ALL_ALERT_SUCCEEDED = "SUCCEEDED",
  POST_ALL_ALERT_FAILED = "FAILED",
}

// Interface for Get All Alert Action Type
export interface IAlertLoadingGetAllAction {
  type: AlertActionTypes.GET_ALL_ALERTS_LOADING;
  status: string;
}
export interface IAlertSucceededGetAllAction {
  type: AlertActionTypes.GET_ALL_ALERTS_SUCCEEDED;
  alerts: IAlert;
  status: string;
}
export interface IAlertFailedGetAllAction {
  type: AlertActionTypes.GET_ALL_ALERTS_FAILED;
  status: string;
  error: any;
}

// Interface for Post Alert Action Type
export interface IAlertLoadingPostAction {
  type: AlertActionTypes.POST_ALERT_LOADING;
  status: string;
}
export interface IAlertSucceededPostAction {
  type: AlertActionTypes.POST_ALERT_SUCCEEDED;
  alerts: IAlert;
  status: string;
}
export interface IAlertFailedPostAction {
  type: AlertActionTypes.POST_ALERT_FAILED;
  status: string;
  error: any;
}

export interface IAlertLoadingPostAllAction {
  type: AlertActionTypes.POST_ALL_ALERT_LOADING;
  status: string;
}
export interface IAlertSucceededPostAllAction {
  type: AlertActionTypes.POST_ALL_ALERT_SUCCEEDED;
  status: string;
}
export interface IAlertFailedPostAllAction {
  type: AlertActionTypes.POST_ALL_ALERT_FAILED;
  status: string;
}
/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type AlertActions =
  | IAlertLoadingGetAllAction
  | IAlertSucceededGetAllAction
  | IAlertFailedGetAllAction
  | IAlertLoadingPostAction
  | IAlertSucceededPostAction
  | IAlertFailedPostAction
  | IAlertLoadingPostAllAction
  | IAlertSucceededPostAllAction
  | IAlertFailedPostAllAction;

/* Get All Alerts
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getAllAlerts: ActionCreator<ThunkAction<
  Promise<any>,
  IAlertState,
  null,
  IAlertSucceededGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: AlertActionStatus.GET_ALL_ALERTS_LOADING,
        type: AlertActionTypes.GET_ALL_ALERTS_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      newApp
        ? await api_general
            .get(`${basePath}${params_qs}`, {})
            .then((response) => {
              dispatch({
                alerts: response.data,
                status: AlertActionStatus.GET_ALL_ALERTS_SUCCEEDED,
                type: AlertActionTypes.GET_ALL_ALERTS_SUCCEEDED,
              });
            })
        : await api_v2.get(`${basePath}${params_qs}`, {}).then((response) => {
            dispatch({
              alerts: response.data,
              status: AlertActionStatus.GET_ALL_ALERTS_SUCCEEDED,
              type: AlertActionTypes.GET_ALL_ALERTS_SUCCEEDED,
            });
          });
    } catch (err) {
      treatError(options?.culture, "PageAlerts::Alerts", err);
    }
  };
};

/* Post Alert
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const postAlerts: ActionCreator<ThunkAction<
  Promise<any>,
  IAlertState,
  null,
  IAlertSucceededPostAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: AlertActionStatus.POST_ALERT_LOADING,
        type: AlertActionTypes.POST_ALERT_LOADING,
      });
      const params = {
        alertId: options.alertId,
      };

      newApp
        ? await api_general
            .post(`${basePath}/${options.alertId}`, params)
            .then(async () => {
              let params = [];
              let params_qs = "";

              params.push(`pageNumber=${options.pageNumber}`);
              params.push(`pageSize=${options.pageSize}`);

              if (params.length) {
                params_qs = `?${params.join("&")}`;
              }

              const response = await api_general.get(
                `${basePath}${params_qs}`,
                {}
              );
              dispatch({
                alerts: response.data,
                status: AlertActionStatus.POST_ALERT_SUCCEEDED,
                type: AlertActionTypes.POST_ALERT_SUCCEEDED,
              });
            })
        : await api_v2
            .post(`${basePath}/${options.alertId}`, params)
            .then(async () => {
              let params = [];
              let params_qs = "";

              params.push(`pageNumber=${options.pageNumber}`);
              params.push(`pageSize=${options.pageSize}`);

              if (params.length) {
                params_qs = `?${params.join("&")}`;
              }

              const response = await api_v2.get(`${basePath}${params_qs}`, {});
              dispatch({
                alerts: response.data,
                status: AlertActionStatus.POST_ALERT_SUCCEEDED,
                type: AlertActionTypes.POST_ALERT_SUCCEEDED,
              });
            });
    } catch (err) {
      treatError(options?.culture, "PageAlerts::Alerts", err);
    }
  };
};

export const postAllAlerts: ActionCreator<ThunkAction<
  Promise<any>,
  IAlertState,
  null,
  IAlertSucceededPostAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: AlertActionStatus.POST_ALL_ALERT_LOADING,
        type: AlertActionTypes.POST_ALL_ALERT_LOADING,
      });

      newApp
        ? await api_general.post(`${basePath}`, {}).then(async () => {
            dispatch({
              status: AlertActionStatus.GET_ALL_ALERTS_LOADING,
              type: AlertActionTypes.GET_ALL_ALERTS_LOADING,
            });
          })
        : await api_v2.post(`${basePath}`, {}).then(async () => {
            dispatch({
              status: AlertActionStatus.GET_ALL_ALERTS_LOADING,
              type: AlertActionTypes.GET_ALL_ALERTS_LOADING,
            });
          });
    } catch (err) {
      treatError(options?.culture, "PageAlerts::Alerts", err);
    }
  };
};
