import XDialog from "common/X/XDialog";
import React, { useState } from "react";
import Resource from "components/Resources/Resource";
import { IAllManageGroups } from "redux/types/manageGroups";
import {
  Typography,
  Box,
  Grid,
  Button,
  makeStyles,
  Theme,
  createStyles,
  FormControlLabel,
  Divider,
  Checkbox,
} from "@material-ui/core";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  allGroups: IAllManageGroups[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      width: "67%",
    },
    allLabel: {
      backgroundColor: theme.palette.primary.light,
      padding: "4px 0",
      borderRadius: 6,
      marginBottom: 4,
      width: "70%",
    },
    controlLabel: {
      width: "70%",
      padding: "8px 0",
    },
    scrollGrid: {
      maxHeight: 320,
      overflowY: "auto",
      padding: 12,
      "&::-webkit-scrollbar": {
        width: "0.3em",
        border: 0,
      },

      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: 0,
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c3c3c3",
        outline: 0,
        borderRadius: 12,
        border: 0,
      },
    },
  })
);

function DialogUsersManageGroup(props: IProps) {
  const { open, setOpen, allGroups } = props;

  const classes = useStyles();

  const actions = (
    <Box textAlign="right">
      <Button
        color="primary"
        size="small"
        variant="outlined"
        style={{ marginRight: 8 }}
        onClick={() => setOpen(false)}
      >
        <Resource tag="PageManageGroups::Cancel" />
      </Button>
      <Button color="primary" size="small" variant="contained">
        <Resource tag="PageManageGroups::Save" />
      </Button>
    </Box>
  );

  const [values, setValues] = useState<string[]>([]);
  const [all, setAll] = useState(false);

  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      maxWidth="md"
      title={<Resource tag="PageManageGroups::ManageGroups" />}
      actions={actions}
    >
      <Typography variant="h4">
        <Resource tag="PageManageGroups::ChooseTheGroups" />
      </Typography>
      <Box mt={1}>
        <Typography variant="body1" color="textSecondary">
          <Resource tag="PageManageGroups::SelectTheGroups" />
        </Typography>
      </Box>
      <Box mt={3}>
        <Grid container className={classes.scrollGrid}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                className={classes.allLabel}
                control={<Checkbox checked={all} color="primary" name="all" />}
                onChange={(e) => setAll(!all)}
                label={<Resource tag="PageManageGroups::AllWorkspace" />}
              />
            </Grid>
          </Grid>
          <Grid container>
            {allGroups &&
              allGroups?.map((x, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={values?.indexOf(x?.title) > -1 || all}
                        name={x?.title}
                      />
                    }
                    disabled={all}
                    onChange={() =>
                      values?.indexOf(x?.title) > -1
                        ? setValues(values?.filter((y) => y !== x.title))
                        : setValues([...values, x?.title])
                    }
                    label={x?.title}
                    className={classes.controlLabel}
                  />
                  <Divider className={classes.divider} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Box>
    </XDialog>
  );
}

export default DialogUsersManageGroup;
