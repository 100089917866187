import React from "react";
import XCard from "common/X/XCard";
import history from "routes/history";
import newApp from "utils/reactNewApp";
import { Link } from "react-router-dom";
import EMIcon from "components/Icon/Icon";
import AddIcon from "@material-ui/icons/Add";
import Resource, {resource} from "../../Resources/Resource";
import { IResultChallengesPermanent } from "redux/types/challenges";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import {
  Button,
  Grid,
  Typography,
  Chip,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getAllChallenges, patchHighlightChallengeAction, putEndSubmissionAction } from "redux/actions/ChallengeActions";
import { IAppState } from "redux/store/Store";
import { api_general } from "config/api";
import {toast} from "react-toastify";
import {ICultureState} from "../../../redux/reducers/cultureReducer";

interface IProps {
  challengesPermanent: IResultChallengesPermanent;
  culture: ICultureState;
}

function PermanentCard(props: IProps) {
  const { challengesPermanent, culture } = props;
  const theme = useTheme();

  const dispatch = useDispatch();
  const store = useSelector((store) => store) as IAppState;

  const dispatchHighlight = async () => {
    await dispatch(
      patchHighlightChallengeAction({
        challengeId: challengesPermanent.challengeId,
        workspaceId: store.authState.auth.workspaceId,
        isHighlighted: true,
      })
    );
  };

  const dispatchSubmition = async () => {
    await dispatch(
      putEndSubmissionAction({
        challengeId: challengesPermanent.challengeId,
        workspaceId: store.authState.auth.workspaceId,
      })
    );
  };

  const setHighlight = async () => {
    dispatchHighlight().then(() => {
      toast.success(resource(culture, "PagePrizes::Highlighted"))
      dispatch(
        getAllChallenges({
          workspaceId: store.authState.auth.workspaceId,
          challengeId: store.cultureState.culture.cultureTag
        })
      );
    });
  }

  const endSubmition = () => {
    dispatchSubmition().then(() => {
      getAllChallenges({
        workspaceId: store.authState.auth.workspaceId,
        challengeId: store.cultureState.culture.cultureTag
      })
    })
  }

  const duplicateChallenge = async () => {
    const response = await api_general.post(`challenges/duplicate`, {
      challengeId: challengesPermanent.challengeId,
      workspaceId: store.authState.auth.workspaceId,
    });

    if(response?.data?.data){
      history.push(`/createChallenge/${response?.data?.data}`);
    }
  };

  return (
    <XCard
      key={challengesPermanent?.challengeId}
      link={`/challenges/details/${challengesPermanent?.challengeId}`}
      color={"dark"}
      backgroundImage={challengesPermanent?.image}
      elevation={1}
      height={200}
      topContentPadding={20}
      bottomContentPadding={20}
      topContent={
        <Grid container>
          <Grid container item xs={11}>
            <Typography variant="h4">{challengesPermanent?.title}</Typography>
          </Grid>
          {newApp && (
            <Grid container justify="flex-end" item xs={1}>
              <MoreOptionsButton
                translate="-170px, 42px"
                iconColor={theme.palette.primary.contrastText}
              >
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push(
                      `createChallenge/${challengesPermanent.challengeId}`
                    );
                  }}
                >
                  <Resource tag="PageChallenges::EditChallenge" />
                </MenuItem>
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setHighlight()
                  }}
                >
                  <Resource tag="PageChallenges::Highlight" />
                </MenuItem>
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    duplicateChallenge();
                  }}
                >
                  <Resource tag="PageChallenges::Duplicate" />
                </MenuItem>
                <MenuItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    endSubmition();
                  }}
                >
                  <Resource tag="PageChallenges::EndSubmissionPeriod" />
                </MenuItem>
              </MoreOptionsButton>
            </Grid>
          )}
        </Grid>
      }
      bottomContent={
        <Grid container>
          {challengesPermanent?.allowSubmitIdea ? (
            <Grid
              container
              item
              xs={8}
              className="new-ideas"
              style={{ marginLeft: "0px" }}
            >
              <object>
                <Link
                  style={{ cursor: "pointer", textDecoration: "none" }}
                  to={`/createIdea/${challengesPermanent?.challengeId}`}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    size="small"
                  >
                    <Resource tag={"PageChallenges::Challenge_ShareNewIdeas"} />
                  </Button>
                </Link>
              </object>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            container
            item
            xs={challengesPermanent?.allowSubmitIdea ? 4 : 12}
            alignItems="flex-start"
            justify="flex-end"
            direction="row"
          >
            <object>
              <Link
                style={{ cursor: "pointer", textDecoration: "none" }}
                to={{
                  pathname: `idea`,
                  state: { challengeId: challengesPermanent?.challengeId },
                }}
              >
                <Chip
                  size="medium"
                  icon={<EMIcon color="#000" icon="section-ideas" />}
                  style={{
                    marginRight: "-40px",
                    paddingRight: "24px",
                    cursor: "pointer",
                  }}
                  label={
                    <>
                      {challengesPermanent.ideasInProgressCount === 1 ? (
                        <Resource
                          tag={"PageChallenges::Challenge_Idea"}
                          args={[challengesPermanent.ideasSubmitedCount]}
                        />
                      ) : (
                        <Resource
                          tag={"PageChallenges::Challenge_Ideas"}
                          args={[challengesPermanent.ideasSubmitedCount]}
                        />
                      )}
                    </>
                  }
                />
              </Link>
            </object>
          </Grid>
        </Grid>
      }
    />
  );
}

export default PermanentCard;
