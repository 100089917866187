import { Reducer } from 'redux';
import {
  WalletActions,
  WalletActionsTypes,
} from '../actions/WalletActions';

export interface IResultWalletBalanceHistory {
  movement: number,
  balance: number,
  dateDay: string,
  dateHour: string,
  resource: string,
  resourceParameter: string
  itemType: number,
  itemId: string
}

export interface IResultWalletInvestments {
  isIncreaseInvestment: boolean,
  ideaId: string,
  ideaTitle: string,
  invested: number,
  currentInvestmentValue: number,
  investmentDate: string,
  graphIdeaRate: {
    additionalProp1: number,
    additionalProp2: number,
    additionalProp3: number,
  }
}

export interface IWallet {
  allowToSeeInvestment:boolean,
  investments: IWalletInvestments,
  totalAvailable: number,
  totalInvested: number,
  totalInvestedCurrentValue: number
}

export interface IWalletBalanceHistory {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultWalletBalanceHistory[]
}

export interface IWalletInvestments {
  totalInvestedAmount: number,
  totalCurrentValue: number,
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultWalletInvestments[]
}

export interface IWalletState {
  readonly wallet: IWallet;
  readonly balanceHistory: IWalletBalanceHistory;
  readonly walletInvestments: IWalletInvestments;
  readonly points: number
  readonly showMorePoints: boolean;
}

const initialWalletInvestments = {
  totalInvestedAmount: 0,
  totalCurrentValue: 0,
  meta: {
    currentPage: 0,
    from: 0,
    lastPage: 0,
    perPage: 0,
    to: 0,
    total: 0
  },
  result: [
    {
      isIncreaseInvestment: true,
      ideaId: '',
      ideaTitle: '',
      invested: 0,
      currentInvestmentValue: 0,
      investmentDate: '',
      graphIdeaRate: {
        additionalProp1: 0,
        additionalProp2: 0,
        additionalProp3: 0
      }
    }
  ]
}

const initialCharacterState: IWalletState = {
  wallet: {
    totalAvailable: 0, 
    totalInvested: 0,
    allowToSeeInvestment:false,
    totalInvestedCurrentValue:0,
    investments:initialWalletInvestments
  },

  balanceHistory: {
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: [
      {
        movement: 0,
        balance: 0,
        dateDay: "",
        dateHour: "",
        resource: "",
        resourceParameter: "",
        itemId: '',
        itemType: 0,
      }
    ]
  },
  walletInvestments: initialWalletInvestments,
  points: 0,
  showMorePoints:false,
};

export const walletReducer: Reducer<IWalletState, WalletActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case WalletActionsTypes.GET_WALLET: {
      return {
        ...state,
        wallet: {
          totalAvailable: action.wallet.totalAvailable , 
          totalInvested: action.wallet.totalInvested,
          investments: action.wallet.investments,
          allowToSeeInvestment: action.wallet.allowToSeeInvestment,
          totalInvestedCurrentValue: action.wallet.totalInvestedCurrentValue
        },
      };
    }
    case WalletActionsTypes.GET_WALLET_BALANCEHISTORY: {
      return {
        ...state,
        balanceHistory: action.balanceHistory,
        points: action.points
      };
    }
    case WalletActionsTypes.GET_WALLET_INVESTMENTS: {
      return {
        ...state,
        walletInvestments: action.walletInvestments,
        points: action.points
      };
    }
    case WalletActionsTypes.SHOW_MORE: {
      return {
        ...state,
        showMorePoints: !state.showMorePoints
      }
    }
    default:
      return state;
  }
};