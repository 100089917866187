import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useEffect, useState } from "react";
import { ICultureState } from "redux/reducers/cultureReducer";
import Resource, { resource } from "components/Resources/Resource";
import {
  Box,
  Button,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    criteriasContainer: {
      maxHeight: 220,
      overflowY: "auto",
    },
    numberPercentage: {
      width: "9%",
      marginLeft: 24,
      fontWeight: 500,

      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "-moz-appearance": "textfield",
    },
    removeButton: {
      padding: 0,
      border: `1px solid ${theme.palette.grey[300]}`,
      color: theme.palette.grey[300],
      marginLeft: 12,
    },
  })
);

interface IProps {
  culture: ICultureState;
  evaluationState: {
    evaluationType: number;
    criterias: {
      cultures: [
        {
          title: string;
          cultureTag: string;
        }
      ];
      weight: string;
      order: number;
      criteriaId?: string;
    }[];
  };
  setState: (newValue: any) => void;
  setCriteriaCompletion: (number:number) => void
}

function EvaluationWorkflow({ culture, evaluationState, setState, setCriteriaCompletion }: IProps) {
  const classes = useStyles();
  const [evaluationType, setevaluationType] = useState(evaluationState.evaluationType);
  const [criterias, setCriterias] = useState(evaluationState.criterias);
  const { cultureTag } = culture?.culture || "";

  const addcriteriasValue = () => {
    setCriterias([...criterias, { cultures: [{ title: "", cultureTag }], weight: "", order: criterias.length + 1 }]);
  };

  const setCriteriasValue = (newValue: any, index: number) => {
    const newArr = [...criterias];
    newArr[index] = { ...newArr[index], ...newValue };
    setCriterias(newArr);
  };

  const deleteStar = (valIndex: number) => {
    const totalWeight = criterias.length
      ? criterias
          .map((x, i) => (i !== valIndex ? Number(x.weight) : 0))
          .reduce((accumulator, currentValue) => accumulator + currentValue)
      : 0;

    setCriteriaCompletion(totalWeight)
    setCriterias(criterias.filter((star, index) => index !== valIndex));
  };

  useEffect(() => {
    setState({ criterias, evaluationType });
  }, [setState, criterias, evaluationType]);

  const handleCriteriaWeight = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = e.target;
    const totalWeight = criterias.length
      ? criterias
          .map((x, i) => (i !== index ? Number(x.weight) : 0))
          .reduce((accumulator, currentValue) => accumulator + currentValue)
      : 0;

    setCriteriaCompletion(totalWeight)

    totalWeight + Number(value) < 101
      ? setCriteriasValue({ weight: e.target.value }, index)
      : setCriteriasValue({ weight: 100 - totalWeight }, index);

      setCriteriaCompletion(totalWeight + Number(value))
  };

  return (
    <Box>
      <Box>
        <Typography variant="h4">
          <Resource tag="PageCreateChallenge::Workflow_EvaluationMethod" />
        </Typography>
        <Box mt={1}>
          <Typography variant="body2" color="textSecondary">
            <Resource tag="PageCreateChallenge::Workflow_SelectEvaluation" />
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <FormControl component="fieldset">
          <RadioGroup row name="STAR" value={evaluationType} onChange={(e, v) => setevaluationType(Number(v))}>
            <FormControlLabel
              color="primary"
              value={1}
              control={<Radio color="primary" />}
              label={<Resource tag="PageCreateChallenge::Workflow_StarRating" />}
            />
            <FormControlLabel
              color="primary"
              value={2}
              control={<Radio color="primary" />}
              label={<Resource tag="PageCreateChallenge::Workflow_Investments" />}
            />
          </RadioGroup>
        </FormControl>
        <Divider style={{ width: "100%", marginTop: 8 }} />
      </Box>
      <Box mt={4}>
        {evaluationType === 1 ? (
          <Box>
            <Typography variant="body2" color="textSecondary">
              <Resource tag="PageCreateChallenge::Workflow_ApplyingMethod" />
            </Typography>
            <Box mt={2}>
              <Typography variant="h4">
                <Resource tag="PageCreateChallenge::Workflow_RatingCriteria" />
              </Typography>
            </Box>
            <Box mt={1}>
              <Resource tag="PageCreateChallenge::Workflow_DefineCriterias" />
            </Box>

            <Box mt={2} className={classes.criteriasContainer}>
              {criterias.map((star, index, self) => (
                <Box display="flex" alignItems="center" mb={2} key={index}>
                  <Input
                    fullWidth
                    value={star.cultures[0].title}
                    onChange={(e) => setCriteriasValue({ cultures: [{ title: e.target.value, cultureTag }] }, index)}
                    placeholder={resource(culture, "PageCreateChallenge::Workflow_WriteCriteria")}
                    style={{ width: "80%" }}
                  />
                  <Input
                    value={star.weight}
                    type="number"
                    onChange={(e) => handleCriteriaWeight(e, index)}
                    className={classes.numberPercentage}
                    endAdornment={
                      <InputAdornment position="end">
                        <strong>%</strong>
                      </InputAdornment>
                    }
                  />
                  {self.length > 1 && (
                    <IconButton className={classes.removeButton} onClick={() => deleteStar(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                </Box>
              ))}
            </Box>
            <Box mt={1} textAlign="end">
              <Button
                color="primary"
                startIcon={<AddIcon />}
                disabled={
                  criterias.length
                    ? criterias
                        .map((x, i) => Number(x.weight))
                        .reduce((accumulator, currentValue) => accumulator + currentValue) >= 100
                    : false
                }
                onClick={addcriteriasValue}
              >
                <Resource tag="PageCreateChallenge::Workflow_AddAnotherCriteria" />
              </Button>
            </Box>
          </Box>
        ) : (
          <Box mb={3}>
            <Typography color="textSecondary">
              <Resource tag="PageCreateChallenge::Workflow_TextToInvestments" />
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default EvaluationWorkflow;
