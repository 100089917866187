import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ICultureState } from '../reducers/cultureReducer';
import { toast } from 'react-toastify';
import { api_general } from "../../config/api";
import { ICountry } from 'redux/reducers/countryReducer';

export enum CountryActionTypes {
  GET_COUNTRIES = 'GET_COUNTRIES',
}

export interface ICountrySetCountries {
  type: CountryActionTypes.GET_COUNTRIES;
  countries: Array<ICountry>;
}

export type CountryActions = ICountrySetCountries;

export const getCountries: ActionCreator<
  ThunkAction<Promise<any>, ICultureState, null, ICountrySetCountries>
> = () => {
  return async (dispatch: Dispatch) => {
    try {

      const response = await api_general.get(`workspaces/countries`, {});

      dispatch({
        countries: response.data,
        type: CountryActionTypes.GET_COUNTRIES,
      });

    } catch (err) {
      toast.error("Não encontramos os paises")
    }
  };
};