import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
  CardActions,
  Button,
  Grid,
  Box,
  CardActionArea,
  Dialog,
  useMediaQuery,
  IconButton,
  Divider,
} from "@material-ui/core";
import EMIcon from "components/Icon/Icon";
import Image from "material-ui-image";
import React, { useState } from "react";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import history from "../../routes/history";
import Resource from "../Resources/Resource";
import { red } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import EvaluationBox from "../EvaluationBox/EvaluationBox";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import getAleatoryImage from "../AleatoryImage/AleatoryImage";
import { ICultureState } from "redux/reducers/cultureReducer";
import MuiDialogContent from "@material-ui/core/DialogContent";
import getMaxChar from "../../utils/maxIdeaCardDescriptionWidth";
import ReviewStars from "components/ReviewRatingBox/ReviewStars";
import InvestmentPopUp from "../ReviewRatingBox/ReviewInvestPopup";
import { IAllIdeasState } from "redux/reducers/ideas/AllIdeasReducer";
import { IResultDetailIdea } from "redux/reducers/challengeDetailReducer";
import { IResultIdeasCollaborative } from "redux/types/ideas";
import ReviewReinvestmentPopup from "../ReviewRatingBox/ReviewReinvestPopup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
      marginLeft: 16,
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
    cardActions: {
      alignItems: "flex-end",
      height: "100%",
    },
    button: {
      marginLeft: 13,
    },
    icon: {
      color: theme.palette.primary.main,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    titleDivider: {
      width: "96%",
      margin: "10px 0",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    popupTitle: {
      textTransform: "lowercase",
      "&:first-letter": {
        textTransform: "capitalize",
      },
      padding: "21px 36px 4px 36px",
    },
  })
);

interface IProps {
  width: number;
  idea?: IResultIdeasCollaborative | IResultDetailIdea;
  hasImage?: boolean;
  postInvestments: (options: object) => Promise<any>;
  applicationId: string;
  culture: ICultureState;
  postLikes: (options: object) => Promise<any>;
  deleteLikes: (options: object) => Promise<any>;
  getAllIdeas: (options: object) => Promise<IAllIdeasState>;
}

function CardIdeaCollaborative(props: IProps) {
  const {
    postInvestments,
    applicationId,
    culture,
    postLikes,
    deleteLikes,
    getAllIdeas,
  } = props;

  const [isInvestmentOn, setInvestmentOn] = useState(false);
  const [isReinvestmentOn, setReinvestmenOn] = useState(false);
  const investment = props?.idea?.evaluation?.investment!;
  const invested = investment?.investmentValue > 0;
  const [isReviewOn, setIsReviewOn] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [pageNumber] = useState(1);
  const [pageSize] = useState(4);
  const doInvestment = (value: any, isIncrease: boolean) => {
    const ideaId = props?.idea?.ideaId;

    postInvestments({
      ideaId,
      points: value,
      isIncrease,
      applicationId,
    }).then((x) => getAllIdeas({ pageNumber, pageSize }));
  };

  const classes = useStyles();
  var description = props.idea?.description!;
  var div: any = document.createElement("div");
  div.innerHTML = description;
  var firstImage = div.getElementsByTagName("img")[0];
  var firstText = div.getElementsByTagName("p")[0]?.innerHTML;
  var rawImgSrc = firstImage ? firstImage.getAttribute("src") : "";
  let value;
  let graph;
  let variation;
  let maxChar = props.hasImage ? 30 : getMaxChar(props.width);
  let title = props.idea?.title!;
  const [liked, setLiked] = useState(
    !!props.idea?.evaluation?.likesEvaluation?.evaluationUser!
  );

  const likeOptions = {
    ideaId: props.idea?.ideaId,
    applicationId: props.applicationId,
    pageSize,
    pageNumber,
  };

  description = description?.replace(
    /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
    ""
  );
  div.innerHTML = firstText;
  firstText = div.innerText;
  if (firstText.length > maxChar) {
    firstText = firstText.substring(0, maxChar);
    firstText = firstText + " [...] ";
  } else {
    div.innerHTML = firstText;
    firstText = div.innerText;
  }
  if (title?.length > maxChar) {
    title = title?.substring(0, maxChar);
    title = title + " [...] ";
  }
  if (description?.length > maxChar) {
    description = description?.substring(0, maxChar);
    description = description + " [...] ";
  }

  switch (props?.idea?.evaluation?.evaluationType) {
    case "Stars":
      props?.idea?.evaluation.starsEvaluation.average !== undefined
        ? (value = props?.idea?.evaluation.starsEvaluation.average)
        : (value = 0);
      // buttonType = <RoundedButton background='white' color={iconColor} icon="star" size={16}>Rate</RoundedButton>
      break;
    case "Investments":
      props?.idea?.evaluation.investment.investmentBeliefValue !== undefined
        ? (value = props?.idea?.evaluation.investment.investmentBeliefValue)
        : (value = 0);

      variation = props?.idea?.evaluation.investment?.investmentBeliefVariation;

      props?.idea?.evaluation.investment.investmentGraph !== undefined
        ? (graph = Object.values(
            props?.idea?.evaluation.investment.investmentGraph
          ) as Array<number>)
        : (graph = Object.values(0) as Array<number>);
      // buttonType = <RoundedButton background='white' color={iconColor} icon="investment" size={16}>Invest</RoundedButton>
      break;
    case "Likes":
      value = props?.idea?.evaluation.likesEvaluation.countEvaluations;
      // buttonType = <RoundedButton background='white' color={iconColor} icon="like" size={16}>Like</RoundedButton>
      break;
    default:
  }

  function handleClickOnCard(clickComment?: boolean) {
    history.push({
      pathname: `/idea/details/${props?.idea?.ideaId}`,
      state: { toComments: clickComment },
    });
  }
  return (
    <>
      <Card className={classes.root} elevation={3}>
        <Grid container className={classes.root}>
          <CardActionArea onClick={() => handleClickOnCard()}>
            <Grid container direction="row" className={classes.root}>
              {props.hasImage ? (
                <>
                  <Grid item xs={12}>
                    <Box height="115px">
                      <Grid container>
                        <Grid item xs={12} lg={8}>
                          <Grid
                            container
                            direction="row"
                            className={classes.root}
                          >
                            <Grid item xs={12}>
                              <Box p={2}>
                                <Typography
                                  variant="h4"
                                  color="textPrimary"
                                  component="p"
                                >
                                  {title}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <CardContent>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                  component="span"
                                >
                                  {firstText !== "undefined"
                                    ? firstText
                                    : description}
                                  {firstText !== "undefined" &&
                                    firstText?.length > maxChar && (
                                      <Link
                                        component={RouterLink}
                                        to={{
                                          pathname: `ideas/details/${props?.idea?.ideaId}`,
                                        }}
                                      >
                                        <Resource tag={"PageIdea::Menu_More"} />
                                      </Link>
                                    )}
                                  {firstText === "undefined" &&
                                    description?.length > maxChar && (
                                      <Link
                                        component={RouterLink}
                                        to={{
                                          pathname: `ideas/details/${props?.idea?.ideaId}`,
                                        }}
                                      >
                                        <Resource tag={"PageIdea::Menu_More"} />
                                      </Link>
                                    )}
                                </Typography>
                              </CardContent>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <EvaluationBox
                            type={`new${props.idea?.evaluation?.evaluationType}`}
                            value={value === undefined ? (value = 0) : value}
                            variation={variation}
                            graph={graph}
                            scale={1}
                            evaluated={liked}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Image
                      src={rawImgSrc ? rawImgSrc : getAleatoryImage(480, 200)}
                      style={{
                        margin: "16px",
                        height: "200px",
                        padding: "0",
                        marginTop: "10px",
                        marginBottom: "0px",
                      }}
                      imageStyle={{ objectFit: "cover", borderRadius: "7px" }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Box>
                      <Grid container>
                        <Grid item xs={12} lg={8}>
                          <Grid
                            container
                            direction="row"
                            className={classes.root}
                          >
                            <Grid item xs={12}>
                              <Box p={2}>
                                <Typography
                                  variant="h4"
                                  color="textPrimary"
                                  component="p"
                                >
                                  {title}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <CardContent style={{ minHeight: "80px" }}>
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  component="span"
                                >
                                  {firstText !== "undefined"
                                    ? firstText
                                    : description}
                                  {firstText !== "undefined" &&
                                    firstText?.length > maxChar && (
                                      <Link
                                        component={RouterLink}
                                        to={{
                                          pathname: `ideas/details/${props?.idea?.ideaId}`,
                                        }}
                                      >
                                        <Resource tag={"PageIdea::Menu_More"} />
                                      </Link>
                                    )}
                                  {firstText === "undefined" &&
                                    description?.length > maxChar && (
                                      <Link
                                        component={RouterLink}
                                        to={{
                                          pathname: `ideas/details/${props?.idea?.ideaId}`,
                                        }}
                                      >
                                        <Resource tag={"PageIdea::Menu_More"} />
                                      </Link>
                                    )}
                                </Typography>
                              </CardContent>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                          <EvaluationBox
                            type={`new${props.idea?.evaluation?.evaluationType}`}
                            value={value === undefined ? (value = 0) : value}
                            variation={variation}
                            graph={graph}
                            scale={1}
                            evaluated={liked}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </CardActionArea>
          <Grid item xs={12}>
            <CardActions disableSpacing className={classes.cardActions}>
              {props.idea?.evaluation?.evaluationType === "Likes" && (
                <Button
                  color="primary"
                  startIcon={
                    <EMIcon
                      icon="like"
                      color={
                        liked
                          ? "white"
                          : !props.idea?.evaluation?.likesEvaluation
                              ?.isAllowedEvaluate
                          ? "#ccc"
                          : theme.palette.primary.main
                      }
                    />
                  }
                  size="small"
                  className={classes.button}
                  variant={liked ? "contained" : "text"}
                  onClick={() =>
                    !liked
                      ? postLikes(likeOptions).then((x) => setLiked(true))
                      : deleteLikes(likeOptions).then((x) => setLiked(false))
                  }
                  disabled={
                    !props.idea?.evaluation?.likesEvaluation?.isAllowedEvaluate
                  }
                >
                  {liked ? (
                    <Resource tag="PageIdea::LikedIdea" />
                  ) : (
                    <Resource tag="PageIdea::LikeIdea" />
                  )}
                </Button>
              )}
              {props.idea?.evaluation?.evaluationType === "Stars" || props.idea?.evaluation?.evaluationType === "Average" ? (
                <>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={
                      <EMIcon
                        icon="star"
                        color={
                          props.idea?.evaluation?.starsEvaluation
                            ?.isAllowedEvaluate
                            ? theme.palette.primary.main
                            : "#ccc"
                        }
                      />
                    }
                    size="small"
                    className={classes.button}
                    onClick={() => setIsReviewOn(true)}
                    disabled={
                      !props.idea?.evaluation?.starsEvaluation
                        ?.isAllowedEvaluate
                    }
                  >
                    <Resource tag={"Common::Rate"} />
                  </Button>
                  <Dialog
                    open={isReviewOn}
                    fullWidth
                    maxWidth={"xs"}
                    fullScreen={fullScreen}
                  >
                    <MuiDialogTitle
                      disableTypography
                      className={classes.popupTitle}
                    >
                      <Typography variant="h4">
                        <Resource tag={"Common::Rate"} />
                      </Typography>
                      <IconButton
                        // aria-label="close"
                        className={classes.closeButton}
                        onClick={() => setIsReviewOn(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Divider className={classes.titleDivider}></Divider>
                    </MuiDialogTitle>
                    <MuiDialogContent style={{ padding: 0 }}>
                      <ReviewStars
                        ideaId={props.idea?.ideaId}
                        getAllIdeas={props.getAllIdeas}
                        starsEvaluation={props.idea?.evaluation}
                        isCollaboration={
                          props.idea?.evaluation?.isCollaboration
                        }
                      />
                    </MuiDialogContent>
                  </Dialog>
                </>
              ): ''}
              {props.idea?.evaluation?.evaluationType === "Investments" && (
                <>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={
                      <EMIcon
                        icon="investment"
                        color={
                          props?.idea?.evaluation?.investment?.maximumInvestment
                            ? theme.palette.primary.main
                            : "#ccc"
                        }
                      />
                    }
                    size="small"
                    className={classes.button}
                    onClick={() =>
                      invested ? setReinvestmenOn(true) : setInvestmentOn(true)
                    }
                    disabled={
                      !props?.idea?.evaluation?.investment?.maximumInvestment
                    }
                  >
                    {invested ? (
                      <Resource tag="PageIdea::ReinvestIdea" />
                    ) : (
                      <Resource tag="PageDetailedIdea::InvestmentArea_ButtonInvest" />
                    )}
                  </Button>
                  <InvestmentPopUp
                    investment={props?.idea?.evaluation?.investment}
                    points={
                      props?.idea?.evaluation?.investment?.maximumInvestment
                    }
                    isInvestmentOn={isInvestmentOn}
                    setInvestmentOn={setInvestmentOn}
                    title={props?.idea?.title}
                    postInvestments={doInvestment}
                    culture={culture}
                  />
                  <ReviewReinvestmentPopup
                    investment={props?.idea?.evaluation?.investment}
                    points={
                      props?.idea?.evaluation?.investment?.maximumInvestment
                    }
                    isReinvestmentOn={isReinvestmentOn}
                    setReinvestmentOn={setReinvestmenOn}
                    title={props?.idea?.title}
                    postInvestments={doInvestment}
                    culture={culture}
                  />
                </>
              )}
              <Button
                variant="text"
                color="primary"
                startIcon={
                  <EMIcon
                    icon="comments-b"
                    color={theme.palette.primary.main}
                  />
                }
                size="small"
                onClick={() => handleClickOnCard(true)}
                className={classes.button}
              >
                <Resource tag={"PageFeed::Comment"} />
              </Button>
            </CardActions>{" "}
            color={theme.palette.primary.main}
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default CardIdeaCollaborative;
