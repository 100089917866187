import { Reducer } from 'redux';
import { DraftActionTypes, DraftActions } from '../../actions/Drafts/DraftAction';

export interface IDraftState {
  readonly draft: any;
}

const initialDraftSelected = {
  draft: null
}

export const draftReducer: Reducer<any, DraftActions> = (
  state = initialDraftSelected,
  action
) => {
  switch (action.type) {
    case DraftActionTypes.ADD_SELECTED_DRAFT: {
      return {
        ...state,
        draft: action.draft,
      };
    }
    case DraftActionTypes.REMOVE_SELECTED_DRAFT: {
      return {
        ...state,
        draft: null,
      }
    }
    default:
      return state;
  }
};