import React from "react";
import Resource from "components/Resources/Resource";
import { IResultMyActivitiesPrizesWon } from "redux/types/myActivities";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  Paper,
  Box,
  createStyles,
  Theme,
} from "@material-ui/core";

interface IProps {
  prize: IResultMyActivitiesPrizesWon;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: "grid",
      position: "relative",
      background: "transparent",
      margin: "0 8px",
    },
    media: {
      height: 220,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      borderRadius: 6,
      backgroundBlendMode: "color",
      display: "flex",
    },
    typography: {
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "1.5em",
      lineHeight: "normal",
      color: "#fff",
      textDecoration: "none",
      fontWeight: 500,
    },
    prizeCardTitleAndTime: {
      height: "fit-content",
      alignSelf: "flex-start",
    },
    colorTime: {
      color: "#fff",
      fontSize: 12,
      marginTop: 8,
    },
    wonTag: {
      padding: 8,
      color: theme.palette.primary.main,
      alignSelf: "flex-end",
    },
    cardContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingBottom: "16px !important",
    },
  })
);

function MyActivityPrizesWonCard(props: IProps) {
  const { prize } = props;
  const classes = useStyles();

  return (
    <Card key={prize.prizeId} className={classes.card}>
      <CardMedia
        className={classes.media}
        style={{ backgroundImage: `url(${prize.image})` }}
      >
        <CardContent className={classes.cardContent}>
          <Paper className={classes.wonTag}>
            <Box component="span" fontWeight={600}>
              {prize.quantity ? (
                prize.quantity > 1 ? (
                  <Resource
                    tag="PageMyActivity::Prizes_WonUnits"
                    args={[prize.quantity]}
                  />
                ) : (
                  <Resource
                    tag="PageMyActivity::Prizes_WonUnit"
                    args={[prize.quantity]}
                  />
                )
              ) : (
                ""
              )}
            </Box>
            <Box component="span" marginLeft={0.5} marginRight={0.5}>
              <Resource tag="Common::on" />
            </Box>
            <Box component="span">{prize.date}</Box>
          </Paper>
          <Box className={classes.prizeCardTitleAndTime}>
            <Typography className={classes.typography}>
              {prize.title}
            </Typography>
            <Typography variant="subtitle2" className={classes.colorTime}>
              <Resource
                tag={prize.purchasePriceResource.replace(".", "::")}
                args={[prize.value]}
              />
            </Typography>
          </Box>
        </CardContent>
      </CardMedia>
    </Card>
  );
}

export default MyActivityPrizesWonCard;
