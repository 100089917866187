import { useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IAppState } from '../../../redux/store/Store';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import Badge from '@material-ui/core/Badge';

import { Box, Grid, Select, MenuItem, makeStyles, createStyles, Theme, Typography, Button, Dialog, Divider, IconButton, withStyles } from '@material-ui/core';
import ModalSelectFilterAnalytics from 'common/Modal/ModalSelectFilterAnalytics';
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Resource from 'components/Resources/Resource';
import { IProfileData } from 'redux/reducers/profileReducer';
import { api_v3 } from 'config/api';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      borderBottom: "solid 2px !important"
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 11,
      padding: 16
    },
    helperText: {
      marginBottom: 35
    },
    tableContainer: {
      marginTop: 30,
      "& .MuiPaper-root > div:nth-child(3)": {
        paddingLeft: 20,
        paddingRight: 20
      },
      "& .MuiTableCell-root": {
        padding: "15px 10px!important"
      },
      "& table > tbody > tr:nth-child(even)": {
        backgroundColor: theme.palette.grey[200],
      }
    },
    label: {
      textTransform: "uppercase",
      color: theme.palette.grey[600],
      fontSize: 13
    },

    dialog: {
      margin: "auto",
    },
    titleHeader: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#313b53",
    },
    dividerHeader: {
      width: "90%",
      marginLeft: "30px",
    },
    dialogHeader: {
      padding: "22px 24px 10px 30px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    badge: {
      margin: "10px",
      padding: "5px 10px",
      textTransform: "uppercase",
      borderRadius: 8,
      width: "fit-content",
      color: "#FFFFFF",
      fontSize: "9px",
      justifyContent: "center",
      backgroundColor: "#000000"
    }
  })
);


interface IDispatchProps {

}

interface IStateProps {
  profile: IProfileData;
  workspaceId: string
}

const columns = {
  users: [
    "PageAnalytics::Column_state",
    "PageAnalytics::Column_first_login",
    "PageAnalytics::Column_date_accept",
    "PageAnalytics::Column_last_login",
    "PageAnalytics::Column_visits",
    "PageAnalytics::Column_groups",
    "PageAnalytics::Column_permission",
    "PageAnalytics::Column_name",
    "PageAnalytics::Column_username",
    "PageAnalytics::Column_email",
    "PageAnalytics::Column_user_1",
    "PageAnalytics::Column_user_2",
    "PageAnalytics::Column_ideas_sub",
    "PageAnalytics::Column_ideas_impl",
    "PageAnalytics::Column_inspiration",
    "PageAnalytics::Column_comments",
    "PageAnalytics::Column_comments_denounced",
    "PageAnalytics::Column_assessment",
    "PageAnalytics::Column_investments",
    "PageAnalytics::Column_awards",
  ],
  prizes: [
    "PageAnalytics::Column_awards",
    "PageAnalytics::Column_name_award",
    "PageAnalytics::Column_description",
    "PageAnalytics::Column_type",
    "PageAnalytics::Column_access",
    "PageAnalytics::Column_date_initial",
    "PageAnalytics::Column_date_final",
    "PageAnalytics::Column_value",
    "PageAnalytics::Column_bidding",
    "PageAnalytics::Column_value_final",
    "PageAnalytics::Column_name_winner",
    "PageAnalytics::Column_username",
    "PageAnalytics::Column_email",
    "PageAnalytics::Column_groups",
    "PageAnalytics::Column_user_1",
    "PageAnalytics::Column_user_2",
  ],
  ideas: [
    "PageAnalytics::Column_idea",
    "PageAnalytics::Column_title",
    "PageAnalytics::Column_description",
    "PageAnalytics::Column_challenge",
    "PageAnalytics::Column_tags",
    "PageAnalytics::Column_1",
    "PageAnalytics::Column_2",
    "PageAnalytics::Column_autor",
    "PageAnalytics::Column_coautor",
    "PageAnalytics::Column_state",
    "PageAnalytics::Column_date",
    "PageAnalytics::Column_return",
    "PageAnalytics::Column_impact",
    "PageAnalytics::Column_date_submission",
    "PageAnalytics::Column_responsible",
    "PageAnalytics::Column_comment",
    "PageAnalytics::Column_date_ticket",
    "PageAnalytics::Column_type",
    "PageAnalytics::Column_assessment",
    "PageAnalytics::Column_comment",
    "PageAnalytics::Column_date_ticket",
    "PageAnalytics::Column_extra_1",
    "PageAnalytics::Column_extra_2",
    "PageAnalytics::Column_resposible_edition",
    "PageAnalytics::Column_date_submission_edition",
    "PageAnalytics::Column_comment",
    "PageAnalytics::Column_date_ticket",
    "PageAnalytics::Column_responsible_assessment_ticket",
    "PageAnalytics::Column_assessment_comit",
    "PageAnalytics::Column_phase_evaluation",
    "PageAnalytics::Column_comments",
  ],
  usergroups: [
    "PageAnalytics::Column_name_group",
    "PageAnalytics::Column_name",
    "PageAnalytics::Column_username",
    "PageAnalytics::Column_email",
    "PageAnalytics::Column_user_1",
    "PageAnalytics::Column_user_2",
  ],
  evaluations: [
    "PageAnalytics::Column_url",
    "PageAnalytics::Column_title_idea",
    "PageAnalytics::Column_autor",
    "PageAnalytics::Column_coautor",
    "PageAnalytics::Column_phase_type",
    "PageAnalytics::Column_name_phase",
    "PageAnalytics::Column_evaluation_responsible",
    "PageAnalytics::Column_email",
    "PageAnalytics::Column_date_evaluation",
    "PageAnalytics::Column_c1",
    "PageAnalytics::Column_c2",
    "PageAnalytics::Column_c3",
    "PageAnalytics::Column_c4",
    "PageAnalytics::Column_average_rating",
    "PageAnalytics::Column_principal_comment",
  ],
  comments: [
    "PageAnalytics::Column_date_comment",
    "PageAnalytics::Column_type_comment",
    "PageAnalytics::Column_text_comment",
    "PageAnalytics::Column_visibility",
    "PageAnalytics::Column_principal_comment",
    "PageAnalytics::Column_username",
    "PageAnalytics::Column_email",
    "PageAnalytics::Column_object",
    "PageAnalytics::Column_title_object",
    "PageAnalytics::Column_url_project",
    "PageAnalytics::Column_phase",
    "PageAnalytics::Column_challenge",
    "PageAnalytics::Column_likes",
    "PageAnalytics::Column_added_value",
    "PageAnalytics::Column_denounced",
    "PageAnalytics::Column_accusations",
    "PageAnalytics::Column_comment_reply",
    "PageAnalytics::Column_principal_comment",
  ],
  userAggregate: [
    "PageAnalytics::Column_name",
    "PageAnalytics::Column_email",
    "PageAnalytics::Column_challenge",
    "PageAnalytics::Column_name_group",
    "PageAnalytics::Column_points",
    "PageAnalytics::Column_total_idea",
    "PageAnalytics::Column_total_visits",
    "PageAnalytics::Column_total_evaluations",
    "PageAnalytics::Column_total_idea_comments"
  ]
}

const data = {
  users: [
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "1"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "0", "0", "0", "0", "0", "0", "0", "0"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "1"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercialMarketingSales" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "1"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "3"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "1"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "1"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercialMarketingSales" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "7"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "1"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "1"],
    [<Resource tag="PageAnalytics::Data_Active" />, "10/02/2000", "11/02/2000", "11/03/2021", "5", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Participant" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />, "10", "2", "8", "10", "0", "24", "22", "1"]

  ],
  prizes: [
    ["", "", "Iphone", <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Auction" />, <Resource tag="PageAnalytics::Data_AllWorkspace" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "15.000", "322", "23.050", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Auction" />, <Resource tag="PageAnalytics::Data_AllWorkspace" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "2.000", "467", "8.740", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Purchase" />, <Resource tag="PageAnalytics::Data_Sales" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "2.500", "—", "—", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Auction" />, <Resource tag="PageAnalytics::Data_AllWorkspace" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "3.250", "21", "3.755", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercialMarketingSales" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Auction" />, <Resource tag="PageAnalytics::Data_Sales" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "15.000", "245", "18.340", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Auction" />, <Resource tag="PageAnalytics::Data_AllWorkspace" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "2.000", "345", "6.150", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Purchase" />, <Resource tag="PageAnalytics::Data_Sales" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "2.500", "—", "—", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Auction" />, <Resource tag="PageAnalytics::Data_AllWorkspace" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "3.250", "432", "7.400", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercialMarketingSales" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Auction" />, <Resource tag="PageAnalytics::Data_Sales" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "15.000", "234", "19.480", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Auction" />, <Resource tag="PageAnalytics::Data_AllWorkspace" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "2.000", "34", "2.755", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    ["", "", <Resource tag="PageAnalytics::Data_AwardTitle" />, <Resource tag="PageAnalytics::Data_AwardDescription" />, <Resource tag="PageAnalytics::Data_Purchase" />, <Resource tag="PageAnalytics::Data_Sales" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "2.500", "—", "—", <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_CommitteeCommercial" />, <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
  ],
  ideas: [
    ["", <Resource tag="PageAnalytics::Data_SavePrints" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_Sustainability" />, <Resource tag="PageAnalytics::DataInnovationSustainability" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "12%", "15", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "15", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "3.8", "15", "15"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "68%", "6", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "6", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.11", "6", "6"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "34%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.12", "3", "0"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "34%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.13", "5", "0"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "0", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "46%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.14", "6", "0"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "55%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.15", "4", "0"],
    ["", <Resource tag="PageAnalytics::Data_SavePrints" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_Sustainability" />, <Resource tag="PageAnalytics::DataInnovationSustainability" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "12%", "15", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "15", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.8", "15", "15"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "68%", "6", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "6", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.11", "6", "6"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "34%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.12", "3", "0"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "34%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.13", "5", "0"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "0", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "46%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.14", "6", "0"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "55%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.15", "4", "0"],
    ["", <Resource tag="PageAnalytics::Data_SavePrints" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_Sustainability" />, <Resource tag="PageAnalytics::DataInnovationSustainability" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "12%", "15", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "15", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.8", "15", "15"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "68%", "6", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "6", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.11", "6", "6"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "34%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "3.12", "3", "0"],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_IdeaDescription" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "(tag1; tag2)", <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, <Resource tag="PageAnalytics::Data_OnGoing" />, "—", "—", "—", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Username" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_Investments" />, "34%", "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="PageAnalytics::Data_TextOptions" />, <Resource tag="" />, <Resource tag="PageAnalytics::Data_DayMonthYear" />, "0", <Resource tag="PageAnalytics::Data_DayMonthYear" />, <Resource tag="" />, "3.13", "5", "0"],

  ],
  usergroups: [
    [<Resource tag="PageAnalytics::Data_Active" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    [<Resource tag="PageAnalytics::Data_Imported" />, "—", "—", "Maria@exago.com", "—", "—"],
    [<Resource tag="PageAnalytics::Data_Disabled" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    [<Resource tag="PageAnalytics::Data_Active" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    [<Resource tag="PageAnalytics::Data_Active" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    [<Resource tag="PageAnalytics::Data_Active" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    [<Resource tag="PageAnalytics::Data_Active" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    [<Resource tag="PageAnalytics::Data_Active" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    [<Resource tag="PageAnalytics::Data_Active" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    [<Resource tag="PageAnalytics::Data_Active" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
    [<Resource tag="PageAnalytics::Data_Active" />, "Maria", "Mary", "Maria@exago.com", <Resource tag="PageAnalytics::Data_Birthday" />, <Resource tag="PageAnalytics::Data_Feminine" />],
  ],
  evaluations: [
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "Avaliação experts", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_DayMonthYear" />, "3", "3", "3", "3", "3,4", <Resource tag="PageAnalytics::Data_CommentText" />],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "Avaliação experts", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_DayMonthYear" />, "5", "5", "5", "5", "4,8", <Resource tag="PageAnalytics::Data_CommentText" />],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "Avaliação experts", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_DayMonthYear" />, "4", "4", "4", "4", "4,8", <Resource tag="PageAnalytics::Data_CommentText" />],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "Avaliação experts", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_DayMonthYear" />, "3", "3", "3", "3", "4,8", <Resource tag="PageAnalytics::Data_CommentText" />],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "Avaliação experts", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_DayMonthYear" />, "3", "3", "3", "3", "4,8", <Resource tag="PageAnalytics::Data_CommentText" />],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "Avaliação experts", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_DayMonthYear" />, "4", "4", "4", "4", "4,8", <Resource tag="PageAnalytics::Data_CommentText" />],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "Avaliação experts", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_DayMonthYear" />, "4", "4", "4", "4", "4,3", <Resource tag="PageAnalytics::Data_CommentText" />],
    ["", <Resource tag="PageAnalytics::Data_TitleIdea" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_UsernameTwo" />, "Avaliação experts", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_DayMonthYear" />, "—", "—", "—", "—", "—", "—"],
  ],
  comments: [
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentC1" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_Idea" />, <Resource tag="PageAnalytics::Data_TitleIdea" />, "", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "15", <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, "—"],
    [<Resource tag="PageAnalytics::Data_Answer" />, <Resource tag="PageAnalytics::Data_CommentC2" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_Idea" />, <Resource tag="PageAnalytics::Data_TitleIdea" />, "", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "7", <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_CommentC4" />],
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentC3" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_Idea" />, <Resource tag="PageAnalytics::Data_TitleIdea" />, "", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "0", <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, "—"],
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentText" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_Inspiration" />, <Resource tag="PageAnalytics::Data_TitleInspiration" />, "", "—", <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "6", <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, "—"],
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentText" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_News" />, <Resource tag="PageAnalytics::Data_NewsTitle" />, "", "—", "—", "0", <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, "—"],
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentText" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_Idea" />, <Resource tag="PageAnalytics::Data_TitleIdea" />, "", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "0", <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, "—"],
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentText" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_Idea" />, <Resource tag="PageAnalytics::Data_TitleIdea" />, "", <Resource tag="PageAnalytics::Data_PhaseName" />, <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "0", <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, "—"],
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentText" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_Inspiration" />, <Resource tag="PageAnalytics::Data_TitleInspiration" />, "", "—", <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "0", <Resource tag="PageAnalytics::Data_Yes" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, "—"],
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentText" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_News" />, <Resource tag="PageAnalytics::Data_NewsTitle" />, "", "—", "—", "0", <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_Yes" />, "2", <Resource tag="PageAnalytics::Data_Yes" />, "—"],
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentText" />, <Resource tag="PageAnalytics::Data_Public" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_Inspiration" />, <Resource tag="PageAnalytics::Data_TitleInspiration" />, "", "—", <Resource tag="PageAnalytics::Data_ChallengeTitle" />, "0", <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, "—"],
    [<Resource tag="PageAnalytics::Data_Principal" />, <Resource tag="PageAnalytics::Data_CommentText" />, <Resource tag="PageAnalytics::Data_Private" />, <Resource tag="PageAnalytics::Data_Username" />, <Resource tag="PageAnalytics::Data_Username" />, "Maria@exago.com", <Resource tag="PageAnalytics::Data_News" />, <Resource tag="PageAnalytics::Data_NewsTitle" />, "", "—", "—", "0", <Resource tag="PageAnalytics::Data_No" />, <Resource tag="PageAnalytics::Data_No" />, "0", <Resource tag="PageAnalytics::Data_No" />, "—"],
  ],
  userAggregate:[]
};

const Reports = (store: IStateProps) => {

  const classes = useStyles();

  const { profile, workspaceId } = store;

  const [analiticType, setAnalictType] = useState("");

  const [table, setTable] = useState({
    columns: Array(0),
    data: Array(0)
  });

  const listReports = [
    {
      title: "PageAnalytics::Title_Userlist",
      value: "users"
    },
    {
      title: "PageAnalytics::Title_Grouplist",
      value: "usergroups"
    },
    {
      title: "PageAnalytics::Title_Ideas",
      value: "ideas"
    },
    {
      title: "PageAnalytics::Title_Prizes",
      value: "prizes"
    },
    {
      title: "PageAnalytics::Title_Evaluations",
      value: "evaluations"
    },
    {
      title: "PageAnalytics::Title_Comments",
      value: "comments"
    },
    {
      title: "PageAnalytics::Title_UserAggregate",
      value: "userAggregate"
    }
  ]

  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelect = (e: any) => {

    setAnalictType(e.target.value);

    switch (e.target.value) {
      case "users":
        api_v3.post(
          "reports/users",
          {
            WorkspaceId: workspaceId,
            Preview: true
          }
        ).then(r => {
          let arr: any[] = [];
          r.data.data.forEach((item: any) => {
            arr.push([
              item.status,
              item.firstLogin,
              item.dates,
              item.lastLogin,
              item.visits,
              item.groups,
              item.permission,
              item.fullName,
              item.userName,
              item.email,
              null, // Campo genérico 1
              null, // Campo genérico 2
              item.submittedIdeas,
              item.decidedToImplementIdeas,
              item.inspirations,
              item.comments,
              null, //Comentários relatados
              item.evaluationsStars,
              item.investments,
              item.prizes,
            ]);
          });

          setTable({
            columns: columns.users,
            data: arr,
          });

        });
        break;
      case "prizes":
        api_v3.post(
          "reports/prizes",
          {
            WorkspaceId: workspaceId,
            Preview: true
          }
        ).then(r => {
          let arr: any[] = [];
          r.data.data.forEach((item: any) => {
            arr.push([
              item.status,
              item.firstLogin,
              item.dates,
              item.lastLogin,
              item.visits,
              item.groups,
              item.permission,
              item.fullName,
              item.userName,
              item.email,
              null, // Campo genérico 1
              null, // Campo genérico 2
              item.submittedIdeas,
              item.decidedToImplementIdeas,
              item.inspirations,
              item.comments,
              null, //Comentários relatados
              item.evaluationsStars,
              item.investments,
              item.prizes,
            ]);
          });

          setTable({
            columns: columns.prizes,
            data: arr,
          });
        });
        break;
      case "ideas":
        api_v3.post(
          "reports/ideas",
          {
            WorkspaceId: workspaceId,
            Preview: true
          }
        ).then(r => {
          let arr: any[] = [];
          r.data.data.forEach((item: any) => {
            arr.push([
              item.url,
              item.ideaTitle,
              item.description,
              item.challengeTitle,
              item.tags,
              item.generic1,
              item.generic2,
              item.author,
              item.coAuthors,
              item.ideaStatus,
              item.collaborativeAvg,
              item.collaborativeCommentsCount,
              item.collaborativeEvaluation,
              item.collaborativeSubmitionDate,
              item.developmentCommentsCount,
              item.developmentEndDate,
              item.developmentGeneric1,
              item.developmentGeneric2,
              item.developmentResponsibles,
              item.developmentSubmitionDate,
              item.expertReviewAvg,
              item.expertReviewCommentsCount,
              item.expertReviewEvaluationsCount,
              item.expertReviewResponsibles,
              item.expertReviewTransitionDate,
              item.impact,
              item.return,
              item.updateAt,
              item.validationCommentsCount,
              item.validationResponsibles,
              item.validationSubmitionDate
            ]);
          });

          setTable({
            columns: columns.ideas,
            data: arr,
          });
        });
        break;
      case "usergroups":
        api_v3.post(
          "reports/users-groups",
          {
            WorkspaceId: workspaceId,
            Preview: true
          }
        ).then(r => {
          let arr: any[] = [];
          r.data.data.forEach((item: any) => {
            arr.push([
              item.status,
              item.firstLogin,
              item.dates,
              item.lastLogin,
              item.visits,
              item.groups,
              item.permission,
              item.fullName,
              item.userName,
              item.email,
              null, // Campo genérico 1
              null, // Campo genérico 2
              item.submittedIdeas,
              item.decidedToImplementIdeas,
              item.inspirations,
              item.comments,
              null, //Comentários relatados
              item.evaluationsStars,
              item.investments,
              item.prizes,
            ]);
          });

          setTable({
            columns: columns.usergroups,
            data: arr,
          });
        });
        break;
      case "evaluations":
        api_v3.post(
          "reports/evaluations",
          {
            WorkspaceId: workspaceId,
            Preview: true
          }
        ).then(r => {
          let arr: any[] = [];
          r.data.data.forEach((item: any) => {
            arr.push([
              item.status,
              item.firstLogin,
              item.dates,
              item.lastLogin,
              item.visits,
              item.groups,
              item.permission,
              item.fullName,
              item.userName,
              item.email,
              null, // Campo genérico 1
              null, // Campo genérico 2
              item.submittedIdeas,
              item.decidedToImplementIdeas,
              item.inspirations,
              item.comments,
              null, //Comentários relatados
              item.evaluationsStars,
              item.investments,
              item.prizes,
            ]);
          });

          setTable({
            columns: columns.evaluations,
            data: arr,
          });
        });
        break;
      case "comments":
        api_v3.post(
          "reports/comment",
          {
            WorkspaceId: workspaceId,
            Preview: true
          }
        ).then(r => {
          let arr: any[] = [];
          r.data.data.forEach((item: any) => {
            arr.push([
              item.status,
              item.firstLogin,
              item.dates,
              item.lastLogin,
              item.visits,
              item.groups,
              item.permission,
              item.fullName,
              item.userName,
              item.email,
              null, // Campo genérico 1
              null, // Campo genérico 2
              item.submittedIdeas,
              item.decidedToImplementIdeas,
              item.inspirations,
              item.comments,
              null, //Comentários relatados
              item.evaluationsStars,
              item.investments,
              item.prizes,
            ]);
          });

          setTable({
            columns: columns.comments,
            data: arr,
          });
        });
        break;
      case "userAggregate":
        setTable({
          columns: columns.userAggregate,
          data: data.userAggregate
        });
        break;
      default:
        break;
    }
  }

  return (
    <Box>
      <Grid container>
        <Box className={classes.helperText}>
          <Typography variant="h4">
            <Resource tag="PageAnalytics::Choose" />
          </Typography>
          <Typography variant="body1">
            <Resource tag="PageAnalytics::Choose_sub1" />
          </Typography>
          <Typography variant="body1">
            <Resource tag="PageAnalytics::Choose_sub2" /> <b>{profile.email}</b>
          </Typography>
        </Box>
        <Grid
          container
          className={classes.container}
        >
          <Grid
            container
            alignItems="flex-end"
            justify="space-between"
          >
            <Grid item xs={12} sm={5}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageAnalytics::Label_report_type"}
                />
              </Typography>
              <Select
                defaultValue={"defaultSelect"}
                style={{ marginTop: "16px" }}
                name="challengeId-select"
                labelId={""}
                onChange={handleSelect}
                id="challengeId-select"
                fullWidth
              >
                <MenuItem selected disabled value="defaultSelect">
                  <Resource tag="PageAnalytics::Report_type" />
                </MenuItem>
                {
                  listReports.map((data) => {
                    return (
                      <MenuItem
                        key={data.value}
                        value={data.value}
                      >
                        <Resource
                          tag={data.title}
                        />
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>

            <Grid item xs={3}>
              <Box textAlign="right">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!analiticType}
                  onClick={() => {
                    analiticType && handleOpen();
                  }}
                  style={{ color: "#ffffff" }}
                >
                  <Resource
                    tag={"PageAnalytics::Export"}
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.tableContainer}>
            <TableContainer component={Paper}>
              <div className={classes.badge}>
                <Resource tag="PageAnalytics::Sample" />
              </div>
              <Table aria-label="customized table" size="small">
                <TableHead>
                  <TableRow>
                    {
                      table.columns.map((column: any, index: number) => {
                        return <StyledTableCell key={index}>{
                          <Resource tag={column} />
                        }</StyledTableCell>
                      })
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table.data?.map((row, index) => (
                    <StyledTableRow key={index}>{
                      row.map((cell: any, index: number) => {
                        return <StyledTableCell key={index}>
                          {cell}
                        </StyledTableCell>
                      })
                    }
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={isOpen} maxWidth="md" fullWidth className={classes.dialog}>
        <MuiDialogTitle className={classes.dialogHeader}>
          <Typography color="textPrimary" className={classes.titleHeader}>
            <Resource
              tag={"PageAnalytics::Export"}
            />
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Divider className={classes.dividerHeader} />
        <ModalSelectFilterAnalytics
          closeButton={handleClose}
          path={analiticType}
        ></ModalSelectFilterAnalytics>
      </Dialog>
    </Box>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => {
  return {

  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    profile: store.profileState.profileData,
    workspaceId: store.authState.auth.workspaceId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);