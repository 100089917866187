import WorkflowCardDropable from "../../../../components/Workflow/WorkflowDropableCard";
import AddIcon from "@material-ui/icons/Add";
import React, { useCallback, useState } from "react";
import { PageState, PageStateWorkflow } from "redux/types/createChallenge";
import PopupSaveTemplate from "../../../../components/Workflow/WorkflowDialogs/PopupSaveTemplate";
import DraggableButton from "../../../../components/Draggable/DraggableButton/DraggableButton";
import PopupClearAllWorkflow from "../../../../components/Workflow/WorkflowDialogs/PopupClearAllWorkflow";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { ICultureState } from "redux/reducers/cultureReducer";
import Resource, { resource } from "components/Resources/Resource";
import WorkflowCard from "components/Workflow/WorkflowCard";
import { IAppState } from "redux/store/Store";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  deleteWorkflowAllPhaseAction,
  deleteWorkflowPhaseAction,
  getWorkflowAction,
  postChallengeDetailValidationPhaseAction,
  postWfPhaseCollaboration,
  postWfPhaseDevelopment,
  postWfPhaseExpertReview,
  postWfTemplateAction,
  postWfTemplateByChallengeAction,
  putChallengeDetailValidationPhaseAction,
  putPhaseOrderAction,
  putWfPhaseCollaboration,
  putWfPhaseDevelopment,
  putWfPhaseExpertReview,
  WorkflowActionStatus,
  getWorkflowTemplateAction,
} from "redux/actions/Workflow/WorkflowActions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsDivider: {
      [theme.breakpoints.up("md")]: {
        borderRight: `1px solid ${theme.palette.primary.main}`,
      },
    },
    clearButtonDiv: {
      display: "flex",
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-end",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        marginTop: 12,
      },
    },
  })
);

interface IProps {
  culture: ICultureState;
  challengeId: string;
  workspaceId: string;
  workflowState: PageStateWorkflow;
  getChallengeDetails: () => void;
  templates: any;
  templateStatus: string;
  pageState: PageState;
}

interface IDispatchProps {
  getWorkflow: (params: object, culture:ICultureState) => Promise<any>;
  postValidation: (params: object, culture:ICultureState) => Promise<any>;
  putValidation: (params: object, culture:ICultureState) => Promise<any>;
  postTemplate: (params: object, culture:ICultureState) => Promise<any>;
  clearWorkflow: (params: object, culture:ICultureState) => Promise<any>;
  setChallengeTemplate: (params: object, culture:ICultureState) => Promise<any>;
  postCollaboration: (params: object, culture:ICultureState) => Promise<any>;
  putCollaboration: (params: object, culture:ICultureState) => Promise<any>;
  postDevelopment: (params: object, culture:ICultureState) => Promise<any>;
  putDevelopment: (params: object, culture:ICultureState) => Promise<any>;
  postExpertReview: (params: object, culture:ICultureState) => Promise<any>;
  putExpertReview: (params: object, culture:ICultureState) => Promise<any>;
  deletePhase: (params: object, culture:ICultureState) => Promise<any>;
  orderPhase: (params: object, culture:ICultureState) => Promise<any>;
  getTemplates: (params: object, culture: ICultureState) => Promise<any>;
}

function CreateChallengeStepWorkflow(props: IProps & IDispatchProps) {
  const {
    culture,
    challengeId,
    workspaceId,
    workflowState,
    getWorkflow,
    postValidation,
    postCollaboration,
    postDevelopment,
    postExpertReview,
    putValidation,
    putCollaboration,
    putDevelopment,
    putExpertReview,
    postTemplate,
    templates,
    clearWorkflow,
    setChallengeTemplate,
    templateStatus,
    deletePhase,
    orderPhase,
    pageState,
    getTemplates
  } = props;

  const classes = useStyles();
  const [saveTemplateOpen, setSaveTemplateOpen] = useState(false);
  const [clearAllOpen, setClearAllOpen] = useState(false);
  const { cards, workflowStatus } = workflowState;
  const [loadingCards, setLoadingCards] = useState(false);
  const [templateSelect, setTemplateSelect] = useState("");

  const { cultureTag } = culture?.culture ?? "";

  const handleClear = useCallback(() => {
    clearWorkflow({ challengeId, workspaceId }, culture).then(() => {
      getWorkflow({ workspaceId, challengeId, cultureTag }, culture);
    });
    // eslint-disable-next-line
  }, []);

  const handleDrop = async (item: any, index: number) => {
    setLoadingCards(true);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endDate = workflowState.cards?.[workflowState.cards.length-1]?.endDate || tomorrow;
    const challengeTypePermantent = pageState.stepDetail.challengeType === 2;
    
    switch (item.data.type) {
      case 1:
        await postValidation({
          challengeId,
          workspaceId,
          order: cards.length + 1,
          endDate: challengeTypePermantent ? null : endDate,
          endDays: challengeTypePermantent ? 1 : 0,
          culture,
          cultures: [
            {
              title: resource(culture, "PageCreateChallenge::Workflow_Validation"),
              description: resource(culture, "PageCreateChallenge::Workflow_Validation"),
              cultureTag,
            },
          ],
        }, culture);
        break;
      case 2:
        await postCollaboration({
          challengeId,
          workspaceId,
          order: cards.length + 1,
          endDate: challengeTypePermantent ? null : endDate,
          endDays: challengeTypePermantent ? 1 : 0,
          culture,
          isAutomaticFlow: true,
          isLastPhase: false,
          criterias: [],
          responsibles: [],
          endTransitionDate: challengeTypePermantent ? null : endDate,
          endTransitionDays: challengeTypePermantent ? 1 : 0,
          evaluationType: 1,
          flowRuleAverage: 1,
          flowRuleParticipants: 1,
          cultures: [
            {
              title: resource(culture, "PageCreateChallenge::Workflow_Collaboration"),
              description: resource(culture, "PageCreateChallenge::Workflow_Collaboration"),
              cultureTag,
            },
          ],
        }, culture);
        break;
      case 3:
        await postDevelopment({
          allowCoAuthorEdit: false,
          allowAuthorEdit: false,
          allowResponsiblesChooseEditors: true,
          usersAllowed: [],
          ideaForms: [],
          workspaceId,
          challengeId,
          endDate: challengeTypePermantent ? null : endDate,
          endDays: challengeTypePermantent ? 1 : 0,
          cultures: [
            {
              title: resource(culture, "PageCreateChallenge::Workflow_Development"),
              description: resource(culture, "PageCreateChallenge::Workflow_Development"),
              cultureTag,
            },
          ],
          responsibles: [],
          isAutomaticFlow: true,
          isLastPhase: false,
          order: cards.length + 1,
          endTransitionDate: challengeTypePermantent ? null : endDate,
          endTransitionDays: challengeTypePermantent ? 1 : 0,
        }, culture);
        break;
      case 4:
        await postExpertReview({
          criterias: [],
          reviewers: [],
          flowRuleAverage: 1,
          flowRuleParticipants: 1,
          workspaceId,
          challengeId,
          endDate: challengeTypePermantent ? null : endDate,
          endDays: challengeTypePermantent ? 1 : 0,
          cultures: [
            {
              title: resource(culture, "PageCreateChallenge::Workflow_ExpertReview"),
              description: resource(culture, "PageCreateChallenge::Workflow_ExpertReview"),
              cultureTag,
            },
          ],
          responsibles: [],
          isAutomaticFlow: true,
          isLastPhase: false,
          order: cards.length + 1,
          endTransitionDate: challengeTypePermantent ? null : endDate,
          endTransitionDays: challengeTypePermantent ? 1 : 0,
        }, culture);
        break;
      default:
        setLoadingCards(false);
    }

    getWorkflow({ workspaceId, challengeId, cultureTag }, culture).then(() => {
      setLoadingCards(false);
    });
  };

  const handleMove = useCallback(
    (newOrder, phaseId) => {
      orderPhase({ challengeId, workspaceId, newOrder, phaseId }, culture).then(() =>
        getWorkflow({ workspaceId, challengeId, cultureTag }, culture)
      );
    },
    [challengeId, workspaceId, orderPhase, getWorkflow, cultureTag, culture]
  );

  const handleSaveValue = useCallback(() => {
    getWorkflow({ workspaceId, challengeId, cultureTag }, culture).then(() => {
      setLoadingCards(false);
    });
  }, [getWorkflow, workspaceId, challengeId, cultureTag, setLoadingCards, culture]);

  const postTemplate2 = async (title: string) => {
    await postTemplate({ workspaceId, challengeId, title }, culture);
    await setCLickCmbModel();
  };

  const setCLickCmbModel = () => {
    Promise.all([getTemplates({ workspaceId }, culture)]).finally();
  };

  const setTemplate = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;

    setChallengeTemplate({
      challengeId,
      workspaceId,
      workflowTemplateId: value,
    }, culture).then(() => {
      getWorkflow({ workspaceId, challengeId, cultureTag }, culture).then(() => {
        setLoadingCards(false);
      });
    });
    templates?.result?.map((template: any) => {
      if (template.workflowTemplateId == e.target.value) {
        setTemplateSelect(template.title);
      }
    });
  };

  const deleteCard = (phaseId: string) => {
    deletePhase({ phaseId, workspaceId, challengeId }, culture).then(() => {
      getWorkflow({ workspaceId, challengeId, cultureTag }, culture);
    });
  };

  return (
    <Box>
      <Box mt={1} mb={4}>
        <Grid container>
          <Grid item sm={12} md={8}>
            <Typography variant="h4" color="textPrimary">
              <Resource tag="PageCreateChallenge::Workflow_DefineFlow" />
            </Typography>
            <Typography variant="body2" color="textPrimary">
              <Resource tag="PageCreateChallenge::Workflow_Create" />
            </Typography>
          </Grid>
          <Grid item sm={12} md={4}>
            <TextField
              select
              label={<Resource tag="PageCreateChallenge::Workflow_Template" />}
              fullWidth
              defaultValue="0"
              onChange={setTemplate}
              onClick={setCLickCmbModel}
              key={"selectTemplate"}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem selected disabled value="0">
                <Typography variant="body2" color="textSecondary">
                  <Resource tag="PageCreateChallenge::Workflow_SelectTemplate" />
                </Typography>
              </MenuItem>
              {templates &&
                templates?.result?.map((template: any) => (
                  <MenuItem value={template.workflowTemplateId} key={template.workflowTemplateId}>
                    <Typography variant="body2" color="textSecondary">
                      {template.title}
                    </Typography>
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Box mb={2}>
          <Typography variant="body2" color="textPrimary">
            <Resource tag="PageCreateChallenge::Workflow_ChoosePhases" />
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              display="flex"
              flexDirection="column"
              textAlign="center"
              py={1}
              px={2}
              justifyContent="center"
              alignSelf="center"
              className={classes.buttonsDivider}
            >
              <DraggableButton name={<Resource tag="PageCreateChallenge::Workflow_Validation" />} data={{ type: 1 }} />
              <Box mt={1}>
                <Typography variant="caption" color="textSecondary">
                  <Resource tag="PageCreateChallenge::Workflow_Validation_Tip" />
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              display="flex"
              flexDirection="column"
              textAlign="center"
              py={1}
              px={2}
              justifyContent="center"
              alignSelf="center"
              className={classes.buttonsDivider}
            >
              <DraggableButton
                name={<Resource tag="PageCreateChallenge::Workflow_Collaboration" />}
                data={{ type: 2 }}
              />
              <Box mt={1}>
                <Typography variant="caption" color="textSecondary">
                  <Resource tag="PageCreateChallenge::Workflow_Collaboration_Tip" />
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              display="flex"
              flexDirection="column"
              textAlign="center"
              py={1}
              px={2}
              justifyContent="center"
              alignSelf="center"
              className={classes.buttonsDivider}
            >
              <DraggableButton name={<Resource tag="PageCreateChallenge::Workflow_Development" />} data={{ type: 3 }} />
              <Box mt={1}>
                <Typography variant="caption" color="textSecondary">
                  <Resource tag="PageCreateChallenge::Workflow_Development_Tip" />
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              display="flex"
              flexDirection="column"
              textAlign="center"
              py={1}
              px={2}
              justifyContent="center"
              alignSelf="center"
            >
              <DraggableButton
                name={<Resource tag="PageCreateChallenge::Workflow_ExpertReview" />}
                data={{ type: 4 }}
              />
              <Box mt={1}>
                <Typography variant="caption" color="textSecondary">
                  <Resource tag="PageCreateChallenge::Workflow_ExpertReview_Tip" />
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box mt={5} width="100%">
          <Grid container style={{ alignItems: "center" }}>
            <Grid item sm={12} md={7}>
              <Typography variant="body2" color="textPrimary">
                <Resource tag="PageCreateChallenge::Workflow_SetConfigurations" />
                <p>
                  <strong><Resource tag="PageCreateChallenge::ChosenTemplate" /></strong>
                  {" " + templateSelect}
                </p>
              </Typography>
            </Grid>
            <Grid item sm={12} md={5}>
              <Box className={classes.clearButtonDiv}>
                <Button style={{ marginRight: 8 }}  disabled={cards.length === 0} color="primary" onClick={() => setClearAllOpen(true)}>
                  <Resource tag="PageCreateChallenge::Workflow_ClearAll" />
                </Button>
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={() => setSaveTemplateOpen(true)}
                  disabled={cards.length === 0}
                >
                  <Resource tag="PageCreateChallenge::Workflow_SaveTemplate" />
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box bgcolor={"#F2F2F2"} mt={2} pt={2} px={2} minHeight="480px" borderRadius={11}>
            {(workflowStatus === WorkflowActionStatus.GET_WORKFLOW_LOADING ||
              templateStatus === WorkflowActionStatus.GET_WORKFLOW_TEMPLATE_LOADING) &&
            !loadingCards ? (
              <Box
                textAlign="center"
                height="448px"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box display="flex" width="100%" minHeight="464px" overflow="auto" pb={2}>
                {cards.map((card: any, index: number) => (
                  <WorkflowCard
                    key={card.phaseId || card.workflowTemplatePhaseId}
                    card={card}
                    index={index}
                    onMove={handleMove}
                    handleSaveValue={handleSaveValue}
                    challengeId={challengeId}
                    deleteCard={deleteCard}
                  />
                ))}
                <WorkflowCardDropable
                  onDrop={(item: any, index: number) => handleDrop(item, index)}
                  loading={loadingCards}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <PopupClearAllWorkflow open={clearAllOpen} setOpen={setClearAllOpen} confirmAction={handleClear} />
      <PopupSaveTemplate
        open={saveTemplateOpen}
        setOpen={setSaveTemplateOpen}
        culture={culture}
        saveTemplateAction={postTemplate2}
      />
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => ({
  cards: store.createChallengeState.pageState.stepWorkflow.cards,
  templateStatus: store.createChallengeState.pageState.stepWorkflow.templates.status,
  templates: store.createChallengeState.pageState.stepWorkflow.templates.templates,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => ({
  getWorkflow: (params: object, culture:ICultureState) => dispatch(getWorkflowAction(params,culture)),
  postValidation: (params: object, culture:ICultureState) => dispatch(postChallengeDetailValidationPhaseAction(params,culture)),
  putValidation: (params: object, culture:ICultureState) => dispatch(putChallengeDetailValidationPhaseAction(params, culture)),
  postTemplate: (params: object, culture:ICultureState) => dispatch(postWfTemplateAction(params, culture)),
  clearWorkflow: (params: object, culture:ICultureState) => dispatch(deleteWorkflowAllPhaseAction(params,culture)),
  setChallengeTemplate: (params: object, culture:ICultureState) => dispatch(postWfTemplateByChallengeAction(params, culture)),
  postCollaboration: (params: object, culture:ICultureState) => dispatch(postWfPhaseCollaboration(params, culture)),
  putCollaboration: (params: object, culture:ICultureState) => dispatch(putWfPhaseCollaboration(params, culture)),
  postDevelopment: (params: object, culture:ICultureState) => dispatch(postWfPhaseDevelopment(params, culture)),
  putDevelopment: (params: object, culture:ICultureState) => dispatch(putWfPhaseDevelopment(params, culture)),
  postExpertReview: (params: object, culture:ICultureState) => dispatch(postWfPhaseExpertReview(params, culture)),
  putExpertReview: (params: object, culture:ICultureState) => dispatch(putWfPhaseExpertReview(params, culture)),
  deletePhase: (params: object, culture:ICultureState) => dispatch(deleteWorkflowPhaseAction(params, culture)),
  orderPhase: (params: object, culture:ICultureState) => dispatch(putPhaseOrderAction(params, culture)),
  getTemplates: (params: object, culture: ICultureState) => dispatch(getWorkflowTemplateAction(params, culture)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateChallengeStepWorkflow);
