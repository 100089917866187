import { ThunkAction } from "redux-thunk";
import reactNewApp from "utils/reactNewApp";
import { ActionCreator, Dispatch } from "redux";
import { treatError } from "../../utils/treatError";
import { api_v2, api_v3, api_general } from "./../../config/api";
import { IChallengeState } from "redux/reducers/challenges/challengeReducer";
import {
  IIdeaForm,
  IChallengesTops,
  IChallengesPermanent,
  IChallengesInProgress,
  IChallengesClosed,
  IIdeaFormV3,
  ChallengeDrafts,
  IChallengeAllList,
  IChallengeActive,
} from "redux/types/challenges";

const basePath = "challenges";

// Create Action Constants
export enum ChallengeActionTypes {
  GET_ALL_CHALLENGE = "GET_ALL_CHALLENGE",
  GET_IDEA_FORM = "GET_IDEA_FORM",
  SHOW_MORE = "SHOW_MORE",
  GET_CHALLENGE_TOPS = "GET_CHALLENGE_TOPS",
  GET_CHALLENGE_PERMANET = "GET_CHALLENGE_PERMANET",
  GET_CHALLENGE_INPROGRESS = "GET_CHALLENGE_INPROGRESS",
  GET_CHALLENGE_CLOSED = "GET_CHALLENGE_CLOSED",
  GET_ACTIVE_CHALLENGES = "GET_ACTIVE_CHALLENGES",
  GET_HIGHLIGHTED_CHALLENGES = "GET_HIGHLIGHTED_CHALLENGES",
  GET_CHALLENGE_LIST = "GET_CHALLENGE_LIST",
  GET_ALL_CHALLENGE_LIST = "GET_ALL_CHALLENGE_LIST",

  GET_IDEA_FORM_V3_LOADING = "GET_IDEA_FORM_V3_LOADING",
  GET_IDEA_FORM_V3_SUCCESS = "GET_IDEA_FORM_V3_SUCCESS",
  GET_IDEA_FORM_V3_FAIL = "GET_IDEA_FORM_V3_FAIL",

  GET_CHALLENGE_DRAFTS = "GET_CHALLENGES_DRAFTS",
  PUT_END_SUBMISSION = "PUT_END_SUBMISSION",
  PATCH_HIGHLIGHT_CHALLENGE = "PATCH_HIGHLIGHT_CHALLENGE",
  POST_CHALLENGE_DUPLICATE = "POST_CHALLENGE_DUPLICATE",
  GET_ALL_CHALLENGE_INSPIRATION = "GET_ALL_CHALLENGE_INSPIRATION",
}

export enum ChallengeStatusTypes {
  CHALLENGE_LOADING = "LOADING",
  CHALLENGE_SUCCESS = "SUCCESS",
  CHALLENGE_FAIL = "FAIL",
}

export interface IChallengeIdeaFormV3GetLoadingAction {
  type: ChallengeActionTypes.GET_IDEA_FORM_V3_LOADING;
  ideaFormV3Status: string;
}

export interface IChallengeIdeaFormV3GetSuccessAction {
  type: ChallengeActionTypes.GET_IDEA_FORM_V3_SUCCESS;
  ideaFormV3Status: string;
  ideasFormV3: IIdeaFormV3[];
}

export interface IChallengeIdeaFormV3GetFailAction {
  type: ChallengeActionTypes.GET_IDEA_FORM_V3_FAIL;
  ideaFormV3Status: string;
  error: any;
}

export interface IChallengeGetAllAction {
  type: ChallengeActionTypes.GET_ALL_CHALLENGE;
  challengesPermanent: IChallengesPermanent;
  challengesInProgress: IChallengesInProgress;
  challengesClosed: IChallengesClosed;
  activeChallenges: any;
  highlightedChallenges: any;
  challengeDrafts: ChallengeDrafts;
  challengeAllList:IChallengeAllList[];
  status: string;
}

export interface IChallengeGetAllListAction {
  type: ChallengeActionTypes.GET_ALL_CHALLENGE_LIST;
  challengeAllList: any;
}

export interface IChallengeGetNewListAction {
  type: ChallengeActionTypes.GET_CHALLENGE_LIST;
  challengeList: any;
  isInfinite: boolean;
}

export interface IChallengeGetIdeaFormAction {
  type: ChallengeActionTypes.GET_IDEA_FORM;
  ideasForm: IIdeaForm;
  status: string;
}

export interface IChallengeTopGetAction {
  type: ChallengeActionTypes.GET_CHALLENGE_TOPS;
  challengesTop: IChallengesTops;
}

export interface IChallengePermanentGetAction {
  type: ChallengeActionTypes.GET_CHALLENGE_PERMANET;
  challengesPermanent: IChallengesPermanent;
}

export interface IChallengeInProgressGetAction {
  type: ChallengeActionTypes.GET_CHALLENGE_INPROGRESS;
  challengesInProgress: IChallengesInProgress;
}

export interface IChallengeClosedGetAction {
  type: ChallengeActionTypes.GET_CHALLENGE_CLOSED;
  challengesClosed: IChallengesClosed;
}

export interface IChallengeGetActiveAction {
  type: ChallengeActionTypes.GET_ACTIVE_CHALLENGES;
  activeChallenges: any;
}

export interface IChallengeGetHighlightedAction {
  type: ChallengeActionTypes.GET_HIGHLIGHTED_CHALLENGES;
  highlightedChallenges: any;
}

export interface IChallengeShowMoreAction {
  type: ChallengeActionTypes.SHOW_MORE;
}

export interface GetChallengeDrafts {
  type: ChallengeActionTypes.GET_CHALLENGE_DRAFTS;
  status: string;
  payload: ChallengeDrafts;
  isInfinite: boolean;
}

export interface PutEndSubmission {
  type: ChallengeActionTypes.PUT_END_SUBMISSION;
  status: string;
}

export interface PatchHighlightChallenge {
  type: ChallengeActionTypes.PATCH_HIGHLIGHT_CHALLENGE;
  status: string;
}

export interface PostChallengeDuplicate {
  type: ChallengeActionTypes.POST_CHALLENGE_DUPLICATE;
  status: string;
}

export interface GetChallengeInspiration {
  type: ChallengeActionTypes.GET_ALL_CHALLENGE_INSPIRATION;
  challengeList: any;
  isInfinite: boolean;
}

export type ChallengeActions =
  | IChallengeIdeaFormV3GetLoadingAction
  | IChallengeIdeaFormV3GetSuccessAction
  | IChallengeIdeaFormV3GetFailAction
  | IChallengeGetAllAction
  | IChallengeShowMoreAction
  | IChallengeGetIdeaFormAction
  | IChallengeTopGetAction
  | IChallengePermanentGetAction
  | IChallengeInProgressGetAction
  | IChallengeClosedGetAction
  | IChallengeGetActiveAction
  | IChallengeGetHighlightedAction
  | IChallengeGetNewListAction
  | IChallengeGetAllListAction
  | GetChallengeDrafts
  | PutEndSubmission
  | PatchHighlightChallenge
  | PostChallengeDuplicate
  | GetChallengeInspiration;

export const showMoreChallenges: ActionCreator<IChallengeShowMoreAction> = () => ({
  type: ChallengeActionTypes.SHOW_MORE,
});

export const getAllChallenges: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ChallengeActionTypes.GET_ALL_CHALLENGE,
        status: ChallengeStatusTypes.CHALLENGE_LOADING,
      });
      reactNewApp
        ? await api_general.get(`${basePath}?pageSize=10&pageNumber=1`, {}).then((response) => {
          dispatch({
            activeChallenges: response.data.challengesActive,
            highlightedChallenges: response.data.challengesHighlighted,
            challengesClosed: response.data.challengesClosed,
            challengesInProgress: response.data.challengesInProgress,
            challengesPermanent: response.data.challengesPermanent,
            challengeDrafts: response.data.challengesDraft,
            challengeAllList: [
              ...response.data.challengesActive.result,
              ...response.data.challengesHighlighted.result,
              ...response.data.challengesClosed.result,
              ...response.data.challengesInProgress.result,
              ...response.data.challengesPermanent.result,
            ],
            type: ChallengeActionTypes.GET_ALL_CHALLENGE,
            status: ChallengeStatusTypes.CHALLENGE_SUCCESS,
          });
        })
        : await api_v3.get(`${basePath}`, {}).then((response) => {
          dispatch({
            activeChallenges: response.data.challengesActive,
            highlightedChallenges: response.data.challengesHighlighted,
            challengesClosed: response.data.challengesClosed,
            challengesInProgress: response.data.challengesInProgress,
            challengesPermanent: response.data.challengesPermanent,
            challengeAllList: [
              ...response.data.challengesActive.result,
              ...response.data.challengesHighlighted.result,
              ...response.data.challengesClosed.result,
              ...response.data.challengesInProgress.result,
              ...response.data.challengesPermanent.result,
            ],
            type: ChallengeActionTypes.GET_ALL_CHALLENGE,
            status: ChallengeStatusTypes.CHALLENGE_SUCCESS,
          });
        });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
      dispatch({
        type: ChallengeActionTypes.GET_ALL_CHALLENGE,
        status: ChallengeStatusTypes.CHALLENGE_FAIL,
      });
    }
  };
};

export const getIdeasFormV3: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeIdeaFormV3GetSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        ideaFormV3Status: ChallengeStatusTypes.CHALLENGE_LOADING,
        type: ChallengeActionTypes.GET_IDEA_FORM_V3_LOADING,
      });
      let params: any[] = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      await api_general.get(`${basePath}/${options.challengeId}/getideaform${params_qs}`).then((response) => {
        dispatch({
          ideasFormV3: response.data,
          ideaFormV3Status: ChallengeStatusTypes.CHALLENGE_SUCCESS,
          type: ChallengeActionTypes.GET_IDEA_FORM_V3_SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        ideaFormV3Status: ChallengeStatusTypes.CHALLENGE_FAIL,
        type: ChallengeActionTypes.GET_IDEA_FORM_V3_FAIL,
      });
      treatError(options?.culture, "Idea Creation Page v3", err);
    }
  };
};

export const getIdeasForm: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeGetIdeaFormAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general.get(`${basePath}/${options.challengeId}/getideaform${params_qs}`).then((response) => {
          dispatch({
            ideasForm: response.data,
            type: ChallengeActionTypes.GET_IDEA_FORM,
            status: ChallengeStatusTypes.CHALLENGE_SUCCESS,
          });
        })
        : await api_v2.get(`${basePath}/${options.challengeId}/getideaform${params_qs}`).then((response) => {
          dispatch({
            ideasForm: response.data,
            type: ChallengeActionTypes.GET_IDEA_FORM,
          });
        });
    } catch (err) {
      treatError(options?.culture, "Idea Creation Page", err);
    }
  };
};

export const getNewChallengeList: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeGetNewListAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general.get(`${basePath}/list${params_qs}`).then((response) => {
          dispatch({
            challengeList: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_LIST,
            isInfinite
          });
        })
        : await api_v2.get(`${basePath}/list${params_qs}`).then((response) => {
          dispatch({
            challengeList: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_LIST,
            isInfinite
          });
        });
    } catch (err) {
      treatError(options?.culture, "Idea Creation Page", err);
    }
  };
};

export const getAllChallengeList: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeGetAllListAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      reactNewApp
        ? await api_general.get(`${basePath}/listall`).then((result) => {
          dispatch({
            challengeAllList: result.data,
            type: ChallengeActionTypes.GET_ALL_CHALLENGE_LIST,
          });
        })
        : await api_v2.get(`${basePath}/listall`).then((result) => {
          dispatch({
            challengeAllList: result.data,
            type: ChallengeActionTypes.GET_ALL_CHALLENGE_LIST,
          });
        });
    } catch (err) {
      treatError(options?.culture, "Challenge", err);
    }
  };
};

export const getAllChallengesTops: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeTopGetAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      reactNewApp
        ? await api_general.get(`${basePath}/${options.challengeId}/tops`, {}).then((response) => {
          dispatch({
            challengesTop: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_TOPS,
          });
        })
        : await api_v2.get(`${basePath}/${options.challengeId}/tops`, {}).then((response) => {
          dispatch({
            challengesTop: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_TOPS,
          });
        });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
    }
  };
};

export const getAllChallengesPermanent: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengePermanentGetAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general.get(`${basePath}/permanent${params_qs}`, {}).then((response) => {
          dispatch({
            challengesPermanent: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_PERMANET,
          });
        })
        : await api_v2.get(`${basePath}/permanent${params_qs}`, {}).then((response) => {
          dispatch({
            challengesPermanent: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_PERMANET,
          });
        });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
    }
  };
};

export const getActiveChallenges: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeGetActiveAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general.get(`${basePath}/active${params_qs}`, {}).then((response) => {
          dispatch({
            activeChallenges: response.data,
            type: ChallengeActionTypes.GET_ACTIVE_CHALLENGES,
          });
        })
        : await api_v2.get(`${basePath}/active${params_qs}`, {}).then((response) => {
          dispatch({
            activeChallenges: response.data,
            type: ChallengeActionTypes.GET_ACTIVE_CHALLENGES,
          });
        });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
    }
  };
};

export const getAllChallengesInProgress: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeInProgressGetAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general.get(`${basePath}/inprogress${params_qs}`, {}).then((response) => {
          dispatch({
            challengesInProgress: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_INPROGRESS,
          });
        })
        : await api_v2.get(`${basePath}/inprogress${params_qs}`, {}).then((response) => {
          dispatch({
            challengesInProgress: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_INPROGRESS,
          });
        });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
    }
  };
};

export const getAllChallengesClosed: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeClosedGetAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general.get(`${basePath}/closed${params_qs}`, {}).then((response) => {
          dispatch({
            challengesClosed: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_CLOSED,
          });
        })
        : await api_v3.get(`${basePath}/closed${params_qs}`, {}).then((response) => {
          dispatch({
            challengesClosed: response.data,
            type: ChallengeActionTypes.GET_CHALLENGE_CLOSED,
          });
        });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
    }
  };
};

export const getHighlightedChallenges: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  IChallengeGetHighlightedAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general.get(`${basePath}/highlighted${params_qs}`, {}).then((response) => {
          dispatch({
            highlightedChallenges: response.data,
            type: ChallengeActionTypes.GET_HIGHLIGHTED_CHALLENGES,
          });
        })
        : await api_v2.get(`${basePath}/highlighted${params_qs}`, {}).then((response) => {
          dispatch({
            highlightedChallenges: response.data,
            type: ChallengeActionTypes.GET_HIGHLIGHTED_CHALLENGES,
          });
        });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
    }
  };
};

export const getChallengeDraftsAction: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  GetChallengeDrafts
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";
      const isInfinite = options?.isInfinite ?? false;

      dispatch({
        status: ChallengeStatusTypes.CHALLENGE_LOADING,
        type: ChallengeActionTypes.GET_CHALLENGE_DRAFTS,
      });

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      await api_general.get(`${basePath}/draft${params_qs}`, {}).then((response) => {
        dispatch({
          status: ChallengeStatusTypes.CHALLENGE_SUCCESS,
          payload: response.data,
          type: ChallengeActionTypes.GET_CHALLENGE_DRAFTS,
          isInfinite,
        });
      });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
      dispatch({
        status: ChallengeStatusTypes.CHALLENGE_FAIL,
        type: ChallengeActionTypes.GET_CHALLENGE_DRAFTS,
      });
    }
  };
};

export const putEndSubmissionAction: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  PutEndSubmission
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_general.put(`${basePath}/${options.challengeId}/endsubmition`, options).then((response) => {
        dispatch({
          status: ChallengeStatusTypes.CHALLENGE_SUCCESS,
          type: ChallengeActionTypes.PUT_END_SUBMISSION,
        });
      });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
      dispatch({
        status: ChallengeStatusTypes.CHALLENGE_FAIL,
        type: ChallengeActionTypes.PUT_END_SUBMISSION,
      });
    }
  };
};

export const patchHighlightChallengeAction: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  PatchHighlightChallenge
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_general.post(`${basePath}/${options.challengeId}/highlight`, options).then((response) => {
        dispatch({
          status: ChallengeStatusTypes.CHALLENGE_SUCCESS,
          type: ChallengeActionTypes.PATCH_HIGHLIGHT_CHALLENGE,
        });
      });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
      dispatch({
        status: ChallengeStatusTypes.CHALLENGE_FAIL,
        type: ChallengeActionTypes.PATCH_HIGHLIGHT_CHALLENGE,
      });
    }
  };
};

export const postChallengeDuplicateAction: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  PostChallengeDuplicate
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await api_general.post(`${basePath}/duplicate`, options);
      dispatch({
        status: ChallengeStatusTypes.CHALLENGE_SUCCESS,
        type: ChallengeActionTypes.POST_CHALLENGE_DUPLICATE,
      });
      return response.data;
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
      dispatch({
        status: ChallengeStatusTypes.CHALLENGE_FAIL,
        type: ChallengeActionTypes.POST_CHALLENGE_DUPLICATE,
      });
    }
  };
};

export const getAllChallengesInspiration: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeState,
  null,
  GetChallengeInspiration
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";
      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general.get(`${basePath}/listtoinspiration${params_qs}`).then((response) => {
          dispatch({
            challengeList: response.data,
            type: ChallengeActionTypes.GET_ALL_CHALLENGE_INSPIRATION,
            isInfinite
          });
        })
        : await api_v2.get(`${basePath}/list${params_qs}`).then((response) => {
          dispatch({
            challengeList: response.data,
            type: ChallengeActionTypes.GET_ALL_CHALLENGE_INSPIRATION,
            isInfinite
          });
        });
    } catch (err) {
      treatError(options?.culture, "Idea Creation Page", err);
    }
  };
};




export const getChallengesActives = async(options :any): Promise<IChallengeActive | undefined> => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const { data } = await api_v3.get(`${basePath}/active${params_qs}`, {});
      return data;
    } catch (err) {
      return undefined;
    }
};

export const getChallengesInProgress = async(options :any): Promise<IChallengesInProgress | undefined> => {
  try {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }
    if (options.hasOwnProperty("pageSize")) {
      params.push(`pageSize=${options.pageSize}`);
    }
    if (options.hasOwnProperty("pageNumber")) {
      params.push(`pageNumber=${options.pageNumber}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/inprogress${params_qs}`, {});
    return data;
  } catch (err) {
    return undefined;
  }
};

export const getChallengesPermanent = async(options :any): Promise<IChallengesPermanent | undefined> => {
  try {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }
    if (options.hasOwnProperty("pageSize")) {
      params.push(`pageSize=${options.pageSize}`);
    }
    if (options.hasOwnProperty("pageNumber")) {
      params.push(`pageNumber=${options.pageNumber}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/permanent${params_qs}`, {});
    return data;
  } catch (err) {
    return undefined;
  }
};

export const getChallengesClosed = async(options :any): Promise<IChallengesClosed | undefined> => {
  try {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }
    if (options.hasOwnProperty("pageSize")) {
      params.push(`pageSize=${options.pageSize}`);
    }
    if (options.hasOwnProperty("pageNumber")) {
      params.push(`pageNumber=${options.pageNumber}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/closed${params_qs}`, {});
    return data;
  } catch (err) {
    return undefined;
  }
};