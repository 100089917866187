import { Reducer } from "redux";
import {
  CreateChallengesActions,
  CreateChallengesActionTypes,
} from "redux/actions/CreateChallenge/CreateChallengeActions";
import {
  WorkflowActions,
  WorkflowActionStatus,
  WorkflowActionTypes,
} from "redux/actions/Workflow/WorkflowActions";
import {
  CollaborationPopupState,
  DevelopmentPopupState,
  ExpertReviewPopupReview,
  PageState,
  ValidationPopupState,
  IDynamicWorkflow
} from "redux/types/createChallenge";



export interface CreateChallengesState {
  pageState: PageState;
  validationPopupState: ValidationPopupState;
  collaborationPopupState: CollaborationPopupState;
  expertReviewPopupState: ExpertReviewPopupReview;
  developmentPopupState: DevelopmentPopupState;
  workflowStatus: string;
  status: string;
  dynamicWorkFlow: IDynamicWorkflow;
}

const initialState: CreateChallengesState = {
  pageState: {
    stepDetail: {
      challengeType: 1,
      startDate: new Date(),
      endSubmitionDate: new Date(),
      isIdeasVisible: true,
      title: "",
      description: "",
      tags: [],
      image: "",
    },
    stepParticipants: {
      managers: [],
      groups: [],
      allowParticipantsShare: false,
      allowManagersShare: false,
    },
    stepIdeaForm: {
      cards: [],
    },
    stepWorkflow: {
      cards: [],
      workflowStatus: "",
      templates: {
        status: "",
        templates: [],
      },
    },
    notifications: [],
  },
  validationPopupState: {
    basicSettings: {
      title: "",
      description: "",
      isLastPhase: false,
      endDate: new Date(),
      endDays: "0",
      dateType: 1,
    },
    validators: [] as Array<{ userId: string; fullName: string; photo: string }>,
    status: "",
  },
  collaborationPopupState: {
    basicSettings: {
      title: "",
      description: "",
      isLastPhase: false,
      endDate: new Date(),
      endDays: "0",
      dateType: 1,
    },
    evaluation: {
      evaluationType: 1,
      criterias: [],
    },
    flow: {
      isAutomaticFlow: true,
      flowRuleAverage: 1,
      flowRuleParticipants: 1,
      dateType: 1,
      endTransitionDate: new Date(),
      endTransitionDays: "0",
      responsibles: [],
    },
    status: "",
  },

  expertReviewPopupState: {
    basicSettings: {
      title: "",
      description: "",
      isLastPhase: false,
      endDate: new Date(),
      endDays: "0",
      dateType: 1,
    },
    criterias: [],
    reviewers: [],
    flow: {
      isAutomaticFlow: true,
      flowRuleAverage: 1,
      flowRuleParticipants: 1,
      dateType: 1,
      endTransitionDate: new Date(),
      endTransitionDays: "0",
      responsibles: [],
    },
    status: "",
  },
  developmentPopupState: {
    basicSettings: {
      title: "",
      description: "",
      isLastPhase: false,
      endDate: new Date(),
      endDays: "0",
      dateType: 1,
    },
    ideaForm: { cards: [] },
    developers: {
      allowResponsiblesChooseEditors: true,
      allowAuthorEdit: false,
      allowCoAuthorEdit: false,
      usersAllowed: [],
    },
    flow: {
      isAutomaticFlow: true,
      flowRuleAverage: 1,
      flowRuleParticipants: 1,
      dateType: 1,
      endTransitionDate: new Date(),
      endTransitionDays: "0",
      responsibles: [],
    },
    status: "",
  },
  dynamicWorkFlow: {
    optionSelect: [] as Array<{ optionOrder: any; userId: string; ideaCustomFieldId: string; ideaCustomFieldIdValue: string}>
  },
  workflowStatus: "",
  status: ""
};

export const createChallengeReducer: Reducer<CreateChallengesState, CreateChallengesActions | WorkflowActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CreateChallengesActionTypes.GET_CREATE_CHALLENGE_DETAIL:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          stepDetail: action.payload
            ? {
              title: action.payload?.cultures?.[0]?.title,
              description: action.payload?.cultures?.[0]?.description,
              challengeType: action.payload?.challengeType,
              startDate: action.payload?.startDate,
              endSubmitionDate: action.payload?.endSubmitionDate,
              image: action.payload?.image,
              tags: action.payload?.tags,
              isIdeasVisible: action.payload?.isIdeasVisible,
            }
            : initialState.pageState.stepDetail,
          stepParticipants: action.payload
            ? {
              ...state.pageState.stepParticipants,
              groups: action.payload?.groups,
              managers: action.payload?.participants,
              allowManagersShare: action.payload?.allowChallengeManagerShareInspiration,
              allowParticipantsShare: action.payload?.allowParticipantsShareInspiration,
            }
            : initialState.pageState.stepParticipants,
          stepIdeaForm: action.payload
            ? {
              ...state.pageState.stepIdeaForm,
              cards: action.payload?.ideaForms,
            }
            : initialState.pageState.stepIdeaForm,
          notifications: action.payload?.notifications || [],
        },
      };
    case WorkflowActionTypes.SET_WORKFLOW:
      return {
        ...state,
        dynamicWorkFlow:{
          optionSelect: action.payload          
        } 
        
      }
    case WorkflowActionTypes.GET_WORKFLOW:
      switch (action.status) {
        case WorkflowActionStatus.GET_WORKFLOW_LOADING:
        case WorkflowActionStatus.GET_WORKFLOW_FAILED:
          return {
            ...state,
            pageState: {
              ...state.pageState,
              stepWorkflow: {
                ...state.pageState.stepWorkflow,
                workflowStatus: action.status,
              },
            },
          };
        case WorkflowActionStatus.GET_WORKFLOW_SUCCESS:
          return {
            ...state,
            pageState: {
              ...state.pageState,
              stepWorkflow: {
                ...state.pageState.stepWorkflow,
                cards: action.payload?.workflow || [],
                workflowStatus: action.status,
              },
            },
          };
        default:
          return {
            ...state,
          };
      }
    case WorkflowActionTypes.GET_WORKFLOW_TEMPLATE:
      switch (action.status) {
        case WorkflowActionStatus.GET_WORKFLOW_TEMPLATE_FAILED:
        case WorkflowActionStatus.GET_WORKFLOW_TEMPLATE_LOADING:
          return {
            ...state,
            pageState: {
              ...state.pageState,
              stepWorkflow: {
                ...state.pageState.stepWorkflow,
                templates: {
                  ...state.pageState.stepWorkflow.templates,
                  status: action.status,
                },
              },
            },
          };
        case WorkflowActionStatus.GET_WORKFLOW_TEMPLATE_SUCCESS:
          return {
            ...state,
            pageState: {
              ...state.pageState,
              stepWorkflow: {
                ...state.pageState.stepWorkflow,
                templates: {
                  ...state.pageState.stepWorkflow.templates,
                  templates: action.payload,
                  status: action.status,
                },
              },
            },
          };
        default:
          return {
            ...state,
          };
      }
    case WorkflowActionTypes.GET_WF_PHASE_VALIDATION:
      switch (action.status) {
        case WorkflowActionStatus.GET_WF_PHASE_SUCCEEDED_STATUS:
          return {
            ...state,
            validationPopupState: {
              ...state.validationPopupState,
              basicSettings: {
                ...state.validationPopupState.basicSettings,
                title: action.payload?.title || "",
                description: action.payload?.description || "",
                endDate: state.pageState.stepDetail.challengeType === 1 ? (action.payload?.endDate || null ) : null,
                endDays: action.payload?.endDays || "0",
                isLastPhase: action.payload?.isLastPhase ?? false,
                dateType: action.payload?.endDate && state.pageState.stepDetail.challengeType === 1 ? 1 : 2,
              },
              validators: action.payload?.validators || [],
              status: action.status,
            },
          };
        case WorkflowActionStatus.GET_WF_PHASE_LOADING_STATUS:
        case WorkflowActionStatus.GET_WF_PHASE_FAILED_STATUS:
          return {
            ...state,
            validationPopupState: {
              ...state.validationPopupState,
              status: action.status,
            },
          };
        default:
          return {
            ...state,
          };
      }
    case WorkflowActionTypes.GET_WF_PHASE_COLLABORATION:
      switch (action.status) {
        case WorkflowActionStatus.GET_WF_PHASE_FAILED_STATUS:
        case WorkflowActionStatus.GET_WF_PHASE_LOADING_STATUS:
          return {
            ...state,
            collaborationPopupState: {
              ...state.collaborationPopupState,
              status: action.status,
            },
          };
        case WorkflowActionStatus.GET_WF_PHASE_SUCCEEDED_STATUS:
          return {
            ...state,
            collaborationPopupState: {
              ...state.collaborationPopupState,
              basicSettings: {
                ...state.collaborationPopupState.basicSettings,
                title: action.payload?.title || "",
                description: action.payload?.description || "",
                endDate: action.payload?.endDate || null,
                endDays: action.payload?.endDays || "0",
                isLastPhase: action.payload?.isLastPhase ?? false,
                dateType: action.payload?.endDate ? 1 : 2,
              },
              evaluation: {
                ...state.collaborationPopupState.evaluation,
                evaluationType: action.payload?.evaluationType || 1,
                criterias: action.payload?.criterias || [],
              },
              flow: {
                ...state.collaborationPopupState.flow,
                dateType: action.payload?.endTransitionDate ? 1 : 2,
                endTransitionDate: action.payload?.endTransitionDate || null,
                endTransitionDays: action.payload?.endTransitionDays || "0",
                flowRuleAverage: action.payload?.flowRuleAverage || 1,
                flowRuleParticipants: action.payload?.flowRuleParticipants || 1,
                isAutomaticFlow: action.payload?.isAutomaticFlow ?? true,
                responsibles: action.payload?.responsibles || [],
              },
              status: action.status,
            },
          };
        default:
          return {
            ...state,
          };
      }
    case WorkflowActionTypes.GET_WF_PHASE_DEVELOPMENT:
      switch (action.status) {
        case WorkflowActionStatus.GET_WF_PHASE_FAILED_STATUS:
        case WorkflowActionStatus.GET_WF_PHASE_LOADING_STATUS:
          return {
            ...state,
            developmentPopupState: {
              ...state.developmentPopupState,
              status: action.status,
            },
          };
        case WorkflowActionStatus.GET_WF_PHASE_SUCCEEDED_STATUS:
          return {
            ...state,
            developmentPopupState: {
              ...state.developmentPopupState,
              basicSettings: {
                ...state.developmentPopupState.basicSettings,
                title: action.payload?.title || "",
                description: action.payload?.description || "",
                endDate: action.payload?.endDate || null,
                endDays: action.payload?.endDays || "0",
                isLastPhase: action.payload?.isLastPhase ?? false,
                dateType: action.payload?.endDate ? 1 : 2,
              },
              ideaForm: {
                cards: action.payload?.ideaForms,
              },
              developers: {
                usersAllowed: action.payload?.usersAllowed || [],
                allowAuthorEdit: action.payload?.allowAuthorEdit || false,
                allowCoAuthorEdit: action.payload?.allowCoAuthorEdit || false,
                allowResponsiblesChooseEditors: action.payload?.allowResponsiblesChooseEditors || true,
              },
              flow: {
                ...state.developmentPopupState.flow,
                dateType: action.payload?.endTransitionDate ? 1 : 2,
                endTransitionDate: action.payload?.endTransitionDate || null,
                endTransitionDays: action.payload?.endTransitionDays || "0",
                flowRuleAverage: action.payload?.flowRuleAverage || 1,
                flowRuleParticipants: action.payload?.flowRuleParticipants || 1,
                isAutomaticFlow: action.payload?.isAutomaticFlow ?? true,
                responsibles: action.payload?.responsibles || [],
              },
              status: action.status,
            },
          };
        default:
          return {
            ...state,
          };
      }
    case WorkflowActionTypes.GET_WF_PHASE_EXPERTREVIEW:
      switch (action.status) {
        case WorkflowActionStatus.GET_WF_PHASE_FAILED_STATUS:
        case WorkflowActionStatus.GET_WF_PHASE_LOADING_STATUS:
          return {
            ...state,
            expertReviewPopupState: {
              ...state.expertReviewPopupState,
              status: action.status,
            },
          };
        case WorkflowActionStatus.GET_WF_PHASE_SUCCEEDED_STATUS:
          return {
            ...state,
            expertReviewPopupState: {
              ...state.expertReviewPopupState,
              basicSettings: {
                ...state.expertReviewPopupState.basicSettings,
                title: action.payload?.title || "",
                description: action.payload?.description || "",
                endDate: action.payload?.endDate || null,
                endDays: action.payload?.endDays || "0",
                isLastPhase: action.payload?.isLastPhase ?? false,
                dateType: action.payload?.endDate ? 1 : 2,
              },
              criterias: action.payload?.criterias || [],
              reviewers: action.payload?.reviewers || [],
              flow: {
                ...state.expertReviewPopupState.flow,
                dateType: action.payload?.endTransitionDate ? 1 : 2,
                endTransitionDate: action.payload?.endTransitionDate || null,
                endTransitionDays: action.payload?.endTransitionDays || "0",
                flowRuleAverage: action.payload?.flowRuleAverage || 1,
                flowRuleParticipants: action.payload?.flowRuleParticipants || 1,
                isAutomaticFlow: action.payload?.isAutomaticFlow ?? true,
                responsibles: action.payload?.responsibles || [],
              },
              status: action.status,
            },
          };
        default:
          return {
            ...state,
          };
      }
    case CreateChallengesActionTypes.RESET_CREATE_CHALLENGE:
      return {
        ...state,
        pageState: { ...initialState.pageState },
      };
    case CreateChallengesActionTypes.UPDATE_DETAILS:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          stepDetail: action.payload,
        },
      };
    case CreateChallengesActionTypes.UPDATE_PARTICIPANTS:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          stepParticipants: action.payload,
        },
      };
    case CreateChallengesActionTypes.UPDATE_IDEA_FORM:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          stepIdeaForm: action.payload,
        },
      };
    case CreateChallengesActionTypes.UPDATE_WORKFLOW:
      return {
        ...state,
        pageState: {
          ...state.pageState,
          stepWorkflow: action.payload,
        },
      };
    case WorkflowActionTypes.DELETE_ALL_WORKFLOW_PHASE:
    case WorkflowActionTypes.POST_WF_PHASE_VALIDATION:
    case WorkflowActionTypes.PUT_WF_PHASE_VALIDATION:
    case WorkflowActionTypes.PUT_WF_PHASE_EXPERTREVIEW:
    case WorkflowActionTypes.POST_WF_TEMPLATE:
    case WorkflowActionTypes.POST_WF_PHASE_COLLABORATION_FAILED:
    case WorkflowActionTypes.POST_WF_PHASE_COLLABORATION_SUCCESS:
    case WorkflowActionTypes.POST_WF_PHASE_DEVELOPMENT_FAILED:
    case WorkflowActionTypes.POST_WF_PHASE_DEVELOPMENT_SUCCESS:
    case WorkflowActionTypes.POST_WF_PHASE_EXPERTREVIEW_FAILED:
    case WorkflowActionTypes.POST_WF_PHASE_EXPERTREVIEW_SUCCESS:
    case WorkflowActionTypes.PUT_WF_PHASE_COLLABORATION_FAILED:
    case WorkflowActionTypes.PUT_WF_PHASE_COLLABORATION_SUCCEEDED:
    case WorkflowActionTypes.PUT_WF_PHASE_DEVELOPMENT_FAILED:
    case WorkflowActionTypes.PUT_WF_PHASE_DEVELOPMENT_SUCCEEDED:
      return {
        ...state,
        workflowStatus: action.status,
      };
    case CreateChallengesActionTypes.POST_CHALLENGE_DETAIL_FAILED:
    case CreateChallengesActionTypes.POST_CHALLENGE_DETAIL_SUCCEEDED:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ACTIVATE_FAILED:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ACTIVATE_SUCCEEDED:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_FAILED:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_SUCCEEDED:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_PARTICIPANTS_FAILED:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_PARTICIPANTS_SUCCEESS:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_IDEAFORM_FAILED:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_IDEAFORM_SUCCEEDED:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ENDSUBMITION_FAILED:
    case CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ENDSUBMITION_SUCCEEDED:
      return {
        ...state,
        status: action.status,
      };
    default:
      return state;
  }
};
