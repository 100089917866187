import { Reducer } from 'redux';
import { CountryActions, CountryActionTypes } from 'redux/actions/CountryAction';

// Define the Character type
export interface ICountry {
  countryId: string,
  cultureTag: string,
  tenantId: number,
  title: string,
  workspaceId: string
}

export interface ICountryState{
  readonly countries:Array<ICountry>
}

// Define the initial state
const initialCharacterState: ICountryState = {
  countries:[]
};

export const countryReducer: Reducer<ICountryState, CountryActions> = (
  state = initialCharacterState,
  action) => {
  switch (action.type) {
    case CountryActionTypes.GET_COUNTRIES: {
      return {
        ...state,
        countries: action.countries
      };
    }
    default:
      return state;
  } 
}