import React from "react";
import XDialog from "common/X/XDialog";
import { Box, Button } from "@material-ui/core";
import Resource from "components/Resources/Resource";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  groupName: string;
  noCancelButton?: any;
  yesButton?: any;
}

function DeleteGroupDialog({
  open,
  setOpen,
  groupName,
  noCancelButton,
  yesButton,
}: IProps) {
  const actions = (
    <Box textAlign="right">
      <Button
        color="primary"
        size="small"
        variant="outlined"
        style={{ marginRight: 8 }}
        onClick={noCancelButton}
      >
        <Resource tag="PageManageGroups::NoCancel" />
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={yesButton}
      >
        <Resource tag="PageManageGroups::Yes" />
      </Button>
    </Box>
  );

  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      title="Delete Group"
      maxWidth="xs"
      actions={actions}
    >
      <Box textAlign="center">
        <Resource
          tag="PageManageGroups::ConfirmDelete"
          args={[`${groupName}`]}
        />
      </Box>
    </XDialog>
  );
}

export default DeleteGroupDialog;
