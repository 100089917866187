import React from "react";
import Resource from "components/Resources/Resource";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import { ICultureState } from "redux/reducers/cultureReducer";
import TextEditorInput from "components/GenericField/TextEditorInput/TextEditorInput";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

interface IProps {
  culture: ICultureState;
  isOpen: boolean;
  setIsOpen: (on: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    widthCheckboxBtn: {
      alignItems: "center",
      flexFlow: "nowrap",
      "& .MuiSvgIcon-root": {
        width: "0.7em",
        height: "0.7em",
      },
    },
    labelCheckbox: {
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
        color: theme.palette.primary.main,
      },
    },
    paperReplyAllContainer: {
      borderRadius: 6,
      marginLeft: "16px",
      width: "100%",
    },
    replyIcon: {
      color: theme.palette.text.secondary,
      fontSize: "1rem",
      marginRight: "8px",
    },
  })
);

function MessageReplyAllDialog(props: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const matchXsSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const { culture, isOpen, setIsOpen } = props;

  //reply functions: rich text
  const msgDescriptionProps = {
    columnName: "msg-description",
    isMandatory: false,
    typeId: 9,
    title: "",
    isEditable: true,
  };

  //reply functions: checkbox
  const [stateCheckbox, setStateCheckbox] = React.useState({
    checkedA: true,
    checkedB: false,
  });
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCheckbox({
      ...stateCheckbox,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <>
      {isOpen && (
        <Box display="flex" className="replyAll-container">
          <Avatar
            className="replyAll-avatar"
            alt="avatar"
            src="https://cdn.iconscout.com/icon/free/png-512/ironman-marvel-super-hero-earth-saver-avenger-28699.png"
          />
          <Paper elevation={3} className={classes.paperReplyAllContainer}>
            <Box
              className="icon-and-email"
              display="flex"
              padding="12px 12px 8px"
            >
              <ReplyAllIcon className={classes.replyIcon} />
              <Typography color="textSecondary" variant="subtitle1">
                s.philips@exago.com
              </Typography>
            </Box>
            <Box className="replyAll-content" padding="0 24px 24px">
              <Box className="replyAll-description">
                <TextEditorInput
                  // value={"description test"}
                  culture={culture}
                  propsI={msgDescriptionProps}
                  charCounterLabelShow={false}
                  maxCharCountShow={false}
                />
              </Box>
              <Box
                className="replyAll-actions-container"
                mt={matchXsSm ? 14 : 8}
                display={matchXsSm ? "grid" : "flex"}
                alignItems="center"
                justifyContent="space-between"
              >
                <Box className="replyAll-checkbox">
                  <FormControl
                    component="fieldset"
                    className={classes.widthCheckboxBtn}
                    style={{
                      display: matchXsSm ? "grid" : "flex",
                      gap: matchXsSm ? "8px" : "20px",
                    }}
                  >
                    <FormLabel>
                      <Box fontSize="0.7rem" fontWeight="bold">
                        <Resource tag={"PageMessages::SendChannels"} />
                      </Box>
                    </FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        className={classes.labelCheckbox}
                        control={
                          <Checkbox
                            checked={stateCheckbox.checkedA}
                            onChange={handleChangeCheckbox}
                            name="checkedA"
                            color="primary"
                          />
                        }
                        label={
                          <Resource tag={"PageMessages::InternalMessage"} />
                        }
                      />
                      <FormControlLabel
                        className={classes.labelCheckbox}
                        control={
                          <Checkbox
                            checked={stateCheckbox.checkedB}
                            onChange={handleChangeCheckbox}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={<Resource tag={"PageMessages::EmailMessage"} />}
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
                <Box
                  className="replyAll-buttons-cancel-send"
                  display="flex"
                  mt={matchXsSm ? 2 : 0}
                >
                  <Box mr={2}>
                    <Button
                      color="primary"
                      size="small"
                      variant="text"
                      onClick={() => setIsOpen(false)}
                    >
                      <Resource tag={"PageMessages::Cancel"} />
                    </Button>
                  </Box>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    // onClick={() => setOpen(false)}
                  >
                    <Resource tag={"PageMessages::Send"} />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
}

export default MessageReplyAllDialog;
