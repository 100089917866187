import { AnyAction } from "redux";
import { connect } from "react-redux";
import React, { Fragment, useCallback } from "react";
import Steps from "components/Steps/Steps";
import { ThunkDispatch } from "redux-thunk";
import { useEffect, useState } from "react";
import { IAppState } from "redux/store/Store";
import { Box, Button, CircularProgress, useTheme } from "@material-ui/core";
import Resource from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import StickyComponent from "../../../components/Sticky/Sticky";
import CreateChallengeStepWorkflow from "./Steps/CreateChallengeStepWorkflow";
import CreateChallengeStepIdeaForm from "./Steps/CreateChallengeStepIdeaForm";
import CreateChallengeStepConfirmation from "./Steps/CreateChallengeStepConfirmation";
import CreateChallengeStepParticipants from "./Steps/CreateChallengeStepParticipants";
import CreateChallengeStepDetails from "views/Challenges/CreateChallenge/Steps/CreateChallengeStepDetails";
import {
  PageState,
  PageStateDetail,
  PageStateIdeaForm,
  PageStateParticipants,
} from "redux/types/createChallenge";
import {
  getCreateChallengeDetail,
  postChallengeDetail,
  putChallengeDetail,
  putChallengeDetailActivate,
  putChallengeDetailIdeaform,
  putChallengeDetailParticipants,
  resetCreateChallenge,
  updateDetails,
  updateIdeaForm,
  updateParticipants,
  updateWorkflow,
} from "redux/actions/CreateChallenge/CreateChallengeActions";
import { IManageGroups } from "redux/types/manageGroups";
import { getAllManageGroups } from "redux/actions/manageGroups/manageGroupsAction";
import history from "routes/history";
import { getUserChallengeManagersAdminAction } from "redux/actions/manageUsers/ManageUsersAction";
import { IPermissions } from "redux/types/manageUsers";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
import { getAllCountries, getAllDepartaments } from "redux/actions/UsersAction";
import {
  getWorkflowAction,
  getWorkflowTemplateAction,
  putChallengeDetailValidationPhaseAction,
} from "redux/actions/Workflow/WorkflowActions";

interface IDispatchProps {
  updateDetails: (newValue: any) => any;
  updateParticipants: (newValue: any) => any;
  updateIdeaForm: (newValue: any) => any;
  updateWorkflow: (newValue: any) => any;
  getAllManageGroups: (options: object, culture: ICultureState) => Promise<any>;
  getChallengeDetail: (options: object, culture: ICultureState) => Promise<any>;
  postChallengeDetail: (options: object, culture: ICultureState) => Promise<any>;
  putChallengeDetail: (options: object, culture: ICultureState) => Promise<any>;
  putChallengeDetailParticipants: (options: object, culture: ICultureState) => Promise<any>;
  putChallengeDetailIdeaform: (options: object, culture: ICultureState) => Promise<any>;
  getCountries: (options: object, culture: ICultureState) => Promise<any>;
  getDeparments: (options: object, culture: ICultureState) => Promise<any>;
  getWorkflow: (params: object, culture: ICultureState) => Promise<any>;
  putValidationPhase: (options: object, culture: ICultureState) => Promise<any>;
  getTemplates: (options: object, culture: ICultureState) => Promise<any>;
  activateChallenge: (options: object, culture: ICultureState) => Promise<any>;
  getManagers: (options: object, culture: ICultureState) => Promise<any>;
  resetPage: () => any;
}

interface ILocation {
  location: {
    state: {
      activeIndex: number;
    };
  };
}

interface IProps {
  culture: ICultureState;
  pageState: PageState;
  groups: IManageGroups;
  workspaceId: string;
  challengeId: string;
  manageUsers: IPermissions;
  countries: ICountries[];
  departments: IDepartments[];
  templates: any;
}

function CreateChallenge(props: IProps & IDispatchProps & ILocation) {
  const {
    culture,
    updateDetails,
    updateParticipants,
    updateIdeaForm,
    groups,
    pageState,
    workspaceId,
    challengeId,
    getAllManageGroups,
    getChallengeDetail,
    postChallengeDetail,
    putChallengeDetail,
    putChallengeDetailParticipants,
    putChallengeDetailIdeaform,
    getManagers,
    manageUsers,
    getCountries,
    getDeparments,
    getWorkflow,
    getTemplates,
    templates,
    activateChallenge,
    resetPage
  } = props;

  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActive] = useState(
    props?.location?.state?.activeIndex || 1
  );
  const [postLoading, setPostLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(0);
  const { cultureTag } = culture?.culture ?? "";

  useEffect(() => {
    if (challengeId) {
      Promise.all([
        getChallengeDetail({ challengeId, workspaceId, cultureTag }, culture).then(() =>
          setLoading(false)
        ),
        getAllManageGroups({
          workspaceId,
          cultureTag,
        }, culture),
        getCountries({ cultureTag }, culture),
        getDeparments({ cultureTag }, culture),
        getManagers({
          workspaceId,
          permission: 2,
          isInfinite: false,
          pageSize: 100,
          cultureTag,
        }, culture),
        getWorkflow({ workspaceId, challengeId }, culture),
        getTemplates({ workspaceId }, culture),
      ]).finally(() => setLoading(false));
    } else {
      resetPage();
      Promise.all([
        getAllManageGroups({
          workspaceId,
          cultureTag,
        }, culture),
        getCountries({ cultureTag }, culture),
        getDeparments({ cultureTag }, culture),
        getManagers({
          workspaceId,
          permission: 2,
          isInfinite: false,
          pageSize: 100,
          cultureTag,
        }, culture),
        getTemplates({ workspaceId }, culture),
      ]).finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDetailsState = useCallback(
    (newState: PageStateDetail) => {
      updateDetails(newState);
    },
    [updateDetails]
  );

  const setParticipantsState = useCallback(
    (newState: PageStateParticipants) => {
      updateParticipants(newState);
    },
    [updateParticipants]
  );

  const setIdeaFormState = useCallback(
    (newState: PageStateIdeaForm) => {
      updateIdeaForm(newState);
    },
    [updateIdeaForm]
  );

  const reGetChallengeDetails = () =>
    getChallengeDetail({ challengeId, workspaceId }, culture);

  const buttonArr = [
    {
      title: <Resource tag="PageCreateChallenge::Details" />,
      form: (
        <CreateChallengeStepDetails
          setState={setDetailsState}
          state={pageState.stepDetail}
          culture={culture}
        />
      ),
    },
    {
      title: <Resource tag="PageCreateChallenge::Participants" />,
      form: (
        <CreateChallengeStepParticipants
          setState={setParticipantsState}
          state={pageState.stepParticipants}
          culture={culture}
          allGroups={groups}
          challengeManagers={manageUsers.result}
        />
      ),
    },
    {
      title: <Resource tag="PageCreateChallenge::IdeaForm" />,
      form: (
        <CreateChallengeStepIdeaForm
          culture={culture}
          setState={setIdeaFormState}
          state={pageState.stepIdeaForm}
        />
      ),
    },
    {
      title: <Resource tag="PageCreateChallenge::Workflow" />,
      form: (
        <CreateChallengeStepWorkflow
          culture={culture}
          challengeId={challengeId}
          workspaceId={workspaceId}
          workflowState={pageState.stepWorkflow}
          getChallengeDetails={reGetChallengeDetails}
          //templates={templates}
          pageState={pageState}
        />
      ),
    },
    {
      title: <Resource tag="PageCreateChallenge::Confirmation" />,
      form: (
        <CreateChallengeStepConfirmation
          setActive={setActive}
          steps={pageState}
          culture={culture}
          notifications={pageState.notifications}
          challengeId={challengeId}
          groups={groups}
        />
      ),
    },
  ];

  const handleActivateChallenge = () => {
    activateChallenge({ challengeId, workspaceId, notificationDate: new Date() }, culture).then(() => {
      history.push(`/challenges/details/${challengeId}`)
    })
  }

  const switchTab = async (newActive: number) => {
    setLoadingIndex(newActive);
    setPostLoading(true);
    switch (activeIndex) {
      case 1:
        const cultures = [
          {
            cultureTag: culture.culture.cultureTag,
            title: pageState.stepDetail.title,
            description: pageState.stepDetail.description,
          },
        ];
        if (challengeId) {
          await putChallengeDetail({
            ...pageState.stepDetail,
            workspaceId,
            challengeId,
            cultures,
          }, culture);
        } else {
          setPostLoading(true);
          const response = await postChallengeDetail({
            ...pageState.stepDetail,
            workspaceId,
            cultures,
          }, culture);
          if (response) {
            history.push({
              pathname: `/createChallenge/${response.id}`,
              state: { activeIndex: newActive },
            });
          }
        }
        break;
      case 2:
        const participants = {
          managersId: pageState.stepParticipants?.managers?.map(
            (manager) => manager.userId || manager.managerId
          ),
          groupsId: pageState.stepParticipants?.groups?.map(
            (group) => group.groupId
          ),
          allowParticipantsShareInspiration:
            pageState.stepParticipants.allowParticipantsShare,
          allowChallengeManagerShareInspiration:
            pageState.stepParticipants.allowManagersShare,
        };
        await putChallengeDetailParticipants({
          ...participants,
          workspaceId,
          challengeId,
        }, culture);
        break;
      case 3:
        await putChallengeDetailIdeaform({
          ideaCustomField: pageState.stepIdeaForm.cards,
          workspaceId,
          challengeId,
        }, culture);
        break;
      default:
    }
    if (challengeId) {
      getChallengeDetail({ challengeId, workspaceId }, culture).then(() => {
        setActive(newActive);
        setPostLoading(false);
        setLoadingIndex(0);
      });
    } else {
      setActive(newActive);
      setPostLoading(false);
      setLoadingIndex(0);
    }
  };

  return !loading ? (
    <Fragment>
      <Box mt={4}>
        <StickyComponent offsetX={"0"}>
          <Box
            className="buttons-container"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ background: theme.palette.common.white }}
          >
            <Box className="steps" mb={2} mr={1}>
              <Steps
                activeIndex={activeIndex}
                setActive={switchTab}
                contentArr={buttonArr}
                challengePage
                challengeId={challengeId}
                loading={postLoading}
                loadingIndex={loadingIndex}
              />
            </Box>
          </Box>
        </StickyComponent>
        {buttonArr.map((data: any, index: any) => (
          <Box mt={4} key={index}>
            {index + 1 === activeIndex && (
              <>
                {!postLoading ? (
                  data.form
                ) : (
                  <Box mt={4} textAlign="center">
                    <CircularProgress />
                  </Box>
                )}
              </>
            )}
          </Box>
        ))}
      </Box>
      <Box className="buttons" display="flex" alignItems="center" mb={2} mt={2}>
        <Box mr={2}>
          <Button variant="outlined" color="primary" size="small" onClick={() => history?.goBack()}>
            <Resource tag="PageCreateChallenge::Cancel" />
          </Button>
        </Box>
        <Button
          onClick={() => activeIndex < 5 ? switchTab(activeIndex + 1) : handleActivateChallenge()}
          variant="contained"
          color="primary"
          size="small"
          disableElevation
          endIcon={postLoading && <CircularProgress size="14px" />}
          disabled={activeIndex === 5 && pageState.notifications.length > 0}
        >
          {activeIndex < 5 ? <Resource tag="PageCreateChallenge::Next" /> : <Resource tag="PageCreateChallenge::ScheduleButton" />}
        </Button>
      </Box>
    </Fragment>
  ) : (
    <Box width="100%" textAlign="center" mt={3}>
      <CircularProgress />
    </Box>
  );
}

const mapStateToProps = (store: IAppState, ownProps: any) => ({
  culture: store.cultureState,
  groups: store.manageGroupsState.groups,
  workspaceId: store.authState.auth.workspaceId,
  pageState: store.createChallengeState.pageState,
  challengeId: ownProps?.match?.params?.challengeId,
  manageUsers: store.manageUsersState.permissions,
  countries: store.usersState.countries,
  departments: store.usersState.departaments,
  templates: store.createChallengeState.pageState.stepWorkflow.templates.templates,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  getAllManageGroups: (options: object, culture: ICultureState) =>
    dispatch(getAllManageGroups(options, culture)),
  updateDetails: (newValue: any) => dispatch(updateDetails(newValue)),
  updateParticipants: (newValue: any) => dispatch(updateParticipants(newValue)),
  updateIdeaForm: (newValue: any) => dispatch(updateIdeaForm(newValue)),
  updateWorkflow: (newValue: any) => dispatch(updateWorkflow(newValue)),
  resetPage: () => dispatch(resetCreateChallenge()),
  getChallengeDetail: (newValue: any, culture: ICultureState) =>
    dispatch(getCreateChallengeDetail(newValue, culture)),
  postChallengeDetail: (object: object, culture: ICultureState) =>
    dispatch(postChallengeDetail(object, culture)),
  putChallengeDetail: (newValue: object, culture: ICultureState) =>
    dispatch(putChallengeDetail(newValue, culture)),
  putChallengeDetailParticipants: (newValue: object, culture: ICultureState) =>
    dispatch(putChallengeDetailParticipants(newValue, culture)),
  putChallengeDetailIdeaform: (newValue: object, culture: ICultureState) =>
    dispatch(putChallengeDetailIdeaform(newValue, culture)),
  getCountries: (options: object, culture: ICultureState) => dispatch(getAllCountries(options, culture)),
  getDeparments: (options: object, culture: ICultureState) => dispatch(getAllDepartaments(options, culture)),
  getWorkflow: (params: object, culture: ICultureState) => dispatch(getWorkflowAction(params, culture)),
  putValidationPhase: (options: object, culture: ICultureState) =>
    dispatch(putChallengeDetailValidationPhaseAction(options, culture)),
  getTemplates: (params: object, culture: ICultureState) => dispatch(getWorkflowTemplateAction(params, culture)),
  getManagers: (params: object, culture: ICultureState) => dispatch(getUserChallengeManagersAdminAction(params, culture)),
  activateChallenge: (params: object, culture: ICultureState) => dispatch(putChallengeDetailActivate(params, culture))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateChallenge);
