import React from "react";
import "./ReviewLikes.css";
import { Button } from "@material-ui/core";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";

/* Example
    <ReviewLikes title="Adapt our offering, while making the most of digital" props={
        {
                "evaluationType": "Likes",
                "starsEvaluation": null,
                "likesEvaluation": {
                    "countEvaluations": 1,
                    "evaluationResource": "PageDetailIdea.EvaluationAreaEdit_Title",
                    "evaluationUser": null,
                    "isAllowedEvaluate": false
                },
                "investment": null
        }

    }/>
*/

interface IProps {
  title: string;
  props: any;
  postLike: (userLike: boolean) => void;
}

class ReviewLikes extends React.PureComponent<IProps> {
  public render() {
    const { postLike } = this.props;
    const isAllowedEvaluate = this?.props?.props?.likesEvaluation
      ?.isAllowedEvaluate;
    const evaluationUser = this?.props?.props?.likesEvaluation?.evaluationUser;

    return (
      <div className="ReviewLikes-container">
        {/* TODO Translate */}
        <div className="ReviewLikes-Likes">Likes</div>
        <div className="ReviewLikes-container">
          <div className="ReviewLikes-Count">
            {this.props.props.likesEvaluation.countEvaluations}
          </div>
          {/* <div onClick={()=> isAllowedEvaluate ? postLike(!!evaluationUser) : ()=>{}}  
                        className={'LikeButton'}
                        style={isAllowedEvaluate ? {cursor:'pointer'} : {}}
                    >
                        <Icon color={evaluationUser ? '#1B9DFC' : '#ccc'} size={25} icon={'like'} />
                    </div> */}
          <Button
            onClick={() => postLike(!!evaluationUser)}
            disabled={!isAllowedEvaluate}
            variant={evaluationUser ? "contained" : "outlined"}
            color="primary"
            // aria-label="add"
            style={{ borderRadius: 50 }}
          >
            <ThumbUpAltIcon fontSize="small" />
          </Button>
        </div>
      </div>
    );
  }
}

export default ReviewLikes;
