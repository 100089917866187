import history from "routes/history";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ArrowForward } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { IAppState, store } from "redux/store/Store";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IAuthLoginAction } from "redux/actions/AuthAction";
import { ICultureState } from "redux/reducers/cultureReducer";
import Resource, { resource } from "components/Resources/Resource";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  getResourcesDefault,
  ICultureSetCulture,
} from "redux/actions/CultureActions";

interface IProps {
  getResourcesDefault: () => Promise<ICultureSetCulture>;
}

interface IStateProps {
  cultureState: ICultureState;
}

interface IState {
  email: string;
  emailVerifying: boolean;
  loadingSubmit: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    containerInputs: {
      position: "relative",
      minHeight: "120px",
      marginLeft: "16px",
    },
    textField: {
      width: "100%",
      "& label": {
        textTransform: "uppercase",
      },
    },
  })
);

function RequestAccess(props: IProps & IStateProps & IState) {
  const { cultureState } = props;
  const classes = useStyles();
  const [successful, setSuccessful] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailVerifying] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [, setSubmitted] = useState(false);

  function setCulture() {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store.dispatch;
    dispatch(getResourcesDefault());
  }

  useEffect(() => {
    cultureState?.culture?.resources?.length === 0 && setCulture();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function HandleBackLoginScreen() {
    setMsgError("");
    history.push("/log-in");
  }

  function handleEmailChange(e: any) {
    const { value } = e.target;
    setEmail(value);
    setEmailError(false);
    setMsgError("");
  }

  async function handleRequestSubmit(e: any) {
    e.preventDefault();
    setEmailError(false);
    setLoadingSubmit(true);
    setSubmitted(true);
    let emailCorrect = true;

    setLoadingSubmit(true);
    const atSignIndex = email.indexOf("@");
    const dotIndex = email.indexOf(".", atSignIndex);

    if (atSignIndex === -1 || dotIndex === -1) {
      setMsgError(resource(cultureState, "Errors::EmailInvalid"));
      setLoadingSubmit(false);
      emailCorrect = false;
    }
    if (emailCorrect) {
      // TODO awaiting backend
      setSuccessful(true);
    }
  }

  return (
    <Box mt="50px">
      {!successful && (
        <form name="form" onSubmit={handleRequestSubmit}>
          <Box className="request-content">
            <IconButton color="primary" onClick={HandleBackLoginScreen}>
              <ArrowBackIcon />
            </IconButton>
            <Box padding="30px 0 50px 16px">
              <Box fontSize="1.8rem" fontWeight="bold" component="p">
                <Resource tag="PageLogin::RequestAccess" />
              </Box>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                <Resource tag="PageLogin::EnterYourEmail" />
              </Typography>
            </Box>
            <Box className={classes.containerInputs}>
              <FormControl className={classes.textField}>
                <InputLabel htmlFor="LoginFieldEmail">EMAIL</InputLabel>
                <Input
                  id="LoginFieldEmail"
                  type={"email"}
                  autoComplete="new-password" // workaround to disable autocomplete on chrome
                  value={email}
                  error={emailError}
                  onChange={handleEmailChange}
                />
                {!emailVerifying ? "" : <LinearProgress />}
              </FormControl>
              {msgError && (
                <Box mt={2}>
                  <Typography color="error">{msgError}</Typography>
                </Box>
              )}
            </Box>
            <Box textAlign="end">
              <Button
                type="submit"
                variant={!loadingSubmit ? "contained" : "outlined"}
                color="primary"
                disabled={email.length && !emailError ? false : true}
                endIcon={
                  !loadingSubmit ? (
                    <ArrowForward className="icon icon-arrow" />
                  ) : (
                    <CircularProgress size={22} className="icon" />
                  )
                }
              >
                <Resource tag="PageLogin::SendRequest" />
              </Button>
            </Box>
          </Box>
        </form>
      )}
      {successful && (
        <Box
          className="request-successful"
          pt={4}
          onAnimationStart={() => alert("awaiting backend")}
        >
          <Box mb={3} fontSize="1.8rem" fontWeight="bold" component="p">
            <Resource tag="PageLogin::AccessRequested" />
          </Box>
          <Box
            fontSize="1rem"
            fontWeight={300}
            lineHeight="1.5rem"
            component="p"
          >
            <Resource tag="PageLogin::ThankYou" />
          </Box>
        </Box>
      )}
    </Box>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, IAuthLoginAction>
): IProps => {
  return {
    getResourcesDefault: () => dispatch(getResourcesDefault()),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    cultureState: store.cultureState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestAccess);
