import { Grid } from "@material-ui/core";

export interface IProps {
    src: string;
    alt: string;
    style: object;
    height: string;
  }

const Image = (props: IProps) => {
    const {style} = props;
    return(
        <Grid>
            <img
                style={{
                    ...style,
                    height: props.height,
                    objectFit: "cover"
                }}
                src={props.src}
                alt={props.alt ? props.alt : ""}
                loading="lazy"
            />
        </Grid>
    )
}


export default Image;