import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Resource, { resource } from "components/Resources/Resource";
import React, { useState } from "react";
import { IResultIdeasCollaborativeEvaluationInvestment } from "redux/types/ideas";
import CloseIcon from "@material-ui/icons/Close";
import { useStylesInvestmentPopup } from "./ReviewInvestments";

interface IProps {
  investment: IResultIdeasCollaborativeEvaluationInvestment;
  points: number;
  title: string;
  isInvestmentOn: boolean;
  setInvestmentOn: (is: boolean) => void;
  postInvestments: (value: any, isIncrease: boolean) => void;
  culture?: any;
}

const InvestmentPopUp = (props: IProps) => {
  const [screen, setScreen] = useState("points");
  const {
    investment,
    points,
    isInvestmentOn,
    setInvestmentOn,
    postInvestments,
  } = props;
  const [value, setValue] = useState<number | number[]>(
    investment.investmentStep > points ? 0 : investment.investmentStep
  );
  const [isIncrease, setIsIncrease] = useState(false);
  const classes = useStylesInvestmentPopup();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const maxInvest = !points
    ? investment.maximumInvestment
    : investment.maximumInvestment <= points
    ? investment.maximumInvestment
    : points;

  const investimentDirection = (isAproove: boolean) =>
    isAproove
      ? resource(
          props.culture,
          "PageDetailedIdea::InvestmentAreaPopUp_ButtonApprove"
        )
      : resource(
          props.culture,
          "PageDetailedIdea::InvestmentAreaPopUp_ButtonDisapprove"
        );

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={isInvestmentOn}
    >
      <MuiDialogTitle disableTypography className={classes.popupTitle}>
        <Typography variant="h4">
          <Resource tag="PageDetailedIdea::InvestmentAreaPopUp_Header" />
        </Typography>
        <IconButton
          // aria-label="close"
          className={classes.closeButton}
          onClick={() => setInvestmentOn(false)}
        >
          <CloseIcon />
        </IconButton>
        <Divider className={classes.titleDivider}></Divider>
      </MuiDialogTitle>
      <MuiDialogContent>
        <Box className="ReviewInvestmentsModal">
          {screen === "points" ? (
            <>
              <Box className="ReviewInvestmentsModalText">
                <Resource tag="ModalInvestNow::DefineTheNumberOfPoints" />
              </Box>

              <Box className="ReviewInvestmentsModalSlider">
                <Box className="ReviewInvestmentsModalValue">{value}</Box>
                <Box className="ReviewInvestmentsModalPoints">
                  <Resource tag="Common::points" />
                </Box>
                <Box>
                  <Slider
                    value={value}
                    aria-labelledby="discrete-slider-small-steps"
                    step={investment.investmentStep}
                    marks
                    min={
                      points > investment?.investmentStep
                        ? investment?.investmentStep
                        : 0
                    }
                    max={maxInvest}
                    valueLabelDisplay="off"
                    onChange={(event, value) => setValue(value)}
                  />
                </Box>
              </Box>

              <Box className="ReviewInvestmentsModalButtons">
                <Box>
                  {" "}
                  <Resource tag="PageDetailedIdea::InvestmentAreaPopUp_Invest" />{" "}
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      setScreen("confirm");
                      setIsIncrease(false);
                    }}
                    color="primary"
                    variant="outlined"
                    size={"small"}
                    disabled={!value}
                  >
                    <Resource tag="PageDetailedIdea::InvestmentAreaPopUp_ButtonDisapprove" />
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      setScreen("confirm");
                      setIsIncrease(true);
                    }}
                    color="primary"
                    variant="contained"
                    size={"small"}
                    disabled={!value}
                  >
                    <Resource tag="PageDetailedIdea::InvestmentAreaPopUp_ButtonApprove" />
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box className="ReviewInvestmentsModalText">
                <Resource
                  tag="PageDetailedIdea::InvestmentAreaPopUp_Confirmation"
                  args={[
                    `<strong>${value}</strong>`,
                    `<strong>${props.title}</strong>`,
                    investimentDirection(isIncrease),
                  ]}
                />
              </Box>

              <Box className="ReviewInvestmentsModalButtons">
                <Box>
                  <Button
                    onClick={() => {
                      setScreen("points");
                    }}
                    color="primary"
                    variant="outlined"
                    size={"small"}
                  >
                    <Resource tag="PageDetailedIdea::InvestmentAreaPopUp_ButtonNoConfirmation" />
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      setInvestmentOn(false);
                      setScreen("points");
                      postInvestments(value, isIncrease);
                    }}
                    color="primary"
                    variant="contained"
                    size={"small"}
                  >
                    <Resource tag="PageDetailedIdea::InvestmentAreaPopUp_ButtonConfirmation" />
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </MuiDialogContent>
    </Dialog>
  );
};

export default InvestmentPopUp;
