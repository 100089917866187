import {
  makeStyles,
  createStyles,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import XCard from "common/X/XCard";
import Resource from "../Resources/Resource";
import React, { useState, useEffect } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    gridExpectedROI: {
      height: "100%",
      textAlign: "end",
      justifyContent: "flex-end",
      paddingRight: 5,
    },
    date: {
      textTransform: "lowercase",
      "&:first-line": {
        textTransform: "capitalize",
      },
    },
    title: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

interface IProps {
  idea: any;
}

function ValuableIdeaCard(props: IProps) {
  const classes = useStyles();
  const [isImplemented, setIsImplemented] = useState(false);

  useEffect(() => {
    if (props?.idea?.isChosenForImplementation === true) {
      setIsImplemented(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <XCard
      key={props?.idea?.ideaId}
      link={`idea/details/${props?.idea?.ideaId}`}
      color={isImplemented ? "success" : "info"}
      elevation={2}
      height={155}
      topContentPadding={10}
      middleContentPadding={10}
      bottomContentPadding={10}
      topContent={
        isImplemented ? (
          <Box mt={1} ml={2}>
            <Typography
              variant="subtitle1"
              style={{ textTransform: "uppercase" }}
            >
              <Resource tag={"IdeaCard::Implemented"} />
            </Typography>
          </Box>
        ) : (
          <Box mt={1} ml={2}>
            <Typography
              variant="subtitle1"
              style={{ textTransform: "uppercase" }}
            >
              <Resource tag={"IdeaCard::ChosenForImplementation"} />
            </Typography>
          </Box>
        )
      }
      middleContent={
        <Box ml={2} mr={2}>
          <Grid container>
            <Grid item xs={8} sm={8} md={8}>
              <Typography variant="h4" className={classes.title}>
                {props?.idea?.ideaTitle}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.gridExpectedROI}
                >
                  <Typography variant="h4">
                    $ {props?.idea?.expectedROI}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.gridExpectedROI}>
                {isImplemented ? (
                  <Typography variant="subtitle2">
                    <Resource tag={"IdeaCard::AchievedROI"} />
                  </Typography>
                ) : (
                  <Typography variant="subtitle2">
                    <Resource tag={"IdeaCard::ExpectedROI"} />
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
      bottomContent={
        <Box mt={2} ml={2} mb={1.5}>
          <Grid container>
            <Box mr={0.5}>
              <Typography variant="subtitle1">
                <Resource tag={"IdeaCard::on"} />
              </Typography>
            </Box>
            <Typography variant="subtitle1" className={classes.date}>
              {props?.idea?.date}
            </Typography>
          </Grid>
        </Box>
      }
    />
  );
}

export default ValuableIdeaCard;
