import React, { useState } from 'react'
import {
  postIdeasComments,
  postLikeComment,
  postDislikeComment,
  IIdeasCommentsSuccessAction,
  getAllIdeasComments
} from 'redux/actions/ideas/IdeaAction'
import {
  postAddValue
} from '../../redux/actions/ideas/AddValueAction'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { IAppState } from '../../redux/store/Store'
import { Box, CircularProgress, Typography, Grow  } from '@material-ui/core';
//import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CommentItem from '../CommentItem/CommentItem'
//import './CommentList.scss'
import Resource from "../Resources/Resource";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     thumbnail: {
//       width: theme.spacing(5),
//       height: theme.spacing(5),
//     }
//   }),
// );

interface IProps {
  newcomments: any;
  initialPageSize?: number;
  statusComments: string;
  objectType?: number;
  ideaId: string;
  workspaceId: string;
}

interface IDispatchProps {
  postIdeasComments: any
  postLikeComment: any
  postDislikeComment: any
  postAddValue: any
  getAllIdeasComments: (options: object) => Promise<IIdeasCommentsSuccessAction>;
}

const pagination = {
  current: 1,
  pageSize: 0,
  finished: false
};

const status = {
  init: true,
  refreshControl: false,
  isActive: true
};

const options = {
  ideaId: '',
  status: 1,
  isActive: status.isActive,
  pageNumber: status.init || !status.isActive ? 1 : pagination.current + 1,
  pageSize: pagination.pageSize,
  culture: {}
};

function CommentListRelatedContent(props: IProps & IDispatchProps) {
  
  const [reply, setReply] = useState('');
  const [countPreviousClick, setClick] = useState(1)
  const {
    postLikeComment,
    postDislikeComment,
    getAllIdeasComments,
    initialPageSize,
    workspaceId
  } = props
  const comments = props?.newcomments;

  let statusComments = 'SUCCESS'
  //const classes = useStyles()
  const replyHandler = (value: any) => {
    if (reply === value) {
      setReply('')
    } else {
      setReply(value)
    }
  }

  const commentOptions = {
    ideaId: "",
    status: 1,
    pageNumber: 1,
    pageSize: options.pageSize + initialPageSize!,
    culture: {},
  };
  //commentOptions.culture = props.culture;
  commentOptions.ideaId = props.ideaId;

  const postLikeComments = (commentId: string, userLiked: boolean) => {
    if (userLiked === false) {
      postLikeComment({ ...options, ideaId: props?.ideaId, commentId: commentId, applicationId: 2, workspaceId })
    } else {
      postDislikeComment({ ...options, ideaId: props?.ideaId, commentId: commentId, applicationId: 2, workspaceId })
    }
  }

  const handleMoreComments = () => {
    if(comments?.result?.length >= comments?.meta?.total){
      return
    }else{
      setClick(countPreviousClick + 1)
      commentOptions.pageSize = commentOptions.pageSize + (initialPageSize! * countPreviousClick)
      getAllIdeasComments(commentOptions);
    }
  }
  
  return (
    <Box className='comment-container'>
      <Box display="flex" justifyContent="space-between" paddingY={1}>
        <Typography onClick={handleMoreComments} style={{textTransform: 'lowercase', cursor: 'pointer'}} variant="caption" color="primary">
          <Resource tag="Common::PreviousComments"/>
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {comments?.length} <Resource tag="Common::of"/> {comments.length}
        </Typography>
      </Box>
      {statusComments === 'SUCCESS' ?
        <Grow in>
          <Box className='comment-list'>
            {comments && comments?.map((comment: any) => {
              let high=false;          
              return (
                <CommentItem
                  key={comment.commentId}
                  commentId={comment.commentId}
                  userId={comment.userId}
                  fullName={comment.userName}
                  photo={comment.photo}
                  timeAgo={comment.timeAgo}
                  message={comment.message}
                  isLikedByMe={comment.isLikedByMe}
                  isAllowedComment={false}
                  likesCount={comment.likesCount}
                  replies={comment.replies}
                  isAddedValue={comment.isAddedValue}
                  timeStamp={comment.timeStamp}
                  timeStampResource={comment.timeStampResource}
                  //postAddedValue={postAddedValue}
                  //isAuthor={isAuthor}
                  replyHandler={replyHandler}
                  postLikeComment={postLikeComments}
                  highlight= {high}
                />
              )
            })
            }
          </Box>
        </Grow>
        :
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      }
    </Box>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    addStatus: store.addValueState.status,
    comments: store.ideasState.ideasComments,
    culture: store.cultureState,
    applicationId: store.authState.auth.applicationId,
    profile: store.profileState.profile,
    statusComments: store.ideasState.statusComment,
    workspaceId: store.authState.auth.workspaceId
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => {
  return {
    postIdeasComments: (options: object) => dispatch(postIdeasComments(options)),
    postLikeComment: (options: object) => dispatch(postLikeComment(options)),
    postDislikeComment: (options: object) => dispatch(postDislikeComment(options)),
    postAddValue: (options: object) => dispatch(postAddValue(options)),
    getAllIdeasComments: (options: object) => dispatch(getAllIdeasComments(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentListRelatedContent)