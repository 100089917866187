import React, { useCallback } from "react";
import Resource from "components/Resources/Resource";
import TextCounter from "common/UI/Text/TextCounter";
import { IWFBasicSettings, PageState } from "redux/types/createChallenge";
import { ICulture } from "redux/reducers/cultureReducer";
import XDatepicker from "common/UI/XDatepicker/XDatepicker";
import {
  Box,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";

interface IProps {
  culture?: ICulture;
  fatherState: IWFBasicSettings;
  setFatherState: (newValue: IWFBasicSettings) => void;
  stepsState: PageState;
  order: number;
}

function BasicSettings(props: IProps) {
  const { stepsState, fatherState, setFatherState, order } = props;
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const minDate = order - 2 >= 0 ? stepsState?.stepWorkflow.cards?.[order - 2]?.endDate : tomorrow;

  const setDateState = useCallback((finalDate) => setFatherState({ ...fatherState, endDate: finalDate }), [
    fatherState,
    setFatherState,
  ]);

  const handleDatetypeChange = (value: number) => {
    if (value === 1 && stepsState?.stepDetail?.challengeType === 1) {
      setFatherState({ ...fatherState, dateType: value, endDays: 0, endDate: new Date(minDate) });
    } else {
      setFatherState({ ...fatherState, dateType: value, endDate: null, endDays: 1 });
    }
  };

  return (
    <Container>
      <Grid item xs={12}>
        <FormControl style={{ width: "100%" }}>
          <TextCounter
            label={<Resource tag={"PageCreateChallenge::Workflow_Title"} />}
            maxLength={40}
            fullWidth
            rows={1}
            InputLabelProps={{
              shrink: true,
            }}
            value={fatherState.title}
            onChange={(e) => setFatherState({ ...fatherState, title: e.target.value })}
          />
          <TextCounter
            label={<Resource tag={"PageCreateChallenge::Workflow_Description"} />}
            maxLength={500}
            fullWidth
            rows={3}
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            value={fatherState.description}
            onChange={(e) => setFatherState({ ...fatherState, description: e.target.value })}
          />

          <Box mt={3}>
            <Box mb={1}>
              <Typography variant="h4">
                <Resource tag={"PageCreateChallenge::Workflow_TimeStay"} />
              </Typography>
            </Box>
            <Box mt={2}>
              {stepsState?.stepDetail?.challengeType === 1 && (
                <>
                  <Typography variant="body2" color="textSecondary">
                    <Resource tag={"PageCreateChallenge::Workflow_SelectCondition"} />
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      name="typeDate"
                      value={fatherState.dateType}
                      onChange={(e, v) => handleDatetypeChange(Number(v))}
                    >
                      <FormControlLabel
                        color="primary"
                        value={1}
                        control={<Radio color="primary" />}
                        label={<Resource tag={"PageCreateChallenge::Workflow_FinalDate"} />}
                      />
                      <FormControlLabel
                        color="primary"
                        value={2}
                        control={<Radio color="primary" />}
                        label={<Resource tag={"PageCreateChallenge::Workflow_NumOfDaysInThisPhase"} />}
                      />
                    </RadioGroup>
                  </FormControl>
                </>
              )}
              <Divider style={{ width: "100%", marginTop: 8 }} />
              <Box mt={3} width={"50%"}>
                {fatherState.dateType === 1 && stepsState?.stepDetail?.challengeType === 1 ? (
                  <XDatepicker
                    minDateMessage={
                      <Typography variant="caption" color="error">
                        <Resource tag="PageCreateChallenge::MinDateMessage" />
                      </Typography>
                    }
                    value={fatherState.endDate}
                    onChange={setDateState}
                    fullWidth
                    minDate={minDate}
                  />
                ) : (
                  <>
                    <Typography variant="body2" color="textSecondary" style={{ textTransform: "uppercase" }}>
                      {stepsState?.stepDetail?.challengeType === 1 ? (
                        <Resource tag={"PageCreateChallenge::Workflow_NumOfDays"} />
                      ) : (
                        <Resource tag={"PageCreateChallenge::Workflow_NumOfDaysInThisPhase"} />
                      )}
                    </Typography>
                    <TextField
                      value={fatherState.endDays}
                      onChange={(e) =>
                        setFatherState({
                          ...fatherState,
                          endDays: e.target.value,
                        })
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </FormControl>
      </Grid>
    </Container>
  );
}

export default BasicSettings;
