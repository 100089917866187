import React from 'react'
import './ShareButtons.css';

// import iconFacebook from '../../assets/img/facebook.svg';
// import iconTwitter from '../../assets/img/twitter.svg';
// import iconLinkedin from '../../assets/img/linkedin.svg';
// import iconMail from '../../assets/img/mail.svg';
// import iconWhatsApp from '../../assets/img/whatsapp.svg';

/* Example
    <ShareButtons/>
*/

interface IProps { }

export default class ShareButtons extends React.PureComponent<IProps> {

  public render() {

    return (<></>);

    /*
    return (
      <div className='ShareButtons'>
        <div className="ShareButtonsTitle">SHARE:</div>

        <div className="ShareButtonsIcons">
          <div className="ShareButtonsIcon" style={{ borderColor: "#3b5999" }}><img src={iconFacebook} alt="Facebook" /></div>
          <div className="ShareButtonsIcon" style={{ borderColor: "#03a9f4" }}><img src={iconTwitter} alt="Twitter" /></div>
          <div className="ShareButtonsIcon" style={{ borderColor: "#0077b5" }}><img src={iconLinkedin} alt="Linkedin" /></div>
          <div className="ShareButtonsIcon" style={{ borderColor: "#626365" }}><img src={iconMail} alt="Mail" /></div>
          <div className="ShareButtonsIcon" style={{ borderColor: "#4caf50" }}><img src={iconWhatsApp} alt="Whatsapp" /></div>
        </div>
      </div>
    );
    */
  }
}
