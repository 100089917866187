import {IChallengesClosed} from "redux/types/challenges";
import {
    Box,
    CircularProgress,
    FormGroup,
    Grid,
} from "@material-ui/core";
import ClosedCard from "../../ChallengesCards/ClosedCard/ClosedCard";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {resource} from "../../Resources/Resource";
import {ICultureState} from "../../../redux/reducers/cultureReducer";

interface IProps {
    challengeClosed: IChallengesClosed;
    challengeStatus: string;
    culture: ICultureState;
}

export default function ChallengeClosedList(props: IProps) {
    const {
        culture,
        challengeClosed,
        challengeStatus,
    } = props;

    useEffect(() => {
        if (!challengeClosed || challengeClosed?.result?.length === 0) {
            toast.error(resource(culture, "PageSettings::NoResults"));
        }
    }, [challengeClosed?.result?.length]);

    return (
        <FormGroup>
            {challengeStatus === "LOADING" ? (
                <Box display="flex" justifyContent="center" alignItems="center" p={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container>
                    <Grid container spacing={2} direction="row">
                        {challengeClosed &&
                            challengeClosed?.result?.map((chaC, index) => {
                                return (
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <ClosedCard
                                            key={"closed_" + challengeClosed.meta.currentPage + "_" + index}
                                            challengesClosed={chaC}
                                        />
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>
            )}
        </FormGroup>
    );
}