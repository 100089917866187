import React from "react";
import EMIcon from "components/Icon/Icon";
import Truncate from "react-truncate";
import Link from "@material-ui/core/Link";
import Resource from "components/Resources/Resource";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { IResultActivityComments } from "../../redux/types/myActivities";
import {
  TableRow,
  TableCell,
  Box,
  Typography,
  Hidden,
  IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      borderBottom: "solid 1px #ccc",
    },
    tableCell: {
      padding: "16px 10px 16px 0px!important",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "& div": {
        "& svg": {
          marginTop: "5px",
        },
      },
    },
    secondCell: {
      padding: "0px 10px 32px 0px!important",
      whiteSpace: "nowrap",
    },
    linkItem: {
      "& a": {
        textDecoration: "none",
        color: theme.palette.text.primary,
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        "text-overflow": "ellipsis",
      },
    },
    truncate: {
      overflow: "hidden",
      "text-overflow": "ellipsis",
      "max-width": "300px",
      [theme.breakpoints.down("xs")]: {
        "max-width": "215px",
      },
    },
  })
);

interface IProps {
  data: IResultActivityComments;
  isPublic?: boolean;
}

function CommentsItem(props: IProps) {
  const { data, isPublic } = props;
  const ideaBackURL = "/idea/details";
  const classes = useStyles();
  const capitalizedData = data?.date.replace(
    data?.date?.slice(4, 7),
    data?.date?.slice(4, 7).toLocaleLowerCase()
  );

  const tableCell = (
    <TableCell className={classes.tableCell} align="left">
      <Box display="inline-flex">
        <Box>
          <IconButton>
            <EMIcon size={18} color="#adadad" icon="comments-c" />
          </IconButton>
        </Box>
        <Box marginLeft={2}>
          <Hidden implementation="css" mdUp>
            <Box className={classes.linkItem}>
              <Link
                component={RouterLink}
                to={{
                  pathname: `${ideaBackURL}/${data?.ideaId}`,
                }}
              >
                <Typography className={classes.truncate}>
                  <strong>{data?.ideaTitle}</strong>
                </Typography>
              </Link>
            </Box>
          </Hidden>
          <Typography variant="body1">{data?.comment}</Typography>
          <Box display="flex" gridGap={30} paddingTop={2}>
            <Typography variant="caption">{capitalizedData}</Typography>
            <Typography variant="caption">
              {data?.totalLikes === 0 ? (
                ""
              ) : (
                <Resource
                  tag={
                    data?.totalLikes === 1 ? "Common::XLike" : "Common::XLikes"
                  }
                  args={[data?.totalLikes]}
                />
              )}
            </Typography>
            <Typography variant="caption">
              {data?.totalReplies === 0 ? (
                ""
              ) : (
                <Resource
                  tag={
                    data?.totalReplies === 1 ? "Common::XReply" : "Common::XReplies"
                  }
                  args={[data?.totalReplies]}
                />
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </TableCell>
  );

  return (
    <TableRow className={classes.tableRow}>
      {isPublic ? (
        <Link
          component={RouterLink}
          to={{
            pathname: `${ideaBackURL}/${data?.ideaId}`,
          }}
        >
          {tableCell}
        </Link>
      ) : (
        tableCell
      )}
      <TableCell
        className={classes.secondCell + " " + classes.linkItem}
        align="left"
      >
        <Hidden implementation="css" smDown>
          <Link
            component={RouterLink}
            to={{
              pathname: `${ideaBackURL}/${data?.ideaId}`,
            }}
          >
            <Truncate
              lines={2}
              width={370}
              trimWhitespace
              ellipsis={<span>...</span>}
            >
              <Typography>
                <strong>{data?.ideaTitle}</strong>
              </Typography>
            </Truncate>
          </Link>
        </Hidden>
      </TableCell>
    </TableRow>
  );
}

export default CommentsItem;
