import { Grid } from "@material-ui/core";
import XCarousel from "common/X/XCarousel";
import React from "react";
import { IMyActivitiesPrizesLost } from "redux/types/myActivities";
import MyActivityPrizesLostCard from "./MyActivityPrizesLostCard";

interface IProps {
  classes: any;
  myActivitiesPrizesLost: IMyActivitiesPrizesLost;
}

function MyActivityPrizesLost(props: IProps) {
  const { myActivitiesPrizesLost } = props;

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      style={{ marginBottom: 32, padding: "0 8px" }}
    >
      <XCarousel itemsToShow={2} infinite={true} partialVisible={true}>
        {myActivitiesPrizesLost?.result?.length > 0 &&
          myActivitiesPrizesLost?.result?.map((prize) => (
            <MyActivityPrizesLostCard key={prize?.prizeId} prize={prize} />
          ))}
      </XCarousel>
    </Grid>
  );
}

export default MyActivityPrizesLost;
