import { Reducer } from "redux";
import {
  KeyTasksActions,
  KeyTasksActionStatus,
  KeyTasksActionTypes,
} from "../../actions/keyTasks/KeyTasksAction";

// Define the Character type
export interface IResultKeyTasks {
  titleResource: string;
  ideaId: string;
  title: string;
  stateId: string;
  state: string;
  typeTask: number;
  typeDate: number;
  typeTaskResource: string;
  limitDate: number;
  limitDateResource: string;
  stateUpdated: number;
  stateUpdatedAtResource: string;
  averageRate: number;
  countRate: number;
  totalTasks: number;
}

export interface IResultEvaluationKeyTasks {
  ideaId: string;
  ideaTitle: string;
  status: string;
  challengeId: string;
  challengeTitle: string;
  limitDate: number;
  limitDateResource: string;
  stateUpdated: number;
  stateUpdatedAtResource: string;
}
export interface IResultValidationKeyTasks {
  ideaId: string;
  ideaTitle: string;
  challengeId: string;
  challengeTitle: string;
  status: string;
  numCorrections: number;
  stateUpdated: number;
  stateUpdatedAtResource: string;
}
export interface IResultTransitionKeyTasks {
  stateId: string;
  state: string;
  allowReject: boolean;
  isEvaluation: boolean;
  totalIdeas: number;
  keyTaskTransitions: [
    {
      ideaId: string;
      ideaTitle: string;
      challengeId: string;
      challengeTitle: string;
      evaluationType: string;
      value: number;
      state: string;
      stateUpdated: number;
      stateUpdatedAtResource: string;
      totalUsersEvaluation: number;
      totalUsersLeftToEvaluation: number;
      usersLeftToEvaluation: [
        {
          userId: string;
          name: string;
          photo: string;
          email: string;
        }
      ];
      dimensionsAverages: {
        additionalProp1: number;
        additionalProp2: number;
        additionalProp3: number;
      },
      isAllowedChangeManager: boolean;
    }
  ];
}

export interface IResultCorrectionKeyTasks {
  ideaId: string;
  ideaTitle: string;
  challengeId: string;
  challengeTitle: string;
  status: string;
  limitDate: number;
  limitDateResource: string;
}

export interface IEvaluation {
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
  result: IResultEvaluationKeyTasks[];
}
export interface IValidation {
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
  challengeId?:string,
  result: IResultValidationKeyTasks[];
}
export interface ITransition {
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
  result: IResultTransitionKeyTasks[];
}
export interface ICorrection {
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
  result: IResultCorrectionKeyTasks[];
}
export interface IKeyTasks {
  totalAll: number;
  totalEvaluation: number;
  totalValidation: number;
  totalTransition: number;
  totalCorrection: number;
  totalDelayed: number;
  totalThisWeek: number;
  totalNoDeadline: number;
  totalFuture: number;
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
  result: IResultKeyTasks[];
}

export interface IKeyTasksPositive {
  stateType: number;
  stateId: string;
  phaseId: string;
  description: string;
}

export interface IKeyTasksNegative {
  stateType: number;
  stateId: string;
  phaseId: string;
  description: string;
}

export interface IKeyTasksTransitions {
  stateId: string;
  state: string;
  totalIdeas: number;
}
export interface IFilters {
  states: [
    {
      statusId: number;
      name: string;
    }
  ];
  challenges: [
    {
      challengeId: string;
      name: string;
    }
  ];
  status: [
    {
      stateId: string;
      name: string;
    }
  ];
}
export interface IKeyTasksSummary {
  phaseId: string;
  stateId: string;
  state: string;
  allowReject: boolean;
  isEvaluation: boolean;
  totalIdeas: number;
}

export interface IKeyTasksImpactTypes {
  impactTypeId: string,
  title: string
}

// Define the Character State
export interface IKeyTasksState {
  readonly challengeId?: string;
  readonly searchTerm?:string;
  readonly keyTasks: IKeyTasks;
  readonly evaluationTasks: IEvaluation;
  readonly validationTasks: IValidation;
  readonly transitionTasks: ITransition;
  readonly correctionTasks: ICorrection;
  readonly impactTypes: IKeyTasksImpactTypes[];
  readonly keyTasksPositive: IKeyTasksPositive[];
  readonly keyTasksNegative: IKeyTasksNegative[];
  readonly keyTaskStatus: string;
  readonly evaluationKeyTasksStatus: string;
  readonly validationKeyTasksStatus: string;
  readonly transitionKeyTasksStatus: string;
  readonly filters: IFilters;
  readonly summary: IKeyTasksSummary[];
  readonly postTransitionStatus: string;
  readonly error: any;
}

// Define the initial state
const initialCharacterState: IKeyTasksState = {
  challengeId:"",
  searchTerm:"",
  keyTasks: {} as IKeyTasks,
  evaluationTasks: {} as IEvaluation,
  validationTasks: {} as IValidation,
  transitionTasks: {} as ITransition,
  correctionTasks: {} as ICorrection,
  summary: [] as IKeyTasksSummary[],
  impactTypes: [] as IKeyTasksImpactTypes[],
  keyTasksPositive: [],
  keyTasksNegative: [],
  keyTaskStatus: "",
  evaluationKeyTasksStatus: "",
  validationKeyTasksStatus: "",
  transitionKeyTasksStatus: "",
  filters: {} as IFilters,
  postTransitionStatus: "",
  error: "",
};

export const keyTasksReducer: Reducer<IKeyTasksState, KeyTasksActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case KeyTasksActionTypes.GET_KEYTASKS_LOADING: {
      return {
        ...state,
        keyTaskStatus: action.keyTaskStatus,
      };
    }
    case KeyTasksActionTypes.GET_KEYTASKS_SUCCEDDED: {
      return action?.isInfinite
        ? {
            ...state,
            keyTasks: {
              ...action.keyTasks,
              meta: action.keyTasks.meta,
              result: [...state.keyTasks.result, ...action.keyTasks.result],
            },
            keyTaskStatus: action.keyTaskStatus,
          }
        : {
            ...state,
            keyTasks: { ...action.keyTasks },
            keyTaskStatus: action.keyTaskStatus,
          };
    }
    case KeyTasksActionTypes.GET_KEYTASKS_FAILED: {
      return {
        ...state,
        keyTaskStatus: action.keyTaskStatus,
      };
    }
    case KeyTasksActionTypes.GET_EVALUATION_LOADING: {
      return {
        ...state,
        evaluationKeyTasksStatus: action.evaluationKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_EVALUATION_SUCCEDDED: {
      return action?.isInfinite
        ? {
            ...state,
            evaluationKeyTasksStatus: action.evaluationKeyTasksStatus,
            evaluationTasks: {
              meta: action.evaluationTasks.meta,
              result: [
                ...state.evaluationTasks.result,
                ...action.evaluationTasks.result,
              ],
            },
          }
        : {
            ...state,
            evaluationTasks: action.evaluationTasks,
            evaluationKeyTasksStatus: action.evaluationKeyTasksStatus,
          };
    }
    case KeyTasksActionTypes.GET_EVALUATION_FAILED: {
      return {
        ...state,
        evaluationKeyTasksStatus: action.evaluationKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_VALIDATION_LOADING: {
      return {
        ...state,
        validationKeyTasksStatus: action.validationKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_VALIDATION_SUCCEDDED: {
      return action.isInfinite
        ? {
            ...state,
            validationTasks: {
              meta: action.validationTasks.meta,
              challengeId:action.challengeId??"",
              searchTerm: action.searchTerm??"",
              result: [
                ...state.validationTasks.result,
                ...action.validationTasks.result,
              ],
            },
            validationKeyTasksStatus: action.validationKeyTasksStatus,
          }
        : {
            ...state,
            validationTasks: action.validationTasks,
            challengeId:action.challengeId??"",
            searchTerm:action.searchTerm??"",
            validationKeyTasksStatus: action.validationKeyTasksStatus,
          };
    }
    case KeyTasksActionTypes.GET_VALIDATION_FAILED: {
      return {
        ...state,
        validationKeyTasksStatus: action.validationKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_TRANSITIONS_LOADING: {
      return {
        ...state,
        transitionKeyTasksStatus: action.transitionKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_TRANSITIONS_SUCCEDDED: {
      return action?.isInfinite
        ? {
            ...state,
            transitionTasks: {
              meta: action.transitionTasks.meta,
              result: [
                ...state.transitionTasks.result,
                ...action.transitionTasks.result,
              ],
            },
            transitionKeyTasksStatus: action.transitionKeyTasksStatus,
          }
        : {
            ...state,
            transitionTasks: action.transitionTasks,
            transitionKeyTasksStatus: action.transitionKeyTasksStatus,
          };
    }
    case KeyTasksActionTypes.GET_TRANSITIONS_FAILED: {
      return {
        ...state,
        transitionKeyTasksStatus: action.transitionKeyTasksStatus,
      };
    }

    case KeyTasksActionTypes.GET_CORRECTIONS_LOADING: {
      return {
        ...state,
        correctionKeyTasksStatus: action.correctionKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_CORRECTIONS_SUCCEDDED: {
      return action?.isInfinite
        ? {
            ...state,
            correctionTasks: {
              meta: action.correctionTasks.meta,
              result: [
                ...state.correctionTasks.result,
                ...action.correctionTasks.result,
              ],
            },
            correctionKeyTasksStatus: action.correctionKeyTasksStatus,
          }
        : {
            ...state,
            correctionTasks: { ...action.correctionTasks },
            correctionKeyTasksStatus: action.correctionKeyTasksStatus,
          };
    }
    case KeyTasksActionTypes.GET_TRANSITIONS_SUMMARY: {
      return {
        ...state,
        summary: action.summary,
      };
    }
    case KeyTasksActionTypes.GET_CORRECTIONS_FAILED: {
      return {
        ...state,
        correctionKeyTasksStatus: action.correctionKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_FILTERS_LOADING: {
      return {
        ...state,
        filtersKeyTasksStatus: action.filtersKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_FILTERS_SUCCEDDED: {
      return {
        ...state,
        filters: action.filters,
        filtersKeyTasksStatus: action.filtersKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_FILTERS_FAILED: {
      return {
        ...state,
        filtersKeyTasksStatus: action.filtersKeyTasksStatus,
      };
    }
    case KeyTasksActionTypes.GET_ALL_KEYTASKS_POSITIVE: {
      return {
        ...state,
        keyTasksPositive: action.keyTasksPositive,
      };
    }
    case KeyTasksActionTypes.GET_ALL_KEYTASKS_NEGATIVE: {
      return {
        ...state,
        keyTasksNegative: action.keyTasksNegative,
      };
    }
    case KeyTasksActionTypes.POST_TANSITIONS_LOADING: {
      return {
        ...state,
        postTransitionStatus: action.postTransitionStatus,
      };
    }
    case KeyTasksActionTypes.POST_TANSITIONS_SUCCESS: {
      return {
        ...state,
        postTransitionStatus: action.postTransitionStatus,
      };
    }
    case KeyTasksActionTypes.POST_TANSITIONS_FAILED: {
      return {
        ...state,
        postTransitionStatus: action.postTransitionStatus,
        error: action.error,
      };
    }
    case KeyTasksActionTypes.GET_IMPACT_TYPES: {
      switch(action.status){
        case KeyTasksActionStatus.KEYTASKS_FAILED:
        case KeyTasksActionStatus.KEYTASKS_LOADING:{
          return {
            ...state
          }
        }
        case KeyTasksActionStatus.KEYTASKS_SUCCEDDED:{
          return {
            ...state,
            impactTypes: action.payload
          }
        }
        default: {
          return {
            ...state
          }
        }
      }
    }
    case KeyTasksActionTypes.POST_CHOSE_FOR_IMPLEMENTATION: {
      return {
        ...state
      }
    }
    default:
      return state;
  }
};
