import { api_v3 } from "./../../config/api";
import {
  IChallengeAllList,
} from "redux/types/challenges";
import {ICountry} from "../reducers/countryReducer";
import {IAllManageGroups} from "../types/manageGroups";

const basePath = "dashboards";

export const getAllChallenges = async(options :any): Promise<IChallengeAllList[] | undefined> => {
  try {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }
    if (options.hasOwnProperty("status")) {
      params.push(`status=${options.status}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/challenges${params_qs}`, {});
    return data;
  } catch (err) {
    return undefined;
  }
};

export const getAllCountries = async(options :any): Promise<ICountry[] | undefined> => {
  try {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/countries${params_qs}`, {});
    return data;
  } catch (err) {
    return undefined;
  }
};

export const getAllGroups = async(options :any): Promise<IAllManageGroups[] | undefined> => {
  try {
    let params = [];
    let params_qs = "";

    if (options.hasOwnProperty("workspaceId")) {
      params.push(`workspaceId=${options.workspaceId}`);
    }

    if (params.length) {
      params_qs = `?${params.join("&")}`;
    }

    const {data} = await api_v3.get(`${basePath}/groups${params_qs}`, {});
    return data;
  } catch (err) {
    return undefined;
  }
};