import React, { useState } from "react";
import Resource from "../../components/Resources/Resource";
import { TabPanel, TabList, TabContext } from "@material-ui/lab";
import CommentsList from "../../containers/CommentsList/CommentsList";
import {
  AppBar,
  Box,
  Tab,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import {
  IPublicMyActivityComments,
  IPrivateMyActivityComments,
} from "redux/types/myActivities";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      padding: "12px 0",
    },
    titlesTab: {
      "& span": {
        textTransform: "capitalize",
      },
    },
  })
);

interface IProps {
  publicComments: IPublicMyActivityComments;
  privateComments: IPrivateMyActivityComments;
  cultureTag: string;
  showMore: (typeTabs?: string, pageNumber?: number, pageSize?: number, isInfinite?: boolean) => Promise<any>
}

function CommentsTab(props: IProps) {
  const { publicComments, privateComments, cultureTag, showMore } = props;
  //Change Tabs
  const [tab, setTab] = useState("1");
  const classes = useStyles();
  const handleChange = (event: any, newValue: string) => {
    setTab(newValue);
  };

  return (
    <section>
      <TabContext value={tab}>
        <AppBar position="static" elevation={0}>
          <TabList onChange={handleChange}>
            <Tab
              value="1"
              label={
                publicComments.result ? (
                  <Box className={classes.titlesTab}>
                    <Resource
                      tag="PageMyActivity::Comments_Public"
                      args={[publicComments?.meta?.total]}
                    />
                  </Box>
                ) : (
                  <Box className={classes.titlesTab}>
                    <Resource
                      tag="PageMyActivity::Comments_Public"
                      args={[0]}
                    />
                  </Box>
                )
              }
            />
            <Tab
              value="2"
              label={
                privateComments.result ? (
                  <Box className={classes.titlesTab}>
                    <Resource
                      tag="PageMyActivity::Comments_Private"
                      args={[privateComments?.meta?.total]}
                    />
                  </Box>
                ) : (
                  <Box className={classes.titlesTab}>
                    <Resource
                      tag="PageMyActivity::Comments_Private"
                      args={[0]}
                    />
                  </Box>
                )
              }
            />
          </TabList>
        </AppBar>
        <TabPanel className={classes.tabPanel} value="1">
          {publicComments?.result ? (
            <DynamicInfiniteScroll 
              dataList={publicComments} 
              getAction={(pageNumber,pageSize) => showMore(tab, pageNumber, pageSize, true)}
              pageSize={6}
              //isAutomatic
            >
              <CommentsList
                isPublic
                comments={publicComments?.result}
                cultureTag={cultureTag}
              />
            </DynamicInfiniteScroll>
          ) : (
            <Box className="MyActivity-error" style={{ textAlign: "center" }}>
              <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
            </Box>
          )}
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="2">
          {privateComments?.result ? (
            <DynamicInfiniteScroll 
              dataList={privateComments} 
              getAction={(pageNumber,pageSize) => showMore(tab, pageNumber, pageSize, true)}
              pageSize={6}
              //isAutomatic
            >
              <CommentsList
                comments={privateComments?.result}
                cultureTag={cultureTag}
              />
            </DynamicInfiniteScroll>
          ) : (
            <Box className="MyActivity-error" style={{ textAlign: "center" }}>
              <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
            </Box>
          )}
        </TabPanel>
      </TabContext>
    </section>
  );
}

export default CommentsTab;
