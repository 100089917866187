import React, { useEffect, useState } from "react";
import { AppBar, Box, Tab } from "@material-ui/core";
import PopularListTab from "./TabPopular/PopularListTab";
import Resource from "../../components/Resources/Resource";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import HighlightedListTab from "./TabHighlighted/HighlightedListTab";
import {
  INewsHighlighted,
  INewsPopular,
} from "redux/types/news";

interface IProps {
  highlighted: INewsHighlighted;
  popular: INewsPopular;
}

function NewsHighlightedTabs(props: IProps) {
  const { highlighted, popular } = props
  const [tab, setTab] = useState(highlighted?.result?.length === 0 ? "2" : "1");
  useEffect(() => {
    if(highlighted?.meta?.total === 0){
      setTab('2')
    }else {setTab('1')}
  },[highlighted])
  //Change Tabs
  const handleChange = (event: any, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box className="NewsHighlightedTabs" margin="0 auto" width="100%">
      <TabContext value={tab}>
        <AppBar position="static" elevation={0}>
          <TabList onChange={handleChange}>
            <Tab
              label={
                highlighted?.meta?.total > 0 ? (
                  <Resource tag="Common::Highlighted" />
                ) : (
                  ""
                )
              }
              value="1"
            />
            <Tab
              label={
                popular?.meta?.total > 0 ? (
                  <Resource tag="Common::Popular" />
                ) : (
                  ""
                )
              }
              value="2"
            />
          </TabList>
        </AppBar>
        <TabPanel value="1" style={{ padding: 0 }}>
          <HighlightedListTab highlighted={highlighted}/>
        </TabPanel>
        <TabPanel value="2" style={{ padding: 0 }}>
          <PopularListTab popular={popular} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default NewsHighlightedTabs;
