import React from "react";
import { useDrop } from "react-dnd";
import { Button, Box } from "@material-ui/core";
import Resource from "components/Resources/Resource";
import { ItemTypes } from "../ButtonIconTypes/cfTypes";

interface IProps {
  handleAdd: (data: any, index: number) => void;
}

function DropableButton({ handleAdd }: IProps) {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.BUTTON,
    drop: (item) => {
      //@ts-ignore
      handleAdd(item.data);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Box
      py={3}
      textAlign="center"
      //ts-ignore to use ref in a mui box,
      //@ts-ignore
      ref={drop}
    >
      <Button
        disabled
        disableRipple
        disableElevation
        disableFocusRipple
        disableTouchRipple
        style={{ border: `2px dashed #BDBDBD` }}
      >
        {isOver ? (
          <Resource tag="CustomFields::ReleaseDrop" />
        ) : (
          <Resource tag="CustomFields::DragAndDrop" />
        )}
      </Button>
    </Box>
  );
}

export default DropableButton;
