import "./IdeaDetail.scss";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import newApp from "utils/reactNewApp";
import { Link } from "react-router-dom";
import Tags from "components/Tags/Tags";
import sanitizeHtml from "sanitize-html";
import EMIcon from "components/Icon/Icon";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { IIdeaDetails } from "redux/types/ideas";
import Resource from "components/Resources/Resource";
import { IProfile } from "redux/reducers/profileReducer";
import { resource } from "components/Resources/Resource";
import StateRuler from "components/StateRuler/StateRuler";
import SkeletonGrid from "common/UI/Skeleton/SkeletonGrid";
import CommentList from "components/CommentList/CommentList";
import SkeletonTitle from "common/UI/Skeleton/SkeletonTitle";
import { securityTextEditor } from "utils/securityTextEditor";
import { AppBar, Tab, Button, Grid, Divider, Paper } from "@material-ui/core";
import { ICultureState } from "redux/reducers/cultureReducer";
import { Box, Container, Typography } from "@material-ui/core";
import { TabPanel, TabList, TabContext } from "@material-ui/lab";
import { postSellInvestments } from "redux/actions/WalletActions";
import StateRulerItem from "components/StateRuler/StateRulerItem";
import ShowCoAuthors from "components/ShowCoAuthors/ShowCoAuthors";
import React, { createRef, Fragment, useEffect, useState } from "react";
import ReviewRatingBox from "components/ReviewRatingBox/ReviewRatingBox";
import { Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import RelatedContentList from "containers/RelatedContentList/RelatedContentList";
import { getRelatedContent } from "redux/actions/relatedContent/RelatedContentAction";
import IdeaDetailCustomFields from "components/IdeaDetailCustomFields/IdeaDetailCustomFields";
import {
  IIdeasDetailGetAllAction,
  getAllIdeaDetails,
  postIdeasLikes,
  deleteIdeasLikes,
  postIdeasInvestments,
  getAllIdeasComments,
  IIdeasCommentsSuccessAction,
} from "redux/actions/ideas/IdeaAction";
import { IRelatedContentResult, IRelatedContentState } from "redux/reducers/relatedContent/RelatedContentReducer";
import { getProfile, IProfileGetAllAction } from "redux/actions/ProfileActions";
import MoveForwardPopup from "./MoveForwardPopup";
import { getImpactTypes, postChoseForImplementationAction, postKeyTasksTransitions } from "redux/actions/keyTasks/KeyTasksAction";
import { IKeyTasksImpactTypes } from "redux/reducers/keyTasks/keyTasksReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    ideaTitle: {
      color: "#343953",
      fontSize: "1.5rem",
      fontWeight: 500,
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    challenge: {
      color: "#a2a2a2",
      margin: "10px 0",
      fontWeight: 500,
      fontSize: "0.71rem",
    },
    statusTitle: {
      color: "#a2a2a2",
      fontWeight: 500,
      fontSize: "0.71rem",
      textTransform: "uppercase",
    },
    status: {
      color: "#343953",
      fontWeight: "bold",
      fontSize: "0.71rem",
      textTransform: "uppercase",
    },
    tabNoSidePadding: {
      padding: "24px 0",
    },
    tabPanelHistory: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    tableBody: {
      color: "gray",
      fontWeight: "normal",
    },
    tableRow: {
      borderBottom: "solid 1px #333",
    },
    tableCell: {
      padding: "16px 10px 16px 0px!important",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    tableCellRight: {
      textAlign: "right",
      padding: "16px 10px 16px 0px!important",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    divEvaluation: {
      marginTop: 5,
      marginLeft: 10,
    },
    evaluationIcon: {
      marginTop: 2,
    },
    divider: {
      width: 0,
      [theme.breakpoints.between("md", "xl")]: {
        marginLeft: '15px',
        marginRight: '15px',
        height: "13vh",
        width: "2px",
        maxHeight: "68px",
      },
    },
  })
);

interface IDispatchProps {
  getAllIdeaDetails: (options: object) => Promise<IIdeasDetailGetAllAction>;
  postIdeasLikes: (options: object) => Promise<any>;
  postIdeasInvestments: (options: object) => Promise<any>;
  postSellInvestments: (options: object) => Promise<any>;
  deleteIdeasLikes: (options: object) => Promise<any>;
  getAllIdeasComments: (options: object) => Promise<IIdeasCommentsSuccessAction>;
  getRelatedContent: (options: object) => Promise<IRelatedContentState>;
  getProfile: (options: object) => Promise<IProfileGetAllAction>;
  getImpactTypes: (options: object) => Promise<any>;
  postChoseForImplementation: (options: object, culture: ICultureState) => Promise<any>;
  transitKeytask: (options:object) => Promise<any>;
}

interface IRouter {
  location: {
    search: string;
    state: {
      ideaId: string;
    };
  };
}

interface IStateProps {
  workspaceId: string;
  isShowOn: boolean;
  applicationId: any;
  culture: ICultureState;
  points: number;
  relatedContent: IRelatedContentResult[];
  relatedStatus: string;
  profile: IProfile[];
  toComments: boolean;
  isDraft: boolean;
  impactTypes: IKeyTasksImpactTypes[];
  permission: string;
}

interface IProps {
  isOrderByPublicationDate: true;
  topic: "";
  isFavorite: false;
  ideaDetails: IIdeaDetails;
  ideaId: "";
  localtion: any;
}

function IdeaDetail(props: IDispatchProps & IRouter & IProps & IStateProps) {
  const {
    workspaceId,
    ideaId,
    ideaDetails,
    culture,
    applicationId,
    points,
    relatedContent,
    relatedStatus,
    profile,
    toComments,
    isDraft,
    impactTypes,
    permission,
    getAllIdeaDetails,
    postIdeasLikes,
    postIdeasInvestments,
    postSellInvestments,
    deleteIdeasLikes,
    getProfile,
    getImpactTypes,
    postChoseForImplementation,
    transitKeytask,
    getAllIdeasComments,
    getRelatedContent,
  } = props;

  const [tab, setTab] = useState("1");
  const [load, setLoad] = useState(false);
  const [isAuthor, setAuthor] = useState(false);
  const [moveForwardOpen, setMoveForwardOpen] = useState(false);
  const [moveForwardReload, setMoveForwardReload] = useState(false);

  const scrollTo = () => {
    window?.scrollTo({
      top: myRef?.current?.offsetTop && myRef?.current?.offsetTop - 75,
      behavior: "smooth", // smooth scroll.
    });
  };

  useEffect(() => {
    ideaDetails?.author?.name === profile[0]?.fullName ? setAuthor(true) : setAuthor(false);
    if (toComments) {
      scrollTo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideaDetails?.author?.name, profile[0]?.fullName]);
  const classes = useStyles();

  useEffect(() => {
    setLoad(true);
    const options = {
      ideaDetailId: ideaId,
    };
    const commentOptions = {
      ideaId: "",
      status: 1,
      pageNumber: 1,
      pageSize: 3,
      culture: {},
    };
    commentOptions.culture = props?.culture;
    commentOptions.ideaId = props?.ideaId;

    Promise.all([
      getAllIdeaDetails(options),
      getAllIdeasComments(commentOptions)
    ]).finally(() => setLoad(false))

    if (commentHighlighted) {
      scrollTo();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllIdeaDetails, getAllIdeasComments]);

  useEffect(() => {
    setLoad(true);
    const options = {
      ideaDetailId: ideaId,
    };
    const commentOptions = {
      ideaId: "",
      status: 1,
      pageNumber: 1,
      pageSize: 3,
      culture: {},
    };
    commentOptions.culture = props?.culture;
    commentOptions.ideaId = props?.ideaId;

    Promise.all([
      getAllIdeaDetails(options),
      getAllIdeasComments(commentOptions)
    ]).finally(() => {setLoad(false); setMoveForwardOpen(false)})

    if (commentHighlighted) {
      scrollTo();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveForwardReload]);

  useEffect(() => {
    if(!newApp === false) {
      getImpactTypes({ culture: culture });
    }
    // eslint-disable-next-line
  }, []);

  const options = {
    ideaId: ideaId,
    applicationId: applicationId,
    pageNumber: 1, //TODO: Change for real options
    pageSize: 10, //TODO: Change for real options
    culture: culture,
  };

  const postLike = (userLike: boolean) => {
    if (userLike) {
      deleteIdeasLikes(options)?.then(() => {
        const newOptions = {
          ...options,
          ideaDetailId: ideaId,
        };
        getAllIdeaDetails(newOptions);
      });
    } else {
      postIdeasLikes(options)?.then(() => {
        const newOptions = {
          ...options,
          ideaDetailId: ideaId,
        };
        getAllIdeaDetails(newOptions);
      });
    }
  };

  const postInvestments = (value: any, isIncrease: boolean) => {
    const params = {
      ...options,
      workspaceId: workspaceId,
      points: value,
      isIncrease,
    };
    postIdeasInvestments(params)?.then(() => {
      const newOptions = {
        ...options,
        ideaDetailId: ideaId,
      };

      getProfile(options);
      getAllIdeaDetails(newOptions);
    });
  };

  const handleRelatedContent = () => {
    const relatedContentOptions = {
      applicationId,
      objectType: 1,
      objectId: ideaId,
      pageNumber: 1,
      pageSize: 8,
    };
    getRelatedContent(relatedContentOptions);
  };

  const sellInvestment = (value: any) => {
    const params = {
      ...options,
      steps: value,
    };

    postSellInvestments(params)?.then(() => {
      const newOptions = {
        ...options,
        ideaDetailId: ideaId,
      };

      getProfile(options);
      getAllIdeaDetails(newOptions);
    });
  };

  const activeStage = ideaDetails?.stages
    ?.map((stage, index) => stage?.isActual && index)
    ?.filter((x) => x !== undefined)?.[0];

  const activePhase = ideaDetails?.phases
    ?.map((phase, index) => phase?.isActual && index)
    ?.filter((x) => x !== undefined)?.[0];

 
  const handleChange = (event: any, newValue: string) => {
    setTab(newValue);
  };
  //Get commentId from url (?comment=1234) to be highlighted
  const commentId = new URLSearchParams(props?.location?.search)?.get("comment");
  let commentHighlighted = commentId ? commentId : "";
  const myRef = createRef<HTMLDivElement>();
  const imgFiles = ideaDetails?.files?.map((file, index) => {
    let fileType = file?.fileName?.split(".")[1];
    if (
      fileType === "jpg" ||
      fileType === "bmp" ||
      fileType === "png" ||
      fileType === "jpeg" ||
      fileType === "gif" ||
      fileType === "webp" ||
      fileType === "svg"
    ) {
      return file;
    } else {
      return "";
    }
  });

  const otherFiles = ideaDetails?.files?.map((file, index) => {
    let fileType = file?.fileName?.split(".")[1];
    if (
      fileType === "jpg" ||
      fileType === "bmp" ||
      fileType === "png" ||
      fileType === "jpeg" ||
      fileType === "gif" ||
      fileType === "webp" ||
      fileType === "svg"
    ) {
      return "";
    } else {
      return file;
    }
  });

  return (
    <>
      {!load ? (
        <Box>
          <Container className="idea-detail-page">
            {!isDraft && (
              <Box>
                {ideaDetails?.isAllowedEdit ? (
                  <Link
                    to={{
                      pathname: `/editIdea/${ideaDetails?.ideaId}`,
                      state: { challengeId: ideaDetails?.challengeId },
                    }}
                    className="edit-idea-link"
                  >
                    <Button color="primary" variant="contained">
                      <Resource tag={"PageDetailIdea::EvaluationArea_ButtonEdit"} />
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </Box>
            )}
            <Box marginTop="10px">
              <Typography component="h3" className={classes.ideaTitle}>
                {ideaDetails?.title}
              </Typography>
              <Typography component="div" className={classes.challenge}>
                <Box mr={1} display="inline">
                  <Resource tag={"PageEditIdea::Challenge"} />
                </Box>
                <Box display="inline">"{ideaDetails?.challengeTitle}"</Box>
              </Typography>
              <StateRuler>
                {!newApp &&
                  ideaDetails?.stages?.map((stage, index) => (
                    <StateRulerItem
                      key={stage?.stageId}
                      title={stage?.stage}
                      isActive={stage?.isActual <= activeStage ? false : true}
                    />
                  ))}

                {newApp &&
                  ideaDetails?.phases?.map((phase, index) => (
                    <StateRulerItem
                      key={phase?.phaseId}
                      title={phase?.title}
                      isActive={phase?.isActual <= activePhase ? false : true}
                    />
                  ))}
              </StateRuler>
              <Typography component="div">
                <Box mr={1} display="inline" className={classes.statusTitle}>
                { newApp ? <Resource tag={"PageDetailIdea::StatusV3"} /> : <Resource tag={"PageDetailIdea::Status"} /> }:
                </Box>
                <Box display="inline" className={classes.status}>
                  {newApp ? ideaDetails?.phaseTitle : ideaDetails?.stateTitle}
                </Box>
              </Typography>
            </Box>
            {newApp ? 
              <Box mt={3}>
                {!ideaDetails?.isChooseForImplementation ? (
                  <>
                    {permission === "Admin" || permission === "ChallengeManager" ? (
                      <Button color="primary" variant="contained" onClick={() => setMoveForwardOpen(true)}>
                        <Resource tag="PageIdeaDetail::MoveForward" />
                      </Button>
                    ) : ''}
                  </>
                ) : (
                  <Box display="flex" width="100%">
                    <Box>
                      <Box display="flex">
                        <Typography
                          color="textPrimary"
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          <Resource tag={"PageIdeaDetail::CurrentStatus"}/>:
                        </Typography>
                        <Typography
                          color="textSecondary"
                          style={{
                            marginBottom: "16px",
                            marginLeft: '5px',
                          }}
                        >
                          <Resource tag={ideaDetails?.histories[ideaDetails?.histories.length - 1]?.phaseTitleResource.replace('.', '::')}/>
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography
                          style={{
                            marginBottom: "16px",
                          }}
                        >
                          <Resource tag={'PageIdeaDetail::DecisionMadePhase'} args={[ideaDetails?.histories[ideaDetails?.histories.length - 2]?.stateTitle]}/>
                        </Typography>
                      </Box>
                    </Box>
                    <Divider orientation="vertical" className={classes.divider}/>
                    <Box>
                      <Box display="flex">
                        <Typography
                          color="textSecondary"
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "16px",
                          }}
                        >
                          <Resource tag={'PageIdeaDetail::ExpectedReturn'}/>:
                        </Typography>
                        <Typography
                          color="textPrimary"
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "16px",
                            fontWeight: "bold",
                            marginLeft: '5px'
                          }}
                        >
                          $ {ideaDetails?.expectedROI}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography
                          color="textSecondary"
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "16px",
                          }}
                        >
                          <Resource tag={'PageIdeaDetail::ImpactType'}/>:
                        </Typography>
                        <Box ml={1}>
                          <Paper style={{
                            backgroundColor: '#eaeceb',
                            height: '25px',
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            <Typography
                              color="textPrimary"
                              style={{
                                padding: '5px',
                                fontSize: "12px"
                              }}
                            >
                              {ideaDetails?.impactType ? ideaDetails?.impactType : <Resource tag={'PageIdeaDetail::UninformedImpactType'}/> }
                            </Typography>
                          </Paper>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            : '' }
            <Box className="ideaDetail-content-l-r">
              <Box marginRight="40px" className="ideaDetail-content-l">
                <Box
                  className="ideaDetail-description"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(ideaDetails?.description ? ideaDetails?.description : "", securityTextEditor),
                  }}
                ></Box>
                <Box className="ideaDetail-tags">
                  {ideaDetails?.tags?.length <= 0 ? "" : <Tags list={ideaDetails?.tags} />}
                </Box>
                <Box mt={4} py={2}>
                  {ideaDetails?.files?.length > 0 && imgFiles[0] && (
                    <>
                      <Typography
                        color="textSecondary"
                        style={{
                          textTransform: "uppercase",
                          marginBottom: "16px",
                        }}
                      >
                        <Resource tag={"PageDetailIdea::Media"} />
                      </Typography>
                      <Grid container spacing={2}>
                        {ideaDetails?.files?.map((file, index) => {
                          let fileType = file?.fileName?.split(".")[1];
                          if (
                            fileType === "jpg" ||
                            fileType === "bmp" ||
                            fileType === "png" ||
                            fileType === "jpeg" ||
                            fileType === "gif" ||
                            fileType === "webp" ||
                            fileType === "svg"
                          ) {
                            return (
                              <Fragment key={file?.fileId || index}>
                                <Grid
                                  key={file?.fileId || index}
                                  item
                                  container
                                  xs={12}
                                  sm={12}
                                  md={index === 0 ? 8 : 4}
                                  lg={index === 0 ? 8 : 4}
                                >
                                  <a
                                    style={{ width: "100%" }}
                                    rel="noopener noreferrer"
                                    href={file?.fileUrl}
                                    download={file?.fileName}
                                  >
                                    <div
                                      className="img-media"
                                      style={{
                                        backgroundImage: `url("${file?.fileUrl}")`,
                                      }}
                                    ></div>
                                  </a>
                                </Grid>
                              </Fragment>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Grid>
                    </>
                  )}
                </Box>
                {(ideaDetails?.customFields?.length > 0 || otherFiles?.length > 0) && (
                  <IdeaDetailCustomFields
                    files={ideaDetails?.files}
                    expectedROI={ideaDetails?.expectedROI}
                    customFields={ideaDetails?.customFields}
                  />
                )}
                {ideaDetails?.isAllowedViewOwnerOrCoOwner ? <ShowCoAuthors ideaDetails={ideaDetails} /> : <></>}
              </Box>
              <Box className="ideaDetail-content-r">
                <Box className="ideaDetail-evaluationBox">
                  <ReviewRatingBox
                    isCollaboration={ideaDetails?.evaluation?.isCollaboration}
                    isChooseForImplementation={ideaDetails?.isChooseForImplementation}
                    title={ideaDetails?.challengeTitle}
                    props={ideaDetails?.evaluation}
                    postLike={postLike}
                    postInvestments={postInvestments}
                    sellInvestments={sellInvestment}
                    points={points}
                    ideaId={props?.ideaId}
                    getAllIdeaDetails={getAllIdeaDetails}
                    culture={props?.culture}
                  />
                </Box>
              </Box>
            </Box>
            <MoveForwardPopup
              open={moveForwardOpen}
              setOpen={setMoveForwardOpen}
              workspaceId={workspaceId}
              postChoseForImplementation={postChoseForImplementation}
              culture={culture}
              phases={ideaDetails?.phases}
              phaseId={ideaDetails?.phaseId}
              impactTypes={impactTypes}
              ideaId={ideaDetails?.ideaId}
              transitKeytask={transitKeytask}
              setMoveForwardReload={setMoveForwardReload}
              //getAllIdeaDetails={getAllIdeaDetails}
            />
          </Container>
          {!isDraft && (
            <Container className="ideaDetail-tabs">
              <TabContext value={tab}>
                <AppBar position="static" elevation={0}>
                  <TabList onChange={handleChange}>
                    <Tab label={<Resource tag={"PageDetailedIdea::CommentsArea_Title"} />} value="1" />
                    <Tab label={<Resource tag={"PageDetailIdea::Menu_History"} />} value="2" />
                    <Tab
                      label={<Resource tag={"PageDetailedIdea::RelatedContentArea_Title"} />}
                      onClick={handleRelatedContent}
                      value="3"
                    />
                  </TabList>
                </AppBar>
                <TabPanel className={classes.tabNoSidePadding} value="1">
                  <div ref={myRef}></div>
                  {ideaId && (
                    <CommentList
                      phaseTitle={ideaDetails?.phaseTitle}
                      isAuthor={isAuthor}
                      isAllowedComment={ideaDetails?.isAllowedComment}
                      ideaId={ideaId}
                      initialPageSize={4}
                      highlight={commentHighlighted}
                    />
                  )}
                </TabPanel>
                <TabPanel value="2" className={classes.tabPanelHistory}>
                  <Table>
                    <TableBody className={classes.tableBody}>
                      {ideaDetails?.histories?.map((history) => {
                        let period = history?.periodResource?.replace(".", "::");
                        let title = history?.titleResource?.replace(".", "::");

                        period = resource(props?.culture, period, [history?.periodDate1, history?.periodDate2]);
                        title = resource(props?.culture, title, ["<strong>" + history?.stateTitle + "</strong>"]);

                        let evaluationIcon = "";
                        switch (history?.evaluationType?.toUpperCase()) {
                          case "LIKES":
                            evaluationIcon = "like-b";
                            break;
                          case "STARS":
                            evaluationIcon = "star-b";
                            break;
                          case "ROI":
                            evaluationIcon = "";
                            break;
                          case "INVESTMENTS":
                            evaluationIcon = "investment";
                            break;
                          default:
                            evaluationIcon = "";
                            break;
                        }
                        return (
                          <TableRow className={classes.tableRow} key={Math?.random()}>
                            <TableCell className={classes.tableCell}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeHtml(title, securityTextEditor),
                                }}
                              ></span>
                              <div className={classes.divEvaluation}>
                                {history?.evaluationType ? (
                                  <></>
                                ) : (
                                  <>
                                    {evaluationIcon && (
                                      <>
                                        {history?.evaluationType === "Likes"
                                          ? history?.countEvaluations
                                          : history?.evaluationAverage}
                                        &nbsp;
                                        <EMIcon
                                          className={classes.evaluationIcon}
                                          color={"gray"}
                                          size={12}
                                          icon={evaluationIcon}
                                        />
                                        &nbsp;&nbsp; | &nbsp;&nbsp;
                                        <Resource
                                          tag={
                                            history?.countEvaluations === 1
                                              ? "PageIdeaDetail::XEvaluation"
                                              : "PageIdeaDetail::XEvaluations"
                                          }
                                          args={[history?.countEvaluations]}
                                        />
                                        &nbsp;&nbsp;
                                      </>
                                    )}
                                  </>
                                )}
                                {history?.countComments === 0 ? (
                                  <></>
                                ) : (
                                  <Resource
                                    tag={
                                      history?.countComments === 1
                                        ? "PageInsights::Insight_CommentNumber"
                                        : "PageInsights::Insight_CommentsNumber"
                                    }
                                    args={[history?.countComments]}
                                  />
                                )}
                              </div>
                            </TableCell>
                            <TableCell className={classes.tableCellRight}>{period}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TabPanel>
                <TabPanel className={classes.tabNoSidePadding} value="3">
                  {relatedContent?.length > 0 ? (
                    <RelatedContentList relatedContent={relatedContent} relatedStatus={relatedStatus} />
                  ) : (
                    <Typography variant="body1">
                      <Resource tag={"Common::NoRelatedContent"} />
                    </Typography>
                  )}
                </TabPanel>
              </TabContext>
            </Container>
          )}
        </Box>
      ) : (
        <>
          <SkeletonTitle />
          <SkeletonGrid row={1} col={4} rowHeight={100} />
          <SkeletonGrid row={1} col={1} rowHeight={300} />
          <SkeletonTitle />
          <SkeletonGrid row={1} col={1} rowHeight={300} />
        </>
      )}
    </>
  );
}

const mapStateToProps = (store: IAppState, ownProps: any) => {
  return {
    workspaceId: store?.authState?.auth?.workspaceId,
    applicationId: store?.authState?.auth?.applicationId,
    culture: store?.cultureState,
    ideaDetails: store?.ideasState?.ideaDetails,
    ideaId: ownProps?.match?.params?.ideaId,
    points: store?.walletState?.points,
    relatedContent: store?.relatedContentState?.relatedContent,
    relatedStatus: store?.relatedContentState?.relatedStatus,
    profile: store?.profileState?.profile,
    toComments: ownProps?.location?.state?.toComments,
    isDraft: ownProps?.location?.state?.isDraft,
    impactTypes: store?.keyTasksState?.impactTypes,
    permission: store.authState.auth.permission,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getAllIdeaDetails: (options: object) => dispatch(getAllIdeaDetails(options)),
    postIdeasLikes: (options: object) => dispatch(postIdeasLikes(options)),
    postIdeasInvestments: (options: object) => dispatch(postIdeasInvestments(options)),
    postSellInvestments: (options: object) => dispatch(postSellInvestments(options)),
    deleteIdeasLikes: (options: object) => dispatch(deleteIdeasLikes(options)),
    getAllIdeasComments: (options: object) => dispatch(getAllIdeasComments(options)),
    getRelatedContent: (options: object) => dispatch(getRelatedContent(options)),
    getProfile: (options: object) => dispatch(getProfile(options)),
    getImpactTypes: (options: object) => dispatch(getImpactTypes(options)),
    postChoseForImplementation: (options: object, culture: ICultureState) =>
      dispatch(postChoseForImplementationAction(options, culture)),
    transitKeytask: (options: object) => dispatch(postKeyTasksTransitions(options))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdeaDetail);
