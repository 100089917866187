import sanitizeHtml from "sanitize-html";
import EMIcon from "components/Icon/Icon";
import { IIdeaDetails } from "redux/types/ideas";
import React, { createRef, useState } from "react";
import CommentList from "components/CommentList/CommentList";
import { securityTextEditor } from "utils/securityTextEditor";
import { ICultureState } from "redux/reducers/cultureReducer";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Resource, { resource } from "components/Resources/Resource";
import RelatedContentList from "containers/RelatedContentList/RelatedContentList";
import { getRelatedContent } from "redux/actions/relatedContent/RelatedContentAction";
import {
  Container,
  AppBar,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Box,
} from "@material-ui/core";
import {
  IRelatedContentResult,
  IRelatedContentState,
} from "redux/reducers/relatedContent/RelatedContentReducer";

interface IProps {
  ideaDetails: IIdeaDetails;
  culture?: ICultureState;
  relatedContent: IRelatedContentResult[];
  relatedStatus: string;
  getRelatedContent: (options: object) => Promise<IRelatedContentState>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabNoSidePadding: {
      padding: "24px 0",
    },
    tabPanelHistory: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    tableBody: {
      color: "gray",
      fontWeight: "normal",
    },
    tableRow: {
      borderBottom: "solid 1px #333",
    },
    tableCell: {
      padding: "16px 10px 16px 0px!important",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    tableCellRight: {
      textAlign: "right",
      padding: "16px 10px 16px 0px!important",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    divEvaluation: {
      marginTop: 5,
      marginLeft: 10,
    },
    evaluationIcon: {
      marginTop: 2,
    },
    container: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  })
);

function KeyTasksIdeaDetailTabs(props: IProps) {
  const classes = useStyles();
  const { ideaDetails, relatedContent, relatedStatus } = props;
  const [tab, setTab] = useState("1");
  const [isAuthor] = useState(false);
  const myRef = createRef<HTMLDivElement>();

  const handleChange = (event: any, newValue: string) => {
    setTab(newValue);
  };

  const handleRelatedContent = () => {
    const relatedContentOptions = {
      objectType: 1,
      objectId: ideaDetails.ideaId,
      pageNumber: 1,
      pageSize: 8,
    };
    getRelatedContent(relatedContentOptions);
  };

  return (
    <Container className={classes.container}>
      <TabContext value={tab}>
        <AppBar position="static" elevation={0}>
          <TabList onChange={handleChange}>
            <Tab
              label={resource(
                props?.culture,
                "PageDetailedIdea::CommentsArea_Title"
              )}
              value="1"
            />
            <Tab
              label={resource(props?.culture, "PageDetailIdea::Menu_History")}
              value="2"
            />
            <Tab
              label={resource(
                props?.culture,
                "PageDetailedIdea::RelatedContentArea_Title"
              )}
              onClick={handleRelatedContent}
              value="3"
            />
          </TabList>
        </AppBar>
        <TabPanel className={classes.tabNoSidePadding} value="1">
          <div ref={myRef}></div>
          {ideaDetails?.ideaId && (
            <CommentList
              phaseTitle={ideaDetails?.phaseTitle}
              isAuthor={isAuthor}
              isAllowedComment={ideaDetails?.isAllowedComment}
              ideaId={ideaDetails?.ideaId}
              initialPageSize={4}
            />
          )}
        </TabPanel>
        <TabPanel value="2" className={classes.tabPanelHistory}>
          <Table>
            <TableBody className={classes.tableBody}>
              {ideaDetails?.histories?.map((history) => {
                let period = history?.periodResource?.replace(".", "::");
                let title = history?.titleResource?.replace(".", "::");

                period = resource(props?.culture, period, [
                  history?.periodDate1,
                  history?.periodDate2,
                ]);
                title = resource(props?.culture, title, [
                  "<strong>" + history?.stateTitle + "</strong>",
                ]);

                let evaluationIcon = "";
                switch (history?.evaluationType?.toUpperCase()) {
                  case "LIKES":
                    evaluationIcon = "like-b";
                    break;
                  case "STARS":
                    evaluationIcon = "star-b";
                    break;
                  case "ROI":
                    evaluationIcon = "";
                    break;
                  case "INVESTMENTS":
                    evaluationIcon = "investment";
                    break;
                  default:
                    evaluationIcon = "";
                }
                return (
                  <TableRow className={classes.tableRow} key={Math.random()}>
                    <TableCell className={classes.tableCell}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(title, securityTextEditor),
                        }}
                      ></span>
                      {history?.evaluationType === null ? (
                        <></>
                      ) : (
                        <Box className={classes.divEvaluation}>
                          {history?.evaluationAverage}&nbsp;
                          <EMIcon
                            className={classes.evaluationIcon}
                            color={"gray"}
                            size={12}
                            icon={evaluationIcon}
                          />
                          &nbsp;&nbsp; | &nbsp;&nbsp;
                          <Resource
                            tag={
                              history?.countEvaluations === 1
                                ? "PageIdeaDetail::XEvaluation"
                                : "PageIdeaDetail::XEvaluations"
                            }
                            args={[history?.countEvaluations]}
                          />
                          &nbsp;&nbsp;
                          <Resource
                            tag={
                              history?.countComments === 1
                                ? "PageInsights::Insight_CommentNumber"
                                : "PageInsights::Insight_CommentsNumber"
                            }
                            args={[history?.countComments]}
                          />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCellRight}>
                      {period}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel className={classes.tabNoSidePadding} value="3">
          {relatedContent?.length > 0 ? (
            <RelatedContentList
              relatedContent={relatedContent}
              relatedStatus={relatedStatus}
            />
          ) : (
            <Typography variant="body1">
              <Resource tag={"Common::NoRelatedContent"} />
            </Typography>
          )}
        </TabPanel>
      </TabContext>
    </Container>
  );
}

export default KeyTasksIdeaDetailTabs;
