import React, { useEffect } from "react";
import EMIcon from "components/Icon/Icon";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

interface IProps {
  contentArr: any;
  activeIndex: any;
  setActive: any;
  challengeId?: string;
  challengePage?: boolean;
  loading?: boolean;
  loadingIndex?: number;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepsContainer: {
      background: theme.palette.common.white,
    },
    rootButton: {
      borderRadius: "0",
      padding: "8px 14px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    active: {
      color: `${theme.palette.text.primary}`,
    },
    disabled: {
      color: `${theme.palette.primary.main}`,
    },
  })
);

function Steps(props: IProps) {
  const classes = useStyles();
  const { challengeId, challengePage, activeIndex, contentArr, setActive, loading, loadingIndex } = props;
  
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className={classes.stepsContainer}
      width="fit-content"
      borderBottom="solid 1px #ccc"
      display="flex"
      flexWrap="wrap"
    >
      {contentArr.map((data: any, index: any) => {
        return (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            marginBottom="-1px"
            borderBottom={activeIndex === index + 1 ? "solid 3px #000" : ""}
          >
            <Button
              className={`${classes.rootButton} + ${
                activeIndex === index + 1 ? classes.active : classes.disabled
              }`}
              disableRipple
              disableTouchRipple
              disableFocusRipple
              disabled={!challengeId && challengePage && loading}
              variant="text"
              color="primary"
              onClick={() => setActive(index + 1)}
              endIcon={loadingIndex === index +1  && <CircularProgress size="14px" />}
            >
              {data.title}
            </Button>
            {index + 1 !== contentArr.length && (
              <EMIcon icon="arrow-right" color="#ccc" size={8} />
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default Steps;
