import "./BuyNowPrizes.css";
import { UX } from "../../config/ux";
import Resource from "../Resources/Resource";
import React, { Component, createRef } from "react";
import PrizesSkeleton from "../PrizePage/PrizesSkeleton";
import BuyNowPrizeCard from "../BuyNowPrizeCard/BuyNowPrizeCard";
import { Collapse, Grid, Box, Typography, Hidden, Button } from "@material-ui/core";
import { IResultPrizeActiveBuyNow } from "../../redux/reducers/prizeReducer";
import BuyNowPrizeDescription from "../BuyNowPrizeCard/BuyNowPrizeDescription";
import XDialog from "common/X/XDialog";

interface IProps {
  buyNowPrizes: IResultPrizeActiveBuyNow[];
  pageLink?: any;
  postBuyPrize: (prizeId: string) => void;
  requestManagePrize: (prizeId: string, type: number, isCanceling?: boolean) => void;
  cultureTag: string;
  permission: string;
}

interface IState {
  activePrize: any;
  activePrizeIndex: number;
  loading: boolean;
  openGrid: boolean;
  openCloseAuction: boolean;
  prizeId: string;
}

class BuyNowPrizes extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      activePrize: null,
      activePrizeIndex: 0,
      openGrid: false,
      openCloseAuction: false,
      prizeId: ''
    };
  }

  private myRef = createRef<HTMLDivElement>();
  scrollTo(scroll: boolean) {
    if (scroll) {
      window.scrollTo({
        top: this.myRef.current?.offsetTop && this.myRef.current.offsetTop - 75,
        behavior: "smooth", // smooth scroll.
      });
    }
  }

  componentDidMount() {
    const interval = setTimeout(() => {
      this.setState({ loading: false });
    }, UX.skeleton.minDelay);
    
    return () => clearInterval(interval)
  }

  render() {
    const { buyNowPrizes, pageLink, cultureTag, permission } = this.props;
    const maxCards = 6;
    const { postBuyPrize, requestManagePrize } = this.props;
    const handleClick = (cardData: any, index: number) => {
      if (
        this.state?.activePrize !== null &&
        cardData?.prizeId === this.state?.activePrize?.prizeId
      ) {
        this.setState({
          activePrize: null,
          activePrizeIndex: 0,
          openGrid: false,
        });
      } else {
        this.setState({
          activePrize: cardData,
          activePrizeIndex: index,
          openGrid: true,
        });
      }
    };

    const handleOpenCloseAuction = (open: boolean, prizeId: string) => {
      this.setState({openCloseAuction: open, prizeId: prizeId});
    };

    const buyPrize = (prizeId: string) => {
      this.setState({ activePrize: null, activePrizeIndex: 0 });
      postBuyPrize(prizeId);
    };

    function handleManagePrize(prizeId: string, type: number, isCanceling: boolean) {
      requestManagePrize(prizeId, type, isCanceling)
    }

    return (
      <>
        <Box width="100%" marginTop={4}>
          <Grid container>
            <Box mt={4} mb={2}>
              <Typography variant="h3">
                <Resource tag={"PagePrizes::Prize_ActiveBuyNow"} />
              </Typography>
            </Box>
          </Grid>
          <Grid container style={{ position: "relative" }} spacing={3}>
            {buyNowPrizes &&
              buyNowPrizes?.slice(0, maxCards).map((cardData, index) => {
                if (cardData?.prizeId === pageLink?.prizeId) {
                  this.scrollTo(pageLink?.scrollDown);
                }
                if (
                  (index === 0 || index !== 2) &&
                  buyNowPrizes.length !== index + 1
                ) {
                  return (
                    <React.Fragment key={cardData?.prizeId}>
                      {!this.state.loading ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          style={{ width: "100%" }}
                        >
                          <div
                            ref={
                              cardData?.prizeId === pageLink?.prizeId
                                ? this.myRef
                                : ""
                            }
                          >
                            <BuyNowPrizeCard
                              permission={permission}
                              cultureTag={cultureTag}
                              pageLink={pageLink}
                              setOpenCloseAuction={handleOpenCloseAuction}
                              postBuyPrize={buyPrize}
                              requestManagePrize={requestManagePrize}
                              index={index + 1}
                              buyNowCard={cardData}
                              onClick={handleClick}
                              openGrid={
                                index + 1 === this.state.activePrizeIndex &&
                                this.state.openGrid
                              }
                            />
                          </div>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          style={{ width: "100%" }}
                        >
                          <PrizesSkeleton />
                        </Grid>
                      )}
                    </React.Fragment>
                  );
                } else
                  return (
                    <React.Fragment key={cardData?.prizeId}>
                      {!this.state.loading ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          style={{ width: "100%" }}
                        >
                          <div
                            ref={
                              cardData?.prizeId === pageLink?.prizeId
                                ? this.myRef
                                : ""
                            }
                          >
                            <BuyNowPrizeCard
                              permission={permission}
                              openGrid={
                                index + 1 === this.state.activePrizeIndex &&
                                this.state.openGrid
                              }
                              cultureTag={cultureTag}
                              pageLink={pageLink}
                              setOpenCloseAuction={handleOpenCloseAuction}
                              postBuyPrize={buyPrize}
                              requestManagePrize={requestManagePrize}
                              index={index + 1}
                              buyNowCard={cardData}
                              onClick={handleClick}
                            />
                          </div>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          style={{ width: "100%" }}
                        >
                          <PrizesSkeleton />
                        </Grid>
                      )}
                      <Hidden smDown>
                        <Collapse
                          style={{
                            position: "absolute",
                            top:
                              this.state.activePrizeIndex > 3
                                ? "calc(275px * 2)"
                                : "calc(264px)",
                            width: "100%",
                            padding: "8px",
                            margin: "4px",
                          }}
                          in={this.state.activePrize != null}
                        >
                          <BuyNowPrizeDescription
                            handler={handleClick}
                            postBuyPrize={buyPrize}
                            result={this.state.activePrize}
                            itemIndex={this.state.activePrizeIndex}
                            index={index <= 2 ? 1 : 2}
                          />
                        </Collapse>
                      </Hidden>
                    </React.Fragment>
                  );
              })}
          </Grid>
          {/* <div className="view-all">
            <Button>
              {buyNowPrizes?.length > 6 ? <Resource tag={"Common::ViewAll"}/> : ""}
            </Button>
          </div> */}
        </Box>

        <XDialog
          width="800px"
          maxWidth="md"
          open={this.state.openCloseAuction}
          setOpen={() => this.setState({openCloseAuction: false})}
          title={<Resource tag='PagePrizes::CloseAuction'/>}
          actions={
            <Box textAlign="right">
              <Button
                color="primary"
                size="small"
                variant="outlined"
                style={{ marginRight: 8 }}
                onClick={() => this.setState({openCloseAuction: false})}
              >
                <Resource tag="PagePrizes::Cancel" />
              </Button>
              <Button
                color="primary"
                size="small"
                variant="outlined"
                style={{ marginRight: 8 }}
                onClick={() => handleManagePrize(this.state.prizeId, 4, false)}
              >
                <Resource tag="PagePrizes::AwardThePrize" />
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => handleManagePrize(this.state.prizeId, 4, true)}
              >
                <Resource tag="PagePrizes::DeletePrize" />
              </Button>
            </Box>
          }
        >
          <Box textAlign="center">
            <Box fontWeight="bold">
              <Resource tag="PagePrizes::PrizeWantInactivate" />
            </Box>
            <Resource tag="PagePrizes::PrizeWantAward" />
          </Box>
        </XDialog>
      </>
    );
  }
}

export default BuyNowPrizes;
