import React from "react";
import { connect } from "react-redux";
import Image from "material-ui-image";
import logo from "../assets/img/logo.png";
import { IAppState } from "../redux/store/Store";
import { Route, RouteProps } from "react-router-dom";
import BGImage from "../assets/img/loginBackground.png";
import { IAuthUser } from "../redux/reducers/authReducer";
import { ITenantState } from "../redux/reducers/tenantReducer";
import { Grid, Box, Hidden, Container } from "@material-ui/core";
import LoggedOutFooter from "../components/LoggedOutFooter/LoggedOutFooter";

interface IProps {
  isAuthenticated: IAuthUser;
  tenant: ITenantState;
  children: RouteProps["children"];
  userProps: any;
}
const LoggedOutRoute: React.FC<IProps> = (props: IProps) => {

  return (
    <>
      <Route
        render={(otherProps) => (
          <>
            <Grid container>
              <Hidden smDown>
                <Grid item xs={6}>
                  <Box height="100vh" width="100%">
                    <Image
                      src={
                        props.tenant?.error?.error ||
                          !props.tenant.activeTenant?.photoLogin
                          ? BGImage
                          : props.tenant?.activeTenant?.photoLogin
                      }
                      style={{
                        paddingTop: "0",
                        position: "none",
                        height: "100vh",
                      }}
                      disableSpinner
                      animationDuration={5500}
                      imageStyle={{ objectFit: "cover", position: "none" }}
                    />
                  </Box>
                </Grid>
              </Hidden>
              <Grid item md={6} xs={12} style={{ position: "relative" }}>
                <Box
                  height="6vh"
                  display="flex"
                  flexDirection="row-reverse"
                  p={1}
                  m={1}
                >
                  <Box p={1}>
                    <Image
                      className="tenant-logo"
                      alt="Exago"
                      src={
                        props.tenant?.error?.error ||
                          !props.tenant.activeTenant?.image
                          ? logo
                          : props.tenant?.activeTenant?.image
                      }
                      style={{
                        paddingTop: "0",
                        position: "none",
                        height: "50px",
                      }}
                      imageStyle={{
                        paddingTop: "0",
                        position: "none",
                        objectFit: "contain",
                        maxWidth: "150px",
                      }}
                      disableSpinner
                      animationDuration={5500}
                    />
                  </Box>
                </Box>
                <Box height="80%">
                  <Container style={{ margin: 0 }} maxWidth="sm" className="container-children">
                    <>{props.children}</>
                    <Box width="100%">
                      <LoggedOutFooter userProps={props?.userProps} />
                    </Box>
                  </Container>
                </Box>
              </Grid>
            </Grid>
            {/* <Box
              className="login-footer"
              bottom="3vh"
              position="absolute"
              right="4px"
              width="100%"
            >
              <LoggedOutFooter userProps={props?.userProps}/>
            </Box> */}
          </>
        )}
      />
    </>
  );
};
const mapStateToProps = (state: IAppState) => ({
  isAuthenticated: state.authState.auth,
  tenant: state.tenantState,
});
export default connect(mapStateToProps)(LoggedOutRoute);
