// Import redux types
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { api_general, api_v3 } from "config/api";
import { treatError } from "utils/treatError";
import {
  IManageUsersActivateSuccessAction, IManageUsersDeactivateSuccessAction,
  IManageUsersDeleteInviteSuccessAction, IManageUsersGetSuccessAction, IManageUsersInviteSuccessAction,
  IManageUsersPermissionsSuccessAction, IManageUsersPutPermissionSuccessAction,
  IManageUsersReinviteSuccessAction, IManageUsersRescheduleSuccessAction,
  IManageUsersScheduleSuccessAction, IManageUsersState, ManageUsersActionTypes,
  ManageUsersStatusTypes,
  IManageUsersImportPostGroupSuccessAction,
  IManageUsersTokenSuccessAction,
  IManageUsersWalletGivePointsSuccessAction,
  IManageUsersWalletResetPointsSuccessAction,
  IManageUsersWalletRemovePointsSuccessAction,
} from "redux/types/manageUsers";
import { IManageUsersDetailSuccessAction } from "redux/types/manageUsers";

const basePath = "users";

// Create Action Constants
export const getManageUsers: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersGetSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.GET_USERS_LOAD,
      });
      let params = [];
      let params_qs = "";

      const { isInfinite = false } = options ?? false;

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if (options.hasOwnProperty("groupIds") && options.groupIds[0] !== '') {
        params.push(`groupIds=${options.groupIds}`);
      }
      if (options.hasOwnProperty("departmentsIds") && options.departmentsIds[0] !== '') {
        params.push(`departmentsIds=${options.departmentsIds}`);
      }
      if (options.hasOwnProperty("countriesIds") && options.countriesIds[0] !== '') {
        params.push(`countriesIds=${options.countriesIds}`);
      }
      if (options.hasOwnProperty("ages") && options.ages[0] !== 0) {
        params.push(`ages=${options.ages}`);
      }
      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if (options.hasOwnProperty("userType")) {
        params.push(`userType=${options.userType}`);
      }
      if (options.hasOwnProperty("searchTerm") && options.searchTerm !== "") {
        params.push(`searchTerm=${options.searchTerm}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("userStatusInvitation") && options.userStatusInvitation) {
        params.push(`userStatusInvitation=${options.userStatusInvitation}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}${params_qs}`, {});
      dispatch({
        type: ManageUsersActionTypes.GET_USERS_SUCCESS,
        users: response.data,
        status: ManageUsersStatusTypes.SUCCESS,
        isInfinite
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.GET_USERS_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.cultureTag, "PageManageUsers::Header", err);
    }
  };
};
export const getUserDetail: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersDetailSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const { isInfinite = false } = options ?? false
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.GET_USERSDETAIL_LOAD,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }
      const response = await api_v3.get(`${basePath}/${options.userId}${params_qs}`, {});
      dispatch({
        type: ManageUsersActionTypes.GET_USERSDETAIL_SUCCESS,
        userDetails: response.data,
        status: ManageUsersStatusTypes.SUCCESS,
        isInfinite
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.GET_USERSDETAIL_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const getUserPermissions: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersPermissionsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const { isInfinite = false } = options ?? false
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.GET_PERMISSIONS_LOAD,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if (options.hasOwnProperty("permission")) {
        params.push(`permission=${options.permission}`);
      }
      if (options.hasOwnProperty("filterNameOrEmail")) {
        params.push(`filterNameOrEmail=${options.filterNameOrEmail}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }
      const response = await api_v3.get(`${basePath}/permissions${params_qs}`, {});
      dispatch({
        type: ManageUsersActionTypes.GET_PERMISSIONS_SUCCESS,
        permissions: response.data,
        status: ManageUsersStatusTypes.SUCCESS,
        isInfinite
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.GET_PERMISSIONS_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const getUserChallengeManagersAdminAction: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersPermissionsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      const { isInfinite = false } = options ?? false
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.GET_PERMISSIONS_LOAD,
      });


      const response1 = await api_general.get(`${basePath}/permissions?permission=1&pageSize=100`, {}) || [];
      const response2 = await api_general.get(`${basePath}/permissions?permission=2&pageSize=100`, {}) || [];

      const arr1 = response1?.data?.result || [];
      const arr2 = response2?.data?.result || [];

      const managers = {
        meta: response1?.data?.meta,
        result: [...arr1, ...arr2 ]
      }

      dispatch({
        type: ManageUsersActionTypes.GET_PERMISSIONS_SUCCESS,
        permissions: managers,
        status: ManageUsersStatusTypes.SUCCESS,
        isInfinite
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.GET_PERMISSIONS_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const postUserDeactivate: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersDeactivateSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.POST_DEACTIVATE_LOAD,
      });
      const params = {
        workspaceId: options.workspaceId,
        usersId: options.usersId
      }
      await api_v3.put(`${basePath}/deactivate`, params).then(() => {
        dispatch({
          type: ManageUsersActionTypes.POST_DEACTIVATE_SUCCESS,
          status: ManageUsersStatusTypes.SUCCESS
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.POST_DEACTIVATE_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const postUserActivate: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersActivateSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.POST_ACTIVATE_LOAD,
      });
      const params = {
        workspaceId: options.workspaceId,
        usersId: options.usersId
      }
      await api_v3.put(`${basePath}/activate`, params).then(() => {
        dispatch({
          type: ManageUsersActionTypes.POST_ACTIVATE_SUCCESS,
          status: ManageUsersStatusTypes.SUCCESS
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.POST_ACTIVATE_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const postInviteUser: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersInviteSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.POST_ACTIVATE_LOAD,
      });
      const params = {
        emails: options.emails,
        groups: options.groups,
        permissionType: options.permissionType,
        workspaceId: options.workspaceId,
        resourceId: options.resourceId
      }
      await api_v3.post(`${basePath}/invite`, params).then(() => {
        dispatch({
          type: ManageUsersActionTypes.POST_ACTIVATE_SUCCESS,
          status: ManageUsersStatusTypes.SUCCESS
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.POST_ACTIVATE_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const deleteInvite: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersDeleteInviteSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.POST_ACTIVATE_LOAD,
      });
      const params = {
        workspaceId: options.workspaceId,
        userIds: options.usersId
      }
      await api_v3.delete(`${basePath}/invite`, { data: params }).then(() => {
        dispatch({
          type: ManageUsersActionTypes.POST_ACTIVATE_SUCCESS,
          status: ManageUsersStatusTypes.SUCCESS
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.POST_ACTIVATE_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const postSchedule: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersScheduleSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.POST_SCHEDULE_LOAD,
      });
      const params = {
        scheduleDate: options.scheduleDate,
        emails: options.emails,
        groups: options.groups,
        permissionType: options.permissionType,
        workspaceId: options.workspaceId,
        resourceId: options.resourceId
      }
      await api_v3.post(`${basePath}/invite/schedule`, params).then(() => {
        dispatch({
          type: ManageUsersActionTypes.POST_SCHEDULE_SUCCESS,
          status: ManageUsersStatusTypes.SUCCESS
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.POST_SCHEDULE_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};
export const putReschedule: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersRescheduleSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.PUT_RESCHEDULE_LOAD,
      });
      const params = {
        scheduleDate: new Date(options.scheduleDate),
        userIds: options.userIds,
        workspaceId: options.workspaceId
      }
      await api_v3.put(`${basePath}/invite/reschedule`, params).then(() => {
        dispatch({
          type: ManageUsersActionTypes.PUT_RESCHEDULE_SUCCESS,
          status: ManageUsersStatusTypes.SUCCESS
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.PUT_RESCHEDULE_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const putReinvite: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersReinviteSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.PUT_REINVITE_LOAD,
      });
      const params = {
        userIds: options.userIds,
        workspaceId: options.workspaceId
      }
      await api_v3.put(`${basePath}/reinvite`, params).then(() => {
        dispatch({
          type: ManageUsersActionTypes.PUT_REINVITE_SUCCESS,
          status: ManageUsersStatusTypes.SUCCESS
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.PUT_REINVITE_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const putPermission: ActionCreator<ThunkAction<
  Promise<any>,
  IManageUsersState,
  null,
  IManageUsersPutPermissionSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.PUT_PERMISSION_LOAD,
      });
      const params = {
        groupId: options.groupId,
        permission: options.permission,
        users: options.users,
        workspaceId: options.workspaceId
      }
      await api_v3.put(`${basePath}/permission`, params).then(async () => {
        dispatch({
          type: ManageUsersActionTypes.PUT_PERMISSION_SUCCESS,
          status: ManageUsersStatusTypes.SUCCESS
        });
        let params = [];
        let params_qs = "";

        if (options.hasOwnProperty("workspaceId")) {
          params.push(`workspaceId=${options.workspaceId}`);
        }
        if (options.hasOwnProperty("type")) {
          params.push(`permission=${options.type}`);
        }
        if (options.hasOwnProperty("filterNameOrEmail")) {
          params.push(`filterNameOrEmail=${options.filterNameOrEmail}`);
        }
        if (options.hasOwnProperty("pageNumber")) {
          params.push(`pageNumber=${options.pageNumber}`);
        }
        if (options.hasOwnProperty("pageSize")) {
          params.push(`pageSize=${options.pageSize}`);
        }

        if (params.length) {
          params_qs = `?${params.join("&")}`;
        }
        const response = await api_v3.get(`${basePath}/permissions${params_qs}`, {});
        dispatch({
          type: ManageUsersActionTypes.GET_PERMISSIONS_SUCCESS,
          permissions: response.data,
          status: ManageUsersStatusTypes.SUCCESS
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.PUT_PERMISSION_FAILED,
        status: ManageUsersStatusTypes.FAILED
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const postImportUsers: ActionCreator<ThunkAction<
  Promise<any>, 
  IManageUsersState, 
  null, 
  IManageUsersImportPostGroupSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ManageUsersActionTypes.POST_IMPORT_USERS_LOAD,
        status: ManageUsersStatusTypes.LOAD,
      });
      let form = new FormData();
      form.append('file', options.file)
      form.append('privateMessage' ,options.privateMessage)
      form.append('workspaceId' ,options.workspaceId)
      form.append('resourceId' ,options.resourceId)

      await api_v3.post(`${basePath}/import`, form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        dispatch({
          type: ManageUsersActionTypes.POST_IMPORT_USERS_SUCCESS,
          status: ManageUsersStatusTypes.SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.POST_IMPORT_USERS_FAILED,
        status: ManageUsersStatusTypes.FAILED,
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const putWalletGivePoints: ActionCreator<ThunkAction<
  Promise<any>, 
  IManageUsersState, 
  null, 
  IManageUsersWalletGivePointsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ManageUsersActionTypes.PUT_WALLET_POINTS_GIVE_LOAD,
        status: ManageUsersStatusTypes.LOAD,
      });
      let params

      params = {
        value: Number(options.points),
        usersIds: options.usersId,
        workspaceId: options.workspaceId,
      }
      await api_v3.put(`${basePath}/wallet/give`, params).then(() => {
        dispatch({
          type: ManageUsersActionTypes.PUT_WALLET_POINTS_GIVE_SUCCESS,
          points: Number(options.points),
          usersId: options.usersId,
          status: ManageUsersStatusTypes.SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        type: ManageUsersActionTypes.PUT_WALLET_POINTS_GIVE_FAILED,
        status: ManageUsersStatusTypes.FAILED,
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};
export const putWalletResetPoints: ActionCreator<ThunkAction<
  Promise<any>, 
  IManageUsersState, 
  null, 
  IManageUsersWalletResetPointsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ManageUsersActionTypes.PUT_WALLET_POINTS_RESET_LOAD,
        status: ManageUsersStatusTypes.LOAD,
      });
      let params

      params = {
        usersIds: options.usersId,
        workspaceId: options.workspaceId,
      }

      await api_v3.put(`${basePath}/wallet/reset`, params).then(() => {
        dispatch({
          type: ManageUsersActionTypes.PUT_WALLET_POINTS_RESET_SUCCESS,
          usersId: options.usersId,
          status: ManageUsersStatusTypes.SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        status: ManageUsersStatusTypes.FAILED,
        type: ManageUsersActionTypes.PUT_WALLET_POINTS_RESET_FAILED,
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};
export const putWalletRemovePoints: ActionCreator<ThunkAction<
  Promise<any>, 
  IManageUsersState, 
  null, 
  IManageUsersWalletRemovePointsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ManageUsersStatusTypes.LOAD,
        type: ManageUsersActionTypes.PUT_WALLET_POINTS_REMOVE_LOAD,
      });
      let params

      params = {
        value: Number(options.points),
        usersIds: options.usersId,
        workspaceId: options.workspaceId,
      }

      await api_v3.put(`${basePath}/wallet/remove`, params).then(() => {
        dispatch({
          status: ManageUsersStatusTypes.SUCCESS,
          points: Number(options.points),
          usersId: options.usersId,
          type: ManageUsersActionTypes.PUT_WALLET_POINTS_REMOVE_SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        status: ManageUsersStatusTypes.FAILED,
        type: ManageUsersActionTypes.PUT_WALLET_POINTS_REMOVE_FAILED,
      });
      treatError(options?.culture, "PageManageUsers::Header", err);
    }
  };
};

export const postToken: ActionCreator<ThunkAction<
  Promise<any>, 
  IManageUsersState, 
  null, 
  IManageUsersTokenSuccessAction
  >> = (options) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({
          status: ManageUsersActionTypes.POST_TOKEN_LOAD,
          type: ManageUsersStatusTypes.LOAD,
        });
        const params = {
          token: options.token,
          groups: options.groups,
          permissionType: options.permissionType,
          workspaceId: options.workspaceId
        }
        await api_v3.post(`${basePath}/invite/token`, params).then(() => {
          dispatch({
            mageGroupsStatus: ManageUsersActionTypes.POST_TOKEN_SUCCESS,
            type: ManageUsersStatusTypes.SUCCESS,
          });
        });
      } catch (err) {
        dispatch({
          manageGroupsStatus: ManageUsersActionTypes.POST_TOKEN_FAILED,
          type: ManageUsersStatusTypes.FAILED,
        });
        treatError(options?.culture, "PageManageUsers::Header", err);
      }
    };
  };