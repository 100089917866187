import React from "react";
import { connect } from "react-redux";
import Resource from "components/Resources/Resource";
import MiniPopularCardTab from "./MiniPopularCardTab";
import { Link as RouterLink } from "react-router-dom";
import { IAppState } from "redux/store/Store";
import { INewsPopular } from "redux/types/news";
import {
  makeStyles,
  Box,
  Link,
  Button,
  Typography,
  Divider,
  createStyles,
  Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewAll: {
      textTransform: "uppercase",
      fontSize: "0.75rem",
    },
    button: {
      padding: 0,
    },
    position: {
      fontWeight: 500,
      fontSize: "16px",
      fontFamily: "Rubik",
      color: theme.palette.primary.main
    },
  })
);

interface IProps {
  popular: INewsPopular;
}

function PopularListTab(props: IProps) {
  const { popular } = props;
  let maxCards = 3;
  const newsBackURL = "/news/popular";
  const classes = useStyles();

  return (
    <>
      {popular?.result?.slice(0, maxCards).map((pop, index) => (
        <Box key={index}>
          {index === 0 ? (
            <Box key={pop?.newsId} alignItems="baseline" display="flex">
              <Box paddingRight={2}>
                <Typography className={classes.position}>
                  #{index + 1}
                </Typography>
              </Box>
              <MiniPopularCardTab resultPopular={pop} />
            </Box>
          ) : (
            <>
              <Divider />
              <Box key={pop?.newsId} alignItems="baseline" display="flex">
                <Box paddingRight={2}>
                  <Typography className={classes.position}>
                    #{index + 1}
                  </Typography>
                </Box>
                <MiniPopularCardTab resultPopular={pop} />
              </Box>
            </>
          )}{" "}
        </Box>
      ))}
      {popular?.meta?.total > maxCards ? (
        <Box mt={2} textAlign={"end"}>
          <Link component={RouterLink} to={{ pathname: `${newsBackURL}` }}>
            <Button variant="text" color="primary" className={classes.button}>
              <Typography variant="button" className={classes.viewAll}>
                <Resource tag="Common::ViewAll" />
              </Typography>
            </Button>
          </Link>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    news: store.newsState.news,
  };
};

export default connect(mapStateToProps)(PopularListTab);
