import React from 'react'

import AddRounded from '@material-ui/icons/Add';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import './ShareCard.scss';

/* Example
    <ShareCard action={() => alert('TESTE')} background="#FF0000" color="#0000FF" buttonColor="red" buttonCaption="+" >
        Share your<br/>
        <strong>ideas</strong>
    </ShareCard>

*/

interface IProps {
    action?: () => void,
    background: string,
    color: string,
    buttonColor: string,
    buttonCaption?: string,
}

export default class ShareCard extends React.PureComponent<IProps> {

    public render() {
        return (
            <div className='ShareCard' onClick={this.props.action} style={{ backgroundColor: this.props.background, color: this.props.color }}>
                <div className='ShareText'>
                    {this.props.children}
                </div>

                <div className='ShareCardButton' style={{backgroundColor: this.props.buttonColor}}>
                    <div className='ShareCardButtonCaption'>
                        {this.props.buttonCaption === "AddRounded" ?
                            <AddRounded fontSize="small"/>
                        :
                            <ArrowForwardIosRoundedIcon fontSize="small"/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
