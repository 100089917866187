import React from "react";
import PrizeBid from "./PrizeBid";
import { Box } from "@material-ui/core";
import Resource from "../Resources/Resource";
import "./ActiveAuctionPrizeDescription.css";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ListWinning from "../ListPrizeUserWinning/ListPrizeUserWinning";
import sanitizeHtml from "sanitize-html";
import { securityTextEditor } from "utils/securityTextEditor";

type Props = {
  result: any;
  index: number;
  itemIndex: number;
  postBidPrize?: (prizeId: string, value: number) => void;
  cultureTag?: string;
  handleClick: any;
  userAllowToBid?: any;
};

const ActiveAuctionPrizeDescription = (props: Props) => {
  const {
    result,
    index,
    itemIndex,
    postBidPrize,
    handleClick,
    cultureTag,
  } = props;
  let activeLine = itemIndex <= 3 ? 1 : 2;
  let activeIndex = itemIndex <= 3 ? itemIndex : itemIndex - 3;

  //format to resource
  const motiveDeniedBidResource = result?.motiveDeniedBid?.replace(".", "::");

  if (result == null || activeLine !== index) return <></>;
  else
    return (
      <Box>
        <Box
          className={`prize-description-auctions prize-description-type-${activeIndex}`}
        >
          <Box
            onClick={() => handleClick(result, index)}
            className="arrow-icon"
          >
            <KeyboardArrowUpIcon
              style={{
                cursor: "pointer",
              }}
              color="primary"
              fontSize="large"
            />
          </Box>
        </Box>
        <Box className="prize-info-auctions">
          <Box
            className="prize-auctions-container-left-side"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box
              className="prize-auctions-description"
              textAlign="left"
              fontSize="14px"
              margin="0 15px 0 15px"
              lineHeight="26px"
              fontWeight="400"
              style={{overflowY: 'auto', wordBreak: 'break-word', maxWidth: '100%', maxHeight: '160px'}}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(result?.description, securityTextEditor),
              }}
            />
            <Box>
              <Box
                className="prize-auctions-units"
                display="flex"
                textAlign="left"
                fontSize="12px"
                style={{ textTransform: "uppercase" }}
              >
                <Resource tag="PagePrizes::UnitsAuction" />:
                <Box ml={1} fontWeight="bold">
                  {result?.quantity}
                </Box>
              </Box>
              <Box display="flex" className="prize-auctions-bid-and-message">
                <PrizeBid
                  cultureTag={cultureTag}
                  postBidPrize={postBidPrize!}
                  prizeId={result?.prizeId}
                  lastBidValue={result?.quantity < result.potentialWinners? result.value:(result.potentialWinners.length > 0 ?  result.potentialWinners?.slice(-1).pop().value : result.value) }
                  userAllowToBid={result?.userAllowToBid}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  className="prize-auctions-message"
                >
                  {result?.motiveDeniedBid !== "" ? (
                    <Resource tag={motiveDeniedBidResource!} />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className="prize-auctions-container-right-side"
            overflow="hidden"
            maxHeight="205px"
            ml={"26px"}
          >
            <ListWinning
              cultureTag={props?.cultureTag}
              winners={result?.potentialWinners}
              bids={result?.userBids}
              mode="bid"
              total={result?.totalBidAlreadyMade}
            />
          </Box>
        </Box>
      </Box>
    );
};
export default ActiveAuctionPrizeDescription;
