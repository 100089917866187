import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { ICultureState } from "redux/reducers/cultureReducer";
import React, { useState, useLayoutEffect } from "react";
import { getIdeasInProgress } from 'redux/actions/ideas/IdeaAction';
import { IIdeasInProgress } from "redux/types/ideas";
import { Box } from "@material-ui/core";
import IdeaList from "containers/IdeaList/IdeaList";
import Resource from "components/Resources/Resource";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";

interface IDispatchProps {
  getIdeasInProgress: (options: object) => Promise<IIdeasInProgress>
}

interface IStateProps {
  ideasInProgress: IIdeasInProgress;
  applicationId: string;
  culture: ICultureState;
  challengeId: string;
  q: string;
}

function IdeasInProgress(props: IDispatchProps & IStateProps) {
  const {
    ideasInProgress,
    getIdeasInProgress,
    challengeId,
    q,
  } = props;
  const [pageNumber] = useState(1);
  const [pageSize] = useState(6);

  useLayoutEffect(() => {
    getIdeasInProgress({
      challengeId,
      q,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function showMore(typeTabs?: string, pageNumber?: number, pageSize?: number, isInfinite?: boolean) {
    if(typeTabs === 'inprogress') {
      getIdeasInProgress({
        challengeId,
        q,
        pageNumber,
        pageSize,
        isInfinite
      });
    }
  }

  const inProgressTitle = <Resource tag="PageIdea::Idea_TitleInProgress" />;

  return (
    <Box mb={7} className="ideas-page-container">
      <div className="ideas" style={{ paddingTop: "10px" }}>
        <>
          <DynamicInfiniteScroll 
            dataList={ideasInProgress} 
            getAction={(pageNumber,pageSize) => showMore('inprogress', pageNumber, pageSize, true)}
            pageSize={6}
            isAutomatic
          >
            <IdeaList
              type="inprogress"
              //getMoreListIdea={showMore}
              ideasInProgress={ideasInProgress}
              listHeader={inProgressTitle}
            />
          </DynamicInfiniteScroll>
        </>
      </div>
    </Box>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getIdeasInProgress: (parameters: object) => dispatch(getIdeasInProgress(parameters))
  };
};

const mapStateToProps = (store: IAppState, ownProps: any) => {
  return {
    q: ownProps?.location?.state?.q,
    challengeId: ownProps?.location?.state?.challengeId,
    ideasInProgress: store.ideasState.ideasInProgress,
    culture: store.cultureState,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdeasInProgress);
