import React, { useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import Resource from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import XDialog from "common/X/XDialog";

interface IProps {
  setOpen: (open: boolean) => void;
  workspaceId?: string;
  culture?: ICultureState;
  open?: boolean
  isReschedule?: boolean
  putReschedule?: any
  userIds?: string[]
  scheduledDate?: any;
  setScheduledDate?: any;
  submitForm: (publicationDate: Date) => void
  newDate?: string;
  createNewStatus?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconBack: {
      display: "flex",
      position: "absolute",
      left: 28,
      top: 81.5,
    },
    boxSubtitle: {
      marginTop: "10px",
    },
  })
);

function DialogScheduleNew(props: IProps) {
  const { setOpen, open, submitForm, newDate, createNewStatus } = props;
  const classes = useStyles();
  const handleComplete = () => {
    submitForm(selectedDate)
  }
  const [selectedDate, setSelectedDate] = useState(newDate ? new Date(newDate) : new Date());
  const [time, setTime] = useState("00:00")
  const handleDateChange = (date: any) => {
    setSelectedDate(new Date(date));
  };

  return (
    <>
      <XDialog
        open={open!}
        setOpen={setOpen}
        title={<Resource tag="PageCreateNews::SchedulePublication" />}
        actions={
          <Grid container>
            <Grid item xs={12}>
              <Box textAlign="right">
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={() => setOpen(false)}
                  style={{ marginRight: 8 }}
                >
                  <Resource tag="PageCreateNews::Cancel" />
                </Button>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  endIcon={
                    createNewStatus === "LOADING" ? (
                      <CircularProgress size={16} color="inherit" />
                    ) : (
                      ""
                    )
                  }
                  disabled={createNewStatus === "LOADING"}
                  onClick={() => handleComplete()}
                >
                  <Resource tag="PageCreateNews::Save" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
        maxWidth="md"
      >
        <Box px={4.5}>
          <Box my={3} className={classes.boxSubtitle} mt={10}>
            <Typography variant="body1">
              <strong><Resource tag="PageCreateNews::PublishDate" /></strong>
            </Typography>
            <Typography variant="body1" color="textSecondary">
              <Resource tag="PageCreateNews::SelectTheDate" />
            </Typography>
          </Box>
          <Box>
            <Box my={4}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <DatePicker
                    label={
                      <Typography variant="body2" color="textPrimary">
                        <Resource tag="PageCreateNews::Date" />
                      </Typography>
                    }
                    variant="inline"
                    format="dd/MM/yyyy"
                    disableToolbar
                    autoOk
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="time"
                    value={time}
                    onChange={(e)=> {
                      setTime(e.target.value)
                      const hour = Number(e.target.value.split(':')[0])
                      const minute = Number(e.target.value.split(':')[1])
                      if (hour)
                        setSelectedDate(new Date(selectedDate.setHours(hour, minute)))
                    }}
                    label={
                      <Typography variant="body2" color="textPrimary">
                        <Resource tag="PageCreateNews::Hour"/>
                      </Typography>
                    }
                    type="time"
                    // defaultValue={timeNow}
                    //ampm={props.culture?.culture.cultureTag === 'en-GB'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </XDialog>
    </>
  );
}

export default DialogScheduleNew;
