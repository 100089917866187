
import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { api } from "../../config/api";

import { IInvestment, IInvestmentState } from '../reducers/investmentReducer';
import { treatError } from "../../utils/treatError";

const basePath = "wallets/investments";

export enum InvestmentActionsTypes {
    GET_INVESTMENTS = 'GET_INVESTMENTS',
    SHOW_MORE = 'SHOW_MORE',
}

export interface IInvestmentGetAllAction {
    type: InvestmentActionsTypes.GET_INVESTMENTS;
    investment: IInvestment;
}
export interface IInvestmentsShowMoreAction {
  type: InvestmentActionsTypes.SHOW_MORE;
}



export type InvestmentActions =
| IInvestmentGetAllAction
| IInvestmentsShowMoreAction

export const showMoreInvestments: ActionCreator<IInvestmentsShowMoreAction> = () => ({
  type: InvestmentActionsTypes.SHOW_MORE,
})


export const getInvestments: ActionCreator<ThunkAction<Promise<any>, IInvestmentState, null, IInvestmentGetAllAction>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
        let params    = [];
        let params_qs = "";

        if( options.hasOwnProperty("pageNumber")){
          params.push(`pageNumber=${options.pageNumber}`);
        }
        if( options.hasOwnProperty("pageSize")){
          params.push(`pageSize=${options.pageSize}`);
        }
        
        params_qs = `?${params.join("&")}`;
        const response = await api.get(`${basePath}${params_qs}`, {});
        dispatch({
        investment: response.data,
          type: InvestmentActionsTypes.GET_INVESTMENTS,
        }); 
    } catch (err) {
      treatError(options.culture, 'Investment', err.toString(),['Investment']);
    }
  };
};