import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { api_general, api_v2, api_v3 } from "../../../config/api";
import { treatError } from "../../../utils/treatError";
import {
  ICreateNewsSuccessAction,
  IDeleteNewsSuccessAction,
  IFutureNewsSuccessAction,
  IHighlightNewsSuccessAction,
  INewsAction,
  INewsHighlightedGetAction,
  INewsPopularGetAction,
  INewsState,
  IUpdateNewsSuccessAction,
  NewsActionTypes,
  NewsStatusTypes,
} from "redux/types/news";
import newApp from "utils/reactNewApp";

const basePath = "news";

export const getNewsTopics: ActionCreator<ThunkAction<
  Promise<any>,
  INewsState,
  null,
  INewsAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";
      if (options.hasOwnProperty("workspaceId")) {
         params.push(`workspaceId=${options.workspaceId}`);
      }
      params_qs = `?${params.join("&")}`;
      if(newApp) {
         const response = await api_v3.get(`${basePath}/topics${params_qs}`);
         dispatch({
            topics: response.data,
            type: NewsActionTypes.GET_TOPICS,
         });
      }else{
         const response = await api_v2.get(`${basePath}/topics${params_qs}`);
         dispatch({
            topics: response.data,
            type: NewsActionTypes.GET_TOPICS,
         });
      }
      
    } catch (err) {
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

export const getNews: ActionCreator<ThunkAction<
  Promise<any>,
  INewsState,
  null,
  INewsAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("applicationId")) {
        params.push(`applicationId=${options.applicationId}`);
      }
      if (options.hasOwnProperty("workspaceId")) {
         params.push(`workspaceId=${options.workspaceId}`);
      }
      if (options.hasOwnProperty("isOrderByPublicationDate")) {
        params.push(`isOrderByPublicationDate=${options.isOrderByPublicationDate}`);
      }
      if (options.hasOwnProperty("topic")) {
        params.push(`topic=${options.topic}`);
      }
      if (options.hasOwnProperty("isFavorite")) {
        params.push(`isFavorite=${options.isFavorite}`);
      }
      if (options.hasOwnProperty("q")) {
        params.push(`q=${options.q}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      params_qs = `?${params.join("&")}`;
      if(newApp){
         const response = await api_v3.get(`${basePath}${params_qs}`);
         dispatch({
            news: response.data,
            type: NewsActionTypes.GET_NEWS,
         });
      }else{
         const response = await api_v2.get(`${basePath}${params_qs}`);
         dispatch({
            news: response.data,
            type: NewsActionTypes.GET_NEWS,
         });
      }
      
    } catch (err) {
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

export const getNewsHighlighted: ActionCreator<ThunkAction<
  Promise<any>,
  INewsState,
  null,
  INewsHighlightedGetAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
         params.push(`workspaceId=${options.workspaceId}`);
      }
      if (options.hasOwnProperty("applicationId")) {
        params.push(`applicationId=${options.applicationId}`);
      }
      if (options.hasOwnProperty("cultureTag ")) {
        params.push(`cultureTag =${options.cultureTag}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      params_qs = `?${params.join("&")}`;
      if(newApp){
         const response = await api_v3.get(`${basePath}/highlighted${params_qs}`);
         dispatch({
            newsHighlighted: response.data,
            type: NewsActionTypes.GET_NEWS_HIGHLIGHTED,
         });
      }else{
         const response = await api_v2.get(`${basePath}/highlighted${params_qs}`);
         dispatch({
            newsHighlighted: response.data,
            type: NewsActionTypes.GET_NEWS_HIGHLIGHTED,
         });
      }
      
    } catch (err) {
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

export const getNewsPopular: ActionCreator<ThunkAction<
  Promise<any>,
  INewsState,
  null,
  INewsPopularGetAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
         params.push(`workspaceId=${options.workspaceId}`);
      }
      if (options.hasOwnProperty("applicationId")) {
        params.push(`applicationId=${options.applicationId}`);
      }
      if (options.hasOwnProperty("cultureTag ")) {
        params.push(`cultureTag =${options.cultureTag}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      params_qs = `?${params.join("&")}`;
      if(newApp) {
         const response = await api_v3.get(`${basePath}/popular${params_qs}`);
         dispatch({
            newsPopular: response.data,
            type: NewsActionTypes.GET_NEWS_POPULAR,
         });
      }else{
         const response = await api_v2.get(`${basePath}/popular${params_qs}`);
         dispatch({
            newsPopular: response.data,
            type: NewsActionTypes.GET_NEWS_POPULAR,
         });
      }
    } catch (err) {
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

export const createNews: ActionCreator<ThunkAction<
  Promise<any>,
  INewsState,
  null,
  ICreateNewsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: NewsActionTypes.CREATE_NEW_LOAD,
        status: NewsStatusTypes.LOAD,
      });
      let params = {
        title: options.title,
        workspaceId: options.workspaceId,
        description: options.description,
        topic: options.topic,
        image: options.image,
        enableComments: options.enableComments,
        publicationDate: options.publicationDate,
        isHighlighted: options.isHighlighted
      };
      await api_v3.post(`${basePath}`, params);
      dispatch({
        type: NewsActionTypes.CREATE_NEW_SUCCESS,
        status: NewsStatusTypes.SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: NewsActionTypes.CREATE_NEW_FAILED,
        status: NewsStatusTypes.FAILED,
      });
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

export const updateNews: ActionCreator<ThunkAction<
  Promise<any>,
  INewsState,
  null,
  IUpdateNewsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: NewsActionTypes.UPDATE_NEW_LOAD,
        status: NewsStatusTypes.LOAD,
      });
      let params = {
        title: options.title,
        workspaceId: options.workspaceId,
        description: options.description,
        topic: options.topic,
        image: options.image,
        enableComments: options.enableComments,
        publicationDate: options.publicationDate,
        isHighlighted: options.isHighlighted
      };
      await api_v3.put(`${basePath}/${options.newsId}`, params);
      dispatch({
        type: NewsActionTypes.UPDATE_NEW_SUCCESS,
        status: NewsStatusTypes.SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: NewsActionTypes.UPDATE_NEW_FAILED,
        status: NewsStatusTypes.FAILED,
      });
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

export const deleteNew: ActionCreator<ThunkAction<
  Promise<any>,
  INewsState,
  null,
  IDeleteNewsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: NewsActionTypes.DELETE_NEW_LOAD,
        status: NewsStatusTypes.LOAD,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
         params.push(`workspaceId=${options.workspaceId}`);
      }
      params_qs = `?${params.join("&")}`;

      await api_v3.delete(`${basePath}/${options.newsId}${params_qs}`);
      dispatch({
        type: NewsActionTypes.DELETE_NEW_SUCCESS,
        status: NewsStatusTypes.SUCCESS,
        newsId: options.newsId
      });
    } catch (err) {
      dispatch({
        type: NewsActionTypes.DELETE_NEW_FAILED,
        status: NewsStatusTypes.FAILED,
      });
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

export const highlightNew: ActionCreator<ThunkAction<
  Promise<any>,
  INewsState,
  null,
  IHighlightNewsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: NewsActionTypes.HIGHLIGHT_NEW_LOAD,
        status: NewsStatusTypes.LOAD,
      });
      let params = {
        workspaceId: options.workspaceId,
        isHighlighted: options.isHighlighted
      };

      await api_v3.post(`${basePath}/${options.newsId}/highlight`, params);
      dispatch({
        type: NewsActionTypes.HIGHLIGHT_NEW_SUCCESS,
        status: NewsStatusTypes.SUCCESS,
        newsId: options.newsId,
        isHighlighted: options.isHighlighted
      });
    } catch (err) {
      dispatch({
        type: NewsActionTypes.HIGHLIGHT_NEW_FAILED,
        status: NewsStatusTypes.FAILED,
      });
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};

export const getFutureNews: ActionCreator<ThunkAction<
  Promise<any>,
  INewsState,
  null,
  IFutureNewsSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: NewsActionTypes.FUTURE_NEWS_LOAD,
        status: NewsStatusTypes.LOAD,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
         params.push(`workspaceId=${options.workspaceId}`);
      }
      params_qs = `?${params.join("&")}`;

      newApp && await api_general.get(`${basePath}/future${params_qs}`).then(response => {
        dispatch({
          type: NewsActionTypes.FUTURE_NEWS_SUCCESS,
          status: NewsStatusTypes.SUCCESS,
          futureNews: response.data
        });
      })
      
    } catch (err) {
      dispatch({
        type: NewsActionTypes.FUTURE_NEWS_FAILED,
        status: NewsStatusTypes.FAILED,
      });
      treatError(options?.culture, "PageNews::Header", err);
    }
  };
};
