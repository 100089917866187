import * as React from "react";
import Resource from "../../components/Resources/Resource";
import CommentsItem from "../../components/CommentsItem/CommentsItem";
import { IResultActivityComments } from "redux/types/myActivities";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Hidden,
  makeStyles,
  createStyles,
  Theme,
  TableBody,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: "20px 0px",
    },
    tableTitles: {
      "margin-top": "5px",
      "margin-bottom": "5px",
      "margin-left": "20px",
      padding: "20px 20px 0px 0px",
      color: " #767676",
      "font-weight": 500,
      "font-size": "12px",
      "text-transform": "uppercase",
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "none",
      },
    },
  })
);
interface IProps {
  comments: IResultActivityComments[];
  cultureTag: string;
  isPublic?: boolean;
}

function CommentsList(props: IProps) {
  const { comments, isPublic } = props;
  const classes = useStyles();
  return (
    <Table>
      <TableHead>
        <TableRow className={classes.tableRow}>
          <TableCell
            className={classes.tableTitles}
            variant="head"
            align="left"
          >
            <Hidden smDown>
              <Typography>
                <Resource tag="Common::MyComment" />
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography>
                <Resource tag="Common::Idea" />/
                <Resource tag="Common::MyComment" />
              </Typography>
            </Hidden>
          </TableCell>
          <TableCell
            className={classes.tableTitles}
            variant="head"
            align="left"
          >
            <Hidden smDown>
              <Typography>
                <Resource tag="Common::Idea" />
              </Typography>
            </Hidden>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {comments &&
          comments.map((data) => {
            return (
              <CommentsItem
                isPublic={isPublic}
                key={data.commentId}
                data={data}
              />
            );
          })}
      </TableBody>
    </Table>
  );
}

export default CommentsList;
