import { AuthActionTypes, AuthActions } from "../actions/AuthAction";
import { Reducer } from 'redux';
import { WorkspaceActions, WorkspaceActionTypes } from "redux/actions/workspaceAction";

// Define the Character type
export interface IAuthUser {
  jwtToken: IJWToken,
  isFirstAccess: boolean,
  isProfileSetted: boolean,
  isVisibleButtonHelp: boolean,
  backOfficeAllowed: boolean,
  backOfficeURL: string,
  cultureTag: string,
  applicationId:string,
  workspaceId: string,
  allowToSeePrizes: boolean,
  permission: string,
  allowCreateWorkspace: boolean,
  hasWorkspaceConfigured: boolean,
  notifyUserPasswordExpiration: boolean
  isAuthenticated: Boolean,
  resources: {}, 
}

export interface IJWToken{
  accessToken: string,
  expires: string
}

// Define the Character State
export interface IAuthState {
  readonly auth: IAuthUser;
}

// Define the initial state
const initialCharacterState: IAuthState = {
  auth: {
    jwtToken: {
      accessToken:"", 
      expires: ""
    }, 
    isFirstAccess:false,
    isProfileSetted:false,
    isVisibleButtonHelp: false,
    backOfficeAllowed: false,
    backOfficeURL: '',
    cultureTag:"", 
    applicationId:"",
    workspaceId: '',
    allowToSeePrizes: false,
    permission: "",
    allowCreateWorkspace: false,
    hasWorkspaceConfigured: false,
    notifyUserPasswordExpiration: false,
    isAuthenticated:false, 
    resources:{}, 
  }
};

export const authReducer: Reducer<IAuthState, AuthActions | WorkspaceActions> = (
  state = initialCharacterState,
  action) => {
  switch (action.type) {
    case WorkspaceActionTypes.POST_WORKSPACE_INVITE_SUCCEEDED:
    case WorkspaceActionTypes.PUT_WORKSPACE_COMPLETED_SUCCEEDED:
    case AuthActionTypes.LOGIN: {
      return {
        ...state,
        auth: {
          jwtToken: action.auth.jwtToken, 
          isFirstAccess:action.auth.isFirstAccess,
          isProfileSetted: action.auth.isProfileSetted,
          isVisibleButtonHelp: action.auth.isVisibleButtonHelp,
          backOfficeAllowed: action.auth.backOfficeAllowed,
          backOfficeURL: action.auth.backOfficeURL,
          cultureTag:action.auth.cultureTag,
          applicationId: action.auth.applicationId,
          workspaceId: action.auth.workspaceId,
          allowToSeePrizes: action.auth.allowToSeePrizes,
          permission: action.auth.permission,
          allowCreateWorkspace: action.auth.allowCreateWorkspace,
          hasWorkspaceConfigured: action.auth.hasWorkspaceConfigured,
          notifyUserPasswordExpiration: action.auth.notifyUserPasswordExpiration,
          isAuthenticated:action.auth.isAuthenticated, 
          resources: action.auth.resources,      
        },
      };
    }
    case AuthActionTypes.LOGOUT: {
      return {
        ...state,
        auth: {
          jwtToken: action.auth.jwtToken, 
          isFirstAccess:action.auth.isFirstAccess,
          isProfileSetted: action.auth.isProfileSetted,
          isVisibleButtonHelp: action.auth.isVisibleButtonHelp,
          backOfficeAllowed: action.auth.backOfficeAllowed,
          backOfficeURL: action.auth.backOfficeURL,
          cultureTag:action.auth.cultureTag,
          applicationId: action.auth.applicationId,
          workspaceId: action.auth.workspaceId,
          allowToSeePrizes: action.auth.allowToSeePrizes,
          permission: action.auth.permission,
          allowCreateWorkspace: action.auth.allowCreateWorkspace,
          hasWorkspaceConfigured: action.auth.hasWorkspaceConfigured,
          notifyUserPasswordExpiration: action.auth.notifyUserPasswordExpiration,
          isAuthenticated:action.auth.isAuthenticated, 
          resources: action.auth.resources,      
        },  
      };
    }
    default:
      return state;
  } 
}