import React from 'react';
import './MyActivitiesNotProgress.scss';
import EMIcon from 'components/Icon/Icon';
import { IResultMyActivitiesIdeasClosed, } from '../../redux/types/myActivities';
import { Link } from 'react-router-dom';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import { Box, Hidden } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      margin: '0 5px'
    },
    tableRow: {
      borderBottom: 'solid 1px #ccc',
      
    },
    firstTableCell:{
      padding: '24px 10px 24px 0px!important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '250px',
      maxWidth: '250px',
      [theme.breakpoints.down('sm')]:{
        minWidth: '80px',
        maxWidth: '80px',
      } 
    },
    tableCell: {
      padding: '24px 10px 24px 0px!important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '150px'
    },
    icon: {
      margin: '0 5px'
    },
    linkItem: {
      fontSize: '14px',
      color: '#333',
      fontWeight: "bold",
      '& a':{
        'textDecoration': 'none',
        'color': '#333',
        'display': '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        'overflow': "hidden",
        'text-overflow': "ellipsis",
      }
    },
    ideaDate: {
      fontSize: 10,
      color: '#adadad',
      fontWeight: "bold",
      textTransform: 'lowercase',
      '&:first-line': {
        textTransform: 'capitalize',
      },
    },
    ideaEvaluation: {
      fontSize: 14,
      fontWeight: 'bold',
      color: '#5f5f5f'
    },
    ideaStatus: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#333'
    },
    ideaManager: {
      fontSize: 12,
      color: '#333'
    }
  }),
);

interface IProps {
  ideasClosed: IResultMyActivitiesIdeasClosed,
}

function MyActivitiesNotProgress(props: IProps) {
  const { ideasClosed } = props;
  const ideaBackURL = "/idea/details";
  const classes = useStyles()
  return (
    <TableBody >
      <TableRow className={classes.tableRow}>
        <TableCell align="left" className={classes.firstTableCell + " " + classes.linkItem}>
          <Link to={{
            pathname: `${ideaBackURL}/${ideasClosed?.ideaId}`
          }}>
            {ideasClosed?.ideaTitle}
          </Link>
          <Hidden implementation="css" smUp>
            <Box className={classes.ideaStatus}>{ideasClosed?.status}</Box>
          </Hidden>
          <Hidden implementation="css" smUp>
            <Box className={classes.ideaDate}>{ideasClosed?.date}</Box>
          </Hidden>
        </TableCell>
        <TableCell align="center" className={classes.tableCell + " " + classes.ideaDate}>
          <Hidden xsDown>{ideasClosed?.date}</Hidden>
        </TableCell>
        <TableCell align="center" className={classes.tableCell + " " + classes.ideaEvaluation}>
          <span>
            {ideasClosed?.evaluation?.value}
            {ideasClosed?.evaluation?.value ?
              <em className={classes.icon}>
                {ideasClosed?.evaluation?.type === "Stars" ?
                  <EMIcon icon="star-b" size={13} color="#5f5f5f" />
                  : <EMIcon icon="investment" size={13} color="#5f5f5f" />
                }
              </em>
              :
              <em className={classes.icon}> - </em>
            }
          </span>
        </TableCell>
        <TableCell align="center" className={classes.tableCell + " " + classes.ideaStatus}>
          <Hidden xsDown>{ideasClosed?.status}</Hidden>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

export default MyActivitiesNotProgress