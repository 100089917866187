import React, { useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import Resource from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import XDialog from "common/X/XDialog";

interface IProps {
  setOpen: (open: boolean) => void;
  workspaceId?: string;
  culture?: ICultureState;
  open?: boolean
  isReschedule?: boolean
  putReschedule?: any
  userIds?: string[]
  scheduledDate?: any;
  setScheduledDate?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconBack: {
      display: "flex",
      position: "absolute",
      left: 28,
      top: 81.5,
    },
    boxSubtitle: {
      marginTop: "10px",
    },
  })
);

function DialogManageUsersScheduleInvite(props: IProps) {
  const { setOpen, workspaceId, open, isReschedule, putReschedule, userIds, scheduledDate, setScheduledDate, culture } = props;
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(Date.now());

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
  
  const dates = new Date();
  let hour = dates.getHours();
  let minute = dates.getMinutes();
  let timeNow = hour + ":" + minute;
  const handleChanges = async () => {
    await putReschedule({
      workspaceId,
      scheduleDate: selectedDate,
      userIds,
      culture
    }).then(() => setOpen(false))
  }
  return (
    <>
      {isReschedule ?
        <XDialog
          open={open!}
          setOpen={setOpen}
          title={<Resource tag="PageManageUsers::InviteToWorkspace" />}
          actions={
            <Grid container>
              <Grid item xs={12}>
                <Box textAlign="right">
                  <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => setOpen(false)}
                    style={{ marginRight: 8 }}
                  >
                    <Resource tag="PageManageUsers::Cancel" />
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => handleChanges()}
                  >
                    <Resource tag="PageManageUsers::SaveChanges" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          maxWidth="md"
        >
          <Box px={4.5}>
            <Box my={3} className={classes.boxSubtitle} mt={10}>
            </Box>
            <Box>
              <Box my={4}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <DatePicker
                      label={
                        <Typography variant="body2" color="textPrimary">
                          <Resource tag="PageManageUsers::InvitationDate" />
                        </Typography>
                      }
                      variant="inline"
                      format="dd/MM/yyyy"
                      disableToolbar
                      autoOk
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="time"
                      label={
                        <Typography variant="body2" color="textPrimary">
                          <Resource tag="PageManageUsers::InvitationHour" />
                        </Typography>
                      }
                      type="time"
                      defaultValue={timeNow}
                      //ampm={props.culture?.culture.cultureTag === 'en-GB'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </XDialog>
      :
      <Box px={4.5}>
        <Box my={3} className={classes.boxSubtitle} mt={10}>
          <Box mb={2}>
            <IconButton
              color="primary"
              onClick={() => {setOpen(false)}}
              className={classes.iconBack}
            >
              <ArrowBackIosRoundedIcon />
            </IconButton>
            <Typography variant="h4" color="textPrimary">
              <Resource tag="PageManageUsers::ScheduleInvite" />
            </Typography>
            <Box mt={1}>
              <Typography variant="body2" color="textSecondary">
                <Resource tag="PageManageUsers::SelectTheDate" />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box my={4}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <DatePicker
                  label={
                    <Typography variant="body2" color="textPrimary">
                      <Resource tag="PageManageUsers::InvitationDate" />
                    </Typography>
                  }
                  variant="inline"
                  format="dd/MM/yyyy"
                  disableToolbar
                  autoOk
                  value={scheduledDate}
                  onChange={(date) => setScheduledDate(date)}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="time"
                  label={
                    <Typography variant="body2" color="textPrimary">
                      <Resource tag="PageManageUsers::InvitationHour" />
                    </Typography>
                  }
                  type="time"
                  defaultValue={timeNow}
                  //ampm={props.culture?.culture.cultureTag === 'en-GB'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      }
      
    </>
  );
}

export default DialogManageUsersScheduleInvite;
