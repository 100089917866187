import "./FormTag.css";
import { toast } from "react-toastify";
import ToolTip from "components/ToolTip/ToolTip";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useEffect, useState } from "react";
import Resource, { resource } from "../Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";

interface IProps {
  handler?: any;
  tags?: string[];
  culture: ICultureState;
  placeholder?: string;
  tooltip?: any;
}

function FormTag(props: IProps) {
  const [tags, setTags] = useState(
    props.tags ? props.tags : ([] as Array<string>)
  );
  const [tagsString, setTagStrings] = useState(
    props.tags ? props.tags.join(";") : ""
  );
  const [currentValue, setValue] = useState("");

  const handleValueChange = (e: any) => {
    const { value } = e.target;

    setValue(value);
  };

  const handleAddTag = () => {
    if (currentValue.trim() !== "") {
      if (tags.indexOf(currentValue) === -1) {
        setTags([...tags, currentValue]);
        setTagStrings([...tags, currentValue].join(";"));
        setValue("");
      } else {
        toast.error(
          resource(props.culture, "PageNewIdea::ThisTagAlreadyExists")
        );
      }
    }
  };
  useEffect(() => {
    if ([...tags, currentValue].join(";") !== "") {
      props.handler(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, currentValue, tagsString]);
  const handleEnter = (e: any) => {
    if (e.keyCode === 13) {
      handleAddTag();
    }
  };

  const deleteTag = (value: any) => {
    const newTagsArr = tags.filter((tag) => tag !== value);
    setTags(newTagsArr);
    setTagStrings(newTagsArr.join(";"));
  };

  const theme = useTheme()
  const { tooltip } = props;
  const label = (
    <Box className="label-tooltip" display='flex' position="relative" width="5%">
      <Typography style={{ fontWeight: "bold", fontSize: "0.8rem", color: theme.palette.text.secondary }}>
        <Resource tag="PageEditIdea::Idea_TagsPopUp_Header" />
      </Typography>
      {tooltip && (
        <Box component="span" position="absolute" top="-3px" right="-24px">
          <ToolTip>{tooltip}</ToolTip>
        </Box>
      )}
    </Box>
  );
  return (
    <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
      {label}
      <Input type="hidden" name="Tags" value={tagsString} />
      <Box display="flex" alignItems="center" gridGap={10}>
        <TextField
          //label={label}
          type="text"
          onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
          value={currentValue}
          placeholder={props.placeholder}
          onKeyUp={handleEnter}
          onChange={handleValueChange}
          inputProps={{ style: { width: "30vw" } }}
          InputLabelProps={{
            shrink: true,
            style: { fontWeight: "bold", fontSize: "1.05rem" },
          }}
        />
        <Box alignSelf="flex-end" ml={2} pb={"6px"}>
          <Button
            style={{ fontSize: "0.75rem" }}
            variant="contained"
            color="primary"
            onClick={handleAddTag}
            disableElevation
            disabled={!currentValue}
          >
            <Resource tag="PageEditIdea::Idea_TagsPopUp_ButtonAdd" />
          </Button>
        </Box>
      </Box>
      <Grid container>
        {tags &&
          tags.map((tag) => (
            <Box
              key={tag}
              width="fit-content"
              borderRadius={4}
              padding={"2px 8px"}
              mt={2}
              mr={1}
              display="flex"
              alignItems="center"
              bgcolor="rgb(241,241,243)"
            >
              <Box mr={1}>
                <Typography component="p" color="textSecondary">
                  {tag}
                </Typography>
              </Box>
              <IconButton onClick={() => deleteTag(tag)} style={{ padding: 0 }}>
                <ClearIcon style={{ fontSize: "1rem", color: "#313B53" }} />
              </IconButton>
            </Box>
          ))}
      </Grid>
    </Container>
  );
}

export default FormTag;
