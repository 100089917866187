import React, { useEffect, useState } from "react";
import "./KeyTaskTransitionList.scss";
import KeyTaskListItem from "../../components/KeyTaskListItem/KeyTaskListItem";
import KeyTaskDetailButtons from "../../components/KeyTaskDetailButtons/KeyTaskDetailButtons";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Theme,
  createStyles,
  makeStyles,
  Hidden,
  Typography,
  Checkbox,
  Box,
} from "@material-ui/core";
import Resource from "components/Resources/Resource";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { ICultureState } from "redux/reducers/cultureReducer";
import newApp from "utils/reactNewApp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: "20px 0px",
    },
    tableTitles: {
      padding: "5px 10px 0px 0!important",
      color: " #767676",
      "font-weight": 500,
      "text-transform": "uppercase",
    },
    tableRow: {
      borderBottom: "solid 1px #ccc",
      [theme.breakpoints.down("sm")]: {
        borderBottom: "none",
      },
    },
  })
);

interface IProps {
  transitionList?: any;
  radioButtons?: boolean;
  allowReject?: boolean;
  culture?: ICultureState;
  isEvaluation?: boolean;
  secondTab?: string;
  activeIndex?: number;
}

function KeyTaskTransitionList(props: IProps) {
  const classes = useStyles();
  const [checkAll, setCheckAll] = useState(false);
  const [dataObject, setDataObject] = useState({
    ideaId: "",
    title: [""],
    ideaIds: [""],
  });
  const {
    transitionList,
    radioButtons,
    allowReject,
    isEvaluation,
    activeIndex,
    secondTab,
  } = props;

  const handlerPopUp = (value: string, check: boolean) => {
    const data = value.split(",").map((data) => data);
    if (check) {
      dataObject.ideaIds[0] === "" && dataObject.ideaIds.shift();
      dataObject.title[1] === "" && dataObject.title.shift();

      const dataObjectHandler = {
        title: dataObject.title.concat(data[1]),
        ideaId: data[0],
        ideaIds: dataObject.ideaIds.concat(data[0]),
      };
      setDataObject(dataObjectHandler);
    } else {
      dataObject.title.length === 1 && dataObject.title.shift();
      const title = dataObject.title.splice(
        dataObject.title.indexOf(data[1]),
        1
      );
      const ideaIds = dataObject.ideaIds.filter((e) => e !== data[0]);
      const unCheckData = {
        title: title,
        ideaId: "",
        ideaIds,
      };
      setDataObject(unCheckData);
    }
  };
  const checkAllIdeaIdData = transitionList?.map((data: any) => data.ideaId);
  const checkAllTitleData = transitionList?.map((data: any) => data.ideaTitle);
  const handleCheckAll = (e: any) => {
    const check = e?.target?.checked;
    setCheckAll(check);
    if (check) {
      const allCheckData = {
        title: checkAllTitleData,
        ideaId: "",
        ideaIds: checkAllIdeaIdData,
      };
      setDataObject(allCheckData);
    } else {
      const allCheckData = {
        title: [],
        ideaId: "",
        ideaIds: [],
      };
      setDataObject(allCheckData);
    }
  };

  useEffect(() => {
    setDataObject({
      ideaId: "",
      title: [""],
      ideaIds: [""],
    });
  }, [transitionList]);

  return (
    <div>
      {transitionList?.length ? (
        <>
          <div className="key-task-transition-buttons">
            <KeyTaskDetailButtons
              secondTab={secondTab}
              isAllowedToChangeManager={
                transitionList[activeIndex!]?.isAllowedChangeManager
              }
              allowReject={allowReject}
              dataObject={dataObject}
              type="transition"
            />
          </div>
          <Table
            // aria-label="simple table"
            className={classes.table}
          >
            <TableHead className="key-header-list">
              {radioButtons && (
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableTitles}>
                    <Checkbox
                      onChange={handleCheckAll}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      icon={<RadioButtonUncheckedIcon />}
                      inputProps={{
                        "aria-label": "Checkbox A",
                        name: "idea-key-task",
                      }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell className={classes.tableTitles}>
                    <Hidden xsDown>
                      <Typography>
                        <Resource tag="PageIdea::Idea_ColumnIdeia" />
                      </Typography>
                    </Hidden>
                  </TableCell>
                  <TableCell className={classes.tableTitles}>
                    <Hidden implementation="css" xsDown>
                      <Typography>
                        <Resource tag="Common::Challenge" />
                      </Typography>
                    </Hidden>
                  </TableCell>
                  {!isEvaluation && (
                    <TableCell className={classes.tableTitles}>
                      <Hidden implementation="css" xsDown>
                        <Typography> 
                          { newApp ? <Resource tag="SearchFilter::StateOfEvaluationV3" /> : <Resource tag="SearchFilter::StateOfEvaluation" /> } 
                        </Typography>
                      </Hidden>
                    </TableCell>
                  )}
                  {!isEvaluation && (
                    <TableCell className={classes.tableTitles}>
                      <Hidden implementation="css" xsDown>
                        <Typography>
                          <Resource tag="PageKeyTasks::TimeInThisStatus" />
                        </Typography>
                      </Hidden>
                    </TableCell>
                  )}
                  {isEvaluation && (
                    <TableCell className={classes.tableTitles}>
                      <Hidden implementation="css" xsDown>
                        <Typography>
                          <Resource tag="PageKeyTasks::WeightedAverage" />
                        </Typography>
                      </Hidden>
                    </TableCell>
                  )}
                  {isEvaluation && (
                    <TableCell className={classes.tableTitles}>
                      <Hidden implementation="css" xsDown>
                        <Typography>
                          { newApp ? <Resource tag="PageKeyTasks::EvaluationStatusV3" /> : <Resource tag="PageKeyTasks::EvaluationStatus" /> }
                        </Typography>
                      </Hidden>
                    </TableCell>
                  )}
                  {isEvaluation && (
                    <TableCell className={classes.tableTitles}>
                      <Hidden implementation="css" xsDown>
                        <Typography>
                          <Resource tag="PageKeyTasks::WaitingTimeForTransition" />
                        </Typography>
                      </Hidden>
                    </TableCell>
                  )}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {transitionList?.map((list: any, index: any) => (
                <KeyTaskListItem
                  key={list?.ideaId}
                  checkAll={checkAll}
                  type="transition"
                  handlerPopUp={handlerPopUp}
                  radioButtons={radioButtons}
                  title={list.ideaTitle}
                  challenge={list.challengeTitle}
                  time={list.stateUpdated}
                  timeType={list.stateUpdatedAtResource}
                  evaluationType={list.evaluationType}
                  evaluationValue={list.value}
                  isEvaluation={isEvaluation}
                  totalUsersEvaluation={list.totalUsersEvaluation}
                  totalUsersLeftToEvaluation={list.totalUsersLeftToEvaluation}
                  usersLeft={list.usersLeftToEvaluation}
                  ideaId={list.ideaId}
                />
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Box textAlign="center" width="100%" p={5}>
          <Typography color="textSecondary" variant="caption">
            <Resource tag="PageKeyTasks::Error_NoKeyTasksAvailable" />
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default KeyTaskTransitionList;
