import {
  createStyles,
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
  TextFieldProps,
  Theme,
} from "@material-ui/core";
import Resource from "components/Resources/Resource";
import React from "react";

interface IProps {
  value?: any;
  maxLength: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleT: {
      "& .MuiInputLabel-root": {
        fontWeight: 700,
        fontSize: "1.05rem",
        textTransform: "uppercase",
      },
      "& .MuiInputBase-root": {
        fontSize: "0.88rem",
        color: theme.palette.text.primary,
      },
    },
  })
);

function TextCounter({
  value,
  maxLength,
  ...props
}: IProps & TextFieldProps) {
  const classes = useStyles();

  return (
    <FormControl style={props.fullWidth ? {width:"100%"} : {}}>
      <TextField
        className={classes.titleT}
        value={value}
        inputProps={{
          maxLength: maxLength,
        }}
        {...props}
      />
      <FormHelperText style={{ textAlign: "right" }}>
        <Resource tag="ManageGroups::AvailableCharacters" />:{" "}
        {maxLength - value?.length}{" "}
      </FormHelperText>
    </FormControl>
  );
}

export default TextCounter;
