import React, { useState } from "react";
import MiniLine from "../../components/MiniLine/MiniLine";
import Resource from "../../components/Resources/Resource";
import "./ReviewInvestments.css";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  useTheme,
} from "@material-ui/core";
import { IResultIdeasCollaborativeEvaluationInvestment } from "redux/types/ideas";
import ReviewSellPopup from "./ReviewSellPopup";
import ReviewReinvestmentPopup from "./ReviewReinvestPopup";
import InvestmentPopUp from "./ReviewInvestPopup";
import EMIcon from "components/Icon/Icon";

export const useStylesInvestmentPopup = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    pageTitle: {
      marginTop: 24,
    },
    popupTitle: {
      textTransform: "lowercase",
      "&:first-letter": {
        textTransform: "capitalize",
      },
      padding: "21px 36px 4px 36px",
    },
    titleDivider: {
      width: "96%",
      margin: "10px 0",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    amountDivider: {
      margin: "12px 0",
      width: "50%",
    },
    reviewInvestmentsModalText: {
      marginTop: 35,
      textAlign: "center",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

interface IProps {
  title: string;
  investment: IResultIdeasCollaborativeEvaluationInvestment;
  postInvestments: (value: any, isIncrease: boolean) => void;
  sellInvestments: (value: any) => void;
  points: number;
  culture: ICultureState;
  isChooseForImplementation?: boolean;
}

export default function ReviewInvestments(props: IProps) {
  const { investment, points, postInvestments, sellInvestments, title, isChooseForImplementation } =
    props || {};
  const theme = useTheme();
  const [isSellOn, setSellOn] = useState(false);
  const [isInvestmentOn, setInvestmentOn] = useState(false);
  const [isReinvestmentOn, setReinvestmenOn] = useState(false);
  const InvestmentValueSize = 22;
  const varSize = 15;

  const graph = Object.values(investment.investmentGraph);
  const BeliefVariation = investment.investmentBeliefVariation;
  const BeliefValue = investment.investmentBeliefValue;
  const invested = investment.investmentValue > 0;
  const lBtnInvest = (investment.maximumInvestment > 0) && (!isChooseForImplementation);

  return (
    <div className="ReviewInvestments">
      <div className="ReviewInvestments-container">
        <div className="ReviewInvestmentsInvBox">
          <div className="ReviewInvestmentsTitle">
            <Resource tag={"PageDetailIdea::InvestmentArea_Title"} />
          </div>
          {graph != null ? (
            <div className="ReviewInvestmentsGraph">
              <MiniLine
                data={graph}
                height={180}
                showPoints={false}
                variation={BeliefVariation}
              />
            </div>
          ) : (
            <span></span>
          )}
        </div>
        <div className="ReviewInvestmentsBelief">
          <div
            className="ReviewInvestmentsValue"
            style={{ fontSize: InvestmentValueSize }}
          >
            {BeliefValue.toFixed(0)}%
            {BeliefVariation === 0 ? (
              <span
                style={{ fontSize: varSize }}
                className="ReviewInvestmentsVarZero"
              >
                {BeliefVariation.toFixed(0)}%
              </span>
            ) : BeliefVariation != null && BeliefVariation >= 0 ? (
              <span
                style={{ fontSize: varSize }}
                className="ReviewInvestmentsVarPositive"
              >
                <span>&#129145;</span>
                {BeliefVariation.toFixed(0)}%
              </span>
            ) : (
              <span
                style={{ fontSize: varSize }}
                className="ReviewInvestmentsVarNegative"
              >
                <span>&#129147;</span>
                {BeliefVariation.toFixed(0)}%
              </span>
            )}
          </div>

          <div className="ReviewInvestmentsCrowdBelief">
            <Resource tag={"PageDetailIdea::InvestmentArea_CrowdBelief"} />
          </div>
        </div>
      </div>
      {invested ? (
        <>
          <div className="ReviewInvestments-points">
            <div className="ReviewInvestmentsMyInvest">
              {" "}
              <Resource tag={"PageDetailIdea::InvestmentArea_MyInvestment"} />:
            </div>
            <div className="ReviewInvestmentsMyInvestPoints">
              <div className="ReviewInvestmentsMyInvestPointsToApprove">
                {investment.isIncreaseInvestment ? (
                  <Resource
                    tag={"PageDetailIdea::InvestmentArea_PointsToApprove"}
                    args={[investment.investmentValue]}
                  />
                ) : (
                  <Resource
                    tag={"PageDetailIdea::InvestmentArea_PointsToReject"}
                    args={[investment.investmentValue]}
                  />
                )}
                <br />
              </div>
              {investment.investmentValue <= investment.investmentVariation && (
                <Resource tag={"PageDetailIdea::InvestmentArea_Gain"} />
              )}
              {investment.investmentValue > investment.investmentVariation && (
                <Resource tag={"PageDetailIdea::InvestmentArea_Loss"} />
              )}

              {investment.investmentValue <= investment.investmentVariation && (
                <span className="ReviewInvestmentsGain">
                  &nbsp;
                  <strong>
                    {investment.investmentVariation -
                      investment.investmentValue}
                  </strong>
                  &nbsp;
                  <Resource tag={"Common::points"} />
                </span>
              )}
              {investment.investmentValue > investment.investmentVariation && (
                <span className="ReviewInvestmentsLoss">
                  &nbsp;
                  <strong>
                    {investment.investmentVariation -
                      investment.investmentValue}
                  </strong>
                  &nbsp;
                  <Resource tag={"Common::points"} />
                </span>
              )}
            </div>
          </div>
          <div className="ReviewInvestments-buttons">
            <div>
              {
                <Button
                  onClick={() => setSellOn(true)}
                  color="primary"
                  variant="outlined"
                  disabled={!investment.allowSell}
                  size="small"
                >
                  &nbsp;&nbsp;
                  <Resource tag="PageDetailIdea::InvestmentArea_ButtonSell" />
                  &nbsp;&nbsp;
                </Button>
              }
              <ReviewSellPopup
                investment={investment}
                isSellOn={isSellOn}
                setSellOn={setSellOn}
                points={points}
                title={title}
                sellInvestments={sellInvestments}
              />
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              <Button
                startIcon={
                  <EMIcon
                    color={
                      !investment.maximumInvestment
                        ? theme.palette.grey[500]
                        : theme.palette.primary.main
                    }
                    icon={"investment"}
                    size={12}
                  />
                }
                onClick={() => setReinvestmenOn(true)}
                color="primary"
                variant="outlined"
                disabled={!lBtnInvest}
                size="small"
              >
                <Resource tag="PageDetailIdea::InvestmentArea_ButtonReinvest" />
                &nbsp;&nbsp;
              </Button>
              <ReviewReinvestmentPopup
                culture={props.culture}
                investment={investment}
                isReinvestmentOn={isReinvestmentOn}
                setReinvestmentOn={setReinvestmenOn}
                points={points}
                title={title}
                postInvestments={postInvestments}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="ReviewInvestments-container">
          <div>
            <Button
              onClick={() => setInvestmentOn(true)}
              startIcon={
                <EMIcon
                  color={
                    !investment.maximumInvestment
                      ? theme.palette.grey[500]
                      : theme.palette.primary.main
                  }
                  icon={"investment"}
                  size={12}
                />
              }
              color="primary"
              variant="outlined"
              disabled={!lBtnInvest}
              size="small"
            >
              <Resource tag="PageDetailIdea::InvestmentArea_ButtonInvest" />
            </Button>
            <InvestmentPopUp
              culture={props.culture}
              investment={investment}
              isInvestmentOn={isInvestmentOn}
              setInvestmentOn={setInvestmentOn}
              points={points}
              title={title}
              postInvestments={postInvestments}
            />
          </div>
        </div>
      )}
    </div>
  );
}
