import history from "routes/history";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ArrowForward } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { IAppState, store } from "redux/store/Store";
import { IAuthLoginAction } from "redux/actions/AuthAction";
import { ICultureState } from "redux/reducers/cultureReducer";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Resource, { resource } from "components/Resources/Resource";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  getResourcesDefault,
  ICultureSetCulture,
} from "redux/actions/CultureActions";

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: "100%",
      "& label": {
        textTransform: "uppercase",
      },
    },
  })
);

interface IProps {
  getResourcesDefault: () => Promise<ICultureSetCulture>;
}

interface IStateProps {
  cultureState: ICultureState;
  token: string;
  cultureStatus: string;
}

interface IMatch {
  token: string;
}

interface IState {
  password: string;
  confirmEmail: string;
}

function NewUserLink(props: IStateProps & IState & IProps) {
  const { cultureState, token, cultureStatus } = props;
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [msgError, setMsgError] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  function setCulture() {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store.dispatch;
    dispatch(getResourcesDefault());
  }

  useEffect(() => {
    cultureState?.culture?.resources?.length === 0 && setCulture();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function submitEmail(e: any) {
    e.preventDefault();
    setLoadingSubmit(true);

    const atSignIndex = email.indexOf("@");
    const dotIndex = email.indexOf(".", atSignIndex);

    if (atSignIndex === -1 || dotIndex === -1) {
      setMsgError(resource(cultureState, "PageLogin::InvalidEmail"));
      setLoadingSubmit(false);
    } else {
      history.push({
        pathname: `/newUser/email=${email}&token=${token}`,
        state: {
          email,
        },
      });
      setLoadingSubmit(false);
    }
  }

  return (
    <>
      {token ? (
        <form name="form" onSubmit={submitEmail}>
          {cultureStatus === "LOADING" ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="500px"
            >
              <CircularProgress size={64} />
            </Box>
          ) : (
            <>
              <Box pt={4}>
                <Box fontSize="1.8rem" fontWeight="bold" component="p">
                  <Resource tag="PageLogin::Welcome" />
                </Box>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  <Resource tag="PageLogin::DefineYourEmail" />
                </Typography>
              </Box>
              <Box mt={6} mb={6}>
                <FormControl className={classes.textField}>
                  <InputLabel htmlFor="LoginFieldEmail">
                    <Resource tag={"PageLogin::ChangeEmail_Email"} />
                  </InputLabel>
                  <Input
                    id="LoginFieldEmail"
                    type={"email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                {msgError && (
                  <Box marginTop={2}>
                    <Typography color="error">{msgError}</Typography>
                  </Box>
                )}
              </Box>
              <Box textAlign="end">
                <Button
                  type="submit"
                  variant={!loadingSubmit ? "contained" : "outlined"}
                  color="primary"
                  disabled={!email}
                  endIcon={
                    !loadingSubmit ? (
                      <ArrowForward className="icon icon-arrow" />
                    ) : (
                      <CircularProgress size={22} className="icon" />
                    )
                  }
                >
                  <Resource tag="PageLogin::Next" />
                </Button>
              </Box>
            </>
          )}
        </form>
      ) : (
        <Redirect to="/log-in" />
      )}
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, IAuthLoginAction>
): IProps => {
  return {
    getResourcesDefault: () => dispatch(getResourcesDefault()),
  };
};

const mapStateToProps = (
  store: IAppState,
  ownProps: RouteComponentProps<IMatch>
): IStateProps => {
  return {
    cultureState: store.cultureState,
    cultureStatus: store.cultureState.statusCultureDefault,
    token: ownProps.match.params.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUserLink);
