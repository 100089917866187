import {
  Box,
  createStyles,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  NativeSelect,
  Paper,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import Resource, { resource } from "../Resources/Resource";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import newApp from "utils/reactNewApp";
import {
  IFilters,
  IKeyTasksState,
} from "redux/reducers/keyTasks/keyTasksReducer";

const options = {
  pageNumber: 1,
  pageSize: 4,
  searchTerm: "",
  challengeId: "00000000-0000-0000-0000-000000000000",
  statusId: 0,
  stateId: "00000000-0000-0000-0000-000000000000",
  fromAverage: 0,
  toAverage: 0,
  hasEvaluation: 0,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperP: {
      padding: "20px 30px",
      background: "#F2F2F2",
      width: "100%",
    },
    paperSearch: {
      borderRadius: 22,
      padding: "3px 10px 2px 14px",
      display: "flex",
    },
    iconButton: {
      padding: 0,
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    inputLabel: {
      width: "max-content",
      textTransform: "uppercase",
    },
  })
);

const SearchInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "0.7rem",
      padding: "0 0 0",
    },
  })
)(InputBase);

const SelectInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "& .MuiNativeSelect-icon": {
        color: theme.palette.primary.main,
      },
    },
    input: {
      borderRadius: 22,
      backgroundColor: theme.palette.background.paper,
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "0.8rem",
      padding: "7px 26px 7px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 22,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.1rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const AverageInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      width: "32px",
      borderRadius: 22,
      backgroundColor: theme.palette.background.paper,
      fontSize: 16,
      padding: "4px 26px 4px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 22,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.1rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

interface IProps {
  culture?: ICultureState;
  filters: IFilters;
  isEvaluation?: boolean;
  stateId: string;
  getTransitionKeyTasks(options: object): Promise<IKeyTasksState>;
}

function TransitionFilter(props: IProps): JSX.Element {
  const classes = useStyles();
  const { filters, getTransitionKeyTasks, isEvaluation, stateId } = props;
  const filterData = (e: any) => {
    let value = e.target.value;
    e.preventDefault();
    switch (e.target.id) {
      case "task-type-status":
        options.statusId = value;
        break;
      case "task-type-challenge-transition":
        options.challengeId = value;
        break;
      case "task-type-state-transition":
        options.hasEvaluation = value;
        break;
      case "task-average-from":
        options.fromAverage = value;
        break;
      case "task-average-to":
        options.toAverage = value;
        break;
      default:
        break;
    }
    getTransitionKeyTasks({
      ...options,
      stateId: stateId ? stateId : "00000000-0000-0000-0000-000000000000",
    });
  };
  let keyTimer: any;
  let waitTimeAfterType = 100;
  const handlerKeyUp = (e: any, from?: string) => {
    let text = e.value;
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      if (from === "from" || from === "to") {
        searchAverage(text, from!);
      } else {
        search(text);
      }
    }, waitTimeAfterType);
  };
  const handlerKeydDown = (e: any) => {
    clearTimeout(keyTimer);
  };

  const search = (text: string) => {
    if (text.length >= 3) {
      options.searchTerm = text;
      getTransitionKeyTasks({
        ...options,
        stateId: stateId ? stateId : "00000000-0000-0000-0000-000000000000",
      });
    } else {
      options.searchTerm = "";
      getTransitionKeyTasks({
        ...options,
        stateId: stateId ? stateId : "00000000-0000-0000-0000-000000000000",
      });
    }
  };
  const searchAverage = (text: string, method: string) => {
    if (method === "from") {
      options.fromAverage = Number(text);
    } else {
      options.toAverage = Number(text);
    }
    if (options.fromAverage > 0 && options.toAverage > 0) {
      getTransitionKeyTasks({
        ...options,
        stateId: stateId ? stateId : "00000000-0000-0000-0000-000000000000",
      });
    }
    if (options.fromAverage === 0 && options.toAverage === 0) {
      getTransitionKeyTasks({
        ...options,
        stateId: stateId ? stateId : "00000000-0000-0000-0000-000000000000",
      });
    }
  };

  return (
    <Box marginY={2} width="100%">
      <Paper elevation={0} className={classes.paperP}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <FormControl
              fullWidth
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <InputLabel
                htmlFor="task-type-challenge-transition"
                shrink={true}
                className={classes.inputLabel}
              >
                <Resource tag={"Common::Challenge"} />
              </InputLabel>
              <NativeSelect
                id="task-type-challenge-transition"
                onChange={filterData}
                input={<SelectInput />}
              >
                {filters?.challenges &&
                  filters?.challenges?.map((data, index) => {
                    return (
                      <option key={index} value={data.challengeId}>
                        {data.name === "PageKeyTasks.TaskType_Default"
                          ? resource(props?.culture, "Common::All")
                          : data.name}
                      </option>
                    );
                  })}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md
            xl
            style={{ display: isEvaluation ? "block" : "none" }}
          >
            <FormControl
              fullWidth
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <InputLabel
                htmlFor="task-type-state-transition"
                shrink={true}
                className={classes.inputLabel}
              > 
              { newApp ? <Resource tag="SearchFilter::StateOfEvaluationV3" /> : <Resource tag="SearchFilter::StateOfEvaluation" /> }
              </InputLabel>
              <NativeSelect
                id="task-type-state-transition"
                onChange={filterData}
                input={<SelectInput />}
              >
                <option value={0}>
                  {" "}
                  {resource(props.culture, "Common::All")}{" "}
                </option>
                <option value={1}>
                  {resource(props.culture, "PageKeyTasks::AllEvaluationsDone")}{" "}
                </option>
                <option value={2}>
                  {resource(props.culture, "PageKeyTasks::PendingEvaluations")}{" "}
                </option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            xl={3}
            style={{
              display: isEvaluation ? "flex" : "none",
              alignItems: "baseline",
            }}
          >
            <Box display="flex">
              <Box>
                <FormControl
                  style={{ flexDirection: "row", alignItems: "baseline" }}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <InputLabel
                    htmlFor="task-average-from"
                    shrink={true}
                    className={classes.inputLabel}
                  >
                    <Resource tag={"SearchFilter::AverageStarRating"} />
                  </InputLabel>
                  <AverageInput
                    id="task-average-from"
                    onKeyUp={(e) => {
                      handlerKeyUp(e.target, "from");
                      e.preventDefault();
                    }}
                    onKeyDown={handlerKeydDown}
                  />

                  <Box ml={1} mr={1}>
                    <Resource tag={"Common::to"} />
                  </Box>
                </FormControl>
              </Box>
              <Box display="flex" alignItems="flex-end">
                <FormControl
                  style={{
                    flexDirection: "row",
                    alignItems: "baseline",
                    justifyContent: "space-evenly",
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                  fullWidth
                >
                  <AverageInput
                    id="task-average-to"
                    onKeyUp={(e) => {
                      handlerKeyUp(e.target, "to");
                      e.preventDefault();
                    }}
                    onKeyDown={handlerKeydDown}
                  />
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} xl={3}>
            <Paper
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              elevation={0}
              className={`${classes.paperSearch} + ${classes.withoutLabel}`}
            >
              <SearchInput
                placeholder={resource(
                  props.culture,
                  "SearchFilter::SearchByIdeaTitle"
                )}
                inputMode="search"
                onKeyUp={(e) => {
                  handlerKeyUp(e.target);
                  e.preventDefault();
                }}
                onKeyDown={handlerKeydDown}
                fullWidth
              />
              <SearchIcon className={classes.iconButton} />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default TransitionFilter;
