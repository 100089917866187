import React from "react";
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import NewsList from "containers/NewsList/NewsList";
import Resource from "components/Resources/Resource";
import { INewsPopular } from "redux/types/news";
import {
  Grid,
  makeStyles,
  createStyles,
  Box,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 25,
    },
  })
);

interface IProps {
  popular: INewsPopular;
}

function NewsPopularPage(props: IProps) {
  const classes = useStyles();
  const { popular } = props;

  return (
    <>
      <Grid container>
        <Box mt={4} mb={2}>
          <Typography variant="h3">
            <Resource tag={"Common::Popular"}></Resource>
          </Typography>
        </Box>
      </Grid>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <NewsList arrNews={popular?.result} />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    popular: store.newsState.newsPopular,
  };
};

export default connect(mapStateToProps)(NewsPopularPage);
