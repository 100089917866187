import { Reducer } from "redux";
import {
  WorkspaceActions,
  WorkspaceActionTypes,
} from "redux/actions/workspaceAction";

// Define the Character type
export interface ISuggestedNamesWorkspaces extends Array<string> { }

// Define the Character type
export interface IImagesWorkspaces { }

// Define the Character type
export interface ICulturesWorkspaces {
  cultureTag: string;
  cultureDescription: string;
  cultureTitle:string;
}

// Define the Character type
export interface ITheme {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}

// Define the Character type
export interface IWorkspaceReducer {
  workspaceId: string;
  category: number;
  name: string;
  countries: string[],
  departments: string[],
  currencies: string[],
  photoLogo: string;
  photoLogin: string;
  isCompleted: boolean;
  tenantId: number;
  theme: ITheme;
}

// Define the Character State
export interface IWorkspaceState {
  readonly workspaces: IWorkspaceReducer;
  readonly suggestedNamesWorkspaces: ISuggestedNamesWorkspaces;
  readonly imagesWorkspaces: IImagesWorkspaces;
  readonly culturesWorkspaces: ICulturesWorkspaces[];
  readonly themesWorkspaces: ITheme;
  readonly status: string;
  readonly error: any;
}
// Define the initial state
const initialCharacterState: IWorkspaceState = {
  workspaces: {
    workspaceId: "",
    category: 0,
    name: "",
    photoLogo: "",
    photoLogin: "",
    isCompleted: false,
    tenantId: 0,
    countries: [],
    departments: [],
    currencies:[],
    theme: {
      primaryColor: "",
      secondaryColor: "",
      tertiaryColor: "",
    },
  },
  suggestedNamesWorkspaces: [],
  imagesWorkspaces: {} as IImagesWorkspaces,
  culturesWorkspaces: [] as ICulturesWorkspaces[],
  themesWorkspaces: {
    primaryColor: "",
    secondaryColor: "",
    tertiaryColor: "",
  },
  status: "",
  error: null,
};

export const workspaceReducer: Reducer<IWorkspaceState, WorkspaceActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case WorkspaceActionTypes.GET_ALL_WORKSPACES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_ALL_WORKSPACES_SUCCEEDED: {
      return {
        ...state,
        workspaces: action.workspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_ALL_WORKSPACES_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_SUGGESTEDNAMES_WORKSPACES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_SUGGESTEDNAMES_WORKSPACES_SUCCEEDED: {
      return {
        ...state,
        suggestedNamesWorkspaces: action.suggestedNamesWorkspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_SUGGESTEDNAMES_WORKSPACES_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_IMAGES_WORKSPACES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_IMAGES_WORKSPACES_SUCCEEDED: {
      return {
        ...state,
        imagesWorkspaces: action.imagesWorkspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_IMAGES_WORKSPACES_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_CULTURES_WORKSPACES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_CULTURES_WORKSPACES_SUCCEEDED: {
      return {
        ...state,
        culturesWorkspaces: action.culturesWorkspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_CULTURES_WORKSPACES_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_THEMES_WORKSPACES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_THEMES_WORKSPACES_SUCCEEDED: {
      return {
        ...state,
        themesWorkspaces: action.themesWorkspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.GET_THEMES_WORKSPACES_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.POST_WORKSPACE_CATEGORY_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.POST_WORKSPACE_CATEGORY_SUCCEEDED: {
      return {
        ...state,
        workspaces: action.workspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.POST_WORKSPACE_CATEGORY_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_CATEGORY_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_CATEGORY_SUCCEEDED: {
      return {
        ...state,
        workspaces: action.workspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_CATEGORY_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_NAME_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_NAME_SUCCEEDED: {
      return {
        ...state,
        workspaces: action.workspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_NAME_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_THEMES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_THEMES_SUCCEEDED: {
      return {
        ...state,
        workspaces: action.workspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_THEMES_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_IMAGES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_IMAGES_SUCCEEDED: {
      return {
        ...state,
        workspaces: action.workspaces,
        status: action.status,
      };
    }
    case WorkspaceActionTypes.PUT_WORKSPACE_IMAGES_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    default:
      return state;
  }
};
