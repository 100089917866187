import Resource from "../Resources/Resource";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import React, { useEffect, useState } from "react";
import { IResultPrizeActiveBuyNow } from "../../redux/reducers/prizeReducer";
import sanitizeHtml from "sanitize-html";
import {
  Card,
  Paper,
  CardMedia,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Hidden,
  Button,
  Grid,
  useTheme,
  MenuItem,
} from "@material-ui/core";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import newApp from "utils/reactNewApp";
import { securityTextEditor } from "utils/securityTextEditor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridGap: "10px",
      cursor: 'pointer'
    },
    card: {
      display: "grid",
      position: "relative",
      background: "transparent",
    },
    media: {
      height: 220,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      borderRadius: 6,
      backgroundBlendMode: "color",
    },
    body: {
      alignSelf: "baseline",
      textAlign: "start",
    },
    bidText: {
      display: "block",
      alignItems: "center",
      padding: "10px 16px",
      fontSize: "0.9rem",
    },
    currentBid: {
      height: "55px",
    },
    titleAndTime: {
      width: "100%",
      maxWidth: "250px",
      minWidth: "250px",
      display: "flex",
      flexDirection: "column",
      flex: 1,
      justifyContent: "flex-end",
      textAlign: "left",
      color: theme.palette.common.white,
      padding: "16px",
    },
    cardTitle: {
      fontSize: 20,
      fontWeight: 500,
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    cardSubTitle: {
      fontWeight: 200,
      marginTop: "4px",
      fontSize: "0.9rem",
      color: theme.palette.common.white,
    },
    buyNowBtn: {
      width: "fit-content",
      fontWeight: "bold",
      padding: "6px 12px",
      borderRadius: "20px",
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        transition: "all 1s ease",
      },
    },
    buyNowBtnDisabled: {
      width: "fit-content",
      fontWeight: "bold",
      padding: "6px 12px",
      borderRadius: "20px",
      cursor: "default",
      backgroundColor: theme.palette.text.disabled,
      color: `${theme.palette.text.hint} !important`,
    },
    soldOutBought: {
      width: "fit-content",
      cursor: "default",
      padding: "6px",
      borderRadius: "5px",
      fontSize: "0.7rem",
      fontWeight: 800,
      marginBottom: "8px",
    },
    containerBtn: {
      margin: "12px 16px",
    },
    containerUpperbtnTitle: {
      display: "grid",
      width: "100%",
      height: "100%",
      gridTemplateRows: "1fr",
    },
  })
);

interface IProps {
  buyNowCard: IResultPrizeActiveBuyNow;
  onClick: any;
  index: number;
  postBuyPrize: (prizeId: string) => void;
  requestManagePrize: (prizeId: string, type: number) => void;
  pageLink?: any;
  cultureTag: string;
  openGrid?: boolean;
  permission: string;
  setOpenCloseAuction: (open: boolean, prizeId: string) => void;
}

function BuyNowPrizeCard(props: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    buyNowCard,
    onClick,
    index,
    postBuyPrize,
    requestManagePrize,
    setOpenCloseAuction,
    cultureTag,
    openGrid,
    permission
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [statusQuantity, setCalcQtde] = useState(0);
  const {currentQuantity, prizeId} = buyNowCard; //statusQuantity
  const formatPoints = new Intl.NumberFormat(cultureTag).format(
    Number(buyNowCard?.value)
  );

  useEffect(() => {
    const { pageLink, buyNowCard, onClick, index } = props;
    if (pageLink?.prizeId === buyNowCard?.prizeId) {
      onClick(buyNowCard, index);
    }
    setCalcQtde(buyNowCard?.quantity-buyNowCard?.currentQuantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyNowCard]);

  const handleSubmit = () => {
    postBuyPrize(prizeId);
  };

  //format to resource
  const motiveDeniedBuyResource = buyNowCard?.motiveDeniedBuy?.replace(
    ".",
    "::"
  );

  function handleManagePrize(prizeId: string, type: number) {
    requestManagePrize(prizeId, type)
  }

  return (
    <Box onClick={() => onClick(buyNowCard, index)} className={classes.root}>
      <Card
        style={{ gridTemplateRows: openGrid ? "1fr 1.2fr" : "" }}
        className={classes.card}
      >
        <Box className={classes.body}>
          <CardMedia
            className={classes.media}
            style={{ backgroundImage: `url(${buyNowCard?.image})` }}
          >
            <Grid container className={classes.containerUpperbtnTitle}>
              <Grid item className="upper-btn">
                <Grid container style={{paddingTop: '16px', paddingRight: '16px', paddingLeft: '16px'}}>
                  <Grid
                    item
                    container
                    xs={4}
                    sm={4}
                    md={4}
                    xl={4}
                    lg={4}
                  >
                    {currentQuantity === 0 && (
                      <Paper elevation={0} className={classes.soldOutBought}>
                        <Box color={theme.palette.secondary.main}>
                          <Resource tag={"PagePrizes::Auction_SoldOut"} />
                        </Box>
                      </Paper>
                    )}
                    {currentQuantity === 1 && (
                      <Paper elevation={0} className={classes.soldOutBought}>
                        <Box color={theme.palette.secondary.main}>
                          <Resource tag={"PagePrizes::Auction_LastUnit"} />
                        </Box>
                      </Paper>
                    )}
                    {currentQuantity > 0 && statusQuantity > 0 ? (
                      <Paper elevation={0} className={classes.soldOutBought}>
                        <Box color={theme.palette.primary.main}>
                          {statusQuantity > 1 ? (
                            <Resource
                              tag={"PagePrizes::Auction_StatusBought"}
                              args={[statusQuantity]}
                            />
                          ) : (
                            <Resource
                              tag={"PagePrizes::Auction_StatusBought1"}
                              args={[statusQuantity]}
                            />
                          )}
                        </Box>
                      </Paper>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    justify="flex-end"
                    alignItems="center"
                    style={{gap: '24px'}}
                    xs={8}
                    sm={8}
                    md={8}
                    xl={8}
                    lg={8}
                  >
                    <Paper
                      elevation={0}
                      onClick={() =>
                        buyNowCard?.isAllowedToBuy === true
                          ? setIsOpen(true)
                          : () => {}
                      }
                      className={
                        buyNowCard?.isAllowedToBuy === true
                          ? classes.buyNowBtn
                          : classes.buyNowBtnDisabled
                      }
                    >
                      <Resource tag={"PagePrizes::Auction_BuyNow"} />
                    </Paper>
                    {newApp ?
                      <Grid container justify="flex-end" item xs={1}>
                        {permission === 'Admin' || permission === 'ChallengeManager' ? (
                          <>
                            <MoreOptionsButton translate="-76px, 42px" iconColor={theme.palette.primary.contrastText}>
                              <MenuItem
                                onClick={(e) => {e.stopPropagation();handleManagePrize(buyNowCard?.prizeId, 1)}}
                              >
                                <Resource tag="PagePrizes::EditPrize"/>
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {e.stopPropagation();handleManagePrize(buyNowCard?.prizeId, 2)}}
                              >
                                <Resource tag="PagePrizes::Duplicate"/>
                              </MenuItem>
                              {buyNowCard.currentQuantity === buyNowCard.quantity ?
                              <MenuItem
                                onClick={(e) => {e.stopPropagation();handleManagePrize(buyNowCard?.prizeId, 3)}}
                              >
                                <Resource tag="PagePrizes::Delete"/>
                              </MenuItem>
                              :
                              <MenuItem
                                onClick={(e) => {e.stopPropagation();setOpenCloseAuction(true, buyNowCard?.prizeId)}}
                              >
                                <Resource tag="PagePrizes::CloseSale"/>
                              </MenuItem>
                              }
                            </MoreOptionsButton>
                          </>
                        ) : ''}
                        
                      </Grid>
                      :''
                    }
                  </Grid>
                </Grid>
                <PopUpConfirmation
                  closeButton={() => setIsOpen(false)}
                  sendButton={handleSubmit}
                  message={<Resource tag={'PagePrizes::ConfirmAction'}/>}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              </Grid>
              <Grid item className="buynow-title">
                <Box className={classes.titleAndTime}>
                  <Typography variant="h4" className={classes.cardTitle}>
                    {buyNowCard?.title}
                  </Typography>
                  <Typography className={classes.cardSubTitle}>
                    <Resource
                      tag={buyNowCard?.timeRemainingResource.replace(".", "::")}
                      args={[buyNowCard?.timeRemaining]}
                    />
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardMedia>
          <Paper className={classes.currentBid}>
            <Typography className={classes.bidText}>
              {formatPoints && (
                <>
                  <Box component="span" style={{ textTransform: "uppercase" }}>
                    <Resource
                      tag={"PagePrizes::Auction_BuyValue"}
                      args={[formatPoints]}
                    />
                  </Box>
                  <Box
                    component="span"
                    marginLeft={"3px"}
                    fontWeight={"fontWeightBold"}
                  >
                    <Resource tag={"Common::points"} />
                  </Box>
                </>
              )}
            </Typography>
          </Paper>
        </Box>
        <Hidden mdUp>
          <Box
            p={2}
            display={openGrid ? "flex" : "none"}
            flexDirection="column"
            justifyContent="space-between"
            className="buyNow-description"
          >
            <Box 
              className="buyNow-description" 
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(buyNowCard?.description, securityTextEditor),
              }}/>
            <Box className="footer-info">
              <Box display="flex" alignItems="center" className="footer-button">
                <Box mr={1} fontWeight="bold" className="unit-left">
                  <Resource
                    tag={"PagePrizes::Auction_UnitsLeft1"}
                    args={[buyNowCard?.currentQuantity, buyNowCard?.quantity]}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsOpen(true)}
                  disabled={buyNowCard?.isAllowedToBuy === false}
                >
                  <Box fontWeight="bold">
                    <Resource tag={"PagePrizes::Auction_BuyNow"} />
                  </Box>
                </Button>
                <Box
                  ml={1}
                  display="flex"
                  alignItems="center"
                  className="prize-buy-now-message"
                >
                  {buyNowCard?.motiveDeniedBuy !== "" ? (
                    <Resource tag={motiveDeniedBuyResource!} />
                  ) : (
                    ""
                  )}
                </Box>
                <PopUpConfirmation
                  closeButton={() => setIsOpen(false)}
                  sendButton={handleSubmit}
                  message={<Resource tag="Common::ConfirmAction" />}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              </Box>
            </Box>
          </Box>
        </Hidden>
      </Card>
    </Box>
  );
}

export default BuyNowPrizeCard;
