import React from "react";
import { IKeyTasks } from "../../redux/reducers/keyTasks/keyTasksReducer";
import Resource from "../../components/Resources/Resource";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Theme,
  createStyles,
  makeStyles,
  Box,
  Typography,
} from "@material-ui/core";
import KeyTaskListItemAll from "components/KeyTaskListItem/KeyTaskListItemAll";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      margin: "20px 0px",
    },
    tableTitles: {
      color: " #333",
      "font-weight": 500,
      "text-transform": "uppercase",
    },
    tableRow: {
      borderBottom: "solid 1px #333",
    },
  })
);
interface IProps {
  allList: IKeyTasks;
  filterKeyTasksEvaluations: any;
  postIdeasStars?: (options: object) => Promise<any>;
  // viewMoreData: (pageSize:number, type: typesKeytask) => void
}

function KeyTaskAllList(props: IProps) {
  const { allList, filterKeyTasksEvaluations, postIdeasStars } = props;
  const classes = useStyles();
  let delayed = 0;
  let thisWeek = 0;
  let noDeadLine = 0;
  let futureTask = 0;
  allList?.result?.map((list) => {
    if (list?.typeDate === 1) {
      return delayed++;
    } else if (list?.typeDate === 2) {
      return thisWeek++;
    } else if (list?.typeDate === 3) {
      return noDeadLine++;
    } else if (list?.typeDate === 4) {
      return futureTask++;
    } else {
      return "";
    }
  });
  return (
    <Box mt={3} mb={3} width="100%">
      {allList?.meta?.total > 0 ? (
        <>
          {delayed > 0 && (
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableTitles}>
                    <Resource
                      tag="PageKeyTasks::TaksOrder_Delayed"
                      args={[delayed]}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allList?.result?.map((list, index) => {
                  if (list.typeDate === 1) {
                    return (
                      <KeyTaskListItemAll
                        filterKeyTasksEvaluations={filterKeyTasksEvaluations}
                        type=""
                        key={index}
                        allList={list}
                        postIdeasStars={postIdeasStars}
                      />
                    );
                  } else {
                    return "";
                  }
                })}
              </TableBody>
            </Table>
          )}
          {thisWeek > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Resource
                      tag="PageKeyTasks::Evaluations_TaksOrderWeek"
                      args={[thisWeek]}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allList?.result?.map((list, index) => {
                  if (list.typeDate === 2) {
                    return (
                      <KeyTaskListItemAll
                        filterKeyTasksEvaluations={filterKeyTasksEvaluations}
                        key={index}
                        allList={list}
                        postIdeasStars={postIdeasStars}
                      />
                    );
                  } else {
                    return "";
                  }
                })}
              </TableBody>
            </Table>
          )}

          {noDeadLine > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Resource
                      tag="PageKeyTasks::TaksOrder_NoDeadline"
                      args={[noDeadLine]}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allList?.result?.map((list, index) => {
                  if (list.typeDate === 3) {
                    return (
                      <KeyTaskListItemAll
                        filterKeyTasksEvaluations={filterKeyTasksEvaluations}
                        key={index}
                        allList={list}
                        postIdeasStars={postIdeasStars}
                      />
                    );
                  } else {
                    return "";
                  }
                })}
              </TableBody>
            </Table>
          )}
          {futureTask > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Resource
                      tag="PageKeyTasks::Evaluations_TaksOrderFutureTasks"
                      args={[futureTask]}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allList?.result?.map((list, index) => {
                  if (list.typeDate === 4) {
                    return (
                      <KeyTaskListItemAll
                        filterKeyTasksEvaluations={filterKeyTasksEvaluations}
                        key={index}
                        allList={list}
                        postIdeasStars={postIdeasStars}
                      />
                    );
                  } else {
                    return "";
                  }
                })}
              </TableBody>
            </Table>
          )}
        </>
      ) : (
        <Box textAlign="center" width="100%" p={5}>
          <Typography color="textSecondary" variant="caption">
            <Resource tag="PageKeyTasks::Error_NoKeyTasksAvailable" />
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default KeyTaskAllList;
