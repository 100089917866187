import React from "react";
import {
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Box, Button } from "@material-ui/core";
import UserListItem from "../../../../common/UI/List/User/UserListItem";
import Resource from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IPermissions } from "redux/types/manageUsers";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";
import DialogDemotion from "../Dialogs/DialogDemotion";

interface IProps {
  culture: ICultureState;
  permissionStatus: string;
  permissions: IPermissions;
  workspaceId: string;
  getUserPermissions: any;
  tab: number;
  putPermission: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& label": {
        width: "100%",
        marginBottom: "15px",
        marginTop: "15px",
        marginRight: "8px",
        "& span:last-child": {
          width: "100%",
        },
      },
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  })
);
export default function ManageAdminsList(props: IProps) {
  const {
    culture,
    permissions,
    workspaceId,
    getUserPermissions,
    tab,
    putPermission,
  } = props;
  const classes = useStyles();
  const [state, setState] = React.useState<any>([]);
  const [openPromotion, setPromotionOpen] = React.useState(false);
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <FormGroup className={classes.root}>
      {!permissions || permissions?.result?.length === 0 ? (
        ""
      ) : (
        <Box display="flex" alignItems="center" width="100%" mb={1}>
          <FormControlLabel
            style={{
              marginBottom: "0px",
              marginTop: "0px",
              width: "auto",
            }}
            control={
              <Checkbox
                checked={state.length === permissions?.result?.length}
                onChange={() =>
                  state?.length !== permissions?.result?.length
                    ? setState(permissions?.result)
                    : setState([])
                }
                name="checkedAll"
                color="primary"
              />
            }
            label=""
          />
          <Grid
            container
            direction={matchSm ? "column-reverse" : "row"}
            spacing={2}
          >
            <Grid
              justify="flex-start"
              container
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
            >
              <Button
                onClick={(e: any) => {
                  e.stopPropagation();
                  setPromotionOpen(true);
                }}
                onFocus={(e: any) => e.stopPropagation()}
                // aria-label="Buttons"
                variant="text"
                color="primary"
                disabled={state.length === 0}
                style={{
                  textTransform: "uppercase",
                }}
              >
                <Resource tag="PageManageUsers::Demote" />
              </Button>
              <DialogDemotion
                action={() => {
                  putPermission({
                    workspaceId,
                    permission: 2,
                    users: state.map((x: any) => x.userId),
                    pageNumber: 1,
                    pageSize: 6,
                    type: 1,
                  });
                }}
                type={2}
                culture={culture}
                open={openPromotion}
                setOpen={setPromotionOpen}
              />
            </Grid>
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              wrap="nowrap"
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
            >
              <Typography
                style={{ paddingRight: "15px" }}
                variant="body2"
                color="textSecondary"
              >
                {permissions?.meta?.total}{" "}
                <Resource tag="PageSettings::users" />
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        display={matchSm ? "block" : "flex"}
        justifyContent="flex-start"
        alignItems="flex-start"
        gridGap={30}
      >
        <Box width={matchSm ? "100%" : "50%"}>
          <DynamicInfiniteScroll 
            dataList={permissions}
            getAction={(pageNumber, pageSize) =>
              getUserPermissions({
                pageNumber,
                pageSize,
                permission: Number(tab),
                workspaceId,
                isInfinite: true,
              })
            }
            pageSize={10}
            isAutomatic
          >
            <Box width={matchSm ? "100%" : "100%"}>
              {permissions &&
                permissions?.result?.map((data, index) => {
                  return (
                    <FormControlLabel
                      key={data?.userId}
                      control={
                        <Checkbox
                          checked={state
                            .map((x: any) => x.userId)
                            .includes(data?.userId)}
                          onChange={() =>
                            state.map((x: any) => x.userId).includes(data?.userId)
                              ? setState(
                                  state.filter(
                                    (y: any) => y.userId !== data?.userId
                                  )
                                )
                              : setState([...state, data])
                          }
                          name="checked-permissions-user"
                          color="primary"
                        />
                      }
                      label={
                        <UserListItem
                          userId={data?.userId}
                          culture={culture}
                          permissionTab
                          checked={state
                            .map((x: any) => x.userId)
                            .includes(data?.userId)}
                          alignItems
                          principalText={data?.fullName}
                          subtitle={data?.email}
                          photo={data?.photo}
                        />
                      }
                    />
                  );
                })}
            </Box>
          </DynamicInfiniteScroll>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box width={matchSm ? "100%" : "50%"}>
          <Typography>
            <Resource tag="PageManageUsers::PermissionInfo_Admin" />
          </Typography>
        </Box>
      </Box>
    </FormGroup>
  );
}
