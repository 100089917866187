import { Reducer } from 'redux';
import {
  InsightsActions,
  InsightsActionTypes,
} from '../../actions/insight/InsightsActions';

// Define the Character type
export interface IInsights {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IInsightResult[]
}

export interface IInsightResult {
  commentsCount: number,
  comments: [
    {
      repliesCount: number,
      commentId: string,
      userId: string,
      fullName: string,
      photo: string,
      timeAgo: string,
      timeStampResource: string,
      message: string,
      isLikedByMe: true,
      likesCount: 0
    }
  ],
  challengeId: string,
  challengeTitle: string,
  insightId: string,
  creationDate: string,
  title: string,
  description: string,
  tags: [
    string
  ]
}
export interface IInsightComments {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: [
    {
      replies: [
        {
          commentId: string,
          userId: string,
          fullName: string,
          photo: string,
          timeAgo: string,
          timeStampResource: string,
          message: string,
          isLikedByMe: boolean,
          likesCount: number
        }
      ],
      commentId: string,
      userId: string,
      fullName: string,
      photo: string,
      timeAgo: string,
      timeStampResource: string,
      message: string,
      isLikedByMe: boolean,
      likesCount: number
    }
  ]
  messagesCount: number,
  totalMessagePages: number
}
export interface Insight {
  tenantId: number,
  applicationId: number,
  workspaceId: string,
  insightId: string,
  challengeId: string,
  creationDate: Date,
  title: string,
  description: string,
  tags: [string],
  comments: [string],
  image: string,
}

// Define the Character State
export interface IInsightsState {
  readonly insights: IInsights;
  readonly insightStatus: string;
  readonly insightsComments: IInsightComments;
  readonly commentInsightStatus: string
  readonly specificInsight: Insight
}

// Define the initial state
const initialCharacterState: IInsightsState = {
  insights: {} as IInsights,
  insightsComments: {
    totalMessagePages: 0,
    messagesCount: 0,
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: [
      {
        replies: [
          {
            commentId: '',
            userId: '',
            fullName: '',
            photo: '',
            timeAgo: '',
            timeStampResource: '',
            message: '',
            isLikedByMe: false,
            likesCount: 0
          }
        ],
        commentId: '',
        userId: '',
        fullName: '',
        photo: '',
        timeAgo: '',
        timeStampResource: '',
        message: '',
        isLikedByMe: false,
        likesCount: 0
      }
    ]
  },
  insightStatus: '',
  commentInsightStatus: '',
  specificInsight: {} as Insight
};

export const insightsReducer: Reducer<IInsightsState, InsightsActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case InsightsActionTypes.GET_MORE_INSIGHTS_SUCCESS: {
      return {
        ...state,
        insights: {
          meta: { ...action?.insights?.meta },
          result: [
            ...state.insights.result,
            ...action.insights.result,
          ]
        },
        insightStatus: action.insightStatus,
      };
    }
    case InsightsActionTypes.GET_INSIGHTS_SUCCESS: {
      return {
        ...state,
        insights: {
          ...action.insights,
        },
        insightStatus: action.insightStatus,
      };
    }
    case InsightsActionTypes.GET_INSIGHTS_LOAD: {
      return {
        ...state,
        insightStatus: action.insightStatus,
      };
    }
    case InsightsActionTypes.GET_INSIGHTS_FAIL: {
      return {
        ...state,
        insightStatus: action.insightStatus,
      };
    }
    case InsightsActionTypes.INSIGHTS_COMMENTS_LOAD: {
      return {
        ...state,
        commentInsightStatus: action.commentInsightStatus,
      };
    }
    case InsightsActionTypes.INSIGHTS_COMMENTS_SUCCESS: {
      return {
        ...state,
        insightsComments: action.insightsComments,
        commentInsightStatus: action.commentInsightStatus,
      };
    }
    case InsightsActionTypes.INSIGHTS_COMMENTS_FAIL: {
      return {
        ...state,
        commentInsightStatus: action.commentInsightStatus,
      };
    }
    case InsightsActionTypes.INSIGHTS_POSTCOMMENTS_LOAD: {
      return {
        ...state,
        commentInsightStatus: action.commentInsightStatus,
      };
    }
    case InsightsActionTypes.INSIGHTS_POSTCOMMENTS_SUCCESS: {
      return {
        ...state,
        insightsComments: action.insightsComments,
        commentInsightStatus: action.commentInsightStatus,
      };
    }
    case InsightsActionTypes.INSIGHTS_POSTCOMMENTS_FAIL: {
      return {
        ...state,
        commentInsightStatus: action.commentInsightStatus,
      };
    }
    case InsightsActionTypes.POST_CREATE_INSIGHTS: {
      return {
        ...state

      }
    }
    case InsightsActionTypes.GET_SPECIFIC_INSIGHTS: {
      return {
        ...state,
        specificInsight: action.specificInsight
      }
    }
    default:
      return state;
  }
};