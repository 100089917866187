import { api_v3 } from "config/api"
import { ActionCreator, Dispatch } from "redux"
import { ThunkAction } from "redux-thunk"
import { CreateChallengesState } from "redux/reducers/createChallenge/createChallengeReducer"
import { ICultureState } from "redux/reducers/cultureReducer"
import { PageStateDetail, PageStateIdeaForm, PageStateParticipants, PageStateWorkflow  } from "redux/types/createChallenge"
import { treatError } from "utils/treatError"

const basePath = 'challenges'

export enum CreateChallengesActionTypes { 
  UPDATE_DETAILS = "UPDATE_DETAILS",
  UPDATE_PARTICIPANTS = "UPDATE_PARTICIPANTS",
  UPDATE_IDEA_FORM = "UPDATE_IDEA_FORM",
  UPDATE_WORKFLOW = "UPDATE_WORKFLOW",

  GET_CREATE_CHALLENGE_DETAIL = 'GET_CREATE_CHALLENGE_DETAIL',

  RESET_CREATE_CHALLENGE = 'RESET_CREATE_CHALLENGE',

  POST_CHALLENGE_DETAIL_SUCCEEDED = 'POST_CHALLENGE_DETAIL_SUCCEEDED',
  POST_CHALLENGE_DETAIL_FAILED = 'POST_CHALLENGE_DETAIL_FAILED',

  PUT_CHALLENGE_DETAIL_FAILED = 'PUT_CHALLENGE_DETAIL_FAILED',
  PUT_CHALLENGE_DETAIL_SUCCEEDED = 'PUT_CHALLENGE_DETAIL_SUCCEEDED',

  PUT_CHALLENGE_DETAIL_PARTICIPANTS_FAILED = 'PUT_CHALLENGE_DETAIL_PARTICIPANTS_FAILED',
  PUT_CHALLENGE_DETAIL_PARTICIPANTS_SUCCEESS = 'PUT_CHALLENGE_DETAIL_PARTICIPANTS_SUCCEEDED',

  PUT_CHALLENGE_DETAIL_IDEAFORM_FAILED = 'PUT_CHALLENGE_DETAIL_IDEAFORM_FAILED',
  PUT_CHALLENGE_DETAIL_IDEAFORM_SUCCEEDED = 'PUT_CHALLENGE_DETAIL_IDEAFORM_SUCCEEDED',

  PUT_CHALLENGE_DETAIL_ACTIVATE_FAILED = 'PUT_CHALLENGE_DETAIL_ACTIVATE_FAILED',
  PUT_CHALLENGE_DETAIL_ACTIVATE_SUCCEEDED = 'PUT_CHALLENGE_DETAIL_ACTIVATE_SUCCEEDED',

  PUT_CHALLENGE_DETAIL_ENDSUBMITION_FAILED = 'PUT_CHALLENGE_DETAIL_ENDSUBMITION_FAILED',
  PUT_CHALLENGE_DETAIL_ENDSUBMITION_SUCCEEDED = 'PUT_CHALLENGE_DETAIL_ENDSUBMITION_SUCCEEDED',

}

export enum CreateChallengeActionStatus {

  POST_CHALLENGE_DETAIL_SUCCEEDED = 'CHALLENGE_DETAIL_SUCCEEDED',
  POST_CHALLENGE_DETAIL_FAILED = 'CHALLENGE_DETAIL_FAILED',

  PUT_CHALLENGE_DETAIL_SUCCEEDED = 'CHALLENGE_DETAIL_SUCCEEDED',
  PUT_CHALLENGE_DETAIL_FAILED = 'CHALLENGE_DETAIL_FAILED',

  GET_CHALLENGE_DETAIL_LOADING = 'GET_CHALLENGE_DETAIL_LOADING',
  GET_CHALLENGE_DETAIL_SUCCEEDED = 'GET_CHALLENGE_DETAIL_SUCCEEDED',
  GET_CHALLENGE_DETAIL_FAILED = 'GET_CHALLENGE_DETAIL_FAILED',

  PUT_CHALLENGE_DETAIL_PARTICIPANTS_SUCCEEDED = 'PUT_CHALLENGE_DETAIL_PARTICIPANTS_SUCCEEDED',
  PUT_CHALLENGE_DETAIL_PARTICIPANTS_FAILED = 'PUT_CHALLENGE_DETAIL_PARTICIPANTS_FAILED',

  PUT_CHALLENGE_DETAIL_IDEAFORM_SUCCEEDED = 'PUT_CHALLENGE_DETAIL_IDEAFORM_SUCCEEDED',
  PUT_CHALLENGE_DETAIL_IDEAFORM_FAILED = 'PUT_CHALLENGE_DETAIL_IDEAFORM_FAILED',

  PUT_CHALLENGE_DETAIL_ACTIVATE_SUCCEEDED = 'PUT_CHALLENGE_DETAIL_ACTIVATE_SUCCEEDED',
  PUT_CHALLENGE_DETAIL_ACTIVATE_FAILED = 'PUT_CHALLENGE_DETAIL_ACTIVATE_FAILED',

  PUT_CHALLENGE_DETAIL_ENDSUBMITION_FAILED = 'PUT_CHALLENGE_DETAIL_ENDSUBMITION_FAILED',
  PUT_CHALLENGE_DETAIL_ENDSUBMITION_SUCCEEDED = 'PUT_CHALLENGE_DETAIL_ENDSUBMITION_SUCCEEDED'

}

interface UpdateDetailsAction {
  type: CreateChallengesActionTypes.UPDATE_DETAILS;
  payload: PageStateDetail
}

interface UpdateParticipantsAction {
  type: CreateChallengesActionTypes.UPDATE_PARTICIPANTS;
  payload: PageStateParticipants
}

interface UpdateIdeaFormAction {
  type: CreateChallengesActionTypes.UPDATE_IDEA_FORM;
  payload: PageStateIdeaForm
}

interface UpdateWorkflowAction {
  type: CreateChallengesActionTypes.UPDATE_WORKFLOW;
  payload: PageStateWorkflow
}


export interface GetChallengeDetailAction {
  type: CreateChallengesActionTypes.GET_CREATE_CHALLENGE_DETAIL,
  payload?: any;
  status: string
}

export interface PostChallengeDetailFailed {
  type: CreateChallengesActionTypes.POST_CHALLENGE_DETAIL_FAILED;
  status: string;
}

export interface PostChallengeDetailSucceeded {
  type: CreateChallengesActionTypes.POST_CHALLENGE_DETAIL_SUCCEEDED;
  status: string;
}

export interface PutChallengeDetailParticipantsFailed {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_PARTICIPANTS_FAILED;
  status: string;
}

export interface PostChallengeDetailSucceeded {
  type: CreateChallengesActionTypes.POST_CHALLENGE_DETAIL_SUCCEEDED;
  status: string;
}

export interface PutChallengeDetailFailed {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_FAILED;
  status: string;
}

export interface PutChallengeDetailSucceeded {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_SUCCEEDED;
  status: string;
}

export interface PutChallengeDetailParticipantsFailed {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_PARTICIPANTS_FAILED;
  status: string;
}

export interface PutChallengeDetailParticipantsSucceeded {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_PARTICIPANTS_SUCCEESS;
  status: string;
}

export interface PutChallengeDetailIdeaformFailed{
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_IDEAFORM_FAILED;
  status: string;
}

export interface PutChallengeDetailIdeaformSucceeded {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_IDEAFORM_SUCCEEDED;
  status: string;
}

export interface PutChallengeDetailActivateFailed {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ACTIVATE_FAILED;
  status: string;
}

export interface PutChallengeDetailActivateSucceeded {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ACTIVATE_SUCCEEDED;
  status: string;
}

export interface PutChallengeDetailEndsubmitionFailed {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ENDSUBMITION_FAILED;
  status: string;
}

export interface PutChallengeDetailEndsubmitionSucceeded {
  type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ENDSUBMITION_SUCCEEDED;
  status: string;
}

export interface ResetCreateChallenge {
  type: CreateChallengesActionTypes.RESET_CREATE_CHALLENGE
}

export type CreateChallengesActions = 
  | GetChallengeDetailAction
  | UpdateDetailsAction
  | UpdateParticipantsAction
  | UpdateIdeaFormAction
  | UpdateWorkflowAction
  | PostChallengeDetailFailed
  | PostChallengeDetailSucceeded
  | PutChallengeDetailFailed
  | PutChallengeDetailSucceeded
  | PutChallengeDetailParticipantsFailed
  | PutChallengeDetailParticipantsSucceeded
  | PutChallengeDetailActivateFailed
  | PutChallengeDetailActivateSucceeded
  | PutChallengeDetailEndsubmitionFailed
  | PutChallengeDetailEndsubmitionSucceeded
  | PutChallengeDetailIdeaformFailed
  | PutChallengeDetailIdeaformSucceeded
  | ResetCreateChallenge

  export interface PostChallengeDetailParam {
    workspaceId: string,
    challengeType: number,
    startDate: Date | string,
    endSubmitionDate: Date | string,
    isIdeasVisible: boolean,
    image: string,
    tags: string[],
    cultures: any
    culture: ICultureState
  }
  
  export interface PutChallengeDetailParam extends PostChallengeDetailParam {
    challengeId: string;
  }
  
  export interface PutChallengesDetailParticipantsParam {
    challengeId: string;
    workspaceId: string;
    managersId: string[];
    groupsId: string[];
    culture: ICultureState;
  }
  
  export interface PutChallengeDetailIdeaformParam {
    challengeId: string,
    workspaceId: string,
    ideaCustomField: {
      ideaCustomFieldId: string,
      cultures: {
          cultureTag: string,
          title: string,
          description: string
        }[],
      isRequired: boolean,
      order: number,
      options: {
          ideaCustomFieldOptionId: string,
          cultures: {
              cultureTag: string,
              title: string,
              description: string
            }[]
        }[],
      type: number
    }[]
    culture: ICultureState;
  }
  
  export interface PutChallengeDetailActivateParam {
    challengeId: string,
    notificationDate: Date | string,
    workspaceId: string
    culture: ICultureState;
  }
  
  export interface PutChallengeDetailEndsubmitionParam {
    challengeId: string,
    workspaceId: string,
    culture: ICultureState;
  }

export function updateDetails(challengeDetails: PageStateDetail): UpdateDetailsAction {
  return {
    type: CreateChallengesActionTypes.UPDATE_DETAILS,
    payload: challengeDetails
  }
}

export function updateParticipants(challengeDetails: PageStateParticipants): UpdateParticipantsAction {
  return {
    type: CreateChallengesActionTypes.UPDATE_PARTICIPANTS,
    payload: challengeDetails
  }
}

export function updateIdeaForm(challengeDetails: PageStateIdeaForm): UpdateIdeaFormAction {
  return {
    type: CreateChallengesActionTypes.UPDATE_IDEA_FORM,
    payload: challengeDetails
  }
}

export function updateWorkflow(challengeDetails: PageStateWorkflow): UpdateWorkflowAction {
  return {
    type: CreateChallengesActionTypes.UPDATE_WORKFLOW,
    payload: challengeDetails
  }
}

export function resetCreateChallenge(): ResetCreateChallenge {
  return {
    type: CreateChallengesActionTypes.RESET_CREATE_CHALLENGE,
  }
}

export const getCreateChallengeDetail: ActionCreator<
ThunkAction<Promise<any>, CreateChallengesState, null, GetChallengeDetailAction>
> = (options, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: CreateChallengeActionStatus.GET_CHALLENGE_DETAIL_LOADING,
        type: CreateChallengesActionTypes.GET_CREATE_CHALLENGE_DETAIL,
      })

      let params: any[] = [];
      let params_qs = "";

      if( options.hasOwnProperty("workspaceId") )
      {
        params.push(`workspaceId=${options.workspaceId }`);
      }

      if(params.length) {
        params_qs = `?${params.join("&")}`;
      }
       
      const response = await api_v3.get(`${basePath}/${options.challengeId}/details${params_qs}`);

      dispatch({
        status: CreateChallengeActionStatus.GET_CHALLENGE_DETAIL_SUCCEEDED,
        type: CreateChallengesActionTypes.GET_CREATE_CHALLENGE_DETAIL,
        payload: response.data
      })
    } catch (err) {
      dispatch({
        status: CreateChallengeActionStatus.GET_CHALLENGE_DETAIL_FAILED,
        type: CreateChallengesActionTypes.GET_CREATE_CHALLENGE_DETAIL,
      })

      treatError(culture,"Create Challenge",err)
    }
  }
}

export const postChallengeDetail: ActionCreator<
  ThunkAction<Promise<any>, CreateChallengesState, null, PostChallengeDetailSucceeded>
> = (options:PostChallengeDetailParam, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      
      const response = await api_v3.post(`${basePath}/details`, options);
      dispatch({
        status: CreateChallengeActionStatus.POST_CHALLENGE_DETAIL_SUCCEEDED,
        type: CreateChallengesActionTypes.POST_CHALLENGE_DETAIL_SUCCEEDED,
      });
      return response.data
    } catch (err) {
      dispatch({
        status: CreateChallengeActionStatus.POST_CHALLENGE_DETAIL_FAILED,
        type: CreateChallengesActionTypes.POST_CHALLENGE_DETAIL_FAILED,
      });
      treatError(culture, 'PageChallenges::Header', err);
    }
  };
};

export const putChallengeDetail: ActionCreator<
  ThunkAction<Promise<any>, CreateChallengesState, null, PutChallengeDetailSucceeded>
> = (options:PutChallengeDetailParam, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.put(`${basePath}/${options.challengeId}/details`, options);
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_SUCCEEDED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_SUCCEEDED,
      });
    } catch (err) {
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_FAILED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_FAILED,
      });
      treatError(culture, 'PageChallenges::Header', err);
    }
  };
};


export const putChallengeDetailParticipants: ActionCreator<
  ThunkAction<Promise<any>, CreateChallengesState, null, PutChallengeDetailParticipantsSucceeded>
> = (options:PutChallengesDetailParticipantsParam, culture) => {
  return async (dispatch: Dispatch) => {
    try {

      await api_v3.put(`${basePath}/${options.challengeId}/participants`, options);
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_PARTICIPANTS_SUCCEEDED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_PARTICIPANTS_SUCCEESS,
      });
    } catch (err) {
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_PARTICIPANTS_FAILED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_PARTICIPANTS_FAILED,
      });
      treatError(culture, 'PageChallenges::Header', err);
    }
  };
};

export const putChallengeDetailIdeaform: ActionCreator<
  ThunkAction<Promise<any>, CreateChallengesState, null, PutChallengeDetailIdeaformSucceeded>
> = (options:PutChallengeDetailIdeaformParam, culture) => {
  return async (dispatch: Dispatch) => {
    try {
      await api_v3.put(`${basePath}/${options.challengeId}/ideaform`, options);
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_IDEAFORM_SUCCEEDED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_IDEAFORM_SUCCEEDED,
      });
    } catch (err) {
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_IDEAFORM_FAILED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_IDEAFORM_FAILED,
      });
      treatError(culture, 'PageChallenges::Header', err);
    }
  };
};

export const putChallengeDetailActivate: ActionCreator<
  ThunkAction<Promise<any>, CreateChallengesState, null, PutChallengeDetailActivateSucceeded>
> = (options:PutChallengeDetailActivateParam, culture) => {
  return async (dispatch: Dispatch) => {
    try {

      await api_v3.put(`${basePath}/${options.challengeId}/activate`, options);
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_ACTIVATE_SUCCEEDED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ACTIVATE_SUCCEEDED,
      });
    } catch (err) {
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_ACTIVATE_FAILED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ACTIVATE_FAILED,
      });
      treatError(culture, 'PageChallenges::Header', err);
    }
  };
};

export const putChallengeDetailEndsubmition: ActionCreator<
  ThunkAction<Promise<any>, CreateChallengesState, null, PutChallengeDetailEndsubmitionSucceeded>
> = (options:PutChallengeDetailEndsubmitionParam, culture) => {
  return async (dispatch: Dispatch) => {
    try {

      await api_v3.put(`${basePath}/${options.challengeId}/endsubmition`, options);
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_ENDSUBMITION_SUCCEEDED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ENDSUBMITION_SUCCEEDED,
      });
    } catch (err) {
      dispatch({
        status: CreateChallengeActionStatus.PUT_CHALLENGE_DETAIL_ENDSUBMITION_FAILED,
        type: CreateChallengesActionTypes.PUT_CHALLENGE_DETAIL_ENDSUBMITION_FAILED,
      });
      treatError(culture, 'PageChallenges::Header', err);
    }
  };
};