import {
  InputAdornment,
  Input,
  IconButton,
  FormControl,
  InputLabel,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Box,
  Typography,
  Button,
  useTheme,
} from "@material-ui/core";
import React from "react";
import Resource from "../Resources/Resource";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      width: "100%",
    },
    formControl: {
      width: "100%",
      maxWidth: 424,
    },
    btnSearch: {
      padding: 0,
    },
    chip: {
      height: "21px",
      borderRadius: "3px",
      marginRight: 3,
      border: "0.8px solid",
      borderColor: theme.palette.primary.light,
      backgroundColor: "transparent",
      "&:hover": {
        background: theme.palette.primary.light,
      },
    },
    chipActive: {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
    label: {
      color: theme.palette.primary.main,
      fontSize: "0.7rem",
      fontWeight: "normal",
      "&::before": {
        display: "block",
        content: "attr(title)",
        fontWeight: "bold",
        height: 0,
        overflow: "hidden",
        visibility: "hidden",
      },
    },
    labelActive: {
      fontWeight: 500,
    },
  })
);

interface Iprops {
  topics: [];
  handlerInput: any;
  handlerSearch: any;
  handlerTopicSearch: any;
  value: string;
  activeTopic: string;
  searchInput?: any;
  handlerKeyDown?: any;
}

function SearchNews(props: Iprops) {
  const theme = useTheme();
  const classes = useStyles();
  const { topics, activeTopic } = props;

  return (
    <>
      <Grid item xs={12}>
        <Box className={classes.root}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="search">
              <Resource tag="PageNews::SearchPlaceHolder" />
            </InputLabel>
            <Input
              id="search"
              type="search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    className={classes.btnSearch}
                    onClick={props.handlerSearch}
                  >
                    <SearchIcon htmlColor={theme.palette.text.primary} />
                  </IconButton>
                </InputAdornment>
              }
              value={props.value}
              onChange={props.handlerInput}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.root}>
          <Button
            className={
              activeTopic === ""
                ? classes.chip + " " + classes.chipActive
                : classes.chip
            }
            color="primary"
            variant={activeTopic === "" ? "contained" : "outlined"}
            onClick={() => props.handlerTopicSearch("")}
          >
            <Typography
              className={
                activeTopic === ""
                  ? classes.label + " " + classes.labelActive
                  : classes.label
              }
            >
              <Resource tag={"PageNews::AllTopics"} />
            </Typography>
          </Button>
          {topics &&
            topics?.map((topic, index) => {
              return (
                <Grid key={index} item>
                  <Button
                    className={
                      activeTopic === topic
                        ? classes.chip + " " + classes.chipActive
                        : classes.chip
                    }
                    color="primary"
                    variant={activeTopic === topic ? "contained" : "outlined"}
                    onClick={() => props.handlerTopicSearch(topic)}
                    key={index}
                  >
                    <Box
                      title={topic}
                      className={
                        activeTopic === topic
                          ? classes.label + " " + classes.labelActive
                          : classes.label
                      }
                    >
                      {topic}
                    </Box>
                  </Button>
                </Grid>
              );
            })}
        </Box>
      </Grid>
    </>
  );
}

export default SearchNews;
