export interface IManageUsersState {
  readonly users: IManageUsers;
  readonly userDetails: any;
  readonly userDetailStatus: string;
  readonly permissions: IPermissions;
  readonly permissionStatus: string;
  readonly activateStatus: string;
  readonly userStatus: string;
  readonly inviteStatus: string;
  readonly deleteInviteStatus: string;
  readonly scheduleStatus: string;
  readonly rescheduleStatus: string;
  readonly reinviteStatus: string;
  readonly putPermissionStatus: string;
  readonly importUserStatus: string;
  readonly givePointsStatus: string;
  readonly removePointsStatus: string;
  readonly resetPointsStatus: string;
  readonly tokenStatus: string;
}

export interface IUserDetail {
  userId: string,
  fullName: string,
  photo: string,
  userName: string,
  email: string,
  timezone: string,
  phone: string,
  department: string,
  country: string,
  genre: string,
  permission: number,
  groups: string[],
  availablePoints: number
}

export interface IUserLogs {
  adminName: string,
  adminPhoto: string,
  date: string,
  logType: 1 | 2 | 3 | 4 | 5 | 6,
  reason: string
}

export const IUserLogType = {
  1: "ManageUsers::ResetPassword",
  2: "ManageUsers::ChangedPassword",
  3: "ManageUsers::ChangedDepartment",
  4: "ManageUsers::ChangedCountry",
  5: "ManageUsers::ChangedGroup",
  6: "ManageUsers::ChangedPermission"
}
export interface IPermissions {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IPermissionResults[]
}

export interface IPermissionResults {
  userId: string,
  fullName: string,
  photo: string,
  email: string,
  permission: number
}
export interface IManageUsers {
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
  result: IManageUsersResult[];
}
export interface IManageUsersResult {
  userId: string;
  fullName: string;
  photo: string;
  email: string;
  availablePoints: number;
  permission: number;
  invited: boolean;
  inviteDate: string;
  deactivateDate: string;
  groups: [
    {
      groupId: string;
      title: string;
      cultureTag: string;
    }
  ];
}

export enum ManageUsersActionTypes {
  GET_USERS_LOAD = "GET_USERS_LOAD",
  GET_USERS_SUCCESS = "GET_USERS_SUCCESS",
  GET_USERS_FAILED = "GET_USERS_FAILED",

  GET_USERSDETAIL_LOAD = "GET_USERSDETAIL_LOAD",
  GET_USERSDETAIL_SUCCESS = "GET_USERSDETAIL_SUCCESS",
  GET_USERSDETAIL_FAILED = "GET_USERSDETAIL_FAILED",

  GET_PERMISSIONS_LOAD = "GET_PERMISSIONS_LOAD",
  GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS",
  GET_PERMISSIONS_FAILED = "GET_PERMISSIONS_FAILED",

  POST_DEACTIVATE_LOAD = "POST_DEACTIVATE_LOAD",
  POST_DEACTIVATE_SUCCESS = "POST_DEACTIVATE_SUCCESS",
  POST_DEACTIVATE_FAILED = "POST_DEACTIVATE_FAILED",

  POST_INVITE_LOAD = "POST_INVITE_LOAD",
  POST_INVITE_SUCCESS = "POST_INVITE_SUCCESS",
  POST_INVITE_FAILED = "POST_INVITE_FAILED",

  DELETE_INVITE_LOAD = "DELETE_INVITE_LOAD",
  DELETE_INVITE_SUCCESS = "DELETE_INVITE_SUCCESS",
  DELETE_INVITE_FAILED = "DELETE_INVITE_FAILED",

  POST_ACTIVATE_LOAD = "POST_ACTIVATE_LOAD",
  POST_ACTIVATE_SUCCESS = "POST_ACTIVATE_SUCCESS",
  POST_ACTIVATE_FAILED = "POST_ACTIVATE_FAILED",

  POST_SCHEDULE_LOAD = "POST_SCHEDULE_LOAD",
  POST_SCHEDULE_SUCCESS = "POST_SCHEDULE_SUCCESS",
  POST_SCHEDULE_FAILED = "POST_SCHEDULE_FAILED",

  PUT_RESCHEDULE_LOAD = "PUT_RESCHEDULE_LOAD",
  PUT_RESCHEDULE_SUCCESS = "PUT_RESCHEDULE_SUCCESS",
  PUT_RESCHEDULE_FAILED = "PUT_RESCHEDULE_FAILED",

  PUT_REINVITE_LOAD = "PUT_REINVITE_LOAD",
  PUT_REINVITE_SUCCESS = "PUT_REINVITE_SUCCESS",
  PUT_REINVITE_FAILED = "PUT_REINVITE_FAILED",

  PUT_PERMISSION_LOAD = "PUT_PERMISSION_LOAD",
  PUT_PERMISSION_SUCCESS = "PUT_PERMISSION_SUCCESS",
  PUT_PERMISSION_FAILED = "PUT_PERMISSION_FAILED",

  POST_IMPORT_USERS_LOAD = "POST_IMPORT_USERS_LOAD",
  POST_IMPORT_USERS_SUCCESS = "POST_IMPORT_USERS_SUCCESS",
  POST_IMPORT_USERS_FAILED = "POST_IMPORT_USERS_FAILED",

  POST_TOKEN_LOAD = "POST_TOKEN_LOAD",
  POST_TOKEN_SUCCESS = "POST_TOKEN_SUCCESS",
  POST_TOKEN_FAILED = "POST_TOKEN_FAILED",
  PUT_WALLET_POINTS_GIVE_LOAD = "PUT_WALLET_POINTS_GIVE_LOAD",
  PUT_WALLET_POINTS_GIVE_SUCCESS = "PUT_WALLET_POINTS_GIVE_SUCCESS",
  PUT_WALLET_POINTS_GIVE_FAILED = "PUT_WALLET_POINTS_GIVE_FAILED",

  PUT_WALLET_POINTS_RESET_LOAD = "PUT_WALLET_POINTS_RESET_LOAD",
  PUT_WALLET_POINTS_RESET_SUCCESS = "PUT_WALLET_POINTS_RESET_SUCCESS",
  PUT_WALLET_POINTS_RESET_FAILED = "PUT_WALLET_POINTS_RESET_FAILED",

  PUT_WALLET_POINTS_REMOVE_LOAD = "PUT_WALLET_POINTS_REMOVE_LOAD",
  PUT_WALLET_POINTS_REMOVE_SUCCESS = "PUT_WALLET_POINTS_REMOVE_SUCCESS",
  PUT_WALLET_POINTS_REMOVE_FAILED = "PUT_WALLET_POINTS_REMOVE_FAILED",

}
export enum ManageUsersStatusTypes {
  LOAD = "LOADING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}
export interface IManageUsersGetLoadAction {
  type: ManageUsersActionTypes.GET_USERS_LOAD;
  status: string;
}
export interface IManageUsersGetSuccessAction {
  type: ManageUsersActionTypes.GET_USERS_SUCCESS;
  status: string;
  users: IManageUsers;
  isInfinite: boolean;
}
export interface IManageUsersGetFailAction {
  type: ManageUsersActionTypes.GET_USERS_FAILED;
  status: string;
}
export interface IManageUsersDetailLoadAction {
  type: ManageUsersActionTypes.GET_USERSDETAIL_LOAD;
  status: string;
}
export interface IManageUsersDetailSuccessAction {
  type: ManageUsersActionTypes.GET_USERSDETAIL_SUCCESS;
  status: string;
  userDetails: IUserDetail;
}
export interface IManageUsersDetailFailAction {
  type: ManageUsersActionTypes.GET_USERSDETAIL_FAILED;
  status: string;
}

export interface IManageUsersPermissionsLoadAction {
  type: ManageUsersActionTypes.GET_PERMISSIONS_LOAD;
  status: string;
}
export interface IManageUsersPermissionsSuccessAction {
  type: ManageUsersActionTypes.GET_PERMISSIONS_SUCCESS;
  status: string;
  permissions: IPermissions;
  isInfinite: boolean;
}
export interface IManageUsersPermissionsFailAction {
  type: ManageUsersActionTypes.GET_PERMISSIONS_FAILED;
  status: string;
}

export interface IManageUsersDeactivateLoadAction {
  type: ManageUsersActionTypes.POST_DEACTIVATE_LOAD;
  status: string;
}
export interface IManageUsersDeactivateSuccessAction {
  type: ManageUsersActionTypes.POST_DEACTIVATE_SUCCESS;
  status: string;
}
export interface IManageUsersDeactivateFailAction {
  type: ManageUsersActionTypes.POST_DEACTIVATE_FAILED;
  status: string;
}

export interface IManageUsersActivateLoadAction {
  type: ManageUsersActionTypes.POST_ACTIVATE_LOAD;
  status: string;
}
export interface IManageUsersActivateSuccessAction {
  type: ManageUsersActionTypes.POST_ACTIVATE_SUCCESS;
  status: string;
}
export interface IManageUsersActivateFailAction {
  type: ManageUsersActionTypes.POST_ACTIVATE_FAILED;
  status: string;
}

export interface IManageUsersInviteLoadAction {
  type: ManageUsersActionTypes.POST_INVITE_LOAD;
  status: string;
}
export interface IManageUsersInviteSuccessAction {
  type: ManageUsersActionTypes.POST_INVITE_SUCCESS;
  status: string;
}
export interface IManageUsersInviteFailAction {
  type: ManageUsersActionTypes.POST_INVITE_FAILED;
  status: string;
}
export interface IManageUsersDeleteInviteLoadAction {
  type: ManageUsersActionTypes.DELETE_INVITE_LOAD;
  status: string;
}
export interface IManageUsersDeleteInviteSuccessAction {
  type: ManageUsersActionTypes.DELETE_INVITE_SUCCESS;
  status: string;
}
export interface IManageUsersDeleteInviteFailAction {
  type: ManageUsersActionTypes.DELETE_INVITE_FAILED;
  status: string;
}
export interface IManageUsersScheduleLoadAction {
  type: ManageUsersActionTypes.POST_SCHEDULE_LOAD;
  status: string;
}
export interface IManageUsersScheduleSuccessAction {
  type: ManageUsersActionTypes.POST_SCHEDULE_SUCCESS;
  status: string;
}
export interface IManageUsersScheduleFailAction {
  type: ManageUsersActionTypes.POST_SCHEDULE_FAILED;
  status: string;
}
export interface IManageUsersRescheduleLoadAction {
  type: ManageUsersActionTypes.PUT_RESCHEDULE_LOAD;
  status: string;
}
export interface IManageUsersRescheduleSuccessAction {
  type: ManageUsersActionTypes.PUT_RESCHEDULE_SUCCESS;
  status: string;
}
export interface IManageUsersRescheduleFailAction {
  type: ManageUsersActionTypes.PUT_RESCHEDULE_FAILED;
  status: string;
}
export interface IManageUsersReinviteLoadAction {
  type: ManageUsersActionTypes.PUT_REINVITE_LOAD;
  status: string;
}
export interface IManageUsersReinviteSuccessAction {
  type: ManageUsersActionTypes.PUT_REINVITE_SUCCESS;
  status: string;
}
export interface IManageUsersReinviteFailAction {
  type: ManageUsersActionTypes.PUT_REINVITE_FAILED;
  status: string;
}

export interface IManageUsersPutPermissionLoadAction {
  type: ManageUsersActionTypes.PUT_PERMISSION_LOAD;
  status: string;
}
export interface IManageUsersPutPermissionSuccessAction {
  type: ManageUsersActionTypes.PUT_PERMISSION_SUCCESS;
  status: string;
}
export interface IManageUsersPutPermissionFailAction {
  type: ManageUsersActionTypes.PUT_PERMISSION_FAILED;
  status: string;
}
export interface IManageUsersImportPostGroupLoadAction {
  type: ManageUsersActionTypes.POST_IMPORT_USERS_LOAD;
  status: string;
}
export interface IManageUsersImportPostGroupSuccessAction {
  type: ManageUsersActionTypes.POST_IMPORT_USERS_SUCCESS;
  status: string;
}
export interface IManageUsersImportPostGroupFailAction {
  type: ManageUsersActionTypes.POST_IMPORT_USERS_FAILED;
  status: string;
}

export interface IManageUsersTokenLoadAction {
  type: ManageUsersActionTypes.POST_TOKEN_LOAD;
  status: string;
}
export interface IManageUsersTokenSuccessAction {
  type: ManageUsersActionTypes.POST_TOKEN_SUCCESS;
  status: string;
}
export interface IManageUsersTokenFailAction {
  type: ManageUsersActionTypes.POST_TOKEN_FAILED;
  status: string;
}
export interface IManageUsersWalletGivePointsLoadAction {
  type: ManageUsersActionTypes.PUT_WALLET_POINTS_GIVE_LOAD;
  status: string;
}
export interface IManageUsersWalletGivePointsSuccessAction {
  type: ManageUsersActionTypes.PUT_WALLET_POINTS_GIVE_SUCCESS;
  status: string;
  points: number,
  usersId: string[],
}
export interface IManageUsersWalletGivePointsFailAction {
  type: ManageUsersActionTypes.PUT_WALLET_POINTS_GIVE_FAILED;
  status: string;
}

export interface IManageUsersWalletResetPointsLoadAction {
  type: ManageUsersActionTypes.PUT_WALLET_POINTS_RESET_LOAD;
  status: string;
}
export interface IManageUsersWalletResetPointsSuccessAction {
  type: ManageUsersActionTypes.PUT_WALLET_POINTS_RESET_SUCCESS;
  usersId: string[],
  status: string;
}
export interface IManageUsersWalletResetPointsFailAction {
  type: ManageUsersActionTypes.PUT_WALLET_POINTS_RESET_FAILED;
  status: string;
}

export interface IManageUsersWalletRemovePointsLoadAction {
  type: ManageUsersActionTypes.PUT_WALLET_POINTS_REMOVE_LOAD;
  status: string;
}
export interface IManageUsersWalletRemovePointsSuccessAction {
  type: ManageUsersActionTypes.PUT_WALLET_POINTS_REMOVE_SUCCESS;
  points: number,
  usersId: string[],
  status: string;
}
export interface IManageUsersWalletRemovePointsFailAction {
  type: ManageUsersActionTypes.PUT_WALLET_POINTS_REMOVE_FAILED;
  status: string;
}

export type ManageUsersActions =
  | IManageUsersGetLoadAction
  | IManageUsersGetSuccessAction
  | IManageUsersGetFailAction
  | IManageUsersDetailLoadAction
  | IManageUsersDetailSuccessAction
  | IManageUsersDetailFailAction
  | IManageUsersPermissionsLoadAction
  | IManageUsersPermissionsSuccessAction
  | IManageUsersPermissionsFailAction
  | IManageUsersDeactivateLoadAction
  | IManageUsersDeactivateSuccessAction
  | IManageUsersDeactivateFailAction
  | IManageUsersActivateLoadAction
  | IManageUsersActivateSuccessAction
  | IManageUsersActivateFailAction
  | IManageUsersInviteLoadAction
  | IManageUsersInviteSuccessAction
  | IManageUsersInviteFailAction
  | IManageUsersDeleteInviteLoadAction
  | IManageUsersDeleteInviteSuccessAction
  | IManageUsersDeleteInviteFailAction
  | IManageUsersScheduleLoadAction
  | IManageUsersScheduleSuccessAction
  | IManageUsersScheduleFailAction
  | IManageUsersRescheduleLoadAction
  | IManageUsersRescheduleSuccessAction
  | IManageUsersRescheduleFailAction
  | IManageUsersReinviteLoadAction
  | IManageUsersReinviteSuccessAction
  | IManageUsersReinviteFailAction
  | IManageUsersPutPermissionLoadAction
  | IManageUsersPutPermissionSuccessAction
  | IManageUsersPutPermissionFailAction
  | IManageUsersImportPostGroupLoadAction
  | IManageUsersImportPostGroupSuccessAction
  | IManageUsersImportPostGroupFailAction
  | IManageUsersTokenLoadAction
  | IManageUsersTokenSuccessAction
  | IManageUsersTokenFailAction
  | IManageUsersWalletGivePointsLoadAction
  | IManageUsersWalletGivePointsSuccessAction
  | IManageUsersWalletGivePointsFailAction
  | IManageUsersWalletResetPointsLoadAction
  | IManageUsersWalletResetPointsSuccessAction
  | IManageUsersWalletResetPointsFailAction
  | IManageUsersWalletRemovePointsLoadAction
  | IManageUsersWalletRemovePointsSuccessAction
  | IManageUsersWalletRemovePointsFailAction
