/**
 * XSelect
 * Author: William Coscodai
 */
import {
  Checkbox,
  createStyles,
  FormGroup,
  InputLabel,
  makeStyles,
  MenuItem,
  TextField,
  TextFieldProps,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

interface IProps {
  inputLabel?: string | JSX.Element;
  outline?: boolean;
  options?: any[];
  multiple?: boolean;
  optionId: string;
  optionName: string;
  setValue?: (value: any) => void;
  selectedResource?: string | JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: 16,
      marginBottom: 6,
    },
    outlinedInput: (props: any) => ({
      "& > .MuiOutlinedInput-notchedOutline": !props.outline
        ? {
            border: 0,
          }
        : {},
    }),
  })
);

function XSelect({
  inputLabel,
  outline = true,
  multiple,
  options,
  optionId,
  optionName,
  selectedResource,
  value,
  setValue,
  ...props
}: IProps & TextFieldProps) {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ outline });
  const [innerValue, setInnerValue] = useState<any>(value ? value : "Select");

  useEffect(() => {
    setValue && setValue(innerValue);
  }, [innerValue, setValue]);

  return (
    <FormGroup>
      {inputLabel && <InputLabel className={classes.label}> {inputLabel} </InputLabel>}
      <TextField
        {...props}
        variant="outlined"
        size="small"
        select
        fullWidth
        onChange={(e) => setInnerValue(e.target.value)}
        value={innerValue}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.outlinedInput,
        }}
        SelectProps={{
          ...props.SelectProps,
          multiple: multiple,
          style: {
            borderRadius: 30,
          },
          open: open,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false),
          IconComponent: () =>
            open ? (
              <ExpandLessIcon
                style={{ cursor: "pointer", marginRight: 6 }}
                onClick={() => setOpen(false)}
                color="primary"
              />
            ) : (
              <ExpandMoreIcon
                color="primary"
                style={{ cursor: "pointer", marginRight: 6 }}
                onClick={() => setOpen(true)}
              />
            ),
          MenuProps: {
            variant: "menu",
            MenuListProps: {
              style: multiple
                ? {
                    padding: 0,
                  }
                : {},
            },
          },

          renderValue: (selected: any) =>
            multiple
              ? selected.length > 1
                ? selected.length + ` ${selectedResource}`
                : selected
              : selected,
        }}
      >
        {!multiple && (
          <MenuItem value={"Select"} selected disabled>
            Select
          </MenuItem>
        )}
        {options?.length &&
          options.map((option) =>
            multiple ? (
              <MenuItem key={option[optionId]} value={option[optionName]}>
                <Checkbox
                  color="primary"
                  checked={innerValue.indexOf(option[optionName]) > -1}
                />
                {option[optionName]}
              </MenuItem>
            ) : (
              <MenuItem key={option[optionId]} value={option[optionName]}>
                {option[optionName]}
              </MenuItem>
            )
          )}
      </TextField>
    </FormGroup>
  );
}

export default XSelect;
