import ShareCard from "./ShareCard";
import newApp from "utils/reactNewApp";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Resource from "components/Resources/Resource";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ModalSelectAChallenge from "common/Modal/ModalSelectAChallenge";
import {
  Grid,
  Typography,
  IconButton,
  Theme,
  makeStyles,
  createStyles,
  Divider,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    titleHeader: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#313b53",
    },
    dividerHeader: {
      width: "90%",
      marginLeft: "30px",
    },
    dialog: {
      margin: "auto",
    },
    dialogHeader: {
      padding: "22px 24px 10px 30px",
    },
  })
);

interface IProps {
  username: string;
  totalTasks?: number;
  ideasEvaluate?: number;
  overdue?: number;
  showShareYourIdea?: boolean;
  showShareInspiration?: boolean;
}

function FeedActionCardBar(props: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    username,
    totalTasks,
    ideasEvaluate,
    overdue,
    showShareYourIdea,
  } = props;
  const [newPath, setNewPath] = useState("/");
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography component="h4" align="left" paragraph={true}>
        <Resource tag={"PageFeed::Welcome_Hello"} args={[username]} />
        &nbsp;
        <Resource tag={"PageFeed::Welcome_CallToAction"} />
      </Typography>
      <Grid container spacing={2}>
        {showShareYourIdea && (
          <Grid
            item
            xs={12}
            sm={6}
            md={newApp && props?.showShareInspiration ? 3 : 4}
            lg={newApp && props?.showShareInspiration ? 3 : 4}
            xl={newApp && props?.showShareInspiration ? 3 : 4}
          >
            <ShareCard
              background={theme.palette.secondary.main}
              color="white"
              buttonColor={theme.palette.secondary.light}
              buttonCaption="AddRounded"
              action={() => {
                handleClose();
                setOpen(true);
                setNewPath("/createIdea");
              }}
            >
              <Resource tag="PageFeed::Idea_Share" />
            </ShareCard>
          </Grid>
        )}
        {newApp && props?.showShareInspiration && (
          <>
            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
              <ShareCard
                background={theme.palette.primary.main}
                color="white"
                buttonColor={theme.palette.primary.light}
                buttonCaption="AddRounded"
                action={() => {
                  handleClose();
                  setOpen(true);
                  setNewPath("/createInspiration");
                }}
              >
                <Resource tag="PageFeed::ShareInspiration" />
              </ShareCard>
            </Grid>
          </>
        )}
        {ideasEvaluate ? (
          <Grid
            item
            xs={12}
            sm={6}
            md={newApp && props?.showShareInspiration ? 3 : 4}
            lg={newApp && props?.showShareInspiration ? 3 : 4}
            xl={newApp && props?.showShareInspiration ? 3 : 4}
          >
            <Link to={"/idea"}>
              <ShareCard
                background="white"
                color={theme.palette.primary.main}
                buttonColor={theme.palette.primary.light}
                buttonCaption="ArrowForwardIosRoundedIcon"
              >
                {ideasEvaluate !== undefined ? (
                  <>
                    {ideasEvaluate > 1 ? (
                      <>
                        <Resource
                          tag="PageFeed::WebIdeasEvaluate"
                          args={[ideasEvaluate]}
                        />
                      </>
                    ) : (
                      <>
                        <Resource
                          tag="PageFeed::WebIdeaEvaluate"
                          args={[ideasEvaluate]}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Resource tag="PageFeed::WebIdeaEvaluate" args={[0]} />
                  </>
                )}
              </ShareCard>
            </Link>
          </Grid>
        ) : (
          <></>
        )}
        {totalTasks ? (
          <>
            <Grid
              item
              xs={12}
              sm={6}
              md={newApp && props?.showShareInspiration ? 3 : 4}
              lg={newApp && props?.showShareInspiration ? 3 : 4}
              xl={newApp && props?.showShareInspiration ? 3 : 4}
            >
              <Link to={"/keytasks"}>
                <ShareCard
                  background="white"
                  color={theme.palette.secondary.main}
                  buttonColor={theme.palette.secondary.light}
                  buttonCaption="ArrowForwardIosRoundedIcon"
                >
                  <>
                    {totalTasks !== undefined ? (
                      <>
                        {totalTasks > 1 ? (
                          <>
                            <Resource
                              tag="PageFeed::WebCompleteYourTasks"
                              args={[totalTasks]}
                            />
                          </>
                        ) : (
                          <>
                            <Resource
                              tag="PageFeed::WebCompleteYourTask"
                              args={[totalTasks]}
                            />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Resource
                          tag="PageFeed::WebCompleteYourTask"
                          args={[0]}
                        />
                      </>
                    )}
                    <div
                      className="FeedActionCardBar-overdue"
                      style={{
                        fontWeight: 500,
                        marginLeft: "1px",
                        fontSize: "13px",
                        color: "#313b53",
                      }}
                    >
                      {overdue !== undefined ? (
                        <>
                          {overdue > 1 ? (
                            <>
                              <p>
                                {overdue} <Resource tag="PageFeed::Overdues" />
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                {overdue} <Resource tag="PageFeed::Overdue" />
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p>
                            {0} <Resource tag="PageFeed::Overdue" />
                          </p>
                        </>
                      )}
                    </div>
                  </>
                </ShareCard>
              </Link>
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>

      <Dialog open={isOpen} maxWidth="md" fullWidth className={classes.dialog}>
        <MuiDialogTitle className={classes.dialogHeader}>
          <Typography color="textPrimary" className={classes.titleHeader}>
            <Resource tag={"PageNewIdea::Challenge_Header"} />
          </Typography>
          <IconButton
            // aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Divider className={classes.dividerHeader} />
        <ModalSelectAChallenge closeButton={handleClose} path={newPath} />
      </Dialog>
    </>
  );
}

export default FeedActionCardBar;
