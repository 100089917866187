import { ThunkAction } from "redux-thunk";
import reactNewApp from "utils/reactNewApp";
import { ActionCreator, Dispatch } from "redux";
import { api_v2, api_general } from "../../../config/api";
import { IRelatedContentState } from "../../reducers/relatedContent/RelatedContentReducer";

const basePath = "relatedcontent";

// Create Action Constants
export enum RelatedContentActionTypes {
  RELATED_CONTENT_LOADING = "RELATED_CONTENT_LOADING",
  RELATED_CONTENT_SUCCEEDED = "RELATED_CONTENT_SUCCEEDED",
  RELATED_CONTENT_FAILED = "RELATED_CONTENT_FAILED",
}

//Create Action Statusses
export enum RelatedContentActionStatus {
  RELATED_CONTENT_LOADING = "LOADING_PREVIEW",
  RELATED_CONTENT_SUCCEEDED = "SUCCEEDED",
  RELATED_CONTENT_FAILED = "FAILED",
}

//------------*Get Preview Manager Actions*---------------
export interface IRelatedContentGetPreviewLoadingAction {
  type: RelatedContentActionTypes.RELATED_CONTENT_LOADING;
  relatedStatus: string;
}

export interface IRelatedContentGetPreviewSucceededAction {
  type: RelatedContentActionTypes.RELATED_CONTENT_SUCCEEDED;
  relatedContent: any;
  meta: any;
  relatedStatus: string;
}

export interface IRelatedContentGetPreviewFailedAction {
  type: RelatedContentActionTypes.RELATED_CONTENT_FAILED;
  relatedStatus: string;
  error: null;
}

/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type RelatedContentActions =
  | IRelatedContentGetPreviewLoadingAction
  | IRelatedContentGetPreviewSucceededAction
  | IRelatedContentGetPreviewFailedAction;

/* Get All idea
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getRelatedContent: ActionCreator<ThunkAction<
  Promise<any>,
  IRelatedContentState,
  null,
  IRelatedContentGetPreviewSucceededAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: RelatedContentActionTypes.RELATED_CONTENT_LOADING,
        relatedStatus: RelatedContentActionStatus.RELATED_CONTENT_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("objectId")) {
        params.push(`objectId=${options.objectId}`);
      }
      if (options.hasOwnProperty("objectType")) {
        params.push(`objectType=${options.objectType}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      reactNewApp
        ? await api_general
            .get(`${basePath}${params_qs}`, {})
            .then((response) => {
              dispatch({
                type: RelatedContentActionTypes.RELATED_CONTENT_SUCCEEDED,
                relatedStatus:
                  RelatedContentActionStatus.RELATED_CONTENT_SUCCEEDED,
                relatedContent: response.data.result,
                meta: response.data.meta,
              });
            })
        : await api_v2.get(`${basePath}${params_qs}`, {}).then((response) => {
            dispatch({
              type: RelatedContentActionTypes.RELATED_CONTENT_SUCCEEDED,
              relatedStatus:
                RelatedContentActionStatus.RELATED_CONTENT_SUCCEEDED,
              relatedContent: response.data.result,
              meta: response.data.meta,
            });
          });
    } catch (err) {
      dispatch({
        type: RelatedContentActionTypes.RELATED_CONTENT_FAILED,
        relatedStatus: RelatedContentActionStatus.RELATED_CONTENT_FAILED,
        error: err,
      });
    }
  };
};
