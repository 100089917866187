import { Reducer } from 'redux';

import { UsersActionTypes, UsersActions } from "../actions/UsersAction"

export interface IUsers {
  fullName: string;
  photo: string;
  userId: string;
  authorId?: string;
  email: string;
}

export interface IDepartments {
  departmentId: string,
  title: string
}

export interface ICurrency {
  currencyId: string,
  description: string,
  symbol:string
}

export interface ICountries {
  countryId: string,
  title: string,
  name: string
}

export interface ITimeZones {
  timeZoneId: string,
  name: string
}

export interface IUsersState {
  readonly users: IUsers[];
  readonly departaments: IDepartments[];
  readonly countries: ICountries[];
  readonly currency: ICurrency[];
  readonly timeZones: ITimeZones[];
  readonly status: string;
  readonly error: any;
}

const initialUsersState: IUsersState = {
  users: [],
  timeZones: [],
  departaments: [],
  countries: [],
  currency: [],
  status: '',
  error: null
}

export const usersReducer: Reducer<IUsersState, UsersActions> = (
  state = initialUsersState,
  action
) => {
  switch (action.type) {
    case UsersActionTypes.GET_USERS_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case UsersActionTypes.GET_USERS_SUCCEEDED: {
      return {
        ...state,
        users: action.users,
        status: action.status
      };
    }
    case UsersActionTypes.GET_TIMEZONES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case UsersActionTypes.GET_TIMEZONES_SUCCEEDED: {
      return {
        ...state,
        timeZones: action.timeZones,
        status: action.status
      };
    }
    case UsersActionTypes.GET_TIMEZONES_FAILED: {
      return {
        ...state,
        error: action.error,
        status: action.status
      };
    }
    case UsersActionTypes.GET_DEPARTMENTS_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case UsersActionTypes.GET_DEPARTMENTS_SUCCEEDED: {
      return {
        ...state,
        departaments: action.departaments,
        status: action.status
      };
    }
    case UsersActionTypes.GET_COUNTRIES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case UsersActionTypes.GET_COUNTRIES_SUCCEEDED: {
      return {
        ...state,
        countries: action.countries,
        status: action.status
      };
    }
    case UsersActionTypes.GET_CURRENCY_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case UsersActionTypes.GET_CURRENCY_SUCCEEDED: {
      return {
        ...state,
        currency: action.currency,
        status: action.status
      };
    }
    case UsersActionTypes.GET_CURRENCY_FAILED: {
      return {
        ...state,
        error: action.error,
        status: action.status
      };
    }
    default:
      return state
  }
}