import {
  Box,
  Button,
  createStyles,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  NativeSelect,
  NativeSelectProps,
  Paper,
  Theme,
  Typography,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { ICultureState } from "redux/reducers/cultureReducer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Resource from "components/Resources/Resource";

type GridSize = "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperP: {
      padding: "20px 30px",
      background: "#F2F2F2",
      minHeight: "50px",
      borderRadius: "5px",
      display: "flex",
      alignItems: "flex-end",
      gap: "16px",
    },
  })
);

interface IProps {
  children?: JSX.Element;
  culture?: ICultureState;
  title?: JSX.Element | string;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  filter?: any;
  id?: string;
  options?: any;
  optionsName?: any;
  value?: any;
}
const SelectInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "& .MuiNativeSelect-icon": {
        color: theme.palette.primary.main,
      },
    },
    input: {
      borderRadius: 22,
      backgroundColor: theme.palette.background.paper,
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "0.8rem",
      padding: "7px 26px 7px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 22,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.1rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

function ManageUsersFilter(props: IProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box marginY={2} width="100%">
      <Paper elevation={0} className={classes.paperP}>
        <Grid container spacing={4}>
          {props.children}
        </Grid>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => props.filter()}
        >
          <Resource tag="PageSettings::Apply" />
        </Button>
      </Paper>
    </Box>
  );
}
function ManageUsersInput(props: IProps & NativeSelectProps): JSX.Element {
  return (
    <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg}>
      <FormControl fullWidth onSubmit={(e) => e.preventDefault()}>
        <InputLabel
          htmlFor="type-task-challenge-validation"
          shrink={true}
          // className={classes.inputLabel}
        >
          <Typography variant="body2" color="textSecondary">
            {props.title}
          </Typography>
        </InputLabel>
        <NativeSelect
          IconComponent={() => (
            <ExpandMoreIcon
              style={{ cursor: "pointer", position: "absolute", right: "0" }}
              color="primary"
            />
          )}
          id="type-task-challenge-validation"
          input={<SelectInput />}
          {...props}
        >
          {props.children}
        </NativeSelect>
      </FormControl>
    </Grid>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export { ManageUsersFilter, ManageUsersInput };
