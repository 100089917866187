import * as React from "react";
import "./CollaborativeDetailList.css";
import { IDetailIdea } from "../../redux/reducers/challengeDetailReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import CardIdeaCollaborative from "components/CardIdeaCollaborative/CardIdeaCollaborative";
import { IAllIdeasState } from "redux/reducers/ideas/AllIdeasReducer";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";

interface IProps {
  ideasCollaborative?: IDetailIdea;
  culture?: ICultureState;
  applicationId?: string;
  postInvestments: (options: object) => Promise<any>;
  postLikes: (options: object) => Promise<any>;
  deleteLikes: (options: object) => Promise<any>;
  getAllIdeas: (options: object) => Promise<IAllIdeasState>;
}

function CollaborativeDetailList(props: IProps) {
  const {
    ideasCollaborative,
    culture,
    applicationId,
    postInvestments,
    postLikes,
    deleteLikes,
    getAllIdeas,
  } = props;

  const cardArray: any = [];
  let cardArrayAux: any = [];

  ideasCollaborative?.result?.map((ideaCollaborative, index) => {
    cardArrayAux.push(
      <CardIdeaCollaborative
        getAllIdeas={getAllIdeas}
        postLikes={postLikes}
        deleteLikes={deleteLikes}
        culture={culture!}
        applicationId={applicationId!}
        postInvestments={postInvestments}
        idea={ideaCollaborative}
        width={6}
        key={ideaCollaborative?.ideaId}
      />
    );

    if ((index + 1) % 7 === 0) {
      return cardArray?.push(cardArrayAux, ((cardArrayAux = [])));
    } else {
      return "";
    }
  });

  if (cardArrayAux.length) {
    cardArray.push(cardArrayAux);
    cardArrayAux = [];
  }
  const theme = useTheme();
  const matchXsSm = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return cardArray.map((cardArr: any, index: any) => (
    <Box key={index} className="collaborativeIdeaList-container">
      {matchXsSm ? (
        <>
          <Grid container spacing={2}>
            {cardArr[1] ? (
              <>
                <Grid item xs={12}>
                  <Box>{cardArr[0]}</Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>{cardArr[1]}</Box>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Box>{cardArr[0]}</Box>
              </Grid>
            )}

            {cardArr[2] && (
              <>
                <Grid item xs={12}>
                  <Box>{cardArr[2]}</Box>
                </Grid>

                <Grid item xs={12}>
                  <Box>{cardArr[3]}</Box>
                </Grid>

                {cardArr[4] && (
                  <Grid item xs={12}>
                    <Box>{cardArr[4]}</Box>
                  </Grid>
                )}

                {cardArr[5] && (
                  <>
                    <Grid item xs={12}>
                      <Box>{cardArr[5]}</Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>{cardArr[6]}</Box>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </>
      ) : (
        <>
          {cardArr[1] ? (
            <Box className="firstLine">
              {cardArr[0]}
              {cardArr[1]}
            </Box>
          ) : (
            <Box>{cardArr[0]}</Box>
          )}
          {cardArr[2] && (
            <Box
              style={{
                display: "grid",
                gridTemplateColumns: cardArr[3] ? "1fr 1fr" : "1fr",
                gap: "20px",
              }}
            >
              <Box
                style={{
                  display: "grid",
                  gridTemplateColumns: "390px",
                  gridTemplateRows: cardArr[3] ? "265px 265px" : "265px",
                  gap: "20px",
                }}
              >
                {cardArr[2]}
                {cardArr[3]}
              </Box>

              {cardArr[4] && <Box className="secondLine-big">{cardArr[4]}</Box>}
            </Box>
          )}
          {cardArr[5] && (
            <Box className="thirdLine">
              {cardArr[5]}
              {cardArr[6]}
            </Box>
          )}
        </>
      )}
    </Box>
  ));
}

export default CollaborativeDetailList;
