import * as React from "react";
import { connect } from "react-redux";
import { doLogin, IAuthLoginAction } from "../../../redux/actions/AuthAction";
import {
  getTenantConfigurations,
  ITenantConfigurationAction,
  setActiveTenant,
  IActiveTenantAction,
} from "../../../redux/actions/tenantAction";
import { ITenant } from "../../../redux/reducers/tenantReducer";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../../../redux/store/Store";
import Slide from "@material-ui/core/Slide";
import history from "../../../routes/history";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import lang from "../../../languages/index";
import {
  IPasswordUpdateAction,
  updatePassword,
  recoverPassword,
  IPasswordRecoverAction,
  validateRecoverCode,
  IPasswordVerifyAction,
} from "../../../redux/actions/PasswordActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import { ArrowForward, Visibility, VisibilityOff } from "@material-ui/icons";
import { useState, useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Input,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  Box,
  Typography,
} from "@material-ui/core";
import Resource, { resource } from "../../../components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Links from "@material-ui/core/Link";

interface IProps {
  doLogin: (
    tenantId: number,
    username: string,
    password: string
  ) => Promise<IAuthLoginAction>;
  getTenantConfigurations: (
    email: string
  ) => Promise<ITenantConfigurationAction>;
  updatePassword: (options: object) => Promise<IPasswordUpdateAction>;
  recoverPassword: (options: object) => Promise<IPasswordRecoverAction>;
  validateRecoverCode: (options: object) => Promise<IPasswordVerifyAction>;
  setActiveTenant: (activeTenant: ITenant) => Promise<IActiveTenantAction>;
}

interface IStateProps {
  activeTenant: ITenant;
  cultureState: ICultureState;
}

interface IState {
  email: string;
  password: string;
  tenantId: number;
  submitted: boolean;
  tenantConfigurations: boolean;
  emailVerifying: boolean;
  loadingSubmit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
    textField: {
      width: "100%",
      "& label": {
        textTransform: "uppercase",
      },
    },
    verificationCode: {
      display: "flex",
      flexWrap: "wrap",
    },
    verificationCodeInput: {
      margin: theme.spacing(1, "auto"),
      width: "5vh",
    },
    containerInputs: {
      position: "relative",
      minHeight: "190px",
    },
  })
);

function Recover(props: IProps & IStateProps & IState) {
  const classes = useStyles();
  const codeInputOneRef = useRef<HTMLInputElement>();
  const codeInputTwoRef = useRef<HTMLInputElement>();
  const codeInputThreeRef = useRef<HTMLInputElement>();
  const codeInputFourRef = useRef<HTMLInputElement>();
  const codeInputFiveRef = useRef<HTMLInputElement>();
  const codeInputSixRef = useRef<HTMLInputElement>();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [, setSubmitted] = useState(false);
  const [emailVerifying] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [codeNumberOne, setCodeNumberOne] = useState("");
  const [codeNumberTwo, setCodeNumberTwo] = useState("");
  const [codeNumberThree, setCodeNumberThree] = useState("");
  const [codeNumberFour, setCodeNumberFour] = useState("");
  const [codeNumberFive, setCodeNumberFive] = useState("");
  const [codeNumberSix, setCodeNumberSix] = useState("");
  const [isEmailValidated, setIsEmailValidated] = useState(false);
  const [isCodeValidated, setIsCodeValidated] = useState(false);
  const [recoverCodeError, setRecoverCodeError] = useState(false);
  const [passwordUpdateError, setPasswordUpdateError] = useState(false);

  const [msgError, setMsgError] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  let captcha: string = "";

  function handleEmailChange(e: any) {
    const { value } = e.target;
    setEmail(value);
    setEmailError(false);
    setMsgError("");
  }

  function handlePasswordChange(e: any) {
    const { value } = e.target;

    if (value !== confirmPassword) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
    setPassword(value);
    setMsgError("");
  }

  function handleConfirmPasswordChange(e: any) {
    const { value } = e.target;
    if (value !== password) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
    setConfirmPassword(value);
    setMsgError("");
  }

  async function handleRecoverSubmit(e: any) {
    e.preventDefault();
    setEmailError(false);
    setLoadingSubmit(true);
    setSubmitted(true);
    let emailCorrect = true;

    executeRecaptcha &&
      (await executeRecaptcha("Recover").then((response) => {
        captcha = response;
      }));

    const atSignIndex = email.indexOf("@");
    const dotIndex = email.indexOf(".", atSignIndex);

    if (atSignIndex === -1 || dotIndex === -1) {
      setMsgError(resource(props?.cultureState, "Errors::EmailInvalid"));
      setLoadingSubmit(false);
      emailCorrect = false;
    }

    if (emailCorrect) {
      // if(isTenantSelected){
      props
        ?.recoverPassword({
          email: email,
          captcha: captcha,
          culture: props.cultureState,
        })
        .then(
          (response) => {
            setLoadingSubmit(false);
            setIsEmailValidated(true);
            setMsgError("");
          },
          (error) => {
            setMsgError(
              resource(
                props?.cultureState,
                error?.error?.response?.data?.length > 0
                  ? error?.error?.response?.data[0].value.replace(".", "::")
                  : "PageLogin::UnexpectedError"
              )
            );
            setEmailError(true);
            setLoadingSubmit(false);
            setIsEmailValidated(false);
          }
        );
      // }
    }
  }

  function handleRecoverCodeSubmit(e: any) {
    setLoadingSubmit(true);
    setSubmitted(true);
    e.preventDefault();

    props
      ?.validateRecoverCode({
        email: email,
        code:
          codeNumberOne +
          codeNumberTwo +
          codeNumberThree +
          codeNumberFour +
          codeNumberFive +
          codeNumberSix,
      })
      .then(
        (response) => {
          setLoadingSubmit(false);
          setIsCodeValidated(true);
          setMsgError("");
        },
        (error) => {
          setLoadingSubmit(false);
          setRecoverCodeError(true);
          setCodeNumberOne("");
          setCodeNumberTwo("");
          setCodeNumberThree("");
          setCodeNumberFour("");
          setCodeNumberFive("");
          setCodeNumberSix("");
          setMsgError(
            resource(
              props?.cultureState,
              error?.error?.response?.data?.length > 0
                ? error?.error?.response?.data[0].value.replace(".", "::")
                : "PageLogin::UnexpectedError"
            )
          );
          if (
            error?.error?.response?.data[0].value === "MessageError.CodeExpired"
          ) {
            setRecoverCodeError(false);
            HandleBackRecoverScreen();
          }
        }
      );
  }

  function handleRecoverPasswordSubmit(e: any) {
    e.preventDefault();

    //The password must have numbers, letters, capital letters, special characters and at least 8 characters.
    if (
      !(
        password.match(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
        ) != null
      )
    ) {
      setMsgError(lang.Login.Error.ForcePassword);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    } else setConfirmPasswordError(false);
    setLoadingSubmit(true);
    setSubmitted(true);

    props
      ?.updatePassword({
        email: email,
        culture: props.cultureState,
        code:
          codeNumberOne +
          codeNumberTwo +
          codeNumberThree +
          codeNumberFour +
          codeNumberFive +
          codeNumberSix,
        password: password,
      })
      .then(
        (response) => {
          setLoadingSubmit(false);
          setPasswordUpdateError(false);
          history.push("log-in");
        },
        (error) => {
          setLoadingSubmit(false);
          setPasswordUpdateError(true);
          setMsgError(
            resource(
              props?.cultureState,
              error?.error?.response?.data?.length > 0
                ? error?.error?.response?.data[0].value.replace(".", "::")
                : "PageLogin::UnexpectedError"
            )
          );
        }
      );
  }

  function handleClickShowPassword1() {
    setShowPassword1(!showPassword1);
  }

  function handleClickShowPassword2() {
    setShowPassword2(!showPassword2);
  }

  function handleCodeOneForm(e: any) {
    setRecoverCodeError(false);
    const { value } = e.target;
    if (value.length > 0) {
      setCodeNumberOne(value.substr(value.length - 1));
      codeInputTwoRef.current?.focus();
    } else {
      if (codeNumberOne.length > 0) {
        setCodeNumberOne("");
      } else {
        setCodeNumberOne(value);
        codeInputTwoRef.current?.focus();
      }
    }
    setMsgError("");
  }

  function handleCodeTwoForm(e: any) {
    setRecoverCodeError(false);
    const { value } = e.target;
    if (value.length > 0) {
      setCodeNumberTwo(value.substr(value.length - 1));
      codeInputThreeRef.current?.focus();
    } else {
      if (codeNumberTwo.length > 0) {
        setCodeNumberTwo("");
        setCodeNumberThree("");
        setCodeNumberFour("");
        setCodeNumberFive("");
        setCodeNumberSix("");
        codeInputOneRef.current?.focus();
      } else {
        setCodeNumberTwo(value);
        codeInputThreeRef.current?.focus();
      }
    }
    setMsgError("");
  }

  function handleCodeThreeForm(e: any) {
    setRecoverCodeError(false);
    const { value } = e.target;
    if (value.length > 0) {
      setCodeNumberThree(value.substr(value.length - 1));
      codeInputFourRef.current?.focus();
    } else {
      if (codeNumberThree.length > 0) {
        setCodeNumberThree("");
        codeInputTwoRef.current?.focus();
      } else {
        setCodeNumberTwo(value);
        codeInputFourRef.current?.focus();
      }
    }
    setMsgError("");
  }

  function handleCodeFourForm(e: any) {
    setRecoverCodeError(false);
    const { value } = e.target;
    if (value.length > 0) {
      setCodeNumberFour(value.substr(value.length - 1));
      codeInputFiveRef.current?.focus();
    } else {
      if (codeNumberFour.length > 0) {
        setCodeNumberFour("");
        codeInputThreeRef.current?.focus();
      } else {
        setCodeNumberTwo(value);
        codeInputFiveRef.current?.focus();
      }
    }
    setMsgError("");
  }

  function handleCodeFiveForm(e: any) {
    setRecoverCodeError(false);
    const { value } = e.target;
    if (value.length > 0) {
      setCodeNumberFive(value.substr(value.length - 1));
      codeInputSixRef.current?.focus();
    } else {
      if (codeNumberFive.length > 0) {
        setCodeNumberFive("");
        setCodeNumberSix("");
        codeInputFourRef.current?.focus();
      } else {
        setCodeNumberTwo(value);
        codeInputSixRef.current?.focus();
      }
    }
    setMsgError("");
  }

  function handleCodeSixForm(e: any) {
    setRecoverCodeError(false);
    const { value } = e.target;
    if (value.length > 0) {
      setCodeNumberSix(value.substr(value.length - 1));
    } else {
      if (codeNumberSix.length > 0) {
        setCodeNumberSix("");
        codeInputFiveRef.current?.focus();
      } else {
        setCodeNumberTwo(value);
      }
    }
    setMsgError("");
  }

  function HandleBackRecoverCodeScreen() {
    setMsgError("");
    setIsCodeValidated(false);
  }

  function HandleBackRecoverScreen() {
    setIsEmailValidated(false);
  }

  function HandleBackLoginScreen() {
    setMsgError("");
    history.push("/log-in");
  }

  return (
    <>
      {!isEmailValidated && (
        <Slide
          direction="left"
          in={!isEmailValidated}
          mountOnEnter
          unmountOnExit
        >
          <form name="form" onSubmit={handleRecoverSubmit}>
            <Box display="flex" flexDirection="row">
              <div onClick={HandleBackLoginScreen}>
                <IconButton color="primary">
                  <ArrowBackIcon />
                </IconButton>
              </div>
            </Box>
            <Box pl={1} ml={1} mt={5}>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                <Resource tag={"PageLogin::ResetPassword_Header"} />
              </Typography>
            </Box>
            <Box pl={1} ml={1}>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                <Resource tag={"PageLogin::ResetPassword_HelpMessage"} />
              </Typography>
            </Box>
            <Box className={classes.containerInputs}>
              <Box ml={2} mt={5}>
                <FormControl className={classes.textField}>
                  <InputLabel htmlFor="RecoverFieldEmail">
                    <Resource tag={"PageLogin::ChangeEmail_Email"} />
                  </InputLabel>
                  <Input
                    id="RecoverFieldEmail"
                    type={"email"}
                    value={email}
                    error={emailError}
                    onChange={handleEmailChange}
                  />
                  {!emailVerifying ? "" : <LinearProgress />}
                </FormControl>
              </Box>

              <Box pl={1} ml={1} mt={2} mb={2}>
                <Typography variant="body2" color="textSecondary">
                  <Resource tag={"PageLogin::Captcha_Protected"} />
                  <Links
                    href={"https://policies.google.com/privacy"}
                    target="blank"
                  >
                    {" "}
                    <Resource tag={"PageLogin::Captcha_Privacy"} />{" "}
                  </Links>{" "}
                  <Resource tag={"PageLogin::Captcha_And"} />
                  <Links
                    href={"https://policies.google.com/terms"}
                    target="blank"
                  >
                    {" "}
                    <Resource tag={"PageLogin::Captcha_Terms"} />{" "}
                  </Links>{" "}
                  <Resource tag={"PageLogin::Captcha_Apply"} />
                </Typography>
              </Box>
              {msgError && (
                <Box pl={1} ml={1} mt={2}>
                  <Typography color="error">{msgError}</Typography>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="row-reverse" mt={10}>
              <Box>
                <Button
                  type="submit"
                  variant={!loadingSubmit ? "contained" : "outlined"}
                  color="primary"
                  className="btn-login"
                  disabled={email.length && !emailError ? false : true}
                  endIcon={
                    !loadingSubmit ? (
                      <ArrowForward className="icon icon-arrow" />
                    ) : (
                      <CircularProgress size={22} className="icon" />
                    )
                  }
                >
                  <Resource tag={"PageLogin::ResetPassword_ButtonSendCode"} />
                </Button>
              </Box>
            </Box>
          </form>
        </Slide>
      )}

      {isEmailValidated && !isCodeValidated && (
        <Slide
          direction="left"
          in={isEmailValidated && !isCodeValidated}
          mountOnEnter
          unmountOnExit
        >
          <form name="form" onSubmit={handleRecoverCodeSubmit}>
            <Box display="flex" flexDirection="row">
              <div onClick={HandleBackRecoverScreen}>
                <IconButton color="primary">
                  <ArrowBackIcon />
                </IconButton>
              </div>
            </Box>
            <Box pl={1} ml={1} mt={5}>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                <Resource tag={"PageLogin::ResetPassword_Header"} />
              </Typography>
            </Box>
            <Box pl={1} ml={1}>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                <Resource tag={"PageLogin::ResetPassword_HelpMessageCode"} />
              </Typography>
            </Box>
            <Box>
              <Box className={classes.containerInputs}>
                <Box ml={2} mt={5}>
                  <div className={classes.verificationCode}>
                    <Input
                      error={recoverCodeError}
                      autoFocus
                      type="text"
                      inputRef={codeInputOneRef}
                      id="codeOne"
                      onClick={() => {
                        setCodeNumberOne("");
                      }}
                      onChange={handleCodeOneForm}
                      className={classes.verificationCodeInput}
                      value={codeNumberOne.toUpperCase()}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                    />
                    <Input
                      error={recoverCodeError}
                      inputRef={codeInputTwoRef}
                      id="codeTwo"
                      onClick={() => {
                        setCodeNumberTwo("");
                      }}
                      onChange={handleCodeTwoForm}
                      className={classes.verificationCodeInput}
                      value={codeNumberTwo.toUpperCase()}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                    />
                    <Input
                      error={recoverCodeError}
                      inputRef={codeInputThreeRef}
                      id="codeThree"
                      onClick={() => {
                        setCodeNumberThree("");
                      }}
                      onChange={handleCodeThreeForm}
                      className={classes.verificationCodeInput}
                      value={codeNumberThree.toUpperCase()}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                    />
                    <Input
                      error={recoverCodeError}
                      inputRef={codeInputFourRef}
                      id="codeFour"
                      onClick={() => {
                        setCodeNumberFour("");
                      }}
                      onChange={handleCodeFourForm}
                      className={classes.verificationCodeInput}
                      value={codeNumberFour.toUpperCase()}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                    />
                    <Input
                      error={recoverCodeError}
                      inputRef={codeInputFiveRef}
                      id="codeFive"
                      onClick={() => {
                        setCodeNumberFive("");
                      }}
                      onChange={handleCodeFiveForm}
                      className={classes.verificationCodeInput}
                      value={codeNumberFive.toUpperCase()}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                    />
                    <Input
                      error={recoverCodeError}
                      inputRef={codeInputSixRef}
                      id="codeSix"
                      onClick={() => {
                        setCodeNumberSix("");
                      }}
                      onChange={handleCodeSixForm}
                      className={classes.verificationCodeInput}
                      value={codeNumberSix.toUpperCase()}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                    />
                  </div>
                </Box>
                {msgError && (
                  <Box pl={1} ml={2} mt={2}>
                    <Typography color="error">{msgError}</Typography>
                  </Box>
                )}
              </Box>

              <Box display="flex" flexDirection="row-reverse" mt={8}>
                <Box>
                  <Button
                    type="submit"
                    variant={!loadingSubmit ? "contained" : "outlined"}
                    color="primary"
                    className="btn-login"
                    disabled={
                      codeNumberOne === "" ||
                      codeNumberTwo === "" ||
                      codeNumberThree === "" ||
                      codeNumberFour === "" ||
                      codeNumberFive === "" ||
                      codeNumberSix === ""
                    }
                    endIcon={
                      !loadingSubmit ? (
                        <ArrowForward className="icon icon-arrow" />
                      ) : (
                        <CircularProgress size={22} className="icon" />
                      )
                    }
                  >
                    <Resource
                      tag={"PageLogin::ResetPassword_ButtonDefinePassword"}
                    />
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Slide>
      )}
      {isCodeValidated && (
        <Slide direction="left" in={isCodeValidated} mountOnEnter unmountOnExit>
          <form name="form" onSubmit={handleRecoverPasswordSubmit}>
            <Box display="flex" flexDirection="row">
              <div onClick={HandleBackRecoverCodeScreen}>
                <IconButton color="primary">
                  <ArrowBackIcon />
                </IconButton>
              </div>
            </Box>
            <Box pl={1} ml={1} mt={5}>
              <Typography variant="h4" color="textPrimary" gutterBottom>
                <Resource tag={"PageLogin::ResetPassword_Header"} />
              </Typography>
            </Box>
            <Box pl={1} ml={1}>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                <Resource
                  tag={"PageLogin::ResetPassword_HelpMessageNewPassword"}
                />
              </Typography>
            </Box>
            <Box className={classes.containerInputs}>
              <Box ml={2} mt={5}>
                <Box>
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="RecoverFieldPassword">
                      <Resource tag={"PageLogin::ResetPassword_NewPassword"} />
                    </InputLabel>
                    <Input
                      id="RecoverFieldPassword"
                      type={showPassword1 ? "text" : "password"}
                      value={password}
                      error={passwordUpdateError}
                      onChange={handlePasswordChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // aria-label="toggle password visibility"
                            onClick={handleClickShowPassword1}
                          >
                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
                <Box mt={5}>
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="standard-repeat-password">
                      <Resource
                        tag={"PageLogin::ResetPassword_NewPasswordRepeat"}
                      />
                    </InputLabel>
                    <Input
                      id="standard-repeat-password"
                      type={showPassword2 ? "text" : "password"}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      error={confirmPasswordError || passwordUpdateError}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {!emailVerifying ? "" : <LinearProgress />}
                  </FormControl>
                </Box>
              </Box>
              {msgError && (
                <Box pl={1} ml={2} mt={2}>
                  <Typography color="error">{msgError}</Typography>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="row-reverse" mt={10}>
              <Box>
                <Button
                  type="submit"
                  variant={!loadingSubmit ? "contained" : "outlined"}
                  color="primary"
                  className="btn-login"
                  disabled={
                    confirmPasswordError ||
                    password === "" ||
                    confirmPassword === ""
                  }
                  endIcon={
                    !loadingSubmit ? (
                      <ArrowForward className="icon icon-arrow" />
                    ) : (
                      <CircularProgress size={22} className="icon" />
                    )
                  }
                >
                  <Resource
                    tag={"PageLogin::ResetPassword_ButtonChangePassword"}
                  />
                </Button>
              </Box>
            </Box>
          </form>
        </Slide>
      )}
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, IAuthLoginAction>
): IProps => {
  return {
    doLogin: (tenantId: number, email: string, password: string) =>
      dispatch(doLogin(tenantId, email, password)),
    getTenantConfigurations: (email: string) =>
      dispatch(getTenantConfigurations(email)),
    updatePassword: (options: object) => dispatch(updatePassword(options)),
    recoverPassword: (options: object) => dispatch(recoverPassword(options)),
    validateRecoverCode: (options: object) =>
      dispatch(validateRecoverCode(options)),
    setActiveTenant: (activeTenant: ITenant) =>
      dispatch(setActiveTenant(activeTenant)),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    activeTenant: store.tenantState.activeTenant,
    cultureState: store.cultureState,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Recover);
