import { api_v3 } from "config/api";
import { random } from "utils/random";
import { toast } from "react-toastify";
import XDialog from "common/X/XDialog";
import { Skeleton } from "@material-ui/lab";
import { treatError } from "utils/treatError";
import React, { useState, useEffect, useCallback, FC, Fragment } from "react";
import { IAllManageGroups } from "redux/types/manageGroups";
import { ICultureState } from "redux/reducers/cultureReducer";
import Resource, { resource } from "components/Resources/Resource";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
import { IUserDetail, IUserLogs, IUserLogType } from "redux/types/manageUsers";
import {
  Typography,
  Grid,
  Button,
  Theme,
  makeStyles,
  createStyles,
  Avatar,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
  Input,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";
import { format } from 'date-fns';
import { Check, Close, Visibility, VisibilityOff } from "@material-ui/icons";
import { RegexPassword, RegexCaracter, RegexEight, RegexLower, RegexNumber, RegexUpper } from "utils/regex";


interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  userId: string;
  culture: ICultureState;
  workspaceId: string;
  userDetails: IUserDetail;
  allGroups: IAllManageGroups[];
  countries: ICountries[];
  departments: IDepartments[];
  userDetailStatus: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleGroup: {
      color: theme.palette.text.secondary,
      fontSize: "0.8rem",
      paddingBottom: "10px",
      textTransform: "uppercase",
    },
    textInfo: {
      fontSize: "0.9rem",
    },
    label: {
      width: "220px",
      padding: "3px 0",
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
      },
    },
    dividerRadio: {
      width: "100%",
      background:
        "linear-gradient(90deg, rgba(242,242,242,0.3617822128851541) 0%, rgba(242,242,242,1) 29%, rgba(242,242,242,1) 66%, rgba(242,242,242,0.32816876750700286) 100%)",
    },
    dividerCheckbox: {
      width: "90%",
      background:
        "linear-gradient(90deg, rgba(242,242,242,0.3617822128851541) 0%, rgba(242,242,242,1) 29%, rgba(242,242,242,1) 66%, rgba(242,242,242,0.32816876750700286) 100%)",
    },
    textUppercase: {
      textTransform: "uppercase",
    },
    widthBtn: {
      "& .MuiSvgIcon-root": {
        width: "0.8em",
        height: "0.8em",
      },
    },
  })
);

function DialogUserDetails(props: IProps) {
  const {
    open,
    setOpen,
    culture,
    workspaceId,
    userDetails,
    allGroups,
    userId,
    userDetailStatus,
    departments,
    countries
  } = props;
  const classes = useStyles();

  //Checkbox Group
  const [values, setValues] = useState<string[]>([]);

  //Radio Group
  const [valueRadio, setValueRadio] = useState('');

  const [newDepartment, setNewDepartment] = useState("");
  const [newCountry, setNewCountry] = useState("");
  useEffect(() => {
    open &&
      setValues(userDetails.groups);
    setValueRadio(userDetails?.permission?.toString())
    setNewDepartment(userDetails.department)
    setNewCountry(userDetails.country)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRadio((event.target as HTMLInputElement).value);
  };

  const handleChangeCheckbox = (id: string) => {
    values?.map((x: any) => x).includes(id)
      ? setValues(values.filter((y: any) => y !== id))
      : setValues([...values!, id])
  };

  const handleSaveChanges = async () => {
    const options = {
      groups: values,
      permission: Number(valueRadio),
      workspaceId,
      departmentId:newDepartment,
      countryId: newCountry
    };
    await api_v3.put(`users/${userId}`, options).then(
      () => {
        setOpen(false);
        toast.success(
          resource(culture?.culture.cultureTag, "PageFilterIdea::DetailedChallenge_ButtonDone")
        );
      },
      (err) => {
        treatError(culture?.culture.cultureTag, "PageManageUsers::Header", err);
      }
    );
  };

  //Dialog Buttons
  const actions = (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={2}>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          <Resource tag="ManageGroups::Cancel" />
        </Button>
      </Box>
      <Box>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => handleSaveChanges()}
        >
          <Resource tag="ManageGroups::SaveChanges" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <XDialog
      open={open}
      setOpen={setOpen}
      maxWidth="md"
      title={<Resource tag="ManageUsers::UserDetails" />}
      actions={actions}
    >
      <Grid container className="user-details-container">
        <Grid
          container
          item
          xs={6}
          sm={4}
          md={3}
          lg={3}
          className="user-informations"
          direction="column"
          spacing={3}
        >
          <Grid item>
            {userDetailStatus === "LOADING" ? (
              <Skeleton variant="circle" height={82} width={82} />
            ) : (
              <Avatar
                alt={userDetails?.userName}
                src={userDetails?.photo}
                style={{ width: "82px", height: "82px" }}
              />
            )}
          </Grid>
          <Grid item>
            {userDetailStatus === "LOADING" ? (
              <>
                <Skeleton variant="text" height={10} width={50} />
                <Skeleton variant="text" width={100} />
              </>
            ) : (
              <>
                <Box fontSize="1.1rem">{userDetails?.fullName}</Box>
                <Typography className={classes.textInfo} color="textSecondary">
                  {userDetails?.email}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {userDetailStatus === "LOADING" ? (
              <>
                <Skeleton variant="text" height={10} width={50} />
                <Skeleton variant="text" width={100} />
              </>
            ) : (
              <>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.textUppercase}
                >
                  <Resource tag="ManageUsers::AvailablePoints" />
                </Typography>
                <Typography className={classes.textInfo}>
                  {userDetails?.availablePoints} points
                </Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {userDetailStatus === "LOADING" ? (
              <>
                <Skeleton variant="text" height={10} width={50} />
                <Skeleton variant="text" width={100} />
              </>
            ) : (
              <>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.textUppercase}
                >
                  <Resource tag="ManageUsers::PhoneNumber" />
                </Typography>
                <Typography className={classes.textInfo}>
                  {userDetails?.phone}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {userDetailStatus === "LOADING" ? (
              <>
                <Skeleton variant="text" height={10} width={50} />
                <Skeleton variant="text" width={100} />
              </>
            ) : (
              <>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.textUppercase}
                >
                  <Resource tag="ManageUsers::Genre" />
                </Typography>
                <Typography className={classes.textInfo}>
                  {userDetails?.genre}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item>
            {userDetailStatus === "LOADING" ? (
              <>
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
              </>
            ) : (
              <>
                <Box display={"flex"} gridGap={"15px"} flexDirection={"column"}>
                  <ResetPassword workspaceId={workspaceId} culture={culture} userId={userId} email={userDetails.email} />
                  <AlterPassword workspaceId={workspaceId} culture={culture} userId={userId} email={userDetails.email} />
                  <ActiviyRegister workspaceId={workspaceId} userId={userId} />
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          lg={1}
          className="divider"
          style={{ maxWidth: "4%" }}
        >
          <Divider orientation="vertical" />
        </Grid>
        <Grid
          item
          container
          xs={5}
          sm={7}
          md={8}
          lg={8}
          direction="column"
          spacing={4}
        >
          <Grid item>
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.textUppercase}
            >
              <Resource tag="ManageUsers::Department" />
            </Typography>
            <Select
              defaultValue={"all"}
              style={{ marginTop: "16px" }}
              name="department"
              labelId={""}
              id="department"
              value={newDepartment}
              fullWidth
              onChange={(e: any) => {
                setNewDepartment(e.target.value);
              }}
            >
              {departments?.map(department => {
                return (
                  <MenuItem value={department.departmentId}>
                    {department.title}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
          <Grid item >
            <Typography
              variant="caption"
              color="textSecondary"
              className={classes.textUppercase}
            >
              <Resource tag="ManageUsers::Country" />
            </Typography>
            <Select
              defaultValue={"all"}
              style={{ marginTop: "16px" }}
              name="department"
              labelId={""}
              id="department"
              value={newCountry}
              fullWidth
              onChange={(e: any) => {
                setNewCountry(e.target.value);
              }}
            >
              {countries?.map(country => {
                return (
                  <MenuItem value={country.countryId}>
                    {country.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
          <Grid item className="radio-group">
            <FormControl component="fieldset" className={classes.widthBtn}>
              {userDetailStatus === "LOADING" ? (
                <>
                  <Skeleton variant="text" width={50} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={150} />
                </>
              ) : (
                <>
                  <FormLabel component="legend" className={classes.titleGroup}>
                    <Resource tag="ManageUsers::PermissionsAssigned" />
                  </FormLabel>
                  <RadioGroup
                    // aria-label="permissions-assigned"
                    name="permissions"
                    value={valueRadio}
                    onChange={handleChangeRadio}
                  >
                    <FormControlLabel
                      value={"3"}
                      control={<Radio color="primary" />}
                      label={<Resource tag="ManageUsers::Participant" />}
                      className={classes.label}
                    />
                    <Divider className={classes.dividerRadio} />
                    <FormControlLabel
                      value={"2"}
                      control={<Radio color="primary" />}
                      label={<Resource tag="ManageUsers::ChallengeManager" />}
                      className={classes.label}
                    />
                    <Divider className={classes.dividerRadio} />
                    <FormControlLabel
                      value={"1"}
                      control={<Radio color="primary" />}
                      label={<Resource tag="ManageUsers::Admin" />}
                      className={classes.label}
                    />
                  </RadioGroup>
                </>
              )}
            </FormControl>
          </Grid>
          <Grid item className="checkbox-group">
            <FormControl component="fieldset" className={classes.widthBtn}>
              {userDetailStatus === "LOADING" ? (
                <>
                  <Skeleton variant="text" width={50} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={150} />
                </>
              ) : (
                <>
                  <FormLabel component="legend" className={classes.titleGroup}>
                    <Resource tag="ManageUsers::GroupsAssigned" />
                  </FormLabel>
                  <FormGroup>
                    <Grid container>
                      {allGroups &&
                        allGroups?.map((list) => {
                          return (
                            <Grid key={random()} item xs={12} sm={6}>
                              <FormControlLabel
                                key={list?.groupId}
                                control={
                                  <Checkbox
                                    key={list?.groupId}
                                    checked={values?.map((x: any) => x).includes(list?.groupId)}
                                    onChange={() => handleChangeCheckbox(list?.groupId)}
                                    name="checked"
                                    color="primary"
                                  />
                                }
                                label={list?.title}
                                className={classes.label}
                              />
                              <Divider className={classes.dividerCheckbox} />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </FormGroup>
                </>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </XDialog>
  );
}

const ResetPassword: FC<{ workspaceId: string, userId: string, email: string, culture: ICultureState }> = ({ workspaceId, userId, email, culture }) => {

  const [open, setOpen] = useState(false);
  const [motive, setMotive] = useState<string>("")
  const [loading, setLoading] = useState(false);

  const handleSaveChanges = useCallback((theMotive: string) => {
    setLoading(true);
    api_v3.post("users/resetpassword", {
      workspaceId,
      userId,
      reason: theMotive,
      email: email
    })
      .then(() => {
        setLoading(false);
        setOpen(false);
        setMotive("");
        toast.success(resource(culture, "ManageUsers::ChangedPassword"));
      })
      .catch((err: any) => {
        setLoading(false);
        treatError(culture?.culture.cultureTag, "PageManageUsers::Header", err);
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, userId, workspaceId])


  //Dialog Buttons
  const actions = (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={2}>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          disabled={loading}
          onClick={() => setOpen(false)}
        >
          <Resource tag="ManageGroups::Cancel" />
        </Button>
      </Box>
      <Box>
        <Button
          color="primary"
          size="small"
          variant="contained"
          disabled={motive.length < 50 || loading}
          onClick={() => handleSaveChanges(motive)}
          endIcon={
            loading && (
              <CircularProgress size={22} className="icon" />
            )
          }
        >
          <Resource tag="ManageUsers::ResetPassword" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <Box onClick={() => { setOpen(true) }}>
        <Typography style={{ fontSize: '16px', fontWeight: "bold", cursor: "pointer" }} color="error">
          <Resource tag="ManageUsers::ResetPassword" />
        </Typography>
      </Box>
      <XDialog
        open={open}
        setOpen={setOpen}
        maxWidth="sm"
        title={<Resource tag="ManageUsers::ResetPassword" />}
        actions={actions}

      >
        <Box mb="15px">
          <Resource tag="ManageUsers::ResetPasswordMotive" />
        </Box>
        <TextField
          id="workspaceName"
          label={
            <Box display="flex" alignItems="center" gridGap="5px">
              <Resource tag={"ManageUsers::Motive"} />
            </Box>
          }
          placeholder={resource(culture, "ManageUsers::ResetPasswordPlaceholder")}
          fullWidth
          value={motive}
          onChange={(e) => setMotive(e.target.value)}
          helperText={
            <Box textAlign={"right"}>
              <Resource tag="ManageUsers::MinimumCharacters" />
            </Box>
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </XDialog>
    </Fragment>
  )
}

const AlterPassword: FC<{ workspaceId: string, userId: string, email: string, culture: ICultureState }> = ({ workspaceId, userId, email, culture }) => {

  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState<string>("")
  const [motive, setMotive] = useState<string>("")
  const [eyeStatus, setEyeStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const handleSaveChanges = useCallback((theMotive: string, password: string) => {
    setLoading(true);
    api_v3.post("users/changePassword", {
      workspaceId,
      userId,
      reason: theMotive,
      password,
      confirmPassword: password
    }).then(() => {
      setOpen(false);
      setPassword("");
      setMotive("");
      setLoading(false);
      toast.success(resource(culture, "ManageUsers::ChangedPassword"));
    })
      .catch((err: any) => {
        treatError(culture?.culture.cultureTag, "PageManageUsers::Header", err);
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, userId, workspaceId])

  const iconValid = {
    false: <Close style={{ fontSize: "12px", color: "red" }} />,
    true: <Check style={{ fontSize: "12px", color: "green" }} />
  }

  //Dialog Buttons
  const actions = (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={2}>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          disabled={loading}
          onClick={() => setOpen(false)}
        >
          <Resource tag="ManageGroups::Cancel" />
        </Button>
      </Box>
      <Box>
        <Button
          color="primary"
          size="small"
          variant="contained"
          disabled={!RegexPassword(password) || motive.length < 50 || loading}
          onClick={() => handleSaveChanges(motive, password)}
          endIcon={
            loading && (
              <CircularProgress size={22} className="icon" />
            )
          }
        >
          <Resource tag="ManageUsers::ChangePassword" />
        </Button>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <Box onClick={() => { setOpen(true) }}>
        <Typography style={{ fontSize: '16px', fontWeight: "bold", cursor: "pointer" }} color="error">
          <Resource tag="ManageUsers::ChangePassword" />
        </Typography>
      </Box>
      <XDialog
        open={open}
        setOpen={setOpen}
        maxWidth="sm"
        title={<Resource tag="ManageUsers::ChangePassword" />}
        actions={actions}

      >
        <Box mb="15px">
          <Resource tag="ManageUsers::ChangePasswordTitle" />
        </Box>
        <Box mb={"15px"}>
          <TextField
            id="workspaceName"
            label={
              <Box display="flex" alignItems="center" gridGap="5px">
                <Resource tag={"ManageUsers::Motive"} />
              </Box>
            }
            placeholder={resource(culture, "ManageUsers::ResetPasswordPlaceholder")}
            fullWidth
            value={motive}
            onChange={(e) => setMotive(e.target.value)}
            helperText={
              <Box textAlign={"right"}>
                <Resource tag="ManageUsers::MinimumCharacters" />
              </Box>
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <FormControl fullWidth>
          <InputLabel
            shrink
            htmlFor="password"
          >
            <Resource tag={"ManageUsers::Password"} />
          </InputLabel>
          <Input
            id="password"
            fullWidth
            type={eyeStatus ? 'text' : 'password'}
            inputProps={{
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off'
              }
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setEyeStatus(!eyeStatus)}
                  edge="end"
                >
                  {eyeStatus ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }

          />
          <Box mt="15px" display={"flex"} flexDirection={"column"} gridGap={"5px"}>
            <Box>{RegexEight(password) ? iconValid.true : iconValid.false} 8 Caracters</Box>
            <Box>{RegexUpper(password) ? iconValid.true : iconValid.false} 1 letra maiúscula</Box>
            <Box>{RegexLower(password) ? iconValid.true : iconValid.false} 1 letra minúscula</Box>
            <Box>{RegexNumber(password) ? iconValid.true : iconValid.false} 1 número</Box>
            <Box>{RegexCaracter(password) ? iconValid.true : iconValid.false} 1 caractere especial (!,@,#,$,etc)</Box>
          </Box>
        </FormControl>
      </XDialog>
    </Fragment>
  )
}

const ActiviyRegister: FC<{ workspaceId: string, userId: string }> = ({ workspaceId, userId }) => {

  const [logs, setLogs] = useState<IUserLogs[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [reasonOpen, setReasonOpen] = useState<any>({});

  const getActivities = useCallback(() => {
    api_v3.get(`users/logactivity?workspaceId=${workspaceId}&userId=${userId}`)
      .then(({ data }) => {

        const logs: IUserLogs[] = data;

        let modalOptions: any = {};

        for (var i = 0; i <= logs.length; i++) {
          modalOptions[i] = false;
        }

        setReasonOpen(modalOptions);
        if(logs)
          setLogs(logs)
      }).catch(e => console.log(e))
  }, [userId, workspaceId])

  useEffect(() => {
    getActivities()
  }, [getActivities])

  return (
    <Fragment>
      <Typography onClick={() => setModalOpen(true)} style={{ fontSize: '16px', fontWeight: "bold", cursor: "pointer" }} color="error">
        <Resource tag="ManageUsers::ActivityRecords" />
      </Typography>
      <XDialog
        open={modalOpen}
        setOpen={setModalOpen}
        maxWidth="md"
        title={<Resource tag="ManageUsers::ActivityRecords" />}
      >
        <Box display={"flex"} flexDirection={"column"} gridGap={"15px"}>
          {logs?.map((log: IUserLogs, index: number) => (
            <Box display="flex" alignItems="center" gridGap="15px" key={index}>
              <Box flex={2} textAlign={"center"}>
                <Typography component={"p"}>{format(new Date(log.date), 'dd/MM/yyyy')}</Typography>
                <Typography component={"p"}>{format(new Date(log.date), 'hh:mm:ss')}</Typography>
              </Box>
              <Box flex={3} display={"flex"} alignItems={"center"} gridGap={"7px"}>
                <Avatar src={log.adminPhoto} />
                <Typography component={"p"}>
                  {log.adminName}
                </Typography>
              </Box>
              <Box flex={2} flexWrap={"wrap"}>
                <Typography component="p">
                  <Resource tag={IUserLogType[log.logType]} />
                </Typography>
              </Box>
              <Box flex={1}>
                {(
                  log.logType === 1 ||
                  log.logType === 2
                ) &&
                  <Fragment>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => setReasonOpen({ [index]: true })}
                    >
                      <Resource tag="ManageUsers::Motive" />
                    </Button>
                    <XDialog
                      open={reasonOpen[index]}
                      setOpen={() => setReasonOpen({ [index]: false })}
                      maxWidth="sm"
                      title={<Resource tag="ManageUsers::Motive" />}
                    >
                      <Typography component="p">
                        {log.reason}
                      </Typography>
                    </XDialog>
                  </Fragment>
                }
              </Box>
            </Box>
          ))}
        </Box>
      </XDialog>
    </Fragment>
  )
}

export default DialogUserDetails;
