import { Reducer } from 'redux';
import {
    RankingsActions,
    RankingActionTypes
} from '../actions/RankingActions';

export interface IRanking {
  meta: {
      currentPage: number,
      from: number,
      lastPage: number,
      perPage: number,
      to: number,
      total: number
  },
  result: IMyRanking[]
}
export interface IMyRanking {
  fullName: string,
  photo: string,
  rankingPosition: string,
  rankingPoints: string,
  resourceYou: string
}
export interface IRankingUsers {
  fullName: string,
  photo: string,
  rankingPosition: number,
  rankingPoints: number,
  resourceYou: string
}

export interface IRankingsState {
  readonly rankings: IRanking;
  readonly myRanking: IMyRanking;
  readonly top3Users: IRankingUsers[];
  readonly usersFound: IRankingUsers[];
  readonly missingPoints: number,
  readonly fullName: string,
  readonly photo: string,
  readonly rankingPosition: number,
  readonly rankingPoints: number,
  readonly resourceYou: string
}

const initialCharacterState: IRankingsState = {
    rankings: {
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0
      },
      result: [],
    },
    myRanking: {
        fullName: "",
        photo: "",
        rankingPosition: "",
        rankingPoints: "",
        resourceYou: "",
    },
    top3Users: [],
    usersFound: [],
    missingPoints: 0,
    fullName: "",
    photo: "",
    rankingPosition: 0,
    rankingPoints: 0,
    resourceYou: ""
}

export const rankingsReducer: Reducer<IRankingsState, RankingsActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case RankingActionTypes.GET_RANKING: {
      return {
        ...state,
        rankings: action.pagination,
        myRanking: {
          fullName: action.myRanking.fullName,
          photo: action.myRanking.photo,
          rankingPoints: action.myRanking.rankingPoints,
          rankingPosition: action.myRanking.rankingPosition,
          resourceYou: action.myRanking.resourceYou,
        }
      };
    }
    case RankingActionTypes.SEARCH_RANKING: {
      return {
        ...state,
        top3Users: action.top3Users,
        usersFound: action.usersFounded,
        missingPoints: action.missingPoints,
        fullName: action.fullName,
        photo: action.photo,
        rankingPosition: action.rankingPosition,
        rankingPoints: action.rankingPoints,
        resourceYou: action.resourceYou
      }
    }
    default:
      return state;
  }
};