import React from "react";
import { Grid, Box } from "@material-ui/core";
import { IRelatedContentResult } from "../../redux/reducers/relatedContent/RelatedContentReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import RelatedContentItem from "components/RelatedContentItem/RelatedContentItem";

interface IProps {
  relatedContent: IRelatedContentResult[];
  relatedStatus: string;
}

function RelatedContentList(props: IProps) {
  const { relatedContent, relatedStatus } = props;

  return (
    <>
      <Grid container spacing={2}>
        {relatedStatus === "SUCCEEDED" ? (
          <>
            <Grid item xs={6}>
              <Box
                gridGap={15}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                {relatedContent &&
                  relatedContent?.map((content, index) => {
                    if (index === 0) {
                      return (
                        <RelatedContentItem key={index} result={content} />
                      );
                    }
                    if (index % 2 === 0) {
                      return (
                        <RelatedContentItem key={index} result={content} />
                      );
                    }
                    return null;
                  })}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                gridGap={15}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                {relatedContent &&
                  relatedContent?.map((content, index) => {
                    if (index === 1) {
                      return (
                        <RelatedContentItem key={index} result={content} />
                      );
                    }
                    if (index % 2 !== 0) {
                      return (
                        <RelatedContentItem key={index} result={content} />
                      );
                    }
                    return null;
                  })}
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={2}
              >
                <CircularProgress />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default RelatedContentList;
