import {
  makeStyles,
  Theme,
  createStyles,
  TableRow,
  TableCell,
  Box,
  Typography,
} from "@material-ui/core";
import KeyTaskIdeaDetailPopUp from "components/KeyTaskIdeaDetailPopUp/KeyTasksIdeaDetailDialog";
import Resource from "components/Resources/Resource";
import React, { useState } from "react";

interface IProps {
  allList: any;
  filterKeyTasksEvaluations: any;
  postIdeasStars?: (options: object) => Promise<any>;
  type?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      borderTop: "solid 1px #ccc",
    },
    allListCell: {
      display: "flex",
      padding: "24px 10px 24px 0px!important",
      width: "100%",
      justifyContent: "space-between",
    },
    truncate: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      "text-overflow": "ellipsis",
      maxWidth: 600,
    },
    uppercase: {
      textTransform: "uppercase",
    },
  })
);

function KeyTaskListItemAll(props: IProps) {
  const { allList, filterKeyTasksEvaluations, postIdeasStars } = props;
  const [type, setType] = useState(props.type);
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  const typeTaskResource = allList?.typeTaskResource?.replace(".", "::");
  const titleAllListResource = allList?.titleResource?.replace(".", "::");
  const timeLimitResource = allList?.limitDateResource?.replace(".", "::");

  const openModal = async (type: number) => {
    switch (type) {
      case 1:
        await setType("evaluation");
        break;
      case 2:
        await setType("validation");
        break;
      case 3:
        await setType("transition");
        break;
      default:
        break;
    }
  };

  const redirectTab = (type: number, status?: string) => {
    filterKeyTasksEvaluations(String(type), status);
  };

  return (
    <>
      <TableRow className={classes.tableRow}>
        {allList && (
          <TableCell className={classes.allListCell}>
            <Box display="flex" flexDirection="column" gridGap={3.5}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.truncate + " " + classes.uppercase}
              >
                <strong>
                  <Resource tag={typeTaskResource} />
                </strong>
              </Typography>
              <Box
                onClick={() =>
                  openModal(allList?.typeTask).then(() => setOpen(true))
                }
                style={{ cursor: "pointer" }}
              >
                <Typography
                  color="textPrimary"
                  variant="body2"
                  className={classes.truncate}
                >
                  <strong>{allList?.title}</strong>
                </Typography>
              </Box>
              {titleAllListResource && (
                <Box
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    redirectTab(allList?.typeTask, allList?.state);
                  }}
                >
                  <Typography
                    className={classes.truncate}
                    color="textPrimary"
                    variant="body2"
                  >
                    <strong>
                      {allList?.totalTasks} &nbsp;
                      <Resource
                        tag={titleAllListResource}
                        args={[allList?.state]}
                      />
                    </strong>
                  </Typography>
                </Box>
              )}
            </Box>
            <Box marginTop={3}>
              {timeLimitResource && (
                <Typography variant="body2" color="textSecondary">
                  {allList?.typeDate !== 2 && (
                    <Resource
                      tag={
                        allList?.typeDate === 1
                          ? "PageKeyTasks::Delay"
                          : allList?.typeDate === 3
                          ? "PageKeyTasks::TimeLeft"
                          : allList?.typeDate === 4
                          ? "PageKeyTasks::Deadline"
                          : ""
                      }
                      args={[""]}
                    />
                  )}
                  &nbsp;
                  <Resource
                    tag={timeLimitResource}
                    args={[allList?.limitDate]}
                  />
                </Typography>
              )}
            </Box>
          </TableCell>
        )}
      </TableRow>
      {isOpen && (
        <KeyTaskIdeaDetailPopUp
          type={type}
          isOpen={isOpen}
          setOpen={setOpen}
          ideaId={allList?.ideaId}
          closeButton={() => setOpen(false)}
          postIdeasStars={postIdeasStars}
        />
      )}
    </>
  );
}

export default KeyTaskListItemAll;
