import React from 'react'
import './Sticky.css'

interface IProps {
    offsetX:string,
    children:any
}

function Sticky(props:IProps){
    return(
        <div style={{top:props.offsetX}} className='sticky-component'>
            {props.children}
        </div>
    )
}

export default Sticky