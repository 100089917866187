import { Reducer } from 'redux';
import {
  ChallengeDetailActions,
  ChallengeDetailActionTypes,
} from '../actions/ChallengeDetailAction'
// Define the Character type
export interface IParticipations {
  UserId: string,
  FullName: string,
  Photo: string,
  UserName: string,
  Participations: number,
  Departament: string,
  Contry: string
}

export interface IWorkflows  {
  phaseId: string,
  title: string,
  phase: number,
  totalIdeas: number,
  endDate: Date
}

export interface IChallengeDetail {
  challengeId: string,
  title: string,
  description: string,
  descriptionHtml: string,
  image: string,
  statusResource: string,
  dateResource: string,
  allowSubmitIdea: boolean,
  timeValue1Resource: string,
  timeValue1: number,
  timeValue2Resource: string,
  timeValue2: number,
  isFollowing: boolean,
  stages: IStages[],
  managers: IManagers[],
  tags: string[],
  workflows: IWorkflows[]
}
// Define the Character type
export interface IIdeaState {
  StateId: string,
  StateName: string,
  CountState: number
}

export interface IStages {
  stageId: string,
  stage: string,
  totalIdeas: number,
  totalIdeasImplemented: number,
  endDate: string
}
// Define the Character type
export interface IManagers {
  userName: string,
  userId: string,
  fullName: string,
  photo: string
}
export interface IDetailIdea {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResultDetailIdea[];
}
export interface IResultDetailIdea {
  challengeId: string,
  ideaId: string,
  title: string,
  description: string,
  isRating: boolean,
  evaluation: {
    evaluationType: string,
    isCollaboration: boolean,
    starsEvaluation: {
      average: number,
      countEvaluations: number,
      evaluationResource: string,
      isAllowedEvaluate: boolean,
      evaluationUser: {
        userId: string,
        lastUpdated: string
      },
      dimensionsTitle: {
        additionalProp1: string,
        additionalProp2: string,
        additionalProp3: string
      }
    },
    likesEvaluation: {
      countEvaluations: number,
      evaluationResource: string,
      evaluationUser: {
        userId: string,
        lastUpdated: string
      },
      isAllowedEvaluate: boolean
    },
    investment: {
      investmentGraph: {
        additionalProp1: number,
        additionalProp2: number,
        additionalProp3: number
      },
      investmentBeliefValue: number,
      investmentBeliefVariation: number,
      investmentValue: number,
      investmentVariation: number,
      investmentDate: string,
      investmentHour: string,
      investmentDateResource: string,
      isIncreaseInvestment: boolean,
      investmentStep: number,
      maximumInvestment: number,
      allowSell: boolean
    }
  },
  isAllowedComment: boolean,
  average: 0
}

// Define the Character type
export interface ITags {
  tagId: string,
  TagName: string
}

export interface IResultDetailChallengeImplementedIdeas {
  archivedROI: number,
  ideaId: string,
  title: string,
  authors: [
    {
      authorId: string,
      name: string,
      photo: string
    }
  ]
}

export interface IResultDetailChallengeIdeasForImplementation {
  excpectedROI: number,
  ideaId: string,
  title: string,
  authors: [
    {
      authorId: string,
      name: string,
      photo: string
    }
  ]
}

export interface IDetailChallengeResult {
  countSubmittedIdeas: number,
  countIdeasForImplementation: number,
  countImplementedIdeas: number,
  expectedROI: 0,
  implementedIdeas: IResultDetailChallengeImplementedIdeas[]
  ideasForImplementation: IResultDetailChallengeIdeasForImplementation[]
}

// Define the Character State
export interface IChallengeDetailState {
  readonly challengeDetail: IChallengeDetail;
  readonly challengeDetailStatus: string;
  readonly ideas: IDetailIdea;
  readonly error: any;
  readonly challengeDetailResults: IDetailChallengeResult;
  readonly status: string;
}

// Define the initial state
const initialCharacterState: IChallengeDetailState = {
  ideas: {
    meta: {
      currentPage: 0,
      from: 0,
      lastPage: 0,
      perPage: 0,
      to: 0,
      total: 0
    },
    result: []
  },
  challengeDetail: {} as IChallengeDetail,
  challengeDetailStatus: '',
  error: '',
  challengeDetailResults: {
    countSubmittedIdeas: 0,
    countIdeasForImplementation: 0,
    countImplementedIdeas: 0,
    expectedROI: 0,
    implementedIdeas: [
      {
        archivedROI: 0,
        ideaId: '',
        title: '',
        authors: [
          {
            authorId: '',
            name: '',
            photo: ''
          }
        ]
      }
    ],
    ideasForImplementation: [
      {
        excpectedROI: 0,
        ideaId: '',
        title: '',
        authors: [
          {
            authorId: '',
            name: '',
            photo: ''
          }
        ]
      }
    ]
  },
  status: ''
};

export const challengeDetailReducer: Reducer<IChallengeDetailState, ChallengeDetailActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_IDEA: {
      return {
        ...state,
        ideas: action.ideas
      };
    }
    case ChallengeDetailActionTypes.GET_MORE_DETAIL_CHALLENGE_IDEA: {
      return {
        ...state,
        ideas: {
          meta:{...action.ideas.meta},
          result:[
            ...state.ideas.result,
            ...action.ideas.result
          ]
        }
      };
    }
    case ChallengeDetailActionTypes.DETAIL_CHALLENGE_LOADING: {
      return {
        ...state,
        challengeDetailStatus: action.challengeDetailStatus
      };
    }
    case ChallengeDetailActionTypes.DETAIL_CHALLENGE_SUCCEEDED: {
      return {
        ...state,
        challengeDetail: action.challengeDetail,
        challengeDetailStatus: action.challengeDetailStatus
      };
    }
    case ChallengeDetailActionTypes.DETAIL_CHALLENGE_FAILED: {
      return {
        ...state,
        error: action.error,
        challengeDetailStatus: action.error
      };
    }
    case ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_RESULT_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_RESULT_SUCCEEDED: {
      return {
        ...state,
        challengeDetailResults: action.challengeDetailResults,
        status: action.status
      };
    }
    case ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_RESULT_FAILED: {
      return {
        ...state,
        status: action.status
      };
    }
    default:
      return state;
  }
};