import { useEffect, useState } from "react";
import "./KeyTaskTransitionBar.scss";
import {
  IFilters,
  IKeyTasksState,
  IKeyTasksSummary,
  ITransition,
} from "../../redux/reducers/keyTasks/keyTasksReducer";
import KeyTaskTransitionList from "../../containers/KeyTaskTransitionList/KeyTaskTransitionList";
import {
  AppBar,
  Box,
  CircularProgress,
  Tab,
  Typography,
} from "@material-ui/core";
import { TabPanel, TabList, TabContext } from "@material-ui/lab";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ICultureState } from "redux/reducers/cultureReducer";
import KeyTasksFilters from "components/KeyTasksFilters/KeyTasksFilters";
import Resource from "components/Resources/Resource";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";
import newApp from "utils/reactNewApp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabText: {
      textTransform: "none",
    },
    tabPanel: {
      width: "100%",
      padding: "8px 0",
    },
  })
);
/** Example of use
 *   <KeyTaskTransitionBar stateId={"111"} state={"Commite"} totalIdeas={1}/>
 */

interface Iprops {
  keyTasksTransitions: ITransition;
  culture?: ICultureState;
  filters?: IFilters;
  secondTab?: any;
  setSecondTab?: any;
  summary: IKeyTasksSummary[];
  transitionKeyTasksStatus: string;
  getTransitionKeyTasks(options: object): Promise<IKeyTasksState>;
}

function KeyTaskTransitionBar(props: Iprops) {
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const {
    keyTasksTransitions,
    culture,
    summary,
    filters,
    getTransitionKeyTasks,
    secondTab,
    setSecondTab,
  } = props;
  useEffect(() => {
    summary && summary.length <= 1 && setSecondTab("1");
    if (summary.length > 0) {
      newApp
        ? getTransitionKeyTasks({
            stateId: summary[0]?.phaseId,
            pageNumber: 1,
            pageSize: 10,
          }).then(() => setLoading(false))
        : getTransitionKeyTasks({
            stateId: summary[0]?.stateId,
            pageNumber: 1,
            pageSize: 10,
          }).then(() => setLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: any, newValue: string) => {
    setSecondTab(newValue);
    setLoading(true);
    newApp
      ? getTransitionKeyTasks({
          stateId: summary[Number(newValue) - 1]?.phaseId,
          pageNumber: 1,
          pageSize: 10,
        }).then(() => setLoading(false))
      : getTransitionKeyTasks({
          stateId: summary[Number(newValue) - 1]?.stateId,
          pageNumber: 1,
          pageSize: 10,
        }).then(() => setLoading(false));
  };

  return (
    <>
      {summary ? (
        <Box marginTop={2} width="100%">
          <TabContext value={secondTab}>
            <AppBar position="static" elevation={0}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                // aria-label="scrollable auto tabs"
              >
                {summary &&
                  summary?.map((tabs, index) => {
                    return (
                      <Tab
                        className={classes.tabText}
                        key={index}
                        label={tabs.state + " (" + tabs.totalIdeas + ")"}
                        value={(index + 1).toString()}
                      />
                    );
                  })}
              </TabList>
            </AppBar>
            {!loading ? (
              <>
                <KeyTasksFilters
                  typeFilter="transition"
                  stateId={
                    summary && newApp
                      ? summary[Number(secondTab) - 1]?.phaseId
                      : summary[Number(secondTab) - 1]?.stateId
                  }
                  isEvaluation={
                    summary
                      ? summary[Number(secondTab) - 1]?.isEvaluation
                      : true
                  }
                  culture={culture!}
                  filters={filters!}
                  getTransitionKeyTasks={getTransitionKeyTasks}
                />
                {summary &&
                  summary?.map((transitions, index) => (
                    <TabPanel
                      className={classes.tabPanel}
                      key={index}
                      value={(index + 1).toString()}
                    >
                      <DynamicInfiniteScroll
                        dataList={keyTasksTransitions}
                        getAction={(pageNumber, pageSize) =>
                          getTransitionKeyTasks({
                            stateId: newApp
                              ? summary[Number(secondTab) - 1]?.phaseId
                              : summary[Number(secondTab) - 1]?.stateId,
                            pageNumber,
                            pageSize,
                            isInfinite: true,
                          })
                        }
                        pageSize={10}
                        isAutomatic
                      >
                        <KeyTaskTransitionList
                          secondTab={secondTab}
                          activeIndex={index + 1}
                          allowReject={transitions?.allowReject}
                          radioButtons={true}
                          transitionList={keyTasksTransitions?.result}
                          culture={culture}
                          isEvaluation={transitions.isEvaluation}
                        />
                      </DynamicInfiniteScroll>
                    </TabPanel>
                  ))}
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                padding={5}
                width="100%"
              >
                <CircularProgress />
              </Box>
            )}
          </TabContext>
        </Box>
      ) : (
        <Box textAlign="center" width="100%" p={5}>
          <Typography color="textSecondary" variant="caption">
            <Resource tag="PageKeyTasks::Error_NoKeyTasksAvailable" />
          </Typography>
        </Box>
      )}
    </>
  );
}

export default KeyTaskTransitionBar;
