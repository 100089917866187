import React from "react";
import ListIcon from "@material-ui/icons/List";
import CreateIcon from "@material-ui/icons/Create";
import SubjectIcon from "@material-ui/icons/Subject";
import Filter7Icon from "@material-ui/icons/Filter7";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";

export const ItemTypes = {
  CARD: "card",
  BUTTON: "button",
  COLLAPSIBLECARD: "collapsibleCard",
};

export const cfIcons: { [key: string]: any } = {
  shortText: <CreateIcon fontSize="small" />,
  longText: <SubjectIcon fontSize="small" />,
  attachment: <AttachFileIcon fontSize="small" />,
  number: <Filter7Icon fontSize="small" />,
  checkmark: <ListIcon fontSize="small" />,
  radio: <RadioButtonCheckedIcon fontSize="small" />,
  dropdown: <ArrowDropDownCircleIcon fontSize="small" />,
};
