import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Resource from "components/Resources/Resource";
import { ArrowForward } from "@material-ui/icons";
import XCardColorTheme from "common/X/XCardColorTheme";
import { ColorPicker } from "material-ui-color";
import {
  getAllWorkspaces,
  getThemesWorkspaces,
  getWorkspace,
  putWorkspacesThemes,
} from "redux/actions/workspaceAction";
import {
  IWorkspaceReducer,
  IWorkspaceState,
  ITheme,
} from "redux/reducers/workspaceReducer";
import { IApplication } from "redux/reducers/tenantReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IAppState } from "redux/store/Store";
import { connect } from "react-redux";
import "./ColorPicker.css";

interface IDispatchProps {
  getAllWorkspace: (options: object) => Promise<IWorkspaceState>;
  getThemesWorkspace: (options: object) => Promise<IWorkspaceState>;
  putWorkspacesTheme: (options: object) => Promise<IWorkspaceState>;
  getWorkspace: (options: object) => Promise<IWorkspaceState>;
}

interface IProps {
  setCurrentScreen: Dispatch<SetStateAction<number>>;
}

interface IStateProps {
  workspaces: IWorkspaceReducer;
  application: IApplication;
  culture: ICultureState;
  themes: ITheme;
  istab?: boolean
}

const options = {
  applicationId: 2,
  culture: {},
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    LookWorkspace_Question: {
      fontSize: "22px",
      textAlign: "left",
      color: "#313B53",
      fontWeight: 700,
    },
    LookWorkspace_Themes: {
      fontSize: "12px",
      textAlign: "left",
      color: "#828282",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    active: {
      boxShadow: "0px 0px 4px rgba(27, 157, 252, 0.5)",
      borderRadius: "7px",
    },
    pageSetupTextColors: {
      fontSize: "12px",
      textAlign: "left",
      fontWeight: 700,
    },
    buttonStep03: {
      display: "flex",
      paddingTop: "180px",
    },
    neon: {
      boxShadow: "0 0 3px 0 " + theme.palette.primary.main,
      borderRadius: "7px",
      padding: "2px",
    },
  })
);

const Palletes = React.memo(function (props: any) {
  const { colorPrimary, colorSecondary, colorTertiary, handleOnChange } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box mt={3}>
            <Typography
              variant="subtitle2"
              className={classes.pageSetupTextColors}
            >
              <Resource tag={"PageSetup::LookWorkspace_PrimaryColor"} />
            </Typography>
          </Box>
          <Box className="colorPicker">
            <ColorPicker
              value={colorPrimary}
              onChange={(e) => handleOnChange("1", e)}
            />
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box mt={3}>
            <Typography
              variant="subtitle2"
              className={classes.pageSetupTextColors}
            >
              <Resource tag={"PageSetup::LookWorkspace_AccentColor"} />
            </Typography>
          </Box>
          <Box className="colorPicker">
            <ColorPicker
              value={colorTertiary}
              onChange={(e) => handleOnChange("3", e)}
            />
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box mt={3}>
            <Typography
              variant="subtitle2"
              className={classes.pageSetupTextColors}
            >
              <Resource tag={"PageSetup::LookWorkspace_SecondaryColor"} />
            </Typography>
          </Box>
          <Box className="colorPicker">
            <ColorPicker
              value={colorSecondary}
              onChange={(e) => handleOnChange("2", e)}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
});

function StepWorkspaceTheme(props: IDispatchProps & IStateProps & IProps) {
  const theme = useTheme();
  const [colorSelected, setColorSelected] = useState(false);
  const [colorPrimary, setColorPrimary] = useState("");
  const [colorSecondary, setColorSecondary] = useState("");
  const [colorTertiary, setColorTertiary] = useState("");
  const [stateIndex, setStateIndex] = useState<number>();
  const {
    getAllWorkspace,
    getThemesWorkspace,
    putWorkspacesTheme,
    getWorkspace,
    workspaces,
    istab
  } = props;
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const classes = useStyles();

  const colorTheme = [
    {
      primaryColor: workspaces?.theme?.primaryColor ?? "#1B9DFC",
      secondaryColor: workspaces?.theme?.secondaryColor ?? "#1B9DFC4D",
      tertiaryColor: workspaces?.theme?.tertiaryColor ?? "#FF5C00",
    },
  ];

  useEffect(() => {
    options.culture = props.culture;

    if (!istab) {
      Promise.all([
        getAllWorkspace(options),
        getThemesWorkspace(options),
      ]).finally(() => setLoading(false));
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      workspaces?.theme?.primaryColor &&
      workspaces?.theme?.secondaryColor &&
      workspaces?.theme?.tertiaryColor
    ) {
      setColorPrimary(workspaces?.theme?.primaryColor);
      setColorSecondary(workspaces?.theme?.secondaryColor);
      setColorTertiary(workspaces?.theme?.tertiaryColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces]);

  function handleClick(color: any) {
    setColorSelected(true);
    setColorPrimary(color?.primaryColor);
    setColorSecondary(color?.secondaryColor);
    setColorTertiary(color?.tertiaryColor);
  }

  function handleOnChange(num: string, newColor: any) {
    if (num === "1") {
      setColorPrimary(`#${newColor?.hex}`);
    } else if (num === "2") {
      setColorSecondary(`#${newColor?.hex}`);
    } else if (num === "3") {
      setColorTertiary(`#${newColor?.hex}`);
    }
  }

  function putThemesWorkspace() {
    setLoadingSubmit(true);
    let params = {};

    if (colorSelected) {
      params = {
        idWorkspace: workspaces?.workspaceId,
        themes: {
          primaryColor: colorPrimary,
          secondaryColor: colorSecondary,
          tertiaryColor: colorTertiary,
        },
        culture: props.culture,
      };

      putWorkspacesTheme(params).then(() => {
        if(!istab){
          getAllWorkspace(options).then(() => {
            props.setCurrentScreen((prev: any) => prev + 1);
          });
        }else{
          const options = {
            applicationId: props.application.applicationId,
            culture: props.culture,
            workspaceId: props.workspaces.workspaceId,
          };
          getWorkspace(options);
        }
        setLoadingSubmit(false);
      });
    }
  }

  return (
    <Container>
      {loading ? (
        <Box display="flex" mt={26} ml={20}>
          <Box>
            <CircularProgress size={50} className="icon" />
          </Box>
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.LookWorkspace_Question}>
              <Resource tag={"PageSetup::LookWorkspace_Question"} />
            </Typography>
          </Grid>
          <Grid container>
            <Box mt={5} mb={2}>
              <Typography
                variant="subtitle2"
                className={classes.LookWorkspace_Themes}
              >
                <Resource tag={"PageSetup::LookWorkspace_Themes"} />
              </Typography>
            </Box>

            <Grid container spacing={2}>
              {colorTheme?.map((color, index) => (
                <Grid
                  item
                  xs={3}
                  key={index}
                  className={index === stateIndex ? classes.neon : ""}
                  onClick={() => {
                    handleClick(color);
                    setStateIndex(index);
                  }}
                >
                  <XCardColorTheme
                    index={index}
                    stateIndex={stateIndex}
                    elevation={5}
                    height={70}
                    color1={color?.primaryColor}
                    color2={color?.secondaryColor}
                    color3={color?.tertiaryColor}
                    selectedColor={colorSelected}
                    stateColor1={colorPrimary}
                    stateColor2={colorSecondary}
                    stateColor3={colorTertiary}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {colorSelected === true ? (
            <Palletes
              colorPrimary={colorPrimary}
              colorSecondary={colorSecondary}
              colorTertiary={colorTertiary}
              handleOnChange={handleOnChange}
            />
          ) : (
            <></>
          )}
          <Box display="flex" mt="56px" width="100%" justifyContent="flex-end">
            <Box>
              <Button
                endIcon={
                  !loadingSubmit ? (
                    <ArrowForward className="icon icon-arrow" />
                  ) : (
                    <CircularProgress
                      size={22}
                      style={{ color: `${theme.palette.common.white}` }}
                    />
                  )
                }
                onClick={() => putThemesWorkspace()}
                disabled={!colorSelected}
                variant="contained"
                color="primary"
              >
                {istab ?
                  <Resource tag="PageSetup::Save" /> :
                  <Resource tag="PageSetup::Continue" />
                }
              </Button>
            </Box>
          </Box>
        </Grid>
      )}
    </Container>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAllWorkspace: (options: object) => dispatch(getAllWorkspaces(options)),
    getWorkspace: (options: object) => dispatch(getWorkspace(options)),
    getThemesWorkspace: (options: object) =>
      dispatch(getThemesWorkspaces(options)),
    putWorkspacesTheme: (params: object) =>
      dispatch(putWorkspacesThemes(params)),
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    application: store.tenantState.application,
    culture: store.cultureState,
    workspaces: store.workSpacesState.workspaces,
    themes: store.workSpacesState.themesWorkspaces,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepWorkspaceTheme);
