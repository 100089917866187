import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "../../redux/store/Store";
import "./ChangeCoAuthor.scss";
import Resource, { resource } from "../Resources/Resource";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getPreviewManagers,
  getActualManagers,
  postChangeManager,
} from "../../redux/actions/ideas/IdeaManagersAction";
import { Button, Avatar, IconButton, Input, InputAdornment, Box } from "@material-ui/core";
import {
  IIdeaManagersState,
  IIdeasManagers,
} from "../../redux/reducers/ideas/IdeaManagersReducer";
import { toast } from "react-toastify";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import Truncate from "react-truncate";
import SearchIcon from "@material-ui/icons/Search"
import MoreIcon from "@material-ui/icons/Add"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 20,
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    btn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },

    actualManagers: {
      p: {
        margin: '10px 0',
      },

      minWidth: '300px',
      width: '20%',
      minHeight: '300px',
  }
  })
);

interface IProps {
  ideaId: string;
  onClose: () => void;
}
export interface IStateProps {
  previewManagers: IIdeasManagers[];
  managers?: IIdeasManagers[];
  applicationId: string | number;
  culture: ICultureState;
  managersStatus: string;
  workspaceId: string;
}
interface IDispatchProps {
  getActualManagers: (options: object) => Promise<IIdeaManagersState>;
  getPreviewManagers: (options: object) => Promise<IIdeaManagersState>;
  postChangeManager: (options: object) => Promise<IIdeaManagersState>;
}

function ChangeCoAuthors(props: IProps & IDispatchProps & IStateProps) {
  const {
    managers,
    getActualManagers,
    getPreviewManagers,
    postChangeManager,
    previewManagers,
    ideaId,
    managersStatus,
    onClose,
  } = props;
  const [managersState, setManagers] = useState(managers);
  const [view, setView] = useState(false);
  const classes = useStyles();
  let list: IIdeasManagers[] | undefined;

  const options = {
    ideaId,
    q: "",
  };
  useEffect(() => {
    async function loadManagers() {
      const useEffectOptions = {
        ideaId,
      };
      await getActualManagers(useEffectOptions);
    }
    loadManagers();
  }, [ideaId, getActualManagers]);

  useEffect(() => {
    setManagers(managers);
  }, [managers]);

  let keyTimer: any;
  let waitTimeAfterType = 500;
  const search = (text?: string) => {
    if (text && text.length > 2) {
      options.q = text;
      getPreviewManagers(options);
      setView(true);
    } else {
      setView(false);
    }
  };
  const container = React.createRef<HTMLDivElement>();
  const handleClickOutside = useCallback(
    (e: any) => {
      if (container.current && !container.current.contains(e.target)) {
        setView(false);
      }
    },
    [container, setView]
  );
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);
  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handlerKeyUp = (e: any) => {
    let text = e.value;
    clearTimeout(keyTimer);
    keyTimer = setTimeout(() => {
      search(text);
    }, waitTimeAfterType);
  };
  const handlerKeydDown = () => {
    clearTimeout(keyTimer);
  };
  const handleAddManager = (user: any) => {
    const { culture } = props;
    let identifier = managersState?.findIndex((x) => x.userId === user.userId);
    if (identifier !== -1) {
      toast.error(resource(culture, "PageKeyTasks::DuplicatedManager"));
    } else {
      list = managersState?.concat([user]);
      setManagers(list);
    }
  };
  const handleRemoveManager = (user: any) => {
    let index = managersState?.findIndex((x) => x.userId === user.userId);
    list = [...managersState!];
    list.splice(index!, 1);
    setManagers(list);
  };
  const postData = (manager: IIdeasManagers[]) => {
    let managersIds = manager?.map((data) => {
      return data.userId;
    });
    const { applicationId, culture, workspaceId } = props;
    const postOptions = {
      managersIds,
      applicationId,
      culture,
      ideaId,
      workspaceId
    };
    postChangeManager(postOptions).then(() => {
      onClose();
    });
  };

  return (
    <>
      {managersStatus !== "LOADING_MANAGERS" ? (
        <>
          <div className="display-flex-change-manager">
            <div>
              <Typography variant="body1">
                <Resource tag="PageKeyTasks::AssignTo" />
              </Typography>
              <div className="change-author-container">
                <input type="hidden" name="idea-change-author" />
                <Box>
                  <Input
                    placeholder={resource(
                      props.culture,
                      "PageKeyTasks::ReassignTask_AddName"
                    )}
                    type="text"
                    id="search-input-manager"
                    className="change-author-input"
                    onKeyUp={(e) => {
                      handlerKeyUp(e.target);
                    }}
                    onKeyDown={handlerKeydDown}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon htmlColor="#333" />
                      </InputAdornment>
                    }
                  />
                </Box>

                {view && (
                  <div className="change-author" ref={container}>
                    {managersStatus !== "LOADING_PREVIEW" ? (
                      <div className="share-change-author">
                        {previewManagers?.length === 0 ? (
                          <Typography variant="subtitle2" color="textSecondary">
                            <Resource tag="PageKeyTasks::NoManagersFound" />
                          </Typography>
                        ) : (
                          previewManagers?.map((user, index) => {
                            return (
                              <div
                                className="share-change-author-content"
                                key={user.userId}
                              >
                                <Avatar
                                  src={user.photo}
                                  alt={user.fullName}
                                  className={classes.thumbnail}
                                />
                                <div className="share-change-author-label">
                                  <Truncate
                                    width={180}
                                    ellipsis={<span>...</span>}
                                  >
                                    {user.fullName}
                                  </Truncate>
                                </div>

                                <div className="share-change-author-add">
                                  <IconButton
                                    className="bottom-share-add"
                                    onClick={() => handleAddManager(user)}
                                    style={{borderRadius:5}}
                                  >
                                    <MoreIcon htmlColor="#333" />
                                  </IconButton>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    ) : (
                      <div className={classes.root}>
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className={classes.actualManagers}>
              <Typography variant="body1" color="textSecondary">
                <Resource tag="PageKeyTasks::CurrentManagers" />
              </Typography>
              {managersState && managersState?.length > 0 ? (
                managersState?.map((actualManagers, index) => {
                  return (
                    <div
                      className="manager-item-container"
                      key={actualManagers.userId}
                    >
                      <Avatar
                        src={actualManagers.photo}
                        alt={actualManagers.fullName}
                        className={classes.thumbnail}
                      />
                      <div className="manager-item-label">
                        <Truncate width={120} ellipsis={<span>...</span>}>
                          {actualManagers.fullName}
                        </Truncate>
                      </div>
                      <div className="manage-item-cross">
                        <IconButton
                          className="bottom-manage-item-cross"
                          onClick={() => handleRemoveManager(actualManagers)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <Typography variant="subtitle2" color="textSecondary">
                    <Resource tag="PageKeyTasks::NoManagerAssigned" />
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div className="post-managers-button">
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => onClose()}
            >
              <Resource tag="PageKeyTasks::Transitions_PopUpButtonCancel" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              onClick={() => postData(managersState!)}
              disabled={managersState?.length === 0 && true}
            >
              {managersStatus !== "CHANGING_MANAGERS" ? (
                <>
                  <Resource tag="PageKeyTasks::ChangeManagers" />
                </>
              ) : (
                <div className={classes.btn}>
                  <Resource tag="PageKeyTasks::ChangeManagers" />
                  <CircularProgress size={14} />
                </div>
              )}
            </Button>
          </div>
        </>
      ) : (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      )}
    </>
  );
}
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getPreviewManagers: (options: object) =>
      dispatch(getPreviewManagers(options)),
    getActualManagers: (options: object) =>
      dispatch(getActualManagers(options)),
    postChangeManager: (options: object) =>
      dispatch(postChangeManager(options)),
  };
};

const mapStateToProps = (store: IAppState, ownProps: any): IStateProps => {
  return {
    managers: store.ideasManagersState.managers,
    managersStatus: store.ideasManagersState.status,
    previewManagers: store.ideasManagersState.previewManagers,
    applicationId: store.authState.auth.applicationId,
    culture: store.cultureState,
    workspaceId: store.authState.auth.workspaceId
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeCoAuthors);
