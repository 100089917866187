import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Grid, Input, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import Resource, { resource } from 'components/Resources/Resource';
import PortfolioCarousel from 'components/PortfolioCarousel/PortfolioCarousel';
import { ThunkDispatch } from 'redux-thunk';
import { getAllChallengesInspiration, getNewChallengeList, IChallengeGetNewListAction } from 'redux/actions/ChallengeActions';
import { IChallengeAllList } from 'redux/types/challenges';
import { IApplication } from 'redux/reducers/tenantReducer';
import { ICultureState } from 'redux/reducers/cultureReducer';
import { IAllManageGroups } from 'redux/types/manageGroups';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IAppState } from 'redux/store/Store';
import { api_v3 } from 'config/api';
import Chart from "react-apexcharts";
import {
  Chart as ChartLine,
  Grid as GridChart,
  ArgumentAxis as ArgumentAxisLine,
  Legend as LegendLine,
  Series as SeriesLine,
  CommonSeriesSettings,
  CommonAxisSettings,
  Export,
  Margin,
  Tooltip,
  Label,
  Format
} from 'devextreme-react/chart';
import XDatepicker from 'common/UI/XDatepicker/XDatepicker';
import { ICountry } from 'redux/reducers/countryReducer';
import {getAllChallenges, getAllCountries, getAllGroups} from "../../../redux/actions/DashboardActions";
import ImageChart from 'components/ImageChart/ImageChart';

export const architectureSources = [
  { value: 'visitors', name: 'PageDashboardEngagement::Visitors' },
  { value: 'participants', name: 'PageDashboardEngagement::NrParticipants' },
];

export const architectureSources2 = [
  { value: 'nroIdeas', name: 'PageDashboardEngagement::NrIdeas' },
  // { value: 'nroInsights', name: 'PageDashboardEngagement::NrInsights' },
  { value: 'nroComments', name: 'PageDashboardEngagement::NrComments' },
  { value: 'nroComments', name: 'PageDashboardEngagement::NrEvaluations' },
];


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: `${theme.palette.secondary.contrastText}`,
      borderRadius: 8,
      padding: 30,
      "& hr": {
        backgroundColor: `${theme.palette.primary.dark}`,
        maxWidth: "50%",
        height: 1,
        border: "none",
        marginTop: 30,
        marginBottom: 20
      }
    },
    card2: {
      backgroundColor: `${theme.palette.secondary.contrastText}`,
      borderRadius: 8,
      padding: 10,
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 60,
      "& > div": {
        padding: 20,
      },
      "& > div:nth-last-child(-n+3)": {
        borderLeft: "solid 1px"
      },
      "& hr": {
        backgroundColor: `${theme.palette.primary.dark}`,
        maxWidth: "50%",
        height: 1,
        border: "none",
        marginTop: 30,
        marginBottom: 20
      }
    },
    container: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 11,
      padding: 16
    },
    label: {
      textTransform: "uppercase",
      color: theme.palette.grey[600],
      fontSize: 13
    },
    labelChart: {
      display: "flex",
      alignItems: "center",
      "& ~ div": {
        display: "none"
      }
    },
    textColor: {
      color: `${theme.palette.primary.dark}`,
    },
    chart: {
      paddingRight: 30,
      "& #center-container": {
        flexDirection: "column!important",
        "& ul": {
          display: "flex",
          width: "fit-content",
          margin: "0 auto"
        }
      }
    },
    userBacklog: {
      display: "flex",
      alignItems: "center",
      "&:nth-child(even)": {
        marginTop: 15,
        marginBottom: 15
      }
    },
    nameBacklog: {
      "& span": {
        paddingRight: 5,
        marginRight: 5,
        borderRight: "solid 2px"
      }
    },
    photoBacklog: {
      width: 40,
      height: 40,
      borderRadius: "50%",
      backgroundSize: "cover",
      backgroundPosition: "center"
    },
    numberBacklog: {
      padding: 10
    },
    blockBacklog: {
      borderRight: "solid 1px #c1c1c1"
    },
    headerBacklog: {
      marginBottom: 30
    },
    labelPier: {
      textAlign: 'center',
      fontSize: 15
    }
  })
);

interface IDispatchProps {
  getNewChallengeList: (options: object) => Promise<IChallengeGetNewListAction>;
  getAllChallengesInspiration: (options: object) => Promise<any>;
}

interface IProps {
  closeButton?: any;
  selectedChallenge?: string;
  challengeList: IChallengeAllList[];
  application: IApplication;
  culture: ICultureState;
  allGroups: IAllManageGroups[];
  countries: ICountry[];
  setChallengeId?: (challenge: any) => void;
}

interface Parametros {
  workspaceId: string;
}

const guid = "00000000-0000-0000-0000-000000000000";

const Engagement = (props: IProps & IDispatchProps & Parametros) => {

  const classes = useStyles();
  const { workspaceId } = props;
  const [challengeList, setChallengeList] = useState<IChallengeAllList[]>([]);
  const [allGroups, setAllGroups] = useState<IAllManageGroups[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [options, setOptions] = useState<{
    challenge: string[],
    groups: string[],
    countries: string[]
  }>({
    challenge: [guid],
    groups: [guid],
    countries: [guid]
  });

  useEffect(() => {
    getAllChallenges({workspaceId: workspaceId, status: 0 }).then((res) => {
      if (res) {
        setChallengeList(res!);
      }
    });
    getAllGroups({workspaceId: workspaceId }).then((res) => {
      if (res) {
        setAllGroups(res!);
      }
    });
    getAllCountries({workspaceId: workspaceId }).then((res) => {
      if (res) {
        setCountries(res!);
      }
    });
  }, []);

  const [date, setDate] = useState({
    initial: new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)).toISOString(),
    final: new Date().toISOString()
  });
  const [data, setData] = useState({
    commentsPerActiveUser: 0,
    evaluationsPerActiveUser: 0,
    evalutionsOfActivityReturnLists: [],
    ideasPerActiveUser: 0,
    numberVisitsParticipantsReturnLists: [],
    participants: 1,
    participationsPerUser: 0,
    visitors: 0,
    totalActiveUsers: 0
  });

  const [options2] = useState({
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5,
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px'
          }
        }
      }
    },

  });

  const handleSelect = (e: any, arr: any, property: string, stateOption: string[]) => {
    if (stateOption.includes(guid) && !e.target.value.includes(guid)) {
      setOptions({ ...options, [e.target.name]: [] })
    }
    else if (
      e.target.value.includes(guid) &&
      !stateOption.includes(guid)
    ) {
      let items: string[] = [guid];
      arr.forEach((element: any) => {
        items.push(element[property])
      });
      setOptions({ ...options, [e.target.name]: items })
    } else if (
      e.target.value.includes(guid) &&
      stateOption.includes(guid) &&
      e.target.value.length <= (arr.length + 1)
    ) {
      const idx = e.target.value.indexOf(guid);
      e.target.value.splice(idx, 1);
      setOptions({ ...options, [e.target.name]: e.target.value })
    } else {
      setOptions({ ...options, [e.target.name]: e.target.value })
    }
  }


  const send = (workspaceId: any) => {
    api_v3.post("dashboards/engagement", {
      workspaceId: workspaceId,
      Challenges: options.challenge,
      Countries: options.countries,
      Groups: options.groups,
      TimePeriodInitial: date.initial,
      TimePeriodFinal: date.final
    })
      .then(r => {
        setData(r.data);
      })
      .catch(err => { console.log(err) })
  }
  let calcParticipant = ((data?.participants * 100) / (data?.participants + data?.visitors)) 
  let calcVisitor = ((data?.visitors * 100) / (data?.participants + data?.visitors)) 
  const apply = () => {
    send(props.workspaceId);
  }

  useEffect(() => {
    send(workspaceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.container}
        >
          <Grid
            container
            alignItems="flex-end"
            spacing={3}
          >
            <Grid item xs={12} sm={4}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageAnalytics::Challenge"}
                />
              </Typography>
              <Select
                labelId="checkbox-challenges-label"
                id="checkbox-challenges"
                name="challenge"
                multiple
                fullWidth
                onChange={(e) => handleSelect(e, challengeList, "challengeId", options.challenge)}
                value={options.challenge}
                input={<Input />}
                renderValue={(selected) => {
                  let names: string[] = [];
                  if (options.challenge.indexOf(guid) > -1) {
                    names.push(resource(props.culture, "PageAnalytics::All"))
                  } else {
                    (selected as string[]).forEach(element => {
                      if (element === guid) {
                        names.unshift(resource(props.culture, "PageAnalytics::All"))
                      } else if (challengeList?.filter((val) => val.challengeId === element)[0]) {
                        names.push(challengeList?.filter((val) => val.challengeId === element)[0].title)
                      }
                    });
                  }
                  return (names as string[]).join(', ')
                }}
              >
                <MenuItem value={guid}>
                  <Checkbox checked={options.challenge.indexOf(guid) > -1} />
                  <Resource tag="PageAnalytics::All" />
                </MenuItem>
                {challengeList?.map((list: any, index: number) => (
                  <MenuItem key={index} value={list.challengeId}>
                    <Checkbox checked={options.challenge.indexOf(list.challengeId) > -1 || options.challenge.indexOf(guid) > -1} />
                    <ListItemText primary={list.title} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageSettings::group"}
                />
              </Typography>
              <Select
                labelId="checkbox-groups-label"
                id="checkbox-groups"
                name="groups"
                multiple
                fullWidth
                onChange={(e) => handleSelect(e, allGroups, "groupId", options.groups)}
                value={options.groups}
                input={<Input />}
                renderValue={(selected) => {
                  let names: string[] = [];
                  if (options.groups.indexOf(guid) > -1) {
                    names.push(resource(props.culture, "PageAnalytics::All"))
                  } else {
                    (selected as string[]).forEach(element => {
                      if (element === guid) {
                        names.unshift(resource(props.culture, "PageAnalytics::All"))
                      } else if (allGroups?.filter((val) => val.groupId === element)[0]) {
                        names.push(allGroups?.filter((val) => val.groupId === element)[0].title)
                      }
                    });
                  }
                  return (names as string[]).join(', ')
                }}
              >
                <MenuItem value={guid}>
                  <Checkbox checked={options.groups.indexOf(guid) > -1} />
                  <Resource tag="PageAnalytics::All" />
                </MenuItem>
                {allGroups?.map((list: any, index: number) => (
                  <MenuItem key={index} value={list.groupId}>
                    <Checkbox checked={options.groups.indexOf(list.groupId) > -1 || options.groups.indexOf(guid) > -1} />
                    <ListItemText primary={list.title} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.label} >
                <Resource
                  tag={"PageSettings::Country"}
                />
              </Typography>
              <Select
                labelId="checkbox-country-label"
                id="checkbox-countri"
                name="countries"
                multiple
                fullWidth
                onChange={(e) => handleSelect(e, countries, "countryId", options.countries)}
                value={options.countries}
                input={<Input />}
                renderValue={(selected) => {
                  let names: string[] = [];
                  if (options.countries.indexOf(guid) > -1) {
                    names.push(resource(props.culture, "PageAnalytics::All"))
                  } else {
                    (selected as string[]).forEach(element => {
                      if (element === guid) {
                        names.unshift(resource(props.culture, "PageAnalytics::All"))
                      } else if (countries?.filter((val) => val.countryId === element)[0]) {
                        names.push(countries?.filter((val) => val.countryId === element)[0].title)
                      }
                    });
                  }
                  return (names as string[]).join(', ')
                }}
              >
                <MenuItem value={guid}>
                  <Checkbox checked={options.countries.indexOf(guid) > -1} />
                  <Resource tag="PageAnalytics::All" />
                </MenuItem>

                {countries?.map((list: any, index: number) => (
                  <MenuItem key={index} value={list.countryId}>
                    <Checkbox checked={options.countries.indexOf(list.countryId) > -1 || options.countries.indexOf(guid) > -1} />
                    <ListItemText primary={list.title} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={5}>
              <Grid container alignContent="center" justify="center" alignItems="center" spacing={3}>
                <Grid item xs={6}>
                  <XDatepicker
                    value={date.initial}
                    label={<Resource tag={"PageAnalytics::Date_initial"} />}
                    onChange={(e: any) => {
                      setDate({ ...date, initial: e })
                    }}
                    fullWidth
                    disablePast={false}
                    keyboardDatePicker={true}
                    allowKeyboardControl={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <XDatepicker
                    label={<Resource tag={"PageAnalytics::Date_final"} />}
                    value={date.final}
                    onChange={(e: any) => {
                      setDate({ ...date, final: e })
                    }}
                    fullWidth
                    disablePast={false}
                    minDate={date.initial}
                    keyboardDatePicker={true}
                    allowKeyboardControl={true}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Box textAlign="right">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={apply}
                  style={{ color: "#ffffff" }}
                >
                  <Resource
                    tag={"PageDashboard::Apply"}
                  />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
          <ImageChart
            culture={props.culture}
            visit={data?.visitors}
            partic={data?.participants}
            visitors={Math.round(calcVisitor)}
            participants={Math.round(calcParticipant)}
          />
        <Grid container>
          <Grid item xs={12}>
            <PortfolioCarousel
              portfolioTag={"PageDashboardEngagement::Metrics"}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.card2}>
              <div>
                <span>
                  <Typography variant="h2">
                    {data?.participationsPerUser ?? 0}
                  </Typography>
                </span>
                <Typography variant="body1">
                  <Resource
                    tag={"PageDashboardEngagement::Participations"}
                  />
                </Typography>
              </div>
              <div>
                <span>
                  <Typography variant="h2">
                    {data?.ideasPerActiveUser ?? 0}
                  </Typography>
                </span>
                <Typography variant="body1">
                  <Resource
                    tag={"PageDashboardEngagement::Ideas"}
                  />
                </Typography>
              </div>
              <div>
                <span>
                  <Typography variant="h2">
                    {data?.commentsPerActiveUser ?? 0}
                  </Typography>
                </span>
                <Typography variant="body1">
                  <Resource
                    tag={"PageDashboardEngagement::Comments"}
                  />
                </Typography>
              </div>
              <div>
                <span>
                  <Typography variant="h2">
                    {data?.evaluationsPerActiveUser ?? 0}
                  </Typography>
                </span>
                <Typography variant="body1">
                  <Resource
                    tag={"PageDashboardEngagement::Evaluations"}
                  />
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <PortfolioCarousel
              portfolioTag={"PageDashboardEngagement::NumberParticipants"}
            />
          </Grid>
          <Grid item xs={12} sm={9} className={classes.chart}>
            <ChartLine
              palette="Violet"
              dataSource={data?.numberVisitsParticipantsReturnLists ?? []}
            >
              <CommonSeriesSettings
                argumentField="day"
                type="spline"
              />
              <CommonAxisSettings>
                <GridChart visible={true} />
              </CommonAxisSettings>
              {
                architectureSources.map(function (item) {
                  return <SeriesLine key={item.value} valueField={item.value} name={resource(props.culture, item.name)} />;
                })
              }
              <Margin bottom={20} />
              <ArgumentAxisLine
                allowDecimals={false}
                axisDivisionFactor={60}
              >
                <Label>
                  <Format type="decimal" />
                </Label>
              </ArgumentAxisLine>
              <LegendLine
                verticalAlignment="bottom"
                horizontalAlignment="center"
              />
              <Export enabled={false} />
              <Tooltip enabled={false} />
            </ChartLine>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className={classes.card}>
              <div>
                <Typography variant="h2">
                  {
                    data?.visitors ?? 0
                  }
                </Typography>
                <Typography variant="body1">
                  <Resource
                    tag={"PageDashboardEngagement::Visitors"}
                  />
                </Typography>
              </div>
              <hr />
              <div>
                <span>
                  <Typography variant="h2">
                    {data?.participants ?? 0}
                  </Typography>
                </span>
                <Typography variant="body1">
                  <Resource
                    tag={"PageDashboardEngagement::Participants"}
                  />
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <PortfolioCarousel
              portfolioTag={"PageDashboardEngagement::EvolutionActivity"}
            />
          </Grid>
          <Grid item xs={12} sm={9} className={classes.chart}>
            <ChartLine
              palette="Violet"
              dataSource={data?.evalutionsOfActivityReturnLists ?? []}
            >
              <CommonSeriesSettings
                argumentField="day"
                type="spline"
              />
              <CommonAxisSettings>
                <GridChart visible={true} />
              </CommonAxisSettings>
              {
                architectureSources2.map(function (item) {
                  return <SeriesLine key={item.value} valueField={item.value} name={
                    resource(props.culture, item.name)
                  } />;
                })
              }
              <Margin bottom={20} />
              <ArgumentAxisLine
                allowDecimals={false}
                axisDivisionFactor={60}
              >
                <Label>
                  <Format type="decimal" />
                </Label>
              </ArgumentAxisLine>
              <LegendLine
                verticalAlignment="bottom"
                horizontalAlignment="center"
              />
              <Export enabled={false} />
              <Tooltip enabled={false} />
            </ChartLine>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className={classes.card}>
              <div>
                <Typography variant="h2">
                  {
                    data?.ideasPerActiveUser ?? 0
                  }
                </Typography>
                <Typography variant="body1">
                  <Resource
                    tag={"PageDashboardEngagement::Ideas"}
                  />
                </Typography>
              </div>
              <hr />
              <div>
                <span>
                  <Typography variant="h2">
                    {
                      data?.commentsPerActiveUser ?? 0
                    }
                  </Typography>
                </span>
                <Typography variant="body1">
                  <Resource
                    tag={"PageDashboardEngagement::Comments"}
                  />
                </Typography>
              </div>
              <hr />
              <div>
                <span>
                  <Typography variant="h2">
                    {
                      data?.evaluationsPerActiveUser ?? 0
                    }
                  </Typography>
                </span>
                <Typography variant="body1">
                  <Resource
                    tag={"PageDashboardEngagement::Evaluations"}
                  />
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    challengeList: store.challengeState.challengeAllList,
    application: store.tenantState.application,
    culture: store.cultureState,
    allGroups: store.manageGroupsState.allGroups,
    countries: store.countryReducerState.countries
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getNewChallengeList: (parameters: object) =>
      dispatch(getNewChallengeList(parameters)),

    getAllChallengesInspiration: (parameters: object) =>
      dispatch(getAllChallengesInspiration(parameters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Engagement);