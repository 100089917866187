import { makeStyles, Theme, createStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    tableTitles: {
        'margin-top': '5px',
        'margin-bottom': '5px',
        'margin-left': '20px',
        'padding-top': '10px',
        'color':' #767676',
        'font-weight': 900,
        'font-size': '12px',
        'text-transform': 'uppercase'
    }
  }),
);