import React from "react";
import Resource from "../Resources/Resource";
import { IResultPrizePrevious } from "../../redux/reducers/prizeReducer";
import {
  Card,
  Paper,
  CardMedia,
  makeStyles,
  Box,
  Typography,
  useTheme,
  Theme,
  createStyles,
  MenuItem,
} from "@material-ui/core";
import MoreOptionsButton from "components/MoreOptionsButton/MoreOptionsButton";
import newApp from "utils/reactNewApp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      gridGap: "10px",
      width: "100%",
      cursor: 'pointer'
    },
    bidText: {
      display: "flex",
      alignItems: "center",
      padding: "10px 16px",
    },
    currentBid: {
      height: "40px",
      width: "100%",
    },
    cardTitle: {
      fontSize: 20,
      fontWeight: 500,
    },
    media: {
      width: "100%",
      height: "210px",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      backgroundBlendMode: "color",
      borderRadius: "6px",
      color: theme.palette.common.white,
    },
  })
);

interface IProps {
  prize: IResultPrizePrevious;
  onClick: any;
  index: number;
  cultureTag?: string;
  iWon: boolean;
  openGrid?: boolean;
  permission: string;
  requestManagePrize: (prizeId: string, type: number) => void;
}

function PreviousCard(props: IProps) {
  const { prize, index, onClick, iWon, openGrid, permission, requestManagePrize } = props;
  const classes = useStyles();
  const theme = useTheme();

  function handleManagePrize(prizeId: string, type: number) {
    requestManagePrize(prizeId, type)
  }

  return (
    <Box
      onClick={() => onClick(prize, index)}
      className={classes.root}
      style={{ height: openGrid ? "350px" : "250px" }}
    >
      <Card>
        <Box width="100%" style={{ display: "inline-flex" }}>
          <CardMedia
            className={classes.media}
            style={{ backgroundImage: `url(${prize.image})` }}
          >
            <Box
              padding="11px 11px 9px 10px"
              width="100%"
              height="205px"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              textAlign="left"
              position="relative"
              ml="4px"
            >
              {iWon && (
                <Box
                  style={{
                    background: "white",
                    color: theme.palette.primary.main,
                    alignSelf: "flex-start",
                    position: "absolute",
                    top: "16px",
                    padding: 6,
                    borderRadius: 5,
                    fontWeight: 700,
                  }}
                >
                  <Resource tag="PagePrizes::IWon" />
                </Box>
              )}
              {newApp ? (
                <Box
                  style={{
                    position: "absolute",
                    top: "16px",
                    right: '16px',
                    padding: 6,
                  }}
                >
                  {permission === 'Admin' || permission === 'ChallengeManager' ? (
                    <>
                      <MoreOptionsButton
                        translate="-72px, 42px"
                        iconColor={theme.palette.primary.contrastText}
                      >
                        <MenuItem onClick={(e) => {e.stopPropagation();handleManagePrize(prize?.prizeId!, 2)}}>
                          <Resource tag="PagePrizes::Duplicate" />
                        </MenuItem>
                      </MoreOptionsButton>
                    </>
                  ) : '' }
                </Box>
              ) : (
                ""
              )}
              <Typography variant="h4" className={classes.cardTitle}>
                {prize.title}
              </Typography>
              <Box marginTop={0.5}>
                <Resource tag="Common::On" /> {prize.dateClosed}
              </Box>
            </Box>
          </CardMedia>
        </Box>
        <Paper className={classes.currentBid}>
          <Box className={classes.bidText}>
            <Box
              component="span"
              marginRight={0.5}
              fontWeight="fontWeightMedium"
            >
              {prize.winners.length}
            </Box>
            {prize.winners.length === 1 ? (
              <Box component="span" style={{ textTransform: "uppercase" }}>
                <Resource tag={"PagePrizes::Winner"} />
              </Box>
            ) : (
              <Box component="span" style={{ textTransform: "uppercase" }}>
                <Resource tag={"PagePrizes::Winners"} />
              </Box>
            )}
          </Box>
        </Paper>
      </Card>
    </Box>
  );
}

export default PreviousCard;
