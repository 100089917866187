import "./ShortText.scss";
import React, { useState } from "react";
import ToolTip from "../../ToolTip/ToolTip";
import Resource from "components/Resources/Resource";
import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Box,
  TextFieldProps,
  FormControl,
  TextField,
  FormHelperText,
} from "@material-ui/core";

interface IProps {
  value: string;
  handler?: any;
  propsI: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      padding: "15px 0 7px",
    },
    label: {
      fontWeight: "bold",
      fontSize: "0.8rem",
      textTransform: "uppercase",
      color: theme.palette.text.secondary,
    },
    BoxTooltip: {
      display: "flex",
      alignItems: "center",
    },
    titleT: {
      "& .MuiInputLabel-root": {
        fontWeight: 700,
        fontSize: "1.05rem",
        textTransform: "uppercase",
      },
      "& .MuiInputBase-root": {
        fontSize: "0.88rem",
        color: theme.palette.text.primary,
      },
    },
  })
);

function ShortText({
  value,
  handler,
  propsI,
  ...props
}: IProps & TextFieldProps) {
  const classes = useStyles();
  const [text, setText] = useState(
    value ? value : propsI?.defaultValue ? propsI?.defaultValue : ""
  );
  const labelT = (
    <div className="textField-and-tooltip">
      <Typography variant="body1" className={classes.label}>
        {propsI?.title || propsI.cultures?.[0]?.title}
        {propsI?.isMandatory || propsI?.isRequired ? " *" : ""}
      </Typography>
      <Box className={classes.BoxTooltip} ml={1}>
        {propsI?.help || propsI?.description ? (
          <ToolTip title={<Resource tag="Common::Help" />}>
            {propsI?.help || propsI?.description}
          </ToolTip>
        ) : (
          ""
        )}
      </Box>
    </div>
  );

  const textValueChange = (e: any) => {
    setText(e.target.value);
    handler(e.target.value);
  };
  return (
    <div>
      {labelT}
      <FormControl style={{ width: "100%" }}>
        <TextField
          placeholder={propsI?.placeholder}
          id={propsI?.columnName || propsI?.ideaCustomFieldId}
          name={propsI?.columnName || propsI?.ideaCustomFieldId}
          type="text"
          className={classes.titleT}
          value={text}
          onChange={textValueChange}
          onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
          inputProps={{
            pattern: propsI?.validationExpression,
            maxLength: 1000,
          }}
          fullWidth
          {...props}
        />
        <FormHelperText style={{ textAlign: "right" }}>
          <Resource tag="ManageGroups::AvailableCharacters" />:{" "}
          {1000 - text?.length}{" "}
        </FormHelperText>
      </FormControl>
    </div>
  );
}

export default ShortText;
