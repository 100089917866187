import "./RadioButton.css";
import React, { useState, useEffect } from "react";
import ToolTip from "../../ToolTip/ToolTip";
import Resource from "components/Resources/Resource";
import { Typography, Input, Box, RadioGroup, FormControlLabel, Radio, FormControl } from "@material-ui/core";
import reactNewApp from "utils/reactNewApp";

interface IProps {
  value: any;
  handler?: any;
  propsI: any;
  getValueChange?: any;
}

function RadioButton(props: IProps) {
  const { propsI, handler, value: ogValue, getValueChange } = props;

  const [value, setValue] = useState((reactNewApp ? ogValue : propsI?.value) ?? "");

  useEffect(() => {
    handler && handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    getValueChange && getValueChange((event.target as HTMLInputElement).value);
  };

  const optionsKeys = propsI?.options ? (Object.keys(propsI?.options) as Array<any>) : [];
  const optionsValues = propsI?.options ? (Object.values(propsI?.options) as Array<any>) : [];

  return (
    <Box className="checkbox-input-container classRadio">
      <Box className="title-and-tooltip">
        <Typography className="checkbox-input-title" style={{ fontSize: "0.8rem" }} color={"textSecondary"}>
          {propsI?.title || propsI.cultures?.[0]?.title}
          {propsI?.isRequired ? "*" : ""}
        </Typography>
        <Box className="BoxTooltipCheckBox" ml={1}>
          {propsI?.description ? <ToolTip title={<Resource tag="Common::Help" />}>{propsI?.description}</ToolTip> : ""}
        </Box>
      </Box>
      <Box className="checkbox-data">
        <Input
          onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
          type="hidden"
          value={value}
          name={propsI?.ideaCustomFieldId}
        />
        {reactNewApp
          ? propsI?.options &&
            propsI?.options?.map((data: any, index: any) => (
              <FormControl component="fieldset" className="check-container" key={index}>
                <RadioGroup value={value} onChange={handleChange}>
                  <FormControlLabel
                    value={data.ideaCustomFieldOptionId}
                    control={<Radio color="primary" />}
                    label={data?.title || data?.cultures?.[0]?.title}
                  />
                </RadioGroup>
              </FormControl>
            ))
          : propsI?.options &&
            optionsValues?.map((data, index) => (
              <FormControl component="fieldset" className="check-container" key={index}>
                <RadioGroup value={value} onChange={handleChange}>
                  <FormControlLabel
                    value={reactNewApp ? data.ideaCustomFieldOptionId : optionsKeys[index]}
                    control={<Radio color="primary" />}
                    label={reactNewApp ? data?.title || data?.cultures?.[0]?.title : data}
                  />
                </RadioGroup>
              </FormControl>
            ))}
      </Box>
    </Box>
  );
}

export default RadioButton;
