import React from 'react'
import './Terms.css';

interface IProps {
}

function Terms(props: IProps) {
    return (
        <>
        <div style={{marginLeft:'5%', marginRight:'5%'}}>
        <img alt="Exago" src="https://www.exago.com/wp-content/themes/exago-bootstrap/img/logo-exago.png" width='140px'></img>
        <p style={{textAlign: "center"}}>&nbsp;</p>
        <h3 style={{textAlign: "center"}}><strong>PROGRAMA DE INOVAÇÃO</strong></h3>
        <h3 style={{textAlign: "center"}}><strong>TERMOS E CONDIÇÕES</strong></h3>
        <p><br/></p>
        <p><strong>Âmbito e objetivo:</strong></p>
        <p>O presente Regulamento estabelece os termos e condições aplicáveis ao funcionamento do software de gestão de inovação empresarial “Programa de inovação”, uma iniciativa do Grupo Cornélio Bernard para promover um programa de inovação incremental mais dinâmico nas organizações.&nbsp;</p>
        <p>A plataforma estará disponível via internet ou intranet, e os Participantes serão capazes de:</p>
        <ul>
        <li>Submeter ideias relacionadas com os desafios disponíveis;</li>
        <li>Submeter&nbsp;<em>insights</em>&nbsp;relacionadas com os desafios disponíveis;</li>
        <li>Avaliar colaborativamente as ideias por&nbsp;meio de investimentos, votos por estrelas e/ou curtidas;</li>
        <li>Debater as ideias/<em>&nbsp;insights</em>&nbsp;nos respectivos fóruns, contribuindo para a sua evolução;</li>
        <li>Editar os projetos das ideias que passaram para implementar;</li>
        <li>Usar os pontos coletados para concorrer aos prêmios disponíveis.</li>
        </ul>
        <p>Por conseguinte, o presente Regulamento rege-se pelas disposições seguintes:</p>
        <p><br/></p>
        <p><strong>1 DISPOSIÇÕES GERAIS</strong></p>
        <p><br/></p>
        <p><strong>1.1 &nbsp;- OBRIGAÇÕES GERAIS DOS PARTICIPANTS&nbsp;</strong></p>
        <ul>
        <li>Os Participantes devem respeitar os valores definidos pelo Grupo Cornélio Bernard, incorporando esses valores ao usar o Programa de inovação.</li>
        <li>O Participante deverá reportar quaisquer irregularidades que possam pôr em causa o bom funcionamento da plataforma ou a boa imagem do Grupo Cornélio Bernard, utilizando os contatos apresentados no menu Ajuda da plataforma.</li>
        <li>Os Participantes devem manter, dentro do Programa de inovação, um comportamento que ateste honestidade, respeito, cooperação e clareza de comunicação. Também devem manter essa conduta ao interagir com os outros Participantes, contribuindo assim para a iniciativa de uma forma valiosa, agradável e profissional.</li>
        </ul>
        <p><strong>1.2 &nbsp;- OBRIGAÇÕES GERAIS DA EQUIPE DE PROJETO DA PLATAFORMA</strong></p>
        <ul>
        <li>A Equipe de Projeto tem como missão dinamizar o Programa de Inovação, gerir os processos de suporte e definir regras de participação.</li>
        <li>A Equipe de Projeto poderá ajustar os procedimentos ou datas previstas bem como suspender o acesso à plataforma por razões fundamentadas, por motivos de força maior, ou quaisquer motivos de ordem técnica alheios à Equipe e que lhe seja reportado.&nbsp;</li>
        <li>A Equipe de Projeto pode suspender o acesso de qualquer participante ao Programa de Inovação, se este não cumprir os Regulamentos ou se for considerado uma ameaça ao funcionamento da iniciativa.</li>
        <li>Compete à Equipe de Projeto a verificação e validação das ideias submetidas, sendo a decisão fundamentada em critérios de forma e adequação do conteúdo ao desafio a que a ideia respeita.</li>
        <li>A Equipe de Projeto poderá desativar os fóruns de discussão ou os acessos às ideias por motivos de força maior ou sempre que o funcionamento e os objetivos sejam colocados em risco.</li>
        <li>Compete à Equipe de Projeto acompanhar e controlar a submissão de ideias pelos Participantes e regular o funcionamento do Programa de Inovação.</li>
        <li>As decisões tomadas pela Equipe de Projeto são finais e inquestionáveis. A Equipe de Projeto dará sempre&nbsp;<em>feedback</em>&nbsp;relevante a qualquer participante que o solicite.</li>
        </ul>
        <p><strong>2 PROGRAMA DE INOVAÇÃO&nbsp;</strong></p>
        <p><br/></p>
        <p><strong>2.1 – ACESSO DOS PARTICIPANTES</strong></p>
        <ul>
        <li>Os Participantes com acesso ao Programa de Inovação devem acessar site da plataforma e utilizá-la de acordo com os termos estabelecidos no presente Regulamento.</li>
        <li>Caso o Participante deixe, por qualquer motivo de possuir um utilizador válido, a sua condição de Participante será automaticamente cancelada.&nbsp;</li>
        </ul>
        <p><strong>2.2 &nbsp;- FUNCIONAMENTO DO PROGRAMA DE INOVAÇÃO</strong></p>
        <h3>Funcionamento geral</h3>
        <ul>
        <li>Esta plataforma é dinamizada através da colocação de desafios, cabendo à Equipe de Projeto a gestão/aprovação dos desafios de acordo com os objetivos estratégicos da empresa.&nbsp;</li>
        <li>Compete à Equipe de Projeto a definição do período de duração de cada desafio e o respetivo alcance.</li>
        <li>A Equipe de Projeto poderá aceitar, rejeitar ou solicitar correções ou esclarecimentos adicionais às ideias submetidas pelos Participantes nos termos definidos no presente Regulamento.</li>
        </ul>
        <p><strong>Submissão de Ideias</strong></p>
        <ul>
        <li>As ideias criadas devem ser submetidas na Plataforma de Inovação, sem qualquer registo ou ato ofensivo ou de má-fé.</li>
        <li>As ideias criadas devem ser registadas numa linguagem clara e tão completa quanto possível de modo a agilizar todo o processo inerente ao Plataforma de Inovação e às ações consequentes, garantindo sempre que responda diretamente aos desafios propostos.</li>
        <li>As ideias submetidas devem ter como objetivo um resultado positivo para o Grupo Cornélio Bernard nos desafios definidos pelo Responsável do Desafio para o Programa de Inovação.</li>
        <li>O participante deve verificar primeiro se não existe no Programa de Inovação uma ideia com elevado grau de semelhança à que pretende inserir. A Equipe de Projeto não aprova, ou seja, não autoriza a entrada no Programa de Inovação, ideias com grau de semelhança considerado elevado.</li>
        <li>Após a submissão de uma ideia, caso a Equipe de Projeto não valide a ideia, o Participante será informado que a sua ideia tem o&nbsp;<em>status</em>&nbsp;de “A alterar”. O Participante deverá realizar a edição da ideia, cumprindo o prazo definido e comunicado pela Equipe de Projeto, submetendo-a a nova validação, caso contrário a ideia passará ao estado “Não validada”.</li>
        <li>Caso a Equipe de Projeto verifique que uma ideia no Programa de Inovação é suficientemente semelhante à que passou pelo Programa de Inovação, já está sendo ou foi implementada, pode decidir retirá-la do Programa de Inovação imediatamente.</li>
        <li>Os prazos para a submissão de ideias serão previamente definidos e anunciados, não sendo aceites quaisquer exceções aos mesmos.</li>
        <li>A avaliação colaborativa e comentários às ideias serão possíveis, exclusivamente, enquanto cada ideia estiver no estado “Em avaliação”.</li>
        </ul>
        <p><strong>Submissão de Insights</strong></p>
        <ul>
        <li>Os&nbsp;<em>insights</em>&nbsp;criados devem ser submetidos no Programa de Inovação, sem qualquer registo ou ato ofensivo ou de má-fé. Os&nbsp;<em>insights</em>&nbsp;são peças de informação que podem estar relacionados com desafios atuais e podem ser inspiradores para a criação de novas ideias, ou seja, os&nbsp;<em>insights</em>&nbsp;não seguem o mesmo fluxo de ideias. Os&nbsp;<em>insights</em>&nbsp;podem ser, por exemplo, notícias, informações sobre boas práticas no Grupo Cornélio Bernard, em formato de texto, vídeo e/ou imagem.</li>
        <li>Os&nbsp;<em>insights</em>&nbsp;criados devem ser registados numa linguagem clara e tão completa quanto possível, de modo a serem úteis aos desafios em questão e de modo a que contribuam para a inspiração de outros participantes na criação de ideias para os desafios em questão.&nbsp;</li>
        <li>Qualquer participante pode criar&nbsp;<em>insights</em>&nbsp;e associá-los aos desafios ativos.</li>
        <li>Todos os participantes da plataforma podem comentar/debater&nbsp;<em>insights</em>&nbsp;sempre que estes estejam visíveis no Programa de Inovação.</li>
        </ul>
        <p><strong>Participação nos fóruns de discussão</strong></p>
        <ul>
        <li>Os Participantes poderão participar na discussão das ideias com vista a comentar e debater as ideias colocadas no Programa e/ou sugerir quaisquer alterações/ adaptações às mesmas.</li>
        <li>Os comentários criados devem ser inseridos sem qualquer registo ou ato ofensivo ou de má-fé.</li>
        <li>Os comentários colocados deverão ser relevantes para a discussão, devendo contribuir para o aprofundamento e melhoria da qualidade da ideia.</li>
        <li>Sendo respeitadas as condições anteriormente expostas, o Participante deverá aceitar os comentários efetuados à sua ideia.</li>
        <li>A Equipe de Projeto realizará a monitorização periódica dos fóruns de discussão e poderá decidir retirar a visibilidade de comentários considerados inadequados e inapropriados. Serão anulados os Pontos aos participantes que efetuem comentários apenas com o objetivo de conquistar Pontos, ou seja, que não acrescentem à discussão.</li>
        </ul>
        <p><strong>2.3 &nbsp;- DESQUALIFICAÇÃO DE PARTICIPANTES&nbsp;</strong></p>
        <p>- Qualquer Participante, durante qualquer fase do Programa, poderá ser desqualificado por decisão da Equipe de Projeto, em caso de violação grave das regras estabelecidas no presente Regulamento.</p>
        <p><br/></p>
        <p><br/></p>
        <p><strong>3 DISPOSIÇÕES FINAIS</strong></p>
        <p><br/></p>
        <p><strong>3.1 &nbsp;- DIREITO DE PROPRIEDADE INTELECTUAL</strong></p>
        <p><br/></p>
        <p>- Ao aceitar o presente Regulamento, o participante declara e reconhece que todos os direitos de propriedade intelectual, nomeadamente os direitos de autor concebidos ou desenvolvidos como resultado do seguimento da sua participação na iniciativa, serão propriedade exclusiva do Grupo Cornélio Bernard. O Grupo Cornélio Bernard também tem o direito de modificar total ou parcialmente, adicionar, reduzir, transmitir a terceiros, licenciar sob quaisquer condições, bem como alienar esses direitos, sem nenhum direito do Participante a qualquer compensação por qualquer um desses atos realizados peo Grupo Cornélio Bernard, ou por qualquer outra entidade que suceda o Grupo Cornélio Bernard no exercício desses direitos. Além disso, o participante se compromete a colaborar na formalização de tudo o que possa ser considerado necessário para esse fim.</p>
        <p>- A disposição acima aplica-se também a todos os materiais especialmente concebidos e/ou desenvolvidos pelos Participantes dentro da estrutura da iniciativa - escrita ou em qualquer outro formato, original ou copiado, incluindo estudos, materiais publicitários e publicidade, planos, relatórios, dados em formatos eletrônicos ou tabulações, formulários e questionários, técnicas, métodos de investigação ou outros.</p>
        <p>- Os Participantes serão responsáveis em caso de violação de direitos de propriedade industrial e/ou intelectual de terceiros, nomeadamente direitos de propriedade, direitos de autor, direitos conexos e direitos de personalidade, mas especialmente direitos de imagem. Assim, os Participantes assumem a responsabilidade de informar a Equipe de Projeto sempre que eles achem que tais situações aumentaram, e estão obrigados a reembolsar o Grupo Cornélio Bernard por quaisquer compensações, custos ou encargos que ela possa vir a arcar como consequência de qualquer ato ou fato do Participante em relação à terceiros com fundamento na violação de tais direitos.</p>
        <p><br/></p>
        <p><strong>3.2 &nbsp;- DADOS</strong></p>
        <p><br/></p>
        <p>- Os Participantes autorizam o Grupo Cornélio Bernard a reunir e processar quaisquer dados e informações resultantes da sua participação no Programa de inovação para fins da iniciativa, sempre em conformidade com as Leis de Proteção de Dados.</p>
        <p>- O Grupo Cornélio Bernard garante a não transmissão a entidades externas dos dados pessoais e/ou atividades coletadas no âmbito da iniciativa.</p>
        <p>- O Grupo Cornélio Bernard não tem obrigação de armazenar nenhum dado submetido na plataforma, uma vez que estes são inseridos de boa-fé por qualquer utilizador com acesso ao Programa de Inovação. Assim, estes não são detidos ou controlados pela empresa e o Grupo Cornélio Bernard não deve ser responsabilizada pela sua gestão.</p>
        <p><br/></p>
        <p><strong>3.3 &nbsp;- ALTERAÇÕES DO REGULAMENTO</strong></p>
        <p><br/></p>        
        <p>- A Equipe de Projeto poderá alterar, suspender o Programa, bem como o respetivo Regulamento, por razões fundamentadas. A decisão de encerrar a iniciativa deve ser comunicada pelo menos um (1) dia antes da data final da iniciativa e do encerramento do Programa de Inovação.</p>
        <p>- Nenhuma objeção a respeito de alterações ao Regulamento atual pode ser submetida à Equipe de Projeto, desde que tais alterações sejam disponibilizadas para consulta na plataforma.</p>
        <p><br/></p>
        <p><strong>3.4 &nbsp;- CESSAÇÃO DA INICIATIVA</strong></p>
        <p><br/></p>
        <p>- A Equipe de Projeto poderá cessar a iniciativa por situações imprevistas e devidamente fundamentadas, devendo dar conhecimento desse fato a todos os Participantes, bem como dos respetivos motivos.</p>
        <p>- Entende-se por situação imprevista, os fatos que o Grupo Cornélio Bernard não conhecia nem podia conhecer à data da criação da iniciativa e que determinem a necessidade de seu encerramento.</p>
        <p>- O encerramento do Programa não confere direito a qualquer tipo de compensação.</p>
        <p>- Todo o conteúdo criado é propriedade exclusiva do Grupo Cornélio Bernard.</p>
        <p>- Nenhuma alteração poderá ter efeitos retroativos.</p>
        <p><br/></p>
        <p><strong>3.5 – OUTRAS DISPOSIÇÕES</strong></p>
        <p><br/></p>
        <p>- Ao participarem na iniciativa, os Participantes aceitarão integralmente e sem reservas, o presente Regulamento.&nbsp;</p>
        <p>- O presente Regulamento estará disponível para consulta na plataforma de inovação Programa de Inovação.</p>
        <p>- Os Participantes poderão solicitar informações ou esclarecimentos à Equipe de Projeto.</p>
        </div>
        </>
    )
}

export default Terms;