import { makeStyles, Theme, createStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    rankingItem:{
        margin:'10px 0 5px 0',
        padding:'10px 0',  
    },
    rankingMySelf:{
        'background-color':'#f2f3f5',
        'border-radius':'6px',
        '& p:last-child': {
            'font-weight': '600'
        },
    },
    rankingMySelfTd:{
        padding:'8px 0 !important',
    },
    rankingUser:{
        display:'inline-flex',
        alignItems:'center',
    },
    rankingListItemRank:{
        fontWeight:900,
        color:theme.palette.primary.main,
        fontSize:'20px',
    },
    rankingListItemAvatar:{
        width:'50px',
        height:'50px',
        margin:'10px 20px'
    },
    rankingListItemName:{
        color:theme.palette.text.primary,
        textAlign: 'left',
        '&.rankingMySelf' : {
            fontWeight:900
        }
    },
    rankingListItemPoints:{
        display:'flex',
        alignItems:'center',
        justifyContent: 'flex-end',
        position: 'relative'
    },
    rankingListItemPointsP:{
        color:theme.palette.text.secondary,
        marginTop:'-2px',
        marginLeft:'16px'
    },
    rankingListItemSector:{
        width: '115px',
    },
    boxes: {
        width: '30px'
    },
    svgBox: {
        position: 'absolute',
        right: '65px'
    }
  }),
);