/*  Imports from Redux:
 applyMiddleware: Applies middleware to the dispatch method of the Redux store
 combineReducers: Merges reducers into one
 createStore: Creates a Redux store that holds the state tree
 Store: The TS Type used for the store, or state tree
 */
import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
/*  Thunk
Redux Thunk middleware allows you to write action creators that return a function instead of an action. The thunk can be used to delay the dispatch of an action, or to dispatch only if a certain condition is met. The inner function receives the store methods dispatch and getState as parameters.
*/
import thunk from "redux-thunk";
// Import reducers and state type
import {
  challengeReducer,
  IChallengeState,
} from "redux/reducers/challenges/challengeReducer";

import { profileReducer, IProfileState } from "../reducers/profileReducer";

import { walletReducer, IWalletState } from "../reducers/walletReducer";

import {
  investmentReducer,
  IInvestmentState,
} from "../reducers/investmentReducer";

import { cultureReducer, ICultureState } from "../reducers/cultureReducer";

import { prizeReducer, IPrizeState } from "../reducers/prizeReducer";

import { authReducer, IAuthState } from "../reducers/authReducer";
import { tabReducer, ITabState } from "../reducers/tabReducer";

import { newsReducer } from "../reducers/news/newsReducer";
import { INewsState } from "redux/types/news";

import {
  newsDetailReducer,
  INewsDetailState,
} from "../reducers/news/newsDetailReducer";

import { tenantReducer, ITenantState } from "../reducers/tenantReducer";

import { usersReducer, IUsersState } from "../reducers/usersReducers";

import { ideasReducer, IIdeasState } from "redux/reducers/ideas/ideaReducer";

import { workflowReducer, IWorkflowState } from "../reducers/workflow/workFlowReducer";

import { rankingsReducer, IRankingsState } from "../reducers/rankingsReducer";

import {
  challengeDetailReducer,
  IChallengeDetailState,
} from "../reducers/challengeDetailReducer";

import {
  myActivitiesReducer,
  IMyActivitiesState,
} from "../reducers/MyActivities/myActivitiesReducer";

import { alertReducer, IAlertState } from "../reducers/alertReducer";

import {
  insightsReducer,
  IInsightsState,
} from "../reducers/insight/insightsReducer";

import { feedReducer, IFeedState } from "../reducers/feedReducer";

import {
  keyTasksReducer,
  IKeyTasksState,
} from "../reducers/keyTasks/keyTasksReducer";

import {
  IAllIdeasState,
  allIdeasReducer,
} from "../reducers/ideas/AllIdeasReducer";

import {
  IAddValueState,
  addValueReducer,
} from "../reducers/ideas/AddValueReducer";

import {
  IIdeaManagersState,
  ideasManagersReducer,
} from "../reducers/ideas/IdeaManagersReducer";

import {
  IResourcesState,
  resourcesReducer,
} from "../reducers/resourcesReducer";

import {
  IRelatedContentState,
  relatedContentReducer,
} from "../reducers/relatedContent/RelatedContentReducer";

import {
  IWorkspaceState,
  workspaceReducer,
} from "../reducers/workspaceReducer";

import { manageUsersReducer } from "../reducers/manageUsers/manageUsersReducer";
import { IManageUsersState } from "redux/types/manageUsers";
import {
  IMessagesState,
  messagesReducer,
} from "redux/reducers/messagesReducer";
import { IManageGroupsState } from "redux/types/manageGroups";
import { manageGroupsReducer } from "redux/reducers/manageGroups/manageGroupsReducer";
import {
  createChallengeReducer,
  CreateChallengesState,
} from "redux/reducers/createChallenge/createChallengeReducer";
import { createPrizeReducer, CreatePrizesState } from 'redux/reducers/createPrize/createPrizeReducer';
import { IRegulationsState } from "../types/regulations";
import { regulationReducer } from "../reducers/regulations/regulationsReducer";
import { countryReducer, ICountryState } from "redux/reducers/countryReducer";
import { draftReducer, IDraftState } from "redux/reducers/drafts/DraftsReducer";

// Create an interface for the application state
export interface IAppState {
  authState: IAuthState;
  tenantState: ITenantState;
  challengeState: IChallengeState;
  profileState: IProfileState;
  walletState: IWalletState;
  investmentState: IInvestmentState;
  cultureState: ICultureState;
  prizeState: IPrizeState;
  newsState: INewsState;
  tabState: ITabState;
  ideasState: IIdeasState;
  usersState: IUsersState;
  challengeDetailState: IChallengeDetailState;
  myActivitiesState: IMyActivitiesState;
  alertState: IAlertState;
  rankingsState: IRankingsState;
  insightsState: IInsightsState;
  feedState: IFeedState;
  keyTasksState: IKeyTasksState;
  allIdeasState: IAllIdeasState;
  ideasManagersState: IIdeaManagersState;
  resourcesState: IResourcesState;
  relatedContentState: IRelatedContentState;
  addValueState: IAddValueState;
  newDetailState: INewsDetailState;
  workSpacesState: IWorkspaceState;
  manageUsersState: IManageUsersState;
  manageGroupsState: IManageGroupsState;
  messagesState: IMessagesState;
  createChallengeState: CreateChallengesState;
  createPrizeState: CreatePrizesState;
  regulationsState: IRegulationsState;  
  worflowState: IWorkflowState;
  countryReducerState:ICountryState
  draftState: IDraftState
}

// Create the root reducer
const appReducer = combineReducers<IAppState>({
  worflowState: workflowReducer,
  authState: authReducer,
  tenantState: tenantReducer,
  challengeState: challengeReducer,
  profileState: profileReducer,
  cultureState: cultureReducer,
  prizeState: prizeReducer,
  walletState: walletReducer,
  investmentState: investmentReducer,
  newsState: newsReducer,
  tabState: tabReducer,
  usersState: usersReducer,
  ideasState: ideasReducer,
  challengeDetailState: challengeDetailReducer,
  myActivitiesState: myActivitiesReducer,
  alertState: alertReducer,
  rankingsState: rankingsReducer,
  insightsState: insightsReducer,
  feedState: feedReducer,
  keyTasksState: keyTasksReducer,
  allIdeasState: allIdeasReducer,
  ideasManagersState: ideasManagersReducer,
  resourcesState: resourcesReducer,
  relatedContentState: relatedContentReducer,
  addValueState: addValueReducer,
  newDetailState: newsDetailReducer,
  workSpacesState: workspaceReducer,
  manageUsersState: manageUsersReducer,
  messagesState: messagesReducer,
  manageGroupsState: manageGroupsReducer,
  createChallengeState: createChallengeReducer,
  createPrizeState: createPrizeReducer,
  regulationsState: regulationReducer,
  countryReducerState: countryReducer,
  draftState: draftReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: String(process.env.REACT_APP_VERSION),
  storage,
  blacklist: ["createChallengeState", "createPrizeState"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create a configure store function of type `IAppState`
export default function configureStore(): Store<IAppState, any> {
  const store = createStore(
    persistedReducer,
    undefined,
    composeWithDevTools(applyMiddleware(thunk))
  );
  return store;
}

export const store = createStore(
  persistedReducer,
  undefined,
  composeWithDevTools(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
