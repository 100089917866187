import { api_v3 } from "config/api";
import { toast } from "react-toastify";
import { ThunkAction } from "redux-thunk";
import { treatError } from "utils/treatError";
import { ActionCreator, Dispatch } from "redux";
import { resource } from "components/Resources/Resource";
import {
  IRegulationsState,
  IRegulationsPostSuccessAction,
  RegulationsStatusTypes,
  RegulationsActionTypes,
  IRegulationsHistoryGetSuccessAction,
  IRegulationsPutSuccessAction,
  IRegulationsPublishGetSuccessAction,
} from "./../../types/regulations";

const basePath = "regulations";

export const getRegulationsHistory: ActionCreator<ThunkAction<
  Promise<any>,
  IRegulationsState,
  null,
  IRegulationsHistoryGetSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        regulationsStatus: RegulationsStatusTypes.REGULATIONS_LOADING,
        type: RegulationsActionTypes.GET_REGULATIONS_HISTORY_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/history${params_qs}`, {});
      dispatch({
        regulationsHistory: response.data,
        regulationsStatus: RegulationsStatusTypes.REGULATIONS_SUCCESS,
        type: RegulationsActionTypes.GET_REGULATIONS_HISTORY_SUCCESS,
      });
    } catch (err) {
      dispatch({
        regulationsStatus: RegulationsStatusTypes.REGULATIONS_FAIL,
        type: RegulationsActionTypes.GET_REGULATIONS_HISTORY_FAIL,
      });
      treatError(options?.culture, "PageRegulation::Regulation", err);
    }
  };
};

export const getRegulationsPublish: ActionCreator<ThunkAction<
  Promise<any>,
  IRegulationsState,
  null,
  IRegulationsPublishGetSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        regulationsStatus: RegulationsStatusTypes.REGULATIONS_LOADING,
        type: RegulationsActionTypes.GET_REGULATIONS_PUBLISH_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("workspaceId")) {
        params.push(`workspaceId=${options.workspaceId}`);
      }
      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }

      const response = await api_v3.get(`${basePath}/publish${params_qs}`, {});
      dispatch({
        regulationsPublish: response.data,
        regulationsStatus: RegulationsStatusTypes.REGULATIONS_SUCCESS,
        type: RegulationsActionTypes.GET_REGULATIONS_PUBLISH_SUCCESS,
      });
    } catch (err) {
      dispatch({
        regulationsStatus: RegulationsStatusTypes.REGULATIONS_FAIL,
        type: RegulationsActionTypes.GET_REGULATIONS_PUBLISH_FAIL,
      });
      treatError(options?.culture, "PageRegulation::Regulation", err);
    }
  };
};

export const postRegulations: ActionCreator<ThunkAction<
  Promise<any>,
  IRegulationsState,
  null,
  IRegulationsPostSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        regulationsStates: RegulationsStatusTypes.REGULATIONS_LOADING,
        type: RegulationsActionTypes.POST_REGULATIONS_LOADING,
      });

      let params;
      let querys = [];
      let params_qs = "";

      params = {
        title: options.title,
        description: options.description,
        workspaceId: options.workspaceId,
        publish: options.publish,
      };

      if (options.hasOwnProperty("workspaceId")) {
        querys.push(`workspaceId=${options.workspaceId}`);
      }

      if (querys.length) {
        params_qs = `?${querys.join("&")}`;
      }

      await api_v3.post(`${basePath}${params_qs}`, params).then(async () => {
        toast.success(resource(options?.culture, "PageRegulation::RegulationsSuccess"));

        const response = await api_v3.get(`${basePath}/history${params_qs}`);
        const responsePublish = await api_v3.get(
          `${basePath}/publish${params_qs}`
        );
        dispatch({
          regulationsHistory: response.data,
          regulationsPublish: responsePublish.data,
          regulationsStatus: RegulationsStatusTypes.REGULATIONS_SUCCESS,
          type: RegulationsActionTypes.POST_REGULATIONS_SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        regulationsStatus: RegulationsStatusTypes.REGULATIONS_FAIL,
        type: RegulationsActionTypes.POST_REGULATIONS_FAIL,
      });
      treatError(options?.culture, "PageRegulation::Regulation", err);
    }
  };
};

export const putRegulations: ActionCreator<ThunkAction<
  Promise<any>,
  IRegulationsState,
  null,
  IRegulationsPutSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        regulationsStates: RegulationsStatusTypes.REGULATIONS_LOADING,
        type: RegulationsActionTypes.PUT_REGULATIONS_LOADING,
      });

      let params;
      let querys = [];
      let params_qs = "";

      params = {
        title: options.title,
        description: options.description,
        workspaceId: options.workspaceId,
      };

      if (options.hasOwnProperty("workspaceId")) {
        querys.push(`workspaceId=${options.workspaceId}`);
      }

      if (querys.length) {
        params_qs = `?${querys.join("&")}`;
      }

      await api_v3.put(`${basePath}/draft`, params).then(async () => {
        toast.success(resource(options?.culture, "PageRegulation::RegulationsSuccess"));

        const response = await api_v3.get(
          `${basePath}/history${params_qs}`,
          {}
        );
        dispatch({
          regulationsHistory: response.data,
          regulationsStatus: RegulationsStatusTypes.REGULATIONS_SUCCESS,
          type: RegulationsActionTypes.PUT_REGULATIONS_SUCCESS,
        });
      });
    } catch (err) {
      dispatch({
        regulationsStatus: RegulationsStatusTypes.REGULATIONS_FAIL,
        type: RegulationsActionTypes.PUT_REGULATIONS_FAIL,
      });
      treatError(options?.culture, "PageRegulation::Regulation", err);
    }
  };
};
