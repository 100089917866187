import AddIcon from "@material-ui/icons/Add";
import { useDrag, useDrop } from "react-dnd";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { ICultureState } from "redux/reducers/cultureReducer";
import { cfIcons, ItemTypes } from "../ButtonIconTypes/cfTypes";
import Resource, { resource } from "components/Resources/Resource";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { typeButtons } from "../../../views/Challenges/CreateChallenge/Steps/CreateChallengeStepIdeaForm";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  IconButton,
  Input,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { DraggableCard } from "redux/types/createChallenge";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 8,
      marginBottom: 8,
      
    },
    iconButtonRoot: {
      padding: 8,
    },
    rounded: {
      borderRadius: 6,
      "&::before": {
        height: 0,
        width: 0,
      },
    },
  })
);

interface IProps {
  culture: ICultureState;
  card: DraggableCard;
  index: number;
  handleMove: (index: number, futureIndex: number) => void;
  handleCardChange: (index: number, value: any) => void;
  handleDeleteCard: (card: any, index: number) => void;
}

function CustomFieldCollapsible({
  culture,
  card,
  index,
  handleMove,
  handleCardChange,
  handleDeleteCard,
}: IProps) {
  const [expanded, setExpanded] = useState(card.firstOpen || false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const { cultureTag } = culture?.culture || "";
  const [currentCulture] = useState(0);

  const [state, setState] = useState({
    fields: card.cultures,
    isRequired: card.isRequired,
    options: card.options,
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const currentField = [...state.fields];
    currentField[currentCulture] = {
      ...currentField[currentCulture],
      [name]: value,
    };
    setState({ ...state, fields: currentField });
  };

  const handleMoreOptions = () => {
    if (state.options)
      setState({
        ...state,
        options: [...state.options, { cultures: [{ cultureTag, title: "" }] }],
      });
  };

  const handleLessOptions = (index: number) => {
    const newArr = state?.options?.filter(
      (option: any, i: number) => index !== i
    );
    setState({ ...state, options: newArr });
  };

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (state.options) {
      const newArr = [...state.options];
      newArr[index] = { cultures: [{ cultureTag, title: event.target.value }] };
      setState({ ...state, options: newArr });
    }
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [expanded, setAnchorEl]);

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.COLLAPSIBLECARD,
    item: {
      type: ItemTypes.COLLAPSIBLECARD,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.COLLAPSIBLECARD,
    drop: (item) => {
      //item type does not contain index or data
      //@ts-ignore
      handleMove(index, item.index);
    },
  });

  return (
    <div
      ref={drop}
      style={{ marginTop: expanded ? 8 : 0, marginBottom: expanded ? 8 : 0 }}
    >
      <div ref={drag} style={{ backgroundColor: isDragging ? "#BDBDBD" : "" }}>
        <Accordion
          expanded={expanded}
          classes={{ root: classes.root, rounded: classes.rounded }}
          TransitionProps={{ unmountOnExit: true }}
        >
          {!expanded && (
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <MoreHorizIcon fontSize="small" />
                </IconButton>
              }
              IconButtonProps={{
                onClick: (e: any) => {
                  e.stopPropagation();
                  setAnchorEl(e.currentTarget);
                },
                classes: { root: classes.iconButtonRoot },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                fontWeight={500}
                justifyContent="flex-end"
              >
                {cfIcons[card.type]}
                <Typography
                  variant="h4"
                  style={{ marginLeft: 8 }}
                  component="strong"
                  color="textPrimary"
                >
                  {state.fields[currentCulture].title ||
                    typeButtons[card.type - 1].name}{" "}
                  {state.isRequired && "*"}
                </Typography>
              </Box>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={(e: any) => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={(e: any) => {
                    setAnchorEl(null);
                    setExpanded(true);
                  }}
                >
                  <Resource tag="CustomFields::Edit" />
                </MenuItem>
                <MenuItem
                  onClick={(e: any) => {
                    setAnchorEl(null);
                    handleDeleteCard(card, index);
                  }}
                >
                  <Resource tag="CustomFields::Delete" />
                </MenuItem>
              </Menu>
            </AccordionSummary>
          )}
          <AccordionDetails>
            <Box width="100%">
              {expanded && (
                <Box
                  minHeight={64}
                  textAlign="right"
                  display="flex"
                  fontWeight={500}
                  pt={1}
                  justifyContent="flex-end"
                >
                  {typeButtons[card.type - 1].startIcon}
                  <Typography
                    component="span"
                    style={{ marginLeft: 8 }}
                    variant={"body1"}
                    color="textPrimary"
                  >
                    {typeButtons[card.type - 1].name}
                  </Typography>
                </Box>
              )}
              <Box mt={-4}>
                <Box mb={4}>
                  <TextField
                    label={<Resource tag="CustomFields::Title" />}
                    name="title"
                    onChange={handleChange}
                    value={state.fields[currentCulture].title}
                    placeholder={resource(culture, "CustomFields::Title_Tip")}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                  />
                </Box>
                <Box mb={4}>
                  <TextField
                    label={<Resource tag="CustomFields::HelpText" />}
                    name="description"
                    onChange={handleChange}
                    value={state.fields[currentCulture].description}
                    placeholder={resource(
                      culture,
                      "CustomFields::HelpText_Tip"
                    )}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                  />
                </Box>
                {(card.type === 1 || card.type === 2 || card.type === 4) && (
                  <Box mb={4}>
                    <TextField
                      label={<Resource tag="CustomFields::Placeholder" />}
                      name="placeholder"
                      onChange={handleChange}
                      value={state.fields[currentCulture].placeholder}
                      placeholder={resource(
                        culture,
                        "CustomFields::Placeholder_Tip"
                      )}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Box>
                )}
                {(card.type === 5 || card.type === 6 || card.type === 7) && (
                  <Box mb={1}>
                    <Typography
                      color="textSecondary"
                      variant="caption"
                      style={{ fontWeight: 500, textTransform: "uppercase" }}
                    >
                      <Resource tag="CustomFields::Option" />
                    </Typography>

                    {state?.options?.map((field, index, self) => (
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        key={index}
                        mb={2}
                      >
                        {(card.type === 5 || card.type === 6) && (
                          <Box
                            pr={1}
                            display="flex"
                            alignItems="flex-end"
                            pb="2px"
                          >
                            {card.type === 5 && (
                              <CheckBoxOutlineBlankIcon htmlColor="#828282" />
                            )}
                            {card.type === 6 && (
                              <RadioButtonUncheckedIcon htmlColor="#828282" />
                            )}
                          </Box>
                        )}
                        <Input
                          placeholder={resource(
                            culture,
                            "CustomFields::Option_Tip"
                          )}
                          fullWidth
                          value={field?.cultures?.[0]?.title}
                          onChange={(e) => handleOptionChange(e, index)}
                        />
                        {self.length > 1 && (
                          <Box pl={1}>
                            <IconButton
                              onClick={() => handleLessOptions(index)}
                              style={{ padding: 4 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    ))}
                    <Box textAlign="right">
                      <Button
                        color="primary"
                        startIcon={<AddIcon fontSize="small" />}
                        onClick={() => handleMoreOptions()}
                      >
                        <Resource tag="CustomFields::AddOption" />
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box display="flex" justifyContent="space-between">
                <FormControlLabel
                  checked={state.isRequired}
                  //checked doesn't exist on mui target type
                  //@ts-ignore
                  onChange={(e) =>
                    //@ts-ignore
                    setState({ ...state, isRequired: e.currentTarget.checked })
                  }
                  control={<Checkbox color="primary" />}
                  label={<Resource tag="CustomFields::Required" />}
                />
                <Button
                  color="primary"
                  disabled={!state.fields[currentCulture].title || !state.fields[currentCulture].description}
                  onClick={() => {
                    handleCardChange(index, state);
                    setExpanded(false);
                  }}
                >
                  <Resource tag="CustomFields::Done" />
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default CustomFieldCollapsible;
