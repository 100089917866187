import { Reducer } from "redux";
import {
  MessageActions,
  MessagesActionTypes,
} from "redux/actions/MessagesActions";
import {
  IMessagesArchived,
  IMessagesDetail,
  IMessagesForwardHeader,
  IMessagesReceived,
  IMessagesSent,
} from "redux/types/messages";

export interface IMessagesState {
  readonly received: IMessagesReceived;
  readonly sent: IMessagesSent;
  readonly archived: IMessagesArchived;
  readonly messageDetail: IMessagesDetail;
  readonly forwardHeader: IMessagesForwardHeader;
  readonly status: string;
}

const initialCharacterState: IMessagesState = {
  received: {} as IMessagesReceived,
  sent: {} as IMessagesSent,
  archived: {} as IMessagesArchived,
  messageDetail: {} as IMessagesDetail,
  forwardHeader: {} as IMessagesForwardHeader,
  status: "",
};

export const messagesReducer: Reducer<IMessagesState, MessageActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_SUCCEEEDED: {
      return {
        ...state,
        received: action.payload,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_RECEIVED_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_SEND_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_SEND_SUCCEEEDED: {
      return {
        ...state,
        sent: action.payload,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_SEND_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_SUCCEEEDED: {
      return {
        ...state,
        archived: action.payload,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_ARCHIVED_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_SUCCEEEDED: {
      return {
        ...state,
        messageDetail: action.payload,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_DETAILS_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_SUCCEEEDED: {
      return {
        ...state,
        forwardHeader: action.payload,
        status: action.status,
      };
    }
    case MessagesActionTypes.GET_ALL_MESSAGES_FORWARDHEADER_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MessagesActionTypes.POST_MESSAGES_SUCCEEDED:
    case MessagesActionTypes.POST_MESSAGES_FAILED:
    case MessagesActionTypes.POST_MESSAGES_ARCHIVE_SUCCEEDED:
    case MessagesActionTypes.POST_MESSAGES_ARCHIVE_FAILED:
    case MessagesActionTypes.POST_MESSAGES_REPLY_SUCCEEDED:
    case MessagesActionTypes.POST_MESSAGES_REPLY_FAILED:
    case MessagesActionTypes.POST_MESSAGES_REPLYALL_SUCCEEDED:
    case MessagesActionTypes.POST_MESSAGES_REPLYALL_FAILED:
    case MessagesActionTypes.DELETE_MESSAGES_SUCCEEDED: 
    case MessagesActionTypes.DELETE_MESSAGES_FAILED: {
      return {
        ...state,
        status: action.status,
      };
    }
    default:
      return state;
  }
};
