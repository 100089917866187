import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import React, { useEffect, useState } from "react";
import { IFeed } from "redux/reducers/feedReducer";
import FeedPageSkeleton from "./FeedPage.Skeleton";
import FeedCarouselPrizes from "./FeedCarouselPrizes";
import { IProfile } from "redux/reducers/profileReducer";
import { Box, Container, Grid } from "@material-ui/core";
import { ICultureState } from "redux/reducers/cultureReducer";
import FeedCarouselChallenges from "./FeedCarouselChallenges";
import FeedActionCardBar from "./FeedActionCardBar/FeedActionCardBar";
import { IAllIdeasState } from "redux/reducers/ideas/AllIdeasReducer";
import { getAllFeed, IFeedGetAllAction } from "redux/actions/FeedAction";
import InspirationCard from "components/InspirationCard/InspirationCard";
import FeedCardIdeaCollaborative from "components/FeedIdeaCollaborative/FeedIdeaCollaborative";
import Onboard from "components/Onboard/Onboard";

import {
  deleteIdeasLikes,
  postIdeasInvestments,
  postIdeasLikes,
  getAllIdeas,
} from "redux/actions/ideas/IdeaAction";
import {
  IProfileGetDataAction,
  getProfile,
} from "redux/actions/ProfileActions";
import {
  getNewChallengeList,
  IChallengeGetNewListAction,
} from "redux/actions/ChallengeActions";
import { IChallengeList } from "redux/types/challenges";

interface IDispatchProps {
  getProfile: () => Promise<IProfileGetDataAction>;
  getAllFeed: (options: object) => Promise<IFeedGetAllAction>;
  postInvestments: (options: object) => void;
  getNewChallengeList: (options: object) => Promise<IChallengeGetNewListAction>;
  getAllIdeas: (options: object) => Promise<IAllIdeasState>;
  postLikes: (options: object) => Promise<any>;
  deleteLikes: (options: object) => Promise<any>;
}

interface IStateProps {
  workspaceId: string;
  applicationId: any;
  culture: ICultureState;
  feed: IFeed;
  profile: IProfile[];
  statusComment: string;
  challengeList: IChallengeList;
  permission: string;
}

function Feed(props: IDispatchProps & IStateProps) {
  const [loading, setLoading] = useState(true);
  const {
    workspaceId,
    getAllFeed,
    applicationId,
    feed,
    culture,
    postInvestments,
    profile,
    getNewChallengeList,
    getAllIdeas,
    postLikes,
    deleteLikes,
    getProfile,
    challengeList,
    permission
  } = props;

  useEffect(() => {
    let isMounted = true;
    isMounted && getNewChallengeList({ pageNumber: 1, pageSize: 10 });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;

    const options = {
      workspaceId: workspaceId,
      applicationId: applicationId,
      culture: culture,
    };

    isMounted &&
      getProfile()?.then(() => {
        isMounted &&
          getAllFeed(options)?.then(() => {
            isMounted && setLoading(false);
          });
      });
    return () => {
      isMounted = false;
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loading ? (
        <FeedPageSkeleton />
      ) : (
        <Container>

          <FeedActionCardBar
            showShareInspiration={feed?.showShareInspiration && challengeList?.result?.length > 0}
            showShareYourIdea={feed?.showShareYourIdea && challengeList?.result?.length > 0}
            username={profile[0]?.fullName}
            totalTasks={feed?.totalTasks}
            ideasEvaluate={feed?.totalEvaluate}
            overdue={feed?.totalOverdueTasks}
          />

          <Onboard
            permission={permission}
            offBoard={!feed?.showShareInspiration || !feed?.showShareYourIdea}
            culture={props?.culture}
            existAnyChallenges={feed?.existAnyChallenges}
          />

          <FeedCarouselChallenges
            items={feed?.challenges ? feed?.challenges : []}
          />

          <Grid container spacing={2}>
            {feed?.ideas &&
              feed?.ideas?.map((idea, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <FeedCardIdeaCollaborative
                      workspaceId={workspaceId}
                      culture={props.culture}
                      name={profile[0]?.fullName}
                      applicationId={applicationId}
                      postLikes={postLikes}
                      deleteLikes={deleteLikes}
                      postInvestments={postInvestments}
                      getAllIdeas={getAllIdeas}
                      key={index}
                      size="Big"
                      result={idea}
                    />
                  </Grid>
                );
              })}
          </Grid>

          {feed?.prizes?.length > 0 ? (

            <Box mt={2}>
              <FeedCarouselPrizes items={feed?.prizes} />
            </Box>
          ) : (
            ""
          )}

          <Box mt={2}>
            <Grid container spacing={2}>
              {feed?.insights &&
                feed?.insights?.map((insight, index) => {
                  return (
                    <Grid item sm={12} md={12} xs={12} key={index}>
                      <InspirationCard
                        key={index}
                        insights={insight}
                        culture={culture!}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          {/* ADD after service team to define */}
          {/* <Box mt={3}>
          <NewsCarousel arrNews={feed?.news} isLoading={loading} />
        </Box> */}
        </Container>
      )}
    </div>
  );
}

const mapStateToProps = (store: IAppState, ownProps: any): IStateProps => {
  return {
    workspaceId: store.authState.auth.workspaceId,
    feed: store.feedState.feed,
    applicationId: store.authState.auth.applicationId,
    culture: store.cultureState,
    profile: store.profileState.profile,
    statusComment: store.ideasState.statusComment,
    challengeList: store.challengeState.challengeList,
    permission: store.authState.auth.permission,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getProfile: () => dispatch(getProfile()),
    getAllFeed: (options: object) => dispatch(getAllFeed(options)),
    postLikes: (options: object) => dispatch(postIdeasLikes(options)),
    deleteLikes: (options: object) => dispatch(deleteIdeasLikes(options)),
    postInvestments: (options: object) =>
      dispatch(postIdeasInvestments(options)),
    getNewChallengeList: (parameters: object) =>
      dispatch(getNewChallengeList(parameters)),
    getAllIdeas: (parameters: object) => dispatch(getAllIdeas(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
