import React from "react";
import ShortText from "./ShortText/ShortText";
import Checkmark from "./Checkmark/Checkmark";
import InputDate from "../InputDate/InputDate";
import { IInput } from "../../redux/types/inputs";
import NumberInput from "./NumberInput/NumberInput";
import DropdownInput from "./Dropdown/DropdownInput";
import DecimalInput from "../DecimalInput/DecimalInput";
import TextEditorInput from "./TextEditorInput/TextEditorInput";

interface IProps {
  input: IInput;
  value?: any;
  handler?: any;
  noFutureDate?: boolean;
}

const GenericField = ({ input, value, handler, noFutureDate }: IProps) => {
  const inputX = ({ typeId }: IInput, value: any) => {
    switch (typeId) {
      case 1:
        return <ShortText value={value} propsI={input} handler={handler} />;
      case 2:
        return <NumberInput value={value} propsI={input} handler={handler} />;
      case 3:
        return <DecimalInput value={value} propsI={input} handler={handler} />;
      case 4:
        return <DropdownInput value={value} propsI={input} handler={handler} />;
      case 5:
        return (
          <InputDate
            value={value}
            props={input}
            handler={handler}
            noFutureDate={noFutureDate}
          />
        );
      case 9:
        return (
          <TextEditorInput propsI={input} value={value} handler={handler} />
        );
      case 10:
        return <Checkmark value={value} propsI={input} handler={handler} />;
      default:
        return "";
    }
  };

  return (
    <div key={input.columnName} className="input-div">
      {inputX(input, value)}
    </div>
  );
};

export default GenericField;
