import Truncate from "react-truncate";
import AddIcon from "@material-ui/icons/Add";
import { Visibility } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import ToolTip from "components/ToolTip/ToolTip";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { ICultureState } from "redux/reducers/cultureReducer";
import XAutocomplete from "common/UI/XAutocomplete/XAutocomplete";
import { PageStateParticipants } from "redux/types/createChallenge";
import GroupDetailsDialog from "../../../../components/Dialogs/GroupDetailsDialog";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Switch,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  IManageGroup,
  IManageGroups,
  IResultManageGroups,
} from "redux/types/manageGroups";
import CreateGroupDialog from "components/Dialogs/CreateGroupDialog";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IAppState } from "redux/store/Store";
import { connect } from "react-redux";
import { getAllCountries, getAllDepartaments } from "redux/actions/UsersAction";
import {
  deleteManageGroups,
  getManageGroup,
  getManageGroups,
} from "redux/actions/manageGroups/manageGroupsAction";
import DeleteGroupDialog from "components/Dialogs/DeleteGroupDialog";

interface IProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    thumbnailSuggested: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    managerItemContainer: {
      width: "194px",
      padding: "8px",
      display: "flex",
      alignItems: "center",
      margin: "4px",
      border: "1px solid #ccc",
      borderRadius: "30px",
    },
    suggestedItemContainer: {
      padding: "8px",
      display: "flex",
      alignItems: "center",
      margin: "4px",
      borderRadius: "4px",
    },
    listButton: {
      width: 25,
      height: 25,
      borderRadius: 5,
      boxShadow: "0 0 5px " + theme.palette.grey[400],
      marginTop: 4,
      marginBottom: 4,
      "&:hover": {
        borderRadius: 5,
      },
    },
    labelCheckbox: {
      display: "flex",
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
        width: "75%",
        [theme.breakpoints.only("xs")]: {
          width: "80%",
        },
      },
    },
    widthBtnCheckbox: {
      width: "100%",
      "& .MuiSvgIcon-root": {
        width: "0.8em",
        height: "0.8em",
      },
    },
    dividerCheckbox: {
      width: "90%",
    },
    iconBtn: {
      padding: 0,
      "&:hover": {
        padding: 0,
      },
    },
    labelMenu: {
      fontSize: "0.8rem",
    },
  })
);

interface IProps {
  fieldsProgress?: number;
  completedFieldsProgress?: number;
  culture: ICultureState;
  allGroups: IManageGroups;
  setState: (newState: PageStateParticipants) => void;
  state: PageStateParticipants;
  challengeManagers: any;
  workspaceId: string;
  permission: string;
}

interface IStateProps {
  countries: ICountries[];
  departments: IDepartments[];
  group: IManageGroup;
}

interface IDispatchProps {
  getCountries: (options: object) => Promise<any>;
  getDepartments: (options: object) => Promise<any>;
  // getAllManageGroups: (options: object) => Promise<any>;
  getManageGroups: (options: object) => Promise<any>;
  getManageGroup: (options: object) => Promise<any>;
  //putRevoveUserGroupManageGroups: (options: object) => Promise<any>;
  deleteManageGroups: (options: object) => Promise<any>;
}

function CreateChallengeStepParticipants(
  props: IProps & IDispatchProps & IStateProps
) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    permission,
    culture,
    state,
    setState,
    allGroups,
    challengeManagers,
    countries,
    departments,
    group,
    workspaceId,
    getCountries,
    getDepartments,
    getManageGroups,
    getManageGroup,
    deleteManageGroups,
  } = props;
  const [groupOpen, setGroupOpen] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [groupSelected, setGroupSelected] = useState("");
  const [groupDetailSelected, setGroupDetailSelected] =
    useState<IResultManageGroups>();
  const [allWorkpaceSelected, setAllWorkpaceSelected] = useState(false);
  const [allGroupSelected, setAllGroupSelected] = useState(false);

  useEffect(() => {
    Promise.all([
      getCountries({ cultureTag: culture.culture.cultureTag }),
      getDepartments({ cultureTag: culture.culture.cultureTag }),
      getManageGroups({
        workspaceId,
        cultureTag: culture.culture.cultureTag,
        culture: culture.culture,
        pageSize: 100,
      }),
    ]).finally(() => {});

    // eslint-disable-next-line
  }, []);

  const handleAutoComplete = (value: any) => {
    if (value?.length) {
      setState({
        ...state,
        managers: value,
      });
    }
  };

  const handleDeleteValue = (value: any) => {
    setState({
      ...state,
      managers: state.managers
        .filter((x: any) => x.managerId !== value)
        .filter((x: any) => x.userId !== value),
    });
  };

  const handleChangeAdded = (value: any) => {
    if (
      !state.managers
        .map((x: any) => x.userId)
        .includes(value?.userId || value?.managerId)
    ) {
      setState({
        ...state,
        managers: [...state.managers, value],
      });
    }
  };
  const [open, setOpen] = useState(false);

  //Menu options functions
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (groupSelected !== "") {
      Promise.all([
        getManageGroup({ workspaceId, groupId: groupSelected }),
      ]).finally(() => {});
    }
    // eslint-disable-next-line
  }, [groupSelected]);

  function handleDeleteGroup() {
    deleteManageGroups({
      groupId: groupSelected,
      workspaceId,
      culture: culture,
    }).then(() => {
      getManageGroups({ workspaceId, cultureTag: culture.culture.cultureTag });
    });
  }

  const handleChangeCheckbox = (list: any) => {
    setAllGroupSelected(false);
    state.groups.map((x: any) => x.groupId).includes(list.groupId)
      ? setState({
          ...state,
          groups: state.groups.filter((y: any) => y.groupId !== list.groupId),
        })
      : setState({
          ...state,
          groups: [...state.groups, list],
        });
  };

  const handleChangeAllCheckbox = (event: React.ChangeEvent<any>) => {
    setAllGroupSelected(!allGroupSelected);
    setState({
      ...state,
      groups: [],
    });
  };

  return (
    <Box className="step-participants-container">
      <Box className="participants-upser-side-container">
        <Box minHeight="170px" className="challenge-manager-container">
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} sm={5} md={8} xl={8}>
              <Typography variant="h4">
                <Resource tag="PageCreateChallenge::Participants_ChallengeManager" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} md={4} xl={4}>
              <XAutocomplete
                culture={culture}
                options={challengeManagers || []}
                optionAvatar={"photo"}
                optionName={"fullName"}
                optionSubtitle={"email"}
                autoCompleteValue={state.managers.map((manager) => ({
                  userId: manager.managerId || manager.userId,
                  fullName: manager.name || manager.fullName,
                  photo: manager.photo,
                  email: manager.email,
                }))}
                onChange={(newValue) => handleAutoComplete(newValue)}
              />
            </Grid>
          </Grid>
          <Grid container style={{ margin: "45px 0" }}>
            {state.managers?.map((value: any, index: number) => {
              return (
                <Grid item xs={6} sm={6} md={3} xl={3} key={index}>
                  <Box className={classes.managerItemContainer}>
                    <Box className="avatar" width="20%">
                      <Avatar
                        src={value?.photo}
                        alt={value?.fullName}
                        className={classes.thumbnail}
                      />
                    </Box>
                    <Box
                      className="name"
                      width="65%"
                      fontSize="0.9rem"
                      margin="0 9px"
                    >
                      <Truncate width={102} ellipsis={<span>...</span>}>
                        {value?.fullName || value?.name || value?.email}
                      </Truncate>
                    </Box>
                    <Box className="btn-close" width="15%">
                      <IconButton
                        onClick={() =>
                          handleDeleteValue(value?.managerId || value?.userId)
                        }
                        style={{ padding: 0 }}
                      >
                        <ClearIcon style={{ fontSize: "0.9rem" }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box mb={"45px"} className="suggested-users-container">
          <Box fontSize="1rem" mb={"35px"}>
            <Resource tag="PageCreateChallenge::Participants_SuggestedUsers" />
          </Box>
          <Grid container spacing={3}>
            {challengeManagers?.length > 0 &&
              [...challengeManagers]
                ?.splice(0, 5)
                .map((value: any, index: number) => (
                  <Grid item xs={12} sm={12} md={4} xl={4} key={value?.userId}>
                    <Box
                      className={classes.suggestedItemContainer}
                      style={{
                        background: state.managers
                          .map((x: any) => x.userId || x?.managerId)
                          .includes(value?.userId || value?.managerId)
                          ? theme.palette.primary.light
                          : "transparent",
                      }}
                    >
                      <Box className="avatar" width="20%">
                        <Avatar
                          src={value?.photo}
                          alt={value?.fullName}
                          className={classes.thumbnailSuggested}
                        />
                      </Box>
                      <Box
                        className="name"
                        width="65%"
                        fontSize="0.9rem"
                        margin="0 9px"
                      >
                        <Box mr="3px">
                          <Truncate width={125} ellipsis={<span>...</span>}>
                            {value?.fullName}
                          </Truncate>
                        </Box>
                        <Typography variant="caption" color="textSecondary">
                          <Truncate width={130} ellipsis={<span>...</span>}>
                            {value?.email}
                          </Truncate>
                        </Typography>
                      </Box>
                      <Box className="btn-add" width="15%">
                        {!state.managers
                          .map((x: any) => x.userId)
                          .includes(value?.userId || value?.managerId) && (
                          <IconButton
                            disableRipple
                            className={classes.listButton}
                            onClick={() => handleChangeAdded(value)}
                          >
                            <AddIcon style={{ fontSize: "0.9rem" }} />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
          </Grid>
        </Box>
      </Box>
      <Box className="participants-under-side">
        <Box mb={"35px"} className="particpants-groups-container">
          <Grid container alignItems="center">
            <Grid item xs={6} sm={6} md={8} xl={8}>
              <Typography variant="h4">
                <Resource tag="PageCreateChallenge::Participants_ParticipantGroups" />
              </Typography>
            </Grid>
            {permission === "Admin" && (
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                xl={4}
                style={{ textAlignLast: "end" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon style={{ fontSize: "0.9rem" }} />}
                  onClick={() => setGroupOpen(true)}
                >
                  <Resource tag="PageCreateChallenge::Participants_CreateGroup" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box className="checkbox-container">
          <FormControl
            component="fieldset"
            className={classes.widthBtnCheckbox}
          >
            <FormGroup>
              <Grid container>
                <Grid key={0} item xs={12} sm={6} md={4} xl={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.groups?.length === 0 && allGroupSelected}
                        onChange={handleChangeAllCheckbox}
                        name="checked"
                        color="primary"
                      />
                    }
                    className={classes.labelCheckbox}
                    label={
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={10} sm={10} md={10} xl={10}>
                          <Truncate width={197} ellipsis={<span>...</span>}>
                            <Resource tag="PageCreateChallenge::Participants_AllWorkspace" />
                          </Truncate>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} xl={1}></Grid>
                        <Grid item xs={1} sm={1} md={1} xl={1}>
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setAllWorkpaceSelected(true);
                              setGroupDetailSelected(undefined);
                            }}
                            className={classes.iconBtn}
                          >
                            <Visibility style={{ color: "#DADADA" }} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    }
                  />
                  <Divider className={classes.dividerCheckbox} />
                </Grid>
                {allGroups &&
                  allGroups?.result?.map((list) => {
                    return (
                      <Grid
                        key={list?.groupId}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        xl={4}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={state.groups
                                .map((x: any) => x.groupId)
                                .includes(list?.groupId)}
                              onChange={() => handleChangeCheckbox(list)}
                              name={list?.groupName}
                              color="primary"
                            />
                          }
                          className={classes.labelCheckbox}
                          label={
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item xs={10} sm={10} md={10} xl={10}>
                                <Truncate
                                  width={197}
                                  ellipsis={<span>...</span>}
                                >
                                  {list?.groupName}
                                </Truncate>
                              </Grid>
                              <Grid item xs={1} sm={1} md={1} xl={1}>
                                <IconButton
                                  className={classes.iconBtn}
                                  onClick={(e) => {
                                    setGroupSelected(list.groupId);
                                    handleClickMenu(e);
                                    setAllWorkpaceSelected(false);
                                  }}
                                >
                                  <MoreHorizIcon
                                    fontSize="small"
                                    style={{ color: "#DADADA" }}
                                  />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleCloseMenu}
                                  PaperProps={{ elevation: 0 }}
                                >
                                  <MenuItem
                                    className={classes.labelMenu}
                                    onClick={() => {
                                      setEditGroup(true);
                                      handleCloseMenu();
                                    }}
                                  >
                                    <Resource tag="PageCreateChallenge::Participants_EditGroup" />
                                  </MenuItem>
                                  <MenuItem
                                    className={classes.labelMenu}
                                    onClick={() => {
                                      setDeleteGroup(true);
                                      handleCloseMenu();
                                    }}
                                  >
                                    <Resource tag="PageCreateChallenge::Participants_Delete" />
                                  </MenuItem>
                                </Menu>
                              </Grid>
                              <Grid item xs={1} sm={1} md={1} xl={1}>
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setGroupDetailSelected(list);
                                  }}
                                  className={classes.iconBtn}
                                >
                                  <Visibility style={{ color: "#DADADA" }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          }
                        />
                        <Divider className={classes.dividerCheckbox} />
                      </Grid>
                    );
                  })}
              </Grid>
            </FormGroup>
          </FormControl>

          <Box mt={8}>
            <Box alignItems="center" width="100%" display="flex" mb={"30px"}>
              <Typography variant="h4" style={{ width: "fit-content", marginRight: 8 }}>
                <Resource tag="PageCreateChallenge::Participants_InspirationsSharingPermissions" />
              </Typography>
              <ToolTip>
                <Resource tag="PageCreateChallenge::Participants_InspirationsSharingPermissions_Tip" />
              </ToolTip>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  gridGap="5px"
                >
                  <Box flex={10}>
                    <Typography>
                      <Resource tag="PageCreateChallenge::Participants_AllowParticipant" />
                    </Typography>
                  </Box>
                  <Box flex={2}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          <Resource tag="PageCreateChallenge::Participants_OFF" />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={state.allowParticipantsShare}
                          onChange={(e, c) =>
                            setState({
                              ...state,
                              allowParticipantsShare: c,
                            })
                          }
                          name="checkedA"
                          color="primary"
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          <Resource tag="PageCreateChallenge::Participants_ON" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  gridGap="5px"
                >
                  <Box flex={10}>
                    <Typography>
                      <Resource tag="PageCreateChallenge::Participants_AllowManagers" />
                    </Typography>
                  </Box>
                  <Box flex={2}>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          <Resource tag="PageCreateChallenge::Participants_OFF" />
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={state.allowManagersShare}
                          onChange={(e, c) =>
                            setState({
                              ...state,
                              allowManagersShare: c,
                            })
                          }
                          name="checkedB"
                          color="primary"
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="textSecondary">
                          <Resource tag="PageCreateChallenge::Participants_ON" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {open && (
            <GroupDetailsDialog
              culture={culture}
              open={open}
              setOpen={setOpen}
              groupDetail={groupDetailSelected}
              allWorkSpace={allWorkpaceSelected}
            />
          )}
          {groupOpen && (
            <CreateGroupDialog
              type="create"
              open={groupOpen}
              setOpen={setGroupOpen}
              countries={countries}
              departments={departments}
            />
          )}
          {editGroup && (
            <CreateGroupDialog
              open={editGroup}
              setOpen={setEditGroup}
              type="edit"
              countries={countries}
              departments={departments}
              groupData={group}
            />
          )}
          {deleteGroup && (
            <DeleteGroupDialog
              open={deleteGroup}
              setOpen={setDeleteGroup}
              groupName={group?.cultures ? group?.cultures[0]?.title : ""}
              noCancelButton={() => setDeleteGroup(false)}
              yesButton={() => {
                handleDeleteGroup();
                setDeleteGroup(false);
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => ({
  //culture: store.cultureState,
  countries: store.usersState.countries,
  departments: store.usersState.departaments,
  // allGroups: store.manageGroupsState.allGroups,
  //groups: store.manageGroupsState.groups,
  workspaceId: store.authState.auth.workspaceId,
  group: store.manageGroupsState.group,
  permission: store.authState.auth.permission,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  getCountries: (options: object) => dispatch(getAllCountries(options)),
  getDepartments: (options: object) => dispatch(getAllDepartaments(options)),
  // getAllManageGroups: (options: object) => dispatch(getAllManageGroups(options)),
  getManageGroups: (options: object) => dispatch(getManageGroups(options)),
  getManageGroup: (options: object) => dispatch(getManageGroup(options)),
  //putRevoveUserGroupManageGroups: (options: object) => dispatch(putRevoveUserGroupManageGroups(options)),
  deleteManageGroups: (options: object) =>
    dispatch(deleteManageGroups(options)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateChallengeStepParticipants);
