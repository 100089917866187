import { AnyAction } from "redux";
import { connect } from "react-redux";
import XDialog from "common/X/XDialog";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import Resource from "components/Resources/Resource";
import { IUsers } from "redux/reducers/usersReducers";
import { getAllUsers } from "redux/actions/UsersAction";
import { ExpertReviewPopupReview, PageState } from "redux/types/createChallenge";
import { ICultureState } from "redux/reducers/cultureReducer";
import React, { useCallback, useEffect, useState } from "react";
import Flow from "components/Workflow/WorkflowDialogsContent/Flow/Flow";
import AnalysisWorkflow from "components/Workflow/WorkflowDialogsContent/Analysis/Analysis";
import ReviewersWorkflow from "components/Workflow/WorkflowDialogsContent/Reviewers/Reviewers";
import BasicSettings from "components/Workflow/WorkflowDialogsContent/BasicSettings/BasicSetting";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  getChallengeDetailExpertReviewAction,
  getWorkflowAction,
  putWfPhaseExpertReview,
} from "redux/actions/Workflow/WorkflowActions";
import { getGroupTotalUsersAction } from "redux/actions/manageGroups/manageGroupsAction";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => any;
  state: ExpertReviewPopupReview;
  users: IUsers[];
  culture: ICultureState;
  saveValue: (value: any) => void;
  pageState: PageState;
  phaseId: string;
  workspaceId: string;
  challengeId: string;
  order: number;
  totalUsers: number;
}

interface IDispatchProps {
  getUserList: (options: object, culture:ICultureState) => Promise<any>;
  getExpertReviewPhase: (options: object, culture:ICultureState) => Promise<any>;
  putExpertReviewPhase: (options: object, culture:ICultureState) => Promise<any>;
  getWorkflow: (options: object, culture:ICultureState) => Promise<any>;
  getGroupTotal: (options: object, culture:ICultureState) => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    item: {
      borderRadius: "50px 0px 0px 50px;",
      paddingTop: "5px",
      paddingBottom: "5px",
      marginBottom: "10px",
    },
    selectedItem: {
      borderRadius: "50px 0px 0px 50px;",
      marginBottom: "10px",
      paddingTop: "5px",
      paddingBottom: "5px",
      background: theme.palette.primary.light,
      "& div": {
        "& span": {
          "& span": {
            fontWeight: 500,
          },
        },
      },
    },
  })
);

function PopupWorkflowExpertReview(props: IProps & IDispatchProps) {
  const {
    open,
    setOpen,
    state,
    users,
    culture,
    getUserList,
    pageState,
    phaseId,
    challengeId,
    workspaceId,
    getExpertReviewPhase,
    putExpertReviewPhase,
    order,
    getWorkflow,
    getGroupTotal,
    totalUsers
  } = props;
  const classes = useStyles();
  const { cultureTag } = culture?.culture || "";
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [loading, setLoading] = useState(true);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [localState, setLocalState] = useState(state);
  const [criteriaCompletion, setCriteriaCompletion] = useState(0)

  useEffect(() => {
    getGroupTotal({
      challengeId,
      workspaceId,
      groupIds: pageState.stepParticipants.groups.map(x => x.groupId)
    }, culture);
    getExpertReviewPhase({ phaseId, challengeId, workspaceId },culture).then(() => setLoading(false));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLocalState(state);
  }, [state]);

  const saveExpertReview = () => {
    putExpertReviewPhase({
      cultures:[{
        cultureTag,
        title:localState.basicSettings.title,
        description:localState.basicSettings.description,
      }],
      ...localState.basicSettings,
      ...localState.flow,
      responsibles: localState.flow.responsibles.map((x) => x.userId),
      criterias: localState.criterias,
      reviewers: localState.reviewers.map((x) => x.userId),
      challengeId,
      workspaceId,
      phaseId,
      order,
    }, culture).then((status) => {
      if(status === "PUT_EXPERTREVIEW_SUCCEEDED"){
        setOpen(false);
        getWorkflow({ challengeId, workspaceId, culture, cultureTag }, culture);
      }
    })
  };

  useEffect(() => {
    let totalWeight = 0;

    if(localState?.criterias?.length > 0) {
      localState?.criterias?.map(x => totalWeight = totalWeight + Number(x.weight)) 
    }

    setCriteriaCompletion(totalWeight)

    // eslint-disable-next-line
  }, [localState?.criterias])

  const action = (
    <Box display="flex" justifyContent="flex-end">
      <Box mr={1}>
        <Button color="primary" onClick={() => setOpen(false)}>
          <Resource tag="PageCreateChallenge::Workflow_Cancel" />
        </Button>
      </Box>
      <Box>
        <Button
          color="primary"
          variant="contained"
          disabled={criteriaCompletion < 100}
          onClick={() => {
            saveExpertReview();
            setOpen(false);
          }}
        >
          <Resource tag="PageCreateChallenge::Workflow_SaveChanges" />
        </Button>
      </Box>
    </Box>
  );

  useEffect(() => {
    getUserList({ q: "" }, culture);
  }, [getUserList, culture]);

  const setStateBasicSettings = useCallback(
    (newValue) => setLocalState((prev) => ({ ...prev, basicSettings: newValue })),
    [setLocalState]
  );

  const setStateFlow = useCallback((newValue) => setLocalState((prev) => ({ ...prev, flow: newValue })), [
    setLocalState,
  ]);

  const setStateReviewers = useCallback((newValue) => setLocalState((prev) => ({ ...prev, reviewers: newValue })), [
    setLocalState,
  ]);

  const setStateAnalysis = useCallback((newValue) => setLocalState((prev) => ({ ...prev, criterias: newValue })), [
    setLocalState,
  ]);

  const getCurrentScreen = () => {
    switch (currentScreen) {
      case 1:
        return (
          <BasicSettings
            fatherState={localState.basicSettings}
            setFatherState={setStateBasicSettings}
            stepsState={pageState}
            order={order}
          />
        );
      case 2:
        return (
          <AnalysisWorkflow setCriteriaCompletion={setCriteriaCompletion} culture={culture} criterias={localState.criterias} setAnalysisState={setStateAnalysis} />
        );
      case 3:
        return (
          <ReviewersWorkflow
            reviewers={localState.reviewers}
            setFatherState={setStateReviewers}
            users={users}
            culture={culture}
          />
        );
      case 4:
        return <Flow stepsState={pageState} order={order} flowState={localState.flow} flowSetState={setStateFlow} users={users} culture={culture} totalUsers={totalUsers} />;
      default:
        return <Box></Box>;
    }
  };

  return (
    <XDialog
      title={<Resource tag="PageCreateChallenge::Workflow_EditPhase" />}
      maxWidth="md"
      open={open}
      setOpen={setOpen}
      height={"550px"}
      actions={action}
      py={1.5}
      px={1.5}
    >
      {!loading ? (
        <Grid container>
          <Grid item xs={3} sm={3} md={3} xl={3}>
            <List disablePadding>
              <ListItem
                button
                id="SideBarWorkflow"
                onClick={() => setCurrentScreen(1)}
                className={currentScreen === 1 ? classes.selectedItem : classes.item}
              >
                <ListItemText className="text-button-sidebar">
                  <Resource tag="PageCreateChallenge::Workflow_BasicSettings" />
                </ListItemText>
              </ListItem>
              <ListItem
                button
                id="SideBarWorkflow"
                onClick={() => setCurrentScreen(2)}
                className={currentScreen === 2 ? classes.selectedItem : classes.item}
              >
                <ListItemText className="text-button-sidebar">
                  <Resource tag="PageCreateChallenge::Workflow_Analysis" />
                </ListItemText>
              </ListItem>
              <ListItem
                button
                id="SideBarWorkflow"
                onClick={() => setCurrentScreen(3)}
                className={currentScreen === 3 ? classes.selectedItem : classes.item}
              >
                <ListItemText className="text-button-sidebar">
                  <Resource tag="PageCreateChallenge::Workflow_Reviewers" />
                </ListItemText>
              </ListItem>
              <ListItem
                button
                id="SideBarWorkflow"
                onClick={() => setCurrentScreen(4)}
                className={currentScreen === 4 ? classes.selectedItem : classes.item}
              >
                <ListItemText className="text-button-sidebar">
                  <Resource tag="PageCreateChallenge::Workflow_Flow" />
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={1} sm={1} md={1} xl={1} style={{ maxWidth: "4%" }}>
            <Divider orientation={"vertical"} />
          </Grid>
          <Grid item xs={8} sm={8} md={8} xl={8}>
            <Box textAlign="right">
              <Typography color="textSecondary" variant="caption">
                <Resource tag="PageCreateChallenge::Workflow_TypeOfPhase" />
                <strong style={{ marginLeft: "8px", fontWeight: 500 }}>
                  <Resource tag="PageCreateChallenge::Workflow_ExpertReview" />
                </strong>
              </Typography>
            </Box>
            {getCurrentScreen()}
          </Grid>
        </Grid>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <CircularProgress />
        </Box>
      )}
    </XDialog>
  );
}

const mapStateToProps = (store: IAppState) => ({
  users: store.usersState.users,
  culture: store.cultureState,
  pageState: store.createChallengeState.pageState,
  state: store.createChallengeState.expertReviewPopupState,
  workspaceId: store.authState.auth.workspaceId,
  totalUsers: store.manageGroupsState.totalMembers
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getUserList: (options: object, culture:ICultureState) => dispatch(getAllUsers(options, culture)),
  getExpertReviewPhase: (options: object, culture:ICultureState) => dispatch(getChallengeDetailExpertReviewAction(options, culture)),
  putExpertReviewPhase: (options: object, culture:ICultureState) => dispatch(putWfPhaseExpertReview(options, culture)),
  getWorkflow: (options: object, culture:ICultureState) => dispatch(getWorkflowAction(options, culture)),
  getGroupTotal: (options: object, culture:ICultureState) => dispatch(getGroupTotalUsersAction(options, culture)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupWorkflowExpertReview);
