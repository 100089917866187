import { connect } from "react-redux";
import EMIcon from "components/Icon/Icon";
import { IAppState } from "redux/store/Store";
import CloseIcon from "@material-ui/icons/Close";
import React, { useCallback, useState } from "react";
import RestoreIcon from "@material-ui/icons/Restore";
import Resource from "components/Resources/Resource";
import { IWorkflowCard, PageState } from "redux/types/createChallenge";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PopupDeleteWorkflowStage from "../WorkflowDialogs/PopupDeleteWorkflowStage";

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ShortDateFormat } from "utils/FormatDate";
import PopupWorkflowCollaboration from "../WorkflowDialogs/PopupWorkflowCollaboration";
import { AvatarGroup } from "@material-ui/lab";

interface IProps {
  challengeId: string;
  index: number;
  card: IWorkflowCard;
  pageState?: PageState;
  deleteCard?: (phaseId: string) => any;
  handleSaveValue?: (value: any, currentIndex: number) => void;
  notifications?: Array<any>;
}

function CollaborationDraggableCard({
  index,
  card,
  pageState,
  deleteCard,
  handleSaveValue,
  challengeId,
  notifications,
}: IProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const closeButton = (
    <IconButton
      color="primary"
      onClick={() => setDeleteOpen(true)}
      style={{
        padding: 2,
        border: `2px solid ${theme.palette.primary.light}`,
      }}
    >
      <CloseIcon color="primary" fontSize={"small"} />
    </IconButton>
  );

  const saveValue = useCallback(
    (newValue) => {
      handleSaveValue && handleSaveValue(newValue, index);
    },
    [index, handleSaveValue]
  );

  const deletePhase = () => {
    deleteCard && deleteCard(card.phaseId);
  };

  return (
    <Box
      display={"inline-block"}
      width={250}
      height={448}
      bgcolor="white"
      borderRadius={11}
      border="1px solid rgba(136, 136, 136, 0.2)"
      p={1.5}
      style={{ opacity: 1 }}
    >
      {handleSaveValue && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            color="primary"
            onClick={() => setOpen(true)}
            style={{ padding: 4, background: theme.palette.primary.light }}
          >
            <EMIcon
              color={theme.palette.primary.main}
              size={16}
              icon="filters"
            />
          </IconButton>
        </Box>
      )}
      <Box
        py={1}
        px={2}
        bgcolor="white"
        style={{ opacity: 1, marginTop: handleSaveValue ? 0 : 8 }}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          style={{
            display: "block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {card.order}.{" "}
          {card?.title || card?.cultures?.[0].title || (
            <Resource tag="PageCreateChallenge::Workflow_Collaboration" />
          )}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          <Resource tag="PageCreateChallenge::Workflow_Type" />{" "}
          <Resource tag="PageCreateChallenge::Workflow_Collaboration" />
        </Typography>
        <Box mt={1} mb={2}>
          <Divider style={{ width: "100%" }} />
        </Box>
        <Box overflow={notifications ? "auto" : "none"} height={320}>
          <Box
            minHeight={320}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gridGap={notifications ? 32 : 0}
          >
            {notifications && (
              <Box>
                <Typography color="textSecondary" variant="body2">
                  <Resource tag={"PageCreateChallenge::Notifications"} />:
                </Typography>
                {notifications.map((noti, index) => (
                  <Box p={1} key={index}>
                    <Typography variant="body2" color="error">
                      <Resource tag={noti.message.replace(".", "::")} />
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Box maxHeight={120} style={{ overflowY: "auto" }}>
              <Typography color="textSecondary" variant="body2">
                <Resource tag="PageCreateChallenge::Workflow_GroupsInAction" />
              </Typography>
              {pageState?.stepParticipants?.groups &&
                pageState?.stepParticipants?.groups?.map((group: any, index: number) => (
                  <Box display="flex" alignItems="center" mt={1} key={group.id ?? index}>
                    <PermIdentityIcon color="disabled" />
                    <Box ml={0.5}>
                      <Typography color="textPrimary" variant="body2">
                        {group?.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
            <Box mt={pageState && pageState?.stepParticipants?.groups?.length > 2 ? -2.5 : 0}>
              <Typography color="textSecondary" variant="body2">
                <Resource tag="PageCreateChallenge::Workflow_Transition" />
              </Typography>
              <Box display="flex" alignItems="center" mt={1}>
                {!card?.isAutomaticFlow ? (
                  card?.responsibles && (
                    <AvatarGroup max={3}>
                      {card?.responsibles.map((responsible, index) => (
                        <Avatar
                          key={index}
                          style={{ width: 35, height: 35, marginRight: 8 }}
                          src={responsible.photo}
                          alt={responsible.fullName}
                        />
                      ))}
                    </AvatarGroup>
                  )
                ) : (
                  <Box>
                    <Box fontWeight={500}><Resource tag="PageCreateChallenge::AutomaticTransitionRules" /></Box>
                    {card?.evaluationType === 1 ? (
                      <Box display="flex" alignItems="center" mt={0.5}>
                        <Box fontWeight={500} mr={0.4}>
                          {card.flowRuleParticipants ?? 0}
                        </Box>
                        {  (card.flowRuleParticipants ?? 0) === 1 ? <Resource tag="PageCreateChallenge::Workflow_Review" /> : <Resource tag="PageCreateChallenge::Workflow_Reviews" /> }
                        <Box height={15} mx={1}>
                          <Divider orientation="vertical" />
                        </Box>
                        <Box fontWeight={500} mr={0.4}>
                          {card.flowRuleAverage ?? 0}
                        </Box>
                        <Resource tag="PageCreateChallenge::Workflow_Stars" />
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center" mt={0.5}>
                        <Box fontWeight={500} mr={0.4}>
                          {card.flowRuleAverage ?? 0} %
                        </Box>
                        <Resource tag="PageCreateChallenge::Workflow_Belief" />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
            <Box>
              <Typography color="textSecondary" variant="body2">
                <Resource tag="PageCreateChallenge::Workflow_TimePhase" />
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between" position="relative" mt={1}>
                <Box display="flex" alignItems="center">
                  <RestoreIcon color="disabled" />
                  <Box ml={1}>
                    <Typography color="textPrimary" variant="body2">
                      {card?.endDate || card?.endDays ? (
                        <Box>
                          {(card?.endDate && (
                            <Box display="flex" alignItems="center">
                              <Resource tag="PageCreateChallenge::Workflow_Until" />{" "}
                              <Box ml={0.4}>
                                <ShortDateFormat date={card?.endDate} />
                              </Box>
                            </Box>
                          )) || (
                            <Box display="flex" alignItems="center">
                              {card?.endDays}{" "}
                              <Box ml={0.4}>
                                <Resource tag="PageCreateChallenge::Workflow_DaysPerIdea" />
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ) : (
                        "-------"
                      )}
                    </Typography>
                  </Box>
                </Box>
                {deleteCard && (
                  <Box position="absolute" top={0} right={-16}>
                    {closeButton}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {handleSaveValue && open && (
        <PopupWorkflowCollaboration
          open={open}
          setOpen={(openVal) => setOpen(openVal)}
          phaseId={card.phaseId}
          challengeId={challengeId}
          order={card.order}
          saveValue={saveValue}
        />
      )}

      {deleteCard && deleteOpen && (
        <PopupDeleteWorkflowStage
          open={deleteOpen}
          setOpen={(openVal) => setDeleteOpen(openVal)}
          confirmAction={deletePhase}
        />
      )}
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => ({
  pageState: store.createChallengeState.pageState,
});

export default connect(mapStateToProps)(CollaborationDraggableCard);
