import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  getAllKeyTasks,
  getTransitionKeyTasks,
  getValidationKeyTasks,
  getEvaluationKeyTasks,
  getCorrectionKeyTasks,
  getFilters,
  getTransitionSummary,
} from "redux/actions/keyTasks/KeyTasksAction";
import { IAppState } from "redux/store/Store";
import { IApplication } from "redux/reducers/tenantReducer";
import {
  IKeyTasks,
  ITransition,
  IKeyTasksState,
  IEvaluation,
  IValidation,
  IFilters,
  ICorrection,
  IKeyTasksSummary,
} from "redux/reducers/keyTasks/keyTasksReducer";
import { ICultureState } from "redux/reducers/cultureReducer";
import KeyTaskTransitionBar from "components/KeyTaskTransitionBar/KeyTaskTransitionBar";
import KeyTasksEvaluationList from "components/KeyTasksEvaluationList/KeyTasksEvaluationList";
import KeyTaskAllList from "containers/KeyTaskAllList/KeyTaskAllList";
import KeyTaskValidationList from "containers/KeyTaskValidationList/KeyTaskValidationList";
import KeyTasksFilterButtons from "components/KeyTasksFilterButtons/KeyTasksFilterButtons";
import { Box } from "@material-ui/core";
import KeyTasksPageSkeleton from "./KeyTasksPage.Skeleton";
import { postIdeasStars } from "redux/actions/ideas/IdeaAction";
import KeyTasksCorrectionList from "containers/KeyTaskCorrectionList/KeyTaskCorrectionList";
import DynamicInfiniteScroll from "./DynamicInfiniteScroll";
import { IProfileGetDataAction, getProfile } from "redux/actions/ProfileActions";

const pagination = {
  current: 1,
  pageSize: 10,
  finished: false,
};

const status = {
  init: true,
  refreshControl: false,
};

const options = {
  ideaId: "",
  applicationId: 0,
  status: 1,
  pageNumber: status.init ? 1 : pagination.current,
  pageSize: pagination.pageSize,
  isInfinite: false,
  cultureTag: "",
  typeTask: "",
  culture: {},
};

interface IDispatchProps {
  getKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getTransitionKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getValidationKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getEvaluationKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getCorrectionKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getFilters: (options: object) => Promise<IKeyTasksState>;
  getTransitionSummary:(options: object) => Promise<IKeyTasksState>;
  getProfile: () => Promise<IProfileGetDataAction>;
  postIdeasStars?: (options: object) => Promise<any>;
}

interface IStateProps {
  application: IApplication;
  culture: ICultureState;
  keyTasks: IKeyTasks;
  keyTasksStatus: string;
  transitionTasks: ITransition;
  evaluationTasks: IEvaluation;
  validationTasks: IValidation;
  correctionTasks: ICorrection;
  filters: IFilters;
  summary: IKeyTasksSummary[]
  transitionKeyTasksStatus: string;
  filterChallengeId?:string;
  filterSearchTerm?:string;
}

function KeyTasks(props: IDispatchProps & IStateProps) {
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("ALL");
  const [secondTab, setSecondTab] = useState("1");
  const [typeTaskState, setTypeTask] = useState("");

  const {
    transitionTasks,
    keyTasks,
    evaluationTasks,
    validationTasks,
    culture,
    correctionTasks,
    filters,
    summary,
    transitionKeyTasksStatus,
    filterChallengeId,
    filterSearchTerm
  } = props;
  const {
    getKeyTasks,
    getTransitionKeyTasks,
    getValidationKeyTasks,
    getEvaluationKeyTasks,
    getCorrectionKeyTasks,
    getTransitionSummary,
    getFilters,
    getProfile,
    postIdeasStars,
  } = props;

  useEffect(() => {
    options.applicationId = props.application.applicationId;
    options.cultureTag = props.culture.culture.cultureTag;
    options.culture = props.culture;
    getKeyTasks(options).then(() => {
      setLoading(false);
    });

    getProfile();
    getTransitionSummary(options);
    getValidationKeyTasks(options);
    getEvaluationKeyTasks(options);
    getCorrectionKeyTasks(options);
    getFilters(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshTabs = (options:object) => {
    getTransitionSummary(options);
    getValidationKeyTasks(options);
    getEvaluationKeyTasks(options);
    getCorrectionKeyTasks(options);
  }

  const filterKeyTasksEvaluations = (typeTask: string, status?: string) => {
    setTypeTask(typeTask);
    switch (typeTask) {
      case "1":
        setActive("EVALUATIONS");
        break;
      case "2":
        setActive("VALIDATIONS");
        break;
      case "3":
        setActive("TRANSITIONS");
        break;
      case "4":
        setActive("CORRECTIONS");
        break;
      default:
        setActive("ALL");
        break;
    }
  };

  const postStars = async (params: object) => {
    postIdeasStars &&
      postIdeasStars(params).then(() => {
        Promise.all([
          getProfile(),
          getKeyTasks(options),
          getEvaluationKeyTasks(options),
          getCorrectionKeyTasks(options),
        ]).finally(() => setLoading(false));
      });
  };

  return (
    <>
      {loading ? (
        <KeyTasksPageSkeleton />
      ) : (
        <Box
          className="keyTasks"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: 32,
          }}
        >
          <KeyTasksFilterButtons
            activeTab={active}
            props={keyTasks}
            getKeyTasks={filterKeyTasksEvaluations}
          />
          {typeTaskState === "4" && (
            <DynamicInfiniteScroll
              dataList={correctionTasks}
              getAction={(pageNumber, pageSize) =>
                getCorrectionKeyTasks({
                  pageNumber,
                  pageSize,
                  isInfinite: true,
                  challengeId:filterChallengeId??null,
                  searchTerm:filterSearchTerm??null,
                })
              }
              pageSize={10}
              isAutomatic
            >
              <KeyTasksCorrectionList
                correctionList={correctionTasks?.result}
                culture={culture}
              />
            </DynamicInfiniteScroll>
          )}
          {typeTaskState === "3" && (
            <KeyTaskTransitionBar
              transitionKeyTasksStatus={transitionKeyTasksStatus}
              secondTab={secondTab}
              setSecondTab={setSecondTab}
              summary={summary}
              keyTasksTransitions={transitionTasks}
              culture={culture}
              filters={filters}
              getTransitionKeyTasks={getTransitionKeyTasks}
            />
          )}
          {typeTaskState === "2" && (
            <DynamicInfiniteScroll
              dataList={validationTasks}
              getAction={(pageNumber, pageSize) =>
                getValidationKeyTasks({
                  pageNumber,
                  pageSize,
                  isInfinite: true,
                  challengeId:filterChallengeId??null,
                  searchTerm:filterSearchTerm??null,
                })
              }
              pageSize={10}
              isAutomatic
            >
              <KeyTaskValidationList
                validationList={validationTasks?.result}
                radioButtons={true}
                culture={culture}
                filters={filters}
                getValidationKeyTasks={getValidationKeyTasks}
              />
            </DynamicInfiniteScroll>
          )}
          {typeTaskState === "1" && (
            <DynamicInfiniteScroll
              dataList={evaluationTasks}
              getAction={(pageNumber, pageSize) =>
                getEvaluationKeyTasks({
                  pageNumber,
                  pageSize,
                  isInfinite: true,
                })
              }
              pageSize={10}
              isAutomatic
            >
              <KeyTasksEvaluationList
                postIdeasStars={postStars}
                evaluationList={evaluationTasks?.result}
                culture={culture}
                filters={filters}
                getEvaluationKeyTasks={getEvaluationKeyTasks}
                refreshTabs={refreshTabs}
              />
            </DynamicInfiniteScroll>
          )}
          {!typeTaskState && (
            <DynamicInfiniteScroll
              dataList={keyTasks}
              pageSize={10}
              getAction={(pageNumber, pageSize) =>
                getKeyTasks({ pageNumber, pageSize, isInfinite: true })
              }
              isAutomatic
            >
              <KeyTaskAllList
                filterKeyTasksEvaluations={filterKeyTasksEvaluations}
                allList={keyTasks}
                postIdeasStars={postStars}
              />
            </DynamicInfiniteScroll>
          )}
        </Box>
      )}
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getKeyTasks: (options: object) => dispatch(getAllKeyTasks(options)),
    getTransitionKeyTasks: (options: object) =>
      dispatch(getTransitionKeyTasks(options)),
    getValidationKeyTasks: (options: object) =>
      dispatch(getValidationKeyTasks(options)),
    getEvaluationKeyTasks: (options: object) =>
      dispatch(getEvaluationKeyTasks(options)),
    getCorrectionKeyTasks: (options: object) =>
      dispatch(getCorrectionKeyTasks(options)),
    getFilters: (options: object) => dispatch(getFilters(options)),
    postIdeasStars: (options: object) => dispatch(postIdeasStars(options)),
    getTransitionSummary:(options: object) => dispatch(getTransitionSummary(options)),
    getProfile: () => dispatch(getProfile())
  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {
    application: store.tenantState.application,
    culture: store.cultureState,
    keyTasks: store.keyTasksState.keyTasks,
    transitionKeyTasksStatus: store.keyTasksState.transitionKeyTasksStatus,
    keyTasksStatus: store.keyTasksState.keyTaskStatus,
    transitionTasks: store.keyTasksState.transitionTasks,
    validationTasks: store.keyTasksState.validationTasks,
    evaluationTasks: store.keyTasksState.evaluationTasks,
    correctionTasks: store.keyTasksState.correctionTasks,
    filters: store.keyTasksState.filters,
    summary: store.keyTasksState.summary,
    filterChallengeId:store.keyTasksState.challengeId,
    filterSearchTerm:store.keyTasksState.searchTerm
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyTasks);