import React from "react";
import { Box } from "@material-ui/core";
import SkeletonGrid from "common/UI/Skeleton/SkeletonGrid";
import SkeletonTitle from "common/UI/Skeleton/SkeletonTitle";

export default function FeedPageSkeleton() {
  return (
    <>
      <Box mb={5}>
        <SkeletonTitle />
        <SkeletonGrid col={3} row={1} rowHeight={80} />

        <SkeletonGrid col={3} row={1} rowHeight={200} />

        <SkeletonGrid col={1} row={5} rowHeight={200} />
      </Box>
    </>
  );
}
