import React from "react";
import XCarousel from "common/X/XCarousel";
import Resource from "../../Resources/Resource";
import { Grid, Box, Typography } from "@material-ui/core";
import { IChallengeHighlighted } from "redux/types/challenges";
import HighlightedCard from "../../ChallengesCards/HighligthedCard/HighligthedCard";

interface IProps {
  challenges: IChallengeHighlighted;
}

function HighlightedCarousel(props: IProps) {
  const { challenges } = props;

  return (
    <>
      <Grid container>
        <Box mt={0} mb={0}>
          <Typography variant="h3">
            <Resource tag="PageChallenges::Challenge_Highlighted" />
          </Typography>
        </Box>
      </Grid>

      <XCarousel
        autoPlay
        itemsToShow={1}
        infinite={challenges?.result?.length > 1}
        partialVisible={challenges?.result?.length > 1}
        showDots={challenges?.result?.length > 1}
      >
        {challenges?.result?.length > 0 &&
          challenges?.result?.map((challenge, index) => {
            return <HighlightedCard key={index} challenge={challenge} />;
          })}
      </XCarousel>
    </>
  );
}

export default HighlightedCarousel;
