import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Button, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import Resource from "components/Resources/Resource";

const useStyles = makeStyles(()=>({
  infinite:{
    overflow:"inherit !important"
  }  
}))

interface IProps {
  dataList: dataList;
  pageSize: number;
  getAction: (pageNumber: number, pageSize: number) => Promise<any> | (() => Promise<void>);
  isAutomatic?: boolean;
  status?: string;
  children: any;
}

interface dataList {
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
  result: any[];
}

export default function DynamicInfiniteScroll(props: IProps) {
  const classes = useStyles();
  const { dataList, pageSize, status, isAutomatic, getAction } = props;
  const check: boolean = dataList?.meta?.currentPage < dataList?.meta?.lastPage;

  const viewMoreData = async () => {
    if (dataList?.meta?.currentPage > 0 && check) {
      await getAction(dataList.meta.currentPage + 1, pageSize);
    } else if (check) {
      await getAction(1, pageSize);
    }
  };

  return isAutomatic ? (
    <>
      <InfiniteScroll
        dataLength={dataList?.meta?.to??0}
        next={viewMoreData}
        hasMore={check}
        className={classes.infinite}
        loader={
          <Grid container key={`Loader`}>
            <Grid item xs={12}>
              {status !== "FAILED" ? (
                <Box my={2} textAlign="center" alignContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              ) : (
                <Box textAlign="right" justifyContent="flex-end">
                  <Button color="primary">
                    <Resource tag="Common::Refresh" />
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        }
        
      >
        {props.children}
      </InfiniteScroll>
    </>
  ) : (
    <Box width="100%">
      <Box width="100%">{props.children}</Box>
      {check && (
        <Box my={status === "LOADING" ? 2 : 0} display="flex" minHeight={36} position="relative" textAlign="center" alignContent="center" alignItems="center">
          {status === "LOADING" && <Box marginX="auto"> <CircularProgress /> </Box>}
          <Button
            color="primary"
            onClick={() => viewMoreData()}
            style={{ textTransform: "uppercase", position: "absolute", right: "0" }}
          >
            <Resource tag={"Common::ViewMore"} />
          </Button>
        </Box>
      )}
    </Box>
  );
}
