import React from "react";
import Typography from "@material-ui/core/Typography";
import { ICultureState } from "redux/reducers/cultureReducer";
import DialogUserDetails from "views/Settings/ManageUsers/Dialogs/DialogUserDetails";
import {
  Avatar,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { AnyAction } from "redux";
import { getUserDetail } from "redux/actions/manageUsers/ManageUsersAction";
import { connect } from "react-redux";
import { IManageUsersState, IUserDetail } from "redux/types/manageUsers";
import { IAllManageGroups } from "redux/types/manageGroups";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";

interface IDispatchProps {
  getUserDetail: (options: object) => Promise<IManageUsersState>;
}

interface IProps {
  checked?: boolean;
  principalText?: string;
  photo?: string;
  subtitle?: string;
  groups?: JSX.Element;
  points?: any; //TODO: mudar esses 4 com 'any' quando estiver certinho os dados da api.
  typeUser?: any;
  online?: any;
  finalContent?: JSX.Element;
  alignItems?: boolean;
  permissionTab?: boolean;
  culture: ICultureState;
  userId: string;
  workspaceId: string;
  userDetails: IUserDetail;
  allGroups: IAllManageGroups[];
  countries: ICountries[];
  departments: IDepartments[];
  userDetailStatus: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  })
);

function UserListItem(props: IProps & IDispatchProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    checked,
    principalText,
    subtitle,
    photo,
    groups,
    points,
    typeUser,
    online,
    finalContent,
    alignItems,
    permissionTab,
    userId,
    workspaceId,
    userDetails, 
    allGroups,
    countries,
    departments,
    userDetailStatus,
    culture,
    getUserDetail
  } = props;
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(false);
  function handleClickOpen(userId: string) {
    Promise.all([
      getUserDetail({
        userId,
        workspaceId,
      })
    ]).finally(() => setOpen(true))
    
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: checked
            ? theme.palette.primary.light
            : "transparent",
          borderRadius: "5px",
        }}
      >
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={2}
          sm={3}
          md={permissionTab ? 3 : 2}
          lg={permissionTab ? 2 : 1}
        >
          <Avatar src={photo} className={classes.avatar} />
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify={alignItems ? "center" : "flex-start"}
          xs={5}
          sm={5}
          md={permissionTab ? 5 : 4}
          lg={permissionTab ? 5 : 4}
        >
          {principalText && (
            <Typography
              variant="body1"
              noWrap
              onClick={(e: any) => {
                handleClickOpen(userId);
                e.stopPropagation();
              }}
              onFocus={(e: any) => e.stopPropagation()}
            >
              {principalText}
            </Typography>
          )}
          {matchSm && groups && <>{groups}</>}
          <Typography variant="caption" color="textSecondary" noWrap>
            {subtitle}
          </Typography>
        </Grid>
        {!matchSm && groups && (
          <Grid item container justify="center" xs={2} sm={2} md={2} lg={2}>
            {groups}
          </Grid>
        )}
        {!matchSm && points && (
          <Grid item container justify="center" xs={3} sm={3} md={2} lg={2}>
            <Typography variant="body1" noWrap>
              {points}
            </Typography>
          </Grid>
        )}
        {typeUser && points && (
          <Grid
            item
            container
            direction="column"
            justify="flex-start"
            style={{ textAlign: "right" }}
            xs={5}
            sm={4}
            md={2}
            lg={3}
          >
            <Typography variant="body1" noWrap>
              {typeUser}
            </Typography>
            {matchSm && points && points}
            {online && (
              <Typography variant="caption" noWrap>
                {online}
              </Typography>
            )}
          </Grid>
        )}
        {finalContent && (
          <Grid
            item
            container
            justify="flex-end"
            alignItems={alignItems ? "center" : "flex-start"}
            xs={5}
            sm={4}
            md={permissionTab ? 4 : 7}
            lg={permissionTab ? 5 : 7}
          >
            {finalContent}
          </Grid>
        )}
      </Grid>
      <DialogUserDetails 
        open={open} 
        setOpen={setOpen} 
        userId={userId}
        userDetails={userDetails}
        countries={countries}
        departments={departments}
        allGroups={allGroups}
        userDetailStatus={userDetailStatus}
        workspaceId={workspaceId}
        culture={culture}
      />
    </>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getUserDetail: (options: object) => dispatch(getUserDetail(options)),
  };
};

const mapStateToProps = (store: IAppState) => ({
  workspaceId: store.authState.auth.workspaceId,
  userDetails: store.manageUsersState.userDetails,
  allGroups: store.manageGroupsState.allGroups,
  countries: store.usersState.countries,
  departments: store.usersState.departaments,
  userDetailStatus: store.manageUsersState.userDetailStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserListItem);