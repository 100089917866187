import React, { useEffect, useState } from "react";
import {
  getInsightsComments,
  postInsightsComments,
  postLikeComment,
  postDislikeComment,
} from "../../redux/actions/insight/InsightsActions";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IAppState } from "../../redux/store/Store";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import { IProfile } from "../../redux/reducers/profileReducer";
import {
  Avatar,
  Box,
  CircularProgress,
  Typography,
  Grow,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CommentItem from "../CommentItem/CommentItem";
import "./CommentList.scss";
import Resource, { resource } from "../Resources/Resource";
import {
  IInsightComments,
  IInsightsState,
} from "redux/reducers/insight/insightsReducer";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  })
);

interface IProps {
  insightId: string;
  comments: IInsightComments;
  isAllowedComment: boolean;
  applicationId: any;
  culture: ICultureState;
  profile: IProfile[];
  initialPageSize?: number;
  commentInsightStatus: string;
  workspaceId: string;
}

interface IDispatchProps {
  postInsightsComments: (options: object) => Promise<IInsightsState>;
  getInsightsComments: (options: object) => Promise<IInsightsState>;
  postDislikeComment: (options: object) => Promise<IInsightsState>;
  postLikeComment: (options: object) => Promise<IInsightsState>;
}

const pagination = {
  current: 1,
  pageSize: 0,
  finished: false,
};

const status = {
  init: true,
  refreshControl: false,
  isActive: true,
};

const options = {
  insightId: "",
  applicationId: 2,
  pageNumber: status.init || !status.isActive ? 1 : pagination.current + 1,
  pageSize: pagination.pageSize,
  culture: {},
};

function CommentListInsights(props: IProps & IDispatchProps) {
  const [commentValue, setCommentValue] = useState("");
  const [reply, setReply] = useState("");
  const [countPreviousClick, setClick] = useState(1);
  const {
    applicationId,
    getInsightsComments,
    postInsightsComments,
    postLikeComment,
    postDislikeComment,
    initialPageSize,
    commentInsightStatus,
    workspaceId,
    culture
  } = props;
  const comments = props?.comments;
  const isAllowedComment = props?.isAllowedComment;
  const profile = props?.profile?.[0];
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();

  let captcha: string = "";

  const replyHandler = (value: any) => {
    if (reply === value) {
      setReply("");
    } else {
      setReply(value);
    }
  };
  const handleChange = (e: any) => {
    setCommentValue(e.target.value);
  };
  const handleEnter = (e: any) => {
    if (e?.keyCode === 13) {
      postComment(e);
    }
  };
  const commentOptions = {
    insightId: "",
    status: 1,
    pageNumber: 1,
    pageSize: options.pageSize + initialPageSize!,
    culture: {},
  };
  commentOptions.culture = props.culture;
  commentOptions.insightId = props.insightId;

  useEffect(() => {
    let isMounted = true;
    isMounted && getInsightsComments(commentOptions);
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInsightsComments]);

  const postLikeComments = (commentId: string, userLiked: boolean) => {
    if (userLiked === false) {
      postLikeComment({
        ...commentOptions,
        insightId: props?.insightId,
        commentId: commentId,
        applicationId: applicationId,
        workspaceId: workspaceId,
      });
    } else {
      postDislikeComment({
        ...commentOptions,
        insightId: props?.insightId,
        commentId: commentId,
        applicationId: applicationId,
        workspaceId: workspaceId,
      });
    }
  };

  async function postReply(replyMessage: string) {
    executeRecaptcha &&
      (await executeRecaptcha("CommentListInsights").then((response) => {
        captcha = response;
      }));

    options.pageSize = initialPageSize! + 1;
    postInsightsComments({
      ...options,
      insightId: props?.insightId,
      message: replyMessage,
      commentParentId: reply,
      applicationId: applicationId,
      captcha: captcha,
      workspaceId: workspaceId,
      culture
    });
    setReply("");
  }

  async function postComment(e?: any) {
    executeRecaptcha &&
      (await executeRecaptcha("CommentListInsights").then((response) => {
        captcha = response;
      }));

    if (reply) {
      commentOptions.pageSize = initialPageSize! + 1;
      postInsightsComments({
        ...commentOptions,
        ideaId: props?.insightId,
        message: commentValue,
        commentParentId: reply,
        applicationId: applicationId,
        captcha: captcha,
        workspaceId: workspaceId,
      });
    } else {
      commentOptions.pageSize = initialPageSize! + 1;
      postInsightsComments({
        ...commentOptions,
        ideaId: props?.insightId,
        message: commentValue,
        applicationId: applicationId,
        captcha: captcha,
        workspaceId: workspaceId,
      });
    }
    setCommentValue("");
  }

  const handleMoreComments = () => {
    if (comments?.result?.length >= comments?.meta?.total) {
      return;
    } else {
      setClick(countPreviousClick + 1);
      commentOptions.pageSize =
        commentOptions.pageSize + initialPageSize! * countPreviousClick;
      getInsightsComments(commentOptions);
    }
  };

  return (
    <Box width={"100%"} className="comment-container">
      <Box
        width={"100%"}
        display="flex"
        justifyContent={
          comments?.messagesCount > comments?.totalMessagePages
            ? "space-between"
            : "flex-end"
        }
        paddingY={1}
      >
        {comments?.messagesCount > comments?.totalMessagePages ? (
          <Typography
            onClick={handleMoreComments}
            style={{ textTransform: "lowercase", cursor: "pointer" }}
            variant="caption"
            color="primary"
          >
            <Resource tag="Common::PreviousComments" />
          </Typography>
        ) : (
          <Typography
            onClick={handleMoreComments}
            style={{ textTransform: "lowercase", cursor: "pointer" }}
            variant="caption"
            color="primary"
          ></Typography>
        )}
        {comments?.messagesCount > 0 ? (
          <Typography variant="caption" color="textSecondary">
            {comments?.totalMessagePages} <Resource tag="Common::of" />{" "}
            {comments?.messagesCount}
          </Typography>
        ) : (
          ""
        )}
      </Box>

      {commentInsightStatus !== "LOADING" ? (
        <Grow in>
          <Box width="100%" className="comment-list">
            {comments &&
              comments?.result?.map((comment) => {
                return (
                  <CommentItem
                    key={comment.commentId}
                    commentId={comment.commentId}
                    userId={comment.userId}
                    fullName={comment.fullName}
                    photo={comment.photo}
                    timeAgo={comment.timeAgo}
                    message={comment.message}
                    isLikedByMe={comment.isLikedByMe}
                    likesCount={comment.likesCount}
                    replies={comment.replies}
                    timeStampResource={comment.timeStampResource}
                    replyHandler={replyHandler}
                    postLikeComment={postLikeComments}
                    postReply={postReply}
                    profile={profile}
                    isAllowedComment
                    canShowMenu={false}
                  />
                );
              })}
          </Box>
        </Grow>
      ) : (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      )}
      {isAllowedComment && (
        <Box className="comment-bar" width="90%">
          <Avatar
            src={profile?.photo}
            alt={profile?.fullName}
            className={classes.thumbnail}
          />
          <Box className="comment-input" width="100%">
            <TextField
              fullWidth
              placeholder={`${resource(
                props.culture,
                "CommentsArea::AddComment"
              )}...`}
              style={{ width: "100%" }}
              disabled={!isAllowedComment}
              onChange={handleChange}
              onKeyUp={handleEnter}
              value={commentValue}
              type="text"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={postComment}
              disabled={!commentValue}
            >
              <Resource tag={"CommentsArea::Post"} />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    addStatus: store.addValueState.status,
    comments: store.insightsState.insightsComments,
    culture: store.cultureState,
    applicationId: store.authState.auth.applicationId,
    profile: store.profileState.profile,
    commentInsightStatus: store.insightsState.commentInsightStatus,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getInsightsComments: (options: object) =>
      dispatch(getInsightsComments(options)),
    postInsightsComments: (options: object) =>
      dispatch(postInsightsComments(options)),
    postLikeComment: (options: object) => dispatch(postLikeComment(options)),
    postDislikeComment: (options: object) =>
      dispatch(postDislikeComment(options)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentListInsights);
