import React, { useState } from 'react';
import InvestmentList from '../../containers/InvestmentList/InvestmentList'
import HistoricInvestmentList from '../../containers/HistoryInvestmentList/HistoryInvestmentList';
import { IMyActivitiesInvestmentsActive, IMyActivitiesInvestmentsHistory } from 'redux/types/myActivities';
import Resource from '../../components/Resources/Resource';
import { AppBar, Tab } from '@material-ui/core';
import { TabPanel, TabList, TabContext } from '@material-ui/lab';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DynamicInfiniteScroll from 'views/KeyTasks/DynamicInfiniteScroll';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      padding: '12px 0'
    }
  }),
);

interface IStateProps {
    activeInvestments?: IMyActivitiesInvestmentsActive;
    investmentsHistory?: IMyActivitiesInvestmentsHistory;
    investmentMeta: any;
    showMore: (typeTabs?: string, pageNumber?: number, pageSize?: number, isInfinite?: boolean) => Promise<any>
}

function MyActivitiesInvestList(props: IStateProps) {
    const { activeInvestments, investmentsHistory, investmentMeta, showMore } = props;
    const [investTab, setTab] = useState('1')
    const classes = useStyles()
    const handleChange = (event: any, newValue: string) => {
        setTab(newValue)
    };

    return (
        <TabContext value={investTab}>
            {!investmentMeta?.total && 
              !investmentsHistory?.meta?.total
            ?
                <>
                    <div className="MyActivity-error" style={{ textAlign: "center" }}>
                        <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
                    </div>
                </>
            :
                <>
                    <AppBar position="static" elevation={0}>
                        <TabList onChange={handleChange}>
                            <Tab value="1" label={
                                <Resource tag="PageMyActivity::Investments_Active" args={[investmentMeta?.total ?? 0]} />
                            } />
                            <Tab value="2" label={
                                <Resource tag="PageMyActivity::Investments_History" args={[investmentsHistory?.meta?.total ?? 0]} />
                            } />
                        </TabList>
                    </AppBar>
                    <TabPanel className={classes.tabPanel} value="1">
                        {activeInvestments ?
                            <DynamicInfiniteScroll 
                                dataList={activeInvestments} 
                                getAction={(pageNumber,pageSize) => showMore(investTab, pageNumber, pageSize, true)}
                                pageSize={6}
                                //isAutomatic
                            >
                                <InvestmentList activeInvestments={activeInvestments.result} />
                            </DynamicInfiniteScroll>
                            :
                            <div className="MyActivity-error" style={{ textAlign: 'center' }}>
                                <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
                            </div>
                        }
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value="2">
                        {investmentsHistory ?
                            <DynamicInfiniteScroll 
                                dataList={investmentsHistory} 
                                getAction={(pageNumber,pageSize) => showMore(investTab, pageNumber, pageSize, true)}
                                pageSize={6}
                                //isAutomatic
                            >
                                <HistoricInvestmentList investmentsHistory={investmentsHistory.result} />
                            </DynamicInfiniteScroll>
                            :
                            <div className="MyActivity-error" style={{ textAlign: 'center' }}>
                                <Resource tag="PageMyActivity::Error_NoActivityAvailable" />
                            </div>
                        }
                    </TabPanel>
                </>
            }
        </TabContext>
    )
}

export default MyActivitiesInvestList;