export interface IManageGroups {
  meta: {
    currentPage: number
    from: number
    lastPage: number
    perPage: number,
    to: number,
    total: number
  },
  result: IResultManageGroups[]
}

export interface IManageGroup {
  groupId: string,
  cultures: [
    {
      cultureTag: string,
      title: string,
      description: string
    }
  ],
  countries: [],
  departments: []
}

export  interface IResultUsersManageGroups {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: [
    {
      userId: string,
      fullName: string,
      email: string,
      photo: string
    }
  ]
}

export interface IResultManageGroups {
  groupId: string,
  groupName: string,
  numberOfMember: number,
  users: IResultUsersManageGroups;
}

export interface IAllManageGroups {
  groupId: string,
  title: string
}

export interface ICultureTag {
  resourceId: string,
  cultureTag: string
}

export interface IManageGroupsMembers {
  meta: {
    currentPage: number
    from: number
    lastPage: number
    perPage: number,
    to: number,
    total: number
  },
  result: IResultManageGroupsMembers[]
}

export interface IResultManageGroupsMembers {
  userId: string,
  fullName: string,
  email: string,
  photo: string
}

// Create Action Constants
export enum ManageGroupsActionTypes {
  GET_USERS_GROUPS_LOADING = 'GET_USERS_GROUPS_LOADING',
  GET_USERS_GROUPS_SUCCESS = 'GET_USERS_GROUPS_SUCCESS',
  GET_USERS_GROUPS_FAIL = 'GET_USERS_GROUPS_FAIL',

  GET_ALL_GROUPS_LOADING = 'GET_ALL_GROUPS_LOADING',
  GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS',
  GET_ALL_GROUPS_FAIL = 'GET_ALL_GROUPS_FAIL',

  GET_GROUP_LOADING = 'GET_GROUP_LOADING',
  GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS',
  GET_GROUP_FAIL = 'GET_GROUP_FAIL',
  
  POST_USERS_GROUPS_LOADING  = 'POST_USERS_GROUPS_LOADING',
  POST_USERS_GROUPS_SUCCESS = 'POST_USERS_GROUPS_SUCCESS',
  POST_USERS_GROUPS_FAIL = 'POST_USERS_GROUPS_FAIL',

  PUT_USERS_GROUPS_LOADING  = 'PUT_USERS_GROUPS_LOADING',
  PUT_USERS_GROUPS_SUCCESS = 'PUT_USERS_GROUPS_SUCCESS',
  PUT_USERS_GROUPS_FAIL = 'PUT_USERS_GROUPS_FAIL',

  PUT_REMOVE_USER_GROUP_LOADING  = 'PUT_REMOVE_USER_GROUP_LOADING',
  PUT_REMOVE_USER_GROUP_SUCCESS = 'PUT_REMOVE_USER_GROUP_SUCCESS',
  PUT_REMOVE_USER_GROUP_FAIL = 'PUT_REMOVE_USER_GROUP_FAIL',

  PUT_USERS_ASSIGN_MEMBERS_GROUP_LOAD = "PUT_USERS_ASSIGN_MEMBERS_GROUP_LOAD",
  PUT_USERS_ASSIGN_MEMBERS_GROUP_SUCCESS = "PUT_USERS_ASSIGN_MEMBERS_GROUP_SUCCESS",
  PUT_USERS_ASSIGN_MEMBERS_GROUP_FAILED = "PUT_USERS_ASSIGN_MEMBERS_GROUP_FAILED",

  GET_ALL_GROUPS_MEMBERS_LOADING = 'GET_ALL_GROUPS_MEMBERS_LOADING',
  GET_ALL_GROUPS_MEMBERS_SUCCESS = 'GET_ALL_GROUPS_MEMBERS_SUCCESS',
  GET_ALL_GROUPS_MEMBERS_FAIL = 'GET_ALL_GROUPS_MEMBERS_FAIL',

  DELETE_GROUPS_MANAGE_LOADING = 'DELETE_GROUPS_MANAGE_LOADING',
  DELETE_GROUPS_MANAGE_SUCCESS = 'DELETE_GROUPS_MANAGE_SUCCESS',
  DELETE_GROUPS_MANAGE_FAIL = 'DELETE_GROUPS_MANAGE_FAIL',

  GET_GROUPS_TOTAL_USERS = 'GET_GROUPS_TOTAL_USERS'
}

export enum ManageGroupsStatusTypes {
  MANAGE_GROUPS_LOADING = 'LOADING',
  MANAGE_GROUPS_SUCCESS = 'SUCCESS',
  MANAGE_GROUPS_FAIL = 'FAIL',
}

// Interface for Get All Action Type
export interface IManageGroupsGetLoadingAction {
  type: ManageGroupsActionTypes.GET_USERS_GROUPS_LOADING;
  manageGroupsStatus: string
}
export interface IManageGroupsGetSuccessAction {
  type: ManageGroupsActionTypes.GET_USERS_GROUPS_SUCCESS;
  manageGroupsStatus: string
  groups: IManageGroups
  isInfinite: boolean;
}
export interface IManageGroupsGetFailAction {
  type: ManageGroupsActionTypes.GET_USERS_GROUPS_FAIL;
  manageGroupsStatus: string
  error: any
}

// Get Group specific
export interface IManageGroupGetLoadingAction {
  type: ManageGroupsActionTypes.GET_GROUP_LOADING;
  manageGroupsStatus: string
}
export interface IManageGroupGetSuccessAction {
  type: ManageGroupsActionTypes.GET_GROUP_SUCCESS;
  manageGroupsStatus: string
  group: IManageGroup
}
export interface IManageGroupGetFailAction {
  type: ManageGroupsActionTypes.GET_GROUP_FAIL;
  manageGroupsStatus: string
  error: any
}

export interface IManageGroupsPostLoadingAction {
  type: ManageGroupsActionTypes.POST_USERS_GROUPS_LOADING;
  manageGroupsStatus: string
}
export interface IManageGroupsPostSuccessAction {
  type: ManageGroupsActionTypes.POST_USERS_GROUPS_SUCCESS;
  manageGroupsStatus: string
  groups: IManageGroups
}
export interface IManageGroupsPostFailAction {
  type: ManageGroupsActionTypes.POST_USERS_GROUPS_FAIL;
  manageGroupsStatus: string
  error: any
}

export interface IManageGroupsPutLoadingAction {
  type: ManageGroupsActionTypes.PUT_USERS_GROUPS_LOADING;
  manageGroupsStatus: string
}
export interface IManageGroupsPutSuccessAction {
  type: ManageGroupsActionTypes.PUT_USERS_GROUPS_SUCCESS;
  manageGroupsStatus: string
  groups: IManageGroups
}
export interface IManageGroupsPutFailAction {
  type: ManageGroupsActionTypes.PUT_USERS_GROUPS_FAIL;
  manageGroupsStatus: string
  error: any
}

export interface IManageGroupsPutRemoveUserGroupLoadingAction {
  type: ManageGroupsActionTypes.PUT_REMOVE_USER_GROUP_LOADING;
  manageGroupsStatus: string
}
export interface IManageGroupsPutRemoveUserGroupSuccessAction {
  type: ManageGroupsActionTypes.PUT_REMOVE_USER_GROUP_SUCCESS;
  manageGroupsStatus: string
  groups: IManageGroups
}
export interface IManageGroupsPutRemoveUserGroupFailAction {
  type: ManageGroupsActionTypes.PUT_REMOVE_USER_GROUP_FAIL;
  manageGroupsStatus: string
  error: any
}

export interface IManageGroupsPutAssignMembersGroupLoadAction {
  type: ManageGroupsActionTypes.PUT_USERS_ASSIGN_MEMBERS_GROUP_LOAD;
  status: string;
}
export interface IManageGroupsPutAssignMembersGroupSuccessAction {
  type: ManageGroupsActionTypes.PUT_USERS_ASSIGN_MEMBERS_GROUP_SUCCESS;
  status: string;
}
export interface IManageGroupsPutAssignMembersGroupFailAction {
  type: ManageGroupsActionTypes.PUT_USERS_ASSIGN_MEMBERS_GROUP_FAILED;
  status: string;
}

export interface IManageGroupsGetAllLoadingAction {
  type: ManageGroupsActionTypes.GET_ALL_GROUPS_LOADING;
  status: string;
}
export interface IManageGroupsGetAllSuccessAction {
  type: ManageGroupsActionTypes.GET_ALL_GROUPS_SUCCESS;
  allGroups: any;
  status: string;
}
export interface IManageGroupsGetAllFailAction {
  type: ManageGroupsActionTypes.GET_ALL_GROUPS_FAIL;
  status: string;
}

export interface IManageGroupsMembersGetAllLoadingAction {
  type: ManageGroupsActionTypes.GET_ALL_GROUPS_MEMBERS_LOADING;
  status: string;
}
export interface IManageGroupsMembersGetAllSuccessAction {
  type: ManageGroupsActionTypes.GET_ALL_GROUPS_MEMBERS_SUCCESS;
  groupMembers: any;
  status: string;
}
export interface IManageGroupsMembersGetAllFailAction {
  type: ManageGroupsActionTypes.GET_ALL_GROUPS_MEMBERS_FAIL;
  status: string;
}

export interface IManageGroupsTotalMembers {
  type: ManageGroupsActionTypes.GET_GROUPS_TOTAL_USERS,
  payload: any,
  status: string
}

/* 
Combine the action types with a union (we assume there are more)
example: export type CharacterActions = IGetAllAction | IGetOneAction ... 
*/
export type ManageGroupsActions = 
| IManageGroupsGetLoadingAction
| IManageGroupsGetSuccessAction
| IManageGroupsGetFailAction
| IManageGroupGetLoadingAction
| IManageGroupGetSuccessAction
| IManageGroupGetFailAction
| IManageGroupsPostLoadingAction
| IManageGroupsPostSuccessAction
| IManageGroupsPostFailAction
| IManageGroupsPutLoadingAction
| IManageGroupsPutSuccessAction
| IManageGroupsPutFailAction
| IManageGroupsPutAssignMembersGroupLoadAction
| IManageGroupsPutAssignMembersGroupSuccessAction
| IManageGroupsPutAssignMembersGroupFailAction
| IManageGroupsGetAllLoadingAction
| IManageGroupsGetAllSuccessAction
| IManageGroupsGetAllFailAction
| IManageGroupsMembersGetAllLoadingAction
| IManageGroupsMembersGetAllSuccessAction
| IManageGroupsMembersGetAllFailAction
| IManageGroupsTotalMembers

export interface IManageGroupsState {
  readonly groups: IManageGroups;
  readonly group: IManageGroup;
  readonly manageGroupsStatus: string
  readonly allGroups: IAllManageGroups[]
  readonly allGroupsStatus: string
  readonly groupMembers: IManageGroupsMembers
  readonly totalMembers: number
}
