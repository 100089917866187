import React from "react";
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import { ShortDateFormat } from "utils/FormatDate";
import { IResultNewsPopular } from "redux/types/news";
import { Link as RouterLink } from "react-router-dom";
import {
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Link,
  Box,
  Grid,
  Theme,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewAll: {
      textTransform: "uppercase",
      fontSize: "0.75rem",
    },
    lineClamp: {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    title: {
      WebkitLineClamp: 2,
      fontSize: "13px",
      color: theme.palette.text.primary,
    },
    content: {
      padding: 0,
      "&:last-child": {
        paddingBottom: 0,
      },
    },
    gridContainer: {
      flexWrap: "nowrap",
      alignItems: "end",
    },
    publicationDate: {
      paddingBottom: "8px",
    },
  })
);

interface IProps {
  resultPopular: IResultNewsPopular;
}

function MiniPopularCardTab(props: IProps) {
  const { resultPopular } = props;
  const newsBackURL = "/news/details";
  const classes = useStyles();

  return (
    <>
      <Box mt={2} width="98%">
        <Card elevation={0}>
          <CardActionArea>
            <Link
              component={RouterLink}
              to={{ pathname: `${newsBackURL}/${resultPopular?.newsId}` }}
            >
              <CardContent className={classes.content}>
                <Grid container spacing={1} className={classes.gridContainer}>
                  <Grid item className="grid-item-title">
                    <Box mt={1}>
                      <Typography
                        className={`${classes.title} + ${classes.lineClamp}`}
                        variant="subtitle2"
                      >
                        {resultPopular?.title}
                      </Typography>
                    </Box>
                    <Box mt={1} className={classes.publicationDate}>
                      <Typography
                        gutterBottom
                        variant="subtitle2"
                        color="textSecondary"
                        style={{ textTransform: "uppercase" }}
                      >
                        <ShortDateFormat
                          date={resultPopular?.publicationDate}
                        />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Link>
          </CardActionArea>
        </Card>
      </Box>
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    news: store.newsState.news,
  };
};

export default connect(mapStateToProps)(MiniPopularCardTab);
