import ToolTip from "components/ToolTip/ToolTip";
import TextInputWCounter from "common/UI/Text/TextCounter";
import React, { useEffect, useRef, useState } from "react";
import PhotoUpload from "components/PhotoUpload/PhotoUpload";
import { ICultureState } from "redux/reducers/cultureReducer";
import { PageStateDetailPrize } from "redux/types/createPrize";
import Resource, { resource } from "components/Resources/Resource";
import TextEditorInput from "components/GenericField/TextEditorInput/TextEditorInput";
import XDatepicker from "common/UI/XDatepicker/XDatepicker";
import {
  Box,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  useTheme,
  Radio,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formProgressBar: {
      backgroundColor: theme.palette.primary.main,
      display: "inline-block",
      width: "32px",
      height: "5px !important",
      margin: "0 4px 0 0",
      opacity: "0.4",
      borderRadius: "5px",
      "&.active": {
        opacity: 1,
      },
    },
    widthRadioBtn: {
      "& .MuiSvgIcon-root": {
        width: "0.8em",
        height: "0.8em",
      },
    },
    titleRadioGroup: {
      color: theme.palette.text.secondary,
      fontSize: "0.8rem",
      fontWeight: 700,
      paddingBottom: "10px",
      textTransform: "uppercase",
    },
    labelRadioBtn: {
      marginRight: "50px",
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
      },
    },
    prizeUnits: {
      "& .MuiInputLabel-root": {
        fontWeight: 700,
        fontSize: "1.05rem",
        textTransform: "uppercase",
        width: "190px",
      },
      "& .MuiInputBase-root": {
        fontSize: "0.88rem",
        color: theme.palette.text.primary,
      },
      "& .MuiInputBase-input": {
        width: "120px",
      },
    },
  })
);

interface IProps {
  culture: ICultureState;
  fieldsProgress?: number;
  completedFieldsProgress?: number;
  setSteps: (newState: PageStateDetailPrize) => void;
  steps: PageStateDetailPrize;
}

function CreatePrizeStepDetails(props: IProps) {
  const { culture, setSteps, steps } = props;
  const classes = useStyles();
  const theme = useTheme();

  //onKeyPress: to on keypress accept just numbers
  function onlynumber(evt: any) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    var regex = /^[0-9.]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  //handleformdata to completedFieldsProgress
  const [fieldsProgress, setFieldsProgress] = useState(0);
  const [completedFieldsProgress, setCompletedFields] = useState(0);
  const formRef = useRef<HTMLFormElement | null>(null);

  //ProgressBar
  const setUpBar = () => {
    const bars = [];
    for (let index = 0; index < fieldsProgress; index++) {
      const barClass = index < completedFieldsProgress;
      bars.push(
        <span
          key={index}
          className={classes.formProgressBar + (barClass ? " active" : "")}
        ></span>
      );
    }
    return bars;
  };

  //Porcentage completed
  const completedPorcentage = Math.floor(
    (completedFieldsProgress / fieldsProgress) * 100
  );

  //radio group
  const handleChangePrizeType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSteps({
      ...steps,
      prizeType: Number((event.target as HTMLInputElement).value),
    });
  };

  //start date
  const handleActivationDateChange = (date: any) => {
    setSteps({
      ...steps,
      activationDate: date,
    });
  };
  //end date
  const handleSubmissionDateChange = (date: any) => {
    setSteps({
      ...steps,
      endDate: date,
    });
  };
  //start time
  let hourActivation = new Date(steps?.activationDate).getHours();
  let minuteActivation = new Date(steps?.activationDate).getMinutes();
  let timeNowActivation =
    (hourActivation < 10 ? "0" + hourActivation : hourActivation) +
    ":" +
    (minuteActivation < 10 ? "0" + minuteActivation : minuteActivation);

  // end time
  let hourEnd = new Date(steps?.endDate).getHours();
  let minuteEnd = new Date(steps?.endDate).getMinutes();
  let timeNowEnd =
    (hourEnd < 10 ? "0" + hourEnd : hourEnd) +
    ":" +
    (minuteEnd < 10 ? "0" + minuteEnd : minuteEnd);

  // start hour
  const changeActivationHour = (e: any) => {
    const [hour, minutes] = e.target.value.split(":");
    const time = new Date(steps?.activationDate);
    time.setHours(hour);
    time.setMinutes(minutes);

    if (hour) {
      setSteps({
        ...steps,
        activationDate: time,
      });
    }
  };
  // end hour
  const changeSubmissionHour = (e: any) => {
    const [hour, minutes] = e.target.value.split(":");
    const time = new Date(steps?.endDate);
    time.setHours(hour);
    time.setMinutes(minutes);

    if (hour) {
      setSteps({
        ...steps,
        endDate: time,
      });
    }
  };

  //props to rich text for description
  const prizeDescriptionProps = {
    columnName: "prize-description",
    isMandatory: false,
    typeId: 9,
    title: resource(culture, "PageCreatePrize::Description"),
    placeholder: resource(culture, "PageCreatePrize::DescriptionTip"),
    isEditable: true,
    maxLength: 3000,
  };

  //functions to take image
  const [photo, setPhoto] = useState<any>(steps?.image);
  const [description, setDescription] = useState(steps?.description);

  useEffect(() => {
    handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo]);

  //functions to take all fields
  useEffect(() => {
    fCompletedFields();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, description]);

  function fCompletedFields() {
    const form = formRef.current!;
    const formData = new FormData(form);
    const { completedFields } = handleFormData(formData);
    setCompletedFields(completedFields);
  }

  function handleFormData(data: FormData) {
    let elemArray;
    steps.prizeType === 2
      ? (elemArray = [
        data.get("buy-value"),
        data.get("unitAvailable"),
        data.get("maxUnitsPerUser"),
        data.get("prize-title"),
        description === "<p><br></p>" ? "" : description,
        photo !== undefined ? photo : "",
      ])
      : (elemArray = [
        data.get("bid-value"),
        data.get("unitAvailable"),
        data.get("prize-title"),
        description === "<p><br></p>" ? "" : description,
        photo !== undefined ? photo : "",
      ]);

    setFieldsProgress(elemArray?.length);

    let newValues = {
      value: data.get(steps?.prizeType === 1 ? "bid-value" : "buy-value"),
      unit: data.get("unitAvailable"),
      maxUnitPerUser: steps.prizeType === 1 ? "" : data.get("maxUnitsPerUser"),
      title: data.get("prize-title"),
      description: data.get("prize-description"),
      image: photo,
    };

    const params = {
      ...newValues,
    };

    const completedFields = elemArray.filter((x) => x).length;

    return { params, completedFields };
  }

  const handleData = () => {
    const form = formRef.current!;
    const formData = new FormData(form);
    const { params } = handleFormData(formData);
    setSteps({
      ...steps,
      unit: params.unit,
      maxUnitPerUser: params.maxUnitPerUser,
      value: params.value,
      title: params.title,
      description:
        params.description === "<p><br></p>" ? "" : params.description,
      image: params.image,
    });
  };

  useEffect(() => {
    setSteps({ ...steps, description });
    // eslint-disable-next-line
  }, [description, setSteps]);

  return (
    <form ref={formRef} className="prize-Form">
      <Box className="step-details-container">
        <Box
          className="header=form-progress"
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" alignSelf="self-start">
            <Box component="p" fontWeight="bold" mr={1}>
              <Resource tag="PageCreatePrize::NewPrize" />
            </Box>
            <Box component="p" color={theme.palette.text.secondary}>
              <Resource
                tag="PageCreatePrize::Complete"
                args={[completedPorcentage]}
              />
            </Box>
          </Box>
          <Box mt={1} display="flex">
            {setUpBar()}
          </Box>
        </Box>
        <Box className="prize-type" mt={4}>
          <Box className="radio-group-prize-type">
            <FormControl component="fieldset" className={classes.widthRadioBtn}>
              <FormLabel component="legend" className={classes.titleRadioGroup}>
                <Resource tag="PageCreatePrize::PrizeType" />
              </FormLabel>
              <RadioGroup
                id="prize-type"
                name="prize-type"
                value={steps?.prizeType}
                onChange={handleChangePrizeType}
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label={resource(culture, "PageCreatePrize::BuyNow")}
                  className={classes.labelRadioBtn}
                />
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label={resource(culture, "PageCreatePrize::Auction")}
                  className={classes.labelRadioBtn}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Divider style={{ width: "100%", margin: "24px 0" }} />
          <Box className="start" mb={4}>
            <Box mb={3}>
              <Box fontSize="0.9rem" fontWeight="bold">
                <Resource tag="PageCreatePrize::ActivationDate" />
              </Box>
              <Typography variant="caption" color="textSecondary">
                <Resource tag="PageCreatePrize::SelectStart" />
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <XDatepicker
                  keyboardDatePicker
                  fullWidth
                  label={
                    <Typography variant="body1" color="textSecondary">
                      <Resource tag="PageCreatePrize::Date" />
                    </Typography>
                  }
                  variant="inline"
                  format="dd/MM/yyyy"
                  disableToolbar
                  autoOk
                  value={steps?.activationDate}
                  onChange={handleActivationDateChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="time"
                  id="timeStart"
                  onChange={changeActivationHour}
                  label={
                    <Typography variant="body2" color="textSecondary">
                      <Resource tag="PageCreatePrize::Hour" />
                    </Typography>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={timeNowActivation}
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="end" mb={5}>
            <Box mb={3}>
              <Box fontSize="0.9rem" fontWeight="bold">
                {steps?.prizeType === 1 ? (
                  <Resource tag="PageCreatePrize::AuctionEndDate" />
                ) : (
                  <Resource tag="PageCreatePrize::SaleEndDate" />
                )}
              </Box>
              <Typography variant="caption" color="textSecondary">
                {steps?.prizeType === 1 ? (
                  <Resource tag="PageCreatePrize::SelectEndAuction" />
                ) : (
                  <Resource tag="PageCreatePrize::SelectEnd" />
                )}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <XDatepicker
                  keyboardDatePicker
                  fullWidth
                  label={
                    <Typography variant="body1" color="textSecondary">
                      <Resource tag="PageCreatePrize::Date" />
                    </Typography>
                  }
                  variant="inline"
                  format="dd/MM/yyyy"
                  disableToolbar
                  autoOk
                  value={steps?.endDate}
                  onChange={handleSubmissionDateChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="time"
                  id="timeEnd"
                  onChange={changeSubmissionHour}
                  label={
                    <Typography variant="body2" color="textSecondary">
                      <Resource tag="PageCreatePrize::Hour" />
                    </Typography>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={timeNowEnd}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          className="units-container"
          mb={3}
          display="flex"
          style={{ gap: 50 }}
        >
          <>
            <TextField
              className={classes.prizeUnits}
              type="text"
              id="unitAvailable"
              name="unitAvailable"
              label={<Resource tag="PageCreatePrize::UnitsAvailable" />}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyPress={onlynumber}
              onChange={() => handleData()}
              value={steps?.unit}
            />
            {steps?.prizeType !== 1 && (
              <Box display="flex" alignItems="flex-start">
                <TextField
                  className={classes.prizeUnits}
                  type="text"
                  id="maxUnitsPerUser"
                  name="maxUnitsPerUser"
                  label={<Resource tag="PageCreatePrize::MaxUnitsPerUser" />}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onKeyPress={onlynumber}
                  error={parseInt(steps.maxUnitPerUser) > parseInt(steps.unit)}
                  helperText={parseInt(steps.maxUnitPerUser) > parseInt(steps.unit) && <Resource tag="PageCreatePrize::MaxUnitsPerUserError" />}
                  onChange={() => handleData()}
                  value={steps?.maxUnitPerUser}
                />
                <Box ml={3} lineHeight={0}>
                  <ToolTip>
                    <Resource tag="PageCreatePrize::MaxUnitsPerUserTooltip" />
                  </ToolTip>
                </Box>
              </Box>
            )}
          </>
        </Box>
        <Box mt={6} className="prize-Value" display="flex">
          {steps?.prizeType === 1 ? (
            <TextField
              className={classes.prizeUnits}
              type="text"
              id="bid-value"
              name="bid-value"
              label={<Resource tag="PageCreatePrize::InitialBid" />}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={() => {
                fCompletedFields();
                handleData();
              }}
              onKeyPress={onlynumber}
              value={steps?.value}
            />
          ) : (
            <TextField
              className={classes.prizeUnits}
              type="text"
              id="buy-value"
              name="buy-value"
              label={<Resource tag="PageCreatePrize::BuyValue" />}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={() => {
                fCompletedFields();
                handleData();
              }}
              onKeyPress={onlynumber}
              value={steps?.value}
            />
          )}
          <Box mt={3}>
            <Typography variant="body1">
              <Resource tag="PageCreatePrize::Points" />
            </Typography>
          </Box>
        </Box>

        <Box mt={6} className="prize-title">
          <TextInputWCounter
            id="prize-title"
            name="prize-title"
            maxLength={100}
            label={<Resource tag="PageCreatePrize::Title" />}
            placeholder={resource(culture, "PageCreatePrize::Title_Tip")}
            fullWidth
            onChange={(e) =>
              setSteps({
                ...steps,
                title: e.target.value,
              })
            }
            value={steps?.title}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box className="prize-description-sun-editor" mt={6}>
          <TextEditorInput
            value={description}
            culture={culture}
            propsI={prizeDescriptionProps}
            handler={fCompletedFields}
            onChange={setDescription}
            maxCharCountShow={true}
            charCounterLabelShow={true}
          />
        </Box>
        <Box className="prize-image" mt={15}>
          <Box
            className="text-and-tooltip"
            display="flex"
            alignItems="center"
            mb={2}
          >
            <Box
              mr={1}
              color={theme.palette.text.secondary}
              fontSize="0.8rem"
              fontWeight="700"
              style={{
                textTransform: "uppercase",
                transformOrigin: "top left",
              }}
            >
              <Resource tag="PageCreatePrize::Image" />
            </Box>
            <ToolTip>
              <Resource tag="PageCreateChallenge::Image_Tip" />
            </ToolTip>
          </Box>
          <PhotoUpload photo={steps?.image} setPhoto={setPhoto} prize={true} />
        </Box>
      </Box>
    </form>
  );
}

export default CreatePrizeStepDetails;
