import "./BuyNowPrizeDescription.css";
import React, { useState } from "react";
import Resource from "../Resources/Resource";
import { Button, Box } from "@material-ui/core";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ListWinning from "../ListPrizeUserWinning/ListPrizeUserWinning";
import sanitizeHtml from "sanitize-html";
import { securityTextEditor } from "utils/securityTextEditor";

type Props = {
  result: any;
  handler: (cardData: any, index: number) => void;
  index: number;
  itemIndex: number;
  postBuyPrize: (prizeId: string) => void;
};

const BuyNowPrizeDescription = (props: Props) => {
  const { result, index, itemIndex, postBuyPrize, handler } = props;
  let activeLine = itemIndex <= 3 ? 1 : 2;
  let activeIndex = itemIndex <= 3 ? itemIndex : itemIndex - 3;
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = () => {
    postBuyPrize(result?.prizeId);
    handler(result, index);
  };

  //format to resource
  const motiveDeniedBuyResource = result?.motiveDeniedBuy?.replace(".", "::");

  if (result === null || activeLine !== index) return <></>;
  else
    return (
      <Box>
        <Box
          className={`buyNow-description buyNow-description-type-${activeIndex}`}
        >
          <Box onClick={() => handler(result, index)} className="arrow-icon">
            <KeyboardArrowUpIcon
              style={{
                cursor: "pointer",
              }}
              color="primary"
              fontSize="large"
            />
          </Box>
        </Box>
        <Box className="buyNow-info">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            ml={2}
          >
            <Box
              textAlign="left"
              fontSize="14px"
              lineHeight="26px"
              fontWeight="400"
              mr={2}
              style={{overflowY: 'auto', wordBreak: 'break-word', maxWidth: '100%', maxHeight: '160px'}}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(result?.description, securityTextEditor),
              }}
            />
            <Box display="flex" alignItems="center">
              <Box fontWeight="bold" mr={2}>
                <Resource
                  tag={"PagePrizes::Auction_UnitsLeft1"}
                  args={[result?.currentQuantity, result?.quantity]}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsOpen(true)}
                disabled={result?.isAllowedToBuy === false}
              >
                <Box fontWeight="bold">
                  <Resource tag={"PagePrizes::Auction_BuyNow"} />
                </Box>
              </Button>
              <Box
                ml={1}
                display="flex"
                alignItems="center"
                className="prize-buy-now-message"
              >
                {result?.motiveDeniedBuy !== "" ? (
                  <Resource tag={motiveDeniedBuyResource!} />
                ) : (
                  ""
                )}
              </Box>
              <PopUpConfirmation
                closeButton={() => setIsOpen(false)}
                sendButton={handleSubmit}
                message={<Resource tag="Common::ConfirmAction" />}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            </Box>
          </Box>
          {/* right side */}
          <Box className="prize-winning-list">
            <ListWinning
              winners={result?.buyers}
              bids={result?.buyers}
              mode="buy-now"
              total={result?.buyers.length}
            />
          </Box>
          {/* ./right side */}
        </Box>
      </Box>
    );
};

export default BuyNowPrizeDescription;
