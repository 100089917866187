import React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IAppState } from '../../redux/store/Store';



interface IDispatchProps {

}

interface IStateProps {

}

class Help extends React.Component<IDispatchProps & IStateProps> {

  componentDidMount() {

  }

  public render() {

    return (
      <div className="help" style={{ paddingLeft: "270px" }} >

      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): IDispatchProps => {
  return {

  };
};

const mapStateToProps = (store: IAppState): IStateProps => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);