import { AnyAction } from "redux";
import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import { ICultureState } from "redux/reducers/cultureReducer";
import { Box } from "@material-ui/core";
import { updateNews } from "redux/actions/news/NewsActions";
import { INewsState, IResultNews, IResultNewsHighlighted } from "redux/types/news";
import NewForm from "./NewForm";
import history from 'routes/history'
import { RouteComponentProps } from "react-router-dom";

interface IDispatchProps {
  updateNews: (options: object) => Promise<INewsState>
}
interface IMatch {
  newsId: string
}
interface IProps {
  culture: ICultureState;
  createNewStatus: string;
  workspaceId: string;
  newsId: string;
  newData: IResultNews
  newDataHighlight: IResultNewsHighlighted
  newDataFuture: Partial<IResultNews>
  topics: []
}
interface submitProps {
  title: string,
  description: string,
  topic: string,
  image: string,
  enableComments: boolean,
  workspaceId: string,
  publicationDate: Date,
  isHighlighted: boolean
}


function UpdateNews(props: IProps & IDispatchProps) {
  const { culture, updateNews, workspaceId, createNewStatus, newsId, newData, newDataFuture, newDataHighlight, topics } = props;
  const submitForm = ({
    title,
    description,
    topic,
    image,
    enableComments,
    workspaceId,
    publicationDate,
    isHighlighted
  }: submitProps) => {
    updateNews({
      title,
      description,
      topic,
      image,
      enableComments,
      workspaceId,
      newsId,
      publicationDate,
      isHighlighted,
      culture: culture?.culture
    }).then(() => {
      history.push(`/news/details/${newsId}`)
    })
  }

  return (
    <Box marginY={4}>
      <NewForm
        topics={topics}
        newData={newData?.newsId ? newData : newDataHighlight?.newsId ? newDataHighlight : newDataFuture}
        culture={culture}
        workspaceId={workspaceId}
        createNewStatus={createNewStatus}
        submitForm={submitForm}
      />
    </Box>
  );
}
const mapStateToProps = (store: IAppState, ownProps: RouteComponentProps<IMatch>) => {
  return {
    culture: store.cultureState,
    workspaceId: store.authState.auth.workspaceId,
    createNewStatus: store.newsState.createNewStatus,
    newData: store?.newsState?.news  ? store?.newsState?.news?.news?.result?.filter(data => data.newsId === ownProps.match.params.newsId)[0] : {} as IResultNews,
    newDataHighlight: store.newsState.newsHighlighted ? store?.newsState?.newsHighlighted.result?.filter(data => data.newsId === ownProps.match.params.newsId)[0] : {} as IResultNewsHighlighted,
    newDataFuture: store.newsState.futureNews ? store?.newsState?.futureNews?.filter(data => data.newsId === ownProps.match.params.newsId)[0] : {} as Partial<IResultNews>,
    newsId: ownProps.match.params.newsId,
    topics: store.newsState.topics
  };
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  updateNews: (options: object) => dispatch(updateNews(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateNews);
