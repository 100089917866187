import { resource } from "components/Resources/Resource"
import { api_general, api_v3 } from "config/api"
import { toast } from "react-toastify"
import { ActionCreator, Dispatch } from "redux"
import { ThunkAction } from "redux-thunk"
import { CreatePrizesState } from "redux/reducers/createPrize/createPrizeReducer"
import { ICultureState } from "redux/reducers/cultureReducer"
import { PageStateDetailPrize, PageStateParticipantsPrize } from "redux/types/createPrize"
import { treatError } from "utils/treatError"
import history from 'routes/history';

const basePath = 'prizes'

export enum CreatePrizesActionTypes { 
  UPDATE_DETAILS = "UPDATE_PRIZE_DETAILS",
  UPDATE_PARTICIPANTS = "UPDATE_PRIZE_PARTICIPANTS",
  UPDATE_IDEA_FORM = "UPDATE_PRIZE_IDEA_FORM",
  UPDATE_WORKFLOW = "UPDATE_PRIZE_WORKFLOW",

  GET_CREATE_PRIZE_DETAIL = 'GET_CREATE_PRIZE_DETAIL',

  POST_PRIZE_DETAIL_SUCCEEDED = 'POST_PRIZE_DETAIL_SUCCEEDED',
  POST_PRIZE_DETAIL_FAILED = 'POST_PRIZE_DETAIL_FAILED',

  PUT_PRIZE_DETAIL_FAILED = 'PUT_PRIZE_DETAIL_FAILED',
  PUT_PRIZE_DETAIL_SUCCEEDED = 'PUT_PRIZE_DETAIL_SUCCEEDED',

  PUT_PRIZE_DETAIL_PARTICIPANTS_FAILED = 'PUT_PRIZE_DETAIL_PARTICIPANTS_FAILED',
  PUT_PRIZE_DETAIL_PARTICIPANTS_SUCCEESS = 'PUT_PRIZE_DETAIL_PARTICIPANTS_SUCCEEDED',

  RESET_CREATE_PRIZE = 'RESET_CREATE_PRIZE',
}

export enum CreatePrizeActionStatus {

  POST_PRIZE_DETAIL_SUCCEEDED = 'PRIZE_DETAIL_SUCCEEDED',
  POST_PRIZE_DETAIL_FAILED = 'PRIZE_DETAIL_FAILED',

  PUT_PRIZE_DETAIL_SUCCEEDED = 'PRIZE_DETAIL_SUCCEEDED',
  PUT_PRIZE_DETAIL_FAILED = 'PRIZE_DETAIL_FAILED',

  GET_PRIZE_DETAIL_LOADING = 'GET_PRIZE_DETAIL_LOADING',
  GET_PRIZE_DETAIL_SUCCEEDED = 'GET_PRIZE_DETAIL_SUCCEEDED',
  GET_PRIZE_DETAIL_FAILED = 'GET_PRIZE_DETAIL_FAILED',

  PUT_PRIZE_DETAIL_PARTICIPANTS_SUCCEEDED = 'PUT_PRIZE_DETAIL_PARTICIPANTS_SUCCEEDED',
  PUT_PRIZE_DETAIL_PARTICIPANTS_FAILED = 'PUT_PRIZE_DETAIL_PARTICIPANTS_FAILED',

  PUT_PRIZE_DETAIL_IDEAFORM_SUCCEEDED = 'PUT_PRIZE_DETAIL_IDEAFORM_SUCCEEDED',
  PUT_PRIZE_DETAIL_IDEAFORM_FAILED = 'PUT_PRIZE_DETAIL_IDEAFORM_FAILED',
}

interface UpdateDetailsAction {
  type: CreatePrizesActionTypes.UPDATE_DETAILS;
  payload: PageStateDetailPrize
}

interface UpdateParticipantsAction {
  type: CreatePrizesActionTypes.UPDATE_PARTICIPANTS;
  payload: PageStateParticipantsPrize
}

export interface GetPrizeDetailAction {
  type: CreatePrizesActionTypes.GET_CREATE_PRIZE_DETAIL,
  payload?: any;
  status: string
}

export interface PostPrizeDetailFailed {
  type: CreatePrizesActionTypes.POST_PRIZE_DETAIL_FAILED;
  status: string;
}

export interface PostPrizeDetailSucceeded {
  type: CreatePrizesActionTypes.POST_PRIZE_DETAIL_SUCCEEDED;
  status: string;
}

export interface PutPrizeDetailParticipantsFailed {
  type: CreatePrizesActionTypes.PUT_PRIZE_DETAIL_PARTICIPANTS_FAILED;
  status: string;
}

export interface PostPrizeDetailSucceeded {
  type: CreatePrizesActionTypes.POST_PRIZE_DETAIL_SUCCEEDED;
  status: string;
}

export interface PutPrizeDetailFailed {
  type: CreatePrizesActionTypes.PUT_PRIZE_DETAIL_FAILED;
  status: string;
}

export interface PutPrizeDetailSucceeded {
  type: CreatePrizesActionTypes.PUT_PRIZE_DETAIL_SUCCEEDED;
  status: string;
}

export interface PutPrizeDetailParticipantsFailed {
  type: CreatePrizesActionTypes.PUT_PRIZE_DETAIL_PARTICIPANTS_FAILED;
  status: string;
}

export interface PutPrizeDetailParticipantsSucceeded {
  type: CreatePrizesActionTypes.PUT_PRIZE_DETAIL_PARTICIPANTS_SUCCEESS;
  status: string;
}

export interface ResetCreatePrize {
  type: CreatePrizesActionTypes.RESET_CREATE_PRIZE;
}

export type CreatePrizesActions = 
  | GetPrizeDetailAction
  | UpdateDetailsAction
  | UpdateParticipantsAction
  | PostPrizeDetailFailed
  | PostPrizeDetailSucceeded
  | PutPrizeDetailFailed
  | PutPrizeDetailSucceeded
  | PutPrizeDetailParticipantsFailed
  | PutPrizeDetailParticipantsSucceeded
  | ResetCreatePrize

  export interface PostPrizeDetailParam {
    workspaceId: string,
    prizeType: number,
    activationDate: Date | string,
    endDate: Date | string,
    unit: number,
    maxUnitPerUser: number,
    value: number,
    image: string,
    description: string,
    cultureTag: string,
    title: string
    culture: ICultureState
  }
  
  export interface PutPrizeDetailParam extends PostPrizeDetailParam {
    prizeId: string;
  }
  
  export interface PutPrizesDetailParticipantsParam {
    prizeId: string;
    groupsId: string[];
    culture: ICultureState;
  }
  
  export function updateDetailsPrize(prizeDetails: PageStateDetailPrize): UpdateDetailsAction {
    return {
      type: CreatePrizesActionTypes.UPDATE_DETAILS,
      payload: prizeDetails
    }
  }
  
  export function updateParticipantsPrize(prizeDetails: PageStateParticipantsPrize): UpdateParticipantsAction {
    return {
      type: CreatePrizesActionTypes.UPDATE_PARTICIPANTS,
      payload: prizeDetails
    }
  }

  export function resetCreatePrize(): ResetCreatePrize {
    return {
      type: CreatePrizesActionTypes.RESET_CREATE_PRIZE,
    }
  }
  
  export const getCreatePrizeDetail: ActionCreator<
  ThunkAction<Promise<any>, CreatePrizesState, null, GetPrizeDetailAction>
  > = (options) => {
    return async (dispatch: Dispatch) => {
      try {
        dispatch({
          status: CreatePrizeActionStatus.GET_PRIZE_DETAIL_LOADING,
          type: CreatePrizesActionTypes.GET_CREATE_PRIZE_DETAIL,
        })
  
        let params: any[] = [];
        let params_qs = "";
  
        if( options.hasOwnProperty("workspaceId") )
        {
          params.push(`workspaceId=${options.workspaceId }`);
        }
  
        if(params.length) {
          params_qs = `?${params.join("&")}`;
        }

        const response = await api_general.get(`${basePath}/${options.prizeId}${params_qs}`);

        dispatch({
          status: CreatePrizeActionStatus.GET_PRIZE_DETAIL_SUCCEEDED,
          type: CreatePrizesActionTypes.GET_CREATE_PRIZE_DETAIL,
          payload: response.data
        })
      } catch (err) {
        dispatch({
          status: CreatePrizeActionStatus.GET_PRIZE_DETAIL_FAILED,
          type: CreatePrizesActionTypes.GET_CREATE_PRIZE_DETAIL,
        })
  
        treatError(options.culture, "PagePrizes::Header", err)
      }
    }
  }
  
  export const postPrizeDetail: ActionCreator<
    ThunkAction<Promise<any>, CreatePrizesState, null, PostPrizeDetailSucceeded>
  > = (options) => { //:PostPrizeDetailParam
    return async (dispatch: Dispatch) => {
      try {

        const response = await api_v3.post(`${basePath}`, options.params);
        dispatch({
          status: CreatePrizeActionStatus.POST_PRIZE_DETAIL_SUCCEEDED,
          type: CreatePrizesActionTypes.POST_PRIZE_DETAIL_SUCCEEDED,
        });

        toast.success(resource(options.culture,'PageCreatePrize::PrizeCreatedSuccess'))
        response.status === 200 && history.push('/prizes')
        
        return response.data
      } catch (err) {
        dispatch({
          status: CreatePrizeActionStatus.POST_PRIZE_DETAIL_FAILED,
          type: CreatePrizesActionTypes.POST_PRIZE_DETAIL_FAILED,
        });
        //treatError(options?.culture, 'PagePrizes::Header', err, [err.error.response.data[0].arguments[0]]);
        treatError(options.culture, 'PagePrizes::Header', err);
      }
    };
  };
  
  export const putPrizeDetail: ActionCreator<
    ThunkAction<Promise<any>, CreatePrizesState, null, PutPrizeDetailSucceeded>
  > = (options) => {
    return async (dispatch: Dispatch) => {
      try {

        await api_general.put(`${basePath}/${options.prizeId}`, options.params).then(response => {
          dispatch({
            status: CreatePrizeActionStatus.PUT_PRIZE_DETAIL_SUCCEEDED,
            type: CreatePrizesActionTypes.PUT_PRIZE_DETAIL_SUCCEEDED,
          });
          toast.success(resource(options.culture,'PagePrizes::PrizeEditedSuccess'))
          response.status === 200 && history.push('/prizes')
        })
        
      } catch (err) {
        dispatch({
          status: CreatePrizeActionStatus.PUT_PRIZE_DETAIL_FAILED,
          type: CreatePrizesActionTypes.PUT_PRIZE_DETAIL_FAILED,
        });
        treatError(options.culture, 'PagePrizes::Header', err);
      }
    };
  };
  
  
  export const putPrizeDetailParticipants: ActionCreator<
    ThunkAction<Promise<any>, CreatePrizesState, null, PutPrizeDetailParticipantsSucceeded>
  > = (options:PutPrizesDetailParticipantsParam) => {
    return async (dispatch: Dispatch) => {
      try {
  
        await api_v3.put(`${basePath}/${options.prizeId}/participants`, options);
        dispatch({
          status: CreatePrizeActionStatus.PUT_PRIZE_DETAIL_PARTICIPANTS_SUCCEEDED,
          type: CreatePrizesActionTypes.PUT_PRIZE_DETAIL_PARTICIPANTS_SUCCEESS,
        });
      } catch (err) {
        dispatch({
          status: CreatePrizeActionStatus.PUT_PRIZE_DETAIL_PARTICIPANTS_FAILED,
          type: CreatePrizesActionTypes.PUT_PRIZE_DETAIL_PARTICIPANTS_FAILED,
        });
        treatError(options.culture, 'PagePrizes::Header', err);
      }
    };
  };
