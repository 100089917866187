import React from 'react'
import './PopUpListWinning.css';
import UserWinning from '../UserWinning/UserWinning';

//Example:
//<PopUpListWinning applicationId=""/>

/* Example in PopUp (https://react-popup.elazizi.com/introduction/)
    <Popup
        trigger={<button className="button"> Open Modal </button>}
        modal
        closeOnDocumentClick
    >
        <PopUpListWinning applicationId=""/>
    </Popup>
*/

interface IProps {
  winners: []
  mode:string;
}

class PopUpListWinning extends React.PureComponent<IProps> {

  //Cut long names
  cutName = (name: string) => {
    const maxSize = 15
    let newName: string = '';

    if (name.length > maxSize) {
      newName = name.substr(0, maxSize).trim() + '...';
    } else {
      newName = name;
    }
    return newName;
  }

  public render() {

    const { winners, mode }: any = this.props
    let show5 = 5;
    let show10 = 10;
    return (

      <div className='PopUpListWinning'>
        <div className="PopUpListWinningColL">
          {
            winners?.slice(0, show5).map((winn: any) => {
              return <UserWinning mode={mode} key={winn.userId} userid={winn.userId} image={winn.image} name={winn.name} value={winn.value || winn.bidValue} position={winn.position} />
            })
            
          }
        </div>

        <div className="PopUpListWinningColR">
          {
            winners?.slice(5, show10).map((winn: any) => {
              return <UserWinning mode={mode} key={winn.userId} userid={winn.userId} image={winn.image} name={winn.name} value={winn.value} position={winn.position} />
            })
          }
        </div>
      </div>
    )
  }
}

export default PopUpListWinning;