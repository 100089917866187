import React from 'react'
import { Grid } from '@material-ui/core';
import CardIdeaCollaborative from '../CardIdeaCollaborative/CardIdeaCollaborative';
import { ICultureState } from 'redux/reducers/cultureReducer';
import { IAllIdeasState } from 'redux/reducers/ideas/AllIdeasReducer';

interface IProps {
    ideaList:any
    postInvestments:(option:object)=> void;
    applicationId:string;
    culture:ICultureState;
    postLikes:(options:object) => void,
    deleteLikes:(options:object) => void
    getAllIdeas: (options: object) => Promise<IAllIdeasState>,
}
  
function MapIdeasToRows(props:any){
    let auxIdeasList = props?.ideas?.slice();
    const { postInvestments, applicationId, culture, postLikes, deleteLikes, getAllIdeas } = props;

    return(
        <>
        {auxIdeasList ?
        auxIdeasList.map((idea:any, index:any)=>{
            var div:any = document.createElement('div');
            div.innerHTML = idea?.description;
            var firstImage = div.getElementsByTagName('img')[0]
            return(
                <Grid item xs={12} md={6} key={index}>
                    <CardIdeaCollaborative getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} idea={idea} width={6} key={index} hasImage={firstImage}/>
                </Grid>
            )
        })
        : ''
        }
        </>
    )
}

function IdeaRows(props:IProps){
    const totalIdeasQuantity = props?.ideaList?.meta?.total;
    const pageIdeasQuantity = props?.ideaList?.meta?.perPage;
    const cardQuantity = totalIdeasQuantity > pageIdeasQuantity ? pageIdeasQuantity : totalIdeasQuantity;
    const {postLikes, deleteLikes, postInvestments, applicationId, culture, getAllIdeas} = props; 
    
    return(
        <>
            <MapIdeasToRows getAllIdeas={getAllIdeas} postLikes={postLikes} deleteLikes={deleteLikes} culture={culture} applicationId={applicationId} postInvestments={postInvestments} ideas={props?.ideaList?.result} cardQuantity={cardQuantity}/>
        </>
    )      
}

export default IdeaRows