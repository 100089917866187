import React from 'react'
import RankingListItem from '../RankingListItem/RankingListItem';
import Resource from '../Resources/Resource';
import { Table, TableHead, TableBody, TableRow, TableCell, Hidden } from '@material-ui/core'
import useStyles from './RankingListStyle'

/* Example:
    <RankingList list={[
        {userid:"1", avatar:"http://i.stack.imgur.com/Dj7eP.jpg", name:"Sebastian Vila", rank:3, points:12750, department:"Sales", mySelf:false},
        {userid:"32", avatar:"http://i.stack.imgur.com/Dj7eP.jpg", name:"Mona Lisa", rank:4, points:8750, department:"Sales", mySelf:true},
        {userid:"77", avatar:"http://i.stack.imgur.com/Dj7eP.jpg", name:"Silas Petter", rank:6, points:6750, department:"Factoy", mySelf:false},
    ]} />
*/

interface IProps {
    list: {
        userid?: string;  //TODO API isnt returning the userID
        avatar: string;
        name: string;
        rank: number;
        points: number;
        department?: string; //TODO API isnt returning the department
        mySelf?: boolean;
    }[]
    cultureTag:string;
    search:boolean;
}

export default function RankingList (props:IProps) {
   const classes = useStyles();
   const rankingList = props.search ? props.list : props.list.splice(3);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableTitles} variant='head' align="left"><Resource tag={"PageRanking::Position"} /></TableCell>
                    <TableCell className={classes.tableTitles} style={{visibility: 'hidden' }} variant='head' align="left">
                        <Hidden smDown implementation="css">
                            <Resource tag={"PageRanking::Rank_Departament"} />
                        </Hidden>
                    </TableCell>                          
                    <TableCell className={classes.tableTitles} variant='head' align="right"><Resource tag={"PageRanking::Points"} /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    rankingList?.map((user,index) => 
                        <RankingListItem cultureTag={props.cultureTag} key={index} userid={user.userid} avatar={user.avatar} name={user.name} rank={user.rank} points={user.points} department={user.department} mySelf={user.mySelf}/>
                    )
                }
                
            </TableBody>
            
        </Table>
    )
}

