import Truncate from "react-truncate";
import ClearIcon from "@material-ui/icons/Clear";
import ToolTip from "components/ToolTip/ToolTip";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import { IUsers } from "redux/reducers/usersReducers";
import { ICultureState } from "redux/reducers/cultureReducer";
import XAutocomplete from "common/UI/XAutocomplete/XAutocomplete";
import {
  Avatar,
  Box,
  Checkbox,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from "@material-ui/core";

interface IProps {
  users: IUsers[];
  culture: ICultureState;
  fatherState: any;
  setFatherState: (newValue: any) => void;
  prevAuto: boolean;
  auto: boolean;
  setAuto: (auto: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    actualManagers: {
      marginRight: "auto",
      marginTop: theme.spacing(2),
      minWidth: "310px",
      width: "100%",
      gap: "24px",
      maxHeight: "150px",
      overflowY: "auto",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",

      "&::-webkit-scrollbar": {
        width: "0.2em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(255, 255, 255, 0.3)",
        border: "0",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#C3C3C3",
        outline: "0px",
        borderRadius: "0px",
        border: "0",
      },
    },
    numberPercentage: {
      width: "5%",
      marginLeft: 8,
      marginRight: 8,
      fontWeight: 500,

      "& ::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& ::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "-moz-appearance": "textfield",
    },
  })
);

function IdeaDevelopers({
  users,
  culture,
  fatherState,
  setFatherState,
  prevAuto,
  auto,
  setAuto,
}: IProps) {
  const classes = useStyles();
  const [autoComplete, setAutoComplete] = useState<Array<IUsers>>(
    fatherState.usersAllowed ?? []
  );

  const handleDeleteValue = (value: any) => {
    setAutoComplete(autoComplete.filter((x) => x.fullName !== value));
  };

  useEffect(() => {
    setFatherState({ ...fatherState, usersAllowed: autoComplete });
    // eslint-disable-next-line
  }, [autoComplete, setFatherState]);

  useEffect(() => {
    setFatherState({ ...fatherState, allowResponsiblesChooseEditors: auto });
    // eslint-disable-next-line
  }, [auto, setFatherState]);

  return (
    <Box pt={1}>     
      <Grid container item direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h4">
            <Resource tag={"PageCreateChallenge::Workflow_IdeaDevelopers"} />
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item xs={9} sm={9} md={9} xl={9}>
              <Typography variant="body2">
                <Resource
                  tag={
                    "PageCreateChallenge::Workflow_EnableTheResponsiblesForTransition"
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3} xl={3}>
              <Typography component="div">
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      variant="caption"
                      style={{ textTransform: "uppercase" }}
                    >
                      <Resource tag={"PageCreateChallenge::Workflow_OFF"} />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={auto}
                      disabled={prevAuto}
                      onChange={(e, c) => setAuto(c)}
                      color="primary"
                      name="haveComments"
                      // inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      style={{ textTransform: "uppercase" }}
                    >
                      <Resource tag={"PageCreateChallenge::Workflow_ON"} />
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
          </Grid>
          <Box textAlign="right" width="89%">
            {prevAuto && (
              <ToolTip error isLeft>
                <Resource
                  tag={"PageCreateChallenge::Workflow_DevelopmentTooltip"}
                />
              </ToolTip>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Divider style={{ width: "100%" }} />
        </Grid>
        <Grid item>
          {!auto && (
            <Box>
              <Box>
                <Typography variant="h4">
                  <Resource
                    tag={
                      "PageCreateChallenge::Workflow_ResponsibleForAllEditions"
                    }
                  />
                </Typography>
                <Box mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    <Resource
                      tag={
                        "PageCreateChallenge::Workflow_SelectTheUsersResponsible"
                      }
                    />
                  </Typography>
                </Box>
                <Box mt={3}>
                  <FormControl component="fieldset" style={{ width: "50%" }}>
                    <FormGroup>
                      <FormControlLabel
                        checked={fatherState.allowAuthorEdit}
                        control={<Checkbox color="primary" name="author" />}
                        onChange={(e, c) =>
                          setFatherState({ ...fatherState, allowAuthorEdit: c })
                        }
                        label={
                          <Typography variant="body2">
                            <Resource
                              tag={"PageCreateChallenge::Workflow_Author"}
                            />
                          </Typography>
                        }
                      />
                      <Divider style={{ width: "100%" }} />
                      <FormControlLabel
                        checked={fatherState.allowCoAuthorEdit}
                        control={<Checkbox color="primary" name="coAuthors" />}
                        onChange={(e, c) =>
                          setFatherState({
                            ...fatherState,
                            allowCoAuthorEdit: c,
                          })
                        }
                        label={
                          <Typography variant="body2">
                            <Resource
                              tag={"PageCreateChallenge::Workflow_CoAuthors"}
                            />
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
                <Box mt={5}>
                  <XAutocomplete
                    options={users}
                    optionName={"fullName"}
                    optionAvatar={"photo"}
                    optionSubtitle={"email"}
                    autoCompleteValue={autoComplete}
                    onChange={(newValue) => setAutoComplete(newValue)}
                    culture={culture}
                  />
                  <Box className={classes.actualManagers} mt={1}>
                    {autoComplete?.map((value: any, index: number) => {
                      return (
                        <div
                          className="manager-item-containerPopup"
                          key={value.userId ?? index}
                        >
                          <Avatar
                            src={value?.photo}
                            alt={value.fullName}
                            className={classes.thumbnail}
                          />
                          <div className="manager-item-label">
                            <Truncate width={120} ellipsis={<span>...</span>}>
                              {value.fullName}
                            </Truncate>
                          </div>
                          <div className="manage-item-cross">
                            <IconButton
                              className="bottom-manage-item-cross"
                              onClick={() => handleDeleteValue(value.fullName)}
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        </div>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default IdeaDevelopers;
