import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import reactNewApp from "utils/reactNewApp";
import Resource from "../Resources/Resource";
import { IIdeaDetails } from "redux/types/ideas";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { IAppState } from "../../redux/store/Store";
import { getAllUsers } from "redux/actions/UsersAction";
import { ICultureState } from "../../redux/reducers/cultureReducer";
import EditIdeaPopUpV3 from "views/Ideas/CreateIdea/EditIdeaPopUpV3";
import { IUsers, IUsersState } from "redux/reducers/usersReducers";
import EditIdeaPopUp from "views/Ideas/CreateIdea/EditIdeaPopUp";
import { IKeyTasksState } from "redux/reducers/keyTasks/keyTasksReducer";
import { IChallengeState } from "redux/reducers/challenges/challengeReducer";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import KeyTaskIdeaDetailEvaluationComments from "components/KeyTaskIdeaDetailEvaluationComments/KeyTaskIdeaDetailEvaluationComments";
import {
  Avatar,
  Box,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  IChallengeList,
  IChallengeListItem,
  IIdeaForm,
  IIdeaFormV3,
} from "redux/types/challenges";
import {
  IProfileGetDataAction,
  getProfile,
} from "redux/actions/ProfileActions";
import {
  getIdeasForm,
  getIdeasFormV3,
  IChallengeGetIdeaFormAction,
} from "redux/actions/ChallengeActions";
import {
  IIdeasDetailGetAllAction,
  getAllIdeaDetails,
  putIdeaUpdate,
  IdeaPutAction,
} from "../../redux/actions/ideas/IdeaAction";
import {
  getAllKeyTasks,
  getCorrectionKeyTasks,
  getValidationKeyTasks,
  postKeyTasksTransitions,
} from "redux/actions/keyTasks/KeyTasksAction";
import TimeFrom from "components/TimeFrom/TimeFrom";
import newApp from 'utils/reactNewApp';

interface IDispatchProps {
  postKeyTasksTransitions: (options: object) => Promise<IKeyTasksState>;
  getKeyTasks: (options: object) => Promise<IKeyTasksState>;
  getAllIdeaDetails: (options: object) => Promise<IIdeasDetailGetAllAction>;
  getAllUsers: (options: object) => Promise<IUsersState>;
  getIdeasFormV3: (params: object) => Promise<IChallengeState>;
  getIdeasForm: (options: object) => Promise<IChallengeGetIdeaFormAction>;
  getCorrectionKeyTasks: (options: object) => Promise<IKeyTasksState>;
  putIdeaUpdate: (params: object, options: object) => Promise<IdeaPutAction>;
  getValidationKeyTasks(options: object): Promise<IKeyTasksState>;
  getProfile: () => Promise<IProfileGetDataAction>;
}

interface IProps {
  workspaceId: string;
  type?: string;
  closeButton?: any;
  applicationId?: string | number;
  culture?: ICultureState;
  ideaDetails: IIdeaDetails;
  ideaId: string;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  relatedStatus: string;
  ideaFormV3: IIdeaFormV3[];
  ideasForm: IIdeaForm;
  userList: IUsers[];
  challengeList: IChallengeList;
  ideaChallenge: IChallengeListItem;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: "30px 60px 0",
      fontSize: 18,
    },
    pageTitle: {
      marginTop: 24,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    divider: {
      width: "100%",
      color: theme.palette.grey[500],
      marginTop: 8,
    },
    grid: {
      padding: " 12px 5% 3%",
      position: "relative",
    },
    ideaTitle: {
      fontSize: 20,
      marginBottom: 4,
    },
    boxCommentToAdjus: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: 2.98733,
      padding: "8px 10px 15px 15px",
    },
    thumbnail: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  })
);

const KeyTaskIdeaCorrectionPopUp = (props: IDispatchProps & IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    ideaDetails,
    isOpen,
    setOpen,
    ideaId,
    ideaFormV3,
    ideasForm,
    culture,
  } = props;

  const [loading, setLoading] = useState({
    main: true,
    tabs: true,
  });
  const [isConfirmOpen, setIsConfirm] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [challenge, setChallenge] = useState<IChallengeListItem>(
    props.ideaChallenge
  );

  useEffect(() => {
    const {
      workspaceId,
      applicationId,
      getAllIdeaDetails,
      ideaId,
      getIdeasFormV3,
      getIdeasForm,
      getAllUsers,
      getProfile,
    } = props;

    const options = {
      ideaDetailId: ideaId,
      applicationId: applicationId,
      culture: props?.culture,
      challengeId: props?.ideaChallenge,
    };

    const optionsV3 = {
      ideaDetailId: ideaId,
      applicationId: applicationId,
      culture: props?.culture,
      challengeId: props?.ideaChallenge,
      workspaceId: workspaceId,
    };

    getProfile();

    getAllIdeaDetails(options).then(() => {
      setLoading((prev) => {
        return { ...prev, main: false };
      });
      setChallenge(challenge);

      if (reactNewApp)
        getIdeasFormV3(optionsV3).then(() => {
          setLoading((prev) => {
            return { ...prev, tabs: false };
          });
        });
      else if (!reactNewApp) {
        getIdeasForm(options).then(() => {
          setLoading((prev) => {
            return { ...prev, tabs: false };
          });
        });
      }
    });
    getAllUsers({ q: "" });
    // eslint-disable-next-line
  }, []);

  async function updateForm(params: any) {
    const {
      putIdeaUpdate,
      getKeyTasks,
      getCorrectionKeyTasks,
      getValidationKeyTasks,
    } = props;
    params.append("ChallengeId", props.ideaChallenge);
    // params.append('Captcha', captcha)
    params.append("ideaId", ideaId);
    if (!reactNewApp) {
      params.append("StateId", Object.keys(ideasForm.initialIdeas)[0]);
    }

    await putIdeaUpdate(params, { culture }).then(() => {
      getKeyTasks({
        pageNumber: 1,
        pageSize: 10,
      });
      getCorrectionKeyTasks({
        pageNumber: 1,
        pageSize: 10,
      });
      getValidationKeyTasks({
        pageNumber: 1,
        pageSize: 10,
      });
      setOpen(false);
    });
  }

  function handleCancel() {
    setIsConfirm(true);
  }

  let timeStampResources = newApp ? ideaDetails?.commentToAdjus?.messages?.timeStampResource?.replace(".", "::") : ideaDetails?.commentToAdjust?.messages?.timeStampResource?.replace(".", "::");
  
  return (
    <Dialog maxWidth={"lg"} fullScreen={fullScreen} fullWidth open={isOpen}>
      {!loading.main ? (
        <>
          <DialogTitle disableTypography className={classes.title}>
            <Typography color="textPrimary" variant="h4">
              <Resource tag="ModalKeyTasks::Correction" />
            </Typography>
            <IconButton
              // aria-label="close"
              className={classes.closeButton}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <Divider className={classes.divider} />
          </DialogTitle>
          <DialogContent>
            <Grid container className={classes.grid}>
              {!newApp ?
                <>
                  {ideaDetails?.commentToAdjus ? (
                    <Grid item xs={12} sm={8} className={classes.boxCommentToAdjus}>
                      <Grid item xs={12} sm={12}>
                        <Box>
                          <Typography
                            color={"textSecondary"}
                            variant="body2"
                            style={{ textTransform: "uppercase" }}
                          >
                            <Resource tag="PageEditIdea::FeedBackComment" />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box display="flex" alignContent="center" mt={1.5}>
                          <Avatar
                            src={ideaDetails?.commentToAdjus?.messages?.photo}
                            alt={ideaDetails?.commentToAdjus?.messages?.userName}
                            className={classes.thumbnail}
                          />
                          <Box
                            ml={1}
                            mt={1}
                            mr={1}
                            textAlign="left"
                            display="inline"
                            width="500px"
                          >
                            <Typography
                              color={"primary"}
                              variant="body2"
                              display="inline"
                            >
                              {ideaDetails?.commentToAdjus?.messages?.userName}
                            </Typography>
                            <Box ml={1} display="inline">
                              <Typography variant="body2" display="inline">
                                {ideaDetails?.commentToAdjus?.messages?.message}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" alignContent="center" mt={1}>
                            <Typography color={"textSecondary"} variant="body2">
                              {ideaDetails?.commentToAdjus?.messages?.timeStamp !==
                                "" &&
                              ideaDetails?.commentToAdjus?.messages?.timeStamp ? (
                                <TimeFrom
                                  time={
                                    ideaDetails?.commentToAdjus?.messages
                                      ?.timeStamp!
                                  }
                                ></TimeFrom>
                              ) : ideaDetails?.commentToAdjus?.messages?.timeAgo
                                  ?.length! >= 5 ? (
                                <Box minWidth="40px">
                                  {ideaDetails?.commentToAdjus?.messages?.timeAgo}
                                </Box>
                              ) : (
                                <Box minWidth="40px">
                                  <Resource
                                    tag={timeStampResources!}
                                    args={[
                                      ideaDetails?.commentToAdjus?.messages
                                        ?.timeAgo,
                                    ]}
                                  />
                                </Box>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              :
                <>
                  {ideaDetails?.commentToAdjust ? (
                    <Grid item xs={12} sm={8} className={classes.boxCommentToAdjus}>
                      <Grid item xs={12} sm={12}>
                        <Box>
                          <Typography
                            color={"textSecondary"}
                            variant="body2"
                            style={{ textTransform: "uppercase" }}
                          >
                            <Resource tag="PageEditIdea::FeedBackComment" />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box display="flex" alignContent="center" mt={1.5}>
                          <Avatar
                            src={ideaDetails?.commentToAdjust?.messages?.photo}
                            alt={ideaDetails?.commentToAdjust?.messages?.userName}
                            className={classes.thumbnail}
                          />
                          <Box
                            ml={1}
                            mt={1}
                            mr={1}
                            textAlign="left"
                            display="inline"
                            width="500px"
                          >
                            <Typography
                              color={"primary"}
                              variant="body2"
                              display="inline"
                            >
                              {ideaDetails?.commentToAdjust?.messages?.userName}
                            </Typography>
                            <Box ml={1} display="inline">
                              <Typography variant="body2" display="inline">
                                {ideaDetails?.commentToAdjust?.messages?.message}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" alignContent="center" mt={1}>
                            <Typography color={"textSecondary"} variant="body2">
                              {ideaDetails?.commentToAdjust?.messages?.timeStamp !==
                                "" &&
                              ideaDetails?.commentToAdjust?.messages?.timeStamp ? (
                                <TimeFrom
                                  time={
                                    ideaDetails?.commentToAdjust?.messages
                                      ?.timeStamp!
                                  }
                                ></TimeFrom>
                              ) : ideaDetails?.commentToAdjust?.messages?.timeAgo
                                  ?.length! >= 5 ? (
                                <Box minWidth="40px">
                                  {ideaDetails?.commentToAdjust?.messages?.timeAgo}
                                </Box>
                              ) : (
                                <Box minWidth="40px">
                                  <Resource
                                    tag={timeStampResources!}
                                    args={[
                                      ideaDetails?.commentToAdjust?.messages
                                        ?.timeAgo,
                                    ]}
                                  />
                                </Box>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              }
              <Grid item xs={12} sm={7}>
                <Box mt={2}>
                  <Typography color={"textSecondary"} variant="body2">
                    <Resource tag="PageEditIdea::Challenge" />
                    {ideaDetails?.challengeTitle}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}></Grid>
              <Grid item xs={12} sm={8}>
                <>
                  {reactNewApp ? (
                    <EditIdeaPopUpV3
                      challenge={ideaDetails?.challengeId}
                      setChallenge={setChallenge}
                      culture={props.culture!}
                      getIdeasFormV3={getIdeasFormV3}
                      handleCancel={handleCancel}
                      handleSubmitAction={updateForm}
                      ideaFormV3={ideaFormV3}
                      mode="edit"
                      userList={props.userList}
                      ideaDetail={ideaDetails}
                    />
                  ) : (
                    <EditIdeaPopUp
                      challenge={ideaDetails?.challengeId}
                      setChallenge={setChallenge}
                      culture={props.culture!}
                      getIdeasForm={getIdeasForm}
                      handleCancel={handleCancel}
                      handleSubmitAction={updateForm}
                      ideaForm={ideasForm}
                      mode="edit"
                      userList={props.userList}
                      ideaDetail={ideaDetails}
                    />
                  )}
                  <PopUpConfirmation
                    closeButton={() => setIsConfirm(false)}
                    sendButton={() => setOpen(false)}
                    message={<Resource tag="PageEditIdea::ExitWithoutSaving" />}
                    isOpen={isConfirmOpen}
                    setIsOpen={setIsConfirm}
                  />
                </>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box mt={4}>
                  {ideaDetails?.evaluation?.starsEvaluation?.comments && (
                    <KeyTaskIdeaDetailEvaluationComments
                      evaluate={props.type === "evaluation"}
                      evaluation={ideaDetails.evaluation}
                      comments={
                        ideaDetails?.evaluation?.starsEvaluation?.comments
                      }
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      ) : (
        <Box width="100%" textAlign="center" padding="5%">
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

const mapStateToProps = (store: IAppState, ownProps: any) => {
  return {
    workspaceId: store.authState.auth.workspaceId,
    ideaFormV3: store.challengeState.ideaFormV3,
    applicationId: store.authState.auth.applicationId,
    culture: store.cultureState,
    ideaDetails: store.ideasState.ideaDetails,
    relatedContent: store.relatedContentState.relatedContent,
    relatedStatus: store.relatedContentState.relatedStatus,
    ideasForm: store.challengeState.ideasForm,
    userList: store.usersState.users,
    challengeList: store.challengeState.challengeList,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getKeyTasks: (options: object) => dispatch(getAllKeyTasks(options)),
    getAllIdeaDetails: (parameters: object) =>
      dispatch(getAllIdeaDetails(parameters)),
    getAllUsers: (options: object) => dispatch(getAllUsers(options)),
    getIdeasFormV3: (params: object) => dispatch(getIdeasFormV3(params)),
    getIdeasForm: (parameters: object) => dispatch(getIdeasForm(parameters)),
    putIdeaUpdate: (params: object, options: object) =>
      dispatch(putIdeaUpdate(params, options)),
    getCorrectionKeyTasks: (options: object) =>
      dispatch(getCorrectionKeyTasks(options)),
    postKeyTasksTransitions: (options: object) =>
      dispatch(postKeyTasksTransitions(options)),
    getValidationKeyTasks: (options: object) =>
      dispatch(getValidationKeyTasks(options)),
    getProfile: () => dispatch(getProfile()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyTaskIdeaCorrectionPopUp);
