import React from "react";
import logo from "../../../assets/img/logo.png";
import Resource from "components/Resources/Resource";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LoggedOutFooter from "components/LoggedOutFooter/LoggedOutFooter";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  createStyles,
  FormControl,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      margin: "30px 0",
      width: "90%",
      "& label.Mui-focused": {
        color: theme.palette.primary.main,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "rgb(212, 34, 78)",
      },
      "& .MuiFormControl-root": {
        padding: "10px 0",
      },
      "& .MuiFormLabel-root": {
        fontSize: "0.8rem",
      },
      "& .MuiInputBase-input": {
        fontSize: "0.8rem",
      },
    },
    iconInvite: {
      color: "#fff",
    },
    resourceInvite: {
      color: "#fff",
      textTransform: "lowercase",
    },
    logo: {
      maxWidth: 250,
      height: 67,
      position: "absolute",
      top: "30px",
      right: "33px",
    },
    circle3: {
      background: theme.palette.primary.main,
      width: 252,
      height: 252,
      borderRadius: "50%",
      position: "absolute",
      top: "-140px",
      right: "587px",
      [theme.breakpoints.only("xs")]: {
        top: "-150px",
        right: "216px",
      },
    },
  })
);

interface IStateProps {
  statusCulture: any;
  photoLogo: any;
  pathname: string;
  email1: any;
  email2: any;
  email3: any;
  putSkip: any;
  postInvite: any;
  disableBtnInvite: any;
}

function CongratsMobile({
  statusCulture,
  photoLogo,
  pathname,
  email1,
  email2,
  email3,
  putSkip,
  postInvite,
  disableBtnInvite,
}: IStateProps) {
  const classes = useStyles();

  return (
    <>
      {statusCulture ? (
        <Container className="congrats-page">
          <Box className={classes.circle3} />
          <Box className={classes.logo}>
            <img
              style={{ maxHeight: "80px", maxWidth: "250px" }}
              src={photoLogo ? photoLogo : logo}
              alt="Exago"
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            margin="20vh auto 7vh"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h2"
                color="textPrimary"
                style={{ fontSize: "1.5rem", marginBottom: "10px" }}
              >
                <Resource tag={"PageSetup::ReadyWorkspace_Message"} />
              </Typography>
              <Typography color="textPrimary">
                <Resource tag={"PageSetup::ReadyWorkspace_Question"} />
              </Typography>
              <FormControl className={classes.form}>
                <TextField
                  id="ReadyWorkspace_Email"
                  label={<Resource tag={"PageSetup::ReadyWorkspace_Email"} />}
                  type="search"
                  onChange={email1}
                />
                <TextField
                  id="ReadyWorkspace_Email"
                  label={<Resource tag={"PageSetup::ReadyWorkspace_Email"} />}
                  type="search"
                  onChange={email2}
                />
                <TextField
                  id="ReadyWorkspace_Email"
                  label={<Resource tag={"PageSetup::ReadyWorkspace_Email"} />}
                  type="search"
                  onChange={email3}
                />
                <Box mt={6} alignSelf="flex-end" display="flex">
                  <Box mr={1}>
                    <Button color="primary" variant="text" onClick={putSkip}>
                      <Resource tag={"PageSetup::SkipStep"} />
                    </Button>
                  </Box>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={postInvite}
                    disabled={disableBtnInvite}
                    endIcon={
                      <ArrowForwardIcon className={classes.iconInvite} />
                    }
                  >
                    <Box className={classes.resourceInvite}>
                      <Resource tag={"PageSetup::ReadyWorkspace_Invite"} />
                    </Box>
                  </Button>
                </Box>
              </FormControl>
            </Box>
          </Box>
          <Box className="congrats-footer" position="relative" right="-6vw">
            <LoggedOutFooter pathname={pathname} />
          </Box>
        </Container>
      ) : (
        <Box height="100vh" display="flex" margin="0px auto">
          <Box m="auto">
            <CircularProgress size={60} className="icon" />
          </Box>
        </Box>
      )}
    </>
  );
}

export default CongratsMobile;
