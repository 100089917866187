import { Box, createStyles, Typography } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import "./ToolTip.css";

/* Exemplo de uso
    <ToolTip title="Lorem Ipsum?">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit.
    </ToolTip>
*/

interface IProps {
  title: string | JSX.Element;
  text: string | JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popText: {
      padding: "0 8px",
      display: "flex",
      // alignItems: 'center',
      "&:hover ": {
        "& span": {
          visibility: "visible",
          minWidth: "200px",
          width: "auto",
          maxWidth: "600px",
          height: "fit-content",
          maxHeight: "200px",
          overflow: "auto",
          overflowAnchor: "none",
        },
      },
    },
    tooltipText: {
      visibility: "hidden",
      backgroundColor: "white",
      marginLeft: "-3px",
      fontSize: "12px",
      color: "#000",
      textAlign: "left",
      minWidth: 0,
      width: 0,
      height: 0,
      overflow: "hidden",
      padding: "15px 20px",

      borderRadius: "6px",
      left: "10px",
      top: "0px",
      boxShadow: "0px 2px 10px -1px rgba(0, 0, 0, 0.2)",
      position: "absolute",
      zIndex: 6,

      "&:after": {
        content: "",
        position: "absolute",
        top: "10%",
        right: "100%",
        /* To the left of the tooltip */
        borderWidth: "7px",
        borderStyle: "solid",
        borderColor: "transparent white transparent transparent",
      },
    },
  })
);

function TextToolTip(props: any | IProps) {
  const classes = useStyles();

  return (
    <Box className={classes.popText}>
      {props?.text}
      <Box position="relative">
        <span className={classes.tooltipText}>
          <Typography variant="h5">
            <strong>{props?.title}</strong>
            <br />
            <br />
          </Typography>
          {props?.children}
        </span>
      </Box>
    </Box>
  );
}

export default TextToolTip;
