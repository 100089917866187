import React, { useState } from "react";
import imageEmpty from "assets/img/empty@3x.png";
import Resource from "components/Resources/Resource";
import { Link as RouterLink } from "react-router-dom";
import { IMessagesReceived } from "redux/types/messages";
import PopUpConfirmation from "components/PopUpConfirmation/PopUpConfirmation";
import { ICultureState } from "redux/reducers/cultureReducer";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";
import MessageListUserItem from "common/UI/List/User/MessageListUserItem";
import {
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Theme,
  Box,
  Button,
  Link,
} from "@material-ui/core";

interface IProps {
  culture: ICultureState;
  receivedMessages: IMessagesReceived;
  getReceivedMessages: (
    pageSize: number,
    pageNumber: number,
    isInfinite: boolean
  ) => Promise<any>;
  deleteMessages: (options: object) => void;
  postMessagesArchive: (options: object) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& label": {
        width: "100%",
        margin: "0 0 0 -11px",
        "& span:last-child": {
          width: "100%",
        },
      },
    },
    textUpper: {
      textTransform: "uppercase",
    },
    divider: {
      width: "99%",
      marginTop: 2,
      marginBottom: 2,
      background:
        "linear-gradient(90deg, rgba(242,242,242,0.3617822128851541) 0%, rgba(242,242,242,1) 29%, rgba(242,242,242,1) 66%, rgba(242,242,242,0.32816876750700286) 100%)",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    checkboxAll: {
      "& .MuiIconButton-label": {
        marginLeft: "-11px",
      },
    },
  })
);

function MessagesReceivedContent({
  culture,
  receivedMessages,
  getReceivedMessages,
  deleteMessages,
  postMessagesArchive,
}: IProps) {
  const classes = useStyles();
  const backURL = "messages/details";

  //state to delete and archive confirmation dialogs
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);

  //function to alter state checkbox
  const [messagesState, setMessagesState] = useState<Array<string>>([]);
  const handleChangeCheckbox = (id: string) =>
    messagesState?.includes(id)
      ? setMessagesState(messagesState?.filter((msg) => msg !== id))
      : setMessagesState([...messagesState, id]);

  //function to alter state checkbox all
  const [allChecked, setAllChecked] = useState(false);
  const handleChangeAllCheckbox = (event: React.ChangeEvent<any>) => {
    setAllChecked(event.target.checked);
    if (event.target.checked) {
      setMessagesState(
        receivedMessages?.result?.map((msg: any) => msg.messageId) || []
      );
    } else {
      setMessagesState([]);
    }
  };

  //function to delete message
  const handleDelete = () => deleteMessages({ messageIds: messagesState });
  //function to archive message
  const handleArchive = () =>
    postMessagesArchive({ messageIds: messagesState });

  return (
    <>
      {receivedMessages?.meta?.total === 0 ||
      receivedMessages?.meta?.total === undefined ? (
        <Box
          className="no-message-received"
          mt={7}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Resource tag="PageMessages::NotReceivedMessage" />
          <Box className="emptyImg" mt={5}>
            <img src={imageEmpty} alt="" width="340px" height="350px" />
          </Box>
        </Box>
      ) : (
        <FormGroup className={classes.root}>
          <Box className="checkAll-delete-archive-buttons">
            <Checkbox
              className={classes.checkboxAll}
              name="checkedAll"
              color="primary"
              checked={allChecked}
              onChange={handleChangeAllCheckbox}
            />
            <Button
              className={classes.textUpper}
              variant="text"
              color="primary"
              onClick={(e: any) => {
                setOpenDeleteDialog(true);
                e.stopPropagation();
              }}
              onFocus={(e: any) => e.stopPropagation()}
              disabled={messagesState?.length <= 0}
            >
              <Resource tag={"PageMessages::Delete"} />
            </Button>
            <PopUpConfirmation
              closeButton={() => setOpenDeleteDialog(false)}
              message={<Resource tag={"PageMessages::ConfirmDelete"} />}
              setIsOpen={setOpenDeleteDialog}
              isOpen={openDeleteDialog}
              sendButton={handleDelete}
            />
            <Button
              className={classes.textUpper}
              variant="text"
              color="primary"
              onClick={(e: any) => {
                e.stopPropagation();
                setOpenArchiveDialog(true);
              }}
              onFocus={(e: any) => e.stopPropagation()}
              disabled={messagesState?.length <= 0}
            >
              <Resource tag={"PageMessages::Archive"} />
            </Button>
            <PopUpConfirmation
              closeButton={() => setOpenArchiveDialog(false)}
              message={<Resource tag={"PageMessages::ConfirmArchive"} />}
              setIsOpen={setOpenArchiveDialog}
              isOpen={openArchiveDialog}
              sendButton={handleArchive}
            />
          </Box>
          <DynamicInfiniteScroll
            getAction={(pageNumber, pageSize) =>
              getReceivedMessages(pageNumber, pageSize, true)
            }
            dataList={receivedMessages}
            pageSize={8}
            // isAutomatic
          >
            {receivedMessages?.result &&
              receivedMessages?.result?.map((message: any) => (
                <FormControlLabel
                  className="checkbox-messageList-container"
                  key={message?.messageId}
                  control={
                    <Checkbox
                      checked={messagesState?.includes(message?.messageId)}
                      onChange={() => handleChangeCheckbox(message?.messageId)}
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label={
                    <Link
                      component={RouterLink}
                      to={{ pathname: `${backURL}/${message?.messageId}` }}
                      className={classes.link}
                    >
                      <MessageListUserItem
                        culture={culture}
                        isMarkedAsRead={message?.isMarkedAsRead}
                        checked={messagesState?.includes(message?.messageId)}
                        avatar={message?.author?.photo}
                        userName={message?.author?.fullName}
                        msgSubject={message?.subject}
                        msgContent={message?.message}
                        msgDate={message?.date}
                      />
                      <Divider className={classes.divider} />
                    </Link>
                  }
                />
              ))}
          </DynamicInfiniteScroll>
        </FormGroup>
      )}
    </>
  );
}

export default MessagesReceivedContent;
