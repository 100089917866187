import {
  Box,
  Button,
  ClickAwayListener,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import Resource from "components/Resources/Resource";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ukIcon from "assets/img/ukIcon.svg";
import spainIcon from "assets/img/spainIcon.svg";
import brazilIcon from "assets/img/brazilIcon.svg";
import portugalIcon from "assets/img/portugalIcon.svg";
import romeniaIcon from "assets/img/romeniaIcon.svg";
import { ThunkDispatch } from "redux-thunk";
import {
  getResourcesDefault,
  ICultureSetCulture,
  setCultureAndResources,
} from "redux/actions/CultureActions";
import { store } from "redux/store/Store";
import { IAuthUser } from "redux/reducers/authReducer";

// Fake data, delete after really API
const data = {
  cultureTag: [
    {
      id: 1,
      img: ukIcon,
      language: "en-GB",
    },
    {
      id: 2,
      img: brazilIcon,
      language: "pt-BR",
    },
    {
      id: 3,
      img: spainIcon,
      language: "es-ES",
    },
    {
      id: 4,
      img: portugalIcon,
      language: "pt-PT",
    },
    {
      id: 5,
      img: romeniaIcon,
      language: "ro-RO",
    },
  ],
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    containerOp: {
      bottom: 25,
      right: 0,
      left: 0,
      zIndex: 1000000000000,
      padding: "8px 0",
      backgroundColor: theme.palette.background.paper,
      width: "120px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    languageOp: {
      color: "rgba(0, 0, 0, 0.87)",
      width: "120px",
      cursor: "pointer",
      margin: "6px 10px",
      display: "flex",
      padding: "5px 15px",
      borderRadius: "20px",
      boxSizing: "border-box",
      justifyContent: "center",
      "&:active": {
        boxShadow: "0 0 3px #626262",
        fontWeight: "bold",
      },
      "&:hover": {
        boxShadow: `0px 0px 3.87097px ${theme.palette.primary.main}`,
        fontWeight: "bold",
      },
      "&:selected": {
        boxShadow: `0px 0px 3.87097px ${theme.palette.primary.main}`,
        fontWeight: "bold",
      },
    },
    imgLanguage: {
      width: "18px",
      height: "18px",
      margin: "0 12px 0 0",
    },
    btnValue: {
      boxShadow: `0px 0px 3.87097px ${theme.palette.primary.main}`,
      width: "140px",
      color: "transparent",
      justifyContent: "space-around",
    },
    language: {
      textTransform: "uppercase",
      color: theme.palette.text.primary,
    },
    icon: {
      color: theme.palette.primary.main,
    },
  })
);

interface IProps {
  authUser?: IAuthUser;
}

function ChooseLanguage(props: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  let cultureNavigator = data.cultureTag.filter(
    (culture) => culture.language === navigator.language
  );
  const [value, setValue] = React.useState(
    cultureNavigator.length > 0 ? cultureNavigator[0] : data.cultureTag[0]
  );
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setCulture(value.language);
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  const handleClickAway = () => {
    setOpen(false);
  };
  const handleSelect = (e: any) => {
    setValue(e);
    setOpen(false);

    props?.authUser?.isAuthenticated === false ||
    props?.authUser?.isAuthenticated === undefined
      ? setCulture(e.language)
      : getResourcesHandler(e.language);
  };

  function setCulture(cultureTag: any) {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store.dispatch;
    dispatch(getResourcesDefault(cultureTag));
  }

  function getResourcesHandler(cultureTag: string) {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store.dispatch;
    dispatch(setCultureAndResources(cultureTag));
    return store.getState().cultureState;
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box className={classes.root}>
          {open ? (
            <Box style={{ position: "absolute", bottom: "30px" }}>
              <Box
                fontSize="0.7rem"
                fontWeight="bold"
                color={theme.palette.text.primary}
              >
                <Resource tag={"PageLogin::ChooseLanguage"} />:
              </Box>
              <Box className={classes.containerOp}>
                {data.cultureTag.map((tag, index) => {
                  return (
                    <div key={index}>
                      {value.id !== tag.id && (
                        <Paper
                          elevation={0}
                          className={classes.languageOp}
                          onClick={(e) => handleSelect(tag)}
                        >
                          <Box display="flex" mr={1}>
                            <img
                              width="18px"
                              height="18px"
                              alt={tag.language}
                              src={tag.img}
                            ></img>
                          </Box>
                          <Box className={classes.language}>{tag.language}</Box>
                        </Paper>
                      )}
                    </div>
                  );
                })}
              </Box>
            </Box>
          ) : null}
          <Button
            className={classes.btnValue}
            variant="outlined"
            onClick={handleClick}
          >
            <Box display="flex" mr={1}>
              <img
                width="18px"
                height="18px"
                alt={value.language}
                src={value.img}
              />
            </Box>
            <Typography color="textPrimary">{value.language}</Typography>
            {!open ? (
              <KeyboardArrowUpIcon className={classes.icon} />
            ) : (
              <KeyboardArrowDownIcon className={classes.icon} />
            )}
          </Button>
        </Box>
      </ClickAwayListener>
    </>
  );
}

export default ChooseLanguage;
