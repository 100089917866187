import React, {useEffect, useState} from "react";
import "./ChallengeList.css";
import ActiveCard from "components/ChallengesCards/ActiveCard/ActiveCard";
import {
    ChallengeDrafts,
    IChallengeActive,
    IChallengeHighlighted, IChallengesClosed,
    IChallengesInProgress,
    IChallengesPermanent,
} from "redux/types/challenges";
import HighlightedCarousel from "components/ChallengesCarousels/HighlightedCarousel/HighlightedCarousel";
import InProgressCarousel from "components/ChallengesCarousels/InProgressCarousel/InProgressCarousel";
import Resource, { resource } from "components/Resources/Resource";
import {Box, Typography, makeStyles, Theme, createStyles, AppBar, Tab} from "@material-ui/core";
import imagemNotChallenges from "assets/img/empty@3x.png";
import { ICultureState } from "redux/reducers/cultureReducer";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {
    getChallengesActives,
    getChallengesInProgress, IChallengeShowMoreAction,
} from "../../redux/actions/ChallengeActions";
import DynamicInfiniteScroll from "../../views/KeyTasks/DynamicInfiniteScroll";
import ChallengeClosedList from "components/ChallengesCarousels/ClosedCarousel/ClosedCarousel";
import ChallengePermanentList from "../../components/ChallengesCarousels/PermanentCarousel/PermanentCarousel";
import ChallengeInProgressList from "components/ChallengesCarousels/InProgressCarousel/InProgressCarousel";

// Create the containers interface
interface IProps {
  permission: string;
  activeChallenges: IChallengeActive;
  getChallengeClosed: (options: object) => Promise<any>;
  getChallengePermanent: (options: object) => Promise<any>;
  getChallengeInProgress: (options: object) => Promise<any>;
  getChallengeActive: (options: object) => Promise<any>;
  highlightedChallenges: IChallengeHighlighted;
  challengeDrafts: ChallengeDrafts;
  draftStatus: string;
  culture: ICultureState;
  workspaceId: string;
  challengeStatus: string;
  ChallengeClosed: IChallengesClosed;
  ChallengePermanent: IChallengesPermanent;
  ChallengeInProgress: IChallengesInProgress;
  ChallengeActive: IChallengeActive;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    futureChallengeContainer: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: 11,
    },
    root: {
      width: "100%",
    },
    noSidePadding: {
      padding: "24px 0",
    },
    topPadding: {
      paddingTop: "3%",
      background: "none",
    },
    noDataChallenges: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 2.0,
    },
  })
);

function ChallengeList(props: IProps) {
  const classes = useStyles();

  const textNoDataChallenges = resource(props.culture?.culture, "Errors::NoChallengesAvailable")?.split("<br></br>");

  const {
    highlightedChallenges,
    challengeDrafts,
    permission,
    culture,
    challengeStatus,
    ChallengeClosed,
    ChallengePermanent,
    ChallengeInProgress,
    ChallengeActive,
    getChallengeClosed,
    getChallengePermanent,
    getChallengeInProgress,
    getChallengeActive,
    workspaceId,
  } = props;

  const [challengeC, setChallengeC] = useState<IChallengesClosed>();
  const [challengeP, setChallengeP] = useState<IChallengesPermanent>();
  const [challengeIP, setChallengeIP] = useState<IChallengesInProgress>();
  const [challengeA, setChallengeA] = useState<IChallengeActive>();

  useEffect(() => {
      setChallengeP((oldChallengeP) => {
          let res = oldChallengeP;
          if (res != undefined) {
              res.meta = ChallengePermanent.meta;
              ChallengePermanent.result.map((chaP, index) => {
                  res?.result.push(chaP);
              })
          } else {
              res = ChallengePermanent;
          }
          return res;
      });
  }, [ChallengePermanent])

  useEffect(() => {
      setChallengeC((oldChallengeC) => {
          let res = oldChallengeC;
          if (res != undefined) {
              res.meta = ChallengeClosed.meta;
              ChallengeClosed.result.map((chaC, index) => {
                  res?.result.push(chaC);
              })
          } else {
              res = ChallengeClosed;
          }
          return res;
      });
  }, [ChallengeClosed])

  useEffect(() => {
      setChallengeIP((oldChallengeIP) => {
          let res = oldChallengeIP;
          if (res != undefined) {
              res.meta = ChallengeInProgress.meta;
              ChallengeInProgress.result.map((chaIP, index) => {
                  res?.result.push(chaIP);
              })
          } else {
              res = ChallengeInProgress;
          }
          return res;
      });
  }, [ChallengeInProgress])

  useEffect(() => {
      setChallengeA((oldChallengeIP) => {
          let res = oldChallengeIP;
          if (res != undefined) {
              res.meta = ChallengeActive.meta;
              ChallengeActive.result.map((chaA, index) => {
                  res?.result.push(chaA);
              })
          } else {
              res = ChallengeActive;
          }
          return res;
      });
  }, [ChallengeInProgress])

  const [tab, setTab] = useState("1");

  const handleChangeTab = (event: any, newValue: string) => {
      setTab(newValue);
  };

  // Maximum number of active cards for the home page
  return (
    highlightedChallenges?.meta?.total ||
    (challengeDrafts?.meta?.total && (permission === "Admin" || permission === "ChallengeManager"))
  ) ? (
    <Box className={classes.root} marginY={3}>
      <br/>
      {highlightedChallenges?.result?.length > 0 ? <HighlightedCarousel challenges={highlightedChallenges} /> : ""}
      <TabContext value={tab}>
        <AppBar className={classes.topPadding} position="static" elevation={0}>
          <TabList
              onChange={handleChangeTab}
              variant="standard"
              scrollButtons="on"
          >
            <Tab label={<Resource tag="PageChallenges::Challenge_Active" />} value="1" />
            <Tab label={<Resource tag="PageChallenges::Challenge_Permanent" />} value="3" />
            <Tab label={<Resource tag="PageChallenges::Challenge_InProgress" />} value="2" />
            <Tab label={<Resource tag="PageChallenges::Challenge_Closed" />} value="4" />
          </TabList>
        </AppBar>
        <TabPanel value="1" className={classes.noSidePadding}>
          <Box className="challenge-list-container" width="100%">
              <DynamicInfiniteScroll
                  getAction={(pn, ps) => getChallengeActive({
                      workspaceId: workspaceId,
                      pageNumber: pn,
                      pageSize: ps,
                  })}
                  dataList={ChallengeActive}
                  pageSize={10}
                  isAutomatic={true}
              >
                {challengeA !== undefined ?
                    challengeA?.result?.map((challenge, index) => <ActiveCard key={"active_" + index} culture={culture} challenge={challenge} />)
                    : ""}
              </DynamicInfiniteScroll>
          </Box>
        </TabPanel>

        <TabPanel value="2" className={classes.noSidePadding}>

            <DynamicInfiniteScroll
                getAction={(pn, ps) => getChallengeInProgress({
                    workspaceId: workspaceId,
                    pageNumber: pn,
                    pageSize: ps,
                })}
                dataList={ChallengeInProgress}
                pageSize={10}
                isAutomatic={true}
            >
                <ChallengeInProgressList
                    challengesInProgress={challengeIP!}
                    culture={culture}
                    challengeStatus={challengeStatus}
                />
            </DynamicInfiniteScroll>
        </TabPanel>

        <TabPanel value="3" className={classes.noSidePadding}>
            <DynamicInfiniteScroll
                getAction={(pn, ps) => getChallengePermanent({
                    workspaceId: workspaceId,
                    pageNumber: pn,
                    pageSize: ps,
                })}
                dataList={ChallengePermanent}
                pageSize={10}
                isAutomatic={true}
            >
                <ChallengePermanentList
                    challengePermanent={challengeP!}
                    culture={culture}
                    challengeStatus={challengeStatus}
                />
            </DynamicInfiniteScroll>
        </TabPanel>

        <TabPanel value="4" className={classes.noSidePadding}>
            <DynamicInfiniteScroll
                getAction={(pn, ps) => getChallengeClosed({
                    workspaceId: workspaceId,
                    pageNumber: pn,
                    pageSize: ps,
                })}
                dataList={ChallengeClosed}
                pageSize={10}
                isAutomatic={true}
            >
              <ChallengeClosedList
                  challengeClosed={challengeC!}
                  culture={culture}
                  challengeStatus={challengeStatus}
              />
            </DynamicInfiniteScroll>
        </TabPanel>
      </TabContext>
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={2} className="no-prizes">
      <Typography variant="h4" className={classes.noDataChallenges}>
        {textNoDataChallenges[0]}
      </Typography>
      <Typography variant="h4" className={classes.noDataChallenges}>
        {textNoDataChallenges[1]}
      </Typography>
      <img src={imagemNotChallenges} alt="" />
    </Box>
  );
}

export default ChallengeList;
