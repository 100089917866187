import { Box, createMuiTheme, Theme, ThemeProvider, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import Resource from "components/Resources/Resource";
import { DatePicker, DatePickerProps, KeyboardDatePicker } from "@material-ui/pickers";

const newTheme = (theme: Theme) => {
  const materialTheme = createMuiTheme({
    overrides: {
      // fontFamily: 'Rubik',
      MuiTypography: {
        body1: {
          fontFamily: "Rubik",
          fontWeight: 500,
          textTransform: "capitalize",
        },
        body2: {
          fontFamily: "Rubik",
          textTransform: "capitalize",
        },
        colorTextPrimary: {
          color: theme.palette.text.primary,
        },
      },
      MuiInputBase: {
        root: {
          fontFamily: "Rubik",
          color: theme.palette.text.primary,
          width: "47%",
        },
      },
      MuiInput: {
        underline: {
          // '&:before': {
          //   borderBottom: `2px solid ${theme.palette.primary.main}`
          // },
          "&:after": {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
        },
      },
      //@ts-ignore
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: theme.palette.primary.main,
        },
      },
      MuiPickersCalendarHeader: {
        dayLabel: {
          color: theme.palette.text.primary,
        },
        transitionContainer: {
          color: theme.palette.primary.main,
        },
      },
      MuiPickersDay: {
        day: {
          color: theme.palette.text.primary,
        },
        daySelected: {
          backgroundColor: theme.palette.primary.main,

          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
        dayDisabled: {
          color: theme.palette.text.disabled,
        },
        current: {
          color: theme.palette.primary.main,
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: theme.palette.primary.main,
        },
      },
    },
  });
  return materialTheme;
};

interface IProps {
  keyboardDatePicker?: boolean,
  disablePast?:boolean
}

function XDatepicker({ keyboardDatePicker, value, onChange, label, ...props }: IProps & DatePickerProps) {
  const theme = useTheme();
  useEffect(() => {
    newTheme(theme);

    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={newTheme}>
      <Box mt={keyboardDatePicker ? 1 : 2}>
        {keyboardDatePicker ? (
          <KeyboardDatePicker
            {...props}
            label={
              <Box style={{ textTransform: "uppercase" }}>
                {label}
              </Box>
            }
            variant="inline"
            format="dd/MM/yyyy"
            disableToolbar
            disablePast={props.disablePast??true}
            autoOk
            value={value}
            onChange={onChange}
          />
        ) : (
          <DatePicker
            {...props}
            label={
              <Box style={{ textTransform: "uppercase" }}>
                {
                  label?
                  <Resource tag={`${label}`} />:
                  <Resource tag={"PageCreateChallenge::Workflow_FinalDate"} />
                }
              </Box>
            }
            variant="inline"
            format="dd/MM/yyyy"
            disableToolbar
            disablePast={props.disablePast??true}
            autoOk
            value={value}
            onChange={onChange}
          />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default XDatepicker;
