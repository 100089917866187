import { Reducer } from 'redux';
import {
  AlertActions,
  AlertActionTypes,
} from '../actions/AlertAction';

// Define the Character type
export interface IResult {
  alertId: string,
  isNew: boolean,
  isVisualized: boolean,
  message: string,
  timeStamp: string,
  timeStampSeparatorResource: string,
  timeStampResource: string,
  type: number,
  object: string,
  objectId: string,
  objectIdHighlight: string
}
export interface IAlert {
  meta: {
    currentPage: number,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number
  },
  result: IResult[]
};

// Define the Character State
export interface IAlertState {
  readonly alerts: IAlert;
  readonly status: string;
  readonly error: any;
}

// Define the initial state
const initialCharacterState: IAlertState = {
  alerts: {
      meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0
      },
      result: [
        {
          alertId: "",
          isNew: true,
          isVisualized: true,
          message: "",
          timeStamp: "",
          timeStampSeparatorResource: "",
          timeStampResource: "",
          type: 0,
          object: "",
          objectId: "",
          objectIdHighlight: ""
        }
      ]
    },
    status: '',
    error: null
};

export const alertReducer: Reducer<IAlertState, AlertActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case AlertActionTypes.GET_ALL_ALERTS_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case AlertActionTypes.GET_ALL_ALERTS_SUCCEEDED: {
      return {
        ...state,
        alerts: action.alerts,
        status: action.status
      };
    }
    case AlertActionTypes.GET_ALL_ALERTS_FAILED: {
      return {
        ...state,
        status: action.status
      };
    }
    case AlertActionTypes.POST_ALERT_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case AlertActionTypes.POST_ALERT_SUCCEEDED: {
      return {
        ...state,
        alerts: action.alerts,
        status: action.status
      };
    }
    case AlertActionTypes.POST_ALERT_FAILED: {
      return {
        ...state,
        status: action.status
      };
    }
    case AlertActionTypes.POST_ALL_ALERT_LOADING: {
      return {
        ...state,
        status: action.status
      };
    }
    case AlertActionTypes.POST_ALL_ALERT_SUCCEEDED: {
      return {
        ...state,
        status: action.status
      };
    }
    case AlertActionTypes.POST_ALL_ALERT_FAILED: {
      return {
        ...state,
        status: action.status
      };
    }
    default:
      return state;
  }
};