import React from "react";
import "./TextEditorInput.scss";
import { toast } from "react-toastify";
import SunEditor from "suneditor-react";
import ToolTip from "../../ToolTip/ToolTip";
import "suneditor/dist/css/suneditor.min.css";
import { resource } from "components/Resources/Resource";
import { ICultureState } from "redux/reducers/cultureReducer";
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Box,
} from "@material-ui/core";

//Example:
//<TextEditorInput props={IInput format} width="100%"/>
// https://github.com/mkhstar/suneditor-react Check documentation for more utilities of SunEditor

interface IProps {
  width?: string | number;
  handler?: any;
  value?: any;
  propsI: any;
  culture?: ICultureState;
  charCounterLabel?: string;
  charCounterLabelShow?: boolean;
  maxCharCount?: string;
  maxCharCountShow?: boolean | number;
  onChange?: any;
  height?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleT: {
      marginRight: "8px",
      fontWeight: 700,
      fontSize: "0.8rem",
      textTransform: "uppercase",
      color: theme.palette.text.secondary,
      "&:focus": {
        color: "#1B9DFC",
      },
    },
  })
);

function TextEditorInput(props: IProps) {
  const classes = useStyles();
  const { propsI, width, handler, value, culture, onChange, height } = props;

  const textValueChange = (value: string) => {
    if (value.length > 1000000) {
      // 10 mb with photos
      toast.warning(
        resource(props.culture, "Alert::CreateIdea_DescriptionIsTooLarge")
      );
    }

    if (handler !== undefined) {
      handler();
    }

    if (onChange) {
      onChange(value);
    }
  };
  const returnLang = () => {
    if (culture) {
      switch (culture.culture.cultureTag) {
        case "en-GB":
          return "en"
        case "pt-PT":
        case "pt-BR":
          return "pt_br"
        case "ro-RO":
          return "ro"
        case "es-ES":
          return "es"
        default:
          return "en"
      }
    }
    return "en"
  }

  return (
    <Box width="100%" className="sun-editor-container">
      <Box display="flex" alignItems="center" textAlign="start">
        <Typography className={classes.titleT}>
          {propsI?.title || propsI.cultures?.[0]?.title}
          {propsI?.isMandatory || propsI?.isRequired ? " *" : ""}
        </Typography>
        {propsI?.help || propsI?.description ? (
          <ToolTip title="Help">{propsI?.help || propsI?.description}</ToolTip>
        ) : (
          ""
        )}
      </Box>
      <Box className="text-editor">
        <SunEditor
          lang={returnLang()}
          enable={propsI?.isEditable}
          onChange={textValueChange}
          name={propsI?.columnName || propsI?.ideaCustomFieldId}
          setContents={value?.value || value}
          width={width}
          onInput={(e) => { }}
          onDrop={(event, cleanData, maxCharCount) => {
            return true;
          }}
          setOptions={{
            minHeight: height ? height : 140,
            buttonList: [
              [
                "fontSize",
                "bold",
                "underline",
                "italic",
                "fontColor",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "image",
                "link",
              ],
            ],
            imageUrlInput: false,
            // resizingBar: false,
            maxCharCount: props?.maxCharCountShow ? 3000 : 0,
            charCounterLabel: props?.charCounterLabelShow
              ? props?.charCounterLabel
                ? props?.charCounterLabel
                : `${resource(culture, "ManageGroups::AvailableCharacters")}:`
              : "",
          }}
          placeholder={propsI?.placeholder}
        />
      </Box>
    </Box>
  );
}

export default TextEditorInput;
