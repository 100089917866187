import { Reducer } from "redux";
import { ProfileActions, ProfileActionTypes } from "../actions/ProfileActions";

// Define the Character type
export interface IProfile {
  userId: string;
  fullName: string;
  photo: string;
  rankingPosition: number;
  countIdeas: number;
  countAvailablePoints: number;
  countInvestedPoints: number;
  countKeyTasks: number;
  countMessages: number;
  countAlerts: number;
}

/* GET
  fullName: string;
  photo: string;
  userName: string;
  email: string;
  timezone: string;
  phone: string;
  cultureTag: string;
  urlTermsOfService: string;
  urlPrivacyPolicy: string;
  agreedTermsAndPolicyDate: string;
  agreedRegulations: string;
  agreedRegulationVersion: string;
  isFirstAccess: boolean;
  department: string;
  country: string;
  possibleCultures: IPossibleCulture[];
*/

/*
PATCH
  fullName: string;
  photo: string;
  userName: string;
  email: string;
  timezone: string;
  cultureTag: string;
  phone: string;
  department: string;
  country: string;
  workspaceId: string; NÃO TEM NO GET
  agreedTermsPolicy: boolean; NÃO TEM NO GET
  agreedRegulations: string; NÃO TEM NO GET
  regulationVersion: string; NÃO TEM NO GET
*/

export interface IProfileData {
  fullName: string;
  photo: string;
  userName: string;
  email: string;
  timezone: string;
  phone: string;
  cultureTag: string;
  urlTermsOfService: string;
  urlPrivacyPolicy: string;
  agreedTermsAndPolicyDate: string;
  agreedRegulations: string;
  agreedRegulationVersion: string;
  isFirstAccess: boolean;
  department: string;
  country: string;
  possibleCultures: IPossibleCulture[];

  customFields: ICustomFields[];
}

interface IPossibleCulture {
  cultureDescription: string;
  cultureTag: string;
}

interface ICustomFields {
  customField: {
    columnName: string;
    customFieldId: number;
    defaultValue: any;
    error: string;
    help: string;
    info: string;
    isEditable: boolean;
    isMandatory: boolean;
    length: number;
    maxValue: number;
    minValue: number;
    options: {};
    title: string;
    typeId: number;
    validationExpression: any;
  };
  value: string;
}

// Define the Character State
export interface IProfileState {
  readonly profile: IProfile[];
  readonly icon: string;
  readonly on: boolean;
  readonly profileData: IProfileData;
  readonly updateSuccess: boolean;
}

// Define the initial state
const initialCharacterState: IProfileState = {
  profile: [],
  profileData: {} as IProfileData,
  icon: "arrow-down",
  on: false,
  updateSuccess: false,
};

export const profileReducer: Reducer<IProfileState, ProfileActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case ProfileActionTypes.GET_DATA: {
      return {
        ...state,
        profile: action.profiles,
      };
    }

    case ProfileActionTypes.TOGGLE_PROFILE: {
      return {
        ...state,
        on: !state.on,
        icon: state.icon === "arrow-up" ? "arrow-down" : "arrow-up",
      };
    }

    case ProfileActionTypes.GET_PROFILE_DATA: {
      return {
        ...state,
        profileData: action.profileData,
      };
    }

    case ProfileActionTypes.UPDATE_PROFILE: {
      return {
        ...state,
        updateSuccess: action.updateSuccess,
      };
    }

    default:
      return state;
  }
};
