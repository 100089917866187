// Import Reducer type
import { Reducer } from 'redux';
import { IdeasActionTypes, IdeasActions, IdeasActionStatus } from '../../actions/ideas/AllIdeasAction';

export interface IAllIdeasState {
    readonly ideas: any;
    readonly error: any;
    readonly status: string
}

const initialAllIdeasState = {
    status:IdeasActionStatus.ALL_IDEAS_LOADING,
    error: null,
    ideas:{
      ideasIdeaImplemented:{},
      ideasInProgress:{},
      ideasRejectedReadModel:{},
      ideasInCollaboration:{
        status:IdeasActionStatus.COLLABORATION_IDEAS_LOADING,
        error:null,
        ideas:{}
      }
    }
}

export const allIdeasReducer: Reducer<any, IdeasActions> = (
  state = initialAllIdeasState,
  action
) => {
  
  switch (action.type) {
    // ---------* ALL IDEAS  *---------
    
    case IdeasActionTypes.ALL_IDEAS_LOADING: {
        return {
          ...state,
          status: action.status,
        };
      }
    case IdeasActionTypes.ALL_IDEAS_SUCCEEDED: {
    return {
        ...state,
        ideas: {
          ...state.ideas, 
          ideasIdeaImplemented:{
            ...state.ideas.ideasIdeaImplemented, 
            ideas:{
              ...state.ideas.ideasIdeaImplemented.ideas, 
              ...action.payload.ideasIdeaImplemented
            }
          },
          ideasInProgress:{
            ...state.ideas.ideasInProgress, 
            ideas:{
              ...state.ideas.ideasInProgress.ideas, 
              ...action.payload.ideasInProgress
            }
          },
          ideasRejectedReadModel:{
            ...state.ideas.ideasRejectedReadModel, 
            ideas:{
              ...state.ideas.ideasRejectedReadModel.ideas, 
              ...action.payload.ideasRejectedReadModel
            }
          },
          ideasInCollaboration:{
            ...state.ideas.ideasInCollaboration, 
            ideas:{
              ...state.ideas.ideasInCollaboration.ideas, 
              ...action.payload.ideasInCollaboration
            }
          }
        },
        status: action.status
      }
    }
    case IdeasActionTypes.ALL_IDEAS_FAILED: {
      return {
          ...state,
          status: action.status,
          error: action.error
      };
    }

    // ---------* IDEAS IN COLLABORATION  *---------
    case IdeasActionTypes.COLLABORATION_IDEAS_LOADING: {
      return {
          ...state,
          status: action.status,
          ideas: {
            ...state.ideas, 
            ideasInCollaboration:{
              ...state.ideas.ideasInCollaboration, 
              status:action.status
            }
          }
      };
    }

    case IdeasActionTypes.COLLABORATION_IDEAS_SUCCEEDED: {
      const { payload } = action
      return {
          ...state,
          status: action.status,
          ideas: {...state.ideas, ideasInCollaboration:{...state.ideas.ideasInCollaboration, ideas:{...state.ideas.ideasInCollaboration.ideas, ...payload}, status:action.status}}
      };
    }

    case IdeasActionTypes.COLLABORATION_IDEAS_FAILED: {
      return {
          ...state,
          status: action.status,
          ideas: {
            ...state.ideas, 
            ideasInCollaboration:{
              ...state.ideas.ideasInCollaboration, 
              status:action.status,
              error:action.error
            }
          }
      };
    }

    case IdeasActionTypes.COLLABORATION_IDEAS_MORE_LOADING: {
      return {
          ...state,
          status: action.status,
          ideas: {
            ...state.ideas, 
            ideasInCollaboration:{
              ...state.ideas.ideasInCollaboration, 
              status:action.status
            }
          }
      };
    }
    case IdeasActionTypes.COLLABORATION_IDEAS_MORE_SUCCEEDED: {
      const { payload } = action
      const newItems = [...state.ideas.ideasInCollaboration.ideas.result].concat(...payload.result);
      return {
          ...state,
          status: action.status,
          ideas: {
            ...state.ideas, 
            ideasInCollaboration:{
              ...state.ideas.ideasInCollaboration, 
              ideas:{
                ...state.ideas.ideasInCollaboration.ideas,
                meta:{
                  ...payload.meta
                }, 
                result:newItems
              },
              status:action.status
            }
          }
      };
    }

    case IdeasActionTypes.COLLABORATION_IDEAS_MORE_FAILED: {
      return {
          ...state,
          status: action.status,
          ideas: {
            ...state.ideas, 
            ideasInCollaboration:{
              ...state.ideas.ideasInCollaboration, 
              status:action.status,
              error:action.error
            }
          },
      };
    }
    case IdeasActionTypes.IMPLEMENTED: {
      const { ideasImplemented } = action
      const newItems = [...ideasImplemented.result]
      return {
        ...state,
        ideas:{
          ...state.ideas,
          ideasIdeaImplemented: { 
            ...state.ideas.ideasIdeaImplemented,
            ideas:{
              ...state.ideas.ideasIdeaImplemented.ideas,
              meta: {
                ...ideasImplemented.meta
              },
              result:newItems
            }
          }
        }
      };
    }
    default:
      return state;
  }
};