import React from "react";
import ShortText from "./ShortText/ShortText";
import Checkmark from "./Checkmark/Checkmark";
import NumberInput from "./NumberInput/NumberInput";
import RadioButton from "./RadioButton/RadioButton";
import DropdownInput from "./Dropdown/DropdownInput";
import TextEditorInput from "./TextEditorInput/TextEditorInput";

interface IProps {
  input: any;
  value?: any;
  handler?: any;
}

const GenericFieldV3 = ({ input, value, handler }: IProps) => {
  const inputX = (typeId: any, value: any) => {
    switch (typeId) {
      case 1:
        // ShortText
        return <ShortText value={value} propsI={input} handler={handler} />;
      case 2:
        // LongText
        return (
          <TextEditorInput propsI={input} value={value} handler={handler} />
        );
      case 3:
        // Attachment
        return <></>;
      case 4:
        // Number
        return <NumberInput value={value} propsI={input} handler={handler} />;
      case 5:
        // Checkmark
        return <Checkmark value={value} propsI={input} handler={handler} />;
      case 6:
        // RadioButton-just v3
        return <RadioButton value={value} propsI={input} handler={handler} />;
      case 7:
        // Dropdown
        return <DropdownInput value={value} propsI={input} handler={handler} />;
      default:
        return <></>;
    }
  };
  return (
    <div key={input.ideaCustomFieldId} className="input-div">
      {inputX(input.type, value)}
    </div>
  );
};

export default GenericFieldV3;
