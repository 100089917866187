import { ThunkAction } from "redux-thunk";
import { ActionCreator, Dispatch} from 'redux';
import { ITenant, ITenantState, IApplication } from '../reducers/tenantReducer';
import { IErrorHandling } from '../types/types'
import { toast } from "react-toastify";

// const basePath = "tenantconfigurations";

export enum TenantActionTypes {
  GET_CONFIGURATIONS = 'GET_CONFIGURATIONS',
  SET_ACTIVE = 'SET_ACTIVE'
}

// Interface for Login Action Type
export interface ITenantConfigurationAction {
  type: TenantActionTypes.GET_CONFIGURATIONS;
  tenants: ITenant[];
  error: IErrorHandling,
  application: IApplication
}

export interface IActiveTenantAction {
  type: TenantActionTypes.SET_ACTIVE,
  activeTenant: ITenant
}

export type TenantActions = 
  ITenantConfigurationAction
  | IActiveTenantAction;


export const getTenantConfigurations: ActionCreator<
  ThunkAction<Promise<any>, ITenantState, null, ITenantConfigurationAction>
> = (tenants) => {
  return async (dispatch: Dispatch) => {
    const tenant: ITenantConfigurationAction = {
      tenants: tenants,
      type: TenantActionTypes.GET_CONFIGURATIONS,
      error: {error: false, message:""},
      application: { applicationId: 2}
    }
    dispatch(tenant);
  };
};

export const setActiveTenant: ActionCreator<
  ThunkAction<Promise<any>, ITenantState, null, IActiveTenantAction>
> = (activeTenant:any) => {
  return async (dispatch: Dispatch) => {
    const tenant: IActiveTenantAction = {
      activeTenant: activeTenant,
      type: TenantActionTypes.SET_ACTIVE,
    }
    dispatch(tenant);
    return
  };
};

export const setLogOutTenant: ActionCreator<
  ThunkAction<Promise<any>, ITenantState, null, ITenantConfigurationAction>
> = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        tenant: {tenantId:0, endpoint: "", image:"", name:""},
        application : {applicationId:0},
        error: {error: false, message:""},
        type: TenantActionTypes.GET_CONFIGURATIONS,
      });
    } catch (err) {
      toast.error(err.error_message)
    }
  };
};

export function checkAuthentication() {

}