import React from "react";
import Resource from "../../components/Resources/Resource";
import AlertListItem from "../../components/Alerts/AlertListItem/AlertListItem";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IAlert, IResult } from "../../redux/reducers/alertReducer";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertList: {
      width: "340px",
      maxHeight: "450px",
      minHeight: "200px",
      background: theme.palette.background.paper,
      position: "absolute",
      right: "0px",
      top: "55px",
      borderRadius: "10px",
      boxShadow: "0px 3px 10px -3px #c0c0c0",
      overflowY: "auto",
      overflowX: "hidden",
    },
    titles: {
      fontWeight: 500,
      fontSize: "0.75rem",
    },
    subtitles: {
      paddingLeft: "20px",
      fontWeight: 500,
    },
    readAll: {
      textTransform: "uppercase",
      fontWeight: 400,
      fontSize: "0.65rem",
    },
  })
);

interface IProps {
  alert?: IAlert;
  news?: IResult;
  previous?: IResult;
  culture: ICultureState;
  readNotification: (
    alertId: string,
    type: string,
    object: string,
    objectId: string,
    objectIdHighlight: string
  ) => void;
}

function AlertList(props: IProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { alert, culture, readNotification } = props;
  let news = [];
  let previous = [];
  let haveNew = false;
  let havePrevious = false;

  for (let i = 0; i < Number(alert?.result?.length); i++) {
    if (
      alert?.result[i].isNew === true &&
      alert.result[i].isVisualized === false
    ) {
      news.push(alert.result[i]);
      haveNew = true;
    } else if (
      alert?.result[i].isNew === false &&
      alert.result[i].isVisualized === false
    ) {
      previous.push(alert.result[i]);
      havePrevious = true;
      haveNew = true;
    } else if (
      alert?.result[i].isNew === false &&
      alert.result[i].isVisualized === true
    ) {
      previous.push(alert.result[i]);
      havePrevious = true;
    }
  }

  return (
    <Box
      className={classes.alertList}
      style={{
        height: haveNew === false && havePrevious === false ? 200 : 450,
      }}
    >
      <Box
        className="alerts-header"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="8px 10px 8px 20px"
      >
        <Typography
          className={classes.titles}
          variant="body2"
          color="textPrimary"
        >
          <Resource tag={"PageAlerts::Alerts"} />
        </Typography>
        <Box display="flex" alignItems="center">
          <Button
            color="primary"
            onClick={() => {
              if (haveNew === true) {
                readNotification("", "ALL", "", "", "");
              }
            }}
          >
            <Typography className={classes.readAll} variant="caption">
              <Resource tag={"PageAlerts::ReadAll"} />
            </Typography>
          </Button>
        </Box>
      </Box>
      {haveNew === false && havePrevious === false && (
        <Box
          className="no-alerts-available"
          textAlign="center"
          margin="40px"
          color={theme.palette.primary.main}
        >
          <Resource tag={"PageAlerts::NoAlertsAvailable"} />
        </Box>
      )}
      {news === null || news.length < 1 ? (
        ""
      ) : (
        <Box className="alert-list-new-container" paddingRight={1}>
          <Typography
            className={classes.subtitles}
            variant="caption"
            color="textSecondary"
          >
            <Resource tag={"PageAlerts::Alerts_New"} />
          </Typography>
          <Box className="alert-list-new">
            {news?.map((alertNews) => {
              return (
                <AlertListItem
                  key={alertNews.alertId}
                  culture={culture}
                  readNotification={readNotification}
                  alertId={alertNews.alertId}
                  isNew={alertNews.isNew}
                  isVisualized={alertNews.isVisualized}
                  message={alertNews.message}
                  timeStamp={alertNews.timeStamp}
                  timeStampSeparatorResource={
                    alertNews.timeStampSeparatorResource
                  }
                  timeStampResource={alertNews.timeStampResource}
                  type={alertNews.type}
                  object={alertNews.object}
                  objectId={alertNews.objectId}
                  objectIdHighlight={alertNews.objectIdHighlight}
                />
              );
            })}
          </Box>
        </Box>
      )}
      {previous === null || previous.length < 1 ? (
        ""
      ) : (
        <Box className="alert-list-previous-container" pr={1}>
          <Typography
            className={classes.subtitles}
            variant="caption"
            color="textSecondary"
          >
            <Resource tag={"PageAlerts::Alerts_Previous"} />
          </Typography>
          <Box className="alert-list-previous">
            {previous?.map((alertPrevious) => {
              return (
                <AlertListItem
                  key={alertPrevious.alertId}
                  culture={culture}
                  readNotification={readNotification}
                  alertId={alertPrevious.alertId}
                  isNew={alertPrevious.isNew}
                  isVisualized={alertPrevious.isVisualized}
                  message={alertPrevious.message}
                  timeStamp={alertPrevious.timeStamp}
                  timeStampSeparatorResource={
                    alertPrevious.timeStampSeparatorResource
                  }
                  timeStampResource={alertPrevious.timeStampResource}
                  type={alertPrevious.type}
                  object={alertPrevious.object}
                  objectId={alertPrevious.objectId}
                  objectIdHighlight={alertPrevious.objectIdHighlight}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default AlertList;
