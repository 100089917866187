import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, Button, Divider, Grid } from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Resource from "components/Resources/Resource";
import DialogSettingsAssignMembersToGroup from "./Dialogs/DialogSettingsAssignMembersToGroup";
import { connect } from "react-redux";
import { IAppState } from "redux/store/Store";
import ManageUsersGroup from "./ManageUsersGroups";
import DialogSettingsManageGroup from "./Dialogs/DialogSettingsManageGroup";
import AddIcon from "@material-ui/icons/Add";
import { ICultureState } from "redux/reducers/cultureReducer";
import { ICountries, IDepartments } from "redux/reducers/usersReducers";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { getAllCountries, getAllDepartaments } from "redux/actions/UsersAction";
import DialogUsersManageGroup from "./Dialogs/DialogUsersManageGroups";
import {
  getAllManageGroups,
  getManageGroups,
  getManageGroup,
  putRevoveUserGroupManageGroups,
} from "redux/actions/manageGroups/manageGroupsAction";
import {
  IAllManageGroups,
  IManageGroup,
  IManageGroups,
} from "redux/types/manageGroups";
import DynamicInfiniteScroll from "views/KeyTasks/DynamicInfiniteScroll";
import { getManageUsers } from "redux/actions/manageUsers/ManageUsersAction";
import { IManageUsers, IManageUsersState } from "redux/types/manageUsers";
import { resource } from "components/Resources/Resource";
import emptyImage from "../../../assets/img/empty@3x.png";



const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "16px 0",
  },
}))(MuiAccordionDetails);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "2px solid rgb(136, 136, 136, 0.3)",
    minHeight: 56,
    padding: 0,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const Accordion = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

interface IProps {
  children?: any;
  culture: ICultureState;
  countries: ICountries[];
  departments: IDepartments[];
  allGroups: IAllManageGroups[];
  groups: IManageGroups;
  workspaceId: string;
  group?: IManageGroup;
  users: IManageUsers;
}

interface IDispatchProps {
  getCountries: (options: object) => Promise<any>;
  getDepartments: (options: object) => Promise<any>;
  getAllManageGroups: (options: object) => Promise<any>;
  getManageGroups: (options: object) => Promise<any>;
  getManageGroup: (options: object) => Promise<any>;
  getUsers: (options: object) => Promise<IManageUsersState>;
  putRevoveUserGroupManageGroups: (options: object) => Promise<any>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    noData: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: 2.0,
    },
  })
);

function ManageGroup(props: IProps & IDispatchProps) {
  const classes = useStyles();
  const {
    culture,
    countries,
    departments,
    workspaceId,
    allGroups,
    groups,
    group,
    getCountries,
    getDepartments,
    getAllManageGroups,
    getManageGroups,
    getManageGroup,
    putRevoveUserGroupManageGroups,
  } = props;
  const [expanded, setExpanded] = useState<string | false>("");
  const [openCreateGroup, setOpenCreateGroup] = useState(false);
  const [openAssignToGroup, setOpenAssignToGroup] = useState(false);
  const [openEditGroup, setOpenEditGroup] = useState(false);
  const [groupId, setGroupId] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [groupSelected, setGroupSelected] = useState("");
  const [userAssignGroup, setUserAssignGroup] = useState(false);

  useEffect(() => {
    Promise.all([
      getCountries({ cultureTag: culture.culture.cultureTag }),
      getDepartments({ cultureTag: culture.culture.cultureTag }),
      getAllManageGroups({
        workspaceId,
        cultureTag: culture.culture.cultureTag,
      }),
      getManageGroups({ workspaceId, cultureTag: culture.culture.cultureTag }),
    ]).finally(() => setLoading(false));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (groupSelected !== "") {
      Promise.all([
        getManageGroup({ workspaceId, groupId: groupSelected }),
      ]).finally(() => {
        setOpenEditGroup(true);
        setGroupSelected("");
      });
    }

    // eslint-disable-next-line
  }, [groupSelected]);

  const [open, setOpen] = useState(false);
  const handleChange = (panel: string, groupId: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : "");
    setGroupId([groupId]);
  };
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));

  function RemoveUserGroup(usersId: []) {
    setLoading(true);
    putRevoveUserGroupManageGroups({
      workspaceId,
      groupId: groupId,
      usersId: usersId,
      cultures: culture.culture,
    }).then(() => {
      getManageGroups({
        workspaceId,
        cultureTag: culture.culture.cultureTag,
      }).then(() => {
        setExpanded("");
        setLoading(false);
      });
    });
  }
  const textNoData = resource(
    culture?.culture,
    "Errors::NoGroupsAvailable"
  )?.split("<br></br>");

  return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="h1">
              <strong>
                <Resource tag="PageSettings::ManageGroups" />
              </strong>
            </Typography>
          </Grid>
          <Grid
            container
            justify={matchSm ? "flex-start" : "flex-end"}
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddIcon htmlColor="white" />}
              onClick={() => setOpenCreateGroup(true)}
            >
              <Resource tag="PageSettings::CreateNewGroup" />
            </Button>
            <DialogSettingsManageGroup
              type={"create"}
              countries={countries}
              departments={departments}
              open={openCreateGroup}
              setOpen={setOpenCreateGroup}
            />
          </Grid>

        </Grid>
      </Box>
      <Box className={classes.root} marginY={3}>
        {loading ? (
          <Box display="flex" mt={35} ml={40}>
            <Box>
              <CircularProgress size={50} className="icon" />
            </Box>
          </Box>
        ) :
          groups.result ?
            <DynamicInfiniteScroll
              dataList={groups}
              getAction={(pageNumber, pageSize) =>
                getManageGroups({
                  workspaceId,
                  cultureTag: culture.culture.cultureTag,
                  pageNumber,
                  pageSize,
                  isInfinite: true,
                })
              }
              pageSize={6}
            //isAutomatic
            >
              {groups?.result.map((group, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === String(index)}
                  onChange={handleChange(String(index), group.groupId)}
                >
                  <AccordionSummary
                    // aria-label="Expand"
                    expandIcon={<ExpandMoreIcon fontSize="large" />}
                    aria-controls={`${String(index)}bh-content`}
                    id={`${String(index)}bh-header`}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        container
                        alignItems="center"
                        item
                        wrap="nowrap"
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        zeroMinWidth
                      >
                        <Box
                          maxWidth="100%"
                          display="flex"
                          alignItems="center"
                          gridGap={10}
                        >
                          <Typography variant="body1" noWrap>
                            <strong>{group.groupName}</strong>
                          </Typography>
                          <Divider orientation="vertical" flexItem />
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            noWrap
                          >
                            {group.numberOfMember}{" "}
                            <Resource tag="PageSettings::members" />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        container
                        justify={matchSm ? "flex-start" : "flex-end"}
                        alignItems="center"
                        spacing={2}
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <Box
                          maxWidth="100%"
                          display="flex"
                          alignItems="center"
                          gridGap={10}
                        >
                          <Button
                            onClick={(e: any) => {
                              setOpenAssignToGroup(true);
                              e.stopPropagation();
                              setGroupId([group.groupId]);
                            }}
                            onFocus={(e: any) => e.stopPropagation()}
                            // aria-label="Buttons"
                            variant="text"
                            color="primary"
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            <Resource tag="PageSettings::AssignMembers" />
                          </Button>
                          <Button
                            onClick={(e: any) => {
                              setGroupSelected(group.groupId);
                              setUserAssignGroup(group?.numberOfMember > 0);
                              e.stopPropagation();
                            }}
                            onFocus={(e: any) => e.stopPropagation()}
                            // aria-label="Buttons"
                            variant="text"
                            color="primary"
                            style={{
                              textTransform: "uppercase",
                            }}
                          >
                            <Resource tag="PageSettings::Edit" />
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ManageUsersGroup
                      culture={props.culture}
                      members={group.users}
                      groupsIds={groupId}
                      workspaceId={workspaceId}
                      putRemoveUser={RemoveUserGroup}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </DynamicInfiniteScroll>
            :
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={2}
              className="no-prizes"
            >
              <Typography variant="h4" className={classes.noData}>
                {textNoData[0]}
              </Typography>
              <Typography variant="h4" className={classes.noData}>
                {textNoData[1]}
              </Typography>
              <img src={emptyImage} alt="" />
            </Box>
        }
        <DialogSettingsManageGroup
          type={"edit"}
          groupData={group}
          countries={countries}
          departments={departments}
          open={openEditGroup}
          setOpen={setOpenEditGroup}
          userAssignGroup={userAssignGroup}
        />
        <DialogUsersManageGroup
          allGroups={allGroups}
          open={open}
          setOpen={setOpen}
        />
        <DialogSettingsAssignMembersToGroup
          open={openAssignToGroup}
          setOpen={setOpenAssignToGroup}
          groupId={groupId}
          workspaceId={workspaceId}
        />
      </Box>
    </>
  );
}

const mapStateToProps = (store: IAppState) => ({
  culture: store.cultureState,
  countries: store.usersState.countries,
  departments: store.usersState.departaments,
  allGroups: store.manageGroupsState.allGroups,
  groups: store.manageGroupsState.groups,
  workspaceId: store.authState.auth.workspaceId,
  group: store.manageGroupsState.group,
  users: store.manageUsersState.users,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => ({
  getCountries: (options: object) => dispatch(getAllCountries(options)),
  getDepartments: (options: object) => dispatch(getAllDepartaments(options)),
  getAllManageGroups: (options: object) =>
    dispatch(getAllManageGroups(options)),
  getManageGroups: (options: object) => dispatch(getManageGroups(options)),
  getManageGroup: (options: object) => dispatch(getManageGroup(options)),
  getUsers: (options: object) => dispatch(getManageUsers(options)),
  putRevoveUserGroupManageGroups: (options: object) =>
    dispatch(putRevoveUserGroupManageGroups(options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageGroup);
