import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  Typography,
  Grid,
  Avatar,
} from "@material-ui/core";
import Resource from "components/Resources/Resource";
import React from "react";
import { IIdeaDetails } from "redux/types/ideas";
import "./ShowCoAuthors.scss";

interface IProps {
  ideaDetails: IIdeaDetails;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumbnail: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  })
);

function ShowCoAuthors(props: IProps) {
  const { ideaDetails } = props;
  const classes = useStyles();

  return (
    <Box
      gridGap="50px"
      flex
      flexDirection="column"
      alignContent="start"
      className=""
    >
      <Box mb={5}>
        <Typography component="p" variant="body2">
          <Resource tag={"PageDetailedIdea::AuthorIdea"} />
        </Typography>
        <Grid container alignItems="center">
          <Grid item>
            <Avatar
              alt={ideaDetails?.author?.name}
              src={ideaDetails?.author?.photo}
              className={classes.thumbnail}
            />
          </Grid>
          <Grid item>
            <Box ml={1} fontWeight="bold">
              {ideaDetails?.author?.name}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {ideaDetails?.coAuthors?.length ? (
          <Typography component="p" variant="body2">
            <Resource tag={"PageDetailIdea::Idea_CoAuthor"} />
          </Typography>
        ) : (
          ""
        )}
        <Box display="flex" gridGap={50}>
          {ideaDetails?.coAuthors?.length >= 1 &&
            ideaDetails?.coAuthors?.map((coAuthors, index) => {
              return (
                <Box mb={1}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Avatar
                        alt={coAuthors.name}
                        src={coAuthors.photo}
                        className={classes.thumbnail}
                      />
                    </Grid>
                    <Grid item>
                      <Box ml={1} fontWeight="bold">
                        {coAuthors.name}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
}

export default ShowCoAuthors;
