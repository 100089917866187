import "./Header.scss";
import { AnyAction } from "redux";
import history from "routes/history";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import newApp from "utils/reactNewApp";
import EMIcon from "components/Icon/Icon";
import { ThunkDispatch } from "redux-thunk";
import { IAppState } from "redux/store/Store";
import Dialog from "@material-ui/core/Dialog";
import PageTitle from "../PageTitle/PageTitle";
import MenuIcon from "@material-ui/icons/Menu";
import Profile from "components/Profile/Profile";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import Resource from "components/Resources/Resource";
import { Link as RouterLink } from "react-router-dom";
import AddButton from "components/AddButton/AddButton";
import { IMessagesReceived } from "redux/types/messages";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { ICultureState } from "redux/reducers/cultureReducer";
import { IMessagesState } from "redux/reducers/messagesReducer";
import AlertsButton from "components/Alerts/AlertsButton/AlertsButton";
import { IAlert, IAlertState } from "redux/reducers/alertReducer";
import ModalSelectAChallenge from "common/Modal/ModalSelectAChallenge";
import { getAllMessagesReceived } from "redux/actions/MessagesActions";
import {
  postAllAlerts,
  postAlerts,
  getAllAlerts,
} from "redux/actions/AlertAction";
import {
  Toolbar,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Box,
  Grid,
  Hidden,
  Divider,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Link,
  useTheme,
  Fade,
} from "@material-ui/core";
import { IChallengeList } from "redux/types/challenges";

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: ({ path }: IProps) => ({
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      color: path === "ranking" ? "white" : theme.palette.primary.main,
      backgroundColor:
        path !== "ranking" ? "white" : theme.palette.primary.main,
    }),
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    pageTitle: {
      marginTop: 24,
      marginLeft: 20,
      fontWeight: "bold",
      [theme.breakpoints.between("xs", "sm")]: {
        marginTop: 0,
      },
    },
    pageTitleRanking: {
      marginTop: 24,
      marginLeft: 20,
      fontWeight: "bold",
      [theme.breakpoints.between("xs", "sm")]: {
        marginTop: 0,
      },
      color: "white",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dialog: {
      margin: "auto",
    },
    titleHeader: {
      fontWeight: "bold",
      color: "#313b53",
    },
    dividerHeader: {
      width: "90%",
      marginLeft: "30px",
    },
    dialogHeader: {
      padding: "22px 24px 10px 30px",
    },
    arrowUpMenuAddNew: {
      width: 0,
      content: "",
      height: 0,
      borderLeft: "14px solid transparent",
      borderRight: "14px solid transparent",
      position: "absolute",
      top: "45px",
      zIndex: 7,
      right: "10px",
      borderBottom: "22px solid #fff",
    },
  })
);

const pagination = {
  current: 1,
  pageSize: 10,
  finished: false,
};

const status = {
  init: true,
  refreshControl: false,
};

const options = {
  applicationId: 2,
  pageNumber: status.init ? 1 : pagination.current,
  pageSize: pagination.pageSize,
  cultureTag: {},
};

interface IDispatchProps {
  getAlerts: (options: object) => Promise<IAlertState>;
  postAlerts: (options: object) => Promise<IAlertState>;
  postAllAlerts: (options: object) => Promise<IAlertState>;
  getReceivedMessages: (options: object) => Promise<IMessagesState>;
}

interface IProps {
  path: string;
  culture: ICultureState;
  alerts: IAlert;
  handleDrawerToggle: any;
  applicationId: any;
  complement: string;
  showShareYourIdea: boolean;
  showShareInspiration: boolean;
  isVisibleButtonHelp: boolean;
  receivedMessages: IMessagesReceived;
  permission: string;
  challengeList: IChallengeList;
}

function Header(props: IProps & IDispatchProps) {
  const theme = useTheme();
  const classes = useStyles(props);
  const {
    complement,
    alerts,
    path,
    culture,
    handleDrawerToggle,
    showShareYourIdea,
    showShareInspiration,
    getAlerts,
    postAlerts,
    postAllAlerts,
    getReceivedMessages,
    permission,
    challengeList
  } = props || {};
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [newPath, setNewPath] = useState(path);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const readNotification = async (
    alertId: string,
    type: string,
    object: string,
    objectId: string,
    objectIdHighlight: string
  ) => {
    setLoading(true);
    const postOptions = {
      alertId,
      pageSize: pagination.pageSize,
      pageNumber: pagination.current,
      culture: props.culture,
    };

    options.cultureTag = props.culture.culture.cultureTag;
    options.applicationId = props.applicationId;

    if (type === "ALL") {
      postAllAlerts(postOptions).then(() => {
        getAlerts(options).then(() => {
          setLoading(false);
        });
      });
    } else {
      await postAlerts(postOptions);
      openObject(object, objectId, objectIdHighlight);
    }
  };

  //getAlertIcon returns icon of notification
  const getAlertURL = (
    object: string,
    objectId: string,
    objectIdHighlight: string
  ) => {
    let url = "/app/";
    switch (object.toUpperCase()) {
      case "COMMENTSIDEA":
        url += "idea/details/" + objectId + "?comment=" + objectIdHighlight;
        break;

      case "KEYTASK":
        url += "keytasks/";
        break;

      case "CHALLENGE":
        url += "challenges/details/" + objectId;
        break;

      case "IDEA":
        url += "idea/details/" + objectId;
        break;

      case "PRIZES":
        url += "prizes";
        break;

      case "MESSAGES":
        url += "messages/details/" + objectId;
        break;
    }

    return url;
  };

  const openObject = (
    object: string,
    objectId: string,
    objectIdHighlight: string
  ) => {
    let url = getAlertURL(object, objectId, objectIdHighlight);

    getAlerts(options).then(() => {
      setLoading(false);
    });

    history.replace(url.replace("/app", ""));
  };

  useEffect(() => {
    options.cultureTag = props.culture.culture.cultureTag;
    options.applicationId = props.applicationId;
    getReceivedMessages({ cultureTag: props.culture.culture.cultureTag });
    getAlerts(options).then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClickAddButton(event: React.MouseEvent<HTMLButtonElement>) {
    newApp ? setAnchorEl(event.currentTarget) : setOpen(true);
  }

  return (
    <Toolbar>
      {props.isVisibleButtonHelp && (
        <Helmet>
          <script id="FreshDesk">{`
            window.fwSettings={ 'widget_id': 60000003236 };          
            !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
          `}</script>

          <script
            type="text/javascript"
            src="https://widget.freshworks.com/widgets/60000003236.js"
            async
            defer
          ></script>
        </Helmet>
      )}
      <IconButton
        color="inherit"
        // aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Grid container>
        <Grid item md={8} sm={12}>
          <Typography
            variant="h1"
            color="textPrimary"
            noWrap
            className={
              path === "ranking" ? classes.pageTitleRanking : classes.pageTitle
            }
          >
            <PageTitle
              path={path}
              culture={culture?.culture}
              isDetail={complement ? true : false}
            />
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={4}>
            <Box display="flex" flexDirection="row-reverse" height="100%">
              <Box mr={2} mt={2}>
                <Profile receivedMessages={props.receivedMessages} />
              </Box>
              <Box mr={7} mt={2}>
                {!loading ? (
                  <>
                    <AlertsButton
                      id="Header_AlertsButton"
                      culture={culture}
                      readNotification={readNotification}
                      alerts={alerts}
                    />
                  </>
                ) : (
                  <>
                    <Box mt={1.5}>
                      <CircularProgress size={26} className="icon" />
                    </Box>
                  </>
                )}
              </Box>
              <h1>{isOpen}</h1>
              <Box mr={3.5} mt={2} position="relative">
                {!newApp && showShareYourIdea && (
                  <Box onClick={handleClickAddButton}>
                    <AddButton id="Header_AddButton" />
                  </Box>
                )}
                {newApp &&
                  (showShareYourIdea ||
                    showShareInspiration ||
                    permission === "Admin" ||
                    permission === "ChallengeManager") && (
                    <Box onClick={handleClickAddButton}>
                      <AddButton id="Header_AddButton" />
                    </Box>
                  )}
                {anchorEl && (
                  <Fade in={true} mountOnEnter unmountOnExit>
                    <div className={classes.arrowUpMenuAddNew}></div>
                  </Fade>
                )}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  elevation={4}
                  PaperProps={{
                    style: { borderRadius: "8px" },
                  }}
                  style={{
                    transform: "translate(-54px,71px)",
                    zIndex: 12,
                  }}
                >
                  <Box paddingX={1.5} style={{ outline: "none" }}>
                    <Box ml={1} mb={1}>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>
                        <Resource tag={"Common::AddNew"} />
                      </Typography>
                    </Box>
                    {showShareYourIdea && challengeList?.result?.length > 0 &&  (
                      <MenuItem
                        style={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                          padding: "8px 12px",
                        }}
                        onClick={() => {
                          handleClose();
                          setOpen(true);
                          setNewPath("/createIdea");
                        }}
                      >
                        <EMIcon
                          icon="section-ideas"
                          size={14}
                          color={theme.palette.grey[500]}
                        />
                        <Box ml={2}>
                          <Resource tag={"AddNew::Ideas"} />
                        </Box>
                      </MenuItem>
                    )}
                    {showShareInspiration && challengeList?.result?.length > 0 && (
                      <MenuItem
                        style={{
                          borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                          padding: "8px 12px",
                        }}
                        onClick={() => {
                          handleClose();
                          setOpen(true);
                          setNewPath("/createInspiration");
                        }}
                      >
                        <EMIcon
                          icon="section-plus-lamp"
                          size={14}
                          color={theme.palette.grey[500]}
                        />
                        <Box ml={2}>
                          <Resource tag={"AddNew::Inspiration"} />
                        </Box>
                      </MenuItem>
                    )}
                    {permission === "Admin" ||
                    permission === "ChallengeManager" ? (
                      <>
                        <MenuItem
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                            padding: "8px 12px",
                          }}
                          onClick={handleClose}
                        >
                          <EMIcon
                            icon="challenges"
                            size={14}
                            color={theme.palette.grey[500]}
                          />
                          <Link
                            style={{ textDecoration: "none" }}
                            component={RouterLink}
                            to="/createChallenge"
                            color="textPrimary"
                          >
                            <Box ml={2}>
                              <Resource tag={"AddNew::Challenges"} />
                            </Box>
                          </Link>
                        </MenuItem>
                        <MenuItem
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                            padding: "8px 12px",
                          }}
                          onClick={handleClose}
                        >
                          <EMIcon
                            icon="section-prizes"
                            size={14}
                            color={theme.palette.grey[500]}
                          />
                          <Link
                            style={{ textDecoration: "none" }}
                            component={RouterLink}
                            to="/createPrize"
                            color="textPrimary"
                          >
                            <Box ml={2}>
                              <Resource tag={"AddNew::Prizes"} />
                            </Box>
                          </Link>
                        </MenuItem>
                      </>
                    ) : (
                      ""
                    )}
                    {permission === "Admin" && (
                      <MenuItem
                        style={{ padding: "8px 12px" }}
                        onClick={handleClose}
                      >
                        <EMIcon
                          icon="section-news"
                          size={14}
                          color={theme.palette.grey[500]}
                        />
                        <Link
                          style={{ textDecoration: "none" }}
                          component={RouterLink}
                          to="/createNews"
                          color="textPrimary"
                        >
                          <Box ml={2}>
                            <Resource tag={"AddNew::News"} />
                          </Box>
                        </Link>
                      </MenuItem>
                    )}
                  </Box>
                </Menu>
                <Dialog
                  open={isOpen}
                  maxWidth="md"
                  fullWidth
                  className={classes.dialog}
                >
                  <MuiDialogTitle className={classes.dialogHeader}>
                    <Typography
                      color="textPrimary"
                      className={classes.titleHeader}
                    >
                      <Resource tag={"PageNewIdea::Challenge_Header"} />
                    </Typography>
                    <IconButton
                      // aria-label="close"
                      className={classes.closeButton}
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </MuiDialogTitle>
                  <Divider className={classes.dividerHeader} />
                  <ModalSelectAChallenge
                    closeButton={handleClose}
                    path={newPath}
                  ></ModalSelectAChallenge>
                </Dialog>
              </Box>
            </Box>
          </Grid>
        </Hidden>        
      </Grid>
    </Toolbar>
  );
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getAlerts: (options: object) => dispatch(getAllAlerts(options)),
    postAlerts: (options: object) => dispatch(postAlerts(options)),
    postAllAlerts: (options: object) => dispatch(postAllAlerts(options)),
    getReceivedMessages: (options: object) =>
      dispatch(getAllMessagesReceived(options)),
  };
};

const mapStateToProps = (store: IAppState) => {
  return {
    alerts: store.alertState.alerts,
    applicationId: store.authState.auth.applicationId,
    showShareYourIdea: store.feedState.feed.showShareYourIdea,
    showShareInspiration: store.feedState.feed.showShareInspiration,
    isVisibleButtonHelp: store.authState.auth.isVisibleButtonHelp,
    receivedMessages: store.messagesState.received,
    permission: store.authState.auth.permission,
    challengeList: store.challengeState.challengeList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
