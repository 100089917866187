import { ThunkAction } from "redux-thunk";
import reactNewApp from "utils/reactNewApp";
import { ActionCreator, Dispatch } from "redux";
import { treatError } from "../../utils/treatError";
import { api_general, api_v2 } from "../../config/api";

// Import Character Typing
import {
  IChallengeDetailState,
  IParticipations,
  IDetailIdea,
  IChallengeDetail,
  IDetailChallengeResult,
} from "../reducers/challengeDetailReducer";

const basePath = "challenges";

export enum ChallengeDetailActionTypes {
  GET_LISTS = "GET_LISTS",

  DETAIL_CHALLENGE_LOADING = "DETAIL_CHALLENGE_LOADING",
  DETAIL_CHALLENGE_SUCCEEDED = "DETAIL_CHALLENGE_SUCCEEDED",
  DETAIL_CHALLENGE_FAILED = "DETAIL_CHALLENGE_FAILED",

  GET_DETAIL_CHALLENGE_IDEA = "GET_DETAIL_CHALLENGE_IDEA",

  GET_MORE_DETAIL_CHALLENGE_IDEA = `GET_MORE_DETAIL_CHALLENGE_IDEA`,

  GET_DETAIL_CHALLENGE_RESULT_LOADING = "GET_DETAIL_CHALLENGE_RESULT_LOADING",
  GET_DETAIL_CHALLENGE_RESULT_SUCCEEDED = "GET_DETAIL_CHALLENGE_RESULT_SUCCEEDED",
  GET_DETAIL_CHALLENGE_RESULT_FAILED = "GET_DETAIL_CHALLENGE_RESULT_FAILED",
}

export enum ChallengeDetailActionStatus {
  DETAIL_CHALLENGE_LOADING = "LOADING",
  DETAIL_CHALLENGE_SUCCEEDED = "SUCCEEDED",
  DETAIL_CHALLENGE_FAILED = "FAILED",

  GET_DETAIL_CHALLENGE_RESULT_LOADING = "LOADING_PREVIEW",
  GET_DETAIL_CHALLENGE_RESULT_SUCCEEDED = "SUCCEEDED",
  GET_DETAIL_CHALLENGE_RESULT_FAILED = "FAILED",
}

export interface IDetailListAction {
  type: ChallengeDetailActionTypes.GET_LISTS;
  ideas: IParticipations[];
  participations: IParticipations[];
  departaments: {};
  country: {};
}

export interface IDetailChallengeListAction {
  type: ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_IDEA;
  ideas: IDetailIdea;
}

export interface IDetailMoreChallengeListAction {
  type: ChallengeDetailActionTypes.GET_MORE_DETAIL_CHALLENGE_IDEA;
  ideas: IDetailIdea;
}

export interface IChallengeDetailLoadAction {
  type: ChallengeDetailActionTypes.DETAIL_CHALLENGE_LOADING;
  challengeDetailStatus: string;
}
export interface IChallengeDetailSuccessAction {
  type: ChallengeDetailActionTypes.DETAIL_CHALLENGE_SUCCEEDED;
  challengeDetail: IChallengeDetail;
  challengeDetailStatus: string;
}
export interface IChallengeDetailFailAction {
  type: ChallengeDetailActionTypes.DETAIL_CHALLENGE_FAILED;
  challengeDetailStatus: string;
  error: any;
}
// Interface for Get All Challenge Results Action Type
export interface IDetailChallengeResultLoadingGetAllAction {
  type: ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_RESULT_LOADING;
  status: string;
}
export interface IDetailChallengeResultSucceededGetAllAction {
  type: ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_RESULT_SUCCEEDED;
  challengeDetailResults: IDetailChallengeResult;
  status: string;
}
export interface IDetailChallengeResultFailedGetAllAction {
  type: ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_RESULT_FAILED;
  status: string;
  error: any;
}

export type ChallengeDetailActions =
  | IDetailChallengeListAction
  | IChallengeDetailLoadAction
  | IChallengeDetailSuccessAction
  | IChallengeDetailFailAction
  | IDetailChallengeResultLoadingGetAllAction
  | IDetailChallengeResultSucceededGetAllAction
  | IDetailChallengeResultFailedGetAllAction
  | IDetailMoreChallengeListAction;

export const getDetailChallengeIdeas: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeDetailState,
  null,
  IDetailChallengeListAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }
      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }
      reactNewApp
        ? await api_general
          .get(`${basePath}/${options.challengeId}/ideas${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideas: response.data,
              type: ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_IDEA,
            });
          })
        : await api_v2
          .get(`${basePath}/${options.challengeId}/ideas${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideas: response.data,
              type: ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_IDEA,
            });
          });
    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
    }
  };
};

export const getMoreDetailChallengeIdeas: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeDetailState,
  null,
  IDetailMoreChallengeListAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      let params: any[] = [];
      let params_qs = "";

      if (options.hasOwnProperty("pageSize")) {
        params.push(`pageSize=${options.pageSize}`);
      }
      if (options.hasOwnProperty("pageNumber")) {
        params.push(`pageNumber=${options.pageNumber}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }
      reactNewApp
        ? await api_general
          .get(`${basePath}/${options.challengeId}/ideas${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideas: response.data,
              type: ChallengeDetailActionTypes.GET_MORE_DETAIL_CHALLENGE_IDEA,
            });
          })
        : await api_v2
          .get(`${basePath}/${options.challengeId}/ideas${params_qs}`, {})
          .then((response) => {
            dispatch({
              ideas: response.data,
              type: ChallengeDetailActionTypes.GET_MORE_DETAIL_CHALLENGE_IDEA,
            });
          })

    } catch (err) {
      treatError(options?.culture, "PageChallenges::Header", err);
    }
  };
};

export const getChallengeDetail: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeDetailState,
  null,
  IChallengeDetailSuccessAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: ChallengeDetailActionTypes.DETAIL_CHALLENGE_LOADING,
        challengeDetailStatus:
          ChallengeDetailActionStatus.DETAIL_CHALLENGE_LOADING,
      });
      reactNewApp
        ? await api_general
          .get(`${basePath}/${options.challengeId}`, {})
          .then((response) => {
            dispatch({
              challengeDetail: response.data,
              type: ChallengeDetailActionTypes.DETAIL_CHALLENGE_SUCCEEDED,
              challengeDetailStatus:
                ChallengeDetailActionStatus.DETAIL_CHALLENGE_SUCCEEDED,
            });
          })
        : await api_v2
          .get(`${basePath}/${options.challengeId}`, {})
          .then((response) => {
            dispatch({
              challengeDetail: response.data,
              type: ChallengeDetailActionTypes.DETAIL_CHALLENGE_SUCCEEDED,
              challengeDetailStatus:
                ChallengeDetailActionStatus.DETAIL_CHALLENGE_SUCCEEDED,
            });
          })
    } catch (err) {
      dispatch({
        error: err,
        type: ChallengeDetailActionTypes.DETAIL_CHALLENGE_FAILED,
        challengeDetailStatus:
          ChallengeDetailActionStatus.DETAIL_CHALLENGE_FAILED,
      });
      treatError(options?.culture, "PageChallenges::Header", err);
    }
  };
};

/* Get Detail Challenge Result
<Promise<Return Type>, State Interface, Type of Param, Type of Action> */
export const getDetailChallengeResult: ActionCreator<ThunkAction<
  Promise<any>,
  IChallengeDetailState,
  null,
  IDetailChallengeResultSucceededGetAllAction
>> = (options) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        status: ChallengeDetailActionStatus.GET_DETAIL_CHALLENGE_RESULT_LOADING,
        type: ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_RESULT_LOADING,
      });
      let params = [];
      let params_qs = "";

      if (options.hasOwnProperty("cultureTag")) {
        params.push(`cultureTag=${options.cultureTag}`);
      }

      if (params.length) {
        params_qs = `?${params.join("&")}`;
      }
      reactNewApp
        ? await api_v2
          .get(`${basePath}/${options.challengeId}/results${params_qs}`, {})
          .then((response) => {
            dispatch({
              challengeDetailResults: response.data,
              status:
                ChallengeDetailActionStatus.GET_DETAIL_CHALLENGE_RESULT_SUCCEEDED,
              type: ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_RESULT_SUCCEEDED,
            });
          })

        : await api_v2
          .get(`${basePath}/${options.challengeId}/results${params_qs}`, {})
          .then((response) => {
            dispatch({
              challengeDetailResults: response.data,
              status:
                ChallengeDetailActionStatus.GET_DETAIL_CHALLENGE_RESULT_SUCCEEDED,
              type: ChallengeDetailActionTypes.GET_DETAIL_CHALLENGE_RESULT_SUCCEEDED,
            });
          })

    } catch (err) {
      treatError(options.culture, "PageChallenges::Header", err);
    }
  };
};
