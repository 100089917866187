import { Reducer } from 'redux';
import { IMyActivities, IMyActivitiesIdeasClosed, IMyActivitiesIdeasImplemented, 
  IMyActivitiesIdeasInProgress, IMyActivitiesInvestmentsActive, IMyActivitiesInvestmentsHistory, 
  IMyActivitiesLikes, IMyActivitiesPrizesActiveBid, IMyActivitiesPrizesLost, IMyActivitiesPrizesWon, 
  IMyActivitiesStarsCollaborative, IMyActivitiesStarsPrivate, IPrivateMyActivityComments, 
  IPublicMyActivityComments } from 'redux/types/myActivities';
import {
  MyActivitiesActions,
  MyActivitiesActionTypes,
} from '../../actions/MyActivities/MyActivitiesActions';


// Define the Character State
export interface IMyActivitiesState {
  readonly myActivitiesStarsCollaborative: IMyActivitiesStarsCollaborative;
  readonly myActivitiesStarsPrivate: IMyActivitiesStarsPrivate;
  readonly myActivitiesInvestmentsActive: IMyActivitiesInvestmentsActive;
  readonly myActivitiesInvestmentsHistory: IMyActivitiesInvestmentsHistory;
  readonly publicComments: IPublicMyActivityComments;
  readonly privateComments: IPrivateMyActivityComments;
  readonly myActivitiesLikes: IMyActivitiesLikes;
  readonly myActivitiesPrizesActiveBid: IMyActivitiesPrizesActiveBid;
  readonly myActivitiesPrizesWon: IMyActivitiesPrizesWon;
  readonly myActivitiesPrizesLost: IMyActivitiesPrizesLost;
  readonly myActivitiesIdeasImplemented: IMyActivitiesIdeasImplemented;
  readonly myActivitiesIdeasClosed: IMyActivitiesIdeasClosed;
  readonly myActivitiesIdeasInProgress: IMyActivitiesIdeasInProgress;
  readonly myActivities: IMyActivities;
  readonly activePageMyActivities: string;
  readonly myActivitiesIdeasInProgressStatus: string;
}

// Define the initial state
const initialCharacterState: IMyActivitiesState = {
  myActivitiesStarsCollaborative: {} as IMyActivitiesStarsCollaborative,
  myActivitiesStarsPrivate: {} as IMyActivitiesStarsPrivate,
  myActivitiesInvestmentsActive: {} as IMyActivitiesInvestmentsActive,
  myActivitiesInvestmentsHistory: {} as IMyActivitiesInvestmentsHistory,
  publicComments: {} as IPublicMyActivityComments,   
  privateComments: {} as IPrivateMyActivityComments,
  myActivitiesLikes: {} as IMyActivitiesLikes,
  myActivitiesPrizesActiveBid: {} as IMyActivitiesPrizesActiveBid,
  myActivitiesPrizesWon: {} as IMyActivitiesPrizesWon,
  myActivitiesPrizesLost: {} as IMyActivitiesPrizesLost,
  myActivitiesIdeasImplemented: {} as IMyActivitiesIdeasImplemented,
  myActivitiesIdeasClosed: {} as IMyActivitiesIdeasClosed,
  myActivitiesIdeasInProgress: {} as IMyActivitiesIdeasInProgress,
  myActivities: {} as IMyActivities,
  activePageMyActivities: 'IDEAS',
  myActivitiesIdeasInProgressStatus: ''
};

export const myActivitiesReducer: Reducer<IMyActivitiesState, MyActivitiesActions> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    // ALL MY ACTIVITIES STARS COLLABORATIVE
    case MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_SUCCEDDED: {
      return action.isInfinite ? {
        ...state,
        myActivitiesStarsCollaborative: {
          ...action.myActivitiesStarsCollaborative,
          meta:action.myActivitiesStarsCollaborative.meta,
          result: [
            ...state.myActivitiesStarsCollaborative.result,
            ...action.myActivitiesStarsCollaborative.result
          ]
        },
        status: action.status
      } : {
        ...state,
        myActivitiesStarsCollaborative: {...action.myActivitiesStarsCollaborative},
        status: action.status
      }
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_COLLABORATIVE_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES STARS PRIVATE
    case MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_SUCCEDDED: {
      return action.isInfinite ? {
        ...state,
        myActivitiesStarsPrivate: {
          ...action.myActivitiesStarsPrivate,
          meta:action.myActivitiesStarsPrivate.meta,
          result: [
            ...state.myActivitiesStarsPrivate.result,
            ...action.myActivitiesStarsPrivate.result
          ]
        },
        status: action.status
      } : {
        ...state,
        myActivitiesStarsPrivate: {...action.myActivitiesStarsPrivate},
        status: action.status
      }
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_STARS_PRIVATE_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES INVESTMENTS ACTIVE
    case MyActivitiesActionTypes.INVESTMENTS_ACTIVE_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.INVESTMENTS_ACTIVE_SUCCEDDED: {
      return action.isInfinite ? {
        ...state,
        myActivitiesInvestmentsActive: {
          ...action.myActivitiesInvestmentsActive,
          meta:action.myActivitiesInvestmentsActive.meta,
          result: [
            ...state.myActivitiesInvestmentsActive.result,
            ...action.myActivitiesInvestmentsActive.result
          ]
        },
        status: action.status
      } : {
        ...state,
        myActivitiesInvestmentsActive: {...action.myActivitiesInvestmentsActive},
        status: action.status
      }
    }
    case MyActivitiesActionTypes.INVESTMENTS_ACTIVE_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES INVESTMENTS HISTORY
    case MyActivitiesActionTypes.INVESTMENTS_HISTORY_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.INVESTMENTS_HISTORY_SUCCEDDED: {
      return action.isInfinite ? {
        ...state,
        myActivitiesInvestmentsHistory: {
          ...action.myActivitiesInvestmentsHistory,
          meta:action.myActivitiesInvestmentsHistory.meta,
          result: [
            ...state.myActivitiesInvestmentsHistory.result,
            ...action.myActivitiesInvestmentsHistory.result
          ]
        },
        status: action.status
      } : {
        ...state,
        myActivitiesInvestmentsHistory: {...action.myActivitiesInvestmentsHistory},
        status: action.status
      }
    }
    case MyActivitiesActionTypes.INVESTMENTS_HISTORY_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES PUBLIC COMMENTS
    case MyActivitiesActionTypes.PUBLIC_COMMENTS_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.PUBLIC_COMMENTS_SUCCEDDED: {
      return action.isInfinite ? {
        ...state,
        publicComments: {
          ...action.publicComments,
          meta:action.publicComments.meta,
          result: [
            ...state.publicComments.result,
            ...action.publicComments.result
          ]
        },
        status: action.status
      } : {
        ...state,
        publicComments: {...action.publicComments},
        status: action.status
      }
    }
    case MyActivitiesActionTypes.PUBLIC_COMMENTS_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES PRIVATE COMMENTS
    case MyActivitiesActionTypes.PRIVATE_COMMENTS_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.PRIVATE_COMMENTS_SUCCEDDED: {
      return action.isInfinite ? {
        ...state,
        privateComments: {
          ...action.privateComments,
          meta:action.privateComments.meta,
          result: [
            ...state.privateComments.result,
            ...action.privateComments.result
          ]
        },
        status: action.status
      } : {
        ...state,
        privateComments: {...action.privateComments},
        status: action.status
      }
    }
    case MyActivitiesActionTypes.PRIVATE_COMMENTS_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES LIKES
    case MyActivitiesActionTypes.LIKES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.LIKES_SUCCEDDED: {
      return action.isInfinite ? {
        ...state,
        myActivitiesLikes: {
          ...action.myActivitiesLikes,
          meta:action.myActivitiesLikes.meta,
          result: [
            ...state.myActivitiesLikes.result,
            ...action.myActivitiesLikes.result
          ]
        },
        status: action.status
      } : {
        ...state,
        myActivitiesLikes: {...action.myActivitiesLikes},
        status: action.status
      }
    }
    case MyActivitiesActionTypes.LIKES_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES PRIZES ACTIVE BID
    case MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_SUCCEDDED: {
      return {
        ...state,
        status: action.status,
        myActivitiesPrizesActiveBid: action.myActivitiesPrizesActiveBid,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_ACTIVEBID_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES PRIZES WON
    case MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_SUCCEDDED: {
      return {
        ...state,
        status: action.status,
        myActivitiesPrizesWon: action.myActivitiesPrizesWon,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_WON_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES PRIZES LOST
    case MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_SUCCEDDED: {
      return {
        ...state,
        status: action.status,
        myActivitiesPrizesLost: action.myActivitiesPrizesLost,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_PRIZES_LOST_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES IDEAS IMPLEMENTED
    case MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_SUCCEDDED: {
      return {
        ...state,
        status: action.status,
        myActivitiesIdeasImplemented: action.myActivitiesIdeasImplemented,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_IMPLEMENTED_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // ALL MY ACTIVITIES IDEAS CLOSED
    case MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_SUCCEDDED: {
      return action.isInfinite ? {
        ...state,
        myActivitiesIdeasClosed: {
          ...action.myActivitiesIdeasClosed,
          meta:action.myActivitiesIdeasClosed.meta,
          result: [
            ...state.myActivitiesIdeasClosed.result,
            ...action.myActivitiesIdeasClosed.result
          ]
        },
        status: action.status
      } : {
        ...state,
        myActivitiesIdeasClosed: {...action.myActivitiesIdeasClosed},
        status: action.status
      }
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_CLOSED_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    // MY ACTIVITIES IN PROGRESS
    case MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_LOADING: {
      return {
        ...state,
        myActivitiesIdeasInProgressStatus: action.myActivitiesIdeasInProgressStatus
      };
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_SUCCEDDED: {
      return action.isInfinite ? {
        ...state,
        myActivitiesIdeasInProgress: {
          ...action.myActivitiesIdeasInProgress,
          meta:action.myActivitiesIdeasInProgress.meta,
          result: [
            ...state.myActivitiesIdeasInProgress.result,
            ...action.myActivitiesIdeasInProgress.result
          ]
        },
        myActivitiesIdeasInProgressStatus: action.myActivitiesIdeasInProgressStatus
      } : {
        ...state,
        myActivitiesIdeasInProgress: {...action.myActivitiesIdeasInProgress},
        myActivitiesIdeasInProgressStatus: action.myActivitiesIdeasInProgressStatus
      }
    }
    case MyActivitiesActionTypes.GET_MYACTIVITIES_IDEAS_INPROGRESS_FAILED: {
      return {
        ...state,
        myActivitiesIdeasInProgressStatus: action.myActivitiesIdeasInProgressStatus
      };
    }

    // ALL MY ACTIVITIES
    case MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_LOADING: {
      return {
        ...state,
        status: action.status,
      };
    }
    case MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_SUCCEDDED: {
      return {
        ...state,
        myActivities: action.myActivities,
      };
    }
    case MyActivitiesActionTypes.GET_ALL_MYACTIVITIES_FAILED: {
      return {
        ...state,
        status: action.status,
        error: action.error
      };
    }

    case MyActivitiesActionTypes.SET_ACTIVE_TAB: {
      return {
        ...state,
        activePageMyActivities: action.activePageMyActivities,
      };
    }
    default:
      return state;
  }
};