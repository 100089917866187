import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import history from "../../../routes/history";
import { treatError } from "utils/treatError";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Resource from "../../../components/Resources/Resource";
import { IAppState, store } from "../../../redux/store/Store";
import { IApplication } from "../../../redux/reducers/tenantReducer";
import { IProfileData } from "../../../redux/reducers/profileReducer";
import { ICultureState } from "../../../redux/reducers/cultureReducer";
import { doLogout, IAuthLogoutAction } from "redux/actions/AuthAction";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  useTheme,
} from "@material-ui/core";
import {
  ICultureSetCulture,
  setCultureAndResources,
} from "redux/actions/CultureActions";
import {
  IProfileGetAllAction,
  IProfileGetDataAction,
  IProfileUpdateAction,
  getProfile,
  getProfileData,
  updateProfile,
} from "../../../redux/actions/ProfileActions";

interface IDispatchProps {
  getProfileData: () => Promise<IProfileGetDataAction>;
  updateProfile: (
    params: object,
    options: object
  ) => Promise<IProfileUpdateAction>;
  getProfile: (params: object) => Promise<IProfileGetAllAction>;
  logOutConnect: () => Promise<IAuthLogoutAction>;
}

interface IProps {
  profileData: IProfileData;
  application: IApplication;
  cultureState: ICultureState;
  loadingSubmit: Boolean;
  updateSuccess: Boolean;
  workspaceId: string;
}

function AcceptRegulations(props: IProps & IDispatchProps) {
  const theme = useTheme();
  const { getProfileData, profileData, cultureState, workspaceId } = props;
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  function getResourcesHandler(cultureTag: string) {
    let dispatch: ThunkDispatch<any, any, ICultureSetCulture> = store?.dispatch;
    dispatch(setCultureAndResources(cultureTag));
    return store?.getState().cultureState;
  }

  useEffect(() => {
    profileData?.cultureTag === ""
      ? getResourcesHandler("en-GB")
      : getResourcesHandler(profileData?.cultureTag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e: any) => {
    setLoadingSubmit(true);
    e.preventDefault();
    const { updateProfile, getProfileData, cultureState } = props;
    let newValues = {
      agreedRegulations: true,
      workspaceId: workspaceId,
    };
    const params = {
      ...newValues,
    };

    updateProfile(params, { cultureState }).then(
      (response) => {
        getProfileData().then((rt) => {
          setLoadingSubmit(false);
          console.log(response)
          console.log(rt)
          //if (response?.updateSuccess) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            !profileData?.agreedRegulations && history?.push("/");
          //}
        });
      },
      (err) => { 
        console.log(err)
        treatError(props?.cultureState?.culture, "Profile", err);
      }
    );
  };

  useEffect(() => {
    getProfileData().then(() => {
      setLoadingSubmit(false);

      if (profileData?.agreedRegulations !== "") {
        history?.push("/");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData?.agreedRegulations]);

  return (
    <>
      {cultureState?.statusCulture === "SUCCEEDED" ? (
        <form name="form" onSubmit={onSubmit}>
          <Box fontSize="1.7rem" fontWeight={700} pl={1} ml={1} mt={3}>
            <Resource tag="PageRegulation::ReviewTheRegulation" />
          </Box>
          <Box pl={1} ml={1} mt={6} display="flex" flexWrap="wrap">
            <Box component="p" mr={1} color={theme.palette.text.secondary}>
              <Resource tag="PageRegulation::AcceptRegulations" />{" "}
              <Link component={RouterLink} to="/regulations" target="_blank">
                <Resource tag="PageRegulation::Regulation" />.
              </Link>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row-reverse" mt={10}>
            <Button
              type="submit"
              variant={!loadingSubmit ? "contained" : "outlined"}
              color="primary"
              className="btn-login"
              endIcon={!loadingSubmit ? "" : <CircularProgress size={22} />}
            >
              <Resource tag={"PageLogin::IAgree"} />
            </Button>
          </Box>
        </form>
      ) : (
        <>
          <Box display="flex" margin="0px auto" height="80vh">
            <Box m="auto">
              <CircularProgress size={60} className="icon" />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

const mapStateToProps = (store: IAppState) => {
  return {
    application: store.tenantState.application,
    cultureState: store.cultureState,
    profileData: store.profileState.profileData,
    countries: store.usersState.countries,
    updateSuccess: store.profileState.updateSuccess,
    workspaceId: store.authState.auth.workspaceId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AnyAction>
): IDispatchProps => {
  return {
    getProfileData: () => dispatch(getProfileData()),
    updateProfile: (params: object, options: object) =>
      dispatch(updateProfile(params, options)),
    getProfile: (params: object) => dispatch(getProfile(params)),
    logOutConnect: () => dispatch(doLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptRegulations);
